import React from 'react'
import { Row, Col } from 'reactstrap'
import Modal from 'react-responsive-modal'
import RotatingLoader from '../RotatingLoader'
import SearchUsersInput from '../SearchUsersInput'
import { IOptions } from '../SelectDropdown'
import {
    startDiscussion,
    IChatOptions,
    IMessageListItem,
} from '../../../services/ChatService'
import { isLandlord } from '../../../helpers/roleHelpers'
import { withRouter } from 'react-router-dom'
import SuccessTick from '../SuccessTick'
const queryString = require('query-string')
import styles from './StartChatModal.module.scss'
import { removeURLParameter } from '../../../helpers/locationHelpers'
import { addChatToChatList } from '../../../helpers/chatHelpers'
import { getUserId } from '../../../helpers/authHelpers'
import { updateToastMessage } from '../../../helpers/toastHelper'
import Buttons from '../Buttons'
import { toggleChatListCollapse } from '../../../helpers/sidebarHelpers'
import { isMobileOnly } from 'react-device-detect'
import { ISearchResult } from '../../../types/SearchService/ISearchService'

export interface IStartChatOptions {
    name?: string
    userId?: string
    propertyId?: string
    redirectToPropertyId?: string
    redirectToContactId?: string
    redirectToUserId?: string
    redirectToChat?: boolean
}

interface IProps {
    open: boolean
    onOpenModal(): void
    onCloseModal(): void
    onAddChatCallback?(item: IMessageListItem): void

    history: any
    match: any
    location: any

    userId?: string
    name?: string
}

interface IState {
    deleteTenancies: boolean
    deleteDocuments: boolean
    loading: boolean
    success: boolean

    propertyId?: string

    selectedUserId: string
    selectedUserName: string

    options: IStartChatOptions
}

export interface IDeleteRequest {
    deleteAssociatedTenancy: boolean
    deleteAssociatedDocument: boolean
}

class StartChatModal extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            selectedUserId: '',
            selectedUserName: '',
            deleteDocuments: true,
            deleteTenancies: true,
            loading: false,
            success: false,
            options: null,
            propertyId: null,
        }

        this.startChat = this.startChat.bind(this)
    }

    componentDidMount(): void {
        if (this.props.userId && this.props.name) {
            this.setState({
                selectedUserId: this.props.userId,
                selectedUserName: this.props.name,
            })
        }

        let options = queryString.parse(
            this.props.location.search
        ).startChatOptions
        if (options) {
            let option: IStartChatOptions = JSON.parse(options)

            if (option.propertyId) {
                this.setState({
                    propertyId: option.propertyId,
                })
            }

            if (option.userId != getUserId()) {
                this.setState({
                    options: option,
                    selectedUserId: option.userId,
                    selectedUserName: option.name,
                })
            }
        }

        let removeQuery = removeURLParameter(
            this.props.location.search,
            'startChatOptions'
        )
        this.props.history.push({
            search: removeQuery,
        })
    }

    startChat(): void {
        this.setState({
            loading: true,
        })
        let request: IChatOptions = {}
        if (this.state.selectedUserId) {
            request.userIds = [this.state.selectedUserId]
        }
        if (this.props.match.params.propertyId) {
            request.propertyId = this.props.match.params.propertyId
        }
        if (this.state.options.redirectToPropertyId) {
            request.propertyId = this.state.options.redirectToPropertyId
        }
        startDiscussion(request).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                let newItem: IMessageListItem = resp.data.item

                let newChatMessage = `Chat started ${
                    resp.data.chatStartedWithName &&
                    'with ' + resp.data.chatStartedWithName
                }`

                updateToastMessage(newChatMessage, 'success')

                if (this.props.onAddChatCallback) {
                    this.props.onAddChatCallback(newItem)
                }
                if (this.state.options) {
                    if (this.state.options.redirectToUserId) {
                        this.props.history.push(
                            `/dashboard/manage/users/${this.state.options.redirectToUserId}/chats/${newItem.chatSessionId}`
                        )
                    }

                    if (this.state.options.redirectToPropertyId) {
                        this.props.history.push(
                            `/dashboard/property/${this.state.options.redirectToPropertyId}/chats/${newItem.chatSessionId}`
                        )
                    }

                    if (
                        this.state.options.redirectToChat &&
                        !this.state.options.redirectToPropertyId
                    ) {
                        this.props.history.push(
                            `/dashboard/chats/${newItem.chatSessionId}`
                        )
                    }
                }
                addChatToChatList(newItem)
                if (isMobileOnly) {
                    toggleChatListCollapse(true)
                }
                this.props.onCloseModal()
            }
            this.setState({
                loading: false,
            })
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <Modal
                    open={this.props.open}
                    onClose={this.props.onCloseModal}
                    center
                >
                    <div>
                        <RotatingLoader
                            loading={true}
                            text={`Starting chat...`}
                        />
                    </div>
                </Modal>
            )
        }

        if (this.state.success) {
            return (
                <Modal
                    open={this.props.open}
                    onClose={this.props.onCloseModal}
                    center
                >
                    <div>
                        <SuccessTick />
                        <p>Chat has been started</p>
                    </div>
                </Modal>
            )
        }

        return (
            <Modal
                classNames={{ modal: styles.modal }}
                open={this.props.open}
                onClose={this.props.onCloseModal}
                center
            >
                <div>
                    <p className={styles.modelHeader}>Start Chat</p>
                    <p className={styles.modalBody}>
                        {isLandlord() ? (
                            <div>
                                Select users to start a chat with
                                <SearchUsersInput
                                    onSelect={(selected: ISearchResult) =>
                                        this.setState({
                                            selectedUserId: selected.value,
                                            selectedUserName: selected.label,
                                        })
                                    }
                                    selectedId={this.state.selectedUserId}
                                    selectedName={
                                        this.state.selectedUserName
                                    }
                                />
                            </div>
                        ) : (
                            <div>
                                Your Landlord will be automatically added to the
                                Chat
                            </div>
                        )}
                    </p>
                    <Row>
                        <Col className={styles.buttonDiv}>
                            <Buttons
                                buttons={[
                                    {
                                        text: 'No',
                                        displayType: 'cancel',
                                        elementType: 'button',
                                        onClick: () =>
                                            this.props.onCloseModal(),
                                    },
                                    {
                                        text: 'Start Chat',
                                        displayType: 'submit',
                                        elementType: 'button',
                                        onClick: () => this.startChat(),
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                </div>
            </Modal>
        )
    }
}

export default withRouter(StartChatModal)
