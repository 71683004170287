import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import * as domains from '../configuration/domains';
import { toQueryString, queryHeaders } from '../helpers/serviceHelper'

export const calculatorApi = createApi({
    reducerPath: 'calculatorApi',
    baseQuery: fetchBaseQuery({
      baseUrl: domains.calculationService,
      prepareHeaders: queryHeaders,
    }),
    tagTypes: ['Calculator'],
    endpoints: (builder) => ({
        getCalculationsByPropertyId: builder.query({
            query: (propertyId) => `/${propertyId}`,
            providesTags: ['Calculator']
        })
    })
});
  
export const { 
    useGetCalculationsByPropertyIdQuery,
    useLazyGetCalculationsByPropertyIdQuery
    } = calculatorApi