import { ErrorMessage, Form, Formik } from 'formik'
import lodashObject from 'lodash/fp'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import {
    addTeamMember,
    toggleAddTeamMember,
} from '../../../actions/teamsActions'
import { entityTypeGroupId } from '../../../configuration/appConfig'
import { elevioTeamsArticleId } from '../../../configuration/elevioConfig'
import { goToElevioArticle } from '../../../helpers/elevioHelpers'
import { capitaliseFirstLetter } from '../../../helpers/formattingHelpers'
import { processToast } from '../../../helpers/toastHelper'
import helpIcon from '../../../images/icons/help_icon.svg'
import { RootState } from '../../../reducers/rootReducer'
import { getTypesForDropdown } from '../../../services/EntityService'
import { getContactTypeWithAssignableRoles } from '../../../services/RolesService'
import { IDataResponse } from '../../../types/ApiService'
import {
    IAddTeamMemberRequest,
    IInviteTeamMemberOptions,
} from '../../../types/Teams'
import { AddTeammate } from '../../../YupValidationSchemas'
import Buttons from '../../atoms/Buttons'
import OutstandingChangesModal from '../../atoms/OutstandingChangesModal'
import RotatingLoader from '../../atoms/RotatingLoader'
import SelectDropdown, { IOptions } from '../../atoms/SelectDropdown'
import FormikPersist, { resetFormikPersist } from '../FormikPersist/FormikPersist.component'
import styles from './AddTeamMemberForm.module.scss'

interface IProps {
    add_loading: boolean
    add_options?: IInviteTeamMemberOptions
    addTeamMember(request: IAddTeamMemberRequest): void
    toggleAddTeamMember(open: boolean): void
}

const AddTeamMemberForm = ({
    add_loading,
    add_options,
    addTeamMember,
    toggleAddTeamMember,
}: IProps) => {
    const [contactTypes, setContactTypes] = useState([])
    const [roles, setRoles] = useState([])
    const [changesModalOpen, setChangesModalOpen] = useState(false)
    const formRef = useRef<any>()

    // Need to see where/how these values are going to be configured and then retrieved
    const [contactTypeDescription] = useState(
        'This determines which contact type the invited user will be referred to throughout the system. This will affect how you will be able to use the user in the system.'
    )
    const [contactTypeMoreInformationUrl] = useState(
        'https://rent-chief.elevio.help/en/articles/153'
    )
    const [userRoleDescription] = useState(
        'Select the User Role which is the level of access you wish your invited user to have.'
    )
    const [userRoleMoreInformationUrl] = useState(
        'https://rent-chief.elevio.help/en/articles/173'
    )

    useEffect(() => {
        // Get the Contact types
        const fetchContactTypes = async () => {
            let { data } = await getTypesForDropdown(entityTypeGroupId)
            if (data) {
                setContactTypes(data)
            }
        }

        fetchContactTypes()

        if (add_options) {
            fetchAssignableRoles(add_options.contactType)
        }

        resetFormikPersist('addTeamMember-form');
    }, [])

    const fetchAssignableRoles = async (contactTypeId: string) => {
        setRoles([])
        const { data } = await getContactTypeWithAssignableRoles(contactTypeId)
        if (data) {
            let response: IDataResponse<any> = data
            if (response.isSuccess) {
                setRoles(response.data.assignableRoles)
            } else {
                processToast(response)
            }
        }
    }

    const onCloseChangesModal = (close: boolean) => {
        setChangesModalOpen(false)

        if (close) {
            toggleAddTeamMember(false)
        }
    }

    const onCancel = (initialValues, values) => {
        if (lodashObject.isEqual(initialValues, values)) {
            toggleAddTeamMember(false)
            return
        }

        setChangesModalOpen(true)
    }

    if (add_loading)
        return <RotatingLoader text="Adding team member..." loading />

    return (
        <div>
            <OutstandingChangesModal
                open={changesModalOpen}
                onCloseChangesModal={onCloseChangesModal}
            />

            <div className={styles.topRow}>
                <div className={styles.header}>
                    <div className={styles.headerTitle}>Invite</div>
                </div>
                <div className={styles.help}>
                    <div
                        className={styles.icon}
                        onClick={() => goToElevioArticle(elevioTeamsArticleId)}
                    >
                        <img src={helpIcon} />
                        <span className={styles.text}>Help</span>
                    </div>
                </div>
            </div>

            <Formik
                ref={formRef}
                initialValues={{
                    forename: null,
                    surname: null,
                    email: null,
                    role: null,
                    contactType: null,
                    roleDescription: null,
                }}
                enableReinitialize={true}
                validationSchema={AddTeammate}
                onSubmit={(values: any) => {
                    addTeamMember({
                        forename: values.forename,
                        surname: values.surname,
                        teamRoleId: values.role,
                        emailAddress: values.email,
                        contactTypeId: values.contactType,
                        contactId:
                            add_options != undefined
                                ? add_options.contactId
                                : '00000000-0000-0000-0000-000000000000',
                        teamId: add_options != undefined
                                ? add_options.teamId
                                : 0
                    })
                }}
            >
                {(props) => (
                    <Form>
                        <FormikPersist name="addTeamMember-form" add_options={add_options} />
                        <div className={styles.row}>
                            <div className={styles.column}>
                                <div className={styles.label}>Email*</div>
                                <input
                                    type="email"
                                    value={props.values.email}
                                    className={styles.input}
                                    onChange={(e) =>
                                        props.setFieldValue(
                                            'email',
                                            e.currentTarget.value
                                        )
                                    }
                                    placeholder="Email..."
                                />
                                <span className={styles.errorMessage}>
                                    <ErrorMessage
                                        className={styles.error}
                                        name="email"
                                    />
                                </span>
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.column}>
                                <div className={styles.label}>Forename*</div>
                                <input
                                    value={props.values.forename}
                                    className={styles.input}
                                    onChange={(e) =>
                                        props.setFieldValue(
                                            'forename',
                                            capitaliseFirstLetter(
                                                e.currentTarget.value
                                            )
                                        )
                                    }
                                    placeholder="Forename..."
                                />
                                <span className={styles.errorMessage}>
                                    <ErrorMessage
                                        className={styles.error}
                                        name="forename"
                                    />
                                </span>
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.column}>
                                <div className={styles.label}>Surname</div>
                                <input
                                    value={props.values.surname}
                                    className={styles.input}
                                    onChange={(e) =>
                                        props.setFieldValue(
                                            'surname',
                                            capitaliseFirstLetter(
                                                e.currentTarget.value
                                            )
                                        )
                                    }
                                    placeholder="Surname..."
                                />
                                <span className={styles.errorMessage}>
                                    <ErrorMessage
                                        className={styles.error}
                                        name="surname"
                                    />
                                </span>
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.column}>
                                <div className={styles.label}>
                                    Contact Type*
                                </div>
                                <p className={styles.description}>
                                    {contactTypeDescription}
                                </p>
                                <SelectDropdown
                                    selectedId={props.values.contactType}
                                    data={contactTypes}
                                    placeholder="Select contact type..."
                                    onSelect={(id: IOptions) => {
                                        props.setFieldValue(
                                            'contactType',
                                            id.value
                                        )
                                        props.setFieldValue('role', null)
                                        props.setFieldValue(
                                            'roleDescription',
                                            null
                                        )
                                        if (
                                            id.value != props.values.contactType
                                        ) {
                                            fetchAssignableRoles(id.value)
                                        }
                                    }}
                                />
                                <span className={styles.errorMessage}>
                                    <ErrorMessage
                                        className={styles.error}
                                        name="contactType"
                                    />
                                </span>
                                <p className={styles.moreInformation}>
                                    <a
                                        href={contactTypeMoreInformationUrl}
                                        target="_blank"
                                    >
                                        More information on contacts
                                    </a>
                                </p>
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.column}>
                                <div className={styles.label}>User Role*</div>
                                <p className={styles.description}>
                                    {userRoleDescription}
                                </p>
                                <SelectDropdown
                                    selectFirstOption
                                    disabled={
                                        !props.values.contactType ||
                                        !roles.length
                                    }
                                    showWhenEmpty
                                    selectedId={props.values.role}
                                    data={roles}
                                    placeholder="Select role..."
                                    onSelect={(id: IOptions) => {
                                        props.setFieldValue('role', id.value)
                                        props.setFieldValue(
                                            'roleDescription',
                                            id.description
                                        )
                                    }}
                                />
                                {props.values.roleDescription && (
                                    <p className={styles.description}>
                                        {props.values.roleDescription}
                                    </p>
                                )}
                                <span className={styles.errorMessage}>
                                    <ErrorMessage
                                        className={styles.error}
                                        name="role"
                                    />
                                </span>
                                <p className={styles.moreInformation}>
                                    <a
                                        href={userRoleMoreInformationUrl}
                                        target="_blank"
                                    >
                                        More information on user roles and teams
                                    </a>
                                </p>
                            </div>
                        </div>

                        <div className={styles.buttons}>
                            <Buttons
                                buttons={[
                                    {
                                        width: 'full',
                                        text: 'Cancel',
                                        displayType: 'cancel',
                                        elementType: 'button',
                                        onClick: () =>
                                            onCancel(
                                                props.initialValues,
                                                props.values
                                            ),
                                    },
                                    {
                                        width: 'full',
                                        text: 'Send Invite',
                                        displayType: 'submit',
                                        elementType: 'button',
                                        onClick: () => props.submitForm(),
                                    },
                                ]}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

const mapStateToProps = (state: RootState) => ({
    add_loading: state.teams.add_loading,
    add_options: state.teams.add_options,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            addTeamMember,
            toggleAddTeamMember,
        },
        dispatch
    )

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AddTeamMemberForm) as any
)
