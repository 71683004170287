// Import React
import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { IDocument } from "../../../../../types/DocumentService";
import GenericPopup from "../../../../atoms/GenericPopup";
import { QuickViewType } from "../../../../../types/QuickView";
import { generateId } from "../../../../../helpers/randomHelpers";
import { getFileTypeLogo } from "../../../../../helpers/documentHelpers";
import addIcon from "../../../../../images/icons/addbutton.svg";
import { connect } from "react-redux";
import { toggleAddDocumentPopup } from "../../../../../helpers/sidebarHelpers";
import styles from "./MyDocuments.module.scss";
import { IDocumentUploadOptions } from "../../../AddDocumentModal";
import { addURLParameter } from "../../../../../helpers/locationHelpers";

interface IProps {
  canAdd?: boolean;
}

interface IReduxProps {
  documents?: Array<IDocument>;
}

interface IRouterProps {
  history: any;
  location: any;
  match: any;
}

interface IState {}

type Props = IRouterProps & IProps & IReduxProps;

class MyDocuments extends Component<Props, IState> {
  constructor(props: any) {
    super(props);

    this.state = {};

    this.onAddDocument = this.onAddDocument.bind(this);
  }

  onAddDocument(): void {
    toggleAddDocumentPopup(true);
  }

  render() {
    return (
      <div className={styles.page}>
        {this.props.documents.length > 0 ? (
          <ul className={styles.documentList}>
            {this.props.documents.map((d, index) => {
              return (
                <li key={index}>
                  <GenericPopup
                    identifier={generateId(10)}
                    options={{
                      type: QuickViewType.Document,
                      documentId: d.documentId,
                      toggleComponent: getFileTypeLogo(d.fileType)
                    }}
                  />
                  {d.documentName}
                </li>
              );
            })}
          </ul>
        ) : (
          <p className={styles.issues}>You currently have no documents</p>
        )}

        {this.props.canAdd && (
          <div className={styles.add}>
            <img onClick={this.onAddDocument} src={addIcon} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  documents: state.document.document_list
});

export default withRouter<Props,any>(connect<IReduxProps>(mapStateToProps)(MyDocuments));
