// Import React
import React, { Component } from "react";
import { Button, FormGroup, Label, Row, Col } from "reactstrap";
import { Formik, Form, ErrorMessage } from "formik";
import { AddClient } from "../../../YupValidationSchemas";
import { getAssignableUsers } from "../../../services/RolesService";
const queryString = require("query-string");

import { withRouter } from "react-router-dom";
import styles from "./AssignRoleUserForm.module.css";
import SuccessTick from "../../atoms/SuccessTick";
import SelectDropdown, { IOptions } from "../../atoms/SelectDropdown";
import CloseButton from "../../atoms/CloseButton";
import { toggleAssignRoleSidebar } from "../../../helpers/sidebarHelpers";
import { assignRoleToUsers } from "../../../services/RolesService";
import { IAssignUserRoleRequest } from "../../../types/RolesService";

interface IProps {
  onSuccessHandler(): void;
}

interface IRouterProps {
  history: any;
  location: any;
  match: any;
}

interface IState {
  availableUsers: Array<IOptions>;
  selectedUsers: Array<IOptions>;
  roleName: string;

  loading: boolean;
  success: boolean;
}

type Props = IRouterProps & IProps;

class AssignRoleUserForm extends Component<Props, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      roleName: "No role name found",
      availableUsers: [],
      selectedUsers: [],
      loading: false,
      success: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.isLoading = this.isLoading.bind(this);
  }

  handleChange(event: React.FormEvent<HTMLInputElement>) {
    const field = event.currentTarget.name;
    const value: string = event.currentTarget.value;
    this.setState({ [field]: value } as Pick<IState, any>);
  }

  isLoading(loading: boolean): void {
    this.setState({
      loading
    });
  }

  onSubmit(): void {
    this.isLoading(true);

    let users: Array<string> = [];

    this.state.selectedUsers.map(user => {
      users.push(user.value);
    });

    let roleId = queryString.parse(this.props.location.search).assignRole;

    let request: IAssignUserRoleRequest = {
      users,
      role: roleId
    };

    assignRoleToUsers(request).then(resp => {
      if (resp.status == 200) {
        this.isLoading(false);
        this.setState({
          success: true
        });
        setTimeout(() => {
          toggleAssignRoleSidebar(false);
        }, 2000);
      }
    });
  }

  componentDidMount() {
    let roleId = queryString.parse(this.props.location.search).assignRole;
    let roleName = queryString.parse(this.props.location.search).assignRoleName;

    if (!roleId) {
      return;
    }

    if (roleName) {
      this.setState({
        roleName
      });
    }

    getAssignableUsers(roleId).then(resp => {
      if (resp && resp.data) {
        this.setState({
          availableUsers: resp.data
        });
      }
    });
  }

  render() {
    if (this.state.loading) {
      return <div>Assigning role...</div>;
    }

    if (this.state.success) {
      return (
        <div>
          <SuccessTick />{" "}
          <span className={styles.text}>
            Role has been assigned succesfully.
          </span>
        </div>
      );
    }

    return (
      <div className={styles.form}>
        <div className={styles.close}>
          <CloseButton close={() => toggleAssignRoleSidebar(false)} />
        </div>

        <Row className={styles.header}>
          <Col>
            <h1 className={styles.h1}>Assign User</h1>
          </Col>
        </Row>
        <Formik
          initialValues={{}}
          onSubmit={() => {
            this.onSubmit();
          }}
        >
          <Form className={styles.form}>
            <FormGroup>
              <Label className={styles.label} for="clientName">
                User(s)
              </Label>
              <SelectDropdown
                multiSelect
                data={this.state.availableUsers}
                onSelect={(result: Array<IOptions>) =>
                  this.setState({ selectedUsers: result })
                }
                placeholder="Select user(s)..."
              />
            </FormGroup>
            <div className={styles.errorMessage}>
              <ErrorMessage className={styles.errorMessage} name="clientName" />
            </div>

            <FormGroup>
              <Label className={styles.label} for="clientDescription">
                Role
              </Label>
              <input
                className={styles.input}
                placeholder="Role..."
                value={this.state.roleName}
                disabled
              />
            </FormGroup>
            <div className={styles.errorMessage}>
              <ErrorMessage
                className={styles.errorMessage}
                name="clientDescription"
              />
            </div>

            {this.state.selectedUsers.length > 0 && (
              <FormGroup>
                <p>
                  You are assigned the role Platform Admin, to the following
                  users:{" "}
                  {this.state.selectedUsers.map((user, index) => {
                    return <span key={index}>{user.label}</span>;
                  })}
                </p>
              </FormGroup>
            )}

            <Button className={styles.button} type="submit">
              {<span>Assign Role</span>}
            </Button>
          </Form>
        </Formik>
      </div>
    );
  }
}

export default withRouter(AssignRoleUserForm) as any;
