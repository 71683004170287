import React from 'react'
import { Checkbox, FormControlLabel } from "@material-ui/core";

interface props {
    field: string;
    label: string;
    props: any;
    onChange?(checked: boolean): void;
}

const RCCheckbox = ({field, label, props, onChange} : props) => {
    return(
        <FormControlLabel
            control={
                <Checkbox
                    checked={props.values[field] == true}
                    color="primary"
                    onChange={(e, checked) => {
                        props.setFieldValue(field, checked);
                        onChange && onChange(checked);
                    }}
                />
            }
            label={label}
        />
    )
}

export default RCCheckbox;