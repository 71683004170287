import React, { Component } from 'react'
import { withRouter, Route } from 'react-router-dom'

// Import Styles
import styles from './RoleDetail.module.scss'
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import GenericButton from '../../../../../atoms/GenericButton'
import ProfileImage from '../../../../../atoms/ProfileImage'
import { getRole, updateRole } from '../../../../../../services/RolesService'
import {
    IRoleDetail,
    IUpdateRoleRequest,
} from '../../../../../../types/RolesService'
import SuccessTick from '../../../../../atoms/SuccessTick'
import { IUserListItem } from '../../../../../../types/ClientService'
import Buttons from '../../../../../atoms/Buttons'

interface IRouterProps {
    history: any
    match: any
    location: any
}

interface IProps {
    roleId: string
    roleDetail: IRoleDetail
    user?: IUserListItem
}

interface IState {
    userFriendlyName: string
    description: string
    success: boolean
}

type Props = IProps & IRouterProps

class RoleDetail extends Component<Props, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            userFriendlyName: '',
            description: '',
            success: false,
        }

        this.onUpdate = this.onUpdate.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount(): void {
        if (!this.props.roleId) {
            return
        }
    }

    onUpdate(): void {
        let request: IUpdateRoleRequest = {
            userFriendlyName: this.state.userFriendlyName,
            description: this.state.description,
        }

        updateRole(this.props.roleId, request).then((resp) => {
            if (resp.status == 200) {
                this.setState({
                    success: true,
                })
            }
        })
    }

    handleChange(
        event:
            | React.FormEvent<HTMLInputElement>
            | React.FormEvent<HTMLTextAreaElement>
    ) {
        const field = event.currentTarget.name
        const value: string = event.currentTarget.value
        this.setState({ [field]: value } as Pick<IState, any>)
    }

    render() {
        if (!this.props.roleDetail) {
            return <div>Cannot get role details.</div>
        }

        if (this.state.success) {
            return (
                <div>
                    <SuccessTick />
                    Role has been updated.
                </div>
            )
        }

        return (
            <div className={styles.generalPage}>
                <Row>
                    <Col xs="3">
                        <ProfileImage
                            large={true}
                            forename={
                                this.props.user && this.props.user.forename
                            }
                            surname={this.props.user && this.props.user.surname}
                            image={
                                this.props.user &&
                                this.props.user.profileImageUri
                            }
                        />
                        <div className={styles.title}>
                            {this.props.roleDetail.roleUserFriendlyName}
                        </div>
                    </Col>

                    <Col xs="3">
                        <Row>
                            <Col>
                                <div className={styles.container}>
                                    <div className={styles.label}>
                                        Role Name
                                    </div>
                                    <input
                                        className={styles.input}
                                        onChange={this.handleChange}
                                        name="userFriendlyName"
                                        defaultValue={
                                            this.props.roleDetail
                                                .roleUserFriendlyName
                                        }
                                        value={
                                            this.props.roleDetail
                                                .roleUserFriendlyName
                                        }
                                        placeholder="Role name..."
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className={styles.container}>
                                    <div className={styles.label}>
                                        Role Code Name
                                    </div>
                                    <input
                                        disabled
                                        className={styles.disabled}
                                        defaultValue={
                                            this.props.roleDetail.roleName
                                        }
                                        value={this.props.roleDetail.roleName}
                                        placeholder="Role name..."
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className={styles.container}>
                                    <div className={styles.label}>
                                        Description
                                    </div>
                                    <textarea
                                        className={styles.input}
                                        defaultValue={
                                            this.props.roleDetail
                                                .roleDescription
                                        }
                                        value={this.state.description}
                                        onChange={this.handleChange}
                                        name="description"
                                        placeholder="Role description..."
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Buttons
                            buttons={[
                                {
                                    displayType: 'cancel',
                                    elementType: 'button',
                                    onClick: () => null,
                                },
                                {
                                    displayType: 'submit',
                                    elementType: 'button',
                                    onClick: () => this.onUpdate(),
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default withRouter(RoleDetail)
