import { addURLParameter, appendAddTenancyOptions, IAddTenancyOptions } from "../../../../../helpers/locationHelpers";
import { toggleTransactionPopup } from "../../../../../helpers/sidebarHelpers";
import { toggleDeleteUnitModal } from "../../../../../helpers/unitHelpers";
import history from "../../../../../history";
import { IAddTransactionReferer } from "../../../../atoms/AddEditTransaction";
import { IAddEditTransactionOptions } from "../../../../atoms/AddEditTransactionModal";

export function onAddUnitTransactionClick(propertyId: string, propertyName: string, unitId: string, unitName: string, search: string, isIncome: boolean, isExpense: boolean,): void {
  let options: IAddEditTransactionOptions = {
    referer: IAddTransactionReferer.Unit,
    propertyId: propertyId,
    propertyName: propertyName,
    unitId: unitId,
    unitName: unitName,
    isIncome,
    isExpense
  }

  let stringed = "addEditTransactionOptions=" + JSON.stringify(options);

  let query = addURLParameter(search, stringed);
  history.push({
    search: query
  });

  toggleTransactionPopup(true);
}

export function onAddUnitTenancyClick(unitId: string, unitName: string, propertyId: string, propertyName: string, search: any): void {
  let options: IAddTenancyOptions = {
    unitId,
    unitName,
    propertyId,
    propertyName
  };

  let query = appendAddTenancyOptions(search, options);

  history.push(`/dashboard/tenancy/add${query}`);
}

export function onDeleteRows(
  unitIds: string[],
  location: any,
  history: any,
  deleteUnitRedirectUrl?: string
): void {
  let test = JSON.stringify(unitIds);

  let q = "deleteUnit=" + test;

  if (deleteUnitRedirectUrl) {
    q += "&deleteUnitRedirectUrl=" + deleteUnitRedirectUrl;
  }

  if (location != null && location != undefined) {
    let query = addURLParameter(
      location.search,
      q
    );

    history.push({
      search: query
    });
  }

  toggleDeleteUnitModal(true);
}