import moment from 'moment'
import React from 'react'
import 'react-chat-elements/dist/main.css'
import { connect } from 'react-redux'
import { Router, withRouter } from 'react-router-dom'
import 'react-tippy/dist/tippy.css'
import { getUserId } from '../../../helpers/authHelpers'
import {
    addMessageToCurrentChat,
    updateMessageToCurrentChat,
    clearUnreadChatMessagesSessionSpecific,
    doesChatSessionHaveUnreadMessages,
    processSignalRMessage,
    removeUnreadMessageCount,
    updateActiveChatSessionId,
    updateChatListItemWithLatestChat,
    updateViewingChat,
    updateFooterVisibility,
    inputFocussed,
    updateChatAreaHeight,
    addChatToChatList
} from '../../../helpers/chatHelpers'
import { getFileExtention, docIsImage } from '../../../helpers/documentHelpers'
import { toggleActiveTab } from '../../../helpers/loadingHelpers'
import { canAddUsersToChat } from '../../../helpers/permissionHelpers'
import {
    getLoggedInUsersForename,
    getLoggedInUsersProfileImage,
    getLoggedInUsersSurname
} from '../../../helpers/profileHelpers'
import history from '../../../history'
import chatAddUser from '../../../images/icons/chat_groupadd_topright.svg'
import {
    getMessages,
    IChatItem,
    IChatOptions,
    IChatUsers,
    IGetMessageResponse,
    IViewingHistory,
    sendMessage,
    startDiscussion,
    markChatAsRead,
    IMessageListItem,
} from '../../../services/ChatService'
import WebsocketService from '../../../services/SignalRService'
import {
    changeChatSessionIdUrlPathParameter,
} from '../../../helpers/locationHelpers'
import AddUsersToChatModal from '../AddUsersToChatModal'
import GenericButton from '../GenericButton'
import RotatingLoader from '../RotatingLoader'
import UserContactInfo, { UserContactInfoType } from '../UserContactInfo'
import styles from './ChatBox.module.scss'
import ChatInput from './ChatInput'
import ChatMessage from './ChatMessage'
import * as S from './ChatBox.styles'
const isImage = require('is-image')

interface IProps {
    history: any
    location: any
    match: any
    options: IChatBoxOptions
    viewing_chat: IGetMessageResponse
    fullChatLink?: boolean
    hideChats?: boolean
    hideUsers?: boolean
    is_sticky_nav: boolean
    isNested: boolean
    inputIsFocussed: false
    editBoxHeight?: number
    property: any
    issue: any
    chatAreaHeight: number,
    viewingUnit: any,
    viewingAsset: any
}

export interface IChatBoxOptions {
    issueId?: string
    chatSessionId?: string
}

interface IState {
    chatSessionId: string
    messages: Array<IChatItem>
    chatImages: Array<string>
    newMessage: string
    chatLoading: boolean
    viewingHistory: Array<IViewingHistory>
    chatUsers: Array<IChatUsers>
    addUser: boolean
    sending: boolean
    is_tab_active: boolean
    //chatAreaHeight: number
    isKeyboardVisible: boolean
}

class ChatBox extends React.Component<IProps, IState> {
    scroll
    chatInput
    initialViewportHeight
    previousChatAreaHeight
    uploadFileSectionVisible

    constructor(props: any) {
        super(props)

        this.scroll = React.createRef()
        this.chatInput = React.createRef()
        this.initialViewportHeight = React.createRef()
        this.previousChatAreaHeight = React.createRef()
        this.previousChatAreaHeight = 0;

        this.state = {
            chatSessionId: '',
            messages: [],
            chatImages: [],
            newMessage: '',
            chatLoading: false,
            viewingHistory: [],
            chatUsers: [],
            addUser: false,
            sending: false,
            is_tab_active: false,
            isKeyboardVisible: false
        }

        this.onSend = this.onSend.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.getChats = this.getChats.bind(this)
        this.startDiscussion = this.startDiscussion.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.register = this.register.bind(this)
        this.unregister = this.unregister.bind(this)
        this.scrollToBottom = this.scrollToBottom.bind(this)
        this.focusInput = this.focusInput.bind(this)
        this.isLastChatVisible = this.isLastChatVisible.bind(this)
        this.onFocus = this.onFocus.bind(this)
        this.onBlur = this.onBlur.bind(this)
    }

    focusInput(): void {
        if (this.chatInput && this.chatInput.current) {
            this.chatInput.current.focus()
        }
        //this.scrollToBottom();
    }

    scrollToBottom = () => {
        if (this.scroll && this.scroll.current) {
            this.scroll.current.scrollTop = this.scroll.current.scrollHeight
        }
    }

    register(): void {
        WebsocketService.registerNewMessage(this.state.chatSessionId, (msg) => {
            processSignalRMessage(
                msg,
                this.state.chatSessionId,
                this.scrollToBottom
            )
        })
    }

    unregister(): void {
        WebsocketService.unregisterNewMessage(this.state.chatSessionId)
    }

    componentDidMount(): void {
        this.getChats()

        if (!window) {
            return
        }

        window.addEventListener('focus', this.onFocus)
        window.addEventListener('blur', this.onBlur)
/*         window.addEventListener("load",function() {
            setTimeout(function(){
                // This hides the address bar:
                window.scrollTo(0, 1);
            }, 0);
        }); */
        updateFooterVisibility(false);
        //window.addEventListener('resize', this.onScreenRezie.bind(this))
        this.initialViewportHeight.current = window.visualViewport.height;
        console.debug(`windowHeight at mount: ${window.innerHeight}`)
        console.debug(`windowHeight width at mount: ${window.innerWidth}`)
        console.debug(`visualViewportHeight at mount: ${window.visualViewport.height}`)
        console.debug(`visualViewportHeight Width at mount: ${window.visualViewport.width}`)
        //window.visualViewport.addEventListener('resize', () => {this.setState({isKeyboardVisible: !this.state.isKeyboardVisible})});
        window.visualViewport.addEventListener('resize', this.onScreenRezie.bind(this));
        var calcChatAreaHeight = this.calculateChatAreaHeight();
        if(this.previousChatAreaHeight != calcChatAreaHeight){
            this.previousChatAreaHeight = calcChatAreaHeight;
            updateChatAreaHeight(calcChatAreaHeight);
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        var calcChatAreaHeight = this.calculateChatAreaHeight();
        if(this.previousChatAreaHeight != calcChatAreaHeight){
            this.previousChatAreaHeight = calcChatAreaHeight;
            updateChatAreaHeight(calcChatAreaHeight);
        }
    }

    onScreenRezie = () =>{
        var calcChatAreaHeight = this.calculateChatAreaHeight();
        updateChatAreaHeight(calcChatAreaHeight);
    }

    calculateChatAreaHeight = () => {
        // Calculation explanation: The hard coded numbers below have been obtained by examining the heights
        // of the various parts of the screen depending on what page you are on, for mobile and desktop.
        // We then use these values based on what the current viewed page is. To work out what the current
        // viewed page is, we 'piggy back' on what redux holds, e.g. so if redux has a viewing issue 
        // then we must be viewing a issue
        let isDesktop = window.innerWidth >= 768;
        let RandomValue = 130
        let MainHeader = 78.8
        let PropertyHeader = isDesktop ? 56.2 : 51.4
        let PropertyNavigation = isDesktop ? 44.2 : 65.6
        let issuesNavigation = 33.6
        let issuesNavigationWithoutProperty = isDesktop ? 44.2 : 65.6
        let EditIssueBox = 0;
        let uploadFileSection = 135;
        if(this.props.isNested){
            //EditIssueBox = isDesktop ? 178.4 : 216.4
            EditIssueBox = this.props.editBoxHeight + 15;
        }
        console.debug(`windowInnerHeight: ${window.innerHeight}`)
        console.debug(`windowHeight: ${window.outerHeight}`)
        console.debug(`windowHeight width: ${window.innerWidth}`)
        console.debug(`visualViewportHeight: ${window.visualViewport.height}`)
        console.debug(`visualViewportHeight Width: ${window.visualViewport.width}`)
        let calcChatAreaHeight = window.visualViewport.height - RandomValue - MainHeader;

        if((this.props.property && this.props.property.property_data.property_data.propertyDetails) 
            || this.props.viewingUnit
            || this.props.viewingAsset){
            calcChatAreaHeight = calcChatAreaHeight - PropertyHeader - PropertyNavigation;
        }

        if((this.props.issue && this.props.issue.viewing_issue_edit) || this.props.viewingUnit){
            if((this.props.property && this.props.property.property_data.property_data.propertyDetails) 
            || this.props.viewingUnit 
            || this.props.viewingAsset)
            {
                calcChatAreaHeight = calcChatAreaHeight - issuesNavigation - EditIssueBox;
            }
            else
            {
                calcChatAreaHeight = calcChatAreaHeight - issuesNavigationWithoutProperty - EditIssueBox;
            }
            
        }

        if(this.uploadFileSectionVisible){
            calcChatAreaHeight = calcChatAreaHeight - uploadFileSection;
        }
        console.debug(`calcChatAreaHeight: ${calcChatAreaHeight}`)
        return  calcChatAreaHeight >= 0 ? calcChatAreaHeight : 0;
    }

    keyboardIsOnscreen = () => {
        let heightDiff = Math.abs(this.initialViewportHeight - window.visualViewport.height);
        if(window.visualViewport.height != this.initialViewportHeight && heightDiff > 30){
            this.setState({isKeyboardVisible: true});
        }else{
            this.setState({isKeyboardVisible: false});
        }
    }

    onFocus = () => {
        if (this.state.is_tab_active) {
            return
        }

        toggleActiveTab(true)
    }

    onBlur = () => {
        if (!this.state.is_tab_active) {
            return
        }

        toggleActiveTab(false)
    }

    componentWillUnmount(): void {
        updateViewingChat(null)
        updateActiveChatSessionId(null)
        this.unregister()
        updateFooterVisibility(true);
    }

    isLastChatVisible(): boolean {
        let offset = 0
        if (!this.scroll) return false
        const top = this.scroll.current.getBoundingClientRect().top
        return top + offset >= 0 && top - offset <= window.visualViewport.height;
    }

    componentWillReceiveProps(props: IProps): void {
        if (this.props.options.chatSessionId != props.options.chatSessionId) {
            this.unregister()
            this.getChats(props.options.chatSessionId)
        }
    }

    getChats(chatSessionId?: string): void {
        updateViewingChat(null)
        this.setState({
            chatLoading: true,
        })
        let req: IChatOptions = {}
        if (this.props.options.issueId) {
            req.issueId = this.props.options.issueId
        }
        if (chatSessionId) {
            req.chatSessionId = chatSessionId
        } else if (this.props.options.chatSessionId) {
            req.chatSessionId = this.props.options.chatSessionId
        }

        getMessages(req)
            .then((resp) => {
                if (resp && resp.status == 200) {
                    let result: IGetMessageResponse = resp.data

                    if (result) {
                    }
                    var images: Array<string> = [];
                    result.messages.map(i => i.isImage && docIsImage(i.imageUri) && images.push(i.imageUri));
                    this.setState({chatImages: images});
                    updateViewingChat(result)
                    updateActiveChatSessionId(req.chatSessionId)
                    this.setState({
                        chatSessionId: result.chatSessionId,
                    })
                }
                this.setState({
                    chatLoading: false,
                })
                this.register()

                if (doesChatSessionHaveUnreadMessages(req.chatSessionId)) {
                    removeUnreadMessageCount(1)
                    clearUnreadChatMessagesSessionSpecific(req.chatSessionId)
                }

                this.scrollToBottom()
                this.focusInput()
            })
            .catch((e) => {
                this.setState({
                    chatLoading: false,
                })
            })
    }

    getChatsForUserListUpdate(chatSessionId?: string): void {
        updateViewingChat(null)
        this.setState({
            chatLoading: true,
        })
        let req: IChatOptions = {}
        if (this.props.options.issueId) {
            req.issueId = this.props.options.issueId
        }
        if (chatSessionId) {
            req.chatSessionId = chatSessionId
        } else if (this.props.options.chatSessionId) {
            req.chatSessionId = this.props.options.chatSessionId
        }

        getMessages(req)
            .then((resp) => {
                if (resp && resp.status == 200) {
                    let result: IGetMessageResponse = resp.data
                    var images: Array<string> = [];
                    result.messages.map(i => i.isImage && docIsImage(i.imageUri) && images.push(i.imageUri));
                    this.setState({chatImages: images});
                    updateViewingChat(result)
                }
                this.setState({
                    chatLoading: false,
                })

                this.scrollToBottom()
                this.focusInput()
            })
            .catch((e) => {
                this.setState({
                    chatLoading: false,
                })
            })
    }

    startDiscussion(): void {
        this.setState({
            chatLoading: true,
        })
        let req: IChatOptions = {}
        if (this.props.options.issueId) {
            req.issueId = this.props.options.issueId
        }
        req.userIds = []
        startDiscussion(req).then((resp) => {
            if (resp && resp.status == 200) {
                let newItem: IMessageListItem = resp.data.item

                let newPath = changeChatSessionIdUrlPathParameter(this.props.match, newItem.chatSessionId)
                this.props.history.push(newPath)
                addChatToChatList(newItem)
            }
            this.setState({
                chatLoading: false,
            })
        })
    }

    handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            this.onSend('', null)
        }
    }

    onSend(msg: string, file?: File): void {
        if (!msg && !file) {
            return
        }

        var formData = new FormData()
        if (file) {
            formData.append('File', file)
        }

        this.setState({
            sending: true,
        })

        var today = new Date()
        var date =
            today.getFullYear() +
            '-' +
            (today.getMonth() + 1) +
            '-' +
            today.getDate()
        var time =
            today.getHours() +
            ':' +
            today.getMinutes() +
            ':' +
            today.getSeconds()
            var dateTime = date + ' ' + time;

        // Message is shown immediately
        const data = null;
        addMessageToCurrentChat({
            locChatMessageId: dateTime.toString(),
            fileExtension: data
                ? data.fileExtension
                : file
                ? getFileExtention(file.type)
                : '',
            isImage: file ? true : false,
            imageUri: data
                ? data.fileUri
                : file
                ? URL.createObjectURL(file)
                : '',
            isSuccess: false,
            systemMessage: false,
            message: msg,
            dateAdded: dateTime,
            isCurrentUserSender: true,
            sender: {
                senderForename: getLoggedInUsersForename(),
                senderSurname: getLoggedInUsersSurname(),
                senderUserId: getUserId(),
                senderProfileImageUri: getLoggedInUsersProfileImage(),
            },
        })

        this.scrollToBottom();

        sendMessage(
            {
                chatSessionId: this.state.chatSessionId,
                message: msg,
            },
            formData
        ).then((resp) => {
            const response = resp.data
            if (response.isSuccess) {
                const { data } = response
                if(file && docIsImage(data.fileUri)){
                    this.setState({chatImages: [data.fileUri, ...this.state.chatImages]})
                }
                updateMessageToCurrentChat({
                    locChatMessageId: dateTime.toString(),
                    fileExtension: data
                        ? data.fileExtension
                        : file
                        ? getFileExtention(file.type)
                        : '',
                    isImage: file ? true : false,
                    imageUri: data
                        ? data.fileUri
                        : file
                        ? URL.createObjectURL(file)
                        : '',
                    isSuccess: response.isSuccess,
                    systemMessage: false,
                    message: msg,
                    dateAdded: dateTime,
                    isCurrentUserSender: true,
                    sender: {
                        senderForename: getLoggedInUsersForename(),
                        senderSurname: getLoggedInUsersSurname(),
                        senderUserId: getUserId(),
                        senderProfileImageUri: getLoggedInUsersProfileImage(),
                    },
                })

                this.setState({
                    sending: false,
                })

                this.focusInput()

                updateChatListItemWithLatestChat(
                    {
                        lastMessageSenderForename: getLoggedInUsersForename(),
                        lastMessageSenderSurname: getLoggedInUsersSurname(),
                        lastMessageSenderProfileImageUri:
                            getLoggedInUsersProfileImage(),
                        lastMessage: msg,
                        lastMessageDateTime: moment().toString(),
                        chatSessionId: this.state.chatSessionId,
                        title: '',
                        hasOpened: true,
                        unreadMessageCount: 0,
                        lastOpened: moment().toString(),
                    },
                    this.state.chatSessionId,
                    true
                )

                //this.scrollToBottom()
            }
        })
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const value: string = event.currentTarget.value
        this.setState({ newMessage: value } as Pick<IState, any>)

        this.handleKeyDown(event)
    }

    render() {
        if (this.state.chatLoading) {
            return (
                <RotatingLoader text="Loading discussion..." loading={true} />
            )
        }

        if (!this.props.viewing_chat) {
            return (
                <div className={styles.chatBox}>
                    <p>No discussion started, press start to begin.</p>{' '}
                    <p className={styles.btnHolder}>
                        <GenericButton border onClick={this.startDiscussion}>
                            Start Chat
                        </GenericButton>
                    </p>
                </div>
            )
        }

        return this.state.chatLoading ? (
            <RotatingLoader text="Loading discussion..." loading={true} />
        ) : this.props.viewing_chat.messages.length === 0 ? (
            <div className={styles.chatBox}>
                <p>No discussion started, press start to begin.</p>{' '}
                <p className={styles.btnHolder}>
                    <GenericButton border onClick={this.startDiscussion}>
                        Start Chat
                    </GenericButton>
                </p>
            </div>
        ) : (
            <div className={styles.box} onClick={() => markChatAsRead(this.state.chatSessionId)}>
                <div
                    className={`${
                        this.props.is_sticky_nav && !this.props.isNested
                            ? styles.sticky
                            : this.props.isNested
                            ? styles.navNestedWrapper
                            : styles.navWrapper
                    }`}
                >
                    <div className={styles.nav}>
                        {!this.props.hideUsers && (
                            <div className={styles.listWrapper}>
                                <ul>
                                    {this.props.viewing_chat.chatUsers.map(
                                        (user) => {
                                            return (
                                                <li>
                                                    <Router history={history}>
                                                        <UserContactInfo
                                                            classNames={{
                                                                userIcon:
                                                                    styles.userIcon,
                                                                typeOrRole:
                                                                    styles.typeOrRole,
                                                            }}
                                                            type={
                                                                UserContactInfoType.User
                                                            }
                                                            id={user.userId}
                                                        />
                                                    </Router>
                                                </li>
                                            )
                                        }
                                    )}
                                </ul>
                            </div>
                        )}

                        {canAddUsersToChat() && (
                            <div className={styles.navActions}>
                                <div>
                                    <img
                                        src={chatAddUser}
                                        onClick={() =>
                                            this.setState({ addUser: true })
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <AddUsersToChatModal
                    onCallback={() => {
                        this.getChatsForUserListUpdate(this.props.options.chatSessionId)
                        this.setState({
                            addUser: false,
                        })
                    }}
                    chatSessionId={this.state.chatSessionId}
                    open={this.state.addUser}
                    onOpenModal={() => this.setState({ addUser: true })}
                    onCloseModal={() => this.setState({ addUser: false })}
                    filteredOutUserList={this.props.viewing_chat.chatUsers}
                />

                {!this.props.hideChats && (
                    <div className={styles.inner} >
                        <S.ChatDiv height={`${window.innerHeight - this.props.chatAreaHeight}px`} ref={this.scroll}>
                            {this.props.viewing_chat.messages.map(
                                (message, index) => {
                                    if (message.systemMessage) {
                                        return null
                                    }

                                    return (
                                        <ChatMessage
                                            message={message}
                                            images={this.state.chatImages}
                                            key={index}
                                        />
                                    )
                                }
                            )}
                        </S.ChatDiv>

                        
                            <ChatInput
                                sending={this.state.sending}
                                onSubmit={this.onSend}
                                onUploadFileSelected={(uploadFileSelected: boolean)=>{
                                    this.uploadFileSectionVisible = uploadFileSelected;
                                    var calcChatAreaHeight = this.calculateChatAreaHeight();
                                    if(this.previousChatAreaHeight != calcChatAreaHeight){
                                        this.previousChatAreaHeight = calcChatAreaHeight;
                                        updateChatAreaHeight(calcChatAreaHeight);
                                    }
                                }}
                            />
                        
                    </div>
                    
                )}
                
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    viewing_chat: state.chat.viewing_chat,
    issue: state.issue,
    property: state.property,
    viewingUnit: state.unit.viewing_unit,
    viewingAsset: state.assets.viewing_asset,
    is_sticky_nav: state.app.is_sticky_nav,
    inputIsFocussed: state.app.input_focussed,
    chatAreaHeight: state.app.chat_area_height
})

export default connect(mapStateToProps)(withRouter(ChatBox))
