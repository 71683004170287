import React from "react";
import { Row, Col } from "reactstrap";
import Modal from "react-responsive-modal";

import styles from "./PlatformVersionModal.module.scss";
import { buildNumber } from "../../../configuration/appConfig";
import { getCoreVersion, getIdentityVersion } from "../../../services/VersionService";
import RotatingLoader from "../RotatingLoader";

interface IProps {
  open: boolean;
  onOpenModal(): void;
  onCloseModal(): void;
}

interface IState {
  isLoading: boolean;
  coreVersion: string;
  identityVersion: string;
  integrationsVersion: string;
}

class PlatformVersionModal extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: false,
      coreVersion: "0.0.0",
      identityVersion: "0.0.0",
      integrationsVersion: "0.0.0"
    }
  }

  componentDidMount(): void {
    this.setState({
      isLoading: true
    });

    getCoreVersion().then(resp => {
      if (resp.status == 200) {
        this.setState({
          coreVersion: resp.data
        });
      }
    });

    getIdentityVersion().then(resp => {
      if (resp.status == 200){
        this.setState({
          identityVersion: resp.data
        });
      }
    });
    
    this.setState({
      isLoading: false
    });
  }

  render() {
    return (
      <Modal classNames={{ modal: styles.modal }} open={this.props.open} onClose={this.props.onCloseModal} center>
        <p className={styles.heading}>Platform Version</p>
        <hr />
        <Row className={styles.modalBody}>

          {
            this.state.isLoading ? <RotatingLoader loading={true} text='Loading version details...' /> : <Col>
              <p>UI: {buildNumber} </p>
              <p>Core: {this.state.coreVersion} </p>
              <p>Identity: {this.state.identityVersion} </p>
              <p>Integrations: {this.state.integrationsVersion} </p>
            </Col>
          }

        </Row>
      </Modal>
    );
  }
}

export default PlatformVersionModal;