import React from "react";
import SFDropdown from "..";
import { withRouter } from "react-router";
import styles from "./SFAssetDropdown.module.scss";
import { goToRoute } from "../../../../history";
import { addURLParameter } from "../../../../helpers/locationHelpers";
import { toggleAddDocumentPopup, toggleDeleteInspectionPopup, toggleIssuePopup } from "../../../../helpers/sidebarHelpers";
import { IDocumentUploadOptions } from "../../../molecules/AddDocumentModal";
import { IRaiseIssueOptions } from "../../AddEditIssue";
import { IAssetAddEdit, IAssetDto } from "../../../../types/Assets";

interface IProps {
  assetId: string;
  asset: IAssetDto;

  toggle?: JSX.Element;
  history: any;
  location: any;
  match: any;

  updateRedirectUrl?: string;
  onDelete(): any;
  onEdit(assetId: string): any;
}

function onDeleteRows(
  unitIds: string[],
  location: any,
  history: any,
  deleteAssetRedirectUrl?: string
): void {
  let test = JSON.stringify(unitIds);

  let q = "deleteAsset=" + test;

  if (deleteAssetRedirectUrl) {
    q += "&deleteAssetRedirectUrl=" + deleteAssetRedirectUrl;
  }

  if (location != null && location != undefined) {
    let query = addURLParameter(
      location.search,
      q
    );

    history.push({
      search: query
    });
  }

  toggleDeleteInspectionPopup(true);
}

class SFAssetDropdown extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  onAddDocument = (assetId: number) => {
    const { asset } = this.props;
    let options: IDocumentUploadOptions = {
      assetId
    }

    if (asset.propertyId) {
      options.propertyId = asset.propertyId;
      options.propertyName = asset.propertyName;
    }

    if (asset.unitId) {
      options.unitId = asset.unitId;
      options.unitName = asset.unitName;
    }

    let query = "uploadDocumentOptions=" + JSON.stringify(options);

    let search1 = addURLParameter(this.props.location.search, query);

    this.props.history.push({
      search: search1
    });

    toggleAddDocumentPopup(true);
  }

  onAddIssue = (assetId: number) => {
    let options: IRaiseIssueOptions = {
      assetId
    }

    if (this.props.asset) {
      const { asset } = this.props;
      options.redirectUrl = `/dashboard/asset/view/${asset.assetId}/issues`;

      if (asset.propertyId) {
        options.propertyId = asset.propertyId;
        options.propertyName = asset.propertyName;
      }

      if (asset.unitId) {
        options.unitId = asset.unitId;
        options.unitName = asset.unitName;
      }
    }

    let stringed = "issueOptions=" + JSON.stringify(options);

    let query = addURLParameter(this.props.location.search, stringed);
    this.props.history.push({
      search: query
    });

    toggleIssuePopup(true);
  }

  render() {
    return (
      <SFDropdown
        loadedBeforeRender
        customToggle={this.props.toggle}
        customCssClassName={styles.syncfusionDropdown}
        onSelect={(args) => {
          if (args.item.text == 'Edit') {
            this.props.onEdit(this.props.assetId)
          }
          if (args.item.text == 'Go to Documents') {
            goToRoute(`/dashboard/asset/view/${this.props.assetId}/documents`)
          }
          if (args.item.text == 'Go to Issues') {
            goToRoute(`/dashboard/asset/view/${this.props.assetId}/issues`)
          }
          if (args.item.text == 'Delete') {
            this.props.onDelete();
          }
          if (args.item.text == 'Add Document') {
            this.onAddDocument(Number(this.props.assetId))
          }
          if (args.item.text == 'Add Issue') {
            this.onAddIssue(this.props.asset.assetId)
          }
        }}
        items={[
          {
            iconCss: styles.subIconEdit,
            text: "Edit"
          },
          {
            separator: true,
          },
          {
            iconCss: styles.subIconAddDocument,
            text: "Add Document"
          },
          {
            iconCss: styles.subIconAddIssue,
            text: "Add Issue"
          },
          {
            separator: true,
          },
          {
            iconCss: styles.subIconGoToDocuments,
            text: "Go to Documents"
          },
          {
            iconCss: styles.iconGoToIssues,
            text: "Go to Issues"
          },
          {
            iconCss: styles.subIconDelete,
            text: "Delete"
          }
        ]}
      />
    );
  }
}

export default withRouter(SFAssetDropdown);