import React from "react";
import crossIcon from "../../../images/icons/ic_clear_24px.png";

import styles from "./ErrorMessage.module.scss";
import CloseButton from "../CloseButton";

interface IProps {
  text?: string;
  onClose?(): void;
}

class ErrorMessage extends React.Component<IProps, {}> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div className={styles.error}>
        {
          this.props.onClose &&
          <CloseButton close={() => this.props.onClose()} />
        }
        <p>We're sorry, there's been a problem.</p>

        {
          this.props.text &&
          <p className={styles.description}>Error: {this.props.text}</p>
        }

      </div>
    );
  }
}

export default ErrorMessage;
