import { Dispatch } from 'redux';
import { ConductInspectionAction, ConductInspectionActionType } from '../types/ConductInspection';
import { getConductInspectionTemplatesFromApi, getInspectionResultFromApi, insertInspectionResultApi, updateInspectionResultApi } from '../services/ConductInspectionService';
import { IInspectionsResultDto } from '../types/Dynamic/types';
import { processToast } from '../helpers/toastHelper';
import { IDataResponse } from '../types/ApiService';
import { updateViewingInspectionHasResult } from '../helpers/inspectionHelpers';
import { InspectionAction, InspectionActionType } from '../reducers/inspectionReducer';

const timeout = () => {
    return new Promise(resolve => setTimeout(resolve, 2500));
}

export const setViewingInspection = (inspection: IInspectionsResultDto) => {
    return async (dispatch: Dispatch<ConductInspectionAction>) => {
        dispatch({ type: ConductInspectionActionType.SET_VIEWING_INSPECTION, payload: inspection })
    }
};

export const insertInspection = (inspection: IInspectionsResultDto) => {
    return async (dispatch: Dispatch<ConductInspectionAction | InspectionAction>) => {
        dispatch({ type: ConductInspectionActionType.SAVE_INSPECTION });

        try {
            const { data } = await insertInspectionResultApi(inspection);
            if (data && data.isSuccess) {
                processToast(data);
                dispatch({
                    type: ConductInspectionActionType.SET_VIEWING_INSPECTION,
                    payload: {
                        ...inspection,
                        inspectionResultId: data.data.inspectionResultId,
                        objectResult: JSON.parse(inspection.jsonResult)
                    }
                })

                dispatch({
                    type: InspectionActionType.UPDATE_INSPECTION_HAS_RESULT,
                    payload: true
                });

                return dispatch({
                    type: ConductInspectionActionType.SAVE_INSPECTION_SUCCESS
                });
            }

            return dispatch({
                type: ConductInspectionActionType.SAVE_INSPECTION_ERROR,
                payload: 'An error occurred when inserting inspection templates'
            })

        } catch (err) {
            dispatch({
                type: ConductInspectionActionType.SAVE_INSPECTION_ERROR,
                payload: err.message
            })
        }
    }
};

export const updateInspection = (inspection: IInspectionsResultDto) => {
    return async (dispatch: Dispatch<ConductInspectionAction | InspectionAction>) => {
        dispatch({ type: ConductInspectionActionType.SAVE_INSPECTION });

        try {
            const { data } = await updateInspectionResultApi(inspection);
            if (data && data.isSuccess) {
                processToast(data);
                dispatch({
                    type: ConductInspectionActionType.SET_VIEWING_INSPECTION,
                    payload: {
                        ...inspection,
                        objectResult: JSON.parse(inspection.jsonResult)
                    }
                });

                dispatch({
                    type: InspectionActionType.UPDATE_INSPECTION_HAS_RESULT,
                    payload: true
                });

                return dispatch({
                    type: ConductInspectionActionType.SAVE_INSPECTION_SUCCESS
                });
            }

            return dispatch({
                type: ConductInspectionActionType.SAVE_INSPECTION_ERROR,
                payload: 'An error occurred when updating inspection templates'
            })

        } catch (err) {
            dispatch({
                type: ConductInspectionActionType.SAVE_INSPECTION_ERROR,
                payload: err.message
            })
        }
    }
};

export const getInspectionFromInspectionId = (inspectionId: number, inspectionTypeId: string) => {
    return async (dispatch: Dispatch<ConductInspectionAction>) => {
        dispatch({ type: ConductInspectionActionType.GET_INSPECTION });

        if (!inspectionTypeId) {
            return dispatch({ type: ConductInspectionActionType.GET_INSPECTION_ERROR, payload: 'You must select an inspection type' });
        }

        try {
            const { data: { data } } = await getInspectionResultFromApi(inspectionId);
            // Get from API
            if (data) {
                const templateObject = JSON.parse(data.jsonResult);
                return dispatch({
                    type: ConductInspectionActionType.GET_INSPECTION_SUCCESS,
                    payload: {
                        ...data,
                        objectResult: templateObject
                    }
                });
            }

            return dispatch({
                type: ConductInspectionActionType.GET_CONDUCT_INSPECTION_TEMPLATES_ERROR,
                payload: 'An error occurred when fetching inspection templates'
            })

        } catch (err) {
            dispatch({
                type: ConductInspectionActionType.GET_INSPECTION_ERROR,
                payload: err.message
            })
        }
    }
};

export const getConductInspectionTemplates = () => {
    return async (dispatch: Dispatch<ConductInspectionAction>) => {
        dispatch({ type: ConductInspectionActionType.GET_CONDUCT_INSPECTION_TEMPLATES });

        try {
            const { data } = await getConductInspectionTemplatesFromApi();
            dispatch({
                type: ConductInspectionActionType.GET_CONDUCT_INSPECTION_TEMPLATES_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch({
                type: ConductInspectionActionType.GET_CONDUCT_INSPECTION_TEMPLATES_ERROR,
                payload: err.message
            })
        }
    }
};