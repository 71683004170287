import { IDocumentUpload } from "../../components/molecules/AddDocumentModal";

export interface IAddTransactionRequest {
    transactionId?: string;
    unitId?: string;
    paidDate: string;
    isExpense: boolean;
    isIncome: boolean;
    isPaidInFull: boolean;
    dueDate: string;
    invoiceDate?: string;
    accountId?: string;
    creditContactId?: string;
    debitContactId?: string;
    tenancyId?: string;
    propertyId?: any;
    creditAccountId?: string;
    debitAccountId?: string;
    reference: string;
    description: string;
    amount?: number;
    paidAmount?: number
    notes: string;
    documents?: Array<IDocumentUpload>;
    deleteDocuments?: Array<string>;
}

export interface ITransactionItem {
    transactionId: string;
    propertyId?: string;
    debitContactId?: string;
    creditContactId?: string;
    accountId?: string;
    unitId?: string;
    tenancyId?: string;

    description: string;
    notes: string;
    reference: string;

    isExpense?: boolean;
    isIncome?: boolean;

    propertyName: string;
    debitContactName?: string;
    creditContactName?: string;
    categoryName: string;
    accountName: string;
    unitName: string;

    dueDate?: any;
    paidDate?: any;
    dateAdded: any;
    invoiceDate?: any;

    // UI only fields
    invoiceMonthYear?: any;

    isPaid: boolean;
    isOverdue: boolean;
    isOpen: boolean;
    doesPropertyHaveUnits: boolean;

    amount?: number;
    paidAmount?: number;
}

export enum ExportTypeEnum {
    Excel,
    Csv,
    PDF
  }


  export interface IAutoGenerateTransactionsRequest
    {
        tenancyId: string;
        markPastAsPaid: boolean;
        startDate?: string;
        endDate?: string;
        type: string;
        debitContact?: string;
        creditContact?: string;
        property?: string;
        category?: string;
        unit: string;
        reference: string;
        amount: number;
        description?: string;
        notes?: string;
        frequency: string;
        dueDay: number;
        recurringTransaction?: boolean;
        isRollingContract: boolean;
        durationLength: number;
        durationPeriod: string;
        propertyName?: string;
        unitName?: string;
        debitContactName?: string;
    }