import { toastDisplayTimeInMs } from "../configuration/appConfig";
import { store } from "../store";
import { IDeleteResponse, IDataResponse } from "../types/ApiService";

export function updateToastMessage(
  toast_text: string,
  toast_type: string,
  toast_duration: number = toastDisplayTimeInMs
) {
  store.dispatch({
    type: "UPDATE_TOAST",
    toast_text,
    toast_type,
    toast_duration,
  });
}

export function processToast<T>(response: IDataResponse<T>): void {
  if(!response){
    updateToastMessage("We're sorry, there's been a problem performing your request.", "failure");
    return;
  }
  
  updateToastMessage(response.isSuccess ? response.message : response.errorMessage, response.isSuccess ? "success" : "failure");
}

export function toastDeleteResponseHandler(response: IDataResponse<IDeleteResponse>): void {
  if (response.message) {
    updateToastMessage(response.message, "success");
  }

  if (response.errorMessage) {
    updateToastMessage(response.errorMessage, "failure");
  }
}