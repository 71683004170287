import React from "react";
import { Col, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { deleteType, updateType } from "../../../services/EntityService";

import styles from "./EntityListItem.module.scss";
import deleteIcon from "../../../images/icons/property-action/Delete-inactive.png";
import editIcon from "../../../images/icons/property-action/Edit-inactive.png";
import moreIcon from "../../../images/icons/property-action/Action-inactive.png";
import { IEntityListItem } from "../../../types/EntityService";
import EditEntityGroupModal from "../../molecules/EditEntityGroupModal";
import MaterialCheckbox from "../MaterialCheckbox";
import GenericButton from "../GenericButton";
import SuccessTick from "../SuccessTick";

interface IProps {
  entity: IEntityListItem;
  onDefaultChange(entityGroupId: string, selectedId): void;
}

interface IWithRouterProps {
  history: any;
  location: any;
  match: any;
}

interface IState {
  editingGroup: boolean;
  editingEntity: boolean;

  entity: IEntityListItem;
  loading: boolean;
  success: boolean;
}

type Props = IProps & IWithRouterProps;

class EntityListItem extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      editingGroup: false,
      editingEntity: false,
      entity: this.props.entity,
      loading: false,
      success: false
    };

    this.onDelete = this.onDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onSave = this.onSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  onSave(): void {
    this.setState({
      loading: true
    });
    updateType(this.state.entity).then(resp => {
      if (resp && resp.status == 200) {
        this.setState({
          success: true,
          loading: false
        });
        setTimeout(() => {
          this.setState({
            editingEntity: false,
            success: false
          });
        }, 1000);
      }
    });
  }

  handleChange(event: React.FormEvent<HTMLInputElement>) {
    const field = event.currentTarget.name;
    const value: string = event.currentTarget.value;
    this.setState(prevState => ({
      entity: {
        ...prevState.entity,
        [field]: value
      }
    } as Pick<IState, any>));
  }

  onDelete(): void {
    deleteType(this.props.entity.entityTypeId);
  }

  onEdit(): void {
    this.setState({
      editingEntity: true
    });
  }

  render() {
    const { entity } = this.state;
    return (
      <Row className={styles.userItem}>
        {
          this.state.editingGroup &&
          <EditEntityGroupModal entityGroupId={this.props.entity.entityGroupId} open={this.state.editingGroup} onClose={() => this.setState({ editingGroup: false })} />
        }
        <Col>
          {
            this.state.editingEntity ? <div>
              <div className={styles.label}>Name</div>
              <input disabled={this.state.loading} className={styles.input} name="entityTypeName" value={entity.entityTypeName} onChange={this.handleChange} />
            </div>
              :
              <div onClick={() => this.onEdit()}>
                <div className={styles.label}>Name</div>
                {entity.entityTypeName}{" "}
                {entity.entityTypeValue && <span>({entity.entityTypeValue})</span>}
              </div>
          }
        </Col>
        <Col>
          {
            this.state.editingEntity ?
              <div>
                <div className={styles.label}>Description</div>
                <input disabled={this.state.loading} className={styles.input} name="entityTypeDescription" value={entity.entityTypeDescription} onChange={this.handleChange} />
              </div> :
              <div onClick={() => this.onEdit()}>
                <div className={styles.label}>Description</div>
                {entity.entityTypeDescription}
              </div>
          }
        </Col>
        <Col>
          <MaterialCheckbox checked={entity.isDefault} onChange={() => this.props.onDefaultChange(entity.entityGroupId, entity.entityTypeId)} labelText='Is Default?' />
        </Col>
        <Col onClick={() => this.setState({ editingGroup: true })}>
          <div className={styles.label}>Group</div>
          {entity.entityGroupName}
        </Col>
        {
          this.state.editingEntity && !this.state.loading &&
          <Col xs="2">
            <GenericButton border text='Save' onClick={this.onSave} />
          </Col>
        }
        {
          this.state.success &&
          <div><SuccessTick /></div>
        }
        <Col className={styles.icons}>
          <img src={deleteIcon} onClick={() => this.onDelete()} />{" "}
          <img src={editIcon} /> <img src={moreIcon} />
        </Col>
      </Row>
    );
  }
}

export default withRouter(EntityListItem);