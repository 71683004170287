import {
    Browser,
    createElement,
    detach,
    EventHandler,
    isNullOrUndefined,
} from '@syncfusion/ej2-base'
import '@syncfusion/ej2-base/styles/material.css'
import '@syncfusion/ej2-buttons/styles/material.css'
import {
    FileInfo,
    RemovingEventArgs,
    SelectedEventArgs,
    UploaderComponent,
    UploadingEventArgs,
} from '@syncfusion/ej2-react-inputs'
import '@syncfusion/ej2-react-inputs/styles/material.css'
import {
    createSpinner,
    hideSpinner,
    showSpinner,
} from '@syncfusion/ej2-react-popups'
import React, { useEffect } from 'react'
import { ImageExtensionsArray } from '../../../configuration/uploader/allowed-extentions.config'
import { createBearerToken } from '../../../helpers/authHelpers'
import { ellipsis } from '../../../helpers/formattingHelpers'
import { toQueryString } from '../../../services/DocumentService'
import { ISFUploadUrls, IUploadRequest } from '../../../types/ImageService'
import Buttons from '../Buttons'
import styles from './SFUploader.module.scss'

interface IProps {
    urls?: ISFUploadUrls
    options: IUploadRequest
    customOptions?: any
    onFileUpload?(imageId?: string): void
    successCallback?: any
    close?(): void
    hideClose?: boolean
    callbackOnly?: boolean
    uploadFileLimit?: number
    onAdd?(file: FileInfo): void
    onRemove?(file: FileInfo): void
    children?: any
    allowedExtensions?: string
    onFilesSelectComplete?(imagesCount?: number): void
}

function SFUploader(props: IProps) {
    const uploadObj = React.useRef<UploaderComponent>(null)

    let queryParams
    if (!props.callbackOnly && props.options) {
        let uploadRequest: IUploadRequest = {
            imageType: props.options.imageType,
        }
        const { propertyId, unitId, contactId, inspectionId, parentId } =
            props.options
        if (propertyId) {
            uploadRequest.propertyId = propertyId
        }

        if (unitId) {
            uploadRequest.unitId = unitId
        }

        if (contactId) {
            uploadRequest.contactId = contactId
        }

        if (inspectionId) {
            uploadRequest.inspectionId = inspectionId
        }

        if (parentId) {
            uploadRequest.parentId = parentId
        }

        queryParams = toQueryString(uploadRequest)
    }

    let customOptions
    if (props.customOptions) {
        customOptions = toQueryString(props.customOptions)
    }

    let filesDetails: FileInfo[] = []
    let dropElement: HTMLElement
    let filesList: HTMLElement[] = []
    let filesName: string[] = []
    let parentElement: HTMLElement
    let dropArea: HTMLElement
    let dropContainerEle: HTMLElement
    let dropAreaEle: HTMLElement
    let dropImageEle: HTMLElement

    let dropContainerRef = (element) => {
        dropContainerEle = element
    }

    let dropAreaRef = (element) => {
        dropAreaEle = element
    }

    let dropImageRef = (element) => {
        dropImageEle = element
    }

    let asyncSettings: object = {
        removeUrl: props.urls && props.urls.removeUrl,
        saveUrl: `${props.urls && props.urls.saveUrl}${
            queryParams
                ? `?${queryParams}`
                : customOptions
                ? `?${customOptions}`
                : ''
        }`,
    }

    const onUpload = (args: UploadingEventArgs) => {
        //Add in auth header
        args.currentRequest.setRequestHeader(
            'Authorization',
            createBearerToken()
        )
    }

    const onClickBrowse = () => {
        uploadObj.current.element.setAttribute('name', 'UploadFiles')
        document
            .getElementsByClassName(styles.upload)[0]
            .getElementsByClassName(`e-file-select-wrap`)[0]
            .querySelector('button')
            .click()
    }

    const renderComplete = () => {
        dropArea = dropAreaEle
        dropElement = dropContainerEle
        if (Browser.isDevice) {
            dropImageEle.style.padding = '0px 10%'
        }
        uploadObj.current.dropArea = dropElement
        uploadObj.current.dataBind()
    }

    const onSelect = (args: SelectedEventArgs) => {
        dropArea = dropAreaEle

        if (isNullOrUndefined(dropArea.querySelector(`e-upload-files`))) {
            parentElement = createElement('ul', { className: `e-upload-files` })
            document
                .getElementsByClassName('e-upload')[0]
                .appendChild(parentElement)
        }

        let validFiles: FileInfo[] = validateFiles(args, filesDetails)
        if (validFiles.length === 0) {
            args.cancel = true
            return
        }
        for (let i: number = 0; i < validFiles.length; i++) {
            formSelectedData(validFiles[i])
        }
        filesDetails = filesDetails.concat(validFiles)
        args.cancel = true

        if (props.onFilesSelectComplete != undefined) {
            props.onFilesSelectComplete(validFiles.length)
        }
    }

    const validateFiles = (args: any, viewedFiles: FileInfo[]) => {
        let modifiedFiles: FileInfo[] = []
        let validFiles: FileInfo[] = []
        let isModified: boolean = false
        if (args.event.type === 'drop' || args.event.type === 'change') {
            isModified = true
            let allImages: string[] = ImageExtensionsArray
            let files: FileInfo[] = args.filesData
            for (let file of files) {
                if (allImages.indexOf(file.type.toLowerCase()) !== -1) {
                    modifiedFiles.push(file)
                }
            }
        }
        let files: FileInfo[] =
            modifiedFiles.length > 0 || isModified
                ? modifiedFiles
                : args.filesData
        if (filesName.length > 0) {
            for (let file of files) {
                if (filesName.indexOf((file.rawFile as any).name) === -1) {
                    filesName.push((file.rawFile as any).name)
                    validFiles.push(file)
                }
            }
        } else {
            for (let file of files) {
                filesName.push(file.name)
                validFiles.push(file)
            }
        }
        return validFiles
    }

    const formSelectedData = (file: FileInfo) => {
        let liEle: HTMLElement = createElement('li', {
            className: 'e-upload-file-list',
            attrs: { 'data-file-name': (file.rawFile as any).name },
        })
        let imageTag: HTMLElement = createElement('div', {
            className: 'upload-image',
            attrs: { alt: 'Image' },
        }) as HTMLElement
        let wrapper: HTMLElement = createElement('span', {
            className: 'wrapper',
        })
        wrapper.appendChild(imageTag)
        liEle.appendChild(wrapper)
        liEle.appendChild(
            createElement('div', {
                className: 'file-name',
                innerHTML: `${ellipsis(
                    file.name,
                    5
                )} - ${uploadObj.current.bytesToSize(file.size)}`,
                attrs: { title: file.name },
            })
        )
        liEle.appendChild(
            createElement('div', {
                className: 'file-status',
                innerHTML: file.status,
            })
        )
        let clearbtn: HTMLElement
        clearbtn = createElement('span', {
            id: 'removeIcon',
            className: 'e-icons e-file-remove-btn',
            attrs: { title: 'Remove' },
        })
        EventHandler.add(clearbtn, 'click', removeFiles)
        liEle.setAttribute('title', 'Ready to Upload')
        let progressbarContainer: HTMLElement
        progressbarContainer = createElement('progress', {
            className: 'progressbar',
            id: 'progressBar',
            attrs: { value: '0', max: '100' },
        })
        liEle.appendChild(clearbtn)
        liEle.appendChild(progressbarContainer)
        readURL(liEle, file)
        document.querySelector('.e-upload-files').appendChild(liEle)
        filesList.push(liEle)

        // If the files just need to be returned to the calling component
        // then do that here
        if (props.callbackOnly) {
            props.onAdd(file)
        } else {
            uploadSpecificFiles(file)
        }
    }

    const uploadSpecificFiles = (files: FileInfo | Array<FileInfo>) =>
        uploadObj.current.upload(files, true)

    //uploadObj.current.

    const removeFiles = (args: any) => {
        let removeFile: FileInfo =
            filesDetails[filesList.indexOf(args.currentTarget.parentElement)]
        let index: number = filesList.indexOf(args.currentTarget.parentElement)
        let file = filesList[index]
        let statusCode: string = removeFile.statusCode
        if (statusCode === '2' || statusCode === '1') {
            let imageId = file.attributes.getNamedItem('data-image-id')
            if (imageId) {
                uploadObj.current.remove(
                    { ...removeFile, name: imageId.value },
                    true
                )
                uploadObj.current.element.value = ''
            }
        }

        if (props.onRemove) {
            props.onRemove(removeFile)
        }

        filesList.splice(index, 1)
        filesDetails.splice(index, 1)
        filesName.splice(filesName.indexOf(removeFile.name), 1)
        if (statusCode !== '2') {
            detach(args.currentTarget.parentElement)
        }
    }

    const onFileUpload = (args: any) => {
        if (dropArea != undefined) {
            let li: Element = dropArea.querySelector(
                '[data-file-name="' + args.file.rawFile.name + '"]'
            )

            let removeEle: HTMLElement = li.querySelector(
                '#removeIcon'
            ) as HTMLElement
            if (removeEle) {
                removeEle.style.visibility = 'hidden'
            }

            EventHandler.remove(
                li.querySelector('#removeIcon'),
                'click',
                removeFiles
            )

            let progressValue: number = Math.round(
                (args.e.loaded / args.e.total) * 100
            )
            if (!isNaN(progressValue) && li.querySelector('.progressbar')) {
                li.getElementsByTagName('progress')[0].value = progressValue
            }
        }
    }

    const onUploadSuccess = (args: any) => {
        dropArea = dropAreaEle
        let spinnerElement: HTMLElement = document.getElementById('dropArea')
        if (!dropArea) {
            return
        }
        let li: HTMLElement = dropArea.querySelector(
            '[data-file-name="' + args.file.rawFile.name + '"]'
        )
        if (props.successCallback) {
            props.successCallback(args.e.target.response)
        }

        if (li && !isNullOrUndefined(li.querySelector('.progressbar'))) {
            ;(
                li.querySelector('.progressbar') as HTMLElement
            ).style.visibility = 'hidden'
        }
        if (li && !isNullOrUndefined(li.querySelector('.file-status'))) {
            ;(li.querySelector('.file-status') as HTMLElement).innerHTML =
                'Uploaded'
        }
        if (args.operation === 'upload') {
            ;(li.querySelector('.file-name') as HTMLElement).style.color =
                'green'
            ;(li.querySelector('.e-icons') as HTMLElement).onclick = () => {
                generateSpinner(dropArea)
            }
        } else {
            if (li) {
                detach(li)
            }
            hideSpinner(spinnerElement)
            detach(spinnerElement.querySelector('.e-spinner-pane'))
        }

        if (!isNullOrUndefined(li)) {
            li.setAttribute('title', args.statusText)
            li.setAttribute('data-image-id', args.e.currentTarget.response)
            let index: number = filesList.indexOf(args.file)
            let removeEle: HTMLElement = li.querySelector(
                '#removeIcon'
            ) as HTMLElement
            if (removeEle) {
                EventHandler.add(removeEle, 'click', removeFiles)
                removeEle.style.visibility = 'visible'
            }
        }

        if (props.onFileUpload) {
            props.onFileUpload(args.e.currentTarget.response)
        }
    }

    const generateSpinner = (targetElement: HTMLElement) => {
        createSpinner({ target: targetElement, width: '25px' })
        showSpinner(targetElement)
    }

    const onUploadFailed = (args: any) => {
        if (dropArea != undefined) {
            let li: Element = dropArea.querySelector(
                '[data-file-name="' + args.file.rawFile.name + '"]'
            )
            ;(li.querySelector('.file-name') as HTMLElement).style.color = 'red'
            li.setAttribute('title', args.e.currentTarget.statusText)
            if (args.operation === 'upload') {
                ;(
                    li.querySelector('.progressbar') as HTMLElement
                ).style.visibility = 'hidden'
            }
        }
    }

    const readURL = (li: HTMLElement, args: any) => {
        let preview: HTMLElement = li.querySelector('.upload-image')
        let file: File = args.rawFile
        let reader: FileReader = new FileReader()

        //reader.addEventListener('load', () => { preview.src = reader.result; }, false);
        reader.addEventListener(
            'load',
            () => {
                preview.style.backgroundImage = `url("${String(
                    reader.result
                )}")`
            },
            false
        )
        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const onRemoveFile = (args: RemovingEventArgs) => {
        args.postRawFile = true
    }

    useEffect(() => {
        renderComplete()
    }, [])

    return (
        <div className={styles.upload}>
            <div className={`control-pane`} ref={dropContainerRef}>
                <div
                    className={`${styles.controlSection} ${styles.row}`}
                    id="uploadpreview"
                >
                    <div className={styles.column}>
                        <div className="imagepreview">
                            <div
                                id="dropArea"
                                ref={dropAreaRef}
                                className="dropTarget"
                            >
                                <span
                                    id="dropimage"
                                    ref={dropImageRef}
                                    className="file-name-drop"
                                >
                                    {props.children ? (
                                        <div onClick={onClickBrowse}>
                                            {props.children}
                                        </div>
                                    ) : (
                                        <div>
                                            Drop image files here or{' '}
                                            <span
                                                onClick={onClickBrowse}
                                                id="browse"
                                            >
                                                <u className={styles.browse}>
                                                    Browse
                                                </u>
                                            </span>{' '}
                                        </div>
                                    )}
                                </span>
                                <UploaderComponent
                                    multiple={
                                        props.uploadFileLimit > 1 ||
                                        !props.uploadFileLimit
                                    }
                                    id="previewfileupload"
                                    type="file"
                                    ref={uploadObj}
                                    asyncSettings={asyncSettings}
                                    success={onUploadSuccess}
                                    selected={onSelect}
                                    removing={onRemoveFile}
                                    progress={onFileUpload}
                                    failure={onUploadFailed}
                                    uploading={onUpload}
                                    allowedExtensions={props.allowedExtensions}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {!props.hideClose && props.close && (
                    <div className={styles.buttonRow}>
                        <div className={styles.buttonColumn}>
                            <Buttons
                                buttons={[
                                    {
                                        displayType: 'cancel',
                                        elementType: 'button',
                                        onClick: () => props.close(),
                                    },
                                ]}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SFUploader
