import styled from 'styled-components';

export const SectionDiv = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 100%; 
    grid-template-areas:
        'header'
        'body'
`; 

export const bodyDiv = styled.div`
    background-color: #F5F5F5;
    border-radius: 20px;
    padding-top: 10px;
`;

export const bodyContentDiv = styled.div`
    margin: auto;
    width: 94%;
    grid-area: body;
    display: grid;
    grid-template-columns: 50% 50%; 
    grid-template-rows: 33px 33px 33px;
    grid-template-areas:
        'getValuation setupRooms'
        'regulationInfo createTenancy'
        'addDocs addAssets';
    align-items: center;
    justify-items: start;
`;

export const getValuationDiv = styled.div`
    grid-area: getValuation;
`;

export const setupRoomsDiv = styled.div`
    grid-area: setupRooms;
`;

export const regulationInfoDiv = styled.div`
    grid-area: regulationInfo;
`;

export const createTenancyDiv = styled.div`
    grid-area: createTenancy;
`;

export const addDocsDiv = styled.div`
    grid-area: addDocs;
`;

export const addAssetsDiv = styled.div`
    grid-area: addAssets;
`;


