import { apiService } from '../services/ApiService';
import { createBearerToken } from '../helpers/authHelpers';
import * as domains from '../configuration/domains';


export const sendEmailsTest = (): Promise<any> => {
    return apiService.get(`${domains.notificationService}`,  {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}