import React from "react";

import styles from "./MaterialCheckbox.module.scss";

interface IProps {
  checked: boolean;
  labelText?: string;
  onChange(): void;
}

class MaterialCheckbox extends React.Component<IProps> {

  onClickLabel(e: any): void {
    e.stopPropagation()
  }

  onClick(e: any): void {
    e.preventDefault();
    e.stopPropagation();
  }

  render() {
    return (
      <div>
        <label className={styles.pureMaterialCheckbox} onClick={this.props.onChange}>
          <input
            type="checkbox"
            checked={this.props.checked}
            onClick={this.onClick}
            readOnly={true}
          />
          {this.props.labelText && <span>{this.props.labelText}</span>}
        </label>
      </div>
    );
  }
}

export default MaterialCheckbox;
