interface IAppState {
  app_loading: boolean;
  is_preview: boolean;
  is_impersonating: boolean;
  is_sticky_nav: boolean;
  overlay_show: boolean;
  sidebars: ISidebarState;
  popups: IPopupState;
  websocketHandlers: Array<IWSHandler>;
  is_tab_active: boolean;
  form_states: IFormState;
  show_footer: boolean;
  input_focussed: boolean;
  chat_area_height: number
}

export interface IFormState {
  add_property_form_changed: boolean;
  add_tenancy_form_changed: boolean;
  add_transaction_form_changed: boolean;
  add_contact_form_changed: boolean;
  add_document_form_changed: boolean;
  add_issue_form_changed: boolean;
}

export interface IWSHandler {
  handlerName: string;
  registerdOn: Date;
}

interface IPopupState {
  welcome_popup: boolean;
  edit_document_popup: boolean;
  add_document_popup: boolean;
  edit_regulation_popup: boolean;
  tenancy_popup: boolean;
  issue_popup: boolean;
  transaction_popup: boolean;
  transaction_delete_popup: boolean;
  tenancy_delete_popup: boolean;
  unit_delete_popup: boolean;
  user_delete_popup: boolean;
  document_delete_popup: boolean;
  start_chat_popup: boolean;
  inspection_delete_popup: boolean;
  asset_delete_popup: boolean;
}

export interface ISidebarState {
  add_property: boolean;
  side_nav: boolean;
  mobile_side_nav: boolean;
  profile: boolean;
  filter: boolean;
  upload_property_image: boolean;
  add_user: boolean;
  edit_property: IEditProperty;
  add_document: boolean;
  add_client: boolean;
  tenancy_action: boolean;
  add_contact: boolean;
  assign_role: boolean;
  issue_action: boolean;
  issue_list_collapsed: boolean;
  user_list_collapsed: boolean;
  chat_list_collapsed: boolean;
  detailed_side_navigation: boolean;
}

interface IEditProperty {
  show: boolean;
  refresh_required: boolean;
}

const initialState = {
  app_loading: false,
  is_preview: false,
  is_impersonating: false,
  is_sticky_nav: false,
  overlay_show: false,
  websocketHandlers: [],
  is_tab_active: false,
  show_footer: true,
  input_focussed: false,
  chat_area_height: 0,
  sidebars: {
    assign_role: false,
    contact_action: false,
    tenancy_action: false,
    add_property: false,
    add_contact: false,
    add_client: false,
    side_nav: false,
    mobile_side_nav: false,
    profile: false,
    filter: false,
    upload_property_image: false,
    add_user: false,
    add_document: false,
    issue_action: false,
    issue_list_collapsed: false,
    user_list_collapsed: false,
    chat_list_collapsed: false,
    detailed_side_navigation: false,
    edit_property: {
      show: false,
      refresh_required: false,
    },
  },
  popups: {
    welcome_popup: false,
    edit_document_popup: false,
    add_document_popup: false,
    edit_regulation_popup: false,
    tenancy_popup: false,
    issue_popup: false,
    transaction_popup: false,
    transaction_delete_popup: false,
    unit_delete_popup: false,
    tenancy_delete_popup: false,
    user_delete_popup: false,
    start_chat_popup: false,
    inspection_delete_popup: false,
    document_delete_popup: false,
    asset_delete_popup: false
  },
  form_states: {
    add_property_form_changed: false,
    add_tenancy_form_changed: false,
    add_transaction_form_changed: false,
    add_contact_form_changed: false,
    add_document_form_changed: false,
    add_issue_form_changed: false
  }
};

export default (state: IAppState = initialState, action: any): IAppState => {
  switch (action.type) {
    case "TOGGLE_ADD_ISSUE_FORM_CHANGED":
      return {
        ...state,
        form_states: {
          ...state.form_states,
          add_issue_form_changed: action.data
        }
      };


    case "TOGGLE_ADD_DOCUMENT_FORM_CHANGED":
      return {
        ...state,
        form_states: {
          ...state.form_states,
          add_document_form_changed: action.data
        }
      };

    case "TOGGLE_ADD_CONTACT_FORM_CHANGED":
      return {
        ...state,
        form_states: {
          ...state.form_states,
          add_contact_form_changed: action.data
        }
      };

    case "TOGGLE_ADD_TRANSACTION_FORM_CHANGED":
      return {
        ...state,
        form_states: {
          ...state.form_states,
          add_transaction_form_changed: action.data
        }
      };

    case "TOGGLE_ADD_TENANCY_FORM_CHANGED":
      return {
        ...state,
        form_states: {
          ...state.form_states,
          add_tenancy_form_changed: action.data
        }
      };

    case "TOGGLE_ADD_PROPERTY_FORM_CHANGED":
      return {
        ...state,
        form_states: {
          ...state.form_states,
          add_property_form_changed: action.data
        }
      };

    case "TOGGLE_TAB_ACTIVE":
      return {
        ...state,
        is_tab_active: action.data
      };

    case "TOGGLE_STICKY_NAV":
      return {
        ...state,
        is_sticky_nav: action.data,
      };

    case "APP_LOADING_TRUE":
      return {
        ...state,
        app_loading: true,
      };

    case "APP_LOADING_FALSE":
      return {
        ...state,
        app_loading: false,
      };

    case "APP_IS_PREVIEW_TRUE":
      return {
        ...state,
        is_preview: true,
      };

    case "APP_IS_PREVIEW_FALSE":
      return {
        ...state,
        is_preview: false,
      };

    case "APP_IS_IMPERSONATING_TRUE":
      return {
        ...state,
        is_impersonating: true,
      };

    case "APP_IS_IMPERSONATING_FALSE":
      return {
        ...state,
        is_impersonating: false,
      };

    case "Websockets/Update Websocket Handlers":
      return {
        ...state,
        websocketHandlers: action.data
      };

    case "APP_OVERLAY":
      return {
        ...state,
        overlay_show: action.show,
      };

    case "APP_TOGGLE_SIDEBAR":
      return {
        ...state,
        sidebars: {
          ...state.sidebars,
          side_nav: action.show,
        },
      };

    case "APP_TOGGLE_MOBILE_SIDEBAR":
      return {
        ...state,
        sidebars: {
          ...state.sidebars,
          mobile_side_nav: action.show,
        },
      };

    case "APP_TOGGLE_ADD_PROPERTY":
      return {
        ...state,
        sidebars: {
          ...state.sidebars,
          add_property: action.show,
        },
      };

    case "APP_TOGGLE_PROFILE_SIDEBAR":
      return {
        ...state,
        sidebars: {
          ...state.sidebars,
          profile: action.show,
        },
      };

    case "APP_TOGGLE_FILTER_SIDEBAR":
      return {
        ...state,
        sidebars: {
          ...state.sidebars,
          filter: action.show,
        },
      };

    case "APP_TOGGLE_UPLOAD_PROPERTY_IMAGE":
      return {
        ...state,
        sidebars: {
          ...state.sidebars,
          upload_property_image: action.show,
        },
      };

    case "APP_TOGGLE_ADD_USER":
      return {
        ...state,
        sidebars: {
          ...state.sidebars,
          add_user: action.show,
        },
      };

    case "APP_TOGGLE_EDIT_PROPERTY":
      return {
        ...state,
        sidebars: {
          ...state.sidebars,
          edit_property: {
            ...state.sidebars.edit_property,
            show: action.show,
          },
        },
      };

    case "APP_TOGGLE_ADD_DOCUMENT":
      return {
        ...state,
        sidebars: {
          ...state.sidebars,
          add_document: action.show,
        },
      };

    case "APP_TOGGLE_ADD_CLIENT":
      return {
        ...state,
        sidebars: {
          ...state.sidebars,
          add_client: action.show,
        },
      };

    case "APP_SAVE_CHANGES_EDIT_PROPERTY":
      return {
        ...state,
        sidebars: {
          ...state.sidebars,
          edit_property: {
            ...state.sidebars.edit_property,
            refresh_required: action.refreshRequired,
          },
        },
      };

    case "APP_TOGGLE_TENANCY_ACTION_SIDEBAR":
      return {
        ...state,
        sidebars: {
          ...state.sidebars,
          tenancy_action: action.show,
        },
      };

    case "APP_TOGGLE_ISSUE_ACTION_SIDEBAR":
      return {
        ...state,
        sidebars: {
          ...state.sidebars,
          issue_action: action.show,
        },
      };

    case "APP_TOGGLE_ADD_CONTACT":
      return {
        ...state,
        sidebars: {
          ...state.sidebars,
          add_contact: action.show,
        },
      };

    case "APP_TOGGLE_ASSIGN_ROLE":
      return {
        ...state,
        sidebars: {
          ...state.sidebars,
          assign_role: action.show,
        },
      };

    case "APP_TOGGLE_WELCOME_POPUP":
      return {
        ...state,
        popups: {
          ...state.popups,
          welcome_popup: action.data,
        },
      };

    case "APP_TOGGLE_EDIT_DOCUMENT_POPUP":
      return {
        ...state,
        popups: {
          ...state.popups,
          edit_document_popup: action.data,
        },
      };

    case "APP_TOGGLE_ADD_DOCUMENT_POPUP":
      return {
        ...state,
        popups: {
          ...state.popups,
          add_document_popup: action.data,
        },
      };

    case "APP_TOGGLE_EDIT_REGULATION_POPUP":
      return {
        ...state,
        popups: {
          ...state.popups,
          edit_regulation_popup: action.data,
        },
      };

    case "APP_TOGGLE_TENANCY_POPUP":
      return {
        ...state,
        popups: {
          ...state.popups,
          tenancy_popup: action.data,
        },
      };

    case "APP_TOGGLE_ISSUE_POPUP":
      return {
        ...state,
        popups: {
          ...state.popups,
          issue_popup: action.data,
        },
      };

    case "APP_TOGGLE_TRANSACTION_POPUP":
      return {
        ...state,
        popups: {
          ...state.popups,
          transaction_popup: action.data,
        },
      };

    case "APP_TOGGLE_TENANCY_DELETE_POPUP":
      return {
        ...state,
        popups: {
          ...state.popups,
          tenancy_delete_popup: action.data,
        },
      };

    case "APP_TOGGLE_DOCUMENT_DELETE_POPUP":
      return {
        ...state,
        popups: {
          ...state.popups,
          document_delete_popup: action.data,
        },
      };

    case "APP_TOGGLE_INSPECTION_DELETE_POPUP":
      return {
        ...state,
        popups: {
          ...state.popups,
          inspection_delete_popup: action.data,
        },
      };

    case "APP_TOGGLE_TRANSACTION_DELETE_POPUP":
      return {
        ...state,
        popups: {
          ...state.popups,
          transaction_delete_popup: action.data,
        },
      };

    case "APP_TOGGLE_UNIT_DELETE_POPUP":
      return {
        ...state,
        popups: {
          ...state.popups,
          unit_delete_popup: action.data,
        },
      };

    case "APP_TOGGLE_USER_DELETE_POPUP":
      return {
        ...state,
        popups: {
          ...state.popups,
          user_delete_popup: action.data,
        },
      };

    case "APP_TOGGLE_ISSUE_LIST_COLLAPSED":
      return {
        ...state,
        sidebars: {
          ...state.sidebars,
          issue_list_collapsed: action.data,
        },
      };

    case "APP_TOGGLE_USER_LIST_COLLAPSED":
      return {
        ...state,
        sidebars: {
          ...state.sidebars,
          user_list_collapsed: action.data,
        },
      };

    case "APP_TOGGLE_CHAT_LIST_COLLAPSED":
      return {
        ...state,
        sidebars: {
          ...state.sidebars,
          chat_list_collapsed: action.data,
        },
      };

    case "APP_TOGGLE_START_CHAT_POPUP":
      return {
        ...state,
        popups: {
          ...state.popups,
          start_chat_popup: action.data,
        },
      };

    case "APP_TOGGLE_DETAILED_SIDE_NAVIGATION":
      return {
        ...state,
        sidebars: {
          ...state.sidebars,
          detailed_side_navigation: action.data,
        },
      };

    case "APP_TOGGLE_FOOTER":
      return {
        ...state,
        show_footer: action.data,
      };

    case "APP_INPUT_FOCUSSED":
      return {
        ...state,
        input_focussed: action.data,
      };

    case "APP_CHAT_AREA_HEIGHT":
      return {
        ...state,
        chat_area_height: action.data,
      };

    default:
      return state;
  }
};
