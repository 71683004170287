// Import React
import React, { Component } from "react";
import { Row, Col } from "reactstrap";

import styles from "./PropertyFinancialsPage.module.scss";
import { IAddress } from "../../../../types/PropertyService/IPropertyService";
import { IPropertyDetail } from "../../../../types/PropertyDetailService";
import { connect } from "react-redux";
import { ICalculateReturnsData } from "../../../../reducers/propertyReducer";
import { withRouter } from "react-router-dom";
import TransactionsGridContainer from "../../FinancialsPage/TransactionsGridContainer/TransactionsGridContainer.component";

interface IProps {
  match: any;
  history: any;
  location: any;
}

interface IReduxProps {
  data: ICalculateReturnsData;
}

interface IState {
  address: IAddress;
  propertyData: IPropertyDetail;
  moreInformation: boolean;
}

type Props = IProps & IReduxProps;

class PropertyFinancialsPage extends Component<Props, IState> {
  constructor(prop: any) {
    super(prop);

    this.state = {
      address: this.props.data.address,
      propertyData: null,
      moreInformation: false,
    };

    this.toggleMoreInformation = this.toggleMoreInformation.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  toggleMoreInformation(show: boolean): void {
    this.setState({
      moreInformation: show,
    });
  }

  render() {
    return (
      <Row className={styles.container}>
        <Col className={styles.routedPage}>
          <div className={styles.content}>
            <div className={styles.nestedPage}>
              <TransactionsGridContainer context="property" />
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: any) => ({
  data: state.property.property_data.property_data,
});

export default connect(
  mapStateToProps,
  null
)(withRouter(PropertyFinancialsPage)) as any;
