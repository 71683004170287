import React from "react";
import { DropDownButtonComponent, MenuEventArgs, ItemModel } from "@syncfusion/ej2-react-splitbuttons";
import styles from "./SFDropdown.module.scss";
import DropdownButton from "../DropdownButton";

interface IProps {
  /** Uses the MenuEventArgs to use the selected dropdown row item. */
  onSelect(args: MenuEventArgs): void;
  /** Rows that are displayed in the dropdown. */
  items: Array<ItemModel>;
  /** Override the default toggle by adding a custom component */
  customToggle?: JSX.Element;
  /** Add in custom styling */
  customCssClassName?: string;

  loadedBeforeRender?: boolean;
}

interface IState {
  loaded: boolean;
  showDropdown: boolean;
}

class SFDropdown extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      loaded: false,
      showDropdown: false
    }
  }

  componentDidMount(): void {
    if (!this.state.loaded) {
      this.setState({
        loaded: true
      });
    }
  }

  componentWillUnmount(): void {
    if (this.state.loaded) {
      this.setState({
        loaded: false
      });
    }
  }

  componentWillUpdate(prevProps, prevState): void {
    if (prevState.loaded != this.state.loaded) {
      this.setState({
        showDropdown: this.state.loaded
      });
    }
  }

  render() {
    const { customCssClassName, customToggle, items, onSelect } = this.props;

    if (!this.state.loaded) {
      return null;
    }

    return (
      <DropDownButtonComponent
        beforeItemRender={e => {
          if (!e.element.onclick) {
            e.element.onclick = (e) => e.preventDefault()
          }
        }}
        cssClass={`${styles.syncfusionDropdown} e-caret-hide ${customCssClassName != null ? customCssClassName : "no-class"}`}
        items={items}
        select={onSelect}
      >
        {customToggle != null ? customToggle : <DropdownButton />}
      </DropDownButtonComponent>
    );
  }
}

export default SFDropdown;