export const dashboardHomeSteps = [
  {
    selector: '[data-tour="getStarted"',
    content:
      "Welcome. Follow these steps to help you get the most from the software and help you simplify your property investments."
  },
  {
    selector: ".propertyNumber",
    content:
      "Get started adding your properties - add purchase info, regulation dates, units for an HMO, key documents and key assets at the property. Goodbye spreadsheets, hello freedom."
  },
  {
    selector: ".tasksSummary",
    content:
      "Keep track of key property dates and all actions related to your property with email reminders. Prioritise and categorise to help you work efficiently."
  },
  {
    selector: '[data-tour="threeTabsDashboard"',
    content:
      "Record issues related to your properties to help you track and resolve for the benefit of your tenants. Easily chat through resolution of issues with tenant and trade."
  },
  {
    selector: '[data-tour="research"',
    content:
      "Use our research tools to help you improve profitability on your next property investment. Analyse area price trends and use our deal calculator to calculate ROI."
  },
  {
    selector: '[data-tour="resetEdit"',
    content:
      "Edit the dashboard layout to show just the info you need. Click edit, drag the components around or close any you don’t need. Easily reset back to the default layout."
  },
  {
    selector: "#navProperties",
    content:
      "Select to view all your properties. You also can access units, assets, tenancies and issues from this menu."
  },
  {
    selector: "#navFinance",
    content:
      "The Money function helps you to track all income and expenses related to properties and contacts. Use this data to get detailed P&L info and generate your tax returns."
  },
  {
    selector: "#navContacts",
    content:
      "The contact function helps you to keep track of tenants, trades, agents and other landlords if you manage for others. Invite contacts to join the platform to chat and share data."
  },
  {
    selector: "#navChat",
    content:
      "The chat function helps you streamline communication with tenants, trades, agents and other landlords if you manage for others."
  },
  {
    selector: "#profile",
    content:
      "Update your profile here, keeping up to date will ensure effective communication between the platform, your tenants and trades."
  }
];

export const addPropertyFormSteps = [
  {
    selector: "#AddressFinder",
    content:
      "Enter a postcode to lookup your property or enter address manually via the link. We match with land registry to get latest sales info about your property."
  },
  {
    selector: "#friendlyPropertyName",
    content:
      "You can have a friendly name for each property, it defaults to part of the address but can be edited if required."
  },
  {
    selector: "#alreadyOwned",
    content:
      "Selecting yes will mark as 'Portfolio' property and give access to full property maintenance functions enabling you to record tenancies, transactions, etc."
  },
  {
    selector: "#alreadyOwned",
    content:
      "Selecting no will mark as 'Prospect' property and give you access to research functions to evaluate potential returns, record photos, docs and discuss with your partners and investors."
  },
  {
    selector: "#MonthlyRentalAmount",
    content:
      "Monthly Rent is calculated as 0.4% of the purchase price as a simple guide of potential rental income. This can be edited as required or you can obtain an automated valuation using the button below."
  },
  {
    selector: "#valuations",
    content:
      "Select either the price or rental valuation buttons to obtain a market estimate. A new dialogue will be displayed asking for further details to make this as accurate as possible."
  },
  {
    selector: "#Deposit",
    content:
      "Deposit is only displayed if there is a mortgage on the property. The deposit amount automatically calculates to assume a 25% deposit and therefore 75% mortgage amount. You can edit with a different deposit amount."
  },
  {
    selector: "#tenantInResidence",
    content:
      "Add high level information regarding the tenancy if a tenant is in place. Further details can be added later on the property details page."
  }
];