import React from "react";
import { ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-grids";
import { minWidthTablet } from "../../../../../global/variables/_variables";
import UserActiveStatus from "../components/UserActiveStatus";

function UserStatus(props) {
  return (<UserActiveStatus active={props.active} />)
}

export var DefaultTemplate = function (props: any) {
  return (
    <ColumnsDirective>
      <ColumnDirective
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        type="checkbox"
        width="50"
      />

      <ColumnDirective
        field="Action"
        textAlign="Left"
        width="120"
      />

      <ColumnDirective
        textAlign="Left"
        headerText="User"
        field="name"
        width="120"
      />

      <ColumnDirective
        textAlign="Left"
        field="email"
        headerText="Email"
        width="120"
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Telephone"
        field="contactNumber"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Role"
        field="role"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Client"
        field="client"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Team"
        field="teamName"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Active"
        field="active"
        template={UserStatus}
        width="80"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Last Login"
        field="lastLoggedIn"
        type="date"
        format={{ type: "date", format: "dd/MM/yy HH:mm:ss" }}
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Created Date"
        field="createdDate"
        type="date"
        format={{ type: "date", format: "dd/MM/yy HH:mm:ss" }}
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Email Confirmation"
        field="emailConfirmation"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />
    </ColumnsDirective>
  );
};