import { rcToken } from '../configuration/appConfig';
import { IPersistedToken } from '../types/Token';
import { rememberLoginDuration_days, rememberLoginDurationForSession_hours } from '../configuration/appConfig';
import { store } from '../store';

function ExpiryDateForLocalStorage() {
    let date = new Date();
    return new Date(date.setHours(date.getHours() + parseInt(rememberLoginDuration_days) * 24));
}

function ExpiryDateForSessionStorage() {
    let date = new Date()
    return new Date(date.setHours(date.getHours() + parseInt(rememberLoginDurationForSession_hours)));
}

export function AddTokenToLocalStorage(token: string, rememberLogin: boolean, refreshOriginalExpiry?: string) {
    let expiryDate: string = "";
    if(refreshOriginalExpiry){
        //console.log("AddTokenToLocalStorage (refreshOriginalExpiry): " + refreshOriginalExpiry);
        expiryDate = refreshOriginalExpiry;
    }else {
        expiryDate = rememberLogin ? ExpiryDateForLocalStorage().toString() : ExpiryDateForSessionStorage().toString();
    }

    let newToken: IPersistedToken = {
        access_token: token,
        expiry_date: expiryDate,
        storage_type: rememberLogin ? 'local' : 'session'
    }

    if(rememberLogin){
        localStorage.setItem(rcToken, JSON.stringify(newToken));
    }else{
        sessionStorage.setItem(rcToken, JSON.stringify(newToken));
    }
    //console.log("AddTokenToLocalStorage: " + JSON.stringify(newToken));
}

export function RemoveTokenFromLocalStorage(token: string) {
    var token = (localStorage.getItem(rcToken));
    if(token){
        localStorage.removeItem(rcToken);
    }else{
        token = (sessionStorage.getItem(rcToken));
        if(token){
            sessionStorage.removeItem(rcToken);
        }
    }
}

export function GetToken() {
    var token = (localStorage.getItem(rcToken));
    if(!token){
        token = (sessionStorage.getItem(rcToken));
    }

    if (!token) {
        return true;
    }

    let obj: IPersistedToken = JSON.parse(token);
    return obj.access_token;
}

export function HasTokenStorageExpired() {
    var token = (localStorage.getItem(rcToken));
    if(!token){
        token = (sessionStorage.getItem(rcToken));
    }

    if (!token) {
        return true;
    }

    let obj: IPersistedToken = JSON.parse(token);

    if (!obj.expiry_date) {
        return true;
    }

    var date = new Date(obj.expiry_date);
    var currentDate = new Date();

    if (currentDate > date) {
        return true;
    }

    return false;
}

export function UpdateTokenInReduxStore(token: string) {
    store.dispatch({
        type: 'UPDATE_TOKEN',
        token
    });
}