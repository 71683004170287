// Import React
import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import CloseButton from "../../atoms/CloseButton";
import SelectDropdown, { IOptions } from "../../atoms/SelectDropdown";
import AvatarEditor from "react-avatar-editor";

import styles from "./ChangeProfileImageForm.module.css";

interface IProps {
  onSuccessHandler(): void;
  toggleSidebar(show: boolean): void;
}

interface IState {}

interface IRouterProps {
  history: any;
  location: any;
  match: any;
}

interface IReduxProps {}

const initialState: IState = {};

type Props = IRouterProps & IProps & IReduxProps;

class ChangeProfileImageForm extends Component<Props, IState> {
  constructor(props: any) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event: React.FormEvent<HTMLInputElement>) {
    const field = event.currentTarget.name;
    const value: string = event.currentTarget.value;
    // this.setState({ [field]: value } as Pick<IState, any>);
  }

  componentDidMount(): void {}

  render() {
    return (
      <div className={styles.page}>
        <Form className={styles.form}>
          <div className={styles.Content} />
          <Row className={styles.header}>
            <Col>
              <h1 className={styles.h1}>
                Upload Profile Image{" "}
                <div className={styles.close}>
                  <CloseButton close={() => null} />
                </div>
              </h1>
            </Col>
          </Row>

          <FormGroup>
            
          </FormGroup>

          <div className={styles.sticky}>
            {" "}
            <Button className={styles.button} onClick={() => null}>
              Upload Image
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default withRouter(ChangeProfileImageForm as any);
