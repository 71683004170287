import React from 'react'
import { withRouter } from 'react-router-dom'
import Sidebar from 'react-sidebar'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import { sidebarStyling } from '../../../global/variables/_variables'
import AddTeamMemberForm from '../AddTeamMemberForm'
import styles from './AddTeamMemberSidePanel.module.scss'

interface IProps {
    isOpen: boolean
    onSetOpen(open: boolean): void
}

const AddTeamMemberSidePanel = ({ isOpen, onSetOpen }: IProps) => {
    return (
        <Sidebar
            touch={false}
            sidebarClassName={styles.sidebar}
            sidebar={isOpen ? <AddTeamMemberForm /> : <></>}
            open={isOpen}
            onSetOpen={onSetOpen}
            styles={sidebarStyling}
        >
            <div />
        </Sidebar>
    )
}

export default AddTeamMemberSidePanel
