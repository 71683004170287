import styled from 'styled-components';

export const ContainerDiv = styled.div`
    font-size: 14px;
    background-color: #F5F5F5;
`;

export const InnerContainerDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
`;

export const MainAreaDiv = styled.div`
    order: 1; // InnerContainerDiv flex
`;

export const HeaderDiv = styled.div`
    display: flex;
    text-align: left;
    justify-content: flex-start;
    column-gap: 4px;
`;

export const TitleDiv = styled.div`
    order: 1; // HeaderDiv flex
    padding: 5px 0px 0px 10px;
    font-weight: bold;
    white-space: nowrap;
`;

export const StatusDiv = styled.div`
    order: 2; // HeaderDiv flex
    display: flex;
`;

export const TooltipDiv = styled.div`
    order: 2; // DaysLeftDiv flex
    padding: 0px 5px 0px 0px;
`;

export const StatusWrapperDiv = styled.div`
    text-align: left;
    padding: 0px 10px 5px 10px;
    color: gray;
`;

