import styled from 'styled-components';


export const MainDiv = styled.div<{ backgroundColor?: string }>`
    padding: 5px 10px 5px 10px;
    white-space: nowrap;
    font-weight: bold;
    color: white;
    background-color: ${p => p.backgroundColor ? p.backgroundColor : '#EFE4E4'};
    border-radius: 20px;
    text-align: center;
`;


