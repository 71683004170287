import React, { Component } from 'react'
import 'react-dropzone-uploader/dist/styles.css'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import { withRouter } from 'react-router-dom'
import { getTenantDocumentLinks } from '../../../helpers/contactHelpers'
import { generateDocumentLinkText } from '../../../helpers/documentHelpers'
import { toggleAddDocumentFormChanged } from '../../../helpers/formStateHelpers'
import {
    addURLParameter,
    removeURLParameter,
} from '../../../helpers/locationHelpers'
import { isTenant } from '../../../helpers/roleHelpers'
import { toggleAddDocumentPopup } from '../../../helpers/sidebarHelpers'
import { updateToastMessage } from '../../../helpers/toastHelper'
import {
    deleteDocuments,
    getAssociationItem,
    searchForAssociationContact,
    searchForAssociationProperty,
    searchForAssociationTenancy,
    updateDocuments,
} from '../../../services/DocumentService'
import { getTypesForDropdown } from '../../../services/EntityService'
import { doesPropertyHaveUnits } from '../../../services/UnitService'
import { IDataResponse } from '../../../types/ApiService'
import { IUpdateDocumentsRequest } from '../../../types/DocumentService'
import { ISearchResult } from '../../../types/SearchService/ISearchService'
import Buttons from '../../atoms/Buttons'
import RotatingLoader from '../../atoms/RotatingLoader'
import { IOptions } from '../../atoms/SelectDropdown'
import SFFileUploader, { IFile } from '../../atoms/SFFileUpload'
import SuccessTick from '../../atoms/SuccessTick'
import { IAddTenantCallback } from '../AddContactForm'
import styles from './AddDocumentModal.module.scss'
import DocumentLinks, { AssociationType } from './DocumentLinks'
const queryString = require('query-string')

export interface IDocumentReturn {
    documentId: string
    fileType: string
    documentType: string
}

interface IState {
    dialogueTitle: string
    documentTypes: Array<IOptions>
    documentName: string
    entityTypeId: string
    selectedOption: IOptions
    isUploading: boolean
    isSuccess: boolean
    isLoading: boolean
    isGettingAssociations: boolean
    associationResults: IAssociationSearchResult
    selectedAssociation: IAssociationItem

    selectedPropertyId: IOptions
    selectedContactId: ISearchResult
    selectedTenancyId: IAssociationItem
    selectedIssueId: IOptions
    selectedTransactionId: IOptions
    selectedUnitId: IOptions

    tenancyAssociationResults: Array<IAssociationItem>
    contactAssociationResults: Array<IAssociationItem>
    propertyAssociationResults: Array<IAssociationItem>
    transactionAssociationResults: Array<IAssociationItem>

    propertyId: string
    contactId: string
    tenancyId: string
    issueId: string
    unitId: string
    inspectionId: number

    preselectedDocumentType: string
    returnDocumentIds: boolean
    documentIds: Array<IDocumentReturn>

    selectedPropertyIdHasUnits: boolean
    outstandingChangesModalOpen: boolean

    addingLinks: boolean
    files: Array<IFile>
    options: IDocumentUploadOptions
}

interface IAssociationSearchResult {
    properties: Array<IAssociationItem>
    contacts: Array<IAssociationItem>
}

interface IAssociationItem {
    type: string
    name: string
    description: string
    propertyId: string
    tenancydId: string
    contactId: string
    userId: string
}

export interface IDocumentUploadOptions {
    propertyId?: string
    propertyName?: string
    contactId?: string
    contactName?: string
    unitName?: string
    tenancyId?: string
    unitId?: string
    userId?: string
    issueId?: string
    issueName?: string
    assetId?: number
    taskId?: number
    inspectionId?: number
    documentTypeId?: string
    dialogueTitle?: string
    returnDocumentIds?: boolean
    isTenant?: boolean
    returnId?: string
    returnNewDocumentId?: boolean
    disableMultiple?: boolean
}

export interface IDocumentUpload {
    fileName: string
    base64: string
    extension: string
    isUploading: boolean
    isUploaded: boolean
    hasError: boolean
    size: number
    isTooBig: boolean
    documentTypeId: IOptions
    propertyId?: string
    tenancyId?: string
    contactId?: string
    userId?: string
}

export interface IAssociationRequest {
    userId?: string
    propertyId?: string
    contactId?: string
    tenancyId?: string
    unitId?: string
}

const initialState: IState = {
    documentTypes: [],
    documentName: '',
    entityTypeId: '',
    files: [],
    isUploading: false,
    isSuccess: false,
    isLoading: false,
    isGettingAssociations: false,
    selectedOption: null,
    associationResults: null,
    selectedAssociation: null,
    propertyId: null,
    contactId: null,
    tenancyId: null,
    issueId: null,
    unitId: null,
    inspectionId: null,
    selectedContactId: null,
    selectedPropertyId: null,
    selectedTenancyId: null,
    selectedIssueId: null,
    selectedTransactionId: null,
    selectedUnitId: null,
    contactAssociationResults: [],
    propertyAssociationResults: [],
    tenancyAssociationResults: [],
    transactionAssociationResults: [],
    preselectedDocumentType: null,
    dialogueTitle: '',
    returnDocumentIds: false,
    documentIds: [],
    selectedPropertyIdHasUnits: false,
    outstandingChangesModalOpen: false,
    addingLinks: false,
    options: null,
}

interface IProps {
    history: any
    location: any
    match: any
}

interface IRouterProps {
    onSuccessHandler?(): void
    toggleSidebar?(show: boolean): void
    add_document_form_changed?: boolean
    openManual?: boolean
    onCloseModalWithDocs?(docs: any[]): void
}

interface IReduxProps {
    propertyId?: string
    open?: boolean
}

type Props = IRouterProps & IProps & IReduxProps
let uploaderRef

class AddDocumentModal extends React.Component<Props, IState> {
    constructor(props: any) {
        super(props)
        uploaderRef = React.createRef()
        this.state = initialState
    }

    generateLinkText = (): string => {
        return generateDocumentLinkText(
            this.state.selectedPropertyId &&
                this.state.selectedPropertyId.label,
            this.state.selectedContactId && this.state.selectedContactId.label,
            this.state.selectedIssueId && this.state.selectedIssueId.label,
            this.state.selectedTransactionId &&
                this.state.selectedTransactionId.label,
            this.state.selectedUnitId && this.state.selectedUnitId.label
        )
    }

    onCancel = (): void => {
        if(this.props.onCloseModalWithDocs){
            this.props.onCloseModalWithDocs(null);
            return;
        }

        // If there are files uploaded, remove them
        if (this.state.files.length === 0) {
            toggleAddDocumentPopup(false)
            return
        }

        let toRemove = []
        this.state.files.forEach((f) => toRemove.push(f.documentId))
        deleteDocuments(toRemove).then((resp) => {
            if (resp && resp.status == 200) {
                toggleAddDocumentPopup(false)

                let newQuery = addURLParameter(
                    location.search,
                    'uploadedDocument=true'
                )
                this.props.history.push({
                    search: newQuery,
                })
            }
        })
    }

    onSave = (): void => {
        if (this.state.files.length === 0) {
            toggleAddDocumentPopup(false)
            return
        }

        let emptyGuid = '00000000-0000-0000-0000-000000000000'
        // Build request
        let request: IUpdateDocumentsRequest = {
            documents: [],
            propertyId:
                this.state.selectedPropertyId &&
                this.state.selectedPropertyId.value != emptyGuid &&
                this.state.selectedPropertyId.value,
            contactId:
                this.state.selectedContactId &&
                this.state.selectedContactId.value != emptyGuid
                    ? this.state.selectedContactId.value
                    : null,
            issueId:
                this.state.selectedIssueId && this.state.selectedIssueId.value,
            transactionId:
                this.state.selectedTransactionId &&
                this.state.selectedTransactionId.value,
            inspectionId: this.state.options && this.state.options.inspectionId,
            tenancyId: this.state.options && this.state.options.tenancyId,
            assetId: this.state.options && this.state.options.assetId,
        }

        this.state.files.map((file) => {
            request.documents = [
                ...request.documents,
                {
                    documentId: file.documentId,
                    documentTypeId: file.documentType,
                    description: file.description,
                },
            ]
        })

        // Show loader
        this.setState({ isLoading: true })

        // Perform request
        updateDocuments(request)
            .then((resp) => {
                if (resp && resp.status == 200) {
                    let response: IDataResponse<Array<string>> = resp.data
                    response.data.forEach((r) =>
                        updateToastMessage(r, 'success')
                    )
                    let newQuery = addURLParameter(
                        this.props.location.search,
                        'refreshDocumentsGrid=true'
                    )
                    this.props.history.push({
                        search: newQuery,
                    })
                    if (
                        this.state.options &&
                        this.state.options.returnNewDocumentId
                    ) {
                        let documentIds = ''
                        request.documents.forEach(
                            (document) =>
                                (documentIds += `uploadedDocumentId=${document.documentId}&`)
                        )
                        let newQuery2 = addURLParameter(
                            this.props.location.search,
                            `${documentIds}&returnId=${this.state.options.returnId}`
                        )
                        this.props.history.push({
                            search: newQuery2,
                        })
                    }
                    toggleAddDocumentPopup(false)
                }
            })
            .catch((e) => {
                this.setState({ isLoading: false })
                updateToastMessage(
                    'Request has failed, please try again.',
                    'failure'
                )
            })
    }

    updateFiles = (files: Array<IFile>): void => {
        this.setState({
            files,
        })
    }

    componentWillReceiveProps(newProps: IProps): void {
        let next = queryString.parse(
            newProps.location.search
        ).addContactCallback

        if (next) {
            let data: IAddTenantCallback = JSON.parse(next)
            if (
                !this.state.selectedContactId ||
                data.contactId != this.state.selectedContactId.value
            ) {
                this.setState(
                    (prevState) => ({
                        selectedContactId: {
                            ...prevState.selectedContactId,
                            id: data.contactId,
                            text: data.name,
                        },
                    }),
                    () => this.checkForChanges()
                )

                let query = removeURLParameter(
                    this.props.location.search,
                    'addContactCallback'
                )
                this.props.history.push({
                    search: query,
                })
            }
        }
    }

    checkForChanges = (): void => {
        if (this.state.files.length > 0 || this.state.selectedContactId) {
            toggleAddDocumentFormChanged(true)
            return
        }

        toggleAddDocumentFormChanged(false)
    }

    onSetOpenInternal = (open: boolean): void => {
        if (!open) {
            // Check if there is outstanding changes
            if (this.props.add_document_form_changed) {
                this.setState({
                    outstandingChangesModalOpen: true,
                })

                return
            }
        }

        if (this.state.files.length > 0) {
            let newQuery = addURLParameter(
                this.props.location.search,
                'refreshDocumentsGrid=true'
            )
            this.props.history.push({
                search: newQuery,
            })
        }

        toggleAddDocumentPopup(false)
    }

    onCloseChangesModal = (closeSidebar: boolean): void => {
        this.setState({
            outstandingChangesModalOpen: false,
        })

        if (closeSidebar) {
            toggleAddDocumentFormChanged(false)
            toggleAddDocumentPopup(false)
        }
    }

    setId = (item: any, type: string): void => {
        if (type == 'Property') {
            this.setState({
                propertyId: item.value,
            })
        }

        if (type == 'Contact') {
            this.setState({
                contactId: item.value,
            })
        }

        if (type == 'Issue') {
            this.setState({
                issueId: item.value,
            })
        }
    }

    onSelectAssociationItem = (selected: any, type: AssociationType): void => {
        if (type == AssociationType.Transaction) {
            if (!selected) {
                this.setState({
                    selectedTransactionId: null,
                })
                return
            }

            this.setState(
                {
                    selectedTransactionId: selected,
                    transactionAssociationResults: [],
                },
                () => this.checkForChanges()
            )
        }

        if (type == AssociationType.Contact) {
            if (!selected) {
                this.setState({
                    contactId: null,
                    selectedContactId: null,
                })
                return
            }

            this.setState(
                {
                    contactId: selected.id,
                    selectedContactId: selected,
                    contactAssociationResults: [],
                },
                () => this.checkForChanges()
            )
        }

        if (type == AssociationType.Issue) {
            if (!selected) {
                this.setState({
                    issueId: null,
                    selectedIssueId: null,
                })
                return
            }

            this.setState(
                {
                    issueId: selected.id,
                    selectedIssueId: selected,
                },
                () => this.checkForChanges()
            )
            this.setId(selected, 'Issue')
        }

        if (type == AssociationType.Property) {
            if (!selected) {
                this.setState({
                    propertyId: null,
                    selectedPropertyId: null,
                })
                return
            }

            this.setState(
                {
                    propertyId: selected.id,
                    selectedPropertyId: selected,
                    propertyAssociationResults: [],
                },
                () => {
                    doesPropertyHaveUnits(this.state.propertyId).then(
                        (resp) => {
                            if (resp && resp.status == 200) {
                                this.setState({
                                    selectedPropertyIdHasUnits:
                                        resp.data == true,
                                })
                            }
                        }
                    )
                }
            )

            this.setId(selected, 'Property')
        }

        if (type == AssociationType.Unit) {
            if (!selected) {
                this.setState({
                    unitId: null,
                    selectedUnitId: null,
                })
                return
            }

            this.setState({
                unitId: selected.value,
                selectedUnitId: selected,
            })
        }

        if (type == AssociationType.Tenancy) {
            this.setState({
                //selectedTenancyId: selected,
                //tenancyAssociationResults: []
            })
            //this.setId(selected);
        }
    }

    onSearch = (query: string, type: AssociationType): void => {
        if (type == AssociationType.Transaction) {
            searchForAssociationContact(query).then((resp) => {
                this.setState({
                    contactAssociationResults: resp.data,
                })
            })
        }

        if (type == AssociationType.Contact) {
            searchForAssociationContact(query).then((resp) => {
                this.setState({
                    contactAssociationResults: resp.data,
                })
            })
        }

        if (type == AssociationType.Property) {
            searchForAssociationProperty(query).then((resp) => {
                this.setState({
                    propertyAssociationResults: resp.data,
                })
            })
        }

        if (type == AssociationType.Tenancy) {
            searchForAssociationTenancy(query).then((resp) => {
                this.setState({
                    tenancyAssociationResults: resp.data,
                })
            })
        }
    }

    componentWillUnmount(): void {
        this.setState({
            associationResults: null,
            selectedAssociation: null,
        })
    }

    handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        const field = event.currentTarget.name
        const value: string = event.currentTarget.value
        this.setState({ [field]: value } as Pick<IState, any>)
    }

    getEntityTypes = (): void => {
        getTypesForDropdown('8a324853-7f7d-4b45-bc8e-891514de3713').then(
            (resp) => {
                if (resp && resp.data) {
                    this.setState({
                        documentTypes: resp.data,
                    })
                }
            }
        )
    }

    getOptions = (): void => {
        if (isTenant()) {
            let links = getTenantDocumentLinks()
            this.setState({
                selectedPropertyId: {
                    label: null,
                    value: links.propertyId,
                },
                selectedContactId: {
                    label: null,
                    value: links.contactId,
                },
            })
        }

        let options = queryString.parse(
            this.props.location.search
        ).uploadDocumentOptions

        if (!options) {
            return
        }

        let option: IDocumentUploadOptions = JSON.parse(options)

        if (option) {
            this.setState({
                options: option,
            })
        }

        if (option.returnDocumentIds) {
            this.setState({
                returnDocumentIds: true,
            })
        }

        // If there is options, get the associated items from API
        if (option.propertyId) {
            doesPropertyHaveUnits(option.propertyId).then((resp) => {
                if (resp && resp.status == 200) {
                    this.setState({
                        selectedPropertyIdHasUnits: resp.data == true,
                    })
                }
            })

            this.setState({
                isGettingAssociations: true,
            })
            getAssociationItem({
                propertyId: option.propertyId,
            }).then((resp) => {
                if (resp && resp.status == '200' && resp.data) {
                    this.setState((prevState) => ({
                        selectedPropertyId: {
                            ...prevState.selectedPropertyId,
                            value: option.propertyId,
                            label: resp.data.propertyName,
                        },
                        propertyId: option.propertyId,
                        isGettingAssociations: false,
                    }))

                    if (
                        !option.unitId &&
                        !option.contactId &&
                        resp.data.contactId &&
                        resp.data.contactName
                    ) {
                        this.setState((prevState) => ({
                            selectedContactId: {
                                ...prevState.selectedContactId,
                                id: resp.data.contactId,
                                text: resp.data.contactName,
                            },
                            contactId: resp.data.contactId,
                        }))
                    }
                }
            })
        }

        if (option.contactId) {
            getAssociationItem({
                contactId: option.contactId,
            }).then((resp) => {
                if (resp && resp.status == '200' && resp.data) {
                    let contact = resp.data
                    let selected: ISearchResult = {
                        label: contact.name,
                        value: contact.contactId,
                    }
                    this.setState({
                        selectedContactId: selected,
                        contactId: option.contactId,
                        isGettingAssociations: false,
                    })
                }
            })
        }

        if (option.tenancyId) {
            getAssociationItem({
                tenancyId: option.tenancyId,
            }).then((resp) => {
                if (resp && resp.status == '200' && resp.data) {
                    this.setState({
                        selectedTenancyId: resp.data,
                        tenancyId: option.tenancyId,
                        isGettingAssociations: false,
                    })
                }
            })
        }

        if (option.unitId) {
            getAssociationItem({
                unitId: option.unitId,
            }).then((resp) => {
                if (resp && resp.status == '200' && resp.data) {
                    this.setState((prev) => ({
                        selectedUnitId: {
                            label: resp.data.unitName,
                            value: resp.data.unitId,
                        },
                        selectedContactId: {
                            label: resp.data.contactName
                                ? resp.data.contactName
                                : this.state.selectedContactId &&
                                  this.state.selectedContactId.label,
                            value: resp.data.contactId
                                ? resp.data.contactId
                                : this.state.selectedContactId &&
                                  this.state.selectedContactId.value,
                        },
                        unitId: option.unitId,
                        isGettingAssociations: false,
                    }))
                }
            })
        }

        if (option.issueId) {
            this.setState({
                selectedIssueId: {
                    value: option.issueId,
                    label: option.issueName,
                },
            })
        }

        // Get pre-selected document type
        if (option.documentTypeId) {
            let lowercase = option.documentTypeId.toLowerCase()

            this.setState({
                preselectedDocumentType: lowercase,
            })
        }

        if (option.dialogueTitle) {
            this.setState({
                dialogueTitle: option.dialogueTitle,
            })
        }

        if (option.inspectionId) {
            this.setState({
                inspectionId: option.inspectionId,
            })
        }

        let query = removeURLParameter(
            this.props.location.search,
            'uploadDocumentOptions'
        )
        this.props.history.push({
            search: query,
        })

        this.setState({
            isLoading: false,
        })
    }

    componentDidMount(): void {
        this.getEntityTypes()
        this.getOptions()
    }

    render() {
        return (
            <Modal
                showCloseIcon={false}
                modalId={styles.modal}
                classNames={{ modal: styles.modal }}
                open={this.props.open || this.props.openManual}
                onClose={() => this.onSetOpenInternal(false)}
                center
            >
                <Modal
                    open={this.state.outstandingChangesModalOpen}
                    onClose={() => this.onSetOpenInternal(false)}
                    center
                >
                    <p className={styles.modalHeader}>Outstanding changes</p>
                    <p className={styles.modalBodyOutstandingChanges}>
                        Any updated information will be lost, do you wish to
                        continue?
                    </p>
                    <Buttons
                        buttons={[
                            {
                                text: 'No',
                                displayType: 'cancel',
                                elementType: 'button',
                                onClick: () => this.onCloseChangesModal(false),
                            },
                            {
                                text: 'Yes',
                                displayType: 'submit',
                                elementType: 'button',
                                onClick: () => this.onCloseChangesModal(true),
                            },
                        ]}
                    />
                </Modal>

                <h1 className={styles.heading}>Upload Files</h1>

                {this.state.isLoading ? (
                    <div>
                        <RotatingLoader loading={true} text="Loading..." />
                    </div>
                ) : this.state.isSuccess ? (
                    <div className={styles.success}>
                        <SuccessTick />
                        <p>
                            {this.state.files.length} files have been
                            succesfully uploaded
                        </p>
                    </div>
                ) : (
                    <div className={styles.addDocumentContent}>
                        <SFFileUploader
                            options={this.state.options}
                            updateFiles={this.updateFiles}
                            onCloseModal={() => toggleAddDocumentPopup(false)}
                            onCloseModalWithDocs={this.props.onCloseModalWithDocs}
                        />

                        {!isTenant() && this.state.files.length > 0 && (
                            <div className={styles.links}>
                                <div className={styles.linkHeading}>
                                    {this.generateLinkText()}
                                    <span
                                        onClick={() =>
                                            this.setState({
                                                addingLinks:
                                                    !this.state.addingLinks,
                                            })
                                        }
                                    >
                                        {this.state.addingLinks
                                            ? 'hide'
                                            : `${
                                                  this.generateLinkText() !=
                                                  'Not linked'
                                                      ? 'edit'
                                                      : 'add'
                                              }`}{' '}
                                        link
                                        {this.state.addingLinks ? 's ' : ' '}
                                    </span>
                                </div>
                                {this.state.addingLinks && (
                                    <DocumentLinks
                                        selectedPropertyId={
                                            this.state.selectedPropertyId
                                        }
                                        selectedContactId={
                                            this.state.selectedContactId
                                        }
                                        selectedIssueId={
                                            this.state.selectedIssueId
                                        }
                                        selectedTenancyId={
                                            this.state.selectedTenancyId
                                        }
                                        selectedUnitId={
                                            this.state.selectedUnitId
                                        }
                                        selectedTransactionId={
                                            this.state.selectedTransactionId
                                        }
                                        selectedPropertyIdHasUnits={
                                            this.state
                                                .selectedPropertyIdHasUnits
                                        }
                                        onSelect={this.onSelectAssociationItem}
                                    />
                                )}
                            </div>
                        )}

                        <div className={styles.sticky}>
                            <Buttons
                                buttons={[
                                    {
                                        displayType: 'cancel',
                                        elementType: 'button',
                                        onClick: () => this.onCancel(),
                                    },
                                    {
                                        displayType: 'submit',
                                        elementType: 'button',
                                        onClick: () => this.onSave(),
                                        disabled: this.state.files.length === 0,
                                    },
                                ]}
                            />
                        </div>
                    </div>
                )}
            </Modal>
        )
    }
}

const mapStateToProps = (state: any) => ({
    propertyId: state.property.property_data.property_data.propertyId,
    open: state.app.popups.add_document_popup,
    add_document_form_changed: state.app.form_states.add_document_form_changed,
})

export default withRouter<Props, any>(connect(mapStateToProps)(AddDocumentModal))
