import React from "react";
import { withRouter, Link } from "react-router-dom";
import styles from "./SimpleRouterTabs.module.scss";

export interface IRouterTab {
  text: string;
  mobileText?: string;
  path: string;
  disabled?: boolean;
  icon?: string;
  hide?: boolean;
}

interface IProps {
  routes: IRouterTab[];
  history: any;
  match: any;
  location: any;
}

class SimpleRouterTabs extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  navigateToTabRoute(route: string): void {
    this.props.history.push(route);
  }

  render() {
    const { routes } = this.props;

    return (
      <div className={styles.simpleRouterTabs}>
        <nav>
          <ul>
            {routes.map((route, index) => {
              const isActive =
                this.props.location.pathname ==
                `${this.props.match.url}/${route.path}` ||
                this.props.location.pathname.indexOf(route.path) > -1;
              const isDisabled = route.disabled;
              if (route.hide) {
                return null;
              }
              return (
                <li
                  className={
                    isDisabled
                      ? styles.disabledLi
                      : isActive
                        ? styles.activeLi
                        : styles.li
                  }
                  key={index}
                >
                  <Link className={styles.link} to={route.path}>
                    <div className={styles.desktop}>
                      {route.icon && (
                        <img
                          src={route.icon}
                          alt={`Navigation Icon ` + route.text}
                        />
                      )}
                      {route.text}
                    </div>
                    <div className={styles.mobile}>
                      {route.icon && (
                        <img
                          src={route.icon}
                          alt={`Navigation Icon ` + route.text}
                        />
                      )}
                      {route.mobileText && route.mobileText}
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    );
  }
}

export default withRouter(SimpleRouterTabs);