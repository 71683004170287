import React from "react";
import { ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-grids";
import { minWidthTablet } from "../../../../../global/variables/_variables";
import GenericPopup from "../../../../atoms/GenericPopup";
import { generateId } from "../../../../../helpers/randomHelpers";
import { QuickViewType } from "../../../../../types/QuickView";
import styles from '../DocumentsGrid.module.scss';
import { generateDocumentLinkText, getFileTypeLogo } from "../../../../../helpers/documentHelpers";

function links(props) {
  return (<div>
    {generateDocumentLinkText(props.propertyName, props.contactName, props.issueName, props.transactionName, props.unitName)}
  </div>)
}

function fileType(props) {
  return (<div className={styles.fileTypeIcon}>
    <GenericPopup
      identifier={generateId(10)}
      options={{
        documentId: props.documentId,
        type: QuickViewType.Document,
        toggleComponent: <div>{getFileTypeLogo(props.fileType)}</div>
      }}
    />
  </div>)
}

function property(props) {
  if (!props.propertyId) {
    return <div>Not linked</div>
  }

  return (<GenericPopup
    identifier={generateId(10)}
    options={{
      propertyId: props.propertyId,
      type: QuickViewType.Property,
      toggleComponent: <div>{props.attachedTo.property}</div>
    }}
  />)
}

export var DocumentPropertyTemplate = function (props: any) {
  return (
    <ColumnsDirective>
      <ColumnDirective
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        type="checkbox"
        width="50"
      />

      <ColumnDirective
        field="Action"
        textAlign="Left"
        width="50"
      />

      <ColumnDirective
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        headerText="File Type"
        width="40"
        template={fileType}
      />

      <ColumnDirective
        textAlign="Left"
        field="documentDescription"
        headerText="Description"
        width="100"
      />

      <ColumnDirective
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        field="documentName"
        headerText="File Name"
        width="120"
        visible={false}
      />

      <ColumnDirective
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        field="type"
        headerText="Document Type"
        width="100"
      />

      <ColumnDirective
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        headerText="Links"
        width="100"
        template={links}
      />

      <ColumnDirective
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        headerText="Created Date"
        width="100"
        field="uploadedOn"
        type="date"
        format={{ type: "date", format: "dd/MM/yyyy HH:mm" }}
      />

      <ColumnDirective
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        headerText="File size"
        width="100"
        field="fileSize"
        visible={false}
      />

      <ColumnDirective
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        headerText="Unit"
        width="100"
        field="unitName"
        visible={false}
      />

      <ColumnDirective
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        headerText="Contact"
        width="100"
        field="contactName"
        visible={false}
      />

      <ColumnDirective
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        headerText="Tenancy"
        width="100"
        field="tenancyName"
        visible={false}
      />

      <ColumnDirective
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        headerText="Issue"
        width="100"
        field="issueName"
        visible={false}
      />

      <ColumnDirective
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        headerText="Created by"
        width="100"
        field="createdBy"
        visible={false}
      />
    </ColumnsDirective>
  );
};