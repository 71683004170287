import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../../reducers/rootReducer';
import RegulationCompliancyV2 from '../../../../atoms/RegulationCompliancyV2';
import KeyDatesSummary from '../../../../organisms/KeyDates/KeyDatesSummary/KeyDatesSummary.component';
import SectionHeader from "../SectionHeader/SectionHeader.component"
import * as S from "./ActiveRegulationSection.styles";

interface sectionProps {
    id: string;
    header: string;
    hideOnClick(id: string): void;
}

const ActiveRegulationSection = ({id, header, hideOnClick}: sectionProps) => {
    // Redux 
    const { propertyDetails, propertyId } = useSelector((state: RootState) => state.property.property_data.property_data);

    let history = useHistory();

    return (
        <div>
            <SectionHeader id={id} header={header} hideOnClick={hideOnClick} titleOnClick={() => history.push(`/dashboard/property/${propertyId}/spec/regulation`)} />
            <S.SectionDiv onClick={(e: any) => e.stopPropagation()}>
                {propertyDetails && (
                    <KeyDatesSummary suppliedPropertyId={propertyId} />
                )}
            </S.SectionDiv>
        </div>
    )
}

export default ActiveRegulationSection;