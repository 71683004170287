import { apiService } from "./ApiService";
import * as domains from "../configuration/domains";
import { createBearerToken } from "../helpers/authHelpers";
import { toQueryString } from "./DocumentService";
import { ITenancyItem, ITenancyRequirement } from "../types/Tenancy";
import {
  IListItemOptions,
  ITenancyListItemOptions
} from "../types/ListFilters";
import { IDeleteRequest } from "../components/atoms/DeletePropertyModal";

export const getPrimaryTenancyInformation = (
  tenancyId: string
): Promise<any> => {
  return apiService
    .get(`${domains.tenancyService}/primary-information/${tenancyId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error;
    });
};

export const updateTenancyRequirement = (
  propertyId: string,
  request: ITenancyRequirement
): Promise<any> => {
  return apiService
    .put(
      `${domains.tenancyService}/tenancy-requirement/${propertyId}`,
      request,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: createBearerToken()
        }
      }
    )
    .catch(error => {
      return error;
    });
};

export const getTenancyRequirement = (propertyId: string): Promise<any> => {
  return apiService
    .get(`${domains.tenancyService}/tenancy-requirement/${propertyId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error;
    });
};

export const getTenancySummary = (): Promise<any> => {
  return apiService
    .get(`${domains.tenancyService}/tenant`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error;
    });
};

export const getTenancySummaryFromPropertyId = (propertyId?: string): Promise<any> => {
  return apiService
    .get(`${domains.tenancyService}/summary/${propertyId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error;
    });
};

export const getTenancyList = (
  options: IListItemOptions,
  tenancyOptions: ITenancyListItemOptions
): Promise<any> => {
  var stringed = toQueryString(options);
  var stringedTenancyOptions = toQueryString(tenancyOptions);

  return apiService
    .get(
      `${domains.tenancyService}/list?${stringed}&${stringedTenancyOptions}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: createBearerToken()
        }
      }
    )
    .catch(error => {
      return error;
    });
};

export const getLatestTenantForPropertyId = (propertyId: string): Promise<any> => {
  return apiService
    .get(`${domains.tenancyService}/get-latest-tenant-for-property/${propertyId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error;
    });
};

export const getTenantsForPropertyId = (propertyId: string): Promise<any> => {
  return apiService
    .get(`${domains.tenancyService}/property/${propertyId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error;
    });
};

export const getTenancy = (tenancyId: string): Promise<any> => {
  return apiService.get(`${domains.tenancyService}/${tenancyId}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export const getTenancyFromContactId = (contactId: string): Promise<any> => {
  return apiService.get(`${domains.tenancyService}/contact/${contactId}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export const getTenancyFromPropertyId = (propertyId: string): Promise<any> => {
  return apiService.get(
    `${domains.tenancyService}/property-tenancy/${propertyId}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    }
  );
};

export const getTenancyProfitFromPropertyId = (
  propertyId: string
): Promise<any> => {
  return apiService.get(
    `${domains.tenancyService}/property/profit/${propertyId}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    }
  );
};

export const updateTenancy = (
  request: ITenancyItem,
  tenancyId: string
): Promise<any> => {
  return apiService.put(`${domains.tenancyService}/${tenancyId}`, request, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export const addTenancy = (request: ITenancyItem): Promise<any> => {
  return apiService.post(`${domains.tenancyService}`, request, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export interface IPrepopulatedDataRequest {
  propertyId?: string;
  contactId?: string;
  unitId?: string;
}

export const getPrepopulatedTenancyInformation = (
  request: IPrepopulatedDataRequest
): Promise<any> => {
  let requestObj = toQueryString(request);

  return apiService.get(
    `${domains.tenancyService}/prepopulated-data/?${requestObj}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    }
  );
};

export const deleteTenancy = (
  tenancyId: string,
  request: IDeleteRequest
): Promise<any> => {
  return apiService.post(
    `${domains.tenancyService}/delete/${tenancyId}`,
    request,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    }
  );
};

const toQueryStringArray = (arr: string[]): string => {
  if (!arr || arr.length === 0) {
    return "";
  }

  let query = "";

  arr.map((a) => {
    if (!query) {
      query = "?tenancyIds=" + a;
    } else {
      query += "&tenancyIds=" + a;
    }
  });

  return query;
};

export const deleteTenancies = (
  tenancyIds: string[],
  request: IDeleteRequest
): Promise<any> => {
  var tenanciesStringed = toQueryStringArray(tenancyIds);

  return apiService.post(
    `${domains.tenancyService}/delete/ids${tenanciesStringed}`,
    request,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    }
  );
};