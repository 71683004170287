import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import DatePicker from 'react-datepicker'
import TextareaAutosize from 'react-textarea-autosize'
import Modal from 'react-responsive-modal'
import { Formik, Form, ErrorMessage } from 'formik'

import SearchPropertyInput from '../../atoms/SearchPropertyInput'
import SelectDropdown, { IOptions } from '../../atoms/SelectDropdown'
import {
    IAssetAddEdit,
    IAssetDto,
    IAssetType,
    IAssetTypeCollection,
    IProductStatusType,
    IQuantityAndValueConditionType,
    IServiceScheduleType,
} from '../../../types/Assets'
import {
    getProductStatusTypes,
    getQuantityAndValueConditionTypes,
    getServiceScheduleTypes,
    getAssetTypes,
    addAsset,
    updateAsset,
    getAssetImages,
    deleteAssetImage,
} from '../../../actions/assetsActions'
import Buttons from '../../atoms/Buttons'
import { RootState } from '../../../reducers/rootReducer'
import ImageGalleryComponent from '../../atoms/ImageGallery'
import SFUploaderModal from '../../atoms/SFUploaderModal'
import SFUploader from '../../atoms/SFUploader'

import styles from './AssetAddEditForm.module.scss'
import { goToRoute } from '../../../history'
import AssetAddEditSchema from './schema'
import CollapsibleContent from '../../atoms/CollapsibleContent'
import { doesPropertyHaveUnits } from '../../../services/UnitService'
import SearchUnitInput from '../../atoms/SearchUnitInput'
import CurrencyInput from '../../atoms/CurrencyInput'
import RotatingLoader from '../../atoms/RotatingLoader'
import moment from 'moment-timezone'
import { mapToDropdown } from '../../../helpers/dropdownHelpers'
import { processToast, updateToastMessage } from '../../../helpers/toastHelper'
import { imageService } from '../../../configuration/domains'
import { ImageTypeEnum, IUploadForm } from '../../../types/ImageService'
import { changePrimaryImage } from '../../../services/ImageService'
import { IDataResponse } from '../../../types/ApiService'
import { FileInfo } from '@syncfusion/ej2-react-inputs'
import AssociatedDocuments from '../../atoms/AssociatedDocuments'
import StickyComponent from '../../atoms/StickyComponent'

interface IState {
    buttonsInView: boolean
    hasChanges: boolean
    outstandingChangesModalOpen: boolean
    returnUrl?: string
    selectedPropertyIdHasUnits?: boolean
    hasNameBeenEdited: boolean
    totalValueEdited: boolean
    uploadingImages: boolean
    images: FileInfo[]
    documents: { documentId: string; type: string }[]
}

interface IProps {
    history: any
    location: any
    match: any

    assetOptions?: IAssetAddEdit
    asset?: IAssetDto
    checkListAssetTypes?: IAssetTypeCollection
    allAssetTypes?: IAssetType[]
    serviceScheduleTypes?: IServiceScheduleType[]
    quantityAndValueConditionTypes?: IQuantityAndValueConditionType[]
    productStatusTypes?: IProductStatusType[]
    getAssetTypes?: any
    getAssetImages?: any
    deleteAssetImage?: any
    addAsset?: any
    getServiceScheduleTypes?(): any
    getQuantityAndValueConditionTypes?(): any
    getProductStatusTypes?(): any
    updateAsset?: any
    assetTypesLoading?: boolean
    loading?: boolean
}

let initialState: any = {}

let buttons
let observer: IntersectionObserver

class AssetAddEditForm extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        buttons = React.createRef()

        this.state = {
            ...initialState,
            buttonsInView: false,
            hasChanges: false,
            hasNameBeenEdited: false,
            totalValueEdited: false,
            uploadingImages: false,
            images: [],
            documents: [],
        }

        const callback = (entries: any[]) => {
            let isIntersecting = entries.filter((x) => x.isIntersecting)
            if (isIntersecting.length) {
                this.setState({
                    buttonsInView: true,
                })
            } else {
                this.setState({
                    buttonsInView: false,
                })
            }
        }

        observer = new IntersectionObserver(callback)
    }

    onAddDocument = (documentIds: string | string[], type: string) => {
        let newArr = []

        if (Array.isArray(documentIds)) {
            newArr = documentIds.map((x) => ({ documentId: x, type }))
        } else {
            newArr = [{ documentId: documentIds, type }]
        }

        this.setState({
            documents: this.state.documents.concat(newArr),
        })
    }

    onChangePrimaryImage = (imageId: string) => {
        const { assetId } = this.props.asset

        if (!assetId) {
            return
        }

        changePrimaryImage({
            imageId,
            parentId: assetId,
            imageType: ImageTypeEnum.ASSET_IMAGE,
        }).then((resp) => {
            if (!resp || resp.status != 200) {
                return
            }

            let response: IDataResponse<string> = resp.data
            processToast(response)
            this.props.getAssetImages(assetId)
        })
    }

    generateName = (assetType: string) => {
        if (this.state.hasNameBeenEdited) {
            return
        }

        if (assetType) {
            return assetType.trim()
        }

        return null
    }

    async componentDidMount() {
        observer.observe(buttons.current)

        const {
            asset,
            productStatusTypes,
            serviceScheduleTypes,
            quantityAndValueConditionTypes,
        } = this.props

        if (serviceScheduleTypes.length === 0) {
            this.props.getServiceScheduleTypes()
        }

        if (quantityAndValueConditionTypes.length === 0) {
            this.props.getQuantityAndValueConditionTypes()
        }

        if (productStatusTypes.length === 0) {
            this.props.getProductStatusTypes()
        }

        this.props.getAssetTypes(
            asset && asset.assetTypeLevel1Id ? asset.assetTypeLevel1Id : 0
        )

        const { assetOptions } = this.props

        if (
            (asset && asset.propertyId && asset.unitId) ||
            (assetOptions && assetOptions.propertyId && assetOptions.unitId)
        ) {
            this.setState({
                selectedPropertyIdHasUnits: true,
            })
            return
        }else if(asset && asset.propertyId){
            const { data } = await doesPropertyHaveUnits(
                asset.propertyId
            )
            if (data === true) {
                this.setState({
                    selectedPropertyIdHasUnits: true,
                })
            }
        }

        if (assetOptions && assetOptions.propertyId && !assetOptions.unitId) {
            const { data } = await doesPropertyHaveUnits(
                assetOptions.propertyId
            )
            if (data === true) {
                this.setState({
                    selectedPropertyIdHasUnits: true,
                })
            }
        }
    }

    getInitialState = () => ({
        assetTypeLevel1Id: null,
        assetTypeLevel2Id: null,
        propertyId:
            this.props.assetOptions && this.props.assetOptions.propertyId,
        propertyName:
            this.props.assetOptions && this.props.assetOptions.propertyName,
        unitId: this.props.assetOptions && this.props.assetOptions.unitId,
        unitName: this.props.assetOptions && this.props.assetOptions.unitName,
    })

    doesPropertyHaveUnits = (propertyId: string) => {
        doesPropertyHaveUnits(propertyId).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                this.setState({
                    selectedPropertyIdHasUnits: resp.data == true,
                })
            }
        })
    }

    checkForChanges = () => {
        if (this.state != initialState) {
            this.setState({
                hasChanges: true,
            })
        }
    }

    populateSubtypes = (selectedId: string) => {
        this.checkForChanges()
        this.props.getAssetTypes(selectedId ? parseInt(selectedId) : 0)
    }

    getDropdownDataInCorrectFormat = (assetTypes: IAssetType[]): IOptions[] => {
        if (!assetTypes || assetTypes.length === 0) {
            return []
        }

        return mapToDropdown(assetTypes, 'assetTypeId', 'assetTypeName')
    }

    onCancel = () => {
        if (this.state.hasChanges) {
            this.setState({
                outstandingChangesModalOpen: true,
            })
            return
        }

        //goToRoute(this.getNavigatePath())
        this.props.history.goBack();
    }

    onSubmit = (asset: IAssetDto): void => {
        if (this.props.asset) {
            this.props.updateAsset(asset, this.getNavigatePath())
            return
        } else {
            asset.assetId = 0
            let filesRequest: IUploadForm = null
            if (this.state.images.length) {
                // If there are images create the form data
                var formData = new FormData()
                this.state.images.forEach((image) =>
                    formData.append('File', image.rawFile)
                )
                filesRequest = {
                    images: formData,
                    options: {
                        imageType: ImageTypeEnum.ASSET_IMAGE.toString(),
                    },
                }
            }

            if (this.state.documents.length) {
                asset.documents = this.state.documents.map((x) => x.documentId)
            }

            this.props.addAsset(asset, this.getNavigatePath(), filesRequest)
            return
        }
    }

    onCloseChangesModal(submitCancel: boolean): void {
        this.setState({
            outstandingChangesModalOpen: false,
        })

        if (submitCancel) {
            this.setState(
                {
                    hasChanges: false,
                },
                () => this.onCancel()
            )
        }
    }

    getNavigatePath = (): string => {
        if (this.props.assetOptions) {
            const { referer } = this.props.assetOptions
            if (referer == 'property') {
                return `/dashboard/property/${this.props.assetOptions.propertyId}/spec/assets`
            }
            if (referer == 'unit') {
                return `/dashboard/unit/view/${this.props.assetOptions.unitId}/assets`
            }
        }

        return '/dashboard/assets'
    }

    getParentType = (childId: string): IOptions => {
        let childType: IAssetType = this.props.allAssetTypes.find(
            (a) => a != null && a.assetTypeId == parseInt(childId)
        )
        if (childType) {
            let parent: IAssetType =
                this.props.checkListAssetTypes.assetTypes.find(
                    (a) => a.assetTypeId == childType.parentAssetTypeId
                )
            return {
                value: parent.assetTypeId.toString(),
                label: parent.assetTypeName,
            }
        }
    }

    getToDefaultOtherChild = (parentId: string): IOptions => {
        if (parentId) {
            let parent: IAssetType =
                this.props.checkListAssetTypes.assetTypes.find(
                    (a) => a.assetTypeId == parseInt(parentId)
                )
            let childOther: IAssetType = parent.subtypes.find(
                (a) => a.assetTypeName == 'Other'
            )
            return {
                value: childOther.assetTypeId.toString(),
                label: childOther.assetTypeName,
            }
        }
    }

    render() {
        if (this.props.loading) {
            return (
                <RotatingLoader
                    loading={true}
                    text={`${
                        this.props.asset
                            ? 'Updating asset...'
                            : 'Adding asset...'
                    }`}
                />
            )
        }

        return (
            <div className={styles.form}>
                <Modal
                    open={this.state.outstandingChangesModalOpen}
                    onClose={() => this.onCloseChangesModal(false)}
                    center
                >
                    <p className={styles.modalHeader}>Outstanding changes</p>
                    <p className={styles.modalBodyOutstandingChanges}>
                        Any updated information will be lost, do you wish to
                        continue?
                    </p>

                    <Buttons
                        buttons={[
                            {
                                text: 'No',
                                displayType: 'cancel',
                                elementType: 'button',
                                onClick: () => this.onCloseChangesModal(false),
                            },
                            {
                                text: 'Yes',
                                displayType: 'submit',
                                elementType: 'button',
                                onClick: () => this.onCloseChangesModal(true),
                            },
                        ]}
                    />
                </Modal>

                <div className={styles.content}>
                    <Formik
                        initialValues={
                            this.props.asset
                                ? {
                                      propertyId: this.props.asset.propertyId,
                                      propertyName:
                                          this.props.asset.propertyName,
                                      unitId: this.props.asset.unitId,
                                      unitName: this.props.asset.unitName,
                                      assetName: this.props.asset.assetName,
                                      assetSubTitle:
                                          this.props.asset.assetSubTitle,
                                      assetTypeLevel2Id:
                                          this.props.asset.assetTypeLevel2Id,
                                      assetTypeLevel2Name:
                                          this.props.asset.assetTypeLevel2Name,
                                      assetTypeLevel1Id:
                                          this.props.asset.assetTypeLevel1Id,
                                      assetTypeLevel1Name:
                                          this.props.asset.assetTypeLevel1Name,
                                      supplier: this.props.asset.supplier,
                                      purchasedDate:
                                          this.props.asset.purchasedDate,
                                      make: this.props.asset.make,
                                      model: this.props.asset.model,
                                      modelNumber: this.props.asset.modelNumber,
                                      serialNumber:
                                          this.props.asset.serialNumber,
                                      assetTagId: this.props.asset.assetTagId,
                                      cost: this.props.asset.cost,
                                      colour: this.props.asset.colour,
                                      serviceScheduleTypeId:
                                          this.props.asset
                                              .serviceScheduleTypeId,
                                      quantityAndValueConditionTypeId:
                                          this.props.asset
                                              .quantityAndValueConditionTypeId,

                                      dateOfInstallation:
                                          this.props.asset.dateOfInstallation,
                                      warrantyExpirationDate:
                                          this.props.asset
                                              .warrantyExpirationDate,
                                      nextService: this.props.asset.nextService,

                                      widthInInches:
                                          this.props.asset.widthInInches,
                                      heightInInches:
                                          this.props.asset.heightInInches,
                                      depth: this.props.asset.depth,

                                      description: this.props.asset.description,

                                      meter1Reading:
                                          this.props.asset.meter1Reading,
                                      meter1ReadingDate:
                                          this.props.asset.meter1ReadingDate,
                                      meter2Reading:
                                          this.props.asset.meter2Reading,
                                      meter2ReadingDate:
                                          this.props.asset.meter2ReadingDate,

                                      value: this.props.asset.value,
                                      quantity: this.props.asset.quantity,
                                      totalValue: this.props.asset.totalValue,

                                      energyUsage: this.props.asset.energyUsage,
                                      energyInfo: this.props.asset.energyInfo,
                                      productStatusTypeId:
                                          this.props.asset.productStatusTypeId,
                                  }
                                : this.getInitialState()
                        }
                        validationSchema={AssetAddEditSchema}
                        onSubmit={(values: any) => {
                            this.onSubmit({
                                assetId: this.props.asset
                                    ? this.props.asset.assetId
                                    : null,
                                createdByUserId: this.props.asset
                                    ? this.props.asset.createdByUserId
                                    : null,
                                teamId: this.props.asset
                                    ? this.props.asset.teamId
                                    : null,
                                unitId: values.unitId,
                                propertyId: values.propertyId,
                                propertyName: values.propertyName,
                                assetName: values.assetName,
                                assetTypeLevel2Id: values.assetTypeLevel2Id,
                                assetTypeLevel2Name: values.assetTypeLevel2Name,
                                assetTypeLevel1Id: values.assetTypeLevel1Id,
                                assetTypeLevel1Name: values.assetTypeLevel1Name,
                                assetTagId: values.assetTagId,
                                supplier: values.supplier,
                                purchasedDate: values.purchasedDate,
                                make: values.make,
                                colour: values.colour,
                                cost: values.cost,
                                model: values.model,
                                modelNumber: values.modelNumber,
                                serialNumber: values.serialNumber,
                                dateOfInstallation: values.dateOfInstallation,
                                warrantyExpirationDate:
                                    values.warrantyExpirationDate,
                                nextService: values.nextService,
                                widthInInches: values.widthInInches,
                                heightInInches: values.heightInInches,
                                depth: values.depth,
                                description: values.description,
                                meter1Reading: values.meter1Reading,
                                meter1ReadingDate: values.meter1ReadingDate,
                                meter2Reading: values.meter2Reading,
                                meter2ReadingDate: values.meter2ReadingDate,
                                value: values.value,
                                quantity: values.quantity,
                                totalValue: values.totalValue,
                                energyUsage: values.energyUsage,
                                energyInfo: values.energyInfo,
                                serviceScheduleTypeId:
                                    values.serviceScheduleTypeId,
                                quantityAndValueConditionTypeId:
                                    values.quantityAndValueConditionTypeId,
                                assetSubTitle: values.assetSubTitle,
                                productStatusTypeId: values.productStatusTypeId,
                            })
                        }}
                    >
                        {(props) => (
                            <Form>
                                {/* Row 2 */}
                                <div className={styles.row}>
                                    <div className={styles.column}>
                                        <div className={styles.label}>
                                            Asset Type
                                        </div>
                                        <SelectDropdown
                                            selectedId={
                                                props.values.assetTypeLevel2Id
                                                    ? props.values.assetTypeLevel2Id.toString()
                                                    : null
                                            }
                                            data={
                                                this.props.checkListAssetTypes
                                                    ? this.getDropdownDataInCorrectFormat(
                                                          this.props
                                                              .checkListAssetTypes
                                                              .assetSubtypes
                                                      )
                                                    : []
                                            }
                                            placeholder="Select..."
                                            onSelect={(id: IOptions) => {
                                                props.setFieldValue(
                                                    'assetTypeLevel2Id',
                                                    id.value
                                                )
                                                props.setFieldValue(
                                                    'assetTypeLevel2Name',
                                                    id.label
                                                )
                                                let parentType =
                                                    this.getParentType(id.value)
                                                if (parentType) {
                                                    props.setFieldValue(
                                                        'assetTypeLevel1Id',
                                                        parentType.value
                                                    )
                                                    props.setFieldValue(
                                                        'assetTypeLevel1Name',
                                                        parentType.label
                                                    )
                                                }
                                                props.setFieldValue(
                                                    'assetName',
                                                    this.generateName(id.label)
                                                )
                                                this.checkForChanges()
                                            }}
                                            isSearchable={true}
                                        />

                                        <div className={styles.errorMessage}>
                                            <ErrorMessage name="assetTypeLevel2Id" />
                                        </div>
                                    </div>
                                    <div className={styles.column}>
                                        <div className={styles.label}>
                                            Asset Category
                                        </div>
                                        <SelectDropdown
                                            showAllOption
                                            selectedId={
                                                props.values.assetTypeLevel1Id
                                                    ? props.values.assetTypeLevel1Id.toString()
                                                    : null
                                            }
                                            data={this.getDropdownDataInCorrectFormat(
                                                this.props.checkListAssetTypes
                                                    .assetTypes
                                            )}
                                            placeholder="Select..."
                                            onSelect={(id: IOptions) => {
                                                props.setFieldValue(
                                                    'assetTypeLevel1Id',
                                                    id.value
                                                )
                                                props.setFieldValue(
                                                    'assetTypeLevel1Name',
                                                    id.label
                                                )
                                                // Set default to other
                                                let childOther = null
                                                if (id.value != null) {
                                                    childOther =
                                                        this.getToDefaultOtherChild(
                                                            id.value
                                                        )
                                                    props.setFieldValue(
                                                        'assetTypeLevel2Id',
                                                        childOther.value
                                                    )
                                                    props.setFieldValue(
                                                        'assetTypeLevel2Name',
                                                        childOther.label
                                                    )
                                                } else {
                                                    // When 'All' is selected
                                                    props.setFieldValue(
                                                        'assetTypeLevel2Id',
                                                        null
                                                    )
                                                    props.setFieldValue(
                                                        'assetTypeLevel2Name',
                                                        null
                                                    )
                                                }
                                                this.populateSubtypes(id.value)
                                            }}
                                            isSearchable={true}
                                        />
                                        <div className={styles.errorMessage}>
                                            <ErrorMessage name="assetTypeLevel1Id" />
                                        </div>
                                    </div>
                                </div>
                                {/* Row 4 */}
                                <div className={styles.row}>
                                    <div className={styles.column}>
                                        <div className={styles.label}>Name</div>
                                        <input
                                            maxLength={128}
                                            className={styles.input}
                                            value={props.values.assetName}
                                            placeholder="Name"
                                            onChange={(e) => {
                                                props.setFieldValue(
                                                    'assetName',
                                                    e.currentTarget.value
                                                )
                                                this.setState({
                                                    hasNameBeenEdited: true,
                                                })
                                            }}
                                        />
                                    </div>

                                    <div className={styles.column}>
                                        <div className={styles.label}>
                                            Asset Tag
                                        </div>
                                        <input
                                            maxLength={64}
                                            className={styles.input}
                                            value={props.values.assetTagId}
                                            onChange={(e) =>
                                                props.setFieldValue(
                                                    'assetTagId',
                                                    e.currentTarget.value
                                                )
                                            }
                                            placeholder="Asset Tag"
                                        />
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.column}>
                                        <div className={styles.label}>
                                            Sub Title
                                        </div>
                                        <input
                                            maxLength={200}
                                            className={styles.input}
                                            value={props.values.assetSubTitle}
                                            onChange={(e) =>
                                                props.setFieldValue(
                                                    'assetSubTitle',
                                                    e.currentTarget.value
                                                )
                                            }
                                            placeholder="Sub Title"
                                        />
                                    </div>
                                </div>
                                {/* Row 5 */}
                                <div className={styles.row}>
                                    <div className={styles.column}>
                                        <div className={styles.label}>Make</div>
                                        <input
                                            maxLength={64}
                                            className={styles.input}
                                            value={props.values.make}
                                            onChange={(e) =>
                                                props.setFieldValue(
                                                    'make',
                                                    e.currentTarget.value
                                                )
                                            }
                                            placeholder="Make"
                                        />
                                    </div>
                                    <div className={styles.column}>
                                        <div className={styles.label}>
                                            Model
                                        </div>
                                        <input
                                            maxLength={64}
                                            className={styles.input}
                                            value={props.values.model}
                                            onChange={(e) =>
                                                props.setFieldValue(
                                                    'model',
                                                    e.currentTarget.value
                                                )
                                            }
                                            placeholder="Model"
                                        />
                                    </div>
                                </div>
                                {/* Row 4 */}
                                <div className={styles.row}>
                                    <div className={styles.column}>
                                        <div className={styles.label}>
                                            Description
                                        </div>
                                        <TextareaAutosize
                                            maxLength={500}
                                            value={props.values.description}
                                            onChange={(e) =>
                                                props.setFieldValue(
                                                    'description',
                                                    e.currentTarget.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                {/* Row 1 */}
                                <div className={styles.row}>
                                    <div className={styles.column}>
                                        <div className={styles.label}>
                                            Property
                                        </div>
                                        <SearchPropertyInput
                                            selectedPropertyId={
                                                props.values.propertyId
                                            }
                                            selectedPropertyName={
                                                props.values.propertyName
                                            }
                                            onSelect={(selected: IOptions) => {
                                                props.setFieldValue(
                                                    'propertyId',
                                                    selected.value
                                                )
                                                props.setFieldValue(
                                                    'propertyName',
                                                    selected.label
                                                )
                                                this.doesPropertyHaveUnits(
                                                    selected.value
                                                )
                                            }}
                                            onClear={() => {
                                                props.setFieldValue(
                                                    'propertyId',
                                                    null
                                                )
                                                props.setFieldValue(
                                                    'propertyName',
                                                    null
                                                )
                                                props.setFieldValue(
                                                    'unitId',
                                                    null
                                                )
                                                props.setFieldValue(
                                                    'unitName',
                                                    null
                                                )
                                                this.setState({
                                                    selectedPropertyIdHasUnits:
                                                        false,
                                                })
                                            }}
                                            disableClear={
                                                this.props.assetOptions &&
                                                this.props.assetOptions
                                                    .propertyId
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                    <div className={styles.column}>
                                        <div className={styles.label}>Unit</div>
                                        {this.state
                                            .selectedPropertyIdHasUnits ? (
                                            <SearchUnitInput
                                                propertyId={
                                                    props.values.propertyId
                                                }
                                                onClear={() => {
                                                    props.setFieldValue(
                                                        'unitId',
                                                        null
                                                    )
                                                    props.setFieldValue(
                                                        'unitName',
                                                        null
                                                    )
                                                }}
                                                disableClear={
                                                    this.props.assetOptions &&
                                                    this.props.assetOptions
                                                        .unitId
                                                        ? true
                                                        : false
                                                }
                                                selectedUnitId={
                                                    props.values.unitId
                                                }
                                                selectedUnitName={
                                                    props.values.unitName
                                                }
                                                onSelect={(
                                                    option: IOptions
                                                ) => {
                                                    props.setFieldValue(
                                                        'unitId',
                                                        option.value
                                                    )
                                                    props.setFieldValue(
                                                        'unitName',
                                                        option.label
                                                    )
                                                    this.checkForChanges()
                                                }}
                                            />
                                        ) : props.values.propertyId ? (
                                            'Property has no Units'
                                        ) : (
                                            'Select property to get Units'
                                        )}
                                    </div>
                                </div>

                                {/* More details */}
                                <div className={styles.row}>
                                    <div className={styles.column}>
                                        <CollapsibleContent title="Product details">
                                            <React.Fragment>
                                                <div className={styles.row}>
                                                    <div
                                                        className={
                                                            styles.columnHalf
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Status
                                                        </div>
                                                        <SelectDropdown
                                                            selectedId={
                                                                props.values
                                                                    .productStatusTypeId
                                                                    ? props.values.productStatusTypeId.toString()
                                                                    : null
                                                            }
                                                            data={
                                                                this.props
                                                                    .productStatusTypes
                                                                    ? mapToDropdown(
                                                                          this
                                                                              .props
                                                                              .productStatusTypes,
                                                                          'typeId',
                                                                          'typeName'
                                                                      )
                                                                    : []
                                                            }
                                                            placeholder="Select..."
                                                            onSelect={(
                                                                id: IOptions
                                                            ) => {
                                                                props.setFieldValue(
                                                                    'productStatusTypeId',
                                                                    Number(
                                                                        id.value
                                                                    )
                                                                )
                                                            }}
                                                        />
                                                    </div>

                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Model Number
                                                        </div>
                                                        <input
                                                            value={
                                                                props.values
                                                                    .modelNumber
                                                            }
                                                            onChange={(e) =>
                                                                props.setFieldValue(
                                                                    'modelNumber',
                                                                    e
                                                                        .currentTarget
                                                                        .value
                                                                )
                                                            }
                                                            aria-label="Model Number"
                                                            className={
                                                                styles.input
                                                            }
                                                            maxLength={64}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Serial number
                                                        </div>
                                                        <input
                                                            value={
                                                                props.values
                                                                    .serialNumber
                                                            }
                                                            onChange={(e) =>
                                                                props.setFieldValue(
                                                                    'serialNumber',
                                                                    e
                                                                        .currentTarget
                                                                        .value
                                                                )
                                                            }
                                                            aria-label="Serial Number"
                                                            className={
                                                                styles.input
                                                            }
                                                            maxLength={64}
                                                        />
                                                    </div>
                                                </div>

                                                {/* Row 1 */}
                                                <div className={styles.row}>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Date of Installation
                                                        </div>
                                                        <DatePicker
                                                            className={
                                                                styles.input
                                                            }
                                                            todayButton="Today"
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={
                                                                props.values
                                                                    .dateOfInstallation &&
                                                                moment(
                                                                    props.values
                                                                        .dateOfInstallation
                                                                ).toDate()
                                                            }
                                                            onChange={(
                                                                date: Date
                                                            ) => {
                                                                props.setFieldValue(
                                                                    'dateOfInstallation',
                                                                    date
                                                                )
                                                                this.checkForChanges()
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Warranty expiration
                                                            date
                                                        </div>
                                                        <DatePicker
                                                            className={
                                                                styles.input
                                                            }
                                                            todayButton="Today"
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={
                                                                props.values
                                                                    .warrantyExpirationDate &&
                                                                moment(
                                                                    props.values
                                                                        .warrantyExpirationDate
                                                                ).toDate()
                                                            }
                                                            onChange={(
                                                                date: Date
                                                            ) => {
                                                                props.setFieldValue(
                                                                    'warrantyExpirationDate',
                                                                    date
                                                                )
                                                                this.checkForChanges()
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Next service
                                                        </div>
                                                        <DatePicker
                                                            className={
                                                                styles.input
                                                            }
                                                            todayButton="Today"
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={
                                                                props.values
                                                                    .nextService &&
                                                                moment(
                                                                    props.values
                                                                        .nextService
                                                                ).toDate()
                                                            }
                                                            onChange={(
                                                                date: Date
                                                            ) => {
                                                                props.setFieldValue(
                                                                    'nextService',
                                                                    date
                                                                )
                                                                this.checkForChanges()
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Service schedule
                                                        </div>
                                                        <SelectDropdown
                                                            selectedId={
                                                                props.values
                                                                    .serviceScheduleTypeId
                                                                    ? props.values.serviceScheduleTypeId.toString()
                                                                    : null
                                                            }
                                                            data={mapToDropdown(
                                                                this.props
                                                                    .serviceScheduleTypes,
                                                                'typeId',
                                                                'typeName'
                                                            )}
                                                            placeholder="Select"
                                                            onSelect={(
                                                                id: IOptions
                                                            ) => {
                                                                props.setFieldValue(
                                                                    'serviceScheduleTypeId',
                                                                    Number(
                                                                        id.value
                                                                    )
                                                                )
                                                                this.checkForChanges()
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={styles.row}>
                                                    <div
                                                        className={
                                                            styles.columnHalf
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Supplier
                                                        </div>
                                                        <input
                                                            maxLength={64}
                                                            className={styles.input}
                                                            value={props.values.supplier}
                                                            onChange={(e) =>
                                                                props.setFieldValue(
                                                                    'supplier',
                                                                    e
                                                                        .currentTarget
                                                                        .value
                                                                )
                                                            }
                                                            placeholder="Supplier"
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Purchased Date
                                                        </div>
                                                        <DatePicker
                                                            className={
                                                                styles.input
                                                            }
                                                            todayButton="Today"
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={
                                                                props.values
                                                                    .purchasedDate &&
                                                                moment(
                                                                    props.values
                                                                        .purchasedDate
                                                                ).toDate()
                                                            }
                                                            onChange={(
                                                                date: Date
                                                            ) => {
                                                                props.setFieldValue(
                                                                    'purchasedDate',
                                                                    date
                                                                )
                                                                this.checkForChanges()
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Purchase Price
                                                        </div>
                                                        <CurrencyInput
                                                            prefix="£"
                                                            className={
                                                                styles.input
                                                            }
                                                            handleChange={(
                                                                e,
                                                                maskedValue,
                                                                floatValue
                                                            ) =>
                                                                props.setFieldValue(
                                                                    'cost',
                                                                    floatValue
                                                                )
                                                            }
                                                            value={
                                                                props.values
                                                                    .cost
                                                            }
                                                            name="cost"
                                                        />
                                                    </div>
                                                </div>
                                                {/* Row 3 */}
                                                <div className={styles.row}>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Width
                                                        </div>
                                                        <input
                                                            maxLength={10}
                                                            value={
                                                                props.values
                                                                    .widthInInches
                                                            }
                                                            onChange={(e) =>
                                                                props.setFieldValue(
                                                                    'widthInInches',
                                                                    e
                                                                        .currentTarget
                                                                        .value
                                                                )
                                                            }
                                                            type="number"
                                                            aria-label="Width"
                                                            className={
                                                                styles.input
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Height
                                                        </div>
                                                        <input
                                                            maxLength={10}
                                                            value={
                                                                props.values
                                                                    .heightInInches
                                                            }
                                                            onChange={(e) =>
                                                                props.setFieldValue(
                                                                    'heightInInches',
                                                                    e
                                                                        .currentTarget
                                                                        .value
                                                                )
                                                            }
                                                            type="number"
                                                            aria-label="Height"
                                                            className={
                                                                styles.input
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Depth
                                                        </div>
                                                        <input
                                                            maxLength={10}
                                                            value={
                                                                props.values
                                                                    .depth
                                                            }
                                                            onChange={(e) =>
                                                                props.setFieldValue(
                                                                    'depth',
                                                                    e
                                                                        .currentTarget
                                                                        .value
                                                                )
                                                            }
                                                            type="number"
                                                            aria-label="Depth"
                                                            className={
                                                                styles.input
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Colour
                                                        </div>
                                                        <input
                                                            value={
                                                                props.values
                                                                    .colour
                                                            }
                                                            onChange={(e) =>
                                                                props.setFieldValue(
                                                                    'colour',
                                                                    e
                                                                        .currentTarget
                                                                        .value
                                                                )
                                                            }
                                                            aria-label="Colour"
                                                            className={
                                                                styles.input
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        </CollapsibleContent>
                                    </div>
                                </div>
                                {/* Quantity and Value */}
                                <div className={styles.row}>
                                    <div className={styles.column}>
                                        <CollapsibleContent title="Quantity and value">
                                            <React.Fragment>
                                                {/* Row 1 */}
                                                <div className={styles.row}>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Condition
                                                        </div>
                                                        <SelectDropdown
                                                            isInsideAccordion
                                                            selectedId={
                                                                props.values
                                                                    .quantityAndValueConditionTypeId
                                                                    ? props.values.quantityAndValueConditionTypeId.toString()
                                                                    : null
                                                            }
                                                            data={mapToDropdown(
                                                                this.props
                                                                    .quantityAndValueConditionTypes,
                                                                'typeId',
                                                                'typeName'
                                                            )}
                                                            placeholder="Select condition..."
                                                            onSelect={(
                                                                id: IOptions
                                                            ) => {
                                                                props.setFieldValue(
                                                                    'quantityAndValueConditionTypeId',
                                                                    Number(
                                                                        id.value
                                                                    )
                                                                )
                                                                this.checkForChanges()
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Value
                                                        </div>
                                                        <CurrencyInput
                                                            maxLength={10}
                                                            prefix="£"
                                                            className={
                                                                styles.input
                                                            }
                                                            handleChange={(
                                                                e,
                                                                maskedValue,
                                                                floatValue
                                                            ) => {
                                                                props.setFieldValue(
                                                                    'value',
                                                                    floatValue
                                                                )
                                                                if (
                                                                    !this.state
                                                                        .totalValueEdited &&
                                                                    Number(
                                                                        props
                                                                            .values
                                                                            .quantity
                                                                    ) > 0
                                                                ) {
                                                                    props.setFieldValue(
                                                                        'totalValue',
                                                                        Number(
                                                                            floatValue
                                                                        ) *
                                                                            Number(
                                                                                props
                                                                                    .values
                                                                                    .quantity
                                                                            )
                                                                    )
                                                                }
                                                            }}
                                                            value={
                                                                props.values
                                                                    .value
                                                            }
                                                            name="value"
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Quantity
                                                        </div>
                                                        <input
                                                            value={
                                                                props.values
                                                                    .quantity
                                                            }
                                                            onChange={(e) => {
                                                                if (
                                                                    e
                                                                        .currentTarget
                                                                        .value
                                                                        .length >
                                                                    8
                                                                ) {
                                                                    return
                                                                }

                                                                props.setFieldValue(
                                                                    'quantity',
                                                                    e
                                                                        .currentTarget
                                                                        .value
                                                                )
                                                                if (
                                                                    !this.state
                                                                        .totalValueEdited &&
                                                                    Number(
                                                                        e
                                                                            .currentTarget
                                                                            .value
                                                                    ) > 0
                                                                ) {
                                                                    props.setFieldValue(
                                                                        'totalValue',
                                                                        Number(
                                                                            props
                                                                                .values
                                                                                .value
                                                                        ) *
                                                                            Number(
                                                                                e
                                                                                    .currentTarget
                                                                                    .value
                                                                            )
                                                                    )
                                                                }
                                                            }}
                                                            type="number"
                                                            aria-label="Quantity"
                                                            className={
                                                                styles.input
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Total Value
                                                        </div>
                                                        <CurrencyInput
                                                            maxLength={10}
                                                            prefix="£"
                                                            className={
                                                                styles.input
                                                            }
                                                            handleChange={(
                                                                e,
                                                                maskedValue,
                                                                floatValue
                                                            ) => {
                                                                if (
                                                                    !floatValue
                                                                ) {
                                                                    this.setState(
                                                                        {
                                                                            totalValueEdited:
                                                                                false,
                                                                        }
                                                                    )
                                                                } else {
                                                                    this.setState(
                                                                        {
                                                                            totalValueEdited:
                                                                                true,
                                                                        }
                                                                    )
                                                                }
                                                                props.setFieldValue(
                                                                    'totalValue',
                                                                    floatValue
                                                                )
                                                            }}
                                                            value={
                                                                props.values
                                                                    .totalValue
                                                            }
                                                            name="totalValue"
                                                        />
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        </CollapsibleContent>
                                    </div>
                                </div>
                                {/* Energy */}
                                <div className={styles.row}>
                                    <div className={styles.column}>
                                        <CollapsibleContent title="Energy">
                                            <React.Fragment>
                                                {/* Row 1 */}
                                                <div className={styles.row}>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Energy Usage
                                                        </div>
                                                        <input
                                                            maxLength={10}
                                                            value={
                                                                props.values
                                                                    .energyUsage
                                                            }
                                                            onChange={(e) =>
                                                                props.setFieldValue(
                                                                    'energyUsage',
                                                                    e
                                                                        .currentTarget
                                                                        .value
                                                                )
                                                            }
                                                            type="number"
                                                            aria-label="Energy Usage"
                                                            className={
                                                                styles.input
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Energy Info
                                                        </div>
                                                        <input
                                                            maxLength={200}
                                                            value={
                                                                props.values
                                                                    .energyInfo
                                                            }
                                                            onChange={(e) =>
                                                                props.setFieldValue(
                                                                    'energyInfo',
                                                                    e
                                                                        .currentTarget
                                                                        .value
                                                                )
                                                            }
                                                            type="text"
                                                            aria-label="Energy Info"
                                                            className={
                                                                styles.input
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        </CollapsibleContent>
                                    </div>
                                </div>
                                {/* Meter readings */}
                                <div className={styles.row}>
                                    <div className={styles.column}>
                                        <CollapsibleContent title="Meter readings">
                                            <React.Fragment>
                                                {/* Row 1 */}
                                                <div className={styles.row}>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Meter 1 reading
                                                        </div>
                                                        <input
                                                            value={
                                                                props.values
                                                                    .meter1Reading
                                                            }
                                                            onChange={(e) =>
                                                                props.setFieldValue(
                                                                    'meter1Reading',
                                                                    e
                                                                        .currentTarget
                                                                        .value
                                                                )
                                                            }
                                                            type="number"
                                                            aria-label="Meter 1 Reading"
                                                            className={
                                                                styles.input
                                                            }
                                                            maxLength={10}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Meter 1 reading date
                                                        </div>
                                                        <DatePicker
                                                            className={
                                                                styles.input
                                                            }
                                                            todayButton="Today"
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={
                                                                props.values
                                                                    .meter1ReadingDate &&
                                                                moment(
                                                                    props.values
                                                                        .meter1ReadingDate
                                                                ).toDate()
                                                            }
                                                            onChange={(
                                                                date: Date
                                                            ) => {
                                                                props.setFieldValue(
                                                                    'meter1ReadingDate',
                                                                    date
                                                                )
                                                                this.checkForChanges()
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Meter 2 reading
                                                        </div>
                                                        <input
                                                            value={
                                                                props.values
                                                                    .meter2Reading
                                                            }
                                                            onChange={(e) =>
                                                                props.setFieldValue(
                                                                    'meter2Reading',
                                                                    e
                                                                        .currentTarget
                                                                        .value
                                                                )
                                                            }
                                                            type="number"
                                                            aria-label="Meter 2 Reading"
                                                            className={
                                                                styles.input
                                                            }
                                                            maxLength={10}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.column
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.label
                                                            }
                                                        >
                                                            Meter 2 reading date
                                                        </div>
                                                        <DatePicker
                                                            className={
                                                                styles.input
                                                            }
                                                            todayButton="Today"
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={
                                                                props.values
                                                                    .meter2ReadingDate &&
                                                                moment(
                                                                    props.values
                                                                        .meter2ReadingDate
                                                                ).toDate()
                                                            }
                                                            onChange={(
                                                                date: Date
                                                            ) => {
                                                                props.setFieldValue(
                                                                    'meter2ReadingDate',
                                                                    date
                                                                )
                                                                this.checkForChanges()
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        </CollapsibleContent>
                                    </div>
                                </div>
                                {/* buttons */}
                                <div ref={buttons} className={styles.buttons}>
                                    <StickyComponent>
                                        <Buttons
                                            buttons={[
                                                {
                                                    text: 'Cancel',
                                                    displayType: 'cancel',
                                                    elementType: 'button',
                                                    onClick: () =>
                                                        this.onCancel(),
                                                },
                                                {
                                                    text: 'Save',
                                                    displayType: 'submit',
                                                    elementType: 'button',
                                                    onClick: () =>
                                                        props.submitForm(),
                                                },
                                            ]}
                                        />
                                    </StickyComponent>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>

                <div className={styles.image}>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            {this.props.asset ? (
                                <ImageGalleryComponent
                                    onClickFullscreen
                                    dropdownActions={{
                                        onClickUpload: () =>
                                            this.setState({
                                                uploadingImages: true,
                                            }),
                                        onClickDelete: () => null,
                                        onClickOverride:
                                            this.props.deleteAssetImage,
                                        onChangePrimaryImage:
                                            this.onChangePrimaryImage,
                                    }}
                                    items={
                                        this.props.asset
                                            ? this.props.asset.images
                                            : []
                                    }
                                />
                            ) : (
                                <SFUploader
                                    hideClose
                                    options={{
                                        imageType:
                                            ImageTypeEnum.ASSET_IMAGE.toString(),
                                    }}
                                    onAdd={(e) =>
                                        this.setState({
                                            images: this.state.images.concat([
                                                e,
                                            ]),
                                        })
                                    }
                                    onRemove={(e) =>
                                        this.setState({
                                            images: this.state.images.filter(
                                                (x) => x != e
                                            ),
                                        })
                                    }
                                    callbackOnly={true}
                                />
                            )}
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <div className={styles.documents}>
                                <div className={styles.document}>
                                    <div className={styles.label}>Manual</div>
                                    {this.props.asset &&
                                    this.props.asset.assetId ? (
                                        <AssociatedDocuments
                                            onAddDocument={(id) =>
                                                this.onAddDocument(
                                                    id,
                                                    '8AA5A709-6117-4FD9-9EA6-8CC7086523E0'
                                                )
                                            }
                                            showUpload
                                            returnDocumentId
                                            showOnlyNoneText
                                            options={{
                                                assetId:
                                                    this.props.asset.assetId,
                                                propertyId:
                                                    this.props.asset.propertyId,
                                                propertyName:
                                                    this.props.asset
                                                        .propertyName,
                                                unitId: this.props.asset.unitId,
                                                unitName:
                                                    this.props.asset.unitName,
                                                documentType:
                                                    '8AA5A709-6117-4FD9-9EA6-8CC7086523E0',
                                            }}
                                        />
                                    ) : (
                                        <AssociatedDocuments
                                            onAddDocument={(id) =>
                                                this.onAddDocument(
                                                    id,
                                                    '8AA5A709-6117-4FD9-9EA6-8CC7086523E0'
                                                )
                                            }
                                            showUpload
                                            showOnlyNoneText
                                            returnDocumentId
                                            options={{
                                                documentsOverride:
                                                    this.state.documents.filter(
                                                        (d) =>
                                                            d.type ==
                                                            '8AA5A709-6117-4FD9-9EA6-8CC7086523E0'
                                                    ),
                                                isCreating: true,
                                                documentType:
                                                    '8AA5A709-6117-4FD9-9EA6-8CC7086523E0',
                                            }}
                                        />
                                    )}
                                </div>
                                <div className={styles.document}>
                                    <div className={styles.label}>Warranty</div>
                                    {this.props.asset &&
                                    this.props.asset.assetId ? (
                                        <AssociatedDocuments
                                            onAddDocument={(id) =>
                                                this.onAddDocument(
                                                    id,
                                                    '7A493135-A36C-49E1-A69B-F97A0623FC10'
                                                )
                                            }
                                            showUpload
                                            returnDocumentId
                                            showOnlyNoneText
                                            options={{
                                                assetId:
                                                    this.props.asset.assetId,
                                                propertyId:
                                                    this.props.asset.propertyId,
                                                propertyName:
                                                    this.props.asset
                                                        .propertyName,
                                                unitId: this.props.asset.unitId,
                                                unitName:
                                                    this.props.asset.unitName,
                                                documentType:
                                                    '7A493135-A36C-49E1-A69B-F97A0623FC10',
                                            }}
                                        />
                                    ) : (
                                        <AssociatedDocuments
                                            onAddDocument={(id) =>
                                                this.onAddDocument(
                                                    id,
                                                    '7A493135-A36C-49E1-A69B-F97A0623FC10'
                                                )
                                            }
                                            showUpload
                                            showOnlyNoneText
                                            returnDocumentId
                                            options={{
                                                documentsOverride:
                                                    this.state.documents.filter(
                                                        (d) =>
                                                            d.type ==
                                                            '7A493135-A36C-49E1-A69B-F97A0623FC10'
                                                    ),
                                                isCreating: true,
                                                documentType:
                                                    '7A493135-A36C-49E1-A69B-F97A0623FC10',
                                            }}
                                        />
                                    )}
                                </div>
                                <div className={styles.document}>
                                    <div className={styles.label}>
                                        Financials
                                    </div>
                                    {this.props.asset &&
                                    this.props.asset.assetId ? (
                                        <AssociatedDocuments
                                            onAddDocument={(id) =>
                                                this.onAddDocument(
                                                    id,
                                                    'DD9FF29D-6094-43F5-B39F-C03571E0E248'
                                                )
                                            }
                                            showUpload
                                            returnDocumentId
                                            showOnlyNoneText
                                            options={{
                                                assetId:
                                                    this.props.asset.assetId,
                                                propertyId:
                                                    this.props.asset.propertyId,
                                                propertyName:
                                                    this.props.asset
                                                        .propertyName,
                                                unitId: this.props.asset.unitId,
                                                unitName:
                                                    this.props.asset.unitName,
                                                documentType:
                                                    'DD9FF29D-6094-43F5-B39F-C03571E0E248',
                                            }}
                                        />
                                    ) : (
                                        <AssociatedDocuments
                                            onAddDocument={(id) =>
                                                this.onAddDocument(
                                                    id,
                                                    'DD9FF29D-6094-43F5-B39F-C03571E0E248'
                                                )
                                            }
                                            showUpload
                                            showOnlyNoneText
                                            returnDocumentId
                                            options={{
                                                documentsOverride:
                                                    this.state.documents.filter(
                                                        (d) =>
                                                            d.type ==
                                                            'DD9FF29D-6094-43F5-B39F-C03571E0E248'
                                                    ),
                                                isCreating: true,
                                                documentType:
                                                    'DD9FF29D-6094-43F5-B39F-C03571E0E248',
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.props.asset && this.props.asset.assetId && (
                    <SFUploaderModal
                        open={this.state.uploadingImages}
                        onToggle={(open: boolean) =>
                            this.setState({ uploadingImages: open })
                        }
                        urls={{
                            saveUrl: `${imageService}/Save`,
                            removeUrl: `${imageService}/Remove`,
                        }}
                        onFileUpload={(imageIds?: string[]) =>
                            updateToastMessage(
                                'Asset image has been uploaded successfully',
                                'success'
                            )
                        }
                        successCallback={() => {
                            this.setState({ uploadingImages: false })
                            this.props.getAssetImages(this.props.asset.assetId)
                        }}
                        options={{
                            imageType: ImageTypeEnum.ASSET_IMAGE,
                            parentId: this.props.asset.assetId,
                        }}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    productStatusTypes: state.assetDropdownTypes.productStatusTypes,
    serviceScheduleTypes: state.assetDropdownTypes.serviceScheduleTypes,
    quantityAndValueConditionTypes:
        state.assetDropdownTypes.quantityAndValueConditionTypes,
    checkListAssetTypes: state.assetTypes.data,
    allAssetTypes: state.assetTypes.allAssetTypes,
    assetTypesLoading: state.assetTypes.loading,
    loading: state.assets.loading,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getAssetImages,
            deleteAssetImage,
            getProductStatusTypes,
            getQuantityAndValueConditionTypes,
            getServiceScheduleTypes,
            getAssetTypes,
            addAsset,
            updateAsset,
        },
        dispatch
    )

export default withRouter<IProps,any>(
    connect(mapStateToProps, mapDispatchToProps)(AssetAddEditForm)
)
