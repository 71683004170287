import { setValue } from '@syncfusion/ej2-base';
import { ODataV4Adaptor } from '@syncfusion/ej2-data';
import { DataResult } from '@syncfusion/ej2-react-grids';
import { normaliseTransactionData } from '../_config/dataConfig';
import { ITransactionItem } from '../../../../../types/Transaction';

export class CustomODataV4Adapter extends ODataV4Adaptor {

    public processResponse() {
        let i: number = 0;
        // calling base class processResponse function
        const original: any = super.processResponse.apply(this, arguments);

        //original.result = normaliseTransactionData(original.result);
        return original;
    }
}