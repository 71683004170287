import { DataManager } from '@syncfusion/ej2-data';
import { IDocumentListItemOptions, IListItemOptions } from '../../../../../types/ListFilters';
import { documentServiceOdataFriendly } from '../../../../../configuration/domains';
import { createBearerToken } from '../../../../../helpers/authHelpers';
import { toQueryString } from '../../../../../services/DocumentService';
import { CustomODataV4Adapter } from '../_adapters';

export function generateOdataSource(listItemOptions?: IListItemOptions, documentItemOptions?: IDocumentListItemOptions): DataManager {
    let stringed = toQueryString(listItemOptions);
    let stringed2 = toQueryString(documentItemOptions);

    //if (activeStatus != null) {
    //    if (filter) {
    //        filter += ` and (active eq ${activeStatus})`;
    //    } else {
    //        filter = `&$filter=(active eq ${activeStatus})`;
    //    }
    //}

    let dataManager: DataManager = new DataManager({
        adaptor: new CustomODataV4Adapter,
        url: `${documentServiceOdataFriendly}?${stringed}&${stringed2}`,
        headers: [
            { "Authorization": createBearerToken() }
        ]
    });

    return dataManager;
}