import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { RootState } from '../../../reducers/rootReducer';
import { useAppDispatch } from '../../../store/hooks'
import { tasksApi, useLazyFetchTasksQuery } from '../../../servicesRtk/TasksServiceRtk'
import { Formik, FormikProps } from 'formik';
import { Loader } from '@rentchief/components';
import RotatingLoader from '../../atoms/RotatingLoader';
import StickyComponent from '../../atoms/StickyComponent';
import Buttons from '../../atoms/Buttons'
import { IRegulation } from '../../../reducers/propertyReducer';
import { getPropertyDetails, updatePropertyRegulations } from '../../../services/PropertyDetail'
import { IPropertyDetail, IUpdateRegulationsRequest } from '../../../types/PropertyDetailService'
import { updateToastMessage } from '../../../helpers/toastHelper';
import { dateWithoutTime, correctDate } from '../../../helpers/dateHelpers';
import { hasClientArea } from '../../../helpers/clientHelpers'
import { ITasksListItemOptions, ITaskDto } from '../../../types/Tasks' 
import KeyDatesSection from './KeyDatesSection/KeyDatesSection.component';
import RegulationChecks from './RegulationChecks/RegulationChecks.component';
import { IKeyDatesConfig } from './_config/config';
import { hmoTooltip, epcTooltip, eicrTooltip, gasSafetyTooltip, landlordInsuranceTooltip, propertyInspectionTooltip, mortgageRateTooltip, selectiveLicenseTooltip } from './_config/tooltipConfig';
import * as S from './KeyDates.styles'

const KeyDates = () => {
    const dispatch = useAppDispatch();

    // Redux 
    const { propertyId, propertyName  } = useSelector((state: RootState) => state.property.property_data.property_data);

    const [listOptions, setListOptions] = useState<ITasksListItemOptions>({ page : 0, size : 9999, skip : 0, onlyShowOwnRecords : false, taskStatusIds: '1,2,3,4', propertyId: propertyId});
    //const { data: tasksListData } = useFetchTasksQuery(listOptions);
    const [fetchTasksListData, { data: tasksListData }] = useLazyFetchTasksQuery();

    const history = useHistory();

    const config = useRef<IKeyDatesConfig>(require("./_config/config.json"));

    const [keyDatesData, setKeyDatesData] = useState<IRegulation>(null);
    const [origKeyDatesData, setOrigKeyDatesData] = useState<IRegulation>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [highlightedDate, setHighlightedDate] = useState<string>(null);
    //const [regulationData, setRegulationData] = useState<IRegulation>();


    useEffect(() => {
        getDetails();
      }, []);

      useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if(queryParams.has('highlightedDate')){
            let value = queryParams.get('highlightedDate');
            setHighlightedDate(value);
            queryParams.delete('highlightedDate');
        }

        history.replace({ search: queryParams.toString() });

        fetchTasksListData(listOptions);
      }, []);

    const getDetails = async () => {
        if (!propertyId) {
            return
        }

        setLoading(true);
        let resp = await getPropertyDetails(propertyId);
        if (resp && resp.data) {
            let data: IPropertyDetail = resp.data
            let response = {
                regulation: {
                    gasAppliances: data.gasAppliances,
                    epcCertificateRequired: data.epcCertificateRequired,
                    epcExpiryDate: data.epcExpiryDate && dateWithoutTime(data.epcExpiryDate),
                    gasExpiryDate: data.gasExpiryDate && dateWithoutTime(data.gasExpiryDate),
                    smokeDetectors: data.smokeDetectors,
                    smartMeters: data.smartMeters,
                    carbonMonoxide: data.carbonMonoxide,
                    hmoLicence: data.hmoLicence,
                    hmoLicenceExpires: data.hmoLicenceExpires && dateWithoutTime(data.hmoLicenceExpires),
                    legionellaAssessment: data.legionellaAssessment,
                    electricalInspectionRequired: data.electricalInspectionRequired,
                    electricalCheckInspection: data.electricalCheckInspection && dateWithoutTime(data.electricalCheckInspection),
                    landlordInsuranceExpiry: data.landlordInsuranceExpiry && dateWithoutTime(data.landlordInsuranceExpiry),
                    mortgageRateExpiry: data.mortgageRateExpiry && dateWithoutTime(data.mortgageRateExpiry),
                    selectiveLicenseExpiry: data.selectiveLicenseExpiry && dateWithoutTime(data.selectiveLicenseExpiry),
                    nextPropertyInspection: data.nextPropertyInspection && dateWithoutTime(data.nextPropertyInspection),
                    landlordInsuranceRequired: data.landlordInsuranceRequired,
                    propertyInspectioneRequired: data.propertyInspectioneRequired,
                    mortgageRateUpdateRequired: data.mortgageRateUpdateRequired,
                    selectiveLicenseRequired: data.selectiveLicenseRequired,
                    gasCertificateRequired: data.gasAppliances,
                    hmoLicenceRequired: data.hmoLicenceRequired,
                },
            }

            setKeyDatesData(response.regulation);
            setOrigKeyDatesData(response.regulation);

            setLoading(false);
        }
    }

    const saveChanges = async (changes) => {
        if (!propertyId) {
            return
        }

        let updatedRegs: IRegulation = {
            propertyId: propertyId,
            epcExpiryDate: correctDate(changes.energyPerformanceCertificateDate),
            gasExpiryDate: correctDate(changes.gasSafetyCertificateDate),
            smokeDetectors: changes.smokeDetectors,
            carbonMonoxide: changes.carbonMonoxide,
            hmoLicenceExpires: correctDate(changes.hmoLicenceDate),
            legionellaAssessment: changes.legionellaAssessment,
            electricalCheckInspection: correctDate(changes.electricalSafetyCheckDate),
            landlordInsuranceExpiry: correctDate(changes.landlordInsuranceExpiry),
            mortgageRateExpiry: correctDate(changes.mortgageRateExpiry),
            selectiveLicenseExpiry: correctDate(changes.selectiveLicenseExpiry),
            nextPropertyInspection: correctDate(changes.nextPropertyInspection),
            landlordInsuranceRequired: changes.landlordInsuranceRequired,
            propertyInspectioneRequired: changes.propertyInspectioneRequired,
            mortgageRateUpdateRequired: changes.mortgageRateUpdateRequired,
            selectiveLicenseRequired: changes.selectiveLicenseRequired,
            gasCertificateRequired: changes.gasCertificateRequired,
            electricalInspectionRequired: changes.electricalInspectionRequired,
            epcCertificateRequired: changes.epcCertificateRequired,
            hmoLicenceRequired: changes.hmoLicenceRequired
        }
        setSaving(true);
        setKeyDatesData(updatedRegs);
        setHighlightedDate(null);

        let request: IUpdateRegulationsRequest = {
            propertyId: propertyId,
            propertyName: propertyName,
            original: origKeyDatesData, 
            updated: updatedRegs
        }

        let resp = await updatePropertyRegulations(request);
        if(resp){
            setSaving(false);
            if (resp.status == 200) {
                updateToastMessage('Saved', 'success')
                //fetchTasksListData(listOptions);
                dispatch(tasksApi.endpoints.fetchTasks.initiate(listOptions, {forceRefetch: true}));
            }
        }
    }

    const navigateToTask = (systemType: string) => {
        let task = tasksListData.data.data.find(t => t.systemType == systemType);
        if(task){
            let url = `/dashboard/task?taskId=${task.taskId}`;
            history.push(url);
        }
    }

    const getInitialState = () => {
        let reData = null;
        if(keyDatesData != undefined || keyDatesData != null){
            reData = {
                gasSafetyCertificateDate: keyDatesData.gasExpiryDate,
                electricalSafetyCheckDate: keyDatesData.electricalCheckInspection,
                energyPerformanceCertificateDate: keyDatesData.epcExpiryDate,
                hmoLicenceDate: keyDatesData.hmoLicenceExpires,
                landlordInsuranceExpiry: keyDatesData.landlordInsuranceExpiry,
                mortgageRateExpiry: keyDatesData.mortgageRateExpiry,
                selectiveLicenseExpiry: keyDatesData.selectiveLicenseExpiry,
                nextPropertyInspection: keyDatesData.nextPropertyInspection,
                landlordInsuranceRequired: keyDatesData.landlordInsuranceRequired != null ? keyDatesData.landlordInsuranceRequired : config.current.landlordInsurancePolicyDate.defaultActive,
                propertyInspectioneRequired: keyDatesData.propertyInspectioneRequired != null ? keyDatesData.propertyInspectioneRequired : config.current.propertyInspectionDate.defaultActive,
                mortgageRateUpdateRequired: keyDatesData.mortgageRateUpdateRequired != null ? keyDatesData.mortgageRateUpdateRequired: config.current.mortgageRateExpiryDate.defaultActive,
                selectiveLicenseRequired: keyDatesData.selectiveLicenseRequired != null ? keyDatesData.selectiveLicenseRequired: config.current.selectiveLicenseDate.defaultActive,
                gasCertificateRequired: keyDatesData.gasCertificateRequired != null ? keyDatesData.gasCertificateRequired : config.current.gasSafetyCertificateDate.defaultActive,
                electricalInspectionRequired: keyDatesData.electricalInspectionRequired != null ? keyDatesData.electricalInspectionRequired : config.current.electricalSafetyCheckDate.defaultActive,
                epcCertificateRequired: keyDatesData.epcCertificateRequired != null ? keyDatesData.epcCertificateRequired : config.current.energyPerformanceCertificateDate.defaultActive,
                hmoLicenceRequired: keyDatesData.hmoLicenceRequired != null ? keyDatesData.hmoLicenceRequired : config.current.hmoLicenceDate.defaultActive,
                smokeDetectors: keyDatesData.smokeDetectors,
                carbonMonoxide: keyDatesData.carbonMonoxide,
                legionellaAssessment: keyDatesData.legionellaAssessment
            }
        }else{
            reData = {
                gasSafetyCertificateDate: null,
                electricalSafetyCheckDate: null,
                energyPerformanceCertificateDate: null,
                hmoLicenceDate: null,
                landlordInsuranceExpiry: null,
                mortgageRateExpiry: null,
                selectiveLicenseExpiry: null,
                nextPropertyInspection: null,
                landlordInsuranceRequired: config.current.landlordInsurancePolicyDate.defaultActive,
                propertyInspectioneRequired: config.current.propertyInspectionDate.defaultActive,
                mortgageRateUpdateRequired: config.current.mortgageRateExpiryDate.defaultActive,
                selectiveLicenseRequired: config.current.selectiveLicenseDate.defaultActive,
                gasCertificateRequired: config.current.gasSafetyCertificateDate.defaultActive,
                electricalInspectionRequired: config.current.electricalSafetyCheckDate.defaultActive,
                epcCertificateRequired: config.current.energyPerformanceCertificateDate.defaultActive,
                hmoLicenceRequired: config.current.hmoLicenceDate.defaultActive,
                smokeDetectors: false,
                carbonMonoxide: false,
                legionellaAssessment: false
            }
        } 
        return reData;
    }

    if(loading){
        return <RotatingLoader
            text="Loading your Regulations..."
            loading={loading}
        />
    }

    if(saving){
        return <RotatingLoader
            text="Saving Updates..."
            loading={saving}
        />
    }

    return (
        <>
            <S.ContainerDiv>
                <Formik
                    initialValues={getInitialState()}
                    onSubmit={(v) => saveChanges(v) }
                    enableReinitialize={true}
                >
                    {(props: FormikProps<{
                        gasSafetyCertificateDate: Date;
                        electricalSafetyCheckDate: Date;
                        energyPerformanceCertificateDate: Date;
                        hmoLicenceDate: Date;
                        smokeDetectors: boolean;
                        carbonMonoxide: boolean;
                        legionellaAssessment: boolean;
                        landlordInsuranceExpiry: Date
                        mortgageRateExpiry: Date
                        selectiveLicenseExpiry: Date
                        nextPropertyInspection: Date
                        landlordInsuranceRequired?: boolean
                        propertyInspectioneRequired?: boolean
                        mortgageRateUpdateRequired?: boolean
                        selectiveLicenseRequired?: boolean
                        gasCertificateRequired?: boolean;
                        electricalInspectionRequired?: boolean;
                        epcCertificateRequired?: boolean;
                        hmoLicenceRequired?: boolean;
                    }>) => (
                        <>
                            <S.SummaryDiv>Record key regulation dates, associated documentation and receive reminders ahead of deadlines. Use the switches to mark which ones are required for your property..</S.SummaryDiv>
                            <S.SectionDiv gridArea='landlordInsurancePolicyDate'>
                                <KeyDatesSection
                                    title={config.current.landlordInsurancePolicyDate.title}
                                    description={config.current.landlordInsurancePolicyDate.description}
                                    expiryDate={props.values.landlordInsuranceExpiry}
                                    docType='E0378110-8854-442F-8530-188A4727AD85'
                                    tooltip={landlordInsuranceTooltip}
                                    onDateChanged={(date) => props.setFieldValue('landlordInsuranceExpiry', date)}
                                    isActive={props.values.landlordInsuranceRequired}
                                    onActiveChanged={(active) => props.setFieldValue('landlordInsuranceRequired', active)}
                                    highlighted={highlightedDate == config.current.landlordInsurancePolicyDate.id}
                                    onDetailsClick={() => navigateToTask(config.current.landlordInsurancePolicyDate.id)}
                                />
                            </S.SectionDiv>
                            <S.SectionSpacerDiv />
                            <S.SectionDiv gridArea='propertyInspectionDate'>
                                <KeyDatesSection
                                    title={config.current.propertyInspectionDate.title}
                                    description={config.current.propertyInspectionDate.description}
                                    expiryDate={props.values.nextPropertyInspection}
                                    docType='85BA3702-2186-4ACC-B489-6A80E692894F'
                                    tooltip={propertyInspectionTooltip}
                                    onDateChanged={(date) => props.setFieldValue('nextPropertyInspection', date)}
                                    isActive={props.values.propertyInspectioneRequired}
                                    onActiveChanged={(active) => props.setFieldValue('propertyInspectioneRequired', active)}
                                    highlighted={highlightedDate == config.current.propertyInspectionDate.id}
                                    onDetailsClick={() => navigateToTask(config.current.propertyInspectionDate.id)}
                                />
                            </S.SectionDiv>
                            <S.SectionSpacerDiv />
                            <S.SectionDiv gridArea='mortgageRateExpiryDate'>
                                <KeyDatesSection
                                    title={config.current.mortgageRateExpiryDate.title}
                                    description={config.current.mortgageRateExpiryDate.description}
                                    expiryDate={props.values.mortgageRateExpiry}
                                    docType='C318A7F4-5445-4274-9034-9A94453B8913'
                                    tooltip={mortgageRateTooltip}
                                    onDateChanged={(date) => props.setFieldValue('mortgageRateExpiry', date)}
                                    isActive={props.values.mortgageRateUpdateRequired}
                                    onActiveChanged={(active) => props.setFieldValue('mortgageRateUpdateRequired', active)}
                                    highlighted={highlightedDate == config.current.mortgageRateExpiryDate.id}
                                    onDetailsClick={() => navigateToTask(config.current.mortgageRateExpiryDate.id)}
                                />
                            </S.SectionDiv>
                            <S.SectionSpacerDiv />
                            <S.SectionDiv gridArea='gasSafetyCertificateDate'>
                                <KeyDatesSection 
                                    title={config.current.gasSafetyCertificateDate.title}
                                    description={config.current.gasSafetyCertificateDate.description}
                                    expiryDate={props.values.gasSafetyCertificateDate}
                                    docType='417ac960-cd11-4c76-8ff2-b09f3164c076'
                                    tooltip={gasSafetyTooltip}
                                    onDateChanged={(date) => props.setFieldValue('gasSafetyCertificateDate', date)}
                                    isActive={props.values.gasCertificateRequired}
                                    onActiveChanged={(active) => props.setFieldValue('gasCertificateRequired', active)}
                                    highlighted={highlightedDate == config.current.gasSafetyCertificateDate.id}
                                    onDetailsClick={() => navigateToTask(config.current.gasSafetyCertificateDate.id)}
                                />
                            </S.SectionDiv>
                            <S.SectionSpacerDiv />
                            <S.SectionDiv gridArea='electricalSafetyCheckDate'>
                                <KeyDatesSection
                                    title={config.current.electricalSafetyCheckDate.title}
                                    description={config.current.electricalSafetyCheckDate.description}
                                    expiryDate={props.values.electricalSafetyCheckDate}
                                    docType='e54f2818-682a-473a-b510-2cb1c6b3088e'
                                    tooltip={eicrTooltip}
                                    onDateChanged={(date) => props.setFieldValue('electricalSafetyCheckDate', date)}
                                    isActive={props.values.electricalInspectionRequired}
                                    onActiveChanged={(active) => props.setFieldValue('electricalInspectionRequired', active)}
                                    highlighted={highlightedDate == config.current.electricalSafetyCheckDate.id}
                                    onDetailsClick={() => navigateToTask(config.current.electricalSafetyCheckDate.id)}
                                />
                            </S.SectionDiv>
                            <S.SectionSpacerDiv />
                            <S.SectionDiv gridArea='energyPerformanceCertificateDate'>
                                <KeyDatesSection
                                    title={config.current.energyPerformanceCertificateDate.title}
                                    description={config.current.energyPerformanceCertificateDate.description}
                                    expiryDate={props.values.energyPerformanceCertificateDate}
                                    docType='11eb9db3-1e55-4136-9615-0cb530654070'
                                    tooltip={epcTooltip}
                                    onDateChanged={(date) => props.setFieldValue('energyPerformanceCertificateDate', date)}
                                    isActive={props.values.epcCertificateRequired}
                                    onActiveChanged={(active) => props.setFieldValue('epcCertificateRequired', active)}
                                    highlighted={highlightedDate == config.current.energyPerformanceCertificateDate.id}
                                    onDetailsClick={() => navigateToTask(config.current.energyPerformanceCertificateDate.id)}
                                />
                            </S.SectionDiv>
                            <S.SectionSpacerDiv />
                            <S.SectionDiv gridArea='hmoLicenceDate'>
                                <KeyDatesSection
                                    title={config.current.hmoLicenceDate.title}
                                    description={config.current.hmoLicenceDate.description}
                                    expiryDate={props.values.hmoLicenceDate}
                                    docType='7f00b2b7-7fa9-442f-922e-7734ff31d8bf'
                                    tooltip={hmoTooltip}
                                    onDateChanged={(date) => props.setFieldValue('hmoLicenceDate', date)}
                                    isActive={props.values.hmoLicenceRequired}
                                    onActiveChanged={(active) => props.setFieldValue('hmoLicenceRequired', active)}
                                    highlighted={highlightedDate == config.current.hmoLicenceDate.id}
                                    onDetailsClick={() => navigateToTask(config.current.hmoLicenceDate.id)}
                                />
                            </S.SectionDiv>
                            <S.SectionSpacerDiv />
                            <S.SectionDiv gridArea='selectiveLicenseDate'>
                                <KeyDatesSection
                                    title={config.current.selectiveLicenseDate.title}
                                    description={config.current.selectiveLicenseDate.description}
                                    expiryDate={props.values.selectiveLicenseExpiry}
                                    docType='0489E611-6B71-49EF-93F5-9BE65542E3C9'
                                    tooltip={selectiveLicenseTooltip}
                                    onDateChanged={(date) => props.setFieldValue('selectiveLicenseExpiry', date)}
                                    isActive={props.values.selectiveLicenseRequired}
                                    onActiveChanged={(active) => props.setFieldValue('selectiveLicenseRequired', active)}
                                    highlighted={highlightedDate == config.current.selectiveLicenseDate.id}
                                    onDetailsClick={() => navigateToTask(config.current.selectiveLicenseDate.id)}
                                />
                            </S.SectionDiv>
                            <S.SectionSpacerDiv />
                            <S.SectionDiv gridArea='regulationChecks'>
                                <RegulationChecks
                                    regData={keyDatesData}
                                    onRegsChanged={(reg: IRegulation) => 
                                        { 
                                            props.setFieldValue('smokeDetectors', reg.smokeDetectors);
                                            props.setFieldValue('carbonMonoxide', reg.carbonMonoxide);
                                            props.setFieldValue('legionellaAssessment', reg.legionellaAssessment);
                                        }}
                                />
                            </S.SectionDiv>
                            <S.SaveCancelDiv>
                                <StickyComponent>
                                    <Buttons
                                            buttons={[
                                                {
                                                    displayType: 'cancel',
                                                    elementType: 'button',
                                                    onClick: () => {
                                                        /* let changes = this.checkForChanges()
                                                        if (changes) {
                                                            this.setState({outstandingChangesModalOpen: true})
                                                            return
                                                        } */

                                                        history.push(
                                                            `/dashboard/property/${propertyId}/summary`
                                                        )
                                                    },
                                                },
                                                {
                                                    displayType: 'submit',
                                                    elementType: 'button',
                                                    onClick: () => props.submitForm(),
                                                }
                                            ]}
                                        />
                                </StickyComponent>
                            </S.SaveCancelDiv>
                        </>
                    )}
                </Formik>
            </S.ContainerDiv>
        </>
    )
}

export default KeyDates;