import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from "formik";
import { doesPropertyHaveUnits } from "../../../../services/UnitService";
import { IAddTenancyOptions } from "../../../../helpers/locationHelpers";
import { getCurrentDate } from "../../../../helpers/timerHelpers";
import { ITenancyItem, ITenancyRequirement } from "../../../../types/Tenancy";
import { AddTenancy } from "../../../../YupValidationSchemas";
import Buttons from "../../../atoms/Buttons";
import SearchContactInput from "../../../atoms/SearchContactInput";
import { 
    calculateDepositFromMonthlyRent, 
    calculateEndDateFromDuration,
    calculateDaysCountBetweenDates } from "../../../../helpers/tenancyHelpers";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ISearchResult } from "../../../../types/SearchService/ISearchService";
import SearchPropertyInput from "../../../atoms/SearchPropertyInput";
import SelectDropdown, { IOptions } from "../../../atoms/SelectDropdown";
import MaterialRadioButtons from "../../../atoms/MaterialRadioButtons";
import TenantDetailsSection from "../TenantDetailsSection/TenantDetailsSection.component";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import chevronDown from "../../../../images/icons/chevronDown.png";
import taskIcon from "../../../../images/icons/taskIcon.svg";
import taskIconHover from "../../../../images/icons/taskIconHover.svg";
import { ITenancyDetailsConfig } from "./_config/config";
import { RCCurrencyInput } from "@rentchief/components";
import { despositSchemes, durations, rentPaymentDurations } from "./_config/staticData";
import AssociatedDocuments from "../../../atoms/AssociatedDocuments";
import { IDocumentReturn } from "../../../molecules/AddDocumentModal";
import StickyComponent from "../../../atoms/StickyComponent";
import { addTenancy, getPrepopulatedTenancyInformation, getTenancyRequirement, updateTenancy } from "../../../../services/TenancyService";
import { tasksApi, useLazyFetchTasksQuery } from "../../../../servicesRtk/TasksServiceRtk";
import { ITaskDto, ITasksListItemOptions } from "../../../../types/Tasks";
import { goToRoute } from "../../../../history";
import { processToast } from "../../../../helpers/toastHelper";
import { correctDate } from "../../../../helpers/dateHelpers";
import { IDataResponse } from "../../../../types/ApiService";
import RotatingLoader from "../../../atoms/RotatingLoader";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import SearchUnitInput from "../../../atoms/SearchUnitInput";
import { IAddTenantCallback } from "../../../molecules/AddContactForm";
import OutstandingChangesModal from '../../../atoms/OutstandingChangesModal'
import lodashObject from 'lodash/fp'
import { useAppDispatch } from "../../../../store/hooks";
import { rentReviewDateTooltip, depositTooltip, depositSchemeTooltip, contractTooltip, inventoryTooltip } from "./_config/tooltipConfig"
import * as S from './TenancyDetailsPage.styles'
import styles from './TenancyDetailsPage.module.scss'
import { useFetchTenancyData } from "../../../../servicesHooks/useFetchTenancyData.hook";
import { useFetchTenancyEntityData } from "../../../../servicesHooks/useFetchTenancyEntityData.hook";
import RCCheckbox from "../../../atoms/RCCheckbox/RCCheckbox.component";

const TenancyDetailsPage = () => {
    const dispatch = useAppDispatch();

    // Redux 
    const { property_data } = useSelector((state: RootState) => state.property.property_data);
    const { viewing_unit } = useSelector((state: RootState) => state.unit);

    const { data : tenancy, dataRetrieved } = useFetchTenancyData();
    const { tenancyTypes, contractTypes, tenancyStatus, rentPayTypes } = useFetchTenancyEntityData();

    const [listOptions, setListOptions] = useState<ITasksListItemOptions>({ page : 0, size : 9999, skip : 0, onlyShowOwnRecords : false, taskStatusIds: '1,2,3,4', propertyId: property_data.propertyId});
    const [fetchTasksListData, { data: tasksListData }] = useLazyFetchTasksQuery();
    
    const history = useHistory();
    const location = useLocation();

    const config = useRef<ITenancyDetailsConfig>(require("./_config/config.json"));

    const [tenancyItem, setTenancyItem] = useState<ITenancyItem>(null);
    const [doesPropertyHaveUnitsLocal, setDoesPropertyHaveUnitsLocal] = useState<Boolean>(false);
    const [expanded, setExpanded] = React.useState<string | false>(false);
    
    // Linked
    const [documentIds, setDocumentIds] = useState<Array<IDocumentReturn>>([]);

    const[submitting, setSubmitting] = useState<boolean>(false);
    const [changesModalOpen, setChangesModalOpen] = useState(false)
    const [startDateTaskId, setStartDateTaskId] = useState<number>(null);
    const [endDateTaskId, setEndDateTaskId] = useState<number>(null);
    const [rentReviewDateTaskId, setRentReviewDateTaskId] = useState<number>(null);

    var initialTenancy = useRef<ITenancyItem>(null);

    var rentReviewDate = new Date();
    rentReviewDate.setFullYear(rentReviewDate.getFullYear() + 1);
    var initialDate = getCurrentDate();
    
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        var addTenantCallback = queryParams.get('addTenantCallback');
        if(addTenantCallback){
            let data: IAddTenantCallback = JSON.parse(addTenantCallback);

            setTenancyItem({ 
                ...tenancyItem,
                contactId: data.contactId
            });

            queryParams.delete('addTenantCallback');
            history.replace({ search: queryParams.toString() });
        }
    }, [location.search]);

    useEffect(() => {
        // If this is a clone call but the tenancy data has not been fetched yet then do nothing and return
        const queryParams = new URLSearchParams(location.search)
        if(queryParams.has('addTenancyOptions')){
            let obj: IAddTenancyOptions = JSON.parse(queryParams.get('addTenancyOptions'));
            if(obj.cloneTenancyId && !tenancy){
                return;
            }
        }
        
        if(tenancy){
            var correctedTenancy: ITenancyItem = null;
            if(queryParams.has('addTenancyOptions')){
                let obj: IAddTenancyOptions = JSON.parse(queryParams.get('addTenancyOptions'));
                queryParams.delete('addTenancyOptions');
                history.replace({ search: queryParams.toString() });

                if(obj.cloneTenancyId){
                    correctedTenancy = {
                        ...tenancy,
                        tenancyId: undefined
                    }

                    setDatesForClonedTenancy(correctedTenancy);
                }
            }else{
                correctedTenancy = {
                    ...tenancy,
                    startDate: new Date(tenancy.startDate),
                    endDate: new Date(tenancy.endDate),
                    rentReviewDate: new Date(tenancy.rentReviewDate),
                    moveInDate: new Date(tenancy.moveInDate),
                    moveOutDate: new Date(tenancy.moveOutDate)
                }
            }
            
            setTenancyItem(correctedTenancy);
            initialTenancy.current = {...correctedTenancy};
            if(correctedTenancy.unitId){
                setDoesPropertyHaveUnitsLocal(true);
            }else{
                checkIfPropertyHasUnits(correctedTenancy.propertyId);
            }
            fetchTasksListData(listOptions);
        }else{
            let propId, propName, unitId, unitName, contactId = null;
            if(queryParams.has('addTenancyOptions')){
                let obj: IAddTenancyOptions = JSON.parse(queryParams.get('addTenancyOptions'));

                queryParams.delete('addTenancyOptions');
                history.replace({ search: queryParams.toString() });

                propId = obj.propertyId;
                propName = obj.propertyName;
                unitId = obj.unitId;
                unitName = obj.unitName;
                contactId = obj.contactId;
            }else if(property_data && property_data.propertyId){
                propId = property_data.propertyId;
                propName = property_data.propertyName;
            }else if(viewing_unit){
                propId = viewing_unit.propertyId;
                propName = viewing_unit.propertyName;
                unitId = viewing_unit.unitId;
                unitName = viewing_unit.unitName;
            }

            if(propId){
                checkIfPropertyHasUnits(propId);
            }

            var defaultNewTenancy: ITenancyItem = {
                startDate: initialDate,
                moveInDate: initialDate,
                durationLength: 6,
                durationPeriod: "Months",
                rentReviewDate,
                rentTakenOnDay: new Date().getDate(),
                endDate: setDefaultEndDate(),
                moveOutDate: setDefaultEndDate(),
                frequency: "Monthly",
                tenantNoticePeriodDuration: 1,
                tenantNoticePeriodRange: "Months",
                landlordNoticePeriodDuration: 2,
                landlordNoticePeriodRange: "Months",
                tenantName: null,
                rollingContract: false,
                autoGenerateTransactions: true,
                markPastAsPaid: true,
                propertyId: propId,
                propertyName: propName,
                unitId: unitId,
                unitName: unitName,
                contactId: contactId,
                tenancyStatusId: "4163e4c5-ef43-4f75-8309-18d3f9dcf3ef",
                tenancyTypeId: "dc5ffbdb-b796-41b5-8ee0-ab8af5466214",
                contractTypeId: "312e7e75-10c8-4c71-9720-3c300cf1dab1",
                depositSchemeName: undefined,
                depositSchemeReference: undefined,
                documents: undefined,
                reference: undefined,
                rentPaymentTypeId: undefined,
                tenancyId: undefined
            } as ITenancyItem

            getOptions(defaultNewTenancy);

            setTenancyItem(defaultNewTenancy);
            initialTenancy.current = {...defaultNewTenancy}
        }
    }, [tenancy]);

    useEffect(() => {
        if(tasksListData){
            let startDateTask: ITaskDto = tasksListData.data.data.find(t => t.systemType == 22 && t.tenancyId == tenancyItem.tenancyId);
            if(startDateTask && startDateTask.taskStatusId != '5'){
                setStartDateTaskId(startDateTask.taskId);
            }
            let endDateTask = tasksListData.data.data.find(t => t.systemType == 21 && t.tenancyId == tenancyItem.tenancyId);
            if(endDateTask && endDateTask.taskStatusId != '5'){
                setEndDateTaskId(endDateTask.taskId);
            }
            let rentReviewDateTask = tasksListData.data.data.find(t => t.systemType == 20 && t.tenancyId == tenancyItem.tenancyId);
            if(rentReviewDateTask && rentReviewDateTask.taskStatusId != '5'){
                setRentReviewDateTaskId(rentReviewDateTask.taskId);
            }
        }
    }, [tasksListData])

    const getOptions = (tenancyItem: ITenancyItem) => {
        let propId, propName, unitId, unitName, contactId = null;
        const queryParams = new URLSearchParams(location.search)
        if(queryParams.has('addTenancyOptions')){
            let obj: IAddTenancyOptions = JSON.parse(queryParams.get('addTenancyOptions'));

            queryParams.delete('addTenancyOptions');
            history.replace({ search: queryParams.toString() });

            propId = obj.propertyId;
            propName = obj.propertyName;
            unitId = obj.unitId;
            unitName = obj.unitName;
            contactId = obj.contactId;
        }else if(property_data && property_data.propertyId){
            propId = property_data.propertyId;
            propName = property_data.propertyName;
        }else if(viewing_unit){
            propId = viewing_unit.propertyId;
            propName = viewing_unit.propertyName;
            unitId = viewing_unit.unitId;
            unitName = viewing_unit.unitName;
        }

        if ((propId || contactId || unitId) && !tenancy) {
            getPrepopulatedTenancyInformation({
              propertyId: propId ? propId : null,
              contactId: contactId ? contactId : null,
              unitId: unitId ? unitId : null
            }).then(resp => {
              if (resp && resp.status == 200 && resp.data) {
                if (resp.data.unitTargetRent) {
                    initialTenancy.current = {
                        ...tenancyItem,
                        rentAmount: resp.data.unitTargetRent,
                        deposit: calculateDepositFromMonthlyRent(
                            tenancyItem.frequency,
                            resp.data.unitTargetRent
                        )
                    }

                    setTenancyItem(initialTenancy.current);
                }
              }
            });
          }
    
          if (propId) {
            getTenancyRequirement(propId).then(resp => {
                if (resp && resp.status == 200 && resp.data) {
                    let data: ITenancyRequirement = resp.data;

                    initialTenancy.current = {
                        ...tenancyItem,
                        contractTypeId: (tenancyItem && tenancyItem.contractTypeId) || data.contractType,
                        tenancyTypeId: (tenancyItem && tenancyItem.tenancyTypeId) || data.tenancyType,
                        electricity: data.electricityIncluded,
                        gas: data.gasIncluded,
                        water: data.waterIncluded,
                        tvLicense: data.tvLicenseIncluded,
                        skyCable: data.skyCableIncluded,
                        internet: data.internetIncluded,
                        phone: data.phoneIncluded,
                        rentAmount: (tenancyItem && tenancyItem.rentAmount) || data.marketRent,
                        //frequency: data.frequency,
                        durationLength: data.durationValue,
                        durationPeriod: data.durationRange,
                        deposit: calculateDepositFromMonthlyRent(
                            data.frequency,
                            data.marketRent)
                    }
                    setTenancyItem(initialTenancy.current);
                }
            })
        }
    }

    const setDefaultEndDate = (): Date => {
        var newDate = new Date();
        newDate.setMonth(newDate.getMonth() + 6);
        newDate.setDate(newDate.getDate() - 1);
        return newDate;
    }

    const checkIfPropertyHasUnits = (propertyId: string) => {
        doesPropertyHaveUnits(propertyId).then(resp => {
            if (resp && resp.status == 200) {
                setDoesPropertyHaveUnitsLocal(resp.data);
            }
        });
    }

    const setDatesForClonedTenancy = (tenancy: ITenancyItem) => {
        let d = moment(tenancy.endDate).add(1, 'days').utcOffset(0, true).set({ hours: 0, minutes: 0 }).format();
        let date = new Date(d);
    
        let dayOfMonthPaid = date.getDate();
        if(dayOfMonthPaid > 28)
        {
            dayOfMonthPaid = 28;
        }
        
        let newRentReviewDate = new Date(date); 
        newRentReviewDate.setFullYear(newRentReviewDate.getFullYear() + 1);
    
        var newEndDate = calculateEndDateFromDuration(tenancy.durationLength, tenancy.durationPeriod, date);

        tenancy.startDate = new Date(date);
        tenancy.moveInDate = new Date(date);
        tenancy.rentReviewDate = new Date(newRentReviewDate);
        tenancy.rentTakenOnDay = dayOfMonthPaid;
        tenancy.endDate = new Date(newEndDate);
        tenancy.moveOutDate = new Date(newEndDate);
    };

    const onChangeStartDate = (date: Date, props?: any) => {
        if (!date) {
          return;
        }
    
        let d = moment(date).utcOffset(0, true).set({ hours: 0, minutes: 0 }).format();
        date = new Date(d);
    
        let dayOfMonthPaid = date.getDate();
        if(dayOfMonthPaid > 28)
        {
            dayOfMonthPaid = 28;
        }
        
        let newRentReviewDate = new Date(date); 
        newRentReviewDate.setFullYear(newRentReviewDate.getFullYear() + 1);
    
        var newEndDate = calculateEndDateFromDuration(props.values.durationLength, props.values.durationPeriod, date);

        if(props){
            props.setFieldValue('startDate', date);
            props.setFieldValue('moveInDate', date);
            props.setFieldValue('rentTakenOnDay', dayOfMonthPaid);
            props.setFieldValue('rentReviewDate', newRentReviewDate);
            props.setFieldValue('endDate', newEndDate);
            props.setFieldValue('moveOutDate', newEndDate);
        }
    };

    const onChangeEndDate = (date: Date, props: any) => {
        props.setFieldValue('endDate', date);
        props.setFieldValue('moveOutDate', date);

        let days = calculateDaysCountBetweenDates(props.values.startDate, date);
        props.setFieldValue('durationLength', days);
        props.setFieldValue('durationPeriod', 'Days');
    }

    const onChangeMoveInDate = (date: Date, props: any) => {
        if (!date) {
            return;
        }

        props.setFieldValue('moveInDate', date);

        let validate = moment(props.values.moveOutDate
                  ? props.values.moveOutDate
                  : new Date()
              ).toDate() < moment(props.values.moveInDate).local().toDate();
        if (validate) {
            let newMoveOutDate = new Date();
            newMoveOutDate.setDate(props.values.endDate.getDate());
            props.setFieldValue('moveOutDate', newMoveOutDate);
        }
    };
    
    const onChangeDurationValue = (e: any, props: any): void => {
        const value: string = e.currentTarget.value;
        props.setFieldValue(e.currentTarget.name, value != "" ? Number(value) : null);
        var newEndDate = calculateEndDateFromDuration(
            value != "" && value != "0"  ? props.values.durationLength : 1,
            props.values.durationPeriod,
            props.values.startDate
          );
        props.setFieldValue('endDate', newEndDate);
        props.setFieldValue('moveOutDate', newEndDate);
    }
    
    const onBlurDurationValue = (e: any, props: any): void => {
        const value: string = e.currentTarget.value
        props.setFieldValue(e.currentTarget.name, value != "" ? Number(value) : null);
        var newEndDate = calculateEndDateFromDuration(
            value != "" && value != "0"  ? props.values.durationLength : 1,
            props.values.durationPeriod,
            props.values.startDate
          );
        props.setFieldValue('endDate', newEndDate);
        props.setFieldValue('moveOutDate', newEndDate);
    }
    
    const onChangeDurationRange = (range: IOptions, props: any): void => {
        props.setFieldValue('durationPeriod', range.value);
        var newEndDate =  calculateEndDateFromDuration(
            props.values.durationLength,
            range.value,
            props.values.startDate
          )

          props.setFieldValue('endDate', newEndDate);
          props.setFieldValue('moveOutDate', newEndDate);
    }

    const onAddDocument = (documentId: string, documentType: string, props: any): void => {
        props.setFieldValue('documents', props.values.documents ? props.values.documents.concat([documentId]) : [documentId]);
        setDocumentIds(documentIds.concat([{ documentId, documentType, fileType: '' }]));
    }

    const handleAccordionClick = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
      };

    const saveChanges = (values: any) => {
        setSubmitting(true);
        var updatedTenancy: ITenancyItem = values as ITenancyItem;

        // need to correct any date fields
        updatedTenancy.startDate = correctDate(updatedTenancy.startDate);
        updatedTenancy.endDate = correctDate(updatedTenancy.endDate);
        updatedTenancy.rentReviewDate = correctDate(updatedTenancy.rentReviewDate);
        updatedTenancy.moveInDate = correctDate(updatedTenancy.moveInDate);
        updatedTenancy.moveOutDate = correctDate(updatedTenancy.moveOutDate);
        
        // If there is a Tenancy ID then this is an Update
        if (updatedTenancy.tenancyId) {
            updateTenancy(
                updatedTenancy,
                updatedTenancy.tenancyId
            ).then((resp) => {
                if (resp && resp.status == 200) {
                    setSubmitting(false);
                    if (updatedTenancy && updatedTenancy.propertyId) {
                        // Redirect the User to the Property Tenancy page
                        goToRoute(
                            `/dashboard/property/${updatedTenancy.propertyId}/tenancy/tenancies`
                        )
                    }
                    //fetchTasksListData(listOptions);
                    dispatch(tasksApi.endpoints.fetchTasks.initiate(listOptions, {forceRefetch: true}));
                }
            }).catch(error => {
                setSubmitting(false);
            })
            return
        }else{
            addTenancy(updatedTenancy).then((resp) => {
                if (resp && resp.status == 200) {
                    let response: Array<IDataResponse<any>> = resp.data

                    response.forEach((x) => {
                        processToast(x)
                    })

                    goToRoute(
                        `/dashboard/tenancy/view/${resp.data[0].data.tenancyItem.tenancyId}/rentschedule`
                    )

                    //fetchTasksListData(listOptions);
                    dispatch(tasksApi.endpoints.fetchTasks.initiate(listOptions, {forceRefetch: true}));
                }
            }
            ).catch(error => {
                setSubmitting(false);
            })
        }
    }

    const onCancel = (currentValues: any) => {
        if (!lodashObject.isEqual(initialTenancy.current, currentValues)) {
            setChangesModalOpen(true)
            return;
        }

        history.goBack();
    }

    const navigateToTask = (taskId: number) => {
        let url = `/dashboard/task?taskId=${taskId}`;
        history.push(url);
    }

    const onCloseChangesModal = (submitCancel: boolean): void => {
        setChangesModalOpen(false);

        if (submitCancel) {
            //goToRoute(getNavigatePath());
            history.goBack();
        } 
    }

    if (submitting) {
        let text = tenancyItem && tenancyItem.tenancyId
          ? "Updating tenancy..."
          : "Adding tenancy...";
        return (
          <div>
            <RotatingLoader text={text} loading={true} />
          </div>
        );
    }

    if (!tenancyItem || !tenancyTypes || !tenancyStatus || !contractTypes || !rentPayTypes) {
        let text = "Loading Tenancy...";
        return (
          <div>
            <RotatingLoader text={text} loading={true} />
          </div>
        );
    }

    return (
        <>
            <OutstandingChangesModal
                open={changesModalOpen}
                onCloseChangesModal={onCloseChangesModal}
            />
            
                <Formik
                    initialValues={{ ...tenancyItem }}
                    enableReinitialize={true}
                    validationSchema={AddTenancy}
                    onSubmit={(values: any) => saveChanges(values)}
                >
                    {(props) => (
                        <>
                            {/* Main fields */}
                            <S.ContainerDiv>
                                {
                                    <S.SectionDiv>
                                        <TenantDetailsSection 
                                            config={config.current.tenant}
                                            //tooltip={gasSafetyTooltip}
                                            selectionControl={
                                                <SearchContactInput
                                                    focussed={true}
                                                    callbackName="callbackTenant"
                                                    onAdd={() => setTenancyItem(props.values)}
                                                    allowNewContact
                                                    newContactType="Tenant"
                                                    contactType={["921CCFFF-7386-400D-804C-B638121A75D2"]}
                                                    onClear={() => {
                                                        props.setFieldValue('contactId', null);
                                                        props.setFieldValue('tenantName', null);
                                                        //checkForChanges();
                                                    }}
                                                    onSelect={(option: ISearchResult) => {
                                                        props.setFieldValue('contactId', option.value);
                                                        props.setFieldValue('tenantName', option.label);
                                                        //checkForChanges();
                                                    }}
                                                    selectedId={props.values.contactId}
                                                    selectedName={props.values.tenantName}
                                                    //firstInputRef={this.props.firstInputRef}
                                                />}
                                            selectionControlWidth={"350px"}
                                            errorId="contactId"
                                        />
                                    </S.SectionDiv>
                                }
                                {
                                    <S.SectionDiv>
                                        <TenantDetailsSection
                                            config={config.current.propertyUnit}
                                            //tooltip={eicrTooltip}
                                            selectionControl={
                                                <div className={styles.flexibleWrapper}>
                                                    <div className={styles.innerWrappingItem}>
                                                        <SearchPropertyInput
                                                            onClear={() => {
                                                                props.setFieldValue('propertyId', null);
                                                                props.setFieldValue('propertyName', null);
                                                                props.setFieldValue('unitId', null);
                                                                props.setFieldValue('unitName', null);
                                                                setDoesPropertyHaveUnitsLocal(false);
                                                                //checkForChanges();
                                                            }}
                    
                                                            //disableClear={options.isProperty}
                                                            selectedPropertyId={props.values.propertyId}
                                                            selectedPropertyName={props.values.propertyName}
                                                            onSelect={(option) => {
                                                                props.setFieldValue('propertyId', option.value);
                                                                props.setFieldValue('propertyName', option.label);
                                                                checkIfPropertyHasUnits(option.value)
                                                            }}
                                                        />
                                                    </div>
                                                    {(doesPropertyHaveUnitsLocal) && (
                                                        <div className={styles.innerWrappingItem}>
                                                            <SearchUnitInput
                                                                propertyId={props.values.propertyId}
                                                                onClear={() =>{
                                                                    props.setFieldValue('unitId', null);
                                                                    props.setFieldValue('unitName', null);
                                                                }}
                                                                selectedUnitId={props.values.unitId}
                                                                selectedUnitName={props.values.unitName}
                                                                onSelect={(selected: IOptions) => {
                                                                    props.setFieldValue('unitId', selected.value);
                                                                    props.setFieldValue('unitName', selected.label);
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            selectionControlWidth={"100%"}
                                            errorId="propertyName"
                                        />
                                    </S.SectionDiv>
                                }
                                {
                                    <S.SectionDiv>
                                        <TenantDetailsSection
                                            config={config.current.tenancyDates}
                                            //tooltip={epcTooltip}
                                            selectionControl={
                                                <div className={styles.flexibleWrapper}>
                                                    <div className={styles.dateContainer}>
                                                        <div className={styles.datePickerWrapper}>
                                                            <DatePicker
                                                                todayButton="Today"
                                                                dateFormat="dd/MM/yyyy"
                                                                className={styles.input}
                                                                selected={moment(props.values.startDate).toDate()}
                                                                onChange={(date: Date) => onChangeStartDate(date, props)}
                                                            />
                                                        </div>
                                                        {
                                                            tenancyItem && tenancyItem.tenancyId && startDateTaskId
                                                            ?
                                                            <div className={styles.keyDateIndicator}>
                                                                <img 
                                                                    className={styles.keyDateIndicatorImage}
                                                                    src={taskIcon} alt="Info" 
                                                                    onMouseOver={(e) => e.currentTarget.src=taskIconHover}
                                                                    onMouseOut={(e) => e.currentTarget.src=taskIcon}
                                                                    onClick={() => navigateToTask(startDateTaskId)}
                                                                />
                                                            </div>
                                                            :
                                                            ""
                                                        }
                                                    </div>
                                                    <span className={styles.durationWrapper}>
                                                        <input
                                                            name="durationLength"
                                                            className={styles.durationLengthInput}
                                                            type="number"
                                                            pattern="[0-9]*"
                                                            value={props.values.durationLength}
                                                            onChange={e => onChangeDurationValue(e, props)}
                                                            onBlur={e => onBlurDurationValue(e, props)}
                                                        />
                                                        <div className={styles.durationSelect}>
                                                            <SelectDropdown
                                                                data={durations}
                                                                placeholder="Duration..."
                                                                onSelect={(id: IOptions) => 
                                                                    onChangeDurationRange(id, props)
                                                                }
                                                                selectedId={props.values.durationPeriod}
                                                            />
                                                        </div>
                                                    </span>
                                                    <div className={styles.dateContainer}>
                                                        <div className={styles.datePickerWrapper}>
                                                            <DatePicker
                                                                required={false}
                                                                todayButton="Today"
                                                                minDate={moment(props.values.startDate).toDate()}
                                                                dateFormat="dd/MM/yyyy"
                                                                className={styles.input}
                                                                selected={
                                                                    props.values.endDate &&
                                                                    moment(props.values.endDate).local().toDate()
                                                                }
                                                                onChange={(date: Date) => onChangeEndDate(date, props)}
                                                                //ref={endDateRef}
                                                            />
                                                        </div>
                                                        {
                                                            tenancyItem && tenancyItem.tenancyId && endDateTaskId
                                                            ?
                                                            <div className={styles.keyDateIndicator}>
                                                                <img 
                                                                    className={styles.keyDateIndicatorImage}
                                                                    src={taskIcon} alt="Info" 
                                                                    onMouseOver={(e) => e.currentTarget.src=taskIconHover}
                                                                    onMouseOut={(e) => e.currentTarget.src=taskIcon}
                                                                    onClick={() => navigateToTask(endDateTaskId)}
                                                                />
                                                        </div>
                                                            :
                                                            ""
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            
                                        />
                                    </S.SectionDiv>
                                }   
                                {
                                    <S.SectionDiv>
                                        <TenantDetailsSection
                                            config={config.current.rentAndFrequency}
                                            //tooltip={hmoTooltip}
                                            selectionControl={
                                                <span className={styles.durationWrapper}>
                                                    <div className={styles.currencyInputWrapper}>
                                                        <RCCurrencyInput
                                                            name="rentAmount"
                                                            value={props.values.rentAmount}
                                                            prefix="£"
                                                            handleChange={(e) => props.setFieldValue('rentAmount', e)}
                                                            handleBlur={(e) => {
                                                                let deposit = calculateDepositFromMonthlyRent(props.values.frequency, e);
                                                                props.setFieldValue('deposit', deposit);
                                                            }}
                                                            //innerRef={rentAmountRef}
                                                        />
                                                    </div>
                                                    <div className={styles.rentDurationSelect}>
                                                        <SelectDropdown
                                                            data={rentPaymentDurations}
                                                            showDefaultOption
                                                            placeholder="Frequency..."
                                                            onSelect={(id: IOptions) => {
                                                                let deposit = calculateDepositFromMonthlyRent(id.value, props.values.rentAmount);
                                                                props.setFieldValue("frequency", id.value);
                                                                props.setFieldValue("deposit", deposit);
                                                            }}
                                                            selectedId={props.values.frequency}
                                                        />
                                                    </div>
                                                </span>}
                                            selectionControlWidth={"260px"}
                                        />
                                    </S.SectionDiv>
                                }
                                {
                                    <S.SectionDiv>
                                        <TenantDetailsSection
                                            config={config.current.rollingContract}
                                            //tooltip={hmoTooltip}
                                            selectionControl={
                                                <div id="tenancy_rolling_contract">
                                                    <MaterialRadioButtons
                                                        labels={["Yes", "No"]}
                                                        default={
                                                            props.values.rollingContract ? "Yes" : "No"
                                                        }
                                                        handleChange={(value) => props.setFieldValue('rollingContract', value == "Yes")}
                                                        reducedLabelHeight={true}
                                                    />
                                                </div>
                                            }
                                        />
                                    </S.SectionDiv>
                                }
                            </S.ContainerDiv>

                            <div className={styles.sectionSpacer} />

                            {/* Tenancy finace fields */}
                            <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionClick('panel1')}>
                                <AccordionSummary
                                    className={styles.accordionSummary}
                                    expandIcon={<img src={chevronDown} height="10px" width="15px"/>}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    >
                                    <Typography className={styles.accordionHeading}>Deposit Info</Typography>
                                </AccordionSummary>  

                                <S.ContainerDiv>
                                {
                                    <S.SectionDiv>
                                        <TenantDetailsSection
                                            config={config.current.depositAmountAndSchemeName}
                                            tooltip={depositTooltip}
                                            selectionControl={
                                                <div className={styles.flexibleWrapper}>
                                                    <div className={styles.currencyInputWrapper}>
                                                        <RCCurrencyInput
                                                            name="deposit"
                                                            value={props.values.deposit}
                                                            prefix="£"
                                                            handleChange={(e) => props.setFieldValue('deposit', e)}
                                                        />
                                                    </div>
                                                    <div className={styles.depositSchemeSelect}>
                                                        <SelectDropdown
                                                            selectedId={props.values.depositSchemeName}
                                                            data={despositSchemes}
                                                            onSelect={(id: IOptions) => props.setFieldValue('depositSchemeName', id.value)}
                                                            allowWrapping={true}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            selectionControlWidth={"100%"}
                                        />
                                    </S.SectionDiv>
                                }
                                {
                                    <S.SectionDiv>
                                        <TenantDetailsSection
                                            config={config.current.depositReference}
                                            //tooltip={hmoTooltip}
                                            selectionControl={
                                                <input
                                                    name={"depositSchemeReference"}
                                                    maxLength={20}
                                                    className={styles.input}
                                                    value={props.values.depositSchemeReference}
                                                    onChange={(e) => props.setFieldValue('depositSchemeReference', e.currentTarget.value)}
                                                />
                                            }
                                            selectionControlWidth={"200px"}
                                        />
                                    </S.SectionDiv>
                                }
                                </S.ContainerDiv>
                            </Accordion>

                            <div className={styles.sectionSpacer} />

                            {/* Docs fields */}
                            <Accordion expanded={expanded === 'panel4'} onChange={handleAccordionClick('panel4')}>
                                <AccordionSummary
                                    className={styles.accordionSummary}
                                    expandIcon={<img src={chevronDown} height="10px" width="15px"/>}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    >
                                    <Typography className={styles.accordionHeading}>Docs</Typography>
                                </AccordionSummary>  

                                <S.ContainerDiv>

                                {
                                    <S.SectionDiv>
                                        <TenantDetailsSection
                                           config={config.current.tenancyContractDocument}
                                            tooltip={contractTooltip}
                                            selectionControl={
                                                tenancyItem && tenancyItem.tenancyId 
                                                ?
                                                <AssociatedDocuments
                                                    onAddDocument={(documentId) => onAddDocument(documentId, "3bdd80c3-91c9-4e37-8588-db6a0db0f4a9", props)}
                                                    showUpload
                                                    showOnlyNoneText
                                                    returnDocumentId
                                                    options={{
                                                        isCreating: false,
                                                        tenancyId: props.values.tenancyId,
                                                        propertyId: props.values.propertyId,
                                                        unitId: props.values.unitId,
                                                        documentType: "3bdd80c3-91c9-4e37-8588-db6a0db0f4a9"
                                                    }}
                                                />
                                                :
                                                <AssociatedDocuments
                                                    onAddDocument={(documentId) => onAddDocument(documentId, "3bdd80c3-91c9-4e37-8588-db6a0db0f4a9", props)}
                                                    showUpload
                                                    returnDocumentId
                                                    showOnlyNoneText
                                                    options={{
                                                        documentsOverride: documentIds && documentIds.filter(
                                                            d =>
                                                            d.documentType ==
                                                            "3bdd80c3-91c9-4e37-8588-db6a0db0f4a9"
                                                        ),
                                                        isCreating: true,
                                                        documentType: "3bdd80c3-91c9-4e37-8588-db6a0db0f4a9"
                                                    }}
                                                />
                                            }
                                            selectionControlWidth={"260px"}
                                        />
                                    </S.SectionDiv>
                                }
                                {
                                    <S.SectionDiv>
                                        <TenantDetailsSection
                                           config={config.current.tenancyInventoryDocument}
                                            tooltip={inventoryTooltip}
                                            selectionControl={
                                                tenancyItem && tenancyItem.tenancyId 
                                                ?
                                                <AssociatedDocuments
                                                    onAddDocument={(documentId) => onAddDocument(documentId, "fab30d94-4e16-4791-a8bf-dece946a24f0", props)}
                                                    showUpload
                                                    showOnlyNoneText
                                                    options={{
                                                        isCreating: false,
                                                        tenancyId: props.values.tenancyId,
                                                        propertyId: props.values.propertyId,
                                                        unitId: props.values.unitId,
                                                        documentType: "fab30d94-4e16-4791-a8bf-dece946a24f0"
                                                    }}
                                                />
                                                :
                                                <AssociatedDocuments
                                                    onAddDocument={(documentId) => onAddDocument(documentId, "fab30d94-4e16-4791-a8bf-dece946a24f0", props)}
                                                    showUpload
                                                    returnDocumentId
                                                    showOnlyNoneText
                                                    options={{
                                                        documentsOverride: documentIds && documentIds.filter(
                                                        d =>
                                                            d.documentType ==
                                                            "fab30d94-4e16-4791-a8bf-dece946a24f0"
                                                        ),
                                                        isCreating: true,
                                                        documentType: "fab30d94-4e16-4791-a8bf-dece946a24f0"
                                                    }}
                                                />
                                            }
                                            selectionControlWidth={"260px"}
                                        />
                                    </S.SectionDiv>
                                }
                                </S.ContainerDiv>
                            </Accordion>

                            <div className={styles.sectionSpacer} />

                            {/* Bill fields */}
                            <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionClick('panel2')}>
                                <AccordionSummary
                                    className={styles.accordionSummary}
                                    expandIcon={<img src={chevronDown} height="10px" width="15px"/>}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    >
                                    <Typography className={styles.accordionHeading}>Bills</Typography>
                                </AccordionSummary>  

                                <S.ContainerDiv>
                                {
                                    <S.SectionDiv>
                                        <TenantDetailsSection
                                            config={config.current.billsIncluded}
                                            //tooltip={hmoTooltip}
                                            selectionControl={
                                                <div className={styles.flexibleWrapper}>
                                                    <RCCheckbox field="electricity" label="Electricity" props={props} />
                                                    <RCCheckbox field="gas" label="Gas" props={props} />
                                                    <RCCheckbox field="water" label="Water" props={props} />
                                                    <RCCheckbox field="tvLicense" label="TV License" props={props} />
                                                    <RCCheckbox field="skyCable" label="Sky/Cable" props={props} />
                                                    <RCCheckbox field="internet" label="Internet" props={props} />
                                                    <RCCheckbox field="phone" label="Phone" props={props} />
                                                </div>
                                            }
                                            selectionControlWidth={"100%"}
                                        />
                                    </S.SectionDiv>
                                }
                                </S.ContainerDiv>
                            </Accordion>

                            <div className={styles.sectionSpacer} />

                            {/* More info fields */}
                            <Accordion expanded={expanded === 'panel3'} onChange={handleAccordionClick('panel3')}>
                                <AccordionSummary
                                    className={styles.accordionSummary}
                                    expandIcon={<img src={chevronDown} height="10px" width="15px"/>}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    >
                                    <Typography className={styles.accordionHeading}>More Info</Typography>
                                </AccordionSummary>  

                                <S.ContainerDiv>
                                {
                                    <S.SectionDiv>
                                        <TenantDetailsSection
                                            config={config.current.rentReviewDate}
                                            tooltip={rentReviewDateTooltip}
                                            selectionControl={
                                                <div className={styles.dateContainer}>
                                                    <div className={styles.datePickerWrapper}>
                                                    <DatePicker
                                                        required={false}
                                                        todayButton="Today"
                                                        //minDate={moment(props.values.startDate).toDate()}
                                                        //maxDate={moment(props.values.endDate).toDate()}
                                                        dateFormat="dd/MM/yyyy"
                                                        className={styles.input}
                                                        selected={
                                                            props.values.rentReviewDate &&
                                                            moment(props.values.rentReviewDate).local().toDate()
                                                        }
                                                        onChange={(date: Date) => props.setFieldValue('rentReviewDate', date)}
                                                        //ref={endDateRef}
                                                        />
                                                    </div>
                                                    {
                                                        tenancyItem && tenancyItem.tenancyId && rentReviewDateTaskId
                                                        ?
                                                        <div className={styles.keyDateIndicator}>
                                                            <img 
                                                                className={styles.keyDateIndicatorImage}
                                                                src={taskIcon} alt="Info" 
                                                                onMouseOver={(e) => e.currentTarget.src=taskIconHover}
                                                                onMouseOut={(e) => e.currentTarget.src=taskIcon}
                                                                onClick={() => navigateToTask(rentReviewDateTaskId)}
                                                            />
                                                        </div>
                                                        :
                                                        ""
                                                    }
                                                </div>}
                                        />
                                    </S.SectionDiv>
                                }
                                {
                                    <S.SectionDiv>
                                        <TenantDetailsSection
                                           config={config.current.tenancyStatus}
                                            //tooltip={hmoTooltip}
                                            selectionControl={
                                                <SelectDropdown
                                                    selectedId={props.values.tenancyStatusId}
                                                    data={tenancyStatus}
                                                    onSelect={(id: IOptions) => props.setFieldValue('tenancyStatusId', id.value)}
                                                />
                                            }
                                            selectionControlWidth={"260px"}
                                        />
                                    </S.SectionDiv>
                                }
                                {
                                    <S.SectionDiv>
                                        <TenantDetailsSection
                                           config={config.current.tenancyAndContractType}
                                            //tooltip={hmoTooltip}
                                            selectionControl={
                                                <div className={styles.flexibleWrapper}>
                                                    <div className={styles.innerWrappingItem}>              
                                                        <SelectDropdown
                                                            selectedId={props.values.tenancyTypeId}
                                                            data={tenancyTypes}
                                                            onSelect={(id: IOptions) => props.setFieldValue('tenancyTypeId', id.value)}
                                                        />
                                                    </div>
                                                    <div className={styles.innerWrappingItem}>
                                                        <SelectDropdown
                                                            selectedId={props.values.contractTypeId}
                                                            data={contractTypes}
                                                            onSelect={(id: IOptions) => props.setFieldValue('contractTypeId', id.value)}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            selectionControlWidth={"100%"}
                                        />
                                    </S.SectionDiv>
                                }
                                {
                                    <S.SectionDiv>
                                        <TenantDetailsSection
                                            config={config.current.moveInAnOutDate}
                                            //tooltip={hmoTooltip}
                                            selectionControl={
                                                <div className={styles.flexibleWrapper}>
                                                    <div className={styles.dateContainer}>
                                                        <div className={styles.datePickerWrapper}>
                                                            <DatePicker
                                                                required={false}
                                                                todayButton="Today"
                                                                //minDate={moment(props.values.startDate).toDate()}
                                                                //maxDate={moment(props.values.endDate).toDate()}
                                                                dateFormat="dd/MM/yyyy"
                                                                className={styles.input}
                                                                selected={
                                                                    props.values.moveInDate &&
                                                                    moment(props.values.moveInDate).local().toDate()
                                                                }
                                                                onChange={(date: Date) => onChangeMoveInDate(date, props)}
                                                                //ref={endDateRef}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={styles.dateContainer}>
                                                        <div className={styles.datePickerWrapper}>
                                                            <DatePicker
                                                                required={false}
                                                                todayButton="Today"
                                                                //minDate={moment(props.values.moveInDate).toDate()}
                                                                //maxDate={moment(props.values.endDate).toDate()}
                                                                dateFormat="dd/MM/yyyy"
                                                                className={styles.input}
                                                                selected={
                                                                    props.values.moveOutDate &&
                                                                    moment(props.values.moveOutDate).local().toDate()
                                                                }
                                                                onChange={(date: Date) => props.setFieldValue('moveOutDate', date)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </S.SectionDiv>
                                }
                                {
                                    <S.SectionDiv>
                                        <TenantDetailsSection
                                            config={config.current.tenantNoticePeriod}
                                            //tooltip={hmoTooltip}
                                            selectionControl={
                                                <span className={styles.durationWrapper}>
                                                    <input
                                                        name="tenantNoticePeriodDuration"
                                                        className={styles.durationLengthInput}
                                                        type="number"
                                                        pattern="[0-9]*"
                                                        value={props.values.tenantNoticePeriodDuration}
                                                        onChange={e => props.setFieldValue('tenantNoticePeriodDuration', e.currentTarget.value != "" ? Number(e.currentTarget.value) : null)}
                                                        //onBlur={e => onBlurDurationValue(e, props)}
                                                    />
                                                    <div className={styles.durationSelect}>
                                                        <SelectDropdown
                                                            data={durations}
                                                            placeholder="Duration..."
                                                            onSelect={(id: IOptions) => 
                                                                props.setFieldValue('tenantNoticePeriodRange', id.value)
                                                            }
                                                            selectedId={props.values.tenantNoticePeriodRange}
                                                        />
                                                    </div>
                                                </span>}
                                            selectionControlWidth={"260px"}
                                        />
                                    </S.SectionDiv>
                                }
                                {
                                    <S.SectionDiv>
                                        <TenantDetailsSection
                                            config={config.current.landlordNoticePeriod}
                                            //tooltip={hmoTooltip}
                                            selectionControl={
                                                <span className={styles.durationWrapper}>
                                                    <input
                                                        name="landlordNoticePeriodDuration"
                                                        className={styles.durationLengthInput}
                                                        type="number"
                                                        pattern="[0-9]*"
                                                        value={props.values.landlordNoticePeriodDuration}
                                                        onChange={e => props.setFieldValue('landlordNoticePeriodDuration', e.currentTarget.value != "" ? Number(e.currentTarget.value) : null)}
                                                    />
                                                    <div className={styles.durationSelect}>
                                                        <SelectDropdown
                                                            data={durations}
                                                            placeholder="Duration..."
                                                            onSelect={(id: IOptions) => 
                                                                props.setFieldValue('landlordNoticePeriodRange', id.value)
                                                            }
                                                            selectedId={props.values.landlordNoticePeriodRange}
                                                        />
                                                    </div>
                                                </span>}
                                            selectionControlWidth={"260px"}
                                        />
                                    </S.SectionDiv>
                                }
                                </S.ContainerDiv>
                            </Accordion>

                            <div className={styles.sectionSpacer} />

                            {/* buttons */}
                            <div className={styles.buttons}>
                                <StickyComponent>
                                    <div className={styles.stickyComponentContentWrapper}>
                                        <Buttons
                                            buttons={[
                                                {
                                                    text: 'Cancel',
                                                    displayType: 'cancel',
                                                    elementType: 'button',
                                                    onClick: () => onCancel(props.values),
                                                },
                                                {
                                                    text: 'Save',
                                                    displayType: 'submit',
                                                    elementType: 'button',
                                                    onClick: () => props.submitForm(),
                                                },
                                            ]}
                                        />
                                    </div>
                                </StickyComponent>
                            </div>
                        </>
                    )}
                </Formik>
        </>
    )
}

export default TenancyDetailsPage;