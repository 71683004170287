import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { addURLParameter } from '../../../../helpers/locationHelpers'
import { toggleTransactionPopup } from '../../../../helpers/sidebarHelpers'
import { IAddTransactionReferer } from '../../../atoms/AddEditTransaction'
import { IAddEditTransactionOptions } from '../../../atoms/AddEditTransactionModal'
import IconButton from '../../../atoms/Buttons/IconButton'
import styles from './TransactionsGrid.module.scss'

interface IProps {
    history: any
    location: any
    match: any

    addEditOptions?: IAddEditTransactionOptions
}

class AddTransactions extends Component<IProps, {}> {
    constructor(props: any) {
        super(props)

        this.onAdd = this.onAdd.bind(this)
    }

    onAdd(isExpense: boolean, isIncome: boolean): void {
        let options: IAddEditTransactionOptions = {
            referer: IAddTransactionReferer.Transaction,
        }

        if (this.props.addEditOptions) {
            options = this.props.addEditOptions
        }

        options.isExpense = isExpense
        options.isIncome = isIncome

        let query = addURLParameter(
            this.props.location.search,
            'addEditTransactionOptions=' + JSON.stringify(options)
        )
        this.props.history.push({
            search: query,
        })

        toggleTransactionPopup(true)
    }

    render() {
        return (
            <div className={styles.row}>
                <div className={styles.iconColumn}>
                    <IconButton
                        button={{
                            text: 'Add Expense',
                            displayType: 'submit',
                            elementType: 'button',
                            icon: 'add',
                            onClick: () => this.onAdd(true, false),
                        }}
                    />
                </div>
                <div className={styles.iconColumn}>
                    <IconButton
                        button={{
                            text: 'Add Income',
                            displayType: 'submit',
                            elementType: 'button',
                            icon: 'add',
                            onClick: () => this.onAdd(false, true),
                        }}
                    />
                </div>
            </div>
        )
    }
}

export default withRouter(AddTransactions)
