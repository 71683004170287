import React from 'react';
import { ColumnDirective, ColumnsDirective, CommandModel } from '@syncfusion/ej2-react-grids';
import { IAuditResponseV2 } from "../../../../types/AuditService";

const minWidthTablet = '376px';
const customAttributes: any = { class: 'customcss' };

export const reformatDates = (data: Array<object>): Array<IAuditResponseV2> => {
    let result: Array<IAuditResponseV2> = [];

    if(data)
    {
        data.forEach((x: IAuditResponseV2) => {
            let normalisedTask : IAuditResponseV2 = {
                ...x,
                dateCreated: x.dateCreated != null ? new Date(x.dateCreated) : null
                
            }
    
            result.push(normalisedTask);
        });
    }

    return result;
}

export const auditGridGridColumns = () => {
    const environmentCommand: CommandModel[] = [
        {
            title: "context",
            buttonOption: {
                cssClass: 'gridCustomButton', iconCss: 'e-icons e-search', 
          }
        },
        {
            title: "environment",
            buttonOption: {
                cssClass: 'gridCustomButton', iconCss: 'e-icons e-upload', 
          }
        }
    ];

    return (
        <ColumnsDirective>
            {/*             
            <ColumnDirective
                field="address"
                headerText="Address"
                textAlign="Left"
                template={(x: ISaleRecord) => formattedAddress(x.address)}
                customAttributes={customAttributes}
                width="35%"
            /> */}
            <ColumnDirective
                field="dateCreated"
                headerText="Date"
                textAlign="Left"
                type="date"
                format={{ type: 'date', format: 'dd MMM yyyy HH:mm:ss' }}
                //width="20%"
                customAttributes={customAttributes}
            />
            <ColumnDirective
                field="title"
                headerText="Title"
                textAlign="Left"
                //width="29%"
                //hideAtMedia={`(min-width: ${minWidthTablet})`}
                //customAttributes={customAttributes}
            />
            <ColumnDirective
                field="auditCode"
                headerText="Audit Code"
                textAlign="Left"
                //width="29%"
                hideAtMedia={`(min-width: ${minWidthTablet})`}
                customAttributes={customAttributes}
                visible={false}
            />
            <ColumnDirective
                field="userId"
                headerText="User Id"
                textAlign="Left"
                //width="29%"
                hideAtMedia={`(min-width: ${minWidthTablet})`}
                customAttributes={customAttributes}
            />
            <ColumnDirective
                field="teamId"
                headerText="Team Id"
                textAlign="Left"
                //width="29%"
                hideAtMedia={`(min-width: ${minWidthTablet})`}
                customAttributes={customAttributes}
                visible={false}
            />
            <ColumnDirective
                field="auditTypeLevel1Name"
                headerText="Category"
                textAlign="Left"
                //width="29%"
                hideAtMedia={`(min-width: ${minWidthTablet})`}
                customAttributes={customAttributes}
            />
            <ColumnDirective
                field="auditTypeLevel2Name"
                headerText="Sub-Category"
                textAlign="Left"
                //width="29%"
                hideAtMedia={`(min-width: ${minWidthTablet})`}
                customAttributes={customAttributes}
            />
            <ColumnDirective
                field="clientId"
                headerText="Client ID"
                textAlign="Left"
               //width="29%"
                hideAtMedia={`(min-width: ${minWidthTablet})`}
                customAttributes={customAttributes}
                visible={false}
            />
            <ColumnDirective
                field="clientName"
                headerText="Client Name"
                textAlign="Left"
               //width="29%"
                hideAtMedia={`(min-width: ${minWidthTablet})`}
                customAttributes={customAttributes}
            />
            <ColumnDirective 
                headerText='Info' 
                textAlign="Left" 
                width='100' 
                commands= {environmentCommand}
            />
            <ColumnDirective
                field="requestOriginatingDomain"
                headerText="Request Orig. Domain"
                textAlign="Left"
                //width="29%"
                hideAtMedia={`(min-width: ${minWidthTablet})`}
                customAttributes={customAttributes}
            />
            <ColumnDirective
                field="requestDomain"
                headerText="Request Domain"
                textAlign="Left"
                //width="29%"
                hideAtMedia={`(min-width: ${minWidthTablet})`}
                customAttributes={customAttributes}
            />
            <ColumnDirective
                field="browserSession"
                headerText="Browser Session"
                textAlign="Left"
                //width="29%"
                hideAtMedia={`(min-width: ${minWidthTablet})`}
                customAttributes={customAttributes}
            />
            <ColumnDirective
                field="postcode"
                headerText="Postcode"
                textAlign="Left"
                //width="29%"
                hideAtMedia={`(min-width: ${minWidthTablet})`}
                customAttributes={customAttributes}
            />
            <ColumnDirective
                field="address"
                headerText="Address"
                textAlign="Left"
                //width="29%"
                hideAtMedia={`(min-width: ${minWidthTablet})`}
                customAttributes={customAttributes}
            />
            <ColumnDirective
                field="purchasePrice"
                headerText="Purchase Price"
                textAlign="Left"
                template={(x: IAuditResponseV2) => {
                    return x.purchasePrice ? `£${x.purchasePrice}` : '';
                }}
                //width="20%"
                customAttributes={customAttributes}
            />
            <ColumnDirective
                field="monthlyRentalAmount"
                headerText="Rent"
                textAlign="Left"
                template={(x: IAuditResponseV2) => {
                    return x.monthlyRentalAmount ? `£${x.monthlyRentalAmount}` : '';
                }}
                //width="20%"
                customAttributes={customAttributes}
            />
            <ColumnDirective
                field="propertyId"
                headerText="Property Id"
                textAlign="Left"
                //width="29%"
                hideAtMedia={`(min-width: ${minWidthTablet})`}
                customAttributes={customAttributes}
                visible={false}
            />
            <ColumnDirective
                field="propertyType"
                headerText="Property Type"
                textAlign="Left"
                //width="29%"
                hideAtMedia={`(min-width: ${minWidthTablet})`}
                customAttributes={customAttributes}
                visible={false}
            />
        </ColumnsDirective>
    );
};
