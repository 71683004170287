import { ErrorMessage, Field, Formik, getIn } from 'formik'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { Dispatch } from 'redux'
import { toggleAddContactSidebar } from '../../../actions/appActions'
import {
    entityTypeGroupId,
    ownerTypeGroupId,
    taxTypeGroupId,
} from '../../../configuration/appConfig'
import { addToContactsList } from '../../../helpers/contactHelpers'
import { capitaliseFirstLetter } from '../../../helpers/formattingHelpers'
import { toggleAddContactFormChanged } from '../../../helpers/formStateHelpers'
import {
    addURLParameter,
    removeURLParameter,
} from '../../../helpers/locationHelpers'
import { isLandlord, isPlatformAdmin } from '../../../helpers/roleHelpers'
import { validateEmail } from '../../../helpers/stringHelpers'
import { processToast } from '../../../helpers/toastHelper'
import { addContact } from '../../../services/ContactService'
import { getTypesForDropdown } from '../../../services/EntityService'
import { IDataResponse } from '../../../types/ApiService'
import {
    IAddContactRequest,
    IContactListItem,
} from '../../../types/ContactService/ContactService'
import Buttons from '../../atoms/Buttons'
import CloseButton from '../../atoms/CloseButton'
import RotatingLoader from '../../atoms/RotatingLoader'
import SelectDropdown, { IOptions } from '../../atoms/SelectDropdown'
import SuccessTick from '../../atoms/SuccessTick'
import { AddContactSchema } from '../../../YupValidationSchemas'
import styles from './AddContactForm.module.scss'

const queryString = require('query-string')

interface IProps {
    toggleSidebar(show: boolean): void
}

interface IRouterProps {
    history: any
    location: any
    match: any
}

interface IState {
    forename: string
    surname: string
    email: string
    type: IOptions
    taxType: string
    ownerType: string
    typeTypes: Array<IOptions>
    taxTypes: Array<IOptions>
    ownerTypes: Array<IOptions>
    success: boolean
    addTenant: boolean
    callbackTenant: boolean
    callbackLandlord: boolean
    callbackPropertyManager: boolean
    newContactCallback: boolean
    error: string
    contactType: string
    invalidEmail: boolean
    addLoading: boolean
}

export interface IAddTenantCallback {
    name: string
    contactId: string
}

type Props = IRouterProps & IProps

let initialState = {
    forename: '',
    surname: '',
    email: '',
    type: { value: '', label: '' },
    contactType: '',
}

class AddContactForm extends Component<Props, IState> {
    typePropertyOwner = 'E08D17B2-3FA0-43BB-8458-18378F92FDED'
    typeBlank = '00000000-0000-0000-0000-000000000000'

    constructor(props: any) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
        this.handleSaveClick = this.handleSaveClick.bind(this)
        this.closeSidebar = this.closeSidebar.bind(this)
        this.getEntityTypes = this.getEntityTypes.bind(this)
        this.getTaxTypes = this.getTaxTypes.bind(this)
        this.contactInformationFilled = this.contactInformationFilled.bind(this)
        this.setTenantType = this.setTenantType.bind(this)
        this.state = {
            ...initialState,
            ownerType: this.typeBlank,
            taxType: this.typeBlank,
            typeTypes: [],
            taxTypes: [],
            ownerTypes: [],
            success: false,
            addTenant: false,
            callbackTenant: false,
            callbackLandlord: false,
            callbackPropertyManager: false,
            newContactCallback: false,
            error: '',
            invalidEmail: false,
            addLoading: false
        }

        this.checkForChanges = this.checkForChanges.bind(this)
    }

    checkForChanges(): void {
        let difference = Object.keys(this.state).filter((k) => {
            let type = this.state[k]
            let d = moment(type, 'YYYY-MM-DDThh:mm:ssZ', true)
            if (d.isValid()) {
                let nowDate = moment(type, 'YYYY-MM-DD', true)
                let initialDate = moment(initialState[k], 'YYYY-MM-DD', true)

                return !moment(nowDate).isSame(initialDate, 'day')
            }

            return (
                this.state[k] !== initialState[k] &&
                initialState[k] !== undefined
            )
        })

        if (difference.length > 0) {
            toggleAddContactFormChanged(true)
            return
        }

        toggleAddContactFormChanged(false)
    }

    closeSidebar() {
        this.props.toggleSidebar(false)
    }

    handleSaveClick({
        forename,
        surname,
        email,
        contactType,
    }: {
        forename: string
        surname: string
        email: string
        contactType: string
    }) {
        let request: IAddContactRequest = {
            forename: forename,
            surname: surname,
            email: email,
            type: contactType,
            taxStatus: this.state.taxType,
        }

        if (request.email) {
            let isEmailValid = validateEmail(request.email)
            if (!isEmailValid) {
                this.setState({
                    invalidEmail: true,
                })

                return
            }
        }

        this.setState({ addLoading : true });
        addContact(request)
            .then((resp) => {
                if (resp.status == '200' && resp.data) {
                    this.setState({
                        success: true,
                        addLoading: false
                    })

                    let response: IDataResponse<IContactListItem> = resp.data
                    let contact: IContactListItem = response.data

                    processToast(response)

                    contact.invitation = null
                    addToContactsList(contact)

                    let responseData: IContactListItem = contact
                    let callback: IAddTenantCallback = {
                        name:
                            responseData.profile.forename +
                            ' ' +
                            responseData.profile.surname,
                        contactId: responseData.contact.contactId,
                    }

                    if (this.state.callbackTenant) {
                        let callbackParam = addURLParameter(
                            this.props.location.search,
                            'addTenantCallback=' + JSON.stringify(callback)
                        )
                        this.props.history.push({
                            search: callbackParam,
                        })
                    }

                    if (this.state.callbackLandlord) {
                        let callbackParam = addURLParameter(
                            this.props.location.search,
                            'addLandlordCallback=' + JSON.stringify(callback)
                        )
                        this.props.history.push({
                            search: callbackParam,
                        })
                    }

                    if (this.state.callbackPropertyManager) {
                        let callbackParam = addURLParameter(
                            this.props.location.search,
                            'addPropertyManagerCallback=' +
                                JSON.stringify(callback)
                        )
                        this.props.history.push({
                            search: callbackParam,
                        })
                    }

                    if (this.state.newContactCallback) {
                        let callbackParam = addURLParameter(
                            this.props.location.search,
                            'addContactCallback=' + JSON.stringify(callback)
                        )
                        this.props.history.push({
                            search: callbackParam,
                        })
                    }

                    this.closeSidebar()
                }
            })
            .catch((e) => {
                this.setState({
                    error: 'The email address is already associated with a user record.',
                })
            })
    }

    async getEntityTypes(type: string, field: string): Promise<void> {
        getTypesForDropdown(type).then((resp) => {
            if (resp && resp.data) {
                this.setState({ [field]: resp.data } as Pick<IState, any>)
            }
        })
    }

    getTaxTypes(): void {
        getTypesForDropdown(taxTypeGroupId).then((resp) => {
            if (resp && resp.data) {
                this.setState({
                    taxTypes: resp.data,
                })
            }
        })
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const field = event.currentTarget.name
        let value: string = event.currentTarget.value
        value = capitaliseFirstLetter(value)

        if (field === 'email') {
            let isValidEmail = validateEmail(value)
            if (isValidEmail) {
                this.setState({
                    invalidEmail: false,
                })
            }
        }

        this.setState({ [field]: value } as Pick<IState, any>, () =>
            this.checkForChanges()
        )
    }

    componentDidMount(): void {
        this.getEntityTypes(entityTypeGroupId, 'typeTypes').then(() => {
            this.getEntityTypes(taxTypeGroupId, 'taxTypes')
            this.getEntityTypes(ownerTypeGroupId, 'ownerTypes')

            let callbackTenant = queryString.parse(
                this.props.location.search
            ).callbackTenant

            if (callbackTenant) {
                this.setState({
                    addTenant: true,
                })
            }

            if (callbackTenant) {
                this.setState(
                    {
                        callbackTenant: true,
                    },
                    () => {
                        let query = removeURLParameter(
                            this.props.location.search,
                            'callbackTenant'
                        )
                        this.props.history.push({
                            search: query,
                        })
                        this.setState({
                            contactType: '921CCFFF-7386-400D-804C-B638121A75D2',
                        })
                    }
                )
            }

            let callbackLandlord = queryString.parse(
                this.props.location.search
            ).callbackLandlord

            if (callbackLandlord) {
                this.setState(
                    {
                        callbackLandlord: true,
                    },
                    () => {
                        let query = removeURLParameter(
                            this.props.location.search,
                            'callbackLandlord'
                        )
                        this.props.history.push({
                            search: query,
                        })
                        this.setState({
                            contactType: 'E08D17B2-3FA0-43BB-8458-18378F92FDED',
                        })
                    }
                )
            }

            let callbackPropertyManager = queryString.parse(
                this.props.location.search
            ).callbackPropertyManager

            if (callbackPropertyManager) {
                this.setState(
                    {
                        callbackPropertyManager: true,
                    },
                    () => {
                        let query = removeURLParameter(
                            this.props.location.search,
                            'callbackPropertyManager'
                        )
                        this.props.history.push({
                            search: query,
                        })
                        this.setState({
                            contactType: '897D7652-F770-42A2-AB3D-9D3E6CDEDF51',
                        })
                    }
                )
            }

            let newContactCallback = queryString.parse(
                this.props.location.search
            ).newContactCallback

            if (newContactCallback) {
                this.setState(
                    {
                        newContactCallback: true,
                    },
                    () => {
                        let query = removeURLParameter(
                            this.props.location.search,
                            'newContactCallback'
                        )
                        this.props.history.push({
                            search: query,
                        })
                    }
                )
            }

            let name = queryString.parse(this.props.location.search).name

            if (name) {
                if (name.trim().indexOf(' ') != -1) {
                    let firstWord = name.split(' ')
                    var result
                    if (firstWord instanceof Array) {
                        result = firstWord
                            .filter((x) => x !== firstWord[0])
                            .join(' ')
                    }

                    this.setState({
                        forename: capitaliseFirstLetter(firstWord[0]),
                        surname: capitaliseFirstLetter(result),
                    })
                } else {
                    this.setState({
                        surname: capitaliseFirstLetter(name),
                    })
                }

                let query = removeURLParameter(
                    this.props.location.search,
                    'name'
                )
                this.props.history.push({
                    search: query,
                })
            }
        })
    }

    setTenantType(): IOptions {
        let tenant = this.state.typeTypes.filter((ct) => ct.label == 'Tenant')

        if (!tenant) {
            return
        }

        this.setState(
            {
                type: tenant[0],
            },
            () => this.checkForChanges()
        )

        return this.state.type
    }

    componentWillUnmount(): void {
        let removeQuery = removeURLParameter(
            this.props.location.search,
            'addTenant'
        )
        this.props.history.push({
            search: removeQuery,
        })

        this.setState({
            contactType: '',
        })
    }

    contactInformationFilled() {
        if (this.state.contactType === '') return false

        if (this.state.type.value.toUpperCase() === this.typePropertyOwner) {
            if (this.state.ownerType === this.typeBlank) return false

            if (this.state.taxType === this.typeBlank) return false
        }

        if (this.state.forename === '') return false

        return true
    }

    render() {
        if (!isLandlord() && !isPlatformAdmin()) {
            return <div>Not authorised</div>
        }

        if (this.state.addLoading)
            return <RotatingLoader text="Adding contact..." loading />
            
        if (this.state.success) {
            return (
                <div>
                    <SuccessTick />
                    <p className={styles.text}>Contact has been added</p>
                </div>
            )
        }

        return (
            <div className={styles.page}>
                <Formik
                    initialValues={{
                        email: this.state.email ? this.state.email : '',
                        contactType: this.state.contactType ? this.state.contactType : '',
                        forename: this.state.forename ? this.state.forename : '',
                        surname: this.state.surname ? this.state.surname: '',
                    }}
                    onSubmit={(values: any) => {
                            this.handleSaveClick({
                                forename: values.forename,
                                surname: values.surname,
                                email: values.email,
                                contactType: values.contactType
                            });
                        }
                    }
                    enableReinitialize={true}
                    validationSchema={AddContactSchema}
                    validateOnChange={false}
                >
                    {(formProps) => (
                        <Form className={styles.form}>
                            <div className={styles.Content} />
                            <Row>
                                <Col>
                                    <div className={styles.headerTitle}>
                                        Add{' '}
                                        {this.state.addTenant ? 'Tenant' : 'Contact'}{' '}
                                        <div className={styles.close}>
                                            <CloseButton close={this.closeSidebar} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <p className={styles.p} />
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label
                                            className={styles.label}
                                            for="contact.name"
                                        >
                                            Forename*
                                        </Label>
                                        <Input
                                            type="text"
                                            name="forename"
                                            id="forename"
                                            placeholder="Forename"
                                            onChange={(e) =>{
                                                formProps.setFieldValue(
                                                    'forename',
                                                    capitaliseFirstLetter(e.currentTarget.value)
                                                )
                                            }
                                            }
                                            value={formProps.values.forename}
                                        />
                                        <div className={styles.errorMessage}>
                                            <ErrorMessage
                                                className={styles.errorMessage}
                                                name="forename"
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label
                                            className={styles.label}
                                            for="contact.name"
                                        >
                                            Surname*
                                        </Label>
                                        <Input
                                            type="text"
                                            name="surname"
                                            id="surname"
                                            placeholder="Surname"
                                            onChange={(e) =>
                                                formProps.setFieldValue(
                                                    'surname',
                                                    capitaliseFirstLetter(e.currentTarget.value)
                                                )
                                            }
                                            value={formProps.values.surname}
                                        />
                                        <div className={styles.errorMessage}>
                                            <ErrorMessage
                                                className={styles.errorMessage}
                                                name="surname"
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label
                                            className={styles.label}
                                            for="contact.name"
                                        >
                                            Email
                                        </Label>
                                        <Input
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="Email"
                                            onChange={(e) =>
                                                formProps.setFieldValue(
                                                    'email',
                                                    e.currentTarget.value
                                                )
                                            }
                                            value={formProps.values.email}
                                        />
                                        <div className={styles.errorMessage}>
                                            <ErrorMessage
                                                className={styles.errorMessage}
                                                name="email"
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label
                                            className={styles.label}
                                            for="contact.name"
                                        >
                                            Contact Type*
                                        </Label>

                                        <SelectDropdown
                                            selectedId={formProps.values.contactType}
                                            data={this.state.typeTypes}
                                            placeholder="Select contact type..."
                                            onSelect={(id: IOptions) =>{
                                                formProps.setFieldValue(
                                                    'contactType',
                                                    id.value
                                                )
                                            }
                                            }
                                        />
                                        <div className={styles.errorMessage}>
                                            <ErrorMessage
                                                className={styles.errorMessage}
                                                name="contactType"
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {this.state.type.value.toUpperCase() ===
                                this.typePropertyOwner && (
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label
                                                className={styles.label}
                                                for="contact.name"
                                            >
                                                Owner Type
                                            </Label>

                                            <SelectDropdown
                                                data={this.state.ownerTypes}
                                                placeholder="What type of Owner..."
                                                onSelect={(id: IOptions) =>
                                                    this.setState(
                                                        { ownerType: id.value },
                                                        () => this.checkForChanges()
                                                    )
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )}
                            {this.state.type.value.toUpperCase() ===
                                this.typePropertyOwner && (
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label
                                                className={styles.label}
                                                for="contact.name"
                                            >
                                                Tax Rate
                                            </Label>

                                            <SelectDropdown
                                                data={this.state.taxTypes}
                                                placeholder="Select tax rate..."
                                                onSelect={(id: IOptions) =>
                                                    this.setState({ taxType: id.value })
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col className={styles.buttons}>
                                    <FormGroup>
                                        <Buttons
                                            buttons={[
                                                {
                                                    width: 'full',
                                                    displayType: 'cancel',
                                                    elementType: 'button',
                                                    onClick: () =>
                                                        this.props.toggleSidebar(false),
                                                },
                                                {
                                                    width: 'full',
                                                    displayType: 'submit',
                                                    elementType: 'button',
                                                    onClick: () =>
                                                        formProps.submitForm(),
                                                    
                                                },
                                            ]}
                                        />

                                        {this.state.invalidEmail && (
                                            <p className={styles.error}>
                                                You must enter a valid email
                                            </p>
                                        )}

                                        {this.state.error && (
                                            <p className={styles.error}>
                                                {this.state.error}
                                            </p>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
                
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    app_loading: state.app.app_loading,
    is_logged_in: state.user.is_logged_in,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toggleSidebar: (show: boolean) => dispatch(toggleAddContactSidebar(show)),
})
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AddContactForm) as any
)
