import styled from 'styled-components';
import { minWidthTablet } from '../../../../global/variables/_variables';

export const ContainerDiv = styled.div<{ highlighted?: boolean }>`
    padding: 5px 0px 5px 0px;
    background-color: white;
    height: 100%;
`;

export const InnerContainerDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    @media (max-width: ${minWidthTablet}) {            
        display: initial;
    }
`;

export const MainAreaDiv = styled.div`
    flex: 0 0 40%;
    order: 1;
`;

export const SecondAreaDiv = styled.div<{ visibility?: string }>`
    flex: 0 0 60%;
    order: 2;
    white-space: nowrap;
    visibility: ${p => p.visibility ? p.visibility : 'visible'};
`;

export const AnswerAreaDiv = styled.div<{ visibility?: string, width?: string }>`
    width: ${p => p.width ? p.width : '100%'};
    visibility: ${p => p.visibility ? p.visibility : 'visible'};
    //height: ${p => p.visibility && p.visibility == 'visible' ? '50px' : '0px'};
    padding: 5px 0px 5px 10px;

    @media (max-width: ${minWidthTablet}) {
        width: 98%;
    }
`;

export const HeaderDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    justify-content: flex-start;
    column-gap: 4px;
`;

export const TitleDiv = styled.div`
    order: 1;
    padding: 5px 0px 5px 10px;
    //white-space: nowrap;
    font-weight: bold;
`;

export const TooltipDiv = styled.div`
    order: 2;
`;

export const DetailDiv = styled.div`
    text-align: left;
    padding: 5px 10px 5px 10px;
    color: gray;
    //white-space: pre-wrap;
`;

export const ErrorDiv = styled.div`
    color: red !important;
    font-size: 0.85rem;
    text-align: left;
    margin-left: 10px;
`;
