import React, { useState } from "react";
import { QuickViewType } from "../../../types/QuickView";
import { getSearchUsers } from "../../../services/SearchService";
import { ISearchResult } from "../../../types/SearchService/ISearchService";
import { IChatUsers } from "../../../services/ChatService";
import SelectDropdownV2 from "../SelectDropdownV2/SelectDropdownV2.component";

interface IProps {
  history?: any;
  location?: any;
  match?: any;

  selectedId: string;
  selectedName: string;
  onSelect(option: ISearchResult): void;
  onClear?(): void;

  filteredOutUserList?: Array<IChatUsers>;
}

const SearchUsersInput = (props: IProps) => {
  const [results, setResults] = useState<ISearchResult[]>([]);

  const onSelect = (option: ISearchResult) => {
    setResults([]);
    props.onSelect(option);
  }

  const getUsers = (search?: string) => {
    getSearchUsers(search ? search : '').then(resp => {
      if (resp && resp.status == 200 && resp.data) {
        setResults(filterUserIfRequired(resp.data));
      }
    });
  }

  const filterUserIfRequired = (respUsers: ISearchResult[]) =>{
    var finalUserList : ISearchResult[] = [];
    if(props.filteredOutUserList){
        respUsers.map((rUser)=>{
        let found = false;
        props.filteredOutUserList.map((user) => {
          if(user.userId == rUser.value){
            found = true;
          }
        })
        if(!found){
          finalUserList.push(rUser);
        }
      })
      return finalUserList;
    }else{
      return respUsers;
    }
  }

  return (
    <SelectDropdownV2 
      onClear={props.onClear}
      disableClear={false}
      selectedId={props.selectedId}
      selectedName={props.selectedName}
      onSelect={(options) => onSelect({value: options.value, label: options.label})}
      onSearch={getUsers}
      data={results}
      inputPlaceHolder={"Search for a User..."}
      quickView={QuickViewType.Contact}
      isSearchable={true}
    />
  )
}

export default SearchUsersInput;