import React from "react";
import styles from "./TenancyStatus.module.scss";

interface IProps {
  status: string;
}

class TenancyStatus extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { status } = this.props;

    return (
      <span className={`${styles.tenancyStatus} ${status.toLowerCase() == "approved" ? styles.approved :
        status.toLowerCase() == "current" ? styles.current :
          status.toLowerCase() == "ending" ? styles.ending :
            status.toLowerCase() == "past" ? styles.past :
              status.toLowerCase() == "rejected" ? styles.rejected :
                status.toLowerCase() == "new" ? styles.new : ""}`}>{status.toUpperCase()}</span>
    );
  }
}

export default TenancyStatus;