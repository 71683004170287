import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers/rootReducer';
import RotatingLoader from '../../atoms/RotatingLoader';
import ConductInspectionForm from '../../molecules/ConductInspectionForm';
import { getInspectionFromInspectionId, setViewingInspection } from '../../../actions/conductInspectionActions';
import dotIcon from '../../../images/menu-dot-icon-png.png';
import styles from './ConductInspectionPage.module.scss';
import { IReactRouterProps } from '../../../types';
import { withRouter } from 'react-router';
import { IConductInspectionOptions } from '../../../types/ConductInspection';
import { removeURLParameter } from '../../../helpers/locationHelpers';
import { ConductInspectionProvider } from '../../../types/Dynamic/conductInspectionContext';
import { isPlatformAdmin } from "../../../helpers/roleHelpers";
const queryString = require("query-string");

const ConductInspectionPage = (props: IReactRouterProps) => {
    const { loading, viewing_inspection } = useSelector((state: RootState) => state.conductInspection);
    const { viewing_inspection: inspectionDto } = useSelector((state: RootState) => state.inspection);
    const [isEditing, setIsEditing] = useState(false);
    const [options, setOptions] = useState<IConductInspectionOptions>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        let options = queryString.parse(props.location.search).conductInspectionOptions;
        if (options) {
            let json: IConductInspectionOptions = JSON.parse(options);
            if (!json) {
                return;
            }

            setOptions(json);

            let removeQuery = removeURLParameter(props.location.search, "conductInspectionOptions");
            props.history.replace({
                search: removeQuery
            })
        }

        if (!viewing_inspection) {
            dispatch(getInspectionFromInspectionId(inspectionDto.inspectionId, inspectionDto.inspectionTypeId));
        }

        return () => {
            dispatch(setViewingInspection(null));
            setOptions(null);
        }
    }, []);

    const onEdit = (e) => {
        if (!e) {
            return null;
        }

        dispatch(setViewingInspection({
            ...viewing_inspection,
            objectResult: JSON.parse(e.currentTarget.value)
        }));
    }


    if (loading) {
        return <div><RotatingLoader loading text='Loading...' /></div>
    }

    if (!viewing_inspection) {
        return <div>No template is available for the provided inspection</div>
    }

    return (
        <div className={styles.conductInspectionPage}>
            <div className={styles.header}>
                <div className={styles.textContainer}>
                    <div className={styles.headerText}>
                        {viewing_inspection.objectResult.headerText}
                    </div>

                    {inspectionDto &&
                        <div className={styles.subText}>
                            {inspectionDto.propertyName}
                        </div>
                    }

                </div>
                {
                    isPlatformAdmin() &&
                    <div className={styles.icon} onClick={() => setIsEditing(!isEditing)}>
                        <img src={dotIcon} />
                    </div>
                }

            </div>

            <div className={styles.content}>
                <div className={styles.form}>
                    <ConductInspectionProvider>
                        <ConductInspectionForm inspectionResult={viewing_inspection} inspectionDto={inspectionDto} options={options} />
                    </ConductInspectionProvider>
                </div>

                {
                    isEditing &&
                    <div className={styles.editor}>
                        <textarea rows={20} onChange={onEdit} value={JSON.stringify(viewing_inspection.objectResult, null, 2)} />
                    </div>
                }

            </div>
        </div>
    )
}

export default withRouter(ConductInspectionPage);