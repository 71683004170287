import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Col, FormGroup, Label, Row } from 'reactstrap'
import { toggleAddClientSidebar } from '../../../helpers/sidebarHelpers'
import { delayAction } from '../../../helpers/timerHelpers'
import { addClient } from '../../../services/ClientService'
import { ICreateClient } from '../../../types/ClientService'
import { AddClient } from '../../../YupValidationSchemas'
import Buttons from '../../atoms/Buttons'
import CloseButton from '../../atoms/CloseButton'
import MaterialCheckbox from '../../atoms/MaterialCheckbox'
import SuccessTick from '../../atoms/SuccessTick'
import styles from './AddClientForm.module.css'

interface IProps {
    onSuccessHandler(): void
}

interface IRouterProps {
    history: any
    location: any
    match: any
}

interface IState {
    clientName: string
    clientDescription: string
    registrationsEnabled: boolean

    loading: boolean
    success: boolean
}

type Props = IRouterProps & IProps

class AddUserForm extends Component<Props, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            clientName: '',
            clientDescription: '',
            registrationsEnabled: true,
            loading: false,
            success: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onCheckChange = this.onCheckChange.bind(this)
        this.isLoading = this.isLoading.bind(this)
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const field = event.currentTarget.name
        const value: string = event.currentTarget.value
        this.setState({ [field]: value } as Pick<IState, any>)
    }

    onCheckChange(): void {
        this.setState({
            registrationsEnabled: !this.state.registrationsEnabled,
        })
    }

    isLoading(loading: boolean): void {
        this.setState({
            loading,
        })
    }

    onSubmit(): void {
        this.isLoading(true)

        let request: ICreateClient = {
            name: this.state.clientName,
            description: this.state.clientDescription,
        }

        addClient(request).then((resp) => {
            this.isLoading(false)
            if (resp && resp.status == '200') {
                this.setState({
                    success: true,
                })

                if (this.props.onSuccessHandler) {
                    this.props.onSuccessHandler()
                }

                delayAction(2000, () => toggleAddClientSidebar(false))
            }
        })
    }

    componentDidMount() {}

    render() {
        if (this.state.loading) {
            return <div>Adding your new client...</div>
        }

        if (this.state.success) {
            return (
                <div>
                    <SuccessTick />{' '}
                    <span className={styles.text}>
                        {this.state.clientName} has been added as a client.
                    </span>
                </div>
            )
        }

        return (
            <div className={styles.form}>
                <Row className={styles.header}>
                    <div className={styles.close}>
                        <CloseButton
                            close={() => toggleAddClientSidebar(false)}
                        />
                    </div>
                    <Col>
                        <h1 className={styles.h1}>Add Client</h1>
                    </Col>
                </Row>
                <Formik
                    initialValues={{
                        clientName: '',
                        clientDescription: '',
                    }}
                    validationSchema={AddClient}
                    onSubmit={(values: any) => {
                        this.setState({
                            clientName: values.clientName,
                            clientDescription: values.clientDescription,
                        })

                        this.onSubmit()
                    }}
                >
                    {(props) => (
                        <Form className={styles.form}>
                            <FormGroup>
                                <Label
                                    className={styles.label}
                                    for="clientName"
                                >
                                    Client name
                                </Label>
                                <Field
                                    className={styles.input}
                                    type="text"
                                    name="clientName"
                                    placeholder="Client name"
                                />
                            </FormGroup>
                            <div className={styles.errorMessage}>
                                <ErrorMessage
                                    className={styles.errorMessage}
                                    name="clientName"
                                />
                            </div>

                            <FormGroup>
                                <Label
                                    className={styles.label}
                                    for="clientDescription"
                                >
                                    Client description
                                </Label>
                                <Field
                                    className={styles.input}
                                    type="text"
                                    name="clientDescription"
                                    placeholder="Client description"
                                />
                            </FormGroup>
                            <div className={styles.errorMessage}>
                                <ErrorMessage
                                    className={styles.errorMessage}
                                    name="clientDescription"
                                />
                            </div>

                            <FormGroup>
                                <MaterialCheckbox
                                    labelText="Registrations enabled?"
                                    checked={this.state.registrationsEnabled}
                                    onChange={this.onCheckChange}
                                />
                            </FormGroup>

                            <Buttons
                                buttons={[
                                    {
                                        width: 'full',
                                        text: 'Cancel',
                                        displayType: 'cancel',
                                        elementType: 'button',
                                        onClick: () =>
                                            toggleAddClientSidebar(false),
                                    },
                                    {
                                        width: 'full',
                                        text: 'Add Client',
                                        displayType: 'submit',
                                        elementType: 'button',
                                        onClick: () => props.submitForm(),
                                    },
                                ]}
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}

export default withRouter(AddUserForm) as any
