import React, { useEffect, useState } from "react";
import RCGrid from "./RCGrid.component";
import { IListItemOptions } from "../../../../types/ListFilters";
import { getAuditsV2 } from "../../../../services/AuditService";
import { IAuditListResponseV2 } from "../../../../types/AuditService";
import { Button, Popup } from "@rentchief/components";
import { auditGridGridColumns, reformatDates } from './auditGridHelper'
import styles from './AuditPageV2.module.scss'

const AuditPageV2 = () => {
    const [results, setResults] = useState<IAuditListResponseV2>(null);
    const [envDetailsOpen, setEnvDetailsOpen] = useState(false);
    const [envDetails, setEnvDetails] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetch();
      },[]
    )

    const fetch = async (
        page?: number,
        size?: number,
        skip?: number,
        sortQuery?: string
      ) => {

        page = page != undefined ? page : 1;
        size = size != undefined ? size : 20;
        skip = skip != undefined ? skip : 0;
        sortQuery = sortQuery != undefined ? sortQuery : "";
    
        let listOptions : IListItemOptions = {
            page: page,
            size: size,
            skip: skip,
            sortQuery: sortQuery
        };

        setLoading(true);
        const response = await getAuditsV2(listOptions);
    
        if (response.data)
        {
          let dataWithFornattedDates = null;
          if(response.data.count){
            dataWithFornattedDates = reformatDates(response.data.data);
          }
          setResults(response.data.count > 0 ?  { data: dataWithFornattedDates, count: response.data.count } : null); 
        }

        setLoading(false);
      };

      const renderTableData = () : any => {
          
          if(envDetails){
            const res = JSON.parse(envDetails);
            var envArray = [];
            Object.entries(res).forEach((entry) => {
                envArray.push(entry);
                });
            return envArray.map((entry, index) => {
                const [key, value] = entry;
               return (
                  <tr>
                     <td>{key}</td>
                     <td>{value}</td>
                  </tr>
               )
            })
          }

     }

     const processClick = (x: any) => {
      setEnvDetails(x);
      setEnvDetailsOpen(true);
    };

    const commandClick = (commadTitle: string, rowData: any ): void => {
      if(commadTitle == "environment"){
        processClick(rowData["environmentAuditJson"]);
      }
      else if(commadTitle == "context"){
        processClick(rowData["contextAuditJson"]);
      }
    }

    const onRowClick = (rowData : any) => {
      processClick(rowData["contextAuditJson"]);
    };

    return (
        <>
        <div className={styles.pageBorder}>
          <RCGrid
                /* noResultsMessage={``} */
                datasource={results}
                loading={loading}
                fetchData={fetch}
                rowClick={onRowClick}
                commandClick={commandClick}
                columnsDirective={() => {return auditGridGridColumns();}}
              />
        </div>
        <Popup 
            title="Details"
            open={envDetailsOpen}
            onClose={() => setEnvDetailsOpen(false)}
        >
            <div id="envDetailsDiv">
                <table>
                    <tbody>
                    {renderTableData()}
                    </tbody>
                </table>
                
            </div>
            <Button onClick={() => setEnvDetailsOpen(false)}>
                Close
            </Button>
        </Popup>
        </>
        
        
    );
}

export default AuditPageV2;