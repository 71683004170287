import { getIn } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { IQuestion, ISection } from "../../../../types/Dynamic/types";
import useConductInspection from '../../../../types/Dynamic/useConductInspection';
import CollapsibleContent from '../../../atoms/CollapsibleContent';
import ConductQuestion from '../ConductQuestion';

import styles from './ConductSection.module.scss';

interface IProps {
    section: ISection;
    setFieldValue: any;
    sectionIndex: number;
    objectRoute?: string;
}

const ConductSection = ({ section, setFieldValue, objectRoute, sectionIndex }: IProps) => {
    const [initialLoad, setInitialLoad] = useState(false);
    const { getValidation, validations } = useConductInspection();
    const ref = useRef(null);

    const handleClick = (open: boolean) => {
        if (ref.current && ref.current.toggle) {
            ref.current.toggle(open)
        }
    };

    useEffect(() => {
        if (initialLoad) {
            var hasErrors = getValidation(objectRoute, true);
            if (hasErrors) {
                handleClick(true);
            }
        } else {
            setInitialLoad(true);
        }
    }, [validations])

    const route = (index) => `${objectRoute ? `${objectRoute}.sections[${index}]` : `sections[${index}]`}`

    const sortQuestions = (a: IQuestion, b: IQuestion) => a.sequenceOrder > b.sequenceOrder ? 1 : -1;

    const mapQuestions = (question: IQuestion, index: number) =>
        <ConductQuestion objectRoute={`${objectRoute}.questions[${index}]`} onHandleChange={(question) => onHandleChange(question, index)} question={question} />

    const onHandleChange = (question: IQuestion, index: number) => {
        setFieldValue(`${objectRoute}.questions[${index}]`, question);
    }

    const content = (
        <>
            {/* Header Text */}
            {section.hasAccordion === false && section.headerText && section.questions && <div className={styles.headerText}>{section.headerText}</div>}

            {/* Questions */}
            {section.questions && section.questions
                .sort(sortQuestions)
                .map(mapQuestions)}

            {/* Recursively render Child Sections */}
            {section.sections && section.sections.map((s, i) => <ConductSection objectRoute={route(i)} sectionIndex={i} section={s} setFieldValue={setFieldValue} />)}
        </>
    )

    return (
        section.hasAccordion === false ? <div>{content}</div> : <CollapsibleContent scrollIntoView ref={ref} defaultStateOpen={section.isAccordionCollapsed} title={section.headerText}>{content}</CollapsibleContent>
            
    )
}

export default ConductSection;