import { FileInfo } from "@syncfusion/ej2-react-inputs";
import { IOptions } from "../../components/atoms/SelectDropdown";
import { IImageDto } from "../ImageService";

export interface IAssetsListItemOptions {
    // Pass 0 size to get all records
    size: number;
    page: number;
    skip?: number;
    assetTypeId?: number;
    propertyId?: string;
    issueId?: string;
    clientId?: string;
    unitId?: string;
    search?: string;
    status?: string;
    onlyShowOwnRecords?: boolean;
    sortQuery?: string;
}

export interface IAssetAddEdit {
    propertyId?: string;
    propertyName?: string;
    unitId?: string;
    unitName?: string;
    returnUrl?: string;
    referer?: string;
    images?: string[];
}

export interface IAssetDto {
    assetId?: number;
    createdByUserId?: string;
    teamId?: number;
    assetName?: string;
    assetSubTitle?: string;
    assetTypeLevel1Id?: number;
    assetTypeLevel1Name?: string;
    assetTypeLevel2Id?: number;
    assetTypeLevel2Name?: string;
    assetTagId?: string;
    supplier?: string;
    purchasedDate?: Date;
    make?: string;
    serviceScheduleTypeId?: string;
    quantityAndValueConditionTypeId?: string;
    model?: string;
    propertyId?: string;
    propertyName?: string;
    unitId?: string;
    unitName?: string;
    cost?: number,
    widthInInches?: number,
    heightInInches?: number,
    depth?: number,
    colour?: string,
    description?: string,
    meter1Reading?: number,
    meter1ReadingDate?: Date,
    meter2Reading?: number,
    meter2ReadingDate?: Date
    images?: IImageDto[],
    newImages?: any,
    documents?: string[],

    productStatusTypeId?: number;

    // Additional fields
    dateOfInstallation?: Date;
    warrantyExpirationDate?: Date;
    nextService?: Date;
    modelNumber?: string;
    serialNumber?: string;

    // Quantity and Value
    quantity?: number;
    value?: number;
    totalValue?: number;

    // Energy
    energyUsage?: number;
    energyInfo?: string;
}

interface IBaseType {
    typeId: string;
    typeName: string;
    description: string;
}

export interface IServiceScheduleType extends IBaseType { };
export interface IQuantityAndValueConditionType extends IBaseType { };
export interface IProductStatusType extends IBaseType { };

export interface IDropdownTypes {
    serviceSchedule: IServiceScheduleType[];
    quantityAndValueConditionType: IQuantityAndValueConditionType[];
    productStatusType: IProductStatusType[];
}

export interface IAssetsListResponse {
    data: IAssetDto[];
    count: number;
}

export interface IAssetType {
    assetTypeId: number;
    assetTypeName: string;
    description: string;
    parentAssetTypeId: number;
    status: number;
    subtypes: Array<IAssetType>;
}

export interface IAssetTypeCollection {
    assetTypes: Array<IAssetType>,
    assetSubtypes: Array<IAssetType>
}

export enum AssetActionType {
    CLEAR_ASSETS = 'CLEAR_ASSETS',
    CLEAR_ASSET = 'CLEAR_ASSET',
    GET_ASSETS = 'GET_ASSETS',
    GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS',
    GET_ASSETS_ERROR = 'GET_ASSETS_ERROR',
    GET_ASSET = 'GET_ASSET',
    GET_ASSET_SUCCESS = 'GET_ASSET_SUCCESS',
    GET_ASSET_LOCAL_SUCCESS = 'GET_ASSET_LOCAL_SUCCESS',
    GET_ASSET_ERROR = 'GET_ASSET_ERROR',
    ADD_ASSET = "ADD_ASSET",
    ADD_ASSET_SUCCESS = "ADD_ASSET_SUCCESS",
    ADD_ASSET_ERROR = "ADD_ASSET_ERROR",
    TOGGLE_DELETE_ASSETS = 'TOGGLE_DELETE_ASSETS',
    DELETE_ASSETS = "DELETE_ASSETS",
    DELETE_ASSETS_SUCCESS = "DELETE_ASSETS_SUCCESS",
    DELETE_ASSETS_ERROR = "DELETE_ASSETS_ERROR",
    GET_ASSET_IMAGES = "GET_ASSET_IMAGES",
    GET_ASSET_IMAGES_SUCCESS = "GET_ASSET_IMAGES_SUCCESS",
    GET_ASSET_IMAGES_ERROR = "GET_ASSET_IMAGES_ERROR",
    DELETE_ASSET_IMAGE = "DELETE_ASSET_IMAGE",
    DELETE_ASSET_IMAGE_SUCCESS = "DELETE_ASSET_IMAGE_SUCCESS",
    DELETE_ASSET_IMAGE_ERROR = "DELETE_ASSET_IMAGE_ERROR",
    UPDATE_ASSET = "UPDATE_ASSET",
    UPDATE_ASSET_SUCCESS = "UPDATE_ASSET_SUCCESS",
    UPDATE_ASSET_ERROR = "UPDATE_ASSET_ERROR",
    GET_SERVICE_SCHEDULE_TYPES = "GET_SERVICE_SCHEDULE_TYPES",
    GET_SERVICE_SCHEDULE_TYPES_SUCCESS = "GET_SERVICE_SCHEDULE_TYPES_SUCCESS",
    GET_SERVICE_SCHEDULE_TYPES_ERROR = "GET_SERVICE_SCHEDULE_TYPES_ERROR",
    GET_QUANTITY_AND_VALUE_CONDITION_TYPES = "GET_QUANTITY_AND_VALUE_CONDITION_TYPES",
    GET_QUANTITY_AND_VALUE_CONDITION_TYPES_SUCCESS = "GET_QUANTITY_AND_VALUE_CONDITION_TYPES_SUCCESS",
    GET_QUANTITY_AND_VALUE_CONDITION_TYPES_ERROR = "GET_QUANTITY_AND_VALUE_CONDITION_TYPES_ERROR",
    GET_PRODUCT_STATUS_TYPES = "GET_PRODUCT_STATUS_TYPES",
    GET_PRODUCT_STATUS_TYPES_SUCCESS = "GET_PRODUCT_STATUS_TYPES_SUCCESS",
    GET_PRODUCT_STATUS_TYPES_ERROR = "GET_PRODUCT_STATUS_TYPES_ERROR",
    GET_ASSET_TYPES = "GET_ASSET_TYPES",
    GET_ASSET_TYPES_SUCCESS = "GET_ASSET_TYPES_SUCCESS",
    GET_ASSET_TYPES_ERROR = "GET_ASSET_TYPES_ERROR",
    UPDATE_SELECTED_LEVEL1_ID = "UPDATE_SELECTED_LEVEL1_ID"
}

// The following are acitons and should really sit in a actions file, no such structure exists at the moment
export interface IGetProductStatusTypes {
    type: AssetActionType.GET_PRODUCT_STATUS_TYPES;
}

export interface IGetProductStatusTypesSuccess {
    type: AssetActionType.GET_PRODUCT_STATUS_TYPES_SUCCESS;
    payload: IProductStatusType[];
}

export interface IGetProductStatusTypesError {
    type: AssetActionType.GET_PRODUCT_STATUS_TYPES_ERROR;
    payload: string;
}

export interface IGetQuantityAndValueConditionTypes {
    type: AssetActionType.GET_QUANTITY_AND_VALUE_CONDITION_TYPES;
}

export interface IGetQuantityAndValueConditionTypesSuccess {
    type: AssetActionType.GET_QUANTITY_AND_VALUE_CONDITION_TYPES_SUCCESS;
    payload: IQuantityAndValueConditionType[];
}

export interface IGetQuantityAndValueConditionTypesError {
    type: AssetActionType.GET_QUANTITY_AND_VALUE_CONDITION_TYPES_ERROR;
    payload: string;
}

export interface IGetServiceScheduleTypes {
    type: AssetActionType.GET_SERVICE_SCHEDULE_TYPES;
}

export interface IGetServiceScheduleTypesSuccess {
    type: AssetActionType.GET_SERVICE_SCHEDULE_TYPES_SUCCESS;
    payload: IServiceScheduleType[];
}

export interface IGetServiceScheduleTypesError {
    type: AssetActionType.GET_SERVICE_SCHEDULE_TYPES_ERROR;
    payload: string;
}

export interface IClearAssets {
    type: AssetActionType.CLEAR_ASSETS;
}

export interface IDeleteAssetImage {
    type: AssetActionType.DELETE_ASSET_IMAGE;
}

export interface IDeleteAssetImageSuccess {
    type: AssetActionType.DELETE_ASSET_IMAGE_SUCCESS;
    payload: string;
}

export interface IDeleteAssetImageError {
    type: AssetActionType.DELETE_ASSET_IMAGE_ERROR;
}

export interface IGetAssetImages {
    type: AssetActionType.GET_ASSET_IMAGES;
}

export interface IGetAssetImagesSuccess {
    type: AssetActionType.GET_ASSET_IMAGES_SUCCESS;
    payload: IImageDto[];
}

export interface IGetAssetImagesError {
    type: AssetActionType.GET_ASSET_IMAGES_ERROR;
    payload: string;
}

export interface IClearAssetAction {
    type: AssetActionType.CLEAR_ASSET;
}

export interface IGetAssetsAction {
    type: AssetActionType.GET_ASSETS;
}

export interface IGetAssetsSuccessAction {
    type: AssetActionType.GET_ASSETS_SUCCESS;
    payload: IAssetsListResponse;
}

export interface IGetAssetsErrorAction {
    type: AssetActionType.GET_ASSETS_ERROR;
    payload: string;
}

export interface IGetAssetAction {
    type: AssetActionType.GET_ASSET;
}

export interface IGetAssetSuccessAction {
    type: AssetActionType.GET_ASSET_SUCCESS;
    payload: IAssetDto;
}

export interface IGetAssetLocalSuccessAction {
    type: AssetActionType.GET_ASSET_LOCAL_SUCCESS;
    payload: number;
}

export interface IGetAssetErrorAction {
    type: AssetActionType.GET_ASSET_ERROR;
    payload: string;
}

export interface IAddAssetAction {
    type: AssetActionType.ADD_ASSET;
}

export interface IAddAssetSuccessAction {
    type: AssetActionType.ADD_ASSET_SUCCESS;
}

export interface IAddAssetErrorAction {
    type: AssetActionType.ADD_ASSET_ERROR;
    payload: string;
}

export interface IToggleDeleteAssetsAction {
    type: AssetActionType.TOGGLE_DELETE_ASSETS;
    payload: {
        open: boolean,
        assetIds: Array<number>,
        returnUrl: string
    }
}

export interface IDeleteAssetsAction {
    type: AssetActionType.DELETE_ASSETS;
}

export interface IDeleteAssetsSuccessAction {
    type: AssetActionType.DELETE_ASSETS_SUCCESS;
    payload: number[];
}

export interface IDeleteAssetsErrorAction {
    type: AssetActionType.DELETE_ASSETS_ERROR;
    payload: string;
}

export interface IUpdateAssetAction {
    type: AssetActionType.UPDATE_ASSET;
}

export interface IUpdateAssetSuccessAction {
    type: AssetActionType.UPDATE_ASSET_SUCCESS;
    payload: IAssetDto;
}

export interface IUpdateAssetErrorAction {
    type: AssetActionType.UPDATE_ASSET_ERROR;
    payload: string;
}

export interface IGetAssetTypesAction {
    type: AssetActionType.GET_ASSET_TYPES;
}

export interface IGetAssetTypesSuccessAction {
    type: AssetActionType.GET_ASSET_TYPES_SUCCESS
    payload: IAssetType[];
}

export interface IGetAssetTypesErrorAction {
    type: AssetActionType.GET_ASSET_TYPES_ERROR
    payload: string;
}

export interface IUpdateSelectedLevel1IdSuccessAction {
    type: AssetActionType.UPDATE_SELECTED_LEVEL1_ID
    payload: number;
}

export type AssetsAction =
    IGetProductStatusTypes |
    IGetProductStatusTypesSuccess |
    IGetProductStatusTypesError |
    IGetQuantityAndValueConditionTypes |
    IGetQuantityAndValueConditionTypesError |
    IGetQuantityAndValueConditionTypesSuccess |
    IGetServiceScheduleTypes |
    IGetServiceScheduleTypesError |
    IGetServiceScheduleTypesSuccess |
    IClearAssets
    | IDeleteAssetImage
    | IDeleteAssetImageSuccess
    | IDeleteAssetImageError
    | IGetAssetImages
    | IGetAssetImagesSuccess
    | IGetAssetImagesError
    | IClearAssetAction
    | IGetAssetsAction
    | IGetAssetsSuccessAction
    | IGetAssetsErrorAction
    | IGetAssetAction
    | IGetAssetSuccessAction
    | IGetAssetLocalSuccessAction
    | IGetAssetErrorAction
    | IToggleDeleteAssetsAction
    | IDeleteAssetsAction
    | IDeleteAssetsSuccessAction
    | IDeleteAssetsErrorAction
    | IAddAssetAction
    | IAddAssetSuccessAction
    | IAddAssetErrorAction
    | IUpdateAssetAction
    | IUpdateAssetSuccessAction
    | IUpdateAssetErrorAction
    | IUpdateAssetAction
    | IGetAssetTypesAction
    | IGetAssetTypesSuccessAction
    | IGetAssetTypesErrorAction
    | IUpdateSelectedLevel1IdSuccessAction;