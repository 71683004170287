import React, { useEffect, useRef, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Formik } from "formik";
import { ITenancyItem } from "../../../../types/Tenancy";
import { AddTenancy } from "../../../../YupValidationSchemas";
import Buttons from "../../../atoms/Buttons";
import "react-datepicker/dist/react-datepicker.css";
import SelectDropdown, { IOptions } from "../../../atoms/SelectDropdown";
import TenantDetailsSection from "../TenantDetailsSection/TenantDetailsSection.component";
import { ITenancyDetailsConfig } from "../TenancyDetailsPage/_config/config";
import StickyComponent from "../../../atoms/StickyComponent";
import { updateTenancy } from "../../../../services/TenancyService";
import { correctDate } from "../../../../helpers/dateHelpers";
import RotatingLoader from "../../../atoms/RotatingLoader";
import OutstandingChangesModal from '../../../atoms/OutstandingChangesModal'
import lodashObject from 'lodash/fp'
import { ToggleSlider } from "react-toggle-slider";
import * as S from './TenancyRentSchedulePage.styles'
import styles from './TenancyRentSchedulePage.module.scss'
import { useFetchTenancyData } from "../../../../servicesHooks/useFetchTenancyData.hook";
import { IAddTransactionRequest, IAutoGenerateTransactionsRequest } from "../../../../types/Transaction";
import TransactionsSchedule from "../../../molecules/TransactionsSchedule/TransactionsSchedule.component";
import { useFetchTenancyEntityData } from "../../../../servicesHooks/useFetchTenancyEntityData.hook";
import { addTransactions } from "../../../../services/TransactionService";
import { processToast } from "../../../../helpers/toastHelper";

const TenancyRentSchedulePage = () => {
    // Redux 
    const { data : viewing_tenancy_detail, dataRetrieved } = useFetchTenancyData();
    const { rentPayTypes } = useFetchTenancyEntityData();
    
    const history = useHistory();

    const config = useRef<ITenancyDetailsConfig>(require("../TenancyDetailsPage/_config/config.json"));

    const [tenancyItem, setTenancyItem] = useState<ITenancyItem>(null);

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [changesModalOpen, setChangesModalOpen] = useState(false)
    const [rentScheduleIsToggled, setRentScheduleIsToggled] = useState<boolean>(false);

    const initialTenancy = useRef<ITenancyItem>(null);
    var generatedTransactions = useRef<Array<IAddTransactionRequest>>(null);
    const [initialRequest, setInitialRequest] = useState<IAutoGenerateTransactionsRequest>(null);

    useEffect(() => {
        if(viewing_tenancy_detail){
            var correctedTenancy: ITenancyItem = null;
            correctedTenancy = {
                ...viewing_tenancy_detail,
                startDate: new Date(viewing_tenancy_detail.startDate),
                endDate: new Date(viewing_tenancy_detail.endDate),
                rentReviewDate: new Date(viewing_tenancy_detail.rentReviewDate),
                moveInDate: new Date(viewing_tenancy_detail.moveInDate),
                moveOutDate: new Date(viewing_tenancy_detail.moveOutDate)
            }
            
            setTenancyItem(correctedTenancy);
            initialTenancy.current = {...correctedTenancy};

            setInitialRequest(buidTransactionsRequest());
        }
    }, [viewing_tenancy_detail]);

    const buidTransactionsRequest = (): IAutoGenerateTransactionsRequest => {
        return {
            tenancyId: initialTenancy.current.tenancyId,
            markPastAsPaid: true,
            startDate: initialTenancy.current.startDate.toString(),
            endDate: initialTenancy.current.endDate.toString(),
            type: "Income",
            debitContact: initialTenancy.current.contactId,
            debitContactName: initialTenancy.current.tenantName,
            creditContact: "",
            property: initialTenancy.current.propertyId,
            propertyName: initialTenancy.current.propertyName,
            category: "9AB582AB-7558-8FCE-A2DF-75039724924E",
            unit: initialTenancy.current.unitId ? initialTenancy.current.unitId : "",
            unitName: initialTenancy.current.unitId ? initialTenancy.current.unitName : "",
            reference: initialTenancy.current.reference,
            amount: initialTenancy.current.rentAmount,
            //description: "None",
            //notes: "None",
            frequency: initialTenancy.current.frequency,
            dueDay: initialTenancy.current.rentTakenOnDay,
            recurringTransaction: true,
            isRollingContract: initialTenancy.current.rollingContract,
            durationLength: initialTenancy.current.durationLength,
            durationPeriod: initialTenancy.current.durationPeriod
        }
    }

    const saveChanges = (values: any) => {
        setSubmitting(true);
        var updatedTenancy: ITenancyItem = values as ITenancyItem;

        // need to correct any date fields
        updatedTenancy.startDate = correctDate(updatedTenancy.startDate);
        updatedTenancy.endDate = correctDate(updatedTenancy.endDate);
        updatedTenancy.rentReviewDate = correctDate(updatedTenancy.rentReviewDate);
        updatedTenancy.moveInDate = correctDate(updatedTenancy.moveInDate);
        updatedTenancy.moveOutDate = correctDate(updatedTenancy.moveOutDate);
        
        // If there is a Tenancy ID then this is an Update
        if (updatedTenancy.tenancyId) {
            updateTenancy(
                updatedTenancy,
                updatedTenancy.tenancyId
            ).then((resp) => {
                if (resp && resp.status == 200) {
                    setSubmitting(false);
                    if (updatedTenancy && updatedTenancy.propertyId) {
                       setRentScheduleIsToggled(false);
                    }
                }
            }).catch(error => {
                setSubmitting(false);
            })

            saveTransactions();
            return
        }
    }

    const saveTransactions = async() => {
        let resp = await addTransactions(generatedTransactions.current);
        if(resp && resp.data)
        {
            processToast(resp.data);
        }
    }

    const onCancel = (currentValues: any) => {
        if (!lodashObject.isEqual(initialTenancy.current, currentValues)) {
            setChangesModalOpen(true)
            return;
        }

        history.goBack();
    }

    const onCloseChangesModal = (submitCancel: boolean): void => {
        setChangesModalOpen(false);

        if (submitCancel) {
            //goToRoute(getNavigatePath());
            history.goBack();
        } 
    }

    if (submitting) {
        return (
          <div>
            <RotatingLoader text={"Updating tenancy..."} loading={true} />
          </div>
        );
    }

    if (!dataRetrieved) {
        let text = "Loading Tenancy...";
        return (
          <div>
            <RotatingLoader text={text} loading={true} />
          </div>
        );
    }

    return (
        <>
            <OutstandingChangesModal
                open={changesModalOpen}
                onCloseChangesModal={onCloseChangesModal}
            />
            
                <Formik
                    initialValues={{ ...tenancyItem }}
                    enableReinitialize={true}
                    validationSchema={AddTenancy}
                    onSubmit={(values: any) => saveChanges(values)}
                >
                    {(props) => (
                        <>
                            {/* Main fields */}
                            <S.ContainerDiv>
                            {
                                    <S.SectionDiv>
                                        <TenantDetailsSection
                                           config={config.current.rentPayMethod}
                                            //tooltip={hmoTooltip}
                                            selectionControl={
                                                <SelectDropdown
                                                    selectedId={props.values.rentPaymentTypeId}
                                                    data={rentPayTypes}
                                                    onSelect={(id: IOptions) => props.setFieldValue('rentPaymentTypeId', id.value)}
                                                />
                                            }
                                            selectionControlWidth={"200px"}
                                        />
                                    </S.SectionDiv>
                                }
                                {
                                    <S.SectionDiv>
                                        <TenantDetailsSection
                                            config={config.current.dayOfMonthPaid}
                                            //tooltip={hmoTooltip}
                                            selectionControl={
                                                <input
                                                    value={props.values.rentTakenOnDay}
                                                    name="rentTakenOnDay"
                                                    onChange={(e) => props.setFieldValue('rentTakenOnDay', e.currentTarget.value)}
                                                    onBlur={(e) => {
                                                        var value = Number(e.currentTarget.value && e.currentTarget.value != "" ? e.currentTarget.value : 1);
                                                        props.setFieldValue('rentTakenOnDay', value > 28 ? 28 : value)
                                                        setInitialRequest({...initialRequest, dueDay: value});
                                                    }}
                                                    type="number"
                                                    className={styles.input}
                                                />
                                            }
                                            selectionControlWidth={"60px"}
                                        />
                                    </S.SectionDiv>
                                }
                                {
                                    <S.SectionDiv>
                                        <TenantDetailsSection
                                            config={config.current.reference}
                                            //tooltip={hmoTooltip}
                                            selectionControl={
                                                <input
                                                    name="reference"
                                                    value={props.values.reference}
                                                    maxLength={20}
                                                    className={styles.input}
                                                    onChange={(e) => props.setFieldValue('reference', e.currentTarget.value)}
                                                    placeholder="Tenancy Reference"
                                                />
                                            }
                                            selectionControlWidth={"200px"}
                                        />
                                    </S.SectionDiv>
                                }
                                {
                                    <S.SectionDiv>
                                        <TenantDetailsSection
                                            config={config.current.autoGenerate}
                                            //tooltip={hmoTooltip}
                                            selectionControl={
                                                <ToggleSlider
                                                    active={false}
                                                    onToggle={(isToggled) => {
                                                        setRentScheduleIsToggled(isToggled);
                                                        //isToggled ? setDate(rememberDate) : setDate(null);
                                                        //onActiveChanged && onActiveChanged(isToggled);
                                                        //isToggled ? onDateChanged && onDateChanged(rememberDate) : onDateChanged && onDateChanged(null);
                                                    }}
                                                />
                                            }
                                            selectionControlWidth={"60px"}
                                        />
                                    </S.SectionDiv>
                                }
                            </S.ContainerDiv>

                            <div className={styles.sectionSpacer} />


                            {/* Repeat transactions fields */}
                            {
                                rentScheduleIsToggled && 
                                <TransactionsSchedule 
                                    request={initialRequest} 
                                    showInternalSaveButton={false}
                                    onGenerateTransactionsCompleted={(transactions) => generatedTransactions.current = transactions}
                                />
                            }

                            <div className={styles.sectionSpacer} />

                            {/* buttons */}
                            <div className={styles.buttons}>
                                <StickyComponent>
                                    <div className={styles.stickyComponentContentWrapper}>
                                        <Buttons
                                            buttons={[
                                                {
                                                    text: 'Cancel',
                                                    displayType: 'cancel',
                                                    elementType: 'button',
                                                    onClick: () => onCancel(props.values),
                                                },
                                                {
                                                    text: 'Save',
                                                    displayType: 'submit',
                                                    elementType: 'button',
                                                    onClick: () => props.submitForm(),
                                                },
                                            ]}
                                        />
                                    </div>
                                </StickyComponent>
                            </div>
                        </>
                    )}
                </Formik>
        </>
    )
}

export default TenancyRentSchedulePage;