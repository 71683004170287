import { apiService } from "./ApiService";
import { createBearerToken } from "../helpers/authHelpers";
import * as domains from "../configuration/domains";

export const getCouncilList = (): Promise<any> => {
  return apiService
    .get(`${domains.councilService}/all`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error;
    });
};
