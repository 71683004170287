import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../../reducers/rootReducer';
import { onAddTenancyClick } from '../../../../atoms/SFDropdown/SFPropertyDropdown/_helpers';
import TenancySummaryInfo from '../../../../atoms/TenancySummaryInfo'
import { ISection } from '../../PropertyDashboard/_config/dashboardConfig';
import SectionHeader from "../SectionHeader/SectionHeader.component"
import * as S from "./ActiveTenancySectionSection.styles";

interface sectionProps {
    id: string;
    header: string;
    hideOnClick(id: string): void;
    config: ISection;
}

const ActiveTenancySection = ({id, header, hideOnClick, config}: sectionProps) => {
    // Redux 
    const { propertyId, propertyName } = useSelector((state: RootState) => state.property.property_data.property_data);

    let history = useHistory();

    return (
        <div>
            <SectionHeader id={id} header={header} hideOnClick={hideOnClick} titleOnClick={() => history.push(`/dashboard/property/${propertyId}/tenancy`)} addOnClick={() => onAddTenancyClick(propertyId, propertyName, location.search)}/>
            <S.SectionDiv>
                <TenancySummaryInfo
                    onAdd={() => history.push(`/dashboard/tenancy/add`)}
                    propertyId={propertyId}
                />
            </S.SectionDiv>
        </div>
    )
}

export default ActiveTenancySection;