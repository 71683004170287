import React, { ButtonHTMLAttributes } from 'react'

import plusIcon from '../../../images/icons/ic_add_24px.png'
import styles from './GenericButton.module.scss'

interface IProps {
    text?: string
    small?: boolean
    color?: string
    floatRight?: boolean
    isCancelButton?: boolean
    showIcon?: boolean
    submitButton?: boolean
    disabled?: boolean
    onClick?(): void
    preventDefault?: boolean
    border?: boolean
    className?: string
    children?: any
}

class GenericButton extends React.Component<IProps> {
    constructor(props: any) {
        super(props)

        this.onClickInternal = this.onClickInternal.bind(this)
    }

    onClickInternal(e: any): void {
        if (this.props.preventDefault) {
            e.preventDefault()
        }

        if (this.props.onClick) {
            this.props.onClick()
        }
    }

    render() {
        return (
            <button
                type={this.props.submitButton ? 'submit' : 'button'}
                className={`
        ${this.props.className ? this.props.className : ''}
          ${
              this.props.disabled
                  ? styles.disabled
                  : this.props.small
                  ? styles.addPropertySmall
                  : this.props.floatRight
                  ? styles.addPropertyFloatRight
                  : this.props.border
                  ? styles.addPropertyWithBorder
                  : styles.addProperty
          }
        `}
                onClick={(e) => this.onClickInternal(e)}
                style={{
                    backgroundColor: this.props.color ? this.props.color : '',
                }}
            >
                {this.props.text && this.props.text != '' && (
                    <span className={styles.addPropertyText}>
                        {this.props.text}
                    </span>
                )}

                {this.props.children && this.props.children}
            </button>
        )
    }
}

export default GenericButton
