import { apiService } from "../services/ApiService";
import { createBearerToken } from "../helpers/authHelpers";
import {
  ICalculatorAddPropertyRequest,
  IPropertyInformation,
  IUpdatePropertyRequest,
  IUploadPropertyImage,
  IEditPropertyRequest,
  IAddPropertyFromStepsRequest,
  IGetPriceValuationRequest, UpdatePropertyConfigurationRequest
} from "../types/PropertyService/IPropertyService";
import * as domains from "../configuration/domains";
import { IDeleteRequest } from "../components/atoms/DeletePropertyModal";
import { toQueryString } from "./DocumentService";
import { IListItemOptions } from "../types/ListFilters";

export const updatePropertyConfiguration = (request: UpdatePropertyConfigurationRequest): Promise<any> => {
  return apiService
    .post(`${domains.propertyService}/update-configuration`, request, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const getReturnsPanel = (propertyId: any): Promise<any> => {
  return apiService
    .get(`${domains.propertyService}/returns-panel/${propertyId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const getMortgagePanel = (propertyId: any): Promise<any> => {
  return apiService
    .get(`${domains.propertyService}/mortgage-panel/${propertyId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const updatePropertyStatus = (propertyId: any, isProspect: boolean): Promise<any> => {
  return apiService
    .get(`${domains.propertyService}/update-property-status/${propertyId}/${isProspect}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const getCalculateReturnsData = (propertyId: any): Promise<any> => {
  return apiService
    .get(`${domains.propertyService}/calculate-returns/${propertyId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const getOtherPropertiesInformation = (): Promise<any> => {
  return apiService
    .get(
      `${domains.propertyService}/properties-list?field=formattedAddress&field=propertyId&field=propertyMetadata`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: createBearerToken()
        }
      }
    )
    .catch(error => {
      return error.response.data;
    });
};

export const getPropertiesDropdown = (search?: string): Promise<any> => {
  let searchParam = search ? `?search=${search}` : ``;

  return apiService.get(`${domains.propertyService}/dropdown${searchParam}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export const getPropertyDropdownForPropertyId = (
  propertyId: string
): Promise<any> => {
  return apiService.get(`${domains.propertyService}/dropdown/${propertyId}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export const getPropertyForEdit = (propertyId: any): Promise<any> => {
  return apiService
    .get(`${domains.propertyService}/get-property-edit/${propertyId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const addProperty = (
  request: ICalculatorAddPropertyRequest
): Promise<any> => {
  return apiService
    .post(`${domains.propertyService}/add`, request, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const addPropertyFromSteps = (
  request: IAddPropertyFromStepsRequest
): Promise<any> => {
  return apiService
    .post(`${domains.propertyService}/addFromSteps`, request, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const editProperty = (request: IEditPropertyRequest): Promise<any> => {
  return apiService
    .put(`${domains.propertyService}/edit`, request, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const deleteProperty = (propertyId: string, request: IDeleteRequest): Promise<any> => {
  return apiService
    .post(`${domains.propertyService}/delete/${propertyId}`, request, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const getPropertyInformation = (propertyId: any): Promise<any> => {
  return apiService
    .get(`${domains.propertyService}/${propertyId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const updatePropertyValues = (
  body: IUpdatePropertyRequest
): Promise<any> => {
  return apiService
    .put(`${domains.propertyService}/update-property`, body, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const getActivePropertyStatus = (): Promise<any> => {
  return apiService
    .get(`${domains.propertyService}/active-propertystatus`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const getPrimaryPropertyDetails = (propertyId: string): Promise<any> => {
  return apiService.get(
    `${domains.propertyService}/primary-details/${propertyId}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    }
  );
};

export const uploadPropertyImage = (
  request: IUploadPropertyImage
): Promise<any> => {
  return apiService.post(`${domains.propertyService}/image`, request, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export const getEstimatedSaleValue = (
  request: IGetPriceValuationRequest,
): Promise<any> => {
  let stringed = toQueryString(request);
  return apiService.get(`${domains.propertyDetailService}/price-valuation?${stringed}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export const getPriceValuationHistory = (
  options: IListItemOptions
): Promise<any> => {
  let stringed = toQueryString(options);
  return apiService.get(`${domains.propertyDetailService}/price-valuation/history/?${stringed}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export const getRentValuationHistory = (
  options: IListItemOptions
): Promise<any> => {
  let stringed = toQueryString(options);
  return apiService.get(`${domains.propertyDetailService}/rent-valuation/history/?${stringed}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export const deleteValuationApi = (valuationId: string): Promise<any> => {
  return apiService
    .post(`${domains.propertyDetailService}/valuation/${valuationId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};
