import styled from 'styled-components';

export const SectionDiv = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 32% 32% 32%; 
    grid-template-areas:
        'contact rent tenancyLeft';
`; 

export const ContactDiv = styled.div`
    grid-area: contact;
`;

export const RentDiv = styled.div`
    grid-area: rent;
`;

export const TenancyLeftlDiv = styled.div`
    grid-area: tenancyLeft;
`;
