import React, { Component } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import styles from './ChangePasswordForm.module.scss'
import { changePassword } from '../../../services/CredentialsService'
import { IChangePasswordRequest } from '../../../types/CredentialsService'
import RotatingLoader from '../../atoms/RotatingLoader'
import { Formik, Form, ErrorMessage } from 'formik'
import { ChangePasswordSchema } from '../../../YupValidationSchemas'
import ChangePasswordFormSuccess from './ChangePasswordFormSuccess'

interface IProps {
    onSuccessHandler(): void
}

interface IRouterProps {
    history: any
    location: any
    match: any
}

interface IState {
    userId: string
    token: string
    newPassword: string
    confirmPassword: string
    isLoading: boolean
    isSuccess: boolean
    response: IChangePasswordResponse
}

interface IChangePasswordResponse {
    isChanged: boolean
    message: string
}

type Props = IRouterProps & IProps

class ChangePasswordForm extends Component<Props, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            userId: '',
            token: '',
            newPassword: '',
            confirmPassword: '',
            isLoading: false,
            response: null,
            isSuccess: false,
        }
    }

    goToLoginRoute(): void {
        this.props.history.push(`/account/login`)
    }

    componentDidMount = (): void => {
        let token = this.props.match.params.token
        let userId = this.props.match.params.userId

        if (!token || !userId) {
            return
        }

        this.setState({
            userId,
            token,
        })
    }

    handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        const field = event.currentTarget.name
        const value: string = event.currentTarget.value
        this.setState({ [field]: value } as Pick<IState, any>)
    }

    onSubmit = async (newPassword: string): Promise<void> => {
        this.setState({
            response: null,
            isLoading: true,
        })

        let request: IChangePasswordRequest = {
            newPassword: newPassword,
            userId: this.state.userId,
            token: this.state.token,
        }

        const { data, status } = await changePassword(request)
        if (status === 200) {
            let responseData: IChangePasswordResponse = data
            this.setState({
                response: data,
            })

            if (responseData.isChanged) {
                this.props.history.push('/account/password/success')
            }
        }

        this.setState({
            isLoading: false,
        })
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div>
                    <RotatingLoader loading={true} text="Loading..." />
                </div>
            )
        }

        return (
            <>
                <Switch>
                    <Route
                        path={`${this.props.match.path}/success`}
                        component={ChangePasswordFormSuccess}
                    />

                    <Route path={`${this.props.match.url}/:userId?/:token?`}>
                        <h1>Change Password</h1>

                        {!this.state.token || !this.state.userId ? (
                            <p>Invalid password change request.</p>
                        ) : (
                            <>
                                <h2>
                                    You will recieve an email to confirm your
                                    password has been changed.
                                </h2>
                                <Formik
                                    initialValues={{
                                        password: null,
                                        newPassword: null,
                                    }}
                                    validationSchema={ChangePasswordSchema}
                                    onSubmit={(values: {
                                        password: string
                                        newPassword: string
                                    }) => this.onSubmit(values.password)}
                                >
                                    {(props) => (
                                        <Form className={styles.form}>
                                            <div>
                                                <div className={styles.group}>
                                                    <label
                                                        className={styles.label}
                                                    >
                                                        New Password
                                                    </label>

                                                    <input
                                                        className={styles.input}
                                                        placeholder="New Password"
                                                        type="password"
                                                        required={true}
                                                        name="newPassword"
                                                        onChange={(e) =>
                                                            props.setFieldValue(
                                                                'password',
                                                                e.currentTarget
                                                                    .value
                                                            )
                                                        }
                                                    />

                                                    <div
                                                        className={
                                                            styles.errorMessage
                                                        }
                                                    >
                                                        <ErrorMessage
                                                            className={
                                                                styles.errorMessage
                                                            }
                                                            name="password"
                                                        />
                                                    </div>
                                                </div>

                                                <div className={styles.group}>
                                                    <label
                                                        className={styles.label}
                                                    >
                                                        Confirm New Password
                                                    </label>

                                                    <input
                                                        className={styles.input}
                                                        value={
                                                            props.values
                                                                .newPassword
                                                        }
                                                        placeholder="Confirm New Password"
                                                        type="password"
                                                        required={true}
                                                        name="newPassword"
                                                        onChange={(e) =>
                                                            props.setFieldValue(
                                                                'newPassword',
                                                                e.currentTarget
                                                                    .value
                                                            )
                                                        }
                                                    />

                                                    <div
                                                        className={
                                                            styles.errorMessage
                                                        }
                                                    >
                                                        <ErrorMessage
                                                            className={
                                                                styles.errorMessage
                                                            }
                                                            name="newPassword"
                                                        />
                                                    </div>
                                                </div>

                                                <div
                                                    className={
                                                        styles.buttonContainer
                                                    }
                                                >
                                                    <button
                                                        className={
                                                            styles.button
                                                        }
                                                        onClick={
                                                            props.submitForm
                                                        }
                                                    >
                                                        Change Password
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </>
                        )}
                    </Route>
                </Switch>
                <div className={styles.page}>
                    <p
                        className={styles.links}
                        onClick={() =>
                            this.props.history.push('/account/login')
                        }
                    >
                        Back to login
                    </p>
                </div>
            </>
        )
    }
}

export default withRouter(ChangePasswordForm)
