import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import 'react-chat-elements/dist/main.css'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import 'react-tippy/dist/tippy.css'
import { getFileTypeLogo } from '../../../helpers/documentHelpers'
import chatAttach from '../../../images/icons/chat_attach.svg'
import { RootState } from '../../../reducers/rootReducer'
import AddDocumentModal from '../../molecules/AddDocumentModal'
import styles from './RCTextareaAutosize.module.scss'
const isImage = require('is-image')

interface IProps extends RouteComponentProps {
    location: any
    history: any
    match: any
    
    handleChange?(e: any): void
    handleDocumentAdded?(document: File): void
    onSubmit?(value: string, file: File): void
    placeholder?: string
    sending?: boolean
    chat_uploading_documents: File[]
    maxRows?: number
    maxLength?: number
}

interface IState {
    msg: string
    file: File
    uploadingFile: boolean
    showDocumentModal: boolean
}

class RCTextareaAutosize extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props)

        this.state = {
            msg: '',
            file: null,
            uploadingFile: false,
            showDocumentModal: false
        }
    }

    onAddDocumentClick = (): void => {
        this.setState({showDocumentModal: true})
    }

    onCloseDocModal = (images: any[]) => {
        if(images && images.length > 0){
            this.setState({
                file: images[0],
            })

            if (this.props.handleDocumentAdded) {
                this.props.handleDocumentAdded(
                    images[0]
                )
            }
        }

        this.setState({
            showDocumentModal: false
        })
    }

    handleChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
        const value: string = event.currentTarget.value
        if (value !== '\n') { // we are using the enter key to also send so ignore
            this.setState({ msg: value } as Pick<IState, any>)

            if (this.props.handleChange) {
                this.props.handleChange(event)
            }
        }
    }

/*     handleEnterPress = (e: any): void => {
        if (e.key === 'Enter') {
            this.onSubmit()
        }
    } */

    onSubmit = (): void => {
        if (this.props.onSubmit) {
            this.props.onSubmit(this.state.msg, this.state.file)
            this.setState({
                msg: '',
                file: null,
            })
        }
    }

    displayFile = (file: File): JSX.Element => {
        if (isImage(file.name)) {
            return <img src={URL.createObjectURL(file)} />
        }

        return getFileTypeLogo(file.name)
    }

    render() {
        return (
            <div>
                {
                this.state.showDocumentModal && 
                    <AddDocumentModal 
                        openManual={true} 
                        onCloseModalWithDocs={this.onCloseDocModal} 
                    />
                }
                {this.state.file && (
                    <div className={styles.files}>
                        <p className={styles.label}>Upload File</p>
                        {this.displayFile(this.state.file)}
                    </div>
                )}

                <div className={styles.container}>
                    <div className={styles.innerContainer}>
                        <div className={styles.actions}>
                            <div
                                className={styles.attach}
                                onClick={() => this.onAddDocumentClick()}
                            >
                                <img src={chatAttach} />
                            </div>
                        </div>

                        <TextareaAutosize
                            //onKeyDown={this.handleEnterPress}
                            value={this.state.msg}
                            onChange={this.handleChange}
                            maxRows={this.props.maxRows ? this.props.maxRows : 1}
                            maxLength={this.props.maxLength ? this.props.maxLength : 64}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(RCTextareaAutosize)
