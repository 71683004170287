import styled from 'styled-components';

export const MainDiv = styled.div`
    display: block;
    align-items: center;
    &:hover {
        cursor: pointer;
      }
`; 

export const SquareDiv = styled.div`
    //display: block;
    margin: auto;
    height: 60px;
    width: 60px;
    background-color: white;
    display: flex;
    align-items: center;
`; 

export const LabelText = styled.label`
    font-size: 12px;
    color: #004150;
    line-height: 0;
`; 

export const IconImage = styled.img`
    height: 30px;
    width: 30px;
    margin: auto;
    width: 50%;
`; 
