import styled from "styled-components";

export const ResearchAreaDiv = styled.div`
    display: grid;
    grid-gap: 1px;
    grid-template-columns: 60% 30%;
    grid-template-areas:
    'postcodeInput submitButton';
`;

export const InputDiv = styled.div`
    grid-area: postcodeInput;
    //width: 50%; 
    padding-right: 10px;
    padding-top: 0px;
    text-align: left;
    input {
        text-transform: uppercase;
        //margin-top: 0.5rem;
        //width: 70%;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 32px;
        box-sizing: border-box;
        //margin-top: 6px;
        //resize: vertical;
        text-align: left;
        font-weight: normal;
        height: 38px;
        font-size: 1rem;
    }
`;

export const ButtonDiv = styled.div`
    grid-area: submitButton;
    //width: 30%; 
    height: 35px;
`;

export const Error = styled.div`
    text-align: left;
    font-size: 0.8rem;
    color: red;
`;

export const Label = styled.label`
    line-height: 0;
    font-size: 12px; 
    font-weight: bold;
`;