import React, { Component } from 'react'
import { Redirect, Route, withRouter } from 'react-router-dom'
import Col from 'reactstrap/lib/Col'
import { addURLParameter, IAddTenancyOptions } from '../../../../helpers/locationHelpers'
import {
    toggleAddDocumentPopup,
    toggleTenancyActionSidebar,
} from '../../../../helpers/sidebarHelpers'
import { getPrimaryTenancyInformation } from '../../../../services/TenancyService'
import { ITenancyItem } from '../../../../types/Tenancy'
import SimpleRouterTabs from '../../../atoms/SimpleRouterTabs'
import TabbedNavigationContainer from '../../../atoms/TabbedNavigationContainer'
import TenancyCardNav from '../../../atoms/TenancyCardNav'
import { ITenancyAction } from '../../../atoms/TenancyListItem'
import { IDocumentUploadOptions } from '../../../molecules/AddDocumentModal'
import TenancyDetailsPage from '../TenancyDetailsPage/TenancyDetailsPage.component'
import TenantRentSchedulePage from '../TenancyRentSchedulePage/TenancyRentSchedulePage.component'
import DocumentsPage from '../../DocumentsPage'
import TenancyAuditPage from '../TenancyAuditPage'
import styles from './TenancyContainerPage.module.scss'
import TransactionsGridContainer from '../../FinancialsPage/TransactionsGridContainer/TransactionsGridContainer.component'
const queryString = require("query-string");

interface IProps {
    history: any
    match: any
    location: any

    contactId?: string
    propertyId?: string

    tenancy: ITenancyItem
}

interface IState {
    tenancyId: string
    tenancyName: string
    options: ITenancyAction

    tenantForename: string
    tenantSurname: string
    tenantPrimaryImageUri: string

    propertyId: string
    propertyName: string
    contactId: string

    unitName: string
    unitId: string

    tenancyItem: ITenancyItem

    isAddingTenant: boolean

    loading: boolean;
    tenancyIdChecked: boolean;

    cloneTenancyId: string;
}

class TenancyContainerPage extends Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            tenancyId: null,
            tenancyName: null,
            options: null,
            tenantForename: '',
            tenantSurname: '',
            tenantPrimaryImageUri: '',
            propertyId: '',
            propertyName: '',
            contactId: '',
            unitId: '',
            unitName: '',
            tenancyItem: null,
            isAddingTenant: false,
            loading: false,
            tenancyIdChecked: false,
            cloneTenancyId: null
        }
    }

    onClickAddDocument = () => {
        let options: IDocumentUploadOptions = {
            tenancyId: this.state.tenancyId,
        }

        const {
            propertyId,
            propertyName,
            contactId,
            tenantForename,
            tenantSurname,
            unitId,
            unitName,
        } = this.state
        if (propertyId && propertyName) {
            options.propertyId = propertyId
            options.propertyName = propertyName
        }

        if (contactId && tenantForename && tenantSurname) {
            options.contactId = contactId
            options.contactName = `${tenantForename} ${tenantSurname}`
        }

        if (unitId && unitName) {
            options.unitId = unitId
            options.unitName = unitName
        }

        let query = 'uploadDocumentOptions=' + JSON.stringify(options)

        let search = addURLParameter(this.props.location.search, query)

        this.props.history.push({
            search,
        })

        toggleAddDocumentPopup(true)
    }

    async componentDidMount(): Promise<void> {
        let tenancyId = this.props.match.params.tenancyId
        this.setState({
            tenancyId: tenancyId,
            tenancyIdChecked: true
        })

        if (!tenancyId) {
            if(this.props.match.path = '/dashboard/tenancy/add'){
                let options = queryString.parse(this.props.location.search).addTenancyOptions;
                if(options){
                    let obj: IAddTenancyOptions = JSON.parse(options);
                    if(obj){
                        this.setState({
                            propertyId: obj.propertyId,
                            propertyName: obj.propertyName,
                            unitId: obj.unitId,
                            unitName: obj.unitName,
                            contactId: obj.contactId,
                            isAddingTenant: true,
                            cloneTenancyId: obj.cloneTenancyId
                        })
                    }

                    if(obj.cloneTenancyId){
                        const { data } = await getPrimaryTenancyInformation(obj.cloneTenancyId)
            
                        if (data) {
                            this.setState({
                                tenancyName: data.tenacyName,
                                tenantForename: data.tenantForename,
                                tenantSurname: data.tenantSurname,
                                tenantPrimaryImageUri: data.tenantPrimaryImageUri,
                                propertyId: data.propertyId,
                                propertyName: data.propertyName,
                                contactId: data.contactId,
                                unitId: data.unitId,
                                unitName: data.unitName,
                                tenancyItem: data
                            })
                        }
                    }
                }
            }
        }else{
            this.setState({
                loading: true
            })
            const { data } = await getPrimaryTenancyInformation(tenancyId)

            if (data) {
                this.setState({
                    loading: false,
                    tenancyId: data.tenancyId,
                    tenancyName: data.tenacyName,
                    tenantForename: data.tenantForename,
                    tenantSurname: data.tenantSurname,
                    tenantPrimaryImageUri: data.tenantPrimaryImageUri,
                    propertyId: data.propertyId,
                    propertyName: data.propertyName,
                    contactId: data.contactId,
                    unitId: data.unitId,
                    unitName: data.unitName,
                    tenancyItem: data
                })
            }
        }
    }

    onActionMenuClick = () => {
        let options: ITenancyAction = {
            tenancyId: this.props.match.params.tenancyId,
            contactId: this.state.contactId,
            contactName: `${this.state.tenantForename} ${this.state.tenantSurname}`,
            propertyId: this.state.propertyId,
            propertyName: this.state.propertyName,
        }

        let query = 'tenancyActionOptions=' + JSON.stringify(options)

        this.props.history.push({
            search: query,
        })

        toggleTenancyActionSidebar(true)
    }

    render() {
/*         if(this.state.loading || !this.state.tenancyIdChecked){
            return (
                <div>
                    <RotatingLoader text={"Loading Tenancy..."} loading={true} />
                </div>
            )
        } */

        if (!this.state.tenancyId && !this.state.cloneTenancyId) {
            return (
                <div className={styles.container}>
                    <div className={styles.propertyCard}>
                        <TenancyCardNav
                            propertyId={this.state.propertyId}
                            propertyName={this.state.propertyName}
                            unitId={this.state.unitId}
                            unitName={this.state.unitName}
                        />
                        <div className={styles.page}>
                            <TenancyDetailsPage />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.cloneTenancyId) {
            return (
                <div className={styles.container}>
                    <div className={styles.propertyCard}>
                        <TenancyCardNav
                            propertyId={this.state.propertyId}
                            propertyName={this.state.propertyName}
                            unitId={this.state.unitId}
                            unitName={this.state.unitName}
                        />
                        <div className={styles.page}>
                            <TenancyDetailsPage />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className={styles.container}>
                <div className={styles.propertyCard}>
                    <TenancyCardNav
                        propertyId={this.state.propertyId}
                        propertyName={this.state.propertyName}
                        contactId={this.state.contactId}
                        tenancyId={this.state.tenancyId}
                        tenantName={
                            this.state.tenantForename +
                            ' ' +
                            this.state.tenantSurname
                        }
                        unitId={this.state.unitId}
                        unitName={this.state.unitName}
                    />
                </div>

                <div className={styles.tabbedNav}>
                    <TabbedNavigationContainer>
                        <Col>
                            <SimpleRouterTabs
                                routes={[
                                    {
                                        path: this.props.match.url + '/details',
                                        text: 'Summary',
                                        mobileText: 'Summary',
                                    },
                                    {
                                        path: this.props.match.url + '/rentschedule',
                                        text: 'Rent Schedule',
                                        mobileText: 'Rent Schedule',
                                    },
                                    {
                                        path: this.props.match.url + '/money',
                                        text: 'Money',
                                        mobileText: 'Money',
                                    },
                                    {
                                        path:
                                            this.props.match.url + '/documents',
                                        text: 'Docs',
                                        mobileText: 'Docs',
                                    },
                                    {
                                        path:
                                            this.props.match.url + '/timeline',
                                        text: 'Timeline',
                                        mobileText: 'Timeline',
                                    },
                                ]}
                            />
                        </Col>
                    </TabbedNavigationContainer>
                </div>

                <div className={styles.page}>
                    <div>
                        <Route
                            path={`${this.props.match.url}`}
                            exact
                            render={() => (
                                <Redirect
                                    to={`${this.props.match.url}/details`}
                                />
                            )}
                        />

                        <Route
                            path={`${this.props.match.path}/details`}
                            render={(props) => <TenancyDetailsPage />}
                        />
                        
                        <Route
                            path={`${this.props.match.path}/rentschedule`}
                            render={(props) => <TenantRentSchedulePage />}
                        />

                        <Route
                            path={`${this.props.match.path}/money`}
                            render={(props) => <TransactionsGridContainer context="tenancy" />}
                        />

                        <Route
                            path={`${this.props.match.path}/documents`}
                            render={(props) => (
                                <DocumentsPage
                                    onAddDocumentClick={this.onClickAddDocument}
                                    tenancyId={
                                        this.props.match.params.tenancyId
                                    }
                                />
                            )}
                        />

                        <Route
                            path={`${this.props.match.path}/timeline`}
                            render={(props) => <TenancyAuditPage />}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(TenancyContainerPage)
