import React from 'react'
import { connect } from 'react-redux'
import { IClientData } from '../../../types/ClientService'
import ReactHtmlParser from 'react-html-parser'
import styles from './ClientRegistrationFooter.module.scss'

interface IProps {
    client: IClientData
    className?: string
}

class ClientRegistrationFooter extends React.Component<IProps> {
    constructor(props: any) {
        super(props)
    }

    render() {
        if (
            this.props.client &&
            this.props.client.htmlRegistrationScreenFooter
        ) {
            return (
                <div className={styles.footer}>
                    {ReactHtmlParser(
                        this.props.client.htmlRegistrationScreenFooter
                    )}
                </div>
            )
        }

        return null
    }
}

const mapStateToProps = (state: any) => ({
    client: state.client,
})

export default connect(mapStateToProps)(ClientRegistrationFooter) as any
