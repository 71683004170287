import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router'
import { Col } from 'reactstrap'
import { propertyCardStatusProspect } from '../../../../configuration/appConfig'
import { hasClientArea } from '../../../../helpers/clientHelpers'
import { delayAction } from '../../../../helpers/timerHelpers'
import { goToRoute } from '../../../../history'
import { ICalculateReturnsData } from '../../../../reducers/propertyReducer'
import { saveStreetViewStaticImage } from '../../../../services/GoogleService'
import { IPropertyDetail } from '../../../../types/PropertyDetailService'
import { IAddress } from '../../../../types/PropertyService/IPropertyService'
import { IPropertyConfigurationRequest } from '../../../../types/UnitService'
import ClientAreaRestriction from '../../../atoms/ClientAreaRestriction'
import EditStreetViewModal from '../../../atoms/EditStreetViewModal'
import IFrameContainer from '../../../atoms/IFrameContainer'
import NestedSimpleRouterTabs from '../../../atoms/NestedSimpleRouterTabs'
import TabbedNavigationContainer from '../../../atoms/TabbedNavigationContainer'
import UploadPropertyImage from '../../../atoms/UploadPropertyImage'
import AssetsGrid from '../../AssetsPage/AssetsGrid'
import InspectionsGrid from '../../InspectionsPage/InspectionsGrid'
import KeyDates from '../../KeyDates/KeyDates.component'
import UnitGrid from '../../UnitPage/UnitGrid'
import { UnitDefaultTemplate } from '../../UnitPage/UnitGrid/_templates/unitDefaultTemplate'
import PropertyOwnershipPage from '../PropertyOwnershipPage'
import PropertyCompositionPage from './PropertyCompositionPage'
import styles from './PropertyDetailPage.module.scss'

interface IProps {
    match: any
    history: any
    location: any
    isMapAvailable: any
    isKeyDatesBeta: Boolean;
}

interface IReduxProps {
    data: ICalculateReturnsData
}

interface IState {
    address: IAddress
    propertyData: IPropertyDetail
    moreInformation: boolean
    uploadImageModalOpen: boolean
    propertyImageUploadSuccess: boolean
    newImage: string
    isEditingStreetView: boolean
}

export interface IUnitTemplateOptions {
    updateRedirectRoute: string
}

type Props = IProps & IReduxProps

class PropertyDetailPage extends Component<Props, IState> {
    constructor(prop: any) {
        super(prop)

        this.state = {
            address: this.props.data.address,
            propertyData: null,
            moreInformation: false,
            uploadImageModalOpen: false,
            propertyImageUploadSuccess: false,
            newImage: '',
            isEditingStreetView: false,
        }

        this.toggleMoreInformation = this.toggleMoreInformation.bind(this)
        this.firstLoadCallback = this.firstLoadCallback.bind(this)
        this.onPropertyImageUploadSuccess =
            this.onPropertyImageUploadSuccess.bind(this)
        this.onUploadCallback = this.onUploadCallback.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    firstLoadCallback(lat: number, lng: number, heading: number): void {
        saveStreetViewStaticImage({
            lat: lat,
            lng: lng,
            heading: heading,
            propertyId: this.props.data.propertyId,
        })
    }

    toggleMoreInformation(show: boolean): void {
        this.setState({
            moreInformation: show,
        })
    }

    onPropertyImageUploadSuccess(img: string): void {
        this.setState({
            newImage: img,
            uploadImageModalOpen: false,
            propertyImageUploadSuccess: true,
        })

        delayAction(2500, () =>
            this.setState({ propertyImageUploadSuccess: false })
        )
    }

    onUploadCallback(uri: string): void {
        if (uri) {
            this.setState({
                newImage: uri,
                uploadImageModalOpen: false,
            })

            if (this.state.isEditingStreetView) {
                this.setState({
                    isEditingStreetView: false,
                })
            }
        }
    }

    render() {
        return (
            <div>
                <UploadPropertyImage
                    onSuccess={this.onPropertyImageUploadSuccess}
                    propertyId={this.props.data.propertyId}
                    open={this.state.uploadImageModalOpen}
                    onClose={() =>
                        this.setState({ uploadImageModalOpen: false })
                    }
                    onUploadCallback={this.onUploadCallback}
                />

                <EditStreetViewModal
                    onUploadSuccessCallback={this.onUploadCallback}
                    open={this.state.isEditingStreetView}
                    onCloseCallback={() =>
                        this.setState({ isEditingStreetView: false })
                    }
                    propertyId={this.props.data.propertyId}
                    address={this.props.data.address}
                />

                <div>
                    <TabbedNavigationContainer fullWidth isSubMenu noPadding>
                        <Col>
                            <NestedSimpleRouterTabs
                                routes={[
                                    {
                                        path:
                                            this.props.match.url +
                                            '/composition',
                                        text: 'Details',
                                    },
                                    {
                                        path:
                                            this.props.match.url + '/owners',
                                        text: 'Owners',
                                        hide:
                                            this.props.data.propertyStatusId ==
                                            propertyCardStatusProspect,
                                    },
                                    ...(this.props.isKeyDatesBeta
                                        ? [
                                              {
                                                path: this.props.match.url + '/regulation',
                                                text: 'Regulation',
                                              },
                                          ]
                                        : []),
                                    {
                                        path: this.props.match.url + '/units',
                                        text: 'Units',
                                        hide:
                                            this.props.data.propertyStatusId ==
                                            propertyCardStatusProspect,
                                    },
                                    ...(hasClientArea('AssetsFunctions')
                                        ? [
                                              {
                                                  path:
                                                      this.props.match.url +
                                                      '/assets',
                                                  text: 'Assets',
                                                  hide:
                                                      this.props.data
                                                          .propertyStatusId ==
                                                      propertyCardStatusProspect,
                                              },
                                          ]
                                        : []),

                                    ...(hasClientArea('InspectionsFunctions')
                                        ? [
                                              {
                                                  path:
                                                      this.props.match.url +
                                                      '/inspections',
                                                  text: 'Inspections',
                                                  hide:
                                                      this.props.data
                                                          .propertyStatusId ==
                                                      propertyCardStatusProspect,
                                              },
                                          ]
                                        : []),
                                ]}
                            />
                        </Col>
                    </TabbedNavigationContainer>
                </div>

                <div className={styles.container}>
                    <div className={styles.routedPage}>
                        <div className={styles.content}>
                            <Route
                                path={`${this.props.match.url}`}
                                exact
                                render={() => (
                                    <Redirect
                                        to={`${this.props.match.url}/composition`}
                                    />
                                )}
                            />

                            <Route
                                path={`${this.props.match.path}/composition`}
                                component={PropertyCompositionPage}
                            />

                            <Route
                                path={`${this.props.match.path}/owners`}
                                component={PropertyOwnershipPage}
                            />

                            <Route
                                path={`${this.props.match.path}/regulation`}
                                component={KeyDates}
                            />

                            <ClientAreaRestriction restriction="InspectionsFunctions">
                                <Route
                                    path={`${this.props.match.path}/inspections`}
                                    exact
                                    render={() => (
                                        <div className={styles.nestedPage}>
                                            <InspectionsGrid
                                                config={{
                                                    dataFilterSettings: {
                                                        propertyId:
                                                            this.props.match
                                                                .params
                                                                .propertyId,
                                                    },
                                                    addSettings: {
                                                        propertyId:
                                                            this.props.match
                                                                .params
                                                                .propertyId,
                                                        propertyName:
                                                            this.props.data
                                                                .propertyName,
                                                        returnUrl: `/dashboard/property/${this.props.match.params.propertyId}/spec/inspections`,
                                                    },
                                                }}
                                            />
                                        </div>
                                    )}
                                />
                            </ClientAreaRestriction>

                            <Route
                                path={`${this.props.match.path}/devices`}
                                render={() => (
                                    <div className={styles.nestedPage}>
                                        <IFrameContainer
                                            scrolling={false}
                                            url="https://www.rentchief.co.uk/devices"
                                        />
                                    </div>
                                )}
                            />

                            <Route
                                path={`${this.props.match.path}/units`}
                                render={() => (
                                    <div className={styles.nestedPage}>
                                        <UnitGrid
                                            defaultAvailableToRent={false}
                                            onAdd={() =>
                                                this.props.history.push(
                                                    `/dashboard/unit/add?propertyId=${this.props.data.propertyId}&propertyName=${this.props.data.propertyName}&updateRedirectUrl=${this.props.location.pathname}`
                                                )
                                            }
                                            onMultiAdd={() => {
                                                let query: IPropertyConfigurationRequest =
                                                    {
                                                        propertyId:
                                                            this.props.data
                                                                .propertyId,
                                                        propertyName:
                                                            this.props.data
                                                                .propertyName,
                                                        propertyType:
                                                            this.props.data
                                                                .propertyDetails
                                                                .propertyType,
                                                        residentialType:
                                                            this.props.data
                                                                .propertyDetails
                                                                .residentialType,
                                                        commercialType:
                                                            this.props.data
                                                                .propertyDetails
                                                                .commercialType,
                                                        returnUrl: `/dashboard/property/${this.props.data.propertyId}/spec/units`,
                                                    }

                                                let toQuery =
                                                    JSON.stringify(query)
                                                goToRoute(
                                                    `/dashboard/units/multi-add?propertyConfiguration=${toQuery}`
                                                )
                                            }}
                                            config={{
                                                template: UnitDefaultTemplate(
                                                    this.props,
                                                    {
                                                        updateRedirectUrl:
                                                            this.props.location
                                                                .pathname,
                                                    }
                                                ),
                                                dataFilterSettings: {
                                                    propertyId:
                                                        this.props.match.params
                                                            .propertyId,
                                                },
                                                addSettings: {
                                                    propertyId:
                                                        this.props.match.params
                                                            .propertyId,
                                                },
                                            }}
                                        />
                                    </div>
                                )}
                            />

                            <ClientAreaRestriction restriction="AssetsFunctions">
                                <Route
                                    path={`${this.props.match.path}/assets`}
                                    exact
                                    render={() => (
                                        <div className={styles.nestedPage}>
                                            <AssetsGrid
                                                config={{
                                                    filterSettings: {
                                                        propertyId:
                                                            this.props.match
                                                                .params
                                                                .propertyId,
                                                    },
                                                    addSettings: {
                                                        propertyId:
                                                            this.props.match
                                                                .params
                                                                .propertyId,
                                                        propertyName:
                                                            this.props.data
                                                                .propertyName,
                                                        returnUrl: `/dashboard/property/${this.props.match.params.propertyId}/spec/assets`,
                                                        referer: 'property',
                                                    },
                                                }}
                                            />
                                        </div>
                                    )}
                                />
                            </ClientAreaRestriction>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    data: state.property.property_data.property_data,
    isKeyDatesBeta: state.betaConfigReducer.isKeyDatesBeta
})
export default connect(mapStateToProps, null)(PropertyDetailPage)
