import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'

// Import Styles
import styles from './ContactPage.module.scss'
import { IUser } from '../../../types/UserService/IUser'

import { getContactList } from '../../../services/ContactService'
import { IContactListItem } from '../../../types/ContactService/ContactService'
import ContactListItem from '../../atoms/ContactListItem'

import { toggleAddContactSidebar } from '../../../helpers/sidebarHelpers'
import { IUserListItem, IClientListItem } from '../../../types/ClientService'
import RotatingLoader from '../../atoms/RotatingLoader'
import SelectDropdown, { IOptions } from '../../atoms/SelectDropdown'
import { updateContactsList } from '../../../helpers/contactHelpers'
import ShowOnlyMy from '../../atoms/ShowOnlyMy'
import { getTypesForDropdown } from '../../../services/EntityService'
import { entityTypeGroupId } from '../../../configuration/appConfig'
import { removeURLParameter } from '../../../helpers/locationHelpers'
import ElevioArticleEmbedWrapper from '../../atoms/ElevioArticleEmbedWrapper'
import { isPlatformAdmin } from '../../../helpers/roleHelpers'
import { elevioContactArticleId } from '../../../configuration/elevioConfig'
import IconButton from '../../atoms/Buttons/IconButton'
import SearchContactInput from '../../atoms/SearchContactInput'
import { string } from 'yup'
const queryString = require('query-string')

interface IWithRouterProps {
    history: any
    location: any
    match: any
    user: IUserListItem
    client: IClientListItem
    contactList: Array<IContactListItem>
}

interface IData {
    user: IUser
}

interface IState {
    data: IData
    userId: string
    AddContact: boolean
    loading: boolean
    search: string
    showMyContactsOnly: boolean
    totalContacts: number
    page: number
    size: number
    contactTypeParam: string
    selectedContactType: string
    contactTypes: Array<IOptions>
}

class ContactPage extends Component<IWithRouterProps, IState> {
    constructor(props: any) {
        super(props)

        this.toggleAdd = this.toggleAdd.bind(this)
        this.onAddCancel = this.onAddCancel.bind(this)
        this.toggleShowMyOwnContacts = this.toggleShowMyOwnContacts.bind(this)
        this.getContacts = this.getContacts.bind(this)
        this.onSearchCallback = this.onSearchCallback.bind(this)
        this.onSelectDropdown = this.onSelectDropdown.bind(this)
        this.getEntities = this.getEntities.bind(this)
        this.resetAndFetch = this.resetAndFetch.bind(this)
        this.getOptions = this.getOptions.bind(this)
        this.getContactList = this.getContactList.bind(this)

        this.state = {
            data: {
                user: {
                    email: '',
                },
            },
            userId: '',
            search: '',
            AddContact: false,
            loading: false,
            showMyContactsOnly: false,
            totalContacts: 0,
            page: 1,
            size: 8,
            contactTypeParam: '',
            selectedContactType: '',
            contactTypes: [],
        }

        this.addContact = this.addContact.bind(this)
    }

    async getEntities(): Promise<void> {
        getTypesForDropdown(entityTypeGroupId).then((resp) => {
            let data = [
                {
                    label: 'All',
                    value: '',
                },
            ]
            this.setState(
                {
                    contactTypes: data.concat(resp.data),
                },
                async () => await this.getOptions()
            )
        })
    }

    onSelectDropdown(selected: IOptions): void {
        updateContactsList([])
        this.setState(
            {
                page: 1,
                selectedContactType: selected.value,
            },
            () => {
                this.resetAndFetch()
            }
        )
    }

    async onSearchCallback(search: string): Promise<void> {
        this.setState(
            {
                search: search,
            },
            () => {
                this.resetAndFetch()
            }
        )
    }

    toggleShowMyOwnContacts(): void {
        updateContactsList([])
        this.setState(
            {
                page: 1,
                showMyContactsOnly: !this.state.showMyContactsOnly,
            },
            () => this.getContacts()
        )
    }

    componentWillUnmount(): void {
        updateContactsList([])
    }

    resetAndFetch(): void {
        this.setState({
            loading: true,
        })

        getContactList(
            {
                page: this.state.search && this.state.search != '' ? 1 : this.state.page,
                size: this.state.size,
                onlyShowOwnRecords: this.state.showMyContactsOnly,
                search: this.state.search,
            },
            {
                contactType: this.state.selectedContactType,
                ownerType: '',
            }
        ).then((resp) => {
            if (resp.data) {
                let data: Array<IContactListItem> = resp.data.data
                updateContactsList(data)
            }
            this.setState({
                loading: false,
                page: resp.data.nextPage,
                totalContacts: resp.data.totalCount,
            })
        })
    }

    getContacts(): void {
        this.setState({
            loading: true,
            data: null,
        })

        getContactList(
            {
                page: this.state.page,
                size: this.state.size,
                onlyShowOwnRecords: this.state.showMyContactsOnly,
                search: this.state.search,
            },
            {
                contactType: this.state.selectedContactType,
                ownerType: '',
            }
        ).then((resp) => {
            if (resp.data) {
                let data: Array<IContactListItem> = resp.data.data
                let newTenancies = this.props.contactList.concat(data)
                updateContactsList(newTenancies)
            }
            this.setState({
                loading: false,
                page: resp.data.nextPage,
                totalContacts: resp.data.totalCount,
            })
        })
    }

    async getOptions(): Promise<void> {
        let contactType =
            this.state.contactTypeParam ||
            queryString.parse(this.props.location.search).contactType
        if (this.state.contactTypeParam == 'All') {
            this.setState(
                {
                    selectedContactType: '',
                },
                () => this.getContactList()
            )
        } else if (contactType) {
            let contactTypes = this.state.contactTypes.filter(
                (ct) => ct.label == contactType
            )
            if (contactTypes && contactTypes.length > 0) {
                this.setState(
                    {
                        selectedContactType: contactTypes[0].value,
                    },
                    () => this.getContactList()
                )
            }
        } else {
            this.getContactList()
        }

        let removeQuery = removeURLParameter(
            this.props.location.search,
            'contactType'
        )
        this.props.history.push({
            search: removeQuery,
        })
    }

    getContactList(): void {
        updateContactsList([])
        this.setState({
            loading: true,
        })
        getContactList(
            {
                page: this.state.page,
                size: this.state.size,
                onlyShowOwnRecords: this.state.showMyContactsOnly,
                search: this.state.search,
            },
            {
                contactType: this.state.selectedContactType,
                ownerType: '',
            }
        ).then((resp) => {
            if (resp.data) {
                let data: Array<IContactListItem> = resp.data.data
                updateContactsList(data)
            }

            this.setState({
                loading: false,
                page: resp.data.nextPage,
                totalContacts: resp.data.totalCount,
            })
        })
    }

    async componentDidMount(): Promise<void> {
        this.setState({
            loading: true,
        })

        await this.getEntities()
    }

    componentDidUpdate(newProps: IWithRouterProps): void {
        let path = this.props.location.pathname
        let newPath = newProps.location.pathname
        if (path === newPath) {
            let contactType = queryString.parse(
                this.props.location.search
            ).contactType
            let newContactType = queryString.parse(
                newProps.location.search
            ).contactType
            if (contactType != newContactType) {
                if (newContactType == 'All') {
                    this.setState(
                        {
                            contactTypeParam: '',
                            selectedContactType: '',
                            data: null,
                            page: 1,
                        },
                        () => this.getOptions()
                    )
                    return
                }
                this.setState(
                    {
                        contactTypeParam: newContactType,
                        data: null,
                        page: 1,
                    },
                    () => this.getOptions()
                )
            }
        }
    }

    addContact(): void {
        if (
            this.state.selectedContactType ===
            'e08d17b2-3fa0-43bb-8458-18378f92fded'
        ) {
            this.props.history.push({
                search: '?callbackLandlord=true',
            })
        }

        toggleAddContactSidebar(true)
    }

    toggleAdd() {
        this.setState({
            AddContact: !this.state.AddContact,
        })
    }

    onAddCancel() {
        this.setState({
            AddContact: false,
        })
    }

    render() {
        if (!this.props.contactList) {
            return null
        }

        return (
            <div className={styles.page}>
                <Row>
                    <Col  className={styles.SearchBarDiv} xs="12">
                        <SearchContactInput
                            onSelect={(option: IOptions) =>{
                                option.value && this.props.history.push('/dashboard/contact/' + option.value)}
                            }
                            showSearchButton={true}
                            searchButtonOnClick={(searchValue: string) => this.onSearchCallback(searchValue)}
                        />
                    </Col>
                </Row>
                <div className={styles.filter}>
                    <div className={styles.dropdown}>
                        <SelectDropdown
                            selectedId={this.state.selectedContactType}
                            data={this.state.contactTypes}
                            placeholder="Contact type..."
                            onSelect={(id: IOptions) =>
                                this.onSelectDropdown(id)
                            }
                        />
                    </div>

                    <div className={styles.rightFilter}>
                        {isPlatformAdmin() && (
                            <div className={styles.showOnlyMyContacts}>
                                <ShowOnlyMy
                                    onChange={() =>
                                        this.toggleShowMyOwnContacts()
                                    }
                                    checked={this.state.showMyContactsOnly}
                                    showOnlyMy="contacts"
                                />
                            </div>
                        )}

                        <div className={styles.addDiv}>
                            <IconButton
                                button={{
                                    text: 'Add Contact',
                                    displayType: 'submit',
                                    elementType: 'button',
                                    icon: 'add',
                                    onClick: () => this.addContact(),
                                }}
                            />
                        </div>
                    </div>
                </div>
                <Row>
                    <div className={styles.list}>
                        <InfiniteScroll
                            className={styles.infiniteScroll}
                            dataLength={this.state.totalContacts}
                            next={this.getContacts}
                            hasMore={
                                this.props.contactList.length <
                                this.state.totalContacts
                            }
                        >
                            <Col>
                                {this.props.contactList &&
                                    this.props.contactList.map(
                                        (prop: IContactListItem, index) => {
                                            return (
                                                <ContactListItem
                                                    client={this.props.client}
                                                    user={this.props.user}
                                                    contactItem={prop}
                                                    key={index}
                                                />
                                            )
                                        }
                                    )}

                                {this.props.contactList.length == 0 &&
                                    !this.state.loading && (
                                        <ElevioArticleEmbedWrapper>
                                            <elevio-element
                                                data-type="article"
                                                data-id={`${elevioContactArticleId}`}
                                            ></elevio-element>
                                        </ElevioArticleEmbedWrapper>
                                    )}

                                {this.state.loading && (
                                    <div>
                                        <RotatingLoader
                                            text="Loading your contacts..."
                                            loading={true}
                                        />
                                    </div>
                                )}
                            </Col>
                        </InfiniteScroll>
                    </div>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    contactList: state.contact.contact_list,
})

export default withRouter(connect(mapStateToProps)(ContactPage) as any)
