import React from "react";
import { IOptions } from "../SelectDropdown";
import { getSearchUnits } from "../../../services/SearchService";
import SelectDropdownV2 from "../SelectDropdownV2/SelectDropdownV2.component";

interface IProps {
  selectedUnitId: string;
  selectedUnitName: string;
  onSelect(option: IOptions): void;
  onClear?(): void;
  disableClear?: boolean;
  propertyId?: string;
}

interface IState {
  results: any[];
}

class SearchUnitInput extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      results: []
    };

    this.onSearchUnit = this.onSearchUnit.bind(this);
    this.getUnits = this.getUnits.bind(this);
  }

  onSearchUnit(search: string): void {
    if (!search || search == "") {
      this.setState({
        results: []
      });
      return;
    }

    this.getUnits(search);
  }

  getUnits(search?: string,): void {
    getSearchUnits(search, this.props.propertyId).then(resp => {
      if (resp && resp.status && resp.data) {
        this.setState({
          results: resp.data
        });
      }
    });
  }

  render() {
    return (
      <SelectDropdownV2  
        propertyId={this.props.propertyId}
        onClear={this.props.onClear}
        disableClear={false}
        selectedId={this.props.selectedUnitId}
        selectedName={this.props.selectedUnitName}
        onSelect={this.props.onSelect}
        onSearch={this.getUnits}
        data={this.state.results}
        inputPlaceHolder={"Search for a Unit..."}
        isSearchable={true}
      />
    )
  }
}

export default SearchUnitInput;
