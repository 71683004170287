import { apiService } from "../services/ApiService";
import { createBearerToken } from "../helpers/authHelpers";
import * as domains from "../configuration/domains";
import { ICreateClient, IClientData } from "../types/ClientService";

export const getClients = (): Promise<any> => {
  return apiService
    .get(`${domains.clientService}/client-list`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error;
    });
};

export const getClient = (clientId: string): Promise<any> => {
  return apiService
    .get(`${domains.clientService}/${clientId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const getDefaultClient = (): Promise<any> => {
  return apiService
    .get(`${domains.clientService}/default`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};


export const getSelectableClientList = (): Promise<any> => {
  return apiService.get(`${domains.clientService}/list`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export const getClientsDropdown = (): Promise<any> => {
  return apiService
    .get(`${domains.clientService}/dropdown`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error;
    });
};

export const getClientUsers = (
  pageSize: number,
  page: number,
  search?: string,
  clientIds?: Array<any>
): Promise<any> => {
  let clientIdParams;
  if (clientIds) {
    clientIds.map(client => {
      clientIdParams = clientIdParams + "&clientId=" + client;
    });
  }

  let url = `${
    domains.clientService
  }/users?size=${pageSize}&page=${page}&search=${search}${clientIdParams}`;
  return apiService.get(url, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export const getNewUserFormData = (): Promise<any> => {
  let url = `${domains.clientService}/new-user-data`;
  return apiService.get(url, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export const getUserData = (userId: string): Promise<any> => {
  let url = `${domains.clientService}/user-profile/${userId}`;
  return apiService
    .get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const addClient = (request: ICreateClient): Promise<any> => {
  return apiService
    .post(`${domains.clientService}/`, request, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const updateClient = (clientId: string, request: IClientData): Promise<any> => {
  return apiService
    .put(`${domains.clientService}/${clientId}`, request, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error;
    });
};