import { useEffect, useState } from "react";
import { getChart } from "../services/DashboardService";
import { IChartDataPointPercentage } from "../types/StatisticsService";

export const useFetchChartData = (chartApiName: string) => {
    const [dataRetrieved, setDataRetrieved] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setDataRetrieved(false);
            let resp = await getChart(chartApiName);
            if (resp && resp.status == 200 && resp.data) {
                let data1: Array<IChartDataPointPercentage> = resp.data.chartData.values

                let array1: Array<Array<any>> = []
                array1.push(['Name', 'Percentage'])

                data1.map((point) => {
                    let data = [point.name, point.percentage]
                    array1.push(data)
                })

                setData(array1);
                setDataRetrieved(true);
            }
        }
        fetchData();
    }, [chartApiName])

    return { dataRetrieved, data };
}