interface ICalculatorState {
    last_added_property_id: string;
}

const initialState = {
    last_added_property_id: ''
}

export default (state: ICalculatorState = initialState, action: any): ICalculatorState => {
    switch (action.type) {

        case "ADD_PROPERTY_FROM_CALCULATOR_SUCCESS":
            return {
                ...state,
                last_added_property_id: action.propertyId
            }

        default:
            return state
    }
}