let create = require("history").createBrowserHistory

const history = create();
export default history;

export function goToRoute(route: string): void {
    history.push(route);
}

export function goToPreviousRoute(): void {
    history.goBack();
}