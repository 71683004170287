import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Sidebar from 'react-sidebar'
import { sidebarStyling } from '../../../global/variables/_variables'
import PropertySidePanelBody from '../../atoms/ProfileSidePanelBody'
import styles from './ProfileSidePanel.module.scss'

interface IProps {
    isOpen: boolean
    onSetOpen(open: boolean): void
}

interface IRouterProps {
    history: any
    location: any
    match: any
}

interface IState {
    sidebarOpen: boolean
}

type Props = IProps & IRouterProps

class ProfileSidePanel extends Component<Props, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            sidebarOpen: false,
        }
    }

    render() {
        return (
            <div>
                <Sidebar
                    touch={false}
                    pullRight={true}
                    sidebarClassName={styles.sidebar}
                    sidebar={
                        this.props.isOpen ? (
                            <PropertySidePanelBody />
                        ) : (
                            <div></div>
                        )
                    }
                    open={this.props.isOpen}
                    onSetOpen={this.props.onSetOpen}
                    styles={sidebarStyling}
                >
                    <div />
                </Sidebar>
            </div>
        )
    }
}

export default withRouter(ProfileSidePanel)
