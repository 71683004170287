import { HubConnection, LogLevel, HubConnectionBuilder } from '@aspnet/signalr';
import { getUserId, getJwt, removeWebsocketHandler, addWebsocketHandler, updateWebsocketHandlers } from '../helpers/authHelpers';
import * as domains from '../configuration/domains';
import ChatMessageProcessor from './ChatMessageProcessor';
import { IWSHandler } from '../reducers/appReducer';
import { NewChatMessage, NewChatMessage_ChatSpecific, NewChatStarted } from '../configuration/signalRConfig';
import { INewChatStarted } from '../types/ChatService';
import { debug } from 'console';

class ChatWebsocketService {
    private _connection: HubConnection = null;

    constructor() {
        console.debug('ChatWebsocketService:constructor - start');
        this.startConnection = this.startConnection.bind(this);
    }

    hasInstance(): boolean {
        if (this._connection != null) {
            console.debug('ChatWebsocketService:hasInstance - true');
            return true;
        }
        console.debug('ChatWebsocketService:hasInstance - false');
        return false;
    }

    createConnection(): void {
        console.debug('ChatWebsocketService:createConnection - start');
        if (this.hasInstance()) {
            console.debug('ChatWebsocketService:createConnection - hasInstance, unregister and disconnect first');
            //this.unregisterAll();
            //this.disconnectFromHub();
            return;
        }

        this._connection = new HubConnectionBuilder().withUrl(`${domains.rootDomainCore}/chathub?userId=${getUserId()}&access_token=${getJwt()}`)
            .configureLogging(LogLevel.Error).build();
        this.startConnection();

        this._connection.serverTimeoutInMilliseconds = 100000;

        //this._connection.onclose(() => setTimeout(this.startConnection, 5000));
    }

    startConnection(): void {
        console.debug('ChatWebsocketService:startConnection - start');
        if (!this.hasInstance()) {
            console.debug('ChatWebsocketService:startConnection - has no instance so return immediately without starting connection');
            return;
        }

        // start connection
        this._connection.start().then(_ => {
            console.debug('ChatWebsocketService:startConnection - started connection');
            WebsocketService.registerNewChatMessageNotification((msg => {
                console.debug('ChatWebsocketService:startConnection - registerNewChatMessageNotification');
                ChatMessageProcessor.process(msg);
            }));

            WebsocketService.registerNewChatStarted((msg => {
                console.debug('ChatWebsocketService:startConnection - registerNewChatStarted');
                ChatMessageProcessor.processNewChat(msg);
            }));
        }).catch(e => {
            console.log("Error connecting to SignalR: ", e);
        });
    }

    disconnectFromHub(): void {
        console.debug('ChatWebsocketService:disconnectFromHub - start');
        if (this._connection) {
            console.debug('ChatWebsocketService:disconnectFromHub - stop connection');
            this._connection.stop();
        }
    }

    unregisterAll(): void {
        console.debug('ChatWebsocketService:unregisterAll - start');
        let handlers: Array<IWSHandler> = [];

        // WS Handlers - Unregister all
        handlers.map(x => {
            this._connection.off(x.handlerName);
        });

        updateWebsocketHandlers([]);
    }

    registerNotification(notificationAdded: (msg: any) => void) {
        console.debug('ChatWebsocketService:registerNotification - start');
        if (!this._connection) {
            console.debug('ChatWebsocketService:registerNotification - No connection so return immediatelty');
            return;
        }

        this._connection.on(`NewNotification`, (message: any) => {
            console.debug('ChatWebsocketService:registerNotification - NewNotification');
            notificationAdded(message);
        });
    }

    registerNewChatStarted(newChatStarted: (msg: INewChatStarted) => void) {
        console.debug('ChatWebsocketService:registerNewChatStarted - start');
        if (!this._connection) {
            console.debug('ChatWebsocketService:registerNewChatStarted - no connection so return');
            console.log("No connection");
            return;
        }

        addWebsocketHandler({
            handlerName: NewChatStarted,
            registerdOn: new Date()
        });
        this._connection.on(NewChatStarted, (message: any) => {
            console.debug('ChatWebsocketService:registerNewChatStarted - on NewChatStarted');
            newChatStarted(message);
        });
    }

    registerNewChatMessageNotification(newChatMessageRecieved: (msg: any) => void) {
        console.debug('ChatWebsocketService:registerNewChatMessageNotification - start');
        if (!this._connection) {
            console.debug('ChatWebsocketService:registerNewChatMessageNotification - no connection so return');
            return;
        }

        addWebsocketHandler({
            handlerName: NewChatMessage,
            registerdOn: new Date()
        });
        this._connection.on(NewChatMessage, (message: any) => {
            console.debug('ChatWebsocketService:registerNewChatMessageNotification - on NewChatMessage');
            newChatMessageRecieved(message);
        });
    }

    unregisterNewChatMessageNotification() {
        console.debug('ChatWebsocketService:unregisterNewChatMessageNotification - start');
        if (!this._connection) {
            console.debug('ChatWebsocketService:unregisterNewChatMessageNotification - no connection so return');
            return;
        }

        this._connection.off(NewChatMessage);

        // WS Handlers
        removeWebsocketHandler(NewChatMessage);
    }

    registerNewMessage(sessionId: string, messageAdded: (msg: any) => void) {
        console.debug('ChatWebsocketService:registerNewMessage - start');
        if (!this._connection) {
            console.debug('ChatWebsocketService:registerNewMessage - no connection so return');
            return;
        }

        addWebsocketHandler({
            handlerName: `${NewChatMessage_ChatSpecific(sessionId)}`,
            registerdOn: new Date()
        });
        this._connection.on(`${NewChatMessage_ChatSpecific(sessionId)}`, (message: any) => {
            console.debug('ChatWebsocketService:registerNewMessage - on NewChatMessage_ChatSpecific');
            messageAdded(message);
        });
    }

    unregisterNewMessage(sessionId: string) {
        console.debug('ChatWebsocketService:unregisterNewMessage - start');
        if (!this._connection) {
            console.debug('ChatWebsocketService:unregisterNewMessage - no connection ');
            return;
        }

        this._connection.off(`${NewChatMessage_ChatSpecific(sessionId)}`);

        // WS Handlers
        removeWebsocketHandler(`${NewChatMessage_ChatSpecific(sessionId)}`);
    }

    registerMessageAdded(messageAdded: (msg: any) => void) {
        console.debug('ChatWebsocketService:registerMessageAdded - start');
        if (!this._connection) {
            console.debug('ChatWebsocketService:registerMessageAdded - no connectio so return');
            return;
        }

        this._connection.on('ReceiveMessage', (message: any) => {
            console.debug('ChatWebsocketService:registerMessageAdded - on ReceiveMessage');
            messageAdded(message);
        });
    }
}

const WebsocketService = new ChatWebsocketService();

export default WebsocketService;