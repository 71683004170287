import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { getUserInformation } from "../../../actions/userActions";
import {
  getJwt,

  isImpersonatingTrue, isPreviewTrue,

  userIsLoggedInTrue
} from "../../../helpers/authHelpers";
import {
  AuthenticatedRoute,
  GuestRoute
} from "../../../helpers/routeAuthentication";
import { UpdateTokenInReduxStore } from "../../../helpers/tokenHelpers";
import ResearchPage from "../../atoms/ResearchPage";
import CreatePasswordForm from "../../molecules/CreatePasswordForm";
import LoadingOverlay from "../../molecules/LoadingOverlay";
import RegisterForm from "../../molecules/RegisterForm";
import AccountActivationPage from "../AccountActivationPage";
import AccountPage from "../AccountPage";
import DashboardPage from "../DashboardPage";
import WhitelabelPage from "../WhitelabelPage";
import WhitelabelRegisterForm from "../WhitelabelRegisterForm";
import ScrollToTop from "../../atoms/ScrollToTop/ScrollToTop.component"

const queryString = require("query-string");

interface IRouterProps {
  is_logged_in: boolean;
  app_loading: boolean;
  is_impersonating: boolean;
  getUserInformation(): void;
  history: any;
  location: any;
  match: any;
}

class PageContainer extends Component<IRouterProps> {
  componentWillMount() {
    let isPreview = queryString.parse(this.props.location.search).preview;
    if (isPreview) {
      return;
    }

    let isImpersonating = queryString.parse(
      this.props.location.search
    ).impersonatorAccessToken;
    if (isImpersonating) {
      isImpersonatingTrue();
      userIsLoggedInTrue();
      UpdateTokenInReduxStore(isImpersonating);
    }

    if (getJwt() != undefined || getJwt() != "" || getJwt() != null) {
      this.props.getUserInformation();
    }
  }

  componentWillReceiveProps(newProps: IRouterProps) {
    let isPreview = queryString.parse(this.props.location.search).preview;
    if (isPreview) {
      isPreviewTrue();
      return;
    }

    if (
      newProps.is_logged_in != this.props.is_logged_in ||
      (this.props.is_impersonating &&
        newProps.is_impersonating != this.props.is_impersonating)
    ) {
      if (newProps.is_logged_in || this.props.is_impersonating) {
        this.props.getUserInformation();
      }

      if (!newProps.is_logged_in) {
        this.props.history.push({
          pathname: `/account/login`,
          search: this.props.location.search,
        });
      }
    }
  }

  render() {
    if (this.props.app_loading) {
      return <LoadingOverlay />;
    }

    return (
      <div>
        <ScrollToTop />
        <Switch>
          <Redirect
            from="/"
            exact
            to={{ pathname: "/dashboard", search: this.props.location.search, state: this.props.location.state }}
          />
          <AuthenticatedRoute
            {...this.props}
            path="/dashboard"
            component={DashboardPage}
          />

          <AuthenticatedRoute path="/sticky" component={ResearchPage} />

          <GuestRoute path="/account" component={AccountPage} />

          <GuestRoute path="/wl/register" component={WhitelabelRegisterForm} />

          <Route path={`/registration-preview`} component={RegisterForm} />

          <Route
            path={`/embed-test`}
            render={() => (
              <div>
                <h2>This is an embed test using the Boomin client</h2>
                <iframe
                  src="https://dev.rentchief.co.uk/wl/register?whitelabelId=53B0BDDF-9B7E-432C-8CFB-4C8CB7952794"
                  width="600"
                ></iframe>
              </div>
            )}
          />

          <Route path={`/whitelabel`} render={() => <WhitelabelPage />} />

          <Route
            path={`/no-default-client`}
            render={() => <div>No default client has been added</div>}
          />

          <Route
            path="/account/activation"
            exact
            component={AccountActivationPage}
          />
          <GuestRoute
            path="/account/create-password/:userId/:token"
            exact
            component={CreatePasswordForm}
          />

          <Route
            render={() => (
              <Redirect
                {...this.props.location}
                to={{
                  pathname: "/",
                  search: this.props.location.search,
                }}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  is_logged_in: state.user.is_logged_in,
  app_loading: state.app.app_loading,
  is_impersonating: state.app.is_impersonating,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getUserInformation: () => {
      dispatch<any>(getUserInformation());
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageContainer) as any
);
