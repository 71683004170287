import { Dispatch } from 'redux';
import axios from 'axios';
import { identityService, userService, propertyService, registrationService, profileUpdateService } from '../configuration/domains';

import { IIdentityRequest, IIdentityResponse } from '../types/LoginService/ILogin';
import { IGetUserInformationResponse } from '../types/UserService/IUser';

import { createBearerToken, getJwt, hasTokenInReduxStore, loginSuccess, isImpersonatingFalse } from '../helpers/authHelpers';
import { AddTokenToLocalStorage, RemoveTokenFromLocalStorage, HasTokenStorageExpired, UpdateTokenInReduxStore } from '../helpers/tokenHelpers';
import { ICreateUser } from '../types/RegistrationService/IRegistration';
import { dispatchAppLoadingTrue } from './calculatorActions';
import { dispatchClearClientData } from './clientActions';
import { rcToken, startRollbar, runningEnvironnment } from '../configuration/appConfig';
import { identifyUser } from '../helpers/fullstoryHelpers';
import WebsocketService from '../services/SignalRService';
import RollbarService, { setRollbarConfiguration } from '../helpers/rollbarHelpers';
import { apiService } from "../services/ApiService";
import { setElevioConfiguration } from '../helpers/elevioHelpers';
import { addClientIdToSessionStorage } from '../helpers/sessionStorageHelpers';
import history from '../history';

export interface IRegistrationResponse {
    isLoggedIn: boolean;
    isAccountCreated: boolean;
    isValidAccessCode: boolean;
    accessToken: string;
    message: string;
}

export const loginUser = (loginRequest: IIdentityRequest, rememberLogin: boolean, returnUrl?: string) => {
    return (dispatch: Dispatch) => {

        dispatch(dispatchLoginRequested());

        return axios.post(`${identityService}`, loginRequest, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const data: IIdentityResponse = response.data;
                if (data.success && data.accessToken != '') {
                    //console.log('loginUser - AddTokenToLocalStorage');
                    AddTokenToLocalStorage(data.accessToken, rememberLogin);

                    dispatch(dispatchLoginSuccess(response.data));

                    // Redirect if there is a return URL
                    if (returnUrl) {
                        history.push(returnUrl);
                    }

                    // Identify the user for FullStory Analytics
                    identifyUser();
                    // Set Rollbar User Config
                    RollbarService.createRollbarInstance();
                    //setRollbarConfiguration();
                }

            }).catch(error => {
                console.log('Login exception');
                if(error && error.response && error.response.status){
                    console.log('Login exception status: ' + error.response.status);
                    //dispatch(dispatchLoginError(error.response ? error.response.data : ""));
                }
                dispatch(dispatchLoginError(error.response ? error.response.data : ""));
            });
    }
}

export const registerUser = (registerRequest: ICreateUser) => {
    return (dispatch: Dispatch) => {

        dispatch(dispatchAppLoadingTrue());

        return axios.post(`${registrationService}`, registerRequest, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const data: IRegistrationResponse = response.data;

                if (data != null && data.isLoggedIn && data.accessToken != "") {
                    AddTokenToLocalStorage(data.accessToken, true);
                    UpdateTokenInReduxStore(data.accessToken);
                    getUserInformation();

                    dispatch(dispatchRegisterUserSuccess(data.isValidAccessCode));
                    dispatch(dispatchAppLoadingFalse());
                }

                if (data != null && data.isAccountCreated) {
                    dispatch(dispatchRegisterUserSuccess(data.isValidAccessCode));
                    dispatch(dispatchAppLoadingFalse());
                    return;
                }

                if (data == null || !data.isLoggedIn || data.accessToken == "") {
                    dispatch(dispatchAppLoadingFalse());
                    dispatch(dispatchRegisterUserNoAutoLogin(data));
                }

            }).catch(error => {
                dispatch(dispatchAppLoadingFalse());
            });
    }
}

export const dispatchRegisterUserSuccess = (registerAccessCodeValid: boolean) => {
    return {
        type: "REGISTER_USER_SUCCESS",
        registerAccessCodeValid
    }
}

export const dispatchRegisterUserNoAutoLogin = (response: IRegistrationResponse) => {
    return {
        type: "REGISTER_USER_NO_AUTO_LOGIN",
        data: response.message
    }
}

export const logoutUser = () => {
    return (dispatch: Dispatch) => {
        dispatch(dispatchGetUserInformationRequested());
        dispatch(dispatchLogoutUser());
        dispatch(dispatchAppLoadingFalse());
        isImpersonatingFalse();
        WebsocketService.unregisterNewChatMessageNotification();
    }
}

export const getUserInformation = () => {
    return (dispatch: Dispatch) => {

        if (!hasTokenInReduxStore()) {
            // Get Token from Local Storage
            var token = getJwt();

            if (token == undefined || token == '' || token == null) {
                return;
            }

            if (HasTokenStorageExpired()) {
                RemoveTokenFromLocalStorage(rcToken);
                return;
            }

            dispatch(dispatchMoveTokenToReduxStore(token));
        }

        dispatch(dispatchGetUserInformationRequested());

        return apiService.get(`${userService}/get-user-information`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': createBearerToken()
            },
        })
            .then((response: any) => {
                const data: IGetUserInformationResponse = response.data;
                if (data) {
                    dispatch(dispatchGetUserInformationSuccess(data));
                    dispatch(dispatchAppLoadingFalse());

                    // Create Websocket
                    WebsocketService.createConnection();

                    // Setup Rollbar
                    RollbarService.createRollbarInstance();
                    //setRollbarConfiguration();

                    setElevioConfiguration(data.elevioHash);

                    if (data.client) {
                        dispatch(dispatchGetUserClientSuccess(data.client));
                        addClientIdToSessionStorage(data.client.id);
                    }
                }
                return;
            }).catch(error => {
                RemoveTokenFromLocalStorage(rcToken);
                dispatch(dispatchGetUserInformationFailure());
                dispatch(dispatchAppLoadingFalse());
            });
    }
}

export const updateUserInformation = (data: any) => {
    return {
        type: 'PROFILE_UPDATE',
        data
    }
}

export const dispatchGetUserClientSuccess = (data: any) => {
    return {
        type: "FETCH_CLIENT_DATA_SUCCESS",
        data
    }
}

export const dispatchGetUserInformationFailure = () => {
    return {
        type: "GET_USER_DATA_FAILURE"
    }
}

export const dispatchLogoutUser = () => {
    return {
        type: "LOGOUT_USER"
    }
}

export const dispatchMoveTokenToReduxStore = (token: any) => {
    return {
        type: "MOVE_TOKEN_TO_STORE",
        token
    }
}

export const dispatchAppLoadingFalse = () => {
    return {
        type: "APP_LOADING_FALSE"
    }
}

export const dispatchGetUserInformationRequested = () => {
    return {
        type: "APP_LOADING_TRUE"
    }
}

export const dispatchGetUserInformationSuccess = (response: IGetUserInformationResponse) => {
    return {
        type: "GET_USER_DATA_SUCCESS",
        data: response
    }
}

export const dispatchLoginError = (message: string) => {
    return {
        type: "USER_LOGIN_ERROR",
        message
    }
}

export const dispatchLoginRequested = () => {
    return {
        type: "USER_LOGIN_REQUESTED"
    }
}

export const dispatchLoginSuccess = (data: IIdentityRequest) => {
    return {
        type: "USER_LOGIN_SUCCESS",
        data
    }
}