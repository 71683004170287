import React from 'react'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import { withRouter } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

import { removeURLParameter } from '../../../helpers/locationHelpers'
import { toggleDeleteUserPopup } from '../../../helpers/sidebarHelpers'
import {
    toastDeleteResponseHandler,
    updateToastMessage,
} from '../../../helpers/toastHelper'
import { deleteUsers } from '../../../services/UserService'
import { IDataResponse, IDeleteResponse } from '../../../types/ApiService'
import Buttons from '../Buttons'
import RotatingLoader from '../RotatingLoader'
import SuccessTick from '../SuccessTick'
import styles from './DeleteUserModal.module.scss'

const queryString = require('query-string')

interface IProps {
    user_delete_popup: boolean
    history: any
    location: any
    match: any
}

interface IState {
    deleteDocuments: boolean
    loading: boolean
    success: boolean
    userIds: string[]
}

class DeleteUserModal extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            deleteDocuments: true,
            loading: false,
            success: false,
            userIds: [],
        }
    }

    componentDidMount(): void {
        let options = queryString.parse(this.props.location.search).deleteUser

        if (!options) {
            return
        }

        let option = JSON.parse(options)

        this.setState({
            userIds: option,
        })

        let removeQuery = removeURLParameter(
            this.props.location.search,
            'deleteUser'
        )

        this.props.history.push({
            search: removeQuery,
        })
    }

    onDelete(): void {
        this.setState({
            loading: true,
        })

        deleteUsers(this.state.userIds)
            .then((resp) => {
                if (resp.status == 200) {
                    let response: IDataResponse<IDeleteResponse> = resp.data

                    toastDeleteResponseHandler(response)

                    this.setState({
                        loading: false,
                        success: true,
                    })

                    toggleDeleteUserPopup(false)
                }
            })
            .catch((e) => {
                updateToastMessage(e.message, 'failure')
            })
    }

    render() {
        if (!this.state.userIds) {
            return null
        }

        return (
            <Modal
                classNames={{ modal: styles.modal }}
                open={this.props.user_delete_popup}
                onClose={() => toggleDeleteUserPopup(false)}
                center
            >
                <div className={styles.body}>
                    {this.state.loading ? (
                        <div>
                            <RotatingLoader
                                loading={true}
                                text={`Deleting users...`}
                            />
                        </div>
                    ) : this.state.success ? (
                        <div>
                            <SuccessTick />
                            <p className={styles.modalBody}>Users deleted</p>
                        </div>
                    ) : (
                        <div>
                            <p className={styles.modelHeader}>DELETE USERS</p>

                            <p className={styles.modalBody}>
                                <div>You are about to delete:</div>
                                <div className={styles.records}>
                                    {this.state.userIds.length} User
                                    {this.state.userIds.length == 1 ? '' : 's'}
                                </div>
                                <div>Do you wish to go ahead and delete?</div>
                            </p>

                            <Row>
                                <Col className={styles.buttonDiv}>
                                    <Buttons
                                        buttons={[
                                            {
                                                displayType: 'cancel',
                                                elementType: 'button',
                                                onClick: () =>
                                                    toggleDeleteUserPopup(
                                                        false
                                                    ),
                                            },
                                            {
                                                text: 'Delete',
                                                displayType: 'submit',
                                                elementType: 'button',
                                                onClick: () => this.onDelete(),
                                            },
                                        ]}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state: any) => ({
    user_delete_popup: state.app.popups.user_delete_popup,
})

export default withRouter(connect(mapStateToProps)(DeleteUserModal) as any)
