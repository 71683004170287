import styled from "styled-components";

export const CalculatorDiv = styled.div`
    display: grid;
    grid-gap: 1px;
    grid-template-columns: 40% 40% 14%;
    grid-template-areas:
        'purchasePriceInput monthlyRentalInput submitButton';
`; 

/* export const CalculatorDiv = styled.div`
    display: flex;
`; */

export const InputPurchasePriceDiv = styled.div`
    grid-area: purchasePriceInput;
    padding-right: 2px;
    padding-top: 0px;
    text-align: left;
    input {
        text-transform: uppercase;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 32px;
        box-sizing: border-box;
        text-align: left;
        font-weight: normal;
        height: 35px;
        font-size: 1rem;
    }
`;

export const InputMonthlyRentalDiv = styled.div`
    grid-area: monthlyRentalInput;
    padding-right: 2px;
    padding-top: 0px;
    text-align: left;
    input {
        text-transform: uppercase;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 32px;
        box-sizing: border-box;
        text-align: left;
        font-weight: normal;
        height: 35px;
        font-size: 1rem;
    }
`;

export const ButtonDiv = styled.div`
    grid-area: submitButton;  
    //padding-bottom: 6px;
    //width: 30%; 
    height: 35px;
    button{
        margin-top: 0px;
        margin-bottom: 0px;
    }
`;

export const Label = styled.label`
    line-height: 0;
    font-size: 12px; 
    font-weight: bold;
`;
