import React, { useState } from 'react'
import { Button } from 'reactstrap'

import { ErrorMessage, Form, Formik } from 'formik'
import RotatingLoader from '../../atoms/RotatingLoader'
import SuccessTick from '../../atoms/SuccessTick'
import { createPasswordResetToken } from '../../../services/CredentialsService'
import { RequestPasswordResetSuccess } from '../../../configuration/account/login.config'

import styles from './ForgottenPasswordForm.module.scss'
import { ForgottenPasswordSchema } from '../../../YupValidationSchemas'
import { withRouter } from 'react-router'

const ForgottenPasswordForm = ({ history }: { history: any }) => {
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const onResetPassword = async (email: string): Promise<void> => {
        setLoading(true)

        const { status } = await createPasswordResetToken(email)
        if (status === 200) {
            setSuccess(true)
        }

        setLoading(false)
    }

    return (
        <>
            <h1 className={styles.formHeading}>Forgotten Password</h1>
            <>
                {success ? (
                    <div>
                        <SuccessTick />
                        <p className={styles.formSubHeading}>
                            {RequestPasswordResetSuccess}{' '}
                            <a href="mailto:RCSupport@rentchief.co.uk">
                                RCSupport@rentchief.co.uk
                            </a>
                        </p>
                    </div>
                ) : loading ? (
                    <div>
                        <RotatingLoader loading={true} text="Loading..." />
                    </div>
                ) : (
                    <React.Fragment>
                        <h2 className={styles.formSubHeading}>
                            Enter the email you use to login and we'll send you
                            a link to get you back into your account
                        </h2>

                        <Formik
                            initialValues={{
                                email: null,
                            }}
                            enableReinitialize={true}
                            validationSchema={ForgottenPasswordSchema}
                            onSubmit={(values: { email: string }) =>
                                onResetPassword(values.email)
                            }
                        >
                            {(props) => (
                                <Form className={styles.form}>
                                    <div className={styles.group}>
                                        <label className={styles.label}>
                                            Email
                                        </label>

                                        <input
                                            className={styles.input}
                                            name="email"
                                            value={props.values.email}
                                            placeholder="Email"
                                            onChange={(e) =>
                                                props.setFieldValue(
                                                    'email',
                                                    e.currentTarget.value
                                                )
                                            }
                                        />

                                        <div className={styles.errorMessage}>
                                            <ErrorMessage
                                                className={styles.errorMessage}
                                                name="email"
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.buttonContainer}>
                                        <Button
                                            onClick={() => props.submitForm()}
                                            className={styles.button}
                                            type="submit"
                                        >
                                            Send link
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </React.Fragment>
                )}

                <p
                    className={styles.links}
                    onClick={() => history.push('/account/login')}
                >
                    Back to login
                </p>
            </>
        </>
    )
}

export default withRouter(ForgottenPasswordForm)
