import React, { useState, useEffect, useRef, createContext } from 'react';
import ImageGalleryComponent from '../../../../atoms/ImageGallery'
import UploadPropertyImage from '../../../../atoms/UploadPropertyImage'
import SFUploaderModal from '../../../../atoms/SFUploaderModal'
import EditStreetViewModal from '../../../../atoms/EditStreetViewModal'
import { imageService } from '../../../../../configuration/domains'
import { changePrimaryImage, getImages } from '../../../../../services/ImageService'
import { updatePropertyImages } from '../../../../../helpers/propertyHelpers'
import { processToast, updateToastMessage } from '../../../../../helpers/toastHelper'
import { IImageDto, ImageTypeEnum } from '../../../../../types/ImageService'
import { IAddress } from '../../../../../types/PropertyService/IPropertyService'
import { IDataResponse } from '../../../../../types/ApiService'
import { delayAction } from '../../../../../helpers/timerHelpers'
import SectionHeader from "../SectionHeader/SectionHeader.component"
import * as S from "./ActiveStreetViewSection.styles";

interface sectionProps {
    id: string;
    header: string;
    hideOnClick(id: string): void;
    propertyId: string;
    address: IAddress;
    images: Array<IImageDto>
    onImageChanged?(height: number, width: number): void;
}

const ActiveStreetViewSection = ({id, header, hideOnClick, propertyId, address, images, onImageChanged }: sectionProps) => {
    const [uploadImages, setUploadImages] = useState(false);
    const [uploadImageModalOpen, setUploadImageModalOpen] = useState(false);
    const [propertyImageUploadSuccess, setPropertyImageUploadSuccess] = useState(false);
    const [isEditingStreetView, setIsEditingStreetView] = useState(false);
    const [newImage, setNewImage] = useState(null);

    const onPropertyImageUploadSuccess = (img: string): void => {
        setNewImage(img);
        setUploadImageModalOpen(false);
        setPropertyImageUploadSuccess(true);

        delayAction(2500, () => setPropertyImageUploadSuccess(false));
    }

    const onUploadCallback = (uri: string): void => {
        if (uri) {
            if (isEditingStreetView) {
                setIsEditingStreetView(false);
            }

            refreshImages()
        }
    }

    const refreshImages = async(focusOnLatest?: boolean) => {
        let resp = await getImages({
            propertyId: propertyId,
            imageType: [ImageTypeEnum.PROPERTY_IMAGE],
        })
        
        if (resp && resp.status == 200) {
            updatePropertyImages(resp.data)
        }
    }

    const onChangePrimaryImage = async(imageId: string) => {
        let resp = await changePrimaryImage({
            imageId,
            propertyId: propertyId,
            imageType: ImageTypeEnum.PROPERTY_IMAGE,
        })
        
        if (resp && resp.status == 200) {
            processToast(resp.data)
        }
    }

    return (
        <div>
            <SFUploaderModal
                open={uploadImages}
                onToggle={(open: boolean) => setUploadImages(open)}
                urls={{
                    saveUrl: `${imageService}/Save`,
                    removeUrl: `${imageService}/Remove`,
                }}
                onFileUpload={(imageIds?: string[]) =>
                    updateToastMessage(
                        'Property image has been uploaded successfully',
                        'success'
                    )
                }
                successCallback={() => {
                    refreshImages()
                }}
                options={{
                    propertyId: propertyId,
                    imageType: ImageTypeEnum.PROPERTY_IMAGE,
                }}
            />

            <UploadPropertyImage
                onSuccess={onPropertyImageUploadSuccess}
                propertyId={propertyId}
                open={uploadImageModalOpen}
                onClose={() => setUploadImageModalOpen(false)}
                onUploadCallback={onUploadCallback}
            />

            <EditStreetViewModal
                onUploadSuccessCallback={onUploadCallback}
                open={isEditingStreetView}
                onCloseCallback={() => setIsEditingStreetView(false)}
                propertyId={propertyId}
                address={address}
            />

            <SectionHeader id={id} header={header} hideOnClick={hideOnClick}/>
            <S.SectionDiv>
                <ImageGalleryComponent
                    onClickFullscreen
                    dropdownActions={{
                        onClickUpload: () => setUploadImages(true),
                        onClickDelete: () => refreshImages(),
                        onChangePrimaryImage:(imageId: string) => onChangePrimaryImage(imageId),
                        onChangeStreetView: () => setIsEditingStreetView(true),
                    }}
                    items={images}
                    onImageChanged={onImageChanged}
                />
            </S.SectionDiv>
        </div>
    )
}

export default ActiveStreetViewSection;