import React, { useEffect, useState } from "react";
import { FormGroup } from "reactstrap";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Tooltip } from "react-tippy";
import govImage from "../../../../images/gov.png";
import regIcon from "../../../../images/icons/icon_reg.svg";
import { IRegulation } from "../../../../reducers/propertyReducer";
import { smokeDetectorsTooltip, carbonMonoxideTooltip, legionellaAssessmentTooltip } from '../_config/tooltipConfig';
import styles from "./RegulationChecks.module.scss";
import * as S from './RegulationChecks.styles'

interface IProps {
    regData?: IRegulation;
    onRegsChanged?(regulation: IRegulation): void;
  }

const RegulationChecks = ({regData, onRegsChanged}: IProps) => {
    const [regulation, setRegulation] = useState<IRegulation>(regData);

    useEffect(() => {
        setRegulation(regData);
      }, [regData]);

    const onCheckChangeRegulation = (field: string): void => {
        let newRegs: IRegulation = {
            ...regulation, 
                [field]: !regulation[field]
        };

        setRegulation(newRegs);
        onRegsChanged(newRegs);
      }

    return (
        <S.ContainerDiv>
            <S.InnerContainerDiv>
                <S.CheckContainerDiv>
                    <div className={`${styles.column} ${styles.checkboxHeight}`}>
                        <FormGroup className={styles.flexColumn}>
                            <div className={styles.smallText}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked={regulation && regulation.smokeDetectors}
                                            color="primary"
                                            onChange={() => onCheckChangeRegulation("smokeDetectors")}
                                            value="Smoke Detectors"
                                            className={regulation && regulation.smokeDetectors ? styles.materialCheckbox : null}
                                        />
                                        }
                                        label="Smoke Detectors"
                                    />
                                </FormGroup>
                                <Tooltip
                                    theme="light"
                                    html={smokeDetectorsTooltip}
                                    position="right"
                                    trigger="mouseenter"
                                    arrow
                                    interactive={true}
                                    >
                                    <img className={styles.infoIcon} src={regIcon} alt="Info" />
                                </Tooltip>
                            </div>
                            <div className={styles.regulationSmallText}>
                                Installed on each floor
                            </div>
                        </FormGroup>
                    </div>
                </S.CheckContainerDiv>
                <S.CheckContainerDiv>
                    <div className={`${styles.column} ${styles.checkboxHeight}`}>
                        <FormGroup className={styles.flexColumn}>
                            <div className={styles.smallText}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked={regulation && regulation.carbonMonoxide}
                                            color="primary"
                                            onChange={() => onCheckChangeRegulation("carbonMonoxide")}
                                            value="Carbon Monoxide Detectors"
                                            className={regulation && regulation.carbonMonoxide ? styles.materialCheckbox : null}
                                        />
                                        }
                                        label="Carbon Monoxide Detectors"
                                    />
                                </FormGroup>
                                <Tooltip
                                    theme="light"
                                    html={carbonMonoxideTooltip}
                                    position="right"
                                    trigger="mouseenter"
                                    arrow
                                    interactive={true}
                                    >
                                    <img className={styles.infoIcon} src={regIcon} alt="Info" />
                                </Tooltip>
                            </div>
                            <div className={styles.regulationSmallText}>
                                Installed in each room required
                            </div>
                        </FormGroup>
                    </div>
                </S.CheckContainerDiv>
                <S.CheckContainerDiv>
                    <div className={`${styles.column} ${styles.checkboxHeight}`}>
                        <FormGroup className={styles.flexColumn}>
                            <div className={styles.smallText}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked={regulation && regulation.legionellaAssessment}
                                            color="primary"
                                            onChange={() => onCheckChangeRegulation("legionellaAssessment")}
                                            value="Legionella Assessment"
                                            className={regulation && regulation.legionellaAssessment ? styles.materialCheckbox : null}
                                        />
                                        }
                                        label="Legionella Assessment"
                                    />
                                </FormGroup>
                                <Tooltip
                                    theme="light"
                                    html={legionellaAssessmentTooltip}
                                    position="right"
                                    trigger="mouseenter"
                                    arrow
                                    interactive={true}
                                    >
                                    <img className={styles.infoIcon} src={regIcon} alt="Info" />
                                </Tooltip>
                            </div>
                            <div className={styles.regulationSmallText}>
                                Conducted and no risk identified
                            </div>
                        </FormGroup>
                    </div>
                </S.CheckContainerDiv>
            </S.InnerContainerDiv>
        </S.ContainerDiv>
    );
}

export default RegulationChecks;