import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// Import Styles
import styles from "./ContactChatsPage.module.scss";
import { IRaiseIssueOptions } from "../../../../atoms/AddEditIssue";
import { addURLParameter } from "../../../../../helpers/locationHelpers";
import IssueListPage from "../../../IssueListPage";
import ChatListPage from "../../../ChatListPage";

interface IWithRouterProps {
  history: any;
  location: any;
  match: any;
}

interface IProps {
  contactId: string;
  contactName: string;
}

type Props = IProps & IWithRouterProps;

interface IState {

}

class ContactChatsPage extends Component<Props, IState> {
  constructor(props: Props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount(): void {

  }

  render() {
    return (
        <ChatListPage options={{ contactId: this.props.match.params.contactId }} />
    );
  }
}

export default withRouter(ContactChatsPage);