import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Row } from 'reactstrap'
import Col from 'reactstrap/lib/Col'
import { Dispatch } from 'redux'
import { toggleProfileSidebar } from '../../../actions/appActions'
import { logoutUser } from '../../../helpers/authHelpers'
import callIcon from '../../../images/icons/ic_call_24px.png'
import homeIcon from '../../../images/icons/ic_home_dark_24px.png'
import mailIcon from '../../../images/icons/ic_mail_outline_24px.png'
import smsIcon from '../../../images/icons/ic_stay_primary_portrait_24px.png'
import { getContactPreferences } from '../../../services/ContactPreferencesService'
import { IReactRouterProps } from '../../../types'
import { IContactPreference } from '../../../types/ContactPreferencesService'
import CloseButton from '../../atoms/CloseButton'
import FormattedAddress from '../../atoms/FormattedAddress'
import ProfileImage from '../../atoms/ProfileImage'
import Buttons from '../Buttons'
import styles from './ProfileSidePanelBody.module.scss'

interface IProps {
    user: any
    clientName: string
    toggleProfileSidebar(toggle: boolean): void
}

type Props = IReactRouterProps & IProps

class ProfileSidePanelBody extends React.Component<
    Props,
    { contactPreferences: IContactPreference }
> {
    constructor(props: any) {
        super(props)

        this.state = {
            contactPreferences: {
                email: false,
                phone: false,
                sms: false,
                post: false,
            },
        }
    }

    getContactPreferences = async () => {
        const { data } = await getContactPreferences()
        if (!data) {
            return
        }

        this.setState({
            contactPreferences: {
                email: data.email,
                sms: data.sms,
                post: data.post,
                phone: data.phone,
            },
        })
    }

    onClickEditProfile = () => {
        this.props.toggleProfileSidebar(false)
        this.props.history.push('/dashboard/profile')
    }

    componentWillMount() {
        this.getContactPreferences()
    }

    signOut = () => {
        this.props.toggleProfileSidebar(false)
        logoutUser()
    }

    render() {
        return (
            <div className={styles.body}>
                <div className={styles.headingSection}>
                    <div className={styles.close}>
                        <CloseButton
                            close={() => this.props.toggleProfileSidebar(false)}
                        />
                    </div>
                    <ProfileImage
                        forename={this.props.user.forename}
                        surname={this.props.user.surname}
                        image={this.props.user.profileImage}
                        large={true}
                    />
                    <div className={styles.name}>
                        {this.props.user.forename
                            ? this.props.user.forename
                            : 'No forename set'}
                        &nbsp;
                        {this.props.user.surname
                            ? this.props.user.surname
                            : 'No surname set'}
                    </div>
                    <div className={styles.role}>
                        {this.props.user.role
                            ? this.props.user.role
                            : 'No role set'}{' '}
                    </div>
                    <div className={styles.role}>
                        {this.props.clientName
                            ? this.props.clientName
                            : 'Individual'}{' '}
                    </div>
                </div>

                <div className={styles.divider} />

                <div className={styles.editButton}>
                    <Buttons
                        align={'center'}
                        buttons={[
                            {
                                text: 'Edit Profile',
                                displayType: 'submit',
                                elementType: 'button',
                                onClick: () => this.onClickEditProfile(),
                            },
                        ]}
                    />
                </div>

                <div className={styles.details}>
                    <div className={styles.detailSection}>
                        <div className={styles.label}>Role</div>
                        <div className={styles.body}>
                            {this.props.user.role
                                ? this.props.user.role
                                : 'No role set'}
                        </div>
                    </div>

                    <div className={styles.detailSection}>
                        <div className={styles.label}>Email</div>
                        <div className={styles.body}>
                            {this.props.user.email
                                ? this.props.user.email
                                : 'No email set'}
                        </div>
                    </div>

                    <div className={styles.detailSection}>
                        <div className={styles.label}>Contact Number</div>
                        <div className={styles.body}>
                            {this.props.user.contactNumber
                                ? this.props.user.contactNumber
                                : 'No contact number set'}
                        </div>
                    </div>

                    <div className={styles.detailSection}>
                        <div className={styles.label}>Address</div>
                        <div className={styles.body}>
                            <FormattedAddress
                                showDefaultText
                                isUserAddress
                                inline={false}
                                address={this.props.user.address}
                            />
                        </div>
                    </div>

                    <div className={styles.detailSection}>
                        <div className={styles.label}>
                            Marketing Preferences
                        </div>
                        <div className={styles.body}>
                            <Row>
                                <Col xs="1">
                                    <img src={mailIcon} />{' '}
                                </Col>
                                <Col xs="3">Email</Col>
                                <Col className={styles.opt}>
                                    {this.props.user.contactPreferences.email
                                        ? 'Opted in'
                                        : 'Opted out'}
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="1">
                                    <img src={callIcon} />{' '}
                                </Col>
                                <Col xs="3">Phone</Col>
                                <Col className={styles.opt}>
                                    {this.props.user.contactPreferences.phone
                                        ? 'Opted in'
                                        : 'Opted out'}
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="1">
                                    <img src={smsIcon} />{' '}
                                </Col>
                                <Col xs="3">SMS</Col>
                                <Col className={styles.opt}>
                                    {this.props.user.contactPreferences.sms
                                        ? 'Opted in'
                                        : 'Opted out'}
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="1">
                                    <img src={homeIcon} />{' '}
                                </Col>
                                <Col xs="3">Post</Col>
                                <Col className={styles.opt}>
                                    {this.props.user.contactPreferences.post
                                        ? 'Opted in'
                                        : 'Opted out'}
                                </Col>
                            </Row>

                            <p className={styles.detailInfo}>
                                Edit profile to amend your preferences.
                            </p>

                            <div className={styles.signOutButton}>
                                <Buttons
                                    align={'center'}
                                    buttons={[
                                        {
                                            text: 'Sign out',
                                            displayType: 'submit',
                                            elementType: 'button',
                                            onClick: () => this.signOut(),
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    user: state.user,
    clientName: state.client.name,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toggleProfileSidebar: (show: boolean) => {
        dispatch(toggleProfileSidebar(show))
    },
})

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProfileSidePanelBody) as any
)
