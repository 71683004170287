import { useEffect, useState } from 'react'
import { parseHtmlToAddButton } from '@rentchief/components';

const useStaticHtmlString = (htmlString, buttonId, buttonText, buttonClick) => {
    const [html, setHtml] = useState(null); 

    useEffect(() => {
        updateHtml();
      }, []);

    useEffect(() => {
        window.addEventListener("resize", updateHtml);
        return () => window.removeEventListener("resize", updateHtml);
    });

    const updateHtml = async() => {
        let html = parseHtmlToAddButton(htmlString, buttonId, buttonText, buttonClick);
        setHtml(html);
    };

    return (
        html
    )

}

export default useStaticHtmlString;