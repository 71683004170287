import { store } from '../store';

export function isLandlord() {
    let state = store.getState();
    if (state.user.permissions.indexOf('IsLandlord') > -1 || state.user.permissions.indexOf('IsPlatformAdmin') > -1) {
        return true;
    }
    return false;
}

export function isTenant() {
    let state = store.getState();
    if (state.user.permissions.indexOf('IsTenant') > -1) {
        return true;
    }
    return false;
}

export function isTradesPerson() {
    let state = store.getState();
    if (state.user.permissions.indexOf('IsTradesPerson') > -1) {
        return true;
    }
    return false;
}

export function isClientAdmin() {
    let state = store.getState();
    if (state.user.permissions.indexOf('IsClientAdmin') > -1) {
        return true;
    }
    return false;
}

export function isPlatformAdmin() {
    let state = store.getState();
    if (state.user.permissions.indexOf('IsPlatformAdmin') > -1) {
        return true;
    }
    return false;
}

export function getRole() {
    let state = store.getState();
    return state.user.role;
}