import React, { useEffect, useState } from "react";
import styles from "./TenancyCardNav.module.scss";
import { withRouter } from "react-router";
import SFTenancyDropdown from "../SFDropdown/SFTenancyDropdown";
import Breadcrumbs from "../Breadcrumbs";
import { canAccessPropertyDetails } from "../../../helpers/permissionHelpers";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";

interface IProps {
  propertyId?: string;
  propertyName?: string;
  contactId?: string;
  tenancyId?: string;
  tenantName?: string;

  unitId?: string;
  unitName?: string;

  isAddingTenant?: boolean;
}

interface IWithRouterProps {
  history: any;
  location: any;
  match: any;
}

type Props = IProps & IWithRouterProps;

const TenancyCardNav = (props: IProps) => {

  // Redux 
  const { property_data } = useSelector((state: RootState) => state.property.property_data);
  const { viewing_unit } = useSelector((state: RootState) => state.unit);

  const [curPropName, setCurPropName] = useState<string>(null);
  const [curPropId, setCurPropId] = useState<string>(null);
  const [curUnitName, setCurUnitName] = useState<string>(null);
  const [curUnitId, setCurUnitId] = useState<string>(null);

  useEffect(() => {
    if(props.propertyId || props.unitId){
      setCurUnitId(props.unitId);
      setCurUnitName(props.unitName);
      setCurPropName(props.propertyName);
      setCurPropId(props.propertyId);
    }
    else if(property_data && property_data.propertyId){
      setCurPropName(property_data.propertyName);
      setCurPropId(property_data.propertyId);
    } else if(viewing_unit){
      setCurUnitId(viewing_unit.unitId);
      setCurUnitName(viewing_unit.unitName);
      setCurPropName(viewing_unit.propertyName);
      setCurPropId(viewing_unit.propertyId);
    }
  }, [props])


    return (
      <div className={styles.userCardNav}>
        <div className={styles.name}>
          <Breadcrumbs
              breadcrumbs={[
                  ...(curPropName
                      ? [
                            {
                                name: curPropName,
                                route: canAccessPropertyDetails() ? `/dashboard/property/${curPropId}` : '',
                                ordinal: 0,
                            },
                        ]
                      : []),
                  ...(curUnitName
                    ? [
                      {
                        name: curUnitName ? curUnitName : 'No unit name',
                        route: curUnitId ? `/dashboard/unit/view/${curUnitId}` : '/dashboard/',
                        ordinal: 1
                      }] : []),
                  {
                      name: 'TENANCIES',
                      route: curPropName
                          ? canAccessPropertyDetails() ? `/dashboard/property/${curPropId}/tenancy/tenancies` : ''
                          : '/dashboard/tenancies',
                      ordinal: 2,
                  },
                  {
                      name: props.tenantName ? props.tenantName : 'Add Tenancy',
                      //route: this.props.tenantName ? `/dashboard/tenancy/${this.props.tenancyId}` : null,
                      ordinal: 3,
                  }
              ]}
          />
        </div>

        <div className={styles.actionButton}>
          <SFTenancyDropdown
            unitId={curUnitId}
            unitName={curUnitName}
            propertyId={curPropId}
            propertyName={curPropName}
            contactId={props.contactId}
            tenancyId={props.tenancyId}
            tenantName={props.tenantName}
          />
        </div>
      </div>
    );

}

export default TenancyCardNav;