import React from "react";
import styles from "./HorizontalEntitySelector.module.scss";
import { IOptions } from "../SelectDropdown";
import { Scrollbars } from 'react-custom-scrollbars';

interface IProps {
  options: Array<IOptions>;
  showAllOption?: boolean;
  showCount?: boolean;
  selectedOption: string;
  onSelect(selected: IOptions): void;
}

class HorizontalEntitySelector extends React.Component<IProps> {
  constructor(props: any) {
    super(props);

    this.onClickOptionInternal = this.onClickOptionInternal.bind(this);
    this.getTotalCount = this.getTotalCount.bind(this);
  }

  onClickOptionInternal(selected: IOptions): void {
    this.props.onSelect(selected);
  }

  componentDidMount(): void {

  }

  getTotalCount(): number {
    let total = 0;
    this.props.options.forEach(x => {
      total += x.countValue
    });
    return total;
  }

  render() {
    if (!this.props.options || this.props.options.length === 0) {
      return null;
    }

    const { options } = this.props;

    return (
      <div className={styles.horizontalEntitySelector}>
        <ul>
          {
            this.props.showAllOption &&
            <li className={`${!this.props.selectedOption ? styles.active : ''}`} onClick={() => this.onClickOptionInternal({
              label: 'All',
              value: ''
            })}>All
            {
                this.props.showCount &&
                <span className={styles.count}>{this.getTotalCount()}</span>
              }
            </li>
          }
          {
            options.map((i, index) => {
              return <li key={index} className={`${this.props.selectedOption != null && this.props.selectedOption.toLocaleLowerCase() === i.value.toLocaleLowerCase() ? styles.active : ''}`} onClick={() => this.onClickOptionInternal(i)}>{i.label}
                {
                  this.props.showCount &&
                  <span className={styles.count}>{i.countValue}</span>
                }
              </li>
            })
          }
        </ul>
      </div>
    );
  }
}

export default HorizontalEntitySelector;