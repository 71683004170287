import {
  ApiContext,
  CalculationService,
  AddressService,
  LandRegistryService,
  IResearchConfig,
  IResearchContextData,
  IROICalculatorData,
  ResearchContext,
  ResearchSelectedProperty,
  ROICalculator
} from "@rentchief/components";
import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useSelector } from 'react-redux';
import "react-toastify/dist/ReactToastify.css";
import { Col } from "reactstrap";
import Row from "reactstrap/lib/Row";
import TabbedNavigationContainer from '../../atoms/TabbedNavigationContainer'; 
import NestedSimpleRouterTabs from '../../atoms/NestedSimpleRouterTabs';
import PropertyResearchDashboard from "../../molecules/DashboardHome/PropertyResearchDashboard/PropertyResearchDashboard.component";
import { getJwt } from "../../../helpers/authHelpers";
import { formatAddress } from "../../../helpers/formattingHelpers";
import { RootState } from "../../../reducers/rootReducer";
import { IAddress } from "../../../types/PropertyService/IPropertyService";
import styles from "./ResearchPage.module.scss";

const ResearchPage = ({ address }: { address: IAddress }) => {
  const match = useRouteMatch();
  const propertyId = (match.params as any).propertyId;

  const { calculation_service } = useContext(ApiContext);
  const calculationService = new CalculationService(calculation_service, {
    bearer_token: getJwt(),
  });
  const { address_service } = useContext(ApiContext);
  const addressService = new AddressService(address_service, {
    bearer_token: getJwt(),
  });
  const { land_registry_service } = useContext(ApiContext);
  const landRegistryService = new LandRegistryService(land_registry_service, {
    bearer_token: getJwt(),
  });
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState<IResearchContextData>({
    address: address ? address : null,
    formattedAddress: address ? formatAddress(address) : null,
  });
  const [calculatorData, setCalculatorData] =
    useState<IROICalculatorData>(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState({
    dateFrom: "01-01-1900",
    dateTo: "01-01-2021",
    selected: "All years",
  });

  const config: IResearchConfig = {
    showRentChiefLink: false,
    clientCodeName: "rentchief",
    calculatorTabsList: [
      {
        name: "Buy to let",
        tab: "buy-to-let",
      },
      {
        name: "Renovate to let",
        tab: "renovate-to-let",
      },
      ,
      {
        name: "Renovate to sell",
        tab: "renovate-to-sell",
      },
    ],
    calculatorTabSummary: {
      buyToLetText: "",
      renovateToLetText: "This scenario helps model returns for a rental investment where there is renovation required. It will help you analyse your potential returns based on development size, timescale to complete and the estimated increase in value that the renovation brings to both the value of the property and increased rent.",
      renovateToSellText: "This scenario helps model returns for an investment where there is renovation required and you wish to sell the property at the end of the renovation period for a profit. You need to ensure that you buy at the right price, in the right place and keep any refurbishments or maintenance within your budget."
    }
  };

  return (
    <Row>
      <Col className={styles.routedPage}>
        <ResearchContext.Provider
          value={{
            data,
            calculatorData,
            setData,
            setCalculatorData,
            filtering: {
              selected_date_filter: selectedDateFilter,
              setDateFilter: setSelectedDateFilter,
            },
            services: {
              calculationService,
              addressService,
              landRegistryService
            },
            config,
            routes: {
              researchProperty: `/dashboard/property/${
                (match.params as { propertyId: string }).propertyId
              }/research`,
              research: null,
              dealCalculator: `/dashboard/property/${
                (match.params as { propertyId: string }).propertyId
              }/research/deal-calculator`,
            },
          }}
        >
          <div>
            <div>
                <TabbedNavigationContainer fullWidth isSubMenu noPadding>
                    <Col>
                        <NestedSimpleRouterTabs
                            routes={[
                                {
                                    path:`${match.url}/summary`,
                                    text: 'Summary',
                                },
                                {
                                    path:`${match.url}/areaResearch`,
                                    text: 'Area Research',
                                },
                                {
                                    path: `${match.url}/deal-calculator`,
                                    text: 'Deal Calculator',
                                },
                            ]}
                        />
                    </Col>
                </TabbedNavigationContainer>
            </div>

            <div>
              <div>
                <Switch>
                  <Route
                    exact
                    path={`${match.url}/areaResearch`}
                    render={(props) => 
                      <div className={styles.researchAddress}>
                        <ResearchSelectedProperty propertyId={propertyId} hideNav />
                      </div>
                    }
                  />

                  <Route
                    path={`${match.path}/deal-calculator`}
                    render={(props) => 
                      <div className={styles.content}>
                        <ROICalculator propertyId={propertyId} />
                      </div>
                    }
                  />

                <Route
                    path={`${match.path}/summary`}
                    render={() => 
                      <div className={styles.nestedPropertyDashboard}>
                        <PropertyResearchDashboard />
                      </div>
                    }
                  />
                </Switch>
              </div>
            </div>
          </div>
        </ResearchContext.Provider>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: RootState) => ({
  address: state.property.property_data.property_data.address,
});

export default connect(mapStateToProps)(ResearchPage);
