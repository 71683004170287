import React from "react";
import { Row, Col } from "reactstrap";

import styles from "./ComingSoon.module.scss";

class ComingSoon extends React.Component {
  render() {
    return (
      <div className={styles.page}>
        <Row>
          <Col>We're working on this feature and it'll be coming soon!</Col>
        </Row>
      </div>
    );
  }
}

export default ComingSoon;
