import React from "react";
import styles from "./AddButton.module.scss";
import addIcon from "../../../images/icons/addbutton.svg";

interface IProps {
  text?: string;
  small?: boolean;
  color?: string;
  showIcon?: boolean;
  showTextOnMobile?: boolean;
  onAdd(): void;
}

class AddButton extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <button
        className={
          this.props.small ? styles.addPropertySmall : styles.addProperty
        }
        onClick={this.props.onAdd}
        style={{ backgroundColor: this.props.color ? this.props.color : "" }}
      >
        {this.props.text ? (
          <div>
            <span className={styles.addPropertyIcon}>
              <img src={addIcon} />
            </span>

            <span
              className={
                this.props.showTextOnMobile
                  ? styles.addPropertyTextFixed
                  : styles.addPropertyText
              }
            >
              {this.props.text}
            </span>
          </div>
        ) : (
          <span className={styles.addPropertyIcon}>
            <img src={addIcon} />
          </span>
        )}
      </button>
    );
  }
}

export default AddButton;
