// Import React
import React, { Component } from "react";

import styles from "./PropertyDocumentPage.module.scss";
import { IDocument } from "../../../../../types/DocumentService";
import { getDocument } from "../../../../../services/DocumentService";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import DateTimeFormat from "../../../../atoms/DateTimeFormat";
import backIcon from '../../../../../images/icons/ic_arrow_back_24px.png';

interface IProps {
  match: any;
  history: any;
  location: any;
}

interface IState {
  document: IDocument;
}

class PropertyDocumentPage extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      document: null
    };
  }

  componentDidMount(): void {
    let documentId = this.props.match.params.documentId;

    getDocument(documentId).then(resp => {
      if (resp.status == 200) {
        this.setState({
          document: resp.data
        });
      }
    });
  }

  render() {

    if (!this.state.document) {
      return (<div>Loading document...</div>)
    }

    return (
      <div className={styles.page}>
        <div className={styles.heading}>

          <Row>
            <Col><p onClick={() => this.props.history.push('../../documents')} className={styles.goBack}> <img className={styles.backIcon} src={backIcon} alt='Go back' /> Go back to Documents list</p>
              <hr />
            </Col>
          </Row>

          <Row>
            <Col xs="4">
              <span className={styles.label}>Document Name</span>
              <span className={styles.name}>{this.state.document.documentName}</span>
            </Col>

            <Col xs="2">
              <span className={styles.label}>Uploaded On</span>
              <span className={styles.name}><DateTimeFormat rawDateTime={this.state.document.uploadedOn} showOnlyDateFormat /></span>
            </Col>

            <Col>
              <span className={styles.label}>Type</span>
              <span className={styles.name}>EPC</span>
            </Col>
          </Row>
        </div>

        <iframe width={'100%'} height={'50rem'} src={this.state.document.uri} />
      </div>
    );
  }
}

export default PropertyDocumentPage;