import { apiService } from "./ApiService";
import * as domains from "../configuration/domains";
import { createBearerToken } from "../helpers/authHelpers";
import { IUnitItem } from "../types/UnitService";
import { IDeleteRequest } from "../components/atoms/DeletePropertyModal";
import { AutoGenerateUnitRequest } from "../types/PropertyService/IPropertyService";

const toQueryStringArray = (arr: string[]): string => {
  if (!arr || arr.length === 0) {
    return "";
  }

  let query = "";

  arr.map((a) => {
    if (query == "") {
      query += "?unitIds=" + a;
    } else {
      query += "&unitIds=" + a;
    }
  });

  return query;
};

export const getUnitMetadataForPropertyId = (propertyId: string): Promise<any> => {
  return apiService
    .get(`${domains.unitService}/metadata/${propertyId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const getImages = (unitId: string): Promise<any> => {
  return apiService
    .get(`${domains.unitService}/images/${unitId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const multiAddUnit = (request: AutoGenerateUnitRequest): Promise<any> => {
  return apiService
    .post(`${domains.unitService}/multi-add-unit`, request, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const deleteUnit = (unitIds: Array<string>, options: IDeleteRequest): Promise<any> => {
  var unitsStringed = toQueryStringArray(unitIds);

  return apiService
    .post(`${domains.unitService}/delete${unitsStringed}`, options, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const doesPropertyHaveUnits = (propertyId: string): Promise<any> => {
  return apiService
    .get(`${domains.unitService}/doesPropertyHaveUnits/${propertyId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const addUnit = (unit: IUnitItem): Promise<any> => {
  return apiService
    .post(`${domains.unitService}`, unit, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const updateUnit = (unitId: string, unit: IUnitItem): Promise<any> => {
  return apiService
    .put(`${domains.unitService}/${unitId}`, unit, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const getUnitByUnitId = (unitId: string): Promise<any> => {
  return apiService
    .get(`${domains.unitService}/${unitId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const getUnitTypesDropdown = (subType: string, isResidential: boolean, isCommercial: boolean): Promise<any> => {
  return apiService
    .get(`${domains.unitService}/types?subType=${subType}&isResidential=${isResidential}&isCommercial=${isCommercial}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const getUnitRentalStatusDropdown = (): Promise<any> => {
  return apiService
    .get(`${domains.unitService}/status`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const getPropertyConfigurationDefaults = (propertyType: string, subType: string): Promise<any> => {
  return apiService
    .get(`${domains.unitService}/get-defaults/?propertyType=${propertyType}&subType=${subType}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};