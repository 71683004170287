import React, { Component } from "react";
import { withRouter, Route, Redirect } from "react-router-dom";

// Import Styles
import styles from "./ContactHistory.module.scss";
import { IUserProfileResponse } from "../../../../../types/ProfileService/IProfile";
import { getAudits } from "../../../../../services/AuditService";
import AuditPage from "../../../PlatformManagementPage/AuditPage";

interface IWithRouterProps {
  history: any;
  location: any;
  match: any;
  userProfile: IUserProfileResponse;
}

interface IState {
  contactId: string;
}

class ContactHistory extends Component<IWithRouterProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      contactId: null
    };
  }

  componentDidMount(): void {
    let contactId = this.props.match.params.contactId;

    if(!contactId){
      return null;
    }

    this.setState({
      contactId
    });
  }

  render() {
    if(!this.state.contactId){
      return null;
    }

    return (
      <div className={styles.page}>
        <AuditPage contactId={this.state.contactId} hideDropdown />
      </div>
    );
  }
}

export default withRouter(ContactHistory);