import styled from 'styled-components';

export const DueDateDiv = styled.div<{ days?: number }>`
    //color: ${p => p.days < 2 ? 'red' : 'black'};
    width: 100px;
    font-size: 16px;
`;

export const DateStatus = styled.div`
    //width: 20%;
    //align-self:center;
    font-size: 12px;
    padding: 0px 0px 0px 0px;
`;

export const OuterTitleDiv = styled.div`
    width: 380px;
    font-size: 16px;

    @media (max-width: 768px) {
        width: 280px;
    }
`;

export const InnerTitleDiv = styled.div`
    padding-bottom: 2px;
`;

export const PropertyDiv = styled.div`
    color: gray;
`;

export const TitleSpan = styled.span`
    font-weight: bold;  
`;

export const HeaderContainer = styled.div`
    background-color: white;
    height: 50px;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    text-align: left;
`;

export const HeaderItem = styled.div`
    display: inline-block;
    white-space: normal;
`;

export const ResetButton = styled.button`
    color: #707070;
    border: 1px solid #e4e4e4;
    background-color: #fff;
    padding: 0.65rem;
    float: left;
    margin-bottom: 0.6rem;
    border-radius: 0;
    font-size: 0.65rem;
    margin: 0.1rem;
    margin-left: 1rem;
    border-radius: 6px 6px 6px 6px;
    min-width: 5rem;
  
    &:hover {
        background-color: ${({ theme }) => theme.calculatorTab.tabHoverColor};
    }
  }
  `;

export const GroupingDiv = styled.div`
    float: left;
    margin-left: 1rem;
`;

export const ContactsDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const ContactWrapper = styled.div`
    //width: 40%;
`;

export const DateAndAmountDiv = styled.div`
    color: black;
`;
