import { IAddress } from "../PropertyService/IPropertyService";
import { IDocumentUpload } from "../../components/molecules/AddDocumentModal";

export interface IAddIssueRequest {
    propertyId?: string;
    contactId?: string;
    unitId?: string;
    inspectionId?: number;
    assetId?: number;
    isTenantRaising: boolean;
    title: string;
    description: string;
    category?: string;
    status?: string;
    document?: IDocumentUpload;
    shareWithLandlord: boolean;
    shareWithTenant: boolean;
    shareWithPropertyManager: boolean;
}

export interface IUpdateIssueRequest {
    propertyId?: string;
    contactId?: string;
    unitId?: string;
    inspectionId?: number;
    title?: string;
    description?: string;
    category?: string;
    status?: string;
    priority?: string;
}

export interface IIssueItem {
    issueId: string;
    title: string;
    description: string;
    created: string;
    status: string;
    statusId: string;
    priority: string;
    priorityId: string;
    category: string;
    categoryId: string;
    propertyId: string;
    propertyName: string;
    unitId?: string;
    unitName: string;
    raisedBy: string;
    raisedById: string;
    createdBy: string;
    dateAdded: string;
    inspectionId?: number;
    address: IAddress;
    contactId?: string;
    contactName: string;
}

export interface IIssueActionMenuOptions {
    issueId: string;
    issueTitle: string;
}

export enum IssueActionType {
    GET_ISSUE = "GET_ISSUE",
    GET_ISSUE_SUCCESS = "GET_ISSUE_SUCCESS",
    GET_ISSUE_ERROR = "GET_ISSUE_ERROR",
    GET_ISSUE_EDIT = "GET_ISSUE_EDIT",
    GET_ISSUE_EDIT_SUCCESS = "GET_ISSUE_EDIT_SUCCESS",
    GET_ISSUE_EDIT_ERROR = "GET_ISSUE_EDIT_ERROR",
    UPDATE_VIEWING_ISSUE = "UPDATE_VIEWING_ISSUE",
    CLEAR_ISSUE_EDIT = "CLEAR_ISSUE_EDIT"
}

export interface IUpdateViewingIssueAction {
    type: IssueActionType.UPDATE_VIEWING_ISSUE,
    payload: IIssueItem;
}

export interface IClearIssueEditAction {
    type: IssueActionType.CLEAR_ISSUE_EDIT
}

export interface IGetIssueAction {
    type: IssueActionType.GET_ISSUE
}

export interface IGetIssueSuccessAction {
    type: IssueActionType.GET_ISSUE_SUCCESS
    payload: IIssueItem
}

export interface IGetIssueErrorAction {
    type: IssueActionType.GET_ISSUE_ERROR
    payload: string;
}

export interface IGetIssueEditAction {
    type: IssueActionType.GET_ISSUE_EDIT
}

export interface IGetIssueEditSuccessAction {
    type: IssueActionType.GET_ISSUE_EDIT_SUCCESS
    payload: IIssueItem
}

export interface IGetIssueEditErrorAction {
    type: IssueActionType.GET_ISSUE_EDIT_ERROR
    payload: string;
}

export type IssueAction =
    IUpdateViewingIssueAction |
    IGetIssueAction |
    IGetIssueSuccessAction |
    IGetIssueErrorAction |
    IGetIssueEditAction |
    IGetIssueEditSuccessAction |
    IGetIssueEditErrorAction |
    IClearIssueEditAction;