import React, { useState } from "react";

export interface IValidationItem {
    name: string;
    message: string;
}

interface IContext {
    validations: IValidationItem[];
    updateValidations(name: string, message: string): void;
    removeValidation(name: string): void;
    validateAll(): Promise<{ isValid: boolean, failed?: string[] }>;
    addValidationFn(name: string, fn: () => {}): void;
    getValidation(name: string, includes?: boolean): IValidationItem;
    getValidations(): IValidationItem[];
}

const ConductInspectionContext = React.createContext<IContext>({
    validations: [],
    updateValidations: (values) => { },
    removeValidation: (name) => { },
    validateAll: () => null,
    addValidationFn: (name: string, fn: () => {}) => { },
    getValidation: (name) => ({} as IValidationItem),
    getValidations: () => ([] as IValidationItem[])
});

export const ConductInspectionProvider = ({ children }) => {
    const [validationsItems, setValidations] = useState<IValidationItem[]>([]);
    const [validationFns, setValidationFns] = useState([]);

    const getValidationItem = (name: string, includes?: boolean): IValidationItem => {
        if (includes) {
            return validationsItems.find(x => x.name.indexOf(name) > -1);
        }

        return validationsItems.find(x => x.name == name);
    }

    const removeValidations = (name: string) => {
        const newArr = validationsItems.filter(x => x.name != name).slice();
        setValidations(newArr);
    }

    const validateAll = () => {
        var errorCount = 0;
        var failed = [];
        var bar = new Promise<void>((resolve) => {
            validationFns.forEach((value, index, array) => {
                const { isError } = value.fn();
                if (isError) {
                    failed = failed.concat([value.name]);
                    errorCount++;
                }
                if (index === array.length - 1) resolve();
            });
        });

        return bar.then(() => {
            return { isValid: errorCount === 0, failed };
        });
    }

    const updateValidations = (name, message) => {
        setValidations(prevState => [...prevState.filter(x => x.name !== name), { name, message }]);
    }

    const updateValidationsFns = (name: string, fn: () => {}): void => {
        setValidationFns(prevState => [...prevState.filter(x => x.name != name), { name, fn }])
    }

    return (
        <ConductInspectionContext.Provider
            value={{
                validations: validationsItems,
                updateValidations,
                validateAll,
                addValidationFn: updateValidationsFns,
                getValidation: getValidationItem,
                getValidations: () => validationsItems,
                removeValidation: removeValidations
            }}
        >
            {children}
        </ConductInspectionContext.Provider>
    );
};

export const ConductInspectionConsumer = ConductInspectionContext.Consumer;

export default ConductInspectionContext;