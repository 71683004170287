import React, { Component } from 'react'
import { Form, Row, Col, FormGroup, Label, Input } from 'reactstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import 'react-input-range/lib/css/index.css'
import Tour from 'reactour'
import { RotateLoader } from 'react-spinners'

import {
    ICalculatorAddPropertyRequest,
    IAddress,
    ISelectedPropertyStatus,
    IActivePropertyStatus,
    IEditPropertyRequest,
    IGetPriceValuationResponse,
    IGetPriceValuationRequest,
    IPropertyDataAddProperty,
} from '../../../../types/PropertyService/IPropertyService'
import SuccessTick from '../../../atoms/SuccessTick'
import RotatingLoader from '../../../atoms/RotatingLoader'
import {
    IMoreInformation,
    ICalculateReturnsData,
    IPropertyContacts,
} from '../../../../reducers/propertyReducer'
import MoreInformationModal from '../../../atoms/MoreInformationModal'

import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { delayAction } from '../../../../helpers/timerHelpers'
import {
    addProperty,
    getPropertyForEdit,
    editProperty,
} from '../../../../services/PropertyService'
import { doesEmailAlreadyExist } from '../../../../services/LoginService'
import { getPricePaidData } from '../../../../services/LandRegistryService'
import AddressFinder from '../../../atoms/AddressFinder'
import { RCCurrencyInput } from '@rentchief/components'
const queryString = require('query-string')
import {
    addURLParameter,
    removeURLParameter,
} from '../../../../helpers/locationHelpers'
import moment from 'moment'
import { rotateLoaderStyling } from '../../../../global/variables/_variables'
import { IData } from '../../../atoms/PropertyListItem'
import { toast } from 'react-toastify'
import { addPropertyFormSteps } from '../../../../configuration/joyride'
import {
    getLocalTourInformation,
    setLocalTourInformation,
} from '../../../../helpers/joyrideHelpers'
import takeTourIcon from '../../../../images/icons/tour_icon.png'
import EstimatedValuationModal from '../../../atoms/EstimatedValuationModal'
import MaterialRadioButtons from '../../../atoms/MaterialRadioButtons'
import infoIcon from '../../../../images/icons/ic_info_24px.png'
import { Tooltip } from 'react-tippy'
import styles from './Property.module.scss'
import lodashObject from 'lodash/fp'
import { toggleAddPropertyFormChanged } from '../../../../helpers/formStateHelpers'
import SearchContactInput from '../../../atoms/SearchContactInput'
import { ISearchResult } from '../../../../types/SearchService/ISearchService'
import { toggleAddContactSidebar } from '../../../../helpers/sidebarHelpers'
import { IAddTenantCallback } from '../../AddContactForm'
import {
    getLoggedInUsersName,
    getLoggedInUsersContactId,
} from '../../../../helpers/profileHelpers'
import { uuid } from 'uuidv4'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion'

import 'react-accessible-accordion/dist/fancy-example.css'
import Buttons from '../../../atoms/Buttons'
import { defaultState } from './Property.config'
import { RootState } from '../../../../reducers/rootReducer'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { updateAddPropertyRequest } from '../../../../actions/propertyActions';

interface IState {
    findAddressResult: any
    enterAddressManually: boolean
    purchasePrice: any
    investmentAmount: any
    yearsOfInvestment: any
    monthlyRentalAmount: any
    postcode: string
    address: IAddress
    email: string
    mortgageAmount: any
    emailIsAlreadyAdded: boolean
    showAssumptionInputs1: boolean
    deposit: any
    submitting: boolean
    loading: boolean
    propertyStatus: string
    propertyStatusName: string
    ActivePropertyStatus: ISelectedPropertyStatus[]
    propertyName: string
    isLandRegistryMatch: boolean
    isLandRegistryPopupOpen: boolean
    moreInformation: IMoreInformation
    propertyMortgageStatus: number
    dateOfPurchase: Date
    editPropertyId: string
    isEditProperty: boolean
    addPropertyTour: boolean
    depositOnStartChange: number
    isDepositChanged: boolean
    priceValuation: boolean
    rentalPriceValuation: boolean

    ltv?: number
    interestRate?: number
    valuationFee?: number
    mortgageFee?: number
    refurbishment?: number
    stampDuty?: number
    lettingAgent?: number
    maintenance?: number
    voidPeriod?: number
    insurance?: number
    capitalGrowthRate?: number
    rentalGrowthRate?: number
    serviceChargesPCM?: number
    groundRentPCM?: number
    solicitorFeeAndSearches?: number  

    investmentAmountPercent?: number
    mortgagePCM?: number
    mortgageType?: string
    mortgageTerm?: number
    lettingAgentPercent?: number
    maintenancePercent?: number
    voidPeriodPercent?: number
    refurbishmentPercent?: number
    otherPurchaseCosts?: number
    otherOperatingCosts?:number
    country?: string
    stampDutyLocale?: string
    isInvestmentProperty?: boolean
    insurancePCM?: number

    // Renovation
   renovationPeriodMonths?: number;
   renovationStartDate?: Date;
   renovationEndDate?: Date;
   propertyValueAfterRenovation?: number;
 
   // Selling
   sellingCost?: number;
   sellingCostPercent?: number;

   // Investor strategy
   investorStrategy?: string;
   clientName? :string;

    // Fields that we get from Property Data API Valuation
    propertyType?: string
    finishQuality?: string
    outdoorSpace?: string
    parking?: string
    size?: number
    sizeType?: string
    numberOfParkingSpaces?: number
    bedrooms?: number
    bathrooms?: number
    yearBuilt?: number

    landlordContactId?: string
    landlordName?: string
    propertyManagerContactId?: string
    propertyManagerName?: string

    propertyData?: IPropertyDataAddProperty

    hasUpdatedAddress?: boolean
    initialPostcode: string
    hasLoaded: boolean

    propertyContacts: IPropertyContacts
}

interface IRouterProps {
    history: any
    location: any
    match: any
}

interface IReduxProps {
    last_added_property_id_guest: string
    last_added_property_id: string
    addPropertyFromCalculator(request: ICalculatorAddPropertyRequest): void
    dispatchUpdateCalculateReturnsFromEdit(data: IUpdateProperty): void
    dispatchUpdatePropertyList(data: any): void
    app_loading: boolean
    is_logged_in: boolean
    add_property: boolean
    data: ICalculateReturnsData
    property_list_data: IData[]
    updateAddPropertyRequest?(request): void;
    add_property_request?: any;
    wrappedComponentRef: any
}

export interface IUpdateProperty {
    propertyId: string
    propertyName: string
    address: IAddress
    propertyStatusName: string
    propertyStatus: string
}

let initialState: IState = defaultState

interface IProps {
    changePageNumber(number): void
    propertyOwnedSelection(number): void
    updateRentAmount(string): void
    updateDepositAmount(string): void
    outstandingChanges?(): void
    propertyOwnershipStatus: number
    editMode?: boolean
    userId: string

    moreDetailsRef: any
}

type Props = IProps & IReduxProps & IRouterProps

class Property extends Component<Props, IState> {
    addressFinder

    constructor(props: any) {
        super(props)

        this.state = {
            ...defaultState,
        }

        this.addressFinder = React.createRef()

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeYears = this.handleChangeYears.bind(this)
        this.handleChangeAddress = this.handleChangeAddress.bind(this)
        this.handleChangeCurrencyInput =
            this.handleChangeCurrencyInput.bind(this)
        this.toggleAddress = this.toggleAddress.bind(this)
        this.selectAddressFromResult = this.selectAddressFromResult.bind(this)
        this.resetAddress = this.resetAddress.bind(this)
        this.resetAddressState = this.resetAddressState.bind(this)
        this.onCheckEmailExists = this.onCheckEmailExists.bind(this)
        this.getMonthlyRent = this.getMonthlyRent.bind(this)
        this.afterSuccess = this.afterSuccess.bind(this)
        this.getActiveStatus = this.getActiveStatus.bind(this)
        this.handlePropertyStatus = this.handlePropertyStatus.bind(this)
        this.getLandRegistry = this.getLandRegistry.bind(this)
        this.onLandRegistryClick = this.onLandRegistryClick.bind(this)
        this.handleFocus = this.handleFocus.bind(this)
        this.wipeQueryParameters = this.wipeQueryParameters.bind(this)
        this.setAddressFromParamValue = this.setAddressFromParamValue.bind(this)
        this.propertyMortgageSelection =
            this.propertyMortgageSelection.bind(this)
        this.setStartDragDepositPrice = this.setStartDragDepositPrice.bind(this)
        this.onChangePropertyPrice = this.onChangePropertyPrice.bind(this)
        this.selectOwnership = this.selectOwnership.bind(this)
        this.selectMortgage = this.selectMortgage.bind(this)
        this.updatePostcode = this.updatePostcode.bind(this)
        this.checkForChanges = this.checkForChanges.bind(this)
        this.onAddLandlord = this.onAddLandlord.bind(this)
        this.onAddPropertyManager = this.onAddPropertyManager.bind(this)
    }

    onAddPropertyManager(search?: string): void {
        let query = addURLParameter(
            this.props.location.search,
            `callbackPropertyManager=true&name=${search}`
        )
        this.props.history.push({
            search: query,
        })

        toggleAddContactSidebar(true)
    }

    onAddLandlord(search?: string): void {
        let query = addURLParameter(
            this.props.location.search,
            `callbackLandlord=true&name=${search}`
        )
        this.props.history.push({
            search: query,
        })

        toggleAddContactSidebar(true)
    }

    onLandRegistryClick(): void {
        if (this.state.isLandRegistryMatch) {
            this.setState({ isLandRegistryPopupOpen: true })
        }
    }

    checkForChanges(): void {
        if (lodashObject.isEqual(this.state, initialState)) {
            toggleAddPropertyFormChanged(false)
        } else {
            let difference = Object.keys(this.state).filter(
                (k) => this.state[k] !== initialState[k]
            )
            toggleAddPropertyFormChanged(true)
        }
    }

    handlePropertyStatus(selectedStatus: IActivePropertyStatus) {
        this.setState({
            propertyStatus: selectedStatus.id,
            propertyStatusName: selectedStatus.status,
        })

        let activerecords: ISelectedPropertyStatus[] = []
        for (let i = 0; i < this.state.ActivePropertyStatus.length; i++) {
            let propertyStatus: ISelectedPropertyStatus =
                this.state.ActivePropertyStatus[i]
            propertyStatus.isSelected = false
            if (
                this.state.ActivePropertyStatus[i].status.id ===
                selectedStatus.id
            ) {
                propertyStatus.isSelected = true
            }
            activerecords.push(propertyStatus)
        }
        this.setState({
            ActivePropertyStatus: activerecords,
        })
    }

    getActiveStatus() {}

    resetAddressState() {
        this.setState({
            address: initialState.address,
            isLandRegistryMatch: false,
        })
    }

    onCheckEmailExists = async (email: string) => {
        return doesEmailAlreadyExist(email).then((resp) => {
            this.setState({
                emailIsAlreadyAdded: resp.data,
            })
        })
    }

    private doesAddressEqualInitialState() {
        return (
            this.state.address.line1 === '' &&
            this.state.address.line2 === '' &&
            this.state.address.street_Name === '' &&
            this.state.address.town === '' &&
            this.state.address.county === '' &&
            this.state.address.postcode === '' &&
            this.state.address.country === ''
        )
    }

    updatePostcode(postcode: string): void {
        this.setState((prevState) => ({
            address: {
                ...prevState.address,
                postcode: postcode,
            },
        }))
    }

    selectAddressFromResult(address: IAddress) {
        this.setState(
            {
                address: address,
                hasUpdatedAddress: true,
            },
            () => {
                this.getLandRegistry()

                let propertyName = address.line1 ? address.line1 : '';
                if (address.line2) propertyName ? propertyName += ',' + address.line2 : propertyName += address.line2
                //if (address.street_Name) propertyName ? propertyName += ',' + address.street_Name : propertyName += address.street_Name
                if (address.postcode) propertyName ? propertyName += ',' + address.postcode : propertyName += address.postcode

                this.setState({
                    propertyName: propertyName,
                })

                this.checkForChanges()
            }
        )
    }

    getLandRegistry(): void {
        this.setState({
            isLandRegistryMatch: false,
        })

        getPricePaidData(this.state.address).then((resp) => {
            if (resp && resp.data && resp.status == 200) {
                this.setState(
                    (prevState) => ({
                        propertyType:
                            resp.data.propertyTypeId != null &&
                            resp.data.propertyTypeId !=
                                '00000000-0000-0000-0000-000000000000'
                                ? resp.data.propertyTypeId
                                : null,
                        isLandRegistryMatch: true,
                        moreInformation: resp.data,
                        purchasePrice: resp.data.currentValuation,
                        deposit: Math.floor(resp.data.currentValuation * 0.25),
                        dateOfPurchase: new Date(
                            resp.data.currentValuationDate
                                ? moment(
                                      resp.data.currentValuationDate.toString()
                                  )
                                      .local()
                                      .format('YYYY/MM/DD')
                                : new Date()
                        ),
                    }),
                    () => this.props.updateDepositAmount(this.state.deposit)
                )

                this.getMonthlyRent(resp.data.currentValuation)
            }
        })
    }

    getMonthlyRent(value: number) {
        let monthlyrent = Math.round(value * 0.004)
        this.setState(
            {
                monthlyRentalAmount: monthlyrent,
            },
            () => this.props.updateRentAmount(this.state.monthlyRentalAmount)
        )
    }

    resetAddress(): void {
        this.setState(
            {
                address: initialState.address,
                propertyName: initialState.propertyName,
                isLandRegistryMatch: initialState.isLandRegistryMatch,
            },
            () => this.checkForChanges()
        )
    }

    toggleAddress(): void {
        this.setState({
            enterAddressManually: !this.state.enterAddressManually,
        })
    }

    private formatAddressIntoSingleLine(address: IAddress): string {
        let returnString = address.line1 ? address.line1 : '';
        if (address.line2) returnString ? returnString += ',' + address.line2 : returnString += address.line2
        if (address.street_Name && !address.line1.includes(address.street_Name) && !address.line2.includes(address.street_Name)) returnString ? returnString += ',' + address.street_Name : returnString += address.street_Name
        if (address.town) returnString ? returnString += ',' + address.town : returnString += address.town
        if (address.county) returnString ? returnString += ',' + address.county : returnString += address.county
        if (address.country) returnString ? returnString += ',' + address.country : returnString += address.country
        if (address.postcode) returnString ? returnString += ',' + address.postcode : returnString += address.postcode

        return returnString
    }

    handleChangeAddress(event: React.FormEvent<HTMLInputElement>) {
        const field: string = event.currentTarget.name
        const value: string = event.currentTarget.value

        let manualaddress: IAddress = {...this.state.address}
        manualaddress[field] = value

        this.setState({ address: manualaddress, hasUpdatedAddress: true }, () =>
            this.checkForChanges()
        )
    }

    handleChangeCurrencyInput(field: string, floatvalue: number) {
        this.setState({ [field]: floatvalue } as Pick<IState, any>)
        this.checkForChanges()
    }

    handleBlurCurrencyInput(field: string, floatvalue: number) {
        if (field == 'purchasePrice') {
            this.getMonthlyRent(floatvalue)

            if (this.state.deposit > floatvalue) {
                this.setState({ deposit: floatvalue }, () =>
                    this.props.updateDepositAmount(this.state.deposit)
                )
            } else {
                this.setState({ deposit: Math.floor(floatvalue * 0.25) }, () =>
                    this.props.updateDepositAmount(this.state.deposit)
                )
            }
        }
        if (field == 'deposit') {
            this.setState({ isDepositChanged: true })
            if(floatvalue < this.state.purchasePrice){
                this.setState({ deposit: floatvalue })
            }
        }
        if (field == 'monthlyRentalAmount') {
            this.props.updateRentAmount(floatvalue.toString())
        }
    }

    handleChangeYears(event: React.FormEvent<HTMLInputElement>) {
        let value: string = event.currentTarget.value
        value = value.replace('-', '')
        this.setState({ yearsOfInvestment: value } as Pick<IState, any>, () =>
            this.checkForChanges()
        )
    }

    getIsDepositRequired() {
        return this.state.propertyMortgageStatus === 1
    }

    getPropertyStatus() {
        return this.props.propertyOwnershipStatus == 1
            ? '500F8D5E-1C31-4465-23E7-08D6DFA464B9'
            : 'A2E860AE-D660-4969-23E6-08D6DFA464B9'
    }

    wipeQueryParameters(): void {
        this.props.history.push({
            search: '',
        })
    }

    notify = (message: string) => {
        toast(message)
    }

    afterSuccess(propertyId: string): void {
        this.setState(
            {
                address: initialState.address,
                submitting: false,
                loading: false,
                propertyName: '',
                propertyStatusName: '',
                purchasePrice: initialState.purchasePrice,
                deposit: initialState.deposit,
                monthlyRentalAmount: initialState.monthlyRentalAmount,
            },
            () => {
                this.props.updateRentAmount(this.state.monthlyRentalAmount)
                this.props.updateDepositAmount(this.state.deposit)
            }
        )

        this.props.changePageNumber(1)
    }

    public onChangePurchaseDate = (dateOfPurchase: Date) =>
        this.setState({ dateOfPurchase })

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const field = event.currentTarget.name
        const value: string = event.currentTarget.value
        this.setState({ [field]: value } as Pick<IState, any>, () =>
            this.checkForChanges()
        )
    }

    handleState(vari: any, valu: any) {
        const field = vari
        const value: string = valu
        this.setState({ [field]: value } as Pick<IState, any>, () =>
            this.checkForChanges()
        )
    }

    getRoute(): string {
        let route = this.props.location.pathname

        if (route.indexOf('/dashboard/properties') > -1) {
            return 'propertyList'
        }

        if (route.indexOf('/research') > -1) {
            return 'research'
        }

        if (route.indexOf('property') > -1) {
            return 'calculateReturns'
        }

        return ''
    }

    setAddressFromParamValue(field, queryParam) {
        if (queryParam) {
            this.setState({
                address: { ...this.state.address, [field]: queryParam },
            })
        }
    }

    closeTour() {
        let tourInformation = getLocalTourInformation(this.props.userId)
        tourInformation.addPropertyTour = true
        setLocalTourInformation(tourInformation, this.props.userId)
        this.setState({ addPropertyTour: false })
    }

    restartTour() {
        let tourInformation = getLocalTourInformation(this.props.userId)
        tourInformation.addPropertyTour = false
        setLocalTourInformation(tourInformation, this.props.userId)
        this.setState({ addPropertyTour: true })
    }

    componentWillReceiveProps(newProps: Props): void {
        let next = queryString.parse(
            newProps.location.search
        ).addLandlordCallback

        if (next) {
            let data: IAddTenantCallback = JSON.parse(next)

            if (data.contactId != this.state.landlordContactId) {
                this.setState(
                    {
                        landlordContactId: data.contactId,
                        landlordName: data.name,
                    },
                    () => this.checkForChanges()
                )

                let query = removeURLParameter(
                    this.props.location.search,
                    'addLandlordCallback'
                )
                this.props.history.push({
                    search: query,
                })
            }
        }

        let next1 = queryString.parse(
            newProps.location.search
        ).addPropertyManagerCallback

        if (next1) {
            let data: IAddTenantCallback = JSON.parse(next1)

            if (data.contactId != this.state.propertyManagerContactId) {
                this.setState(
                    {
                        propertyManagerContactId: data.contactId,
                        propertyManagerName: data.name,
                    },
                    () => this.checkForChanges()
                )

                let query = removeURLParameter(
                    this.props.location.search,
                    'addPropertyManagerCallback'
                )
                this.props.history.push({
                    search: query,
                })
            }
        }
    }

    componentDidMount() {
        if(this.props.add_property_request){ 
            let request: ICalculatorAddPropertyRequest = this.props.add_property_request;

            console.log("ADD", request);

            this.setState({
                propertyName: request.propertyName,
                address: request.address ? request.address : initialState.address,
                purchasePrice: request.purchasePrice,
                monthlyRentalAmount: request.monthlyRentalAmount,
                deposit: request.deposit,
                yearsOfInvestment: request.yearsOfInvestment,
                mortgageAmount: request.mortgageAmount,
                initialPostcode: request.address && !request.address.line1 && request.address.postcode ? request.address.postcode : null,
                ltv: request.ltv,
                interestRate: request.interestRate,
                valuationFee: request.valuationFee,
                mortgageFee: request.mortgageFee,
                refurbishment: request.refurbishment,
                stampDuty: request.stampDuty,
                lettingAgent: request.lettingAgent,
                maintenance: request.maintenance,
                voidPeriod: request.voidPeriod,
                insurance: request.insurance,
                capitalGrowthRate: request.capitalGrowthRate,
                rentalGrowthRate: request.rentalGrowthRate,
                serviceChargesPCM: request.serviceChargesPCM,
                groundRentPCM: request.groundRentPCM,
                solicitorFeeAndSearches: request.solicitorFeeAndSearches,

                mortgagePCM: request.mortgagePCM,
                mortgageType: request.mortgageType,
                mortgageTerm: request.mortgageTerm,
                lettingAgentPercent: request.lettingAgentPercent,
                maintenancePercent: request.maintenancePercent,
                voidPeriodPercent: request.voidPeriodPercent,
                refurbishmentPercent: request.refurbishmentPercent,
                otherPurchaseCosts: request.otherPurchaseCosts,
                otherOperatingCosts: request.otherOperatingCosts,
                country: request.country,
                stampDutyLocale: request.stampDutyLocale,
                isInvestmentProperty: request.isInvestmentProperty,
                insurancePCM: request.insurancePCM,
                investmentAmount: request.investmentAmount,
                investmentAmountPercent: request.investmentAmountPercent,
                renovationPeriodMonths: request.renovationPeriodMonths,
                propertyValueAfterRenovation: request.propertyValueAfterRenovation,
                investorStrategy: request.investorStrategy,
                clientName: request.clientName
            });

            // Set owner to false
            this.props.propertyOwnedSelection(2)
        }

        let contactId = queryString.parse(this.props.location.search).contactId

        let contactName = queryString.parse(
            this.props.location.search
        ).contactName

        let isPropertyManager = queryString.parse(
            this.props.location.search
        ).isPropertyManager

        let isLandlord = queryString.parse(
            this.props.location.search
        ).isLandlord

        if (contactId) {
            if (isPropertyManager === 'true' && contactName) {
                this.setState({
                    propertyManagerContactId: contactId,
                    propertyManagerName: contactName,
                })
            }

            if (isLandlord === 'true' && contactName) {
                this.setState({
                    landlordContactId: contactId,
                    landlordName: contactName,
                })
            }
        }

        let tourInformation = getLocalTourInformation(this.props.userId)

        if (tourInformation && !tourInformation.addPropertyTour) {
            setTimeout(() => { 
                this.setState({
                    addPropertyTour: true,
                })
            }, 500);
            
            tourInformation.addPropertyTour = true
            setLocalTourInformation(tourInformation, this.props.userId)
        }

        let editPropertyId = queryString.parse(
            this.props.location.search
        ).editProperty
        let isEditProperty =
            editPropertyId != null &&
            editPropertyId != undefined &&
            editPropertyId != ''
        if (isEditProperty) {
            this.setState({ editPropertyId, isEditProperty })
        }

        if (!editPropertyId && !contactId) {
            this.setState({
                landlordName: getLoggedInUsersName(),
                landlordContactId: getLoggedInUsersContactId(),
            })
        }

        let isOwnerParam = queryString.parse(this.props.location.search).isOwner
        if (isOwnerParam === 'true') {
            this.props.propertyOwnedSelection(1)
        } else if (isOwnerParam === 'false') {
            this.props.propertyOwnedSelection(2)
        }

        let salePrice = queryString.parse(this.props.location.search).salePrice
        if (salePrice) {
            this.setState(
                {
                    purchasePrice: salePrice,
                    deposit: Math.floor(salePrice * 0.25),
                    monthlyRentalAmount: Math.round(+salePrice * 0.004),
                },
                () => {
                    this.props.updateRentAmount(this.state.monthlyRentalAmount)
                    this.props.updateDepositAmount(this.state.deposit)
                }
            )
        }

        let address = queryString.parse(this.props.location.search).address
        if (address && !this.state.propertyName) {
            let addressObject: IAddress = JSON.parse(address)
            addressObject.lastUpdatedAt = new Date()
            addressObject.addressAddedAt = new Date()
            this.setState(
                {
                    address: addressObject,
                },
                () => this.getLandRegistry()
            )

            this.setState({
                propertyName:
                addressObject.line1 ? `${addressObject.line1},` : '' + addressObject.postcode,

            })

            let query = removeURLParameter(
                this.props.location.search,
                'address'
            )
            this.props.history.push({
                search: query,
            })
        }

        // Edit
        const { property_list_data } = this.props

        let editPropertyId1 = queryString.parse(
            this.props.location.search
        ).editProperty

        if (
            this.getRoute() == 'calculateReturns' &&
            this.getRoute() != 'research' &&
            editPropertyId1
        ) {
            const { address, propertyName, propertyStatus, propertyContacts } =
                this.props.data
            this.setState({
                address: address,
                propertyName: propertyName,
                propertyStatus: propertyStatus,
            })

            if (propertyContacts.landlord) {
                this.setState({
                    landlordContactId: propertyContacts.landlord,
                    landlordName: propertyContacts.landlordName,
                })
            }

            if (propertyContacts.propertyManager) {
                this.setState({
                    propertyManagerContactId: propertyContacts.propertyManager,
                    propertyManagerName: propertyContacts.propertyManagerName,
                })
            }
        }

        if (this.getRoute() == 'propertyList') {
            if (this.state.editPropertyId) {
                let propertyData = property_list_data.filter(
                    (p) => p.property.propertyId == this.state.editPropertyId
                )[0]

                if (propertyData) {
                    this.setState(
                        {
                            propertyName: propertyData.property.propertyName,
                            address: propertyData.address,
                            propertyStatus: propertyData.statusId,
                            purchasePrice:
                                propertyData.calculations.purchasePrice,
                            monthlyRentalAmount:
                                propertyData.calculations.rentPCM,
                        },
                        () =>
                            this.props.updateRentAmount(
                                this.state.monthlyRentalAmount
                            )
                    )

                    if (
                        propertyData.statusId.toUpperCase() ===
                        '500F8D5E-1C31-4465-23E7-08D6DFA464B9'
                    ) {
                        this.props.propertyOwnedSelection(1)
                    }
                    if (
                        propertyData.statusId.toUpperCase() ===
                        'A2E860AE-D660-4969-23E6-08D6DFA464B9'
                    ) {
                        this.props.propertyOwnedSelection(2)
                    }
                }
            }
        }
        if (editPropertyId) {
            this.GetPropertyForEdit(editPropertyId)
        } else {
            this.setState({ loading: false })
        }
        this.getActiveStatus()

        this.setState({
            hasLoaded: true
        })
    }

    GetPropertyForEdit(propertyId: any) {
        getPropertyForEdit(propertyId).then((x) => {
            if (x && x.status == 200) {
                this.setState(
                    {
                        address: x.data.address,
                        propertyName: x.data.property.propertyName,
                        propertyStatus: x.data.property.propertyStatus,
                        purchasePrice: x.data.propertyInformation.purchaseCost,
                        deposit: x.data.propertyInformation.deposit,
                        yearsOfInvestment:
                            x.data.propertyInformation.yearsOfInvestment,
                        monthlyRentalAmount:
                            x.data.propertyInformation.monthlyRentalAmount,
                        dateOfPurchase: new Date(
                            moment(
                                x.data.propertyDetails.dateOfTransfer.toString()
                            )
                                .local()
                                .format('YYYY/MM/DD')
                        ),
                        propertyContacts: x.data.propertyContacts
                    },
                    () => {
                        this.props.updateRentAmount(
                            this.state.monthlyRentalAmount
                        )
                        this.props.updateDepositAmount(this.state.deposit)

                        if (this.state.propertyContacts.landlord) {
                            this.setState({
                                landlordContactId: this.state.propertyContacts.landlord,
                                landlordName: this.state.propertyContacts.landlordName,
                            })
                        }
            
                        if (this.state.propertyContacts.propertyManager) {
                            this.setState({
                                propertyManagerContactId: this.state.propertyContacts.propertyManager,
                                propertyManagerName: this.state.propertyContacts.propertyManagerName,
                            })
                        }
                    }
                )

                let ownershipStatus =
                    this.state.propertyStatus.toUpperCase() ===
                    '500F8D5E-1C31-4465-23E7-08D6DFA464B9'
                        ? 1
                        : this.state.propertyStatus.toUpperCase() ===
                          'A2E860AE-D660-4969-23E6-08D6DFA464B9'
                        ? 2
                        : null

                if (ownershipStatus) {
                    this.props.propertyOwnedSelection(ownershipStatus)
                }

                let propertyMortgageStatus =
                    x.data.propertyInformation.deposit !==
                    x.data.propertyInformation.purchaseCost
                        ? 1
                        : 2

                this.propertyMortgageSelection(propertyMortgageStatus)
            }

            this.setState({ loading: false })

            // Update the initial state to be the current state, as this will include the
            // existing property data
            initialState = this.state
        })
    }

    componentWillUnmount() {
        // Clear request from Redux
        this.props.updateAddPropertyRequest(null);
                    
        this.setState(
            {
                address: defaultState.address,
                submitting: false,
                loading: false,
                propertyName: '',
                propertyStatusName: '',
                purchasePrice: defaultState.purchasePrice,
                deposit: defaultState.deposit,
                monthlyRentalAmount: defaultState.monthlyRentalAmount,
            },
            () => {
                this.props.updateRentAmount(this.state.monthlyRentalAmount)
                this.props.updateDepositAmount(this.state.deposit)
            }
        )

        let removedParamSearch = removeURLParameter(
            this.props.location.search,
            'isOwner'
        )

        removedParamSearch = removeURLParameter(
            this.props.location.search,
            'salePrice'
        )

        this.props.history.push({
            search: removedParamSearch,
        })

        let removeEditProperty = removeURLParameter(
            this.props.location.search,
            'editProperty'
        )
        this.props.history.push({
            search: removeEditProperty,
        })
    }

    propertyMortgageSelection(newStatus: number): void {
        this.setState({ propertyMortgageStatus: newStatus })
    }

    setStartDragDepositPrice() {
        this.setState({ depositOnStartChange: this.state.deposit })
    }

    onChangePropertyPrice(value) {
        this.setState(
            {
                purchasePrice: value,
                monthlyRentalAmount: Math.round(+value * 0.004),
                deposit: this.state.isDepositChanged
                    ? this.state.deposit
                    : Math.floor(value * 0.25),
            },
            () => {
                this.props.updateRentAmount(this.state.monthlyRentalAmount)
                this.props.updateDepositAmount(this.state.deposit)
            }
        )

        if (this.state.isDepositChanged) {
            let newDepositValue = this.state.deposit

            if (value < newDepositValue) newDepositValue = value

            if (
                value > newDepositValue &&
                newDepositValue < this.state.depositOnStartChange
            ) {
                newDepositValue =
                    value < this.state.depositOnStartChange
                        ? value
                        : this.state.depositOnStartChange
            }

            this.setState({ deposit: newDepositValue }, () =>
                this.props.updateDepositAmount(this.state.deposit)
            )
        }
    }

    handleFocus = (event) => event.target.select()

    selectOwnership(value) {
        this.props.propertyOwnedSelection(value == 'Yes' ? 1 : 2)
    }

    selectMortgage(value) {
        this.propertyMortgageSelection(value == 'Yes' ? 1 : 2)
    }

    render() {
        if (this.state.loading) {
            return (
                <div>
                    <RotatingLoader
                        loading={true}
                        text={`${
                            this.state.isEditProperty
                                ? 'Updating your property...'
                                : 'Adding your new property...'
                        }`}
                    />
                </div>
            )
        }

        if (this.state.submitting) {
            return (
                <div>
                    <SuccessTick />{' '}
                    <span className={styles.text}>
                        {`${
                            this.state.isEditProperty
                                ? 'Your property has been updated.'
                                : 'Your new property has been added.'
                        }`}
                    </span>
                </div>
            )
        }

        if (this.props.app_loading) {
            return (
                <div>
                    <RotateLoader {...rotateLoaderStyling} />
                    <p className={styles.text}>{`${
                        this.state.isEditProperty
                            ? 'Updating your property...'
                            : 'Adding your new property...'
                    }`}</p>
                </div>
            )
        }

        return (
            <div className={styles.page}>
                {this.state.priceValuation && (
                    <EstimatedValuationModal
                        onSelectAddress={this.selectAddressFromResult}
                        manualOverride={{
                            propertyType: this.state.propertyType,
                            bathrooms: this.state.bathrooms,
                            bedrooms: this.state.bedrooms,
                            finishQuality: this.state.finishQuality,
                            outdoorSpace: this.state.outdoorSpace,
                            yearBuilt: this.state.yearBuilt,
                            size: this.state.size,
                            sizeType: this.state.sizeType,
                            parking: this.state.parking,
                            numberOfParkingSpaces:
                                this.state.numberOfParkingSpaces,
                        }}
                        isResidential={true}
                        onSuccess={(
                            priceItemResp: IGetPriceValuationResponse,
                            rentItemResp: IGetPriceValuationResponse,
                            request: IGetPriceValuationRequest
                        ) => {
                            this.setState((prevState) => ({
                                purchasePrice: priceItemResp.result.estimate,
                                priceValuation: false,
                                bedrooms: request.bedrooms,
                                bathrooms: request.bathrooms,
                                propertyType: request.propertyType,
                                finishQuality: request.finishQuality,
                                outdoorSpace: request.outdoorSpace,
                                parking: request.parkingType,
                                numberOfParkingSpaces: Number(
                                    request.offStreetParking
                                ),
                                size: request.internalArea,
                                sizeType: request.internalAreaType,
                                yearBuilt: request.constructionDate,
                                propertyData: {
                                    ...prevState.propertyData,
                                    priceValuation: priceItemResp.result.estimate,
                                    priceMargin: priceItemResp.result.margin,
                                    priceValuationResponseBody: priceItemResp.responseBody,
                                },
                            }))
                        }}
                        postcode={this.state.address.postcode}
                        onClose={() => this.setState({ priceValuation: false })}
                        open={this.state.priceValuation}
                        propertyId={null}
                    />
                )}

                {this.state.rentalPriceValuation && (
                    <EstimatedValuationModal
                        onSelectAddress={this.selectAddressFromResult}
                        isRental
                        manualOverride={{
                            propertyType: this.state.propertyType,
                            bathrooms: this.state.bathrooms,
                            bedrooms: this.state.bedrooms,
                            finishQuality: this.state.finishQuality,
                            outdoorSpace: this.state.outdoorSpace,
                            yearBuilt: this.state.yearBuilt,
                            size: this.state.size,
                            sizeType: this.state.sizeType,
                            parking: this.state.parking,
                            numberOfParkingSpaces:
                                this.state.numberOfParkingSpaces,
                        }}
                        isResidential={true}
                        onSuccess={(
                            priceItemResp: IGetPriceValuationResponse,
                            rentItemResp: IGetPriceValuationResponse,
                            request: IGetPriceValuationRequest
                        ) => {
                            this.setState((prevState) => ({
                                rentalPriceValuation: false,
                                monthlyRentalAmount: rentItemResp.result.estimate,
                                bedrooms: request.bedrooms,
                                bathrooms: request.bathrooms,
                                propertyType: request.propertyType,
                                finishQuality: request.finishQuality,
                                outdoorSpace: request.outdoorSpace,
                                parking: request.parkingType,
                                numberOfParkingSpaces: Number(
                                    request.offStreetParking
                                ),
                                size: request.internalArea,
                                sizeType: request.internalAreaType,
                                yearBuilt: request.constructionDate,
                                propertyData: {
                                    ...prevState.propertyData,
                                    rentalValuation: rentItemResp.result.estimate,
                                    rentalValuationResponseBody: rentItemResp.responseBody,
                                },
                            }))
                        }}
                        postcode={this.state.address.postcode}
                        onClose={() =>
                            this.setState({ rentalPriceValuation: false })
                        }
                        open={this.state.monthlyRentalAmount}
                        propertyId={null}
                    />
                )}


                <Tour 
                    updateDelay={5}
                    steps={addPropertyFormSteps}
                    isOpen={this.state.addPropertyTour}
                    onRequestClose={() => this.closeTour()}
                />

                {this.state.moreInformation && (
                    <MoreInformationModal
                        propertyType={this.state.moreInformation.propertyType}
                        address={this.state.address}
                        currentValuation={
                            this.state.moreInformation.currentValuation
                        }
                        salesHistory={
                            this.state.moreInformation.propertyValuations
                        }
                        onOpenModal={() =>
                            this.setState({ isLandRegistryPopupOpen: true })
                        }
                        onCloseModal={() =>
                            this.setState({ isLandRegistryPopupOpen: false })
                        }
                        open={this.state.isLandRegistryPopupOpen}
                    />
                )}

                <Form className={styles.form}>
                    <div className={styles.Content} />

                    <div
                        className={styles.takeTour}
                        onClick={() => this.restartTour()}
                    >
                        <img
                            src={takeTourIcon}
                            className={styles.takeTourIcon}
                            alt="Take tour icon"
                        />
                    </div>

                    <div>
                        <div className={styles.propertySearch}>
                            <FormGroup id="AddressFinder">
                                <Label
                                    className={styles.label}
                                    for="loginRequest.email"
                                >
                                    Address
                                </Label>
                                {this.doesAddressEqualInitialState() && (
                                    <div>
                                        {!this.state.enterAddressManually && (
                                            <div className={styles.inputHolder}>
                                                <AddressFinder
                                                    initialValue={this.state.initialPostcode}
                                                    label="Address"
                                                    selectAddressCallback={
                                                        this
                                                            .selectAddressFromResult
                                                    }
                                                    resetAddress={
                                                        this.resetAddress
                                                    }
                                                />
                                            </div>
                                        )}
                                        <p className={styles.addressToggle}>
                                            <div
                                                onClick={this.toggleAddress}
                                                className={
                                                    styles.addressToggleOption
                                                }
                                            >
                                                {this.state.enterAddressManually
                                                    ? 'Use postcode lookup'
                                                    : 'Enter address manually'}
                                            </div>
                                        </p>
                                    </div>
                                )}

                                {!this.doesAddressEqualInitialState() && (
                                    <div className={styles.address}>
                                        {!this.state.enterAddressManually && (
                                            <Input
                                                disabled
                                                className={
                                                    styles.selectedAddress
                                                }
                                                type="text"
                                                name="postcode"
                                                id="postcode"
                                                defaultValue={this.formatAddressIntoSingleLine(
                                                    this.state.address
                                                )}
                                                onChange={this.handleChange}
                                                onFocus={this.handleFocus}
                                            />
                                        )}
                                        <p className={styles.addressToggle}>
                                            <div
                                                onClick={this.resetAddress}
                                                className={
                                                    styles.addressToggleOption
                                                }
                                            >
                                                Reset address
                                            </div>{' '}
                                            |{' '}
                                            <div
                                                onClick={this.toggleAddress}
                                                className={
                                                    styles.addressToggleOption
                                                }
                                            >
                                                {this.state.enterAddressManually
                                                    ? 'Use postcode lookup'
                                                    : 'Enter address manually'}
                                            </div>
                                        </p>
                                    </div>
                                )}

                                {this.state.enterAddressManually && (
                                    <SlideDown className={styles.slidedown}>
                                        <span
                                            className={
                                                styles.manualAddressSlidedown
                                            }
                                        >
                                            <Input
                                                className={styles.inputAddress}
                                                type="text"
                                                name="line1"
                                                id="line1"
                                                placeholder="Line 1"
                                                onChange={
                                                    this.handleChangeAddress
                                                }
                                                value={this.state.address.line1}
                                                onFocus={this.handleFocus}
                                            />
                                            <Input
                                                className={styles.inputAddress}
                                                type="text"
                                                name="line2"
                                                id="line2"
                                                placeholder="Line 2"
                                                onChange={
                                                    this.handleChangeAddress
                                                }
                                                value={this.state.address.line2}
                                                onFocus={this.handleFocus}
                                            />
                                            {/* <Input
                                                className={styles.inputAddress}
                                                type="text"
                                                name="street_Name"
                                                id="street_Name"
                                                placeholder="Line 3"
                                                onChange={
                                                    this.handleChangeAddress
                                                }
                                                value={this.state.address.street_Name}
                                                onFocus={this.handleFocus}
                                            /> */}
                                            <Input
                                                className={styles.inputAddress}
                                                type="text"
                                                name="town"
                                                id="town"
                                                placeholder="Town"
                                                onChange={
                                                    this.handleChangeAddress
                                                }
                                                value={this.state.address.town}
                                                onFocus={this.handleFocus}
                                            />
                                            <Input
                                                className={styles.inputAddress}
                                                type="text"
                                                name="county"
                                                id="county"
                                                placeholder="County"
                                                onChange={
                                                    this.handleChangeAddress
                                                }
                                                value={
                                                    this.state.address.county
                                                }
                                                onFocus={this.handleFocus}
                                            />
                                            <Input
                                                className={styles.inputAddress}
                                                type="text"
                                                name="postcode"
                                                id="postcode"
                                                placeholder="Postcode"
                                                onChange={
                                                    this.handleChangeAddress
                                                }
                                                value={
                                                    this.state.address.postcode
                                                }
                                                onFocus={this.handleFocus}
                                            />
                                            <Input
                                                className={styles.inputAddress}
                                                type="text"
                                                name="country"
                                                id="country"
                                                placeholder="Country"
                                                onChange={
                                                    this.handleChangeAddress
                                                }
                                                value={
                                                    this.state.address.country
                                                }
                                                onFocus={this.handleFocus}
                                            />
                                        </span>
                                    </SlideDown>
                                )}
                            </FormGroup>

                            <div className={styles.friendlyProperty}>
                                {!this.props.is_logged_in && (
                                    <FormGroup>
                                        <Label
                                            className={styles.label}
                                            for="loginRequest.email"
                                        >
                                            Email
                                        </Label>
                                        <Input
                                            className={styles.input}
                                            type="text"
                                            name="email"
                                            id="Surname"
                                            placeholder="Enter your email..."
                                            onChange={this.handleChange}
                                            onBlur={() =>
                                                this.onCheckEmailExists(
                                                    this.state.email
                                                )
                                            }
                                            onFocus={this.handleFocus}
                                        />
                                        {this.state.emailIsAlreadyAdded && (
                                            <p className={styles.emailExists}>
                                                The email{' '}
                                                <strong>
                                                    {this.state.email}
                                                </strong>{' '}
                                                already exists.
                                            </p>
                                        )}
                                    </FormGroup>
                                )}

                                {this.state.isLandRegistryMatch && (
                                    <FormGroup>
                                        <Row
                                            className={styles.landRegistryMatch}
                                        >
                                            <Col
                                                xs="2"
                                                className={styles.lrSuccess}
                                            >
                                                <SuccessTick />
                                            </Col>
                                            <Col
                                                xs="6"
                                                className={styles.lrMatch}
                                                onClick={() =>
                                                    this.onLandRegistryClick()
                                                }
                                            >
                                                Land Registry match
                                            </Col>
                                            <Col xs="2">
                                                <img
                                                    src={
                                                        'https://rentchiefstorageuk.blob.core.windows.net/images/general-image/land_registry.png'
                                                    }
                                                    alt="Land Registry Logo"
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                )}

                                <FormGroup id="friendlyPropertyName">
                                    <Label
                                        className={styles.label}
                                        for="loginRequest.email"
                                    >
                                        Friendly Property Name
                                    </Label>
                                    <Input
                                        className={styles.input}
                                        type="text"
                                        name="propertyName"
                                        id="propertyName"
                                        placeholder="Enter a friendly property name..."
                                        value={this.state.propertyName}
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </div>

                    <div className={styles.ownerOf} id="ownerOf">
                        <div className={styles.ownerTesting} id="alreadyOwned">
                            <Label
                                className={`${styles.label} ${styles.labelReduced}`}
                                for="loginRequest.email"
                            >
                                Is the property owned?
                                <Tooltip
                                    theme="light"
                                    html={
                                        <div>
                                            Use full property management features or our great research features for properties being considered for purchase.
                                        </div>
                                    }
                                    position="right"
                                    trigger="mouseenter"
                                    arrow
                                    interactive={true}
                                >
                                    {' '}
                                    <img
                                        className={styles.infoIcon}
                                        src={infoIcon}
                                        alt="Info"
                                    />
                                </Tooltip>
                            </Label>

                            <div className={styles.yesNoInline}>
                                <MaterialRadioButtons
                                    labels={['Yes', 'No']}
                                    default={
                                        this.props.propertyOwnershipStatus == 1
                                            ? 'Yes'
                                            : 'No'
                                    }
                                    handleChange={this.selectOwnership}
                                />
                            </div>
                        </div>

                        <SlideDown className={'my-dropdown-slidedown'}>
                            {this.props.propertyOwnershipStatus > 0 && (
                                <div className={styles.ownerTesting}>
                                    <Label
                                        className={`${styles.label} ${styles.labelReduced}`}
                                        for="loginRequest.email"
                                    >
                                        {this.props.propertyOwnershipStatus ===
                                        1
                                            ? `Is there a mortgage?`
                                            : `Will there be a mortgage?`}
                                    </Label>

                                    <div className={styles.yesNoInline}>
                                        <MaterialRadioButtons
                                            labels={['Yes', 'No']}
                                            default={
                                                this.state
                                                    .propertyMortgageStatus == 1
                                                    ? 'Yes'
                                                    : 'No'
                                            }
                                            handleChange={this.selectMortgage}
                                        />
                                    </div>
                                </div>
                            )}
                        </SlideDown>

                        <SlideDown className={styles.slidedown}>
                            {this.state.propertyMortgageStatus > 0 && (
                                <div className={styles.row} id="valuations">
                                    <div className={styles.column}>
                                        <FormGroup>
                                            <Label
                                                className={styles.label}
                                                for="loginRequest.email"
                                            >
                                                {this.props
                                                    .propertyOwnershipStatus ==
                                                1
                                                    ? 'Purchase Price'
                                                    : 'Property Value'}
                                            </Label>
                                            <div className={styles.rcCurrencyInput}>
                                                <RCCurrencyInput
                                                    prefix={"£"}
                                                    id="purchasePrice"
                                                    value={this.state.purchasePrice}
                                                    handleChange={(e) => this.handleChangeCurrencyInput("purchasePrice", e) }
                                                    handleBlur={(e) => this.handleBlurCurrencyInput("purchasePrice", e)}
                                                />
                                            </div>
                                        </FormGroup>

                                        <Buttons
                                            align={'left'}
                                            buttons={[
                                                {
                                                    text: 'Price Valuation',
                                                    displayType: 'action',
                                                    elementType: 'button',
                                                    width: 'full',
                                                    onClick: () =>
                                                        this.setState({
                                                            priceValuation:
                                                                true,
                                                        }),
                                                },
                                            ]}
                                        />
                                    </div>

                                    <div className={styles.column}>
                                        <FormGroup id="MonthlyRentalAmount">
                                            <Label
                                                className={styles.label}
                                                for="loginRequest.email"
                                            >
                                                Monthly Rent
                                            </Label>
                                            <div className={styles.rcCurrencyInput}>
                                                <RCCurrencyInput
                                                    prefix={"£"}
                                                    id="monthlyRentalAmount"
                                                    value={this.state.monthlyRentalAmount}
                                                    handleChange={(e) => this.handleChangeCurrencyInput("monthlyRentalAmount", e) }
                                                    handleBlur={(e) => this.handleBlurCurrencyInput("monthlyRentalAmount", e)}
                                                />
                                            </div>
                                        </FormGroup>

                                        <Buttons
                                            align={'left'}
                                            buttons={[
                                                {
                                                    text: 'Rent Valuation',
                                                    displayType: 'action',
                                                    elementType: 'button',
                                                    width: 'full',
                                                    onClick: () =>
                                                        this.setState({
                                                            rentalPriceValuation:
                                                                true,
                                                        }),
                                                },
                                            ]}
                                        />
                                    </div>

                                    {this.state.propertyMortgageStatus == 1 && (
                                        <div className={styles.column}>
                                            <FormGroup id="Deposit">
                                                <Label
                                                    className={styles.label}
                                                    for="loginRequest.email"
                                                >
                                                    Deposit
                                                </Label>
                                                <div className={styles.rcCurrencyInput}>
                                                    <RCCurrencyInput
                                                        prefix={"£"}
                                                        id="deposit"
                                                        value={this.state.deposit}
                                                        handleChange={(e) => this.handleChangeCurrencyInput("deposit", e) }
                                                        handleBlur={(e) => this.handleBlurCurrencyInput("deposit", e)}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </div>
                                    )}

                                    {this.props.propertyOwnershipStatus !=
                                        1 && (
                                        <div className={styles.column}>
                                            <FormGroup>
                                                <Label
                                                    className={styles.label}
                                                    for="loginRequest.email"
                                                >
                                                    Years of Investment
                                                </Label>
                                                <Input
                                                    className={
                                                        styles.inputYearsOfInvestment
                                                    }
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    id="yearsOfInvestment"
                                                    placeholder="How long will you have this investment"
                                                    name="yearsOfInvestment"
                                                    value={
                                                        this.state
                                                            .yearsOfInvestment
                                                    }
                                                    onChange={
                                                        this.handleChangeYears
                                                    }
                                                    onFocus={this.handleFocus}
                                                />
                                            </FormGroup>
                                        </div>
                                    )}
                                </div>
                            )}

                            <div>
                                {false && (
                                    <FormGroup>
                                        <Label
                                            className={styles.label}
                                            for="loginRequest.email"
                                        >
                                            Date Purchased
                                        </Label>
                                        <span className={styles.datepicker}>
                                            <DatePicker
                                                todayButton="Today"
                                                dateFormat="dd/MM/yyyy"
                                                className={styles.input}
                                                selected={moment(
                                                    this.state.dateOfPurchase
                                                )
                                                    .local()
                                                    .toDate()}
                                                onChange={(date: Date) =>
                                                    this.onChangePurchaseDate(
                                                        date
                                                    )
                                                }
                                            />
                                        </span>
                                    </FormGroup>
                                )}
                            </div>

                            <Accordion allowZeroExpanded>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton
                                            className={styles.accordionButton}
                                        >
                                            More details?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div>
                                            <FormGroup id="landlord">
                                                <Label
                                                    className={styles.label}
                                                    for="loginRequest.landlord"
                                                >
                                                    Landlord
                                                </Label>
                                                <SearchContactInput
                                                    callbackName="callbackLandlord"
                                                    onAdd={this.onAddLandlord}
                                                    allowNewContact
                                                    newContactType="Landlord"
                                                    contactType={[
                                                        'E08D17B2-3FA0-43BB-8458-18378F92FDED',
                                                    ]}
                                                    onClear={() =>
                                                        this.setState(
                                                            {
                                                                landlordContactId:
                                                                    null,
                                                                landlordName:
                                                                    null,
                                                            },
                                                            () =>
                                                                this.checkForChanges()
                                                        )
                                                    }
                                                    selectedId={
                                                        this.state
                                                            .landlordContactId
                                                    }
                                                    selectedName={
                                                        this.state.landlordName
                                                    }
                                                    onSelect={(
                                                        o: ISearchResult
                                                    ) =>
                                                        this.setState(
                                                            {
                                                                landlordContactId:
                                                                    o.value,
                                                                landlordName:
                                                                    o.label,
                                                            },
                                                            () =>
                                                                this.checkForChanges()
                                                        )
                                                    }
                                                    menuPlacement="top"
                                                />
                                            </FormGroup>

                                            <FormGroup id="propertyManager">
                                                <Label
                                                    className={styles.label}
                                                    for="loginRequest.propertyManager"
                                                >
                                                    Property Manager
                                                </Label>
                                                <SearchContactInput
                                                    callbackName="callbackPropertyManager"
                                                    onAdd={
                                                        this
                                                            .onAddPropertyManager
                                                    }
                                                    allowNewContact
                                                    newContactType="Property Manager"
                                                    //contactType={["897D7652-F770-42A2-AB3D-9D3E6CDEDF51"]}
                                                    onClear={() =>
                                                        this.setState(
                                                            {
                                                                propertyManagerContactId:
                                                                    null,
                                                                propertyManagerName:
                                                                    null,
                                                            },
                                                            () =>
                                                                this.checkForChanges()
                                                        )
                                                    }
                                                    selectedId={
                                                        this.state
                                                            .propertyManagerContactId
                                                    }
                                                    selectedName={
                                                        this.state
                                                            .propertyManagerName
                                                    }
                                                    onSelect={(
                                                        o: ISearchResult
                                                    ) =>
                                                        this.setState(
                                                            {
                                                                propertyManagerContactId:
                                                                    o.value,
                                                                propertyManagerName:
                                                                    o.label,
                                                            },
                                                            () =>
                                                                this.checkForChanges()
                                                        )
                                                    }
                                                    menuPlacement="top"
                                                />
                                            </FormGroup>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>

                            {/*<AccordionComponent key={uuid()} immediateRender={true} expandMode={'Multiple'}>
                <AccordionItemsDirective>
                  <AccordionItemDirective header='More Details' content={() =>
                    
                  } />
                </AccordionItemsDirective>
                </AccordionComponent>*/}
                        <div className={styles.spacer}/>

                        </SlideDown>
                    </div>
                </Form>
            </div>
        )
    }
}


const mapStateToProps = (state: RootState) => ({
    add_property_request: state.property.add_property_request,
  });
  
  const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
      {
        updateAddPropertyRequest,
      },
      dispatch
    );
  
  export default withRouter<Props, any>(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Property)
  );
  
