import { store } from "../store";
import { IIssueItem } from "../types/IssueService";

export function updateIssueList(data: Array<IIssueItem>) {
  store.dispatch({
    type: "UPDATE_ISSUE_LIST",
    data
  });
}

export function addToIssueList(item: IIssueItem) {
  let current = store.getState().issue.issue_list;
  let newList = [item].concat(current);
  updateIssueList(newList);
}

export function removeFromListById(issueId: string) {
  let current = store.getState().issue.issue_list;
  let newList = current.filter(i => i.issueId != issueId);
  updateIssueList(newList);
}

export function updateIssueListItem(data: IIssueItem) {
  let current = store.getState().issue.issue_list;
  let filtered = current.findIndex(x => x.issueId == data.issueId);
  if (filtered === null) {
    return;
  }
  current[filtered] = data;
  updateIssueList(current);
}

export function getFirstIssueId(): string {
  let current = store.getState().issue.issue_list;
  if (current.length > 0) {
    return current[0].issueId;
  }
  return null;
}