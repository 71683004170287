import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import * as domains from '../configuration/domains';
import { toQueryString, queryHeaders } from '../helpers/serviceHelper'

export const imageApi = createApi({
    reducerPath: 'imageApi',
    baseQuery: fetchBaseQuery({
      baseUrl: domains.imageService,
      prepareHeaders: queryHeaders,
    }),
    tagTypes: ['Image'],
    endpoints: (builder) => ({
        fetchImages: builder.query({
            query: (options) => `?${toQueryString(options)}`,
            providesTags: ['Image']
        }),
        deleteImage: builder.mutation({
            query: (imageId) => (
                { 
                    url: `/delete/${imageId}`, 
                    method: "DELETE" 
                }),
            invalidatesTags: ["Image"],
          })
    })
});
  
export const { 
    useFetchImagesQuery,
    useLazyFetchImagesQuery,
    useDeleteImageMutation
    } = imageApi