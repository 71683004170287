import React from 'react'
import { Row, Col } from 'reactstrap'
import Modal from 'react-responsive-modal'
import styles from './DeleteDocumentModal.module.scss'
import SuccessTick from '../SuccessTick'
import RotatingLoader from '../RotatingLoader'
import { deleteDocuments } from '../../../services/DocumentService'
import Buttons from '../Buttons'
import {
    addURLParameter,
    removeURLParameter,
} from '../../../helpers/locationHelpers'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { toggleDeleteDocumentPopup } from '../../../helpers/sidebarHelpers'
import { IDataResponse } from '../../../types/ApiService'
import { processToast } from '../../../helpers/toastHelper'
const queryString = require('query-string')

interface IProps {
    history: any
    location: any
    match: any

    document_delete_popup: boolean
}

interface IState {
    deleteTenancies: boolean
    deleteDocuments: boolean
    loading: boolean
    success: boolean

    documentIds: Array<string>
}

export interface IDeleteRequest {
    deleteAssociatedTenancy: boolean
    deleteAssociatedDocument: boolean
    deleteAssociatedTransaction: boolean
}

class DeleteDocumentModal extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            deleteDocuments: true,
            deleteTenancies: true,
            loading: false,
            success: false,

            documentIds: [],
        }
    }

    componentDidMount(): void {
        let options = queryString.parse(
            this.props.location.search
        ).deleteDocument

        if (!options) {
            return
        }

        let option = JSON.parse(options)

        this.setState({
            documentIds: option,
        })

        let removeQuery = removeURLParameter(
            this.props.location.search,
            'deleteDocument'
        )
        this.props.history.push({
            search: removeQuery,
        })
    }

    onDelete(): void {
        this.setState({
            loading: true,
        })

        deleteDocuments(this.state.documentIds).then((resp) => {
            if (resp.status == 200) {
                this.setState(
                    {
                        success: true,
                        loading: false,
                    },
                    () => {
                        // Refresh Grid
                        let newQuery = addURLParameter(
                            this.props.location.search,
                            'refreshDocumentsGrid=true'
                        )
                        this.props.history.push({
                            search: newQuery,
                        })

                        // Process response
                        let response: IDataResponse<any> = resp.data
                        processToast(response)

                        // Close Modal
                        toggleDeleteDocumentPopup(false)
                    }
                )
            }
        })
    }

    render() {
        return (
            <Modal
                open={this.props.document_delete_popup}
                onClose={() => toggleDeleteDocumentPopup(false)}
                center
            >
                {this.state.loading ? (
                    <div>
                        <RotatingLoader loading={true} text={`Deleting...`} />
                    </div>
                ) : this.state.success ? (
                    <div>
                        <SuccessTick />
                        <p className={styles.modalBody}>Document deleted</p>
                    </div>
                ) : (
                    <div>
                        <p className={styles.modelHeader}>DELETE DOCUMENT</p>
                        <p className={styles.modalBody}>
                            You are about to delete{' '}
                            {this.state.documentIds.length} document
                            {this.state.documentIds.length == 1 ? '' : 's'}
                        </p>

                        <p className={styles.modalBody}>
                            Do you wish to go ahead and delete?
                        </p>
                        <Row>
                            <Col className={styles.buttonDiv}>
                                <Buttons
                                    buttons={[
                                        {
                                            displayType: 'cancel',
                                            elementType: 'button',
                                            onClick: () =>
                                                toggleDeleteDocumentPopup(
                                                    false
                                                ),
                                        },
                                        {
                                            text: 'Delete',
                                            displayType: 'submit',
                                            elementType: 'button',
                                            onClick: () => this.onDelete(),
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </div>
                )}
            </Modal>
        )
    }
}

const mapStateToProps = (state: any) => ({
    document_delete_popup: state.app.popups.document_delete_popup,
})

export default connect(mapStateToProps)(withRouter(DeleteDocumentModal))
