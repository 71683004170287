import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { toggleAddPropertySidebar } from '../../../../../actions/appActions';
import { IDashboardData } from '../../../../../types/DashboardService'
import SectionHeader from "../SectionHeader/SectionHeader.component"
import SquareWithIconPart from '../SquareWithIconPart/SquareWithIconPart.component';
import * as S from "./HelpCentre.styles";

interface sectionProps {
    id: string;
    header: string;
    hideOnClick(id: string): void;
}

const HelpCentre = ({id, header, hideOnClick}: sectionProps) => {
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <S.SectionDiv>
            <SectionHeader id={id} header={header} hideOnClick={hideOnClick}/>
            <S.bodyDiv>
                <S.bodyContentDiv>
                    <SquareWithIconPart 
                        id="helpCentre" 
                        labelText="Help Centre"  
                        iconUrl="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/help_centre_icon.svg"
                        onClick={() => window.open("https://rent-chief.elevio.help/en", "_blank")}
                    />
                    <SquareWithIconPart 
                        id="helpVideo" 
                        labelText="Help Video"  
                        iconUrl="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/help_videos.svg"
                        onClick={() => window.open("https://www.youtube.com/channel/UC_L7MEqAtM6s5Gxm2FA2a8g", "_blank")}
                    />
                    <SquareWithIconPart 
                        id="suggestion" 
                        labelText="Have a suggestion?"  
                        iconUrl="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/suggestions.svg"
                        onClick={() => window.open("https://rent-chief.elevio.help/en/articles/78-lets-get-started-with-the-platform", "_blank")}
                    />
                </S.bodyContentDiv>
            </S.bodyDiv>
        </S.SectionDiv>
    )
}

export default HelpCentre;