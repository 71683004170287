import React from "react";
import styles from "./ElevioArticleEmbedWrapper.module.scss";

interface IProps{
  children: any;
}

class ElevioArticleEmbedWrapper extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div className={styles.elevioArticleEmbedWrapper}>
        {this.props.children}
      </div>
    );
  }
}

export default ElevioArticleEmbedWrapper;
