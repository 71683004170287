export const tradeEmptyStateHtml = 
`
<!DOCTYPE html>
<html>
    <head>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <style id="applicationStylesheet" type="text/css">
            #outer {
                tex-align: centre;
                width:100%;
                margin: auto;
            }
            #buttonSubstituteDiv {
                width: 200px;
                text-align: center;
                margin: auto;
                padding-top: 20px;
            }
            #image{
                height: 150px;
                width: 150px;
                text-align: center;
            }
            #imageDiv{
                text-align: center;
                padding: 10px;
            }
            #title{
                text-align: center;
            }
        </style>
    </head>
    <body>
        <div id="outer">
            <table>
                <tr>
                    <td>
                        <div id="imageDiv">
                            <img id="image" src="https://rentchiefstorageuk.blob.core.windows.net/config/client/shared/html/TradeEmptyState/trade.jpeg"/>
                        </div>
                    </td>
                    <td>
                        <div id="title">
                            <span>Use the trade portal to follow up on tasks assigned to you and even create new ones.  You can also chat with portal members you have access to.</span>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </body>
</html>
`