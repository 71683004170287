import styled from 'styled-components';

export const SectionDiv = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 21% 77%;
    grid-template-areas:
        'header header'
        'icon title';
        'icon summary';
        'icon action';
`; 

export const SectionNoHeaderDiv = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 21% 77%;
    grid-template-areas:
        'icon title';
        'icon summary';
        'icon action';
`; 

export const IconDiv = styled.div`
    grid-area: icon; 
    text-align: left;
`; 

export const BodyRightDiv = styled.div`
`;

export const TitleDiv = styled.div`
    grid-area: title;
    font-size: 20px;
    text-align: left;
    padding-bottom: 5px;
    color: #121212;
`; 

export const SummaryDiv = styled.div`
    grid-area: summary;
    font-size: 12px;
    text-align: left;     
    //padding-bottom: 10px;
    color: #121212;
`; 

export const ActionDiv = styled.div`
    grid-area: action;
`; 

export const IconImage = styled.img<{ imageSize?: string }>`
    width: ${p => p.imageSize ? p.imageSize : '70px'};
    height: ${p => p.imageSize ? p.imageSize : '70px'};
`;