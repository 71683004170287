import { Dispatch } from 'redux';
import axios from 'axios';
import { propertyService } from '../configuration/domains';
import { ICalculatorAddPropertyRequest } from '../types/PropertyService/IPropertyService';

export const addPropertyFromCalculator = (request: ICalculatorAddPropertyRequest) => {
    return (dispatch: Dispatch) => {

        dispatch(dispatchAppLoadingTrue());

        return axios.post(`${propertyService}/calculator-property`, request, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if(response.data != null){
                    dispatch(dispatchAddCalculatorPropertySuccess(response.data));
                    dispatch(dispatchAppLoadingFalse());
                }

            }).catch(error => {
                
            })
    }
}

export const dispatchAddCalculatorPropertySuccess = (propertyId: string) => {
    return {
        type: "ADD_PROPERTY_FROM_CALCULATOR_SUCCESS",
        propertyId
    }
}

export const dispatchAppLoadingTrue = () => {
    return {
        type: "APP_LOADING_TRUE"
    }
}


export const dispatchAppLoadingFalse = () => {
    return {
        type: "APP_LOADING_FALSE"
    }
}

