// Import React
import React, { Component } from "react";

import { Row, Col, FormGroup, Label, Button } from "reactstrap";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { withRouter, Route, Redirect } from "react-router-dom";
import styles from "./EditProfilePage.module.scss";
import { IUpdateProfile, IUserProfileResponse } from "../../../types/ProfileService/IProfile";
import {
  getUserInformation,
  updateUserInformation
} from "../../../actions/userActions";
import { IContactPreference } from "../../../types/ContactPreferencesService";
import { getContactPreferences } from "../../../services/ContactPreferencesService";
import { getPasswordChangeAudit } from "../../../services/CredentialsService";
import { IPasswordChangeAudit } from "../../../types/CredentialsService";

import "react-dropzone-uploader/dist/styles.css";
import { IAddress } from "../../../types/PropertyService/IPropertyService";
import SuccessTick from "../../atoms/SuccessTick";
import { updateProfile } from "../../../services/ProfileUpdateService";
import RotatingLoader from "../../atoms/RotatingLoader";
import UserProfilePage from "../UserPage/UserProfilePage";
import TabbedNavigationContainer from "../../atoms/TabbedNavigationContainer";
import SimpleRouterTabs from "../../atoms/SimpleRouterTabs";
import AuditPage from "../PlatformManagementPage/AuditPage";
import contactDetails from "../../../images/icons/contact_details.svg";
import contactAudit from "../../../images/icons/icon_timeline.svg";

interface IState {
  navSidebarOpen: boolean;
  termsAndConditionsOpen: boolean;
  changePasswordOpen: boolean;
  firstName: string;
  lastName: string;
  contactNumber: string;
  emailChecked: boolean;
  phoneChecked: boolean;
  smsChecked: boolean;
  postChecked: boolean;
  addressResult: any;
  newAddress: IAddress;
  passwordChangeAudit: IPasswordChangeAudit[];
  showPasswordChangeAudit: boolean;
  contactPreferences: IContactPreference;
  isSuccess: boolean;
  isLoading: boolean;
  isChangingProfilePicture: boolean;
}

interface IManualProps {
  userId?: string;
  contactId?: string;
  profileType?: ProfileType;
  userProfile: IUserProfileResponse;
}

enum ProfileType {
  User,
  Contact
}

interface IReduxProps {
  getUserInformation(): void;
  updateUserInformation(data: any): void;
  userId: string;
  profileId: string;
  forename: string;
  surname: string;
  profileImageUri: string;
  email: string;
  role: string;
  contactNumber: string;
  address: IAddress;
  clientName: string;
  contactPreferences: IContactPreference;
  accountCreatedOn: string;
  title: string;
  gender: string;
  citizen: string;
  clientId: string;
  emergencyContactPhone: number;
  emergencyContactName: string;
  emergencyContactEmail: string;
}

interface IRouterProps {
  history: any;
  location: any;
  match: any;
}

type Props = IRouterProps & IReduxProps & IManualProps;

class EditProfilePage extends Component<Props, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      emailChecked: false,
      phoneChecked: false,
      smsChecked: false,
      postChecked: false,
      navSidebarOpen: false,
      termsAndConditionsOpen: false,
      firstName: this.props.forename,
      lastName: this.props.surname,
      contactNumber: this.props.contactNumber,
      addressResult: null,
      contactPreferences: this.props.contactPreferences,
      newAddress: this.props.address || {
        building_Number: "",
        line1: "",
        line2: "",
        street_Name: "",
        town: "",
        county: "",
        country: "",
        postcode: "",
        locality: "",
        district: ""
      },
      passwordChangeAudit: [],
      showPasswordChangeAudit: false,
      changePasswordOpen: false,
      isSuccess: false,
      isLoading: false,
      isChangingProfilePicture: false
    };

    this.onSetSidebarNavOpen = this.onSetSidebarNavOpen.bind(this);
    this.onToggleTermsAndConditionsOpen = this.onToggleTermsAndConditionsOpen.bind(
      this
    );
    this.onToggleTermsAndConditionsClose = this.onToggleTermsAndConditionsClose.bind(
      this
    );
    this.handleChange = this.handleChange.bind(this);
    this.onUpdateSubmitted = this.onUpdateSubmitted.bind(this);
    this.resetAddress = this.resetAddress.bind(this);
    this.getContactPreferences = this.getContactPreferences.bind(this);
    this.toggleContactPreferencesState = this.toggleContactPreferencesState.bind(
      this
    );
    this.selectAddressCallback = this.selectAddressCallback.bind(this);
    this.handleChangeAddress = this.handleChangeAddress.bind(this);
    this.getPasswordChangeAudit = this.getPasswordChangeAudit.bind(this);
    this.onSetPasswordChangeOpen = this.onSetPasswordChangeOpen.bind(this);
    this.onSetPasswordChangeClose = this.onSetPasswordChangeClose.bind(this);
  }

  getContactPreferences() {
    getContactPreferences().then(x => {
      if (x != null) {
        this.setState({
          contactPreferences: x.data
        });
      }
    }).catch(e => {});
  }

  getPasswordChangeAudit() {
    if (this.state.passwordChangeAudit.length < 1) {
      getPasswordChangeAudit().then(resp => {
        if (resp) {
          this.setState({
            passwordChangeAudit: resp.data
          });
        }
      });
    }
  }

  toggleContactPreferencesState(preference: string) {
    let contactPreference: IContactPreference = this.state.contactPreferences;
    contactPreference[preference] = !contactPreference[preference];
    this.setState({
      contactPreferences: contactPreference
    });
  }

  onSetPasswordChangeOpen() {
    this.setState({
      changePasswordOpen: true
    });
  }

  onSetPasswordChangeClose() {
    this.setState({
      changePasswordOpen: false
    });
  }

  onSetSidebarNavOpen(open) {
    this.setState({ navSidebarOpen: open });
  }

  onToggleTermsAndConditionsOpen() {
    this.setState({
      termsAndConditionsOpen: true
    });
  }

  onToggleTermsAndConditionsClose() {
    this.setState({
      termsAndConditionsOpen: false
    });
  }

  resetAddress() {
    this.setState({
      addressResult: null
    });
  }

  handleChange(event: React.FormEvent<HTMLInputElement>) {
    const field = event.currentTarget.name;
    const value: string = event.currentTarget.value;
    this.setState({ [field]: value } as Pick<IState, any>);
  }

  selectAddressCallback(address: IAddress) {
    this.setState({
      newAddress: address
    });
  }

  componentWillMount() {
    this.getPasswordChangeAudit();
  }

  handleChangeAddress(event: React.FormEvent<HTMLInputElement>) {
    const field: string = event.currentTarget.name;
    const value: string = event.currentTarget.value;
    let manualaddress = this.state.newAddress;
    manualaddress[field] = value;
    this.setState({ newAddress: manualaddress });
  }

  onUpdateSubmitted() {
    const { firstName, lastName, contactNumber, newAddress } = this.state;
    this.setState({
      isLoading: true
    });

    let request: IUpdateProfile = {
      profile: {
        saluation: "Mr",
        forename: firstName ? firstName.trim() : null,
        surname: lastName ? lastName.trim() : null,
        contactNumber: contactNumber ? contactNumber.trim() : null,
        emergencyContactEmail: '',
        emergencyContactName: '',
        emergencyContactPhone: ''
      },
      address: newAddress,
      contactPreferences: {
        email: this.state.contactPreferences.email,
        phone: this.state.contactPreferences.phone,
        sms: this.state.contactPreferences.sms,
        post: this.state.contactPreferences.post
      },
      clientId: ''
    };

    updateProfile(request, "").then(resp => {
      if (resp.status == "200") {
        this.props.updateUserInformation(request);
        this.setState({
          isLoading: false,
          isSuccess: true
        });

        setTimeout(() => {
          this.setState({
            isSuccess: false
          });
        }, 2000);
      }
    });
  }

  componentDidUpdate() {
    if (document && document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
  }

  render() {
    if (this.state.isSuccess) {
      return (
        <div>
          <SuccessTick />
          <p>Your profile has been sucessfully updated</p>
        </div>
      );
    }

    if (this.state.isLoading) {
      return (
        <div className={styles.loader}>
          <RotatingLoader loading={true} text="Updating your profile..." />
        </div>
      );
    }

    return (
      <div className={styles.page}>
        <div className={styles.navigation}>
          <TabbedNavigationContainer fullWidth>
            <Col className={styles.tabs}>
              <SimpleRouterTabs
                routes={[
                  {
                    path: "general",
                    text: "General",
                    mobileText: 'General',
                    icon: contactDetails
                  },
                  {
                    path: "timeline",
                    text: "Timeline",
                    mobileText: 'Timeline',
                    icon: contactAudit
                  }
                ]}
              />
            </Col>
          </TabbedNavigationContainer>
        </div>

        <div className={styles.container}>

          <Route
            path={`${this.props.match.url}`}
            exact
            render={() => <Redirect to={`${this.props.match.url}/general`} />}
          />

          <Route
            path={`${this.props.match.path}/general`}
            render={() => (
              <UserProfilePage isLoggedInUser={true} userProfile={{
                profile: {
                  profileId: this.props.profileId,
                  forename: this.props.forename,
                  surname: this.props.surname,
                  profileImageUri: this.props.profileImageUri,
                  email: this.props.email,
                  contactEmail: this.props.contactPreferences.email,
                  contactPhone: this.props.contactPreferences.phone,
                  contactSms: this.props.contactPreferences.sms,
                  contactPost: this.props.contactPreferences.post,
                  citizen: this.props.citizen,
                  title: this.props.title,
                  gender: this.props.gender,
                  contactNumber: this.props.contactNumber,
                  emergencyContactPhone: this.props.emergencyContactPhone,
                  emergencyContactEmail: this.props.emergencyContactEmail,
                  emergencyContactName: this.props.emergencyContactName
                },
                address: this.props.address,
                user: {
                  clientId: this.props.clientId
                }
              }} />
            )}
          />


          <Route
            path={`${this.props.match.path}/timeline`}
            render={() => (
              <AuditPage userId={this.props.userId} hideDropdown />
            )}
          />

        </div>

      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  userId: state.user.user_id,
  profileId: state.user.profile_id,
  forename: state.user.forename,
  surname: state.user.surname,
  profileImageUri: state.user.profileImage,
  email: state.user.email,
  accountCreatedOn: state.user.accountCreatedOn,
  role: state.user.role,
  contactNumber: state.user.contactNumber,
  address: state.user.address,
  clientName: state.client.name,
  contactPreferences: state.user.contactPreferences,
  citizen: state.user.citizen,
  gender: state.user.gender,
  title: state.user.title,
  clientId: state.client.id,
  emergencyContactName: state.user.emergencyContactName,
  emergencyContactPhone: state.user.emergencyContactPhone,
  emergencyContactEmail: state.user.emergencyContactEmail
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getUserInformation: () => {
      dispatch<any>(getUserInformation());
    },
    updateUserInformation: (data: any) => {
      dispatch(updateUserInformation(data));
    }
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProfilePage) as any);