import { apiService } from './ApiService';
import { createBearerToken } from '../helpers/authHelpers';
import * as domains from '../configuration/domains';
import { IInspectionsResultDto } from '../types/Dynamic/types';

export const updateInspectionResultApi = (request: IInspectionsResultDto): Promise<any> => {
    return apiService.put(`${domains.conductInspectionService}`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getInspectionResultFromApi = (inspectionId: number): Promise<any> => {
    return apiService.get(`${domains.conductInspectionService}/${inspectionId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const insertInspectionResultApi = (request: IInspectionsResultDto): Promise<any> => {
    return apiService.post(`${domains.conductInspectionService}`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getConductInspectionTemplatesFromApi = (): Promise<any> => {
    return apiService.get(`${domains.conductInspectionService}/templates`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}