import React from 'react';
import { getStatusPillValues, getStatusPillValuesForCheckItem } from "./_helpers/statusHelper";
import * as S from './StatusPill.styles';

interface dateProps {
    date?: Date;
}

interface checkProps {
    checkCompleted: boolean;
}

export const DateStatusPill = ({date}: dateProps) => {
    let vals = getStatusPillValues(date);
    return <S.MainDiv backgroundColor={vals.backgroundColor}>{vals.displayText}</S.MainDiv>;
}

export const CheckStatusPill = ({checkCompleted}: checkProps) => {
    let vals = getStatusPillValuesForCheckItem(checkCompleted);
    return <S.MainDiv backgroundColor={vals.backgroundColor}>{vals.displayText}</S.MainDiv>;
}
