export const dispatchAppLoading = () => {
  return {
    type: "APP_LOADING_TRUE",
  };
};

export const dispatchOverlay = (show: boolean) => {
  return {
    type: "APP_OVERLAY",
    show: show,
  };
};

export const toggleSidebar = (show: boolean) => {
  return {
    type: "APP_TOGGLE_SIDEBAR",
    show,
  };
};

export const toggleAddPropertySidebar = (show: boolean) => {
  return {
    type: "APP_TOGGLE_ADD_PROPERTY",
    show,
  };
};

export const toggleProfileSidebar = (show: boolean) => {
  return {
    type: "APP_TOGGLE_PROFILE_SIDEBAR",
    show,
  };
};

export const toggleFilterSidebar = (show: boolean) => {
  return {
    type: "APP_TOGGLE_FILTER_SIDEBAR",
    show,
  };
};

export const toggleUploadPropertyImageSidebar = (show: boolean) => {
  return {
    type: "APP_TOGGLE_UPLOAD_PROPERTY_IMAGE",
    show,
  };
};

export const toggleAddUserSidebar = (show: boolean) => {
  return {
    type: "APP_TOGGLE_ADD_USER",
    show,
  };
};

export const toggleEditPropertySidebar = (show: boolean) => {
  return {
    type: "APP_TOGGLE_EDIT_PROPERTY",
    show,
  };
};

export const toggleAddDocumentSidebar = (show: boolean) => {
  return {
    type: "APP_TOGGLE_ADD_DOCUMENT",
    show,
  };
};

export const toggleAddDocumentPopup = (show: boolean) => {
  return {
    type: "APP_TOGGLE_ADD_DOCUMENT_POPUP",
    show,
  };
};

export const toggleEditRegulationtPopup = (show: boolean) => {
  return {
    type: "APP_TOGGLE_EDIT_REGULATION_POPUP",
    show,
  };
};

export const toggleAddClientSidebar = (show: boolean) => {
  return {
    type: "APP_TOGGLE_ADD_CLIENT",
    show,
  };
};

export const saveChangesEditProperty = (refreshRequired: boolean) => {
  return {
    type: "APP_SAVE_CHANGES_EDIT_PROPERTY",
    refreshRequired,
  };
};

export function toggleTenancyActionSidebar(show: boolean) {
  return {
    type: "APP_TOGGLE_TENANCY_ACTION_SIDEBAR",
    show,
  };
}

export const toggleAddContactSidebar = (show: boolean) => {
  return {
    type: "APP_TOGGLE_ADD_CONTACT",
    show,
  };
};

export const toggleAssignRoleSidebar = (show: boolean) => {
  return {
    type: "APP_TOGGLE_ASSIGN_ROLE",
    show,
  };
};
