import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { IDocumentEditInformation } from "../../../types/DocumentService";
import EditDocumentModal from "../EditDocumentModal";

interface IProps {
  edit_document_popup: boolean;
}

interface IState {
  document: IDocumentEditInformation;
  loading: boolean;
}

class EditDocumentContainer extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      document: null,
      loading: false
    }
  }

  render() {
    if (this.props.edit_document_popup) {
      return <EditDocumentModal />
    }

    return null;
  }
}

const mapStateToProps = (state: any) => ({
  edit_document_popup: state.app.popups.edit_document_popup
});

export default withRouter(connect(
  mapStateToProps,
)(EditDocumentContainer) as any);