import { store } from "../store";
import { IInspectionDto } from "../types/Inspection";

export function updateViewingInspection(inspection: IInspectionDto): void {
  store.dispatch({
    type: "UPDATE_VIEWING_INSPECTION",
    data: inspection
  });
}

export function updateViewingInspectionHasResult(hasResult: boolean): void {
  store.dispatch({
    type: "UPDATE_HAS_INSPECTION_RESULT",
    data: hasResult
  });
}