import React from "react";
import { Label } from "reactstrap";

import styles from "./EllipsisText.module.scss";

interface IProps {
  text: string;
  length: number;
}

const EllipsisText = ({ text, length }: IProps) => {
  if(!text) return null;

  return <label className={styles.ellipsis}>
      {text.substring(0, length)}
      {text.length > length && "..."}
    </label>
}

export default EllipsisText;
