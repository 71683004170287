import { store } from "../store";
import { ITenancyListItem, ITenancySummary } from "../types/Tenancy";
import { IOptions } from "../components/atoms/SelectDropdown";
import { IAddress, IProperty } from "../types/PropertyService/IPropertyService";
import { ICalculateReturnsData } from "../reducers/propertyReducer";
import { IData } from "../components/atoms/PropertyListItem";
import { IImageDto } from "../types/ImageService";

export function updatePropertyListItem(data: IProperty) {
  let current = store.getState().property.property_data.property_list_data;
  let copy = current.map((object: IData) => ({ ...object }));

  let index = copy.findIndex(x => x.property.propertyId == data.propertyId);
  if (!index) {
    return;
  }

  copy[index].status = data.propertyStatus;

  store.dispatch({
    type: "UPDATE_PROPERTY_LIST",
    data: copy
  });
}

export function updatePropertyImages(data: Array<IImageDto>) {
  store.dispatch({
    type: "UPDATE_PROPERTY_IMAGES",
    data: data
  });
}

export function updateCalculateReturnsProperty(data: ICalculateReturnsData) {
  store.dispatch({
    type: "UPDATE_CALCULATE_RETURNS_PROPERTY",
    data: data
  });
}

export function updatePropertyType(type: string) {
  store.dispatch({
    type: "UPDATE_PROPERTY_TYPE",
    data: type
  });
}

export function updatePropertyAddress(address: IAddress) {
  store.dispatch({
    type: "UPDATE_PROPERTY_ADDRESS",
    data: address
  });
}

export function updatePropertySummaryTenantContact(tenancyContactId: string) {
  store.dispatch({
    type: "UPDATE_PROPERTY_SUMMARY_TENANT_CONTACT",
    data: tenancyContactId
  });
}

export function updateCurrentValuation(currentValuation: number) {
  store.dispatch({
    type: "UPDATE_PROPERTY_CURRENT_VALUATION",
    data: currentValuation
  });
}

export function updateCurrentRentalValuation(currentValuation: number) {
  store.dispatch({
    type: "UPDATE_PROPERTY_CURRENT_RENTAL_VALUATION",
    data: currentValuation
  });
}

export function updateComposition(data: any) {
  store.dispatch({
    type: "UPDATE_PROPERTY_COMPOSITION",
    data
  });
}

export function updatePropertyTypes(data: any) {
  store.dispatch({
    type: "UPDATE_PROPERTY_TYPES",
    data
  });
}


export function updateViewingPropertyImage(data: any) {
  store.dispatch({
    type: "UPDATE_VIEWING_PROPERTY_IMAGE",
    data
  });
}

export function updateViewingTenancy(data: any) {
  store.dispatch({
    type: "UPDATE_VIEWING_TENANCY",
    data
  });
}

export function addTenancyListItemToList(data: ITenancyListItem) {
  let state = store.getState();
  var newArray = state.property.tenancy.tenancy_list.slice();
  newArray.unshift(data);

  store.dispatch({
    type: "ADD_TENANCY_LIST_ITEM",
    data: newArray
  });
}

export function removeTenancyItem(tenancyId: string) {
  let state = store.getState();
  let tenancy = state.property.tenancy.tenancy_list.filter(t => t.tenancyId != tenancyId);

  updateTenancyList(tenancy);
}

export function updateTenancyList(data: Array<ITenancyListItem>) {
  store.dispatch({
    type: "ADD_TENANCY_LIST_ITEM",
    data: data
  });
}

export function toggleHasTenancyFlag(data: boolean) {
  store.dispatch({
    type: "TOGGLE_HAS_TENANCY_FLAG",
    data: data
  });
}

export function canSelectNumberOfSpaces(selectedId: string, propertyTypes: Array<IOptions>): boolean {
  let selected = propertyTypes.findIndex(x => x.value == selectedId);
  if (selected < 0) {
    return false;
  }

  let normalised = propertyTypes[selected].label.toLocaleLowerCase();
  return (normalised == "allocated"
    || normalised == "covered"
    || normalised == "driveway"
    || normalised == "garage"
    || normalised == "gated"
    || normalised == "off street");
}