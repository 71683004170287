import styled from 'styled-components';

export const SectionDiv = styled.div`
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
`; 

export const ActionDiv = styled.div`
    width: 150px;
`;