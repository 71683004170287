import { store } from "../store";

export function toggleActiveTab(active: boolean) {
    store.dispatch({
        type: "TOGGLE_TAB_ACTIVE",
        data: active
    });
}

export function toggleStickyNav(sticky: boolean) {
    store.dispatch({
        type: "TOGGLE_STICKY_NAV",
        data: sticky
    });
}

export function showAppLoadingScreen() {
    store.dispatch({
        type: "APP_LOADING_TRUE"
    });
}

export function hideAppLoadingScreen() {
    store.dispatch({
        type: "APP_LOADING_FALSE"
    });
}