import { ITeamMemberDto, TeamsAction, TeamsActionType, ITeamMembersListResponse, IEditTeamName, IInviteTeamMemberOptions } from "../../types/Teams";
import { IDeleteState } from "../../types/Delete";

interface ITeamsState {
    add_loading: boolean;
    adding: boolean;
    add_options: IInviteTeamMemberOptions;
    loading: boolean;
    error: string | null;
    data: ITeamMembersListResponse;
    viewing_teamMember: ITeamMemberDto;
    delete: IDeleteState;
    edit_team_name: IEditTeamName;
}

const initialState: ITeamsState = {
    add_loading: false,
    adding: false,
    add_options: null,
    loading: false,
    error: null,
    data: { isPrincipleUser: null, teamName: null, data: [], count: 0 },
    viewing_teamMember: null,
    delete: {
        loading: false,
        ids: null,
        modelOpen: false,
        returnUrl: null
    },
    edit_team_name: {
        loading: false,
        isEditing: false
    }
}

const reducer = (
    state: ITeamsState = initialState,
    action: TeamsAction
): ITeamsState => {
    switch (action.type) {
        case TeamsActionType.CLEAR_TEAM_MEMBERS:
            return initialState
        case TeamsActionType.EDIT_TEAM_NAME_ERROR:
            return {
                ...state,
                edit_team_name: {
                    ...state.edit_team_name,
                    loading: false
                },
                error: action.payload
            }
        case TeamsActionType.EDIT_TEAM_NAME_SUCCESS:
            return {
                ...state,
                edit_team_name: {
                    ...state.edit_team_name,
                    loading: false,
                    isEditing: false
                },
                data: {
                    ...state.data,
                    teamName: action.payload
                }
            }
        case TeamsActionType.EDIT_TEAM_NAME:
            return {
                ...state,
                edit_team_name: {
                    ...state.edit_team_name,
                    loading: true
                }
            }
        case TeamsActionType.TOGGLE_EDIT_TEAM_NAME:
            return {
                ...state,
                edit_team_name: {
                    ...state.edit_team_name,
                    isEditing: action.payload
                }
            }
        case TeamsActionType.TOGGLE_TEAM_MEMBER_ACCESS:
            return {
                ...state,
                loading: true
            }
        case TeamsActionType.TOGGLE_TEAM_MEMBER_ACCESS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    data: state.data.data
                        .map((object) => (object.userTeamId == action.payload.userTeamId ? action.payload : object))
                    , count: state.data.count
                }
            }
        case TeamsActionType.TOGGLE_TEAM_MEMBER_ACCESS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case TeamsActionType.CANCEL_TEAM_MEMBER_INVITE:
            return {
                ...state,
                loading: true
            }
        case TeamsActionType.CANCEL_TEAM_MEMBER_INVITE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: { ...state.data, data: state.data.data.map((object) => ({ ...object })).filter(x => x.contactId != action.payload), count: state.data.count - 1 }
            }
        case TeamsActionType.CANCEL_TEAM_MEMBER_INVITE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case TeamsActionType.TOGGLE_ADD_TEAM_MEMBER_SIDE_PANEL:
            return {
                ...state,
                adding: action.payload.open,
                add_options: action.payload.options
            }
        case TeamsActionType.ADD_TEAM_MEMBER:
            return {
                ...state,
                add_loading: true,
                error: null
            }
        case TeamsActionType.ADD_TEAM_MEMBER_ERROR:
            return {
                ...state,
                add_loading: false,
                error: action.payload
            }
        case TeamsActionType.ADD_TEAM_MEMBER_SUCCESS:
            return {
                ...state,
                add_loading: false,
                data: {
                    ...state.data,
                    data: action.payload ? [action.payload].concat(state.data.data) : state.data.data,
                    count: state.data.count + 1
                }
            }
        case TeamsActionType.GET_TEAM_MEMBERS:
        case TeamsActionType.ADD_TEAM_MEMBER:
        case TeamsActionType.REMOVE_TEAM_MEMBERS:
            return {
                ...state,
                loading: true,
                error: null
            }
        case TeamsActionType.GET_TEAM_MEMBERS_ERROR:
        case TeamsActionType.ADD_TEAM_MEMBER_ERROR:
        case TeamsActionType.REMOVE_TEAM_MEMBERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case TeamsActionType.GET_TEAM_MEMBERS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case TeamsActionType.REMOVE_TEAM_MEMBERS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    data: state.data.data.filter(x => !action.payload.includes(x.userTeamId)),
                    count: action.payload.length > 0 ? state.data.count - 1 : state.data.count
                },
                delete: {
                    loading: false,
                    modelOpen: false,
                    ids: null,
                    returnUrl: null
                }
            }
        default:
            return state;
    }
};

export default reducer;