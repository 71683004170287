import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import Buttons from "../../atoms/Buttons";
import { 
    calculateEndDateFromDuration,
    calculateDaysCountBetweenDates } from "../../../helpers/tenancyHelpers";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectDropdown, { IOptions } from "../../atoms/SelectDropdown";
import TenantDetailsSection from "../../organisms/TenancyPage/TenantDetailsSection/TenantDetailsSection.component";
import { Button, RCCurrencyInput } from "@rentchief/components";
import { durations, rentPaymentDurations } from "./_config/staticData";
import StickyComponent from "../../atoms/StickyComponent";
import RotatingLoader from "../../atoms/RotatingLoader";
import * as S from './TransactionsSchedule.styles'
import styles from './TransactionsSchedule.module.scss'
import { IAddTransactionRequest, IAutoGenerateTransactionsRequest } from "../../../types/Transaction";
import { addTransactions, autoGenerateTransactions } from "../../../services/TransactionService";
import { ITransactionsScheduleConfig } from "./_config/config";
import RCCheckbox from "../../atoms/RCCheckbox/RCCheckbox.component";
import { processToast } from "../../../helpers/toastHelper";
import { getCurrentDate } from "../../../helpers/timerHelpers";
import TransactionsScheduleGrid from "./TransactionsScheduleGrid/TransactionsScheduleGrid.component";

interface props {
    request: IAutoGenerateTransactionsRequest;
    showInternalSaveButton?: boolean;
    onGenerateTransactionsCompleted?(transactions: Array<IAddTransactionRequest>): void; 
}

const TransactionsSchedule = ({ request, showInternalSaveButton, onGenerateTransactionsCompleted } : props) => {    
    const config = useRef<ITransactionsScheduleConfig>(require("./_config/config.json"));

    const[submitting, setSubmitting] = useState<boolean>(false);

    const [requestItem, setRequestItem] = useState<IAutoGenerateTransactionsRequest>(null);
    const [transactionsData, setTransactionsData] = useState<Array<IAddTransactionRequest>>(null);
    const [showMarkPastAsPaid, setShowMarkPastAsPaid] = useState<boolean>(false);

    useEffect(() => {
        if(request){
            if(!requestItem){
                setRequestItem(request);
                generateTransactions(request);
            }else{
                let updated: IAutoGenerateTransactionsRequest = {
                    ...requestItem,
                    dueDay: request.dueDay
                }
                setRequestItem(updated);
                generateTransactions(updated);
            }
            
            setShowMarkPastAsPaid(new Date(request.startDate) < getCurrentDate())
        }
    }, [request]);

    const onChangeStartDate = (date: Date, props?: any) => {
        if (!date) {
          return;
        }
    
        let d = moment(date).utcOffset(0, true).set({ hours: 0, minutes: 0 }).format();
        date = new Date(d);
            
        var newEndDate = calculateEndDateFromDuration(props.values.durationLength, props.values.durationPeriod, date);

        if(props){
            props.setFieldValue('startDate', date);
            props.setFieldValue('endDate', newEndDate);
        }

        setRequestItem({
            ...requestItem,
            startDate: date.toString(),
            endDate: newEndDate.toString()
        });

        setShowMarkPastAsPaid(date < getCurrentDate());
    };

    const onChangeEndDate = (date: Date, props: any) => {
        props.setFieldValue('endDate', date);

        let days = calculateDaysCountBetweenDates(props.values.startDate, date);
        props.setFieldValue('durationLength', days);
        props.setFieldValue('durationPeriod', 'Days');

        setRequestItem({
            ...requestItem,
            endDate: date.toString(),
            durationLength: days,
            durationPeriod: 'Days'
        });
    }
    
    const onChangeDurationValue = (e: any, props: any): void => {
        const value: string = e.currentTarget.value;
        props.setFieldValue(e.currentTarget.name, value != "" ? Number(value) : null);
        var newEndDate = calculateEndDateFromDuration(
            value != "" && value != "0"  ? props.values.durationLength : 1,
            props.values.durationPeriod,
            props.values.startDate
          );
        props.setFieldValue('endDate', newEndDate);
    }
    
    const onBlurDurationValue = (e: any, props: any): void => {
        const value: string = e.currentTarget.value
        props.setFieldValue(e.currentTarget.name, value != "" ? Number(value) : null);
        var newEndDate = calculateEndDateFromDuration(
            value != "" && value != "0"  ? props.values.durationLength : 1,
            props.values.durationPeriod,
            props.values.startDate
          );
        props.setFieldValue('endDate', newEndDate);

        setRequestItem({
            ...requestItem,
            endDate: newEndDate.toString(),
            durationLength: value != "" ? Number(value) : null,
        })
    }
    
    const onChangeDurationRange = (range: IOptions, props: any): void => {
        props.setFieldValue('durationPeriod', range.value);
        var newEndDate =  calculateEndDateFromDuration(
            props.values.durationLength,
            range.value,
            props.values.startDate
          )

        props.setFieldValue('endDate', newEndDate);

        setRequestItem({
            ...requestItem,
            endDate: newEndDate.toString(),
            durationPeriod: range.value,
        });
    }

    const generateTransactions = async(values?: any) => {
        let newRequest: IAutoGenerateTransactionsRequest = {
            ...values,
            startDate: moment(values.startDate).format("MM-DD-YYYY HH:mm:ss"),
            endDate: moment(values.endDate).format("MM-DD-YYYY HH:mm:ss"),
            amount: values.amount,
            frequency: values.frequency,
            markPastAsPaid: values.markPastAsPaid,
        }

        let resp = await autoGenerateTransactions(newRequest);
        if(resp && resp.status == 200)
        {
            setTransactionsData(resp.data.transactions);
            if(onGenerateTransactionsCompleted){
                onGenerateTransactionsCompleted(resp.data.transactions);
            }
        }
    }

    const updateMarkPastAsPaidForTransactions = (markAsPaid: boolean) => {
        let today = new Date();
        let updatedTransactions : IAddTransactionRequest[] = transactionsData;

        updatedTransactions.map((item: IAddTransactionRequest) => {
            if(new Date(item.dueDate) < today){
                item.isPaidInFull = markAsPaid;
                item.paidDate = markAsPaid ? item.dueDate : null;
            }
        })

        setTransactionsData(updatedTransactions);
        onGenerateTransactionsCompleted(updatedTransactions);
    }

    const saveTransactions = async() => {
        try{
            setSubmitting(true);
            let resp = await addTransactions(transactionsData);
            if(resp && resp.data)
            {
                setSubmitting(false);
                processToast(resp.data);
            }
        }catch{
            setSubmitting(false);
        }
    }

    if (submitting) {
        return (
          <div>
            <RotatingLoader text={"Saving transactions..."} loading={true} />
          </div>
        );
    }

    if (!requestItem) {
        console.log("TransactionsSchedule - if (!requestItem)")
        return (
          <div>
            <RotatingLoader text={"Loading transactions..."} loading={true} />
          </div>
        );
    }

    if (requestItem) {
        console.log("TransactionsSchedule - if (requestItem) - About to attempt display")
        const myJSON = JSON.stringify(request);
        console.log("TransactionsSchedule - if (requestItem):  " + myJSON);
    }

    return (
        <>  
            <Formik
                initialValues={{ ...requestItem }}
                enableReinitialize={true}
                onSubmit={(values: any) => saveTransactions()}
            >
                {(props) => (
                    <>
                        {
                            <S.ContainerDiv>
                            {
                                <S.SectionDiv>
                                    <TenantDetailsSection
                                        config={config.current.datesAndDuration}
                                        //tooltip={epcTooltip}
                                        selectionControl={
                                            <div className={styles.flexibleWrapper}>
                                                <div className={styles.dateContainer}>
                                                    <div className={styles.datePickerWrapper}>
                                                        <DatePicker
                                                            todayButton="Today"
                                                            dateFormat="dd/MM/yyyy"
                                                            className={styles.input}
                                                            selected={moment(props.values.startDate).toDate()}
                                                            onChange={(date: Date) => onChangeStartDate(date, props)}
                                                        />
                                                    </div>
                                                </div>
                                                <span className={styles.durationWrapper}>
                                                    <input
                                                        name="durationLength"
                                                        className={styles.durationLengthInput}
                                                        type="number"
                                                        pattern="[0-9]*"
                                                        value={props.values.durationLength}
                                                        onChange={e => onChangeDurationValue(e, props)}
                                                        onBlur={e => onBlurDurationValue(e, props)}
                                                    />
                                                    <div className={styles.durationSelect}>
                                                        <SelectDropdown
                                                            data={durations}
                                                            placeholder="Duration..."
                                                            onSelect={(id: IOptions) => 
                                                                onChangeDurationRange(id, props)
                                                            }
                                                            selectedId={props.values.durationPeriod}
                                                        />
                                                    </div>
                                                </span>
                                                <div className={styles.dateContainer}>
                                                    <div className={styles.datePickerWrapper}>
                                                        <DatePicker
                                                            required={false}
                                                            todayButton="Today"
                                                            minDate={moment(props.values.startDate).toDate()}
                                                            dateFormat="dd/MM/yyyy"
                                                            className={styles.input}
                                                            selected={
                                                                props.values.endDate &&
                                                                moment(props.values.endDate).local().toDate()
                                                            }
                                                            onChange={(date: Date) => onChangeEndDate(date, props)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        
                                    />
                                </S.SectionDiv>
                            }
                            {
                                <S.SectionDiv>
                                    <TenantDetailsSection
                                        config={config.current.amountAndFrequency}
                                        //tooltip={hmoTooltip}
                                        selectionControl={
                                            <span className={styles.durationWrapper}>
                                                <div className={styles.currencyInputWrapper}>
                                                    <RCCurrencyInput
                                                        name="amount"
                                                        value={props.values.amount}
                                                        prefix="£"
                                                        handleChange={(e) => {
                                                            props.setFieldValue('amount', e);
                                                            setRequestItem({
                                                                ...requestItem,
                                                                amount: e
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className={styles.rentDurationSelect}>
                                                    <SelectDropdown
                                                        data={rentPaymentDurations}
                                                        showDefaultOption
                                                        placeholder="Frequency..."
                                                        onSelect={(id: IOptions) => {
                                                            props.setFieldValue("frequency", id.value);
                                                            setRequestItem({
                                                                ...requestItem,
                                                                frequency: id.value
                                                            });
                                                        }}
                                                        selectedId={props.values.frequency}
                                                    />
                                                </div>
                                            </span>}
                                        selectionControlWidth={"260px"}
                                    />
                                </S.SectionDiv>
                            }
                            {
                                showMarkPastAsPaid
                                &&
                                <S.SectionDiv>
                                    <TenantDetailsSection
                                        config={config.current.markPastAsPaid}
                                        //tooltip={hmoTooltip}
                                        selectionControl={
                                            <div className={styles.flexibleWrapper}>
                                                <RCCheckbox field="markPastAsPaid" label="Mark past as paid" props={props} onChange={updateMarkPastAsPaidForTransactions} />
                                            </div>
                                        }
                                    />
                                </S.SectionDiv>
                            }
                            {
                                // Generate transactions button
                                <S.SectionDiv>
                                    <S.ButtonWrapper>
                                        <Button onClick={() => generateTransactions(props.values)}>Update</Button>
                                    </S.ButtonWrapper>
                                </S.SectionDiv>
                            }
                            {
                                <S.SectionDiv>
                                    <TransactionsScheduleGrid datasource={transactionsData} />
                                </S.SectionDiv>
                            }
                            </S.ContainerDiv>
                        }

                        <div className={styles.sectionSpacer} />

                        {/* buttons */}
                        {
                            showInternalSaveButton &&
                            <div className={styles.buttons}>
                                <StickyComponent>
                                    <div className={styles.stickyComponentContentWrapper}>
                                        <Buttons
                                            buttons={[
                                                {
                                                    text: 'Save Transactions',
                                                    displayType: 'submit',
                                                    elementType: 'button',
                                                    onClick: () => props.submitForm(),
                                                },
                                            ]}
                                        />
                                    </div>
                                </StickyComponent>
                            </div>
                        }
                        
                        
                    </>
                )}
            </Formik>
        </>
    )
}

export default TransactionsSchedule;