import React from 'react'
import { withRouter } from 'react-router-dom'
import { addURLParameter } from '../../../helpers/locationHelpers'
import { isTenant } from '../../../helpers/roleHelpers'
import { toggleIssuePopup } from '../../../helpers/sidebarHelpers'
import issuesIcon from '../../../images/icons/issue_summary_component.svg'
import { IReactRouterProps } from '../../../types'
import { IRaiseIssueOptions } from '../AddEditIssue'
import styles from './IssuesStatus.module.scss'

interface IProps {
    issuesCountNew: number
    issuesCountInProgress: number
    issuesCountTotal: number
    showIcon?: boolean
    propertyId?: string
    propertyName?: string
    goToListPage?: boolean
    canAdd?: boolean
}

type Props = IProps & IReactRouterProps

const IssueStatus = (props: Props) => {
    const onNavigate = (status?: string): void => {
        var url = '/dashboard/issues'

        if (status) {
            url = '/dashboard/issues?status=' + status
        }

        if (props.propertyId) {
            if (isTenant()) {
                url = '/dashboard/issues?propertyId=' + props.propertyId
            } else {
                url = `/dashboard/property/${props.propertyId}/issues`

                if (status) {
                    url += '?status=' + status
                }
            }
        }

        props.history.push(url)
    }

    const onAddIssue = (): void => {
        let options: IRaiseIssueOptions = {
            propertyName: props.propertyName,
            propertyId: props.propertyId,
        }

        if (props.match.params.propertyId) {
            options.isOnPropertyPage = true
        }

        let stringed = 'issueOptions=' + JSON.stringify(options)

        let query = addURLParameter(props.location.search, stringed)
        props.history.push({
            search: query,
        })

        toggleIssuePopup(true)
    }

    return (
        <div className={styles.issuesStatus}>
            <div className={styles.columns}>
                <div
                    className={styles.column}
                    onClick={() => onNavigate('New')}
                >
                    <span className={`${styles.number} ${styles.numberNew}`}>
                        {props.issuesCountNew}
                    </span>
                    <span>New</span>
                </div>
                <div
                    className={styles.column}
                    onClick={() => onNavigate('In Progress')}
                >
                    <span
                        className={`${styles.number} ${styles.numberInProgress}`}
                    >
                        {props.issuesCountInProgress}
                    </span>
                    In Progress
                    <span></span>
                </div>
                <div className={styles.column} onClick={() => onNavigate()}>
                    <span className={`${styles.number} ${styles.numberTotal}`}>
                        {props.issuesCountTotal}
                    </span>
                    <span>
                        {`Issue${
                            props.issuesCountTotal > 1 ||
                            props.issuesCountTotal === 0
                                ? 's'
                                : ''
                        }`}
                    </span>
                </div>
                {props.showIcon && (
                    <div className={styles.column}>
                        <span
                            className={styles.baseIcon}
                            style={{
                                backgroundImage: `url(${issuesIcon}`,
                                backgroundColor: '#F18585',
                            }}
                        />
                    </div>
                )}
            </div>

            {props.canAdd && (
                <div className={styles.add}>
                    <span onClick={onAddIssue}>+ add issue</span>
                    <span onClick={() => onNavigate()}>view issues &gt;</span>
                </div>
            )}
        </div>
    )
}

export default withRouter(IssueStatus)
