import { IInspectionsResultDto, IInspectionsTemplateDto } from "../Dynamic/types";

export interface IConductInspectionOptions {
    redirectUrl: string;
}

export enum ConductInspectionActionType {
    GET_INSPECTION = "GET_INSPECTION",
    GET_INSPECTION_SUCCESS = "GET_INSPECTION_SUCCESS",
    GET_INSPECTION_ERROR = "GET_INSPECTION_ERROR",

    SAVE_INSPECTION = "SAVE_INSPECTION",
    SAVE_INSPECTION_SUCCESS = "SAVE_INSPECTION_SUCCESS",
    SAVE_INSPECTION_ERROR = "SAVE_INSPECTION_ERROR",

    SET_VIEWING_INSPECTION = "SET_VIEWING_INSPECTION",

    GET_CONDUCT_INSPECTION_TEMPLATES = "GET_CONDUCT_INSPECTION_TEMPLATES",
    GET_CONDUCT_INSPECTION_TEMPLATES_SUCCESS = "GET_CONDUCT_INSPECTION_TEMPLATES_SUCCESS",
    GET_CONDUCT_INSPECTION_TEMPLATES_ERROR = "GET_CONDUCT_INSPECTION_TEMPLATES_ERROR",
}

export interface ISaveInspection {
    type: ConductInspectionActionType.SAVE_INSPECTION;
}

export interface ISaveInspectionSuccess {
    type: ConductInspectionActionType.SAVE_INSPECTION_SUCCESS
}

export interface ISaveInspectionError {
    type: ConductInspectionActionType.SAVE_INSPECTION_ERROR,
    payload: string
}

export interface ISetViewingInspection {
    type: ConductInspectionActionType.SET_VIEWING_INSPECTION;
    payload: IInspectionsResultDto;
}

export interface IGetInspection {
    type: ConductInspectionActionType.GET_INSPECTION;
}

export interface IGetInspectionSuccess {
    type: ConductInspectionActionType.GET_INSPECTION_SUCCESS,
    payload: IInspectionsResultDto
}

export interface IGetInspectionError {
    type: ConductInspectionActionType.GET_INSPECTION_ERROR,
    payload: string
}

export interface IGetTemplates {
    type: ConductInspectionActionType.GET_CONDUCT_INSPECTION_TEMPLATES;
}

export interface IGetTemplatesSuccess {
    type: ConductInspectionActionType.GET_CONDUCT_INSPECTION_TEMPLATES_SUCCESS,
    payload: IInspectionsTemplateDto[]
}

export interface IGetTemplatesError {
    type: ConductInspectionActionType.GET_CONDUCT_INSPECTION_TEMPLATES_ERROR,
    payload: string
}

export type ConductInspectionAction =
    ISaveInspection |
    ISaveInspectionSuccess |
    ISaveInspectionError |
    ISetViewingInspection |
    IGetInspection |
    IGetInspectionSuccess |
    IGetInspectionError |
    IGetTemplates |
    IGetTemplatesSuccess |
    IGetTemplatesError;