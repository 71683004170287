import { apiService } from '../services/ApiService';
import * as domains from '../configuration/domains';
import { createBearerToken } from '../helpers/authHelpers';
import { IContactPreference } from '../types/ContactPreferencesService';

export const getContactPreferences = (): Promise<any> => {
    return apiService.get(`${domains.contactPreferencesService}/`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const updateContactPreferences = (contactPreferences: IContactPreference): Promise<any> => {
    return apiService.put(`${domains.contactPreferencesService}/`, contactPreferences, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}