import React from 'react'
import { Row, Col } from 'reactstrap'
import Modal from 'react-responsive-modal'
import SuccessTick from '../SuccessTick'
import RotatingLoader from '../RotatingLoader'
import { deleteTransactions } from '../../../services/TransactionService'
import { toggleDeleteTransactionPopup } from '../../../helpers/sidebarHelpers'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { IReactRouterProps } from '../../../types'
import { removeURLParameter } from '../../../helpers/locationHelpers'
import { deleteTransactionItem } from '../../../helpers/transactionHelpers'
import { updateToastMessage } from '../../../helpers/toastHelper'
import { IDataResponse } from '../../../types/ApiService'
import styles from './DeleteTransactionModal.module.scss'
import Buttons from '../Buttons'
const queryString = require('query-string')

interface IProps {
    transaction_delete_popup: boolean
}

type Props = IProps & IReactRouterProps

interface IState {
    deleteDocuments: boolean
    loading: boolean
    success: boolean

    transactionId: string[]
}

export interface IDeleteRequest {
    deleteAssociatedDocument: boolean
}

export interface IDeleteTransactionOptions {
    transactionId: string
}

class DeleteTransactionModal extends React.Component<Props, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            deleteDocuments: true,
            loading: false,
            success: false,
            transactionId: [],
        }
    }

    componentDidMount(): void {
        let options = queryString.parse(
            this.props.location.search
        ).deleteTransaction

        if (!options) {
            return
        }

        let option = JSON.parse(options)

        let transactionIds: string = option.transactionId

        this.setState({
            transactionId: option,
        })

        let removeQuery = removeURLParameter(
            this.props.location.search,
            'deleteTransaction'
        )
        this.props.history.push({
            search: removeQuery,
        })
    }

    onDelete(transactionId: string[]): void {
        this.setState({
            loading: true,
        })

        deleteTransactions(transactionId).then((resp) => {
            if (resp.status == 200) {
                let response: IDataResponse<boolean> = resp.data

                updateToastMessage(
                    response.message,
                    response.isSuccess ? 'success' : 'failure',
                    4000
                )
            }

            this.setState({
                success: false,
                loading: false,
            })

            transactionId.forEach((id) => {
                deleteTransactionItem(id)
            })

            this.props.history.push({ search: '?refreshTransactionGrid=true' })
            toggleDeleteTransactionPopup(false)
        })
    }

    render() {
        return (
            <Modal
                classNames={{ modal: styles.modal }}
                open={this.props.transaction_delete_popup}
                onClose={() => toggleDeleteTransactionPopup(false)}
                center
            >
                <div className={styles.body}>
                    {this.state.loading ? (
                        <div>
                            <RotatingLoader
                                loading={true}
                                text={`Deleting transaction...`}
                            />
                        </div>
                    ) : this.state.success ? (
                        <div>
                            <SuccessTick />
                            <p className={styles.modalBody}>
                                Transaction deleted
                            </p>
                        </div>
                    ) : (
                        <div>
                            <p className={styles.modelHeader}>
                                DELETE TRANSACTION
                            </p>

                            <p className={styles.modalBody}>
                                <div>You are about to delete:</div>
                                <div className={styles.records}>
                                    {this.state.transactionId.length}{' '}
                                    Transaction
                                    {this.state.transactionId.length == 1
                                        ? ''
                                        : 's'}
                                </div>
                                <div>Do you wish to go ahead and delete?</div>
                            </p>

                            <Row>
                                <Col className={styles.buttonDiv}>
                                    .
                                    <Buttons
                                        buttons={[
                                            {
                                                displayType: 'cancel',
                                                elementType: 'button',
                                                onClick: () =>
                                                    toggleDeleteTransactionPopup(
                                                        false
                                                    ),
                                            },
                                            {
                                                text: 'Delete',
                                                displayType: 'submit',
                                                elementType: 'button',
                                                onClick: () =>
                                                    this.onDelete(
                                                        this.state.transactionId
                                                    ),
                                            },
                                        ]}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state: any) => ({
    transaction_delete_popup: state.app.popups.transaction_delete_popup,
})

export default withRouter(
    connect(mapStateToProps)(DeleteTransactionModal) as any
)
