import {
  CalculatorHeader,
  IResearchContextData,
  IROICalculatorData,
  ResearchAddress,
  ResearchContext,
  ResearchSelectedProperty,
  ROICalculator,
  TabNavigation,
  toRentChiefRequest,
  IResearchConfig,
  IAddress,
  ApiContext,
  CalculationService,
  AddressService,
  LandRegistryService
} from "@rentchief/components";

import React, { useContext, useState } from "react";
import {
  Route,
  Switch,
  useHistory,
  useRouteMatch, 
} from "react-router";
import "react-tippy/dist/tippy.css";
import { toggleAddPropertySidebar } from "../../../helpers/sidebarHelpers";
import styles from "./ResearchPage.module.scss";
import "./imports.css";
import { getJwt } from "../../../helpers/authHelpers";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { updateAddPropertyRequest } from '../../../actions/propertyActions';

const ResearchPage = ({ updateAddPropertyRequest }) => {
  const history = useHistory();
  const match = useRouteMatch();

  const { calculation_service } = useContext(ApiContext);
  const calculationService = new CalculationService(calculation_service, {
    bearer_token: getJwt(),
  });
  const { address_service } = useContext(ApiContext);
  const addressService = new AddressService(address_service, {
    bearer_token: getJwt(),
  });
  const { land_registry_service } = useContext(ApiContext);
  const landRegistryService = new LandRegistryService(land_registry_service, {
    bearer_token: getJwt(),
  });
  const [data, setData] = useState<IResearchContextData>(null);
  const [calculatorData, setCalculatorData] = useState<IROICalculatorData>(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState({
    dateFrom: "01-01-1900",
    dateTo: "01-01-2021",
    selected: "All years",
  });

  const onAddProperty = (address: IAddress, calcData: IROICalculatorData) => {
    const request = toRentChiefRequest(
      address ? address : null,
      calcData
    );

    updateAddPropertyRequest(request);

    toggleAddPropertySidebar(true);
  };

  const config: IResearchConfig = {
    showRentChiefLink: false,
    clientCodeName: "rentchief",
    calculatorTabsList: [
      {
        name: "Buy to let",
        tab: "buy-to-let",
      },
      {
        name: "Renovate to let",
        tab: "renovate-to-let",
      },
      ,
      {
        name: "Renovate to sell",
        tab: "renovate-to-sell",
      },
    ],
    calculatorTabSummary: {
      buyToLetText: "",
      renovateToLetText: "This scenario helps model returns for a rental investment where there is renovation required. It will help you analyse your potential returns based on development size, timescale to complete and the estimated increase in value that the renovation brings to both the value of the property and increased rent.",
      renovateToSellText: "This scenario helps model returns for an investment where there is renovation required and you wish to sell the property at the end of the renovation period for a profit. You need to ensure that you buy at the right price, in the right place and keep any refurbishments or maintenance within your budget."
    }
  };

  return (
    <ResearchContext.Provider
      value={{
        data,
        calculatorData,
        setData,
        setCalculatorData,
        onAddProperty,
        filtering: {
          selected_date_filter: selectedDateFilter,
          setDateFilter: setSelectedDateFilter,
        },
        services: {
          calculationService,
          addressService,
          landRegistryService
        },
        config,
        routes: {
          researchProperty: `${match.path}/property`,
          research: `${match.path}`,
          dealCalculator: `${match.path}/deal-calculator`
      }
      }}
    >
      <div className={styles.page}>
        <div className={styles.tabbedNav}>
          <TabNavigation
            items={[
              {
                name: "Area Research",
                route: "/dashboard/research",
                match: '/dashboard/research',
                indexMatch: ["research/property"]
              },
              {
                name: "Deal Calculator",
                route: "/dashboard/research/deal-calculator",
                indexMatch: ["deal-calculator"],
              },
            ]}
          />
        </div>

        <div>
          <div>
            <Switch>
              <Route
                exact
                path={`${match.path}`}
                render={(props) => {
                  return <div className={styles.researchAddress}>
                    <ResearchAddress />
                  </div>
                  ;
                }}
              />

              <Route
                path={`${match.path}/deal-calculator`}
                render={(props) => {
                  return (
                    <>
                      <div className={styles.header}>
                        <CalculatorHeader />
                      </div>
                      <div className={styles.content}>
                        <ROICalculator />
                      </div>
                    </>
                  );
                }}
              />

              <Route
                path={`${match.path}/property`}
                render={(props) => {
                  return <ResearchSelectedProperty />;
                }}
              />
            </Switch>
          </div>
        </div>
      </div>
    </ResearchContext.Provider>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
      {
          updateAddPropertyRequest,
      },
      dispatch
  )

export default 
  connect(null, mapDispatchToProps)(ResearchPage)