import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { IReactRouterProps } from "../../../types";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { ErrorMessage, Form, Formik } from "formik";
import { Button, FormGroup, Row } from "reactstrap";
import { loginUser } from "../../../actions/userActions";
import {
  ForgottenPasswordText,
  KeepMeLoggedInText,
  LoginFormHeadingText,
  NoAccountText,
  SignUpText
} from "../../../configuration/account/login.config";
import { elevioHelpLinkUrl } from "../../../configuration/appConfig";
import helpIcon from "../../../images/icons/ic_help_outline_24px_white.svg";
import { RootState } from "../../../reducers/rootReducer";
import { activate } from "../../../services/AccountService";
import {
  IActivateAccountRequest,
  IActivateAccountResponse
} from "../../../types/AccountService";
import { IIdentityRequest } from "../../../types/LoginService/ILogin";
import { LoginSchema } from "../../../YupValidationSchemas";
import RotatingLoader from "../../atoms/RotatingLoader";
import SuccessTick from "../../atoms/SuccessTick";
import { ILoginFormProps } from "./login-form.types";
import styles from "./LoginForm.module.scss";

const queryString = require("query-string");

type Props = ILoginFormProps & IReactRouterProps;

const LoginForm = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch()

  // Redux 
  const { is_logging_in, is_logged_in, login_error_message } = useSelector((state: RootState) => state.user);
  const { client } = useSelector((state: RootState) => state);

  const [rememberLogin, setRememberLogin] = useState<boolean>(false);
  const [returnUrl, setReturnUrl] = useState<string>(null);
  const [email, setEmail] = useState<string>(null);
  const [is_activating_account, setIs_activating_account] = useState<boolean>(false);
  const [accountActivationMessage, setAccountActivationMessage] = useState<string>(null);
  const [accountActivated, setAccountActivated] = useState<boolean>(false);
  const [isAccountActivated, setIsAccountActivated] = useState<boolean>(false);

  const onLoginUser = (email: string, password: string) => {
    let loginRequest: IIdentityRequest = {
      email,
      password,
      client_id: "",
      token: "",
      grant_type: "password",
    };

    localStorage.setItem('lastLoginEmail', email);
    dispatch(loginUser(loginRequest, rememberLogin, returnUrl));
  };

  useEffect(() => {
    let queryEmail = queryString.parse(location.search).email;
    if (queryEmail) {
      setEmail(queryEmail);
      localStorage.setItem('lastLoginEmail', queryEmail);
    }else{
      var lastLoginEmail = localStorage.getItem('lastLoginEmail');
      if(lastLoginEmail){
        setEmail(lastLoginEmail);
      }
    }
  });
  
  useEffect(() => {
    // Note queryString.parse fails when there are more than one '?', which can be the case here,
    // i.e. first will be '?returnUrl', and second can be part of the actual return url, e.g. ?taskId=1234
    let returnUrl = queryString.parse(location.search).taskId;
    if(!returnUrl){
      // sometimes the this.props.location.search is not available yet, so do this way 
      let pathnameSplit = location.pathname.split('?');
      if(pathnameSplit.length > 1){
        if(pathnameSplit[1].startsWith("returnUrl")){
          returnUrl = pathnameSplit[1].split('=')[1];
          // As explaind above can have more than one '?'
          if(pathnameSplit[2] != null){
            returnUrl += "?" + pathnameSplit[2];
          }
        }
      }
    }
    if (returnUrl) {
      setReturnUrl(returnUrl);
    }
    
    let activationUserId = props.match.params.userId;
    let activationActivationCode = props.match.params.activationCode;

    let request: IActivateAccountRequest = {
      userId: activationUserId,
      activationCode: activationActivationCode,
    };

    if (activationUserId && activationActivationCode) {
      setIs_activating_account(true);
      activateNow(request);
    }
  }, []);

  const activateNow = async(request: IActivateAccountRequest) =>{
    try{
      setIs_activating_account(true);
      let resp = await activate(request);
      if(resp){
        setIs_activating_account(false);
        if (resp.status == 200 && resp.data){
          let data: IActivateAccountResponse = resp.data;
          setIsAccountActivated(data.isActivatedUser);
          setAccountActivationMessage(data.message);
          setAccountActivated(data.isActivated);
        }
      }
    }
    catch(ex){
      setIs_activating_account(false);
    }
    
}

  if (is_activating_account) {
    return (
      <div className={styles.page}>
        <RotatingLoader loading={true} text="Activating your account..." />
      </div>
    );
  }

  if (is_logging_in) {
    return (
      <RotatingLoader
        loading={is_logging_in}
        text={`Logging you into ${client && client.name ? client.name : "the platform"}...`}
      />
    );
  }
  
  return (
    <div className={styles.page}>
      {!is_logged_in && !is_logging_in && (
        <div>
          <h1 className={styles.formHeading}>
            {LoginFormHeadingText(client)}
          </h1>

          {accountActivationMessage &&
            accountActivated && (
              <div className={styles.success}>
                {accountActivationMessage}
                {!isAccountActivated && (
                  <button className={styles.helpButton}>
                    <img src={helpIcon} />
                    <a href={elevioHelpLinkUrl} target="_blank">Rent Chief Help</a>
                  </button>
                )}
              </div>
            )}

          {accountActivationMessage &&
            !accountActivated && (
              <div className={styles.failure}>
                {accountActivationMessage}
              </div>
            )}
          <Formik
            initialValues={{
              email: email,
              password: "",
            }}
            enableReinitialize={true}
            validationSchema={LoginSchema}
            onSubmit={(values: any) =>
              onLoginUser(values.email, values.password)
            }
          >
            {(props) => (
              <Form className={styles.form}>
                {queryString.parse(location.search).logout !=
                  null && (
                  <div className={styles.logout}>
                    <SuccessTick /> You've been logged out.
                  </div>
                )}

                {login_error_message != "" && (
                  <div className={styles.error}>
                    {login_error_message.indexOf("DOCTYPE") > 0
                      ? "Login failed. Please try again later."
                      : login_error_message}
                  </div>
                )}

                <div className={styles.group}>
                  <label className={styles.label}>Email</label>

                  <input
                    className={styles.input}
                    name="email"
                    value={props.values.email}
                    placeholder="Email"
                    onChange={(e) =>
                      props.setFieldValue("email", e.currentTarget.value)
                    }
                  />

                  <div className={styles.errorMessage}>
                    <ErrorMessage
                      className={styles.errorMessage}
                      name="email"
                    />
                  </div>
                </div>

                <div className={styles.noBottomMargin}>
                  <label className={styles.label}>Password</label>

                  <input
                    className={styles.input}
                    name="password"
                    value={props.values.password}
                    type="password"
                    placeholder="Password"
                    onChange={(e) =>
                      props.setFieldValue("password", e.currentTarget.value)
                    }
                  />

                  <div className={styles.errorMessage}>
                    <ErrorMessage
                      className={styles.errorMessage}
                      name="password"
                    />
                  </div>
                </div>

                <div className={styles.keepMeLoggedIn}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rememberLogin}
                          color="primary"
                          onChange={() =>
                            setRememberLogin(!rememberLogin)
                          }
                          value={KeepMeLoggedInText}
                          className={
                            rememberLogin ? styles.checkbox : null
                          }
                        />
                      }
                      label={KeepMeLoggedInText}
                    />
                  </FormGroup>
                </div>

                <div className={styles.buttonContainer}>
                  <Button className={styles.button} type="submit">
                    {!is_logging_in && <span>Login</span>}
                  </Button>

                  <Row className={styles.text}>
                    <div>
                      <span
                        className={styles.link}
                        onClick={() => history.push("/account/forgotten-password")}
                      >
                        {ForgottenPasswordText}
                      </span>
                    </div>

                    <div>
                      <span>{NoAccountText} &nbsp;</span>
                      <span
                        className={styles.link}
                        onClick={() =>
                          history.push({
                            pathname: "/account/register",
                            search: location.search,
                          })
                        }
                      >
                        {SignUpText}
                      </span>
                    </div>
                  </Row>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );

}

export default LoginForm;
