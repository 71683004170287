import React from 'react';
import govImage from "../../../../../images/gov.png";
import styles from "./tooltipConfig.module.scss";

export const rentReviewDateTooltip = 
    <div className={styles.tooltipContent}>
        <h1>When can you increase rent?</h1>
        <ul>
            <li>
                With all Assured Shorthold Tenancies landlords
                can increase the rent after the initial fixed
                period if it is stated in the tenancy agreement
                or if the tenant agrees to the increase.{" "}
            </li>
            <li>
                Landlord is required to give the tenant
                sufficient notice before a rent increase is to
                take effect and can only increase the rent once
                a year.
            </li>
            <li>
                Rent Chief will alert you 6 weeks prior to the
                of a tenancy giving you time to review the rent
                if required.
            </li>
        </ul>
        <span className={styles.links}>
            <img src={govImage} className={styles.govImage} />
            <a
                href="https://www.gov.uk/private-renting/rent-increases"
                target="_blank"
            >
                Rent Increases
            </a>
        </span>
    </div>;

export const depositTooltip = 
    <div className={styles.tooltipContent}>
        <h1>Deposit</h1>
        <ul>
            <li>
                Holding deposits are capped at one week’s rent
            </li>
            <li>
                Security deposits will be capped at five weeks’
                rent where the annual rent is less than £50,000
                and six weeks’ rent where the annual rent is
                £50,000 or more
            </li>
            <li>
                Default fees (fees which can be charged during the
                tenancy) are limited to the reasonable costs
                incurred in replacing a key or lost security
                device or interest on rent overdue by 14 days or
                more, capped at 3% above the Bank of England base
                rate
            </li>
        </ul>
        <span className={styles.links}>
            <img src={govImage} />
            <a
                href="https://www.gov.uk/private-renting/deposits"
                target="_blank"
            >
                Private renting deposits
            </a>
        </span>
        <p />
        <h1>Deposit Scheme</h1>
        <ul>
            <li>
                Landlords must secure their tenants deposits into
                one of three government approved Tenancy Deposit
                Protection (TDP) schemes with in 30 days of
                receiving the deposit, and they must also serve
                their tenants with{" "}
                <a
                href="https://www.gov.uk/tenancy-deposit-protection/information-landlords-must-give-tenants"
                target="_blank"
                >
                Prescribed Information
                </a>
                &nbsp; related to the deposit, within 30 days.
            </li>
            <li>
                Rent Chief allows you to save the name of the
                scheme and also the agreement number plus any
                documents you need to save
            </li>
            <li>
                Please see the below link for additional
                information regarding Tenancy Deposits and
                available schemes
            </li>
        </ul>
        <span className={styles.links}>
            <img src={govImage} />
            <a
                href="https://www.gov.uk/tenancy-deposit-protection"
                target="_blank"
            >
                Tenancy Deposit Protection
            </a>
        </span>
    </div>;

export const depositSchemeTooltip =
    <div className={styles.tooltipContent}>
        <h1>Deposit Scheme</h1>
        <ul>
            <li>
                Landlords must secure their tenants deposits into
                one of three government approved Tenancy Deposit
                Protection (TDP) schemes with in 30 days of
                receiving the deposit, and they must also serve
                their tenants with{" "}
                <a
                href="https://www.gov.uk/tenancy-deposit-protection/information-landlords-must-give-tenants"
                target="_blank"
                >
                Prescribed Information
                </a>
                &nbsp; related to the deposit, within 30 days.
            </li>
            <li>
                Rent Chief allows you to save the name of the
                scheme and also the agreement number plus any
                documents you need to save
            </li>
            <li>
                Please see the below link for additional
                information regarding Tenancy Deposits and
                available schemes
            </li>
        </ul>
        <span className={styles.links}>
            <img src={govImage} />
            <a
                href="https://www.gov.uk/tenancy-deposit-protection"
                target="_blank"
            >
                Tenancy Deposit Protection
            </a>
        </span>
    </div>;

export const contractTooltip =
    <div className={styles.tooltipContent}>
        <h1>Tenancy contract</h1>
        <ul>
            <li>
                You can attach a tenancy contract via the quick add
                link below.
            </li>
            <li>
                This can be shared with the tenant via the tenant
                portal.
            </li>
            <li>
                This will automatically link to the property and
                tenant.
            </li>
            <li>
                You can add further tenancy contracts using the
                quick add link multiple times.
            </li>
        </ul>
    </div>;

export const inventoryTooltip = 
    <div className={styles.tooltipContent}>
    <h1>Tenancy Property Inventory</h1>
        <ul>
            <li>
                You can attach a property inventory document from
                the start of the tenancy via the quick add link
                below.
            </li>
            <li>
                This can be shared with the tenant via the tenant
                portal.
            </li>
            <li>
                This will automatically link to the property and
                tenant.
            </li>
            <li>
                You can add further property inventory document
                using the quick add link multiple times.
            </li>
        </ul>
    </div>;
