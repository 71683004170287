import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Sidebar from 'react-sidebar'
import { sidebarStyling } from '../../../global/variables/_variables'
import AddClientForm from '../AddClientForm'
import styles from './AddClientUserPanel.module.scss'

interface IProps {
    isOpen: boolean
    onSetOpen(open: boolean): void
}

interface IRouterProps {
    history: any
    location: any
    match: any
}

interface IState {
    sidebarOpen: boolean
}

type Props = IProps & IRouterProps

class AddClientUserPanel extends Component<Props, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            sidebarOpen: false,
        }
    }

    render() {
        return (
            <Sidebar
                touch={false}
                sidebarClassName={styles.sidebar}
                sidebar={this.props.isOpen ? <AddClientForm /> : <div></div>}
                open={this.props.isOpen}
                onSetOpen={this.props.onSetOpen}
                styles={sidebarStyling}
            >
                <div />
            </Sidebar>
        )
    }
}

export default withRouter(AddClientUserPanel)
