import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { updateClientData } from "../../../actions/clientActions";
import {
  addClientIdToSessionStorage,
  getClientIdFromSessionStorage,
} from "../../../helpers/sessionStorageHelpers";
import { getClient, getDefaultClient } from "../../../services/ClientService";
const queryString = require("query-string");
import defaultTheme from "../../../themes/default.theme.json";
import recogniseTheme from "../../../themes/recognise.theme.json";

interface IProps {
  history: any;
  location: any;
  match: any;

  setTheme(theme: string): void;
  children?: any;
}

class WhitelabelWrapper extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  getTheme = (clientId: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (clientId === "recognise") {
        resolve({
          data: recogniseTheme,
        });
      }
      resolve({
        data: defaultTheme,
      });
    });
  };

  async componentDidMount(): Promise<void> {
    let whitelabelId = queryString.parse(
      this.props.location.search
    ).whitelabelId;
    if (whitelabelId) {
      this.setState({
        clientId: whitelabelId,
      });

      addClientIdToSessionStorage(whitelabelId);

      // remove whitelabelId from querystring params
      const queryParams = new URLSearchParams(this.props.history.location.search)
      queryParams.delete('whitelabelId');
      this.props.history.replace({
        search: queryParams.toString(),
      })

      // Mock fetching a theme for now
      var { data } = await this.getTheme(whitelabelId);
      this.props.setTheme(data);

      getClient(whitelabelId).then((resp) => {
        if (resp.data) {
          let client = resp.data;
          updateClientData(client);
        }
      });
      
      return;
    }

    if (!whitelabelId) {
      let clientId = getClientIdFromSessionStorage();
      if (clientId) {
        getClient(clientId).then((resp) => {
          if (resp.data) {
            let client = resp.data;
            updateClientData(client);
          }
        });
        return;
      }
    }

    getDefaultClient()
      .then((resp) => {
        if (resp && resp.status == 200) {
          let client = resp.data;
          updateClientData(client);
          return;
        }
        if (resp.status == 404) {
          this.props.history.push("/no-default-client");
        }
      })
      .catch((e) => {});
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default withRouter<IProps,any>(WhitelabelWrapper);
