import { apiService } from './ApiService';
import * as domains from '../configuration/domains';
import { createBearerToken } from '../helpers/authHelpers';
import { IActivateAccountRequest } from '../types/AccountService';
import { toQueryString } from './DocumentService';

export const activate = (request: IActivateAccountRequest): Promise<any> => {
    return apiService.post(`${domains.accountService}/activate`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const resendEmailConfirmation = (userIds: string[]): Promise<any> => {
    let query = toQueryString(userIds);    

    let q = "";

    userIds.forEach(x => {
        q += `userIds=${x}&`;
    })

    return apiService.get(`${domains.registrationService}/resend-email-confirmation?${q}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}