// Import React
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { IPropertyCard } from '../../../../../types/PropertyService/IPropertyService';
import styles from './Property.module.scss';
import GenericPopup from '../../../../atoms/GenericPopup';
import { generateId } from '../../../../../helpers/randomHelpers';
import { QuickViewType } from '../../../../../types/QuickView';

interface IProps {
  property: IPropertyCard;
}

interface IState {
}

class Property extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
    }
  }

  render() {
    return (
      <div className={styles.property}>
        <div className={styles.row}>
          <div className={styles.image}>
            <img src={this.props.property.propertyImage} />
          </div>

          <div className={styles.text}>
            <div className={styles.row}><span className={styles.bold}>{this.props.property.address.postcode}</span></div>
            <div className={styles.row}>{this.props.property.propertyName}
              <GenericPopup
                identifier={generateId(10)}
                options={{
                  type: QuickViewType.Property,
                  propertyId: this.props.property.propertyId
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Property) as any;