import React, { useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap'
import Modal from 'react-responsive-modal'
import RotatingLoader from '../RotatingLoader'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { IReactRouterProps } from '../../../types'
import styles from './DeleteAssetModal.module.scss'
import Buttons from '../Buttons'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import {
    deleteAssets,
    toggleDeleteAssets,
} from '../../../actions/assetsActions'
import { IDeleteState } from '../../../types/Delete'
const queryString = require('query-string')

interface IProps {
    toggleDeleteAssets(
        ids?: Array<number>,
        open?: boolean,
        returnUrl?: string
    ): any
    delete: IDeleteState
    deleteAssets?: any
}

type Props = IProps & IReactRouterProps

interface IState {
    loading: boolean
    success: boolean

    inspectionId: number[]
    redirectUrl: string
}

const DeleteAssetModal = (props: Props) => {
    const [deleteDocuments, setDeleteDocuments] = useState(false)
    const [deleteImages, setDeleteImages] = useState(true)

    const onDelete = () => {
        const { ids, returnUrl } = props.delete
        props.deleteAssets(ids, returnUrl, {
            deleteAssociatedDocument: deleteDocuments,
            deleteAssociatedImages: deleteImages,
        })
    }

    if (!props.delete.ids) return null

    return (
        <Modal
            classNames={{ modal: styles.modal }}
            open={props.delete.modelOpen}
            onClose={() => props.toggleDeleteAssets([], false)}
            center
        >
            <div className={styles.body}>
                {props.delete.loading ? (
                    <div>
                        <RotatingLoader
                            loading={true}
                            text={`Deleting asset...`}
                        />
                    </div>
                ) : (
                    <div>
                        <p className={styles.modelHeader}>DELETE ASSET</p>

                        <p className={styles.modalBody}>
                            <div>You are about to delete:</div>
                            <div className={styles.records}>
                                {props.delete.ids.length} Asset
                                {props.delete.ids.length == 1 ? '' : 's'}
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={deleteDocuments}
                                            color="primary"
                                            onChange={() =>
                                                setDeleteDocuments(
                                                    !deleteDocuments
                                                )
                                            }
                                        />
                                    }
                                    label="Delete associated documents?"
                                />
                            </div>

                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={deleteImages}
                                            color="primary"
                                            onChange={() =>
                                                setDeleteImages(!deleteImages)
                                            }
                                        />
                                    }
                                    label="Delete associated images?"
                                />
                            </div>

                            <div>Do you wish to go ahead and delete?</div>
                        </p>

                        <Row>
                            <Col className={styles.buttonDiv}>
                                <Buttons
                                    buttons={[
                                        {
                                            displayType: 'cancel',
                                            elementType: 'button',
                                            onClick: () =>
                                                props.toggleDeleteAssets(),
                                        },
                                        {
                                            text: 'Delete',
                                            displayType: 'submit',
                                            elementType: 'button',
                                            onClick: () => onDelete(),
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    asset_delete_popup: state.app.popups.asset_delete_popup,
    delete: state.assets.delete,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            deleteAssets,
            toggleDeleteAssets,
        },
        dispatch
    )

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DeleteAssetModal)
)
