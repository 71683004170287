import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { RootState } from '../../../../../reducers/rootReducer';
import EstimatedValuationModal from '../../../../atoms/EstimatedValuationModal';
import { IGetPriceValuationResponse, IGetPriceValuationRequest } from '../../../../../types/PropertyService/IPropertyService'
import { onAddDocument, onMultiAddUnit } from '../../helpers/dashboardHelper';
import SectionHeader from "../SectionHeader/SectionHeader.component"
import LabelWithIconPart from "../LabelWithIconPart/LabelWithIconPart.component"
import * as S from "./GettingStartedPropertySection.styles";
import { truncate } from 'fs';
import { onAddTenancyClick } from '../../../../atoms/SFDropdown/SFPropertyDropdown/_helpers';

interface sectionProps {
    id: string;
    header: string;
    hideOnClick(id: string): void
    estimatedValueUpdated(priceItemResp: IGetPriceValuationResponse, rentItemResp: IGetPriceValuationResponse): void
}

const GettingStartedPropertySection = ({id, header, hideOnClick, estimatedValueUpdated}: sectionProps) => {
    const history = useHistory();

    const { propertyId, propertyName, address } = useSelector((state: RootState) => state.property.property_data.property_data);
    const { propertyType, residentialType, commercialType } = useSelector((state: RootState) => state.property.property_data.property_data.propertyDetails);
    const { propertyType : propertyTypeStr } = useSelector((state: RootState) => state.property.property_data.property_data.propertySummary);

    const [showEstimatedValuationModal, setShowEstimatedValuationModal] = useState(false);

    return (
        <>
            {showEstimatedValuationModal && (
                <EstimatedValuationModal
                    isResidential={propertyTypeStr == null || propertyTypeStr.toLocaleLowerCase() == 'residential'}
                    //isRental={false}
                    onSuccess={(
                        priceItemResp: IGetPriceValuationResponse,
                        rentItemResp: IGetPriceValuationResponse,
                        request: IGetPriceValuationRequest
                    ) => {
                        setShowEstimatedValuationModal(false);
                        estimatedValueUpdated(priceItemResp, rentItemResp);
                    }}
                    postcode={address.postcode}
                    onClose={() =>
                        setShowEstimatedValuationModal(false)
                    }
                    open={showEstimatedValuationModal}
                    propertyId={propertyId}
                    header="Get Valuation"
                    createValuationForPriceAndRent={true}
                />
            )}
            <S.SectionDiv>
                <SectionHeader id={id} header={header} hideOnClick={hideOnClick} />
                <S.bodyDiv>
                    <S.bodyContentDiv>
                    <LabelWithIconPart 
                            id="regulationInfo" 
                            labelText="1. Regulation Info"  
                            iconUrl="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/invite_team_member_icon.svg"
                            onClick={() => history.push(`/dashboard/property/${propertyId}/spec/regulation`)}
                        />
                        <LabelWithIconPart 
                            id="setupRooms" 
                            labelText="2. Setup rooms"  
                            iconUrl="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/add_property_icon.svg"
                            onClick={() => onMultiAddUnit(propertyId, propertyName, propertyType, residentialType, commercialType, history)}
                        />
                        <LabelWithIconPart 
                            id="getValuation" 
                            labelText="3. Get Valuation"  
                            iconUrl="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/invite_team_member_icon.svg"
                            onClick={() => setShowEstimatedValuationModal(true)}
                            tooltipText="Get a current estimated valuation of this property now."
                        />
                        <LabelWithIconPart 
                            id="createTenancy" 
                            labelText="4. Add tenancy"  
                            iconUrl="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/create_tenancy_icon.svg"
                            onClick={() => onAddTenancyClick(propertyId, propertyName, location.search)}
                        />
                        <LabelWithIconPart 
                            id="addDocs" 
                            labelText="5. Add Key Docs"  
                            iconUrl="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/invite_team_member_icon.svg"
                            onClick={() => onAddDocument(propertyId, history)}
                        />
                        <LabelWithIconPart 
                            id="addAssets" 
                            labelText="6. Add key assets"  
                            iconUrl="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/invite_team_member_icon.svg"
                            onClick={() => history.push(`/dashboard/property/${propertyId}/spec/assets`)}
                        />
                    </S.bodyContentDiv>
                </S.bodyDiv>
            </S.SectionDiv>
        </>
    )
}

export default GettingStartedPropertySection;