import { apiService } from './ApiService';
import * as domains from '../configuration/domains';
import { createBearerToken } from '../helpers/authHelpers';

export const exportAllUserProperties = (): Promise<any> => {
    return apiService.get(`${domains.csvExportService}/properties`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })    
    .catch(error =>{
       return error; 
    });
}