import { IIssueItem, IssueActionType } from "../types/IssueService"

interface IIssueState {
    issue_list: Array<IIssueItem>;
    viewing_issue_edit: IIssueItem;
    error: string;
    loading: boolean;
}

const initialState: IIssueState = {
    issue_list: [],
    viewing_issue_edit: null,
    error: null,
    loading: false
}

export default (state: IIssueState = initialState, action: any): IIssueState => {
    switch (action.type) {
        case IssueActionType.UPDATE_VIEWING_ISSUE:
            return {
                ...state,
                viewing_issue_edit: action.payload,
                error: null
            }

        case IssueActionType.CLEAR_ISSUE_EDIT:
            return {
                ...state,
                viewing_issue_edit: null,
                error: null
            }

        case IssueActionType.GET_ISSUE_EDIT:
            return {
                ...state,
                viewing_issue_edit: null,
                loading: true,
                error: null
            }

        case IssueActionType.GET_ISSUE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                viewing_issue_edit: action.payload
            }

        case IssueActionType.GET_ISSUE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case "UPDATE_ISSUE_LIST":
            return {
                ...state,
                issue_list: action.data
            }

        default:
            return state
    }
}