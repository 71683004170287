import React from "react";
import icon from "../../../images/icons/icon_down_chevron.svg";
import styles from "./DropdownButton.module.scss";

interface IProps {
  onClick?(): void;
}

class DropdownButton extends React.Component<IProps> {
  constructor(props: any) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(e: any): void {
    e.stopPropagation();
    e.preventDefault();
    this.props.onClick && this.props.onClick();
  }

  render() {
    return (
      <span className={styles.dropdownButton} onClick={this.onClick}>
        <img className={styles.icon} src={icon} />  <span className={styles.text}>Actions</span>
      </span>
    );
  }
}

export default DropdownButton;
