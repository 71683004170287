import { Dispatch } from 'redux';
import { AssetActionType, AssetsAction, IAssetDto, IAssetsListItemOptions, IAssetsListResponse } from '../types/Assets'
import { goToRoute, goToPreviousRoute } from "../history";
import { processToast, updateToastMessage } from '../helpers/toastHelper';
import { getAssetTypesApi, getAssetApi, getAssetsApi, getServiceScheduleTypesApi, addAssetApi, updateAssetApi, deleteAssetsApi, getQuantityAndValueConditionTypesFromApi, getProductStatusTypesFromApi } from '../services/AssetsService';
import { store } from '../store';
import { deleteImage, getImages, uploadImagesApi } from '../services/ImageService';
import { ImageTypeEnum, IUploadRequest } from '../types/ImageService';
import { IDataResponse } from '../types/ApiService';

export const getProductStatusTypes = () => {
    return async (dispatch: Dispatch<AssetsAction>) => {
        dispatch({ type: AssetActionType.GET_PRODUCT_STATUS_TYPES });

        try {
            const { data } = await getProductStatusTypesFromApi();
            dispatch({
                type: AssetActionType.GET_PRODUCT_STATUS_TYPES_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch({
                type: AssetActionType.GET_PRODUCT_STATUS_TYPES_ERROR,
                payload: err.message
            })
        }
    }
};

export const getQuantityAndValueConditionTypes = () => {
    return async (dispatch: Dispatch<AssetsAction>) => {
        dispatch({ type: AssetActionType.GET_QUANTITY_AND_VALUE_CONDITION_TYPES });

        try {
            const { data } = await getQuantityAndValueConditionTypesFromApi();
            dispatch({
                type: AssetActionType.GET_QUANTITY_AND_VALUE_CONDITION_TYPES_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch({
                type: AssetActionType.GET_QUANTITY_AND_VALUE_CONDITION_TYPES_ERROR,
                payload: err.message
            })
        }
    }
};

export const getServiceScheduleTypes = () => {
    return async (dispatch: Dispatch<AssetsAction>) => {
        dispatch({ type: AssetActionType.GET_SERVICE_SCHEDULE_TYPES });

        try {
            const { data } = await getServiceScheduleTypesApi();
            dispatch({
                type: AssetActionType.GET_SERVICE_SCHEDULE_TYPES_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch({
                type: AssetActionType.GET_SERVICE_SCHEDULE_TYPES_ERROR,
                payload: err.message
            })
        }
    }
};

export const getAssetTypes = (selectedTypeId?: number) => {
    return async (dispatch: Dispatch<AssetsAction>) => {
        dispatch({ type: AssetActionType.GET_ASSET_TYPES });

        try {
            if (!store.getState().assetTypes.data.assetTypes.length
                && !store.getState().assetTypes.data.assetSubtypes.length) {
                const data = await getAssetTypesApi();
                dispatch({
                    type: AssetActionType.GET_ASSET_TYPES_SUCCESS,
                    payload: data.data
                });
            }
            dispatch({
                type: AssetActionType.UPDATE_SELECTED_LEVEL1_ID,
                payload: selectedTypeId
            });
        } catch (err) {
            dispatch({
                type: AssetActionType.GET_ASSET_TYPES_ERROR,
                payload: err.message
            })
        }
    }
};

export const addAsset = (request: IAssetDto, successReturnUrl: string, files: { images?: FormData, options: IUploadRequest }) => {
    return async (dispatch: Dispatch<AssetsAction>) => {
        dispatch({ type: AssetActionType.ADD_ASSET });
        try {

            const { data } = await addAssetApi(request);

            // If there are files passed, now upload these
            if (files) {
                const response = await uploadImagesApi(files.images, { ...files.options, parentId: data.data });
                if (response.data) {
                    processToast(response.data);
                }
            }

            dispatch({
                type: AssetActionType.ADD_ASSET_SUCCESS
            });

            if (data) {
                processToast(data);
            }

            //goToRoute(successReturnUrl);
            goToPreviousRoute();
        }
        catch (err) {
            updateToastMessage(err.message || 'An error has occured whilst adding the asset', 'failure');
            dispatch({
                type: AssetActionType.ADD_ASSET_ERROR,
                payload: err.message
            })
        }
    }
};

export const updateAsset = (asset: IAssetDto, successReturnUrl: string) => {
    return async (dispatch: Dispatch<AssetsAction>) => {
        dispatch({ type: AssetActionType.UPDATE_ASSET });
        try {
            const { data } = await updateAssetApi(asset);
            dispatch({
                type: AssetActionType.UPDATE_ASSET_SUCCESS,
                payload: asset
            });
            processToast(data);
            //goToRoute(successReturnUrl);
            goToPreviousRoute();
        }
        catch (err) {
            updateToastMessage(err.message || 'An error has occured whilst updating the asset', 'failure');
            dispatch({
                type: AssetActionType.UPDATE_ASSET_ERROR,
                payload: err.message
            })
        }
    }
};

export const deleteAssetImage = (imageId: string) => {
    return async (dispatch: Dispatch<AssetsAction>) => {
        dispatch({ type: AssetActionType.DELETE_ASSET_IMAGE });
        try {
            const { data } = await deleteImage(imageId);
            if (data) {
                processToast(data);
                dispatch({
                    type: AssetActionType.DELETE_ASSET_IMAGE_SUCCESS,
                    payload: imageId
                });
            }
        } catch (err) {
            dispatch({
                type: AssetActionType.DELETE_ASSET_IMAGE_ERROR,
                payload: err.message
            });
        }
    }
};

export const getAssetImages = (assetId: number) => {
    return async (dispatch: Dispatch<AssetsAction>) => {
        dispatch({ type: AssetActionType.GET_ASSET_IMAGES });
        try {
            const { data } = await getImages({ parentId: assetId, imageType: [ImageTypeEnum.ASSET_IMAGE] });
            if (data) {
                dispatch({
                    type: AssetActionType.GET_ASSET_IMAGES_SUCCESS,
                    payload: data
                });
            }
        } catch (err) {
            dispatch({
                type: AssetActionType.GET_ASSET_IMAGES_ERROR,
                payload: err.message
            });
        }
    }
};

export const toggleDeleteAssets = (assetIds?: Array<number>, open?: boolean, returnUrl?: string) => {
    return (dispatch: Dispatch<AssetsAction>) => {
        dispatch({
            type: AssetActionType.TOGGLE_DELETE_ASSETS, payload: {
                open,
                assetIds,
                returnUrl
            }
        })
    }
}

export const deleteAssets = (assetIds: number[], successReturnUrl: string, deleteAssociated: { deleteAssociatedDocument: boolean }) => {
    return async (dispatch: Dispatch<AssetsAction>) => {
        dispatch({ type: AssetActionType.DELETE_ASSETS });

        try {
            const { data } = await deleteAssetsApi(assetIds, deleteAssociated);
            if (data) {
                dispatch({
                    type: AssetActionType.DELETE_ASSETS_SUCCESS,
                    payload: assetIds
                });
                processToast(data);
                //goToRoute(successReturnUrl);
                goToPreviousRoute();
            }
            else {
                dispatch({
                    type: AssetActionType.DELETE_ASSETS_ERROR,
                    payload: 'Failed to delete one or more assets'
                });
            }
        }
        catch (err) {
            dispatch({
                type: AssetActionType.DELETE_ASSETS_ERROR,
                payload: err.message
            });
        }
    }
};

export const getAssets = (
    options: IAssetsListItemOptions) => {
    return async (dispatch: Dispatch<AssetsAction>) => {
        dispatch({ type: AssetActionType.GET_ASSETS });

        try {
            const { data } = await getAssetsApi(options);
            let response: IDataResponse<IAssetsListResponse> = data;
            if (response.isSuccess) {
                return dispatch({
                    type: AssetActionType.GET_ASSETS_SUCCESS,
                    payload: response.data
                });
            }
            return dispatch({
                type: AssetActionType.GET_ASSETS_ERROR,
                payload: response.errorMessage
            });
        } catch (err) {
            updateToastMessage('An error occurred when fetching assets', 'failure');
            dispatch({
                type: AssetActionType.GET_ASSETS_ERROR,
                payload: err.message
            })
        }
    }
};

export const getAsset = (assetId: number, q: string = '') => {
    return async (dispatch: Dispatch<AssetsAction>) => {
        dispatch({ type: AssetActionType.GET_ASSET });
        try {
            let inState = store.getState().assets.data.data.findIndex(x => x.assetId == assetId);
            if (inState > -1) {
                dispatch({
                    type: AssetActionType.GET_ASSET_LOCAL_SUCCESS,
                    payload: assetId
                });
            } else {
                const response = await getAssetApi(assetId);
                if (response.data.isSuccess) {
                    dispatch({
                        type: AssetActionType.GET_ASSET_SUCCESS,
                        payload: response.data.data
                    });
                } else {
                    dispatch({
                        type: AssetActionType.GET_ASSET_ERROR,
                        payload: "The requested asset is not available."
                    })
                }
            }
        } catch (err) {
            dispatch({
                type: AssetActionType.GET_ASSET_ERROR,
                payload: err.message
            })
        }
    }
};

export const clearAsset = () => {
    return async (dispatch: Dispatch<AssetsAction>) => {
        dispatch({ type: AssetActionType.CLEAR_ASSET });
    }
};

export const clearAssets = () => {
    return async (dispatch: Dispatch<AssetsAction>) => {
        dispatch({ type: AssetActionType.CLEAR_ASSETS });
    }
};