import moment from "moment-timezone";

export function delayAction(delayMilliseconds: number, action: () => any) {
  setTimeout(() => {
    action();
  }, delayMilliseconds);
}

export function calculateDaysBetweenDates(startDate: Date, endDate: Date): number {
  if (typeof startDate.getTime != 'function' || typeof endDate.getTime != 'function') {
    return 0;
  }

  let timeDifference = endDate.getTime() - startDate.getTime();
  let dayDiffernece = timeDifference / (1000 * 3600 * 24);
  return Math.round(dayDiffernece);
}

export function getCurrentDate(): Date {
  return new Date(moment(new Date()).utcOffset(0, true).set({ hours: 0, minutes: 0 }).format());
}

export function getDateWithoutOffset(date: Date): Date {
  return new Date(moment(date).utcOffset(0, true).set({ hours: 0, minutes: 0 }).format());
}