const ImageExtensions = '.jpg,.jpeg,.png,.webp,.gif'

const ImageExtensionsArray = [
    'png',
    'jpg',
    'jpeg',
    'image',
    'jfif',
    'gif',
    'webp',
]

export { ImageExtensions, ImageExtensionsArray }
