import React from 'react'
import styles from './Button.module.scss'
import { ButtonType } from '../Buttons.types'

type Props = {
    button: ButtonType
}

const defaultText = {
    submit: 'Save',
    cancel: 'Cancel',
}

const Button = ({ button }: Props) => {
    return (
        <button
            onClick={(e) => {
                e.preventDefault()
                if (button.onClick) button.onClick()
            }}
            type={button.elementType}
            className={`${styles.button} ${styles[button.displayType]} ${
                button.width ? styles[button.width] : ''
            }`}
            disabled={button.disabled}
            ref={button.ref}
        >
            {button.text ? button.text : defaultText[button.displayType]}
        </button>
    )
}

export default Button
