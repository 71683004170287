import {
    Aggregate,
    Column,
    ColumnDirective,
    ColumnMenu,
    ColumnsDirective,
    CommandColumn,
    ContextMenu,
    DataResult,
    DataStateChangeEventArgs,
    ExcelExport,
    Filter,
    Grid,
    GridComponent,
    Group,
    Inject,
    Page,
    PdfExport,
    QueryCellInfoEventArgs,
    RecordClickEventArgs,
    Reorder,
    Resize,
    Sort,
    Sorts,
} from '@syncfusion/ej2-react-grids'
import {
    ChangedEventArgs,
    TextBoxComponent,
} from '@syncfusion/ej2-react-inputs'
import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { RouteComponentProps, Router, withRouter } from 'react-router-dom'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import {
    clearAssets,
    getAsset,
    getAssets,
    getAssetTypes,
    toggleDeleteAssets,
} from '../../../../actions/assetsActions'
import { filterOptions } from '../../../../configuration/grid_syncfusion_options'
import { updateAlertMessage } from '../../../../helpers/alertHelpers'
import { updateToastMessage } from '../../../../helpers/toastHelper'
import history from '../../../../history'
import iconAction from '../../../../images/icons/transactions/icon_action.svg'
import noImage from '../../../../images/noImage.svg'
import { RootState } from '../../../../reducers/rootReducer'
import {
    IAssetAddEdit,
    IAssetDto,
    IAssetsListItemOptions,
    IAssetsListResponse,
    IAssetType,
    IAssetTypeCollection,
} from '../../../../types/Assets'
import AssociatedDocuments from '../../../atoms/AssociatedDocuments'
import IconButton from '../../../atoms/Buttons/IconButton'
import RotatingLoader from '../../../atoms/RotatingLoader'
import SelectDropdown, { IOptions } from '../../../atoms/SelectDropdown'
import SFDropdown from '../../../atoms/SFDropdown'
import SFAssetDropdown from '../../../atoms/SFDropdown/SFAssetDropdown'
import styles from './AssetsGrid.module.scss'
import { IAssetsGridOptions } from './_types'

interface IProps extends RouteComponentProps {
    location: any
    history: any
    match: any

    config?: IAssetsGridOptions

    assets?: IAssetsListResponse
    checkListAssetTypes?: IAssetTypeCollection
    getAssets?: any
    deleteAssets?: any
    getAssetTypes?: any
    clearAssets?(): any
    toggleDeleteAssets?(
        assetIds: Array<number>,
        open: boolean,
        returnUrl?: string
    ): void
    getAsset?: any
    loading?: boolean
    error?: string
}

interface IState {
    selectedAssetType: IOptions
    search?: string
}

class AssetsGrid extends React.Component<IProps, IState> {
    private grid: Grid | null
    public topRowRef: any

    constructor(props: any) {
        super(props)

        this.state = {
            selectedAssetType: null,
            search: null,
        }

        this.topRowRef = React.createRef()
    }

    onRowClick = (e: RecordClickEventArgs) => {
        // This filters out clicks on any of the other items for e.g. the checkbox and action menu
        if (e.target && e.target.classList.contains('e-rowcell')) {
            this.onEditAsset((e.rowData as IAssetDto).assetId)
        }
    }

    fetch = (
        page?: number,
        size?: number,
        skip?: number,
        sortQuery?: string
    ) => {
        // Adjust the following as per filtering requirements
        var options: IAssetsListItemOptions = {
            page: page | 0,
            size: size | 8,
            skip: skip | 0,
            onlyShowOwnRecords: false,
        }

        if (this.state.search) {
            options.search = this.state.search
        }

        options.size = size != undefined ? size : 20

        if (this.props.config.filterSettings) {
            const { unitId, propertyId } = this.props.config.filterSettings

            if (unitId) {
                options.unitId = unitId
            }

            if (propertyId) {
                options.propertyId = propertyId
            }
        }

        if (
            this.state.selectedAssetType &&
            Number(this.state.selectedAssetType.value) > 0
        ) {
            options.assetTypeId = Number(this.state.selectedAssetType.value)
        }

        options.sortQuery = sortQuery
        this.props.getAssets(options)
    }

    dataStateChange = (state: DataStateChangeEventArgs) => {
        let sortQuery: string = ''
        if ((state.sorted || []).length) {
            sortQuery = state.sorted
                .map((obj: Sorts) => {
                    return obj.direction === 'descending'
                        ? `${obj.name} desc`
                        : `${obj.name} asc`
                })
                .reverse()
                .join(',')
        }

        this.fetch(
            state.action['currentPage'],
            state.take,
            state.skip,
            sortQuery
        )
        this.grid.hideSpinner()
    }

    getDropdownDataInCorrectFormat = (assetTypes: IAssetType[]): IOptions[] => {
        // Shouldn't have to do this here, the Dropdown should accomodate a list of any type of properties
        // and then in the dropdown we simply have additional 2 properties, one to specify the name of the,
        // 'label' field and one for the name of the 'value' field.  May be create SelectDropdownV2?
        const parentTypes: IOptions[] = new Array()
        assetTypes.forEach((a) => {
            if (a != undefined) {
                parentTypes.push({
                    label: a.assetTypeName,
                    value: a.assetTypeId.toString(),
                })
            }
        })

        return parentTypes
    }

    componentDidMount() {
        this.props.getAssetTypes(0)
        this.fetch()
    }

    componentWillUnmount(): void {
        this.props.clearAssets()
    }

    onSelectAssetType = (options: IOptions) => {
        this.setState(
            {
                selectedAssetType: options,
            },
            () => this.fetch()
        )
    }

    image = (props: IAssetDto) => {
        if (props.images && props.images.length > 0) {
            let primary = props.images.find((x) => x.primary)
            if (primary) {
                return (
                    <img
                        onClick={() => this.onEditAsset(props.assetId)}
                        className={styles.image}
                        src={primary.original}
                    />
                )
            }

            return (
                <img
                    onClick={() => this.onEditAsset(props.assetId)}
                    className={styles.image}
                    src={props.images[0].original}
                />
            )
        }

        return (
            <img
                onClick={() => this.onEditAsset(props.assetId)}
                className={styles.image}
                src={noImage}
            />
        )
    }

    documents = (props: IAssetDto) => {
        return (
            <AssociatedDocuments
                hideNoDocumentText
                options={{ assetId: props.assetId }}
            />
        )
    }

    onAddAsset = () => {
        this.props.history.push({
            pathname: '/dashboard/asset/add',
            search: this.props.config.addSettings
                ? `assetsOptions=${JSON.stringify(
                      this.props.config.addSettings
                  )}`
                : '',
        })
    }

    onEditAsset = (assetId: number) => {
        let request: IAssetAddEdit = {
            propertyId: this.props.match.params.propertyId,
            unitId: this.props.match.params.unitId,
            referer: this.props.match.params.propertyId
                ? 'property'
                : this.props.match.params.unitId
                ? 'unit'
                : '',
        }

        this.props.history.push({
            pathname: `/dashboard/asset/view/${assetId}`,
            search: request ? `assetsOptions=${JSON.stringify(request)}` : '',
        })
    }

    contextMenuClickedDelete = () => {
        if (this.grid) {
            let rows = this.grid.getSelectedRecords()
            if (rows.length == 0) {
                updateToastMessage('No rows have been selected.', 'warning')
                return
            }
            let assetIds = []
            for (let i = 0; i < rows.length; i++) {
                assetIds.push((rows[i] as any).assetId)
            }

            this.onDeleteRows(assetIds)
        }
    }

    onDeleteRows = (assetIds: number[]) => {
        let returnUrl =
            this.props.config.filterSettings &&
            this.props.config.filterSettings.propertyId
                ? `/dashboard/property/${this.props.config.filterSettings.propertyId}/spec/assets`
                : `/dashboard/assets`
        this.props.toggleDeleteAssets(assetIds, true, returnUrl)
    }

    rowSelected = () => {
        if (this.grid) {
            let rows = this.grid.getSelectedRecords()
            let rowValue = (rows[0] as any).assetId
        }
    }

    customCell = (args: QueryCellInfoEventArgs) => {
        if ((args.column as Column).field === 'action') {
            let data = args.data as any
            ReactDOM.render(
                <Router history={history}>
                    <SFAssetDropdown
                        asset={data}
                        assetId={data.assetId}
                        onDelete={() => this.onDeleteRows([data.assetId])}
                        onEdit={() => this.onEditAsset(data.assetId)}
                    />
                </Router>,
                args.cell as Element
            )
        }
    }

    AssetsDataResult = (): DataResult => {
        return {
            result: this.props.assets.data,
            count: this.props.assets.count,
        }
    }

    render() {
        if (this.props.loading && this.props.assets.count === 0) {
            return (
                <div className={styles.loader}>
                    <RotatingLoader loading={true} text="Loading assets..." />
                </div>
            )
        }

        if (this.props.error) {
            updateAlertMessage(this.props.error, true, 3000, true)
            return null
        }

        const minWidthTablet = '768px'
        return (
            <div className={styles.page}>
                <div className={styles.content}>
                    {/** Add first row containg dropdowns etc */}
                    <div className={styles.row}>
                        <div className={styles.filterColumn}>
                            {/** Add search field */}
                            <div className={styles.searchInput}>
                                <TextBoxComponent
                                    showClearButton
                                    change={(args: ChangedEventArgs) =>
                                        this.setState(
                                            { search: args.value },
                                            () => this.fetch()
                                        )
                                    }
                                    placeholder="Assets Search"
                                />
                            </div>

                            <div className={styles.typeDropdown}>
                                {/** Add dropdown */}
                                <SelectDropdown
                                    showAllOption
                                    selectedId={
                                        this.state.selectedAssetType &&
                                        this.state.selectedAssetType.value
                                    }
                                    placeholder="Filter by type..."
                                    data={this.getDropdownDataInCorrectFormat(
                                        this.props.checkListAssetTypes
                                            .assetSubtypes
                                    )}
                                    onSelect={this.onSelectAssetType}
                                />
                            </div>
                        </div>

                        {/** Add dropdown */}
                        <div className={styles.iconColumn}>
                            <SFDropdown
                                loadedBeforeRender
                                customCssClassName={styles.syncfusionDropdown}
                                customToggle={
                                    <IconButton
                                        button={{
                                            text: 'Actions',
                                            displayType: 'action',
                                            elementType: 'button',
                                            icon: 'action',
                                        }}
                                    />
                                }
                                items={[
                                    {
                                        iconCss: styles.subIconAdd,
                                        text: 'Add Asset',
                                    },
                                    {
                                        iconCss: styles.subIconDelete,
                                        text: 'Delete',
                                    },
                                    {
                                        separator: true,
                                    },
                                    {
                                        iconCss: styles.subIconExportExcel,
                                        text: 'Export to Excel',
                                    },
                                    {
                                        iconCss: styles.subIconExportCsv,
                                        text: 'Export to CSV',
                                    },
                                    {
                                        iconCss: styles.subIconExportPdf,
                                        text: 'Export to PDF',
                                    },
                                ]}
                                onSelect={(args) => {
                                    if (args.item.text === 'Add Asset') {
                                        this.onAddAsset()
                                    }
                                    if (args.item.text === 'Delete') {
                                        this.contextMenuClickedDelete()
                                    }
                                    if (args.item.text === 'Export to PDF') {
                                        this.grid.pdfExport()
                                    }
                                    if (args.item.text === 'Export to Excel') {
                                        this.grid.excelExport()
                                    }
                                    if (args.item.text === 'Export to CSV') {
                                        this.grid.csvExport()
                                    }
                                }}
                            />
                        </div>

                        <div className={`${styles.iconColumn}`}>
                            <IconButton
                                button={{
                                    text: 'Add Asset',
                                    displayType: 'submit',
                                    elementType: 'button',
                                    icon: 'add',
                                    onClick: () => this.onAddAsset(),
                                }}
                            />
                        </div>
                    </div>

                    {/* Mobile dropdown */}
                    <div className={styles.mobileDropdown}>
                        <SelectDropdown
                            showAllOption
                            selectedId={
                                this.state.selectedAssetType &&
                                this.state.selectedAssetType.value
                            }
                            placeholder="Filter by type..."
                            data={this.getDropdownDataInCorrectFormat(
                                this.props.checkListAssetTypes.assetSubtypes
                            )}
                            onSelect={this.onSelectAssetType}
                        />
                    </div>

                    <div className={`${styles.row}`}>
                        <div className={`${styles.column} ${styles.grid}`}>
                            <GridComponent
                                recordClick={this.onRowClick}
                                rowSelected={this.rowSelected}
                                ref={(g) => (this.grid = g)}
                                queryCellInfo={this.customCell}
                                //editSettings={editSettings}
                                showColumnMenu
                                id="assetsGrid"
                                //textWrapSettings={textWrapSettings}
                                allowTextWrap={true}
                                allowExcelExport={true}
                                allowPdfExport={true}
                                allowGrouping={true}
                                pageSettings={{ pageSize: 20, pageSizes: true }}
                                dataSource={this.AssetsDataResult()}
                                dataStateChange={this.dataStateChange.bind(
                                    this
                                )}
                                allowResizing={true}
                                allowReordering={true}
                                selectionSettings={{ checkboxOnly: true }}
                                allowPaging={true}
                                allowSorting={true}
                                filterSettings={filterOptions}
                            >
                                <Inject
                                    services={[
                                        Aggregate,
                                        ColumnMenu,
                                        ContextMenu,
                                        CommandColumn,
                                        Filter,
                                        Page,
                                        Sort,
                                        ExcelExport,
                                        PdfExport,
                                        Group,
                                        Reorder,
                                        Resize,
                                    ]}
                                />
                                <ColumnsDirective>
                                    <ColumnDirective
                                        allowSorting={false}
                                        hideAtMedia={`(min-width: ${minWidthTablet})`}
                                        textAlign="Left"
                                        type="checkbox"
                                        width="50"
                                    />
                                    <ColumnDirective
                                        showInColumnChooser={false}
                                        showColumnMenu={false}
                                        allowSorting={false}
                                        field="action"
                                        headerText=""
                                        textAlign="Left"
                                        width="100"
                                    />
                                    <ColumnDirective
                                        showColumnMenu={false}
                                        field="image"
                                        headerText="Image"
                                        width="100"
                                        template={this.image}
                                    />
                                    <ColumnDirective
                                        field="assetName"
                                        headerText="Name"
                                        textAlign="Left"
                                    />

                                    {(!this.props.config.filterSettings ||
                                        !this.props.config.filterSettings
                                            .propertyId) && (
                                        <ColumnDirective
                                            field="propertyName"
                                            headerText="Property Name"
                                            textAlign="Left"
                                        />
                                    )}
                                    <ColumnDirective
                                        visible={false}
                                        hideAtMedia={`(min-width: ${minWidthTablet})`}
                                        field="assetTypeLevel1Name"
                                        headerText="Category"
                                        textAlign="Left"
                                    />
                                    <ColumnDirective
                                        hideAtMedia={`(min-width: ${minWidthTablet})`}
                                        field="assetTypeLevel2Name"
                                        headerText="Type"
                                        textAlign="Left"
                                    />
                                    <ColumnDirective
                                        hideAtMedia={`(min-width: ${minWidthTablet})`}
                                        field="model"
                                        headerText="Model"
                                        textAlign="Left"
                                    />
                                    <ColumnDirective
                                        hideAtMedia={`(min-width: ${minWidthTablet})`}
                                        field="documents"
                                        headerText="Docs"
                                        textAlign="Left"
                                        template={this.documents}
                                    />

                                    <ColumnDirective
                                        visible={false}
                                        textAlign="Left"
                                        headerText="Installed"
                                        field="dateOfInstallation"
                                        type="date"
                                        format={{
                                            type: 'date',
                                            format: 'dd/MM/yyyy HH:mm',
                                        }}
                                    />

                                    <ColumnDirective
                                        visible={false}
                                        textAlign="Left"
                                        headerText="Warranty expires"
                                        field="warrantyExpirationDate"
                                        type="date"
                                        format={{
                                            type: 'date',
                                            format: 'dd/MM/yyyy HH:mm',
                                        }}
                                    />

                                    <ColumnDirective
                                        visible={false}
                                        textAlign="Left"
                                        headerText="Next Service date"
                                        field="nextService"
                                        type="date"
                                        format={{
                                            type: 'date',
                                            format: 'dd/MM/yyyy HH:mm',
                                        }}
                                    />

                                    <ColumnDirective
                                        visible={false}
                                        textAlign="Left"
                                        headerText="Created Date"
                                        field="dateCreated"
                                        type="date"
                                        format={{
                                            type: 'date',
                                            format: 'dd/MM/yyyy HH:mm',
                                        }}
                                    />

                                    <ColumnDirective
                                        visible={false}
                                        textAlign="Left"
                                        headerText="Created by"
                                        field="createdByName"
                                    />
                                </ColumnsDirective>
                            </GridComponent>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    checkListAssetTypes: state.assetTypes.data,
    assets: state.assets.data,
    loading: state.assets.loading,
    error: state.assets.error,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            clearAssets,
            getAssetTypes,
            getAssets,
            toggleDeleteAssets,
            getAsset,
        },
        dispatch
    )

export default withRouter<IProps,any>(
    connect(mapStateToProps, mapDispatchToProps)(AssetsGrid)
)
