import { store } from "../store";
import Rollbar from 'rollbar';
import { getUserId } from "./authHelpers";
import { getLoggedInUsersName } from "./profileHelpers";
import { environment } from "../configuration/domains";
import { buildNumber } from "../configuration/appConfig";

export function setRollbarConfiguration(): Rollbar {
  let data = store.getState();
  var rollbar = new Rollbar({
    accessToken: "a0cbed094098485e8c5dd626712e8735",
    logLevel: "error",
    captureUncaught: true,
    captureUnhandledRejections: false,
    enabled: true,
    payload: {
      person: {
        id: getUserId(),
        username: getLoggedInUsersName(),
        email: data.user.email
      },
      environment: environment,
      codeVersion: buildNumber
    }
  });

  return rollbar;
}

class Service {
  private _rollbarInstance: Rollbar = null;

  constructor() {
  }

  getInstance(): Rollbar {
    if (this.hasInstance()) {
      return this._rollbarInstance;
    }

    this.createRollbarInstance();
    return this._rollbarInstance;
  }

  hasInstance(): boolean {
    if (this._rollbarInstance != null) {
      return true;
    }
    return false;
  }

  createRollbarInstance(): void {
    if (this.hasInstance()) {
      return;
    }

    let data = store.getState();
    var rollbar = new Rollbar({
      accessToken: "a0cbed094098485e8c5dd626712e8735",
      logLevel: "error",
      captureUncaught: true,
      captureUnhandledRejections: false,
      enabled: true,
      payload: {
        person: {
          id: getUserId(),
          username: getLoggedInUsersName(),
          email: data.user.email
        },
        environment: environment,
        codeVersion: buildNumber
      }
    });

    this._rollbarInstance = rollbar;
  }
}

const RollbarService = new Service();

export default RollbarService;