import { createBearerToken } from '../helpers/authHelpers';

export function toQueryString(obj) {
    var parts = [];
    for (var i in obj) {
        if (obj.hasOwnProperty(i)) {
            parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]));
        }
    }
    return parts.join("&");
}

export const toQueryStringArray = (arr: number[]): string => {
    if (!arr || arr.length === 0) {
        return "";
    }

    let query = "";

    arr.map((a) => {
        query += "&taskIds=" + a;
    });

    return query;
};

export const queryHeaders = (headers) => {
    headers.set('Authorization',createBearerToken())
    headers.set('Content-Type','application/json')
    headers.set('Access-Control-Allow-Origin','*')
    return headers
}