import React, { useState } from "react";
import "./App.scss";
import "@rentchief/components/dist/style.css";
import Toast from "./components/atoms/Toast";

import PageContainer from "./components/organisms/PageContainer";
import WhitelabelWrapper from "./components/organisms/WhitelabelWrapper";
import { ThemeProvider } from "styled-components";
import defaultTheme from "./themes/default.theme.json";
import { ApiContext, ThemeContext } from "@rentchief/components";
import * as domains from "./configuration/domains";
import { Helmet } from "react-helmet";
import favicon192 from "./images/favicons/favicon-192.png";
import favicon160 from "./images/favicons/favicon-160.png";
import favicon96 from "./images/favicons/favicon-96.png";
import favicon64 from "./images/favicons/favicon-64.png";
import favicon32 from "./images/favicons/favicon-32.png";
import favicon16 from "./images/favicons/favicon-16.png";
import favicon57 from "./images/favicons/favicon-57.png";
import favicon72 from "./images/favicons/favicon-72.png";
import favicon114 from "./images/favicons/favicon-114.png";
import favicon144 from "./images/favicons/favicon-144.png";
import favicon60 from "./images/favicons/favicon-60.png";
import favicon120 from "./images/favicons/favicon-120.png";
import favicon76 from "./images/favicons/favicon-76.png";
import favicon152 from "./images/favicons/favicon-152.png";
import favicon180 from "./images/favicons/favicon-180.png";

const App = () => {
  const [theme, setTheme] = useState<any>(defaultTheme);

  return (
    <div className="App" id="app">
      <Helmet>
        <link rel="icon" type="image/png" sizes="196x196" href={favicon192} />
        <link rel="icon" type="image/png" sizes="160x160" href={favicon160} />
        <link rel="icon" type="image/png" sizes="96x96" href={favicon96} />
        <link rel="icon" type="image/png" sizes="64x64" href={favicon64} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
        <link rel="apple-touch-icon" href={favicon57} />
        <link rel="apple-touch-icon" sizes="114x114" href={favicon114} />
        <link rel="apple-touch-icon" sizes="72x72" href={favicon72} />
        <link rel="apple-touch-icon" sizes="144x144" href={favicon144} />
        <link rel="apple-touch-icon" sizes="60x60" href={favicon60} />
        <link rel="apple-touch-icon" sizes="120x120" href={favicon120} />
        <link rel="apple-touch-icon" sizes="76x76" href={favicon76} />
        <link rel="apple-touch-icon" sizes="152x152" href={favicon152} />
        <link rel="apple-touch-icon" sizes="180x180" href={favicon180} />
      </Helmet>

      <ApiContext.Provider
        value={{
          base_rc_research_ui_url: domains.rootRcResearchUiUrl,
          base_rc_ui_url: `https://dev.rentchief.co.uk`,
          calculation_service: `${domains.rootDomainCore}/api/v1/CalculationV2/calculate`,
          land_registry_service: domains.landRegistryService,
          address_service: `${domains.rootDomainIntegrations}/api/v1/address/find/uk`,
        }}
      >
        <ThemeContext.Provider
          value={{
            theme,
            setTheme,
          }}
        >
          <WhitelabelWrapper setTheme={setTheme}>
            <ThemeProvider theme={theme}>
              <Toast />
              <PageContainer />
            </ThemeProvider>
          </WhitelabelWrapper>
        </ThemeContext.Provider>
      </ApiContext.Provider>
    </div>
  );
};

export default App;
