// Import React
import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import styles from "./UserRolesPage.module.scss";
import { IUserProfileResponse } from "../../../../types/ProfileService/IProfile";
import { toast } from "react-toastify";

interface IState {
  userProfile: IUserProfileResponse;
  isLoading: boolean;
}

interface IManualProps {
  userId?: string;
  userProfile: IUserProfileResponse;
}

interface IRouterProps {
  history: any;
  location: any;
  match: any;
}

type Props = IRouterProps & IManualProps;

class UserRolesPage extends Component<Props, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      userProfile: this.props.userProfile,
      isLoading: false
    };
  }

  notify = (message: string) => {
    toast(message);
  };

  render() {
    return (
      <div className={styles.page}>
        User role information here
      </div>
    );
  }
}

export default withRouter(UserRolesPage);