import styled from 'styled-components';

export const IssueListDiv = styled.div<{height?: string}>`
    flex: 0 40%;
    border-right: 1px solid #f0f2f6;
    overflow: auto;
    //height: calc(100vh - 77.83px);
    height: ${p => p.height};
    padding-top: 0;
    background-color: white;
    margin-left: 0;

    //@include tablet {
        //margin-left: 0;
        //padding: 0;
    //}
`