import React, { Component } from 'react'
import 'react-input-range/lib/css/index.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import 'react-tippy/dist/tippy.css'
import { toast } from 'react-toastify'
import { Col, Row } from 'reactstrap'
import { Dispatch } from 'redux'
import {
    toggleAddPropertySidebar,
    toggleEditPropertySidebar,
} from '../../../actions/appActions'
import { addPropertyFromCalculator } from '../../../actions/calculatorActions'
import {
    dispatchUpdateCalculateReturnsFromEdit,
    dispatchUpdatePropertyList,
} from '../../../actions/propertyActions'
import { CalculateProfitPCM } from '../../../helpers/calculationHelper'
import { addURLParameter } from '../../../helpers/locationHelpers'
import { isLandlord, isPlatformAdmin } from '../../../helpers/roleHelpers'
import { delayAction } from '../../../helpers/timerHelpers'
import { processToast } from '../../../helpers/toastHelper'
import navIconCircleGreen from '../../../images/icons/AddPropertyWizard/wizard_complete.svg'
import navIconCircleCurrent from '../../../images/icons/AddPropertyWizard/wizard_current.svg'
import navIconCircleNext from '../../../images/icons/AddPropertyWizard/wizard_next.svg'
import { ICalculateReturnsData } from '../../../reducers/propertyReducer'
import {
    addPropertyFromSteps,
    editProperty,
} from '../../../services/PropertyService'
import { IDataResponse } from '../../../types/ApiService'
import {
    IAddPropertyFromStepsRequest,
    IAddress,
    ICalculatorAddPropertyRequest,
    IEditPropertyRequest,
} from '../../../types/PropertyService/IPropertyService'
import { ITenancyItem } from '../../../types/Tenancy'
import Buttons from '../../atoms/Buttons'
import CloseButton from '../../atoms/CloseButton'
import { IData } from '../../atoms/PropertyListItem'
import RotatingLoader from '../../atoms/RotatingLoader'
import SuccessTick from '../../atoms/SuccessTick'
import styles from './AddEditPropertyForm.module.scss'
import Property from './Property'

const queryString = require('query-string')

interface IProps {
    toggleSidebar(show: boolean): void
}

interface IState {
    pageNumber: number
    submitting: boolean
    added: boolean
    editPropertyId: string
    isEditProperty: boolean
    propertyOwnershipStatus: number
    finishButtonActive: boolean
    monthlyRentalAmount: string
    depositAmount: string
    showAddError: boolean
}

interface IRouterProps {
    history: any
    location: any
    match: any
}

interface IReduxProps {
    last_added_property_id_guest: string
    last_added_property_id: string
    addPropertyFromCalculator(request: ICalculatorAddPropertyRequest): void
    dispatchUpdateCalculateReturnsFromEdit(data: IUpdateProperty): void
    dispatchUpdatePropertyList(data: any): void
    app_loading: boolean
    is_logged_in: boolean
    userId: string
    add_property: boolean
    data: ICalculateReturnsData
    property_list_data: IData[]
    add_document_popup: boolean
}

export interface IUpdateProperty {
    propertyId: string
    propertyName: string
    address: IAddress
    propertyStatusName: string
    propertyStatus: string
}

export interface IAddPropertyTenancy {}

type Props = IRouterProps & IProps & IReduxProps

let tenantSearchRef

class AddEditPropertyForm extends Component<Props, IState> {
    propertyRef
    tenancyRef
    requirementsRef
    regulationRef
    moreDetailsRef

    constructor(props: any) {
        super(props)
        this.propertyRef = React.createRef()
        this.regulationRef = React.createRef()
        this.moreDetailsRef = React.createRef()

        this.state = {
            pageNumber: 0,
            submitting: false,
            added: false,
            editPropertyId: '',
            isEditProperty: false,
            propertyOwnershipStatus: 1,
            finishButtonActive: false,
            monthlyRentalAmount: '800',
            depositAmount: '50000',
            showAddError: false,
        }
    }

    componentDidMount = (): void => { 
        this.setState({
            submitting: false,
            added: false,
        });

        let editPropertyId = queryString.parse(
            this.props.location.search
        ).editProperty
        let isEditProperty =
            editPropertyId != null &&
            editPropertyId != undefined &&
            editPropertyId != ''
        this.setState({ editPropertyId, isEditProperty })
    }

    componentWillUnmount = () => {
        console.log(this.propertyRef);
    }

    setPageNumber = (pageNumber) => {
        this.setState({ pageNumber })
    }

    updateRentAmount = (monthlyRentalAmount) => {
        this.setState({ monthlyRentalAmount })
    }

    updateDepositAmount = (depositAmount) => {
        this.setState({ depositAmount })
    }

    changePageNumber = (difference: any): void => {
        let pageNumber = this.state.pageNumber + difference
        if (pageNumber < 0) {
            pageNumber = 0
        }

        if (pageNumber > 2) {
            pageNumber = 2
        }
        this.setState({ pageNumber })
    }

    getNavIcon(tabReference: number): any {
        let page = this.state.pageNumber
        let icon = navIconCircleNext
        if (page === tabReference) {
            icon = navIconCircleCurrent
        }
        if (page > tabReference) {
            icon = navIconCircleGreen
        }

        return icon
    }

    getNavTick(tabReference: number): boolean {
        if (this.state.pageNumber > tabReference) {
            return true
        }

        return false
    }

    getBarStyles(tabReference: number) {
        return this.state.pageNumber >= tabReference
            ? styles.selected
            : styles.unselected
    }

    async handleShowResultsClick(): Promise<void> {
        this.setState({
            submitting: true,
        })

        let pRef = this.propertyRef.current 

        pRef.state.dateOfPurchase.setUTCDate(
            pRef.state.dateOfPurchase.getDate()
        )
        pRef.state.dateOfPurchase.setUTCHours(
            pRef.state.dateOfPurchase.getHours()
        )

        // Have added this here, prior to this the name was calculated on the server (PropertyServices.CreateProperty),
        // so the time was being displayed was UTC time. This still isn't strictly correct as the local time will be different
        // depending on what timezone you are in.
        /*         if(pRef.state.propertyName == '')
        {
            var timeNow = new Date();
            var hour = timeNow.getHours();
            var minute = timeNow.getMinutes();
            pRef.state.propertyName = `Property ${hour}:${minute}`;
        } */

        if (!this.state.isEditProperty) {
            let request: IAddPropertyFromStepsRequest = {
                propertyRequest: {
                    propertyData: pRef.state.propertyData,
                    landlordContactId: pRef.state.landlordContactId,
                    propertyManagerContactId:
                        pRef.state.propertyManagerContactId,
                    email: pRef.state.email,
                    propertyName: pRef.state.propertyName,
                    address: pRef.state.address,
                    purchasePrice: pRef.state.purchasePrice,
                    monthlyRentalAmount: pRef.state.monthlyRentalAmount | 0,
                    investmentAmount: pRef.state.investmentAmount,
                    mortgageAmount: pRef.state.mortgageAmount,
                    yearsOfInvestment: pRef.state.yearsOfInvestment,
                    deposit: pRef.getIsDepositRequired()
                        ? pRef.state.deposit
                        : pRef.state.purchasePrice,
                    bedrooms: pRef.state.bedrooms,
                    bathrooms: pRef.state.bathrooms,
                    size: pRef.state.size,
                    sizeType: pRef.state.sizeType,
                    parking: pRef.state.parking,
                    numberOfParkingSpaces: pRef.state.numberOfParkingSpaces,
                    propertyType: pRef.state.propertyType,
                    yearBuilt: pRef.state.yearBuilt,
                    finishQuality: pRef.state.finishQuality,
                    outdoorSpace: pRef.state.outdoorSpace,
                    propertyStatus: pRef.getPropertyStatus(),
                    dateOfPurchase: pRef.state.dateOfPurchase,
                    ltv: pRef.state.ltv,
                    interestRate: pRef.state.interestRate,
                    valuationFee: pRef.state.valuationFee,
                    mortgageFee: pRef.state.mortgageFee,
                    refurbishment: pRef.state.refurbishment,
                    stampDuty: pRef.state.stampDuty,
                    lettingAgent: pRef.state.lettingAgent,
                    maintenance: pRef.state.maintenance,
                    voidPeriod: pRef.state.voidPeriod,
                    insurance: pRef.state.insurancePCM,
                    capitalGrowthRate: pRef.state.capitalGrowthRate,
                    rentalGrowthRate: pRef.state.rentalGrowthRate,
                    serviceChargesPCM: pRef.state.serviceChargesPCM,
                    groundRentPCM: pRef.state.groundRentPCM,
                    solicitorFeeAndSearches: pRef.state.solicitorFeeAndSearches,

                    investmentAmountPercent: pRef.state.investmentAmountPercent,
                    mortgagePCM: pRef.state.mortgagePCM,
                    mortgageType: pRef.state.mortgageType,
                    mortgageTerm: pRef.state.mortgageTerm,
                    lettingAgentPercent: pRef.state.lettingAgentPercent,
                    maintenancePercent: pRef.state.maintenancePercent,
                    voidPeriodPercent: pRef.state.voidPeriodPercent,
                    refurbishmentPercent: pRef.state.refurbishmentPercent,
                    otherPurchaseCosts: pRef.state.otherPurchaseCosts,
                    otherOperatingCosts: pRef.state.otherOperatingCosts,
                    country: pRef.state.country,
                    stampDutyLocale: pRef.state.stampDutyLocale,
                    isInvestmentProperty: pRef.state.isInvestmentProperty,
                    insurancePCM: pRef.state.insurancePCM,
                    renovationPeriodMonths: pRef.state.renovationPeriodMonths,
                    propertyValueAfterRenovation: pRef.state.propertyValueAfterRenovation,
                    investorStrategy: pRef.state.investorStrategy,
                    clientName: pRef.state.clientName
                },
            }

            if (pRef.props.is_logged_in) {
                let response = await addPropertyFromSteps(request)

                if (response.data) {
                    this.afterSuccess(response.data)
                }
                return
            }
            await pRef.props.addPropertyFromCalculator(request)
        } else {
            let request: IEditPropertyRequest = {
                propertyId: this.state.editPropertyId,
                propertyName: pRef.state.propertyName,
                landlordContactId: pRef.state.landlordContactId,
                propertyManagerContactId: pRef.state.propertyManagerContactId,
                address: pRef.state.hasUpdatedAddress
                    ? pRef.state.address
                    : null,
                purchasePrice: pRef.state.purchasePrice,
                monthlyRentalAmount: pRef.state.monthlyRentalAmount,
                yearsOfInvestment: pRef.state.yearsOfInvestment,
                deposit: pRef.getIsDepositRequired()
                    ? pRef.state.deposit
                    : pRef.state.purchasePrice,
                propertyStatus: pRef.getPropertyStatus(),
                dateOfPurchase: pRef.state.dateOfPurchase,
            }

            if (this.props.is_logged_in) {
                let response = await editProperty(request)

                if (response) {
                    this.setState({
                        submitting: false,
                        added: true,
                    })

                    delayAction(2500, () => this.afterSuccessEdit(pRef))
                }

                return
            }
        }
    }

    afterSuccess = (data: any): void => {
        this.props.toggleSidebar(false)

        let results: IDataResponse<string>[] = data

        if (this.state.propertyOwnershipStatus == 1) {
            this.props.history.push(
                `/dashboard/property/${results[0].data}/summary?showInitialPopup=true`
            )
        } else {
            this.props.history.push(
                `/dashboard/property/${results[0].data}/summary`
                //`/dashboard/property/${results[0].data}/research/investment-returns`
            )
        }

        results.forEach((x) => {
            processToast<string>(x)
        })
    }

    afterSuccessEdit = (pRef: any) => {
        let route = this.getRoute()
        if (route == 'propertyList') {
            let properties = this.props.property_list_data
            if (!properties) {
                return
            }

            var updatedPropertiesList = properties.map((prop) => {
                if(prop.property.propertyId == this.state.editPropertyId){
                    return {
                        ...prop,
                        address: pRef.state.address,
                        propertyName: pRef.state.propertyName,
                        propertyStatus: pRef.state.propertyStatus,
                        statusId: pRef.state.propertyStatus,
                        status: pRef.state.propertyStatusName,
                        calculations: {
                            purchasePrice: pRef.state.purchasePrice,
                            rentPCM: pRef.state.monthlyRentalAmount,
                            mortgage: pRef.state.purchasePrice - pRef.state.deposit,
                            profitPCM: CalculateProfitPCM(pRef.state.monthlyRentalAmount, prop.calculations ? prop.calculations.costsPCM : 0),
                            investment: pRef.state.investment,
                            costsPCM: pRef.state.costsPCM,
                            roi: pRef.state.roi,
                            gyi: pRef.state.gyi
                        }
                    }
                }else {
                    return prop;
                }
            });

            this.props.dispatchUpdatePropertyList(updatedPropertiesList)
        }

        if (route == 'calculateReturns') {
            let query = addURLParameter(
                this.props.location.search,
                'refreshProperty=true'
            )
            this.props.history.push({
                search: query,
            })
        }

        this.wipeQueryParameters()

        if (route == 'research') {
            window.location.reload()
        }

        this.props.toggleSidebar(false)
    }

    wipeQueryParameters = (): void => {
        this.props.history.push({
            search: '',
        })
    }

    notify = (message: string) => {
        toast(message)
    }

    getRoute(): string {
        let route = this.props.location.pathname

        if (route.indexOf('/dashboard/properties') > -1) {
            return 'propertyList'
        }

        if (route.indexOf('/research') > -1) {
            return 'research'
        }

        if (route.indexOf('property') > -1) {
            return 'calculateReturns'
        }

        return ''
    }

    propertyOwnedSelection = (newStatus: number): void => {
        this.setState({ propertyOwnershipStatus: newStatus })
    }

    closeSidebar = () => {
        this.props.toggleSidebar(false)
    }

    updateTenancy = (tenant: ITenancyItem) => {
        if (this.state.pageNumber == 1) {
            if (
                tenant.contactId == null ||
                tenant.contactId == '' ||
                !tenant.contactId
            ) {
                this.setState({ finishButtonActive: false })
            } else {
                this.setState({ finishButtonActive: true })
            }
        }
    }

    render() {
        if (!isLandlord() && !isPlatformAdmin()) {
            return <div>Not authorised</div>
        }

        if (this.state.submitting) {
            return (
                <div>
                    <RotatingLoader
                        loading={true}
                        text={
                            this.state.isEditProperty
                                ? 'Updating your property...'
                                : 'Adding your new property...'
                        }
                    />
                </div>
            )
        }

        if (this.state.added) {
            return (
                <div>
                    <SuccessTick />{' '}
                    <span className={styles.text}>
                        {`${
                            this.state.isEditProperty
                                ? 'Your property has been updated.'
                                : 'Your new property has been added.'
                        }`}
                    </span>
                </div>
            )
        }

        let tabNames: string[] = ['Property']

        return (
            <div className={styles.page}>
                <Row className={styles.header}>
                    <Col>
                        <div className={styles.headerTitle}>
                            {this.state.editPropertyId
                                ? 'Edit Property'
                                : 'Add Property'}
                            <div className={styles.close}>
                                <CloseButton close={this.closeSidebar} />
                            </div>
                        </div>
                    </Col>
                </Row>

                <div
                    className={
                        this.state.pageNumber == 0
                            ? styles.shown
                            : styles.hidden
                    }
                >
                    <div className={styles.formWrapper}>
                        <Property
                            {...this.props}
                            wrappedComponentRef={this.propertyRef}
                            moreDetailsRef={this.moreDetailsRef}
                            propertyOwnedSelection={this.propertyOwnedSelection}
                            propertyOwnershipStatus={
                                this.state.propertyOwnershipStatus
                            }
                            updateRentAmount={this.updateRentAmount}
                            updateDepositAmount={this.updateDepositAmount}
                            changePageNumber={this.changePageNumber}
                            editMode={this.state.isEditProperty}
                            userId={this.props.userId}
                        />
                    </div>
                </div>

                <div className={styles.sticky}>
                    <Buttons
                        buttons={[
                            {
                                width: 'full',
                                visible: this.state.pageNumber == 0,
                                displayType: 'cancel',
                                elementType: 'button',
                                onClick: () => this.closeSidebar(),
                            },
                            {
                                width: 'full',
                                visible: this.state.pageNumber == 0,
                                text: this.state.editPropertyId
                                    ? 'Update'
                                    : 'Add',
                                displayType: 'submit',
                                elementType: 'button',
                                onClick: () => this.handleShowResultsClick(),
                            },
                        ]}
                    />

                    {this.state.pageNumber == 1 && this.state.showAddError && (
                        <div className={styles.error}>
                            Please add a contact to enable this tenancy
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    last_added_property_id_guest: state.calculator.last_added_property_id,
    last_added_property_id: state.property.last_added_property_id,
    app_loading: state.app.app_loading,
    is_logged_in: state.user.is_logged_in,
    userId: state.user.user_id,
    add_property: state.app.sidebars.add_property,
    data: state.property.property_data.property_data,
    property_list_data: state.property.property_data.property_list_data,
    add_document_popup: state.app.popups.add_document_popup,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addPropertyFromCalculator: (request: ICalculatorAddPropertyRequest) =>
        dispatch<any>(addPropertyFromCalculator(request)),
    toggleSidebar: (show: boolean) => {
        dispatch(toggleAddPropertySidebar(show))
        dispatch(toggleEditPropertySidebar(show))
    },
    dispatchUpdateCalculateReturnsFromEdit: (data: IUpdateProperty) => {
        dispatch(dispatchUpdateCalculateReturnsFromEdit(data))
    },
    dispatchUpdatePropertyList: (data: any) =>
        dispatch(dispatchUpdatePropertyList(data)),
})

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AddEditPropertyForm)
)
