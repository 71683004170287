import styled from 'styled-components';

export const SectionDiv = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 25% 24% 24% 21%; 
    grid-template-areas:
        'header header header header'
        'col1 col2 col3 col4';
`; 

export const Col1 = styled.div`
    grid-area: col1;
`;

export const Col2 = styled.div`
    grid-area: col2;
`;

export const Col3 = styled.div`
    grid-area: col3;
`;

export const Col4 = styled.div`
    grid-area: col4;
`;