import React from "react";
import styles from "./ImageGalleryDropdown.module.scss";
import SFDropdown from "../../SFDropdown";
import actionIcon from "../../../../images/icons/image_action_icon.svg";
import { ImageSourceEnum } from "../../../../types/ImageService";

export interface ImageGalleryDropdownOption {
  text: string;
  action(): void;
}

export interface ImageGalleryDropdownArgs {
  options: Array<ImageGalleryDropdownOption>;
}

const ImageGalleryDropdown = ({ onClickUpload, onClickDelete, onChangePrimaryImage, onChangeStreetView, isPrimaryImageActive, openMap, activeSource, latitude, longitude }) => {

  const DeleteImage = async () => {
    onClickDelete()
  }

  return (
    <SFDropdown
      loadedBeforeRender
      customToggle={<img className={"image"} src={actionIcon} alt='Image Gallery dropdown action button' />}
      onSelect={(args) => {
        if (args.item.text === "Upload") {
          onClickUpload();
        }
        if (args.item.text === "Delete") {
          DeleteImage();
        }
        if (args.item.text === "Set as primary") {
          onChangePrimaryImage();
        }
        if (args.item.text === "Edit Street View") {
          onChangeStreetView();
        }
        if (args.item.text === "View location") {
          openMap();
        }
      }}
      items={[
        ...(activeSource == ImageSourceEnum.GOOGLE_STREET
          ? [
            {
              iconCss: styles.iconDelete,
              text: "Edit Street View",
            },
          ]
          : []),
        ...(!isPrimaryImageActive
          ? [
            {
              iconCss: styles.iconDelete,
              text: "Set as primary",
            },
          ]
          : []),
        ...(latitude && longitude
          ? [
            {
              iconCss: styles.iconDelete,
              text: "View location",
            },
          ]
          : []),
        {
          iconCss: styles.iconDelete,
          text: "Upload",
        },
        ...(activeSource != ImageSourceEnum.GOOGLE_STREET
          ? [
            {
              iconCss: styles.iconDelete,
              text: "Delete",
            }
          ]
          : []),
      ]}
    />
  )
}

export default ImageGalleryDropdown;