import { apiService } from "../services/ApiService";
import { createBearerToken } from "../helpers/authHelpers";
import * as domains from "../configuration/domains";

export const getSearchTransactions = (search: string): Promise<any> => {
  return apiService.get(`${domains.searchService}/transactions/${search}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export const getSearchIssues = (search: string): Promise<any> => {
  return apiService.get(`${domains.searchService}/issues/${search ? search : ""}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export const getSearchContact = (search: string, contactType?: Array<string>): Promise<any> => {
  let route = `${domains.searchService}/contact/${search}`;

  if (contactType) {
    let q = "";
    contactType.forEach(x => {
      q += `contactType=${x}&`;
    })

    if (contactType) {
      route += `?${q}`;
    }
  }

  return apiService.get(route, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export const getSearchProperties = (search: string, contactId?: string): Promise<any> => {
  return apiService.get(`${domains.searchService}/Property/${search}${`${contactId ? `?contactId=${contactId}`: ``}`}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export const getSearchUnits = (search?: string, propertyId?: string): Promise<any> => {
  let propertyQuery = `?propertyId=${propertyId}`;

  return apiService.get(`${domains.searchService}/Unit/${search ? search : ""}${propertyId ? propertyQuery : ""}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export const getSearchUsers = (search: string): Promise<any> => {
  return apiService.get(`${domains.searchService}/user/${search}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};