export interface ILayoutConfig {
    showGetStarted: boolean;
    showHelpCentre: boolean;
    showProperties: boolean;
    showMaintenance: boolean;
    showResearchArea: boolean;
    showDealCalculator: boolean;
    showPortfolioChart: boolean;
    showRecentProperties: boolean;
    showTasksSummary: boolean,
    showIncome: boolean;
    showMoneyChart1: boolean, 
}

export const defaultLayoutConfig: ILayoutConfig = {
    showGetStarted: true,
    showHelpCentre: true,
    showProperties : true, 
    showMaintenance: true, 
    showResearchArea: true, 
    showDealCalculator: true,
    showPortfolioChart: true,
    showRecentProperties: true,
    showTasksSummary: true,
    showIncome: true,
    showMoneyChart1: true, 
}

export interface ILayoutObject {
    i: string;
    x: number;
    y: number;
    w: number;
    h: number;
    //maxH?: number;
    //maxW?: number;
    //minH?: number;
    //minW?: number;
    isDraggable: boolean;
    isResizable: boolean;
    //isBounded?: boolean
    //moved?: boolean;
    //static?: boolean;
}

export const deduceLayout = (layoutName: string, allLayoutItems: Array<ILayoutObject>, editable: boolean, hasProperties: boolean) => {
    let layout = Array<ILayoutObject>();
    let origItems = [...allLayoutItems] ;
    let recentPropertiesFound = false;
    origItems.forEach((item) => {
        if(item.i != 'null'){
            let sectionHeight = item.h;
            if(item.i == 'properties'){
                sectionHeight = hasProperties ? 4 : 3;
            }
            if(item.i == 'portfolioChart'){
                sectionHeight = hasProperties ? 9 : 3;
            }
            if(item.i == 'recentProperties'){
                recentPropertiesFound = true;
            }
            let newItem : ILayoutObject = 
            {
                i : item.i,
                x : item.x,
                y : item.y,
                h : sectionHeight,
                w : item.w,
                isResizable :  item.isResizable,
                isDraggable : editable
            };
            layout.push(newItem);
        }
    });
    // For items that can be hidden from the start, the engine from react-grid-layout, completey removes the item from the 
    // config which in turn causes our functionality, i.e. the section appearing on the bottom left corner in reduced size,
    // so here, if the engine has removed it we add it back even though our ui logic will hide it
    if(!recentPropertiesFound){
        switch(layoutName){
            case "layoutLg": layout.push({ i: 'recentProperties', x: 4, y: 2, w: 4, h: 6, isDraggable: true, isResizable: false}); break;
            case "layoutMd": layout.push({ i: 'recentProperties', x: 6, y: 2, w: 6, h: 6, isDraggable: true, isResizable: false}); break;
            case "layoutXs": layout.push({ i: 'recentProperties', x: 0, y: 8, w: 12, h: 9, isDraggable: true, isResizable: false}); break;
        }
        
    }
    return layout;
}

export const defaultLayoutLg = (isDraggable: boolean) =>{
    return [
        // column1
        { i: 'getStarted', x: 0, y: 0, w: 4, h: 4, isDraggable: isDraggable, isResizable: false },
        { i: 'tasksSummary', x: 0, y: 1, w: 4, h: 3, isDraggable: isDraggable, isResizable: false},
        { i: 'properties', x: 0, y: 2, w: 4, h: 4, isDraggable: isDraggable, isResizable: false },
        { i: 'income', x: 0, y: 3, w: 4, h: 4, isDraggable: isDraggable, isResizable: false},
        { i: 'research', x: 0, y: 4, w: 4, h: 3, isDraggable: isDraggable, isResizable: false},
                
        // column2  
        { i: 'helpCentre', x: 4, y: 0, w: 4, h: 4, isDraggable: isDraggable, isResizable: false },
        { i: 'maintenance', x: 4, y: 1, w: 4, h: 3, isDraggable: isDraggable, isResizable: false},
        { i: 'recentProperties', x: 4, y: 2, w: 4, h: 6, isDraggable: isDraggable, isResizable: false},
        { i: 'dealCalculator', x: 4, y: 3, w: 4, h: 3, isDraggable: isDraggable, isResizable: false},
        // column3
        { i: 'portfolioChart', x: 9, y: 0, w: 4, h: 6, isDraggable: isDraggable, isResizable: false},
        { i: 'moneyChart1', x: 9, y: 1, w: 4, h: 7, isDraggable: isDraggable, isResizable: false},
    ];
}
    
export const defaultLayoutMd = (isDraggable: boolean) => {
    return [
        // column1
        { i: 'getStarted', x: 0, y: 0, w: 6, h: 4, isDraggable: isDraggable, isResizable: false },
        { i: 'tasksSummary', x: 0, y: 1, w: 6, h: 3, isDraggable: isDraggable, isResizable: false},
        { i: 'properties', x: 0, y: 2, w: 6, h: 4, isDraggable: isDraggable, isResizable: false },
        { i: 'income', x: 0, y: 3, w: 6, h: 4, isDraggable: isDraggable, isResizable: false},
        { i: 'research', x: 0, y: 4, w: 6, h: 3, isDraggable: isDraggable, isResizable: false},
        { i: 'portfolioChart', x: 0, y: 5, w: 6, h: 9, isDraggable: isDraggable, isResizable: false},
        
        // column2
        { i: 'helpCentre', x: 6, y: 0, w: 6, h: 4, isDraggable: isDraggable, isResizable: false },
        { i: 'maintenance', x: 6, y: 1, w: 6, h: 3, isDraggable: isDraggable, isResizable: false},
        { i: 'recentProperties', x: 6, y: 2, w: 6, h: 6, isDraggable: isDraggable, isResizable: false},
        { i: 'dealCalculator', x: 6, y: 3, w: 6, h: 3, isDraggable: isDraggable, isResizable: false},
        { i: 'moneyChart1', x: 6, y: 4, w: 6, h: 6, isDraggable: isDraggable, isResizable: false},
    ];
}

export const defaultLayoutXs = (isDraggable: boolean) => {
    return [
        { i: 'getStarted', x: 0, y: 0, w: 12, h: 4, isDraggable: isDraggable, isResizable: false },
        { i: 'tasksSummary', x: 0, y: 2, w: 12, h: 3, isDraggable: isDraggable, isResizable: false},
        { i: 'maintenance', x: 0, y: 3, w: 12, h: 3, isDraggable: isDraggable, isResizable: false},
        { i: 'properties', x: 0, y: 4, w: 12, h: 4, isDraggable: isDraggable, isResizable: false },
        { i: 'income', x: 0, y: 5, w: 12, h: 4, isDraggable: isDraggable, isResizable: false},
        { i: 'recentProperties', x: 0, y: 6, w: 12, h: 6, isDraggable: isDraggable, isResizable: false},
        { i: 'research', x: 0, y: 7, w: 12, h: 3, isDraggable: isDraggable, isResizable: false},
        { i: 'dealCalculator', x: 0, y: 8, w: 12, h: 3, isDraggable: isDraggable, isResizable: false},
        { i: 'portfolioChart', x: 0, y: 9, w: 12, h: 9, isDraggable: isDraggable, isResizable: false},
        { i: 'moneyChart1', x: 0, y: 10, w: 12, h: 8, isDraggable: isDraggable, isResizable: false},
        { i: 'helpCentre', x: 0, y: 11, w: 12, h: 4, isDraggable: isDraggable, isResizable: false },
    ];
}
