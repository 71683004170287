// Import React
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Sidebar from "react-sidebar";

import styles from "./IssueActionPanel.module.scss";
import { sidebarStyling } from "../../../global/variables/_variables";
import IssueActionBody from "../IssueActionBody";

interface IProps {
  isOpen: boolean;
  onSetOpen(open: boolean): void;
}

interface IRouterProps {
  history: any;
  location: any;
  match: any;
}

interface IState {
  sidebarOpen: boolean;
}

type Props = IProps & IRouterProps;

class IssueActionPanel extends Component<Props, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      sidebarOpen: false
    };
  }

  render() {
    return (
      <Sidebar
        overlayClassName={styles.overlay}
        touch={false}
        pullRight={true}
        sidebarClassName={styles.sidebar}
        sidebar={this.props.isOpen ? <IssueActionBody /> : <div></div>}
        open={this.props.isOpen}
        onSetOpen={this.props.onSetOpen}
        styles={sidebarStyling}
      >
        <div />
      </Sidebar>
    );
  }
}

export default withRouter(IssueActionPanel);