interface IWalkthroughState {
    newUser: INewUser;
    property: IProperty;
}

interface INewUser {
    hasHomepageWalkthrough: boolean;
}

interface IProperty {
    hasFirstPropertyWalkthrough: boolean;
    hasFirstPropertyListWalkthrough: boolean;
}

const initialState = {
    newUser: {
        hasHomepageWalkthrough: true
    },
    property: {
        hasFirstPropertyWalkthrough: true,
        hasFirstPropertyListWalkthrough: true
    }
};

export default (state: IWalkthroughState = initialState, action: any): IWalkthroughState => {
  switch (action.type) {

    default:
      return state;
  }
};
