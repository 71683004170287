// Import React
import React, { Component } from "react";
import ComingSoon from "../../atoms/ComingSoon";

class PropertyImportPage extends Component {
  render() {
    return (
      <div>
        <ComingSoon />

        <p>
          Our property import tool allows you to easily import your properties
          with minimal effort. Easily import your entire portfolio at the click
          of a button.
        </p>
      </div>
    );
  }
}

export default PropertyImportPage;
