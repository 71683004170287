import React, { Component } from "react";
import { withRouter, Route, Redirect } from "react-router-dom";
import { IUserProfileResponse } from "../../../../types/ProfileService/IProfile";
import { toast } from "react-toastify";
import styles from "./UsersPage.module.scss";
import UserGridTabs from "../UserGrid/components/UserGridTabs";
import UserGrid from "../UserGrid";
import { DefaultTemplate } from "../UserGrid/_templates/defaultTemplate";

interface IState {

}

interface IProps {
  history: any;
  location: any;
  match: any;
}

class UsersPage extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  notify = (message: string) => {
    toast(message);
  };

  render() {
    return (
      <div className={styles.page}>
        <UserGridTabs />

        <Route
          path={`${this.props.match.url}`}
          exact
          render={() => <Redirect to={`${this.props.match.url}/all`} />}
        />

        <Route
          path={`${this.props.match.path}/all`}
          exact
          render={() => <UserGrid
            config={{
              sortingSettings: {
                columns: ["createdDate"]
              },
              template: DefaultTemplate(this.props)
            }}
          />}
        />

        <Route
          path={`${this.props.match.path}/not-active`}
          exact
          render={() => <UserGrid
            config={{
              dataFilterSettings: {
                active: false
              },
              sortingSettings: {
                columns: ["createdDate"]
              },
              template: DefaultTemplate(this.props)
            }}
          />}
        />

      </div>
    );
  }
}

export default withRouter(UsersPage);