import React, { Component } from "react";
import RegisterForm from "../../molecules/RegisterForm";
import styles from "./WhitelabelRegisterForm.module.scss";

interface IWithRouterProps {
  history: any;
  location: any;
  match: any;
}

interface IState {
}

class WhitelabelRegisterForm extends Component<IWithRouterProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div className={styles.page}>
        <div className={styles.form}>
          <RegisterForm />
        </div>
      </div>
    );
  }
}

export default WhitelabelRegisterForm;
