import { useEffect, useState } from 'react'
import { getHtml, parseHtmlToAddButton } from '@rentchief/components';

const useStaticHtml = (htmlName, buttonId, buttonText, buttonClick) => {
    const [html, setHtml] = useState(null); 

    useEffect(() => {
        updateHtml();
      }, []);

    useEffect(() => {
        window.addEventListener("resize", updateHtml);
        return () => window.removeEventListener("resize", updateHtml);
    });

    const updateHtml = async() => {
        let html = await getHtml(htmlName);
        html = parseHtmlToAddButton(html, buttonId, buttonText, buttonClick);
        setHtml(html);
    };

    return (
        html
    )

}

export default useStaticHtml;