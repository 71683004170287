import React from "react";
import styles from "./Filter.module.scss";
import { IFilterElement } from "../../../../types/ListComponents";
import tuneIcon from "../../../../images/icons/icon_filter.svg";

interface IProps {
  allElement?: IFilterElement;
  activeElementId?: string;
  filterElements: Array<IFilterElement>;
  showFilterIcon?: boolean;
  onChangeFilter(elementId: string): void;
  toggleStatusDropdownMenu?(): void;
  smallVersion?: boolean;
}

interface IState {
  isFilter: boolean;
  status: string;
}

class RCFilter extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isFilter: false,
      status: "All",
    };
  }

  render() {
    const {
      allElement,
      activeElementId,
      filterElements,
      onChangeFilter,
      showFilterIcon,
      toggleStatusDropdownMenu,
    } = this.props;

    const toggleClass = this.props.showFilterIcon
      ? styles.toggleButton
      : styles.toggleButtonShowAlways;

    return (
      <div className={styles.filter}>
        {showFilterIcon && (
          <img
            className={styles.filterIcon}
            src={tuneIcon}
            data-tip="Filter Properties"
            onClick={toggleStatusDropdownMenu}
          />
        )}
        {allElement && (
          <button
            className={
              activeElementId == allElement.id
                ? styles.toggleButtonActive
                : toggleClass
            }
            onClick={() => onChangeFilter(allElement.id)}
          >
            {allElement.status}
          </button>
        )}
        {filterElements.map((elem, index) => {
          return (
            <button
              className={
                activeElementId == elem.id
                  ? styles.toggleButtonActive
                  : toggleClass
              }
              onClick={() => onChangeFilter(elem.id)}
              key={index}
            >
              {elem.status}
            </button>
          );
        })}
      </div>
    );
  }
}

export default RCFilter;
