import { calculateDaysBetweenDates } from "./timerHelpers";
import { store } from "../store";
import { updateViewingUnit } from "./unitHelpers";
import history from "../history";

export function showDeleteTenancyModal(tenancyIds: Array<string>): void {
  
}

export function toggleDeleteTenancyPopup(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_TENANCY_DELETE_POPUP",
    data: show,
  });
}

export function calculateDepositFromMonthlyRent(
  rentPeriod: string,
  rentAmount: number
): number {
  switch (rentPeriod) {
    case "Weekly":
      return rentAmount * 5;
    case "BiWeekly":
      let biWeekly = ((rentAmount * 26) / 52) * 5;
      return Math.round(biWeekly);
    case "Monthly":
      let amount = ((rentAmount * 12) / 52) * 5;
      return Math.round(amount);
    case "Quarterly":
      let quarterly = ((rentAmount * 4) / 52) * 5;
      return Math.round(quarterly);
    case "HalfYearly":
      let halfYearly = ((rentAmount * 2) / 52) * 5;
      return Math.round(halfYearly);
    case "Yearly":
      let yearly = (rentAmount / 52) * 5;
      return Math.round(yearly);
    default:
      return rentAmount;
  }
}

export function getTimeRemainingTenancy(endDate?: string, rollingContract?: boolean): string {
  var timeNow = new Date();

  if(rollingContract){
    return "Rolling Contract";
  }

  if (!endDate) {
    return "No Tenancy";
  }

  var end: Date = new Date(endDate);

  let daysBetween = calculateDaysBetweenDates(timeNow, end);

  var remaining = "No Tenancy";
  var years = Math.floor(daysBetween / 365);
  if (years >= 1) {
    return years + " Year" + (years > 1 ? "s" : "");
  }

  var months = Math.floor(daysBetween / 30);
  if (months >= 2) {
    return months + " Month" + (months > 1 ? "s" : "");
  }

  var days = Math.floor(daysBetween);
  if (days > 0) {
    return days + " Day" + (days > 1 ? "s" : "");
  }

  if (days == 0) {
    return "Today";
  }

  return remaining;
}

export function getTimeRemainingRentReview(endDate?: string): string {
  var timeNow = new Date();

  if (!endDate) {
    return "No Rent Review";
  }

  var end: Date = new Date(endDate);

  let daysBetween = calculateDaysBetweenDates(timeNow, end);

  var years = Math.floor(Math.abs(daysBetween / 365));
  if (years > 0) {
    return years + " Year" + (years > 1 ? "s" : "");
  }

  var months = Math.floor(Math.abs(daysBetween / 30));
  if (months > 0) {
    return months + " Month" + (months > 1 ? "s" : "");
  }

  var days = Math.floor(Math.abs(daysBetween));
  if (days > 0) {
    return days + " Day" + (days > 1 ? "s" : "");
  }

  if (days == 0) {
    return "Today";
  }

  var days = Math.abs(Math.round(daysBetween));
  return days + " Day" + (days > 1 ? "s" : "");
}

export function getTenseRentReview(endDate?: string): string {
  var timeNow = new Date();

  if (!endDate) {
    return "until rent review";
  }

  var end: Date = new Date(endDate);

  let daysBetween = calculateDaysBetweenDates(timeNow, end);

  var years = Math.floor(daysBetween / 365);
  var months = Math.floor(daysBetween / 30);
  var days = Math.floor(daysBetween);

  if (years > 0 || months > 0 || days > 0) {
    return "until rent review";
  }

  if (days == 0) {
    return "";
  }

  return "since rent review";
}

export const calculateEndDateFromDuration = (duration: number, period: string, startDate: Date): Date => {
  if (!duration || !period) {
    return;
  }

  //let startDate = this.state.tenancyItem.startDate || new Date();
  let newDate = new Date(startDate);
  switch (period) {
    case "Days": {
      newDate.setDate(newDate.getDate() + duration - 1);
      break;
    }
    case "Weeks": {
      newDate.setDate(newDate.getDate() + duration * 7 - 1);
      break;
    }
    case "Months": {
      newDate.setMonth(newDate.getMonth() + duration);
      newDate.setDate(newDate.getDate() - 1);
      break;
    }
    case "Years": {
      newDate.setFullYear(newDate.getFullYear() + duration);
      newDate.setDate(newDate.getDate() - 1);
      break;
    }
    default: {
      break;
    }
  }

  return newDate;
}

export const calculateDaysCountBetweenDates = (startDate: Date, endDate: Date): number => {
  var startDate = new Date(startDate);
  if (
    typeof startDate.getTime != "function" ||
    typeof endDate.getTime != "function"
  ) {
    return 0;
  }

  let timeDifference = endDate.getTime() - startDate.getTime();
  let dayDiffernece = timeDifference / (1000 * 3600 * 24);
  return Math.round(dayDiffernece) + 1;
}
