import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from "react-router-dom";
import semiCircleNonCompliant from "../../../../images/icons/Regulation/regulationdial_noncompliant.svg";
import semiCircleWarning from "../../../../images/icons/Regulation/regulationdial_warning.svg";
import semiCircleCompliant from "../../../../images/icons/Regulation/regulationdial_compliant.svg";
import { getPropertyDetails } from '../../../../services/PropertyDetail'
import { IPropertyDetail } from '../../../../types/PropertyDetailService'
import { IRegulation } from '../../../../reducers/propertyReducer';
import { IKeyDatesConfig } from '../_config/config';
import { dateWithoutTime } from '../../../../helpers/dateHelpers';
import { getStatusPillValues, getStatusPillValuesForCheckItem } from "../../../atoms/StatusPill/_helpers/statusHelper"
import styles from "./RegulationChartV2.module.scss";

interface props {
    suppliedPropertyId?: string;
}

const RegulationChartV2 = ({suppliedPropertyId}: props) => {

    const history = useHistory();

    const config = useRef<IKeyDatesConfig>(require("../_config/config.json"));
    const curPropertyId = useRef<string>(null);
    const compliancyLevelSet = useRef<boolean>(null);

    const [regulationData, setRegulationData] = useState<IRegulation>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [compliancyLevel, setCompliancyLevel] = useState<number>(0);

    useEffect(() => {
        getDetails();
      }, []);

    const getDetails = async () => {
        curPropertyId.current = suppliedPropertyId;

        if (!curPropertyId) {
            return
        }

        //setLoading(true);
        let resp = await getPropertyDetails(curPropertyId.current);
        if (resp && resp.data) {
            let data: IPropertyDetail = resp.data
            let response = {
                regulation: {
                    gasAppliances: data.gasAppliances,
                    epcCertificateRequired: data.epcCertificateRequired,
                    epcExpiryDate: data.epcExpiryDate && dateWithoutTime(data.epcExpiryDate),
                    gasExpiryDate: data.gasExpiryDate && dateWithoutTime(data.gasExpiryDate),
                    smokeDetectors: data.smokeDetectors,
                    smartMeters: data.smartMeters,
                    carbonMonoxide: data.carbonMonoxide,
                    hmoLicence: data.hmoLicence,
                    hmoLicenceExpires: data.hmoLicenceExpires && dateWithoutTime(data.hmoLicenceExpires),
                    legionellaAssessment: data.legionellaAssessment,
                    electricalInspectionRequired: data.electricalInspectionRequired,
                    electricalCheckInspection: data.electricalCheckInspection && dateWithoutTime(data.electricalCheckInspection),
                    landlordInsuranceExpiry: data.landlordInsuranceExpiry && dateWithoutTime(data.landlordInsuranceExpiry),
                    mortgageRateExpiry: data.mortgageRateExpiry && dateWithoutTime(data.mortgageRateExpiry),
                    selectiveLicenseExpiry: data.selectiveLicenseExpiry && dateWithoutTime(data.selectiveLicenseExpiry),
                    nextPropertyInspection: data.nextPropertyInspection && dateWithoutTime(data.nextPropertyInspection),
                    landlordInsuranceRequired: data.landlordInsuranceRequired,
                    propertyInspectioneRequired: data.propertyInspectioneRequired,
                    mortgageRateUpdateRequired: data.mortgageRateUpdateRequired,
                    selectiveLicenseRequired: data.selectiveLicenseRequired,
                    gasCertificateRequired: data.gasAppliances,
                    hmoLicenceRequired: data.hmoLicenceRequired,
                },
            }

            setRegulationData(response.regulation);

            setLoading(false);
        }
    }

    const getCompliancyLevel = (): JSX.Element => {
        var messages = ["Non Compliant", "Warning", "Compliant"];
    
        var image = [
          semiCircleNonCompliant,
          semiCircleWarning,
          semiCircleCompliant
        ];
    
        var textStyle = [styles.nonCompliant, styles.warning, styles.compliant];
    
        var allComplianceValues = [];
        if(regulationData){
            regulationData.gasAppliances && allComplianceValues.push(getStatusPillValues(regulationData.gasExpiryDate).backgroundColor);
            regulationData.electricalInspectionRequired &&  allComplianceValues.push(getStatusPillValues(regulationData.electricalCheckInspection).backgroundColor);
            regulationData.epcCertificateRequired && allComplianceValues.push(getStatusPillValues(regulationData.epcExpiryDate).backgroundColor);
            regulationData.hmoLicenceRequired && allComplianceValues.push(getStatusPillValues(regulationData.hmoLicenceExpires).backgroundColor);
            regulationData.selectiveLicenseRequired && allComplianceValues.push(getStatusPillValues(regulationData.selectiveLicenseExpiry).backgroundColor);
            allComplianceValues.push(getStatusPillValuesForCheckItem(regulationData.smokeDetectors).backgroundColor);
            allComplianceValues.push(getStatusPillValuesForCheckItem(regulationData.carbonMonoxide).backgroundColor);
            allComplianceValues.push(getStatusPillValuesForCheckItem(regulationData.legionellaAssessment).backgroundColor);
        }   
        
        if(!compliancyLevelSet.current && allComplianceValues.length > 0){
            compliancyLevelSet.current = true;
            if(allComplianceValues.includes('red')){
                setCompliancyLevel(0);
            }else if(allComplianceValues.includes('orange')){
                setCompliancyLevel(1);
            }else{
                setCompliancyLevel(2);
            }
        }
    
        return (
          <div className={styles.tile}>
            <div className={styles.chart}>
              <img src={image[compliancyLevel]} />
            </div>
            <div className={styles.column}>
              <div
                className={`${styles.compliancyText} ${
                  textStyle[compliancyLevel]
                  }`}
              >
                {messages[compliancyLevel]}
              </div>
            </div>
          </div>
        );
      }

    return(
        <>
        {getCompliancyLevel()}
        </>
    )
}

export default RegulationChartV2;