import { apiService } from '../services/ApiService';
import { createBearerToken } from '../helpers/authHelpers';
import * as domains from '../configuration/domains';
import { IUpdatePropertyCompositionRequest, IUpdatePropertyCurrentValuationRequest, IUpdateRegulationsRequest } from '../types/PropertyDetailService';


export const getPropertyDetails = (propertyId: string): Promise<any> => {
    return apiService.get(`${domains.propertyDetailService}/${propertyId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const updatePropertyComposition = (request: IUpdatePropertyCompositionRequest): Promise<any> => {
    return apiService.put(`${domains.propertyDetailService}/composition`, request,  {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const updatePropertyRegulations = (request: IUpdateRegulationsRequest): Promise<any> => {
    return apiService.put(`${domains.propertyDetailService}/regulations`, request,  {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const updatePropertyCurrentValuation = (request: IUpdatePropertyCurrentValuationRequest): Promise<any> => {
    return apiService.put(`${domains.propertyDetailService}/valuation`, request,  {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}