export const minWidthMobileLandscape = "568px";
export const minWidthTablet = "768px";
export const minWidthDesktop = "1024px";
export const minWidthFullHD = "1200px";

export const sidebarStyling = {
  sidebar: {
    zIndex: "4",
    position: "absolute",
    background: "white",
    top: "0",
    bottom: "0",
    transition: "transform .3s ease-out",
    WebkitTransition: "-webkit-transform .3s ease-out",
    overflowY: "auto"
  },
  overlay: {
    zIndex: "3",
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    opacity: "0",
    visibility: "hidden",
    transition: "opacity .3s ease-out, visibility .3s ease-out",
    backgroundColor: "rgba(0,0,0,.3)"
  }
};

export const rotateLoaderStyling = {
  sizeUnit: "px",
  size: 15,
  margin: "2px",
  color: "#123abc",
  loading: true
};
