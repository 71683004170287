import { Form, Formik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import {
    clearIssueEdit,
    getIssueEdit,
    updateViewingIssue,
} from '../../../actions/issueActions'
import { formatAddress } from '../../../helpers/formattingHelpers'
import { updateIssueListItem } from '../../../helpers/issueHelpers'
import { generateId } from '../../../helpers/randomHelpers'
import { isLandlord } from '../../../helpers/roleHelpers'
import { toggleIssuePopup } from '../../../helpers/sidebarHelpers'
import { RootState } from '../../../reducers/rootReducer'
import { getTypesForDropdown } from '../../../services/EntityService'
import { updateIssue } from '../../../services/IssueService'
import { IIssueItem } from '../../../types/IssueService'
import { IAddress } from '../../../types/PropertyService/IPropertyService'
import { QuickViewType } from '../../../types/QuickView'
import { AddIssue } from '../../../YupValidationSchemas'
import Buttons from '../Buttons'
import GenericPopup from '../GenericPopup'
import RotatingLoader from '../RotatingLoader'
import SelectDropdown, { IOptions } from '../SelectDropdown'
import styles from './EditIssue.module.scss'

export interface IRaiseIssueOptions {
    issueId?: string
}

interface IProps {
    history: any
    location: any
    match: any
    options?: IRaiseIssueOptions

    loading: boolean
    viewing_issue_edit: IIssueItem
    getIssueEdit(issueId: string): void
    clearIssueEdit(): void
    updateViewingIssue(issue: IIssueItem): void
}

type Props = IProps

interface IState {
    selectedTenantId: string
    selectedTenantName: string
    selectedPropertyId: string
    selectedPropertyName: string
    selectedCategory: string
    selectedStatus: string
    selectedPriority: string
    selectedUnitId: string
    selectedUnitName: string
    propertyAddress: IAddress

    options: IRaiseIssueOptions

    categories: Array<IOptions>
    status: Array<IOptions>
    priorites: Array<IOptions>

    title: string
    description: string

    isLoading: boolean
    isSuccess: boolean

    issueItem: IIssueItem
}

class EditIssue extends React.Component<Props, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            selectedTenantId: '',
            selectedTenantName: '',
            selectedPropertyId: '',
            selectedPropertyName: '',
            selectedPriority: '',
            options: null,
            categories: [],
            status: [],
            title: '',
            description: '',
            selectedCategory: '',
            selectedStatus: '',
            selectedUnitId: '',
            selectedUnitName: '',
            propertyAddress: null,
            priorites: [],

            isLoading: false,
            isSuccess: false,

            issueItem: null,
        }

        this.onClose = this.onClose.bind(this)
        this.onSelectTenant = this.onSelectTenant.bind(this)
        this.onSelectProperty = this.onSelectProperty.bind(this)
        this.getEntities = this.getEntities.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.getOptions = this.getOptions.bind(this)
        this.onClearContact = this.onClearContact.bind(this)
        this.onClearProperty = this.onClearProperty.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.useOptions = this.useOptions.bind(this)
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
    }

    onClearProperty(): void {
        this.setState({
            selectedPropertyId: '',
            selectedPropertyName: '',
        })
    }

    onClearContact(): void {
        this.setState({
            selectedTenantId: '',
            selectedTenantName: '',
        })
    }

    getEntities(): void {
        // Categories
        getTypesForDropdown('821F084B-E9BE-4F42-B331-0327C1D8D419').then(
            (resp) => {
                if (resp && resp.status && resp.data) {
                    this.setState({
                        categories: resp.data,
                    })
                }
            }
        )

        // Status
        getTypesForDropdown('8C31AE99-426D-4BFD-9C92-80297591DBA3').then(
            (resp) => {
                if (resp && resp.status && resp.data) {
                    this.setState({
                        status: resp.data,
                    })
                }
            }
        )

        // Priority
        getTypesForDropdown('23CD92B3-D51A-4EF9-A6E5-FC76ECBA326F').then(
            (resp) => {
                if (resp && resp.status && resp.data) {
                    this.setState({
                        priorites: resp.data,
                    })
                }
            }
        )
    }

    useOptions(): void {
        if (!this.state.options) {
            return
        }
    }

    onClose(): void {}

    componentWillReceiveProps(newProps: IProps): void {
        let data: IIssueItem = newProps.viewing_issue_edit
        this.setState({
            issueItem: data,
            selectedCategory: data.category,
            selectedStatus: data.statusId,
            title: data.title,
            description: data.description,
            selectedPropertyId: data.propertyId,
            selectedPropertyName: data.propertyName,
            selectedTenantId: data.contactId,
            selectedPriority: data.priorityId,
            selectedUnitId: data.unitId,
            selectedUnitName: data.unitName,
            selectedTenantName: data.contactName,
            propertyAddress: data.address,
        })
    }

    getOptions(): void {
        if (!this.props.viewing_issue_edit) {
            this.props.getIssueEdit(this.props.match.params.issueId)
        }

        let data: IIssueItem = this.props.viewing_issue_edit
        if(data){
            this.setState({
                issueItem: data,
                selectedCategory: data.category,
                selectedStatus: data.statusId,
                title: data.title,
                description: data.description,
                selectedPropertyId: data.propertyId,
                selectedPropertyName: data.propertyName,
                selectedTenantId: data.contactId,
                selectedPriority: data.priorityId,
                selectedUnitId: data.unitId,
                selectedUnitName: data.unitName,
                selectedTenantName: data.contactName,
                propertyAddress: data.address,
            })
        }
    }

    componentDidMount(): void {
        this.getEntities()
        this.getOptions()
    }

    onSelectTenant(selected: IOptions): void {
        this.setState({
            selectedTenantName: selected.label,
            selectedTenantId: selected.value,
        })
    }

    onSelectProperty(selected: IOptions): void {
        this.setState({
            selectedPropertyId: selected.value,
            selectedPropertyName: selected.label,
        })
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const field = event.currentTarget.name
        const value: string = event.currentTarget.value
        this.setState({ [field]: value } as Pick<IState, any>)
    }

    onSubmit(): void {
        this.setState({
            isLoading: true,
        })

        if (this.props.viewing_issue_edit) {
            updateIssue(
                {
                    status: this.state.selectedStatus,
                    priority: this.state.selectedPriority,
                },
                this.props.viewing_issue_edit.issueId
            ).then((resp) => {
                if (resp && resp.status == 200) {
                    this.setState(
                        {
                            isLoading: false,
                            isSuccess: true,
                        },
                        () => {
                            let issue: IIssueItem = resp.data
                            updateIssueListItem(issue)
                            this.props.updateViewingIssue(issue)
                            setTimeout(() => {
                                toggleIssuePopup(false)
                            }, 2000)
                        }
                    )
                }
            })
            return
        }
    }

    handleDescriptionChange(event: React.FormEvent<HTMLInputElement>) {
        const value: string = event.currentTarget.value
        this.setState({ description: value } as Pick<IState, any>)
    }

    onCancel(): void {
        this.props.history.goBack()
    }

    render() {
        if (!this.props.viewing_issue_edit || this.props.loading) {
            return null
        }

        return (
            <div className={styles.modalBody}>
                <Formik
                    initialValues={{
                        status: this.state.selectedStatus,
                        category: this.state.selectedCategory,
                        title: this.state.title,
                        description: this.state.description,
                    }}
                    enableReinitialize={true}
                    validationSchema={AddIssue}
                    onSubmit={(values: any) => {
                        this.setState(
                            {
                                title: values.title,
                                description: values.description,
                            },
                            () => this.onSubmit()
                        )
                    }}
                >
                    {this.state.isLoading ? (
                        <div>
                            <RotatingLoader loading={true} text="Saving..." />
                        </div>
                    ) : (
                        <Form>
                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <span className={styles.heading}>
                                        Issue -{' '}
                                        {this.state.issueItem &&
                                            this.state.issueItem.title}
                                    </span>
                                </div>
                            </div>

                            <div className={styles.row}>
                                {this.state.propertyAddress ? (
                                    <div className={styles.column}>
                                        <span>
                                            {formatAddress(
                                                this.state.propertyAddress
                                            )}{' '}
                                            <GenericPopup
                                                identifier={generateId(10)}
                                                options={{
                                                    type: QuickViewType.Property,
                                                    propertyId:
                                                        this.state.issueItem &&
                                                        this.state.issueItem
                                                            .propertyId,
                                                }}
                                            />
                                        </span>
                                    </div>
                                ) : (
                                    <div className={styles.column}>
                                        <span>
                                            This property has no address added
                                        </span>
                                    </div>
                                )}
                            </div>

                            {this.state.issueItem &&
                                this.state.issueItem.unitId &&
                                this.state.issueItem.unitName && (
                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            <span>
                                                {this.state.issueItem.unitName}
                                            </span>
                                        </div>
                                    </div>
                                )}

                            <div className={styles.row}>
                                {this.state.issueItem &&
                                this.state.issueItem.contactId &&
                                this.state.issueItem.contactName ? (
                                    <div className={styles.column}>
                                        <span>
                                            The contact at the property is{' '}
                                            {this.state.issueItem &&
                                                this.state.issueItem
                                                    .contactName}{' '}
                                            <GenericPopup
                                                identifier={generateId(10)}
                                                options={{
                                                    type: QuickViewType.Contact,
                                                    contactId:
                                                        this.state.issueItem &&
                                                        this.state.issueItem
                                                            .contactId,
                                                }}
                                            />
                                        </span>
                                    </div>
                                ) : (
                                    <div className={styles.column}>
                                        <span>
                                            There is no contact linked at this
                                            property for this issue
                                        </span>
                                    </div>
                                )}
                            </div>

                            {isLandlord() && (
                                <div className={styles.row}>
                                    <div className={styles.columnSmall}>
                                        <div className={styles.label}>
                                            Status
                                        </div>
                                        <SelectDropdown
                                            selectedId={
                                                this.state.selectedStatus
                                            }
                                            showDefaultOption
                                            data={this.state.status}
                                            placeholder="Status..."
                                            onSelect={(id: IOptions) =>
                                                this.setState({
                                                    selectedStatus: id.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className={styles.columnSmall}>
                                        <div className={styles.label}>
                                            Priority
                                        </div>
                                        <SelectDropdown
                                            selectedId={
                                                this.state.selectedPriority
                                            }
                                            showDefaultOption
                                            data={this.state.priorites}
                                            placeholder="Priority..."
                                            onSelect={(id: IOptions) =>
                                                this.setState({
                                                    selectedPriority: id.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className={styles.columnSmall}>
                                        <Buttons
                                            align={'left'}
                                            buttons={[
                                                {
                                                    displayType: 'submit',
                                                    elementType: 'button',
                                                    onClick: () =>
                                                        this.onSubmit(),
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    viewing_issue_edit: state.issue.viewing_issue_edit,
    loading: state.issue.loading,
    error: state.issue.error,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            clearIssueEdit,
            getIssueEdit,
            updateViewingIssue,
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EditIssue))
