import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// Import Styles
import styles from "./ContactIssues.module.scss";
import { IRaiseIssueOptions } from "../../../../atoms/AddEditIssue";
import { addURLParameter } from "../../../../../helpers/locationHelpers";
import IssueListPage from "../../../IssueListPage";

interface IWithRouterProps {
  history: any;
  location: any;
  match: any;
}

interface IProps {
  contactId: string;
  contactName: string;
}

type Props = IProps & IWithRouterProps;

interface IState {

}

class ContactIssues extends Component<Props, IState> {
  constructor(props: Props) {
    super(props);

    this.state = {

    };

    this.onAddButtonClick = this.onAddButtonClick.bind(this);
  }

  componentDidMount(): void {

  }

  onAddButtonClick(): void {
    let options: IRaiseIssueOptions = {
      contactId: this.props.contactId,
      contactName: this.props.contactName,
    }

    let query = "issueOptions=" + JSON.stringify(options);
    let queryParam = addURLParameter(this.props.location.search, query);

    this.props.history.push({
      search: queryParam
    });
  }

  render() {
    return (
      <div className={styles.page}>
        <IssueListPage onAddIssue={this.onAddButtonClick} contactId={this.props.match.params.contactId} isNested={true} />
      </div>
    );
  }
}

export default withRouter(ContactIssues);