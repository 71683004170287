import React, { useEffect, useState } from "react";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import styles from "./DateRangePicker.module.scss";
import {
  DateRangePickerComponent,
  PresetsDirective,
  PresetDirective,
  RangeEventArgs,
} from "@syncfusion/ej2-react-calendars";

import {
  minDate,
  maxDate,
  weekStart,
  weekEnd,
  monthStart,
  monthEnd,
  yearStart,
  financialYearStart,
  lastMonthStart,
  lastMonthEnd,
  lastFinancialYearStart,
  lastFinancialYearEnd,
  yearEnd,
  financialYearEnd,
  today,
  nextWeekStart,
  nextWeekEnd,
  nextMonthStart,
  nextMonthEnd,
} from "../../../global/variables/_dates";

interface IProps {
  change(startDate, endDate): void;
  cleared(): void;
  focus(): void;
  startDate?: Date;
  endDate?: Date;
  ref?: any;
}

type Props = IProps;

const DateRangePicker = (props: Props) => {
  // Redux 
  //const { filters } = useSelector((state: RootState) => state.filters);

  const [startDate, setStartDate] = useState<Date>(props.startDate);
  const [endDate, setEndDate] = useState<Date>(props.endDate);

  var datePickerRef;

  useEffect(() => {
    datePickerRef = React.createRef();

    /* if (filters.financials.dateRangeStart) {
      setStartDate(filters.financials.dateRangeStart);
    }
    if (filters.financials.dateRangeEnd) {
      setEndDate(filters.financials.dateRangeEnd);
    } */
  }, [])

  useEffect(() => {
    setStartDate(props.startDate);
    setEndDate(props.endDate);
  }, [props.startDate, props.endDate])

  /* useEffect(() => {
    let financials = filters.financials;
    if (financials && financials.dateRangeStart && financials.dateRangeEnd) {
      if (financials.dateRangeStart) {
        setStartDate(financials.dateRangeStart);
      }
      if (financials.dateRangeEnd) {
        setEndDate(financials.dateRangeEnd);
      }
    }
    setStartDate(props.startDate);
    setEndDate(props.endDate);
  }, [filters.financials]) */

  const focusDateRange = () => {
    if (datePickerRef && datePickerRef.current) {
      datePickerRef.current.show();
    }
  }

    return (
      <div className={styles.dateRangePicker}>
        <DateRangePickerComponent
          placeholder="Select a range"
          min={minDate}
          max={maxDate}
          allowEdit={true}
          startDate={startDate}
          endDate={endDate}
          format={"dd-MM-yyyy"}
          change={(event: RangeEventArgs) => props.change(event.startDate, event.endDate)}
          cleared={props.cleared}
          //focus={() => console.log(datePickerRef.current.show())}
          ref={datePickerRef}
        >
          <PresetsDirective>
            <PresetDirective
              label="This Month"
              start={monthStart}
              end={monthEnd}
            />
            <PresetDirective
              label="Last Month"
              start={lastMonthStart}
              end={lastMonthEnd}
            />
            <PresetDirective
              label="Next month"
              start={nextMonthStart}
              end={nextMonthEnd}
            />
            <PresetDirective
              label="This Year"
              start={yearStart}
              end={yearEnd}
            />
            <PresetDirective
              label="Year to date"
              start={yearStart}
              end={today}
            />
            <PresetDirective
              label="This Financial Year"
              start={financialYearStart}
              end={financialYearEnd}
            />
            <PresetDirective
              label="Last Financial Year"
              start={lastFinancialYearStart}
              end={lastFinancialYearEnd}
            />
            <PresetDirective
              label="This Week"
              start={weekStart}
              end={weekEnd}
            />
            <PresetDirective
              label="Next week"
              start={nextWeekStart}
              end={nextWeekEnd}
            />
          </PresetsDirective>
        </DateRangePickerComponent>
      </div>
    );
}

export default DateRangePicker;