import { apiService } from './ApiService';
import * as domains from '../configuration/domains';
import { createBearerToken } from '../helpers/authHelpers';

import { IAssetsListItemOptions, IAssetDto, IAssetAddEdit } from '../types/Assets';


export interface IAssetType {
    label: string;
    value: string;
}

export interface IAddAssetRequest {

}

export const getProductStatusTypesFromApi = (): Promise<any> => {
    return apiService.get(`${domains.assetsService}/productStatusTypes`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getQuantityAndValueConditionTypesFromApi = (): Promise<any> => {
    return apiService.get(`${domains.assetsService}/quantityAndValueConditionTypes`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getServiceScheduleTypesApi = (): Promise<any> => {
    return apiService.get(`${domains.assetsService}/serviceScheduleTypes`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getAssetTypesApi = (): Promise<any> => {
    return apiService.get(`${domains.assetsService}/assetTypes`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getAssetApi = (assetId: number): Promise<any> => {
    return apiService.get(`${domains.assetsService}/${assetId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getAssetsApi = (
    options: IAssetsListItemOptions
): Promise<any> => {
    var stringed = toQueryString(options);
    return apiService.get(
        `${domains.assetsService}/assets?${stringed}`,
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Authorization: createBearerToken()
            }
        }
    );
};

export const addAssetApi = (request: IAssetDto): Promise<any> => {
    return apiService.post(`${domains.assetsService}`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const updateAssetApi = (request: IAssetDto): Promise<any> => {
    return apiService.put(`${domains.assetsService}`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

const toQueryStringArray = (arr: number[]): string => {
    if (!arr || arr.length === 0) {
        return "";
    }

    let query = "";

    arr.map((a) => {
        query += "&assetIds=" + a;
    });

    return query;
};

export const deleteAssetsApi = (assetIds: number[], request: { deleteAssociatedDocument: boolean }): Promise<any> => {
    var assetsStringed = toQueryStringArray(assetIds);
    var options = toQueryString(request);
    return apiService
        .delete(
            `${domains.assetsService}/ids/assetIds?${assetsStringed}&${options}`,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    Authorization: createBearerToken(),
                },
            }
        )
};

// A copy of this sits in the DocumentService, rather move this to a proper shared file
function toQueryString(obj) {
    var parts = [];
    for (var i in obj) {
        if (obj.hasOwnProperty(i)) {
            parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]));
        }
    }
    return parts.join("&");
}