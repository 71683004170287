import { setCurrencyCode } from '@syncfusion/ej2-base'
import {
    Aggregate,
    AggregateColumnDirective,
    AggregateColumnsDirective,
    AggregateDirective,
    AggregatesDirective,
    BeforeDataBoundArgs,
    Column,
    ColumnMenu,
    CommandColumn,
    ContextMenu,
    dataStateChange,
    DataStateChangeEventArgs,
    ExcelExport,
    ExcelQueryCellInfoEventArgs,
    Filter,
    Grid,
    GridComponent,
    Group,
    GroupSettingsModel,
    Inject,
    Page,
    PdfExport,
    QueryCellInfoEventArgs,
    RecordClickEventArgs,
    Reorder,
    Resize,
    Sort,
} from '@syncfusion/ej2-react-grids'
import {
    ChangedEventArgs,
    TextBoxComponent,
} from '@syncfusion/ej2-react-inputs'
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { connect, useSelector } from 'react-redux'
import { Router, useHistory, useLocation, useParams, withRouter } from 'react-router-dom'
import { elevioFinancialsArticleId } from '../../../../configuration/elevioConfig'
import {
    filterOptions,
    pageSettings,
} from '../../../../configuration/grid_syncfusion_options'
import {
    addURLParameter,
    removeURLParameter,
} from '../../../../helpers/locationHelpers'
import {
    toggleFilterSidebar,
    toggleTransactionPopup,
} from '../../../../helpers/sidebarHelpers'
import { updateToastMessage } from '../../../../helpers/toastHelper'
import { updateTransactionItem } from '../../../../helpers/transactionHelpers'
import filterIcon from '../../../../images/icons/icon_filter.svg'
import { IFilterState } from '../../../../reducers/filterReducer'
import { markTransactionAsPaid } from '../../../../services/TransactionService'
import { ExportTypeEnum, ITransactionItem } from '../../../../types/Transaction'
import { IAddTransactionReferer } from '../../../atoms/AddEditTransaction'
import { IAddEditTransactionOptions } from '../../../atoms/AddEditTransactionModal'
import IconButton from '../../../atoms/Buttons/IconButton'
import Currency from '../../../atoms/Currency'
import DateRangePicker from '../../../atoms/DateRangePicker'
import ElevioArticleEmbedWrapper from '../../../atoms/ElevioArticleEmbedWrapper'
import SelectDropdown, { IOptions } from '../../../atoms/SelectDropdown'
import SFDropdown from '../../../atoms/SFDropdown'
import TransactionStatusPill from '../../../atoms/TransactionListItem/TransactionStatusPill'
import UserContactInfo, {
    UserContactInfoType,
} from '../../../atoms/UserContactInfo'
import MoneySummary from '../Components/PaymentSummaryComponent/MoneySummary/MoneySummary.component'
import MoneyIncomeExpenseChart from '../Components/ProfitLossSummary/MoneyIncomeExpenseChart/MoneyIncomeExpenseChart.component'
import AddTransactions from '../AddTransactions'
import TransactionsTabs from '../TransactionsTabs'
import { editSettings, textWrapSettings } from './_config/columnOptions'
import { generateOdataSource, overdueStatusId } from './_config/dataConfig'
import { selectionSettings } from './_config/selectionConfig'
import {
    markAsPaidToast,
    onClickDuplicate,
    onClickPaidMultiple,
    onDeleteRows,
    onEditRow,
} from './_helpers'
import ToggleButton from '../../../atoms/Buttons/ToggleButton/ToggleButton.component'
import { ITransactionsGridOptions } from './_types'
import moment from 'moment-timezone'
import { formatCurrency } from '@rentchief/components'
import { Checkbox } from '@material-ui/core'
import { monthEnd, monthStart, nextMonthEnd, nextMonthStart } from '../../../../global/variables/_dates'
import { BeatLoader } from 'react-spinners'
import { RootState } from '../../../../store'
import { DataManager, ODataV4Adaptor, Query, ReturnOption } from '@syncfusion/ej2-data';
import { IListItemOptions, ITransactionListOptions } from '../../../../types/ListFilters';
import { transactionServiceOdataFriendly } from '../../../../configuration/domains';
import { createBearerToken } from '../../../../helpers/authHelpers';
import { toQueryString } from '../../../../services/DocumentService';
import { getDateWithoutOffset } from '../../../../helpers/timerHelpers';
import { CustomODataV4Adapter } from './_adapters';
import { IFilter } from '../../../../types/FilterService/IFilterService'
import { updateFilters } from '../../../../helpers/filterHelper'
import ColumnsTemplate from "./_templates/columnsTemplate";
import * as S from './TransactionsGrid.styles'
import styles from './TransactionsGrid.module.scss'

setCurrencyCode('GBP')

interface IDateRange {
    startDate: Date,
    endDate: Date
}

interface IProps {
    config: ITransactionsGridOptions
    dateRangeStart?: Date
    dateRangeEnd?: Date
    context?: string;
    tab?: string;
}

const TransactionsGrid = ({config, dateRangeStart, dateRangeEnd, context, tab}: IProps) => {
    const history = useHistory();
    const location = useLocation();
    const params = useParams<any>();

    // Redux 
    const { filters } = useSelector((state: RootState) => state.filters);

    const [transactionType, setTransactionType] = useState<string>('');
    const [search, setSearch] = useState<string>('');
    const [hasData, setHasData] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<IDateRange>({startDate: dateRangeStart, endDate: dateRangeEnd});
    const [showPMColumns, setShowPMColumns] = useState<boolean>(tab == 'PM');
    const [monthReset, setMonthReset] = useState(false);
    const [propertyReset, setPropertyReset] = useState(false);
    const [categoryReset, setCategoryReset] = useState(false);
    const [inAdvancedMode, setInAdvancedMode] = useState(false);
    const [transactionStatus, setTransactionStatus] = useState(null);
    const [groupingInProgress, setGroupingInProgress] = useState<boolean>(false);
    const [transactionsData, setTransactionsData] = useState(null);

    var dataChangeCount = useRef<number>(0);
    const grid = useRef<Grid>(null);
    const datePickerRef = useRef(null);
    const manualDateChange = useRef<boolean>(true);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if(!queryParams.has('filter') && (config && config.dataFilterSettings && config.dataFilterSettings.filtersLoaded)){
            console.debug(`MohTransactionsGrid - useEfeect - status: ${config.dataFilterSettings.status}`)
            setTransactionStatus(config.dataFilterSettings.status);
            setTransactionType(config.dataFilterSettings.transactionType)
            getTransactionsData(config.dataFilterSettings.transactionType, null, config.dataFilterSettings.status, null);
            manualDateChange.current = false;
        }
    }, [config && config.dataFilterSettings])

    useEffect(() => {
        if(filters.financials.transactionType != transactionType){
            console.debug('MohTransactionsGrid - useEfeect - filters.financials.transactionType')
            setTransactionType(filters.financials.transactionType);
    
            getTransactionsData(filters.financials.transactionType, null, null, null);
            manualDateChange.current = false;
        }
    }, [filters.financials.transactionType])

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if(!queryParams.has('filter')){
            if(filters.financials.setBySlideOut && (filters.financials.dateRangeStart != dateRange.startDate && filters.financials.dateRangeEnd != dateRange.endDate)){
                console.debug('MohTransactionsGrid - useEfeect - filters.financials.dateRangeStart')
                setDateRange({startDate: filters.financials.dateRangeStart, endDate: filters.financials.dateRangeEnd});
                getTransactionsData(transactionType, {startDate: filters.financials.dateRangeStart, endDate: filters.financials.dateRangeEnd}, null, null);
                manualDateChange.current = false;
                let updatedFilters: IFilter = {
                    financials: {
                      transactionType: transactionType,
                      dateRangeStart: dateRange.startDate,
                      dateRangeEnd: dateRange.endDate,
                      setBySlideOut: false
                    }
                  
                  }
                  updateFilters(updatedFilters);
            }
        }
    }, [filters.financials.dateRangeStart, filters.financials.dateRangeEnd])

    useEffect(() => {
        console.debug('MohTransactionsGrid - useEfeect - location.search')
        const queryParams = new URLSearchParams(location.search);
        if(queryParams.has('refreshTransactionGrid')){
            dataChangeCount.current++;

            queryParams.delete('refreshTransactionGrid');
            history.replace({ search: queryParams.toString() });
            getTransactionsData(transactionType, null, null, null);
        }

        if(queryParams.has('filter')){
            let val = queryParams.get('filter');
            if(val == 'income_Overdue'){
                setTransactionStatus(overdueStatusId);
                setTransactionType('income');
                //getTransactionsData('income', null, overdueStatusId, null);
            }else if(val == 'income_ThisMonth'){
                setTransactionStatus(null);
                setTransactionType('income');
                setDateRange({ startDate: monthStart, endDate: monthEnd});
                //getTransactionsData('income', { startDate: monthStart, endDate: monthEnd}, null, null);
            }else if(val == 'income_NextMonth'){
                setTransactionStatus(null);
                setTransactionType('income');
                setDateRange({ startDate: nextMonthStart, endDate: nextMonthEnd});
                //getTransactionsData('income', { startDate: nextMonthStart, endDate: nextMonthEnd}, null, null);
            }

            queryParams.delete('filter');
            history.replace({ search: queryParams.toString() });
        }
        manualDateChange.current = false;
    }, [location.search]);

    useEffect(()=> {
        console.debug('MohTransactionsGrid - useEfeect - grid.current')
        if(tab == 'HMRC'){
            grid.current && grid.current.groupColumn('hmrcReporting');
            grid.current && grid.current.groupColumn('categoryName');
        }else{
            grid.current && grid.current.groupColumn('invoiceMonthYear');
        }

        manualDateChange.current = false;
    }, [grid.current]);

    const externalSearchHandler = (searchValue: string): void => {
        if(searchValue != search){
            setSearch(searchValue);
            getTransactionsData(transactionType, null, null, searchValue);
        }
    }

    const customCell = (args: QueryCellInfoEventArgs) => {
        if ((args.column as Column).field === 'contactName') {
            let data = args.data as any
            if (data.contactId && data.contactId != null) {
                ReactDOM.render(
                    <Router history={history}>
                        <UserContactInfo
                            id={data.contactId}
                            type={UserContactInfoType.Contact}
                        />
                    </Router>,
                    args.cell as Element
                )
            } else {
                ReactDOM.render(<div> </div>, args.cell as Element)
            }
        }

        if ((args.column as Column).field === 'debitContactName') {
            let data = args.data as any
            if (data.contactId && data.debitContactId != null) {
                ReactDOM.render(
                    <Router history={history}>
                        <UserContactInfo
                            id={data.debitContactId}
                            type={UserContactInfoType.Contact}
                        />
                    </Router>,
                    args.cell as Element
                )
            } else {
                ReactDOM.render(<div> </div>, args.cell as Element)
            }
        }

        if ((args.column as Column).field === 'creditContactName') {
            let data = args.data as any
            if (data.contactId && data.creditContactId != null) {
                ReactDOM.render(
                    <Router history={history}>
                        <UserContactInfo
                            id={data.creditContactId}
                            type={UserContactInfoType.Contact}
                        />
                    </Router>,
                    args.cell as Element
                )
            } else {
                ReactDOM.render(<div> </div>, args.cell as Element)
            }
        }

        if ((args.column as Column).field === 'action') {
            let data = args.data as any
            ReactDOM.render(
                <SFDropdown
                    loadedBeforeRender
                    customCssClassName={styles.syncfusionDropdown}
                    onSelect={(args) => {
                        if (args.item.text === 'Edit') {
                            onEditRow(data, location, history)
                        }
                        if (args.item.text === 'Duplicate') {
                            onClickDuplicate(data.transactionId)

                            let refreshGrid = addURLParameter(location.search, `refreshTransactionGrid=true`);
                            history.push({search: refreshGrid,});
                        }
                        if (args.item.text === 'Delete') {
                            onDeleteRows([data.transactionId], location, history);
                        }
                        if (args.item.text === 'Mark as Paid') {
                            onClickPaid(data.transactionId);
                        }
                        if (args.item.text === 'Linked Property') {
                            history.push(`/dashboard/property/${data.propertyId}/summary`);
                        }
                        if (args.item.text === 'Linked Contact') {
                            history.push(`/dashboard/contact/${data.contactId}/summary`);
                        }
                    }}
                    items={[
                        {
                            iconCss: styles.subIconEdit,
                            text: 'Edit',
                        },
                        {
                            iconCss: styles.subIconDuplicate,
                            text: 'Duplicate',
                        },
                        {
                            iconCss: styles.subIconDelete,
                            text: 'Delete',
                        },
                        ...(!data.isPaid
                            ? [
                                  {
                                      iconCss: styles.subIconMarkAsPaid,
                                      text: 'Mark as Paid',
                                  },
                              ]
                            : []),
                        ...(data.propertyId
                            ? [
                                  {
                                      iconCss: styles.subIconProperty,
                                      text: 'Linked Property',
                                      url: `/dashboard/property/${data.propertyId}/summary`,
                                  },
                              ]
                            : []),
                        ...(data.contactId
                            ? [
                                  {
                                      iconCss: styles.subIconContact,
                                      text: 'Linked Contact',
                                      url: `/dashboard/contact/${data.contactId}/summary`,
                                  },
                              ]
                            : []),
                    ]}
                />,
                args.cell as Element
            )
        }
    }

    const contextMenuClickedPaid = () => {
        if (!grid || !grid.current) {
            return
        }

        const { current } = grid

        let rows = current.getSelectedRecords()

        if (rows.length == 0) {
            updateToastMessage('No rows have been selected.', 'warning')
            return
        }

        let transactionIds: string[] = []
        for (let i = 0; i < rows.length; i++) {
            transactionIds.push((rows[i] as any).transactionId)
        }

        onClickPaidMultiple(transactionIds)
    }

    const contextMenuClickedDelete = () =>{
        if (!grid || !grid.current) {
            return
        }

        let rows = grid.current.getSelectedRecords()

        if (rows.length == 0) {
            updateToastMessage('No rows have been selected.', 'warning')
            return
        }

        let transactionIds = []
        for (let i = 0; i < rows.length; i++) {
            transactionIds.push((rows[i] as any).transactionId)
        }

        onDeleteRows(transactionIds, location, history)
    }

    const onClickPaid = (transactionId: string): void => {
        markTransactionAsPaid(transactionId).then((resp) => {
          if (resp && resp.status == 200 && resp.data) {
            updateTransactionItem(resp.data);
            markAsPaidToast(1);

            let refreshGrid = addURLParameter(search, `refreshTransactionGrid=true`);
            history.push({search: refreshGrid});
          }
        });
      }

    const onDateRangeChange = (startDate, endDate): void => {
        if(manualDateChange.current){
            setDateRange({startDate: startDate, endDate: endDate});
            getTransactionsData(transactionType, {startDate: startDate, endDate: endDate}, null, null);
        }
    }

    const onDateRangeCleared = (): void => {
        manualDateChange.current = false;
        setDateRange({startDate: null, endDate: null});
        getTransactionsData(transactionType, {startDate: null, endDate: null}, null, null);
    }

    const onAdd = (isExpense: boolean, isIncome: boolean): void => {
        let options: IAddEditTransactionOptions = {
            referer: IAddTransactionReferer.Transaction,
        }

        if (config.addSettings) {
            options = config.addSettings
        }

        options.isExpense = isExpense
        options.isIncome = isIncome

        let query = addURLParameter(location.search, 'addEditTransactionOptions=' + JSON.stringify(options));
        history.push({search: query});

        toggleTransactionPopup(true)
    }

    const ResetFilterAndGrouping = () => {
        grid.current.clearGrouping();
        setMonthReset(true);;
        setPropertyReset(true);
        setCategoryReset(true); 
        setSearch('');
        setTransactionStatus(null);
        getTransactionsData(config.dataFilterSettings.transactionType, null, '', '');
    }

    const toggleButtonOnClick = (onStatus: boolean, columnName: string) => {
        setGroupingInProgress(true);
        switch(columnName){
            case 'invoiceMonthYear': setMonthReset(!onStatus); break;
            case 'propertyName': setPropertyReset(!onStatus); break;
            case 'categoryName': setCategoryReset(!onStatus); break; 
        }
        if(onStatus){
            grid.current.groupColumn(columnName);
        }else{
            grid.current.ungroupColumn(columnName);
        }
    }

    const groupTemplate = (args) => {
        let total = 0;
        let totalPaid = 0;
        let field = '';
        if(!args.items.records){
            switch(args.field){
                case 'invoiceMonthYear': field = moment(args.items[0].invoiceMonthYear).format('MMM yyyy'); break;
                default: field = args.items[0][args.field]; break;
            }
            args.items.forEach(i => {total += i.amount; totalPaid += i.paidAmount});
        }else{ // multiple groupings
            switch(args.field){
                case 'invoiceMonthYear': field = moment(args.items.records[0].invoiceMonthYear).format('MMM yyyy'); break;
                default: field = args.items.records[0][args.field]; break;
            }
            args.items.records.forEach(i => {total += i.amount; totalPaid += i.paidAmount});
        }
        
        return (
            <div>
                {field}: {formatCurrency(total, false)} 
                {
                    tab != 'PL' && tab != 'HMRC' &&
                    <span> (Paid: {formatCurrency(totalPaid, false)})</span>
                }
            </div>
        )
    }

    const onRowClick = (e: RecordClickEventArgs) => {
        // This filters out clicks on any of the other items for e.g. the checkbox and action menu
        if (e.target && e.target.classList.contains('e-rowcell') && e.cellIndex != 0 && e.cellIndex != 1) {
            onEditRow(e.rowData as ITransactionItem, location, history);
            
        }
    }

    const ShowHideAdvancedColumns = () => {
        const advancedColumns = ['Inv Month', 'Status', 'Category', 'DContact', 'CContact', 'HMRC', 'Property', 'Unit', 'Due Date', 'Unit', 'Paid Date', 'Type', 'Added', 'Reference', 'Notes', 'Contact']

        !inAdvancedMode ? grid.current.showColumns(advancedColumns) : grid.current.hideColumns(advancedColumns);
        setInAdvancedMode(!inAdvancedMode); 
    } 

    const moneySummaryCardOnClick = (name: string) => {
        if(name == 'Overdue'){
            setTransactionStatus(overdueStatusId);
            setDateRange({ startDate: null, endDate: null});
            getTransactionsData(config.dataFilterSettings.transactionType, { startDate: null, endDate: null}, overdueStatusId, null);
        }else if(name == 'This Month'){
            setTransactionStatus(null);
            setDateRange({ startDate: monthStart, endDate: monthEnd});
            getTransactionsData(config.dataFilterSettings.transactionType, { startDate: monthStart, endDate: monthEnd}, '', null);
        }else if(name == 'Next Month'){
            setTransactionStatus(null);
            setDateRange({ startDate: nextMonthStart, endDate: nextMonthEnd});
            getTransactionsData(config.dataFilterSettings.transactionType, { startDate: nextMonthStart, endDate: nextMonthEnd}, '', null);
        }
        manualDateChange.current = false;
    }

    const generateOdataSourceLocal = (listItemOptions?: IListItemOptions, transactionOptions?: ITransactionListOptions, startDate?: Date, endDate?: Date) => {
        let stringed = toQueryString(listItemOptions);
        let stringed2 = toQueryString(transactionOptions);
        let dateFilter = ``;
    
        console.debug(`MohTransactionsGrid - generateOdataSourceLocal - listItemOptions: ${stringed}`)
        console.debug(`MohTransactionsGrid - generateOdataSourceLocal - transactionOptions: ${stringed2}`)
        console.debug(`MohTransactionsGrid - generateOdataSourceLocal - StartDate: ${startDate} EndDate: ${endDate}`)
    
        if (startDate && endDate) {
            let startDate1 = getDateWithoutOffset(startDate).toISOString();
            let endDate1 = getDateWithoutOffset(endDate).toISOString();
            dateFilter += `&startDate=${startDate1}&endDate=${endDate1}`
        }
    
        grid.current && grid.current.showSpinner();
        new DataManager({
            adaptor: new CustomODataV4Adapter,
            url: `${transactionServiceOdataFriendly}?${stringed}&${stringed2}${dateFilter}`,
            headers: [
                { "Authorization": createBearerToken() }
            ],
            
        }).executeQuery(new Query()).then((e: ReturnOption) => {
            console.debug(`MohTransactionsGrid - generateOdataSourceLocal - data retrived`)
            grid.current && grid.current.hideSpinner();
            setTransactionsData(e.result);
            manualDateChange.current = true;
        });
    }

    const getTransactionsData = (newSelectedType?: string, newDateRange?: IDateRange, newTransactionStatus?: any, newSearch?: string) => {
        generateOdataSourceLocal(
            {
                size: 0,
                page: 0,
                search: newSearch != null ? newSearch : search,
                propertyId: config.dataFilterSettings && config.dataFilterSettings.propertyId,
                contactId: config.dataFilterSettings && config.dataFilterSettings.contactId,
                unitId: config.dataFilterSettings && config.dataFilterSettings.unitId,
                tenancyId: config.dataFilterSettings && config.dataFilterSettings.tenancyId,
            },
            {
                transactionType: newSelectedType,
                transactionStatus: newTransactionStatus != null ? newTransactionStatus : transactionStatus ? transactionStatus : '',
            },
            newDateRange ? newDateRange.startDate : dateRange.startDate,
            newDateRange ? newDateRange.endDate : dateRange.endDate
        )
    }

    if(!config.dataFilterSettings || (config.dataFilterSettings && !config.dataFilterSettings.filtersLoaded)){
        return <div></div>
    }

    const groupingSpinnerCssOverride = `
            display: block;
            margin: auto;
            margin-left: 10px;
            text-align: center;
            `;

    return (
        <div className={styles.page}>
            {(!config.uiSettings ||
                !config.uiSettings.hideTabs) && (
                <div className={`${styles.row} ${styles.tabRow}`}>
                    <TransactionsTabs />
                </div>
            )}

            {
                tab == 'pl'
                ?
                <MoneyIncomeExpenseChart 
                    propertyId={config.dataFilterSettings && config.dataFilterSettings.propertyId} 
                    contactId={config.dataFilterSettings && config.dataFilterSettings.contactId} 
                    unitId={config.dataFilterSettings && config.dataFilterSettings.unitId} 
                    tenancyId={config.dataFilterSettings && config.dataFilterSettings.tenancyId}
                    forceUpdateCounter={dataChangeCount.current}
                />
                :
                <MoneySummary 
                    propertyId={config.dataFilterSettings && config.dataFilterSettings.propertyId} 
                    contactId={config.dataFilterSettings && config.dataFilterSettings.contactId} 
                    unitId={config.dataFilterSettings && config.dataFilterSettings.unitId} 
                    tenancyId={config.dataFilterSettings && config.dataFilterSettings.tenancyId}
                    forceUpdateCounter={dataChangeCount.current}
                    transactionType={config.dataFilterSettings.transactionType}
                    cardOnClick={moneySummaryCardOnClick}
                />
            }

            <div className={styles.content}>
                {(!config.uiSettings ||
                    !config.uiSettings.hideTopBar) && (
                    <div className={`${styles.row} ${styles.marginTop}`}>
                        <div className={styles.column}>
                            <div className={styles.dropdown}>
                                <div className={styles.typeDropdown}>
                                    <div className={styles.filter} onClick={() => toggleFilterSidebar(true)}>
                                        <img
                                            className={styles.filterIcon}
                                            src={filterIcon}
                                            data-tip="Filter Properties"
                                        />
                                    </div>
                                </div>

                                <div className={styles.searchInput}>
                                    <TextBoxComponent
                                        value={search}
                                        change={(args: ChangedEventArgs) => externalSearchHandler(args.value)}
                                        placeholder="Search Transactions"
                                    />
                                </div>
                                <div className={styles.dateRangePicker}>
                                    <DateRangePicker
                                        change={onDateRangeChange}
                                        cleared={onDateRangeCleared}
                                        focus={() => null}
                                        startDate={dateRange.startDate}
                                        endDate={dateRange.endDate}
                                        ref={(d) => (datePickerRef.current = d)}
                                    />
                                </div>
                                <div className={styles.more}>
                                    <Checkbox 
                                        checked={inAdvancedMode}
                                        onChange={() => ShowHideAdvancedColumns()}
                                    /> More
                                </div>
                            </div>
                        </div>

                        <div className={styles.iconColumn}>
                            <SFDropdown
                                loadedBeforeRender
                                customCssClassName={
                                    styles.syncfusionDropdown
                                }
                                customToggle={
                                    <IconButton
                                        button={{
                                            text: 'Actions',
                                            displayType: 'action',
                                            elementType: 'button',
                                            icon: 'action',
                                        }}
                                    />
                                }
                                items={[
                                    {
                                        iconCss: styles.subIconAddIncome,
                                        text: 'Add Income',
                                    },
                                    {
                                        iconCss: styles.subIconAddExpense,
                                        text: 'Add Expense',
                                    },
                                    {
                                        separator: true,
                                    },
                                    {
                                        iconCss: styles.subIconMarkAsPaid,
                                        text: 'Mark as Paid',
                                    },
                                    {
                                        iconCss: styles.subIconDelete,
                                        text: 'Delete',
                                    },
                                    {
                                        separator: true,
                                    },
                                    {
                                        iconCss: styles.subIconExportExcel,
                                        text: 'Export to Excel',
                                    },
                                    {
                                        iconCss: styles.subIconExportCsv,
                                        text: 'Export to CSV',
                                    },
                                    {
                                        iconCss: styles.subIconExportPdf,
                                        text: 'Export to PDF',
                                    },
                                ]}
                                onSelect={(args) => {
                                    if (args.item.text === 'Mark as Paid') {
                                        contextMenuClickedPaid()
                                    }
                                    if (args.item.text === 'Add Expense') {
                                        onAdd(true, false)
                                    }
                                    if (args.item.text === 'Add Income') {
                                        onAdd(false, true)
                                    }
                                    if (args.item.text === 'Delete') {
                                        contextMenuClickedDelete()
                                    }
                                    if (args.item.text === 'Export to PDF') {
                                        grid.current.pdfExport()
                                    }
                                    if (args.item.text === 'Export to Excel') {
                                        const excelExportProperties = {
                                            includeHiddenColumn: true
                                        };
                                        grid.current.excelExport(excelExportProperties)
                                    }
                                    if (args.item.text === 'Export to CSV') {
                                        grid.current.csvExport()
                                    }
                                }}
                            />

                            <div className={styles.addTransactions}>
                                <AddTransactions
                                    addEditOptions={config.addSettings}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {/* Filter row */}
                <div className={`${styles.groupingRow}`}>
                    <div className={`${styles.filterColumn}`}>
                        <S.HeaderContainer>
                            <S.HeaderItem>
                                <ToggleButton label='Month' buttonOnClick={(onStatus) => toggleButtonOnClick(onStatus, 'invoiceMonthYear')} reset={monthReset} initialStatus={tab != 'HMRC'} />
                            </S.HeaderItem>
                            <S.HeaderItem>
                                <ToggleButton label='Property' buttonOnClick={(onStatus) => toggleButtonOnClick(onStatus, 'propertyName')} reset={propertyReset} />
                            </S.HeaderItem>
                            <S.HeaderItem>
                                <ToggleButton label='Category' buttonOnClick={(onStatus) => toggleButtonOnClick(onStatus, 'categoryName')} reset={categoryReset} initialStatus={tab == 'HMRC'} />
                            </S.HeaderItem>
                            <S.HeaderItem>
                                <S.ResetButton onClick={ResetFilterAndGrouping}>Reset</S.ResetButton>
                            </S.HeaderItem>
                            {
                                groupingInProgress &&
                                <S.HeaderItem>
                                    <BeatLoader css={groupingSpinnerCssOverride} size={20} speedMultiplier={0.5} color={"#C4E2DF"}  />  
                                </S.HeaderItem>
                            }
                        </S.HeaderContainer>
                    </div>
                </div>
                
                {hasData != null && !hasData && (
                    <ElevioArticleEmbedWrapper>
                        <elevio-element
                            data-type="article"
                            data-id={`${elevioFinancialsArticleId}`}
                        ></elevio-element>
                    </ElevioArticleEmbedWrapper>
                )}


                <div className={`${styles.row}`}>
                    <div className={`${styles.column} ${styles.grid}`}>
                        <GridComponent
                            /* beforeDataBound={(e: BeforeDataBoundArgs) => {
                                if (!e.count) {
                                    grid.current.groupSettings = null
                                }
                            }} */
                            excelExportComplete={(e: any) =>
                                updateToastMessage(`Transactions have been successfully exported`,'success')
                            }
                            pdfExportComplete={(e: any) =>
                                updateToastMessage(`Transactions have been successfully exported`,'success')
                            }
                            groupSettings={{ showDropArea: false, showGroupedColumn: false, captionTemplate: groupTemplate }}
                            sortSettings={config.sortingSettings && config.sortingSettings}
                            queryCellInfo={customCell}
                            editSettings={editSettings}
                            showColumnMenu
                            id="transactionsGrid"
                            textWrapSettings={textWrapSettings}
                            allowTextWrap={true}
                            allowExcelExport={true}
                            allowPdfExport={true}
                            allowGrouping={true}
                            actionBegin={(args)=>{
                                console.debug(`MohTransactionsGrid - actionBegin - requestType: ${args.requestType}`)
                                if(args.requestType == 'ungrouping'){
                                    grid.current.getColumnByField('invoiceMonthYear').visible = false;
                                    grid.current.getColumnByField('propertyName').visible = false;
                                    grid.current.getColumnByField('categoryName').visible = false;
                                }
                            }}
                            actionComplete={(args)=>{
                                console.debug(`MohTransactionsGrid - actionComplete - requestType: ${args.requestType}`)
                                if(args.requestType == 'grouping' || args.requestType == 'ungrouping'){
                                    setGroupingInProgress(false);
                                }
                            }}
                            dataSource={transactionsData}
                            allowResizing={true}
                            allowReordering={true}
                            selectionSettings={selectionSettings}
                            allowPaging={true}
                            allowSorting={true}
                            pageSettings={pageSettings}
                            filterSettings={filterOptions}
                            allowFiltering={false}
                            ref={(g) => (grid.current = g)}
                            dataBound={() => {
                                console.debug(`MohTransactionsGrid - databound`)
                                if(!hasData){
                                    setHasData(true);
                                }
                                grid && grid.current.autoFitColumns();
                            }}
                            recordClick={onRowClick}
                        >
                            <Inject
                                services={[Aggregate,ColumnMenu,ContextMenu,CommandColumn,Filter,Page,Sort,ExcelExport,PdfExport,Group,Reorder,Resize,]}
                            />

                            {ColumnsTemplate({
                                config, dateRangeStart, dateRangeEnd, context, tab, // props
                                openTransactionModal: (data: ITransactionItem) => onEditRow(data, location, history),
                                showPMColumns: showPMColumns,
                            })}

                            {/* {config.template &&
                                ((config.aggregateSettings &&
                                    !config.aggregateSettings.hideAggregate) ||
                                    !config.aggregateSettings) && ( */}
                                    <AggregatesDirective>
                                        <AggregateColumnsDirective>
                                            <AggregateColumnDirective
                                                field="amount"
                                                type="Sum"
                                                groupCaptionTemplate={(props: any) => (
                                                    <div>
                                                        <span>
                                                            Sum:{' '}
                                                            <Currency value={props.Sum} prefix="£" />
                                                        </span>
                                                    </div>
                                                )}
                                            />
                                        </AggregateColumnsDirective>
                                    
                                        <AggregateColumnsDirective>
                                            <AggregateColumnDirective
                                                field="amount"
                                                type="Sum"
                                                footerTemplate={(props: any) => (
                                                    <div>
                                                        <span>
                                                            Total:{' '}
                                                            <Currency value={props.Sum} prefix="£" />
                                                        </span>
                                                    </div>
                                                )}
                                            />
                                        </AggregateColumnsDirective>
                                    </AggregatesDirective>
                               
                        </GridComponent>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransactionsGrid;
