import { AssetsAction, AssetActionType, IAssetTypeCollection, IAssetType } from "../../types/Assets";

interface IAssetTypesState {
    loading: boolean;
    error: string | null;
    dataResponse: IAssetType[];
    allAssetTypes: IAssetType[];
    data: IAssetTypeCollection;
    selectedLevel1Id: number;
}

const initialState: IAssetTypesState = {
    loading: false,
    error: null,
    dataResponse: [],
    allAssetTypes: [],
    data: {
        assetSubtypes: [],
        assetTypes: []
    },
    selectedLevel1Id: 0
}

const reducer = (
    state: IAssetTypesState = initialState,
    action: AssetsAction
) => {
    switch (action.type) {
        case AssetActionType.GET_ASSET_TYPES:
            return {
                ...state,
                loading: true,
                error: null
            }
        case AssetActionType.GET_ASSET_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                dataResponse: action.payload,
                allAssetTypes: [action.payload.forEach(function (item) {
                    item.subtypes.forEach(function (a) {
                        if (a.assetTypeName != 'Other' || (a.assetTypeName == 'Other' && a.parentAssetTypeId == 7)) {
                            state.allAssetTypes.push(a)
                        }
                    })
                }), ...state.allAssetTypes],
                data: {
                    assetSubtypes: state.selectedLevel1Id != 0 ? action.payload[state.selectedLevel1Id - 1].subtypes : state.allAssetTypes,
                    assetTypes: action.payload
                }
            }
        case AssetActionType.UPDATE_SELECTED_LEVEL1_ID:
            return {
                ...state,
                loading: false,
                error: null,
                data: {
                    assetSubtypes: action.payload != 0 ? state.dataResponse[action.payload - 1].subtypes : state.allAssetTypes,
                    assetTypes: state.data.assetTypes
                },
                selectedLevel1Id: action.payload,
            }
        case AssetActionType.GET_ASSET_TYPES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
};

export default reducer;
