import { createSlice } from '@reduxjs/toolkit'

export interface IBetaSettings{
    isPropertyDashboardBeta: Boolean;
    isKeyDatesBeta: Boolean;
}

const initialState = { 
    isPropertyDashboardBeta: false, 
    isKeyDatesBeta: true 
} as IBetaSettings;

export const betaConfigSlice = createSlice({
    name: 'betaConfig',
    initialState,
    reducers: {
        setBetaSettings: (state, action) => {
            //state.isPropertyDashboardBeta = action.payload.isPropertyDashboardBeta;
            state.isKeyDatesBeta = action.payload.isKeyDatesBeta;
        }
    }
})

export const { setBetaSettings } = betaConfigSlice.actions;
export const betaConfigReducer = betaConfigSlice.reducer;