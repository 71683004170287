import moment from "moment-timezone";

export const minDate: Date = new Date("1/15/2017");
export const maxDate: Date = moment().add(25, "years").endOf("year").toDate();

export const today: Date = new Date(new Date().toDateString());
export const weekStart: Date = moment().startOf("isoWeek").toDate();
export const weekEnd: Date = moment().endOf("isoWeek").toDate();
export const nextWeekStart: Date = moment().add(1, "weeks").startOf("isoWeek").toDate();
export const nextWeekEnd: Date = moment().add(1, "weeks").endOf("isoWeek").toDate();

export const monthStart: Date = moment().startOf("month").toDate();
export const monthEnd: Date = moment().endOf("month").toDate();
export const lastMonthStart: Date = moment().subtract(1, "months").startOf("month").toDate();
export const lastMonthEnd: Date = moment().subtract(1, "months").endOf("month").toDate();
export const nextMonthStart: Date = moment().add(1, "months").startOf("month").toDate();
export const nextMonthEnd: Date = moment().add(1, "months").endOf("month").toDate();


export const monthStartThreeMonthsAgo: Date = moment().subtract(3, "months").startOf("month").toDate();
export const monthEndThreeMonths: Date = moment().add(3, "months").endOf("month").toDate();

export const yearStart: Date = moment().startOf("year").toDate();
export const yearEnd: Date = moment().endOf("year").toDate();


const getCurrentFinancialYear = () => {
  var fiscalyear = null;
  var today = new Date();
  var thisMonth = today.getMonth() + 1;
  if (thisMonth <= 3 || (thisMonth == 4 && today.getDate() <= 5)) {
    fiscalyear = (today.getFullYear() - 1)
  } else {
    fiscalyear = today.getFullYear()
  }
  return fiscalyear
}
const currentFinancialYear = getCurrentFinancialYear();

export const financialYearStart: Date = new Date(currentFinancialYear, 3, 6);
export const financialYearEnd: Date = new Date(currentFinancialYear+1, 3, 5);
export const lastFinancialYearStart: Date = new Date(currentFinancialYear-1, 3, 6);
export const lastFinancialYearEnd: Date = new Date(currentFinancialYear, 3, 5);



