import React from "react";
import { IQuickViewValuation } from "../../../../types/QuickView";
import RotatingLoader from "../../RotatingLoader";
import Currency from "../../Currency";
import DateTimeFormat from "../../DateTimeFormat";
import UserContactInfo, { UserContactInfoType } from "../../UserContactInfo";
import propertyDataIcon from "../../../../images/icons/property_data.svg";
import landRegistryIcon from "../../../../images/icons/land_registry.svg";
import styles from "./ValuationPopup.module.scss";

interface IProps {
  valuation: IQuickViewValuation;
}

interface IState {

}

class ValuationPopup extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {

    }
  }

  componentDidMount(): void {

  }

  render() {
    if (!this.props.valuation) {
      return <RotatingLoader loading={true} text='' />
    }

    const { valuation } = this.props;
    const margin = <Currency value={valuation.margin} prefix="£" />

    return (
      <div className={styles.valuationPopup}>
        {
          valuation.source == "Property Data" &&
          <p>{valuation.isRental && 'Rental'} Valuation of <Currency value={valuation.amount} prefix="£" />
            {valuation.isRental && ' pcm'}{" "} received on <DateTimeFormat rawDateTime={valuation.dateTime} showOnlyDateFormat />
            {" "}from Property Data {!valuation.isRental && `with a +/- margin of error of `}
            {!valuation.isRental && margin}
            <img src={propertyDataIcon} />
          </p>
        }

        {
          valuation.source == "Land Registry" &&
          <p>
            Sold Price of <Currency value={valuation.amount} prefix="£" /> received on <DateTimeFormat rawDateTime={valuation.dateTime} showOnlyDateFormat />
            {" "}from the Land Registry
            <img src={landRegistryIcon} />
          </p>
        }

        {
          valuation.source == "Manual" &&
          <p>
            {valuation.isRental && 'Rental'} Valuation of <Currency value={valuation.amount} prefix="£" />
            {valuation.isRental && 'pcm'} received on <DateTimeFormat rawDateTime={valuation.dateTime} showOnlyDateFormat />
            {" "}

            {valuation.userId &&
              <div>from: <UserContactInfo disablePopup type={UserContactInfoType.User} id={valuation.userId} />
              </div>
            }
          </p>
        }
      </div>
    );
  }
}

export default ValuationPopup;