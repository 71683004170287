// Import React
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import styles from "./DocumentsPage.module.scss";
import SearchBar from "../../atoms/SearchBar";
import { IDocument } from "../../../types/DocumentService";
import { connect } from "react-redux";
import { updateDocumentList } from "../../../helpers/documentHelpers";
import { getDocumentList } from "../../../services/DocumentService";
import { IOptions } from "../../atoms/SelectDropdown";
import { IListResponse } from "../../../types/List";
import { IListItemOptions } from "../../../types/ListFilters";
import DocumentsGrid from "./DocumentsGrid";
import { DefaultTemplate } from "./DocumentsGrid/_templates/defaultTemplate";
import { DocumentPropertyTemplate } from "./DocumentsGrid/_templates/propertyTemplate";

interface IProps {
  history: any;
  match: any;
  location: any;
  documents?: Array<any>;

  propertyId?: string;
  contactId?: string;
  tenancyId?: string;
  issueId?: string;
  chatSessionId?: string;
  unitId?: string;
  inspectionId?: number;
  assetId?: number;
  onAddDocumentClick?(): void;
  groupBy?: Array<string>;
}

interface IState {
  documents: Array<IDocument>;
  filterOptions: Array<IOptions>;
  loading: boolean;
  showOnlyMyDocuments: boolean;

  page: number;
  size: number;
  totalRecords: number;

  selectedDocumentType: string;

  initialLoad: boolean;
}

class DocumentsPage extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      documents: [],
      filterOptions: [],
      loading: false,
      showOnlyMyDocuments: false,
      page: 1,
      size: 8,
      totalRecords: 0,
      selectedDocumentType: "",
      initialLoad: false
    };

    this.onDocumentTypeChange = this.onDocumentTypeChange.bind(this);
  }

  onDocumentTypeChange(options: IOptions): void {
    this.setState(
      {
        documents: [],
        page: 1,
        selectedDocumentType: options.value
      },
      () => {
        null
      }
    );
  }

  toggleShowOnlyMyDocuments(): void {
    updateDocumentList([]);
    this.setState(
      {
        page: 1,
        showOnlyMyDocuments: !this.state.showOnlyMyDocuments
      },
      () => null
    );
  }

  render() {
    return (
      <div className={styles.page}>
        <DocumentsGrid config={{
          template: this.props.propertyId ? DocumentPropertyTemplate : DefaultTemplate,
          onAddDocument: this.props.onAddDocumentClick,
          dataFilterSettings: {
            propertyId: this.props.propertyId,
            contactId: this.props.contactId,
            tenancyId: this.props.tenancyId,
            issueId: this.props.issueId,
            unitId: this.props.unitId,
            inspectionId: this.props.inspectionId,
            assetId: this.props.assetId
          },
          groupSettings: this.props.groupBy
        }} />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  documents: state.document.document_list
});

export default withRouter<IProps,any>(connect(mapStateToProps)(DocumentsPage));