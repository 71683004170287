import { DataManager } from '@syncfusion/ej2-data';
import { IListItemOptions } from '../../../../../types/ListFilters';
import { tenancyServiceOdataFriendly } from '../../../../../configuration/domains';
import { createBearerToken } from '../../../../../helpers/authHelpers';
import { toQueryString } from '../../../../../services/DocumentService';
import { ODataV4Adaptor } from '@syncfusion/ej2-data';


export function generateOdataSource(listItemOptions?: IListItemOptions, startDate?: Date, endDate?: Date, activeStatus?: boolean): DataManager {
    let stringed = toQueryString(listItemOptions);
    let filter = ``;

    let dataManager: DataManager = new DataManager({
        adaptor: new ODataV4Adaptor,
        url: `${tenancyServiceOdataFriendly}?${stringed}`,
        headers: [
            { "Authorization": createBearerToken() }
        ]
    });

    return dataManager;
}