import React from "react";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import styles from "./ContextMenu.module.scss";

interface IProps {
  clickableComponent: any;
  menuItems: Array<IMenuItem>;
}

interface IMenuItem {
  text: string;
  icon?: string;
  onItemClick(): void;
  canView: boolean;
  disabled?: boolean;
}

interface IState {
  anchorEl: null | HTMLElement;
}

class ContextMenu extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      anchorEl: null
    }

    this.setAnchorEl = this.setAnchorEl.bind(this);
  }

  setAnchorEl(el: any): void {
    this.setState({
      anchorEl: el
    });
  }

  render() {
    const handleClick = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      this.setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      this.setAnchorEl(null);
    };

    const { anchorEl } = this.state;

    return (
      <div>
        <div
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={styles.clickableComponent}>{this.props.clickableComponent}</div>

        <Menu
          className={styles.menu}
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {
            this.props.menuItems.map((m => {
              if (!m.canView) {
                return;
              }
              return <MenuItem>
                <div onClick={() => {
                  handleClose();
                  m.onItemClick ? m.onItemClick() : {}
                }} className={`${styles.row} ${m.disabled ? styles.disabled : ''}`}>
                  <div className={styles.column}><img src={m.icon} /></div>
                  <div className={styles.column}>
                    {m.text}
                  </div>
                </div>
              </MenuItem>
            }))
          }
        </Menu>
      </div>
    )
  }
}

export default ContextMenu;