import React from 'react'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import { withRouter } from 'react-router-dom'
import { removeURLParameter } from '../../../helpers/locationHelpers'
import { toggleTransactionPopup } from '../../../helpers/sidebarHelpers'
import AddEditTransaction, {
    IAddTransactionReferer,
    ITransactionType,
} from '../AddEditTransaction'
import Buttons from '../Buttons'
import styles from './AddEditTransactionModal.module.scss'
const queryString = require('query-string')

interface IProps {
    transaction_popup: boolean
    history: any
    location: any
    match: any

    add_transaction_form_changed: boolean
}

interface IState {
    options: IAddEditTransactionOptions
    outstandingChangesModalOpen: boolean
}

export interface IAddEditTransactionOptions {
    // Where did the open trigger come from?
    referer: IAddTransactionReferer

    transactionId?: string
    isExpense?: boolean
    isIncome?: boolean
    isPaid?: boolean

    // Property
    propertyId?: string
    propertyName?: string

    // Unit
    unitId?: string
    unitName?: string

    // Contact
    contactName?: string
    contactId?: string

    // Tenancy
    tenancyId?: string
}

class AddEditTransactionModal extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            options: null,
            outstandingChangesModalOpen: false,
        }
    }

    componentDidMount(): void {
        let options = queryString.parse(
            this.props.location.search
        ).addEditTransactionOptions

        if (!options) {
            return
        }

        let option: IAddEditTransactionOptions = JSON.parse(options)
        this.setState({
            options: option,
        })

        let removeQuery = removeURLParameter(
            this.props.location.search,
            'addEditTransactionOptions'
        )
        //this.props.history.push({
        //search: removeQuery
        //});
    }

    onSetOpenInternal = (open: boolean): void => {
        if (!open) {
            // Check if there is outstanding changes
            if (
                this.props.add_transaction_form_changed ||
                this.props.add_transaction_form_changed
            ) {
                this.setState({
                    outstandingChangesModalOpen: true,
                })

                return
            }
        }

        toggleTransactionPopup(open)
    }

    onCloseChangesModal = (closeSidebar: boolean): void => {
        this.setState({
            outstandingChangesModalOpen: false,
        })

        if (closeSidebar) {
            toggleTransactionPopup(false)
        }
    }

    render() {
        return (
            <Modal
                classNames={{ modal: styles.modal }}
                open={this.props.transaction_popup}
                onClose={() => this.onSetOpenInternal(false)}
                center
            >
                <Modal
                    open={this.state.outstandingChangesModalOpen}
                    onClose={() => this.onCloseChangesModal(false)}
                    center
                >
                    <p className={styles.modalHeader}>Outstanding changes</p>
                    <p className={styles.modalBodyOutstandingChanges}>
                        Any updated information will be lost, do you wish to
                        continue?
                    </p>

                    <Buttons
                        buttons={[
                            {
                                text: 'No',
                                displayType: 'cancel',
                                elementType: 'button',
                                onClick: () => this.onCloseChangesModal(false),
                            },
                            {
                                text: 'Yes',
                                displayType: 'submit',
                                elementType: 'button',
                                onClick: () => this.onCloseChangesModal(true),
                            },
                        ]}
                    />
                </Modal>

                <div className={styles.heading}>
                    {this.state.options && this.state.options.transactionId
                        ? 'Update'
                        : 'Add'}
                    {this.state.options &&
                        this.state.options.isExpense &&
                        ' Expense'}
                    {this.state.options &&
                        this.state.options.isIncome &&
                        ' Income'}
                </div>
                <AddEditTransaction
                    history={this.props.history}
                    match={this.props.match}
                    location={this.props.location}
                    options={this.state.options}
                    type={ITransactionType.expense}
                />
            </Modal>
        )
    }
}

const mapStateToProps = (state: any) => ({
    transaction_popup: state.app.popups.transaction_popup,
    add_transaction_form_changed:
        state.app.form_states.add_transaction_form_changed,
})

export default withRouter(
    connect(mapStateToProps)(AddEditTransactionModal) as any
)
