import React from "react";

import styles from "./StreetView.module.scss";
import { IAddress } from "../../../types/PropertyService/IPropertyService";
declare var google: any;

interface IProps {
  overlay?: boolean;
  address: IAddress;
  isFirstLoad?: boolean;
  firstLoadCallback?(lat: number, lng: number, heading: number): void;
  onPositionChangeCallback?(lat: number, lng: number, heading: number): void;
  canMove?: boolean;
  className?: string;
}

interface IState {
  lat: number;
  lng: number;
}

class StreetView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      lat: 0,
      lng: 0
    }

    this.getCordinates = this.getCordinates.bind(this);
    this.initializeMap = this.initializeMap.bind(this);
    this.geocodeAddress = this.geocodeAddress.bind(this);
  }

  async geocodeAddress(): Promise<any> {
    if (!google) {
      return;
    }
    var geocoder = new google.maps.Geocoder();
    var address = this.props.address.building_Number + ", " + this.props.address.building_Name + ", " + this.props.address.line1 + ", " + this.props.address.line2 + ", " + this.props.address.town + "," + this.props.address.postcode;
    geocoder.geocode({ 'address': address }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        var latitude = results[0].geometry.location.lat();
        var longitude = results[0].geometry.location.lng();

        this.setState({
          lat: latitude,
          lng: longitude
        }, () => this.initializeMap({ lat: this.state.lat, lng: this.state.lng })
        );
      }
    });
  }

  getCordinates() {
    this.geocodeAddress();
  }

  componentDidMount(): void {
    this.getCordinates();
  }

  componentWillReceiveProps(newProps: IProps): void {
    if (this.props.address != newProps.address) {
      this.getCordinates();
    }
  }

  initializeMap = (location1: any) => {
    var latLng = new google.maps.LatLng(location1.lat, location1.lng);

    var mapOptions = {
      center: latLng,
      zoom: 0,
      fullScreenControl: false,
      streetViewControl: this.props.canMove
    }

    var map = new google.maps.Map(this.refs.map, mapOptions);

    var panoOptions = {
      motionTrackingControl: false,
      motionTracking: false,
      zoomControl: false,
      addressControl: false,
      panControl: false,
      linksControl: this.props.canMove,
      fullscreenControl: false,
      clickToGo: this.props.canMove
    }

    var panorama = map.getStreetView().setOptions(panoOptions);

    panorama = map.getStreetView();
    panorama.setPosition(latLng);

    panorama.setVisible(true);

    var marker = new google.maps.Marker({
      position: latLng,
    });

    var heading = google.maps.geometry.spherical.computeHeading(panorama.getPosition(), marker.getPosition());
    panorama.setPov({
      heading: heading,
      pitch: 0
    });

    if (this.props.onPositionChangeCallback) {
      this.props.onPositionChangeCallback(panorama.getPosition().lat(), panorama.getPosition().lng(), heading);
    }

    if (this.props.isFirstLoad) {
      this.props.firstLoadCallback(latLng.lat(), latLng.lng(), heading);
    }

    if (this.props.onPositionChangeCallback) {
      google.maps.event.addListener(panorama, 'pov_changed', () => {
        var heading = panorama.getPov().heading;
        this.props.onPositionChangeCallback(panorama.getPosition().lat(), panorama.getPosition().lng(), heading);
      });
    }
  }

  render() {
    if (this.state.lat == 0 || this.state.lng == 0) {
      return null;
    }

    return (
      <div
        id='streetview'
        className={`${styles.streetView} ${this.props.canMove ? styles.canDrag : ''} ${this.props.className ? this.props.className : ''}`}
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: '#eeeeee'
        }}
      >
        <div className={`${styles.map} ${styles.test}`} ref="map" id="map" />
        <div ref="pano" id="pano" />
      </div>
    );
  }
}

export default StreetView;