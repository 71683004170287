import styled from 'styled-components';

export const SectionDiv = styled.div<{ width?: string }>`
    //border: 2px solid #0085CA;
    //border-radius: 20px;
    padding: 5px;
    background-color: #F5F5F5;
    max-width: ${p => p.width ? p.width : '600px'};
    width: ${p => p.width ? p.width : 'null'};
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-areas:
        'value1 value2 value3';
`; 

export const Value1Div = styled.div`
    grid-area: value1;
`;

export const Value2Div = styled.div`
    grid-area: value2;
`;

export const Value3Div = styled.div`
    grid-area: value3;
`;
