import {
    FileInfo,
    RemovingEventArgs,
    UploaderComponent,
    UploadingEventArgs,
} from '@syncfusion/ej2-react-inputs'
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { documentService } from '../../../configuration/domains'
import { createBearerToken } from '../../../helpers/authHelpers'
import { addURLParameter } from '../../../helpers/locationHelpers'
import { generateId } from '../../../helpers/randomHelpers'
import clearIcon from '../../../images/icons/clearicon.png'
import { toQueryString } from '../../../services/DocumentService'
import { getTypesForDropdown } from '../../../services/EntityService'
import { IDocumentUploadOptions } from '../../molecules/AddDocumentModal'
import EllipsisText from '../EllipsisText'
import SelectDropdown, { IOptions } from '../SelectDropdown'
import styles from './SFFileUpload.module.scss'

interface IProps {
    options?: IDocumentUploadOptions
    updateFiles(files: Array<IFile>): any
    onCloseModal?(): void
    onCloseModalWithDocs?(docs: any[]): void // added to work without redux

    history: any
    location: any
    match: any
}

export interface IFile {
    progress: number
    documentId: string
    description: string
    documentType: string
    originalFile: FileInfo
}

const SFFileUploader = ({
    history,
    location,
    options,
    updateFiles,
    onCloseModal,
    onCloseModalWithDocs
}: IProps) => {
    // State
    const [files, setFiles] = React.useState<Array<IFile>>([])
    const [fileTypes, setFileTypes] = React.useState([])
    const uniqueButtonKey = generateId(10)

    // Refs
    const uploadObj = React.useRef<UploaderComponent>(null)
    let dropElement: HTMLElement
    let dropContainerEle
    let dropAreaEle: HTMLElement

    let queryParams = toQueryString(options)

    let asyncSettings = {
        saveUrl: `${documentService}/Save?${queryParams}`,
        removeUrl: `${documentService}/Remove`,
    }

    const updateDescription = (index: number, description: string) => {
        let newFiles = [...files]
        let targetFile = newFiles[index]
        if (targetFile) {
            targetFile.description = description
            setFiles(newFiles)
            updateFiles(newFiles)
        }
    }

    const getDropdownTypes = () => {
        getTypesForDropdown('8a324853-7f7d-4b45-bc8e-891514de3713').then(
            (resp) => {
                if (resp && resp.data) {
                    setFileTypes(resp.data)
                }
            }
        )
    }

    let dropAreaRef = (element) => {
        dropAreaEle = element
    }

    let dropContainerRef = (element) => {
        dropContainerEle = element
    }

    const onUpload = (args: UploadingEventArgs) => {
        //Add in auth header
        args.currentRequest.setRequestHeader(
            'Authorization',
            createBearerToken()
        )
    }

    const onSuccess = (args: any) => {
        if (args.operation === 'upload') {
            let newFiles = [...files]
            let targetFile = newFiles.findIndex(
                (x) => x.originalFile.id == args.file.id
            )
            if (targetFile > -1) {
                newFiles[targetFile].documentId = args.e.currentTarget.response
                setFiles(newFiles)
                updateFiles(newFiles)
            }
        } else {
            let newFiles = files.filter(
                (x) => x.originalFile.id != args.file.id
            )
            setFiles(newFiles)
            updateFiles(newFiles)
        }

        let newQuery = addURLParameter(location.search, 'uploadedDocument=true')
        history.push({
            search: newQuery,
        })
    }

    const onFileSelect = (args: any) => {
        if (onCloseModalWithDocs) {
            const { filesData } = args
            if (filesData && filesData.length > 0) {
                let files: File[] = []
                filesData.map((f: FileInfo) => {
                    files.push(f.rawFile as File)
                })

                if(onCloseModalWithDocs){
                    onCloseModalWithDocs(files);
                }
                if (onCloseModal) {
                    onCloseModal()
                }
            }
            args.cancel = true
            return
        }

        let result: Array<any> = []
        for (let i: number = 0; i < args.filesData.length; i++) {
            result.push({
                ...args.filesData[i],
                documentType: (options && options.documentTypeId) || null,
                originalFile: args.filesData[i],
                description: args.filesData[i].name,
            })
        }
        setFiles([...result, ...files])
        uploadObj.current.upload(args.filesData, true)
        args.cancel = true
    }

    const onFileUpload = (args: any) => {
        let progressValue: number = Math.round(
            (args.e.loaded / args.e.total) * 100
        )

        // Update the progress value in state
        if (!isNaN(progressValue)) {
            let newFiles = [...files]
            let targetFile = newFiles.findIndex(
                (x) => x.originalFile.id == args.file.id
            )
            if (targetFile > -1) {
                newFiles[targetFile].progress = progressValue
                setFiles(newFiles)
            }
        }
    }

    const onUploadFailed = (args: any) => {
        if (args.operation === 'upload') {
            // Set progress of the upload to 0
            let newFiles = [...files]
            let targetFile = newFiles.findIndex(
                (x) => x.originalFile.id == args.file.id
            )
            if (targetFile > -1) {
                newFiles[targetFile].progress = 0
                setFiles(newFiles)
                updateFiles(newFiles)
            }
        }
    }

    const removeFiles = (args: any) => {
        let file = files[args]

        if (file) {
            if (file.documentId) {
                uploadObj.current.remove({
                    ...file.originalFile,
                    name: file.documentId,
                })
            }

            setFiles(files.filter((x) => x != file))
            updateFiles(files)
        }
    }

    const onRemoveFile = (args: RemovingEventArgs) => {
        args.postRawFile = true
        args.currentRequest.setRequestHeader(
            'Authorization',
            createBearerToken()
        )
    }

    const renderComplete = () => {
        document.getElementById(`browse_${uniqueButtonKey}`).onclick = () => {
            document
                .getElementsByClassName(styles.upload)[0]
                .getElementsByClassName('e-file-select-wrap')[0]
                .querySelector('button')
                .click()
            return false
        }
        dropElement = dropContainerEle
        uploadObj.current.element.setAttribute('name', 'UploadFiles')
        uploadObj.current.dropArea = dropElement
        uploadObj.current.dataBind()
    }

    const updateDocumentType = (index: number, documentTypeId: string) => {
        let newFiles = [...files]
        newFiles[index].documentType = documentTypeId
        setFiles(newFiles)
        updateFiles(newFiles)
    }

    React.useEffect(() => {
        renderComplete()
        getDropdownTypes()
    }, [])

    return (
        <div>
            <div className={styles.upload} ref={dropContainerRef}>
                <div id="uploader" className={styles.row}>
                    <div className={styles.column}>
                        <div className="template_wrapper">
                            <div
                                id="dropArea"
                                className={styles.dropArea}
                                ref={dropAreaRef}
                            >
                                <span id="drop" className="file-name-span drop">
                                    {' '}
                                    Drop file here or{' '}
                                    <a
                                        className={styles.browse}
                                        id={`browse_${uniqueButtonKey}`}
                                    >
                                        <u>Browse</u>
                                    </a>
                                </span>
                                <UploaderComponent
                                    multiple={
                                        options && !options.disableMultiple
                                    }
                                    key="upload-component"
                                    id="fileUpload"
                                    type="file"
                                    ref={uploadObj}
                                    asyncSettings={asyncSettings}
                                    uploading={onUpload}
                                    success={onSuccess}
                                    removing={onRemoveFile}
                                    selected={async (e) =>
                                        await onFileSelect(e)
                                    }
                                    progress={onFileUpload}
                                    failure={onUploadFailed}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="files" className={`${styles.row} ${styles.fileRow}`}>
                    {files.length > 0 &&
                        files.map((file, index) => {
                            let isUploaded =
                                file.originalFile.statusCode === '2'
                            let isUploading =
                                file.originalFile.statusCode != '2' &&
                                file.originalFile.statusCode != '0'
                            return (
                                <div
                                    className={styles.fileItem}
                                    key={`file__${index}`}
                                    data-file-name={file.originalFile.name}
                                >
                                    <div className={styles.innerColumn}>
                                        <div className={styles.innerRow}>
                                            <div
                                                className={`${
                                                    styles.innerColumn
                                                } ${
                                                    file.originalFile
                                                        .statusCode == '2'
                                                        ? styles.success
                                                        : file.originalFile
                                                              .statusCode == '0'
                                                        ? styles.failure
                                                        : ''
                                                }`}
                                            >
                                                <EllipsisText
                                                    text={
                                                        file.originalFile.name
                                                    }
                                                    length={15}
                                                />
                                            </div>
                                            <div className={styles.innerColumn}>
                                                {uploadObj.current.bytesToSize(
                                                    file.originalFile.size
                                                )}
                                            </div>
                                            <div
                                                className={`${styles.innerColumn} ${styles.status}`}
                                            >
                                                {file.progress === 100 &&
                                                isUploaded ? (
                                                    'Uploaded'
                                                ) : file.originalFile
                                                      .statusCode == '0' ? (
                                                    'Upload failed'
                                                ) : (
                                                    <progress
                                                        value={file.progress}
                                                        max={100}
                                                    />
                                                )}
                                            </div>
                                            <div className={styles.mobileClear}>
                                                <span
                                                    className={styles.clearIcon}
                                                    onClick={() =>
                                                        removeFiles(index)
                                                    }
                                                >
                                                    <img
                                                        src={clearIcon}
                                                        alt="Delete"
                                                    />
                                                </span>
                                            </div>
                                            <div className={styles.dropdown}>
                                                <SelectDropdown
                                                    selectedId={
                                                        file.documentType
                                                    }
                                                    multiSelect={false}
                                                    data={fileTypes}
                                                    placeholder="Select a file type..."
                                                    onSelect={(
                                                        selected: IOptions
                                                    ) =>
                                                        updateDocumentType(
                                                            index,
                                                            selected.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div
                                                className={`${styles.autoColumn} ${styles.delete}`}
                                            >
                                                <span
                                                    className={styles.clearIcon}
                                                    onClick={() =>
                                                        removeFiles(index)
                                                    }
                                                >
                                                    <img
                                                        src={clearIcon}
                                                        alt="Delete"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div className={styles.innerRow}>
                                            <input
                                                placeholder="Description..."
                                                value={file.description}
                                                onChange={(e) =>
                                                    updateDescription(
                                                        index,
                                                        e.currentTarget.value
                                                    )
                                                }
                                            />
                                            <div
                                                className={`${styles.autoColumn} ${styles.hidden}`}
                                            >
                                                <span
                                                    className={`${
                                                        styles.clearIcon
                                                    } ${
                                                        isUploading
                                                            ? styles.disabled
                                                            : ''
                                                    }`}
                                                    onClick={() =>
                                                        removeFiles(index)
                                                    }
                                                >
                                                    <img
                                                        src={clearIcon}
                                                        alt="Delete"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </div>
        </div>
    )
}

export default withRouter(SFFileUploader)
