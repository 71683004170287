import React from "react";
import styles from "./UserCardNav.module.scss";
import ProfileImage from "../ProfileImage";
import GenericButton from "../GenericButton";
import actionIcon from "../../../images/icons/property-action/Action.png";
import { withRouter } from "react-router";

interface IProps {
  forename: string;
  surname: string;
  subText?: string;
  image?: string;
  buttonAction?: any;
  button?: JSX.Element;
}

interface IWithRouterProps {
  history: any;
  location: any;
  match: any;
}

type Props = IProps & IWithRouterProps;

class UserCardNav extends React.Component<Props> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div className={styles.userCardNav}>
        <div className={styles.image}>
          <ProfileImage
            large={false}
            forename={this.props.forename}
            surname={this.props.surname}
            image={null}
          />
        </div>

        <div className={styles.name}>
          <div>
            {this.props.forename} {this.props.surname}{" "}
            {this.props.subText && (
              <span className={styles.subText}>{this.props.subText}</span>
            )}{" "}
          </div>
        </div>

        <div className={styles.actionButton}>
          {
            this.props.button ? this.props.button :
              <GenericButton onClick={this.props.buttonAction}>
                <img src={actionIcon} className={styles.actionIcon} /> <span>Actions</span>
              </GenericButton>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(UserCardNav);