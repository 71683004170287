import React from "react";

import { withRouter } from "react-router-dom";
import styles from "./SearchBar.module.scss";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import OutsideClickHandler from "react-outside-click-handler";
import RotatingLoader from "../RotatingLoader";

interface IProps {
  history: any;
  location: any;
  match: any;
  text?: string;
  onSearch?(searchTerm: string): void;
  isSearching?: boolean;
  results?: any[];
  onSearchButtonClick?(search: string): void;
  onKeyDown?(e: any): void;
  onEnter?(e: any): void;
  onFocus?(): void;
  handleChange?(search: string): void;
  children?: any;
}

interface IState {
  search: string;
  results: any[];
  searchTimeout: any;
}

class SearchBar extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      search: null,
      results: this.props.results,
      searchTimeout: null
    };

    this.handleChange = this.handleChange.bind(this);
    this.search = this.search.bind(this);
  }

  search(): void {
    this.props.onSearch(this.state.search);
  }

  handleChange(event: React.FormEvent<HTMLInputElement>) {
    const field = event.currentTarget.name;
    const value: string = event.currentTarget.value;
    this.setState({ [field]: value } as Pick<IState, any>);

    if (this.state.searchTimeout) clearTimeout(this.state.searchTimeout);

    this.setState({
      searchTimeout: setTimeout(() => {
        this.search();
      }, 500)
    });

    if(this.props.handleChange){
      this.props.handleChange(value);
    }
  }

  render() {
    return (
      <div className={styles.SearchBar}>
        <Row>
          <Col>
            <input
              onFocus={this.props.onFocus && this.props.onFocus}
              onKeyPress={this.props.onEnter ? this.props.onEnter : null}
              onKeyDown={this.props.onKeyDown ? this.props.onKeyDown : null}
              name="search"
              defaultValue={this.state.search}
              onChange={this.handleChange}
              className={styles.input}
              placeholder={this.props.text ? this.props.text : "Search..."}
              autoComplete={"off"}
            />
            <button
              type="submit"
              onClick={() => this.props.onSearchButtonClick(this.state.search)}
              className={styles.searchButton}
            >
              Search
            </button>
            {this.props.isSearching && (
              <RotatingLoader
                showLogo={false}
                loading={true}
                text="Searching..."
              />
            )}
            {!this.props.isSearching &&
              this.state.search &&
              (this.props.results && (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    this.setState({
                      search: ""
                    });
                  }}
                >
                  {this.props.children}
                </OutsideClickHandler>
              ))}
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(SearchBar);