import styled from 'styled-components';

export const SectionDiv = styled.div`
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: 600px;
    overflow-x: auto;
    white-space: nowrap;
`; 

export const CardDiv = styled.div`
    padding: 4px;
`; 