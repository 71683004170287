import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IChartProps } from '../../../../../molecules/Charts/RCMultiChart/RCMultiChart.component';
import { RCMultiChart } from '../../../../../molecules/Charts/RCMultiChart/RCMultiChart.component';
import { IListItemOptions, ITransactionListOptions } from '../../../../../../types/ListFilters';
import { useFetchTransactionsChartData } from '../../../../../../servicesHooks/useFetchTransactionsChartData.hook';
import { Loader } from '@rentchief/components';
import * as S from "./MoneyIncomeExpenseChart.styles";

interface props {
    propertyId?: string;
    contactId?: string;
    unitId?: string;
    tenancyId?: string;
    forceUpdateCounter?: number;
    width?: string;
}

const MoneyIncomeExpenseChart = ({propertyId, contactId, unitId, tenancyId, forceUpdateCounter, width}: props) => {
    const history = useHistory();
    const location = useLocation();

    const [listOptions, setListOptions] = useState<IListItemOptions>({
        size: 0,
        page: 0,
        search: "",
        //propertyId: propertyId,
        //contactId: contactId,
        //unitId: unitId,
        tenancyId: tenancyId
    });

    const [transactionOptions, setTransactionsOptions] = useState<ITransactionListOptions>({
                            //transactionType: transactionType,
                            transactionStatus: "",
                        });

    const { dataRetrieved, data } = useFetchTransactionsChartData(listOptions, transactionOptions);

    useEffect(() => {
        setListOptions({
            size: 0,
            page: 0,
            search: "",
            propertyId: propertyId ? propertyId : "",
            contactId: contactId ? contactId : "",
            unitId: unitId ? unitId : "",
            tenancyId: tenancyId ? tenancyId : ""
            })
        }, [propertyId, contactId, unitId, tenancyId, forceUpdateCounter]) 

    if(!dataRetrieved && !data){
        return <Loader />
    }

    let chart1Props : IChartProps[] =
    [
        {
            chartType: "Column",
            datasource: data.monthlyIncome,
            xAxisFieldName: "monthYear",
            yAxisFieldName: "total",
            name: "Income",
            fillColour: "#707070",
            fillOpacity: 0.6,
            showMarker:false
        },
        {
            chartType: "Column",
            datasource: data.monthlyExpense,
            xAxisFieldName: "monthYear",
            yAxisFieldName: "total",
            name: "Expense",
            fillColour: "red",
            fillOpacity: 0.6,
            showMarker:false
        },
        {
            chartType: "Column",
            datasource: data.monthlyProfit,
            xAxisFieldName: "monthYear",
            yAxisFieldName: "total",
            name: "Profit",
            fillColour: "#0085CA",
            fillOpacity: 0.6,
            showMarker:false
        }
    ];   

    return (
        <div>
            <S.SectionDiv width={width}>
                <RCMultiChart
                    id={"chart1"}
                    loading={false}
                    height={"250px"}
                    //width={"450px"}
                    charts={chart1Props}
                    //xAxisTitle={"Months"}
                    yAxisLabelFormat={"£{value}"}
                    //xAxisLabelFormat={"MMM-yy"}
                    xAxisValueType={"Category"}
                    //yAxisMinimum={0}
                    //yAxisMaximum={100}
                    //xAxisInterval={12}
                />
            </S.SectionDiv>
        </div>
    )
}

export default MoneyIncomeExpenseChart;