import React, { useState, useRef, useEffect } from 'react'
import { Router, useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers/rootReducer';
import {
    Aggregate,
    Column,
    ColumnDirective,
    ColumnMenu,
    ColumnsDirective,
    CommandColumn,
    ContextMenu,
    DataResult,
    DataStateChangeEventArgs,
    deleteBegin,
    ExcelExport,
    Filter,
    Grid,
    GridComponent,
    Group,
    Inject,
    Page,
    PdfExport,
    QueryCellInfoEventArgs,
    RecordClickEventArgs,
    Reorder,
    Resize,
    Sort,
    Sorts,
} from '@syncfusion/ej2-react-grids'
import {
    ChangedEventArgs,
    TextBoxComponent,
} from '@syncfusion/ej2-react-inputs'
import { filterOptions } from '../../../../configuration/grid_syncfusion_options'
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { SplitButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { Checkbox } from '@material-ui/core'
import { updateAlertMessage } from '../../../../helpers/alertHelpers'
import { updateToastMessage } from '../../../../helpers/toastHelper'
import { ITasksListItemOptions, ITaskDto } from '../../../../types/Tasks' 
import AssociatedDocuments from '../../../atoms/AssociatedDocuments'
import IconButton from '../../../atoms/Buttons/IconButton'
import RotatingLoader from '../../../atoms/RotatingLoader'
import SelectDropdown, { IOptions } from '../../../atoms/SelectDropdown'
import SFDropdown from '../../../atoms/SFDropdown'
import SFTaskDropdown from '../../../atoms/SFDropdown/SFTaskDropdown'
import RCFilter from '../../../atoms/ListComponents/Filter'
import { mapToDropdown } from '../../../../helpers/dropdownHelpers'
import styles from './TasksListPage.module.scss'
import * as S from "./TasksListPage.styles";
import './TasksListPage.css'
import DeleteTaskModal from '../DeleteTaskModal/DeleteTaskModal'
import { useFetchTasksQuery, useFetchTaskTypesQuery, useUpdateTaskMutation } from '../../../../servicesRtk/TasksServiceRtk'
import { IListItemOptions } from '../../../../types/ListFilters'
import { dateDiffInDays } from '../../../../helpers/dateHelpers'
import { reformatDates, getAllTypesAsFlatStructure } from '../_helpers'
import { Button, Loader } from "@rentchief/components";
import RCTabs from '../RCTabs/RCTabs.component'
import { DateStatusPill } from '../../../atoms/StatusPill/StatusPill.component';
import TaskEmptyState from '../../EmptyState/TaskEmptyState/TaskEmptyState.component';
import { trustedTeamUser } from '../../../../helpers/permissionHelpers';
import { hasClientArea } from '../../../../helpers/clientHelpers';
import { sendEmailsTest } from '../../../../services/NotificationService';
import UserContactInfo, { UserContactInfoType } from '../../../atoms/UserContactInfo';
const queryString = require('query-string')

const TasksListPage = () => {
    const history = useHistory();
    const location = useLocation();

    // Redux 
    const { propertyId, propertyName } = useSelector((state: RootState) => state.property.property_data.property_data);

    // RTK Query- Tasks
    const [updateTask, {data: updateTaskResponseData, isLoading: isUpdating}] = useUpdateTaskMutation();

    const [taskTypes, setTaskTypes] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [inAdvancedMode, setInAdvancedMode] = useState(false);
    const [listOptions, setListOptions] = useState<ITasksListItemOptions>({ page : 0, size : 20, skip : 0, onlyShowOwnRecords : false, taskStatusIds: '1,2,3,4'});
    const [reset, setReset] = useState(false);

    const { data, isLoading, isFetching, isError } = useFetchTasksQuery(listOptions, { refetchOnMountOrArgChange: true });
    const { data: taskTypesData, isError: taskTypesIsError } = useFetchTaskTypesQuery(null);
    
    const selectedTaskType = useRef(null);
    const deleteTaskIds = useRef<Array<number>>(null);
    const search = useRef(null);
    const selectedGouping = useRef(null);
    const selectedTaskStatusIds = useRef(null);
    const selectedTaskPriorityIds = useRef(null);
    const grid = useRef<Grid>(null);
    const isPaging = useRef<Boolean>(false);

    useEffect(() =>{
        // Redux value for poperty does not always get updated before hitting this,
        // so need to double check that we are indeed in the property context 
        let pathnameSplit = location.pathname.split('/');
        if(pathnameSplit[2] == "property" && propertyId){
            let currentOptions = {
                ...listOptions,
                propertyId: propertyId
            };
            setListOptions(currentOptions);
        }
    }, [])

    useEffect(() =>{
        const queryParams = new URLSearchParams(location.search);
        if(queryParams.has('status')){
            let status = queryParams.get('status');
            if(status == 'overdue'){
                let currentOptions = {
                    ...listOptions,
                    propertyId: propertyId,
                    taskStatusIds: '1,2,3,4',
                    dueDateTo: new Date().toDateString()
                };
                setListOptions(currentOptions);
            }else if(status == 'dueIn28Days'){
                let lastDate = new Date()
                lastDate.setDate(lastDate.getDate() + 29);
                let currentOptions = {
                    ...listOptions,
                    propertyId: propertyId,
                    taskStatusIds: '1,2,3,4',
                    dueDateFrom: new Date().toDateString(),
                    dueDateTo: lastDate.toDateString()
                };
                setListOptions(currentOptions);
            }
            queryParams.delete('status');
        }

        history.replace({ search: queryParams.toString() });

    }, []);

    useEffect(() =>{
        if(selectedGouping.current){
            grid.current.clearGrouping();
            grid.current.groupColumn(selectedGouping.current);
        }
        isPaging.current = false;
    }, [data])

    useEffect(() =>{
        if(!taskTypesIsError && taskTypesData && !taskTypes){
            setTaskTypes(getAllTypesAsFlatStructure(taskTypesData));
        }
    }, [taskTypesData])

    const onRowClick = (e: RecordClickEventArgs) => {
        // This filters out clicks on any of the other items for e.g. the checkbox and action menu
        if (e.target && e.target.classList.contains('e-rowcell') && e.cellIndex != 0 && e.cellIndex != 1) {
            onEditTask((e.rowData as ITaskDto).taskId)
        }
    }
    
    const getListOptions = (page?: number, size?: number, skip?: number, sortQuery?: string): ITasksListItemOptions => {
        let reqPage = page;
        let reqSize = size;
        let reqSkip = skip;

        if(page == null || page == undefined){
            reqPage = listOptions.page ? listOptions.page : 0;
        }

        if(size == null || size == undefined){
            reqSize = listOptions.size ? listOptions.size : 20;
        }

        if(skip == null || skip == undefined){
            reqSkip = listOptions.skip ? listOptions.skip : 0;
        }

        var options : ITasksListItemOptions = {
            ...listOptions,
            page: reqPage,
            size: reqSize,
            skip: reqSkip,
            onlyShowOwnRecords: false,
            search: !search.current || search.current == '' ? '' : search.current
        }

        if (selectedTaskType.current) {
            options.taskTypeId = Number(selectedTaskType.current.value)
        }

        if(propertyId){
            options.propertyId = propertyId;
        }

        if(selectedTaskStatusIds.current){
            options.taskStatusIds = selectedTaskStatusIds.current;
        }

        if(selectedTaskPriorityIds.current){
            options.taskPriorityIds = selectedTaskPriorityIds.current;
        }

        if(sortQuery){
            options.sortQuery = sortQuery;
        }
        
        return options;
    }

    const dataStateChange = (state: DataStateChangeEventArgs) => {
        // Need to keep track of this because we need to disable the loader when its paging 
        // as breaks the paging funcitonality, don't know the root cause yet
        isPaging.current = (state.action['currentPage'] != state.action['previousPage']);
        let sortQuery: string = ''
        if ((state.sorted || []).length) {
            sortQuery = state.sorted
                .map((obj: Sorts) => {
                    return obj.direction === 'descending'
                        ? `${obj.name} desc`
                        : `${obj.name} asc`
                }).reverse().join(',')
        }

        setListOptions(getListOptions(state.action['currentPage'], state.take, state.skip, sortQuery));
    }

    const TasksDataResult = (): DataResult => {
        grid.current && grid.current.hideSpinner();
        let tasksWithNormalisedDates = null;
        if(data){
            tasksWithNormalisedDates = reformatDates(data.data.data);
        }

        return {
            result: tasksWithNormalisedDates,
            count: data ? data.data.count : 0,
        }
    }

    const onSelectTaskType = (options: IOptions) => {
        selectedTaskType.current = options;
        setListOptions(getListOptions());
    }

    const documents = (props: ITaskDto) => {
        return (
            <AssociatedDocuments
                hideNoDocumentText
                options={{ taskId: props.taskId }}
            />
        )
    }

    const onAddTask = () => {
        let url = `/dashboard/task/add`;
        let qs = "";
        if(propertyId){
            qs = `?propertyId=${propertyId}&propertyName=${propertyName}`
        }
        history.push(url + qs);
    }

    const onEditTask = (taskId: number) => {
        //history.push('/dashboard/task/add', taskId);
        history.push('/dashboard/task' + `?taskId=${taskId}`);
    }

    const contextMenuClickedDelete = () => {
        if (grid) {
            let rows = grid.current.getSelectedRecords()
            if (rows.length == 0) {
                updateToastMessage('No rows have been selected.', 'warning')
                return
            }
            let taskIds = []
            for (let i = 0; i < rows.length; i++) {
                taskIds.push((rows[i] as any).taskId)
            }

            onDeleteRows(taskIds)
        }
    }

    const onDeleteRows = (taskIds: number[]) => {
        deleteTaskIds.current = taskIds;
        setDeleteModalOpen(true);
    }

    const rowSelected = () => {
        if (grid) {
            let rows = grid.current.getSelectedRecords()
            let rowValue = (rows[0] as any).taskId
        }
    }

    const ShowHideAdvancedColumns = () => {
        const advancedColumns = ['Category', 'Types', 'Priority', 'Assigned', 'Status', 'Docs', 'Created Date', 'Created By', 'Property', 'Unit']

        !inAdvancedMode ? grid.current.showColumns(advancedColumns) : grid.current.hideColumns(advancedColumns);
        setInAdvancedMode(!inAdvancedMode); 
    } 

    const onFilterClick = (newSelectedTab: string) => {
        setReset(false);
        switch(newSelectedTab){
            case 'all':
                selectedTaskStatusIds.current = '1,2,3,4,5';
                selectedTaskPriorityIds.current = '1,2,3,4';
                break;
            case 'open': 
                selectedTaskStatusIds.current = '1,2,3,4';
                selectedTaskPriorityIds.current = '1,2,3,4';
                break;
            case 'urgent':
                selectedTaskStatusIds.current = '1,2,3,4,5';
                selectedTaskPriorityIds.current = '1';
                break;
        }
        setListOptions(getListOptions());
    }

    const onGroupingClick = (newSelectedTab: string) => {
        setReset(false);
        grid.current.clearGrouping();
        switch(newSelectedTab){
            case 'propertyName':
                grid.current.groupColumn('propertyName');
                selectedGouping.current = 'propertyName';
                break;
            case 'taskTypeLevel2Name':
                grid.current.groupColumn('taskTypeLevel2Name');
                selectedGouping.current = 'taskTypeLevel2Name';
                break;
        }
    }

    const ResetFilterAndGrouping = () => {
        selectedTaskStatusIds.current = '1,2,3,4';
        selectedTaskPriorityIds.current = '1,2,3,4';
        selectedGouping.current = null;
        selectedTaskType.current = 0;
        search.current = null;
        grid.current.clearGrouping();
        let currentOptions = {
            ...getListOptions(),
            dueDateFrom: new Date(1900,1,1).toDateString(),
            dueDateTo: new Date(9999,1,1).toDateString(),
            taskTypeId: 0,
            sortQuery: undefined
        };
        setListOptions(currentOptions);
        setReset(true);
    }

    const ActionTemplate = (p: ITaskDto) => {
        return (
            <Router history={history}>
                <SFTaskDropdown
                    task={p}
                    taskId={p.taskId}
                    onDelete={() => onDeleteRows([p.taskId])}
                    onEdit={() => onEditTask(p.taskId)}
                />
            </Router>
        );
    }

    const TitleTemplate = (p: ITaskDto) => {
        return (
            <S.OuterTitleDiv className={styles.taskName} >
                <S.InnerTitleDiv>
                    <Checkbox className={styles.completedCheck} checked={p && p.taskStatusName == 'Completed'} 
                        onChange={() => {
                                let taskCopy: ITaskDto = {
                                    ...p,
                                    taskStatusName: p && p.taskStatusName == 'Completed' ? 'New' : 'Completed',
                                    taskStatusId: p && p.taskStatusName == 'Completed' ? '1' : '5',
                                    images: null // need to do this, but don't worry doesn't actually remove the images
                                };
                                updateTask(taskCopy);                                
                            }}
                    />
                    <S.TitleSpan onClick={() => onEditTask(p.taskId)}>{p.taskName}</S.TitleSpan>
                </S.InnerTitleDiv>
                <S.PropertyDiv onClick={() => onEditTask(p.taskId)}>{`${p.propertyName} ${p.unitId ? '-' : ''} ${p.unitName}`}</S.PropertyDiv>
                <S.ContactsDiv>{assignedContactTemplate(p)}{createdByTemplate(p)}</S.ContactsDiv>
            </S.OuterTitleDiv>
        )
    }

    const DueDateTemplate = (p: ITaskDto) => {
        if(p && p.dueDate){
            let dueDate = new Date(p.dueDate);
            let dueDateFormatted = dueDate.getDate() + "/" + (dueDate.getMonth() + 1) + "/" + dueDate.getFullYear();
            let diffInDays = dateDiffInDays(new Date(), dueDate);
            return (
                <S.DueDateDiv days={diffInDays} onClick={() => onEditTask(p.taskId)}>
                    <div>{dueDateFormatted}</div>
                    {/* <div>{diffInDays} days</div> */}
                    <S.DateStatus><DateStatusPill date={dueDate} /></S.DateStatus>
                </S.DueDateDiv>
            )
        }
        else{
            return null;
        }
    }

    const createdByTemplate = (p: ITaskDto) => {
        return(
            <S.ContactWrapper>
                <UserContactInfo
                    id={p.createdByUserId}
                    type={UserContactInfoType.User}
                />
            </S.ContactWrapper>
        )
    }

    const assignedContactTemplate = (p: ITaskDto) => {
        if(p.contactId){
            return(
                <S.ContactWrapper>
                    <UserContactInfo
                        id={p.contactId}
                        type={UserContactInfoType.Contact}
                    />
                </S.ContactWrapper>
            )
        }
    }

    const sendEmails = async () => {
        let resp = await sendEmailsTest();
        if(resp){
            if (resp.status == 200) {
                updateToastMessage(resp.data.message, 'success')
            }
        }
    }

    if(isError || (!isError && data && data.isError)){
        if(data && data.httpStatusCode == 401){
            return <div>You do not have permission to access</div> 
        }
        else{
            return <div>{data && data.errorMessage ? data.errorMessage :  "Error loading tasks"}</div> 
        }
    }

    const minWidthTablet = '768px'
    return (
        <>
            <DeleteTaskModal 
                ids={deleteTaskIds.current}
                open={deleteModalOpen}
                onClose={() => {setDeleteModalOpen(false);}}
            />
            <div className={styles.page}>
                <div className={styles.content}>
                    {/** Add first row containg dropdowns etc */}
                    <div className={styles.row}>
                        <div className={styles.filterColumn}>
                            {/** Add search field */}
                            <div className={styles.searchInput}>
                                <TextBoxComponent
                                    showClearButton
                                    value={search.current}
                                    change={(args: ChangedEventArgs) =>
                                        {
                                            search.current = args.value;
                                            setListOptions(getListOptions());
                                        }
                                        
                                    }
                                    placeholder="Tasks Search"
                                />
                            </div>

                            <div className={styles.typeDropdown}>
                                {/** Add dropdown */}
                                <SelectDropdown
                                    showAllOption
                                    showAllOptionValue="0"
                                    selectedId={selectedTaskType.current && selectedTaskType.current.value}
                                    placeholder="Filter by type..."
                                    data={mapToDropdown(taskTypes, 'taskTypeId', 'taskTypeName')}
                                    onSelect={onSelectTaskType}
                                />
                            </div>
                            <div>
                                <Checkbox 
                                    checked={inAdvancedMode}
                                    onChange={() => ShowHideAdvancedColumns()}
                                /> More
                            </div>
                        </div>

                        {/** Add dropdown */}
                        <div className={styles.iconColumn}>
                            <SFDropdown
                                loadedBeforeRender
                                customCssClassName={styles.syncfusionDropdown}
                                customToggle={
                                    <IconButton
                                        button={{
                                            text: 'Actions',
                                            displayType: 'action',
                                            elementType: 'button',
                                            icon: 'action',
                                        }}
                                    />
                                }
                                items={
                                    [
                                        ...(hasClientArea('DebugFunctions') ? [{text: 'Send Emails'}] : []),
                                        { iconCss: styles.subIconAdd, text: 'Add Task' },
                                        ...(trustedTeamUser() ? [{ iconCss: styles.subIconDelete, text: 'Delete' }] : []),
                                        { separator: true },
                                        { iconCss: styles.subIconExportExcel, text: 'Export to Excel' },
                                        { iconCss: styles.subIconExportCsv, text: 'Export to CSV' },
                                        { iconCss: styles.subIconExportPdf, text: 'Export to PDF' },
                                    ]
                                }
                            
                                onSelect={(args) => {
                                    if (args.item.text === 'Add Task') {
                                        onAddTask()
                                    }
                                    if (args.item.text === 'Delete') {
                                        contextMenuClickedDelete()
                                    }
                                    if (args.item.text === 'Export to PDF') {
                                        grid.current.pdfExport()
                                    }
                                    if (args.item.text === 'Export to Excel') {
                                        grid.current.excelExport()
                                    }
                                    if (args.item.text === 'Export to CSV') {
                                        grid.current.csvExport()
                                    }
                                    if (args.item.text === 'Send Emails') {
                                        sendEmails()
                                    }
                                }}
                            />
                        </div>

                        <div className={`${styles.iconColumn}`}>
                            <IconButton
                                button={{
                                    text: 'Add Task',
                                    displayType: 'submit',
                                    elementType: 'button',
                                    icon: 'add',
                                    onClick: () => onAddTask(),
                                }}
                            />
                        </div>
                    </div>

                    {/* Filter row */}
                    <div className={`${styles.row}`}>
                        <div className={`${styles.filterColumn}`}>
                            <S.HeaderContainer>
                                <S.HeaderItem>
                                    <RCTabs
                                        items={[
                                            {name : "Open", tab: "open"}, 
                                            {name : "All", tab: "all"}, 
                                            {name : "Urgent", tab: "urgent"} 
                                        ]}
                                        initialTab={"open"}
                                        width={'220px'}
                                        reset={reset}
                                        onClick={onFilterClick}
                                    />
                                </S.HeaderItem>
                                <S.HeaderItem>
                                    <S.GroupingDiv>
                                        <RCFilter
                                            //showFilterIcon
                                            activeElementId={selectedGouping.current}
                                            filterElements={
                                                [
                                                    {id: 'propertyName', status: 'Property'}, 
                                                    {id: 'taskTypeLevel2Name', status: 'Type'}
                                                ]
                                            }
                                            onChangeFilter={onGroupingClick}
                                            /* toggleStatusDropdownMenu={
                                                this.toggleStatusDropdownMenu
                                            } */
                                        />
                                    </S.GroupingDiv>
                                </S.HeaderItem>
                                <S.HeaderItem>
                                    <S.ResetButton onClick={ResetFilterAndGrouping}>Reset</S.ResetButton>
                                </S.HeaderItem>
                            </S.HeaderContainer>
                        </div>
                    </div>

                    <div className={`${styles.row}`}>
                        <div className={`${styles.column} ${styles.grid}`}>
                            {
                                (selectedGouping.current == null && listOptions.size == 20 && !isPaging.current && (isLoading || isUpdating))
                                ?
                                <div className={styles.loader}>
                                    <RotatingLoader loading={true} text="Loading tasks..." />
                                </div>
                                :
                                data && data.data.count == 0 
                                ?
                                <TaskEmptyState onAddTask={onAddTask} />
                                :
                                <GridComponent
                                    recordClick={onRowClick}
                                    rowSelected={rowSelected}
                                    ref={(g) => (grid.current = g)}
                                    //queryCellInfo={customCell}
                                    //editSettings={editSettings}
                                    showColumnMenu
                                    id="tasksGrid"
                                    //textWrapSettings={textWrapSettings}
                                    allowTextWrap={true}
                                    allowExcelExport={true}
                                    allowPdfExport={true}
                                    allowGrouping={true}
                                    groupSettings={{ showDropArea: false }}
                                    pageSettings={{ pageSize: 20, pageSizes: ["All", "10", "20", "50", "100"] }}
                                    dataSource={TasksDataResult()}
                                    dataStateChange={dataStateChange.bind(
                                        this
                                    )}
                                    dataBound={() => grid.current.autoFitColumns()}
                                    allowResizing={true}
                                    allowReordering={true}
                                    selectionSettings={{ checkboxOnly: true }}
                                    allowPaging={true}
                                    allowSorting={true}
                                    allowMultiSorting={true}
                                    filterSettings={filterOptions}
                                    className={styles.grid}
                                >
                                    <Inject
                                        services={[
                                            Aggregate,
                                            ColumnMenu,
                                            ContextMenu,
                                            CommandColumn,
                                            Filter,
                                            Page,
                                            Sort,
                                            ExcelExport,
                                            PdfExport,
                                            Group,
                                            Reorder,
                                            Resize,
                                        ]}
                                    />
                                    <ColumnsDirective>
                                        <ColumnDirective
                                            showInColumnChooser={false}
                                            showColumnMenu={false}
                                            allowSorting={false}
                                            hideAtMedia={`(min-width: ${minWidthTablet})`}
                                            textAlign="Left"
                                            type="checkbox"
                                            headerText=""
                                            customAttributes={{class: 'customcss'}}
                                        />
                                        <ColumnDirective
                                            showInColumnChooser={false}
                                            showColumnMenu={false}
                                            allowSorting={false}
                                            field="action"
                                            headerText=""
                                            textAlign="Left"
                                            customAttributes={{class: 'customcss'}}
                                            template={ActionTemplate}
                                        />
                                        <ColumnDirective
                                            visible={true}
                                            showColumnMenu={false}
                                            textAlign="Left"
                                            headerText="Due"
                                            field="dueDate"
                                            type="date"
                                            format={{
                                                type: 'date',
                                                format: 'dd/MM/yyyy',
                                            }}
                                            customAttributes={{class: 'customcss'}}
                                            template={DueDateTemplate}
                                        />
                                        <ColumnDirective
                                            field="taskName"
                                            headerText="Title"
                                            textAlign="Left"
                                            template={TitleTemplate}
                                        />
                                        <ColumnDirective
                                            visible={true}
                                            hideAtMedia={`(min-width: ${minWidthTablet})`}
                                            field="taskTypeLevel1Name"
                                            headerText="Category"
                                            textAlign="Left"
                                        />
                                        <ColumnDirective
                                            hideAtMedia={`(min-width: ${minWidthTablet})`}
                                            visible={true}
                                            field="taskPriorityName"
                                            headerText="Priority"
                                            textAlign="Left"
                                        />
                                        <ColumnDirective
                                            hideAtMedia={`(min-width: ${minWidthTablet})`}
                                            visible={false}
                                            field="contactName"
                                            headerText="Assigned"
                                            textAlign="Left"
                                            width={'135px'}
                                            minWidth={'135px'}
                                            maxWidth={'135px'}
                                        />
                                        <ColumnDirective
                                            visible={false}
                                            hideAtMedia={`(min-width: ${minWidthTablet})`}
                                            field="propertyName"
                                            headerText="Property"
                                            textAlign="Left"
                                        />
                                        <ColumnDirective
                                            visible={false}
                                            hideAtMedia={`(min-width: ${minWidthTablet})`}
                                            field="taskTypeLevel2Name"
                                            headerText="Type"
                                            textAlign="Left"
                                        />
                                        <ColumnDirective
                                            hideAtMedia={`(min-width: ${minWidthTablet})`}
                                            visible={true}
                                            field="taskStatusName"
                                            headerText="Status"
                                            textAlign="Left"
                                        />
                                        {/* <ColumnDirective
                                            hideAtMedia={`(min-width: ${minWidthTablet})`}
                                            visible={false}
                                            field="documents"
                                            headerText="Docs"
                                            textAlign="Left"
                                            template={documents}
                                        /> */}
                                        <ColumnDirective
                                            visible={true}
                                            hideAtMedia={`(min-width: ${minWidthTablet})`}
                                            textAlign="Left"
                                            headerText="Created Date"
                                            field="dateCreated" 
                                            type="date"
                                            format={{
                                                type: 'date',
                                                format: 'dd/MM/yyyy HH:mm',
                                            }}
                                        />
                                        <ColumnDirective
                                            visible={false}
                                            hideAtMedia={`(min-width: ${minWidthTablet})`}
                                            textAlign="Left"
                                            headerText="Created by"
                                            field="createdByUserId" // need to change this to name
                                            template={createdByTemplate}
                                        />
                                        <ColumnDirective
                                            visible={false}
                                            hideAtMedia={`(min-width: ${minWidthTablet})`}
                                            field="unitName"
                                            headerText="Unit"
                                            textAlign="Left"
                                        />
                                    </ColumnsDirective>
                                </GridComponent>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
        
    )

}



export default TasksListPage;
