import React from 'react'
import Modal from 'react-responsive-modal'
import { withRouter } from 'react-router-dom'
import { ISFUploadUrls, IUploadRequest } from '../../../types/ImageService'
import SFUploader from '../SFUploader'
import styles from './SFUploaderModal.module.scss'

interface IProps {
    history: any
    location: any
    match: any

    open: boolean
    onToggle(open: boolean): void

    urls?: ISFUploadUrls
    options?: IUploadRequest
    customOptions?: any
    successCallback?(): void
    onFileUpload?(imageId): void
    callbackOnly?: boolean
    onAddCallback?(any): void
    uploadFileLimit?: number
    allowedExtensions?: string;
    onFilesSelectComplete?(selectedImagesCount): void
}

const SFUploaderModal = (props: IProps) => {
    return (
        <Modal
            classNames={{ modal: styles.modal }}
            open={props.open}
            onClose={() => props.onToggle(false)}
            center
        >
            <h1 className={styles.heading}>Upload Images</h1>

            <div className={styles.content}>
                <SFUploader
                    allowedExtensions={props.allowedExtensions}
                    uploadFileLimit={props.uploadFileLimit}
                    onAdd={props.onAddCallback}
                    callbackOnly={props.callbackOnly}
                    close={() => {
                        props.successCallback()
                        props.onToggle(false)
                    }}
                    urls={props.urls}
                    options={props.options}
                    customOptions={props.customOptions}
                    successCallback={props.successCallback}
                    onFileUpload={props.onFileUpload}
                    onFilesSelectComplete={props.onFilesSelectComplete}
                />
            </div>
        </Modal>
    )
}

export default withRouter(SFUploaderModal)
