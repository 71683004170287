import React from 'react';
import { Checkbox as MaterialCheckbox, FormControlLabel } from "@material-ui/core";
import { QuestionComponentProps } from '../../../../types/Dynamic/types';
import styles from './Checkbox.module.scss';

const Checkbox = ({ content, onChange, resultValue, isReadOnly }: QuestionComponentProps) => {
    if (!content) {
        return null;
    }

    const onHandleKeyPress = (e) => {
        if (e.key != 'Enter') {
            return;
        }

        if (isReadOnly) {
            return;
        }

        onChange();
    }

    return (
        <div className={styles.row}>
            {
                content.map((c: string) => <div className={styles.column}><FormControlLabel
                    control={
                        <MaterialCheckbox
                            tabIndex={0}
                            disabled={isReadOnly}
                            checked={resultValue == c}
                            color="primary"
                            onChange={() => onChange(c)}
                            onKeyPress={onHandleKeyPress}
                        />
                    }
                    label={c}
                /></div>)
            }
        </div>
    )
}

export default Checkbox;