import React from 'react';
import * as S from "./SquareWithIconPart.styles";

interface props {
    id: string;
    labelText: string;
    iconUrl: string;
    onClick(): void;
}

const SquareWithIconPart = ({id, labelText, iconUrl, onClick}: props) => {
    return (
        <S.MainDiv onClick={() => onClick()}>
            <S.SquareDiv>
                <S.IconImage src={iconUrl} />
            </S.SquareDiv>
            <S.LabelText>{labelText}</S.LabelText>
        </S.MainDiv>
    )
}

export default SquareWithIconPart;