import React from 'react';
import regIcon from "../../../../images/icons/icon_reg.svg";
import gasIcon from "../../../../images/gassafe.png";
import govImage from "../../../../images/gov.png";
import styles from "./tooltipConfig.module.scss";

export const hmoTooltip = 
    <div className={styles.tooltipContent}>
        <h1>HMO Licence</h1>
        <p>
            A house in multiple occupation (HMO) is a property rented
            out by at least 3 people who are not from 1 ‘household’
            (for example a family) but share facilities like the
            bathroom and kitchen. It’s sometimes called a ‘house
            share’. If you want to rent out your property as a house
            in multiple occupation in England or Wales, you must
            contact your council to check if you need a licence. You
            must have a licence if you’re renting out a large HMO in
            England or Wales. Your property is defined as a large HMO
            if all of the following apply:
        </p>
        <ul>
            <li>
                It is rented to 5 or more people who form more than 1
                household
            </li>
            <li>
                Some or all tenants share toilet, bathroom or kitchen
                facilities
            </li>
            <li>
                At least 1 tenant pays rent (or their employer pays it
                for them)
            </li>
        </ul>
        <p>
            Even if your property is smaller and rented to fewer
            people, you may still need a licence depending on the
            area. Check with your council.
        </p>
        <h2>Restrictions</h2>
        <p>
            A licence is valid for a maximum of 5 years. You must
            renew your licence before it runs out. You need a separate
            licence for each HMO you run.
        </p>
        <span className={styles.links}>
            <img src={govImage} />
            <a
                href="https://www.gov.uk/house-in-multiple-occupation-licence"
                target="_blank"
            >
                https://www.gov.uk/house-in-multiple-occupation-licence
            </a>
        </span>
    </div>;

export const epcTooltip = 
    <div className={styles.tooltipContent}>
        <h1>EPC Certificate</h1>
        <p className={styles.tooltipInfo}>
            Click the check box to indicate that an EPC
            certificate exists for the property and then set the
            date it expires to enable the platform to set
            reminders.
        </p>
        <ul>
            <li>
                It is a legal requirement for residential landlords
                to provide an Energy Performance Certificate (EPC)
                to their tenants If the property you let has been
                marketed for sale or let, or modified, in the past
                10 years.
            </li>
            <li>
                The Rent Chief platform enables you to upload an EPC
                certificate, share this with any of your tenants and
                record an Expiry date. The system will then
                automatically remind you when it is near to expiry
                to book a new EPC.
            </li>
            <li>
                There are further requirements to improve the energy
                efficiency of your property if it as a rating of F
                or G.
            </li>
            <li>
                Please ensure you are aware of the full requirements
                on the above via the guidance link.
            </li>
        </ul>
        <span className={styles.links}>
            <img src={govImage} />
            <a
                href="https://www.gov.uk/guidance/domestic-private-rented-property-minimum-energy-efficiency-standard-landlord-guidance"
                target="_blank"
            >
                Minimum energy efficiency standard - landlord
                guidance
            </a>
        </span>
    </div>;

export const eicrTooltip =
    <div className={styles.tooltipContent}>
        <h1>Electrical Check</h1>
        <p className={styles.tooltipInfo}>
            Click the check box to indicate that all electrical
            installations have been tested in the property and
            then set the date it expires to enable the platform to
            set reminders.
        </p>
        <ul>
            <li>
                From 1 July 2020, all new private tenancies in
                England will need to ensure that electrical
                installations are inspected and tested by a
                qualified person before the tenancy begins. The
                landlord will then need to ensure that the
                installation is inspected and tested at least every
                five years – and more often if the most recent
                safety report requires it.
            </li>
            <li>
                For existing tenancies, an electrical safety test
                will need to be carried out by 1 April 2021, with
                regular tests following this as outlined above.
            </li>
            <li>
                The regulations will apply to all properties across
                the private rented sector, including houses in
                multiple occupation (HMOs), although lodger
                arrangements where the tenant shares accommodation
                or amenities with the landlord or their family are
                excluded. These regulations will replace the
                existing requirements for HMOs regarding electrical
                installation testing and inspection.
            </li>
            <li>
                A ‘qualified person’ for the purposes of these
                regulations is a person competent to undertake the
                inspection and testing required and any further
                investigative or remedial work in accordance with
                the electrical safety standards.
            </li>
            <li>
                Local authorities can impose a financial penalty of
                up to £30,000 for a breach of the regulations. Where
                there are multiple breaches, the local authority can
                impose multiple penalties.
            </li>
        </ul>
    </div>

export const gasSafetyTooltip =
    <div className={styles.tooltipContent}>
    <h1>Gas Certificate</h1>
    <br/>
        Easily upload your Gas certificate, share with your tenants and record the Expiry date. The system will
        then automatically remind you when it is near to expiry so you can arrange in good time for a new Safety check to be conducted.
    <h1>Requirements</h1>
    <ul>
        <li>
            Landlords are responsible for the safety of their
            tenants and when any gas appliances are installed
            in the property a Gas safety certificate will need
            to be present.{" "}
        </li>
    </ul>
    <span className={styles.links}>
        <img src={gasIcon} className={styles.gasIcon} />
        <a
            href="https://www.gassaferegister.co.uk/help-and-advice/renting-a-property/information-for-landlords/"
            target="_blank"
        >
            Information for Landlords
        </a>
    </span>
    </div>

export const landlordInsuranceTooltip = 
    <div className={styles.tooltipContent}>
        <h1>Landlord Insurance</h1>
        <br/>Landlord Insurance helps to cover the risks you face when letting or leasing a property. Track your renewal date to ensure you get the best deal.
        <br/>Easily upload your insurance certificate, and record the Expiry date. The system will then automatically remind you when it is near to expiry so you can arrange renewal of your insurance in good time.
        <ul>
            <li>
                Landlord Insurance helps to cover the risks you face when letting or leasing a property. Track your renewal date to ensure you get the best deal.
            </li>
            <li>
                Easily upload your insurance certificate, and record the Expiry date. The system will
                then automatically remind you when it is near to expiry so you can arrange renewal of your insurance in good time.
            </li>
        </ul>
        <span className={styles.links}>
            <img src={gasIcon} className={styles.gasIcon} />
            <a
                href="https://www.gassaferegister.co.uk/help-and-advice/renting-a-property/information-for-landlords/"
                target="_blank"
            >
                Information for Landlords
            </a>
        </span>
    </div>

export const propertyInspectionTooltip = null;

export const mortgageRateTooltip = null;

export const selectiveLicenseTooltip =
<div className={styles.tooltipContent}>
<h1>Selective License</h1>
<br/>
    Safely store your License document, share with your tenants and record the Expiry date. The system will
    then automatically remind you when it is near to expiry so you can arrange renewal in good time.
<h1>Requirements</h1>
<ul>
    <li>
    Depending on your property’s location, you may need a landlord license from the local authorities in order to rent your property. If you’re unsure if your property falls into a licensed controlled area, contact your local council for confirmation.
    </li>
</ul>
<span className={styles.links}>
            <img src={govImage} />
            <a
                href="https://www.gov.uk/guidance/domestic-private-rented-property-minimum-energy-efficiency-standard-landlord-guidance"
                target="_blank"
            >
                Minimum energy efficiency standard - landlord
                guidance
            </a>
        </span>
</div>

export const smokeDetectorsTooltip =
    <div className={styles.tooltipContent}>
        <h1>Smoke Detectors</h1>
        <ul>
            <li>
            By law, landlords must provide fire-detection
            equipment for each property
            </li>
            <li>
            Rent Chief allows you to record if smoke detectors
            have been installed and confirms to your tenant
            automatically that they exist, have been checked and
            are in working order at the outset of the tenancy.
            </li>
            <li>
            Please ensure you are fully aware of your
            responsibilities for smoke alarms
            </li>
        </ul>
        <span className={styles.links}>
            <img src={govImage} />
            <a
            href="https://www.gov.uk/government/publications/smoke-and-carbon-monoxide-alarms-explanatory-booklet-for-landlords/the-smoke-and-carbon-monoxide-alarm-england-regulations-2015-qa-booklet-for-the-private-rented-sector-landlords-and-tenants"
            target="_blank"
            >
            The Smoke and Carbon Monoxide Alarm (England)
            Regulations{" "}
            </a>
        </span>
    </div>

    export const carbonMonoxideTooltip =
        <div className={styles.tooltipContent}>
            <h1>Carbon Monoxide Detectors</h1>
            <ul>
                <li>
                Landlords are required to ensure carbon monoxide
                alarms a fitted in any room used as living
                accommodation where solid fuel is used.{" "}
                </li>
                <li>
                Rent Chief allows you to record if carbon monoxide
                alarms have been installed and confirms to your tenant
                automatically that they exis
                </li>
                <li>
                Please ensure you are fully aware of your
                responsibilities for carbon monoxide alarms
                </li>
            </ul>
            <span className={styles.links}>
                <img src={govImage} />
                <a
                href="https://www.gov.uk/government/publications/smoke-and-carbon-monoxide-alarms-explanatory-booklet-for-landlords/the-smoke-and-carbon-monoxide-alarm-england-regulations-2015-qa-booklet-for-the-private-rented-sector-landlords-and-tenants"
                target="_blank"
                >
                The Smoke and Carbon Monoxide Alarm (England)
                Regulations{" "}
                </a>
            </span>
        </div>

export const legionellaAssessmentTooltip =
    <div className={styles.tooltipContent}>
        <h1>Legionella Assessment</h1>
        <p>
            Landlords of residential accommodation have
            responsibilities for combating Legionnaires' Disease.
            Health and safety legislation requires that landlords
            carry out risk assessments for the Legionella bacteria
            which cause Legionnaires' Disease and thereafter
            maintain control measures to minimise the risk. Most
            rented premises will be low risk but it is important
            that risk assessments are carried out and control
            measures introduced.
        </p>
        <span className={styles.links}>
            <img src={govImage} />
            <a
            href="https://www.hse.gov.uk/legionnaires/legionella-landlords-responsibilities.htm"
            target="_blank"
            >
            https://www.hse.gov.uk/legionnaires/legionella-landlords-responsibilities.htm
            </a>
        </span>
    </div>