import { IInspectionDto } from "../types/Inspection"

interface IInspectionState {
    viewing_inspection: IInspectionDto;
}

const initialState: IInspectionState = {
    viewing_inspection: null
}

export default (state: IInspectionState = initialState, action: any): IInspectionState => {
    switch (action.type) {

        case "UPDATE_VIEWING_INSPECTION":
            return {
                ...state,
                viewing_inspection: action.data
            }

        case InspectionActionType.UPDATE_INSPECTION_HAS_RESULT:
            return {
                ...state,
                viewing_inspection: {
                    ...state.viewing_inspection,
                    hasInspectionResult: action.payload
                }
            }

        default:
            return state
    }
}

export enum InspectionActionType {
    UPDATE_INSPECTION_HAS_RESULT = "UPDATE_INSPECTION_HAS_RESULT"
}


export interface IUpdateInspectionHasResult {
    type: InspectionActionType.UPDATE_INSPECTION_HAS_RESULT,
    payload: boolean
}

export type InspectionAction =
    IUpdateInspectionHasResult;