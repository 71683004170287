import React from 'react';
import closeIcon from '../../../images/icons/clearicon.png';

import styles from './CloseButton.module.scss';

interface IProps {
    close(): void;
    displayText?: string;
}

class CloseButton extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        if (this.props.displayText) {
            return ( <div className={styles.closeButton} onClick={this.props.close}>{this.props.displayText}</div> );
        }

        return (
            <div className={styles.closeButton} onClick={this.props.close}><img src={closeIcon} alt='Close'/></div>
        );
    }
}

export default CloseButton;