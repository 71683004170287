import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import UnreadMessageCount from '../UnreadChatCount';
import UnreadChatCount from "../UnreadChatCount";

interface IProps {
  history: any;
  match: any;
  location: any;

  unreadChatCount: number;
}

class UnreadChatCountRedux extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return <UnreadChatCount unreadChatCount={this.props.unreadChatCount} />
  }
}

const mapStateToProps = (state: any) => ({
  unreadChatCount: state.notifications.unreadChatCount
});

export default withRouter(connect(mapStateToProps)(UnreadChatCountRedux));