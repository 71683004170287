import React from 'react'
import styles from './RegisterFormSuccess.module.scss'

const RegisterFormSuccess = () => {
    return (
        <div className={styles.success}>
            <h1>Registration Successful</h1>
            <h2>
                Hi, We sent you an email. Confirm and start using the platform
            </h2>

            <div className={styles.steps}>
                <p className={styles.nextStepsHeading}>Next Steps</p>

                <p className={styles.nextStep}>1. Verify your account</p>

                <p className={styles.stepDescription}>
                    Click on the link in the email we sent you. Check your junk
                    or spam if you haven't received the email
                </p>

                <p className={styles.nextStep}>2. Start using the platform</p>

                <p className={styles.stepDescription}>
                    Update your profile and start exploring the features
                </p>
            </div>
        </div>
    )
}

export default RegisterFormSuccess
