// Import React
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import styles from "./PropertyAuditPage.module.scss";
import AuditPage from "../../PlatformManagementPage/AuditPage";

interface IProps {
  history: any;
  match: any;
  location: any;
}

interface IState {
  propertyId: string;
}

class PropertyAuditPage extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      propertyId: null
    };
  }

  componentDidMount(): void {
    let propertyId = this.props.match.params.propertyId;

    if (!propertyId) {
      return;
    }

    this.setState({
      propertyId
    });
  }

  render() {

    if (!this.state.propertyId) {
      return null;
    }

    return (
      <div className={styles.container}>
        <AuditPage propertyId={this.state.propertyId} hideDropdown />
      </div>
    );
  }
}

export default withRouter(PropertyAuditPage);