import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// Import Styles
import { IPermission } from "../../../../../../types/PermissionsService";
import { IRoleDetail } from "../../../../../../types/RolesService";
import PermissionsPage from "../../../PermissionsPage";
import styles from "./RolePermissions.module.scss";

interface IState {
  permissions: Array<IPermission>;
}

interface IRouterProps {
  history: any;
  match: any;
  location: any;
}

interface IProps {
  roleId: string;
}

type Props = IRouterProps & IProps;

class RolePermissions extends Component<Props, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      permissions: []
    };
  }

  render() {
    if (!this.props.roleId) {
      return <div>Ensure there is a Role ID present.</div>;
    }

    return (
      <div className={styles.generalPage}>
        <PermissionsPage roleId={this.props.roleId} />
      </div>
    );
  }
}

export default withRouter(RolePermissions);
