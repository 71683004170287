interface IAlertState {
    alert_text: string;
    is_error: boolean;
    auto_hide: boolean;
    hide_after_milliseconds: number;
}

const initialState = {
    alert_text: "",
    is_error: false,
    auto_hide: true,
    hide_after_milliseconds: 3000
}

export default (state: IAlertState = initialState, action: any): IAlertState => {
    switch (action.type) {

        case "UPDATE_ALERT":
            return {
                ...state,
                alert_text: action.alert_text,
                is_error: action.is_error,
                auto_hide: action.auto_hide,
                hide_after_milliseconds: action.hide_after_milliseconds
            }

        default:
            return state
    }
}