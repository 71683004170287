import { apiService } from "./ApiService";
import { createBearerToken } from "../helpers/authHelpers";
import * as domains from "../configuration/domains";
import { ICreateEntityGroupRequest, ICreateEntityTypeRequest, IEntityListItem } from "../types/EntityService";

export const getEntities = (entityGroups: Array<string> | null): Promise<any> => {
  
  let query = "";
  if(entityGroups && entityGroups.length > 0){
    entityGroups.map(eg => {
      query = query + "entityGroups=" + eg + "&"
    });
  }

  return apiService
    .get(`${domains.entityService}/all/${query && '?' + query}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    });
};

export const addGroup = (request: ICreateEntityGroupRequest): Promise<any> => {
  return apiService
    .post(`${domains.entityService}/group`, request, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const addType = (request: ICreateEntityTypeRequest): Promise<any> => {
  return apiService
    .post(`${domains.entityService}/type`, request, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error;
    });
};

export const updateType = (request: IEntityListItem): Promise<any> => {
  return apiService
    .post(`${domains.entityService}`, request, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error;
    });
};

export const deleteType = (typeId: string): Promise<any> => {
  return apiService
    .delete(`${domains.entityService}/type/${typeId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const updateDefaults = (entityGroupId: string, selectedDefaultId: string): Promise<any> => {
  return apiService
    .put(`${domains.entityService}/${entityGroupId}/${selectedDefaultId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error;
    });
};

export const getTypes = (groupId: string): Promise<any> => {
  return apiService
    .get(`${domains.entityService}/type/${groupId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error;
    });
};

export const getTypesForDropdown = (groupId: string): Promise<any> => {
  return apiService
    .get(`${domains.entityService}/dropdown/type/${groupId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error;
    });
};

export const getGroupsForDropdown = (): Promise<any> => {
  return apiService
    .get(`${domains.entityService}/dropdown/group`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error;
    });
};