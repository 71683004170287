import * as domains from '../configuration/domains';
import { apiService } from '../services/ApiService';
import { createBearerToken } from '../helpers/authHelpers';
import { IChangePasswordRequest } from '../types/CredentialsService';

interface IResetRequest {
    email: string;
}

export const createPasswordResetToken = (email: string): Promise<any> => {
    let request: IResetRequest = {
        email
    }
    return apiService.post(`${domains.passwordService}/create`, request , {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        },
    }); 
}

export const changePassword = (request: IChangePasswordRequest): Promise<any> => {
    return apiService.post(`${domains.passwordService}/change-password`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getPasswordChangeAudit = (): Promise<any> => {
    return apiService.get(`${domains.passwordService}/change-password-audit`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}