import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { RootState } from '../../../../../reducers/rootReducer';
import EstimatedValuationModal from '../../../../atoms/EstimatedValuationModal';
import { IGetPriceValuationResponse, IGetPriceValuationRequest } from '../../../../../types/PropertyService/IPropertyService'
import { onAddDocument, markAsPropertyPurchased } from '../../helpers/dashboardHelper';
import SectionHeader from "../SectionHeader/SectionHeader.component"
import LabelWithIconPart from "../LabelWithIconPart/LabelWithIconPart.component"
import * as S from "./GettingStartedPropertyProspectSection.styles";
import { truncate } from 'fs';

interface sectionProps {
    id: string;
    header: string;
    hideOnClick(id: string): void
    estimatedValueUpdated(priceItemResp: IGetPriceValuationResponse, rentItemResp: IGetPriceValuationResponse): void
}

const GettingStartedPropertyProspectSection = ({id, header, hideOnClick, estimatedValueUpdated}: sectionProps) => {
    const history = useHistory();

    // Redux
    const { propertyId, address } = useSelector((state: RootState) => state.property.property_data.property_data);
    const { propertyType : propertyTypeStr } = useSelector((state: RootState) => state.property.property_data.property_data.propertySummary);

    const [showEstimatedValuationModal, setShowEstimatedValuationModal] = useState(false);

    return (
        <>
            {showEstimatedValuationModal && (
                <EstimatedValuationModal
                    isResidential={propertyTypeStr == null || propertyTypeStr.toLocaleLowerCase() == 'residential'}
                    //isRental={false}
                    onSuccess={(
                        priceItemResp: IGetPriceValuationResponse,
                        rentItemResp: IGetPriceValuationResponse,
                        request: IGetPriceValuationRequest
                    ) => {
                        setShowEstimatedValuationModal(false);
                        estimatedValueUpdated(priceItemResp, rentItemResp);
                    }}
                    postcode={address.postcode}
                    onClose={() =>
                        setShowEstimatedValuationModal(false)
                    }
                    open={showEstimatedValuationModal}
                    propertyId={propertyId}
                    header="Get Valuation"
                />
            )}
            <S.SectionDiv>
                <SectionHeader id={id} header={header} hideOnClick={hideOnClick} />
                <S.bodyDiv>
                    <S.bodyContentDiv>
                        <LabelWithIconPart 
                            id="getValuation" 
                            labelText="1. Get Valuation"  
                            iconUrl="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/invite_team_member_icon.svg"
                            onClick={() => setShowEstimatedValuationModal(true)}
                            tooltipText="Use our integrated valuation tool to check rental and property valuation of this property now."
                        />
                        <LabelWithIconPart 
                            id="analyseROI" 
                            labelText="2. Analyse ROI"  
                            iconUrl="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/add_property_icon.svg"
                            onClick={() => history.push(`/dashboard/property/${propertyId}/research/deal-calculator`)}
                            tooltipText={"Use our unique deal calculator to evaluate and optimise new property investments. Cater for multiple investor strategies – BTL, Buy Renovate Rent, Flips."}
                        />
                        <LabelWithIconPart 
                            id="addDocs" 
                            labelText="3. Add Docs"  
                            iconUrl="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/invite_team_member_icon.svg"
                            onClick={() => onAddDocument(propertyId, history)}
                            tooltipText={"Store property particulars, plans and any photos to help you collate all key buying info."}
                        />
                        <LabelWithIconPart 
                            id="team" 
                            labelText="4. Collaborate"  
                            iconUrl="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/invite_team_member_icon.svg"
                            onClick={() => history.push(`/dashboard/settings/team`)}
                            tooltipText={"Invite staff / co-investors / partners to collaborate on new prospective investments."}
                        />
                        <LabelWithIconPart 
                            id="tasks" 
                            labelText="5. Track tasks"  
                            iconUrl="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/create_tenancy_icon.svg"
                            onClick={() => history.push(`/dashboard/task/add?referer=mainDashboard`)}
                            tooltipText={"Track evaluation and purchase process with our tasks function."}
                        />
                        <LabelWithIconPart 
                            id="propertyPurchased" 
                            labelText="6. Acquired"  
                            iconUrl="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/invite_team_member_icon.svg"
                            onClick={() => markAsPropertyPurchased(propertyId)}
                            tooltipText={"Mark the property as purchased to enable all the property management functionality."}
                        />
                    </S.bodyContentDiv>
                </S.bodyDiv>
            </S.SectionDiv>
        </>
    )
}

export default GettingStartedPropertyProspectSection;