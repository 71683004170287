import { IContactPreference } from "../types/ContactPreferencesService";
import { IAddress } from "../types/PropertyService/IPropertyService";

interface IUserState {
  is_logged_in: boolean;
  is_logging_in: boolean;
  login_error_message: string;
  user_id: string;
  teamId: number;
  contact_id: string;
  profile_id: string;
  tenancy_id?: string;
  tenancy_property_id?: string;
  forename: string;
  surname: string;
  email: string;
  role: string;
  citizen: string;
  title: string;
  gender: string;
  accountCreatedOn: string;
  isApprovedAccount: boolean;
  profileImage: string;
  contactNumber: string;
  permissions: string[];
  access_token: string;
  is_creating_user: boolean;
  user_signup_success: boolean;
  profile_update_success: boolean;
  address: IAddress;
  contactPreferences: IContactPreference;
  registerMessage: string;
  isRegistered: boolean;
  registerAccessCodeValid: boolean;
  emergencyContactName: string;
  emergencyContactPhone: number;
  emergencyContactEmail: string;
}

const initialState = {
  is_logging_in: false,
  is_logged_in: false,
  login_error_message: "",
  user_id: "",
  teamId: null,
  contact_id: "",
  profile_id: "",
  title: "",
  gender: "",
  citizen: "",
  forename: "",
  surname: "",
  isApprovedAccount: false,
  accountCreatedOn: '',
  email: "",
  role: "",
  contactNumber: "",
  profileImage: "",
  permissions: [],
  access_token: "",
  is_creating_user: false,
  user_signup_success: false,
  profile_update_success: false,
  registerMessage: "",
  isRegistered: false,
  registerAccessCodeValid: false,
  emergencyContactEmail: '',
  emergencyContactName: '',
  emergencyContactPhone: 0,
  address: {
    building_Number: "",
    line1: "",
    line2: "",
    street_Name: "",
    town: "",
    county: "",
    postcode: "",
    country: "",
    locality: "",
    district: ""
  },
  contactPreferences: { post: false, sms: false, email: false, phone: false }
};

export default (state: IUserState = initialState, action: any): IUserState => {
  switch (action.type) {
    case "USER_LOGIN_ERROR":
      return {
        ...state,
        is_logging_in: false,
        is_logged_in: false,
        login_error_message: action.message,
        access_token: ""
      };

    case "USER_LOGIN_REQUESTED":
      return {
        ...state,
        is_logging_in: true,
        access_token: ""
      };

    case "USER_LOGIN_SUCCESS":
      return {
        ...state,
        is_logging_in: false,
        is_logged_in: true,
        login_error_message: "",
        forename: action.data.forename,
        access_token: action.data.accessToken
      };

    case "USER_IS_LOGGED_IN_TRUE":
      return {
        ...state,
        is_logged_in: true
      };

    case "GET_USER_DATA_REQUESTED":
      return {
        ...state,
        is_logging_in: false,
        is_logged_in: true,
        login_error_message: "",
        forename: action.data.forename,
        access_token: action.data.accessToken
      };

    case "GET_USER_DATA_SUCCESS":
      return {
        ...state,
        forename: action.data.forename,
        surname: action.data.surname,
        email: action.data.email,
        user_id: action.data.userId,
        teamId: action.data.teamId,
        contact_id: action.data.contactId,
        profile_id: action.data.profileId,
        tenancy_id: action.data.tenancyId,
        tenancy_property_id: action.data.tenancyPropertyId,
        accountCreatedOn: action.data.accountCreatedOn,
        isApprovedAccount: action.data.isApprovedAccount,
        contactNumber: action.data.contactNumber,
        role: action.data.roleName,
        permissions: action.data.permissions,
        profileImage: action.data.profileImageUri,
        title: action.data.title,
        gender: action.data.gender,
        citizen: action.data.citizen,
        address: action.data.address,
        contactPreferences: action.data.contactPreferences || initialState.contactPreferences,
        is_logged_in: true,
        is_logging_in: false,
        emergencyContactName: action.data.emergencyContactName,
        emergencyContactEmail: action.data.emergencyContactEmail,
        emergencyContactPhone: action.data.emergencyContactPhone
      };

    case "GET_USER_DATA_FAILURE":
      return {
        ...state,
        forename: initialState.forename,
        surname: initialState.surname,
        email: initialState.email,
        user_id: initialState.user_id,
        role: initialState.role,
        permissions: initialState.permissions,
        is_logged_in: false,
        is_logging_in: false
      };

    case "MOVE_TOKEN_TO_STORE":
      return {
        ...state,
        access_token: action.token
      };

    case "RESET_IS_CREATING_USER":
      return {
        ...state,
        is_creating_user: false,
        user_signup_success: false
      }

    case "LOGOUT_USER":
      return {
        ...state,
        access_token: initialState.access_token,
        forename: initialState.forename,
        surname: initialState.surname,
        is_logged_in: initialState.is_logged_in,
        is_logging_in: initialState.is_logging_in,
        permissions: initialState.permissions,
        //user_id: initialState.user_id,
        role: initialState.role,
        email: initialState.email,
        citizen: initialState.citizen,
        title: initialState.title,
        gender: initialState.gender
      };

    case "REGISTER_USER_NO_AUTO_LOGIN":
      return {
        ...state,
        is_creating_user: false,
        user_signup_success: false,
        registerMessage: action.data
      };

    case "REGISTER_USER_SUCCESS":
      return {
        ...state,
        is_creating_user: false,
        user_signup_success: true,
        registerAccessCodeValid: action.registerAccessCodeValid
      };

    case "PROFILE_UPDATE":
      return {
        ...state,
        forename: action.data.profile.forename,
        surname: action.data.profile.surname,
        contactNumber: action.data.profile.contactNumber,
        address: action.data.address,
        contactPreferences: action.data.contactPreferences,
        citizen: action.data.citizen,
        title: action.data.title,
        gender: action.data.gender
      };

    case "UPDATE_LOGGED_IN_USER_PROFILE":
      return {
        ...state,
        forename: action.data.forename,
        surname: action.data.surname,
        contactNumber: action.data.contactNumber,
        address: action.data.address,
        contactPreferences: action.data.contactPreferences,
        citizen: action.data.citizen,
        title: action.data.title,
        gender: action.data.gender,
        emergencyContactName: action.data.emergencyContactName,
        emergencyContactEmail: action.data.emergencyContactEmail,
        emergencyContactPhone: action.data.emergencyContactPhone
      };

    case "PROFILE_IMAGE_UPDATE":
      return {
        ...state,
        profileImage: action.data
      };

    case "UPDATE_TOKEN":
      return {
        ...state,
        access_token: action.token
      };

    default:
      return state;
  }
};
