import React from "react";
import ContentLoader from "react-content-loader";


interface IProps {
  loading: boolean;
}

class ContentLoading extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    if (!this.props.loading) {
      return null;
    }

    return (
      <ContentLoader
        height={35}
        width={120}
        speed={2}
        /* foregroundColor="#f3f3f3"
        backgroundColor="#ecebeb" */
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="6" y="8" rx="4" ry="4" width="100" height="6" />
        <rect x="6" y="28" rx="3" ry="3" width="85" height="6" />
      </ContentLoader>
    );
  }
}

export default ContentLoading;