import React from "react";
import Select from "react-select";

import styles from "./SelectDropdown.module.scss";

interface IProps {
  onSelect(ids: Array<IOptions> | IOptions): void;
  data: Array<IOptions>;
  multiSelect?: boolean;
  placeholder?: string;
  selectedValue?: IOptions;
  selectedId?: string;
  isClearable?: boolean;
  disabled?: boolean;
  showDefaultOption?: boolean;
  showAllOption?: boolean;
  showAllOptionText?: string;
  showAllOptionValue?: string;
  smallerFont?: boolean;
  isInsideAccordion?: boolean;
  showWhenEmpty?: boolean;
  selectFirstOption?: boolean;
  isSearchable?: boolean;
  allowWrapping?: boolean;
}

export interface IOptions {
  value: string;
  label: string;
  isDefault?: boolean;
  countValue?: number;
  description?: string;
}

interface IState {
  options: Array<IOptions>;
  selectedValue: Array<IOptions>;
}

class SelectDropdown extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      options: [],
      selectedValue: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentWillReceiveProps(newProps: IProps): void {
    if(!newProps.data){
      return;
    }

    if (!newProps.selectedId) {
      this.setState({
        selectedValue: []
      });

      if (this.props.selectFirstOption && newProps.data.length > 0) {
        this.handleChange(newProps.data[0]);
      }

      return;
    }

    if (newProps.selectedId && newProps.data.length > 0) {
      let selectedId = newProps.data.filter(
        d => String(d.value).toLowerCase() == String(newProps.selectedId).toLowerCase()
      );

      if (!selectedId || selectedId.length === 0) {
        return;
      }

      this.setState({
        selectedValue: selectedId
      });
    }

    if (!this.props.showDefaultOption) {
      return;
    }

    if (
      newProps.selectedId &&
      this.props.selectedId &&
      String(newProps.selectedId).toUpperCase() ==
      String(this.props.selectedId).toUpperCase() &&
      this.props.selectedId != ""
    ) {
      return;
    }
  }

  componentDidMount(): void {
    if (
      this.props.showDefaultOption &&
      !this.props.selectedId &&
      this.props.data.length > 0
    ) {
      let defaultOption = this.props.data.filter(d => d.isDefault);

      if (!defaultOption || defaultOption.length === 0) {
        return;
      }

      this.props.onSelect(defaultOption[0]);
      this.setState({
        selectedValue: defaultOption
      });
    }

    if (this.props.selectedId && this.props.data && this.props.data.length > 0) {
      let selectedId = this.props.data.filter(
        d => String(d.value).toLowerCase() == String(this.props.selectedId).toLowerCase()
      );

      if (!selectedId || selectedId.length === 0) {
        return;
      }

      this.setState({
        selectedValue: selectedId
      });
    }
  }

  handleChange = selectedValue => {
    this.setState({ selectedValue: selectedValue }, () => {
      this.props.onSelect(this.state.selectedValue);
    });
  };

  getData(): Array<IOptions> {
    if (this.props.showAllOption) {
      let data = [
        {
          label: this.props.showAllOptionText
            ? this.props.showAllOptionText
            : "All",
          value: this.props.showAllOptionValue
            ? this.props.showAllOptionValue 
            : null
        }
      ].concat(this.props.data);

      return data;
    }

    return this.props.data;
  }

  desktopWidth = 768;
  selectStyles = {
    menuList: styles => {
      return {
        ...styles,
        maxHeight: ((window.innerWidth < this.desktopWidth) || (this.props.isSearchable != null && this.props.isSearchable == true)) ? 168 : 420,
      };
    }
  };

  render() {
    if (this.props.showWhenEmpty || !this.props.data || this.props.data.length === 0) {
      //return null;
    }

    return (
      <Select
        //menuPortalTarget={this.props.isInsideAccordion ? document.body : null}
        //menuPosition={this.props.isInsideAccordion ? 'fixed' : null}
        id="dropdown"
        isDisabled={this.props.disabled}
        classNamePrefix="rc"
        className={`${styles.dropdown} ${this.props.smallerFont ? styles.smallerFont : ""
          }`}
        isMulti={this.props.multiSelect}
        value={
          this.props.selectedId
            ? this.state.selectedValue
            : this.props.selectedValue ? this.props.selectedValue : this.state.selectedValue
        } 
        placeholder={this.props.placeholder}
        onChange={this.handleChange}
        options={this.getData()}
        isClearable={this.props.isClearable}  
        isSearchable={((window.innerWidth >= this.desktopWidth) || (this.props.isSearchable != null && this.props.isSearchable == true)) ? true : false}
        styles={this.selectStyles}
        menuShouldScrollIntoView={true}
        menuPortalTarget={this.props.allowWrapping && document.body}
      />
    );
  }
}

export default SelectDropdown;
