import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Loader } from "@rentchief/components";
import { isTenant } from '../../../../../helpers/roleHelpers'
import SectionHeader from "../SectionHeader/SectionHeader.component"
import CounterPart from "../CounterPart/CounterPart.component"
import { ISection } from '../../PropertyDashboard/_config/dashboardConfig';
import InitialStateSection from '../../Sections/InitialStateSection/InitialStateSection.component';
import * as S from "./ActiveTasksSection.styles";
import { useFetchTasksStatsQuery } from '../../../../../servicesRtk/TasksServiceRtk';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../reducers/rootReducer';

interface sectionProps {
    id?: string;
    header?: string;
    hideOnClick?(id: string): void;
    config?: ISection;
    suppliedPropertyId?: string;
    hideInitialState?: boolean;
    numberFontSize?: string;
    nameFontSize?: string;
    color?: string;
    hideAddButton?: boolean;
}

const ActiveTasksSection = ({id, header, hideOnClick, config, suppliedPropertyId, hideInitialState, numberFontSize, nameFontSize, color, hideAddButton}: sectionProps) => {
    let history = useHistory();

    // Redux 
    const { propertyId: reduxPropertyId } = useSelector((state: RootState) => state.property.property_data.property_data);
    const { data: tasksStatsData, isError: taskStatsError } = useFetchTasksStatsQuery(suppliedPropertyId ? suppliedPropertyId : reduxPropertyId, { refetchOnMountOrArgChange: true });

    const [propertyId, setPropertyId] = useState(suppliedPropertyId ? suppliedPropertyId : reduxPropertyId);

    const onNavigate = (status?: string): void => {
        var url = '/dashboard/tasks'
    
        if (status != "open") {
            url = '/dashboard/tasks?status=' + status
        }
    
        if (propertyId) {
            if (isTenant()) {
                url = '/dashboard/tasks?propertyId=' + propertyId
            } else {
                url = `/dashboard/property/${propertyId}/tasks`
    
                if (status) {
                    url += '?status=' + status
                }
            }
        }
    
        history.push(url)
    }
    
    const addTask = () => {
        history.push(`/dashboard/task/add?referer=mainDashboard`)
    }

    return (
        <>
        {
            !tasksStatsData
            ?
            <Loader />
            :
            (tasksStatsData && tasksStatsData.allTasksCount > 0) || hideInitialState
            ?
            <S.SectionDiv>
                {
                    header && 
                    <SectionHeader id={id} header={header} hideOnClick={hideOnClick} titleOnClick={() => onNavigate("open")} addOnClick={!hideAddButton && addTask} color={color}/>
                }
                <S.NewDiv><CounterPart id="open" value={tasksStatsData.openTasksCount} type="Open" hasAdd={false} valueOnClick={onNavigate} color={color} numberFontSize={numberFontSize} nameFontSize={nameFontSize}/></S.NewDiv>
                <S.InProgressDiv><CounterPart id="dueIn28Days" value={tasksStatsData.openTasksDueIn28DaysCount} type="Due In 28 Days" hasAdd={false} valueOnClick={onNavigate} color={color} numberFontSize={numberFontSize} nameFontSize={nameFontSize}/></S.InProgressDiv>
                <S.TotalDiv><CounterPart id="overdue" value={tasksStatsData.overdueTasksCount} type="Overdue" hasAdd={false} valueOnClick={onNavigate} color={color} numberFontSize={numberFontSize} nameFontSize={nameFontSize}/></S.TotalDiv>
            </S.SectionDiv>
            :
            <InitialStateSection 
                id="tasksSummary"
                header={config && config.header} 
                iconUrl={config && config.initialState.iconUrl}
                title={config && config.initialState.title}
                summary={config && config.initialState.summary}
                //action={!header && <S.ActionDiv><Button onClick={addTask}>Add</Button></S.ActionDiv>}
                hideOnClick={hideOnClick}
                titleOnClick={() => onNavigate("open")}
                addOnClick={addTask}
                showHeader={header != null}
            />
        }
        </>
        
    )
}

export default ActiveTasksSection;