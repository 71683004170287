import React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { PulseLoader } from 'react-spinners'
import { Button, FormGroup, Input } from 'reactstrap'
import { findAddressByPostcode } from '../../../services/AddressService'
import { IAddress } from '../../../types/PropertyService/IPropertyService'
import styles from './AddressFinder.module.scss'

interface IProps {
    resetAddress(): void
    selectAddressCallback(address: IAddress): void
    label: string
    initialValue?: string
}

interface IState {
    isSearching: boolean
    searchTerm: string
    showDropdown: boolean
    addresses: Array<IAddress>
    error: string
    resultError: Boolean
    cursor: number
    hasInitialValue: boolean
}

let addressRefs = []
let searchInputRef

class AddressFinder extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        searchInputRef = React.createRef()

        this.state = {
            isSearching: false,
            searchTerm: '',
            showDropdown: false,
            addresses: [],
            error: '',
            resultError: false,
            cursor: 0,
            hasInitialValue: false
        }
    }

    componentDidMount = () => {
        if(this.props.initialValue){
            this.setState({
                searchTerm: this.props.initialValue
            })
        }
    }

    componentWillReceiveProps = (newProps) => {
        if(newProps.initialValue && !this.state.hasInitialValue){
            this.setState({
                searchTerm: newProps.initialValue,
                hasInitialValue: true
            })
        }
    }

    selectAddress = (index: number) => {
        let address = this.state.addresses[index]

        if (!address) {
            return
        }

        this.props.selectAddressCallback(address)
        this.setState({
            addresses: [],
            showDropdown: false,
            searchTerm: '',
        })
    }

    findAddress = async (postcode: string): Promise<void> => {
        this.setState({
            isSearching: true,
        })

        let result: any = await findAddressByPostcode(postcode)

        if (result.statusCode == '429') {
            this.setState({
                error: result.data,
                resultError: true,
                showDropdown: false,
                isSearching: false,
            })
        }

        if (!result.data) {
            this.setState({
                resultError: true,
                isSearching: false,
            })
            return
        }

        if (result != null) {
            this.setState(
                {
                    addresses: result.data.addresses,
                    showDropdown: true,
                    isSearching: false,
                    resultError: false,
                },
                searchInputRef.current.focus()
            )
            return
        }
    }

    handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        this.setState({ resultError: false })
        const field = event.currentTarget.name
        const value: string = event.currentTarget.value
        this.setState({ [field]: value.toLocaleUpperCase() } as Pick<
            IState,
            any
        >)
    }

    onKeyDown = (e: any): void => {
        const { cursor, addresses } = this.state

        if (e.keyCode === 38 && cursor > 0) {
            this.setState((prevState) => ({
                cursor: prevState.cursor - 1,
            }))
        } else if (e.keyCode === 40 && cursor < addresses.length - 1) {
            this.setState((prevState) => ({
                cursor: prevState.cursor + 1,
            }))
        } else if (e.keyCode === 13) {
            e.preventDefault();
            if (this.state.addresses.length == 0) {
                this.findAddress(this.state.searchTerm)
            } else {
                this.selectAddress(this.state.cursor)
            }
        }

        if (addressRefs && addressRefs.length > cursor) {
            if (addressRefs[cursor].current != null) {
                addressRefs[cursor].current.scrollIntoView({
                    alignToTop: 'true',
                    behavior: 'auto',
                    block: 'center',
                })
            }
        }
    }

    onKeyPress = (e: any): void => {
        if (e.key === 'Enter') {
            if (this.state.addresses.length == 0) {
                this.findAddress(this.state.searchTerm)
            } else {
                this.selectAddress(this.state.cursor)
            }
        }
    }

    enterPressed = (index: any, event: any) => {
        const { cursor, addresses } = this.state

        var code = index.keyCode || index.which
        if (code === 13) {
            this.selectAddress(event)
        }
        if (code === 38 && cursor > 0) {
            this.setState((prevState) => ({
                cursor: prevState.cursor - 1,
            }))
        } else if (code === 40 && cursor < addresses.length - 1) {
            this.setState((prevState) => ({
                cursor: prevState.cursor + 1,
            }))
        }
    }

    render() {
        return (
            <FormGroup className={styles.formGroup} id="addressFinder">
                <div className={styles.finderDiv}>
                    {this.state.error && <div>{this.state.error}</div>}

                    {!this.state.error && (
                        <div className={styles.inputHolder}>
                            <OutsideClickHandler
                                onOutsideClick={() => {
                                    this.setState({
                                        showDropdown: false,
                                        addresses: [],
                                        cursor: 0,
                                    })
                                }}
                            >
                                <Input
                                    className={styles.addressInput}
                                    value={this.state.searchTerm}
                                    type="text"
                                    name="searchTerm"
                                    id="postcode"
                                    placeholder="Enter postcode..."
                                    onChange={this.handleChange}
                                    onKeyDown={this.onKeyDown}
                                    onKeyPress={this.onKeyPress}
                                    innerRef={searchInputRef}
                                />

                                {this.state.addresses &&
                                    this.state.showDropdown && (
                                        <div
                                            className={
                                                styles.addressResultsDropdown
                                            }
                                        >
                                            <ul>
                                                {this.state.addresses.map(
                                                    (
                                                        x: IAddress,
                                                        index: number
                                                    ) => {
                                                        addressRefs.push(
                                                            React.createRef()
                                                        )

                                                        return (
                                                            <li
                                                                key={index}
                                                                className={
                                                                    this.state
                                                                        .cursor ===
                                                                    index
                                                                        ? styles.active
                                                                        : null
                                                                }
                                                                onClick={() =>
                                                                    this.selectAddress(
                                                                        index
                                                                    )
                                                                }
                                                                onKeyPress={this.enterPressed.bind(
                                                                    this,
                                                                    index
                                                                )}
                                                                onKeyDown={
                                                                    this
                                                                        .onKeyDown
                                                                }
                                                                ref={
                                                                    addressRefs[
                                                                        index
                                                                    ]
                                                                }
                                                            >
                                                                {x.line1},{' '}
                                                                {x.line2},{' '}
                                                                {x.town},{' '}
                                                                {x.county},{' '}
                                                                {x.postcode}
                                                            </li>
                                                        )
                                                    }
                                                )}
                                            </ul>
                                        </div>
                                    )}

                                <Button
                                    type="button"
                                    disabled={this.state.isSearching}
                                    className={styles.addressFindButton}
                                    onClick={() =>
                                        this.findAddress(this.state.searchTerm)
                                    }
                                >
                                    {!this.state.isSearching && 'Find'}
                                    <PulseLoader
                                        //sizeUnit={'px'}
                                        size={5}
                                        margin={'2px'}
                                        color={'#ffffff'}
                                        loading={this.state.isSearching}
                                    />
                                </Button>
                            </OutsideClickHandler>
                        </div>
                    )}
                    {this.state.resultError && (
                        <div>
                            <p className={styles.error}>
                                Please enter a valid postcode. 
                            </p>
                        </div>
                    )}
                </div>
            </FormGroup>
        )
    }
}

export default AddressFinder
