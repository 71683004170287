import React from "react";
import { withRouter } from "react-router-dom";
import ImageGallery from 'react-image-gallery';
import noImage from "../../../images/noImage.svg";
import styles from "./ImageGallery.module.scss";
import ImageGalleryDropdown from "./ImageGalleryDropdown";
import { deleteImage } from "../../../services/ImageService";
import { updateToastMessage } from "../../../helpers/toastHelper";
import { ImageSourceEnum } from "../../../types/ImageService";
import Modal from "react-responsive-modal";
import GoogleMap from "../GoogleMap";

interface IProps {
  history: any;
  location: any;
  match: any;

  items: Array<any>;
  className?: string;

  onClickFullscreen: boolean;

  onImageChanged?(height: number, width: number): void;

  dropdownActions: {
    onClickUpload(): void;
    onClickDelete(): void;
    onClickOverride(imageId: string): void;
    onChangeStreetView(): void;
    onChangePrimaryImage(imageId: string): void;
    openUploader(): void;
  }

  disableActionButtons?: boolean;
}

interface IState {
  active_image_id: string;
  is_primary_image_active: boolean;
  activeSource: ImageSourceEnum;
  activeLatitude?: number;
  activeLongitude?: number;
  mounted: boolean;
  showMap: boolean;
}

let gallery: any;

class ImageGalleryComponent extends React.Component<IProps, IState> {
  gallery = React.createRef();

  constructor(props: any) {
    super(props);

    this.state = {
      active_image_id: null,
      is_primary_image_active: true,
      activeSource: null,
      mounted: false,
      showMap: false
    }

    this.onDelete = this.onDelete.bind(this);
    this.onPrimary = this.onPrimary.bind(this);
    this.onChangeSlide = this.onChangeSlide.bind(this);
    this.checkForImage = this.checkForImage.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentWillReceiveProps(newProps: IProps): void {
    if (!newProps.items || !this.props.items) {
      return;
    }

    if (newProps.items.length != this.props.items.length) {
      if (newProps.items.length === 1) {
        let image = newProps.items[0];
        let source: ImageSourceEnum = ImageSourceEnum[image.source as keyof typeof ImageSourceEnum];

        this.setState({
          is_primary_image_active: image.primary,
          activeSource: source
        });
      }
    }
  }

  componentDidMount(): void {
    this.setState({
      mounted: true
    });

    let currentIndex = gallery.state.currentIndex;
    let image = this.props.items[currentIndex];

    if (!image) {
      return;
    }

    let source: ImageSourceEnum = ImageSourceEnum[image.source as keyof typeof ImageSourceEnum];

    this.setState({
      is_primary_image_active: image.primary,
      activeSource: source,
      activeLatitude: image.latitude,
      activeLongitude: image.longitude
    });
  }

  async onDelete(): Promise<void> {
    let currentIndex = gallery.state.currentIndex;
    let image = this.props.items[currentIndex];
    if (!image) {
      return;
    }

    if (this.props.dropdownActions.onClickOverride) {
      this.props.dropdownActions.onClickOverride(image.imageId);
      return;
    }

    let result = await deleteImage(image.imageId);
    if (result && result.status == 200) {
      updateToastMessage("Image has been deleted successfully", "success");
      this.props.dropdownActions.onClickDelete();
    }
  }

  componentWillUnmount(): void {
    this.setState({
      mounted: false
    });
  }

  async onPrimary(): Promise<void> {
    let currentIndex = gallery.state.currentIndex;
    let image = this.props.items[currentIndex];
    if (!image) {
      return;
    }

    this.props.dropdownActions.onChangePrimaryImage(image.imageId);

    this.setState({
      is_primary_image_active: true
    });
  }

  onChangeSlide(currentIndex: number): void {
    let image = this.props.items[currentIndex];
    if (!image) {
      return;
    }

    let source: ImageSourceEnum = ImageSourceEnum[image.source as keyof typeof ImageSourceEnum];

    this.setState({
      is_primary_image_active: image.primary,
      activeSource: source,
      activeLatitude: image.latitude,
      activeLongitude: image.longitude
    });

    this.props.onImageChanged && this.props.onImageChanged(gallery.state.gallerySlideWrapperHeight, gallery.state.galleryWidth);
  }

  onClick(): void {
    if (this.props.onClickFullscreen) {
      if (this.props.items.length > 0) {
        gallery && gallery.toggleFullScreen();
        return;
      }

      this.props.dropdownActions.onClickUpload();
    }
  }

  checkForImage(): void {
    if (this.props.items.length === 0) {
      this.props.dropdownActions.onClickUpload()
    }
  }

  onImgLoad = ({ target: img }) => {
    const { offsetHeight, offsetWidth, currentSrc } = img;
    let items = this.props.items;
    if(items && items.length > 0 && items[0].original == currentSrc){
      this.props.onImageChanged && this.props.onImageChanged(offsetHeight, offsetWidth);
    }
  };

  render() {
    return (
      <div className={`${styles.imageGallery} ${this.props.className ? this.props.className : ''}`}>
        <div className={styles.actions}>
          {this.state.mounted &&
            <ImageGalleryDropdown
              openMap={() => this.setState({ showMap: true })}
              onClickUpload={!this.props.disableActionButtons ? this.props.dropdownActions.onClickUpload : ()=>{}}
              onClickDelete={!this.props.disableActionButtons ? this.onDelete : ()=>{}}
              onChangePrimaryImage={this.onPrimary}
              onChangeStreetView={this.props.dropdownActions.onChangeStreetView}
              isPrimaryImageActive={this.state.is_primary_image_active}
              activeSource={this.state.activeSource}
              latitude={this.state.activeLatitude}
              longitude={this.state.activeLongitude}
            />
          }
        </div>

        <Modal classNames={{ modal: styles.modal }} open={this.state.showMap} onClose={() => this.setState({ showMap: false })} center>
          <p className={styles.modalHeader}>Image Location</p>
          <p className={styles.modalBody}>
            <GoogleMap
              latitude={this.state.activeLatitude}
              longitude={this.state.activeLongitude}
            />
          </p>
        </Modal>

        <div onClick={() => this.checkForImage()}>
          <ImageGallery
            onClick={this.onClick}
            onSlide={this.onChangeSlide}
            ref={(r: any) => gallery = r}
            infinite={false}
            lazyLoad={true}
            showFullscreenButton={true}
            showPlayButton={false}
            showBullets={true}
            showThumbnails={false}
            showNavigation={true}
            showNav={true}
            showIndex={true}
            isRTL={false}
            slideOnThumbnailOver={false}
            items={this.props.items && this.props.items.length > 0 ? this.props.items : [{ original: noImage }]}
            onImageLoad={this.onImgLoad}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(ImageGalleryComponent);