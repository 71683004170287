import { apiService } from './ApiService';
import * as domains from '../configuration/domains';
import { createBearerToken } from '../helpers/authHelpers';
import { ISaveGoogleStreetViewStaticImageRequest } from '../types/GoogleService';

export const saveStreetViewStaticImage = (request: ISaveGoogleStreetViewStaticImageRequest): Promise<any> => {
    return apiService.post(`${domains.googleService}`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}