import { ErrorMessage } from 'formik';
import React, { useRef } from 'react';
import { Tooltip } from "react-tippy";
import regIcon from "../../../../images/icons/icon_reg.svg";
import { CheckStatusPill, DateStatusPill } from '../../../atoms/StatusPill/StatusPill.component';
import * as S from './TenantDetailsSection.styles';

interface sectionProps {
    config?: any;
    title?: string;
    summary?: string; 
    tooltip?: any;
    selectionControl?: any
    selectionControlWidth?: string
    errorId?: string
}

const TenantDetailsSection = ({config, title, summary, tooltip, selectionControl, selectionControlWidth, errorId}: sectionProps) => {
    const navDivRef = useRef<HTMLDivElement>();

    return (
        <>
            <S.ContainerDiv>
                <S.InnerContainerDiv>
                    <S.MainAreaDiv>
                        <S.HeaderDiv>
                            <S.TitleDiv>{config.title}</S.TitleDiv>
                            {
                                tooltip 
                                ?
                                <S.TooltipDiv>
                                    <Tooltip
                                        theme="light"
                                        html={tooltip}
                                        position="right"
                                        trigger="mouseenter"
                                        arrow
                                        interactive={true}
                                    >
                                        <img src={regIcon} alt="Info" />
                                    </Tooltip>
                                </S.TooltipDiv>
                                :
                                ""
                            }
                        </S.HeaderDiv>
                        <S.DetailDiv dangerouslySetInnerHTML={{__html: config.description}}></S.DetailDiv>
                        {
                            errorId && 
                            <S.ErrorDiv>
                                <ErrorMessage name={errorId} />
                            </S.ErrorDiv>
                        }
                    </S.MainAreaDiv>
                    <S.SecondAreaDiv visibility={'visible'}>
                        <S.AnswerAreaDiv visibility={'visible'} width={selectionControlWidth ? selectionControlWidth : "100%"}>
                            {
                                selectionControl
                            }
                        </S.AnswerAreaDiv>
                    </S.SecondAreaDiv>
                </S.InnerContainerDiv>  
            </S.ContainerDiv>
        </>
    )
}

export default TenantDetailsSection;