import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Row from 'reactstrap/lib/Row'
import { IUserProfileResponse, ProfileType } from '../../../../../types/ProfileService/IProfile'
import UserProfilePage from '../../../UserPage/UserProfilePage'
import styles from './ContactSummary.module.scss'

const queryString = require('query-string')

interface IWithRouterProps {
    history: any
    location: any
    match: any
    userProfile: IUserProfileResponse
}

interface IState {
    contactId: string
    redirectUrl: string
}

class ContactSummary extends Component<IWithRouterProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            contactId: null,
            redirectUrl: null,
        }
    }

    componentDidMount(): void {
        let redirectUrl = queryString.parse(
            this.props.location.search
        ).redirectUrl

        if (redirectUrl) {
            this.setState({
                redirectUrl,
            })
        }
    }

    render() {
        if (!this.props.userProfile) {
            return null
        }

        return (
            <Row className={styles.container}>
                <UserProfilePage
                    redirectUrl={this.state.redirectUrl}
                    profileType={ProfileType.Contact}
                    userProfile={this.props.userProfile}
                />
            </Row>
        )
    }
}

export default withRouter(ContactSummary)
