import { IFilter } from "../types/FilterService/IFilterService";

export interface IFilterState {
  filters: IFilter;
}

const initialState = {
  filters: {
    financials: {
      transactionType: "",
      dateRangeStart: null,
      dateRangeEnd: null,
      setBySlideOut: false
    },
  } as IFilter,
} as IFilterState;

export default (
  state: IFilterState = initialState,
  action: any
): IFilterState => {
  switch (action.type) {
    case "UPDATE_FILTERS":
      return {
        ...state,
        filters: action.filters,
      };

    default:
      return state;
  }
};
