import { toggleDetailedSidebarNavigation } from "./sidebarHelpers";

export interface ISessionSettings {
    // Show Only
    showOwnProperties: boolean;

    // Filter by client
    propertyFilterByClient: string;
}

const lsName = "rc__session_settings";

export function getDashboardSideNavigationState(): string {
    let ls = localStorage.getItem("rc_dashb_state_detailed");
    if (ls) {
        toggleDetailedSidebarNavigation(ls == "1" ? true : false);
        return ls;
    }

    setDashboardSideNavigationState(false);
    toggleDetailedSidebarNavigation(false);
    return "0";
}

export function setDashboardSideNavigationState(isDetailed: boolean): void {
    localStorage.setItem("rc_dashb_state_detailed", isDetailed ? "1" : "0");
}

function getSettings(): ISessionSettings {
    let ls = localStorage.getItem(lsName);
    let settings: ISessionSettings = JSON.parse(ls);
    return settings;
}

export function session__showOwnProperties(): boolean {
    return getSettings().showOwnProperties;
}

export function update__session__showOwnProperties(show: boolean) {
    let s = getSettings();
    s.showOwnProperties = show;
    localStorage.setItem(lsName, JSON.stringify(s));
}

export function session__propertyFilterByClient(): string {
    return getSettings().propertyFilterByClient;
}

export function update__session__propertyFilterByClient(id: string) {
    let s = getSettings();
    s.propertyFilterByClient = id;
    localStorage.setItem(lsName, JSON.stringify(s));
}