import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { addURLParameter } from "../../../helpers/locationHelpers";
import { toggleAddContactSidebar } from "../../../helpers/sidebarHelpers";
import addIcon from "../../../images/icons/addbutton.svg";
import styles from "./AddContactOption.module.scss";

interface IProps {  
    onAdd?(): void;
    newContactType?: string;
    callbackName?: string;
    newName?: string;
    isButton?: boolean;
  }

const AddContactOption = (props: IProps) => {
    const history = useHistory();
    const location = useLocation();

    const onAdd = () => {
        if (props.onAdd) {
          props.onAdd();
        }
    
        let callbackName = props.callbackName ? props.callbackName + "=true" : "newContactCallback=true";
        if (props.newName) {
          callbackName += `&name=${props.newName}`;
        }
    
        let query = addURLParameter(location.search, callbackName);
        history.push({search: query});
    
        toggleAddContactSidebar(true);
      }

    const onAddContactClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onAdd();
      }

      if(props.isButton){
        return (
          <div className={styles.addButton}>
            <img src={addIcon} onClick={onAdd}/>
          </div>
        );
      }else{
          return (
            <div onClick={onAddContactClick} className={styles.addContact}>+ Add {props.newName ? props.newName : props.newContactType}</div>
        );
      }
}

export default AddContactOption;