import { apiService } from './ApiService'
import * as domains from '../configuration/domains'
import { createBearerToken } from '../helpers/authHelpers'
import { IAddIssueRequest, IUpdateIssueRequest } from '../types/IssueService'
import { IListItemOptions, IIssueListItemOptions } from '../types/ListFilters'
import { IDeleteRequest } from '../components/atoms/DeletePropertyModal'

function toQueryString(obj) {
    var parts = []
    for (var i in obj) {
        if (obj.hasOwnProperty(i)) {
            if (obj[i]) {
                parts.push(
                    encodeURIComponent(i) + '=' + encodeURIComponent(obj[i])
                )
            }
        }
    }
    return parts.join('&')
}

export const addIssue = (
    request: IAddIssueRequest,
    files?: FormData
): Promise<any> => {
    var q = toQueryString(request)

    return apiService.post(`${domains.issueService}?${q}`, files, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'multipart/form-data',
            Authorization: createBearerToken(),
        },
    })
}

export const getIssues = (
    options: IListItemOptions,
    issueOptions: IIssueListItemOptions
): Promise<any> => {
    var stringed = toQueryString(options)
    var stringed2 = toQueryString(issueOptions)

    return apiService.get(
        `${domains.issueService}/list?${stringed}&${stringed2}`,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: createBearerToken(),
            },
        }
    )
}

export const getIssue = (issueId: string): Promise<any> => {
    return apiService.get(`${domains.issueService}/${issueId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: createBearerToken(),
        },
    })
}

export const updateIssue = (
    request: IUpdateIssueRequest,
    issueId: string
): Promise<any> => {
    return apiService.put(`${domains.issueService}/${issueId}`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: createBearerToken(),
        },
    })
}

export const deleteIssue = (
    issueId: string,
    request: IDeleteRequest
): Promise<any> => {
    return apiService.post(
        `${domains.issueService}/delete/${issueId}`,
        request,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: createBearerToken(),
            },
        }
    )
}
