import styled from 'styled-components';

export const TabBar = styled.div`
    background-color: ${({ theme }) => theme.calculatorTab.tabBackgroundColor};
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-right: 5px;
`;

export const TabList = styled.ul<{ width?: string }>`
    text-align: left;
    list-style: none;
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    //width: ${p => p.width ? p.width : '300px'};
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    li {
        margin: 0 0.5rem;
        color: ${({ theme }) => theme.calculatorTab.tabColor};
        font-weight: bold;
        font-size: 12px;
        display: inline-block;
        cursor: pointer;
        padding: 0.75rem 0.25rem 0.75rem;

        &:hover {
            color: ${({ theme }) => theme.calculatorTab.tabHoverColor};
        }

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }

        &.active {
            background-color: ${({ theme }) => theme.calculatorTab.tabSelectedItemColor};
        }
    }
`;
