import React from "react";
import styles from "./TeamsStatusPill.module.scss";

interface IProps {
  status: string
}

const TeamsStatusPill = ({ status }: IProps) => {
  return <div className={styles.statuses}>
    {status === 'active' && (
      <div className={`${styles.status} ${styles.active}`}>Active</div>
    )}
    {status === 'invited' && (
      <div className={`${styles.status} ${styles.invited}`}>Invited</div>
    )}
    {status === 'inactive' && (
      <div className={`${styles.status} ${styles.notactive}`}>
        Inactive
      </div>
    )}
  </div>
}

export default TeamsStatusPill;