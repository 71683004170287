import { IDocument } from "../types/DocumentService"

interface IDocumentState {
   document_list: Array<IDocument>;
}

const initialState = {
    document_list: []
}

export default (state: IDocumentState = initialState, action: any): IDocumentState => {
    switch (action.type) {

        case "UPDATE_DOCUMENTS_LIST":
            return {
                ...state,
                document_list: action.data
            }

        default:
            return state
    }
}