// Import React
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getTenancySummary } from "../../../../services/TenancyService";
import { ITenancySummary } from "../../../../types/Tenancy";
import RotatingLoader from "../../../atoms/RotatingLoader";
import UserContactInfo, { UserContactInfoType } from "../../../atoms/UserContactInfo";
import TenancySummaryInfo from "../../../atoms/TenancySummaryInfo";
import IssuesCard from "../../IssuesCard";
import MyDocuments from "../TenantDashboard/MyDocuments";
import styles from "./TenantDashboardV2.module.scss";
import Property from "./Property";
import { updateDocumentList } from "../../../../helpers/documentHelpers";

interface IState {
  tenancySummary: ITenancySummary;
  loading: boolean;
}

interface IProps { }

interface IRouterProps {
  history: any;
  location: any;
  match: any;
}

type Props = IProps & IRouterProps;

class TenantDashboardV2 extends Component<Props, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      tenancySummary: null,
      loading: false
    };
  }

  componentDidMount(): void {
    this.setState({
      loading: true
    });
    getTenancySummary().then(resp => {
      if (resp && resp.status == 200 && resp.data) {
        this.setState({
          tenancySummary: resp.data
        });
        updateDocumentList(this.state.tenancySummary.documents);
      }
      this.setState({
        loading: false
      });
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          <RotatingLoader text="Loading your tenancy..." loading={true} />
        </div>
      );
    }

    if (!this.state.tenancySummary && !this.state.loading) {
      return <div>No active tenancy found</div>;
    }

    return (
      <div className={styles.tenantDashboard}>
        <div className={styles.content}>

          {/* Property Card and Contacts */}
          <div className={styles.parentRow}>
            <div className={`${styles.column}`}>
              <div className={styles.cardNoPadding}>
                <div className={styles.row}>
                  <div className={styles.propertyCol}>
                    <Property
                      property={{
                        propertyId: this.state.tenancySummary.property.propertyId,
                        propertyName: this.state.tenancySummary.property.propertyName,
                        address: this.state.tenancySummary.property.address,
                        propertyImage: this.state.tenancySummary.property.propertyImage
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={`${styles.column}`}>
              <div className={styles.card}>
                <div className={styles.title}>Contacts</div>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <UserContactInfo
                      type={UserContactInfoType.Contact}
                      id={this.state.tenancySummary.landlordId}
                    />
                  </div>

                  <div className={styles.col}>
                    <UserContactInfo
                      type={UserContactInfoType.Contact}
                      id={this.state.tenancySummary.tenantId}
                    />
                  </div>

                  <div className={styles.col}>
                    <UserContactInfo
                      type={UserContactInfoType.Contact}
                      id={this.state.tenancySummary.propertyManagerId}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Tenancy and Issues */}
          <div className={styles.parentRow}>
            <div className={`${styles.column}`}>
              <div className={styles.card}>
                <div className={styles.title}>Tenancy</div>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <TenancySummaryInfo
                      propertyId={this.state.tenancySummary.property.propertyId}
                  />
                  </div>
                </div>
              </div>
            </div>

            <div className={`${styles.column}`}>
              <div className={styles.card}>
                <div className={styles.title}>Issues</div>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <IssuesCard
                      canAdd
                      showIcon={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Tenancy Terms and Documents */}
          <div className={styles.parentRow}>
            <div className={`${styles.column}`}>
              <div className={styles.card}>
                <div className={styles.title}>Tenancy Terms</div>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <p className={styles.tenancyTerms} dangerouslySetInnerHTML={{__html: this.state.tenancySummary.tenancyDetail}} />
                  </div>
                </div>
              </div>
            </div>

            <div className={`${styles.column}`}>
              <div className={styles.card}>
                <div className={styles.title}>Documents</div>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <MyDocuments
                      canAdd
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(TenantDashboardV2) as any;