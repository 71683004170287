import React from 'react';
import {
    Row,
    Col
} from 'reactstrap';

import styles from './Footer.module.scss';

import logo from '../../../images/rent_chief_logo.svg';

class Footer extends React.Component {
    render() {
        return (
            <div className={styles.footer}>
                <Row>
                    <Col className={styles.left}>Powered by </Col>
                    <Col className={styles.right}><embed src={logo} /></Col>
                </Row>

            </div>
        );
    }
}

export default Footer;