import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { Button, Row, FormGroup } from 'reactstrap'

import { doesEmailAlreadyExist } from '../../../services/LoginService'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import {
    getUserInformation,
    IRegistrationResponse,
    registerUser,
} from '../../../actions/userActions'

import styles from './RegisterForm.module.scss'
import { ICreateUser } from '../../../types/RegistrationService/IRegistration'

import { Formik, Form, ErrorMessage } from 'formik'
import RotatingLoader from '../../atoms/RotatingLoader'
import { resetIsCreatingUser } from '../../../helpers/authHelpers'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Axios from 'axios'
import { registrationService } from '../../../configuration/domains'
import {
    AddTokenToLocalStorage,
    UpdateTokenInReduxStore,
} from '../../../helpers/tokenHelpers'
import { store } from '../../../store'
import { SignupSchema } from '../../../YupValidationSchemas'
import { IReactRouterProps } from '../../../types'
import {
    IRegisterForm,
    IRegisterFormProps,
    IRegisterFormState,
} from './register-form.types'
import {
    HeadingText,
    SubHeadingItems,
    SubHeadingText,
} from '../../../configuration/account/register.config'
import greenTick from '../../../images/account/ic_check_24px.svg'
import RegisterFormSuccess from './RegisterFormSuccess'
import ClientRegistrationFooter from '../../atoms/ClientRegistrationFooter'
import { RootState } from '../../../reducers/rootReducer'
const queryString = require('query-string')

let emailRef: React.RefObject<HTMLInputElement> = React.createRef();

const RegisterForm = () => {
    const history = useHistory();
    const routeMatch  = useRouteMatch();

    // Redux 
    const { add_property_request } = useSelector((state: RootState) => state.property);
    const { client } = useSelector((state: RootState) => state);
    const { app_loading } = useSelector((state: RootState) => state.app);
    const { registerMessage } = useSelector((state: RootState) => state.user);

    const [loading, setLoading] = useState<boolean>(false);
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const [email, setEmail] = useState<string>(null);
    const [forename, setForename] = useState<string>(null);
    const [surname, setSurname] = useState<string>(null);
    const [password, setPassword] = useState<string>(null);
    const [accessCode, setAccessCode] = useState<string>(null);
    const [isCalculatorProperty, setIsCalculatorProperty] = useState<boolean>(false);
    const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
    const [termsErrorMessage, setTermsErrorMessage] = useState<string>(null);
    const [salutation, setSalutation] = useState<string>(null);
    const [errorMessage, setErrorMessage] = useState<string>('');
    
    const registerUser = (registerRequest: ICreateUser): void => {
        setLoading(true);

        Axios.post(`${registrationService}`, registerRequest, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                const data: IRegistrationResponse = response.data;

                if (data != null && data.isLoggedIn && data.accessToken != '') {
                    AddTokenToLocalStorage(data.accessToken, true);
                    UpdateTokenInReduxStore(data.accessToken);
                    getUserInformation();

                    store.dispatch({
                        type: 'REGISTER_USER_SUCCESS',
                        data: data.isValidAccessCode,
                    });

                    history.push('/account/register/success');
                    setLoading(false);
                }

                if (data != null && data.isAccountCreated) {
                    store.dispatch({
                        type: 'REGISTER_USER_SUCCESS',
                        data: data.isValidAccessCode,
                    });

                    history.push('/account/register/success');
                    setLoading(false);
                    return;
                }

                if (
                    data == null ||
                    !data.isLoggedIn ||
                    data.accessToken == ''
                ) {
                    setLoading(false);

                    store.dispatch({
                        type: 'REGISTER_USER_NO_AUTO_LOGIN',
                        data: data.message,
                    })

                    if (emailRef && emailRef.current) {
                        emailRef.current.focus()
                        emailRef.current.select()
                        setEmail('');
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
            })
    }

/*     componentWillUnmount(): void {
        resetIsCreatingUser()

        this.setState({
            forename: '',
            surname: '',
            email: '',
            password: '',
        })
    } */

    useEffect(() => {
        if (add_property_request) {
            localStorage.setItem('rc-add-property', JSON.stringify(add_property_request));
        }

        if (queryString.parse(location.search).promo != null) {
            setAccessCode(queryString.parse(location.search).promo);
        }

        if (
            queryString.parse(location.search)
                .calculatorPropertyId != null
        ) {
            setIsCalculatorProperty(true);
        }
    }, []);

    const onRegistrationSubmitted = ({
        email,
        password,
        forename,
        surname,
        accessCode,
    }: {
        email: string
        password: string
        forename: string
        surname: string
        accessCode: string
    }) => {
        if (!termsAgreed) {
            setTermsErrorMessage('Please accept the Terms and Conditions to register.');
        } 
        else {
            let request: ICreateUser = {
                email,
                password,
                clientId: client && client.id,
                salutation: salutation,
                forename,
                surname,
                accessCode,
                calculatorPropertyId: '00000000-0000-0000-0000-000000000000',
            }

            registerUser(request)
        }
    }

    if (loading) {
        return (
            <div>
                <RotatingLoader
                    loading={true}
                    text="Setting up your user account. Please wait!"
                />
            </div>
        );
    }

    if (app_loading) {
        return null
    }

    return (
        <div className={styles.page}>
            <Switch>
                <Route
                    path={`${routeMatch.path}/success`}
                    component={RegisterFormSuccess}
                />

                <Route path={`${routeMatch.url}`}>
                    <div>
                        <h1 className={styles.formHeading}>
                            {HeadingText}
                        </h1>
                        <h2 className={styles.formSubHeading}>
                            {SubHeadingText}
                        </h2>

                        {
                            <div className={styles.offeringItems}>
                                {SubHeadingItems &&
                                    SubHeadingItems.map((item) => (
                                        <div>
                                            <img
                                                src={greenTick}
                                                alt={item}
                                            />
                                            {item}
                                        </div>
                                    ))}
                            </div>
                        }

                        <Formik
                            initialValues={{
                                email: email ? email : '',
                                password: password ? password : '',
                                forename: forename ? forename : '',
                                surname: surname ? surname: '',
                                accessCode: accessCode,
                            }}
                            enableReinitialize={true}
                            validationSchema={SignupSchema}
                            onSubmit={(values: IRegisterForm) =>
                                onRegistrationSubmitted({
                                    email: values.email,
                                    forename: values.forename,
                                    surname: values.surname,
                                    accessCode: values.accessCode,
                                    password: values.password,
                                })
                            }
                        >
                            {(formProps) => (
                                <Form className={styles.form}>
                                    {errorMessage != '' && (
                                        <div className={styles.error}>
                                            {errorMessage}
                                        </div>
                                    )}

                                    {queryString.parse(
                                        location.search
                                    ).calculatorPropertyId != null && (
                                        <div
                                            className={
                                                styles.propertyIdAdded
                                            }
                                        >
                                            Your property has been added.
                                            Please create an account to
                                            access your report.
                                        </div>
                                    )}

                                    {registerMessage && (
                                        <div className={styles.error}>
                                            {registerMessage}
                                        </div>
                                    )}

                                    <div className={styles.group}>
                                        <label className={styles.label}>
                                            Forename
                                        </label>
                                        <input
                                            className={styles.input}
                                            value={formProps.values.forename}
                                            placeholder="Forename"
                                            onChange={(e) =>
                                                formProps.setFieldValue(
                                                    'forename',
                                                    e.currentTarget.value
                                                )
                                            }
                                        />
                                        <div
                                            className={styles.errorMessage}
                                        >
                                            <ErrorMessage
                                                className={
                                                    styles.errorMessage
                                                }
                                                name="forename"
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.group}>
                                        <label className={styles.label}>
                                            Surname
                                        </label>
                                        <input
                                            className={styles.input}
                                            value={formProps.values.surname}
                                            placeholder="Surname"
                                            onChange={(e) =>
                                                formProps.setFieldValue(
                                                    'surname',
                                                    e.currentTarget.value
                                                )
                                            }
                                        />

                                        <div
                                            className={styles.errorMessage}
                                        >
                                            <ErrorMessage
                                                className={
                                                    styles.errorMessage
                                                }
                                                name="surname"
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.group}>
                                        <label className={styles.label}>
                                            Email
                                        </label>
                                        <input
                                            ref={emailRef}
                                            className={styles.input}
                                            value={formProps.values.email}
                                            placeholder="Email"
                                            onChange={(e) =>
                                                formProps.setFieldValue(
                                                    'email',
                                                    e.currentTarget.value
                                                )
                                            }
                                        />

                                        <div
                                            className={styles.errorMessage}
                                        >
                                            <ErrorMessage
                                                className={
                                                    styles.errorMessage
                                                }
                                                name="email"
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.group}>
                                        <label className={styles.label}>
                                            Password
                                        </label>
                                        <input
                                            ref={emailRef}
                                            className={styles.input}
                                            value={formProps.values.password}
                                            type="password"
                                            placeholder="Password"
                                            onChange={(e) =>
                                                formProps.setFieldValue(
                                                    'password',
                                                    e.currentTarget.value
                                                )
                                            }
                                        />

                                        <div
                                            className={styles.errorMessage}
                                        >
                                            <ErrorMessage
                                                className={
                                                    styles.errorMessage
                                                }
                                                name="password"
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.group}>
                                        <label className={styles.label}>
                                            Promo Code
                                        </label>
                                        <input
                                            ref={emailRef}
                                            className={styles.input}
                                            value={formProps.values.accessCode}
                                            placeholder="Promo Code"
                                            onChange={(e) =>
                                                formProps.setFieldValue(
                                                    'accessCode',
                                                    e.currentTarget.value
                                                )
                                            }
                                        />
                                    </div>

                                    <Row className={styles.checkboxText}>
                                        <FormGroup
                                            className={styles.checkboxGroup}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            termsAgreed
                                                        }
                                                        color="primary"
                                                        onChange={() => {
                                                                setTermsAgreed(!termsAgreed);
                                                                setTermsErrorMessage('');
                                                            }
                                                        }
                                                        value="Terms and Conditions"
                                                        className={
                                                            termsAgreed ? styles.materialCheckbox : null
                                                        }
                                                    />
                                                }
                                                label={
                                                    <>
                                                        <p>
                                                            I've read and
                                                            agreed to the
                                                            terms &amp;
                                                            conditions and
                                                            privacy policy.
                                                        </p>
                                                        <p>
                                                            {' '}
                                                            <ClientRegistrationFooter />
                                                        </p>
                                                    </>
                                                }
                                            />
                                        </FormGroup>
                                    </Row>

                                    {termsErrorMessage != '' && (
                                        <div className={styles.termsError}>
                                            {termsErrorMessage}
                                        </div>
                                    )}

                                    <div className={styles.buttonContainer}>
                                        <Button
                                            type="submit"
                                            className={styles.button}
                                            disabled={false}
                                        >
                                            {isLoggingIn && (
                                                <span>
                                                    Signing you up...
                                                </span>
                                            )}

                                            {!isLoggingIn && (
                                                <span>Sign Up</span>
                                            )}
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Route>
            </Switch>

            <Row
                className={styles.text}
                onClick={() => history.push('/account/login')}
            >
                <p className={styles.alreadyHaveAnAccount}>
                    Already have an account? &nbsp;{' '}
                    <span className={styles.link}>Sign in</span>
                </p>
            </Row>
        </div>
    )

}

export default RegisterForm;
