import React from 'react'
import Modal from 'react-responsive-modal'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import styles from './EditDocumentModal.module.scss'
import { Form, Formik } from 'formik'
import {
    IDocumentEditInformation,
    IDocumentUpdateRequest,
    IDocument,
} from '../../../types/DocumentService'
import RotatingLoader from '../RotatingLoader'
import {
    addURLParameter,
    removeURLParameter,
} from '../../../helpers/locationHelpers'
import { toggleEditDocumentPopup } from '../../../helpers/sidebarHelpers'
import {
    getEditInformation,
    updateDocument,
    searchForAssociationContact,
    searchForAssociationProperty,
    searchForAssociationTenancy,
    getAssociationItem,
} from '../../../services/DocumentService'
import {
    generateDocumentLinkText,
    getRemovedFileTypeName,
} from '../../../helpers/documentHelpers'
import SuccessTick from '../SuccessTick'
import SelectDropdown, { IOptions } from '../SelectDropdown'
import { getTypesForDropdown } from '../../../services/EntityService'
import {
    isTenant,
} from '../../../helpers/roleHelpers'
import { ISearchResult } from '../../../types/SearchService/ISearchService'
import Buttons from '../Buttons'
import { processToast } from '../../../helpers/toastHelper'
import { IAddTenantCallback } from '../../molecules/AddContactForm'
import DocumentLinks, {
    AssociationType,
} from '../../molecules/AddDocumentModal/DocumentLinks'
import EllipsisText from '../EllipsisText'
const queryString = require('query-string')

interface IProps {
    open: boolean
    onOpenModal(): void
    onCloseModal(): void

    history: any
    location: any
    match: any

    // Redux
    edit_document_popup: boolean
    documents: Array<IDocument>

    propertyId?: string
    contactId?: string
    tenancyId?: string
    issueId?: string
    unitId?: string
    transactionId?: string
}

interface IState {
    document: IDocumentEditInformation
    loading: boolean

    onSuccess: boolean
    documentTypes: Array<IOptions>

    selectedPropertyId: IOptions
    selectedContactId: ISearchResult
    selectedTenancyId: IAssociationItem
    selectedIssueId: IOptions
    selectedUnitId: IOptions
    selectedTransactionId: IOptions

    tenancyAssociationResults: Array<IAssociationItem>
    contactAssociationResults: Array<IAssociationItem>
    propertyAssociationResults: Array<IAssociationItem>

    propertyId: string
    contactId: string
    tenancyId: string
    issueId: string
    unitId: string
    transactionId: string

    addingLinks: boolean
}

interface IAssociationItem {
    type: string
    name: string
    description: string
    propertyId: string
    tenancydId: string
    contactId: string
    userId: string
    unitId: string
    transactionId: string
}

class EditDocumentModal extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            document: null,
            loading: false,
            onSuccess: false,
            documentTypes: [],
            selectedContactId: null,
            selectedPropertyId: null,
            selectedTenancyId: null,
            selectedIssueId: null,
            selectedUnitId: null,
            selectedTransactionId: null,
            contactAssociationResults: [],
            propertyAssociationResults: [],
            tenancyAssociationResults: [],
            propertyId: this.props.propertyId ? this.props.propertyId : null,
            contactId: this.props.contactId ? this.props.contactId : null,
            tenancyId: this.props.tenancyId ? this.props.tenancyId : null,
            issueId: this.props.issueId ? this.props.issueId : null,
            unitId: this.props.unitId ? this.props.unitId : null,
            transactionId: this.props.transactionId
                ? this.props.transactionId
                : null,
            addingLinks: false,
        }

        this.onClose = this.onClose.bind(this)
        this.onSave = this.onSave.bind(this)
        this.getDocumentTypes = this.getDocumentTypes.bind(this)
        this.getAssociateItems = this.getAssociateItems.bind(this)
        this.generateLinkText = this.generateLinkText.bind(this)
        this.onSelectAssociationItem = this.onSelectAssociationItem.bind(this)
    }

    generateLinkText(): string {
        return generateDocumentLinkText(
            this.state.selectedPropertyId &&
                this.state.selectedPropertyId.label,
            this.state.selectedContactId && this.state.selectedContactId.label,
            this.state.selectedIssueId && this.state.selectedIssueId.label,
            this.state.selectedTransactionId &&
                this.state.selectedTransactionId.label,
            this.state.selectedUnitId && this.state.selectedUnitId.label
        )
    }

    getDocumentTypes(): void {
        getTypesForDropdown('8a324853-7f7d-4b45-bc8e-891514de3713').then(
            (resp) => {
                if (resp && resp.status == 200 && resp.data) {
                    this.setState({
                        documentTypes: resp.data,
                    })
                }
            }
        )
    }

    componentWillReceiveProps(newProps: IProps): void {
        let next = queryString.parse(
            newProps.location.search
        ).addContactCallback

        if (next) {
            let data: IAddTenantCallback = JSON.parse(next)

            if (
                !this.state.selectedContactId ||
                data.contactId != this.state.selectedContactId.value
            ) {
                this.setState((prevState) => ({
                    selectedContactId: {
                        ...prevState.selectedContactId,
                        id: data.contactId,
                        text: data.name,
                    },
                }))

                let query = removeURLParameter(
                    this.props.location.search,
                    'addContactCallback'
                )
                this.props.history.push({
                    search: query,
                })
            }
        }
    }

    onClose(): void {
        let newParam = removeURLParameter(
            this.props.location.search,
            'editDocument'
        )
        this.props.history.push({
            search: newParam,
        })

        toggleEditDocumentPopup(false)
    }

    componentWillUnmount(): void {
        let newParam = removeURLParameter(
            this.props.location.search,
            'editDocument'
        )
        this.props.history.push({
            search: newParam,
        })

        this.setState({
            document: null,
            onSuccess: false,
        })
    }

    componentDidMount(): void {
        let editDocument = queryString.parse(
            this.props.location.search
        ).editDocument

        let removeQuery = removeURLParameter(
            this.props.location.search,
            'editDocument'
        )
        this.props.history.push({
            search: removeQuery,
        })

        let parsedEdit = JSON.parse(editDocument)

        this.setState(
            {
                propertyId: parsedEdit.propertyId,
                contactId: parsedEdit.contactId,
                issueId: parsedEdit.issueId,
                unitId: parsedEdit.unitId,
                transactionId: parsedEdit.transactionId,
            },
            () => this.getAssociateItems()
        )

        getEditInformation(parsedEdit.documentId).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                this.setState({
                    document: resp.data,
                })
            }
        })

        if (!editDocument) {
            return
        }

        getEditInformation(parsedEdit.documentId).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                this.setState({
                    document: resp.data,
                })
            }
        })

        this.getDocumentTypes()
    }

    getAssociateItems() {
        // If there is options, get the associated items from API
        if (
            this.state.propertyId &&
            this.state.propertyId != '00000000-0000-0000-0000-000000000000'
        ) {
            getAssociationItem({
                propertyId: this.state.propertyId,
            }).then((resp) => {
                if (resp && resp.status == '200' && resp.data) {
                    this.setState((prevState) => ({
                        selectedPropertyId: {
                            ...prevState.selectedPropertyId,
                            value: this.state.propertyId,
                            label: resp.data.propertyName,
                        },
                        propertyId: this.state.propertyId,
                    }))
                }
            })
        }

        if (
            this.state.contactId &&
            this.state.contactId != '00000000-0000-0000-0000-000000000000'
        ) {
            getAssociationItem({
                contactId: this.state.contactId,
            }).then((resp) => {
                if (resp && resp.status == '200' && resp.data) {
                    let contact = resp.data
                    let selected: ISearchResult = {
                        label: contact.name,
                        value: contact.contactId,
                    }
                    this.setState({
                        selectedContactId: selected,
                        contactId: this.state.contactId,
                    })
                }
            })
        }

        if (
            this.state.tenancyId &&
            this.state.tenancyId != '00000000-0000-0000-0000-000000000000'
        ) {
            getAssociationItem({
                tenancyId: this.state.tenancyId,
            }).then((resp) => {
                if (resp && resp.status == '200' && resp.data) {
                    this.setState({
                        selectedTenancyId: resp.data,
                        tenancyId: this.state.tenancyId,
                    })
                }
            })
        }

        if (
            this.state.unitId &&
            this.state.unitId != '00000000-0000-0000-0000-000000000000'
        ) {
            getAssociationItem({
                unitId: this.state.unitId,
            }).then((resp) => {
                if (resp && resp.status == '200' && resp.data) {
                    this.setState({
                        selectedUnitId: {
                            label: resp.data.unitName,
                            value: resp.data.unitId,
                        },
                        unitId: this.state.unitId,
                    })
                }
            })
        }
    }

    onSave(): void {
        let updateRequest: IDocumentUpdateRequest = {
            name: this.state.document.name,
            description: this.state.document.description,
            documentType: this.state.document.documentType,
            contactId: this.state.selectedContactId
                ? this.state.selectedContactId.value
                    ? this.state.selectedContactId.value
                    : null
                : null,
            tenancyId: this.state.selectedTenancyId
                ? this.state.selectedTenancyId.tenancydId
                    ? this.state.selectedTenancyId.tenancydId
                    : null
                : null,
            issueId: this.state.selectedIssueId
                ? this.state.selectedIssueId.value
                    ? this.state.selectedIssueId.value
                    : null
                : null,
            propertyId: this.state.selectedPropertyId
                ? this.state.selectedPropertyId.value
                    ? this.state.selectedPropertyId.value
                    : null
                : null,
            unitId: this.state.selectedUnitId
                ? this.state.selectedUnitId.value
                    ? this.state.selectedUnitId.value
                    : null
                : null,
            transactionId: this.state.selectedTransactionId
                ? this.state.selectedTransactionId.value
                    ? this.state.selectedTransactionId.value
                    : null
                : null,
        }

        updateDocument(this.state.document.documentId, updateRequest).then(
            (resp) => {
                if (resp && resp.status == 200) {
                    processToast(resp.data)

                    let newQuery = addURLParameter(
                        this.props.location.search,
                        'refreshDocumentsGrid=true'
                    )
                    this.props.history.push({
                        search: newQuery,
                    })

                    toggleEditDocumentPopup(false)
                }
            }
        )
    }

    onSearch(query: string, type: AssociationType): void {
        if (type == AssociationType.Contact) {
            searchForAssociationContact(query).then((resp) => {
                this.setState({
                    contactAssociationResults: resp.data,
                })
            })
        }

        if (type == AssociationType.Property) {
            searchForAssociationProperty(query).then((resp) => {
                this.setState({
                    propertyAssociationResults: resp.data,
                })
            })
        }

        if (type == AssociationType.Tenancy) {
            searchForAssociationTenancy(query).then((resp) => {
                this.setState({
                    tenancyAssociationResults: resp.data,
                })
            })
        }
    }

    onSelectAssociationItem(selected: any, type: AssociationType): void {
        if (type == AssociationType.Contact) {
            this.setState({
                selectedContactId: selected,
                contactAssociationResults: [],
            })
        }

        if (type == AssociationType.Issue) {
            this.setState({
                selectedIssueId: selected,
            })
        }

        if (type == AssociationType.Property) {
            this.setState({
                selectedPropertyId: selected,
                propertyAssociationResults: [],
            })
        }

        if (type == AssociationType.Unit) {
            this.setState({
                selectedUnitId: selected,
            })
        }

        if (type == AssociationType.Transaction) {
            this.setState({
                selectedTransactionId: selected,
            })
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div>
                    <RotatingLoader text="Loading document..." loading={true} />
                </div>
            )
        }

        if (!this.state.document) {
            return null
        }

        return (
            <Modal
                showCloseIcon={false}
                classNames={{ modal: styles.modal }}
                open={this.props.edit_document_popup}
                onClose={() => this.onClose()}
                center
            >
                {this.state.onSuccess ? (
                    <div className={styles.success}>
                        <SuccessTick />
                        <p>Document has been updated</p>
                    </div>
                ) : (
                    <Formik
                        initialValues={{
                            name: getRemovedFileTypeName(
                                this.state.document.name
                            ),
                            description: this.state.document.description,
                        }}
                        enableReinitialize={true}
                        validationSchema={null}
                        onSubmit={(values: any) => {
                            this.setState(
                                (prevState) => ({
                                    document: {
                                        ...prevState.document,
                                        name: values.name,
                                        description: values.description,
                                    },
                                }),
                                () => this.onSave()
                            )
                        }}
                    >
                        {(props) => (
                            <Form>
                                <div
                                    id="files"
                                    className={`${styles.row} ${styles.fileRow}`}
                                >
                                    <div
                                        className={styles.fileItem}
                                        key={`file__1`}
                                        data-file-name={
                                            this.state.document.name
                                        }
                                    >
                                        <div className={styles.innerColumn}>
                                            <div className={styles.innerRow}>
                                                <div
                                                    className={`${styles.innerColumn}`}
                                                >
                                                    <EllipsisText
                                                        text={
                                                            this.state.document
                                                                .name
                                                        }
                                                        length={15}
                                                    />
                                                </div>
                                                <div
                                                    className={styles.dropdown}
                                                >
                                                    <SelectDropdown
                                                        selectedId={
                                                            this.state.document
                                                                .documentType
                                                        }
                                                        multiSelect={false}
                                                        data={
                                                            this.state
                                                                .documentTypes
                                                        }
                                                        placeholder="Select a file type..."
                                                        onSelect={(
                                                            selected: IOptions
                                                        ) =>
                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    document: {
                                                                        ...prevState.document,
                                                                        documentType:
                                                                            selected.value,
                                                                    },
                                                                })
                                                            )
                                                        }
                                                        isSearchable={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className={styles.innerRow}>
                                                <input
                                                    placeholder="Description..."
                                                    value={
                                                        props.values.description
                                                    }
                                                    onChange={(e) =>
                                                        props.setFieldValue(
                                                            'description',
                                                            e.currentTarget
                                                                .value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {!isTenant() && (
                                    <div className={styles.links}>
                                        <div className={styles.linkHeading}>
                                            {this.generateLinkText()}
                                            <span
                                                onClick={() =>
                                                    this.setState({
                                                        addingLinks:
                                                            !this.state
                                                                .addingLinks,
                                                    })
                                                }
                                            >
                                                {this.state.addingLinks
                                                    ? 'hide'
                                                    : `${
                                                          this.generateLinkText() !=
                                                          'Not linked '
                                                              ? 'edit'
                                                              : 'add'
                                                      }`}{' '}
                                                link
                                                {this.state.addingLinks
                                                    ? 's '
                                                    : ' '}
                                            </span>
                                        </div>
                                        {this.state.addingLinks && (
                                            <DocumentLinks
                                                selectedPropertyId={
                                                    this.state
                                                        .selectedPropertyId
                                                }
                                                selectedContactId={
                                                    this.state.selectedContactId
                                                }
                                                selectedIssueId={
                                                    this.state.selectedIssueId
                                                }
                                                selectedTenancyId={
                                                    this.state.selectedTenancyId
                                                }
                                                selectedUnitId={
                                                    this.state.selectedUnitId
                                                }
                                                selectedTransactionId={
                                                    this.state
                                                        .selectedTransactionId
                                                }
                                                selectedPropertyIdHasUnits={
                                                    false
                                                }
                                                onSelect={
                                                    this.onSelectAssociationItem
                                                }
                                            />
                                        )}
                                    </div>
                                )}

                                <Buttons
                                    buttons={[
                                        {
                                            displayType: 'cancel',
                                            elementType: 'button',
                                            onClick: () => this.onClose(),
                                        },
                                        {
                                            displayType: 'submit',
                                            elementType: 'button',
                                            onClick: () => props.submitForm(),
                                        },
                                    ]}
                                />
                            </Form>
                        )}
                    </Formik>
                )}
            </Modal>
        )
    }
}

const mapStateToProps = (state: any) => ({
    edit_document_popup: state.app.popups.edit_document_popup,
    documents: state.document.document_list,
})

export default withRouter(connect(mapStateToProps)(EditDocumentModal) as any)
