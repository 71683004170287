import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import styles from "./FilterMobile.module.scss";
import { IFilterElement } from "../../../../types/ListComponents";
import tuneIcon from "../../../../images/icons/icon_filter.svg";
import closeIcon from "../../../../images/icons/ic_clear_24px.png";
import MaterialCheckbox from "../../MaterialCheckbox";

interface IProps {
  allElement?: IFilterElement;
  activeElementId: string;
  filterElements: Array<IFilterElement>;
  showFilterIcon?: boolean;
  onChangeFilter(elementId: string): void;
  toggleStatusDropdownMenu();
}

interface IState {
  status: string;
}

class FilterMobile extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      status: "All",
    };
  }
  render() {
    const {
      allElement,
      activeElementId,
      filterElements,
      onChangeFilter,
      showFilterIcon,
      toggleStatusDropdownMenu,
    } = this.props;
    return (
      <div>
        <div className={styles.sortDropdown}>
          <Row className={styles.marginBottom}>
            <Col>
              <img className={styles.sortByIconModel} src={tuneIcon} />
              <span className={styles.title}>Property Filter</span>
              <span className={styles.close} onClick={toggleStatusDropdownMenu}>
                <img src={closeIcon} alt="Close" />
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="4" className={styles.filterWindow}>
              <MaterialCheckbox
                onChange={() => onChangeFilter("all")}
                checked={activeElementId == "all"}
                labelText="All"
              />
            </Col>
            {filterElements.length > 0 &&
              filterElements.map((status, index) => {
                return (
                  <Col
                    xs="12"
                    lg="4"
                    className={styles.filterWindow}
                    key={index}
                  >
                    <MaterialCheckbox
                      onChange={() => onChangeFilter(status.id)}
                      checked={activeElementId == status.id ? true : false}
                      labelText={status.status}
                    />
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
    );
  }
}
export default FilterMobile;
