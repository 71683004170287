import React from 'react';
import TradeEmptyState from '../../../../organisms/EmptyState/TradeEmptyState/TradeEmptyState.component';
import SectionHeader from "../SectionHeader/SectionHeader.component"
import * as S from "./ThirdPartyHelpSection.styles";

interface sectionProps {
    id: string;
    header: string;
    hideOnClick(id: string): void;
}

const ThirdPartyHelpSection = ({id, header, hideOnClick}: sectionProps) => {
    return (
        <S.SectionDiv>
            <SectionHeader id={id} header={header} hideOnClick={hideOnClick}/>
            <S.bodyDiv>
                <TradeEmptyState />
            </S.bodyDiv>
        </S.SectionDiv>
    )
}

export default ThirdPartyHelpSection;