import React from "react";

interface IProps {
  address: IAddress;
  inline: boolean;
  color?: string;
  isUserAddress?: boolean;
  fontUnit?: string;
  fontSize?: number;
  fontweight?: number;
  showDefaultText?: boolean;
  editIcon?: boolean;
}

import styles from "./FormattedAddress.module.scss";
import { IAddress } from "../../../types/PropertyService/IPropertyService";
import editIcon from "../../../images/pencil_edit.png";

class FormattedAddress extends React.Component<IProps> {
  hasPrimaryAddressValues(): boolean {
    if (!this.props.address) {
      return false;
    }

    const {
      line1,
      line2,
      town,
      county,
      postcode,
      country
    } = this.props.address;
    if (line1 || line2 || town || county || postcode || country) {
      return true;
    }
    return false;
  }

  render() {
    const { address } = this.props;
    const fontSizeValues =
      this.props.fontSize && this.props.fontUnit
        ? this.props.fontSize + this.props.fontUnit
        : "1rem";

    if (this.hasPrimaryAddressValues() && !this.props.inline) {
      return (
        <div
          className={styles.formattedAddress}
          style={{
            color: this.props.color ? this.props.color : "black",
            fontSize: fontSizeValues,
            fontWeight: this.props.fontweight ? this.props.fontweight : 0
          }}
        >
          {address.line1 ? (
            <p style={{ fontSize: fontSizeValues }}>{address.line1}</p>
          ) : null}
          {address.line2 ? <p>{address.line2}</p> : null}
          {(address.street_Name && !address.line1.includes(address.street_Name) && !address.line2.includes(address.street_Name)) ? <p>{address.street_Name}</p> : null}
          {address.town ? <p>{address.town}</p> : null}
          {address.county ? <p>{address.county}</p> : null}
          {address.postcode ? (
            <p>
              {address.postcode} {this.props.editIcon && <img src={editIcon} />}
            </p>
          ) : null}
        </div>
      );
    }

    if (this.hasPrimaryAddressValues() && this.props.inline) {
      return (
        <div
          className={styles.formattedAddress}
          style={{
            color: this.props.color ? this.props.color : "black",
            fontSize: fontSizeValues,
            fontWeight: this.props.fontweight ? this.props.fontweight : 0
          }}
        >
          {address.line1 ? <span className={styles.line1}>{address.line1},&nbsp;</span> : null}
          {address.line2 ? <span>{address.line2},&nbsp;</span> : null}
          {address.street_Name && !address.line1.includes(address.street_Name) && !address.line2.includes(address.street_Name)  ? <span>{address.street_Name},&nbsp;</span> : null}
          {address.locality ? <span>{address.locality},&nbsp;</span> : null}
          {address.town ? <span>{address.town},&nbsp;</span> : null}
          {address.county ? <span>{address.county},&nbsp;</span> : null}
          {address.postcode ? (
            <span className={styles.postcode}>
              {address.postcode} {this.props.editIcon && <img src={editIcon} />}
            </span>
          ) : null}
        </div>
      );
    }

    if (!this.props.isUserAddress && this.props.showDefaultText) {
      return (
        <span>
          Please edit property to add an address{" "}
          {this.props.editIcon && <img src={editIcon} />}
        </span>
      );
    }

    if (this.props.isUserAddress && this.props.showDefaultText) {
      return (
        <span>
          Please edit your profile to add an address{" "}
          {this.props.editIcon && <img src={editIcon} />}
        </span>
      );
    }

    return null;
  }
}

export default FormattedAddress;
