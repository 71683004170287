import React from 'react'
import * as S from './MoneyCard.styles'

interface props{
    value: any;
    name: string;
    value2?: any;
    backgroundColor?: string;
    onClick?(id: string): void;
}

const MoneyCard = ({value, name, value2, backgroundColor, onClick} : props) => {

    return(
        <S.CardDiv backgroundColor={backgroundColor} onClick={() => onClick && onClick(name)}>
            <S.NumberLabel fontSize={value2 && (value != value2) ? "25px" : "30px"}>
                {value}
            </S.NumberLabel>

            {
                (value2 && (value != value2)) &&
                <S.NameLabel>/ {value2}</S.NameLabel>
            }
            <S.NameLabel>{name}</S.NameLabel>
        </S.CardDiv>
    )
}

export default MoneyCard;