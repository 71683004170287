import React from "react";
import { connect } from "react-redux";
import styles from "./Alert.module.scss";
import { updateAlertMessage } from "../../../helpers/alertHelpers";

interface IProps {
  alert_text: string;
  is_error: boolean;
  auto_hide: boolean;
  hide_after_milliseconds: number;
}

interface IState {
  isSticky: boolean;
}

class Alert extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isSticky: false
    }

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount(): void {
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillReceiveProps(newProps: IProps): void {
    let autoHide: boolean = newProps.auto_hide == null ? true : newProps.auto_hide;
    if (autoHide) {
      let hideAfterTime: number = newProps.hide_after_milliseconds == null ? 3000 : newProps.hide_after_milliseconds
      if (newProps.alert_text != this.props.alert_text) {
        setTimeout(() => {
          updateAlertMessage("", false);
        }, hideAfterTime);
      }
    }
  }

  handleScroll = () => {
    if (window.pageYOffset >= 100 && this.state.isSticky) {
      return;
    }

    if (window.pageYOffset >= 100 && !this.state.isSticky) {
      this.setState({
        isSticky: true
      });
    }

    if (window.pageYOffset < 100 && this.state.isSticky) {
      this.setState({
        isSticky: false
      });
    }
  };

  render() {
    if (!this.props.alert_text) {
      return null;
    }

    return (
      <div className={`${styles.alert} 
      ${this.state.isSticky ? styles.isScrolled : ''}
      ${this.props.is_error ? styles.isError : styles.isSuccess}
      `}>
        {this.props.alert_text}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  alert_text: state.alert.alert_text,
  is_error: state.alert.is_error,
  auto_hide: state.alert.auto_hide,
  hide_after_milliseconds: state.alert.hide_after_milliseconds
});

export default connect(
  mapStateToProps,
)(Alert);