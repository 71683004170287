import React from 'react'
import Modal from 'react-responsive-modal'
import { withRouter } from 'react-router-dom'
import styles from './EditStreetViewModal.module.scss'
import StreetView from '../StreetView'
import { IAddress } from '../../../types/PropertyService/IPropertyService'
import { saveStreetViewStaticImage } from '../../../services/GoogleService'
import cameraIcon from '../../../images/icons/camera_streetview.svg'
import RotatingLoader from '../RotatingLoader'
import SuccessTick from '../SuccessTick'
import { delayAction } from '../../../helpers/timerHelpers'
import { updateToastMessage } from '../../../helpers/toastHelper'
import Buttons from '../Buttons'

interface IProps {
    history: any
    location: any
    match: any
    open: boolean
    address: IAddress
    propertyId: string
    onUploadSuccessCallback?(url: any): void
    onCloseCallback(): void
}

interface IState {
    lat: number
    lng: number
    heading: number

    isLoading: boolean
    isSuccess: boolean
}

class EditStreetViewModal extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            lat: 0,
            lng: 0,
            heading: 0,

            isLoading: false,
            isSuccess: false,
        }

        this.onPositionChanged = this.onPositionChanged.bind(this)
        this.onSave = this.onSave.bind(this)
    }

    componentDidMount(): void {}

    onSave(): void {
        this.setState({
            isLoading: true,
        })

        saveStreetViewStaticImage({
            lat: this.state.lat,
            lng: this.state.lng,
            heading: this.state.heading,
            propertyId: this.props.propertyId,
        }).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                updateToastMessage(
                    'Street View image has been updated',
                    'success'
                )
                this.props.onUploadSuccessCallback(resp.data)
                this.props.onCloseCallback()
            }
            this.setState({
                isLoading: false,
            })
        })
    }

    onPositionChanged(lat: number, lng: number, heading: number): void {
        this.setState({
            lat,
            lng,
            heading,
        })
    }

    render() {
        return (
            <Modal
                classNames={{ modal: styles.modal }}
                open={this.props.open}
                onClose={() => this.props.onCloseCallback()}
                center
            >
                <div className={styles.content}>
                    <p className={styles.header}>Edit Street View Image</p>

                    {this.state.isLoading ? (
                        <RotatingLoader
                            loading={true}
                            text="Updating Street View"
                        />
                    ) : this.state.isSuccess ? (
                        <div>
                            <SuccessTick />
                            <p>Street View Image has been updated</p>
                        </div>
                    ) : (
                        <div>
                            <div className={styles.mapWrapper}>
                                <StreetView
                                    className={styles.streetView}
                                    onPositionChangeCallback={
                                        this.onPositionChanged
                                    }
                                    canMove
                                    address={this.props.address}
                                />
                            </div>

                            <div className={styles.actions}>
                                <Buttons
                                    buttons={[
                                        {
                                            displayType: 'cancel',
                                            elementType: 'button',
                                            onClick: () =>
                                                this.props.onCloseCallback(),
                                        },
                                        {
                                            displayType: 'submit',
                                            elementType: 'button',
                                            onClick: () => this.onSave(),
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        )
    }
}

export default withRouter(EditStreetViewModal)
