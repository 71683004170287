import React from 'react'
import Button from './Button'
import styles from './Buttons.module.scss'
import { ButtonType } from './Buttons.types'

interface IProps {
    buttons: ButtonType[]
    align?: 'left' | 'right' | 'center'
}

const Buttons = ({ buttons, align }: IProps) => {
    if (!buttons) {
        return <></>
    }

    return (
        <div
            className={`${styles.buttons} ${
                align ? styles[align] : styles.right
            }`}
        >
            {buttons
                .filter((x) => !x.hidden)
                .filter((x) => x.visible || x.visible === undefined)
                .map((b) => (
                    <Button button={b} />
                ))}
        </div>
    )
}

export default Buttons
