import { store } from '../store'
import {
    IChatItem,
    IMessageListItem,
    IGetMessageResponse,
    INewMessageResponse,
    getUnreadMessages,
} from '../services/ChatService'
import { IStickyChatItem, IChatListUpdateItem } from '../types/ChatService'
import { updateUnreadMessageCount } from './notificationHelpers'
import { NewChatMessage_ChatSpecific } from '../configuration/signalRConfig'

export function updateChatList(data: Array<IMessageListItem>) {
    store.dispatch({
        type: 'UPDATE_CHAT_LIST',
        data,
    })
}

export function updateViewingChat(data: IGetMessageResponse) {
    store.dispatch({
        type: 'UPDATE_VIEWING_CHAT',
        data,
    })
}

export function removeChatItemFromChatList(sessionId: string) {
    store.dispatch({
        type: 'REMOVE_ITEM_FROM_CHAT_LIST',
        data: sessionId,
    })
}

export function updateChatListItemWithLatestChat(
    newMsg: IMessageListItem,
    sessionId: string,
    isLoggedInUser?: boolean
) {
    store.dispatch({
        type: 'UPDATE_CHAT_LIST_ITEM_WITH_LATEST_CHAT',
        data: {
            newMsg: newMsg,
            curSessionId: sessionId,
            isLoggedInUser: isLoggedInUser
        },
    })
}

export function addChatToChatList(data: IMessageListItem) {
    store.dispatch({
        type: 'ADD_ITEM_FROM_CHAT_LIST',
        data,
    })
}

export function updateActiveChatSessionId(chatSessionId: string) {
    store.dispatch({
        type: 'UPDATE_ACTIVE_CHAT_SESSION_ID',
        data: chatSessionId,
    })
}

export function addMessageToCurrentChat(data: IChatItem) {
    store.dispatch({
        type: 'ADD_VIEWING_CHAT_MESSAGES',
        data,
    })
}

export function updateMessageToCurrentChat(data: IChatItem) {
    store.dispatch({
        type: 'UPDATE_VIEWING_CHAT_MESSAGES',
        data,
    })
}

export function addToStickyChat(data: IStickyChatItem) {
    let current = store.getState().chat.sticky_chat

    if (current) {
        let exists = current.findIndex(
            (x) => x.chatSessionId == data.chatSessionId
        )
        if (exists > -1) {
            current[exists].open = true

            store.dispatch({
                type: 'UPDATE_STICKY_CHAT',
                data: current,
            })
            return
        }

        store.dispatch({
            type: 'UPDATE_STICKY_CHAT',
            data: current.concat([data]),
        })

        return
    }

    store.dispatch({
        type: 'UPDATE_STICKY_CHAT',
        data: [data],
    })
}

export function toggleShowHideStickyChatItem(
    chatSessionId: string,
    show: boolean
) {
    if (!store.getState().chat.sticky_chat) {
        return
    }

    let current = store.getState().chat.sticky_chat.slice()

    if (!current) {
        return
    }

    let exists = current.findIndex((x) => x.chatSessionId == chatSessionId)
    if (exists > -1) {
        current[exists].open = show

        store.dispatch({
            type: 'UPDATE_STICKY_CHAT',
            data: current,
        })
        return
    }
}

export function removeStickyChatItem(chatSessionId: string) {
    let current = store.getState().chat.sticky_chat
    store.dispatch({
        type: 'UPDATE_STICKY_CHAT',
        data: current.find((x) => x.chatSessionId != chatSessionId),
    })
}

export function toggleStickyChat(show: boolean) {
    store.dispatch({
        type: 'TOGGLE_STICKY_CHAT',
        data: show,
    })
}

export function processSignalRMessage(
    msg: INewMessageResponse,
    sessionId: string,
    callback?: () => void
) {
    updateChatListItemWithLatestChat(msg.listItem, sessionId)
    addMessageToCurrentChat(msg.chatMessage)

    if (callback) {
        callback()
    }
}

export function removeUnreadMessageCount(howManyToRemove: number): void {
    let currentUnreadCount = store.getState().notifications.unreadChatCount
    if (howManyToRemove > currentUnreadCount) {
        return
    }

    updateUnreadMessageCount(currentUnreadCount - howManyToRemove)
}

export function clearUnreadChatMessagesSessionSpecific(
    chatSessionId: string
): void {
    let copy = store.getState().chat.chat_list.map((object) => ({ ...object }))
    let filtered = copy.findIndex((x) => x.chatSessionId == chatSessionId)
    if (filtered < 0) {
        return
    }

    copy[filtered].unreadMessageCount = 0
    updateChatList(copy)
}

export function doesChatSessionHaveUnreadMessages(
    chatSessionId: string
): boolean {
    let chatList = store.getState().chat.chat_list
    let filtered = chatList.findIndex((x) => x.chatSessionId == chatSessionId)

    if (filtered < 0) {
        return false
    }

    return chatList[filtered].unreadMessageCount > 0
}

export function checkForUnreadMessages(): void {
    getUnreadMessages().then((resp) => {
        if (resp && resp.status == 200) {
            let data: Array<any> = resp.data
            updateUnreadMessageCount(data.length)
        }
    })
}

export function getUnreadChatCountFromState(): number {
    return store.getState().notifications.unreadChatCount
}

export function isActiveHandlerForChatSession(chatSessionId: string): boolean {
    let filter = store
        .getState()
        .app.websocketHandlers.findIndex(
            (x) => x.handlerName == NewChatMessage_ChatSpecific(chatSessionId)
        )
    return filter > -1
}

export function updateFooterVisibility(showFooter: boolean) {
    store.dispatch({
        type: 'APP_TOGGLE_FOOTER',
        data: showFooter,
    })
}

export function inputFocussed(inputFocussed: boolean) {
    store.dispatch({
        type: 'APP_INPUT_FOCUSSED',
        data: inputFocussed,
    })
}

export function updateChatAreaHeight(chatAreaHeight: number) {
    store.dispatch({
        type: 'APP_CHAT_AREA_HEIGHT',
        data: chatAreaHeight,
    })
}