import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import lodashObject from 'lodash/fp'
import React, { Component } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import { FormGroup } from 'reactstrap'
import {
    CommercialPropertyEntityId,
    FlatPropertyEntityId,
    FlatTypeGroupId,
    FurnishedTypeGroupId,
    LeaseholdId,
    ParkingArragementsEntityId,
    PropertyCommercialTypeGroupId,
    PropertyResidentialTypeGroupId,
    PropertySizeTypeGroupId,
    PropertyTypeGroupId,
    ResidentialPropertyEntityId,
    RoofMaterialEntityId,
    TenureId,
    WallsConstructionId,
} from '../../../../../configuration/appConfig'
import {
    addURLParameter,
    removeURLParameter,
} from '../../../../../helpers/locationHelpers'
import {
    canSelectNumberOfSpaces,
    updateComposition,
    updatePropertyTypes,
} from '../../../../../helpers/propertyHelpers'
import { toggleAddDocumentPopup } from '../../../../../helpers/sidebarHelpers'
import { updateToastMessage } from '../../../../../helpers/toastHelper'
import govImage from '../../../../../images/gov.png'
import regIcon from '../../../../../images/icons/icon_reg.svg'
import { getCouncilList } from '../../../../../services/CouncilService'
import {
    getTypes,
    getTypesForDropdown,
} from '../../../../../services/EntityService'
import {
    getPropertyDetails,
    updatePropertyComposition,
} from '../../../../../services/PropertyDetail'
import { IEntitySelection } from '../../../../../types/EntityService'
import {
    IPropertyDetail,
    IUpdatePropertyCompositionRequest,
} from '../../../../../types/PropertyDetailService'
import AddDocumentButton from '../../../../atoms/AddDocumentButton'
import AssociatedDocuments from '../../../../atoms/AssociatedDocuments'
import Buttons from '../../../../atoms/Buttons'
import CouncilInformationPopup from '../../../../atoms/CouncilInformationPopup'
import CurrencyInput from '../../../../atoms/CurrencyInput'
import OutstandingChangesModal from '../../../../atoms/OutstandingChangesModal'
import RotatingLoader from '../../../../atoms/RotatingLoader'
import SelectDropdown, { IOptions } from '../../../../atoms/SelectDropdown'
import StickyComponent from '../../../../atoms/StickyComponent'
import { IDocumentUploadOptions } from '../../../../molecules/AddDocumentModal'
import styles from './PropertyCompositionPage.module.scss'

const queryString = require('query-string')

interface IProps {
    history: any
    match: any
    location: any
}

interface IReduxProps {
    add_document_popup: boolean
}

type Props = IProps & IReduxProps

interface IPropertyDetails {
    propertyType: string
    residentialType: string
    commercialType: string
    outdoorSpaceType: string
    finishQualityType: string
    flatType: string
    tenure: string
    serviceCharge: number
    groundRent: number
    managementCompany: string
    bedrooms: number
    bathrooms: number
    receptions: number
    kitchens: number
    floors: number
    furnished: string
    parking: string
    numberOfParkingSpaces: number
    yearBuilt: number
    size: number
    sizeScale: string
    roofMaterial: string
    wallsConstruction: string
    epcRating: string
    council: string
    councilTaxBanding: string
}

export interface ICouncilListItem {
    councilId: string
    dateAdded: string
    dateAmended: string
    councilCode: string
    region: string
    laType: string
    name: string
    officialName: string
    laD18CD: string
    webMain: string
    webCouncilTax: string
    webLandlord: string
    webHMOArticle: string
    webHMO: string
}

interface IPropertyFeatures {
    fridge: boolean
    freezer: boolean
    washingMachine: boolean
    tumbleDryer: boolean
    dishwasher: boolean
    cooker: boolean
    hob: boolean
    microwave: boolean
    airConditioner: boolean
    smartMeters: boolean
}

interface IState {
    propertyDetails: IPropertyDetails
    propertyFeatures: IPropertyFeatures

    councilList: IOptions[]
    councilId: string

    loading: boolean
    success: boolean
    open: boolean
    scrolledToBottom: boolean

    // Dropdown Options
    propertyTypes: Array<IOptions>
    tenureTypes: Array<IOptions>
    residentialTypes: Array<IOptions>
    commercialTypes: Array<IOptions>
    flatTypes: Array<IOptions>
    furnishedTypes: Array<IOptions>
    parkingTypes: Array<IOptions>
    roofTypes: Array<IOptions>
    wallsTypes: Array<IOptions>
    sizeTypes: Array<IOptions>
    finishQuality: Array<IOptions>
    outdoorSpace: Array<IOptions>

    councilOfficialName: string
    councilLinksHomePage: string
    councilLinksTaxInformation: string
    councilLinksLandlordInformation: string
    councilLinksHMOInformation: string

    councilDataList: ICouncilListItem[]

    outstandingChanges: boolean
    outstandingChangesModalOpen: boolean

    isLoaded: boolean
}

let initialState: any = {
    propertyDetails: {
        propertyType: '',
        residentialType: '',
        commercialType: '',
        flatType: '',
        tenure: '',

        serviceCharge: 0,
        groundRent: 0,
        managementCompany: '',

        bedrooms: 0,
        bathrooms: 0,
        receptions: 0,
        kitchens: 0,
        floors: 0,
        furnished: '',
        parking: '',

        yearBuilt: 0,
        size: 0,
        sizeScale: null,
        roofMaterial: '',
        wallsConstruction: '',
        epcRating: '',

        council: '',
        councilTaxBanding: '',
    } as IPropertyDetails,
    propertyFeatures: {} as IPropertyFeatures,
}

class PropertyCompositionPage extends Component<Props, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            ...initialState,
            outstandingChanges: false,
            outstandingChangesModalOpen: false,
            councilList: [],
            councilDataList: [],
            councilId: '',

            loading: false,
            isLoaded: false,
            success: false,
            open: false,
            scrolledToBottom: false,

            // Dropdown options
            propertyTypes: [],
            tenureTypes: [],
            residentialTypes: [],
            commercialTypes: [],
            flatTypes: [],
            furnishedTypes: [],
            parkingTypes: [],
            roofTypes: [],
            wallsTypes: [],
            sizeTypes: [],
            finishQuality: [],
            outdoorSpace: [],
            councilOfficialName: '',
            councilLinksHomePage: '',
            councilLinksTaxInformation: '',
            councilLinksLandlordInformation: '',
            councilLinksHMOInformation: '',
        }

        this.bindEntities = this.bindEntities.bind(this)
        this.handleEntitySelection = this.handleEntitySelection.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.onUpdate = this.onUpdate.bind(this)
        this.isLoading = this.isLoading.bind(this)
        this.getDetails = this.getDetails.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.onSuccess = this.onSuccess.bind(this)
        this.onPropertyDetailsChange = this.onPropertyDetailsChange.bind(this)
        this.onPropertyDetailsChangeLimited =
            this.onPropertyDetailsChangeLimited.bind(this)
        this.onPropertyDetailsChangeSizeLimited =
            this.onPropertyDetailsChangeSizeLimited.bind(this)
        this.onCheckChangePropertyFeatures =
            this.onCheckChangePropertyFeatures.bind(this)
        this.handleChangeCurrencyInput =
            this.handleChangeCurrencyInput.bind(this)
        this.onAddDocumentClick = this.onAddDocumentClick.bind(this)
        this.getSizeTypeFriendlyText = this.getSizeTypeFriendlyText.bind(this)
        this.checkForChanges = this.checkForChanges.bind(this)
        this.onCloseChangesModal = this.onCloseChangesModal.bind(this)
    }

    onCloseChangesModal(submitCancel: boolean): void {
        this.setState({
            outstandingChangesModalOpen: false,
        })

        if (submitCancel) {
            this.setState({
                outstandingChanges: false,
            })
            this.props.history.push(
                `/dashboard/property/${this.props.match.params.propertyId}/summary`
            )
        }
    }

    checkForChanges(): boolean {
        let current = {
            propertyDetails: {
                ...this.state.propertyDetails,
            },
            propertyFeatures: {
                ...this.state.propertyFeatures,
            },
        }

        if (!lodashObject.isEqual(initialState, current)) {
            return true
        }

        return false
    }

    async bindEntities(groupId: string, field: string): Promise<void> {
        getTypes(groupId).then((resp) => {
            if (!resp && !resp.data) {
                return
            }
            let entityItems: Array<IEntitySelection> = []
            resp.data.forEach((element) => {
                let EntityItem: IEntitySelection = {
                    isSelected: false,
                    EntityItem: element,
                }
                entityItems.push(EntityItem)
            })
            this.setState({ [field]: entityItems } as Pick<IState, any>)
        })
    }

    async getEntityTypes(type: string, field: string): Promise<void> {
        getTypesForDropdown(type).then((resp) => {
            if (resp && resp.data) {
                this.setState(
                    { [field]: resp.data } as Pick<IState, any>,
                    () => {
                        if (field == 'sizeTypes') {
                            this.getSizeTypeFriendlyText()
                        }
                    }
                )
            }
        })
    }

    componentDidMount() {
        this.getEntityTypes(
            PropertyResidentialTypeGroupId,
            'resedentialTypes'
        ).then(() => {
            this.getEntityTypes(FlatTypeGroupId, 'flatTypes')
            this.getEntityTypes(PropertyTypeGroupId, 'propertyTypes')
            this.getEntityTypes(FurnishedTypeGroupId, 'furnishedTypes')
            this.getEntityTypes(ParkingArragementsEntityId, 'parkingTypes')
            this.getEntityTypes(RoofMaterialEntityId, 'roofTypes')
            this.getEntityTypes(WallsConstructionId, 'wallsTypes')
            this.getEntityTypes(TenureId, 'tenureTypes')
            this.getEntityTypes(
                PropertyResidentialTypeGroupId,
                'residentialTypes'
            )
            this.getEntityTypes(
                PropertyCommercialTypeGroupId,
                'commercialTypes'
            )
            this.getEntityTypes(PropertySizeTypeGroupId, 'sizeTypes')
            this.getEntityTypes(
                '2DBB47A7-A2D4-4B5A-B6E2-12DCDE270BE4',
                'finishQuality'
            )
            this.getEntityTypes(
                'C22896A7-8470-48D7-9D01-F9991F5CC4DC',
                'outdoorSpace'
            )
            this.getDetails()
            this.getCouncilList()
        })

        document.addEventListener('scroll', this.checkScroll)

        this.setState({
            isLoaded: true,
        })
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.checkScroll)
    }

    getSizeTypeFriendlyText() {
        for (let i = 0; i < this.state.sizeTypes.length; i++) {
            let sizeType = this.state.sizeTypes[i].label

            if (sizeType == 'Sq Foot') {
                sizeType = 'SQFT'
            }

            if (sizeType == 'Sq Metre') {
                sizeType = 'SQM'
            }

            this.state.sizeTypes[i].label = sizeType
        }
    }

    getCouncilList() {
        getCouncilList().then((resp) => {
            let respArray: Array<IOptions> = new Array<IOptions>(
                resp.data.length
            )
            this.setState({
                councilDataList: resp.data,
            })

            for (let i = 0; i < resp.data.length; i++) {
                let newVal: IOptions = {
                    label: '',
                    value: '',
                }
                newVal.label = resp.data[i].officialName
                    ? resp.data[i].officialName
                    : 'Error'
                newVal.value = resp.data[i].councilId
                    ? resp.data[i].councilId
                    : '00000000-0000-0000-0000-000000000000'
                respArray[i] = newVal
            }

            respArray.sort(function (a, b) {
                if (a.label < b.label) {
                    return -1
                }
                if (a.label > b.label) {
                    return 1
                }
                return 0
            })

            this.setState({ councilList: respArray })
        })
    }

    onUpdate(): void {
        let propertyId = this.props.match.params.propertyId

        if (!propertyId) {
            return
        }

        let request: IUpdatePropertyCompositionRequest = {
            propertyId: propertyId,

            // Property Details
            propertyTypeId: this.state.propertyDetails.propertyType || null,
            residentialTypeId:
                this.state.propertyDetails.residentialType || null,
            commercialTypeId: this.state.propertyDetails.commercialType || null,
            flatTypeId: this.state.propertyDetails.flatType || null,
            tenureId: this.state.propertyDetails.tenure || null,
            finishQualityTypeId:
                this.state.propertyDetails.finishQualityType || null,
            outdoorSpaceTypeId:
                this.state.propertyDetails.outdoorSpaceType || null,

            serviceCharge: this.state.propertyDetails.serviceCharge,
            groundRent: this.state.propertyDetails.groundRent,
            managementCompany: this.state.propertyDetails.managementCompany,

            numberOfBathrooms: this.state.propertyDetails.bathrooms,
            numberOfBedrooms: this.state.propertyDetails.bedrooms,
            numberOfFloors: this.state.propertyDetails.floors,
            numberOfKitchens: this.state.propertyDetails.kitchens,
            numberOfReceptions: this.state.propertyDetails.receptions,

            furnishedId: this.state.propertyDetails.furnished || null,
            parkingId: this.state.propertyDetails.parking || null,
            numberOfParkingSpaces:
                this.state.propertyDetails.numberOfParkingSpaces,

            yearBuilt: this.state.propertyDetails.yearBuilt,
            size: this.state.propertyDetails.size,
            sizeScale: this.state.propertyDetails.sizeScale || null,
            roofId: this.state.propertyDetails.roofMaterial || null,
            wallsId: this.state.propertyDetails.wallsConstruction || null,
            epcRating: this.state.propertyDetails.epcRating,

            council: this.state.propertyDetails.council,
            councilTaxBanding: this.state.propertyDetails.councilTaxBanding,

            // Property Features
            fridge: this.state.propertyFeatures.fridge,
            freezer: this.state.propertyFeatures.freezer,
            washingMachine: this.state.propertyFeatures.washingMachine,
            tumbleDryer: this.state.propertyFeatures.tumbleDryer,
            dishwasher: this.state.propertyFeatures.dishwasher,
            cooker: this.state.propertyFeatures.cooker,
            hob: this.state.propertyFeatures.hob,
            microwave: this.state.propertyFeatures.microwave,
            airConditioner: this.state.propertyFeatures.airConditioner,
            smartMeters: this.state.propertyFeatures.smartMeters,
        }

        this.isLoading(true)
        updatePropertyComposition(request).then((resp) => {
            if (resp.status == 200) {
                this.isLoading(false)
                this.onSuccess()

                let propertyTypeLabel = this.state.propertyTypes.findIndex(
                    (x) => x.value == this.state.propertyDetails.propertyType
                )

                updatePropertyTypes({
                    propertyType: this.state.propertyDetails.propertyType,
                    residentialType: this.state.propertyDetails.residentialType,
                    commercialType: this.state.propertyDetails.commercialType,
                })

                updateComposition({
                    yearBuilt: this.state.propertyDetails.yearBuilt,
                    bedrooms: this.state.propertyDetails.bedrooms,
                    kitchens: this.state.propertyDetails.kitchens,
                    bathrooms: this.state.propertyDetails.bathrooms,
                    receptions: this.state.propertyDetails.receptions,
                    propertyType: this.state.propertyTypes[propertyTypeLabel]
                        ? this.state.propertyTypes[propertyTypeLabel].label
                        : null,
                    epcRating: this.state.propertyDetails.epcRating,
                })

                let propertyId = this.props.match.params.propertyId
                this.props.history.push(
                    `/dashboard/property/${propertyId}/summary`
                )
            }
        })
    }

    isLoading(loading: boolean): void {
        this.setState({
            loading,
        })
    }

    onSuccess(): void {
        updateToastMessage('Property Details updated', 'success')
    }

    getDetails() {
        let propertyId = this.props.match.params.propertyId
        if (!propertyId) {
            return
        }

        this.isLoading(true)
        getPropertyDetails(propertyId).then((resp) => {
            if (!resp && !resp.data) {
                return
            }
            let data: IPropertyDetail = resp.data

            let response = {
                propertyDetails: {
                    ...this.state.propertyDetails,
                    finishQualityType: data.finishQualityType
                        ? data.finishQualityType.entityTypeId
                        : '',
                    outdoorSpaceType: data.outdoorSpaceType
                        ? data.outdoorSpaceType.entityTypeId
                        : '',
                    propertyType: data.propertyType
                        ? data.propertyType.entityTypeId
                        : '',
                    residentialType: data.residentialType
                        ? data.residentialType.entityTypeId
                        : '',
                    commercialType: data.commercialType
                        ? data.commercialType.entityTypeId
                        : '',
                    flatType: data.flatType ? data.flatType.entityTypeId : '',
                    tenure: data.tenure ? data.tenure.entityTypeId : '',

                    serviceCharge: data.serviceCharge,
                    groundRent: data.groundRent,
                    managementCompany: data.managementCompany,

                    bedrooms: data.beds,
                    bathrooms: data.baths,
                    receptions: data.receptions,
                    kitchens: data.kitchens,
                    floors: data.floors,
                    furnished: data.furnished
                        ? data.furnished.entityTypeId
                        : '',
                    parking: data.parking ? data.parking.entityTypeId : '',
                    numberOfParkingSpaces: data.numberOfParkingSpaces,
                    yearBuilt: data.yearBuild,
                    size: data.size ? data.size : 0,
                    sizeScale: data.sizeType ? data.sizeType : '',
                    roofMaterial: data.roof ? data.roof.entityTypeId : '',
                    wallsConstruction: data.walls
                        ? data.walls.entityTypeId
                        : '',
                    epcRating: data.epcRating,

                    council: data.council,
                    councilTaxBanding: data.councilTaxBanding,
                },
                propertyFeatures: {
                    ...this.state.propertyFeatures,
                    fridge: data.fridge,
                    freezer: data.freezer,
                    washingMachine: data.washingMachine,
                    tumbleDryer: data.tumbleDryer,
                    dishwasher: data.dishwasher,
                    cooker: data.cooker,
                    hob: data.hob,
                    microwave: data.microwave,
                    airConditioner: data.airConditioner,
                    smartMeters: data.smartMeters,
                },
            }

            initialState = response
            this.setState({
                ...this.state,
                ...response,
            })

            this.setState({
                loading: false,
            })
        })
    }

    handleEntitySelection(selected: string, name: string) {
        this.setState({ [name]: selected } as Pick<IState, any>)
    }

    handleSelect(selected: IOptions, name: string) {
        this.setState({ [name]: selected.value } as Pick<IState, any>)
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const field = event.currentTarget.name
        const value: string = event.currentTarget.value
        this.setState({ [field]: value } as Pick<IState, any>)
    }

    onPropertyDetailsChange(event: React.FormEvent<HTMLInputElement>) {
        const field = event.currentTarget.name
        const value: string = event.currentTarget.value

        this.setState((prevState) => ({
            propertyDetails: {
                ...prevState.propertyDetails,
                [field]: value,
            },
        }))
    }

    onPropertyDetailsChangeLimited(event: React.FormEvent<HTMLInputElement>) {
        const field = event.currentTarget.name

        let value = event.currentTarget.value

        if (value.length > 4) value = value.slice(0, 4)

        this.setState((prevState) => ({
            propertyDetails: {
                ...prevState.propertyDetails,
                [field]: value,
            },
        }))
    }

    onPropertyDetailsChangeSizeLimited(
        event: React.FormEvent<HTMLInputElement>
    ) {
        const field = event.currentTarget.name

        let value = event.currentTarget.value

        if (value.length > 6) value = value.slice(0, 6)

        this.setState((prevState) => ({
            propertyDetails: {
                ...prevState.propertyDetails,
                [field]: value,
            },
        }))
    }

    onCheckChangePropertyFeatures(field: string): void {
        this.setState((prevState) => ({
            propertyFeatures: {
                ...prevState.propertyFeatures,
                [field]: !this.state.propertyFeatures[field],
            },
        }))
    }

    handleChangeCurrencyInput(event, maskedvalue, floatvalue) {
        const field = event.target['name']
        this.setState((prevState) => ({
            propertyDetails: {
                ...prevState.propertyDetails,
                [field]: floatvalue,
            },
        }))
    }

    setPropertyType(id: IOptions) {
        this.setState((prevState) => ({
            ...prevState,
            propertyDetails: {
                ...prevState.propertyDetails,
                propertyType: id.value,
            },
        }))

        if (
            id.value.toUpperCase() === CommercialPropertyEntityId.toUpperCase()
        ) {
            this.setState((prevState) => ({
                ...prevState,
                propertyDetails: {
                    ...prevState.propertyDetails,
                    residentialType: '',
                    flatType: '',
                },
            }))
        }

        if (
            id.value.toUpperCase() === ResidentialPropertyEntityId.toUpperCase()
        ) {
            this.setState((prevState) => ({
                ...prevState,
                propertyDetails: {
                    ...prevState.propertyDetails,
                    commercialType: '',
                },
            }))
        }
    }

    onAddDocumentClick(name: string, documentTypeId?: string): void {
        let propertyId = this.props.match.params.propertyId.toString()

        if (!propertyId) {
            return
        }

        let options: IDocumentUploadOptions = {
            propertyId: propertyId,
            dialogueTitle: name,
        }

        if (documentTypeId) {
            options.documentTypeId = documentTypeId
        }

        let query = 'uploadDocumentOptions=' + JSON.stringify(options)

        let search = addURLParameter(this.props.location.search, query)

        this.props.history.push({
            search,
        })

        toggleAddDocumentPopup(true)
    }

    getCouncilId() {
        var id = ''
        for (let i = 0; i < this.state.councilList.length; i++) {
            if (
                this.state.councilList[i].label ==
                this.state.propertyDetails.council
            ) {
                id = this.state.councilList[i].value
            }
        }
        return id
    }

    setCouncilLinks(councilName) {
        if (councilName == '' || councilName == null) return

        for (let i = 0; i < this.state.councilDataList.length; i++) {
            if (this.state.councilDataList[i].officialName == councilName) {
                this.setState({
                    councilOfficialName:
                        this.state.councilDataList[i].officialName,
                    councilLinksHomePage: this.state.councilDataList[i].webMain,
                    councilLinksTaxInformation:
                        this.state.councilDataList[i].webCouncilTax,
                    councilLinksLandlordInformation:
                        this.state.councilDataList[i].webLandlord,
                    councilLinksHMOInformation:
                        this.state.councilDataList[i].webHMOArticle,
                })
            }
        }
    }

    isBottom() {
        return (
            window.innerHeight + document.documentElement.scrollTop >=
            document.body.offsetHeight - 140
        )
    }

    checkScroll = () => {
        var bottom = this.isBottom()
        if (this.state.scrolledToBottom != bottom) {
            this.setState({ scrolledToBottom: bottom })
        }
    }

    render() {
        const hasData =
            this.state.propertyTypes &&
            this.state.propertyTypes.length > 0 &&
            this.state.tenureTypes &&
            this.state.tenureTypes.length > 0 &&
            this.state.residentialTypes &&
            this.state.residentialTypes.length > 0 &&
            this.state.commercialTypes &&
            this.state.commercialTypes.length > 0 &&
            this.state.flatTypes &&
            this.state.flatTypes.length > 0 &&
            this.state.furnishedTypes &&
            this.state.furnishedTypes.length > 0 &&
            this.state.parkingTypes &&
            this.state.parkingTypes.length > 0 &&
            this.state.roofTypes &&
            this.state.roofTypes.length > 0 &&
            this.state.wallsTypes &&
            this.state.wallsTypes.length > 0
        this.state.sizeTypes && this.state.sizeTypes.length > 0

        if (this.state.loading) {
            return (
                <RotatingLoader
                    text="Loading your property details..."
                    loading={true}
                />
            )
        }

        if (!hasData) {
            return null
        }

        return (
            <div className={styles.page}>
                <OutstandingChangesModal
                    open={this.state.outstandingChangesModalOpen}
                    onCloseChangesModal={this.onCloseChangesModal}
                />
                <div className={styles.sectionTitle}>Property Details</div>
                <div className={styles.rowJustifyLeft}>
                    <div className={styles.column}>
                        <FormGroup className={styles.flexColumn}>
                            <div className={styles.label}>Property Type</div>
                            <SelectDropdown
                                data={this.state.propertyTypes}
                                selectedId={
                                    this.state.propertyDetails.propertyType
                                }
                                placeholder="Property type..."
                                onSelect={(id: IOptions) =>
                                    this.setPropertyType(id)
                                }
                            />
                        </FormGroup>
                    </div>
                    {this.state.propertyDetails.propertyType.toUpperCase() ==
                        ResidentialPropertyEntityId.toUpperCase() && (
                        <div className={styles.column}>
                            <FormGroup className={styles.flexColumn}>
                                <div className={styles.label}>
                                    Residential Type
                                </div>
                                <SelectDropdown
                                    data={this.state.residentialTypes}
                                    selectedId={
                                        this.state.propertyDetails
                                            .residentialType
                                    }
                                    placeholder="Residential type..."
                                    onSelect={(id: IOptions) =>
                                        this.setState((prevState) => ({
                                            ...prevState,
                                            propertyDetails: {
                                                ...prevState.propertyDetails,
                                                residentialType: id.value,
                                            },
                                        }))
                                    }
                                />
                            </FormGroup>
                        </div>
                    )}

                    {this.state.propertyDetails.propertyType.toUpperCase() ==
                        CommercialPropertyEntityId.toUpperCase() && (
                        <div className={styles.column}>
                            <FormGroup className={styles.flexColumn}>
                                <div className={styles.label}>
                                    Commercial Type
                                </div>
                                <SelectDropdown
                                    data={this.state.commercialTypes}
                                    selectedId={
                                        this.state.propertyDetails
                                            .commercialType
                                    }
                                    placeholder="Commercial type..."
                                    onSelect={(id: IOptions) =>
                                        this.setState((prevState) => ({
                                            ...prevState,
                                            propertyDetails: {
                                                ...prevState.propertyDetails,
                                                commercialType: id.value,
                                            },
                                        }))
                                    }
                                />
                            </FormGroup>
                        </div>
                    )}

                    {this.state.propertyDetails.residentialType.toUpperCase() ==
                        FlatPropertyEntityId.toUpperCase() && (
                        <div className={styles.column}>
                            <FormGroup className={styles.flexColumn}>
                                <div className={styles.label}>Flat Type</div>
                                <SelectDropdown
                                    data={this.state.flatTypes}
                                    selectedId={
                                        this.state.propertyDetails.flatType
                                    }
                                    placeholder="Flat type..."
                                    onSelect={(id: IOptions) =>
                                        this.setState((prevState) => ({
                                            ...prevState,
                                            propertyDetails: {
                                                ...prevState.propertyDetails,
                                                flatType: id.value,
                                            },
                                        }))
                                    }
                                />
                            </FormGroup>
                        </div>
                    )}
                    <div className={styles.column}>
                        <FormGroup className={styles.flexColumn}>
                            <div className={styles.label}>Tenure</div>
                            <SelectDropdown
                                data={this.state.tenureTypes}
                                selectedId={this.state.propertyDetails.tenure}
                                placeholder="Tenure..."
                                onSelect={(id: IOptions) =>
                                    this.setState((prevState) => ({
                                        ...prevState,
                                        propertyDetails: {
                                            ...prevState.propertyDetails,
                                            tenure: id.value,
                                        },
                                    }))
                                }
                            />
                        </FormGroup>
                    </div>
                </div>
                {this.state.propertyDetails.tenure.toUpperCase() ==
                    LeaseholdId.toUpperCase() && (
                    <div className={styles.rowJustifyLeft}>
                        <div className={styles.column}>
                            <FormGroup className={styles.flexColumn}>
                                <div className={styles.label}>
                                    Service Charge
                                </div>

                                <div className={styles.row}>
                                    <div className={styles.column}>
                                        <CurrencyInput
                                            inputType={'tel'}
                                            className={styles.input}
                                            value={
                                                this.state.propertyDetails
                                                    .serviceCharge
                                                    ? this.state.propertyDetails.serviceCharge.toString()
                                                    : '0'
                                            }
                                            name="serviceCharge"
                                            prefix="£"
                                            handleChange={
                                                this.handleChangeCurrencyInput
                                            }
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                        </div>

                        <div className={styles.column}>
                            <FormGroup className={styles.flexColumn}>
                                <div className={styles.label}>Ground Rent</div>

                                <div className={styles.row}>
                                    <div className={styles.column}>
                                        <CurrencyInput
                                            inputType={'tel'}
                                            className={styles.input}
                                            value={
                                                this.state.propertyDetails
                                                    .groundRent
                                                    ? this.state.propertyDetails.groundRent.toString()
                                                    : '0'
                                            }
                                            name="groundRent"
                                            prefix="£"
                                            handleChange={
                                                this.handleChangeCurrencyInput
                                            }
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                        </div>

                        <div className={styles.column}>
                            <FormGroup className={styles.flexColumn}>
                                <div className={styles.label}>
                                    Management Company
                                </div>

                                <div className={styles.row}>
                                    <div className={styles.column}>
                                        <input
                                            className={styles.input}
                                            placeholder=""
                                            name="managementCompany"
                                            value={
                                                this.state.propertyDetails
                                                    .managementCompany
                                            }
                                            onChange={
                                                this.onPropertyDetailsChange
                                            }
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                        </div>
                    </div>
                )}
                <div className={styles.rowJustifyLeft}>
                    <div className={styles.propertyList}>
                        <div className={styles.columnDetails}>
                            <FormGroup className={styles.flexColumn}>
                                <div className={styles.label}>Bedrooms</div>

                                <div className={styles.row}>
                                    <div
                                        className={styles.columnDetailsTickbox}
                                    >
                                        <input
                                            className={styles.input}
                                            name="bedrooms"
                                            type="number"
                                            pattern="[0-9]*"
                                            onChange={
                                                this
                                                    .onPropertyDetailsChangeLimited
                                            }
                                            value={
                                                this.state.propertyDetails
                                                    .bedrooms
                                            }
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                        </div>

                        <div className={styles.columnDetails}>
                            <FormGroup className={styles.flexColumn}>
                                <div className={styles.label}>Bathrooms</div>

                                <div className={styles.row}>
                                    <div
                                        className={styles.columnDetailsTickbox}
                                    >
                                        <input
                                            className={styles.input}
                                            name="bathrooms"
                                            type="number"
                                            pattern="[0-9]*"
                                            onChange={
                                                this
                                                    .onPropertyDetailsChangeLimited
                                            }
                                            value={
                                                this.state.propertyDetails
                                                    .bathrooms
                                            }
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                        </div>

                        <div className={styles.columnDetails}>
                            <FormGroup className={styles.flexColumn}>
                                <div className={styles.label}>Receptions</div>

                                <div className={styles.row}>
                                    <div
                                        className={styles.columnDetailsTickbox}
                                    >
                                        <input
                                            className={styles.input}
                                            name="receptions"
                                            type="number"
                                            pattern="[0-9]*"
                                            onChange={
                                                this
                                                    .onPropertyDetailsChangeLimited
                                            }
                                            value={
                                                this.state.propertyDetails
                                                    .receptions
                                            }
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                        </div>

                        <div className={styles.columnDetails}>
                            <FormGroup className={styles.flexColumn}>
                                <div className={styles.label}>Kitchens</div>

                                <div className={styles.row}>
                                    <div
                                        className={styles.columnDetailsTickbox}
                                    >
                                        <input
                                            className={styles.input}
                                            name="kitchens"
                                            type="number"
                                            pattern="[0-9]*"
                                            onChange={
                                                this
                                                    .onPropertyDetailsChangeLimited
                                            }
                                            value={
                                                this.state.propertyDetails
                                                    .kitchens
                                            }
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                        </div>

                        <div className={styles.columnDetails}>
                            <FormGroup className={styles.flexColumn}>
                                <div className={styles.label}>Floors</div>

                                <div className={styles.row}>
                                    <div
                                        className={styles.columnDetailsTickbox}
                                    >
                                        <input
                                            className={styles.input}
                                            name="floors"
                                            type="number"
                                            pattern="[0-9]*"
                                            onChange={
                                                this
                                                    .onPropertyDetailsChangeLimited
                                            }
                                            value={
                                                this.state.propertyDetails
                                                    .floors
                                            }
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                        </div>
                    </div>

                    <div className={styles.column}>
                        <FormGroup className={styles.flexColumn}>
                            <div className={styles.label}>
                                Furnished &nbsp;
                                <Tooltip
                                    theme="light"
                                    html={
                                        <div className={styles.tooltipContent}>
                                            <h1>Furnished / Part Furnished</h1>
                                            <ul>
                                                <li>
                                                    Furniture provided by the
                                                    landlord must meet the fire
                                                    resistance requirements in
                                                    the Furniture and
                                                    Furnishings (Fire) (Safety)
                                                    Regulations 1988{' '}
                                                </li>
                                                <li>
                                                    Furniture manufactured since
                                                    March 1989 will comply with
                                                    these regulations and most
                                                    will be marked with a label
                                                    showing compliance
                                                </li>
                                                <li>
                                                    At Rent Chief we have
                                                    simplified the information,
                                                    for more information see our
                                                    information here, for full
                                                    regulation information
                                                    please see below.
                                                </li>
                                            </ul>
                                            <span className={styles.links}>
                                                <img
                                                    src={govImage}
                                                    className={styles.govImage}
                                                />
                                                <a
                                                    href="http://www.legislation.gov.uk/uksi/1988/1324/contents/made"
                                                    target="_blank"
                                                >
                                                    The Furniture and
                                                    Furnishings (Fire) (Safety)
                                                    Regulations 1988
                                                </a>
                                            </span>
                                        </div>
                                    }
                                    position="right"
                                    trigger="mouseenter"
                                    arrow
                                    interactive={true}
                                >
                                    <img
                                        className={styles.infoIcon}
                                        src={regIcon}
                                        alt="Info"
                                    />
                                </Tooltip>
                            </div>
                            <div className={styles.dropdown}>
                                <SelectDropdown
                                    showDefaultOption
                                    data={this.state.furnishedTypes}
                                    selectedId={
                                        this.state.propertyDetails.furnished
                                    }
                                    placeholder="Level of furnishing?"
                                    onSelect={(id: IOptions) =>
                                        this.setState((prevState) => ({
                                            ...prevState,
                                            propertyDetails: {
                                                ...prevState.propertyDetails,
                                                furnished: id.value,
                                            },
                                        }))
                                    }
                                />
                            </div>
                        </FormGroup>
                    </div>

                    <div className={styles.column}>
                        <FormGroup className={styles.flexColumn}>
                            <div className={styles.label}>Parking</div>
                            <SelectDropdown
                                showDefaultOption
                                data={this.state.parkingTypes}
                                selectedId={this.state.propertyDetails.parking}
                                placeholder="What is the parking situation?"
                                onSelect={(id: IOptions) =>
                                    this.setState((prevState) => ({
                                        ...prevState,
                                        propertyDetails: {
                                            ...prevState.propertyDetails,
                                            parking: id.value,
                                        },
                                    }))
                                }
                            />
                        </FormGroup>
                    </div>

                    {canSelectNumberOfSpaces(
                        this.state.propertyDetails.parking,
                        this.state.parkingTypes
                    ) && (
                        <div className={styles.column}>
                            <FormGroup className={styles.flexColumn}>
                                <div className={styles.label}>
                                    Number of spaces
                                </div>
                                <div className={styles.columnDetailsTickbox}>
                                    <input
                                        className={styles.input}
                                        type="number"
                                        pattern="[0-9]*"
                                        placeholder=""
                                        name="numberOfParkingSpaces"
                                        value={
                                            this.state.propertyDetails
                                                .numberOfParkingSpaces
                                        }
                                        onChange={
                                            this.onPropertyDetailsChangeLimited
                                        }
                                    />
                                </div>
                            </FormGroup>
                        </div>
                    )}
                </div>
                <div className={styles.rowJustifyLeft}>
                    <div
                        className={`${styles.column} ${styles.columnSmallMaxSize}`}
                    >
                        <FormGroup className={styles.flexColumn}>
                            <div className={styles.label}>Year Built</div>

                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <input
                                        className={styles.input}
                                        type="number"
                                        pattern="[0-9]*"
                                        placeholder=""
                                        name="yearBuilt"
                                        value={
                                            this.state.propertyDetails.yearBuilt
                                        }
                                        onChange={
                                            this.onPropertyDetailsChangeLimited
                                        }
                                    />
                                </div>
                            </div>
                        </FormGroup>
                    </div>

                    <div className={`${styles.column} ${styles.columnSize}`}>
                        <FormGroup className={styles.flexColumn}>
                            <div className={styles.label}>Size</div>
                            <div className={styles.row}>
                                <div className={styles.columnDetailsSize}>
                                    <input
                                        maxLength={6}
                                        type="number"
                                        pattern="[0-9]*"
                                        name="size"
                                        className={styles.input}
                                        placeholder="Size..."
                                        value={this.state.propertyDetails.size}
                                        onChange={
                                            this
                                                .onPropertyDetailsChangeSizeLimited
                                        }
                                    />
                                </div>
                                <div
                                    className={`${styles.column} ${styles.columnMinDropdown}`}
                                >
                                    <SelectDropdown
                                        showDefaultOption
                                        data={this.state.sizeTypes}
                                        selectedId={
                                            this.state.propertyDetails.sizeScale
                                        }
                                        placeholder="Size..."
                                        onSelect={(id: IOptions) =>
                                            this.setState((prevState) => ({
                                                ...prevState,
                                                propertyDetails: {
                                                    ...prevState.propertyDetails,
                                                    sizeScale: id.value,
                                                },
                                            }))
                                        }
                                    />
                                </div>
                            </div>
                        </FormGroup>
                    </div>

                    <div className={styles.column}>
                        <FormGroup className={styles.flexColumn}>
                            <div className={styles.label}>Roof Material</div>
                            <SelectDropdown
                                showDefaultOption
                                data={this.state.roofTypes}
                                selectedId={
                                    this.state.propertyDetails.roofMaterial
                                }
                                placeholder="Type of roof material?"
                                onSelect={(id: IOptions) =>
                                    this.setState((prevState) => ({
                                        ...prevState,
                                        propertyDetails: {
                                            ...prevState.propertyDetails,
                                            roofMaterial: id.value,
                                        },
                                    }))
                                }
                            />
                        </FormGroup>
                    </div>

                    <div className={styles.column}>
                        <FormGroup className={styles.flexColumn}>
                            <div className={styles.label}>
                                Walls Construction
                            </div>
                            <SelectDropdown
                                showDefaultOption
                                data={this.state.wallsTypes}
                                selectedId={
                                    this.state.propertyDetails.wallsConstruction
                                }
                                placeholder="Type of wall construction?"
                                onSelect={(id: IOptions) =>
                                    this.setState((prevState) => ({
                                        ...prevState,
                                        propertyDetails: {
                                            ...prevState.propertyDetails,
                                            wallsConstruction: id.value,
                                        },
                                    }))
                                }
                            />
                        </FormGroup>
                    </div>

                    <div className={styles.columnSmall}>
                        <FormGroup className={styles.flexColumn}>
                            <div className={styles.label}>EPC Rating</div>
                            <SelectDropdown
                                data={[
                                    {
                                        label: 'A',
                                        value: 'A',
                                    },
                                    {
                                        label: 'B',
                                        value: 'B',
                                    },
                                    {
                                        label: 'C',
                                        value: 'C',
                                    },
                                    {
                                        label: 'D',
                                        value: 'D',
                                    },
                                    {
                                        label: 'E',
                                        value: 'E',
                                    },
                                    {
                                        label: 'F',
                                        value: 'F',
                                    },
                                    {
                                        label: 'G',
                                        value: 'G',
                                    },
                                ]}
                                selectedId={
                                    this.state.propertyDetails.epcRating
                                }
                                placeholder="Rating..."
                                onSelect={(id: IOptions) =>
                                    this.setState((prevState) => ({
                                        ...prevState,
                                        propertyDetails: {
                                            ...prevState.propertyDetails,
                                            epcRating: id.value,
                                        },
                                    }))
                                }
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className={styles.rowJustifyLeft}>
                    <div className={`${styles.column}`}>
                        <FormGroup className={styles.flexColumn}>
                            <div className={styles.label}>Finish Quality</div>
                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <SelectDropdown
                                        showDefaultOption
                                        data={this.state.finishQuality}
                                        selectedId={
                                            this.state.propertyDetails
                                                .finishQualityType
                                        }
                                        placeholder="Finish Quality..."
                                        onSelect={(id: IOptions) =>
                                            this.setState((prevState) => ({
                                                ...prevState,
                                                propertyDetails: {
                                                    ...prevState.propertyDetails,
                                                    finishQualityType: id.value,
                                                },
                                            }))
                                        }
                                    />
                                </div>
                            </div>
                        </FormGroup>
                    </div>

                    <div className={styles.column}>
                        <FormGroup className={styles.flexColumn}>
                            <div className={styles.label}>Outdoor Space</div>
                            <div className={`${styles.column}`}>
                                <SelectDropdown
                                    showDefaultOption
                                    data={this.state.outdoorSpace}
                                    selectedId={
                                        this.state.propertyDetails
                                            .outdoorSpaceType
                                    }
                                    placeholder="Outdoor Space..."
                                    onSelect={(id: IOptions) =>
                                        this.setState((prevState) => ({
                                            ...prevState,
                                            propertyDetails: {
                                                ...prevState.propertyDetails,
                                                outdoorSpaceType: id.value,
                                            },
                                        }))
                                    }
                                />
                            </div>
                        </FormGroup>
                    </div>
                </div>
                <div className={styles.sectionTitle}>Local Council</div>
                <div className={styles.rowJustifyLeft}>
                    <div className={styles.column}>
                        <FormGroup className={styles.flexColumn}>
                            <div className={styles.label}>Council</div>

                            <div className={styles.row}>
                                <div
                                    className={`${styles.column} ${styles.councilColumn}`}
                                >
                                    <SelectDropdown
                                        data={
                                            this.state.councilList
                                                ? this.state.councilList
                                                : []
                                        }
                                        selectedId={
                                            this.state.councilId
                                                ? this.state.councilId
                                                : this.getCouncilId()
                                        }
                                        placeholder="Please select local council..."
                                        onSelect={(id: IOptions) => {
                                            this.setState((prevState) => ({
                                                ...prevState,
                                                propertyDetails: {
                                                    ...prevState.propertyDetails,
                                                    council: id.label,
                                                },
                                            }))
                                        }}
                                    />
                                    <CouncilInformationPopup
                                        council={
                                            this.state.propertyDetails.council
                                        }
                                    />
                                </div>
                            </div>
                        </FormGroup>
                    </div>
                    <div className={styles.rowJustifyLeft}>
                        <div className={styles.column}>
                            <FormGroup className={styles.flexColumn}>
                                <div className={styles.label}>
                                    Council Tax Banding
                                </div>
                                <SelectDropdown
                                    data={[
                                        {
                                            label: 'A',
                                            value: 'A',
                                        },
                                        {
                                            label: 'B',
                                            value: 'B',
                                        },
                                        {
                                            label: 'C',
                                            value: 'C',
                                        },
                                        {
                                            label: 'D',
                                            value: 'D',
                                        },
                                        {
                                            label: 'E',
                                            value: 'E',
                                        },
                                        {
                                            label: 'F',
                                            value: 'F',
                                        },
                                        {
                                            label: 'G',
                                            value: 'G',
                                        },
                                        {
                                            label: 'H',
                                            value: 'H',
                                        },
                                    ]}
                                    selectedId={
                                        this.state.propertyDetails
                                            .councilTaxBanding
                                    }
                                    placeholder="Banding..."
                                    onSelect={(id: IOptions) =>
                                        this.setState((prevState) => ({
                                            ...prevState,
                                            propertyDetails: {
                                                ...prevState.propertyDetails,
                                                councilTaxBanding: id.value,
                                            },
                                        }))
                                    }
                                />
                            </FormGroup>
                        </div>
                    </div>
                </div>
                {/* TO DO */}
                <div className={styles.sectionTitle}>Appliances</div>
                <div className={styles.rowJustifyLeft}>
                    <div className={styles.splitFeatures}>
                        <div className={styles.columnSplit}>
                            <div className={styles.featureList}>
                                <div
                                    className={`${styles.columnSmall} ${styles.checkbox}`}
                                >
                                    <FormGroup className={styles.flexColumn}>
                                        <div>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                this.state
                                                                    .propertyFeatures
                                                                    .fridge
                                                            }
                                                            color="primary"
                                                            onChange={() =>
                                                                this.onCheckChangePropertyFeatures(
                                                                    'fridge'
                                                                )
                                                            }
                                                            value="Fridge"
                                                            className={
                                                                this.state
                                                                    .propertyFeatures
                                                                    .fridge
                                                                    ? styles.materialCheckbox
                                                                    : null
                                                            }
                                                        />
                                                    }
                                                    label="Fridge"
                                                />
                                            </FormGroup>
                                        </div>
                                    </FormGroup>
                                </div>
                                <div
                                    className={`${styles.columnSmall} ${styles.checkbox}`}
                                >
                                    <FormGroup className={styles.flexColumn}>
                                        <div>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                this.state
                                                                    .propertyFeatures
                                                                    .freezer
                                                            }
                                                            color="primary"
                                                            onChange={() =>
                                                                this.onCheckChangePropertyFeatures(
                                                                    'freezer'
                                                                )
                                                            }
                                                            value="Fridge"
                                                            className={
                                                                this.state
                                                                    .propertyFeatures
                                                                    .freezer
                                                                    ? styles.materialCheckbox
                                                                    : null
                                                            }
                                                        />
                                                    }
                                                    label="Freezer"
                                                />
                                            </FormGroup>
                                        </div>
                                    </FormGroup>
                                </div>
                                <div
                                    className={`${styles.columnSmall} ${styles.checkbox}`}
                                >
                                    <FormGroup className={styles.flexColumn}>
                                        <div>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                this.state
                                                                    .propertyFeatures
                                                                    .washingMachine
                                                            }
                                                            color="primary"
                                                            onChange={() =>
                                                                this.onCheckChangePropertyFeatures(
                                                                    'washingMachine'
                                                                )
                                                            }
                                                            value="Washer"
                                                            className={
                                                                this.state
                                                                    .propertyFeatures
                                                                    .washingMachine
                                                                    ? styles.materialCheckbox
                                                                    : null
                                                            }
                                                        />
                                                    }
                                                    label="Washer"
                                                />
                                            </FormGroup>
                                        </div>
                                    </FormGroup>
                                </div>
                                <div
                                    className={`${styles.columnSmall} ${styles.checkbox}`}
                                >
                                    <FormGroup className={styles.flexColumn}>
                                        <div>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                this.state
                                                                    .propertyFeatures
                                                                    .tumbleDryer
                                                            }
                                                            color="primary"
                                                            onChange={() =>
                                                                this.onCheckChangePropertyFeatures(
                                                                    'tumbleDryer'
                                                                )
                                                            }
                                                            value="Dryer"
                                                            className={
                                                                this.state
                                                                    .propertyFeatures
                                                                    .tumbleDryer
                                                                    ? styles.materialCheckbox
                                                                    : null
                                                            }
                                                        />
                                                    }
                                                    label="Dryer"
                                                />
                                            </FormGroup>
                                        </div>
                                    </FormGroup>
                                </div>
                                <div
                                    className={`${styles.columnSmall} ${styles.checkbox}`}
                                >
                                    <FormGroup className={styles.flexColumn}>
                                        <div>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                this.state
                                                                    .propertyFeatures
                                                                    .dishwasher
                                                            }
                                                            color="primary"
                                                            onChange={() =>
                                                                this.onCheckChangePropertyFeatures(
                                                                    'dishwasher'
                                                                )
                                                            }
                                                            value="Dishwasher"
                                                            className={
                                                                this.state
                                                                    .propertyFeatures
                                                                    .dishwasher
                                                                    ? styles.materialCheckbox
                                                                    : null
                                                            }
                                                        />
                                                    }
                                                    label="Dishwasher"
                                                />
                                            </FormGroup>
                                        </div>
                                    </FormGroup>
                                </div>
                                <div
                                    className={`${styles.columnSmall} ${styles.checkbox}`}
                                >
                                    <FormGroup className={styles.flexColumn}>
                                        <div>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                this.state
                                                                    .propertyFeatures
                                                                    .cooker
                                                            }
                                                            color="primary"
                                                            onChange={() =>
                                                                this.onCheckChangePropertyFeatures(
                                                                    'cooker'
                                                                )
                                                            }
                                                            value="Cooker"
                                                            className={
                                                                this.state
                                                                    .propertyFeatures
                                                                    .cooker
                                                                    ? styles.materialCheckbox
                                                                    : null
                                                            }
                                                        />
                                                    }
                                                    label="Cooker"
                                                />
                                            </FormGroup>
                                        </div>
                                    </FormGroup>
                                </div>
                                <div
                                    className={`${styles.columnSmall} ${styles.checkbox}`}
                                >
                                    <FormGroup className={styles.flexColumn}>
                                        <div>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                this.state
                                                                    .propertyFeatures
                                                                    .hob
                                                            }
                                                            color="primary"
                                                            onChange={() =>
                                                                this.onCheckChangePropertyFeatures(
                                                                    'hob'
                                                                )
                                                            }
                                                            value="Hob"
                                                            className={
                                                                this.state
                                                                    .propertyFeatures
                                                                    .hob
                                                                    ? styles.materialCheckbox
                                                                    : null
                                                            }
                                                        />
                                                    }
                                                    label="Hob"
                                                />
                                            </FormGroup>
                                        </div>
                                    </FormGroup>
                                </div>
                                <div
                                    className={`${styles.columnSmall} ${styles.checkbox}`}
                                >
                                    <FormGroup className={styles.flexColumn}>
                                        <div>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                this.state
                                                                    .propertyFeatures
                                                                    .microwave
                                                            }
                                                            color="primary"
                                                            onChange={() =>
                                                                this.onCheckChangePropertyFeatures(
                                                                    'microwave'
                                                                )
                                                            }
                                                            value="Microwave"
                                                            className={
                                                                this.state
                                                                    .propertyFeatures
                                                                    .microwave
                                                                    ? styles.materialCheckbox
                                                                    : null
                                                            }
                                                        />
                                                    }
                                                    label="Microwave"
                                                />
                                            </FormGroup>
                                        </div>
                                    </FormGroup>
                                </div>

                                <div
                                    className={`${styles.columnSmall} ${styles.checkbox}`}
                                >
                                    <FormGroup className={styles.flexColumn}>
                                        <div className={styles.smallText}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                this.state
                                                                    .propertyFeatures
                                                                    .smartMeters
                                                            }
                                                            color="primary"
                                                            onChange={() =>
                                                                this.onCheckChangePropertyFeatures(
                                                                    'smartMeters'
                                                                )
                                                            }
                                                            value="smartMeters"
                                                            className={
                                                                this.state
                                                                    .propertyFeatures
                                                                    .smartMeters
                                                                    ? styles.materialCheckbox
                                                                    : null
                                                            }
                                                        />
                                                    }
                                                    label="Smart Meters"
                                                />
                                            </FormGroup>
                                        </div>
                                    </FormGroup>
                                </div>
                                <div
                                    className={`${styles.columnSmall} ${styles.checkbox}`}
                                >
                                    <FormGroup className={styles.flexColumn}>
                                        <div>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                this.state
                                                                    .propertyFeatures
                                                                    .airConditioner
                                                            }
                                                            color="primary"
                                                            onChange={() =>
                                                                this.onCheckChangePropertyFeatures(
                                                                    'airConditioner'
                                                                )
                                                            }
                                                            value="Air Conditioner"
                                                            className={
                                                                this.state
                                                                    .propertyFeatures
                                                                    .airConditioner
                                                                    ? styles.materialCheckbox
                                                                    : null
                                                            }
                                                        />
                                                    }
                                                    label="Air Conditioner"
                                                />
                                            </FormGroup>
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${styles.columnSplit} ${styles.columnSplitSmall}`}
                        >
                            <div className={styles.split}>
                                <div className={styles.columnSplit}>
                                    <div className={styles.label}>Warranty</div>
                                    <div className={styles.associated}>
                                        <AssociatedDocuments
                                            showOnlyNoneText
                                            options={{
                                                propertyId:
                                                    this.props.match.params
                                                        .propertyId,
                                                documentType:
                                                    '7a493135-a36c-49e1-a69b-f97a0623fc10',
                                            }}
                                        />
                                    </div>
                                    <AddDocumentButton
                                        params={this.props}
                                        propertyId={this.props.match.params.propertyId.toString()}
                                        name={'Warranty'}
                                        documentTypeId={
                                            '7a493135-a36c-49e1-a69b-f97a0623fc10'
                                        }
                                    />
                                </div>
                                <div className={styles.columnSplit}>
                                    <div className={styles.label}>Manual</div>
                                    <div className={styles.associated}>
                                        <AssociatedDocuments
                                            showOnlyNoneText
                                            options={{
                                                propertyId:
                                                    this.props.match.params
                                                        .propertyId,
                                                documentType:
                                                    '8aa5a709-6117-4fd9-9ea6-8cc7086523e0',
                                            }}
                                        />
                                    </div>
                                    <AddDocumentButton
                                        params={this.props}
                                        propertyId={this.props.match.params.propertyId.toString()}
                                        name={'Manual'}
                                        documentTypeId={
                                            '8aa5a709-6117-4fd9-9ea6-8cc7086523e0'
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className={styles.rowSaveChanges}>
                    <StickyComponent>
                        <div
                            className={`${styles.buttonContainer}`}
                            id={'save'}
                        >
                            <Buttons
                                buttons={[
                                    {
                                        displayType: 'cancel',
                                        elementType: 'button',
                                        onClick: () => {
                                            let changes = this.checkForChanges()
                                            if (changes) {
                                                this.setState({
                                                    outstandingChangesModalOpen:
                                                        true,
                                                })
                                                return
                                            }

                                            this.props.history.push(
                                                `/dashboard/property/${this.props.match.params.propertyId}/summary`
                                            )
                                        },
                                    },
                                    {
                                        displayType: 'submit',
                                        elementType: 'button',
                                        onClick: () => this.onUpdate(),
                                    },
                                ]}
                            />
                        </div>
                    </StickyComponent>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    add_document_popup: state.app.popups.add_document_popup,
})

export default withRouter(
    connect(mapStateToProps, null)(PropertyCompositionPage) as any
)
