import { ODataV4Adaptor } from '@syncfusion/ej2-data';

export class CustomODataV4Adapter extends ODataV4Adaptor {

    public processResponse() {
        let i: number = 0;
        // calling base class processResponse function
        const original: any = super.processResponse.apply(this, arguments);
        return original;
    }
}