import React, { Component } from "react";
import { withRouter, Route, Redirect } from "react-router-dom";

// Import Styles
import styles from "./RolePage.module.scss";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { getRole } from "../../../../../services/RolesService";
import { IRoleDetail } from "../../../../../types/RolesService";
import RolePermissions from "./RolePermissions";
import TabbedNavigationContainer from "../../../../atoms/TabbedNavigationContainer";
import SimpleRouterTabs from "../../../../atoms/SimpleRouterTabs";
import RoleAssignedUsers from "./RoleAssignedUsers";
import RoleDetail from "./RoleDetail";
import permissionsIcon from "../../../../../images/icons/permissions.png";
import detailsIcon from "../../../../../images/icons/ownership.png";
import backIcon from "../../../../../images/icons/ic_arrow_back_24px.png";

interface IState {
  role: string;
  roleData: IRoleDetail;
}

interface IRouterProps {
  history: any;
  match: any;
  location: any;
}

class RolePage extends Component<IRouterProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      role: null,
      roleData: null
    };
  }

  componentDidMount(): void {
    let roleId = this.props.match.params.roleId;
    this.setState({
      role: roleId
    });

    getRole(roleId).then(resp => {
      if (resp && resp.data) {
        this.setState({
          roleData: resp.data
        });
      }
    });
  }

  render() {
    if (!this.state.role) {
      return <div>Cannot get role details.</div>;
    }

    return (
      <div className={styles.generalPage}>
        <TabbedNavigationContainer fullWidth>
          <Col>
            <SimpleRouterTabs
              routes={[
                {
                  path: "details",
                  text: "Details",
                  icon: detailsIcon
                },
                {
                  path: "permissions",
                  text: "Permissions",
                  icon: permissionsIcon
                },
                {
                  path: `assigned-users`,
                  text: "Assigned Users",
                  icon: detailsIcon
                },
                {
                  path: "timeline",
                  text: "Timeline"
                }
              ]}
            />
          </Col>
        </TabbedNavigationContainer>

        <Row>
          <Col>
            <p
              onClick={() => this.props.history.push("../../roles")}
              className={styles.goBack}
            >
              {" "}
              <img src={backIcon} alt="Go back" /> Go back to Roles List
            </p>
          </Col>
        </Row>

        <Route
          path={`${this.props.match.url}`}
          exact
          render={() => <Redirect to={`${this.props.match.url}/details`} />}
        />

        <Route
          path={`${this.props.match.url}/details`}
          exact
          render={() => (
            <RoleDetail
              roleId={this.state.role}
              roleDetail={this.state.roleData}
            />
          )}
        />

        <Route
          path={`${this.props.match.url}/permissions`}
          exact
          render={() => (
            <RolePermissions
              roleId={this.state.role}
              roleDetail={this.state.roleData}
            />
          )}
        />

        <Route
          path={`${this.props.match.path}/assigned-users`}
          exact
          render={() => (
            <RoleAssignedUsers
              roleId={this.state.role}
              roleDetail={this.state.roleData}
            />
          )}
        />
      </div>
    );
  }
}

export default withRouter(RolePage);
