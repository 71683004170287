import React from 'react'
import {
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    Grid,
    GridComponent,
    Inject,
    Edit,
    Resize,
    Toolbar,
    CommandModel,
} from '@syncfusion/ej2-react-grids'
import { MouseEventArgs } from '@syncfusion/ej2-base'
import Currency from '../../../atoms/Currency'
import * as S from './TransactionsScheduleGrid.styles'
import './TransactionsScheduleGrid.css'

interface props {
    datasource: any;
}

const TransactionsScheduleGrid = ({ datasource }: props) => {
    var grid: Grid | null;

    const amount = (props)=> {
        return (<Currency prefix="£" value={props.amount} decimalCount={2} />)
    }

    const load = (): void => {
        let instance = (document.getElementById('transactionsScheduleGrid') as any).ej2_instances[0];
        if (instance) {
            instance.element.addEventListener('mouseup', (e: MouseEventArgs) => {
                if ((e.target as HTMLElement).classList.contains("e-rowcell")) {
                    let index: number = parseInt((e.target as HTMLElement).parentElement.getAttribute("aria-rowindex"));
                    let colindex: number = parseInt((e.target as HTMLElement).getAttribute("aria-colindex"));
                    let field: string = instance.getColumns()[colindex].field;
                    instance.editModule.editCell(index, field);
                };
            });
        }
    }

    //const toolbarOptions: any = ['Delete', 'Cancel'];
    const editSettings: any = { allowEditing: true, allowDeleting: true, mode: 'Batch', showConfirmDialog: false };

    const commands: CommandModel[] = [
        //{ type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
        { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } },
        //{ type: 'Save', buttonOption: { cssClass: 'e-flat', iconCss: 'e-update e-icons' } },
        //{ type: 'Cancel', buttonOption: { cssClass: 'e-flat', iconCss: 'e-cancel-icon e-icons' } }
    ];

    return (
        <>
            <S.GridWrapperDiv>
                <GridComponent
                    ref={(g) => (grid = g)}
                    //toolbar={toolbarOptions}
                    editSettings={editSettings}
                    id="transactionsScheduleGrid"
                    dataSource={datasource}
                    allowResizing={true}
                    load={load}
                    cellSaved={() => grid.endEdit()}
                    batchDelete={() => grid.endEdit()}
                >
                        <Inject
                            services={[CommandColumn, Edit, Toolbar, Resize]}
                        />
                        <ColumnsDirective>
                            <ColumnDirective
                                field="transactionId"
                                headerText="Id"
                                textAlign="Left"
                                isPrimaryKey={true}
                                visible={false}
                                width={"0px"}
                            />
                            <ColumnDirective
                                field="dueDate"
                                headerText="Date"
                                textAlign="Left"
                                width={"43%"}
                                type="date"
                                editType='datepickeredit'
                                format={{type: 'date', format: 'dd/MM/yyyy'}}
                                customAttributes={{class: 'customcss'}}
                            />
                            <ColumnDirective
                                field="amount"
                                headerText="Amount"
                                textAlign="Left"
                                width={"43%"}
                                allowEditing={true}
                                template={amount}
                                //editTemplate={gridTemplate}
                                editType= 'numericedit'
                                customAttributes={{class: 'customcss'}}
                            />
                            <ColumnDirective 
                                field="none"
                                headerText='' 
                                textAlign="Center"
                                width={"14%"}
                                commands={commands} 
                                customAttributes={{class: 'customcss'}}
                            />
                        </ColumnsDirective>
                </GridComponent>
            </S.GridWrapperDiv>
        </>
    )
}

export default TransactionsScheduleGrid;