import React from "react";
import { Col, Row } from "reactstrap";
import { withRouter } from "react-router-dom";

import styles from "./ClientListItem.module.scss";
import deleteIcon from "../../../images/icons/property-action/Delete-inactive.png";
import editIcon from "../../../images/icons/property-action/Edit-inactive.png";
import moreIcon from "../../../images/icons/property-action/Action-inactive.png";
import { IClientListItem } from "../../../types/ClientService";
import EllipsisText from "../EllipsisText";

interface IProps {
  client: IClientListItem;
}

interface IWithRouterProps {
  history: any;
  location: any;
  match: any;
}

type Props = IProps & IWithRouterProps;

class ClientListItem extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const { client, history } = this.props;
    return (
      <Row className={styles.userItem} onClick={() => history.push(`/dashboard/manage/client/${client.id}`)}>
        <Col>
          <div className={styles.label}>Name</div>
          {client.name}
        </Col>
        <Col>
          <div className={styles.label}>Description</div>
          <EllipsisText length={60} text={client.description} />
        </Col>
      </Row>
    );
  }
}

export default withRouter(ClientListItem);