import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// Import Styles
import styles from "./RoleAssignedUsers.module.scss";
import SearchBar from "../../../../../atoms/SearchBar";
import { getAssignedUsers } from "../../../../../../services/RolesService";
import {
  IUserListItem,
  IClientListItem
} from "../../../../../../types/ClientService";
import ListItem from "../../../../../atoms/ListComponents/ListItem";
import RoleAssignedUserItem from "../../../../../atoms/RoleAssignedUserItem";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import AddButton from "../../../../../atoms/AddButton";
import RCFilter from "../../../../../atoms/ListComponents/Filter";
import Viewing from "../../../../../atoms/Viewing";
import { toggleAssignRoleSidebar } from "../../../../../../helpers/sidebarHelpers";
import { IRoleDetail } from "../../../../../../types/RolesService";

interface IState {
  users: Array<IUserListItem>;
}

interface IRouterProps {
  history: any;
  match: any;
  location: any;
}

interface IProps {
  roleId?: string;
  roleDetail?: IRoleDetail;
  user?: IUserListItem;
  client?: IClientListItem;
}

type Props = IProps & IRouterProps;

class RoleAssignedUsers extends Component<Props, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      users: []
    };

    this.onAssignUser = this.onAssignUser.bind(this);
  }

  componentDidMount(): void {
    getAssignedUsers(this.props.match.params.roleId).then(resp => {
      if (resp && resp.data) {
        this.setState({
          users: resp.data
        });
      }
    });
  }

  onAssignUser(): void {
    this.props.history.push({
      search:
        "assignRole=" +
        this.props.roleId +
        "&assignRoleName=" +
        this.props.roleDetail.roleName
    });

    toggleAssignRoleSidebar(true);
  }

  render() {
    if (!this.props.roleDetail) {
      return <div>Cannot load.</div>;
    }

    return (
      <div className={styles.generalPage}>
        <SearchBar
          text="Search for an assigned users..."
          onSearch={() => null}
        />

        <Row>
          <Viewing
            user={this.props.user && this.props.user}
            forename={this.props.roleDetail && this.props.roleDetail.roleName}
            surname=""
          />

          <Col>
            <RCFilter
              showFilterIcon
              filterElements={[
                {
                  status: "All Users",
                  canSelect: true,
                  id: "0"
                },
                {
                  status: "Active Users",
                  canSelect: true,
                  id: "1"
                },
                {
                  status: "Not Active User",
                  canSelect: true,
                  id: "2"
                }
              ]}
              activeElementId="0"
              onChangeFilter={() => null}
              toggleStatusDropdownMenu={() => null}
            />
          </Col>

          <Col>
            <AddButton text="Assign User" onAdd={() => this.onAssignUser()} />
          </Col>
        </Row>

        {this.state.users.length == 0 && (
          <div>No users are assigned to this role.</div>
        )}

        {this.state.users &&
          this.state.users.map((user, index) => {
            return (
              <ListItem
                client={this.props.client}
                user={this.props.user}
                key={index}
              >
                <RoleAssignedUserItem user={user} />
              </ListItem>
            );
          })}
      </div>
    );
  }
}

export default withRouter(RoleAssignedUsers);
