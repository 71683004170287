// Import React
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
const queryString = require("query-string");

// Import Modules
import LoginForm from '../../molecules/LoginForm';

import logo from '../../../images/rentchief-logo-small.png';

import styles from './AccountActivationPage.module.scss';
import RotatingLoader from '../../atoms/RotatingLoader';
import SuccessTick from '../../atoms/SuccessTick';
import GenericButton from '../../atoms/GenericButton';
import { activate } from '../../../services/AccountService';
import { IActivateAccountRequest } from '../../../types/AccountService';
import { isUserAuthenticated } from '../../../helpers/authHelpers';

interface IProps {
  history: any;
  match: any;
  location: any;
}

interface IState {
  isLoading: boolean;
  isValidated: boolean;
  error: string;
  hasError: boolean;
}

class AccountActivationPage extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: false,
      isValidated: false,
      error: '',
      hasError: false
    };
  }

  componentWillMount(): void {
    let authed = isUserAuthenticated();
    let activationCode = queryString.parse(this.props.location.search).authCode;

    if(authed && activationCode){
      this.props.history.push('/dashboard?authCode=' + activationCode)
      return;
    }
  }

  componentDidMount(): void {
    let userId = queryString.parse(this.props.location.search).userId;
    let activationCode = queryString.parse(this.props.location.search).authCode;

    if (!userId || !activationCode) {
      this.props.history.push('/');
      return;
    }

    this.setState({
      isLoading: true
    });

    let request: IActivateAccountRequest = {
      userId,
      activationCode
    }

    activate(request).then(resp => {
      if (resp.status == 200) {
        this.setState({
          isValidated: true,
          isLoading: false
        });
        return;
      }

      this.setState({
        isLoading: false,
        isValidated: false,
        hasError: true,
        error: ''
      });
    }).catch(e => {
      this.setState({
        isLoading: false,
        isValidated: false,
        hasError: true,
        error: e.response.data
      })
    })
  }

  render() {
    return (
      <div className={styles.page}>
        <div className={styles.left}>
          <div className={styles.flexContainer}>
            <img className={styles.logo} src={logo} alt="RentChief Logo" />

            {
              this.state.isLoading &&
              <div><RotatingLoader loading={true} text='Activating your account...' /></div>
            }

            {
              (!this.state.isLoading && this.state.isValidated) &&
              <div className={styles.activated}><SuccessTick /> <p>Your account has been activated.</p>
                <p><GenericButton border onClick={() => this.props.history.push('/account/login')} text='Login' /></p>
              </div>
            }

            {
              (!this.state.isLoading && !this.state.isValidated && this.state.hasError) &&
              <div className={styles.error}><p>There's been a problem: {this.state.error}</p>
                <p><GenericButton border onClick={() => this.props.history.push('/account/login')} text='Login' /></p>
              </div>
            }

          </div></div>
        <div className={styles.right}>
        </div>
      </div>
    );
  }
}

export default withRouter(AccountActivationPage);