export interface IImageDto {
    imageId: string;
    original: string;
    thumbnail: string;
    primary: boolean;
}

export interface IUploadForm {
    images: FormData;
    options: IUploadRequest;
}

export enum ImageTypeEnum {
    UNIT_IMAGE = 0,
    PROPERTY_IMAGE = 1,
    INSPECTION_IMAGE = 2,
    ASSET_IMAGE = 3,
    INSPECTION_RESULT_IMAGE = 4,
    TASK_IMAGE = 5
}

export enum ImageSourceEnum {
    GOOGLE_STREET,
    USER_UPLOAD
}

export interface IUploadRequest {
    propertyId?: string;
    unitId?: string;
    contactId?: string;
    inspectionId?: number;
    imageType: any;
    parentId?: number;
}

export interface ISFUploadUrls {
    saveUrl?: string;
    removeUrl?: string;
}

export interface IGetImagesRequest {
    propertyId?: string;
    unitId?: string;
    parentId?: number;
    imageType: ImageTypeEnum[];
}

export interface IChangePrimaryImageRequest {
    imageId: string;
    propertyId?: string;
    unitId?: string;
    parentId?: number;
    imageType: ImageTypeEnum;
}