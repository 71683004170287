import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Col, FormGroup, Label, Row } from 'reactstrap'
import { toggleAddClientSidebar } from '../../../helpers/sidebarHelpers'
import { getNewUserFormData } from '../../../services/ClientService'
import { AddNewUser } from '../../../YupValidationSchemas'
import CloseButton from '../../atoms/CloseButton'
import SelectDropdown, { IOptions } from '../../atoms/SelectDropdown'
import styles from './AddUserForm.module.css'

interface IProps {
    onSuccessHandler(): void
}

interface IRouterProps {
    history: any
    location: any
    match: any
}

interface IState {
    // Primary Details
    forename: string
    surname: string
    email: string

    // Role
    role: string
    availableRoles: Array<IOptions>

    // Permissions
    permissions: string[]
    availablePermissions: Array<IOptions>

    // Client
    client: string
    availableClients: Array<IOptions>
}

type Props = IRouterProps & IProps

class AddUserForm extends Component<Props, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            availableClients: [],
            availablePermissions: [],
            availableRoles: [],

            forename: '',
            surname: '',
            email: '',
            role: '',
            client: '',
            permissions: [],
        }

        this.handleChange = this.handleChange.bind(this)
        this.getData = this.getData.bind(this)
    }

    getData(): void {
        getNewUserFormData()
            .then((resp) => {
                if (resp) {
                    this.setState({
                        availableClients: resp.data.availableClients,
                        availablePermissions: resp.data.availablePermissions,
                        availableRoles: resp.data.availableRoles,
                    })
                }
            })
            .catch((error) => {
                throw 'Error'
            })
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const field = event.currentTarget.name
        const value: string = event.currentTarget.value
        this.setState({ [field]: value } as Pick<IState, any>)
    }

    componentDidMount() {
        this.getData()
    }

    render() {
        return (
            <div className={styles.form}>
                <Row className={styles.header}>
                    <div className={styles.close}>
                        <CloseButton
                            close={() => toggleAddClientSidebar(false)}
                        />
                    </div>
                    <Col>
                        <h1 className={styles.h1}>Add User</h1>
                    </Col>
                </Row>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        permissions: [],
                        role: '',
                        client: '',
                        forename: '',
                        surname: '',
                    }}
                    validationSchema={AddNewUser}
                    onSubmit={(values: any, { setSubmitting }) => {}}
                >
                    <Form className={styles.form}>
                        <FormGroup>
                            <Label className={styles.label} for="email">
                                Email Address
                            </Label>
                            <Field
                                className={styles.input}
                                type="email"
                                name="email"
                                placeholder="Email Address"
                            />
                        </FormGroup>
                        <div className={styles.errorMessage}>
                            <ErrorMessage
                                className={styles.errorMessage}
                                name="email"
                            />
                        </div>

                        <FormGroup>
                            <Label className={styles.label} for="forename">
                                Forename
                            </Label>
                            <Field
                                className={styles.input}
                                type="text"
                                name="forename"
                                placeholder="Forename"
                            />
                        </FormGroup>
                        <div className={styles.errorMessage}>
                            <ErrorMessage
                                className={styles.errorMessage}
                                name="forename"
                            />
                        </div>

                        <FormGroup>
                            <Label className={styles.label} for="surname">
                                Surname
                            </Label>
                            <Field
                                className={styles.input}
                                type="text"
                                name="surname"
                                placeholder="Surname"
                            />
                        </FormGroup>
                        <div className={styles.errorMessage}>
                            <ErrorMessage
                                className={styles.errorMessage}
                                name="surname"
                            />
                        </div>

                        <FormGroup>
                            <Label className={styles.label} for="role">
                                Role
                            </Label>
                            {this.state.availableRoles && (
                                <SelectDropdown
                                    placeholder="Select a role..."
                                    data={this.state.availableRoles}
                                    multiSelect={false}
                                    onSelect={() => null}
                                />
                            )}
                            <Field
                                className={styles.input}
                                type="hidden"
                                name="role"
                                placeholder="Role"
                            />
                        </FormGroup>
                        <div className={styles.errorMessage}>
                            <ErrorMessage
                                className={styles.errorMessage}
                                name="Role"
                            />
                        </div>

                        <FormGroup>
                            <Label className={styles.label} for="client">
                                Client
                            </Label>
                            {this.state.availableClients && (
                                <SelectDropdown
                                    placeholder="Select a client..."
                                    data={this.state.availableClients}
                                    multiSelect={false}
                                    onSelect={() => null}
                                />
                            )}
                            <Field
                                className={styles.input}
                                type="hidden"
                                name="client"
                                placeholder="Client"
                            />
                        </FormGroup>
                        <div className={styles.errorMessage}>
                            <ErrorMessage
                                className={styles.errorMessage}
                                name="client"
                            />
                        </div>

                        <FormGroup>
                            <Label className={styles.label} for="permissions">
                                Permissions
                            </Label>
                            {this.state.availablePermissions && (
                                <SelectDropdown
                                    placeholder="Assign permissions..."
                                    data={this.state.availablePermissions}
                                    multiSelect={true}
                                    onSelect={() => null}
                                />
                            )}
                            <Field
                                className={styles.input}
                                type="hidden"
                                name="permissions"
                                placeholder="Permissions"
                            />
                        </FormGroup>
                        <div className={styles.errorMessage}>
                            <ErrorMessage
                                className={styles.errorMessage}
                                name="permissions"
                            />
                        </div>

                        <Button className={styles.button} type="submit">
                            {<span>Add User</span>}
                        </Button>
                    </Form>
                </Formik>
            </div>
        )
    }
}

export default withRouter(AddUserForm) as any
