import React from 'react';
import * as S from './ResearchTheAreaPart.styles';

const ResearchTheAreaPart = ({ postcode }: { postcode: string }) => {
    return (
        <S.Section>
            <tbody>
                <tr>
                    <a href={`https://www.zoopla.co.uk/for-sale/property/${postcode}`} target="_blank">
                        <td>Nearby properties for sale</td>
                        <td>
                            <S.Chevron />
                        </td>
                    </a>
                </tr>
                <tr>
                    <a href={`https://www.zoopla.co.uk/to-rent/property/${postcode}`} target="_blank">
                        <td>Nearby properties for rent</td>
                        <td>
                            <S.Chevron />
                        </td>
                    </a>
                </tr>
                <tr>
                    <a href={`https://www.walkscore.com/score/${postcode}`} target="_blank">
                        <td>Walk score</td>
                        <td>
                            <S.Chevron />
                        </td>
                    </a>
                </tr>
            </tbody>
        </S.Section>
    );
};

export default ResearchTheAreaPart;