import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { ITenancySummary } from "../../../types/Tenancy";
import Currency from "../Currency";
import { getTenancySummaryFromPropertyId } from "../../../services/TenancyService";
import ContentLoading from "../ContentLoader";
import InitialStateSection from "../../molecules/DashboardHome/Sections/InitialStateSection/InitialStateSection.component";
import { canAccessPropertyDetails } from "../../../helpers/permissionHelpers"
import UserContactInfo, { UserContactInfoType } from "../UserContactInfo";
import { TenancyRemaining } from "../TenancyRemaining/TenancyRemaining.component";
import styles from "./TenancySummaryInfo.module.scss";
import * as S from "./TenancySummaryInfo.styles";;

interface IProps {
  propertyId?: string;
  onAdd?(): void;
}

export const TenancySummaryInfo = ({propertyId, onAdd} : IProps) => {
  const history = useHistory();

  const [tenancy, setTenancy] = useState<ITenancySummary>(null);
  const [loading, setLoading] = useState<boolean>(null);

  useEffect(() => {
    getData();
  }, [])

  const getData = (): void => {
    setLoading(true);

    getTenancySummaryFromPropertyId(propertyId).then(resp => {
      if (resp && resp.status == 200 && resp.data) {
        setTenancy(resp.data);
      }
      setLoading(false);
    });
  }

  const openTenancyEdit = () => {
    if(canAccessPropertyDetails()){
      history.push(
        `/dashboard/tenancy/view/${tenancy.tenancyId}`
      );
    }
  }


    if (loading) {
      return <ContentLoading loading={true} />;
    }

    if (!tenancy && !loading) {
      return <p>No Tenancy</p>;
    }

    if (
      !tenancy.tenancyId &&
      !tenancy.tenantId &&
      !loading
    ) {
        return(
          <InitialStateSection 
            iconUrl="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/maintenance_icon.svg"
            title="Record tenancy details"
            summary={`Available from ${moment(tenancy.availableFrom).format('MMMM Do YYYY')}`}
            addOnClick={() => onAdd && onAdd}
            showHeader={false}
          />
        );
    }

    return (
      <S.SectionDiv>
          <S.ContactDiv>
            <UserContactInfo
                id={tenancy.tenantId}
                type={UserContactInfoType.Contact}
            />
          </S.ContactDiv>
          <S.RentDiv onClick={() => openTenancyEdit()}>
            <span className={styles.value}>
              <Currency value={tenancy.rentPCM} prefix="£" /> {tenancy.paymentFrequencyShort}
            </span>
            <p className={styles.subText}>
              <span>{tenancy.nextPaymentDueText}</span>
            </p>
          </S.RentDiv>

          <S.TenancyLeftlDiv>
            <TenancyRemaining tenancy={tenancy} />
          </S.TenancyLeftlDiv>
      </S.SectionDiv>
    );

}

export default TenancySummaryInfo;