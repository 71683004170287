import { IOptions } from "../../components/atoms/SelectDropdown";

export interface IInviteTeamMemberOptions {
    forename: string;
    surname: string;
    email: string;
    contactType: string;
    contactId: string;
    teamId?: number;
}

export interface ITeamMembersListResponse {
    isPrincipleUser: boolean;
    teamName: string;
    data: ITeamMemberDto[];
    count: number;
}

export interface IContactTypeAssignableRoles {
    contactTypeName: string;
    contactTypeId: string;
    assignableRoles: Array<IOptions>;
}

export interface IEditTeamName {
    loading: boolean,
    isEditing: boolean;
}

export interface ITeamMemberDto {
    userTeamId?: number;
    userId?: string;
    contactId?: string;
    userName?: string;
    teamId?: number;
    teamRoleId?: string;
    teamRoleName?: string;
    createdByUserId?: string;
    isCurrent?: boolean;
    dateCreated?: Date;
}

export interface IAddTeamMemberRequest {
    forename: string;
    surname: string;
    emailAddress: string;
    teamId?: number;
    userId?: string;
    teamRoleId: string;
    contactTypeId: string;
    contactId: string;
}

export interface IChangeTeamNameRequest {
    teamName: string;
}

export interface IToggleTeamMemberAccessRequest {
    userId: string;
    teamId: number;
    active: boolean;
}

export interface ITeamMembersListItemOptions {
    // Pass 0 size to get all records
    size: number;
    page: number;
    skip?: number;
    teamId?: number;
    statusId?: number;
    roleId?: string;
    search?: string;
    sortQuery?: string;
}

export enum TeamsActionType {
    TOGGLE_ADD_TEAM_MEMBER_SIDE_PANEL = 'TOGGLE_ADD_TEAM_MEMBER_SIDE_PANEL',
    GET_TEAM_MEMBERS = 'GET_TEAM_MEMBERS',
    GET_TEAM_MEMBERS_SUCCESS = 'GET_TEAM_MEMBERS_SUCCESS',
    GET_TEAM_MEMBERS_ERROR = 'GET_TEAM_MEMBERS_ERROR',
    ADD_TEAM_MEMBER = 'ADD_TEAM_MEMBER',
    ADD_TEAM_MEMBER_SUCCESS = 'ADD_TEAM_MEMBER_SUCCESS',
    ADD_TEAM_MEMBER_ERROR = 'ADD_TEAM_MEMBER_ERROR',
    REMOVE_TEAM_MEMBERS = 'REMOVE_TEAM_MEMBERS',
    REMOVE_TEAM_MEMBERS_SUCCESS = 'REMOVE_TEAM_MEMBERS_SUCCESS',
    REMOVE_TEAM_MEMBERS_ERROR = 'REMOVE_TEAM_MEMBERS_ERROR',
    CANCEL_TEAM_MEMBER_INVITE = 'CANCEL_INVITE',
    CANCEL_TEAM_MEMBER_INVITE_SUCCESS = 'CANCEL_INVITE_SUCCESS',
    CANCEL_TEAM_MEMBER_INVITE_ERROR = 'CANCEL_INVITE_ERROR',
    TOGGLE_TEAM_MEMBER_ACCESS = 'TOGGLE_TEAM_MEMBER_ACCESS',
    TOGGLE_TEAM_MEMBER_ACCESS_SUCCESS = 'TOGGLE_TEAM_MEMBER_ACCESS_SUCCESS',
    TOGGLE_TEAM_MEMBER_ACCESS_ERROR = 'TOGGLE_TEAM_MEMBER_ACCESS_ERROR',
    EDIT_TEAM_NAME = 'EDIT_TEAM_NAME',
    EDIT_TEAM_NAME_SUCCESS = 'EDIT_TEAM_NAME_SUCCESS',
    EDIT_TEAM_NAME_ERROR = 'EDIT_TEAM_NAME_ERROR',
    TOGGLE_EDIT_TEAM_NAME = 'TOGGLE_EDIT_TEAM_NAME',
    CLEAR_TEAM_MEMBERS = 'CLEAR_TEAM_MEMBERS'
}

export interface IClearTeamMembers {
    type: TeamsActionType.CLEAR_TEAM_MEMBERS;
}

export interface IToggleEditTeamName {
    type: TeamsActionType.TOGGLE_EDIT_TEAM_NAME;
    payload: boolean;
}

export interface IEditTeamNameAction {
    type: TeamsActionType.EDIT_TEAM_NAME;
}

export interface IEditTeamNameSuccessAction {
    type: TeamsActionType.EDIT_TEAM_NAME_SUCCESS;
    payload: string;
}

export interface IEditTeamNameErrorAction {
    type: TeamsActionType.EDIT_TEAM_NAME_ERROR;
    payload: string;
}

export interface IToggleTeamMemberAccessAction {
    type: TeamsActionType.TOGGLE_TEAM_MEMBER_ACCESS;
}

export interface IToggleTeamMemberAccessSuccessAction {
    type: TeamsActionType.TOGGLE_TEAM_MEMBER_ACCESS_SUCCESS;
    payload: ITeamMemberDto;
}

export interface IToggleTeamMemberAccessErrorAction {
    type: TeamsActionType.TOGGLE_TEAM_MEMBER_ACCESS_ERROR;
    payload: string;
}

export interface IToggleCancelTeamMemberInviteAction {
    type: TeamsActionType.CANCEL_TEAM_MEMBER_INVITE;
}

export interface IToggleCancelTeamMemberInviteSuccessAction {
    type: TeamsActionType.CANCEL_TEAM_MEMBER_INVITE_SUCCESS;
    payload: string;
}

export interface IToggleCancelTeamMemberInviteErrorAction {
    type: TeamsActionType.CANCEL_TEAM_MEMBER_INVITE_ERROR;
    payload: string;
}

export interface IToggleAddTeamMemberAction {
    type: TeamsActionType.TOGGLE_ADD_TEAM_MEMBER_SIDE_PANEL;
    payload: {
        open: boolean,
        options?: IInviteTeamMemberOptions
    };
}

export interface IAddTeamMemberAction {
    type: TeamsActionType.ADD_TEAM_MEMBER;
}

export interface IAddTeamMemberSuccessAction {
    type: TeamsActionType.ADD_TEAM_MEMBER_SUCCESS;
    payload: ITeamMemberDto;
}

export interface IAddTeamMemberErrorAction {
    type: TeamsActionType.ADD_TEAM_MEMBER_ERROR;
    payload: string;
}

export interface IGetTeamMembersAction {
    type: TeamsActionType.GET_TEAM_MEMBERS;
}

export interface IGetTeamMembersSuccessAction {
    type: TeamsActionType.GET_TEAM_MEMBERS_SUCCESS;
    payload: ITeamMembersListResponse;
}

export interface IGetTeamMembersErrorAction {
    type: TeamsActionType.GET_TEAM_MEMBERS_ERROR;
    payload: string;
}

export interface IAddTeamMemberAction {
    type: TeamsActionType.ADD_TEAM_MEMBER;
}

export interface IAddTeamMemberSuccessAction {
    type: TeamsActionType.ADD_TEAM_MEMBER_SUCCESS;
}

export interface IAddTeamMemberErrorAction {
    type: TeamsActionType.ADD_TEAM_MEMBER_ERROR;
    payload: string;
}

export interface IRemoveTeamMembersAction {
    type: TeamsActionType.REMOVE_TEAM_MEMBERS;
}

export interface IRemoveTeamMembersSuccessAction {
    type: TeamsActionType.REMOVE_TEAM_MEMBERS_SUCCESS;
    payload: number[];
}

export interface IRemoveTeamMembersErrorAction {
    type: TeamsActionType.REMOVE_TEAM_MEMBERS_ERROR;
    payload: string;
}

export type TeamsAction =
    IClearTeamMembers |
    IToggleEditTeamName |
    IEditTeamNameAction |
    IEditTeamNameSuccessAction |
    IEditTeamNameErrorAction |
    IToggleTeamMemberAccessAction |
    IToggleTeamMemberAccessSuccessAction |
    IToggleTeamMemberAccessErrorAction |
    IToggleCancelTeamMemberInviteAction |
    IToggleCancelTeamMemberInviteSuccessAction |
    IToggleCancelTeamMemberInviteErrorAction |
    IToggleAddTeamMemberAction |
    IAddTeamMemberAction |
    IAddTeamMemberSuccessAction |
    IAddTeamMemberErrorAction |
    IGetTeamMembersAction
    | IGetTeamMembersSuccessAction
    | IGetTeamMembersErrorAction
    | IRemoveTeamMembersAction
    | IRemoveTeamMembersSuccessAction
    | IRemoveTeamMembersErrorAction
    ;

