import { ITransactionItem } from "../types/Transaction"

interface ITransactionState {
    transactions: Array<ITransactionItem>;
}

const initialState = {
    transactions: []
}

export default (state: ITransactionState = initialState, action: any): ITransactionState => {
    switch (action.type) {
        case "UPDATE_TRANSACTION_LIST":
            return {
                ...state,
                transactions: action.data
            }

        default:
            return state
    }
}