
export const durations = [
    {
        label: "Days",
        value: "Days"
    },
    {
        label: "Weeks",
        value: "Weeks"
    },
    {
        label: "Months",
        value: "Months"
    },
    {
        label: "Years",
        value: "Years"
    }
]

export const rentPaymentDurations = [
    {
        label: "Daily",
        value: "Daily"
    },
    {
        label: "Weekly",
        value: "Weekly"
    },
    {
        label: "Bi Weekly",
        value: "BiWeekly"
    },
    {
        label: "Monthly",
        value: "Monthly",
        isDefault: true
    },
    {
        label: "Quarterly",
        value: "Quarterly"
    },
    {
        label: "Half Yearly",
        value: "HalfYearly"
    },
    {
        label: "Yearly",
        value: "Yearly"
    },
    {
        label: "One Off",
        value: "OneOff"
    }
]

export const despositSchemes = [
    {
        label: "Deposit Protection Service",
        value: "Deposit Protection Service"
    },
    {
        label: "MyDeposits",
        value: "MyDeposits"
    },
    {
        label: "Tenancy Deposit Scheme",
        value: "Tenancy Deposit Scheme"
    },
    {
        label: "Other",
        value: "Other"
    }
]
