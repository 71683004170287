import React, { useContext, useEffect, useState } from 'react';
import ConductInspectionContext from '../../../../types/Dynamic/conductInspectionContext';
import { QuestionComponentProps } from '../../../../types/Dynamic/types';
import Error from '../Error';
import { Required } from '../Validations';
import styles from './TextBox.module.scss';

const TextBox = ({ validations, properties: { rows, maxLength, minLength, placeholder }, resultValue, onChange, isNumeric, isReadOnly, objectRoute }: QuestionComponentProps) => {
    const { updateValidations, removeValidation, addValidationFn } = useContext(ConductInspectionContext);
    const [initialLoad, setInitialLoad] = useState(false);

    const validate = (): { isError } => {
        if (validations) {
            const { required } = validations;
            if (required) {
                if (Required(resultValue)) {
                    updateValidations(objectRoute, validations.errorMessage || 'This is a required field');
                    return { isError: true }
                } else {
                    removeValidation(objectRoute)
                    return { isError: false }
                }
            }
            return { isError: false }
        }

        return { isError: false }
    }

    useEffect(() => {
        if (initialLoad) {
            validate();
        }

        addValidationFn(objectRoute, validate);
        setInitialLoad(true);
    }, [resultValue])

    const onChangeHandler = (e) => {
        let value = e.currentTarget.value;
        if (maxLength) {
            if (value.length > maxLength) {
                return;
            }
        }

        onChange(e.currentTarget.value);
    }

    if (isNumeric) {
        return (<>
            <input disabled={isReadOnly}
                type="number"
                onChange={onChangeHandler}
                className={styles.input}
                minLength={minLength}
                maxLength={maxLength}
                placeholder={placeholder}
                value={resultValue}
                width={40} />

            <Error name={objectRoute} />
        </>)
    }

    return (
        <>
            <textarea disabled={isReadOnly}
                onChange={onChangeHandler}
                rows={rows}
                cols={40}
                className={styles.input}
                minLength={minLength}
                maxLength={maxLength}
                placeholder={placeholder}
                value={resultValue}
            />

            <Error name={objectRoute} />
        </>
    )
}

export default TextBox;
