import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import styles from "./IssueListItem.module.scss";
import actionEdit from "../../../images/icons/action_edit.svg";
import actionDelete from "../../../images/icons/action_delete.svg";
import { withRouter } from "react-router-dom";
import {
  IIssueItem,
  IIssueActionMenuOptions,
} from "../../../types/IssueService";
import DateTimeFormat from "../DateTimeFormat";
import DeleteIssueModal from "../DeleteIssueModal";
import { IRaiseIssueOptions } from "../AddEditIssue";
import {
  addURLParameter,
  changeUrlPathParameter,
} from "../../../helpers/locationHelpers";
import {
  toggleIssuePopup,
  toggleIssueActionSidebar,
  toggleIssueListCollapse,
} from "../../../helpers/sidebarHelpers";
import { canDeleteIssue } from "../../../helpers/permissionHelpers";
import { getFirstIssueId } from "../../../helpers/issueHelpers";
import EllipsisText from "../EllipsisText";
import ContextMenu from "../ContextMenu";
import ActionButton from "../ActionButton";

interface IProps {
  history: any;
  location: any;
  match: any;

  issue: IIssueItem;
  selected: boolean;
  goToIssueDirect?: boolean;
}

interface IState {
  deleting: boolean;
}

class IssueListItem extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      deleting: false,
    };

    this.onClickEdit = this.onClickEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onClickView = this.onClickView.bind(this);
    this.onOpenActionMenu = this.onOpenActionMenu.bind(this);
    this.onClickItem = this.onClickItem.bind(this);
    this.onClickDesktop = this.onClickDesktop.bind(this);
    this.onClickMobile = this.onClickMobile.bind(this);
  }

  onClickView(): void {
    this.props.history.push(`/dashboard/issue/${this.props.issue.issueId}`);
  }

  onClickEdit(): void {
    let query: IRaiseIssueOptions = {
      issueId: this.props.issue.issueId,
    };

    let queryParam = addURLParameter(
      this.props.location.search,
      "issueOptions=" + JSON.stringify(query)
    );
    this.props.history.push({
      search: queryParam,
    });

    toggleIssuePopup(true);
  }

  onDelete(): void {
    let id = getFirstIssueId();
    let newPath = changeUrlPathParameter(this.props.match, id || null);
    this.props.history.push(newPath);
  }

  onOpenActionMenu(): void {
    let options: IIssueActionMenuOptions = {
      issueId: this.props.issue.issueId,
      issueTitle: encodeURIComponent(this.props.issue.title),
    };

    let query = addURLParameter(
      this.props.location.search,
      "actionIssue=" + JSON.stringify(options)
    );
    this.props.history.push({
      search: query,
    });
    toggleIssueActionSidebar(true);
  }

  onClickItem(): void {
    if (this.props.goToIssueDirect) {
      this.onClickView();
      return;
    }

    let newPath = changeUrlPathParameter(
      this.props.match,
      this.props.issue.issueId
    );
    this.props.history.push(newPath);
  }

  onClickDesktop(): void {
    this.onClickItem();
  }

  onClickMobile(): void {
    toggleIssueListCollapse(true);
    this.onClickItem();
  }

  render() {
    if (!this.props.issue) {
      return null;
    }

    return (
      <div
        className={`${styles.listItem} ${this.props.selected && styles.active}`}
      >
        <DeleteIssueModal
          issueTitle={this.props.issue.title}
          issueId={this.props.issue.issueId}
          open={this.state.deleting}
          onOpenModal={null}
          onCloseModal={() => this.setState({ deleting: false })}
          onDeleteCallBack={() => {
            this.onDelete();
          }}
        />
        <div className={styles.item}>
          <Row>
            <div className={styles.mobileToggle}>
              <Col onClick={this.onClickMobile}>
                <span className={styles.title}>
                  Issue - {this.props.issue.title}
                </span>{" "}
                <span className={styles.label}>
                  {this.props.issue.propertyName}
                </span>
              </Col>
            </div>

            <div className={styles.desktopToggle}>
              <Col onClick={this.onClickDesktop}>
                <span className={styles.title}>
                  Issue - {this.props.issue.title}
                </span>{" "}
                <span className={styles.label}>
                  {this.props.issue.propertyName} {this.props.issue.unitId ? `- ${this.props.issue.unitName}` : ''}
                </span>
              </Col>
            </div>
          </Row>

          <div className={styles.row}>
            <div
              className={`${styles.column} ${styles.desktopToggle}`}
            //onClick={this.onClickDesktop}
            >
              <p className={styles.noMargin}>
                <span className={styles.description}>
                  <EllipsisText
                    text={this.props.issue.description}
                    length={30}
                  />
                </span>
              </p>
              <p className={styles.addedContext}>
                <div className={styles.addedBy}>
                  Added{" "}
                  <DateTimeFormat
                    showTimeAgoFormat
                    rawDateTime={this.props.issue.created}
                    showOnlyDateFormat={false}
                  />{" "}
                  by {this.props.issue.createdBy}
                </div>
                <div className={styles.contextMenu}>
                  <ContextMenu
                    clickableComponent={<ActionButton />}
                    menuItems={[
                      {
                        text: "Edit",
                        canView: true,
                        icon: actionEdit,
                        onItemClick: () => this.onClickEdit(),
                      },
                      {
                        text: "Delete",
                        canView: canDeleteIssue(),
                        icon: actionDelete,
                        onItemClick: () => this.setState({ deleting: true }),
                      },
                    ]}
                  />
                </div>
              </p>
            </div>

            <div
              className={`${styles.column} ${styles.mobileToggle}`}
              onClick={this.onClickMobile}
            >
              <p className={styles.noMargin}>
                <span className={styles.description}>
                  <EllipsisText
                    text={this.props.issue.description}
                    length={30}
                  />
                </span>
              </p>
              <p className={styles.addedContext}>
                <div className={styles.addedBy}>
                  Added{" "}
                  <DateTimeFormat
                    showTimeAgoFormat
                    rawDateTime={this.props.issue.created}
                    showOnlyDateFormat={false}
                  />{" "}
                  by {this.props.issue.createdBy}
                </div>
                <div className={styles.contextMenu}>
                  <ContextMenu
                    clickableComponent={<ActionButton />}
                    menuItems={[
                      {
                        text: "Edit",
                        canView: true,
                        icon: actionEdit,
                        onItemClick: () => this.onClickEdit(),
                      },
                      {
                        text: "Delete",
                        canView: canDeleteIssue(),
                        icon: actionDelete,
                        onItemClick: () => this.setState({ deleting: true }),
                      },
                    ]}
                  />
                </div>
              </p>
            </div>
          </div>
        </div>
        {this.props.issue.priority == "High" && (
          <div className={styles.highPriority}>!</div>
        )}
        <div
          className={`${styles.statusFloat} ${this.props.issue.status == "New"
            ? styles.new
            : this.props.issue.status == "In Progress"
              ? styles.inProgress
              : this.props.issue.status == "Completed" ||
                this.props.issue.status == "Closed"
                ? styles.completed
                : styles.new
            }`}
        >
          <span className={styles.status}>{this.props.issue.status}</span>
        </div>
      </div>
    );
  }
}

export default withRouter(IssueListItem);