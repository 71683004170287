import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { toggleAddPropertySidebar } from '../../../../../actions/appActions';
import { IDashboardData } from '../../../../../types/DashboardService'
import SectionHeader from "../SectionHeader/SectionHeader.component"
import CounterPart from "../CounterPart/CounterPart.component"
import { ISection } from '../../PropertyDashboard/_config/dashboardConfig';
import InitialStateSection from '../../Sections/InitialStateSection/InitialStateSection.component';
import * as S from "./ActivePropertiesSection.styles";
import { useFetchTotalStatsData } from '../../../../../servicesHooks/useFetchTotalStatsData.hook';
import { Loader } from '@rentchief/components';

interface sectionProps {
    id: string;
    header: string;
    hideOnClick(id: string): void;
    propertyId?: string;
    config: ISection;
}

const ActivePropertiesSection = ({id, header, hideOnClick, propertyId, config}: sectionProps) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { dataRetrieved, data } = useFetchTotalStatsData(propertyId);

    const onNavigate = (id?: string): void => {
        var url = '/dashboard/units'
    
        switch(id){
            case "Properties": url = '/dashboard/properties?filterAmount=8&viewType=0&sort=0&isSorting=false&propertyStatus=all'; break;
            case "Units":
                url = propertyId ? `/dashboard/property/${propertyId}/spec/units` : '/dashboard/units'; break;
            case "Assets": 
                url = propertyId ? `/dashboard/property/${propertyId}/spec/assets` : '/dashboard/assets'; break;
        }
    
        history.push(url)
    }

    const addProperty = () => {
        // To pass some default values see code in \src\components\atoms\IssuesStatus\index.tsx
        dispatch(toggleAddPropertySidebar(true));
    }

    return (
        <>
        {
            !dataRetrieved
            ?
            <Loader />
            :
            data && data.atAGlance.totalProperties > 0
            ?
            <S.SectionDiv>
                <SectionHeader id={id} header={header} hideOnClick={hideOnClick} titleOnClick={() => onNavigate("Properties")}/>
                <S.NewDiv><CounterPart id="Properties" value={data && data.atAGlance.totalProperties} type="Properties" hasAdd={true} addOnClick={addProperty} valueOnClick={onNavigate} /></S.NewDiv>
                <S.InProgressDiv><CounterPart id="Units" value={data && data.atAGlance.totalUnits} type="Units" hasAdd={true} addOnClick={() => history.push('dashboard/unit/add')} valueOnClick={onNavigate}/></S.InProgressDiv>
                <S.TotalDiv><CounterPart id="Assets" value={data && data.atAGlance.totalAssets} type="Assets" hasAdd={true} addOnClick={() => history.push('dashboard/asset/add')} valueOnClick={onNavigate} /></S.TotalDiv>
            </S.SectionDiv>
            :
            <InitialStateSection 
                id="properties"
                header={config && config.header} 
                iconUrl={config && config.initialState.iconUrl}
                title={config && config.initialState.title}
                summary={config && config.initialState.summary}
                //action={<S.ActionDiv><Button onClick={addProperty}>Add</Button></S.ActionDiv>}
                hideOnClick={hideOnClick}
                addOnClick={addProperty}
            />
        }
        </>
        
    )
}

export default ActivePropertiesSection;