import { IUnitItem } from "../types/UnitService"

interface IUnitState {
    viewing_unit: IUnitItem;
}

const initialState: IUnitState = {
    viewing_unit: null
}

export default (state: IUnitState = initialState, action: any): IUnitState => {
    switch (action.type) {

        case "UPDATE_VIEWING_UNIT":
            return {
                ...state,
                viewing_unit: action.data
            }

        default:
            return state
    }
}