// Import React
import React, { Component } from "react";
import { Col } from "reactstrap";

// Import CSS
require("react-dd-menu/dist/react-dd-menu.css");

import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { connect } from "react-redux";

import styles from "./DashboardNavigationBar.module.scss";

import rentchiefLogoSmall from "../../../images/rent_chief_logo.svg";
import menuIcon from "../../../images/icon-menu.png";
import smallLogo from "../../../images/favicon-32x32.png";
import chatIcon from "../../../images/icons/header_chat_icon.svg";

import { INotification } from "../../../types/NotificationService";
import {
  toggleSidebar,
  toggleAddPropertySidebar,
  toggleProfileSidebar
} from "../../../actions/appActions";
import { IUserListItem } from "../../../types/ClientService";
import { toggleMobileSidebar } from "../../../helpers/sidebarHelpers";
import UserContactInfo, {
  UserContactInfoType
} from "../../atoms/UserContactInfo";
import HelpIcon from "../../../images/icons/ic_help_outline_24px.svg";
import { elevioHelpLinkUrl } from "../../../configuration/appConfig";
import { toggleStickyNav } from "../../../helpers/loadingHelpers";
import UnreadChatCountRedux from "../../atoms/UnreadChatCountRedux";
import { openElevio } from "../../../helpers/elevioHelpers";

interface IRouterProps {
  history: any;
  location: any;
  match: any;
  user: IUserListItem;
}

interface IReduxState {
  toggleSidebar(show: boolean): void;
  toggleAddPropertySidebar(show: boolean): void;
  toggleProfileSidebar(show: boolean): void;
  forename: string;
  surname: string;
  logo: string;
  secondaryLogo: string;
  sidebar: boolean;
  profileImageUri: string;
  userId: string;
  role: string;
  is_impersonating: boolean;
  is_sticky_nav: boolean;
}

interface IState {
  isOpen: boolean;
  addPropertySidebarOpen: boolean;
  navSidebarOpen: boolean;
  profileSidebarOpen: boolean;
  notifications: Array<INotification>;
  notificationsOpen: boolean;
  isStickyNav: boolean;
  isMenuOpen: boolean;
}

type Props = IRouterProps & IReduxState;

class DashboardNavigationBar extends Component<Props, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isOpen: false,
      addPropertySidebarOpen: false,
      navSidebarOpen: false,
      notifications: [],
      notificationsOpen: false,
      isStickyNav: false,
      isMenuOpen: false,
      profileSidebarOpen: false
    };

    this.toggleSidebarNavigation = this.toggleSidebarNavigation.bind(this);
    this.onSetSidebarNavOpen = this.onSetSidebarNavOpen.bind(this);
    this.onSetSidebarAddPropertyOpen = this.onSetSidebarAddPropertyOpen.bind(
      this
    );
    this.toggleNotifications = this.toggleNotifications.bind(this);
    this.onSetSidebarProfileOpen = this.onSetSidebarProfileOpen.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);
    this.click = this.click.bind(this);
    this.onToggleMobileSidebar = this.onToggleMobileSidebar.bind(this);
  }

  toggleSidebarNavigation() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  onSetSidebarNavOpen(open) {
    this.setState({ navSidebarOpen: open });
  }

  onSetSidebarAddPropertyOpen(open) {
    this.setState({ addPropertySidebarOpen: open });
  }

  onSetSidebarProfileOpen(open) {
    this.setState({ profileSidebarOpen: open });
  }

  toggleNotifications() {
    this.setState({
      notificationsOpen: !this.state.notificationsOpen
    });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.pageYOffset >= 70 && this.state.isStickyNav) {
      return;
    }

    if (window.pageYOffset >= 70 && !this.state.isStickyNav) {
      this.setState({
        isStickyNav: true
      });
      toggleStickyNav(true);
    }

    if (window.pageYOffset < 70 && this.state.isStickyNav) {
      this.setState({
        isStickyNav: false
      });
      toggleStickyNav(false);
    }
  };

  toggle() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }

  close() {
    this.setState({ isMenuOpen: false });
  }

  onToggleMobileSidebar(): void {
    toggleMobileSidebar();
  }

  click() { }

  render() {
    return (
      <div>
        <div
          className={
            this.props.is_sticky_nav
              ? `${styles.stickyWrapper}`
              : `${styles.wrapper}`
          }
          id="dashboardNavigationBar"
        >
          <Col>
            <div className={styles.nav} id="logo">
              <div className={styles.left}>
                <span className={styles.menu}>
                  <img
                    className={styles.mobile}
                    src={menuIcon}
                    onClick={() => this.onToggleMobileSidebar()}
                  />
                  <img
                    className={styles.desktop}
                    src={menuIcon}
                    onClick={() => this.props.toggleSidebar(!this.props.sidebar)}
                  />
                </span>
              </div>

              <div className={styles.center}>
                <img
                  className={`${styles.smallLogo} navLogo`}
                  src={
                    this.props.secondaryLogo
                      ? this.props.secondaryLogo
                      : smallLogo
                  }
                  alt="Small Logo"
                />
                <img
                  onClick={() => this.props.history.push("/dashboard")}
                  className={`${styles.logo} navLogo`}
                  src={this.props.logo ? this.props.logo : rentchiefLogoSmall}
                />
              </div>

              <div className={styles.profile}>
                <div className={styles.row}> 
                  <div className={`${styles.column} ${styles.helpColumn}`}>
                    <div className={styles.chatIcon} onClick={() => this.props.history.push(`/dashboard/chats`)}>
                      <img className={styles.chatIc} src={chatIcon} alt="Chat" />
                      <span className={styles.count}>
                        <UnreadChatCountRedux />
                      </span>
                    </div>
                  </div>

                  <div className={`${styles.column} ${styles.helpColumn}`}>
                    <a onClick={() => openElevio()} target="_blank"><img className={styles.helpIcon} src={HelpIcon} alt="Help" /></a>
                  </div> 

                  <div className={styles.column}>
                    <UserContactInfo
                      classNames={{
                        wrapper: styles.profileWrapper,
                        row: styles.userInfoRow,
                        textWrapper: styles.textWrapper,
                        image: styles.image,
                        userIcon: styles.userIcon,
                        typeOrRole: styles.typeOrRole
                      }}
                      onImageClick={() => this.props.toggleProfileSidebar(true)}
                      disablePopup
                      manualOverride={{
                        forename: this.props.forename,
                        surname: this.props.surname,
                        profileImage: this.props.profileImageUri,
                        userId: this.props.userId,
                        isUser: true,
                        typeOrRole: this.props.role
                      }}
                      type={UserContactInfoType.User}
                      id={this.props.userId}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  forename: state.user.forename,
  surname: state.user.surname,
  profileImageUri: state.user.profileImage,
  logo: state.client.logo,
  secondaryLogo: state.client.secondaryLogo,
  sidebar: state.app.sidebars.side_nav,
  userId: state.user.user_id,
  role: state.user.role,
  is_impersonating: state.app.is_impersonating,
  is_sticky_nav: state.app.is_sticky_nav
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    toggleSidebar: (show: boolean) => {
      dispatch(toggleSidebar(show));
    },
    toggleAddPropertySidebar: (show: boolean) => {
      dispatch(toggleAddPropertySidebar(show));
    },
    toggleProfileSidebar: (show: boolean) => {
      dispatch(toggleProfileSidebar(show));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardNavigationBar) as any
);
