import React from "react";
import { ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-grids";
import { minWidthTablet } from "../../../../../global/variables/_variables";
import { Router } from "react-router-dom";
import AssociatedDocuments from "../../../../atoms/AssociatedDocuments";
import history, { goToRoute } from "../../../../../history";
import TenancyStatus from "../components/TenancyStatus";
import styles from "../TenancyGrid.module.scss";
import Currency from "../../../../atoms/Currency";
import GenericPopup from "../../../../atoms/GenericPopup";
import { generateId } from "../../../../../helpers/randomHelpers";
import { QuickViewType } from "../../../../../types/QuickView";
import moment from "moment";

function rentAmount(props) {
  return (<div className={styles.amount}><Currency decimalCount={2} prefix="£" value={props.rentAmount} /></div>)
}

function property(props) {
  return (
    <div onClick={() => goToRoute(`/dashboard/tenancy/view/${props.tenancyId}`)}>
      <GenericPopup
        identifier={generateId(10)}
        options={{
          propertyId: props.propertyId,
          type: QuickViewType.Property,
          toggleComponent: <div>{props.propertyName}</div>
        }}
      />
    </div>
  )
}

function documents(props) {
  return (<Router history={history}><AssociatedDocuments
    showOnlyNoneText
    options={{
      tenancyId: props.tenancyId
    }}
    viewAll={false}
  /></Router>)
}

function rollingContract(props) {
  return (props.rollingContract ? <span>Yes</span> : <span>No</span>)
}

function electricity(props) {
  return (props.electricity ? <span>Yes</span> : <span>No</span>)
}

function gas(props) {
  return (props.gas ? <span>Yes</span> : <span>No</span>)
}

function water(props) {
  return (props.water ? <span>Yes</span> : <span>No</span>)
}

function tvLicense(props) {
  return (props.tvLicense ? <span>Yes</span> : <span>No</span>)
}

function skyOrCable(props) {
  return (props.skyOrCable ? <span>Yes</span> : <span>No</span>)
}

function internet(props) {
  return (props.internet ? <span>Yes</span> : <span>No</span>)
}

function phone(props) {
  return (props.phone ? <span>Yes</span> : <span>No</span>)
}

function tenancyStatus(props) {
  return (<div onClick={() => goToRoute(`/dashboard/tenancy/view/${props.tenancyId}`)}><TenancyStatus status={props.status} /></div>)
}

function startDate(props) {
  return (<div onClick={() => goToRoute(`/dashboard/tenancy/view/${props.tenancyId}`)}>{moment(props.startDate).format('DD/MM/YY')}</div>)
}

function endDate(props) {
  return (<div onClick={() => goToRoute(`/dashboard/tenancy/view/${props.tenancyId}`)}>{moment(props.endDate).format('DD/MM/YY')}</div>)
}

function duration(props) {
  return (<div onClick={() => goToRoute(`/dashboard/tenancy/view/${props.tenancyId}`)}>{props.duration}</div>)
}

function unit(props) {
  return (<div onClick={() => goToRoute(`/dashboard/tenancy/view/${props.tenancyId}`)}>{props.unitName}</div>)
}

export var DefaultTemplate = function (props: any) {
  return (
    <ColumnsDirective>
      <ColumnDirective
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        type="checkbox"
        width="50"
      />

      <ColumnDirective
        textAlign="Left"
        width="100"
        field="action"
        headerText=""
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Tenant"
        field="tenantName"
        width="180"
      />

      <ColumnDirective
        textAlign="Left"
        field="landlordContactId"
        headerText="Landlord Contact ID"
        width="120"
        visible={false}
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        textAlign="Left"
        field="reference"
        headerText="Reference"
        width="120"
        visible={false}
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Property"
        field="propertyName"
        width="100"
        template={property}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Unit"
        field="unitName"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={unit}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Amount"
        field="rentAmount"
        width="100"
        type="number"
        template={rentAmount}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Duration"
        field="duration"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={duration}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Start"
        field="startDate"
        type="date"
        format={{ type: "date", format: "dd/MM/yy" }}
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={startDate}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="End"
        field="endDate"
        type="date"
        format={{ type: "date", format: "dd/MM/yy" }}
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={endDate}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Docs"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={documents}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Status"
        field="status"
        width="150"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={tenancyStatus}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Created by"
        field="createdByName"
        width="100"
        visible={false}
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Created date"
        field="createdDate"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        visible={false}
        type="date"
        format={{ type: "date", format: "dd/MM/yy" }}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Tenancy Type"
        field="tenancyType"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        type="date"
        format={{ type: "date", format: "dd/MM/yy" }}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Contract Type"
        field="contractType"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Rolling?"
        field="rollingContract"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={rollingContract}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Move in Date"
        field="moveInDate"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        type="date"
        format={{ type: "date", format: "dd/MM/yy" }}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Move out Date"
        field="moveOutDate"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        type="date"
        format={{ type: "date", format: "dd/MM/yy" }}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Rent Review Date"
        field="rentReviewDate"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        type="date"
        format={{ type: "date", format: "dd/MM/yy" }}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Tenant Notice"
        field="tenantNoticePeriod"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Landlord Notice"
        field="landlordNoticePeriod"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Rent Frequency"
        field="rentFrequency"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Day of Month Paid"
        field="dayOfMonthPaid"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Rent Pay Method"
        field="rentPayMethod"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Deposit"
        field="deposit"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        type="number"
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Deposit Scheme"
        field="depositScheme"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Deposit Reference"
        field="depositReference"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Electricity"
        field="electricity"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={electricity}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Gas"
        field="gas"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={gas}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Water"
        field="water"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={water}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="TV License"
        field="tvLicense"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={tvLicense}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Sky/Cable"
        field="skyOrCable"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={skyOrCable}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Internet"
        field="internet"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={internet}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Phone"
        field="phone"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={phone}
      />
    </ColumnsDirective>
  );
};