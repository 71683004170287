import { apiService } from './ApiService'
import * as domains from '../configuration/domains'
import { createBearerToken } from '../helpers/authHelpers'
import { toQueryString } from './DocumentService'
import { IDocumentUpload } from '../components/molecules/AddDocumentModal'
import { IListItemOptions } from '../types/ListFilters'
import { IUploadRequest } from '../types/ImageService'

export interface INewMessageResponse {
    listItem: IMessageListItem
    chatMessage: IChatItem
}

export interface IStartDiscussionResponse {
    item: IChatItem
}

export interface IChatOptions {
    issueId?: string
    tenancyId?: string
    propertyId?: string
    contactId?: string
    chatSessionId?: string
    userIds?: Array<string>
}

export interface IChatItem {
    locChatMessageId: string;
    dateAdded: string
    message: string
    isCurrentUserSender: boolean
    sender: IChatSender
    systemMessage: boolean
    isSuccess?: boolean
    isImage: boolean
    imageUri: string
    fileExtension?: string
}

export type ChatMessageType = Text | File

export interface IChatSender {
    senderForename: string
    senderSurname: string
    senderProfileImageUri: string
    senderUserId: string
}

export interface IViewingHistory {
    name: string
    lastViewed: string
}

export interface IChatUsers {
    userId: string
    forename: string
    surname: string
    profileImage: string
    createdChat: boolean
}

export interface IAddChatRequest {
    chatSessionId: string
    message: string
}

export interface IGetMessageResponse {
    chatSessionId: string
    messages: Array<IChatItem>
    viewingHistory: Array<IViewingHistory>
    chatUsers: Array<IChatUsers>
}

export interface IGetMessagesListResponse {
    sessions: Array<IMessageListItem>
}

export interface IMessageListItem {
    chatSessionId: string
    title: string
    lastMessage: string
    lastMessageDateTime: string
    hasOpened: boolean
    lastMessageSenderProfileImageUri: string
    lastMessageSenderForename: string
    lastMessageSenderSurname: string
    unreadMessageCount: number
    lastOpened: string
}

export interface IAddUserToChatSession {
    chatSessionId: string
    userId: string
}

export const markChatAsRead = (chatSessionId: string): Promise<any> => {
    return apiService.get(
        `${domains.chatService}/mark-chat-as-read?chatSessionId=${chatSessionId}`,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: createBearerToken(),
            },
        }
    )
}

export const deleteChatByChatSessionId = (
    chatSessionId: string
): Promise<any> => {
    return apiService.delete(`${domains.chatService}/${chatSessionId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: createBearerToken(),
        },
    })
}

export const getUnreadMessageCountForChatSessionId = (
    chatSessionId: string
): Promise<any> => {
    return apiService.get(
        `${domains.chatService}/unread-messages/${chatSessionId}`,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: createBearerToken(),
            },
        }
    )
}

export const getUnreadMessages = (): Promise<any> => {
    return apiService.get(`${domains.chatService}/unread-messages`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: createBearerToken(),
        },
    })
}

export const addUserToChatSession = (
    request: IAddUserToChatSession
): Promise<any> => {
    return apiService.post(
        `${domains.chatService}/chat-session-user`,
        request,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: createBearerToken(),
            },
        }
    )
}

export const sendMessage = (
    request: IAddChatRequest,
    files?: FormData
): Promise<any> => {
    var q = toQueryString(request)

    return apiService.post(`${domains.chatService}?${q}`, files, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'multipart/form-data',
            Authorization: createBearerToken(),
        },
    })
}

export const getMessages = (request: IChatOptions): Promise<any> => {
    var stringed = toQueryString(request)

    return apiService.get(`${domains.chatService}?${stringed}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: createBearerToken(),
        },
    })
}

export const getMessagesList = (options: IListItemOptions): Promise<any> => {
    var stringed = toQueryString(options)

    return apiService.get(`${domains.chatService}/list?${stringed}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: createBearerToken(),
        },
    })
}

export const startDiscussion = (request: IChatOptions): Promise<any> => {
    var stringed = toQueryString(request)

    return apiService.post(
        `${domains.chatService}/start?options=${stringed}`,
        request,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: createBearerToken(),
            },
        }
    )
}

export const getChatUsers = (chatSessionId: string): Promise<any> => {
    return apiService.get(
        `${domains.chatService}/chat-users/${chatSessionId}`,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: createBearerToken(),
            },
        }
    )
}
