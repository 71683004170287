import React, { ReactNode } from "react";
import styles from "./AddDocumentButton.module.scss";
import { IDocumentUploadOptions } from "../../molecules/AddDocumentModal";
import { addURLParameter } from "../../../helpers/locationHelpers";
import { toggleAddDocumentPopup } from "../../../helpers/sidebarHelpers";
import uploadIcon from "../../../images/icons/doc_upload.svg";
import { Tooltip } from "react-tippy";

declare module 'react-tippy' {
  export interface TooltipProps {
    children: ReactNode;
  }
}

interface IProps {
  params: any;
  propertyId?: string;
  contactId?: string;
  tenancyId?: string;
  unitId?: string;
  returnDocumentIds?: boolean;
  allowEmptyProperty?: boolean;
  name: string;
  documentTypeId?: any;
}

class AddDocumentButton extends React.Component<IProps> {
  constructor(props: any) {
    super(props);

    this.onAddDocumentClick = this.onAddDocumentClick.bind(this);
  }

  onAddDocumentClick(): void {
    if (!this.props.allowEmptyProperty) {
      if (!this.props.propertyId) {
        return;
      }
    }

    let propertyId = this.props.propertyId;

    let options: IDocumentUploadOptions = {
      propertyId: propertyId,
      dialogueTitle: this.props.name
    };

    if (this.props.returnDocumentIds) {
      options.returnDocumentIds = this.props.returnDocumentIds;
    }

    if (this.props.documentTypeId) {
      options.documentTypeId = this.props.documentTypeId;
    }

    if (this.props.contactId) {
      options.contactId = this.props.contactId;
    }

    if (this.props.tenancyId) {
      options.tenancyId = this.props.tenancyId;
    }

    if (this.props.unitId) {
      options.unitId = this.props.unitId;
    }

    let query = "uploadDocumentOptions=" + JSON.stringify(options);

    let search = addURLParameter(this.props.params.location.search, query);

    this.props.params.history.push({
      search
    });

    toggleAddDocumentPopup(true);
  }

  render() {
    return (
      <div
        className={styles.addButton}
        onClick={() => this.onAddDocumentClick()}
      >
        <Tooltip
          theme="light"
          html={
            <div className={styles.tooltipContent}>
              Upload document to the system
            </div>
          }
          position="right"
          trigger="mouseenter"
          arrow={true}
          interactive={true}
        >
          <img className={styles.addUserIcon} src={uploadIcon} />
        </Tooltip>
      </div>
    );
  }
}

export default AddDocumentButton;
