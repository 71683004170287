import React from 'react'
import { IconButtonType } from '../Buttons.types'
import styles from './IconButton.module.scss'
import { defaultText, iconMap } from './IconButton.types'

type Props = {
    button: IconButtonType
    iconOnly?: boolean
    align?: 'center'
    direction?: 'left' | 'right' | 'up' | 'down'
}

const IconButton = ({ align, button, direction, iconOnly }: Props) => {
    return (
        <button
            onClick={(e) => {
                e.preventDefault()
                if (button.onClick) {
                    button.onClick()
                }
            }}
            type={button.elementType}
            className={`${styles.button} ${styles[button.displayType]} ${
                button.width ? styles[button.width] : ''
            } ${iconOnly ? styles.iconOnly : ''} ${align ? styles.center : ''}`}
            disabled={button.disabled}
        >
            <div className={styles.content}>
                {button.icon && (
                    <div
                        className={`${styles.icon} ${
                            direction ? styles[direction] : ''
                        }`}
                    >
                        {iconMap[button.icon]}
                    </div>
                )}

                {!iconOnly && (
                    <div className={styles.text}>
                        {button.text
                            ? button.text
                            : defaultText[button.displayType]}
                    </div>
                )}
            </div>
        </button>
    )
}

export default IconButton
