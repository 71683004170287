import * as Yup from 'yup'

const ChangePasswordSchema = Yup.object().shape({
    password: Yup.string().nullable().required('Password is required'),
    newPassword: Yup.string()
        .nullable()
        .required('You must confirm your new password')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

const AcceptInvitationSchema = Yup.object().shape({
    password: Yup.string().nullable().required('Password is required'),
})

const ForgottenPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .nullable()
        .email('Invalid email')
        .required('Email is required'),
})

const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
})

const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string()
        .required('Password is required')
        .min(6, 'Password is too short - should be 6 chars minimum.'),
    forename: Yup.string().required('Forename is required'),
    surname: Yup.string().required('Surname is required'),
})

const AddNewUser = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
    forename: Yup.string().required('Forename is required'),
    surname: Yup.string().required('Surname is required'),
})

const AddContactSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email'),
    forename: Yup.string().required('Forename is required'),
    surname: Yup.string().required('Surname is required'),
    contactType: Yup.string().required('Contact type is required'),
})

const AddClient = Yup.object().shape({
    clientName: Yup.string().required('Client name is required'),
    clientDescription: Yup.string().required('Client description is required'),
})

const AddTeammate = Yup.object().shape({
    forename: Yup.string().nullable().required('Forename is required'),
    surname: Yup.string().nullable(),
    email: Yup.string()
        .nullable()
        .email('You must enter a valid email')
        .required('Email is required'),
    role: Yup.string().nullable().required('Role is required'),
    contactType: Yup.string().nullable().required('Contact Type is required'),
})

const UpdateProfile = Yup.object().shape({
    contactNumber: Yup.string()
        .min(11, 'Contact number should be 11 digit.')
        .max(11, 'Contact number should be 11 digit.')
        .nullable(),
})

const AddTenancy = Yup.object().shape({
    contactId: Yup.string().nullable().required('You must select a tenant'),
    //tenantName: Yup.string().nullable().required('You must select a tenant'),
    propertyName: Yup.string().nullable().required('You must select a property'),
})

const AddIssue = Yup.object().shape({
    title: Yup.string().required('You must enter a title for the issue'),
    description: Yup.string().required(
        'You must enter a description of the issue'
    ),
})

const EstimateValuation = Yup.object().shape({
    propertyType: Yup.string()
        .nullable()
        .required('You must select a property type'),
    yearBuilt: Yup.string().nullable().required('You must enter a year built'),
    size: Yup.string().nullable().required('You must enter a size'),
    sizeType: Yup.string()
        .nullable()
        //.min(1)
        .required('You must select a size type'),
    bedrooms: Yup.string().nullable().required('You must enter bedrooms'),
    bathrooms: Yup.string().nullable().required('You must enter bathrooms'),
    finishQuality: Yup.string()
        .nullable()
        .required('You must select a finish quality'),
    outdoorSpace: Yup.string()
        .nullable()
        .required('You must select an outdoor space'),
    numberOfSpaces: Yup.string().nullable(),
})

const AddTransaction = Yup.object().shape({
    amount: Yup.number().nullable().required('You must enter an amount'),
})

const AddEditUnit = Yup.object().shape({
    unitName: Yup.string().nullable().required('You must enter a Unit Name'),
    propertyId: Yup.string().nullable().required('You must select a Property'),
    type: Yup.string().nullable().required('You must select a Type'),
})

const MultiAddUnit = Yup.object().shape({
    rooms: Yup.array().of(
        Yup.object().shape({
            amount: Yup.number().required('Enter amount'),
            unitName: Yup.string().required('Enter unit name'),
            unitTypeId: Yup.string().required('Select unit type'),
            isRentable: Yup.boolean().required('Required'),
            targetRent: Yup.number().required('Required'),
        })
    ),
})

const AddInspection = Yup.object().shape({
    inspectionTypeId: Yup.string()
        .nullable()
        .required('You must select an inspection type'),
    propertyId: Yup.string().nullable(),
    description: Yup.string().nullable(),
    inspectionName: Yup.string()
        .nullable()
        .required('You must enter an inspection name'),
    inspectionDateAndTime: Yup.date().nullable(),
    inspectorId: Yup.string().nullable(),
    tenantId: Yup.string().nullable(),
    signatureRequiredInspector: Yup.boolean(),
    signatureRequiredTenant: Yup.boolean(),
})

export {
    AcceptInvitationSchema,
    AddEditUnit,
    AddIssue,
    AddTeammate,
    AddTenancy,
    AddTransaction,
    AddNewUser,
    AddClient,
    AddInspection,
    ChangePasswordSchema,
    EstimateValuation,
    MultiAddUnit,
    LoginSchema,
    ForgottenPasswordSchema,
    SignupSchema,
    UpdateProfile,
    AddContactSchema
}
