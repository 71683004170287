import React from "react";
import SFDropdown from "..";
import { withRouter } from "react-router";
import styles from "./SFUnitDropdown.module.scss";
import { addDocumentClicked, onAddIssueClickGeneric } from "./_helpers";
import { goToRoute } from "../../../../history";
import { onAddUnitTenancyClick, onAddUnitTransactionClick, onDeleteRows } from "../../../organisms/UnitPage/UnitGrid/_helpers";

interface IProps {
  propertyId: string;
  propertyName: string;

  unitId: string;
  unitName: string;

  isProspect: string;
  toggle?: JSX.Element;
  history: any;
  location: any;
  match: any;

  updateRedirectUrl?: string;
}

class SFUnitDropdown extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <SFDropdown
        loadedBeforeRender
        customToggle={this.props.toggle}
        customCssClassName={styles.syncfusionDropdown}
        onSelect={(args) => {
          if (args.item.text == "Edit") {
            goToRoute(`/dashboard/unit/view/${this.props.unitId}`);
          }
          if (args.item.text == "Add Expense") {
            onAddUnitTransactionClick(this.props.propertyId, this.props.propertyName, this.props.unitId, this.props.unitName, this.props.location.search, false, true);
          }
          if (args.item.text == "Add Income") {
            onAddUnitTransactionClick(this.props.propertyId, this.props.propertyName, this.props.unitId, this.props.unitName, this.props.location.search, true, false);
          }
          if (args.item.text == "Add Tenancy") {
            onAddUnitTenancyClick(this.props.unitId, this.props.unitName, this.props.propertyId, this.props.propertyName, this.props.location.search);
          }
          if (args.item.text == "Add Document") {
            addDocumentClicked({
              propertyId: this.props.propertyId,
              unitId: this.props.unitId
            }, "uploadDocumentOptions");
          }
          if (args.item.text == "Add Issue") {
            onAddIssueClickGeneric({
              propertyId: this.props.propertyId,
              propertyName: this.props.propertyName,
              unitId: this.props.unitId,
              unitName: this.props.unitName
            }, "issueOptions");
          }
          if (args.item.text == "Linked Property") {
            goToRoute(`/dashboard/property/${this.props.propertyId}`);
          }
          if (args.item.text == "Go to Documents") {
            goToRoute(`/dashboard/unit/view/${this.props.unitId}/documents`);
          }
          if (args.item.text == "Delete") {
            onDeleteRows([this.props.unitId], this.props.location, this.props.history, this.props.updateRedirectUrl);
          }
        }}
        items={[
          {
            iconCss: styles.subIconEdit,
            text: "Edit",
            url: `/dashboard/unit/view/${this.props.unitId}`
          },
          {
            separator: true,
          },
          {
            iconCss: styles.subIconAddDocument,
            text: "Add Expense"
          },
          {
            iconCss: styles.subIconAddDocument,
            text: "Add Income"
          },
          {
            iconCss: styles.subIconAddDocument,
            text: "Add Document"
          },
          {
            iconCss: styles.subIconAddIssue,
            text: "Add Issue"
          },
          {
            iconCss: styles.subIconTenancy,
            text: "Add Tenancy"
          },
          {
            separator: true,
          },
          ...(this.props.propertyId
            ? [
              {
                iconCss: styles.subIconProperty,
                text: "Linked Property",
                url: `/dashboard/property/${this.props.propertyId}`
              },
            ]
            : []),
          {
            iconCss: styles.iconGoToFinancials,
            text: "Go to Money",
            url: `/dashboard/unit/view/${this.props.unitId}/financials`
          },
          {
            iconCss: styles.subIconGoToDocuments,
            text: "Go to Documents",
            //url: `/dashboard/unit/view/${this.props.unitId}/timeline`
          }, 
          {
            iconCss: styles.subIconDelete,
            text: "Delete"
          }
        ]}
      />
    );
  }
}

export default withRouter(SFUnitDropdown);