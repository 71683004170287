// Import React
import React, { Component } from "react";
import { Col } from "reactstrap";

// Import CSS
require("react-dd-menu/dist/react-dd-menu.css");

import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { connect } from "react-redux";

import styles from "./WhitelabelNavigationBar.module.scss";

import rentchiefLogoSmall from "../../../images/rent_chief_logo.svg";
import menuIcon from "../../../images/icon-menu.png";
import smallLogo from "../../../images/favicon-32x32.png";

import { INotification } from "../../../types/NotificationService";
import {
  toggleSidebar,
  toggleAddPropertySidebar,
  toggleProfileSidebar
} from "../../../actions/appActions";
import { IUserListItem } from "../../../types/ClientService";
import { toggleMobileSidebar } from "../../../helpers/sidebarHelpers";
import UserContactInfo, {
  UserContactInfoType
} from "../../atoms/UserContactInfo";
import HelpIcon from "../../../images/icons/ic_help_outline_24px.svg";
import { elevioHelpLinkUrl } from "../../../configuration/appConfig";
import { getLoggedInUsersName } from "../../../helpers/profileHelpers";

interface IRouterProps {
  history: any;
  location: any;
  match: any;
  user: IUserListItem;
}

interface IReduxState {
  toggleSidebar(show: boolean): void;
  toggleAddPropertySidebar(show: boolean): void;
  toggleProfileSidebar(show: boolean): void;
  forename: string;
  surname: string;
  logo: string;
  secondaryLogo: string;
  sidebar: boolean;
  profileImageUri: string;
  userId: string;
  role: string;
  is_impersonating: boolean;
}

interface IState {
  isOpen: boolean;
  addPropertySidebarOpen: boolean;
  navSidebarOpen: boolean;
  profileSidebarOpen: boolean;
  notifications: Array<INotification>;
  notificationsOpen: boolean;
  isStickyNav: boolean;
  isMenuOpen: boolean;
}

type Props = IRouterProps & IReduxState;

class WhitelabelNavigationBar extends Component<Props, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isOpen: false,
      addPropertySidebarOpen: false,
      navSidebarOpen: false,
      notifications: [],
      notificationsOpen: false,
      isStickyNav: false,
      isMenuOpen: false,
      profileSidebarOpen: false
    };

    this.onSetSidebarNavOpen = this.onSetSidebarNavOpen.bind(this);
    this.onSetSidebarAddPropertyOpen = this.onSetSidebarAddPropertyOpen.bind(this);
    this.toggleNotifications = this.toggleNotifications.bind(this);
    this.onSetSidebarProfileOpen = this.onSetSidebarProfileOpen.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);
    this.click = this.click.bind(this);
    this.onToggleMobileSidebar = this.onToggleMobileSidebar.bind(this);
  }

  onSetSidebarNavOpen(open) {
    this.setState({ navSidebarOpen: open });
  }

  onSetSidebarAddPropertyOpen(open) {
    this.setState({ addPropertySidebarOpen: open });
  }

  onSetSidebarProfileOpen(open) {
    this.setState({ profileSidebarOpen: open });
  }

  toggleNotifications() {
    this.setState({
      notificationsOpen: !this.state.notificationsOpen
    });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.pageYOffset >= 100 && this.state.isStickyNav) {
      return;
    }

    if (window.pageYOffset >= 100 && !this.state.isStickyNav) {
      this.setState({
        isStickyNav: true
      });
    }

    if (window.pageYOffset < 100 && this.state.isStickyNav) {
      this.setState({
        isStickyNav: false
      });
    }
  };

  toggle() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }

  close() {
    this.setState({ isMenuOpen: false });
  }

  onToggleMobileSidebar(): void {
    toggleMobileSidebar();
  }

  click() { }

  render() {
    return (
      <div>
        <div
          className={
            this.state.isStickyNav
              ? `${styles.stickyWrapper}`
              : `${styles.wrapper}`
          }
          id="dashboardNavigationBar"
        >
          <Col>
            <div className={styles.nav} id="logo">
              <div className={styles.center}>
                <img
                  className={`${styles.smallLogo} navLogo`}
                  src={
                    this.props.secondaryLogo
                      ? this.props.secondaryLogo
                      : smallLogo
                  }
                  alt="Small Logo"
                />
                <img
                  className={`${styles.logo} navLogo`}
                  src={this.props.logo ? this.props.logo : rentchiefLogoSmall}
                />
              </div>
            </div>
          </Col>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  forename: state.user.forename,
  surname: state.user.surname,
  profileImageUri: state.user.profileImage,
  logo: state.client.logo,
  secondaryLogo: state.client.secondaryLogo,
  sidebar: state.app.sidebars.side_nav,
  userId: state.user.user_id,
  role: state.user.role,
  is_impersonating: state.app.is_impersonating
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    toggleSidebar: (show: boolean) => {
      dispatch(toggleSidebar(show));
    },
    toggleAddPropertySidebar: (show: boolean) => {
      dispatch(toggleAddPropertySidebar(show));
    },
    toggleProfileSidebar: (show: boolean) => {
      dispatch(toggleProfileSidebar(show));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WhitelabelNavigationBar) as any
);