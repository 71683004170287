import React, { useEffect, useState } from "react";
import { QuickViewType } from "../../../types/QuickView";
import { getSearchContact } from "../../../services/SearchService";
import { ISearchResult } from "../../../types/SearchService/ISearchService";
import { withRouter } from "react-router-dom";
import { canAccessContactDetails } from "../../../helpers/permissionHelpers";
import SelectDropdownV2 from "../SelectDropdownV2/SelectDropdownV2.component";
import AddContactOption from "../AddContactOption/AddContactOption.component";

interface IProps {
  history: any;
  location: any;
  match: any;

  selectedId?: string;
  selectedName?: string;
  onSelect(option: ISearchResult): void;
  onClear?(): void;
  contactType?: Array<string>;
  firstInputRef?: any;
  onAdd?(): void;
  disableClear?: boolean;
  menuPlacement?: string;
  showSearchButton?: boolean;
  searchButtonOnClick?(searchValue: string): void;

  // New contact
  allowNewContact?: boolean;
  newContactType?: string;
  callbackName?: string;
  ignoreFilter?: boolean;
  hideAddButton?: boolean;

  focussed?: boolean;
}

const SearchContactInput = (props: IProps) => {
  const [results, setResults] = useState<ISearchResult[]>([]);
  const [search, setSearch] = useState<string>("");
  const [selectedName, setSelectedName] = useState<string>(null);

  useEffect(() =>{
    if(props.selectedId != undefined){
      getContacts("",true);
    }
  }, [props.selectedId])

  const onSelect = (option: ISearchResult) => {
    setResults([]);
    setSelectedName(option.label);
    props.onSelect(option);
  }

  const getContacts = (search?: string, setName?: boolean) => {
    getSearchContact(search ? search : '', props.ignoreFilter ? null : props.contactType).then(resp => {
      if (resp && resp.status == 200 && resp.data) {
        setResults(resp.data);
        setSearch(search);
        if(setName){
          resp.data.map((i) => {
            if(i.value == props.selectedId){
              setSelectedName(i.label);
            }
          })
        }
      }
    });
  }

  return (
    <div>
      <SelectDropdownV2 
            onClear={props.onClear}
            disableClear={false}
            selectedId={props.selectedId}
            selectedName={selectedName}
            onSelect={(options) => onSelect({value: options.value, label: options.label})}
            onSearch={(getContacts)}
            data={results}
            inputPlaceHolder={"Search contacts..."}
            quickView={QuickViewType.Contact}
            isSearchable={true}
            menuPlacement={props.menuPlacement}
            showSearchButton={props.showSearchButton}
            searchButtonOnClick={props.searchButtonOnClick}
            focussed={props.focussed}
            addNewItemControl={props.allowNewContact && canAccessContactDetails() && 
              <AddContactOption 
                newName={search} 
                newContactType={props.newContactType}
                callbackName={props.callbackName}
                onAdd={props.onAdd}
              />}
            addNewItemButtonControl={!props.hideAddButton && !props.showSearchButton && props.allowNewContact && canAccessContactDetails() && 
              <AddContactOption 
                newContactType={props.newContactType}
                callbackName={props.callbackName}
                onAdd={props.onAdd}
                isButton={true}
              />}
          />
    </div>
    
  )
}

export default withRouter(SearchContactInput);