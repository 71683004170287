import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Col, Label } from 'reactstrap'
import { propertyCardStatusProspect } from '../../../configuration/appConfig'
import { formatAddress } from '../../../helpers/formattingHelpers'
import { updateCalculateReturnsProperty } from '../../../helpers/propertyHelpers'
import { generateId } from '../../../helpers/randomHelpers'
import { toggleEditPropertySidebar } from '../../../helpers/sidebarHelpers'
import { processToast } from '../../../helpers/toastHelper'
import defaultPropertyBackgroundImage from '../../../images/House_Grey.png'
import { ICalculateReturnsData } from '../../../reducers/propertyReducer'
import { updatePropertyStatus } from '../../../services/PropertyService'
import { IReactRouterProps } from '../../../types'
import { IDataResponse } from '../../../types/ApiService'
import { IPropertyCard } from '../../../types/PropertyService/IPropertyService'
import { QuickViewType } from '../../../types/QuickView'
import IconButton from '../Buttons/IconButton'
import DeletePropertyModal from '../DeletePropertyModal'
import EllipsisText from '../EllipsisText'
import FormattedAddress from '../FormattedAddress'
import GenericPopup from '../GenericPopup'
import SFPropertyDropdown from '../SFDropdown/SFPropertyDropdown'
import { Button } from "@rentchief/components";
import styles from './PropertyCardNav.module.scss'

interface IProps {
    propertyCard: IPropertyCard
    canEdit?: boolean
    canView?: boolean
    canAction?: boolean
    isDashboard?: boolean
    history?: any
    displaySmallName?: boolean
    betaOnClick?(): void
}

interface IState {
    showDropdown: boolean
    deletePropertyModalOpen: boolean
}

type Props = IProps & IReactRouterProps

class PropertyCardNav extends Component<Props, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            showDropdown: false,
            deletePropertyModalOpen: false,
        }
    }

    navigateToProperty = (): void => {
        if (!history) {
            return
        }

        this.props.history.push(
            `/dashboard/property/${this.props.propertyCard.propertyId}`
        )
    }

    editProperty = () => {
        const { search } = this.props.location
        const queryFormat = search ? '&' : '?'
        this.props.history.push({
            search:
                search +
                queryFormat +
                'editProperty=' +
                this.props.propertyCard.propertyId,
        })

        toggleEditPropertySidebar(true)
    }

    componentDidMount(): void {
        this.setState({
            showDropdown: true,
        })
    }

    shouldComponentUpdate(): boolean {
        return true
    }

    markAsPropertyPurchased = (): void => {
        updatePropertyStatus(this.props.propertyCard.propertyId, false).then(
            (resp) => {
                if (resp && resp.status == 200 && resp.data) {
                    let response: IDataResponse<any> = resp.data
                    if (response.isSuccess) {
                        processToast(response)

                        let reduxData: ICalculateReturnsData = {
                            propertyId: this.props.propertyCard.propertyId,
                            propertyName: response.data.propertyName,
                            propertyStatus: response.data.propertyStatus,
                            propertyStatusId: response.data.propertyStatusId,
                            propertyImage: response.data.propertyImage,
                            address: response.data.address,
                            propertySummary: response.data.propertySummary,
                            propertyValuation: response.data.propertyValuation,
                            moreInformation: response.data.moreInformation,
                            chartData: response.data.chartData,
                            propertyDetails: response.data.propertyDetail,
                            propertyContacts: response.data.propertyContacts,
                            propertyImages: response.data.images,
                        }

                        updateCalculateReturnsProperty(reduxData)
                    }
                }
            }
        )
    }

    onDelete = (): void => {
        this.props.history.push('/dashboard/properties')
    }

    onOpenModal = (): void => {
        this.setState({
            deletePropertyModalOpen: true,
        })
    }

    onCloseModal = (): void => {
        this.setState({
            deletePropertyModalOpen: false,
        })
    }

    render() {
        const { address, propertyName, propertyStatus } =
            this.props.propertyCard
        return (
            <div
                className={
                    this.props.isDashboard ? styles.dashboard : styles.page
                }
            >
                <DeletePropertyModal
                    onDeleteCallBack={this.onDelete}
                    propertyId={this.props.propertyCard.propertyId}
                    propertyName={this.props.propertyCard.propertyName}
                    propertyAddress={this.props.propertyCard.address}
                    open={this.state.deletePropertyModalOpen}
                    onOpenModal={this.onOpenModal}
                    onCloseModal={this.onCloseModal}
                />
                
                <div className={styles.topRow}>
                    <div className={styles.detailsColumn}>
                        <div
                            className={styles.row}
                            onClick={
                                this.props.canView
                                    ? () => this.navigateToProperty()
                                    : () => null
                            }
                        >
                            <Col
                                xs="1"
                                className={styles.image}
                                onClick={() => this.navigateToProperty()}
                                style={{
                                    backgroundImage: `${
                                        this.props.propertyCard.propertyImage
                                            ? 'url(' +
                                              this.props.propertyCard
                                                  .propertyImage +
                                              ')'
                                            : 'url(' +
                                              defaultPropertyBackgroundImage +
                                              ')'
                                    }`,
                                    backgroundSize: 'cover',
                                }}
                            />

                            <div className={styles.column}>
                                <div className={styles.row}>
                                    <div className={styles.column}>
                                        <span className={styles.title}>
                                            {propertyStatus}&nbsp;
                                        </span>
                                        <span className={styles.propertyName}>
                                            {status ? (
                                                <EllipsisText
                                                    text={
                                                        this.props.propertyCard
                                                            .propertyName
                                                    }
                                                    length={30}
                                                />
                                            ) : (
                                                <Label
                                                    className={styles.ellipsis}
                                                >
                                                    {propertyName}

                                                    <div
                                                        className={`${
                                                            this.props
                                                                .displaySmallName
                                                                ? styles.showPopup
                                                                : styles.popup
                                                        }`}
                                                    >
                                                        <GenericPopup
                                                            identifier={generateId(
                                                                10
                                                            )}
                                                            options={{
                                                                propertyId:
                                                                    this.props
                                                                        .propertyCard
                                                                        .propertyId,
                                                                type: QuickViewType.Property,
                                                            }}
                                                        />
                                                    </div>
                                                </Label>
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.addressContainer}>
                                        <div className={styles.address}>
                                            <FormattedAddress
                                                showDefaultText
                                                inline={true}
                                                address={address}
                                                fontUnit={'px'}
                                                fontSize={12}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.showDropdown && this.props.canAction && (
                        <div className={styles.actionsColumn}>
                            <SFPropertyDropdown
                                propertyId={this.props.propertyCard.propertyId}
                                propertyName={
                                    this.props.propertyCard.propertyName
                                }
                                propertyType={
                                    this.props.propertyCard.propertyType
                                }
                                residentialType={
                                    this.props.propertyCard.residentialType
                                }
                                commercialType={
                                    this.props.propertyCard.commercialType
                                }
                                isProspect={
                                    this.props.propertyCard.propertyStatusId ==
                                    propertyCardStatusProspect
                                }
                                markAsPropertyPurchased={
                                    this.markAsPropertyPurchased
                                }
                                onDelete={this.onOpenModal}
                                toggle={
                                    <IconButton
                                        button={{
                                            displayType: 'action',
                                            elementType: 'button',
                                            icon: 'action',
                                        }}
                                    />
                                }
                                betaOnClick={this.props.betaOnClick}
                            />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default withRouter(PropertyCardNav)
