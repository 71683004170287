import React from 'react'
import { Row, Col } from 'reactstrap'
import Modal from 'react-responsive-modal'
import { deleteProperty } from '../../../services/PropertyService'
import { IAddress } from '../../../types/PropertyService/IPropertyService'
import MaterialCheckbox from '../MaterialCheckbox'
import GenericButton from '../GenericButton'
import { formatAddress } from '../../../helpers/formattingHelpers'
import RotatingLoader from '../RotatingLoader'
import { updateAlertMessage } from '../../../helpers/alertHelpers'
import styles from './DeletePropertyModal.module.scss'
import { updateToastMessage } from '../../../helpers/toastHelper'
import Buttons from '../Buttons'

interface IProps {
    open: boolean
    onOpenModal(): void
    onCloseModal(): void
    propertyName: string
    propertyId: string
    propertyAddress: IAddress
    onDeleteCallBack(): void
}

interface IState {
    deleteTenancies: boolean
    deleteDocuments: boolean
    deleteTransaction: boolean
    loading: boolean
    success: boolean
}

export interface IDeleteRequest {
    deleteAssociatedTenancy: boolean
    deleteAssociatedDocument: boolean
    deleteAssociatedTransaction?: boolean
}

class DeletePropertyModal extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            deleteDocuments: true,
            deleteTenancies: true,
            deleteTransaction: true,
            loading: false,
            success: false,
        }
    }

    componentDidMount(): void {
        updateAlertMessage('', false)
    }

    onDelete(propertyId: string): void {
        this.setState({
            loading: true,
        })
        deleteProperty(propertyId, {
            deleteAssociatedDocument: this.state.deleteDocuments,
            deleteAssociatedTenancy: this.state.deleteTenancies,
            deleteAssociatedTransaction: this.state.deleteTransaction,
        })
            .then((resp) => {
                if (resp.status == 200) {
                    this.setState({
                        success: true,
                        loading: false,
                    })

                    updateToastMessage(
                        this.props.propertyName +
                            ' has been succesfully deleted',
                        'success'
                    )
                    this.props.onDeleteCallBack()
                    this.props.onCloseModal()
                }
                if (resp.status == 400) {
                    updateToastMessage(
                        'There has been a problem deleting your property please try again later or contact us',
                        'failure'
                    )
                    this.props.onCloseModal()
                }
            })
            .catch((e) => {})
    }

    hasPrimaryAddressValues(): boolean {
        if (!this.props.propertyAddress) {
            return false
        }

        const { line1, line2, town, county, postcode, country } =
            this.props.propertyAddress
        if (line1 || line2 || town || county || postcode || country) {
            return true
        }
        return false
    }

    render() {
        return (
            <Modal
                open={this.props.open}
                onClose={this.props.onCloseModal}
                center
            >
                {this.state.loading ? (
                    <div>
                        <RotatingLoader
                            loading={true}
                            text={`Deleting ${formatAddress(
                                this.props.propertyAddress
                            )}...`}
                        />
                    </div>
                ) : (
                    <div>
                        <p className={styles.modelHeader}>DELETE PROPERTY</p>

                        {this.hasPrimaryAddressValues() && (
                            <p className={styles.modalBody}>
                                You're about to delete property:
                            </p>
                        )}

                        {this.props.propertyAddress.line1 &&
                            this.props.propertyAddress.postcode && (
                                <p className={styles.modalBody}>
                                    <span className={styles.address}>
                                        {formatAddress(
                                            this.props.propertyAddress
                                        )}
                                    </span>
                                </p>
                            )}

                        <p className={styles.modalBody}>
                            Deleting a property will delete all property detail
                            information, address, ownership and valuation
                            information. In addition, you can delete associated
                            tenancy records and documents if required.
                        </p>

                        <div className={styles.checkboxes}>
                            <p className={styles.modalBody}>
                                <MaterialCheckbox
                                    checked={this.state.deleteTenancies}
                                    onChange={() =>
                                        this.setState({
                                            deleteTenancies:
                                                !this.state.deleteTenancies,
                                        })
                                    }
                                    labelText="Delete all associated tenancy records?"
                                />
                            </p>

                            <p className={styles.modalBody}>
                                <MaterialCheckbox
                                    checked={this.state.deleteDocuments}
                                    onChange={() =>
                                        this.setState({
                                            deleteDocuments:
                                                !this.state.deleteDocuments,
                                        })
                                    }
                                    labelText="Delete all associated document records?"
                                />
                            </p>

                            <p className={styles.modalBody}>
                                <MaterialCheckbox
                                    checked={this.state.deleteTransaction}
                                    onChange={() =>
                                        this.setState({
                                            deleteTransaction:
                                                !this.state.deleteTransaction,
                                        })
                                    }
                                    labelText="Delete all associated transaction records?"
                                />
                            </p>
                        </div>

                        <p className={styles.modalBody}>
                            Do you wish to go ahead and delete?
                        </p>

                        <Row>
                            <Col className={styles.buttonDiv}>
                                <Buttons
                                    buttons={[
                                        {
                                            displayType: 'cancel',
                                            elementType: 'button',
                                            onClick: () =>
                                                this.props.onCloseModal(),
                                        },
                                        {
                                            text: 'Delete',
                                            displayType: 'submit',
                                            elementType: 'button',
                                            onClick: () =>
                                                this.onDelete(
                                                    this.props.propertyId
                                                ),
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </div>
                )}
            </Modal>
        )
    }
}

export default DeletePropertyModal
