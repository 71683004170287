import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch, withRouter } from 'react-router'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'
import { imageService } from '../../../configuration/domains'
import { updateAlertMessage } from '../../../helpers/alertHelpers'
import { updateViewingInspection } from '../../../helpers/inspectionHelpers'
import { addURLParameter } from '../../../helpers/locationHelpers'
import { canAccessPropertyDetails } from '../../../helpers/permissionHelpers'
import { getLoggedInUsersUsertId } from '../../../helpers/profileHelpers'
import { toggleAddDocumentPopup } from '../../../helpers/sidebarHelpers'
import { processToast, updateToastMessage } from '../../../helpers/toastHelper'
import documents from '../../../images/icons/documents.png'
import auditIcon from '../../../images/icons/icon_timeline.svg'
import menuIssues from '../../../images/icons/menuicon_issues.svg'
import detailsIcon from '../../../images/icons/user-management/usermanagement_details.svg'
import { changePrimaryImage } from '../../../services/ImageService'
import {
    getInspection,
    getInspectionImages,
} from '../../../services/inspectionService'
import { IDataResponse } from '../../../types/ApiService'
import { IImageDto, ImageTypeEnum } from '../../../types/ImageService'
import { IInspectionDto } from '../../../types/Inspection'
import AddEditInspection from '../../atoms/AddEditInspection'
import { IRaiseIssueOptions } from '../../atoms/AddEditIssue'
import Breadcrumbs from '../../atoms/Breadcrumbs'
import IconButton from '../../atoms/Buttons/IconButton'
import GoogleMap from '../../atoms/GoogleMap'
import ImageGalleryComponent from '../../atoms/ImageGallery'
import RotatingLoader from '../../atoms/RotatingLoader'
import SFInspectionDropdown from '../../atoms/SFDropdown/SFInspectionDropdown'
import SFUploaderModal from '../../atoms/SFUploaderModal'
import SimpleRouterTabs from '../../atoms/SimpleRouterTabs'
import TabbedNavigationContainer from '../../atoms/TabbedNavigationContainer'
import { IDocumentUploadOptions } from '../../molecules/AddDocumentModal'
import ConductInspectionPage from '../ConductInspectionPage'
import DocumentsPage from '../DocumentsPage'
import IssueDetail from '../IssueDetail'
import IssueListPage from '../IssueListPage'
import AuditPage from '../PlatformManagementPage/AuditPage'
import styles from './InspectionDetailPage.module.scss'
const queryString = require('query-string')

interface IProps {
    history: any
    match: any
    location: any

    onUpdateReturnUrl?: string
    viewing_inspection: IInspectionDto
}

interface IState {
    loading: boolean
    upload_images: boolean
    images: Array<IImageDto>
    referer: string
    dataFetchFailed: boolean
    isReadOnly: boolean
}

class InspectionDetailPage extends Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            loading: false,
            upload_images: false,
            images: [],
            referer: '',
            dataFetchFailed: false,
            isReadOnly: false
        }
    }

    onChangePrimaryImage = async (imageId: string) => {
        const { data } = await changePrimaryImage({
            imageId,
            imageType: ImageTypeEnum.INSPECTION_IMAGE,
        })

        if (!data) return null

        let response: IDataResponse<string> = data
        processToast(response)
        this.getImages()
    }

    getImages = (): void => {
        getInspectionImages(this.props.match.params.inspectionId).then(
            (resp) => {
                if (resp && resp.status == 200 && resp.data) {
                    this.setState({
                        images: resp.data,
                    })
                }
            }
        )
    }

    getInspection = (): void => {
        this.setState({
            loading: true,
        })

        getInspection(this.props.match.params.inspectionId).then((resp) => {
            if (resp && resp.status == 200) {
                if (resp.data.isSuccess) {
                    updateViewingInspection(resp.data.data)
                    this.getImages()
                    this.setState({isReadOnly: getLoggedInUsersUsertId() != resp.data.data.createdBy })
                } else {
                    this.setState({ dataFetchFailed: true })
                }
            }

            this.setState({
                loading: false,
            })
        })
    }

    componentDidMount(): void {
        this.getInspection()

        // Check if it's been opened up from within a Property context
        let referer = queryString.parse(this.props.location.search).referer
        if (referer) {
            if (referer === 'property') {
                this.setState({
                    referer,
                })
            }
        }
    }

    componentWillUnmount(): void {
        updateViewingInspection(null)
    }

    onAddDocumentClick = (): void => {
        let options: IDocumentUploadOptions = {
            inspectionId: this.props.viewing_inspection.inspectionId,
            documentTypeId: '85ba3702-2186-4acc-b489-6a80e692894f',
        }

        if (this.props.viewing_inspection.propertyId) {
            options.propertyId = this.props.viewing_inspection.propertyId
            options.propertyName = this.props.viewing_inspection.propertyName
        }

        let query = 'uploadDocumentOptions=' + JSON.stringify(options)

        let search = addURLParameter(this.props.location.search, query)

        this.props.history.push({
            search,
        })

        toggleAddDocumentPopup(true)
    }

    onAddButtonClick = (): void => {
        let options: IRaiseIssueOptions = {
            propertyId: this.props.viewing_inspection.propertyId,
            propertyName: this.props.viewing_inspection.propertyName,
            inspectionId: this.props.viewing_inspection.inspectionId,
            redirectUrl: `/dashboard/inspection/view/${this.props.match.params.inspectionId}/issues`,
        }

        let query = 'issueOptions=' + JSON.stringify(options)
        let queryParam = addURLParameter(this.props.location.search, query)

        this.props.history.push({
            search: queryParam,
        })
    }

    render() {
        if (this.state.loading || !this.props.viewing_inspection) {
            if (!this.state.dataFetchFailed) {
                return (
                    <RotatingLoader
                        loading={true}
                        text="Loading Inspection..."
                    />
                )
            } else {
                updateAlertMessage(
                    'The requested inspection is not available.',
                    true,
                    3000,
                    true
                )
                return <div />
            }
        }

        const { inspectionName, propertyName, propertyId } =
            this.props.viewing_inspection

        const Summary = () => {
            return (
                <div className={styles.summary}>
                    <div className={styles.form}>
                        <AddEditInspection
                            inspection={this.props.viewing_inspection}
                            hideBreadcrumbs
                            inspectionId={this.props.match.params.inspectionId}
                        />
                    </div>

                    <div className={styles.images}>
                        <div className={styles.imageGallery}>
                            <SFUploaderModal
                                open={this.state.upload_images}
                                onToggle={(open: boolean) =>
                                    this.setState({ upload_images: open })
                                }
                                urls={{
                                    saveUrl: `${imageService}/Save`,
                                    removeUrl: `${imageService}/Remove`,
                                }}
                                onFileUpload={(imageIds?: string[]) =>
                                    updateToastMessage(
                                        'Inspection image has been uploaded successfully',
                                        'success'
                                    )
                                }
                                successCallback={() => {
                                    this.setState({ upload_images: false })
                                    this.getImages()
                                }}
                                options={{
                                    inspectionId:
                                        this.props.match.params.inspectionId,
                                    imageType: ImageTypeEnum.INSPECTION_IMAGE,
                                }}
                            />

                            <ImageGalleryComponent
                                onClickFullscreen
                                dropdownActions={{
                                    onClickUpload: () =>
                                        this.setState({ upload_images: true }),
                                    onClickDelete: () => this.getImages(),
                                    onChangePrimaryImage:
                                        this.onChangePrimaryImage,
                                }}
                                items={this.state.images}
                                disableActionButtons={this.state.isReadOnly}
                            />
                        </div>

                        {this.props.viewing_inspection.propertyAddress &&
                            this.props.viewing_inspection.propertyAddress
                                .line1 != '' && (
                                <Row>
                                    <Col className={styles.mapContainer}>
                                        <div className={styles.map}>
                                            <div className={styles.mapElement}>
                                                <GoogleMap
                                                    propertyName="The best house"
                                                    address={
                                                        this.props
                                                            .viewing_inspection
                                                            .propertyAddress
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <br />
                                    </Col>
                                </Row>
                            )}
                    </div>
                </div>
            )
        }

        return (
            <Row className={styles.container}>
                <div className={styles.headerBar}>
                    <div className={styles.breadcrumbs}>
                        <Breadcrumbs
                            breadcrumbs={[
                                ...(propertyId && propertyName
                                    ? [
                                          {
                                              name: propertyName,
                                              route: canAccessPropertyDetails() ? `/dashboard/property/${propertyId}` : '',
                                              ordinal: 0,
                                          },
                                      ]
                                    : []),
                                {
                                    name: 'Inspections',
                                    route: propertyId
                                        ? canAccessPropertyDetails() ? `/dashboard/property/${propertyId}/spec/inspections` : ''
                                        : `/dashboard/inspections`,
                                    ordinal: 1,
                                },
                                {
                                    name: inspectionName,
                                    route: `/dashboard/inspection/view/${this.props.viewing_inspection.inspectionId}`,
                                    ordinal: 2,
                                },
                            ]}
                        />
                    </div>
                    <div className={styles.actionMenu}>
                        <SFInspectionDropdown
                            toggle={
                                <IconButton
                                    button={{
                                        displayType: 'action',
                                        elementType: 'button',
                                        icon: 'action',
                                    }}
                                />
                            }
                            propertyId={
                                this.props.viewing_inspection.propertyId
                            }
                            propertyName={
                                this.props.viewing_inspection.propertyName
                            }
                            inspectionId={
                                this.props.viewing_inspection.inspectionId
                            }
                            updateRedirectUrl={
                                this.props.viewing_inspection.propertyId
                                    ? `/dashboard/property/${this.props.viewing_inspection.propertyId}/spec/inspections`
                                    : '/dashboard/inspections'
                            }
                        />
                    </div>
                </div>

                {this.props.location.pathname.indexOf('/conduct') < 0 && (
                    <div className={styles.navigation}>
                        <TabbedNavigationContainer fullWidth>
                            <Col className={styles.tabs}>
                                <SimpleRouterTabs
                                    routes={[
                                        {
                                            path:
                                                this.props.match.url +
                                                '/summary',
                                            text: 'Inspection',
                                            mobileText: 'Inspection',
                                            icon: detailsIcon,
                                        },
                                        {
                                            path:
                                                this.props.match.url +
                                                '/documents',
                                            text: 'Docs',
                                            mobileText: 'Docs',
                                            icon: documents,
                                        },
                                        {
                                            path:
                                                this.props.match.url +
                                                '/issues',
                                            text: 'Issues',
                                            mobileText: 'Issues',
                                            icon: menuIssues,
                                        },
                                        {
                                            path:
                                                this.props.match.url +
                                                '/timeline',
                                            text: 'Timeline',
                                            mobileText: 'Timeline',
                                            icon: auditIcon,
                                        },
                                    ]}
                                />
                            </Col>
                        </TabbedNavigationContainer>
                    </div>
                )}

                <Col className={styles.routedPage}>
                    <div className={styles.content}>
                        <Switch>
                            <Route
                                path={`${this.props.match.url}`}
                                exact
                                component={({ location }) => (
                                    <Redirect
                                        to={{
                                            ...location,
                                            pathname: `${this.props.match.url}/summary`,
                                        }}
                                    />
                                )}
                            />

                            <Route
                                path={`${this.props.match.path}/summary`}
                                exact
                                render={() => <Summary />}
                            />

                            <Route
                                path={`${this.props.match.path}/documents`}
                                exact
                                render={() => (
                                    <div className={styles.whiteBg}>
                                        <DocumentsPage
                                            onAddDocumentClick={
                                                this.onAddDocumentClick
                                            }
                                            inspectionId={
                                                this.props.match.params
                                                    .inspectionId
                                            }
                                        />
                                    </div>
                                )}
                            />

                            <Route
                                path={`${this.props.match.path}/issue/:issueId?`}
                                render={(props) => (
                                    <div className={styles.whiteBg}>
                                        <IssueDetail />
                                    </div>
                                )}
                            />

                            <Route
                                path={`${this.props.match.path}/issues/:issueId?`}
                                render={() => (
                                    <div className={styles.whiteBg}>
                                        <IssueListPage
                                            onAddIssue={this.onAddButtonClick}
                                            inspectionId={
                                                this.props.match.params
                                                    .inspectionId
                                            }
                                        />
                                    </div>
                                )}
                            />

                            <Route
                                path={`${this.props.match.path}/timeline`}
                                exact
                                render={() => (
                                    <div className={styles.whiteBg}>
                                        <AuditPage
                                            inspectionId={
                                                this.props.match.params
                                                    .inspectionId
                                            }
                                            hideDropdown
                                        />
                                    </div>
                                )}
                            />

                            <Route
                                path={`${this.props.match.path}/conduct`}
                                exact
                                render={() => (
                                    <div className={styles.whiteBg}>
                                        <ConductInspectionPage />
                                    </div>
                                )}
                            />
                        </Switch>
                    </div>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state: any) => ({
    viewing_inspection: state.inspection.viewing_inspection,
})

export default withRouter(connect(mapStateToProps)(InspectionDetailPage))
