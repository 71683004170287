import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../../reducers/rootReducer';
import UserContactInfo, { UserContactInfoType } from '../../../../atoms/UserContactInfo'
import SectionHeader from "../SectionHeader/SectionHeader.component"
import * as S from "./ActiveContactsSection.styles";

interface sectionProps {
    id: string;
    header: string;
    hideOnClick(id: string): void;
}

const ActiveContactsSection = ({id, header, hideOnClick}: sectionProps) => {
    let history = useHistory();

    // Redux 
    const { propertyContacts } = useSelector((state: RootState) => state.property.property_data.property_data);
    return (
        <div>
            <SectionHeader id={id} header={header} hideOnClick={hideOnClick} titleOnClick={() => history.push(`/dashboard/contacts`)}/>
            <S.SectionDiv>
                {propertyContacts && propertyContacts.landlord && (
                        <S.CardDiv>
                            <UserContactInfo
                                type={UserContactInfoType.Contact}
                                id={propertyContacts && propertyContacts.landlord}
                            />
                        </S.CardDiv>
                    )}

                {propertyContacts && propertyContacts.propertyManager 
                    && propertyContacts.propertyManager != '00000000-0000-0000-0000-000000000000' 
                    && (
                        <S.CardDiv>
                            <UserContactInfo
                                type={UserContactInfoType.Contact}
                                id={propertyContacts && propertyContacts.propertyManager}
                            />
                        </S.CardDiv>
                    )}

                {propertyContacts && propertyContacts.tenant 
                    && propertyContacts.tenant != '00000000-0000-0000-0000-000000000000' 
                    && (
                        <S.CardDiv>
                            <UserContactInfo
                                type={UserContactInfoType.Contact}
                                id={propertyContacts && propertyContacts.tenant}
                            />
                        </S.CardDiv>
                    )}
            </S.SectionDiv>
        </div>
    )
}

export default ActiveContactsSection;