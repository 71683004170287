let isIdentityLocalDev = true;
let isCoreLocalDev = true;
let isIntegrationLocalDev = false;
let isRentChiefUiLocalDev = false;

let rootDomainIdentityDev = !isIdentityLocalDev
  ? `https://rc-services-identity-dev-ase.azurewebsites.net`
  : `https://localhost:5001`;
let rootDomainCoreDev = !isCoreLocalDev
  ? "https://rc-services-core-dev-ase.azurewebsites.net"
  : "https://localhost:44344";
let rootDomainIntegrationsDev = !isIntegrationLocalDev
  ? "https://rc-services-integrations-dev-ase.azurewebsites.net"
  : "https://localhost:44305";
let rootRentChiefResearchUiUrlLocalhost = !isRentChiefUiLocalDev
  ? "https://dev.rentchief.co.uk"
  : "https://research-dev.rentchief.co.uk";

export const environment = process.env.REACT_APP_ENVIRONMENT || "localhost";
export const environmentName = process.env.NODE_ENV;
/* export const rootDomain =
  process.env.REACT_APP_ROOT_DOMAIN || "https://localhost:44344"; */
export const rootDomainCore =
  process.env.REACT_APP_ROOT_DOMAIN_CORE || rootDomainCoreDev;
export const rootDomainIdentity =
  process.env.REACT_APP_ROOT_DOMAIN_IDENTITY || rootDomainIdentityDev;
export const rootDomainIntegrations =
  process.env.REACT_APP_ROOT_DOMAIN_INTEGRATIONS || rootDomainIntegrationsDev;
export const rootRcResearchUiUrl =
  process.env.REACT_APP_RC_RESEARCH_UI_URL ||
  "https://research-dev.rentchief.co.uk/";

// Identity service
export const identityService = `${rootDomainIdentity}/api/v1/Authorization/token`;
export const identityVersionService = `${rootDomainIdentity}/api/v1/Version`;

// Integration service
export const councilService = `${rootDomainIntegrations}/api/v1/council`;
export const findAddressService = `${rootDomainIntegrations}/api/v1/address/find/uk`;

// Core service
export const emailCheckService = `${rootDomainCore}/api/v1/User/check-email-exists`;
export const userService = `${rootDomainCore}/api/v1/User`;
export const propertyService = `${rootDomainCore}/api/v1/Property`;
export const addressService = `${rootDomainCore}/api/v1/address`;
export const registrationService = `${rootDomainCore}/api/v1/Registration`;
export const passwordService = `${rootDomainCore}/api/v1/PasswordReset`;
export const calculationService = `${rootDomainCore}/api/v1/CalculationV2/calculate`;
export const contentManagementService = `${rootDomainCore}/api/v1/ContentManagement`;
export const notificationService = `${rootDomainCore}/api/v1/Notification`;
export const profileUpdateService = `${rootDomainCore}/api/v1/Profile`;
export const contactPreferencesService = `${rootDomainCore}/api/v1/ContactPreferences`;
export const propertyListService = `${rootDomainCore}/api/v1/PropertyList`;
export const assetService = `${rootDomainCore}/api/v1/Asset`;
export const clientService = `${rootDomainCore}/api/v1/Client`;
export const clientManagementService = `${rootDomainCore}/api/v1/ClientManagement`;
export const dashboardService = `${rootDomainCore}/api/v1/Dashboard`;
export const statisticsService = `${rootDomainCore}/api/v1/Statistics`;
export const rolesService = `${rootDomainCore}/api/v1/Roles`;
export const permissionsService = `${rootDomainCore}/api/v1/Permissions`;
export const documentService = `${rootDomainCore}/api/v1/Document`;
export const entityService = `${rootDomainCore}/api/v1/Entity`;
export const contactService = `${rootDomainCore}/api/v1/Contact`;
export const propertyownershipService = `${rootDomainCore}/api/v1/PropertyOwnership`;
export const auditService = `${rootDomainCore}/api/v1/Audit`;
export const propertyDetailService = `${rootDomainCore}/api/v1/PropertyDetails`;
export const accountService = `${rootDomainCore}/api/v1/Account`;
export const landRegistryService = `${rootDomainCore}/api/v1/LandRegistry`;
export const coreVersionService = `${rootDomainCore}/api/v1/Version`;
export const googleService = `${rootDomainCore}/api/v1/Google`;
export const searchService = `${rootDomainCore}/api/v1/Search`;
export const csvExportService = `${rootDomainCore}/api/v1/CSVExport`;
export const tenancyService = `${rootDomainCore}/api/v1/Tenancy`;
export const quickViewService = `${rootDomainCore}/api/v1/QuickViewData`;
export const invitationService = `${rootDomainCore}/api/v1/Invitation`;
export const issueService = `${rootDomainCore}/api/v1/Issue`;
export const chatService = `${rootDomainCore}/api/v1/Chat`;
export const transactionService = `${rootDomainCore}/api/v1/Transaction`;
export const unitService = `${rootDomainCore}/api/v1/Unit`;
export const transactionServiceOdataFriendly = `${rootDomainCore}/OData/TransactionsOData`;
export const userServiceOdataFriendly = `${rootDomainCore}/OData/UserOData`;
export const tenancyServiceOdataFriendly = `${rootDomainCore}/OData/TenancyOData`;
export const unitServiceOdataFriendly = `${rootDomainCore}/OData/UnitOData`;
export const inspectionServiceOdataFriendly = `${rootDomainCore}/OData/InspectionOData`;
export const documentServiceOdataFriendly = `${rootDomainCore}/OData/DocumentOData`;
export const impersonationService = `${rootDomainCore}/api/v1/Impersonation`;
export const imageService = `${rootDomainCore}/api/v1/Image`;
export const inspectionsService = `${rootDomainCore}/api/v1/Inspection`;
export const assetsService = `${rootDomainCore}/api/v1/Assets`;
export const conductInspectionService = `${rootDomainCore}/api/v1/ConductInspection`;
export const teamsService = `${rootDomainCore}/api/v1/Teams`;
export const tasksService = `${rootDomainCore}/api/v1/Tasks`;
