import { DataManager, ODataV4Adaptor } from '@syncfusion/ej2-data';
import { IListItemOptions, ITransactionListOptions } from '../../../../../types/ListFilters';
import { ITransactionItem } from '../../../../../types/Transaction';
import { transactionServiceOdataFriendly } from '../../../../../configuration/domains';
import { ITransactionGridFilterOptions } from '../_types';
import { createBearerToken } from '../../../../../helpers/authHelpers';
import { toQueryString } from '../../../../../services/DocumentService';
import { getDateWithoutOffset } from '../../../../../helpers/timerHelpers';
import { CustomODataV4Adapter } from '../_adapters';

export const defaultPageSize = 500;
export const paidStatusId = "Paid";
export const unpaidStatusId = "Unpaid";
export const overdueStatusId = "Overdue";

export function generateOdataSource(listItemOptions?: IListItemOptions, transactionOptions?: ITransactionListOptions, startDate?: Date, endDate?: Date): DataManager {
    let stringed = toQueryString(listItemOptions);
    let stringed2 = toQueryString(transactionOptions);
    let dateFilter = ``;

    console.debug(`MohTransactionsGrid - generateOdataSource - listItemOptions: ${stringed}`)
    console.debug(`MohTransactionsGrid - generateOdataSource - transactionOptions: ${stringed2}`)

    if (startDate && endDate) {
        let startDate1 = getDateWithoutOffset(startDate).toISOString();
        let endDate1 = getDateWithoutOffset(endDate).toISOString();
        dateFilter += `&startDate=${startDate1}&endDate=${endDate1}`
    }

    let dataManager: DataManager = new DataManager({
        adaptor: new CustomODataV4Adapter,
        url: `${transactionServiceOdataFriendly}?${stringed}&${stringed2}${dateFilter}`,
        headers: [
            { "Authorization": createBearerToken() }
        ]
    });

    return dataManager;
}

export function transactionDataRequestOptions(page: number, size: number, transactionDataFilterOptions?: ITransactionGridFilterOptions): IListItemOptions {
    let options: IListItemOptions = {
        page,
        size
    };

    if (!transactionDataFilterOptions) {
        return options;
    }

    if (transactionDataFilterOptions.propertyId) {
        options.propertyId = transactionDataFilterOptions.propertyId;
    }

    if (transactionDataFilterOptions.contactId) {
        options.contactId = transactionDataFilterOptions.contactId;
    }

    return options;
}

export function normaliseTransactionData(data: Array<object>): Array<ITransactionItem> {
    let result: Array<ITransactionItem> = [];

    data.forEach((x: ITransactionItem) => {
        x.invoiceDate != null ? x.invoiceDate = new Date(x.invoiceDate) : null;
        x.dueDate != null ? x.dueDate = new Date(x.dueDate) : null,
            x.paidDate != null ? x.paidDate = new Date(x.paidDate) : null;
        x.dateAdded != null ? x.dateAdded = new Date(x.dateAdded) : null;

        let invoiceMonthYear = new Date(x.invoiceDate);
        invoiceMonthYear.setDate(1);
        x.invoiceMonthYear = invoiceMonthYear;

        result.push(x);
    });

    return result;
}