// Import React
import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";

import styles from "./EditEntityGroupModal.module.scss";
import "react-dropzone-uploader/dist/styles.css";
import { IOptions } from "../../atoms/SelectDropdown";
import {
  getTypesForDropdown,
  updateDefaults
} from "../../../services/EntityService";
import MaterialCheckbox from "../../atoms/MaterialCheckbox";
import GenericButton from "../../atoms/GenericButton";
import RotatingLoader from "../../atoms/RotatingLoader";
import SuccessTick from "../../atoms/SuccessTick";

interface IProps {
  history: any;
  location: any;
  match: any;
  open: boolean;
  onClose(): void;
  entityGroupId: string;
}

interface IState {
  entityTypes: Array<IOptions>;
  selectedDefaultId: string;
  loading: boolean;
  success: boolean;
}

class EditEntityGroupModal extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      entityTypes: [],
      selectedDefaultId: "",
      loading: false,
      success: false
    };

    this.onChangeDefault = this.onChangeDefault.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onChangeDefault(selectedId: string): void {
    this.setState({
      selectedDefaultId: selectedId
    });
  }

  onSave(): void {
    this.setState({
      loading: true
    });
    updateDefaults(this.props.entityGroupId, this.state.selectedDefaultId).then(
      resp => {
        if (resp && resp.status == 200) {
          this.setState(
            {
              loading: false,
              success: true
            },
            () => {
              setTimeout(() => {
                this.props.onClose();
              }, 2000);
            }
          );
        }
      }
    );
  }

  componentDidMount(): void {
    getTypesForDropdown(this.props.entityGroupId).then(resp => {
      if (resp && resp.status == 200 && resp.data) {
        this.setState(
          {
            entityTypes: resp.data
          },
          () => {
            let dflt = this.state.entityTypes.filter(et => et.isDefault);
            if (dflt && dflt.length > 0) {
              this.setState({
                selectedDefaultId: dflt[0].value
              });
            }
          }
        );
      }
    });
  }

  render() {
    return (
      <Modal
        modalId={styles.modal}
        classNames={{ modal: styles.modal }}
        open={this.props.open}
        onClose={() => this.props.onClose()}
        center
      >
        <div className={styles.addDocumentContent}>
          <Row className={styles.header}>
            <Col>
              <div className={styles.h1}>Edit Entity Group</div>
            </Col>
          </Row>

          {this.state.loading ? (
            <div>
              <RotatingLoader loading={true} text="Updating..." />
            </div>
          ) : this.state.success ? (
            <div>
              <SuccessTick />
              <p className={styles.success}>Entity Group updated</p>
            </div>
          ) : (
            <div>
              <ul>
                {this.state.entityTypes.map((et, index) => {
                  return (
                    <li key={index}>
                      {et.label}{" "}
                      <MaterialCheckbox
                        checked={et.value == this.state.selectedDefaultId}
                        onChange={() => this.onChangeDefault(et.value)}
                        labelText="Is Default?"
                      />
                    </li>
                  );
                })}
              </ul>
              <GenericButton border text="Save" onClick={this.onSave} />
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default withRouter(EditEntityGroupModal);
