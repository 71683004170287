import React from "react";
import { connect } from "react-redux";
import styles from "./Toast.module.scss";

import {
  ToastComponent,
  ToastModel,
} from "@syncfusion/ej2-react-notifications";
import { PositionDataModel } from "@syncfusion/ej2-popups";
import { capitaliseFirstLetter } from "../../../helpers/formattingHelpers";
import { updateToastMessage } from "../../../helpers/toastHelper";
import { toastDisplayTimeInMs } from "../../../configuration/appConfig";

interface IProps {
  toast_text?: string;
  toast_type?: string;
  toast_duration?: number;
}

interface IState {
  duration: number;
}

class Toast extends React.Component<IProps, IState> {
  public toastInstance: ToastComponent;
  private position: PositionDataModel = { X: "Right", Y: "Top" };
  public timeOutDelay = 100000;
  public toastFlag = 0;

  constructor(props: any) {
    super(props);

    this.state = {
      duration: toastDisplayTimeInMs
    };

    this.toastCreated = this.toastCreated.bind(this);
    this.showMessage = this.showMessage.bind(this);
    this.onBeforeOpen = this.onBeforeOpen.bind(this);
  }

  componentWillReceiveProps(newProps: IProps): void {
    if (
      newProps.toast_text != this.props.toast_text &&
      newProps.toast_text != ""
    ) {
      this.showMessage(newProps.toast_text, newProps.toast_type)
      updateToastMessage("", "", 0);
    }
  }

  getToastMessage(input: string): string {
    return `<div class=${styles.test}>${input}</div>`;
  }

  showMessage(message: string, type: string) {
    let model: ToastModel = {
      title: capitaliseFirstLetter(type) + "!",
      content: this.getToastMessage(message),
      cssClass: "e-toast-" + type,
    };

    if (!this.toastInstance) {
      return;
    }

    this.toastInstance.show(model);
  }

  public onBeforeOpen(): void {
    if (!this.toastInstance) {
      return;
    }

    this.toastInstance.element.style.display = "block";
  }

  public toastCreated(): void {

  }

  render() {
    return (
      <div className={styles.toast}>
        <ToastComponent
          click={(e: any) => e.clickToClose = true}
          cssClass={styles.toast}
          newestOnTop
          ref={(toast) => (this.toastInstance = toast)}
          position={this.position}
          created={this.toastCreated}
          id="toast_type"
          className={styles.toast}
          beforeOpen={this.onBeforeOpen}
          timeOut={this.state.duration}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  toast_text: state.toast.toast_text,
  toast_type: state.toast.toast_type,
  toast_duration: state.toast.toast_duration,
});

export default connect(mapStateToProps)(Toast);