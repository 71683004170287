import { apiService } from './ApiService';
import * as domains from '../configuration/domains';
import { createBearerToken } from '../helpers/authHelpers';
import { IUpdateUserRequest } from '../types/UserService/IUser';

const toQueryStringArray = (arr: string[]): string => {
    if (!arr || arr.length === 0) {
      return "";
    }
  
    let query = "";
  
    arr.map((a) => {
      query += "&userIds=" + a;
    });
  
    return query;
  };

export const deleteUser = (userId: string): Promise<any> => {
    return apiService.delete(`${domains.userService}/${userId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const deleteUsers = (userId: string[]): Promise<any> => {
    var usersStringed = toQueryStringArray(userId);

    return apiService.delete(`${domains.userService}/delete?${usersStringed}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const toggleUserActiveStatus = (userId: string, status: boolean): Promise<any> => {
    return apiService.get(`${domains.userService}/toggle-status/${userId}/${status}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const updateUser = (request: IUpdateUserRequest): Promise<any> => {
    return apiService.put(`${domains.userService}`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}