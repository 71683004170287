import React from "react";
import styles from "./IFrameContainer.module.scss";

interface IProps {
  url: string;
  scrolling: boolean;
}

class IFrameContainer extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { url, scrolling } = this.props;

    return (
      <div className={styles.iframe}>
        <iframe scrolling={scrolling ? 'yes' : 'no'} src={url} frameBorder="0" />
      </div>
    );
  }
}

export default IFrameContainer;