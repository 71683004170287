// Import React
import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Sidebar from "react-sidebar";

import styles from "./ChangeProfileImageSidePanel.module.scss";
import ChangeProfileImageForm from "../ChangeProfileImageForm";
import { sidebarStyling } from "../../../global/variables/_variables";

interface IProps {
  isOpen: boolean;
  onSetOpen(open: boolean): void;
}

interface IRouterProps {
  history: any;
  location: any;
  match: any;
}

interface IState {
  sidebarOpen: boolean;
}

type Props = IProps & IRouterProps;

class ChangeProfileImageSidePanel extends Component<Props, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      sidebarOpen: false
    };
  }

  render() {
    return (
      <div>
        <Sidebar
          touch={false}
          sidebarClassName={styles.sidebar}
          sidebar={<ChangeProfileImageForm />}
          open={this.props.isOpen}
          onSetOpen={this.props.onSetOpen}
          styles={sidebarStyling}
        >
          <div />
        </Sidebar>
      </div>
    );
  }
}

export default withRouter(ChangeProfileImageSidePanel);
