import styled from 'styled-components';

export const ContainerDiv = styled.div`
    padding: 15px 5px 15px 5px;
    //border: solid lightgray;
    background-color: #EFE4E4;
`;

export const InnerContainerDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
`;

export const CheckContainerDiv = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: space-between;
`;