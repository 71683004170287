
import { IDocumentUploadOptions } from '../../../molecules/AddDocumentModal'
import { addURLParameter } from '../../../../helpers/locationHelpers'
import { toggleAddDocumentPopup } from '../../../../helpers/sidebarHelpers'
import { IPropertyConfigurationRequest } from '../../../../types/UnitService'
import { updatePropertyStatus } from '../../../../services/PropertyService'
import { updateCalculateReturnsProperty } from '../../../../helpers/propertyHelpers'
import { processToast } from '../../../../helpers/toastHelper'
import { ICalculateReturnsData } from '../../../../reducers/propertyReducer';
import { IDataResponse } from '../../../../types/ApiService'

export const onAddDocument = (propertyId: string, history: any): void => {
    let options: IDocumentUploadOptions = {}

    if (propertyId) {
        options.propertyId = propertyId
    }

    let query = 'uploadDocumentOptions=' + JSON.stringify(options)

    let search = addURLParameter(location.search, query)

    history.push({ search })

    toggleAddDocumentPopup(true)
}

export const onMultiAddUnit = (propertyId: string, propertyName: string, propertyType: string, residentialType: string, commercialType: string, history: any): void => {
    let query: IPropertyConfigurationRequest = {
        propertyId: propertyId,
        propertyName: propertyName,
        propertyType: propertyType,
        residentialType: residentialType,
        commercialType: commercialType,
        returnUrl: `/dashboard/property/${propertyId}/summary`,
    }

    let toQuery = JSON.stringify(query)
    history.push(`/dashboard/units/multi-add?propertyConfiguration=${toQuery}`)
} 

export const markAsPropertyPurchased = (propertyId: string): void => {
    updatePropertyStatus(propertyId, false).then(
        (resp) => {
            if (resp && resp.status == 200 && resp.data) {
                let response: IDataResponse<any> = resp.data
                if (response.isSuccess) {
                    processToast(response)

                    let reduxData: ICalculateReturnsData = {
                        propertyId: propertyId,
                        propertyName: response.data.propertyName,
                        propertyStatus: response.data.propertyStatus,
                        propertyStatusId: response.data.propertyStatusId,
                        propertyImage: response.data.propertyImage,
                        address: response.data.address,
                        propertySummary: response.data.propertySummary,
                        propertyValuation: response.data.propertyValuation,
                        moreInformation: response.data.moreInformation,
                        chartData: response.data.chartData,
                        propertyDetails: response.data.propertyDetail,
                        propertyContacts: response.data.propertyContacts,
                        propertyImages: response.data.images,
                    }

                    updateCalculateReturnsProperty(reduxData)
                }
            }
        }
    )
}

export const getFromLS = (key: string, storeId: string) => {
    let ls = {};
    if (global.localStorage) {
        try {
            ls = JSON.parse(global.localStorage.getItem(storeId)) || {};
        } catch (e) {
            /*Ignore*/
        }
    }
    return ls[key];
}
  
export const saveToLS = (layoutLgValue, layoutMdValue, layoutXsValue, layoutVisibiltyConfigValue, storeId, version) => {
    if (global.localStorage) {
        global.localStorage.setItem(
            storeId,
            JSON.stringify({
                    ["layoutLg"]: layoutLgValue,
                    ["layoutMd"]: layoutMdValue,
                    ["layoutXs"]: layoutXsValue,
                    ["layoutVisibiltyConfig"]: layoutVisibiltyConfigValue,
                    ["dashboardVersion"]: version
                })
        );
    }
}

export const getLayoutFromLS = (key: string, storeId: string) => {
    return JSON.parse(JSON.stringify(getFromLS(key, storeId)))
}
