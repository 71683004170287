import React from 'react';
import {
    Category,
    ChartComponent,
    ColumnSeries,
    Legend,
    DataLabel,
    AreaSeries,
    LineSeries,
    SeriesCollectionDirective,
    SeriesDirective,
    RangeColorSettingsDirective, 
    RangeColorSettingDirective,
    Tooltip,
    Zoom,
    DateTime,
    Inject,
    AxesDirective,
    AxisDirective,
    Animation,
    Crosshair,
    ITooltipRenderEventArgs
} from '@syncfusion/ej2-react-charts';
import { Loader } from "@rentchief/components";

interface IChartProps {
    chartType: any;
    datasource: Array<object>;
    xAxisFieldName?: string;
    yAxisFieldName?: string;
    yAxisName?: string;
    name?: string;
    fillColour?: string;
    fillOpacity?: number;
    showMarker?: boolean;
    visible?: boolean;
}

interface IProps {
    id?: string;
    loading?: boolean;
    xAxisTitle?: string;
    xAxisLabelFormat?: string;
    yAxisTitle?: string;
    yAxisLabelFormat?: string;
    xAxisMinimum?: number;
    xAxisMaximum?: number;
    xAxisValueType?:any;
    xAxisInterval?: number;
    yAxisMinimum?: number;
    yAxisMaximum?: number;
    yAxisInterval?: number;
    rightAxisName?: string;
    rightAxisTitle?: string;
    rightAxisLabelFormat?: string;
    rightAxisInterval?: number;
    height?: string;
    width?: string;
    charts?: IChartProps[];
}

const RCMultiChart = (props: IProps) => {
    const getCharts = () =>
    {
        return (
                <SeriesCollectionDirective>
                    {
                        props.charts.map((chart)=> {
                                return (
                                    <SeriesDirective
                                        type={chart.chartType}
                                        dataSource={chart.datasource}
                                        xName={chart.xAxisFieldName}
                                        yName={chart.yAxisFieldName}
                                        yAxisName={chart.yAxisName}
                                        name={chart.name}
                                        fill={chart.fillColour}
                                        opacity={chart.fillOpacity}
                                        marker={chart.showMarker != null && chart.showMarker == true ? { dataLabel: { visible: true } } : null}
                                        visible={chart.visible != null && chart.visible == false ? false : true }
                                    />
                                )
                            }
                        )
                    }
                </SeriesCollectionDirective>
        );
    }; 

    const tooltipRender = (args: ITooltipRenderEventArgs) => {
        let formattedString = `${args.series.name} : ${args.point.y}`;
        if(args.series.name != "LTV"){
            formattedString = `${args.series.name} : £${Number(args.point.y).toFixed(0)}`;
        }
        args.text = formattedString;
      };

    if (props.loading != undefined && props.loading) {
        return <Loader />;
    } else {
        return (
            <ChartComponent
                style={{ "height": "100%", "width": "100%" }}
                tabIndex={-1}
                id={props.id != undefined ? props.id : undefined}
                primaryXAxis={{
                    title: props.xAxisTitle != undefined ? props.xAxisTitle : undefined,
                    labelFormat: props.xAxisLabelFormat,
                    minimum: props.xAxisMinimum != undefined ? props.xAxisMinimum : null,
                    maximum: props.xAxisMaximum != undefined ? props.xAxisMaximum : null,
                    interval: props.xAxisInterval != undefined ? props.xAxisInterval : undefined,
                    //edgeLabelPlacement: 'Shift',
                    //labelPlacement: 'BetweenTicks',
                    valueType: props.xAxisValueType,
                }}
                primaryYAxis={{
                    title: props.yAxisTitle != undefined ? props.yAxisTitle : undefined,
                    labelFormat: props.yAxisLabelFormat,
                    minimum: props.yAxisMinimum != undefined ? props.yAxisMinimum : null,
                    maximum: props.yAxisMaximum != undefined ? props.yAxisMaximum : null,
                    interval: props.yAxisInterval != undefined ? props.yAxisInterval : undefined,
                    crosshairTooltip: { enable: false }
                }}

                tooltipRender={tooltipRender}
                tooltip={{ enable: true, shared: true, format: '${series.name} : ${point.y}', header:'Month ${point.x}' }}
                // tooltip={{ enable: true, shared: true, format: '${series.name} : ${point.x} : ${point.y}' }}
                height={props.height}
                legendSettings={ {visible:true, position:'Top'/* , mode: 'Range' */}}
                enableAnimation={true}
                crosshair={{enable: true, lineType: 'Vertical' }}
                width={props.width}
            >
                <Inject services={[ColumnSeries, AreaSeries, LineSeries, Legend, Tooltip, DataLabel, Category, Zoom, DateTime, Crosshair]} />
                {/* <AxesDirective>
                    <AxisDirective 
                        rowIndex={0} 
                        name={props.rightAxisName} 
                        opposedPosition={true} 
                        title={props.rightAxisTitle} 
                        labelFormat={props.rightAxisLabelFormat} 
                        interval={props.rightAxisInterval}>
                    </AxisDirective>
                </AxesDirective> */}
{/*                 <RangeColorSettingsDirective>
                    <RangeColorSettingDirective label="Loss" start={-99999999} end={0} colors={color1}></RangeColorSettingDirective>
                    <RangeColorSettingDirective label="Profit" start={0} end={99999999} colors={color2}></RangeColorSettingDirective>
                </RangeColorSettingsDirective> */}
                {getCharts()}
                </ChartComponent>
        );
    }
};

export { RCMultiChart, IChartProps };
