import React, { useContext } from 'react';
import ConductInspectionContext from '../../../../types/Dynamic/conductInspectionContext';
import styles from './Error.module.scss';

const Error = ({ name }) => {
    const { getValidation } = useContext(ConductInspectionContext);

    const validations = getValidation(name);
    if (validations) {
        return <div className={styles.error}>{validations.message}</div>
    }

    return null;
}

export default Error;