import React, { Component } from 'react'

// Import Styles
import styles from './PropertyConfigurationForm.module.scss'
import { withRouter } from 'react-router-dom'
import Buttons from '../../../atoms/Buttons'
import { ErrorMessage, Form, Formik } from 'formik'
import FormGroup from 'reactstrap/lib/FormGroup'
import SelectDropdown, { IOptions } from '../../../atoms/SelectDropdown'
import {
    getUnitTypesDropdown,
    getUnitRentalStatusDropdown,
    addUnit,
    updateUnit,
    multiAddUnit,
    getPropertyConfigurationDefaults,
} from '../../../../services/UnitService'
import { MultiAddUnit } from '../../../../YupValidationSchemas'
import { getTypesForDropdown } from '../../../../services/EntityService'
import {
    CommercialPropertyEntityId,
    PropertyCommercialTypeGroupId,
    PropertyResidentialTypeGroupId,
    PropertyTypeGroupId,
    ResidentialPropertyEntityId,
} from '../../../../configuration/appConfig'
import RotatingLoader from '../../../atoms/RotatingLoader'
import CloseButton from '../../../atoms/CloseButton'
import { CreateUnitRequest } from '../../../../types/PropertyService/IPropertyService'
import { IDataResponse } from '../../../../types/ApiService'
import { processToast } from '../../../../helpers/toastHelper'
import { goToRoute } from '../../../../history'
import CurrencyInput from '../../../atoms/CurrencyInput'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import {
    updatePropertyType,
    updatePropertyTypes,
} from '../../../../helpers/propertyHelpers'
import { IPropertyConfigurationRequest } from '../../../../types/UnitService'
import { removeURLParameter } from '../../../../helpers/locationHelpers'
import Breadcrumbs from '../../../atoms/Breadcrumbs'
const queryString = require('query-string')

interface IWithRouterProps {
    history: any
    location: any
    match: any

    propertyId: string
    propertyName?: string
    propertyType?: string
    residentialType?: string
    commercialType?: string
}

interface IValues {
    rooms: Array<CreateUnitRequest>
}

interface IState {
    submitting: boolean
    rentalStatus: Array<IOptions>
    unitTypes: Array<IOptions>
    furnishedTypes: Array<IOptions>
    propertyTypeIds: Array<IOptions>
    residentialTypeIds: Array<IOptions>
    commercialTypeIds: Array<IOptions>

    values: IValues

    propertyId: string
    propertyName: string
    propertyType: string
    residentialType: string
    commercialType: string

    loading: boolean
    validation: string

    hasDefaults: boolean
    gettingDefaults: boolean

    returnUrl: string
}

class PropertyConfigurationForm extends Component<IWithRouterProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            submitting: false,
            rentalStatus: [],
            unitTypes: [],
            furnishedTypes: [],
            residentialTypeIds: [],
            commercialTypeIds: [],
            propertyTypeIds: [],
            returnUrl: '/dashboard/units',

            values: {
                rooms: [],
            },

            propertyId: this.props.propertyId,
            propertyName: this.props.propertyName,
            propertyType: this.props.propertyType,
            residentialType: this.props.residentialType,
            commercialType: this.props.commercialType,

            loading: false,
            validation: null,
            hasDefaults: true,
            gettingDefaults: false,
        }

        this.onAdd = this.onAdd.bind(this)
        this.onUpdate = this.onUpdate.bind(this)
        this.addRoom = this.addRoom.bind(this)
        this.removeRoom = this.removeRoom.bind(this)
        this.updateAmount = this.updateAmount.bind(this)
        this.updateUnitType = this.updateUnitType.bind(this)
        this.updateTargetRent = this.updateTargetRent.bind(this)
        this.updateIsRentable = this.updateIsRentable.bind(this)
        this.validateForm = this.validateForm.bind(this)
        this.getTypes = this.getTypes.bind(this)
        this.getDefaults = this.getDefaults.bind(this)
        this.isResidential = this.isResidential.bind(this)
        this.isCommercial = this.isCommercial.bind(this)
        this.getSubType = this.getSubType.bind(this)
        this.getUnitTypes = this.getUnitTypes.bind(this)
    }

    getSubType(): string {
        if (this.state.residentialType) {
            let type = this.state.residentialTypeIds.findIndex(
                (x) =>
                    x.value.toLowerCase() ==
                    this.state.residentialType.toLowerCase()
            )
            if (type > -1) {
                return this.state.residentialTypeIds[type].label
            }
        }

        if (this.state.commercialType) {
            let type = this.state.commercialTypeIds.findIndex(
                (x) => x.value == this.state.commercialType
            )
            if (type > -1) {
                return this.state.commercialTypeIds[type].label
            }
        }

        return ''
    }

    isResidential(): boolean {
        let type = this.state.propertyTypeIds.findIndex(
            (x) => x.value == this.state.propertyType
        )
        if (type > -1) {
            return this.state.propertyTypeIds[type].label == 'Residential'
        }

        return false
    }

    isCommercial(): boolean {
        let type = this.state.propertyTypeIds.findIndex(
            (x) => x.value == this.state.propertyType
        )
        if (type > -1) {
            return this.state.propertyTypeIds[type].label == 'Commercial'
        }

        return false
    }

    validateForm(): boolean {
        if (!this.state.values.rooms) {
            return
        }

        this.setState({
            validation: null,
        })

        let hasError = false

        this.state.values.rooms.map((x) => {
            if (!x.amount) {
                this.setState({
                    validation:
                        'You must enter an amount of units to be generated',
                })
                hasError = true
            }
        })

        return hasError
    }

    updateSizeSqFt(size: number, index: number): void {
        let copy = this.state.values.rooms.map((object: CreateUnitRequest) => ({
            ...object,
        }))
        if (!copy) {
            return
        }

        if (!copy[index]) {
            return
        }

        copy[index].sizeSqFt = size

        this.setState({
            values: {
                rooms: copy,
            },
        })
    }

    updateFloor(floor: string, index: number): void {
        let copy = this.state.values.rooms.map((object: CreateUnitRequest) => ({
            ...object,
        }))
        if (!copy) {
            return
        }

        if (!copy[index]) {
            return
        }

        copy[index].floor = floor

        this.setState({
            values: {
                rooms: copy,
            },
        })
    }

    updateIsRentable(isRentable: boolean, index: number): void {
        let copy = this.state.values.rooms.map((object: CreateUnitRequest) => ({
            ...object,
        }))
        if (!copy) {
            return
        }

        if (!copy[index]) {
            return
        }

        copy[index].isRentable = isRentable

        this.setState({
            values: {
                rooms: copy,
            },
        })
    }

    updateUnitType(e: IOptions, index: number): void {
        let copy = this.state.values.rooms.map((object: CreateUnitRequest) => ({
            ...object,
        }))
        if (!copy) {
            return
        }

        if (!copy[index]) {
            return
        }

        copy[index].unitTypeId = e.value
        copy[index].unitName = e.label

        this.setState({
            values: {
                rooms: copy,
            },
        })
    }

    updateTargetRent(value: any, index: number): void {
        if (!Number(value)) {
            return
        }

        let copy = this.state.values.rooms.map((object: CreateUnitRequest) => ({
            ...object,
        }))
        if (!copy) {
            return
        }

        if (!copy[index]) {
            return
        }

        copy[index].targetRent = value

        this.setState({
            values: {
                rooms: copy,
            },
        })
    }

    updateAmount(e: any, index: number): void {
        let copy = this.state.values.rooms.map((object: CreateUnitRequest) => ({
            ...object,
        }))
        if (!copy) {
            return
        }

        if (!copy[index]) {
            return
        }

        copy[index].amount = e.currentTarget.value

        this.setState({
            values: {
                rooms: copy,
            },
        })
    }

    getDefaults(): void {
        this.setState({
            hasDefaults: true,
        })

        if (!this.state.commercialType && !this.state.residentialType) {
            return
        }

        this.setState({
            gettingDefaults: true,
        })

        let propertyType = this.state.propertyTypeIds.findIndex(
            (x) => x.value == this.state.propertyType
        )
        if (propertyType > -1) {
        }

        let subTypeId = ''
        if (this.state.residentialType) {
            let subType = this.state.residentialTypeIds.findIndex(
                (x) => x.value == this.state.residentialType
            )
            if (subType > -1) {
                subTypeId = this.state.residentialTypeIds[subType].label
            }
        }

        if (this.state.commercialType) {
            let subType = this.state.commercialTypeIds.findIndex(
                (x) => x.value == this.state.commercialType
            )
            if (subType > -1) {
                subTypeId = this.state.commercialTypeIds[subType].label
            }
        }

        let propertyTypeId = this.state.propertyTypeIds[propertyType].label

        getPropertyConfigurationDefaults(propertyTypeId, subTypeId)
            .then((resp) => {
                if (resp && resp.status == 200) {
                    this.setState({
                        values: {
                            rooms: resp.data,
                        },
                        hasDefaults: true,
                    })
                } else {
                    this.setState({
                        values: {
                            rooms: [],
                        },
                        hasDefaults: false,
                    })
                }
                this.setState({
                    gettingDefaults: false,
                })
            })
            .catch((e) => {
                this.setState({
                    gettingDefaults: false,
                    hasDefaults: false,
                })
            })
    }

    getUnitTypes = async () => {
        let unitTypes = await getUnitTypesDropdown(
            this.getSubType(),
            this.isResidential(),
            this.isCommercial()
        )
        if (unitTypes && unitTypes.status == 200) {
            this.setState({
                unitTypes: unitTypes.data,
            })
        }
    }

    getTypes = async () => {
        let unitRentalStatusTypes = await getUnitRentalStatusDropdown()
        if (unitRentalStatusTypes && unitRentalStatusTypes.status == 200) {
            this.setState({
                rentalStatus: unitRentalStatusTypes.data,
            })
        }

        let residentialTypeIds = await getTypesForDropdown(
            PropertyResidentialTypeGroupId
        )
        if (residentialTypeIds && residentialTypeIds.status == 200) {
            this.setState({
                residentialTypeIds: residentialTypeIds.data,
            })
        }

        let commercialTypeIds = await getTypesForDropdown(
            PropertyCommercialTypeGroupId
        )
        if (commercialTypeIds && commercialTypeIds.status == 200) {
            this.setState({
                commercialTypeIds: commercialTypeIds.data,
            })
        }

        let propertyTypeGroup = await getTypesForDropdown(PropertyTypeGroupId)
        if (propertyTypeGroup && propertyTypeGroup.status == 200) {
            this.setState({
                propertyTypeIds: propertyTypeGroup.data,
            })
        }

        let unitTypes = await this.getUnitTypes()

        return Promise.allSettled([
            unitTypes,
            unitRentalStatusTypes,
            residentialTypeIds,
            commercialTypeIds,
            propertyTypeGroup,
        ]).then(() => {
            if (
                this.state.propertyType &&
                (this.state.residentialType || this.state.commercialType)
            ) {
                this.getDefaults()
            }
        })
    }

    componentDidMount(): void {
        this.getTypes()

        let propertyConfigurationRequest = queryString.parse(
            this.props.location.search
        ).propertyConfiguration
        if (propertyConfigurationRequest) {
            let parsed: IPropertyConfigurationRequest = JSON.parse(
                propertyConfigurationRequest
            )
            if (parsed) {
                this.setState({
                    propertyId: parsed.propertyId,
                    propertyName: parsed.propertyName,
                    propertyType: parsed.propertyType,
                    residentialType: parsed.residentialType,
                    commercialType: parsed.commercialType,
                    returnUrl: parsed.returnUrl,
                })
            }

            //let removeQuery = removeURLParameter(this.props.location.search, "propertyConfiguration");
            //this.props.history.push({
            //  search: removeQuery
            //});
        }
    }

    onAdd(): void {}

    onUpdate(): void {
        this.setState({
            loading: true,
        })
        multiAddUnit({
            units: this.state.values.rooms,
            propertyId: this.state.propertyId,
            propertyType: this.state.propertyType,
            residentialType: this.state.residentialType,
            commercialType: this.state.commercialType,
        }).then((resp) => {
            if (resp && resp.status == 200) {
                this.setState({
                    loading: false,
                })

                let response: IDataResponse<any> = resp.data
                processToast(response)

                if (response.isSuccess) {
                    goToRoute(
                        `/dashboard/property/${this.state.propertyId}/spec/units`
                    )

                    updatePropertyTypes({
                        propertyType: this.state.propertyType,
                        residentialType: this.state.residentialType,
                        commercialType: this.state.commercialType,
                    })
                }
            }
        })
    }

    removeRoom(index: number): void {
        let filtered = this.state.values.rooms.filter((x, i) => i != index)
        this.setState({
            values: {
                rooms: filtered,
            },
        })
    }

    addRoom(): void {
        this.setState({
            values: {
                rooms: this.state.values.rooms.concat([
                    {
                        unitTypeId: null,
                        unitName: null,
                        amount: null,
                        isRentable: false,
                    },
                ]),
            },
        })
    }

    render() {
        if (!this.state.propertyId) {
            return <div>No Property ID has been provided</div>
        }

        if (this.state.loading) {
            return (
                <div className={styles.page}>
                    <RotatingLoader loading={true} text="Loading..." />
                </div>
            )
        }

        return (
            <div className={styles.page}>
                <div className={styles.breadcrumbs}>
                    <Breadcrumbs
                        breadcrumbs={[
                            {
                                name: this.state.propertyName,
                                route: `/dashboard/property/${this.state.propertyId}/spec/units`,
                                ordinal: 0,
                            },
                            {
                                name: 'Configure multiple units',
                                ordinal: 1,
                            },
                        ]}
                    />
                </div>

                <div className={styles.infoBox}>
                    Setting the property type will apply a default configuration
                    which you can amend. New units will be added each time this
                    screen is saved.
                </div>
                <Formik
                    initialValues={{
                        values: this.state.values,
                    }}
                    enableReinitialize={true}
                    //validationSchema={MultiAddUnit}
                    onSubmit={(values: any, { setSubmitting }) => {
                        if (!this.validateForm()) {
                            this.onUpdate()
                        }
                    }}
                    render={({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        errors,
                        status,
                    }) => (
                        <Form>
                            <div
                                className={`${styles.row} ${styles.fullWidthMobile}`}
                            >
                                <div className={styles.col}>
                                    <div className={styles.column}>
                                        <FormGroup
                                            className={styles.flexColumn}
                                        >
                                            <div className={styles.label}>
                                                Property Type
                                            </div>
                                            <SelectDropdown
                                                data={
                                                    this.state.propertyTypeIds
                                                }
                                                selectedId={
                                                    this.state.propertyType
                                                }
                                                placeholder="Property type..."
                                                onSelect={(id: IOptions) => {
                                                    this.setState(
                                                        {
                                                            propertyType:
                                                                id.value,
                                                            residentialType:
                                                                null,
                                                            commercialType:
                                                                null,
                                                            values: {
                                                                rooms: [],
                                                            },
                                                        },
                                                        () => {
                                                            this.getTypes()
                                                        }
                                                    )
                                                }}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                {this.state.propertyType &&
                                    this.state.propertyType.toUpperCase() ==
                                        ResidentialPropertyEntityId.toUpperCase() && (
                                        <div className={styles.col}>
                                            <div className={styles.column}>
                                                <FormGroup
                                                    className={
                                                        styles.flexColumn
                                                    }
                                                >
                                                    <div
                                                        className={styles.label}
                                                    >
                                                        Residential Type
                                                    </div>
                                                    <SelectDropdown
                                                        data={
                                                            this.state
                                                                .residentialTypeIds
                                                        }
                                                        selectedId={
                                                            this.state
                                                                .residentialType
                                                        }
                                                        placeholder="Residential type..."
                                                        onSelect={(
                                                            id: IOptions
                                                        ) =>
                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    residentialType:
                                                                        id.value,
                                                                }),
                                                                () => {
                                                                    this.getUnitTypes()
                                                                    this.getDefaults()
                                                                }
                                                            )
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    )}

                                {this.state.propertyType &&
                                    this.state.propertyType.toUpperCase() ==
                                        CommercialPropertyEntityId.toUpperCase() && (
                                        <div className={styles.col}>
                                            <div className={styles.column}>
                                                <FormGroup
                                                    className={
                                                        styles.flexColumn
                                                    }
                                                >
                                                    <div
                                                        className={styles.label}
                                                    >
                                                        Commercial Type
                                                    </div>
                                                    <SelectDropdown
                                                        data={
                                                            this.state
                                                                .commercialTypeIds
                                                        }
                                                        selectedId={
                                                            this.state
                                                                .commercialType
                                                        }
                                                        placeholder="Commercial type..."
                                                        onSelect={(
                                                            id: IOptions
                                                        ) =>
                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    commercialType:
                                                                        id.value,
                                                                }),
                                                                () => {
                                                                    this.getUnitTypes()
                                                                    this.getDefaults()
                                                                }
                                                            )
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    )}
                            </div>

                            <section>
                                <div className={styles.row}>
                                    <div className={styles.col}>
                                        <div className={styles.sectionTitle}>
                                            Units
                                        </div>

                                        <div className={styles.infoBox}>
                                            Enter the number of rooms within the
                                            property. Further detail such as the
                                            tenant can be added to these within
                                            the property details, unit tab.
                                        </div>

                                        {!this.state.hasDefaults &&
                                            this.state.values.rooms.length ===
                                                0 &&
                                            this.state.unitTypes.length > 0 && (
                                                <div
                                                    className={
                                                        styles.infoBoxBlue
                                                    }
                                                >
                                                    The selected configuration
                                                    doesn't have any defaults,
                                                    you must manually add the
                                                    units using the Add more
                                                    button
                                                </div>
                                            )}

                                        {this.state.gettingDefaults ? (
                                            <RotatingLoader
                                                loading={true}
                                                text="Loading defaults..."
                                            />
                                        ) : (
                                            this.state.values.rooms.map(
                                                (x, i) => {
                                                    return (
                                                        <div
                                                            className={
                                                                styles.rooms
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.row
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles.noColumn
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.label
                                                                        }
                                                                    >
                                                                        No.
                                                                    </div>
                                                                    <input
                                                                        max={10}
                                                                        className={
                                                                            styles.input
                                                                        }
                                                                        value={
                                                                            x.amount
                                                                        }
                                                                        type="number"
                                                                        onChange={(
                                                                            e: any
                                                                        ) =>
                                                                            this.updateAmount(
                                                                                e,
                                                                                i
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div
                                                                    className={`${styles.col} ${styles.type}`}
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.label
                                                                        }
                                                                    >
                                                                        Type
                                                                    </div>
                                                                    <FormGroup>
                                                                        {this
                                                                            .state
                                                                            .unitTypes
                                                                            .length >
                                                                            0 && (
                                                                            <SelectDropdown
                                                                                selectedId={
                                                                                    x.unitTypeId
                                                                                }
                                                                                data={
                                                                                    this
                                                                                        .state
                                                                                        .unitTypes
                                                                                }
                                                                                placeholder="Unit Type..."
                                                                                onSelect={(
                                                                                    id: IOptions
                                                                                ) => {
                                                                                    this.updateUnitType(
                                                                                        id,
                                                                                        i
                                                                                    )
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </FormGroup>
                                                                </div>
                                                                <div
                                                                    className={`${styles.col} ${styles.floor}`}
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.label
                                                                        }
                                                                    >
                                                                        Floor
                                                                    </div>
                                                                    <FormGroup>
                                                                        <input
                                                                            max={
                                                                                50
                                                                            }
                                                                            className={
                                                                                styles.input
                                                                            }
                                                                            value={
                                                                                x.floor
                                                                            }
                                                                            type="text"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.updateFloor(
                                                                                    e
                                                                                        .currentTarget
                                                                                        .value,
                                                                                    i
                                                                                )
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div
                                                                    className={`${styles.col} ${styles.size}`}
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.label
                                                                        }
                                                                    >
                                                                        Size
                                                                        SQFT
                                                                    </div>
                                                                    <FormGroup>
                                                                        <input
                                                                            maxLength={
                                                                                9
                                                                            }
                                                                            className={
                                                                                styles.input
                                                                            }
                                                                            value={
                                                                                x.sizeSqFt
                                                                            }
                                                                            type="number"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.updateSizeSqFt(
                                                                                    Number(
                                                                                        e
                                                                                            .currentTarget
                                                                                            .value
                                                                                    ),
                                                                                    i
                                                                                )
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </div>

                                                                <div
                                                                    className={
                                                                        styles.autoCol
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.label
                                                                        }
                                                                    >
                                                                        Rent?
                                                                    </div>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={
                                                                                    x.isRentable
                                                                                }
                                                                                color="primary"
                                                                                onChange={() =>
                                                                                    this.updateIsRentable(
                                                                                        !x.isRentable,
                                                                                        i
                                                                                    )
                                                                                }
                                                                                value="Rent?"
                                                                            />
                                                                        }
                                                                        label="Rent?"
                                                                    />
                                                                </div>
                                                                {x.isRentable && (
                                                                    <div
                                                                        className={`${styles.col} ${styles.targetRent}`}
                                                                    >
                                                                        <div
                                                                            className={
                                                                                styles.label
                                                                            }
                                                                        >
                                                                            Target
                                                                            Rent
                                                                        </div>
                                                                        <FormGroup>
                                                                            <CurrencyInput
                                                                                handleChange={(
                                                                                    event: any,
                                                                                    maskedValue: any,
                                                                                    floatValue: any
                                                                                ) => {
                                                                                    this.updateTargetRent(
                                                                                        floatValue,
                                                                                        i
                                                                                    )
                                                                                }}
                                                                                precision={
                                                                                    2
                                                                                }
                                                                                className={
                                                                                    styles.input
                                                                                }
                                                                                name="targetRent"
                                                                                inputType="tel"
                                                                                value={
                                                                                    x.targetRent
                                                                                        ? x.targetRent.toString()
                                                                                        : null
                                                                                }
                                                                                prefix="£"
                                                                            />
                                                                        </FormGroup>
                                                                    </div>
                                                                )}
                                                                <div
                                                                    className={`${styles.autoCol} ${styles.remove}`}
                                                                >
                                                                    <CloseButton
                                                                        close={() =>
                                                                            this.removeRoom(
                                                                                i
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <hr />
                                                        </div>
                                                    )
                                                }
                                            )
                                        )}

                                        {!this.state.hasDefaults &&
                                            this.state.unitTypes.length ===
                                                0 && (
                                                <div
                                                    className={
                                                        styles.infoBoxRed
                                                    }
                                                >
                                                    You cannot add a Unit for
                                                    the sub type{' '}
                                                    {this.getSubType()} because
                                                    it has not got any Unit
                                                    Types associated.
                                                </div>
                                            )}

                                        {this.state.unitTypes.length > 0 &&
                                        this.state.values.rooms.length < 20 ? (
                                            <p className={styles.addMore}>
                                                <span onClick={this.addRoom}>
                                                    Add more
                                                </span>
                                            </p>
                                        ) : (
                                            <p className={styles.addMore}>
                                                Maximum rows reached
                                            </p>
                                        )}

                                        <div className={styles.error}>
                                            {this.state.validation &&
                                                this.state.validation}
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <div className={styles.row}>
                                <div className={styles.col}>
                                    <Buttons
                                        buttons={[
                                            {
                                                displayType: 'cancel',
                                                elementType: 'button',
                                                onClick: () =>
                                                    goToRoute(
                                                        this.state.returnUrl
                                                    ),
                                            },
                                            {
                                                displayType: 'submit',
                                                elementType: 'button',
                                                onClick: () => handleSubmit(),
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
                />
            </div>
        )
    }
}

export default withRouter(PropertyConfigurationForm)
