import React, { Component } from "react";
import { withRouter, Route, Redirect } from "react-router-dom";
import styles from "./WhitelabelPage.module.scss";
import WhitelabelNavigationBar from "../../molecules/WhitelabelNavigationBar";
const queryString = require("query-string");

interface IWithRouterProps {
  history: any;
  location: any;
  match: any;
}

interface IState {
  showWhiteLabel: boolean;
}

class WhitelabelPage extends Component<IWithRouterProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showWhiteLabel: true
    }
  }

  componentDidMount(){
    let value = queryString.parse(this.props.location.search).showwhitelabel;
    if(value && value === 'false'){
      this.setState({ showWhiteLabel: false});
    }
  }

  render() {
    return (
      <div className={styles.whitelabel}>
        {
          this.state.showWhiteLabel ?
            <WhitelabelNavigationBar />
            :
            <div/>
        }
        
        <div className={this.state.showWhiteLabel ? styles.content : null}>
          <Route
            path={`${this.props.match.url}`}
            exact
            render={() => (
              <Redirect to={`${this.props.match.url}/roi-calculator`} />
            )}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(WhitelabelPage);