import React from "react";
import { Row, Col } from "reactstrap";
import Modal from "react-responsive-modal";

import styles from "./DeleteChatModal.module.scss";
import GenericButton from "../GenericButton";
import RotatingLoader from "../RotatingLoader";
import { deleteChatByChatSessionId } from "../../../services/ChatService";
import { IDataResponse } from "../../../types/ApiService";
import { processToast } from "../../../helpers/toastHelper";
import { removeChatItemFromChatList } from "../../../helpers/chatHelpers";
import { withRouter } from "react-router";

interface IProps {
  history: any;
  location: any;
  match: any;

  open: boolean;
  onOpenModal(): void;
  onCloseModal(): void;
  onDeleteCallback(): void;
  chatSessionId: string;
}

interface IState {
  loading: boolean;
}

export interface IDeleteRequest {
  deleteAssociatedTenancy: boolean;
  deleteAssociatedDocument: boolean;
}

class DeleteChatModal extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false
    };

    this.onDelete = this.onDelete.bind(this);
  }

  onDelete(): void {
    this.setState({
      loading: true
    });

    deleteChatByChatSessionId(this.props.chatSessionId).then(resp => {
      if(resp){
        if (resp && resp.status == 200) {
          let response: IDataResponse<boolean> = resp.data;
          processToast<boolean>(response);
  
          if (response.isSuccess) {
            removeChatItemFromChatList(this.props.chatSessionId);
            //this.props.history.push('/dashboard/chats');
            this.props.onDeleteCallback();
          }
        }
        this.setState({
          loading: false
        });
      }
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <Modal open={this.props.open} onClose={this.props.onCloseModal} center>
          <div>
            <RotatingLoader
              loading={true}
              text={`Deleting Chat...`}
            />
          </div>
        </Modal>
      );
    }

    return (
      <Modal open={this.props.open} onClose={this.props.onCloseModal} center>
        <div>
          <p className={styles.modelHeader}>DELETE CHAT</p>

          <p className={styles.modalBody}>
            Thsi will permanently delete this chat session. Are you sure you want to continue?
              </p>

          <Row>
            <Col className={styles.buttonDiv}>
              <GenericButton border onClick={() => this.onDelete()}>
                Delete
                </GenericButton>
              <GenericButton onClick={() => this.props.onCloseModal()} border>
                Cancel
                </GenericButton>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

export default withRouter(DeleteChatModal);