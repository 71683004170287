import React from "react";
import { Col, Row } from "reactstrap";
import { withRouter } from "react-router-dom";

import styles from "./RoleAssignedUserItem.module.scss";
import { IUserListItem } from "../../../types/ClientService";
import ProfileImage from "../ProfileImage";
import deleteIcon from "../../../images/icons/property-action/Delete.png";
import moreIcon from "../../../images/icons/property-action/Action.png";
import viewIcon from "../../../images/icons/property-action/View.png";

interface IProps {
  user: IUserListItem;
}

interface IWithRouterProps {
  history: any;
  location: any;
  match: any;
}

type Props = IProps & IWithRouterProps;

class RoleAssignedUserItem extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      email,
      forename,
      surname,
      role,
      userId,
      profileImageUri
    } = this.props.user;

    return (
      <Row className={styles.userItem} onClick={() => null}>
        <Col xs="1">
          <ProfileImage
            forename={this.props.user.forename}
            surname={this.props.user.surname}
            image={this.props.user.profileImageUri}
            large={false}
          />
        </Col>
        <Col xs="3">
          <div className={styles.label}>Name</div>
          {forename} {surname}{" "}
        </Col>
        <Col xs="3">
          <div className={styles.label}>Email</div>
          {email}
        </Col>
        <Col xs="2">
          <div className={styles.label}>Assigned since</div>No Date
        </Col>
        <Col xs="2" className={styles.icons}>
          {" "}
          <img src={viewIcon} />{" "}
          <img src={deleteIcon} onClick={() => this.setState({ open: true })} />{" "}
          <img src={moreIcon} />
        </Col>
      </Row>
    );
  }
}

export default withRouter(RoleAssignedUserItem);
