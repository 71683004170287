import React, { useEffect, useState } from 'react'
import { IOptions } from '../components/atoms/SelectDropdown';
import { getTypesForDropdown } from '../services/EntityService';

export const useFetchTenancyEntityData = () => {

    const [dataRetrieved, setDataRetrieved] = useState(false);
    // Dropdown Options
    const [tenancyTypes, setTenancyTypes] = useState<Array<IOptions>>(null);
    const [contractTypes, setContractTypes] = useState<Array<IOptions>>(null);
    const [tenancyStatus, setTenancyStatus] = useState<Array<IOptions>>(null);
    const [rentPayTypes, setRentPayTypes] = useState<Array<IOptions>>(null);

    useEffect(() => {
        getEntities();
    }, []);

    const getEntities = async (): Promise<void> => {
        await getEntityTypes(
          "144e8562-6f5b-4eab-b6fa-d86efb18a41b",
          "tenancyTypes"
        );
        await getEntityTypes(
          "367db491-f955-4061-881b-e4aa4d48c414",
          "contractTypes"
        );
        await getEntityTypes(
          "f8e073ae-fb26-4193-8dd2-56a5fbe275c5",
          "tenancyStatus"
        );
        await getEntityTypes(
          "E3083BC4-3D49-463A-870B-94014F346CF3",
          "rentPayTypes"
        );
    }

    const getEntityTypes = async (type: string, field: string): Promise<void> => {
        let resp = await getTypesForDropdown(type);
        if (resp && resp.data && resp.data.length > 0) {
            switch(field){
                case "tenancyTypes": setTenancyTypes(resp.data); break;
                case "contractTypes": setContractTypes(resp.data); break;
                case "tenancyStatus": setTenancyStatus(resp.data); break;
                case "rentPayTypes": setRentPayTypes(resp.data); break;
                default: return;
            }
        }
    }

    return { tenancyTypes, contractTypes, tenancyStatus, rentPayTypes }
}