import { store } from "../store";
import { IUpdateUserRequest } from "../types/UserService/IUser";
import { IInvitation } from "../types/ContactService/ContactService";
import { updateContactsList } from "./contactHelpers";

export function updateProfileImage(uri: string) {
  store.dispatch({
    type: "PROFILE_IMAGE_UPDATE",
    data: uri
  });
}

export function getLoggedInUsersContactId(): string {
  let data = store.getState();
  return data.user.contact_id;
}

export function getLoggedInUsersUsertId(): string {
  let data = store.getState();
  return data.user.user_id;
}

export function getLoggedInUsersName(): string {
  let data = store.getState();
  return data.user.forename + " " + data.user.surname;
}

export function getLoggedInUsersForename(): string {
  let data = store.getState();
  return data.user.forename;
}

export function getLoggedInUsersSurname(): string {
  let data = store.getState();
  return data.user.surname;
}

export function getLoggedInUsersProfileImage(): string {
  let data = store.getState();
  return data.user.profileImage;
}

export function updateLoggedInUserProfileInReduxStore(data: IUpdateUserRequest): void {
  store.dispatch({
    type: "UPDATE_LOGGED_IN_USER_PROFILE",
    data
  });
}

export function updateInvitationStatusForContactList(invitation: IInvitation, contactId: string): void {
  let data = store.getState().contact.contact_list;
  let contact = data.findIndex(c => c.contact.contactId == contactId);
  
  if(contact < 0){
    return;
  }

  const copy = data.map(object => ({ ...object }));
  copy[contact].invitation = invitation;

  updateContactsList(copy);
}