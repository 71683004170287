import { IContactListItem } from "../types/ContactService/ContactService"
import { IUserContactInfoResponse } from "../types/QuickView";

interface IContactState {
    contact_list: Array<IContactListItem>;
    user_contact_info_popup: Array<IUserContactInfoResponse>;
}

const initialState = {
    contact_list: [],
    user_contact_info_popup: []
}

export default (state: IContactState = initialState, action: any): IContactState => {
    switch (action.type) {

        case "UPDATE_CONTACTS_LIST":
            return {
                ...state,
                contact_list: action.data
            }

        case "UPDATE_USER_CONTACT_INFO_POPUP_LIST":
            return {
                ...state,
                user_contact_info_popup: action.data
            }

        default:
            return state
    }
}