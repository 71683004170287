import React from "react";
import { Button, Row, Col } from "reactstrap";
import Modal from "react-responsive-modal";

import styles from "./MoreInformationModal.module.scss";
import FormattedAddress from "../FormattedAddress";
import { IPropertyValuation } from "../../../reducers/propertyReducer";
import DateTimeFormat from "../DateTimeFormat";
import Currency from "../Currency";
import { IAddress } from "../../../types/PropertyService/IPropertyService";

interface IProps {
  open: boolean;
  salesHistory: Array<IPropertyValuation>;
  address: IAddress;
  currentValuation: number;
  propertyType: string;
  onOpenModal(): void;
  onCloseModal(): void;
}

class MoreInformationModal extends React.Component<IProps, {}> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <Modal
        classNames={{ modal: styles.modal }}
        open={this.props.open}
        onClose={this.props.onCloseModal}
        center
      >
        <p className={styles.heading}>More info about your property</p>
        <hr />

        <Row className={styles.modalBody}>
          <p className={styles.title}>
            We have matched your property with our records...
          </p>

          <p className={styles.address}>
            <FormattedAddress address={this.props.address} inline={false} />
          </p>

          <p>{this.props.propertyType}</p>

          <p className={styles.sales}>Sales History</p>
          <hr />
          <ul className={styles.salesList}>
            {this.props.salesHistory &&
              this.props.salesHistory.map((pv, index) => {
                return (
                  <li key={index}>
                    <Row>
                      <Col xs="5">
                        <DateTimeFormat
                          rawDateTime={pv.dateOfTransfer}
                          showOnlyDateFormat
                        />
                      </Col>
                      <Col className={styles.valuationStatus}>SOLD</Col>
                      <Col className={styles.price}>
                        <Currency prefix="£" value={pv.price} />
                      </Col>
                    </Row>
                  </li>
                );
              })}
          </ul>

          <hr />
          <p className={styles.information}>
            <Row>
              <Col xs="8">Information provided by the Land Registry</Col>

              <Col>
                <img
                  src={
                    "https://rentchiefstorageuk.blob.core.windows.net/images/general-image/land_registry.png"
                  }
                  alt="Land Registry Logo"
                />
              </Col>
            </Row>
          </p>
        </Row>
      </Modal>
    );
  }
}

export default MoreInformationModal;
