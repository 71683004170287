import React, { Component } from 'react'

// Import Styles
import styles from './AddEditUnitForm.module.scss'
import { withRouter } from 'react-router-dom'
import { IAddEditUnitOptions } from '..'
import Buttons from '../../../atoms/Buttons'
import { ErrorMessage, Form, Formik } from 'formik'
import FormGroup from 'reactstrap/lib/FormGroup'
import TextareaAutosize from 'react-textarea-autosize'
import SearchPropertyInput from '../../../atoms/SearchPropertyInput'
import SelectDropdown, { IOptions } from '../../../atoms/SelectDropdown'
import {
    getUnitTypesDropdown,
    getUnitRentalStatusDropdown,
    addUnit,
    updateUnit,
} from '../../../../services/UnitService'
import { AddEditUnit } from '../../../../YupValidationSchemas'
import { getTypesForDropdown } from '../../../../services/EntityService'
import { FurnishedTypeGroupId } from '../../../../configuration/appConfig'
import { IUnitItem } from '../../../../types/UnitService'
import CurrencyInput from '../../../atoms/CurrencyInput'
import { IDataResponse } from '../../../../types/ApiService'
import { processToast } from '../../../../helpers/toastHelper'
import RotatingLoader from '../../../atoms/RotatingLoader'
import MaterialCheckbox from '../../../atoms/MaterialCheckbox'
import { removeURLParameter } from '../../../../helpers/locationHelpers'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { goToRoute } from '../../../../history'
import Breadcrumbs from '../../../atoms/Breadcrumbs'
import StickyComponent from '../../../atoms/StickyComponent'
const queryString = require('query-string')

interface IWithRouterProps {
    history: any
    location: any
    match: any

    // Add Mode
    onSuccessCallback?(unit: IUnitItem): void

    // Edit Mode
    unit: IUnitItem
    unitId?: string
    onUpdateCallback?(unit: IUnitItem): void

    options?: IAddEditUnitOptions
}

interface IState {
    submitting: boolean
    unit: IUnitItem

    showUnitInformation: boolean
    showUnitSpecs: boolean
    showUnitFeatures: boolean

    unitTypes: Array<IOptions>
    rentalStatus: Array<IOptions>
    furnishedTypes: Array<IOptions>

    updateRedirectUrl: string
}

let nameFieldRef

class AddEditUnitForm extends Component<IWithRouterProps, IState> {
    constructor(props: any) {
        super(props)

        nameFieldRef = React.createRef()

        this.state = {
            submitting: false,
            unit: {
                unitId: null,
                unitName: null,
                availableToRent: null,
                type: null,
                status: null,
                propertyId: null,
                targetRent: null,
                size: null,
                floor: null,
                furnished: null,
                description: null,
                laundry: 'In unit',
                keyInfo: null,
                doorInfo: null,
                paintInfo: null,
                floorInfo: null,
                images: [],
            },
            showUnitInformation: true,
            showUnitSpecs: true,
            showUnitFeatures: true,
            unitTypes: [],
            rentalStatus: [],
            furnishedTypes: [],
            updateRedirectUrl: '',
        }

        this.onChangeUnitField = this.onChangeUnitField.bind(this)
        this.onAdd = this.onAdd.bind(this)
        this.onUpdate = this.onUpdate.bind(this)
        this.changeUnitRentalStatus = this.changeUnitRentalStatus.bind(this)
    }

    changeUnitRentalStatus(statusName: string): void {
        let status = this.state.rentalStatus.filter(
            (x) => x.label.toLowerCase() == statusName.toLowerCase()
        )
        if (status) {
            this.setState((prevState) => ({
                unit: {
                    ...prevState.unit,
                    status: status[0].value,
                },
            }))
        }
    }

    onChangeUnitField(value: string, fieldName: string, label?: string): void {
        this.setState((prevState) => ({
            unit: {
                ...prevState.unit,
                [fieldName]: value,
            },
        }))

        if (fieldName == 'type') {
            this.setState((prevState) => ({
                unit: {
                    ...prevState.unit,
                    unitName: label,
                },
            }))
        }
    }

    componentDidMount(): void {
        let propertyId = queryString.parse(
            this.props.location.search
        ).propertyId
        if (propertyId) {
            this.setState((prev) => ({
                unit: {
                    ...prev.unit,
                    propertyId,
                },
            }))

            //let removeQuery1 = removeURLParameter(this.props.location.search, "propertyId");
            //this.props.history.push({
            //  search: removeQuery1
            //})
        }

        let propertyName = queryString.parse(
            this.props.location.search
        ).propertyName
        if (propertyName) {
            this.setState((prev) => ({
                unit: {
                    ...prev.unit,
                    propertyName,
                },
            }))

            //let removeQuery2 = removeURLParameter(this.props.location.search, "propertyName");
            //this.props.history.push({
            //  search: removeQuery2
            //})
        }

        let updateRedirectUrl = queryString.parse(
            this.props.location.search
        ).updateRedirectUrl
        if (updateRedirectUrl) {
            this.setState({
                updateRedirectUrl,
            })

            //let removeQuery = removeURLParameter(this.props.location.search, "updateRedirectRoute");
            //this.props.history.push({
            //  search: removeQuery
            //})
        }

        if (this.props.unit) {
            // Set component state of the Unit
            this.setState({
                unit: this.props.unit,
            })
        } else {
            this.setState((prevState) => ({
                unit: {
                    ...prevState.unit,
                    unitName: 'Bedroom',
                },
            }))
        }

        if (
            this.props.options &&
            this.props.options.propertyId &&
            this.props.options.propertyName
        ) {
            this.setState((prevState) => ({
                unit: {
                    ...prevState.unit,
                    propertyId: this.props.options.propertyId,
                    propertyName: this.props.options.propertyName,
                },
            }))
        }

        getUnitTypesDropdown('', true, true).then((resp) => {
            if (resp && resp.status == 200) {
                this.setState({
                    unitTypes: resp.data,
                })
            }
        })

        getUnitRentalStatusDropdown().then((resp) => {
            if (resp && resp.status == 200) {
                this.setState({
                    rentalStatus: resp.data,
                })
            }
        })

        getTypesForDropdown(FurnishedTypeGroupId).then((resp) => {
            if (resp && resp.status == 200) {
                this.setState({
                    furnishedTypes: resp.data,
                })
            }
        })

        if (nameFieldRef && nameFieldRef.current) {
            nameFieldRef.current.focus()
        }
    }

    onAdd(): void {
        this.setState({
            submitting: true,
        })

        addUnit(this.state.unit).then((resp) => {
            this.setState({
                submitting: false,
            })

            let response: IDataResponse<string> = resp.data
            processToast(response)

            if (response.isSuccess) {
                if (resp && resp.status == 200) {
                    //this.props.history.push(`/dashboard/manage/unit/view/${response.data}`)

                    //this.props.onSuccessCallback && this.props.onSuccessCallback(null);

                    if (this.state.updateRedirectUrl) {
                        goToRoute(this.state.updateRedirectUrl)
                    } else {
                        goToRoute(
                            `/dashboard/property/${this.state.unit.propertyId}/spec/units`
                        )
                    }
                }
            }
        })
    }

    onUpdate(): void {
        this.setState({
            submitting: true,
        })

        updateUnit(this.props.match.params.unitId, this.state.unit).then(
            (resp) => {
                this.setState({
                    submitting: false,
                })

                let response: IDataResponse<string> = resp.data
                processToast(response)

                if (response.isSuccess) {
                    this.props.onUpdateCallback &&
                        this.props.onUpdateCallback(this.state.unit)

                    if (this.props.onUpdateCallback) {
                        this.props.onUpdateCallback(this.state.unit)
                    }

                    if (this.state.updateRedirectUrl) {
                        this.props.history.push(this.state.updateRedirectUrl)
                    }
                }
            }
        )
    }

    render() {
        if (this.state.submitting) {
            return (
                <div className={styles.page}>
                    <RotatingLoader
                        loading={true}
                        text={`${
                            this.props.unit
                                ? 'Updating Unit...'
                                : 'Adding Unit...'
                        }`}
                    />
                </div>
            )
        }

        return (
            <div className={styles.page}>
                {this.state.unit && this.state.unit.unitId && (
                    <div className={styles.breadcrumbs}>
                        <Breadcrumbs
                            breadcrumbs={[
                                ...(this.state.unit &&
                                this.state.unit.propertyId &&
                                this.state.unit.propertyName
                                    ? [
                                          {
                                              name: this.state.unit
                                                  .propertyName,
                                              route: `/dashboard/property/${this.state.unit.propertyId}/spec/units`,
                                              ordinal: 0,
                                          },
                                      ]
                                    : []),
                                {
                                    name: `${
                                        this.props.unit
                                            ? 'Edit Unit'
                                            : 'Add Unit'
                                    }`,
                                    ordinal: 1,
                                },
                            ]}
                        />
                    </div>
                )}

                <Formik
                    initialValues={{
                        unitName: this.state.unit.unitName,
                        description: this.state.unit.description,
                        type: this.state.unit.type,
                        status: this.state.unit.status,
                        propertyId: this.state.unit.propertyId,
                        targetRent: this.state.unit.targetRent,
                        size: this.state.unit.size,
                        furnished: this.state.unit.furnished,
                        laundry: this.state.unit.laundry,
                    }}
                    enableReinitialize={true}
                    validationSchema={AddEditUnit}
                    onSubmit={(values: any, { setSubmitting }) => {
                        this.props.unit ? this.onUpdate() : this.onAdd()
                    }}
                >
                    {(props) => (
                        <Form>
                            <div className={styles.row}>
                                <div className={styles.col}>
                                    <FormGroup>
                                        <div className={styles.label}>Type</div>
                                        {this.state.unitTypes.length > 0 && (
                                            <SelectDropdown
                                                selectedId={
                                                    this.state.unit.type
                                                }
                                                showDefaultOption
                                                data={this.state.unitTypes}
                                                placeholder="Unit Type..."
                                                onSelect={(id: IOptions) => {
                                                    this.onChangeUnitField(
                                                        id.value,
                                                        'type',
                                                        id.label
                                                    )
                                                }}
                                            />
                                        )}

                                        <div className={styles.errorMessage}>
                                            <ErrorMessage
                                                component="div"
                                                className={styles.errorMessage}
                                                name="type"
                                            />
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className={styles.col}>
                                    <FormGroup>
                                        <div className={styles.label}>Name</div>
                                        <input
                                            maxLength={100}
                                            value={this.state.unit.unitName}
                                            onChange={(e: any) => {
                                                this.onChangeUnitField(
                                                    e.currentTarget.value,
                                                    'unitName'
                                                )
                                            }}
                                            ref={nameFieldRef}
                                            className={styles.input}
                                            placeholder="Unit name..."
                                            name="unitName"
                                        />

                                        <div className={styles.errorMessage}>
                                            <ErrorMessage
                                                component="div"
                                                className={styles.errorMessage}
                                                name="unitName"
                                            />
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className={styles.col}>
                                    <FormGroup>
                                        <div className={styles.label}>
                                            Available to rent?
                                        </div>
                                        <div className={styles.mobileRow}>
                                            <div
                                                className={`${styles.thirdCol} ${styles.checkbox}`}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                this.state.unit
                                                                    .availableToRent ==
                                                                true
                                                            }
                                                            color="primary"
                                                            onChange={() => {
                                                                this.setState(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        unit: {
                                                                            ...prevState.unit,
                                                                            availableToRent:
                                                                                true,
                                                                        },
                                                                    })
                                                                )
                                                                this.changeUnitRentalStatus(
                                                                    'Available to let'
                                                                )
                                                            }}
                                                            value="Yes"
                                                        />
                                                    }
                                                    label="Yes"
                                                />
                                            </div>

                                            <div
                                                className={`${styles.thirdCol} ${styles.checkbox}`}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                this.state.unit
                                                                    .availableToRent ==
                                                                false
                                                            }
                                                            color="primary"
                                                            onChange={() => {
                                                                this.setState(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        unit: {
                                                                            ...prevState.unit,
                                                                            availableToRent:
                                                                                false,
                                                                        },
                                                                    })
                                                                )
                                                                this.changeUnitRentalStatus(
                                                                    'N/A'
                                                                )
                                                            }}
                                                            value="No"
                                                        />
                                                    }
                                                    label="No"
                                                />
                                            </div>
                                        </div>
                                    </FormGroup>

                                    <div className={styles.errorMessage}>
                                        <ErrorMessage
                                            component="div"
                                            className={styles.errorMessage}
                                            name="availableToRent"
                                        />
                                    </div>
                                </div>

                                {/*<div className={styles.col}>
              <FormGroup>
                <div className={styles.label}>Unit Rental Status</div>
                {
                  this.state.rentalStatus.length > 0 &&
                  <SelectDropdown
                    selectedId={this.state.unit.status}
                    showDefaultOption
                    data={this.state.rentalStatus}
                    placeholder="Rental Status..."
                    onSelect={(id: IOptions) => {
                      this.onChangeUnitField(id.value, "status");
                    }}
                  />
                }

                <div className={styles.errorMessage}>
                  <ErrorMessage
                    component="div"
                    className={styles.errorMessage}
                    name="status"
                  />
                </div>
              </FormGroup>
              </div>*/}

                                <div className={styles.propertyColumn}>
                                    <FormGroup>
                                        <div className={styles.label}>
                                            Property
                                        </div>
                                        <SearchPropertyInput
                                            onClear={() => {
                                                this.setState((prevState) => ({
                                                    unit: {
                                                        ...prevState.unit,
                                                        propertyId: null,
                                                        propertyName: null,
                                                    },
                                                }))
                                            }}
                                            selectedPropertyId={
                                                this.state.unit.propertyId
                                            }
                                            selectedPropertyName={
                                                this.state.unit.propertyName
                                            }
                                            onSelect={(option: IOptions) => {
                                                this.setState((prevState) => ({
                                                    unit: {
                                                        ...prevState.unit,
                                                        propertyId:
                                                            option.value,
                                                        propertyName:
                                                            option.label,
                                                    },
                                                }))
                                            }}
                                        />

                                        <div className={styles.errorMessage}>
                                            <ErrorMessage
                                                component="div"
                                                className={styles.errorMessage}
                                                name="propertyId"
                                            />
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>

                            {/* Unit Information Section */}
                            <section>
                                <div className={styles.sectionTitle}>
                                    Unit Information
                                </div>

                                <div className={styles.row}>
                                    <div className={styles.col}>
                                        <FormGroup>
                                            <div className={styles.label}>
                                                Target Rent
                                            </div>
                                            <CurrencyInput
                                                maxLength={12}
                                                handleChange={(
                                                    event: any,
                                                    maskedValue: any,
                                                    floatValue: any
                                                ) => {
                                                    this.setState(
                                                        (prevState) => ({
                                                            unit: {
                                                                ...prevState.unit,
                                                                targetRent:
                                                                    floatValue,
                                                            },
                                                        })
                                                    )
                                                }}
                                                precision={2}
                                                className={styles.input}
                                                name="amount"
                                                inputType="tel"
                                                value={
                                                    this.state.unit.targetRent
                                                        ? this.state.unit.targetRent.toString()
                                                        : null
                                                }
                                                prefix="£"
                                            />

                                            <div
                                                className={styles.errorMessage}
                                            >
                                                <ErrorMessage
                                                    component="div"
                                                    className={
                                                        styles.errorMessage
                                                    }
                                                    name="targetRent"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>

                                    <div className={styles.col}>
                                        <FormGroup>
                                            <div className={styles.label}>
                                                Size Sq Ft
                                            </div>
                                            <input
                                                maxLength={9}
                                                value={this.state.unit.size}
                                                onChange={(e: any) => {
                                                    this.onChangeUnitField(
                                                        e.currentTarget.value,
                                                        'size'
                                                    )
                                                }}
                                                className={styles.input}
                                                placeholder="Size Sq Ft..."
                                                name="size"
                                            />

                                            <div
                                                className={styles.errorMessage}
                                            >
                                                <ErrorMessage
                                                    component="div"
                                                    className={
                                                        styles.errorMessage
                                                    }
                                                    name="size"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>

                                    <div className={styles.col}>
                                        <FormGroup>
                                            <div className={styles.label}>
                                                Floor
                                            </div>
                                            <input
                                                maxLength={50}
                                                value={this.state.unit.floor}
                                                onChange={(e: any) => {
                                                    this.onChangeUnitField(
                                                        e.currentTarget.value,
                                                        'floor'
                                                    )
                                                }}
                                                className={styles.input}
                                                placeholder="Floor..."
                                                name="floor"
                                            />

                                            <div
                                                className={styles.errorMessage}
                                            >
                                                <ErrorMessage
                                                    component="div"
                                                    className={
                                                        styles.errorMessage
                                                    }
                                                    name="floor"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>

                                    <div className={styles.col}>
                                        <FormGroup>
                                            <div className={styles.label}>
                                                Furnished
                                            </div>
                                            <SelectDropdown
                                                selectedId={
                                                    this.state.unit.furnished
                                                }
                                                showDefaultOption
                                                data={this.state.furnishedTypes}
                                                placeholder="Furnished..."
                                                onSelect={(id: IOptions) => {
                                                    this.onChangeUnitField(
                                                        id.value,
                                                        'furnished'
                                                    )
                                                }}
                                            />

                                            <div
                                                className={styles.errorMessage}
                                            >
                                                <ErrorMessage
                                                    component="div"
                                                    className={
                                                        styles.errorMessage
                                                    }
                                                    name="furnished"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>

                                    <div className={styles.checkboxColumns}>
                                        <FormGroup>
                                            <div className={styles.label}>
                                                Laundry
                                            </div>
                                            <div className={styles.mobileRow}>
                                                <div
                                                    className={`${styles.thirdCol} ${styles.checkbox}`}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    this.state
                                                                        .unit
                                                                        .laundry ==
                                                                    'None'
                                                                }
                                                                color="primary"
                                                                onChange={() =>
                                                                    this.setState(
                                                                        (
                                                                            prevState
                                                                        ) => ({
                                                                            unit: {
                                                                                ...prevState.unit,
                                                                                laundry:
                                                                                    'None',
                                                                            },
                                                                        })
                                                                    )
                                                                }
                                                                value="None"
                                                            />
                                                        }
                                                        label="None"
                                                    />
                                                </div>

                                                <div
                                                    className={`${styles.thirdCol} ${styles.checkbox}`}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    this.state
                                                                        .unit
                                                                        .laundry ==
                                                                    'In unit'
                                                                }
                                                                color="primary"
                                                                onChange={() =>
                                                                    this.setState(
                                                                        (
                                                                            prevState
                                                                        ) => ({
                                                                            unit: {
                                                                                ...prevState.unit,
                                                                                laundry:
                                                                                    'In unit',
                                                                            },
                                                                        })
                                                                    )
                                                                }
                                                                value="In unit"
                                                            />
                                                        }
                                                        label="In unit"
                                                    />
                                                </div>

                                                <div
                                                    className={`${styles.thirdCol} ${styles.checkbox}`}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    this.state
                                                                        .unit
                                                                        .laundry ==
                                                                    'Shared'
                                                                }
                                                                color="primary"
                                                                onChange={() =>
                                                                    this.setState(
                                                                        (
                                                                            prevState
                                                                        ) => ({
                                                                            unit: {
                                                                                ...prevState.unit,
                                                                                laundry:
                                                                                    'Shared',
                                                                            },
                                                                        })
                                                                    )
                                                                }
                                                                value="Shared"
                                                            />
                                                        }
                                                        label="Shared"
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                className={styles.errorMessage}
                                            >
                                                <ErrorMessage
                                                    component="div"
                                                    className={
                                                        styles.errorMessage
                                                    }
                                                    name="laundry"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>
                                </div>
                            </section>

                            {/* Unit Specs Section */}
                            <section>
                                <div className={styles.sectionTitle}>
                                    Unit Specs
                                </div>

                                <div className={styles.row}>
                                    <div className={styles.descriptionCol}>
                                        <FormGroup>
                                            <div className={styles.label}>
                                                Description
                                            </div>
                                            <TextareaAutosize
                                                maxLength={250}
                                                value={
                                                    this.state.unit.description
                                                }
                                                onChange={(e: any) => {
                                                    this.onChangeUnitField(
                                                        e.currentTarget.value,
                                                        'description'
                                                    )
                                                }}
                                                className={styles.input}
                                                placeholder="Description..."
                                                name="description"
                                            />

                                            <div
                                                className={styles.errorMessage}
                                            >
                                                <ErrorMessage
                                                    component="div"
                                                    className={
                                                        styles.errorMessage
                                                    }
                                                    name="description"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className={styles.row}>
                                    <div className={styles.col}>
                                        <FormGroup>
                                            <div className={styles.label}>
                                                Key Info
                                            </div>
                                            <TextareaAutosize
                                                maxLength={1024}
                                                value={this.state.unit.keyInfo}
                                                onChange={(e: any) => {
                                                    this.onChangeUnitField(
                                                        e.currentTarget.value,
                                                        'keyInfo'
                                                    )
                                                }}
                                                className={styles.input}
                                                placeholder="Enter Key Info..."
                                                name="keyInfo"
                                            />

                                            <div
                                                className={styles.errorMessage}
                                            >
                                                <ErrorMessage
                                                    component="div"
                                                    className={
                                                        styles.errorMessage
                                                    }
                                                    name="keyInfo"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>

                                    <div className={styles.col}>
                                        <FormGroup>
                                            <div className={styles.label}>
                                                Door Info
                                            </div>
                                            <TextareaAutosize
                                                maxLength={1024}
                                                value={this.state.unit.doorInfo}
                                                onChange={(e: any) => {
                                                    this.onChangeUnitField(
                                                        e.currentTarget.value,
                                                        'doorInfo'
                                                    )
                                                }}
                                                className={styles.input}
                                                placeholder="Enter Door Info..."
                                                name="doorInfo"
                                            />

                                            <div
                                                className={styles.errorMessage}
                                            >
                                                <ErrorMessage
                                                    component="div"
                                                    className={
                                                        styles.errorMessage
                                                    }
                                                    name="doorInfo"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className={styles.row}>
                                    <div className={styles.col}>
                                        <FormGroup>
                                            <div className={styles.label}>
                                                Paint Info
                                            </div>
                                            <TextareaAutosize
                                                maxLength={1024}
                                                value={
                                                    this.state.unit.paintInfo
                                                }
                                                onChange={(e: any) => {
                                                    this.onChangeUnitField(
                                                        e.currentTarget.value,
                                                        'paintInfo'
                                                    )
                                                }}
                                                className={styles.input}
                                                placeholder="Enter Paint Info..."
                                                name="paintInfo"
                                            />

                                            <div
                                                className={styles.errorMessage}
                                            >
                                                <ErrorMessage
                                                    component="div"
                                                    className={
                                                        styles.errorMessage
                                                    }
                                                    name="paintInfo"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>

                                    <div className={styles.col}>
                                        <FormGroup>
                                            <div className={styles.label}>
                                                Floor Info
                                            </div>
                                            <TextareaAutosize
                                                maxLength={1024}
                                                value={
                                                    this.state.unit.floorInfo
                                                }
                                                onChange={(e: any) => {
                                                    this.onChangeUnitField(
                                                        e.currentTarget.value,
                                                        'floorInfo'
                                                    )
                                                }}
                                                className={styles.input}
                                                placeholder="Enter Floor Info..."
                                                name="floorInfo"
                                            />

                                            <div
                                                className={styles.errorMessage}
                                            >
                                                <ErrorMessage
                                                    component="div"
                                                    className={
                                                        styles.errorMessage
                                                    }
                                                    name="floorInfo"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>
                                </div>
                            </section>

                            <div className={styles.row}>
                                <div className={styles.col}>
                                    <StickyComponent>
                                        <Buttons
                                            buttons={[
                                                {
                                                    displayType: 'cancel',
                                                    elementType: 'button',
                                                    onClick: () =>
                                                        this.state
                                                            .updateRedirectUrl
                                                            ? this.props.history.push(
                                                                  this.state
                                                                      .updateRedirectUrl
                                                              )
                                                            : this.props
                                                                  .onUpdateCallback
                                                            ? this.props.onUpdateCallback(
                                                                  this.props
                                                                      .unit
                                                              )
                                                            : null,
                                                },
                                                {
                                                    displayType: 'submit',
                                                    elementType: 'button',
                                                    onClick: () =>
                                                        props.submitForm(),
                                                },
                                            ]}
                                        />
                                    </StickyComponent>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}

export default withRouter(AddEditUnitForm)
