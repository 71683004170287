import React, { useEffect, useState } from "react";
import { RootState } from "../../../store";

import styles from "./FilterSidePanelBody.module.scss";
import { useDispatch, useSelector } from "react-redux";
import CloseButton from "../../atoms/CloseButton";
import { toggleFilterSidebar } from "../../../actions/appActions";

import GenericButton from "../GenericButton";
import DateRangePicker from "../DateRangePicker";
import SelectDropdown, { IOptions } from "../SelectDropdown";
import { updateFilters } from "../../../helpers/filterHelper";
import { IFilter } from "../../../types/FilterService/IFilterService";

interface IDateRange {
  startDate: Date,
  endDate: Date
}

const FilterSidePanelBody = () => {
  // Redux 
  const { filters } = useSelector((state: RootState) => state.filters);

  var datePickerRef;

  const [dropdownSelected, setDropdownSelected] = useState("");
  const [dateRange, setDateRange] = useState<IDateRange>({startDate: null, endDate: null});

  const dispatch = useDispatch();

  useEffect(() => {
    datePickerRef = React.createRef();
    setDateRange({startDate: filters.financials.dateRangeStart, endDate: filters.financials.dateRangeEnd});
  }, [])

  const apply = ()=>  {
    let filters: IFilter = {
      financials: {
        transactionType: dropdownSelected,
        dateRangeStart: dateRange.startDate,
        dateRangeEnd: dateRange.endDate,
        setBySlideOut: true
      }
    
    }
    updateFilters(filters);
    dispatch(toggleFilterSidebar(false))
  }

  const reset = () => {
    setDropdownSelected("");
    setDateRange({startDate: null, endDate: null});
    apply();
  }

  const focusDateRange = () => {
    datePickerRef.current.focusDateRange;
  }

  const dateRangeChange = (startDate, endDate) => {
    setDateRange({startDate: startDate, endDate: endDate});
  }

  const dateRangeCleared = () => {
    setDateRange({ startDate: null, endDate: null });
  }

    return (
      <div className={styles.body}>
        <div className={styles.headingSection}>
          <div className={styles.close}>
            <CloseButton close={() => dispatch(toggleFilterSidebar(false))} />
          </div>

          <div className={styles.filterBody}>
            <div className={styles.bar}>
              <div className={styles.name}>Filter transactions</div>
              <div className={styles.buttons}>
                <GenericButton
                  className={styles.button}
                  border
                  onClick={() => apply()}
                >
                  Apply
                </GenericButton>
                <GenericButton
                  className={styles.button}
                  border
                  onClick={() => reset()}
                >
                  Reset
                </GenericButton>
              </div>
            </div>

            <div className={styles.filters}>
              <div>
                <div className={styles.typeDropdown}>
                  <SelectDropdown
                    selectedId={dropdownSelected}
                    showDefaultOption
                    showAllOption
                    data={[
                      {
                        label: "Income",
                        value: "income",
                      },
                      {
                        label: "Expense",
                        value: "expense",
                      },
                    ]}
                    placeholder="Type..."
                    onSelect={(id: IOptions) => {
                      setDropdownSelected(id.value);
                    }}
                  />
                </div>
              </div>
              <div className={styles.dateRange}>
                <DateRangePicker
                  change={dateRangeChange}
                  cleared={dateRangeCleared}
                  focus={focusDateRange}
                  startDate={dateRange.startDate}
                  endDate={dateRange.endDate}
                  ref={datePickerRef}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default FilterSidePanelBody;
