import React, { Component } from 'react'
import { Redirect, Route, withRouter } from 'react-router'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'
import auditIcon from '../../../images/icons/icon_timeline.svg'
import detailsIcon from '../../../images/icons/user-management/usermanagement_details.svg'
import permissionsIcon from '../../../images/icons/user-management/usermanagement_permissions.svg'
import { getProfile } from '../../../services/ProfileUpdateService'
import { IUserProfileResponse, ProfileType } from '../../../types/ProfileService/IProfile'
import SimpleRouterTabs from '../../atoms/SimpleRouterTabs'
import TabbedNavigationContainer from '../../atoms/TabbedNavigationContainer'
import UserCardNav from '../../atoms/UserCardNav'
import AuditPage from '../PlatformManagementPage/AuditPage'
import UserChatsPage from './UserChatsPage'
import styles from './UserPage.module.scss'
import UserPermissionsPage from './UserPermissionsPage'
import UserProfilePage from './UserProfilePage'
import UserRolesPage from './UserRolesPage'

interface IProps {
    history: any
    match: any
    location: any
}

interface IState {
    userProfile: IUserProfileResponse
    isLoading: boolean
    userId: string
}

class UserPage extends Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            userProfile: null,
            isLoading: false,
            userId: '',
        }
    }

    componentWillReceiveProps(newProps: IProps): void {
        if (newProps.match.params.userId == this.props.match.params.userId) {
            return
        }

        this.setState(
            {
                userId: newProps.match.params.userId,
                isLoading: true,
            },
            () => {
                getProfile(this.state.userId).then((resp) => {
                    if (resp.status == 200 && resp.data) {
                        this.setState({
                            userProfile: resp.data,
                        })
                    }

                    this.setState({
                        isLoading: false,
                    })
                })
            }
        )
    }

    componentDidMount(): void {
        let userId = this.props.match.params.userId
        if (!userId) {
            return
        }

        this.setState(
            {
                userId,
                isLoading: true,
            },
            () => {
                getProfile(this.state.userId).then((resp) => {
                    if (resp.status == 200 && resp.data) {
                        this.setState({
                            userProfile: resp.data,
                        })
                    }

                    this.setState({
                        isLoading: false,
                    })
                })
            }
        )
    }

    render() {
        if (!this.state.userProfile) {
            return null
        }

        return (
            <Row className={styles.container}>
                <UserCardNav
                    subText={this.state.userProfile.additionalInformation.role}
                    image={this.state.userProfile.profile.profileImageUri}
                    forename={this.state.userProfile.profile.forename}
                    surname={this.state.userProfile.profile.surname}
                />

                <div className={styles.navigation}>
                    <TabbedNavigationContainer fullWidth>
                        <Col className={styles.tabs}>
                            <SimpleRouterTabs
                                routes={[
                                    {
                                        path: this.props.match.url + '/profile',
                                        text: 'Profile',
                                        mobileText: 'Profile',
                                        icon: detailsIcon,
                                    },
                                    {
                                        path:
                                            this.props.match.url +
                                            '/permissions',
                                        text: 'Permissions',
                                        mobileText: 'Permissions',
                                        icon: permissionsIcon,
                                    },
                                    {
                                        path:
                                            this.props.match.url + '/timeline',
                                        text: 'Timeline',
                                        mobileText: 'Timeline',
                                        icon: auditIcon,
                                    },
                                    {
                                        path: this.props.match.url + '/chats',
                                        text: 'Chats',
                                        mobileText: 'Chats',
                                        icon: auditIcon,
                                    },
                                ]}
                            />
                        </Col>
                    </TabbedNavigationContainer>
                </div>

                <Col className={styles.routedPage}>
                    <div className={styles.content}>
                        <Route
                            path={`${this.props.match.url}`}
                            exact
                            render={() => (
                                <Redirect
                                    to={`${this.props.match.url}/profile`}
                                />
                            )}
                        />

                        <Route
                            path={`${this.props.match.path}/profile`}
                            exact
                            render={() => (
                                <div className={styles.paddedPage}>
                                    <UserProfilePage
                                        userId={this.state.userId}
                                        profileType={ProfileType.User}
                                        userProfile={this.state.userProfile}
                                    />
                                </div>
                            )}
                        />

                        <Route
                            path={`${this.props.match.path}/roles`}
                            exact
                            render={() => (
                                <div className={styles.paddedPage}>
                                    <UserRolesPage />
                                </div>
                            )}
                        />

                        <Route
                            path={`${this.props.match.path}/permissions`}
                            exact
                            render={() => (
                                <div className={styles.paddedPage}>
                                    <UserPermissionsPage />
                                </div>
                            )}
                        />

                        <Route
                            path={`${this.props.match.path}/timeline`}
                            exact
                            render={() => (
                                <div className={styles.paddedPage}>
                                    <AuditPage
                                        userId={this.state.userId}
                                        hideDropdown
                                    />
                                </div>
                            )}
                        />

                        <Route
                            path={`${this.props.match.path}/chats/:chatSessionId?`}
                            render={() => <UserChatsPage />}
                        />
                    </div>
                </Col>
            </Row>
        )
    }
}

export default withRouter(UserPage)
