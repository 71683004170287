import { DataManager } from '@syncfusion/ej2-data';
import { IListItemOptions } from '../../../../../types/ListFilters';
import { unitServiceOdataFriendly } from '../../../../../configuration/domains';
import { createBearerToken } from '../../../../../helpers/authHelpers';
import { toQueryString } from '../../../../../services/DocumentService';
import { ODataV4Adaptor } from '@syncfusion/ej2-data';

export interface UnitListOptions extends IListItemOptions {
    unitStatus?: string;
    availableToRentOnly?: boolean;
}

export function generateOdataSource(listItemOptions?: UnitListOptions, startDate?: Date, endDate?: Date, activeStatus?: boolean): DataManager {
    let stringed = toQueryString(listItemOptions);
    let filter = ``;

    //if (startDate && endDate) {
    //    let startDate1 = getDateWithoutOffset(startDate).toISOString();
    //    let endDate1 = getDateWithoutOffset(endDate).toISOString();
    //    filter += `&$filter=(createdDate gt ${startDate1} and createdDate lt ${endDate1} or createdDate eq ${startDate1} or createdDate eq ${endDate1})`
    //}

    let dataManager: DataManager = new DataManager({
        adaptor: new ODataV4Adaptor,
        url: `${unitServiceOdataFriendly}?${stringed}`,
        headers: [
            { "Authorization": createBearerToken() }
        ]
    });

    return dataManager;
}