import { apiService } from '../services/ApiService';
import * as domains from '../configuration/domains';
import { createBearerToken } from "../helpers/authHelpers";
import { IAddOwnershipRequest } from '../types/PropertyOwnerService/IPropertyOwnerService';

export const getOwnersList = (PropertyId: string): Promise<any> => {
    return apiService
      .get(`${domains.propertyownershipService}/list/${PropertyId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: createBearerToken()
        }
      })
      .catch(error => {
        return error.response.data;
      });
  };

  export const addOwnership = (
    addOwnershipRequest: IAddOwnershipRequest
  ): Promise<any> => {
    return apiService.post(`${domains.propertyownershipService}/`, addOwnershipRequest, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    });
  };