import { ColumnMenuOpenEventArgs, ColumnMenuItemModel, TextWrapSettingsModel, EditSettings, EditSettingsModel } from "@syncfusion/ej2-react-grids";

export function columnMenuOpen(args: ColumnMenuOpenEventArgs) {
    for (const item of args.items) {
        if (item.text === 'Sort Ascending' || item.text === 'Sort Descending' || item.text === 'Autofit all columns' || item.text === 'Autofit this column') {
            (item as ColumnMenuItemModel).hide = true;
        } else {
            (item as ColumnMenuItemModel).hide = false;
        }
    }
}

export const textWrapSettings: TextWrapSettingsModel = {
    wrapMode: "Content"
}

export const editSettings: EditSettingsModel = {
    allowEditing: false,
    allowEditOnDblClick: false,
    allowAdding: false
}