import * as Yup from "yup";

const AssetAddEditSchema = Yup.object().shape({
    assetTypeLevel1Id: Yup.string()
        .nullable()
        .required("You must select a category"),
        assetTypeLevel2Id: Yup.string()
        .nullable()
        .required("You must select an asset type"),
});

export default AssetAddEditSchema;