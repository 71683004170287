import { count } from "console";
import { IAssetDto, AssetsAction, AssetActionType, IAssetsListResponse } from "../../types/Assets";
import { IDeleteState } from "../../types/Delete";

interface IAssetState {
    loading: boolean;
    error: string | null;
    data: IAssetsListResponse;
    viewing_asset: IAssetDto;
    delete: IDeleteState;
}

const initialState: IAssetState = {
    loading: false,
    error: null,
    data: { data: [], count: 0 },
    viewing_asset: null,
    delete: {
        loading: false,
        ids: null,
        modelOpen: false,
        returnUrl: null
    }
}

const reducer = (
    state: IAssetState = initialState,
    action: AssetsAction
): IAssetState => {
    switch (action.type) {
        case AssetActionType.TOGGLE_DELETE_ASSETS:
            return {
                ...state,
                delete: {
                    ...state.delete,
                    modelOpen: action.payload.open,
                    ids: action.payload.assetIds,
                    returnUrl: action.payload.returnUrl
                },
                error: null
            }
        case AssetActionType.DELETE_ASSET_IMAGE_SUCCESS:
            return {
                ...state,
                viewing_asset: {
                    ...state.viewing_asset,
                    images: state.viewing_asset.images.filter(image => image.imageId != action.payload)
                }
            }
        case AssetActionType.GET_ASSET_IMAGES_SUCCESS:
            return {
                ...state,
                viewing_asset: {
                    ...state.viewing_asset,
                    images: action.payload
                }
            }
        case AssetActionType.GET_ASSET_IMAGES_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case AssetActionType.CLEAR_ASSETS:
            return {
                ...state,
                data: {
                    data: [],
                    count: 0
                }
            }
        case AssetActionType.CLEAR_ASSET:
            return {
                ...state,
                viewing_asset: null
            }
        case AssetActionType.DELETE_ASSETS:
            return {
                ...state,
                delete: {
                    ...state.delete,
                    loading: true
                },
                error: null
            }
        case AssetActionType.DELETE_ASSETS_ERROR:
            return {
                ...state,
                error: action.payload,
                delete: {
                    ...state.delete,
                    loading: false
                }
            }
        case AssetActionType.GET_ASSETS:
        case AssetActionType.GET_ASSET:
        case AssetActionType.ADD_ASSET:
        case AssetActionType.UPDATE_ASSET:
            return {
                ...state,
                loading: true,
                error: null
            }
        case AssetActionType.GET_ASSETS_ERROR:
        case AssetActionType.GET_ASSET_ERROR:
        case AssetActionType.ADD_ASSET_ERROR:
        case AssetActionType.UPDATE_ASSET_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case AssetActionType.GET_ASSETS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case AssetActionType.GET_ASSET_SUCCESS:
            return {
                ...state,
                loading: false,
                viewing_asset: action.payload
            }
        case AssetActionType.GET_ASSET_LOCAL_SUCCESS:
            return {
                ...state,
                loading: false,
                viewing_asset: state.data.data.find(a => a.assetId == action.payload)
            }
        case AssetActionType.ADD_ASSET_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case AssetActionType.UPDATE_ASSET_SUCCESS:
            return {
                ...state,
                loading: false,
                data: {
                    data: state.data.data.map(
                        item => item.assetId === action.payload.assetId ? { ...item, ...action.payload } : item),
                    count: state.data.count
                }
            }
        case AssetActionType.DELETE_ASSETS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: {
                    data: state.data.data.filter(
                        function (e) {
                            return this.indexOf(e.assetId) < 0;
                        },
                        action.payload
                    ),
                    count: state.data.count - 1
                },
                delete: {
                    loading: false,
                    modelOpen: false,
                    ids: null,
                    returnUrl: null
                }
            }
        default:
            return state;
    }
};

export default reducer;
