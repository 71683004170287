import styled from 'styled-components';

export const LabelDiv = styled.div`
    width: 100%
    display: grid;
    grid-gap: 1px;
    grid-template-columns: 15% 85%;
    grid-template-areas:
    'icon title';
    &:hover {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: #104351;
      }
`; 

export const LabelText = styled.label`
    grid-area: title;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    color: #004150;
`; 

export const IconImage = styled.img`
    grid-area: icon;
    height: 20px;
    width: 20px;
    padding-right: 2px;
`; 
