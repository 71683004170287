import { store } from "../store";
declare var FS: any;

export function identifyUser() {
  let data = store.getState();
  try{
    FS.identify(data.user.user_id, {
      displayName: data.user.forename + " " + data.user.surname,
      email: data.user.email,
      reviewsWritten_int: 14
    });
  }catch{
    // do nothing, sometimes the FS seems to be null which causes an exception to bubble up during the login 
    // action call which causes the other exception to null a perfectly valid token which results in other 
    // UI issues
  }
}