import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { formatCurrency, Loader } from '@rentchief/components';
import { useFetchTransactionsSummaryData } from '../../../../../../servicesHooks/useFetchTransactionsSummaryData.hook.';
import { IListItemOptions, ITransactionListOptions } from '../../../../../../types/ListFilters';
import MoneyCard from './MoneyCard/MoneyCard.component';
import * as S from './MoneySummary.styles'

interface props{
    propertyId?: string;
    contactId?: string;
    unitId?: string;
    tenancyId?: string;
    forceUpdateCounter?: number;
    width?: string;
    transactionType?: string;
    cardOnClick?(name: string): void;
}

const MoneySummary = ({propertyId, contactId, unitId, tenancyId, forceUpdateCounter, width, transactionType, cardOnClick}: props) => {
    const history = useHistory();

    const [listOptions, setListOptions] = useState<IListItemOptions>({
                                                    size: 0,
                                                    page: 0,
                                                    search: "",
                                                    //propertyId: propertyId,
                                                    //contactId: contactId,
                                                    //unitId: unitId,
                                                    tenancyId: tenancyId
                                                });

    const [transactionOptions, setTransactionsOptions] = useState<ITransactionListOptions>({
                                                                    transactionType: transactionType,
                                                                    transactionStatus: "",
                                                                });

    const { dataRetrieved, data } = useFetchTransactionsSummaryData(listOptions, transactionOptions);

    useEffect(() => {
        setListOptions({
            size: 0,
            page: 0,
            search: "",
            propertyId: propertyId ? propertyId : "",
            contactId: contactId ? contactId : "",
            unitId: unitId ? unitId : "",
            tenancyId: tenancyId ? tenancyId : ""
        })
    }, [propertyId, contactId, unitId, tenancyId, forceUpdateCounter])

    const localCardOnClick = (name: string) => {
        if(cardOnClick){
            cardOnClick(name);
            return;
        }

        let baseUrl: string = "";
        if(!propertyId && !unitId && !tenancyId && !contactId){
            baseUrl = `/dashboard/financials/transactions`;
        }else if(propertyId && !unitId){
            baseUrl = `/dashboard/property/${propertyId}/financials`;
        }else if(unitId){
            baseUrl = `/dashboard/unit/view/${unitId}/financials`;
        }else if(tenancyId){
            baseUrl = `/dashboard/tenancy/view/${tenancyId}/money`;
        }else if(contactId){
            baseUrl = `/dashboard/contact/${contactId}`;
        }

        switch(name){
            case 'Overdue': history.push(`${baseUrl}/income?filter=income_Overdue`); break;
            case 'This Month': history.push(`${baseUrl}/income?filter=income_ThisMonth`); break;
            case 'Next Month': history.push(`${baseUrl}/income?filter=income_NextMonth`); break;
        }
    }

    if(!dataRetrieved){
        return <Loader />
    }
    
    return(
        <S.SectionDiv width={width}>
            <S.Value1Div><MoneyCard value={formatCurrency(data.overdueAmount, false)} name={"Overdue"} backgroundColor={"red"} onClick={localCardOnClick} /></S.Value1Div>
            <S.Value2Div><MoneyCard value={formatCurrency(data.currentMonthDueAmount, false)} value2={formatCurrency(data.currentMonthExpectedAmount, false)} name={"This Month"} backgroundColor={"#0085ca"} onClick={localCardOnClick} /></S.Value2Div>
            <S.Value3Div><MoneyCard value={formatCurrency(data.nextMonthDueAmount, false)} value2={formatCurrency(data.nextMonthExpectedAmount, false)} name={"Next Month"} backgroundColor={"#0085ca"} onClick={localCardOnClick} /></S.Value3Div>
        </S.SectionDiv>
    )
}

export default MoneySummary;