import { apiService } from '../services/ApiService';
import * as domains from '../configuration/domains';
import { createBearerToken } from "../helpers/authHelpers";
import { IUpdateContactRequest, IAddContactRequest, IContactListItem } from '../types/ContactService/ContactService';
import { IListItemOptions, IContactListItemOptions } from '../types/ListFilters';
import { toQueryString } from './DocumentService';
import { IDeleteRequest } from '../components/atoms/DeleteDocumentModal';

export const updateContact = (
  contactUpdateRequest: IUpdateContactRequest
): Promise<any> => {
  return apiService.put(`${domains.contactService}/`, contactUpdateRequest, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export const deleteContact = (
  contactId: string,
  request: IDeleteRequest
): Promise<any> => {
  return apiService.post(`${domains.contactService}/delete/${contactId}`, request, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export const addContact = (
  contactUpdateRequest: IAddContactRequest
): Promise<any> => {
  return apiService.post(`${domains.contactService}/`, contactUpdateRequest, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: createBearerToken()
    }
  });
};

export const getContactFromPropertyId = (propertyId: string): Promise<any> => {
  return apiService
    .get(`${domains.contactService}/property-contact/${propertyId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const getLandlordContactFromPropertyId = (propertyId: string): Promise<any> => {
  return apiService
    .get(`${domains.contactService}/property-landlord-contact/${propertyId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const getContactList = (options: IListItemOptions, contactOptions: IContactListItemOptions): Promise<any> => {
  let stringed = toQueryString(options);
  let stringedContact = toQueryString(contactOptions);

  return apiService
    .get(`${domains.contactService}/getContactList?${stringed}&${stringedContact}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const getContactsForDropDown = (contactType?: string): Promise<any> => {
  let param = contactType ? "?contactType=" + contactType: ''
  return apiService
    .get(`${domains.contactService}/contactsdropdown${param}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};

export const getContactsDropDown = (contactType: string, search?: string): Promise<any> => {
  let searchParam = "";
  if (search) {
    searchParam = `?search=${search}`;
  }

  return apiService
    .get(`${domains.contactService}/dropdown/${contactType}${searchParam}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    })
    .catch(error => {
      return error.response.data;
    });
};