import React from "react";
import Modal from "react-responsive-modal";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./AddEditIssueModal.module.scss";
import { toggleIssuePopup } from "../../../helpers/sidebarHelpers";
import AddEditIssue, { IRaiseIssueOptions } from "../AddEditIssue";
import OutstandingChangesModal from "../OutstandingChangesModal";
import { toggleAddIssueFormChanged } from "../../../helpers/formStateHelpers";
const queryString = require("query-string");

interface IProps {
  issue_popup: boolean;
  add_issue_form_changed: boolean;

  history: any;
  location: any;
  match: any;
}

interface IState {
  options: IRaiseIssueOptions;
  outstandingChangesModalOpen: boolean;
}

class AddEditIssueModal extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      options: null,
      outstandingChangesModalOpen: false
    }

    this.onSetOpenInternal = this.onSetOpenInternal.bind(this)
    this.onCloseChangesModal = this.onCloseChangesModal.bind(this)
  }

  componentDidMount(): void {
    let options = queryString.parse(this.props.location.search).issueOptions;
    if (options) {
      let option: IRaiseIssueOptions = JSON.parse(options);
      this.setState({
        options: option
      });
    }
  }

  onSetOpenInternal(open: boolean): void {
    if (!open) {
      // Check if there is outstanding changes
      if (this.props.add_issue_form_changed) {
        this.setState({
          outstandingChangesModalOpen: true
        });

        return;
      }
    }

    toggleIssuePopup(false)
  }

  onCloseChangesModal(closeSidebar: boolean): void {
    this.setState({
      outstandingChangesModalOpen: false
    });

    if (closeSidebar) {
      toggleAddIssueFormChanged(false);
      toggleIssuePopup(false);
    }
  }

  render() {
    return (
      <Modal 
        classNames={{ modal: styles.modal }} 
        open={this.props.issue_popup} onClose={() => this.onSetOpenInternal(false)} 
        center
      >
        <OutstandingChangesModal
          open={this.state.outstandingChangesModalOpen}
          onCloseChangesModal={this.onCloseChangesModal}
        />
        <div className={styles.heading}>
          {this.state.options && this.state.options.issueId ? 'Update' : 'Raise'} Issue
        </div>
        <AddEditIssue />
      </Modal>
    );
  }
}

const mapStateToProps = (state: any) => ({
  issue_popup: state.app.popups.issue_popup,
  add_issue_form_changed: state.app.form_states.add_issue_form_changed
});

export default withRouter(connect(
  mapStateToProps,
)(AddEditIssueModal) as any);