import { Dispatch } from 'redux';
import { getClient } from '../services/ClientService';
import { IClientData } from '../types/ClientService';
import { store } from "../store";

export const getClientData = (clientId: string) => {
    return (dispatch: Dispatch) => {

        return getClient(clientId)
            .then(response => {
                if (response.data != null) {
                    let client = response.data;
                    dispatch(dispatchClientDataSuccess(response.data))
                }
            }).catch(error => {

            })
    }
}

export function updateClientData(data: IClientData) {
    store.dispatch({
        type: "FETCH_CLIENT_DATA_SUCCESS",
        data
    });
}

export const dispatchClientDataSuccess = (data: IClientData) => {
    return {
        type: "FETCH_CLIENT_DATA_SUCCESS",
        data
    }
}

export const dispatchClearClientData = () => {
    return {
        type: "CLEAR_CLIENT_STATE_DATA"
    }
}