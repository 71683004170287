import React from 'react'
import SFDropdown from '..'
import { withRouter } from 'react-router'
import styles from './SFTenancyDropdown.module.scss'
import { addDocumentClicked, onAddIssueClickGeneric, addTenancyClick } from './_helpers'
import GenericButton from '../../GenericButton'
import actionIcon from '../../../../images/icons/property-action/Action.png'
import history, { goToRoute } from '../../../../history'
import { IAddEditTransactionOptions } from '../../AddEditTransactionModal'
import { addURLParameter } from '../../../../helpers/locationHelpers'
import { toggleTransactionPopup } from '../../../../helpers/sidebarHelpers'
import { IAddTransactionReferer } from '../../AddEditTransaction'
import IconButton from '../../Buttons/IconButton'

interface IProps {
    propertyId?: string
    propertyName?: string
    contactId?: string
    tenancyId?: string
    tenantName?: string
    unitId?: string
    unitName?: string

    history: any
    location: any
    match: any
}

class SFTenancyDropdown extends React.Component<IProps> {
    constructor(props: any) {
        super(props)
    }

    render() {
        return (
            <SFDropdown
                loadedBeforeRender
                customCssClassName={styles.syncfusionDropdown}
                customToggle={
                    <IconButton
                        button={{
                            displayType: 'action',
                            elementType: 'button',
                            icon: 'action',
                        }}
                    />
                }
                onSelect={(args) => {
                    if (args.item.text == 'Edit') {
                        goToRoute(`/dashboard/tenancy/view/${this.props.tenancyId}`)
                    }
                    if (args.item.text == 'Clone') {
                        addTenancyClick(this.props.tenancyId, this.props.location.search);
                    }
                    if (args.item.text == 'Add Document') {
                        addDocumentClicked(
                            {
                                tenancyId: this.props.tenancyId,
                                contactId: this.props.contactId,
                                propertyId: this.props.propertyId,
                            },
                            'uploadDocumentOptions'
                        )
                    }
                    if (args.item.text == 'Add Tenancy Contract') {
                        addDocumentClicked(
                            {
                                tenancyId: this.props.tenancyId,
                                contactId: this.props.contactId,
                                propertyId: this.props.propertyId,
                                documentTypeId:
                                    '3bdd80c3-91c9-4e37-8588-db6a0db0f4a9',
                                dialogueTitle: 'Tenancy Contract',
                            },
                            'uploadDocumentOptions'
                        )
                    }
                    if (args.item.text == 'Add Issue') {
                        onAddIssueClickGeneric(
                            {
                                tenancyId: this.props.tenancyId,
                                contactId: this.props.contactId,
                                contactName: this.props.tenantName,
                                propertyId: this.props.propertyId,
                                propertyName: this.props.propertyName,
                            },
                            'issueOptions'
                        )
                    }
                    if (args.item.text == 'Go to Documents') {
                        goToRoute(
                            `/dashboard/tenancy/view/${this.props.tenancyId}/documents`
                        )
                    }
                    if (args.item.text == 'Go to Timeline') {
                        goToRoute(
                            `/dashboard/tenancy/view/${this.props.tenancyId}/timeline`
                        )
                    }
                    if (args.item.text == 'Linked Property') {
                        goToRoute(
                            `/dashboard/property/${this.props.propertyId}`
                        )
                    }
                    if (args.item.text == 'Linked Contact') {
                        goToRoute(`/dashboard/contact/${this.props.contactId}`)
                    }
                    if (args.item.text == 'Add Expense') {
                        let options: IAddEditTransactionOptions = {
                            referer: IAddTransactionReferer.Tenancy,
                            contactId: this.props.contactId,
                            contactName: this.props.tenantName,
                            propertyId: this.props.propertyId,
                            unitId: this.props.unitId,
                            unitName: this.props.unitName,
                            tenancyId: this.props.tenancyId,
                            propertyName: this.props.propertyName,
                            isExpense: true,
                        }

                        let stringed =
                            'addEditTransactionOptions=' +
                            JSON.stringify(options)

                        let query = addURLParameter(
                            this.props.location.search,
                            stringed
                        )
                        history.push({
                            search: query,
                        })

                        toggleTransactionPopup(true)
                    }
                    if (args.item.text == 'Add Income') {
                        let options: IAddEditTransactionOptions = {
                            referer: IAddTransactionReferer.Tenancy,
                            contactId: this.props.contactId,
                            contactName: this.props.tenantName,
                            propertyId: this.props.propertyId,
                            unitId: this.props.unitId,
                            unitName: this.props.unitName,
                            tenancyId: this.props.tenancyId,
                            propertyName: this.props.propertyName,
                            isIncome: true,
                        }

                        let stringed =
                            'addEditTransactionOptions=' +
                            JSON.stringify(options)

                        let query = addURLParameter(
                            this.props.location.search,
                            stringed
                        )
                        history.push({
                            search: query,
                        })

                        toggleTransactionPopup(true)
                    }
                    if (args.item.text == 'Delete') {
                    }
                }}
                items={[
                    {
                        iconCss: styles.subIconEdit,
                        text: 'Edit',
                    },
                    // Going to hide clone for now as calling from the the TenantDetails screen
                    // causes a 'Can't perform a React state update on an unmounted component.' error
                    // which triggers a component reload and ineffect wiping out the clone mode and
                    // going into edit mode.
                    /* {
                        iconCss: styles.subIconEdit,
                        text: 'Clone',
                    }, */
                    {
                        separator: true,
                    },
                    {
                        iconCss: styles.subIconAddDocument,
                        text: 'Add Document',
                    },
                    {
                        iconCss: styles.subIconAddTenancyContract,
                        text: 'Add Tenancy Contract',
                    },
                    {
                        iconCss: styles.subIconAddIssue,
                        text: 'Add Issue',
                    },
                    {
                        iconCss: styles.subIconAddExpense,
                        text: 'Add Expense',
                    },
                    {
                        iconCss: styles.subIconAddIncome,
                        text: 'Add Income',
                    },
                    {
                        separator: true,
                    },
                    ...(this.props.propertyId
                        ? [
                              {
                                  iconCss: styles.subIconProperty,
                                  text: 'Linked Property',
                              },
                          ]
                        : []),
                    ...(this.props.contactId
                        ? [
                              {
                                  iconCss: styles.subIconContact,
                                  text: 'Linked Contact',
                              },
                          ]
                        : []),
                    {
                        iconCss: styles.subIconDelete,
                        text: 'Delete',
                    },
                ]}
            />
        )
    }
}

export default withRouter(SFTenancyDropdown)
