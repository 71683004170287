import { apiService } from './ApiService';
import * as domains from '../configuration/domains';
import { createBearerToken } from '../helpers/authHelpers';
import { IListItemOptions, IPermissionListOptions } from '../types/ListFilters';
import { toQueryString } from './DocumentService';
import { IPermission } from '../types/PermissionsService';

export const getPermissions = (options: IListItemOptions, permissionOptions: IPermissionListOptions): Promise<any> => {
    var stringed = toQueryString(options);
    var stringed2 = toQueryString(permissionOptions);

    return apiService.get(`${domains.permissionsService}?${stringed}&${stringed2}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getPermissionsByRoleId = (roleId: string): Promise<any> => {
    return apiService.get(`${domains.permissionsService}/get-permissions/role/${roleId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const updatePermission = (permission: any): Promise<any> => {
    return apiService.put(`${domains.permissionsService}`, permission, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}