import React, { useEffect, useState } from 'react'
import * as S from './ToggleButton.styles'

interface props {
    label: string;
    initialStatus?: boolean;
    reset?: boolean;
    buttonOnClick(onStatus: boolean): void;
}

const ToggleButton = ({label, initialStatus, reset, buttonOnClick} : props) => {
    const [onStatus, setOnStatus] = useState<boolean>(initialStatus ? initialStatus : false);

    useEffect(()=> {
        if(reset){
            setOnStatus(false);
        }
    }, [reset])

    const onClick = () => {
        setOnStatus(!onStatus);
        buttonOnClick(!onStatus);
    }

    return (
        <S.ToggleButton onStatus={onStatus} onClick={onClick}>{label}</S.ToggleButton>
    )
}

export default ToggleButton;