import { IClientData } from '../../types/ClientService'

const TagLineItems: string[] = [
    'No credit card required',
    'Online support',
    'Cancel any time',
]
const RequestPasswordResetSuccess =
    'Thank you for your request, please check your email inbox where you should have received an email with a link to reset your password. If not received then please check your junk folder or contact us at'
const LoginFormHeadingText = (client?: IClientData) =>
    `${client && client.name && `Log in to ${client.name}`}`
const NoAccountText = 'No account?'
const SignUpText = 'Sign up'
const ForgottenPasswordText = 'Forgotten password?'
const KeepMeLoggedInText = 'Keep me logged in'

export {
    ForgottenPasswordText,
    KeepMeLoggedInText,
    LoginFormHeadingText,
    NoAccountText,
    TagLineItems,
    SignUpText,
    RequestPasswordResetSuccess,
}
