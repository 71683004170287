import React, { Component } from 'react'

// Import Styles
import styles from './AddEditUnit.module.scss'
import { withRouter } from 'react-router-dom'
import AddEditUnitForm from './AddEditUnitForm'
import { IUnitItem } from '../../../types/UnitService'
import { connect } from 'react-redux'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import { getImages } from '../../../services/UnitService'
import { updateViewingUnit } from '../../../helpers/unitHelpers'
import { imageService } from '../../../configuration/domains'
import { processToast, updateToastMessage } from '../../../helpers/toastHelper'
import SFUploaderModal from '../../atoms/SFUploaderModal'
import ImageGalleryComponent from '../../atoms/ImageGallery'
import { ImageTypeEnum } from '../../../types/ImageService'
import { IDataResponse } from '../../../types/ApiService'
import { changePrimaryImage } from '../../../services/ImageService'

export interface IAddEditUnitOptions {
    unitId?: string
    propertyId?: string
    propertyName?: string
}

interface IWithRouterProps {
    history: any
    location: any
    match: any

    options?: IAddEditUnitOptions

    // Add Mode
    onSuccessCallback?(unit: IUnitItem): void

    // Edit Mode
    unit?: IUnitItem
    onUpdateCallback?(unit: IUnitItem): void
    onUpdateReturnUrl?: string
}

interface IState {
    upload_images: boolean
}

class AddEditUnit extends Component<IWithRouterProps, IState> {
    constructor(props: any) {
        super(props)
        this.state = {
            upload_images: false,
        }

        this.refreshImages = this.refreshImages.bind(this)
        this.onChangePrimaryImage = this.onChangePrimaryImage.bind(this)
    }

    refreshImages(): void {
        getImages(this.props.match.params.unitId).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                updateViewingUnit({
                    ...this.props.unit,
                    images: resp.data,
                })
            }
        })
    }

    onChangePrimaryImage(imageId: string): void {
        changePrimaryImage({
            imageId,
            unitId: this.props.match.params.unitId,
            imageType: ImageTypeEnum.UNIT_IMAGE,
        }).then((resp) => {
            if (!resp || resp.status != 200) {
                return
            }

            let response: IDataResponse<string> = resp.data
            processToast(response)

            this.refreshImages()
        })
    }

    render() {
        return (
            <div className={styles.page}>
                <div className={styles.content}>
                    {/* Main Row */}
                    <div className={styles.row}>
                        {/* Input and Picture columns */}
                        <div className={`${styles.form}`}>
                            <AddEditUnitForm
                                options={this.props.options}
                                unit={this.props.unit}
                                onUpdateCallback={this.props.onUpdateCallback}
                                onSuccessCallback={this.props.onSuccessCallback}
                            />
                        </div>

                        {this.props.match.params.unitId && (
                            <div className={`${styles.col} ${styles.imageCol}`}>
                                <div className={styles.imageGallery}>
                                    <ImageGalleryComponent
                                        onClickFullscreen
                                        dropdownActions={{
                                            onClickUpload: () =>
                                                this.setState({
                                                    upload_images: true,
                                                }),
                                            onClickDelete: () =>
                                                this.refreshImages(),
                                            onChangePrimaryImage: (
                                                imageId: string
                                            ) =>
                                                this.onChangePrimaryImage(
                                                    imageId
                                                ),
                                        }}
                                        items={this.props.unit.images}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <SFUploaderModal
                        open={this.state.upload_images}
                        onToggle={(open: boolean) =>
                            this.setState({ upload_images: open })
                        }
                        urls={{
                            saveUrl: `${imageService}/Save`,
                            removeUrl: `${imageService}/Remove`,
                        }}
                        onFileUpload={(imageIds?: string[]) =>
                            updateToastMessage(
                                'Unit image has been uploaded successfully',
                                'success'
                            )
                        }
                        successCallback={() => {
                            this.setState({ upload_images: false })
                            this.refreshImages()
                        }}
                        options={{
                            unitId: this.props.match.params.unitId,
                            imageType: ImageTypeEnum.UNIT_IMAGE,
                        }}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    unit: state.unit.viewing_unit,
})

export default withRouter<IWithRouterProps,any>(connect(mapStateToProps)(AddEditUnit))
