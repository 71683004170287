import { combineReducers } from "redux";
import user from "./userReducer";
import app from "./appReducer";
import calculator from "./calculatorReducer";
import property from "./propertyReducer";
import client from "./clientReducer";
import walkthrough from "./walkthroughReducer";
import contact from "./contactReducer";
import document from "./documentReducer";
import issue from "./issuesResolver";
import chat from "./chatReducer";
import alert from "./alertReducer";
import transaction from "./transactionReducer";
import toast from "./toastReducer";
import filters from "./filterReducer";
import notifications from "./notificationReducer";
import unit from "./unitReducer";
import inspection from './inspectionReducer';
import assets from './assets/assetsReducer';
import assetTypes from './assets/assetTypesReducer';
import assetDropdownTypes from './assets/assetDropdownTypesReducer';
import conductInspection from './conductInspection/conductInspectionReducer';
import teams from './teams/teamsReducer'
import { tasksApi } from '../servicesRtk/TasksServiceRtk';
import { imageApi } from "../servicesRtk/ImageServiceRtk";
import { unitApi } from "../servicesRtk/UnitServiceRtk";
import { betaConfigReducer } from "../slices/betaConfigSlice"
import { calculatorApi } from "../servicesRtk/CalculatorServiceRtk";

const reducers = combineReducers({
  app,
  alert,
  assets,
  assetDropdownTypes,
  assetTypes,
  chat,
  contact,
  client,
  conductInspection,
  document,
  filters,
  inspection,
  issue,
  notifications,
  property,
  calculator,
  teams,
  toast,
  transaction,
  user,
  unit,
  walkthrough,
  [tasksApi.reducerPath]: tasksApi.reducer,
  [imageApi.reducerPath]: imageApi.reducer,
  [unitApi.reducerPath]: unitApi.reducer,
  [calculatorApi.reducerPath]: calculatorApi.reducer,
  betaConfigReducer
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;