import React, { Component } from "react";
import * as ReactDOM from 'react-dom';

// Import Styles
import styles from "./PermissionsPage.module.scss";
import { getPermissions, updatePermission } from "../../../../services/PermissionsService";
import { ClickEventArgs } from '@syncfusion/ej2-navigations';

// Sync Fusion stuff
import { ColumnDirective, ColumnsDirective, Grid, GridComponent, Sort, Toolbar, DataStateChangeEventArgs, ExcelExport, Group, PdfExport, GroupSettingsModel, EditSettingsModel, Edit, FilterSettingsModel, Filter, CommandColumn, ContextMenuItem, ContextMenu, ColumnMenu, QueryCellInfoEventArgs, Column, PdfExportProperties, ExcelExportProperties, Reorder } from '@syncfusion/ej2-react-grids';
import { Inject, Page } from '@syncfusion/ej2-react-grids';
import { minWidthTablet } from "../../../../global/variables/_variables";
import { IPermissionListOptions } from "../../../../types/ListFilters";
import { contextMenuItems, toolbarOptions, searchOptions, selectionSettings, editOptions, groupOptions, pageSettings, filterOptions, commands } from "../../../../configuration/grid_syncfusion_options";

import "@syncfusion/ej2-react-grids/styles/material.css";
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-calendars/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';
import "@syncfusion/ej2-react-grids/styles/material.css";

import { getValue } from '@syncfusion/ej2-base';
import UserContactInfo, { UserContactInfoType } from "../../../atoms/UserContactInfo";
import { getUserId } from "../../../../helpers/authHelpers";

interface IState {
  permissions: JSON[];
}

interface IProps {
  roleId?: string;
}

class PermissionsPage extends Component<IProps, IState> {
  // Ref to the Grid Instance
  public grid: Grid | null;

  public contactComponent(args: QueryCellInfoEventArgs) {
    if ((args.column as Column).field === "permissionTitle") {
      const val = getValue((args.column as Column).field, args.data);
      ReactDOM.render(<UserContactInfo disablePopup id={getUserId()} type={UserContactInfoType.User} />, args.cell as Element)
    }
  }

  constructor(props: any) {
    super(props);

    this.state = {
      permissions: null
    };

    this.dataStateChange = this.dataStateChange.bind(this);
    this.renderComplete = this.renderComplete.bind(this);
    this.actionBegin = this.actionBegin.bind(this);
    this.contactComponent = this.contactComponent.bind(this);
  }

  // What to do once the grid has loaded in
  renderComplete() {
    if (!this.state.permissions) {
      const state = { skip: 0, take: 10 };
      this.dataStateChange(state);
    }
  }

  // Managing toolbar click events
  toolbarClick = (args: ClickEventArgs) => {
    const selectedRecords = this.grid.getSelectedRecords();

    if (this.grid && args.item.id === 'permissionsGrid_pdfexport') {
      const exportProperties: PdfExportProperties = {
        dataSource: selectedRecords
      };
      if (selectedRecords.length > 0) {
        this.grid.pdfExport(exportProperties, true);
        return;
      }

      this.grid.pdfExport();
      return;
    }

    if (this.grid && args.item.id === 'permissionsGrid_excelexport') {
      if (selectedRecords.length > 0) {
        const exportProperties: ExcelExportProperties = {
          dataSource: selectedRecords
        };
        this.grid.csvExport(exportProperties);
        return;
      }
      this.grid.csvExport();
      return;
    }
  }

  dataStateChange(state: DataStateChangeEventArgs) {
    let permissionOptions: IPermissionListOptions = {}
    if (this.props.roleId) {
      permissionOptions.roleId = this.props.roleId;
    }

    getPermissions({
      page: state.skip,
      size: state.take,
      skip: state.skip
    }, permissionOptions).then(resp => {
      if (resp && resp.status == 200 && resp.data) {
        this.setState({
          permissions: resp.data.result
        });
      }
    });
  }

  // This is the event we use to manage
  // actions across the grid.
  // Use the request type to see what type it is, save, close, delete, etc.
  actionBegin(obj: any) {
    if (obj.requestType === 'save') {
      updatePermission(obj.data);
    }
  }

  render() {
    return (
      <div className={styles.generalPage}>
        <div>
          <GridComponent
            allowReordering={true}
            showColumnMenu
            contextMenuItems={contextMenuItems}
            actionBegin={this.actionBegin}
            id="permissionsGrid"
            toolbarClick={this.toolbarClick}
            allowTextWrap={true}
            allowExcelExport={true}
            allowPdfExport={true}
            allowGrouping={true}
            dataStateChange={this.dataStateChange}
            dataBound={this.renderComplete}
            toolbar={toolbarOptions}
            dataSource={this.state.permissions}
            searchSettings={searchOptions}
            selectionSettings={selectionSettings}
            editSettings={editOptions}
            groupSettings={groupOptions}
            allowPaging={true}
            allowSorting={true}
            pageSettings={pageSettings}
            filterSettings={filterOptions}
            allowFiltering={true}
            ref={g => this.grid = g}
          >
            <Inject services={[Reorder, ColumnMenu, ContextMenu, Edit, CommandColumn, Filter, Page, Sort, Toolbar, ExcelExport, PdfExport, Group]} />
            <ColumnsDirective>
              <ColumnDirective textAlign="Left" type='checkbox' minWidth='25' width='25' />
              <ColumnDirective textAlign="Left" headerText="Group" field='permissionGroup' width='100' />
              <ColumnDirective textAlign="Left" headerText="Title" field='permissionTitle' width='100' />
              <ColumnDirective textAlign="Left" headerText="Description" field='permissionDescription' width='100' />
              <ColumnDirective hideAtMedia={`(min-width: ${minWidthTablet})`} allowEditing={false} textAlign="Left" headerText="Code" field='permissionCode' width='100' />
              <ColumnDirective hideAtMedia={`(min-width: ${minWidthTablet})`} width='25' commands={commands}></ColumnDirective>
            </ColumnsDirective>
          </GridComponent>
        </div>
      </div>
    );
  }
}

export default PermissionsPage;