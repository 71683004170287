import styled from 'styled-components';

export const SectionDiv = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 100%; 
    grid-template-areas:
        'header'
        'body';
`; 

export const bodyDiv = styled.div`
    background-color: #F5F5F5;
    border-radius: 20px;
`;

export const bodyContentDiv = styled.div`
    grid-area: body;
    display: grid;
    grid-template-columns: 33% 33% 33%; 
    grid-template-rows: 100px;
    grid-template-areas:
        'helpCentre helpVideos suggestion'
    padding-top: 0px;
    align-items: center;
`;

export const helpCentreDiv = styled.div`
    grid-area: helpCentre;
`;

export const helpVideosDiv = styled.div`
    grid-area: helpVideos;
`;

export const suggestionDiv = styled.div`
    grid-area: suggestion;
`;


