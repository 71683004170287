import React, { Component } from "react";

import styles from "./MaterialRadioButtons.module.scss";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

interface IProps {
  labels: string[];
  subText?: string[];
  default?: string;
  reducedLabelHeight?: boolean;
  handleChange(radioValue): void;
}

interface IState {
  value: string;
}

class MaterialRadioButtons extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      value: this.props.default ? this.props.default : this.props.labels[0]
    };
  }

  componentWillReceiveProps(newProps: IProps): void {
    if(newProps.default != this.props.default){
      this.setState({
        value: newProps.default
      });
    }
  }

  handleChange = event => {
    this.setState({ value: event.target.value });
    this.props.handleChange(event.target.value);
  };

  render() {
    return (
      <FormControl className={styles.formControl}>
        <RadioGroup
          aria-label="toggle"
          name="toggle"
          value={this.state.value}
          onChange={this.handleChange}
          row
        >
          {this.props.labels.map((l, index) => {
            return (
              <FormControlLabel
                value={l}
                control={
                  <Radio
                    color={"primary"}
                    className={l == this.state.value ? styles.radio : null}
                  />
                }
                label={
                  <>
                    {l}{" "}
                    {this.props.subText != null &&
                      this.props.subText != undefined && (
                        <div className={styles.subText}>
                          {this.props.subText[index]}
                        </div>
                      )}
                  </>
                }
                className={this.props.reducedLabelHeight ? styles.label : ""}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  }
}

export default MaterialRadioButtons;
