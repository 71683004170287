import { apiService } from '../services/ApiService';
import * as domains from '../configuration/domains';
import { createBearerToken } from '../helpers/authHelpers';
import { IListItemOptions } from '../types/ListFilters';
import { toQueryString } from './DocumentService';

export const getAudits = (options: IListItemOptions): Promise<any> => {
    var stringed = toQueryString(options);

    return apiService.get(`${domains.auditService}?${stringed}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getAuditsV2 = (options: IListItemOptions): Promise<any> => {
    var stringed = toQueryString(options);

    return apiService.get(`${domains.auditService}/audits?${stringed}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getAudit = (auditId: string): Promise<any> => {
    return apiService.get(`${domains.auditService}/${auditId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getAuditDashboardData = (): Promise<any> => {
    return apiService.get(`${domains.auditService}/dashboard`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}