import styled from 'styled-components';

export const Table = styled.div`
    width: 100%;
    display: table;
    font-size: 0.8rem;
`; 

export const TableCaption = styled.div`
    display: table-caption;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
`; 

export const TableHeader = styled.div`
    display: table-header-group;
    background-color: gray;
    font-weight: bold;
    font-size: 25px;
`; 

export const TableHeaderCell = styled.div<{ justify?: string }>`
    display: table-cell;
    //padding: 10px;
    text-align: ${p => p.justify ? p.justify : 'left'};
    border-bottom: 1px solid black;
`; 

export const TableBody = styled.div`
    display: table-row-group;
`; 

export const TableRow = styled.div`
    display: table-row;
    height: 10px;
`; 

export const TableCell = styled.div<{ justify?: string, width?: string }>`
    display: table-cell;
    text-align: ${p => p.justify ? p.justify : 'left'};
    width: ${p => p.width ? p.width : "10%"};
    padding-top: 2px;
    padding-bottom: 2px;
`; 

export const DeleteImage = styled.img`
    pointer-events: all !important;
    &:hover {
        background-color: ${({ theme }) => theme.buttons.primaryHover};
        cursor: pointer;
      }
`; 