import { createGlobalStyle } from 'styled-components';

export const GlobalGridStyle = createGlobalStyle`
.gridCustomButton {
    color: white !important;
    background: ${({ theme }: any) => theme.buttons.primary} 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
    border: none !important;
    &:hover{
      background-color: ${({ theme }: any) => theme.colors.primary} !important;
      cursor: pointer !important;
    }
  }

  .e-grid .e-pager .e-currentitem, .e-pager .e-currentitem {
      background-color: ${({ theme }: any) => theme.buttons.primary};
  }

  .e-grid .e-pager .e-currentitem:hover, .e-pager .e-currentitem:hover {
      background-color: ${({ theme }: any) => theme.colors.primary};
  }

.e-grid .e-rowcell {
    color: #104351;
    border-color: #c4e2df;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 1px;
    padding-bottom: 1px;
}

.e-emptyrow {
    text-align: center;
}

.e-grid .e-headercell {
    color: #104351;
    border-color: #c4e2df;
    border-bottom: 1px solid #c4e2df;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 1px;
    padding-bottom: 1px;
}

.e-grid {
    border-collapse: collapse;
    border-color: white;
}

.e-grid .e-sortfilterdiv {
    float: right;
    height: 1px;
    margin: 1px 1px;
    padding: 1px;
    width: 1px;
    border-color: red;
}

.e-search:before{
    content:'\\e345';
}

.e-upload:before {
    content: '\\e607';
}
`;
