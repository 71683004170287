import React, { Component } from "react";
import { getAudit } from '../../../../../services/AuditService';

// Import Styles
import styles from "./Detail.module.scss";
import withErrorShield from "../../../../atoms/withErrorShield";
import { IAudit } from "../../../../../types/AuditService";
import { withRouter } from "react-router-dom";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import AddButton from "../../../../atoms/EditButton";
import GenericButton from "../../../../atoms/GenericButton";
import GoogleMap from "../../../../atoms/GoogleMap";

interface IState {
  audit: IAudit;
}

interface IRouterProps {
  history: any;
  match: any;
  location: any;
}

type Props = IState & IRouterProps;

class Detail extends Component<Props, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      audit: null
    };

    this.goToUser = this.goToUser.bind(this);
  }

  componentDidMount(): void {
    getAudit(this.props.match.params.auditId).then(resp => {
      if (resp && resp.data) {
        this.setState({
          audit: resp.data
        })
      }
    }).catch(e => {
      throw 'Error';
    })
  }

  goToUser(userId: string): void {
    this.props.history.push('/dashboard/manage/users/' + userId)
  }

  render() {
    const { audit } = this.state;

    if (!audit) {
      return null;
    }

    return (
      <div className={styles.generalPage}>

        <Row className={styles.buttons}>
          <Col xs="3">
            <h2>Audit Details</h2>
          </Col>
          <Col>
            <GenericButton border text='Email Audit' onClick={() => null} />
            <GenericButton border color='#e74c3c' text='Flag Audit' onClick={() => null} />
          </Col>
        </Row>

        <Row>
          <Col>
            <GoogleMap propertyName='Test' address={{
              building_Name: "",
              building_Number: "",
              sub_Building_Name: "",
              sub_Building_Number: "",
              line1: "",
              line2: "",
              street_Name: "",
              town: "",
              county: "",
              locality: "",
              district: "",
              country: "",
              postcode: ""
            }} />
          </Col>

          <Col>
            <div className={styles.label}>Type</div>
            <div className={styles.detail}>{audit.type}</div>

            <div className={styles.label}>Title</div>
            <div className={styles.detail}>{audit.title}</div>

            <div className={styles.label}>Details</div>
            <div className={styles.detail}>{audit.body}</div>

            <div className={styles.label}>User Id</div>
            <div className={styles.linkDetail} onClick={() => this.goToUser(audit.user.userId)}>{audit.user.userId}</div>

            <div className={styles.label}>Email</div>
            <div className={styles.detail}>{audit.user.email}</div>

            <div className={styles.label}>Name</div>
            <div className={styles.detail}>{audit.user.name}</div>


          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(withErrorShield(Detail) as any);