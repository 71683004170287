import React from "react";
import styles from "./IssuesCard.module.scss";

import { getIssues } from "../../../services/IssueService";
import { getDashboardIssues } from "../../../services/DashboardService";
import { IListItemOptions, IIssueListItemOptions } from "../../../types/ListFilters";
import { IIssueItem } from "../../../types/IssueService";
import IssueStatus from "../../atoms/IssuesStatus";

interface IProps {
  propertyId?: string;
  propertyName?: string;
  contactId?: string;
  tenancyId?: string;
  showIcon?: boolean;
  canAdd?: boolean;
}

interface IIssueResponse {
  category: string;
  contactId: string;
  ceatedBy: string;
  dateAdded: string;
  description: string;
  issueId: string;
  propertyId: string;
  status: string;
  title: string;
}

interface IState {
  loading: boolean;
  issues: Array<IIssueResponse>;
  results: Array<IIssueItem>;
  page: number;
  size: number;
}

class IssuesCard extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      issues: [],
      results: [],
      page: 1,
      size: 0
    };
  }

  componentDidMount() {
    let request: IListItemOptions = {
      page: this.state.page,
      size: this.state.size
    };

    if (this.props.contactId) {
      request.contactId = this.props.contactId;
    }

    if (this.props.propertyId) {
      request.propertyId = this.props.propertyId;
    }

    if (this.props.tenancyId) {
      request.tenancyId = this.props.tenancyId;
    }

    let issuesRequest: IIssueListItemOptions = {

    }

    getIssues(request, issuesRequest).then(resp => {
      if (resp && resp.status == 200 && resp.data) {
        this.setState({ results: resp.data.data.issues });
      }
      this.setState({
        loading: false
      });
    }).catch(e => {

    });
  }

  getIssueTypeCount(statusId: string[]) {
    let count = 0;

    if (this.state.results.length == 0) {
      for (let i = 0; i < this.state.issues.length; i++) {
        for (let j = 0; j < statusId.length; j++) {
          if (this.state.issues[i].status == statusId[j]) {
            count++;
          }
        }
      }
    } else {
      for (let i = 0; i < this.state.results.length; i++) {
        for (let j = 0; j < statusId.length; j++) {
          if (this.state.results[i].status == statusId[j]) {
            count++;
          }
        }
      }
    }

    return count;
  }

  render() {
    return (
      <div className={styles.issuesStatus}>
        <IssueStatus
          canAdd={this.props.canAdd}
          issuesCountNew={this.getIssueTypeCount([
            "77DD4304-B259-4D9C-8FD2-97D1489203D0".toLowerCase(),
            "New"
          ])}
          issuesCountInProgress={this.getIssueTypeCount([
            "8BD47439-A82A-4CD8-93C6-0468F229DBD9".toLowerCase(),
            "In Progress"
          ])}
          issuesCountTotal={
            this.state.results.length == 0
              ? this.state.issues.length
              : this.state.results.length
          }
          showIcon={this.props.showIcon}
          propertyId={this.props.propertyId}
          propertyName={this.props.propertyName}
        />
      </div>
    );
  }
}

export default IssuesCard;
