import styled from 'styled-components';

export const ToggleButton = styled.button<{onStatus: boolean}>`
    color: ${p => p.onStatus ? 'white' : '#707070'};
    border: 1px solid #e4e4e4;
    background-color: ${p => p.onStatus ? '#0085ca' : '#fff'};
    padding: 0.65rem;
    float: left;
    margin-bottom: 0.6rem;
    border-radius: 0;
    font-size: 0.65rem;
    margin: 0.1rem;
    margin-right: 0.3rem;
    border-radius: 6px 6px 6px 6px;
    min-width: 5rem;
  
    &:hover {
        background-color: ${({ theme }) => theme.calculatorTab.tabHoverColor};
    }
  }
  `;