const { compile } = require("path-to-regexp");
const queryString = require("query-string");
import { generatePath } from "react-router";

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export function removeURLParameter(search: any, parameter: any) {
  var urlParts = search.split("?");

  if (urlParts.length >= 2) {
    var urlBase = urlParts.shift();
    var queryString = urlParts.join("?");

    var prefix = encodeURIComponent(parameter) + "=";
    var parts = queryString.split(/[&;]/g);

    for (var i = parts.length; i-- > 0;) {
      if (parts[i].lastIndexOf(prefix, 0) !== -1) {
        parts.splice(i, 1);
      }
    }

    search = urlBase + "?" + parts.join("&");
  }

  return search;
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function addURLParameter(search: any, parameter: any) {
  // remove it if it already exists
  let newQuery = removeURLParameter(search, parameter);

  let param = queryString.parse(newQuery, {
    ignoreQueryPrefix: true
  });

  let newParam = "";

  if (!isEmpty(param)) {
    newParam = newQuery + "&" + parameter;
  }

  newParam = "?" + parameter;
  return newParam;
}

export function appendAddTenancyOptions(
  search: any,
  options: IAddTenancyOptions
) {
  let optionsObject = "addTenancyOptions=" + JSON.stringify(options);
  let newQuery = addURLParameter(search, optionsObject);
  return newQuery;
}

export interface IAddTenancyOptions {
  propertyId?: string;
  propertyName?: string;
  contactId?: string;
  contactName?: string;
  unitId?: string;
  unitName?: string;
  requireUpdate?: boolean;
  refreshQueryParameter?: string;
  focusField?: string;
  isAddingFromPropertySummary?: boolean;
  cloneTenancyId?: string;
}

export function changeUrlPathParameter(
  match: any,
  newValue: string
) {
  const toPath = compile(match.path);
  const newPath = toPath({ ...match.params, issueId: newValue });
  return newPath;
}

export function changeUserIdUrlPathParameter(match: any, newValue: string) {
  const toPath = compile(match.path);
  const newPath = toPath({ ...match.params, userId: newValue });
  return newPath;
}

export function changeChatSessionIdUrlPathParameter(
  match: any,
  newValue: string
) {
  const path = generatePath(match.path, {
    ...match.params,
    chatSessionId: newValue
  });

  if (path) {
    return path;
  }

  return `/dashboard/chats/${newValue}`;
}

export function isValidPostcode(p: string) {
  var postcodeRegEx = /[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}/i;
  return postcodeRegEx.test(p);
}
