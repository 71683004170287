import { store } from "../store";

interface IReactour {
  dashboardTour: boolean;
  addPropertyTour: boolean;
}

const environment = process.env.NODE_ENV;
const localStorageTourName = "rc_tour_" + environment + "_";

export function setLocalTourInformation(tour: IReactour, userId: string) {
  userId = store.getState().user.user_id.toString();
  let name = localStorageTourName + userId;
  localStorage.setItem(name, JSON.stringify(tour));
}

export function getLocalTourInformation(userId: string) {
  if (store.getState().app.is_impersonating) {
    return;
  }

  let tour = localStorage.getItem(localStorageTourName + userId);
  if (tour) {
    let retrievedObject: IReactour = JSON.parse(tour);
    return retrievedObject;
  }

  let newObject: IReactour = {
    dashboardTour: false,
    addPropertyTour: false
  };

  setLocalTourInformation(newObject, userId);
  return newObject;
}