import styled from 'styled-components';

export const ContainerDiv = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 0px;
    align-content: flex-start;
    justify-content: flex-start;
`;

export const SectionDiv = styled.div`
    flex: 0 0 100%;
    text-align: left;
    background-color: white;
`;

export const ButtonWrapper = styled.div`
    width: 200px;
    padding-bottom: 10px;
    padding-left: 5px;
`;

export const GridWrapperDiv = styled.div`
    max-width: 600px;
    max-height: 300px;
    text-align: left;
    background-color: white;
    overflow-y:scroll;
`;

