import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./SplitListPage.module.scss";
import { withRouter } from "react-router-dom";
import collapse from "../../../images/icons/arrow_left.svg";
import collapsed from "../../../images/icons/arrow_right.svg";

interface IProps {
  listComponent: any;
  detailComponent: any;

  history: any;
  match: any;
  location: any;
  collapsed: boolean;
  toggleCollapse(): void;

  listClassName?: string;
  detailsClassName?: string;

  // Added to solve ios keyboard pushing whole screen upwards - Ignored at the moment
  inputIsFocussed: false;
}

export interface ITenancyOptions {}

class SplitListPage extends Component<IProps> {
  detailWrapper;
  
  constructor(props: any) {
    super(props);

    this.onToggleCollapse = this.onToggleCollapse.bind(this);
    this.detailWrapper = React.createRef()
  }

  onToggleCollapse(): void {
    this.props.toggleCollapse();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
    //this.scrollToBottom();
  }

  scrollToBottom = () => {
    if(this.props.inputIsFocussed){
      if (this.detailWrapper && this.detailWrapper.current) {
        console.debug("scrolling to top of window");
          this.detailWrapper.current.scrollTop = 0
      }
    }
    window.scrollTo(0, 0);
}
  render() {
    return (
      <div className={styles.page}>
        <div className={styles.container}>
          <div
            onClick={this.onToggleCollapse}
            className={`${styles.collapseToggle} ${
              this.props.collapsed ? styles.isCollapsed : ""
            }`}
          >
            <span className={styles.text}>Show list</span>
            <img src={collapsed} />
          </div>

          <div
            className={`${
              this.props.collapsed
                ? styles.collapsed
                : this.props.listClassName + " " + styles.list
            } `}
            id="scrollableList"
          >
            <div
              onClick={this.onToggleCollapse}
              className={`${styles.collapseToggle} ${
                !this.props.collapsed ? styles.isShown : ""
              }`}
            >
              <span className={styles.text}>Hide list</span>
              <img src={collapse} />
            </div>
            {this.props.listComponent}
          </div>

          <div
            className={`${styles.content + " " + this.props.detailsClassName} ${
              this.props.collapsed ? styles.collapsedContent : ""
            }`}
            ref={this.detailWrapper}
          >
            {this.props.detailComponent}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  // Added to solve ios keyboard pushing whole screen upwards - Ignored at the moment
  inputIsFocussed: state.app.input_focussed
});

export default connect(mapStateToProps)(withRouter(SplitListPage));