// Import React
import React, { Component } from 'react'
import { FormGroup, Label } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import 'react-dropzone-uploader/dist/styles.css'
import RotatingLoader from '../../atoms/RotatingLoader'
import { getClient, updateClient } from '../../../services/ClientService'
import Buttons from '../../atoms/Buttons'
import { IClientData } from '../../../types/ClientService'
import { FormControlLabel } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import SelectDropdown, { IOptions } from '../../atoms/SelectDropdown'
import { getRoles } from '../../../services/RolesService'
import addIcon from '../../../images/icons/addbutton.svg'
import deleteIcon from '../../../images/icons/action_delete.svg'
import { processToast } from '../../../helpers/toastHelper'
import { checkIsValidDomain } from '../../../helpers/domainHelpers'
import FileUploadDropzone from '../../atoms/FileUploadDropzone'
import { IDocumentUpload } from '../../molecules/AddDocumentModal'
import { CopyBlock, dracula } from 'react-code-blocks'
import infoIcon from '../../../images/icons/ic_info_24px.png'
import { Tooltip } from 'react-tippy'
import styles from './ClientDetailPage.module.scss'

interface IState {
    client: IClientData
    roles: IOptions[]
    isLoading: boolean
    isChangingProfilePicture: boolean
    adding_whitelabel_name: string
    adding_whitelabel_valid_domain: boolean
}

interface IRouterProps {
    history: any
    location: any
    match: any
}

type Props = IRouterProps

class ClientDetailPage extends Component<Props, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            client: null,
            roles: [],
            isLoading: false,
            isChangingProfilePicture: false,
            adding_whitelabel_name: '',
            adding_whitelabel_valid_domain: null,
        }

        this.handleChange = this.handleChange.bind(this)
        this.updateClient = this.updateClient.bind(this)
        this.addFile = this.addFile.bind(this)
    }

    componentDidMount(): void {
        window.scrollTo(0, 0)

        let clientId = this.props.match.params.clientId
        if (!clientId) {
            return
        }

        this.setState({
            isLoading: true,
        })

        getClient(clientId).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                this.setState({
                    client: resp.data,
                    isLoading: false,
                })
            }
        })

        getRoles().then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                let roles: IOptions[] = []
                resp.data.forEach((x: any) => {
                    roles.push({
                        label: x.roleName,
                        value: x.roleId,
                    })
                })

                this.setState({
                    roles: roles,
                })
            }
        })
    }

    updateClient(): void {
        updateClient(this.state.client.id, this.state.client).then((resp) => {
            processToast<IClientData>(resp.data)
            this.setState({
                isLoading: false,
            })
        })
    }

    addFile(file: IDocumentUpload, field: string): void {
        this.setState((prevState) => ({
            client: {
                ...prevState.client,
                [field]: file.base64,
            },
        }))

        if (field == 'logo') {
            this.setState((prevState) => ({
                client: {
                    ...prevState.client,
                    upload_logo: file,
                },
            }))
        }

        if (field == 'secondaryLogo') {
            this.setState((prevState) => ({
                client: {
                    ...prevState.client,
                    upload_secondary_logo: file,
                },
            }))
        }
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const field = event.currentTarget.name
        const value: string = event.currentTarget.value
        this.setState({ [field]: value } as Pick<IState, any>)
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className={styles.loader}>
                    <RotatingLoader loading={true} text="Loading Client..." />
                </div>
            )
        }

        if (!this.state.client) {
            return <div>No client data available</div>
        }

        return (
            <div className={styles.page}>
                <Formik
                    initialValues={{
                        name: this.state.client.name,
                        platformName: this.state.client.platformName,
                        description: this.state.client.description,
                        logo: this.state.client.logo,
                        clientAdminEmail: this.state.client.clientAdminEmail,
                        htmlEmailSignature:
                            this.state.client.htmlEmailSignature,
                        htmlRegistrationScreenFooter:
                            this.state.client.htmlRegistrationScreenFooter,
                        registrationSnippet:
                            this.state.client.registrationSnippet,
                        defaultRole: this.state.client.defaultRole,
                        clientSystemArea: this.state.client.clientSystemArea,
                    }}
                    onSubmit={(values: any, { setSubmitting }) => {
                        this.setState(
                            (prevState) => ({
                                isLoading: true,
                                client: {
                                    ...prevState.client,
                                    name: values.name,
                                    clientSystemArea: values.clientSystemArea,
                                    platformName: values.platformName,
                                    description: values.description,
                                    clientAdminEmail: values.clientAdminEmail,
                                    whitelabelDomains:
                                        this.state.client.whitelabelDomains &&
                                        this.state.client.whitelabelDomains
                                            .length > 0
                                            ? this.state.client
                                                  .whitelabelDomains
                                            : [],
                                },
                            }),
                            () => {
                                this.updateClient()
                            }
                        )
                    }}
                >
                    {(props) => (
                        <Form>
                            <div className={styles.row}>
                                <div className={styles.details}>
                                    <h2 className={styles.sectionHeading}>
                                        Client Details
                                    </h2>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="client.name"
                                        >
                                            Whitelabel Name
                                        </Label>
                                        <Field
                                            className={styles.input}
                                            type="text"
                                            name="name"
                                            placeholder="Name"
                                            id={styles.smallInput}
                                        />
                                    </FormGroup>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="client.platformName"
                                        >
                                            Platform Name
                                        </Label>
                                        <Field
                                            className={styles.input}
                                            type="text"
                                            name="platformName"
                                            placeholder="Name"
                                            id={styles.smallInput}
                                        />
                                    </FormGroup>

                                    <FormGroup className={styles.formGroup}>
                                        <Label className={styles.label}>
                                            Whitelabel Domains
                                        </Label>

                                        <div
                                            className={
                                                styles.whitelabelDomainsRow
                                            }
                                        >
                                            <div className={styles.inputCol}>
                                                <input
                                                    className={styles.input}
                                                    value={
                                                        this.state
                                                            .adding_whitelabel_name
                                                    }
                                                    type="text"
                                                    placeholder="Add domain"
                                                    id={styles.smallInput}
                                                    onChange={(event: any) => {
                                                        this.setState({
                                                            adding_whitelabel_name:
                                                                event
                                                                    .currentTarget
                                                                    .value,
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <div className={styles.buttonCol}>
                                                <img
                                                    src={addIcon}
                                                    onClick={(e: any) => {
                                                        e.preventDefault()
                                                        e.stopPropagation()

                                                        if (
                                                            !checkIsValidDomain(
                                                                this.state
                                                                    .adding_whitelabel_name
                                                            )
                                                        ) {
                                                            this.setState({
                                                                adding_whitelabel_valid_domain:
                                                                    false,
                                                            })
                                                            return
                                                        }

                                                        this.setState(
                                                            (prevState) => ({
                                                                client: {
                                                                    ...prevState.client,
                                                                    whitelabelDomains:
                                                                        this
                                                                            .state
                                                                            .client
                                                                            .whitelabelDomains !=
                                                                        null
                                                                            ? [
                                                                                  this
                                                                                      .state
                                                                                      .adding_whitelabel_name,
                                                                              ].concat(
                                                                                  this
                                                                                      .state
                                                                                      .client
                                                                                      .whitelabelDomains
                                                                              )
                                                                            : [
                                                                                  this
                                                                                      .state
                                                                                      .adding_whitelabel_name,
                                                                              ],
                                                                },
                                                                adding_whitelabel_name:
                                                                    '',
                                                                adding_whitelabel_valid_domain:
                                                                    null,
                                                            })
                                                        )
                                                    }}
                                                />
                                            </div>

                                            {this.state
                                                .adding_whitelabel_name &&
                                                this.state
                                                    .adding_whitelabel_valid_domain ==
                                                    false && (
                                                    <p>
                                                        Please enter a valid
                                                        domain
                                                    </p>
                                                )}
                                        </div>

                                        {this.state.client.whitelabelDomains &&
                                            this.state.client.whitelabelDomains
                                                .length && (
                                                <ul
                                                    className={
                                                        styles.domainsList
                                                    }
                                                >
                                                    {this.state.client.whitelabelDomains.map(
                                                        (x) => {
                                                            return (
                                                                <li>
                                                                    {x}{' '}
                                                                    <img
                                                                        src={
                                                                            deleteIcon
                                                                        }
                                                                        onClick={() =>
                                                                            this.setState(
                                                                                (
                                                                                    prevState
                                                                                ) => ({
                                                                                    client: {
                                                                                        ...prevState.client,
                                                                                        whitelabelDomains:
                                                                                            this.state.client.whitelabelDomains.filter(
                                                                                                (
                                                                                                    y
                                                                                                ) =>
                                                                                                    y !==
                                                                                                    x
                                                                                            ),
                                                                                    },
                                                                                })
                                                                            )
                                                                        }
                                                                    />
                                                                </li>
                                                            )
                                                        }
                                                    )}
                                                </ul>
                                            )}
                                    </FormGroup>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="client.logo"
                                        >
                                            Whitelabel Main Logo{' '}
                                            <Tooltip
                                                theme="light"
                                                html={
                                                    <div>
                                                        This logo will be used
                                                        on top left corner of
                                                        all pages and in emails.
                                                        Avoid SVG files as will
                                                        not display correctly in
                                                        all email readers.
                                                    </div>
                                                }
                                                position="right"
                                                trigger="mouseenter"
                                                arrow
                                                interactive={true}
                                            >
                                                <img
                                                    className={styles.infoIcon}
                                                    src={infoIcon}
                                                    alt="Info"
                                                />
                                            </Tooltip>
                                        </Label>
                                        <div className={styles.logoRow}>
                                            <div className={styles.logoCol}>
                                                {this.state.client.logo ? (
                                                    <img
                                                        className={styles.logo}
                                                        src={
                                                            this.state.client
                                                                .upload_logo
                                                                ? this.state
                                                                      .client
                                                                      .upload_logo
                                                                      .base64
                                                                : this.state
                                                                      .client
                                                                      .logo
                                                        }
                                                    />
                                                ) : (
                                                    'No logo uploaded'
                                                )}
                                            </div>
                                            <div className={styles.uploadCol}>
                                                <FileUploadDropzone
                                                    addFile={(
                                                        file: IDocumentUpload
                                                    ) =>
                                                        this.addFile(
                                                            file,
                                                            'logo'
                                                        )
                                                    }
                                                    maxSize={10}
                                                    text="Upload main logo"
                                                />
                                            </div>
                                        </div>
                                    </FormGroup>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="client.secondaryLogo"
                                        >
                                            Whitelabel Mini Logo{' '}
                                            <Tooltip
                                                theme="light"
                                                html={
                                                    <div>
                                                        This logo will be used
                                                        on top left corner of
                                                        all pages when the page
                                                        scrolls down and you get
                                                        a mini header and in
                                                        emails. Avoid SVG files
                                                        as will not display
                                                        correctly in all email
                                                        readers.
                                                    </div>
                                                }
                                                position="right"
                                                trigger="mouseenter"
                                                arrow
                                                interactive={true}
                                            >
                                                <img
                                                    className={styles.infoIcon}
                                                    src={infoIcon}
                                                    alt="Info"
                                                />
                                            </Tooltip>
                                        </Label>
                                        <div className={styles.logoRow}>
                                            <div className={styles.logoCol}>
                                                {this.state.client
                                                    .secondaryLogo ? (
                                                    <img
                                                        className={styles.logo}
                                                        src={
                                                            this.state.client
                                                                .upload_secondary_logo
                                                                ? this.state
                                                                      .client
                                                                      .upload_secondary_logo
                                                                      .base64
                                                                : this.state
                                                                      .client
                                                                      .secondaryLogo
                                                        }
                                                    />
                                                ) : (
                                                    'No logo uploaded'
                                                )}
                                            </div>
                                            <div className={styles.uploadCol}>
                                                <FileUploadDropzone
                                                    addFile={(file: any) =>
                                                        this.addFile(
                                                            file,
                                                            'secondaryLogo'
                                                        )
                                                    }
                                                    maxSize={10}
                                                    text="Upload mini logo"
                                                />
                                            </div>
                                        </div>
                                    </FormGroup>

                                    <h2 className={styles.sectionHeading}>
                                        Other Config
                                    </h2>
                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="client.clientSystemAreas"
                                        >
                                            System Areas
                                        </Label>
                                        <textarea
                                            placeholder="Enter system areas..."
                                            name="clientSystemArea"
                                            onChange={(e) =>
                                                props.setFieldValue(
                                                    'clientSystemArea',
                                                    e.currentTarget.value
                                                )
                                            }
                                            value={
                                                props.values.clientSystemArea
                                            }
                                        />
                                    </FormGroup>
                                </div>

                                <div className={styles.secondColumn}>
                                    <h2 className={styles.sectionHeading}>
                                        Email Details
                                    </h2>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="client.clientAdminEmail"
                                        >
                                            Client Admin Email
                                        </Label>
                                        <Field
                                            className={styles.input}
                                            type="text"
                                            name="clientAdminEmail"
                                            placeholder="Name"
                                            id={styles.smallInput}
                                        />
                                    </FormGroup>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="client.htmlEmailSignature"
                                        >
                                            HTML Email Signature
                                        </Label>

                                        <textarea
                                            placeholder="Enter HTML..."
                                            value={
                                                this.state.client
                                                    .htmlEmailSignature
                                            }
                                            className={styles.input}
                                            rows={5}
                                            onChange={(e: any) => {
                                                let value = e.target.value
                                                this.setState((prevState) => ({
                                                    client: {
                                                        ...prevState.client,
                                                        htmlEmailSignature:
                                                            value,
                                                    },
                                                }))
                                            }}
                                        />
                                    </FormGroup>

                                    <h2 className={styles.sectionHeading}>
                                        Registration Details
                                    </h2>
                                    <p className={styles.previewLink}>
                                        <a
                                            href={`/registration-preview?whitelabelId=${this.state.client.id}&preview=true`}
                                            target="_blank"
                                        >
                                            Preview Registration
                                        </a>{' '}
                                        <Tooltip
                                            theme="light"
                                            html={
                                                <div>
                                                    Clicking this allows you to
                                                    preview your registration
                                                    page in a new tab or window.
                                                </div>
                                            }
                                            position="right"
                                            trigger="mouseenter"
                                            arrow
                                            interactive={true}
                                        >
                                            <img
                                                className={styles.infoIcon}
                                                src={infoIcon}
                                                alt="Info"
                                            />
                                        </Tooltip>
                                    </p>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="client.htmlRegistrationScreenFooter"
                                        >
                                            HTML Registration screen footer
                                        </Label>

                                        <textarea
                                            placeholder="Enter HTML..."
                                            value={
                                                this.state.client
                                                    .htmlRegistrationScreenFooter
                                            }
                                            className={styles.input}
                                            rows={5}
                                            onChange={(e: any) => {
                                                let value = e.target.value
                                                this.setState((prevState) => ({
                                                    client: {
                                                        ...prevState.client,
                                                        htmlRegistrationScreenFooter:
                                                            value,
                                                    },
                                                }))
                                            }}
                                        />
                                    </FormGroup>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.labelNoFloat}
                                            for="client.registrationSnippet"
                                        >
                                            Registration snippet
                                        </Label>
                                        <CopyBlock
                                            language={'js'}
                                            text={
                                                this.state.client
                                                    .registrationSnippet
                                            }
                                            showLineNumbers={false}
                                            theme={dracula}
                                            wrapLines={true}
                                        />
                                    </FormGroup>

                                    <FormGroup
                                        className={styles.checkboxFormGroup}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        this.state.client
                                                            .approvalRequired
                                                    }
                                                    color="primary"
                                                    onChange={() =>
                                                        this.setState(
                                                            (prevState) => ({
                                                                client: {
                                                                    ...prevState.client,
                                                                    approvalRequired:
                                                                        !this
                                                                            .state
                                                                            .client
                                                                            .approvalRequired,
                                                                },
                                                            })
                                                        )
                                                    }
                                                    value="Email"
                                                    className={
                                                        this.state.client
                                                            ? styles.materialCheckbox
                                                            : null
                                                    }
                                                />
                                            }
                                            label="Do new users need to be approved by an administrator?"
                                        />
                                    </FormGroup>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="profile.defaultRole"
                                        >
                                            Default Role
                                        </Label>
                                        <span className={styles.dropdown}>
                                            <SelectDropdown
                                                selectedId={
                                                    this.state.client
                                                        .defaultRole
                                                }
                                                data={this.state.roles}
                                                placeholder={'Default Role'}
                                                onSelect={(id: IOptions) =>
                                                    this.setState(
                                                        (prevState) => ({
                                                            client: {
                                                                ...prevState.client,
                                                                defaultRole:
                                                                    id.value,
                                                            },
                                                        })
                                                    )
                                                }
                                            />
                                        </span>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className={styles.buttonBar}>
                                <Buttons
                                    buttons={[
                                        {
                                            displayType: 'cancel',
                                            elementType: 'button',
                                            onClick: () =>
                                                this.props.history.goBack(),
                                        },
                                        {
                                            displayType: 'submit',
                                            elementType: 'button',
                                            onClick: () => props.submitForm(),
                                        },
                                    ]}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}

export default withRouter(ClientDetailPage)
