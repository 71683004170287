// Import React
import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { RotateLoader } from "react-spinners";
import logo from "../../../images/rent_chief_logo.svg";

import { IIdentityRequest } from "../../../types/LoginService/ILogin";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { loginUser } from "../../../actions/userActions";

import styles from "./CreatePasswordForm.module.scss";
import RotatingLoader from "../../atoms/RotatingLoader";

interface IState {
  isLoggingIn: boolean;
  isLoggedIn: boolean;
  email: string;
  password: string;
  errorMessage: string;
  forename: string;
  access_token: string;
  open: boolean;
}

interface IRouterProps {
  loginUser(request: IIdentityRequest): void;
  history: any;
  location: any;
  match: any;
  is_logged_in: boolean;
  is_logging_in: boolean;
  error_message: string;
}

class CreatePasswordForm extends Component<IRouterProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isLoggingIn: false,
      isLoggedIn: false,
      forename: "",
      access_token: "",
      email: "",
      password: "",
      errorMessage: "",
      open: false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event: React.FormEvent<HTMLInputElement>) {
    const field = event.currentTarget.name;
    const value: string = event.currentTarget.value;
    this.setState({ [field]: value } as Pick<IState, any>);
  }

  componentWillMount() {}

  render() {
    return (
      <div className={styles.page}>
        {this.props.is_logging_in && (
          <span>
            <img className={styles.logo} src={logo} />
            <div>
              <RotatingLoader
                loading={true}
                text="Logging you into Rent Chief..."
              />
            </div>
          </span>
        )}

        {!this.props.is_logged_in && !this.props.is_logging_in && (
          <div>
            <Row className={styles.header}>
              <Col>
                <h1>Create Password</h1>
              </Col>
            </Row>
            <Form className={styles.form}>
              <FormGroup>
                <Label className={styles.label} for="loginRequest.password">
                  Password
                </Label>
                <Input
                  className={styles.input}
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter your password"
                  onChange={this.handleChange}
                />
              </FormGroup>

              <Button className={styles.button} type="submit">
                <span>Create Password</span>
              </Button>
            </Form>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  is_logged_in: state.user.is_logged_in,
  is_logging_in: state.user.is_logging_in,
  error_message: state.user.login_error_message
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreatePasswordForm) as any
);
