interface INotificationState {
    unreadChatCount: number;
}

const initialState = {
    unreadChatCount: 0
}

export default (state: INotificationState = initialState, action: any): INotificationState => {
    switch (action.type) {

        case "UPDATE_UNREAD_MESSAGE_COUNT":
            return {
                ...state,
                unreadChatCount: action.data
            }

        default:
            return state
    }
}