import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../../reducers/rootReducer';
import AuditHighlight from '../../../../organisms/PlatformManagementPage/AuditHighlight';
import { propertyCardStatusProspect } from '../../../../../configuration/appConfig'
import SectionHeader from "../SectionHeader/SectionHeader.component"
import * as S from "./ActiveTimelineSection.styles";

interface sectionProps {
    id: string;
    header: string;
    hideOnClick(id: string): void;
}

const ActiveTimelineSection = ({id, header, hideOnClick}: sectionProps) => {
    // Redux 
    const { propertyId, propertyStatusId } = useSelector((state: RootState) => state.property.property_data.property_data);

    let history = useHistory();
    let isProspect = propertyStatusId == propertyCardStatusProspect

    return (
        <div>
            <SectionHeader id={id} header={header} hideOnClick={hideOnClick} titleOnClick={() => history.push(`/dashboard/property/${propertyId}/timeline`)}/>
                <S.SectionDiv onClick={(e: any) => e.stopPropagation()}>
                    <AuditHighlight
                        propertyId={propertyId}
                        showHeading={false}
                        />
                </S.SectionDiv>
        </div>
    )
}

export default ActiveTimelineSection;