import React from "react";
import { Tooltip } from "react-tippy";
import webIcon from "../../../images/icons/icon_web.svg";

import "react-tippy/dist/tippy.css";
import styles from "./CouncilInformationPopup.module.scss";
import { IOptions } from "../SelectDropdown";
import { getCouncilList } from "../../../services/CouncilService";
import { updateAlertMessage } from "../../../helpers/alertHelpers";

interface IProps {
  council: string;
}

interface IState {
  councilOfficialName: string;
  councilLinksHomePage: string;
  councilLinksTaxInformation: string;
  councilLinksLandlordInformation: string;
  councilLinksHMOInformation: string;
  councilDataList: Array<ICouncilListItem>;
}

interface ICouncilListItem {
  councilId: string;
  dateAdded: string;
  dateAmended: string;
  councilCode: string;
  region: string;
  laType: string;
  name: string;
  officialName: string;
  laD18CD: string;
  webMain: string;
  webCouncilTax: string;
  webLandlord: string;
  webHMOArticle: string;
  webHMO: string;
}

class CouncilInformationPopup extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      councilOfficialName: "",
      councilLinksHomePage: "",
      councilLinksTaxInformation: "",
      councilLinksLandlordInformation: "",
      councilLinksHMOInformation: "",
      councilDataList: []
    };

    this.getCouncilList = this.getCouncilList.bind(this);
    this.setCouncilLinks = this.setCouncilLinks.bind(this);
  }

  componentDidMount(): void {
    this.getCouncilList();
  }

  componentWillReceiveProps(newProps: IProps): void {
    if (this.props.council != newProps.council) {
      this.setCouncilLinks(newProps.council);
    }
  }

  componentWillUnmount(): void {
    updateAlertMessage("", false);
  }

  getCouncilList(): void {
    getCouncilList().then(resp => {
      if (resp.status != 200 && !resp.data) {
        updateAlertMessage("We're having trouble loading your council information. Please try again.", true);
        return;
      }
      let respArray: Array<IOptions> = new Array<IOptions>(resp.data.length);
      this.setState({
        councilDataList: resp.data
      });

      for (let i = 0; i < resp.data.length; i++) {
        let newVal: IOptions = {
          label: "",
          value: ""
        };
        newVal.label = resp.data[i].officialName
          ? resp.data[i].officialName
          : "Error";
        newVal.value = resp.data[i].councilId
          ? resp.data[i].councilId
          : "00000000-0000-0000-0000-000000000000";
        respArray[i] = newVal;
      }

      respArray.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      this.setCouncilLinks(this.props.council);
    });
  }

  setCouncilLinks(councilName: string): void {
    if (councilName == "" || councilName == null) return;
    for (let i = 0; i < this.state.councilDataList.length; i++) {
      if (this.state.councilDataList[i].officialName == councilName) {
        this.setState({
          councilOfficialName: this.state.councilDataList[i].officialName,
          councilLinksHomePage: this.state.councilDataList[i].webMain,
          councilLinksTaxInformation: this.state.councilDataList[i]
            .webCouncilTax,
          councilLinksLandlordInformation: this.state.councilDataList[i]
            .webLandlord,
          councilLinksHMOInformation: this.state.councilDataList[i].webHMO
        });
      }
    }
  }

  render() {
    return (
      <div className={styles.councilInformation}>
        <Tooltip
          theme="light"
          html={
            <div className={styles.tooltipContent}>
              <h1 className={styles.councilHeader}>
                Local Council Information
              </h1>
              <h2>{this.state.councilOfficialName}</h2>

              <span className={styles.links}>
                <p>
                  Please select the following weblinks to view the local council
                  information
                </p>
                {this.state.councilLinksHomePage && (
                  <a href={this.state.councilLinksHomePage} target="_blank">
                    Council Home Page
                  </a>
                )}

                {this.state.councilLinksTaxInformation && (
                  <a
                    href={this.state.councilLinksTaxInformation}
                    target="_blank"
                  >
                    Council Tax Information
                  </a>
                )}

                {this.state.councilLinksLandlordInformation && (
                  <a
                    href={this.state.councilLinksLandlordInformation}
                    target="_blank"
                  >
                    Landlord Information
                  </a>
                )}

                {this.state.councilLinksHMOInformation && (
                  <a
                    href={this.state.councilLinksHMOInformation}
                    target="_blank"
                  >
                    HMO Information
                  </a>
                )}
              </span>
            </div>
          }
          position="right"
          trigger="mouseenter"
          arrow
          interactive={true}
        >
          <img className={styles.infoIcon} src={webIcon} alt="Info" />
        </Tooltip>
      </div>
    );
  }
}

export default CouncilInformationPopup;
