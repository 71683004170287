import React from "react";
import ToolTip from "react-portal-tooltip";
import viewIcon from "../../../images/icons/ic_visibility_24px.svg";
import { getQuickViewContact, getQuickViewProfile, getQuickViewDocument, getQuickViewProperty, getQuickViewUser, getQuickViewEvent, getQuickViewIssue, getQuickViewValuation } from "../../../services/QuickViewService";
import FormattedAddress from "../FormattedAddress";
import { withRouter } from "react-router-dom";
import { QuickViewType, IQuickViewProfile, IQuickViewDocument, IQuickViewProperty, IQuickViewEvent, IQuickViewIssue, IQuickViewValuation } from "../../../types/QuickView";
import { getFileTypeLogo } from "../../../helpers/documentHelpers";
import DateTimeFormat from "../DateTimeFormat";
import RotatingLoader from "../RotatingLoader";
import { isTenant } from "../../../helpers/roleHelpers";
import UserContactInfo, { UserContactInfoType } from "../UserContactInfo";
import startChatIcon from "../../../images/icons/startchat_popup.svg";
import styles from "./GenericPopup.module.scss";
import InvitationStatus from "../InvitationStatus";
import { canInviteUsers, canStartChat, canAccessPropertyDetails, canAccessContactDetails } from "../../../helpers/permissionHelpers";
import { canInvite } from "../../../helpers/invitationHelpers";
import { IChatOptions, startDiscussion } from "../../../services/ChatService";
import { addChatToChatList } from "../../../helpers/chatHelpers";
import ValuationPopup from "./ValuationPopup";
import { addURLParameter } from "../../../helpers/locationHelpers";
import { toggleEditDocumentPopup } from "../../../helpers/sidebarHelpers";
import EllipsisText from "../EllipsisText";
import { toggleAddTeamMemberHelper } from "../../../actions/teamsActions";
import { getLoggedInUsersContactId } from "../../../helpers/profileHelpers";

interface IRouterProps {
  history: any;
  location: any;
  match: any;
}

export interface IOptions {
  type: QuickViewType;
  contactId?: string;
  propertyId?: string;
  tenancyId?: string;
  documentId?: string;
  issueId?: string;
  profileId?: string;
  userId?: string;
  eventId?: string;
  toggleComponent?: any;
  valuationId?: string;
}

interface IProps {
  identifier: string;
  options: IOptions;
}

interface IState {
  propertyQuickView: IQuickViewProperty;
  profileQuickView: IQuickViewProfile;
  documentQuickView: IQuickViewDocument;
  issueQuickView: IQuickViewIssue;
  eventQuickView: IQuickViewEvent;
  valuationQuickView: IQuickViewValuation;
  isTooltipActive: boolean;
  hasData: boolean;
  isLoading: boolean;
  isAddingChat: boolean;
}

type Props = IRouterProps & IProps;

class GenericPopup extends React.Component<Props, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      propertyQuickView: null,
      profileQuickView: null,
      issueQuickView: null,
      documentQuickView: null,
      eventQuickView: null,
      valuationQuickView: null,
      isTooltipActive: false,
      hasData: false,
      isLoading: false,
      isAddingChat: false
    }

    this.setTooltipActive = this.setTooltipActive.bind(this);
    this.onDownloadDocument = this.onDownloadDocument.bind(this);
    this.onPropertyClick = this.onPropertyClick.bind(this);
    this.onAddChatCallback = this.onAddChatCallback.bind(this);
    this.onOpen = this.onOpen.bind(this);
  }

  onOpen(): void {
    let query = {
      propertyId: this.state.documentQuickView.propertyId,
      contactId: this.state.documentQuickView.contactId,
      issueId: this.state.documentQuickView.issueId,
      documentId: this.state.documentQuickView.documentId,
      tenancyId: this.state.documentQuickView.tenancyId,
      unitId: this.state.documentQuickView.unitId,
      transactionId: this.state.documentQuickView.transactionId
    };

    let newParam = addURLParameter(
      this.props.location.search,
      "editDocument=" + JSON.stringify(query)
    );
    this.props.history.push({
      search: newParam
    });

    toggleEditDocumentPopup(true);
  }

  onPropertyClick(): void {
    if (!isTenant()) {
      this.props.history.push('/dashboard/property/' + this.state.propertyQuickView.propertyId);
    }
  }

  onDownloadDocument(): void {
    window.open(this.state.documentQuickView.uri);
  }

  componentWillUnmount(): void {
    this.setState({
      profileQuickView: null,
      documentQuickView: null,
      eventQuickView: null,
      issueQuickView: null,
      propertyQuickView: null
    });
  }

  setTooltipActive(isTooltipActive: boolean) {
    if (isTooltipActive) {
      if (this.props.options.type == QuickViewType.Valuation) {
        getQuickViewValuation(this.props.options.valuationId).then(resp => {
          if (resp && resp.status == 200 && resp.data) {
            this.setState({
              valuationQuickView: resp.data,
              isLoading: false
            });
          }
        }).catch(e => { })
      }

      if (this.props.options.type == QuickViewType.Issue) {
        getQuickViewIssue(this.props.options.issueId).then(resp => {
          if (resp && resp.status == 200 && resp.data) {
            this.setState({
              issueQuickView: resp.data,
              isLoading: false
            });
          }
        }).catch(e => { })
      }

      if (this.props.options.type == QuickViewType.Property) {
        getQuickViewProperty(this.props.options.propertyId).then(resp => {
          if (resp && resp.status == 200 && resp.data) {
            this.setState({
              propertyQuickView: resp.data,
              isLoading: false
            });
          }
        }).catch(e => { })
      }

      if (this.props.options.type == QuickViewType.Event) {
        getQuickViewEvent(this.props.options.eventId).then(resp => {
          if (resp && resp.status == 200 && resp.data) {
            this.setState({
              eventQuickView: resp.data,
              isLoading: false
            });
          }
        }).catch(e => { })
      }

      if (this.props.options.type == QuickViewType.User) {
        getQuickViewUser(this.props.options.userId).then(resp => {
          if (resp && resp.status == 200 && resp.data) {
            this.setState({
              profileQuickView: resp.data,
              isLoading: false
            });
          }
        }).catch(e => { })
      }

      if (this.props.options.type == QuickViewType.Contact) {
        getQuickViewContact(this.props.options.contactId).then(resp => {
          if (resp && resp.status == 200 && resp.data) {
            this.setState({
              profileQuickView: resp.data,
              isLoading: false
            });
          }
        }).catch(e => { })
      }

      if (this.props.options.type == QuickViewType.Profile) {
        getQuickViewProfile(this.props.options.profileId).then(resp => {
          if (resp && resp.status == 200 && resp.data) {
            this.setState({
              profileQuickView: resp.data,
              isLoading: false
            });
          }
        }).catch(e => { })
      }

      if (this.props.options.type == QuickViewType.Document) {
        getQuickViewDocument(this.props.options.documentId).then(resp => {
          if (resp && resp.status == 200 && resp.data) {
            this.setState({
              documentQuickView: resp.data,
              isLoading: false
            });
          }
        }).catch(e => { })
      }
    }

    this.setState({ isTooltipActive })
  }

  onAddChatCallback(): void {
    let options: IChatOptions = {
      userIds: [this.state.profileQuickView.userId]
    }

    startDiscussion(options).then(resp => {
      if (resp && resp.status == 200 && resp.data) {
        addChatToChatList(resp.data.item);
        this.props.history.push(`/dashboard/contact/${this.state.profileQuickView.contactId}/chats/${resp.data.item.chatSessionId}`)
      }
    });
  }

  render() {
    if (!this.props.options) {
      return null;
    }

    return (
      <div className={styles.tooltip}>
        {
          this.props.options.toggleComponent ?
            <span id={this.props.identifier} onMouseEnter={() => this.setTooltipActive(true)}
              onMouseLeave={() => this.setTooltipActive(false)}>{this.props.options.toggleComponent}</span> :
            <span onMouseEnter={() => this.setTooltipActive(true)} onMouseLeave={() => this.setTooltipActive(false)}>
              <img src={viewIcon} id={this.props.identifier} className={styles.viewIcon} />
            </span>
        }

        <ToolTip
          active={this.state.isTooltipActive}
          position="bottom"
          parent={"#" + this.props.identifier}
        >

          {
            !this.state.valuationQuickView && !this.state.documentQuickView && !this.state.profileQuickView && !this.state.propertyQuickView && !this.state.eventQuickView && !this.state.issueQuickView &&
            <div><RotatingLoader text='' loading={true} /></div>
          }

          {
            this.state.valuationQuickView &&
            <ValuationPopup
              valuation={this.state.valuationQuickView}
            />
          }

          {
            this.state.eventQuickView &&
            <div className={styles.tooltipBody}>
              <div className={styles.name}>
                <span className={styles.nameText}>Audit Detail</span>
              </div>
              <p>IP Address: {this.state.eventQuickView.ipAddress}</p>
              <p>Browser: {this.state.eventQuickView.browserName}</p>
              <p>Platform: {this.state.eventQuickView.browserPlatform}</p>
            </div>
          }

          {
            this.state.propertyQuickView &&
            <div className={styles.tooltipBody}>
              <div className={styles.name}>

                {
                  this.state.propertyQuickView.propertyImageUri &&
                  <div className={styles.image}>
                    <img src={this.state.propertyQuickView.propertyImageUri} alt='Property Image' />
                  </div>
                }

                <div className={styles.column}>
                  <span className={styles.nameText} onClick={() => canAccessPropertyDetails() ? this.props.history.push('/dashboard/property/' + this.state.propertyQuickView.propertyId) : null}>{this.state.propertyQuickView.propertyName ? this.state.propertyQuickView.propertyName : 'No property name set'}</span>
                  <p className={styles.link} onClick={() => canAccessPropertyDetails() ? this.props.history.push('/dashboard/property/' + this.state.propertyQuickView.propertyId) : null}><FormattedAddress inline={true} address={this.state.propertyQuickView.address} /></p>
                </div>
              </div>
              <p>
                <button className={styles.button} onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${this.state.propertyQuickView.propertyName}`, "_blank")}>Map</button>{'    '}
                <button className={styles.button} onClick={() => window.open(`https://www.google.com/maps/dir/?api=1&destination=${this.state.propertyQuickView.propertyName}`, "_blank")}>Route</button></p>
            </div>
          }

          {
            this.state.documentQuickView &&
            <div className={styles.tooltipBody}>
              <div className={styles.name}>

                <div className={styles.image}>
                  {getFileTypeLogo(this.state.documentQuickView.fileType)}
                </div>

                <div className={styles.column}>
                  <span className={styles.nameText}>
                    <EllipsisText length={25} text={this.state.documentQuickView.documentDescription || this.state.documentQuickView.documentName} /></span>
                </div>
              </div>

              <p>{this.state.documentQuickView.documentType}</p>
              <p>{this.state.documentQuickView.fileType} - {this.state.documentQuickView.size ? this.state.documentQuickView.size : "No size"}</p>

              <p>
                Created: <DateTimeFormat rawDateTime={this.state.documentQuickView.uploadedOn} showOnlyDateFormat />
              </p>

              <p>
                <div className={styles.row}>
                  <div className={styles.column}>
                    <span className={styles.blueText}><img src={viewIcon} /> <a className={styles.blueText} href={this.state.documentQuickView.uri} target="_blank">Open</a></span>
                  </div>
                  <div className={styles.column}>
                    <span onClick={this.onOpen} className={styles.blueText}>Edit</span>
                  </div>
                </div>
              </p>
            </div>
          }

          {
            this.state.issueQuickView &&
            <div className={styles.tooltipBody}>
              <div className={styles.name}>

                <div className={styles.column}>
                  <span className={styles.nameText} onClick={() => this.props.history.push('/dashboard/issue/' + this.state.issueQuickView.issueId)}>{this.state.issueQuickView.issueTitle}</span>
                </div>
              </div>

              <p>Property: {this.state.issueQuickView.propertyName}</p>
              <p>{this.state.issueQuickView.issueStatus} - {this.state.issueQuickView.issueCategory}</p>

              <p>
                Created: <DateTimeFormat rawDateTime={this.state.issueQuickView.createdOn} showOnlyDateFormat />
              </p>
            </div>
          }

          {
            this.state.profileQuickView &&
            <div className={styles.tooltipBody} onClick={() => { (getLoggedInUsersContactId() != this.state.profileQuickView.contactId) && canAccessContactDetails() && this.props.history.push(`/dashboard/contact/${this.state.profileQuickView.contactId}`)}}>

              <UserContactInfo
                disablePopup
                manualOverride={{
                  forename: this.state.profileQuickView.forename,
                  surname: this.state.profileQuickView.surname,
                  profileImage: this.state.profileQuickView.profileImageUri,
                  typeOrRole: this.state.profileQuickView.contactType,
                  isUser: this.state.profileQuickView.isUser
                }}
                type={this.state.profileQuickView.isUser ? UserContactInfoType.User : UserContactInfoType.Contact}
                id=''
              />

              <p>{this.state.profileQuickView.phoneNumber}</p>
              {
              getLoggedInUsersContactId() != this.state.profileQuickView.contactId
              ?
              <p>
                <a href={`mailto:${this.state.profileQuickView.email}`}>
                  {this.state.profileQuickView.email}
                </a>
              </p>
              : 
              ""
              }
              {
                this.state.profileQuickView.address &&
                <span>
                  <FormattedAddress
                    inline={false}
                    address={this.state.profileQuickView.address}
                    isUserAddress
                  />
                </span>
              }

              {
                <div className={styles.action}>
                  {
                    (this.state.profileQuickView.isUser && canStartChat()) ?
                      <p onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.onAddChatCallback();
                      }
                      }>Start Chat <img src={startChatIcon} /></p> :
                      <div onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}>
                        {
                            canInviteUsers() && (
                            (canInvite(this.state.profileQuickView.contactType)) ?
                            <InvitationStatus
                              toggleAddTeamMember={toggleAddTeamMemberHelper}
                              history={this.props.history}
                              contactId={this.state.profileQuickView.contactId}
                              forename={this.state.profileQuickView.forename}
                              surname={this.state.profileQuickView.surname}
                              email={this.state.profileQuickView.email}
                              contactType={this.state.profileQuickView.contactTypeId}
                              userId={this.state.profileQuickView.userId}
                            /> : 
                              <div>You cannot invite this contact</div>)
                        }
                      </div>
                  }
                </div>
              }
            </div>
          }
        </ToolTip>
      </div>
    );
  }
}

export default withRouter(GenericPopup);