export interface ILayoutConfig {
    showGetStarted: boolean;
    showProperties: boolean;
    showStreetView: boolean;
    showGoogleMap: boolean;
    showTasksSummary: boolean,
    showDocuments: boolean,
    showTimeline: boolean;
    showPriceValuationHistory: boolean;
    showRentValuationHistory: boolean;
    showPropertyYield: boolean;
    showKeyStats: boolean;
}

export const defaultLayoutConfig: ILayoutConfig = {
    showGetStarted: true,
    showProperties : true, 
    showStreetView: true,
    showGoogleMap: true,
    showTasksSummary: true,
    showDocuments: true,
    showTimeline: true,
    showPriceValuationHistory: true,
    showRentValuationHistory: true,
    showPropertyYield: true,
    showKeyStats: true
}

export interface ILayoutObject {
    i: string;
    x: number;
    y: number;
    w: number;
    h: number;
    //maxH?: number;
    //maxW?: number;
    //minH?: number;
    //minW?: number;
    isDraggable: boolean;
    isResizable: boolean;
    //isBounded?: boolean
    //moved?: boolean;
    //static?: boolean;
}

export const deduceLayout = (allLayoutItems: Array<ILayoutObject>, editable: boolean, streetViewImageHeight: number) => {
    let layout = Array<ILayoutObject>();
    let origItems = [...allLayoutItems] ;
    origItems.forEach((item) => {
        if(item.i != 'null'){
            let sectionHeight: number = item.h;
            if(item.i == 'streetView'){
                if(streetViewImageHeight > 0)
                {
                    sectionHeight = streetViewImageHeight;
                }
            }
            let newItem : ILayoutObject = 
            {
                i : item.i,
                x : item.x,
                y : item.y,
                h : sectionHeight,
                w : item.w,
                isResizable :  item.isResizable,
                isDraggable : editable
            };
            layout.push(newItem);
        }
    });
    return layout;
}

export const defaultLayoutLg = (isDraggable: boolean) =>{
    return [
        // column1
        { i: 'getStarted', x: 0, y: 0, w: 4, h: 4, isDraggable: isDraggable, isResizable: false },
        { i: 'keyStats', x: 0, y: 1, w: 4, h: 3, isDraggable: isDraggable, isResizable: false},
        { i: 'priceValuationHistory', x: 0, y: 2, w: 4, h: 4, isDraggable: isDraggable, isResizable: false},
        { i: 'tasksSummary', x: 0, y: 3, w: 4, h: 3, isDraggable: isDraggable, isResizable: false},
        
        // column2  
        { i: 'properties', x: 4, y: 0, w: 4, h: 4, isDraggable: isDraggable, isResizable: false },
        { i: 'propertyYield', x: 4, y: 1, w: 4, h: 3, isDraggable: isDraggable, isResizable: false},
        { i: 'rentValuationHistory', x: 4, y: 2, w: 4, h: 4, isDraggable: isDraggable, isResizable: false},
        { i: 'documents', x: 4, y: 3, w: 4, h: 3, isDraggable: isDraggable, isResizable: false},
        // column3
        { i: 'streetView', x: 9, y: 0, w: 4, h: 9, isDraggable: isDraggable, isResizable: false},
        { i: 'googleMap', x: 9, y: 1, w: 4, h: 9, isDraggable: isDraggable, isResizable: false},
        { i: 'timeline', x: 9, y: 2, w: 4, h: 4, isDraggable: isDraggable, isResizable: false},
    ];
}
    

export const defaultLayoutMd = (isDraggable: boolean) => {
    return [
        // column1
        { i: 'getStarted', x: 0, y: 0, w: 6, h: 4, isDraggable: isDraggable, isResizable: false },
        { i: 'keyStats', x: 0, y: 1, w: 6, h: 3, isDraggable: isDraggable, isResizable: false},
        { i: 'priceValuationHistory', x: 0, y: 2, w: 6, h: 4, isDraggable: isDraggable, isResizable: false},
        { i: 'tasksSummary', x: 0, y: 3, w: 6, h: 3, isDraggable: isDraggable, isResizable: false},
        { i: 'streetView', x: 0, y: 4, w: 6, h: 9, isDraggable: isDraggable, isResizable: false},
        
        // column2
        { i: 'properties', x: 6, y: 0, w: 6, h: 4, isDraggable: isDraggable, isResizable: false },
        { i: 'propertyYield', x: 6, y: 1, w: 6, h: 3, isDraggable: isDraggable, isResizable: false},
        { i: 'rentValuationHistory', x: 6, y: 2, w: 6, h: 4, isDraggable: isDraggable, isResizable: false},
        { i: 'documents', x: 6, y: 3, w: 6, h: 3, isDraggable: isDraggable, isResizable: false},
        { i: 'googleMap', x: 6, y: 4, w: 6, h: 9, isDraggable: isDraggable, isResizable: false},
        { i: 'timeline', x: 6, y: 5, w: 6, h: 4, isDraggable: isDraggable, isResizable: false},
    ];
}

export const defaultLayoutXs = (isDraggable: boolean) => {
    return [
        { i: 'getStarted', x: 0, y: 0, w: 12, h: 4, isDraggable: isDraggable, isResizable: false },
        { i: 'properties', x: 0, y: 1, w: 12, h: 4, isDraggable: isDraggable, isResizable: false },
        { i: 'keyStats', x: 0, y: 2, w: 12, h: 3, isDraggable: isDraggable, isResizable: false},
        { i: 'propertyYield', x: 0, y: 3, w: 12, h: 3, isDraggable: isDraggable, isResizable: false},
        { i: 'priceValuationHistory', x: 0, y: 4, w: 12, h: 4, isDraggable: isDraggable, isResizable: false},
        { i: 'rentValuationHistory', x: 0, y: 5, w: 12, h: 4, isDraggable: isDraggable, isResizable: false},
        { i: 'tasksSummary', x: 0, y: 6, w: 12, h: 3, isDraggable: isDraggable, isResizable: false},
        { i: 'documents', x: 0, y: 7, w: 12, h: 3, isDraggable: isDraggable, isResizable: false},
        { i: 'streetView', x: 0, y: 8, w: 12, h: 9, isDraggable: isDraggable, isResizable: false},
        { i: 'googleMap', x: 0, y: 9, w: 12, h: 9, isDraggable: isDraggable, isResizable: false},
        { i: 'timeline', x: 0, y: 10, w: 12, h: 4, isDraggable: isDraggable, isResizable: false},
    ];
}