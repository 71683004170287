import { IAddress, ICalculatorAddPropertyRequest, IUpdateProperty } from '../types/PropertyService/IPropertyService'
import { IData } from '../components/atoms/PropertyListItem'
import { IChartDataPointDateTime } from '../types/StatisticsService'
import { ITenancyItem, ITenancyListItem } from '../types/Tenancy'
import { IImageDto } from '../types/ImageService'

interface IPropertyState { 
    last_added_property_id: string
    editing_property_id: string
    property_data: IPropertyData
    tenancy: ITenancyData
    add_property_request: ICalculatorAddPropertyRequest;
}

interface IPropertyData {
    property_data: ICalculateReturnsData
    property_list_data: IData[] | null
}

export interface ICalculateReturnsData {
    propertyId: string
    propertyName: string
    propertyStatus: string
    propertyStatusId: string
    propertyImage: string
    address: IAddress
    propertyDetails: IPropertyDetail
    propertySummary: IPropertySummary
    propertyValuation: Array<IPropertyValuation>
    moreInformation: IMoreInformation
    chartData: ISummaryChartData
    propertyContacts: IPropertyContacts
    propertyImages: Array<IImageDto>
}

export interface IPropertyContacts {
    landlord?: string
    tenant?: string
    propertyManager?: string

    landlordName?: string
    propertyManagerName?: string
}

export interface IPropertyDetail {
    council: string
    propertyType: string
    residentialType: string
    commercialType: string
    epcRating: string
}

export interface ITenancyData {
    viewing_tenancy_detail: ITenancyItem
    tenancy_list: Array<ITenancyListItem>
    has_tenancy: boolean
}

export interface ISummaryChartData {
    propertyValuation: Array<IChartDataPointDateTime>
}

export interface IMoreInformation {
    hasViewed: boolean
    propertyValuations: Array<IPropertyValuation>
    currentValuation: number
    propertyType: string
}

export interface IPropertySummary {
    currentValuation: number
    currentRentalValuation: number
    bathrooms?: number
    bedrooms?: number
    kitchens?: number
    receptions?: number
    yearBuilt?: number
    addedBy: string
    ownedBy: string
    addedOn: string
    propertyType: string
    secondaryPropertyType: string
    tenureType: string
}

export interface IPropertyValuation {
    entryAdded: string
    dateOfTransfer: string
    price: number
}

export interface IRegulation {
    propertyId?: string
    gasAppliances?: boolean
    epcCertificate?: boolean
    epcExpiryDate?: Date
    gasCertificate?: boolean
    gasExpiryDate?: Date
    smokeDetectors?: boolean
    smartMeters?: boolean
    carbonMonoxide?: boolean
    hmoLicenceRequired?: boolean
    hmoLicence?: boolean
    hmoLicenceExpires?: Date
    legionellaAssessment?: boolean
    electricalCheck?: boolean
    electricalCheckInspection?: Date
    landlordInsuranceExpiry?: Date
    mortgageRateExpiry?: Date
    selectiveLicenseExpiry?: Date
    nextPropertyInspection?: Date
    landlordInsuranceRequired?: boolean
    propertyInspectioneRequired?: boolean
    mortgageRateUpdateRequired?: boolean
    selectiveLicenseRequired?: boolean
    gasCertificateRequired?: boolean
    epcCertificateRequired?: boolean
    electricalInspectionRequired?: boolean
}

const initialState = {
    add_property_request: null,
    tenancy: {
        viewing_tenancy_detail: null,
        tenancy_list: [],
        has_tenancy: false,
    },
    last_added_property_id: '',
    editing_property_id: '',
    property_data: {
        property_data: {
            propertyDetails: null,
            propertyContacts: null,
            propertySummary: {
                currentValuation: 0,
                currentRentalValuation: 0,
                bedrooms: 0,
                bathrooms: 0,
                kitchens: 0,
                receptions: 0,
                yearBuilt: 0,
                ownedBy: '',
                addedBy: '',
                addedOn: '',
                propertyType: '',
                secondaryPropertyType: '',
                tenureType: '',
            },
            propertyImages: [],
            propertyValuation: [],
            moreInformation: null,
            chartData: null,
            propertyId: '',
            propertyName: '',
            propertyStatus: '',
            propertyStatusId: '',
            propertyImage: '',
            address: {
                building_name: '',
                building_number: '',
                sub_building_name: '',
                sub_building_number: '',
                line1: '',
                line2: '',
                street_Name: '',
                town: '',
                county: '',
                locality: '',
                district: '',
                country: '',
                postcode: '',
            },
        },
        property_list_data: null,
    },
}

export default (
    state: IPropertyState = initialState,
    action: any
): IPropertyState => {
    switch (action.type) {
        case 'UPDATE_ADD_PROPERTY_REQUEST':
            return {
                ...state,
                add_property_request: action.data,
            }
        case 'EDIT_PROPERTY_CLEAR':
            return {
                ...state,
                property_data: null,
            }
        case 'UPDATE_PROPERTY_IMAGES':
            return {
                ...state,
                property_data: {
                    ...state.property_data,
                    property_data: {
                        ...state.property_data.property_data,
                        propertyImages: action.data,
                    },
                },
            }

        case 'ADD_PROPERTY_SUCCESS':
            return {
                ...state,
                last_added_property_id: action.data,
            }

        case 'UPDATE_CALCULATE_RETURNS_PROPERTY':
            return {
                ...state,
                property_data: {
                    property_data: action.data,
                    property_list_data: state.property_data.property_list_data,
                },
            }

        case 'UPDATE_VIEWING_PROPERTY_IMAGE':
            return {
                ...state,
                property_data: {
                    ...state.property_data,
                    property_data: {
                        ...state.property_data.property_data,
                        propertyImage: action.data,
                    },
                },
            }

        case 'UPDATE_CALCULATE_RETURNS_PROPERTY_FROM_EDIT':
            let updatedData: IUpdateProperty = action.data
            return {
                ...state,
                property_data: {
                    property_data: {
                        propertyId: updatedData.propertyId,
                        propertyName: updatedData.propertyName,
                        propertyStatus: updatedData.propertyStatusName,
                        propertyStatusId: updatedData.propertyStatus,
                        propertyImage:
                            state.property_data.property_data.propertyImage,
                        address: updatedData.address,
                        propertySummary:
                            state.property_data.property_data.propertySummary,
                        propertyDetails:
                            state.property_data.property_data.propertyDetails,
                        propertyValuation:
                            state.property_data.property_data.propertyValuation,
                        moreInformation:
                            state.property_data.property_data.moreInformation,
                        chartData: state.property_data.property_data.chartData,
                        propertyContacts:
                            state.property_data.property_data.propertyContacts,
                        propertyImages:
                            state.property_data.property_data.propertyImages,
                    },
                    property_list_data: state.property_data.property_list_data,
                },
            }

        case 'UPDATE_PROPERTY_CURRENT_VALUATION':
            return {
                ...state,
                property_data: {
                    ...state.property_data,
                    property_data: {
                        ...state.property_data.property_data,
                        propertySummary: {
                            ...state.property_data.property_data
                                .propertySummary,
                            currentValuation: action.data,
                        },
                    },
                },
            }

        case 'UPDATE_PROPERTY_CURRENT_RENTAL_VALUATION':
            return {
                ...state,
                property_data: {
                    ...state.property_data,
                    property_data: {
                        ...state.property_data.property_data,
                        propertySummary: {
                            ...state.property_data.property_data
                                .propertySummary,
                            currentRentalValuation: action.data,
                        },
                    },
                },
            }

        case 'UPDATE_PROPERTY_TYPES':
            return {
                ...state,
                property_data: {
                    ...state.property_data,
                    property_data: {
                        ...state.property_data.property_data,
                        propertyDetails: {
                            ...state.property_data.property_data
                                .propertyDetails,
                            propertyType: action.data.propertyType,
                            residentialType: action.data.residentialType,
                            commercialType: action.data.commercialType,
                        },
                    },
                },
            }

        case 'UPDATE_PROPERTY_COMPOSITION':
            return {
                ...state,
                property_data: {
                    ...state.property_data,
                    property_data: {
                        ...state.property_data.property_data,
                        propertySummary: {
                            ...state.property_data.property_data
                                .propertySummary,
                            bedrooms: action.data.bedrooms,
                            kitchens: action.data.kitchens,
                            receptions: action.data.receptions,
                            bathrooms: action.data.bathrooms,
                            propertyType: action.data.propertyType,
                            yearBuilt: action.data.yearBuilt,
                        },
                        propertyDetails: {
                            ...state.property_data.property_data
                                .propertyDetails,
                            epcRating: action.data.epcRating,
                        },
                    },
                },
            }

        case 'RESET_CALCUATE_RETURNS_PROPERTY':
            return {
                ...state,
                property_data: initialState.property_data,
            }

        case 'UPDATE_PROPERTY_LIST':
            return {
                ...state,
                property_data: {
                    property_data: state.property_data.property_data,
                    property_list_data: action.data,
                },
            }

        case 'RESET_PROPERTY_LIST':
            return {
                ...state,
                property_data: {
                    property_data: state.property_data.property_data,
                    property_list_data:
                        initialState.property_data.property_list_data,
                },
            }

        case 'UPDATE_VIEWING_TENANCY':
            return {
                ...state,
                tenancy: {
                    ...state.tenancy,
                    viewing_tenancy_detail: action.data,
                },
            }

        case 'ADD_TENANCY_LIST_ITEM':
            return {
                ...state,
                tenancy: {
                    ...state.tenancy,
                    tenancy_list: action.data,
                },
            }

        case 'UPDATE_TENANCY_LIST_ITEM':
            return {
                ...state,
                tenancy: {
                    ...state.tenancy,
                    tenancy_list: action.data,
                },
            }

        case 'UPDATE_PROPERTY_SUMMARY_TENANT_CONTACT':
            return {
                ...state,
                property_data: {
                    ...state.property_data,
                    property_data: {
                        ...state.property_data.property_data,
                        propertyContacts: {
                            ...state.property_data.property_data
                                .propertyContacts,
                            tenant: action.data,
                        },
                    },
                },
            }

        case 'TOGGLE_HAS_TENANCY_FLAG':
            return {
                ...state,
                tenancy: {
                    ...state.tenancy,
                    has_tenancy: action.data,
                },
            }

        case 'UPDATE_PROPERTY_ADDRESS':
            return {
                ...state,
                property_data: {
                    ...state.property_data,
                    property_data: {
                        ...state.property_data.property_data,
                        address: action.data,
                    },
                },
            }

        default:
            return state
    }
}
