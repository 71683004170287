import {
    Aggregate,
    Column,
    ColumnDirective,
    ColumnMenu,
    ColumnsDirective,
    CommandColumn,
    ContextMenu,
    DataResult,
    DataStateChangeEventArgs,
    ExcelExport,
    Filter,
    Grid,
    GridComponent,
    Group,
    Inject,
    Page,
    PdfExport,
    QueryCellInfoEventArgs,
    RecordClickEventArgs,
    Reorder,
    Resize,
    Sort,
    Sorts,
} from '@syncfusion/ej2-react-grids'
import {
    ChangedEventArgs,
    TextBoxComponent,
} from '@syncfusion/ej2-react-inputs'
import React from 'react'
import ReactDOM from 'react-dom'
import { connect, Provider } from 'react-redux'
import { RouteComponentProps, Router, withRouter } from 'react-router-dom'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import {
    addTeamMember,
    cancelTeamMemberInvite,
    changeTeamName,
    clearTeamMembers,
    getTeamMembers,
    removeTeamMembers,
    toggleAccess,
    toggleAddTeamMember,
    toggleEditTeamName,
} from '../../../actions/teamsActions'
import { elevioTeamsArticleId } from '../../../configuration/elevioConfig'
import { filterOptions } from '../../../configuration/grid_syncfusion_options'
import { getUserId } from '../../../helpers/authHelpers'
import { goToElevioArticle } from '../../../helpers/elevioHelpers'
import { canAdministerTeam } from '../../../helpers/permissionHelpers'
import { updateToastMessage } from '../../../helpers/toastHelper'
import history from '../../../history'
import failureIcon from '../../../images/icons/events/failure.png'
import success from '../../../images/icons/events/success.png'
import helpIcon from '../../../images/icons/help_icon.svg'
import editIcon from '../../../images/pencil_edit.png'
import { RootState } from '../../../reducers/rootReducer'
import { store } from '../../../store'
import {
    IEditTeamName,
    IInviteTeamMemberOptions,
    ITeamMembersListItemOptions,
    ITeamMembersListResponse,
} from '../../../types/Teams'
import IconButton from '../../atoms/Buttons/IconButton'
import DateTimeFormat from '../../atoms/DateTimeFormat'
import ElevioArticleEmbedWrapper from '../../atoms/ElevioArticleEmbedWrapper'
import InvitationStatus from '../../atoms/InvitationStatus'
import RotatingLoader from '../../atoms/RotatingLoader'
import { IOptions } from '../../atoms/SelectDropdown'
import SFDropdown from '../../atoms/SFDropdown'
import SFTeamDropdown from '../../atoms/SFDropdown/SFTeamDropdown'
import UserContactInfo, {
    UserContactInfoType,
} from '../../atoms/UserContactInfo'
import AddTeamMemberSidePanel from '../../molecules/AddTeamMemberSidePanel'
import styles from './TeamsPage.module.scss'
import TeamsStatusPill from './TeamsStatusPill'

const queryString = require('query-string')

interface IProps extends RouteComponentProps {
    teamMembers?: ITeamMembersListResponse
    loading?: boolean
    adding: boolean

    toggleAddTeamMember(open: boolean, options?: IInviteTeamMemberOptions): any
    cancelTeamMemberInvite(contactId: string): void
    toggleAccess(userId: string, teamId: number, active: boolean): any
    toggleEditTeamName(open): void
    changeTeamName(name): void
    clearTeamMembers(): void
    getTeamMembers?: any
    addTeamMember?: any
    removeTeamMembers?: any
    edit_team_name: IEditTeamName
}

interface IState {
    search?: string
    email?: string
    newTeamName: string
}

class TeamsPage extends React.Component<IProps, IState> {
    private grid: Grid | null
    public topRowRef: any

    constructor(props: any) {
        super(props)

        this.state = {
            search: null,
            newTeamName: null,
        }

        this.topRowRef = React.createRef()
    }

    lastLoggedIn = (x) => {
        return x.lastLoggedIn ? (
            <DateTimeFormat
                showTimeAgoFormat
                rawDateTime={x.lastLoggedIn}
                showOnlyDateFormat={false}
            />
        ) : (
            'N/A'
        )
    }

    roleName = (x) => {
        return `${x.teamRoleName}${x.isCurrentUser ? ' (You)' : ''}`
    }

    status = (x) => {
        return <TeamsStatusPill status={x.status} />
    }

    componentDidMount() {
        this.fetch()

        let query = queryString.parse(this.props.location.search).inviteRequest
        if (!query) {
            return
        }

        let object: IInviteTeamMemberOptions = {
            forename: 'Test',
            surname: 'Surname',
            email: 'testemail@email.com',
            contactType: null,
            contactId: null,
        }
        this.props.addTeamMember(true, object)
    }

    componentWillUnmount(): void {
        this.props.clearTeamMembers()
    }

    contextMenuClickedDelete = () => {
        if (this.grid) {
            let rows = this.grid.getSelectedRecords()
            if (rows.length == 0) {
                updateToastMessage('No rows have been selected.', 'warning')
                return
            }
            let userTeamIds = []
            for (let i = 0; i < rows.length; i++) {
                userTeamIds.push((rows[i] as any).userTeamId)
            }

            this.onDeleteRows(userTeamIds)
        }
    }

    onDeleteRows = (userTeamIds: number[]) => {
        this.props.removeTeamMembers(userTeamIds)
    }

    onAddMember = () => {
        this.props.toggleAddTeamMember(true)
    }

    onSelectStatus = (options: IOptions) => {}

    onRowClick = (e: RecordClickEventArgs) => {
        // This filters out clicks on any of the other items for e.g. the checkbox and action menu
        if (e.target && e.target.classList.contains('e-rowcell')) {
            //this.onEditAsset((e.rowData as IAssetDto).assetId);
        }
    }

    rowSelected = () => {
        if (this.grid) {
            let rows = this.grid.getSelectedRecords()
            //let rowValue = (rows[0] as any).assetId
        }
    }

    customCell = (args: QueryCellInfoEventArgs) => {
        if ((args.column as Column).field === 'action') {
            if (!canAdministerTeam()) {
                return null
            }
            let data = args.data as any
            ReactDOM.render(
                <Router history={history}>
                    <SFTeamDropdown
                        cancelTeamMemberInvite={
                            this.props.cancelTeamMemberInvite
                        }
                        toggleTeamMemberAccess={this.props.toggleAccess}
                        removeTeamMember={this.props.removeTeamMembers}
                        isInvited={data.status === 'invited'}
                        isActive={data.status === 'active'}
                        IsInactive={data.status === 'inactive'}
                        userTeamId={data.userTeamId}
                        userId={data.userId}
                        contactId={data.contactId}
                        teamId={data.teamId}
                    />
                </Router>,
                args.cell as Element
            )
        }
        if ((args.column as Column).field === 'userName') {
            let data = args.data as any
            ReactDOM.render(
                <Provider store={store}>
                    <Router history={history}>
                        <UserContactInfo
                            manualOverride={{
                                forename: data.forename,
                                surname: data.surname,
                                contactId: data.contactId,
                                profileImage: null,
                                typeOrRole: data.contactTypeName,
                                isUser: data.userId != null,
                            }}
                            type={UserContactInfoType.Contact}
                            id={data.contactId}
                            classNames={{
                                name: styles.name,
                                typeOrRole: styles.typeOrRole,
                                userIcon: styles.userIcon,
                            }}
                        />
                    </Router>
                </Provider>,
                args.cell as Element
            )
        }
        if ((args.column as Column).headerText === 'Chat') {
            let data = args.data as any
            if (data.userId == getUserId() || !data.userId) {
                return null
            }
            ReactDOM.render(
                <Router history={history}>
                    <InvitationStatus
                        hidePermissionMessage
                        history={this.props.history}
                        userId={data.userId}
                        disableLoader
                        contactId={data.contactId}
                        forename={data.forename}
                        surname={data.surname}
                        hideStatus={true}
                    />
                </Router>,
                args.cell as Element
            )
        }
    }

    dataStateChange = (state: DataStateChangeEventArgs) => {
        let sortQuery: string = ''
        if ((state.sorted || []).length) {
            sortQuery = state.sorted
                .map((obj: Sorts) => {
                    return obj.direction === 'descending'
                        ? `${obj.name} desc`
                        : `${obj.name} asc`
                })
                .reverse()
                .join(',')
        }

        this.fetch(
            state.action['currentPage'],
            state.take,
            state.skip,
            sortQuery
        )
        this.grid.hideSpinner()
    }

    fetch = (
        page?: number,
        size?: number,
        skip?: number,
        sortQuery?: string
    ) => {
        // Adjust the following as per filtering requirements
        var options: ITeamMembersListItemOptions = {
            page: page | 0,
            size: size | 8,
            skip: skip | 0,
        }

        if (this.state.search) {
            options.search = this.state.search
        }

        options.size = size != undefined ? size : 20

        /*         if (this.state.selectedAssetType && Number(this.state.selectedAssetType.value) > 0) {
                    options.assetTypeId = Number(this.state.selectedAssetType.value)
                } */

        options.sortQuery = sortQuery
        options.teamId = this.getTeamId()
        if (options.teamId != null) {
            this.props.getTeamMembers(options)
        }
    }

    getTeamId = (): number => {
        let state = store.getState()
        return state.user.teamId
    }

    TeamsDataResult = (): DataResult => {
        return {
            result: this.props.teamMembers.data,
            count: this.props.teamMembers.count,
        }
    }

    render() {
        if (this.props.loading && this.props.teamMembers.count === 0) {
            return (
                <div className={styles.loader}>
                    <RotatingLoader
                        loading={true}
                        text="Loading team members..."
                    />
                </div>
            )
        }

        const minWidthTablet = '768px'
        return (
            <div className={styles.page}>
                <AddTeamMemberSidePanel
                    isOpen={this.props.adding}
                    onSetOpen={(open) => this.props.toggleAddTeamMember(open)}
                />

                <div className={styles.inputContainer}>
                    {this.props.edit_team_name.isEditing ? (
                        <div>
                            <input
                                className={styles.input}
                                onChange={(e) =>
                                    this.setState({
                                        newTeamName: e.currentTarget.value,
                                    })
                                }
                                defaultValue={this.props.teamMembers.teamName}
                                value={this.state.newTeamName}
                                placeholder="Enter team name..."
                            />
                            <img
                                className={`${styles.successIcon}`}
                                src={success}
                                alt="Save"
                                onClick={() =>
                                    this.state.newTeamName
                                        ? this.props.changeTeamName(
                                              this.state.newTeamName
                                          )
                                        : this.props.toggleEditTeamName(false)
                                }
                            />
                            <img
                                className={styles.failureIcon}
                                src={failureIcon}
                                alt="Cancel"
                                onClick={() =>
                                    this.props.toggleEditTeamName(false)
                                }
                            />
                        </div>
                    ) : (
                        <div
                            className={styles.teamNameContainer}
                            onClick={() =>
                                canAdministerTeam()
                                    ? this.props.toggleEditTeamName(true)
                                    : null
                            }
                        >
                            <h1 className={styles.teamName}>
                                {this.props.teamMembers &&
                                    this.props.teamMembers.teamName}
                            </h1>
                            {canAdministerTeam() && <img src={editIcon} />}
                        </div>
                    )}
                </div>

                <div className={styles.content}>
                    {/** Add first row containg dropdowns etc */}
                    <div className={styles.row}>
                        <div className={styles.filterColumn}>
                            {/** Add search field */}
                            <div className={styles.searchInput}>
                                <TextBoxComponent
                                    showClearButton
                                    change={(args: ChangedEventArgs) =>
                                        this.setState(
                                            { search: args.value },
                                            () => this.fetch()
                                        )
                                    }
                                    placeholder="Search Team Members"
                                />
                            </div>
                        </div>

                        {/** Add dropdown */}
                        {canAdministerTeam() && (
                            <div className={styles.iconColumn}>
                                <SFDropdown
                                    loadedBeforeRender
                                    customCssClassName={
                                        styles.syncfusionDropdown
                                    }
                                    customToggle={
                                        <IconButton
                                            button={{
                                                text: 'Actions',
                                                displayType: 'action',
                                                elementType: 'button',
                                                icon: 'action',
                                            }}
                                        />
                                    }
                                    items={[
                                        {
                                            iconCss: styles.subIconDelete,
                                            text: 'Remove',
                                        },
                                        {
                                            separator: true,
                                        },
                                        {
                                            iconCss: styles.subIconExportExcel,
                                            text: 'Export to Excel',
                                        },
                                        {
                                            iconCss: styles.subIconExportCsv,
                                            text: 'Export to CSV',
                                        },
                                        {
                                            iconCss: styles.subIconExportPdf,
                                            text: 'Export to PDF',
                                        },
                                    ]}
                                    onSelect={(args) => {
                                        if (args.item.text === 'Remove') {
                                            this.contextMenuClickedDelete()
                                        }
                                        if (
                                            args.item.text === 'Export to PDF'
                                        ) {
                                            this.grid.pdfExport()
                                        }
                                        if (
                                            args.item.text === 'Export to Excel'
                                        ) {
                                            this.grid.excelExport()
                                        }
                                        if (
                                            args.item.text === 'Export to CSV'
                                        ) {
                                            this.grid.csvExport()
                                        }
                                    }}
                                />
                            </div>
                        )}

                        {this.props.teamMembers && canAdministerTeam() && (
                            <div className={`${styles.iconColumn}`}>
                                <IconButton
                                    button={{
                                        text: 'Add Member',
                                        displayType: 'submit',
                                        elementType: 'button',
                                        icon: 'add',
                                        onClick: () => this.onAddMember(),
                                    }}
                                />
                            </div>
                        )}

                        <div className={styles.iconColumn}>
                            <div
                                className={styles.icon}
                                onClick={() =>
                                    goToElevioArticle(elevioTeamsArticleId)
                                }
                            >
                                <img src={helpIcon} />
                                <span className={styles.text}>Help</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Mobile dropdown */}
                <div className={styles.mobileDropdown}>
                    {/*                   <SelectDropdown
                    showAllOption
                    //selectedId={this.state.selectedAssetType && this.state.selectedAssetType.value}
                    placeholder='Filter by status...'
                    data={}
                    onSelect={this.onSelectStatus} 
                    /> */}
                </div>

                {this.props.teamMembers.count > 0 ? (
                    <div className={`${styles.row}`}>
                        <div className={`${styles.column} ${styles.grid}`}>
                            <GridComponent
                                recordClick={this.onRowClick}
                                rowSelected={this.rowSelected}
                                ref={(g) => (this.grid = g)}
                                queryCellInfo={this.customCell}
                                //editSettings={editSettings}
                                showColumnMenu
                                id="teamMembersGrid"
                                //textWrapSettings={textWrapSettings}
                                allowTextWrap={true}
                                allowExcelExport={true}
                                allowPdfExport={true}
                                allowGrouping={true}
                                pageSettings={{ pageSize: 20, pageSizes: true }}
                                dataSource={this.TeamsDataResult()}
                                dataStateChange={this.dataStateChange.bind(
                                    this
                                )}
                                allowResizing={true}
                                allowReordering={true}
                                selectionSettings={{ checkboxOnly: true }}
                                allowPaging={true}
                                allowSorting={true}
                                filterSettings={filterOptions}
                            >
                                <Inject
                                    services={[
                                        Aggregate,
                                        ColumnMenu,
                                        ContextMenu,
                                        CommandColumn,
                                        Filter,
                                        Page,
                                        Sort,
                                        ExcelExport,
                                        PdfExport,
                                        Group,
                                        Reorder,
                                        Resize,
                                    ]}
                                />
                                <ColumnsDirective>
                                    {canAdministerTeam() && (
                                        <ColumnDirective
                                            allowSorting={false}
                                            hideAtMedia={`(min-width: ${minWidthTablet})`}
                                            textAlign="Left"
                                            type="checkbox"
                                            width="50"
                                        />
                                    )}
                                    <ColumnDirective
                                        showInColumnChooser={false}
                                        showColumnMenu={false}
                                        allowSorting={false}
                                        field="action"
                                        headerText=""
                                        textAlign="Left"
                                        width="100"
                                    />
                                    <ColumnDirective
                                        field="userName"
                                        headerText="Team Member"
                                        textAlign="Left"
                                    />
                                    <ColumnDirective
                                        hideAtMedia={`(min-width: ${minWidthTablet})`}
                                        field="teamRoleName"
                                        headerText="Role"
                                        textAlign="Left"
                                        template={this.roleName}
                                    />
                                    <ColumnDirective
                                        hideAtMedia={`(min-width: ${minWidthTablet})`}
                                        headerText="Chat"
                                        textAlign="Left"
                                    />
                                    <ColumnDirective
                                        textAlign="Left"
                                        headerText="Last Login"
                                        field="lastLoggedIn"
                                        type="date"
                                        format={{
                                            type: 'date',
                                            format: 'dd/MM/yyyy HH:mm',
                                        }}
                                        template={this.lastLoggedIn}
                                    />
                                    <ColumnDirective
                                        textAlign="Left"
                                        headerText="Created Date"
                                        field="dateCreated"
                                        type="date"
                                        format={{
                                            type: 'date',
                                            format: 'dd/MM/yyyy HH:mm',
                                        }}
                                    />
                                    <ColumnDirective
                                        width="100"
                                        textAlign="Left"
                                        headerText="Status"
                                        field="status"
                                        template={this.status}
                                    />
                                </ColumnsDirective>
                            </GridComponent>
                        </div>
                    </div>
                ) : (
                    <div className={styles.elevio}>
                        <ElevioArticleEmbedWrapper>
                            <elevio-element
                                data-type="article"
                                data-id={`${elevioTeamsArticleId}`}
                            ></elevio-element>
                        </ElevioArticleEmbedWrapper>
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    teamMembers: state.teams.data,
    loading: state.teams.loading,
    adding: state.teams.adding,
    edit_team_name: state.teams.edit_team_name,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getTeamMembers,
            addTeamMember,
            removeTeamMembers,
            toggleAddTeamMember,
            cancelTeamMemberInvite,
            toggleAccess,
            toggleEditTeamName,
            changeTeamName,
            clearTeamMembers,
        },
        dispatch
    )

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(TeamsPage)
)
