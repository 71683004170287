import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { propertyCardStatusProspect } from '../../../configuration/appConfig'
import { updateCalculateReturnsProperty } from '../../../helpers/propertyHelpers'
import { processToast } from '../../../helpers/toastHelper'
import defaultPropertyBackgroundImage from '../../../images/House_Grey.png'
import { ICalculateReturnsData } from '../../../reducers/propertyReducer'
import { updatePropertyStatus } from '../../../services/PropertyService'
import { getUnitMetadataForPropertyId } from '../../../services/UnitService'
import { IReactRouterProps } from '../../../types'
import { IDataResponse } from '../../../types/ApiService'
import { IUnitMetadata } from '../../../types/UnitService'
import IconButton from '../Buttons/IconButton'
import DeletePropertyModal from '../DeletePropertyModal'
import PropertySummaryComponent from '../PropertySummaryComponent'
import SFPropertyDropdown from '../SFDropdown/SFPropertyDropdown'
import UnitSummary from '../UnitSummary'
import styles from './PropertySummaryCard.module.scss'
import { IPropertySummaryCard } from './PropertySummaryCard.types'

type Props = IPropertySummaryCard & IReactRouterProps

const PropertySummaryCard = (props: Props) => {
    const [deletePropertyModalOpen, setDeletePropertyModal] =
        useState<boolean>(false)
    const [unitMetadata, setUnitMetadata] = useState<IUnitMetadata>(null)

    const markAsPropertyPurchased = () => {
        updatePropertyStatus(props.propertyCard.propertyId, false).then(
            (resp) => {
                if (resp && resp.status == 200 && resp.data) {
                    let response: IDataResponse<any> = resp.data
                    if (response.isSuccess) {
                        processToast(response)

                        let reduxData: ICalculateReturnsData = {
                            propertyId: props.propertyCard.propertyId,
                            propertyName: response.data.propertyName,
                            propertyStatus: response.data.propertyStatus,
                            propertyStatusId: response.data.propertyStatusId,
                            propertyImage: response.data.propertyImage,
                            address: response.data.address,
                            propertySummary: response.data.propertySummary,
                            propertyValuation: response.data.propertyValuation,
                            moreInformation: response.data.moreInformation,
                            chartData: response.data.chartData,
                            propertyDetails: response.data.propertyDetail,
                            propertyContacts: response.data.propertyContacts,
                            propertyImages: response.data.propertyImages,
                        }

                        updateCalculateReturnsProperty(reduxData)
                    }
                }
            }
        )

        props.onDelete()
    }

    const navigateToProperty = () => {
        if (!history) {
            return
        }

        props.history.push(
            `/dashboard/property/${props.propertyCard.propertyId}`
        )
    }

    const DeleteModal = () => {
        return (
            <DeletePropertyModal
                onDeleteCallBack={props.onDelete}
                propertyId={props.propertyCard.propertyId}
                propertyName={props.propertyCard.propertyName}
                propertyAddress={props.propertyCard.address}
                open={deletePropertyModalOpen}
                onOpenModal={() => setDeletePropertyModal(true)}
                onCloseModal={() => setDeletePropertyModal(false)}
            />
        )
    }

    const ImageColumn = () => {
        return (
            <div
                className={styles.image}
                onClick={
                    props.canView ? () => navigateToProperty() : () => null
                }
                style={{
                    backgroundImage: `${
                        props.propertyCard.propertyImage
                            ? 'url(' + props.propertyCard.propertyImage + ')'
                            : 'url(' + defaultPropertyBackgroundImage + ')'
                    }`,
                    backgroundSize: 'cover',
                }}
            />
        )
    }

    const DetailsColumn = () => {
        return (
            <div
                className={styles.column}
                onClick={
                    props.canView ? () => navigateToProperty() : () => null
                }
            >
                <PropertySummaryComponent
                    classNames={{
                        propertyType: styles.propertyType,
                    }}
                    data={{
                        propertyId: props.propertyCard.propertyId,
                        propertyName: props.propertyCard.propertyName,
                        propertyType: props.propertyCard.propertyTypeName,
                        yearBuilt: props.propertyCard.yearBuilt,
                        propertyStatus: props.propertyCard.propertyStatusId == propertyCardStatusProspect ? "Prospect" : "Portfolio"
                    }}
                    showShortPropertyName
                    showPropertyName
                    showPropertyType
                    showPropertyYearBuilt
                    showPropertyPopupIcon
                />
            </div>
        )
    }

    const UnitColumn = () => {
        return (
            props.propertyCard.propertyStatus == 'Portfolio' &&
            unitMetadata && (
                <div>
                    <UnitSummary
                        propertyId={props.propertyCard.propertyId}
                        showBlankState={false}
                        rentableIndicator
                        vacantIndicator
                        occupiedIndicator
                        total={unitMetadata.total}
                        rentable={unitMetadata.rentable}
                        vacant={unitMetadata.vacant}
                        occupied={unitMetadata.occupied}
                    />
                </div>
            )
        )
    }

    useEffect(() => {
        getUnitMetadataForPropertyId(props.propertyCard.propertyId).then(
            (resp) => {
                if (resp && resp.status == 200) {
                    setUnitMetadata(resp.data)
                }
            }
        )
    }, [])

    return (
        <div className={props.isDashboard ? styles.dashboard : styles.page}>
            <DeleteModal />

            {/*<Action />*/}

            {/* Main Row */}
            <div className={styles.row}>
                {/* Image */}
                <ImageColumn />

                <div className={styles.fullColumn}>
                    <div className={styles.row}>
                        {/* Details */}
                        <DetailsColumn />
                        <div className={styles.autoColumn}>
                            <SFPropertyDropdown
                                toggle={
                                    <IconButton
                                        align={'center'}
                                        iconOnly
                                        button={{
                                            displayType: 'action',
                                            elementType: 'button',
                                            icon: 'action',
                                        }}
                                    />
                                }
                                propertyType={props.propertyCard.propertyType}
                                residentialType={
                                    props.propertyCard.residentialType
                                }
                                commercialType={
                                    props.propertyCard.commercialType
                                }
                                markAsPropertyPurchased={
                                    markAsPropertyPurchased
                                }
                                isProspect={
                                    props.propertyCard.propertyStatusId ==
                                    propertyCardStatusProspect
                                }
                                onDelete={() => setDeletePropertyModal(true)}
                                propertyId={props.propertyCard.propertyId}
                                propertyName={props.propertyCard.propertyName}
                            />
                        </div>
                    </div>

                    <div className={styles.row}>
                        {/* Unit Summary */}
                        <UnitColumn />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(PropertySummaryCard)
