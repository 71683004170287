import styled from 'styled-components';

export const ChatDiv = styled.div<{height?: string}>`
    display: block;
    width: 100%;
    //height: 100%;
    //margin: 0 auto;
    //height: calc(100vh - 150.83px);
    height: ${p => p.height ? 'calc(100vh - ' + p.height + ')' : 'calc(100vh - 150.83px)'};
    padding-bottom: 5rem;
    overflow: auto;
    overflow-x: hidden;
`