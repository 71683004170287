import styled from 'styled-components';

export const ModalHeaderDiv = styled.div`
    border-bottom: 2px solid #0085ca;
    width: 100%;
    text-align: left;
    margin-bottom: 0.4rem;
    padding-bottom: 0.2rem;
    font-size: rem;
    color: #0085ca;
    font-weight: bold;
`;

export const InputWrapperDiv = styled.div`
    display: flex;
`;

export const LabelInputWrapperDiv = styled.div`
    //display: inline-block;
    margin-right: 5px;
`;

export const LabelDiv = styled.div`
    line-height: 0.7rem;
    font-size: 0.7rem;
    color: $colour-shade-2;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.05rem;
    margin-bottom: 0.25rem;
    display: block;
    width: 100%;
`;


