import React, { useRef } from 'react';
import { Tooltip } from "react-tippy";
import regIcon from "../../../../images/icons/icon_reg.svg";
import { CheckStatusPill, DateStatusPill } from '../../../atoms/StatusPill/StatusPill.component';
import * as S from './KeyDatesSummarySection.styles';

interface sectionProps {
    title?: string;
    expiryDate?: Date;
    tooltip?: any;
    checkItem?: boolean;
    checkCompleted?: boolean;
    onClick?(): void;
}

const KeyDatesSummarySection = ({title, expiryDate, tooltip, checkItem, checkCompleted, onClick}: sectionProps) => {
    const navDivRef = useRef<HTMLDivElement>();

    return (
        <>
            <S.ContainerDiv ref={navDivRef} >
                <S.InnerContainerDiv>
                    <S.MainAreaDiv>
                        <S.HeaderDiv>
                            <S.TitleDiv>{title}</S.TitleDiv>
                            {
                                tooltip 
                                ?
                                <S.TooltipDiv>
                                    <Tooltip
                                        theme="light"
                                        html={tooltip}
                                        position="right"
                                        trigger="mouseenter"
                                        arrow
                                        interactive={true}
                                    >
                                        <img src={regIcon} alt="Info" />
                                    </Tooltip>
                                </S.TooltipDiv>
                                :
                                ""
                            }
                        </S.HeaderDiv>
                        <S.StatusWrapperDiv onClick={onClick}>
                            <S.StatusDiv>
                                {
                                    !checkItem
                                    ?
                                    <DateStatusPill date={expiryDate} />
                                    :
                                    <CheckStatusPill checkCompleted={checkCompleted} />
                                }
                            </S.StatusDiv>
                        </S.StatusWrapperDiv>
                    </S.MainAreaDiv>
                </S.InnerContainerDiv>  
            </S.ContainerDiv>
        </>
    )
}

export default KeyDatesSummarySection;