import React from 'react'
import { Row, Col } from 'reactstrap'
import Modal from 'react-responsive-modal'
import GenericButton from '../GenericButton'
import SuccessTick from '../SuccessTick'
import RotatingLoader from '../RotatingLoader'
import { toggleDeleteInspectionPopup } from '../../../helpers/sidebarHelpers'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { IReactRouterProps } from '../../../types'
import { removeURLParameter } from '../../../helpers/locationHelpers'
import { deleteTransactionItem } from '../../../helpers/transactionHelpers'
import { processToast, updateToastMessage } from '../../../helpers/toastHelper'
import { IDataResponse } from '../../../types/ApiService'
import styles from './DeleteInspectionModal.module.scss'
import { deleteInspection } from '../../../services/inspectionService'
import Buttons from '../Buttons'
import { goToRoute } from '../../../history'
const queryString = require('query-string')

interface IProps {
    inspection_delete_popup: boolean
}

type Props = IProps & IReactRouterProps

interface IState {
    loading: boolean
    success: boolean

    inspectionId: number[]
    redirectUrl: string
}

export interface IDeleteRequest {
    deleteAssociatedDocument: boolean
}

export interface IDeleteTransactionOptions {
    inspectionId: number
}

class DeleteInspectionModal extends React.Component<Props, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            loading: false,
            success: false,
            inspectionId: [],
            redirectUrl: null,
        }
    }

    componentDidMount(): void {
        let options = queryString.parse(
            this.props.location.search
        ).deleteInspection

        if (!options) {
            return
        }

        let redirectUrl = queryString.parse(
            this.props.location.search
        ).deleteInspectionRedirectUrl

        let option = JSON.parse(options)

        this.setState({
            inspectionId: option,
            redirectUrl,
        })

        let removeQuery = removeURLParameter(
            this.props.location.search,
            'deleteInspection'
        )
        this.props.history.push({
            search: removeQuery,
        })

        let removeQuery1 = removeURLParameter(
            this.props.location.search,
            'deleteInspectionRedirectUrl'
        )
        this.props.history.push({
            search: removeQuery1,
        })
    }

    onDelete(inspectionId: number[]): void {
        this.setState({
            loading: true,
        })

        deleteInspection(inspectionId).then((resp) => {
            let response: IDataResponse<boolean> = resp.data
            if (resp.status == 200) {
                processToast(response)
            }

            this.setState({
                success: false,
                loading: false,
            })

            if (response.isSuccess) {
                if (this.state.redirectUrl) {
                    goToRoute(this.state.redirectUrl)
                    toggleDeleteInspectionPopup(false)
                    return
                }

                this.props.history.push({
                    search: '?refreshInspectionsGrid=true',
                })
            }

            toggleDeleteInspectionPopup(false)
        })
    }

    render() {
        return (
            <Modal
                classNames={{ modal: styles.modal }}
                open={this.props.inspection_delete_popup}
                onClose={() => toggleDeleteInspectionPopup(false)}
                center
            >
                <div className={styles.body}>
                    {this.state.loading ? (
                        <div>
                            <RotatingLoader
                                loading={true}
                                text={`Deleting inspection...`}
                            />
                        </div>
                    ) : this.state.success ? (
                        <div>
                            <SuccessTick />
                            <p className={styles.modalBody}>
                                Inspection deleted
                            </p>
                        </div>
                    ) : (
                        <div>
                            <p className={styles.modelHeader}>
                                DELETE INSPECTION
                            </p>

                            <p className={styles.modalBody}>
                                <div>You are about to delete:</div>
                                <div className={styles.records}>
                                    {this.state.inspectionId.length} Inspection
                                    {this.state.inspectionId.length == 1
                                        ? ''
                                        : 's'}
                                </div>
                                <div>Do you wish to go ahead and delete?</div>
                            </p>

                            <Row>
                                <Col className={styles.buttonDiv}>
                                    <Buttons
                                        buttons={[
                                            {
                                                displayType: 'cancel',
                                                elementType: 'button',
                                                onClick: () =>
                                                    toggleDeleteInspectionPopup(
                                                        false
                                                    ),
                                            },
                                            {
                                                text: 'Delete',
                                                displayType: 'submit',
                                                elementType: 'button',
                                                onClick: () =>
                                                    this.onDelete(
                                                        this.state.inspectionId
                                                    ),
                                            },
                                        ]}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state: any) => ({
    inspection_delete_popup: state.app.popups.inspection_delete_popup,
})

export default withRouter(
    connect(mapStateToProps)(DeleteInspectionModal) as any
)
