import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import IssueListPage from "../../../IssueListPage";
import { IRaiseIssueOptions } from "../../../../atoms/AddEditIssue";
import { addURLParameter } from "../../../../../helpers/locationHelpers";
import styles from "./PropertyIssuesPage.module.scss";
import IssueList from "../../../IssueListPage/IssueList";

interface IProps {
  history: any;
  match: any;
  location: any;

  propertyId: string;
  propertyName: string;
}


class PropertyIssuesPage extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.state = {

    };

    this.onAddButtonClick = this.onAddButtonClick.bind(this);
  }

  onAddButtonClick(): void {
    let options: IRaiseIssueOptions = {
      propertyId: this.props.propertyId,
      propertyName: this.props.propertyName,
      isOnPropertyPage: true
    }

    let query = "issueOptions=" + JSON.stringify(options);
    let queryParam = addURLParameter(this.props.location.search, query);

    this.props.history.push({
      search: queryParam
    });
  }

  componentDidMount(): void {
  }

  render() {
    return (
      <div>
        <IssueListPage isNested onAddIssue={this.onAddButtonClick} propertyId={this.props.match.params.propertyId} />
      </div>
    );
  }
}

export default withRouter((PropertyIssuesPage));