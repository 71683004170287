import React from "react";
import { connect } from "react-redux";
import { hasClientArea } from "../../../helpers/clientHelpers";
import styles from "./ClientAreaRestriction.module.scss";

interface IProps {
  children: JSX.Element;
  restriction: string;
}

const ClientAreaRestriction = (props: IProps) => {
  if (!hasClientArea(props.restriction)) {
    return null;
  }

  return props.children;
}

const mapStateToProps = (state: any) => ({
  client_areas: state.client.clientSystemAreas
});

export default connect(mapStateToProps)(ClientAreaRestriction);