import React, { Component } from "react";
import styles from "./ChatListItem.module.scss";
import { withRouter } from 'react-router-dom';
import { IMessageListItem } from "../../../../services/ChatService";
import ProfileImage from "../../../atoms/ProfileImage";
import EllipsisText from "../../../atoms/EllipsisText";
import DateTimeFormat from "../../../atoms/DateTimeFormat";
import SFChatDropdown from "../../../atoms/SFDropdown/SFChatDropdown";
import DeleteChatModal from "../../../atoms/DeleteChatModal";
import UnreadChatCount from "../../../atoms/UnreadChatCount";
import { canDeleteChat } from "../../../../helpers/permissionHelpers";

interface IProps {
  history: any;
  match: any;
  location: any;

  item: IMessageListItem;
  isActive: boolean;
  onClick(): void;
}

interface IState {
  isDeleting: boolean;
}

export interface ITenancyOptions { }

class ChatListItem extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isDeleting: false
    };

    this.onDeleteCallback = this.onDeleteCallback.bind(this);
  }

  onDeleteCallback(): void {
    this.setState({
      isDeleting: false
    });
  }

  render() {
    return (
      <li className={`${this.props.isActive ? styles.chatListItemActive : styles.chatListItem}`} onClick={() => this.props.onClick()}>
        <DeleteChatModal
          chatSessionId={this.props.item.chatSessionId}
          open={this.state.isDeleting}
          onOpenModal={() => this.setState({ isDeleting: true })}
          onCloseModal={() => this.setState({ isDeleting: false })}
          onDeleteCallback={() => this.onDeleteCallback()} />

        <div className={styles.row}>
          <div className={styles.avatar}>
            <ProfileImage large={false} forename={this.props.item.lastMessageSenderForename} surname={this.props.item.lastMessageSenderSurname} image={this.props.item.lastMessageSenderProfileImageUri} />
          </div>

          <div className={styles.message}>
            <p className={styles.header}><EllipsisText text={this.props.item.title} length={50} /> <UnreadChatCount unreadChatCount={this.props.item.unreadMessageCount} /></p>
            <p className={styles.message}><EllipsisText text={this.props.item.lastMessage ? this.props.item.lastMessage : ""} length={40} />
            </p>
          </div>

          <div className={styles.actions}>
            <p className={styles.dateTime}><DateTimeFormat 
            rawDateTime={this.props.item.lastMessageDateTime} 
            isChat
            showTimeAgoFormat
            showOnlyDateFormat
            showOnlyShortDate
            showYesterdayText /></p>
            {
              canDeleteChat() 
              ?
              <p>
                <SFChatDropdown 
                  chatSessionId={this.props.item.chatSessionId} 
                  onDelete={() => this.setState({ isDeleting: true })} 
                />
              </p>
              :
              ""
            }
            
          </div>
        </div>
      </li>
    );
  }
}

export default (withRouter(ChatListItem));