import React from "react";
import { store } from "../store";
import { IDocument } from "../types/DocumentService";

interface IFileType {
  key: string;
  value: string;
}

export const generateDocumentLinkText = (property, contact, issue, transaction, unit,) => {
  if (property || contact || issue || transaction) {
    let result = 'Linked to ';
    let hasChanged = false;
    if (property && property != undefined && property != "" && property != " ") {
      hasChanged = true;
      result += `${property}${hasChanged ? ', ' : ''}`
    }
    if (unit && unit != undefined && unit != "" && unit != " ") {
      hasChanged = true;
      result += `${unit}${hasChanged ? ', ' : ''}`
    }
    if (contact && contact != undefined && contact != "" && contact != " ") {
      hasChanged = true;
      result += `${contact}${hasChanged ? ', ' : ''}`
    }
    if (issue && issue != undefined && issue != "" && issue != " ") {
      hasChanged = true;
      result += `${issue}${hasChanged ? ', ' : ''}`
    }
    if (transaction && transaction != undefined && transaction != "" && transaction != " ") {
      hasChanged = true;
      result += `${transaction}`
    }

    return result;
  }

  return 'Not linked ';
}

const fileLocation =
  "https://rentchiefstorageuk.blob.core.windows.net/images/filetype-images/";

const fileTypes: Array<IFileType> = [
  { key: "after-effects", value: "after-effects.svg" },
  { key: "ai", value: "ai.svg" },
  { key: "audition", value: "audition.svg" },
  { key: "avi", value: "avi.svg" },
  { key: "bridge", value: "bridge.svg" },
  { key: "css", value: "css.svg" },
  { key: "csv", value: "csv.svg" },
  { key: "dbf", value: "dbf.svg" },
  { key: "doc", value: "doc.svg" },
  { key: "dreamweaver", value: "dreamweaver.svg" },
  { key: "dwg", value: "dwg.svg" },
  { key: "exe", value: "exe.svg" },
  { key: "file", value: "file.svg" },
  { key: "dwg", value: "dwg.svg" },
  { key: "fireworks", value: "fireworks.svg" },
  { key: "fla", value: "fla.svg" },
  { key: "flash", value: "flash.svg" },
  { key: "html", value: "html.svg" },
  { key: "illustrator", value: "illustrator.svg" },
  { key: "indesign", value: "indesign.svg" },
  { key: "iso", value: "iso.svg" },
  { key: "javascript", value: "javascript.svg" },
  { key: "jpg", value: "jpg.svg" },
  { key: "jpeg", value: "jpg.svg" },
  { key: "json", value: "json.svg" },
  { key: "mp3", value: "mp3.svg" },
  { key: "mp4", value: "mp4.svg" },
  { key: "pdf", value: "pdf.svg" },
  { key: "photoshop", value: "photoshop.svg" },
  { key: "png", value: "png.svg" },
  { key: "ppt", value: "ppt.svg" },
  { key: "prelude", value: "prelude.svg" },
  { key: "premiere", value: "premiere.svg" },
  { key: "psd", value: "psd.svg" },
  { key: "rtf", value: "rtf.svg" },
  { key: "search", value: "search.svg" },
  { key: "svg", value: "svg.svg" },
  { key: "txt", value: "txt.svg" },
  { key: "xls", value: "xls.svg" },
  { key: "xml", value: "xml.svg" },
  { key: "zip", value: "zip.svg" }
];

export function updateDocumentsList(documents: Array<IDocument>) {
  store.dispatch({ type: "UPDATE_DOCUMENTS", data: documents });
}

export function addDocument(document: IDocument) {
  let state = store.getState();
  let currentDocuments = state.document.document_list;
  let copy = currentDocuments.map((object: IDocument) => ({ ...object }));
  let newList = [document].concat(copy);
  updateDocumentList(newList);
}

export function updateDocumentList(documents: Array<IDocument>) {
  store.dispatch({ type: "UPDATE_DOCUMENTS_LIST", data: documents });
}

export function getRemovedFileTypeName(fileName: string): string {
  let split = fileName.split(".");
  split = split.slice(0, 1);

  return split.toString();
}

export function getFileExtention(fileName: string): string {
  let split = fileName.substr(fileName.lastIndexOf('.') + 1);
  return split.toString();
}

export function getFileTypeLogo(fileName: string) {
  fileName = fileName.toLowerCase();
  return <img src={getFileTypeLogoUrl(fileName)} alt="File Type Logo" />;
}

export function getFileTypeLogoUrl(fileName: string) {
  let fileType = fileName.split(".");
  let found;

  if (fileType.length === 1) {
    found = fileTypes.find(x => x.key.toLowerCase() == fileName.toLowerCase());
  } else {
    found = fileTypes.find(x => x.key == fileName.split(".").pop());
  }

  if (found === undefined || found === null) {
    found = fileTypes.find(x => x.key == "file");
  }

  return `${fileLocation}${found.value}`;
}

export function removeDocumentById(documentId: string) {
  let state = store.getState();
  let currentDocuments = state.document.document_list;
  currentDocuments = currentDocuments.filter(doc => doc.documentId !== documentId)
  updateDocumentList(currentDocuments);
}

export function removeDocument(document: IDocument) {
  let state = store.getState();
  let currentDocuments = state.document.document_list;
  currentDocuments = currentDocuments.filter(doc => doc.documentId !== document.documentId)
  updateDocumentList(currentDocuments);
}

export function docIsImage(docName: string){
  /* return docName.match(/\.(jpg|jpeg|png|gif|svg|TIFF|RAW)$/i); */
  var extension = docName.split('.').pop().toLowerCase();
  return (extension === "jpg" 
          || extension === "jpeg"
          || extension === "jfif"
          || extension === "png"
          || extension === "gif"
          || extension === "TIFF"
          || extension === "RAW"
          || extension === "bmp"
          || extension === "svg"
          || extension === "exif"
          || extension === "ppm"
          || extension === "pgm"
          || extension === "pbm"
          || extension === "pnm"
          );
}