import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import { withRouter } from 'react-router-dom'
import Sidebar from 'react-sidebar'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import { sidebarStyling } from '../../../global/variables/_variables'
import {
    toggleAddPropertyFormChanged,
    toggleAddTenancyFormChanged,
} from '../../../helpers/formStateHelpers'
import Buttons from '../../atoms/Buttons'
import AddEditPropertyForm from '../AddEditPropertyForm'
import styles from './AddPropertySidePanel.module.scss'

const queryString = require('query-string')

interface IProps {
    isOpen?: boolean
    add_property_form_changed?: boolean
    add_tenancy_form_changed?: boolean
    onSetOpen?(open: boolean): void
}

interface IRouterProps {
    history: any
    location: any
    match: any
}

interface IState {
    sidebarOpen: boolean
    outstandingChangesModalOpen: boolean
    hasOutstandingChanges: boolean
}

type Props = IProps & IRouterProps

class AddPropertySidePanel extends Component<Props, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            sidebarOpen: false,
            outstandingChangesModalOpen: false,
            hasOutstandingChanges: false,
        }

        this.onSetOpenInternal = this.onSetOpenInternal.bind(this)
    }

    onSetOpenInternal(open: boolean): void {
        if (!open) {
            // Check if there is outstanding changes
            if (
                this.props.add_property_form_changed ||
                this.props.add_tenancy_form_changed
            ) {
                this.setState({
                    outstandingChangesModalOpen: true,
                })

                return
            }
        }

        this.props.onSetOpen(open)
    }

    onCloseChangesModal(closeSidebar: boolean): void {
        this.setState({
            outstandingChangesModalOpen: false,
            sidebarOpen: closeSidebar,
        })

        if (closeSidebar) {
            toggleAddPropertyFormChanged(false)
            toggleAddTenancyFormChanged(false)
            this.props.onSetOpen(false)
        }
    }

    render() {
        return (
            <div>
                <Modal
                    classNames={{ modal: styles.modal }}
                    open={this.state.outstandingChangesModalOpen}
                    onClose={() => this.onCloseChangesModal(false)}
                    center
                >
                    <p className={styles.modalHeader}>Outstanding changes</p>
                    <p className={styles.modalBody}>
                        Any updated information will be lost, do you wish to
                        continue?
                    </p>

                    <Buttons
                        buttons={[
                            {
                                text: 'No',
                                displayType: 'cancel',
                                elementType: 'button',
                                onClick: () => this.onCloseChangesModal(false),
                            },
                            {
                                text: 'Yes',
                                displayType: 'submit',
                                elementType: 'button',
                                onClick: () => this.onCloseChangesModal(true),
                            },
                        ]}
                    />
                </Modal>

                <Sidebar
                    touch={false}
                    sidebarClassName={styles.sidebar}
                    sidebar={
                        this.props.isOpen ? (
                            <AddEditPropertyForm />
                        ) : (
                            <div></div>
                        )
                    }
                    open={this.props.isOpen}
                    onSetOpen={this.onSetOpenInternal}
                    styles={sidebarStyling}
                >
                    <div />
                </Sidebar>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    add_property_form_changed: state.app.form_states.add_property_form_changed,
    add_tenancy_form_changed: state.app.form_states.add_tenancy_form_changed,
})

export default withRouter<Props,any>(connect(mapStateToProps)(AddPropertySidePanel))
