import { Dispatch } from 'redux';
import { getIssue } from '../services/IssueService';
import { store } from '../store';
import { IDataResponse } from '../types/ApiService';
import { IIssueItem, IssueAction, IssueActionType } from '../types/IssueService';

export const updateViewingIssue = (issue: IIssueItem) => {
    return (dispatch: Dispatch<IssueAction>) => {
        let viewingIssue = store.getState().issue.viewing_issue_edit;
        if (viewingIssue.issueId == issue.issueId) {
            dispatch({ type: IssueActionType.UPDATE_VIEWING_ISSUE, payload: issue });
        }
    }
}

export const clearIssueEdit = () => {
    return (dispatch: Dispatch<IssueAction>) => {
        dispatch({ type: IssueActionType.CLEAR_ISSUE_EDIT });
    }
}

export const getIssueEdit = (issueId: string) => {
    return async (dispatch: Dispatch<IssueAction>) => {
        dispatch({ type: IssueActionType.GET_ISSUE_EDIT });
        try {
            const { data } = await getIssue(issueId);
            if (data) {
                let response: IDataResponse<IIssueItem> = data;
                if (response.isSuccess) {
                    return dispatch({
                        type: IssueActionType.GET_ISSUE_EDIT_SUCCESS,
                        payload: response.data
                    });
                }
                dispatch({
                    type: IssueActionType.GET_ISSUE_EDIT_ERROR,
                    payload: response.errorMessage
                });
            }
        } catch (err) {
            dispatch({
                type: IssueActionType.GET_ISSUE_EDIT_ERROR,
                payload: err.message
            });
        }
    }
}