import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getTenancy } from "../services/TenancyService";
import { updateViewingTenancy } from "../helpers/propertyHelpers";
import { IAddTenancyOptions } from "../helpers/locationHelpers";
const queryString = require("query-string");

export const useFetchTenancyData = () => {
    const history = useHistory();
    const location = useLocation();
    let { tenancyId } = useParams<any>();

    const [dataRetrieved, setDataRetrieved] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async() => {
        if (!tenancyId) {
            if(location.pathname = '/dashboard/tenancy/add'){
            let options = queryString.parse(location.search).addTenancyOptions;
            if(options){
                let obj: IAddTenancyOptions = JSON.parse(options);
                if(obj && obj.cloneTenancyId){
                tenancyId = obj.cloneTenancyId;
                }
            }
        }
        }
        setDataRetrieved(false);

        let resp = await getTenancy(tenancyId);
        if (resp && resp.status == 200 && resp.data) {
            setData(resp.data);
            setDataRetrieved(true);
            updateViewingTenancy(resp.data);
        }
    }

    return { dataRetrieved, data };
}