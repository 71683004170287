import React from "react";
import { Alert } from "reactstrap";
import Modal from "react-responsive-modal";
import styles from "./UploadPropertyImage.module.scss";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { setPrimaryPropertyImage } from "../../../services/ImageService";
import PropertyImageDropzone from "../PropertyImageDropzone";
import RotatingLoader from "../RotatingLoader";

interface IProps {
  propertyId: string;
  open: boolean;
  onClose(): void;
  onSuccess(uri: string): void;
  onUploadCallback(uri: string): void;
}

interface IState {
  uri: string;
  loading: boolean;
  success: boolean;
}

class UploadPropertyImage extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      uri: "",
      loading: false,
      success: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.onLoading = this.onLoading.bind(this);
    this.setImage = this.setImage.bind(this);
  }

  setImage(uri: string): void {
    this.props.onSuccess(uri);
  }

  handleChange(event: React.FormEvent<HTMLInputElement>) {
    const field = event.currentTarget.name;
    const value: string = event.currentTarget.value;

    this.setState({ [field]: value } as Pick<IState, any>);
  }

  onLoading(loading: boolean): void {
    this.setState({
      loading
    });
  }

  render() {

    return (
      <div className={styles.uploadPropertyImageModal}>
        <Modal
          modalId={styles.modal}
          open={this.props.open}
          onClose={this.props.onClose}
          center
        >
          <Alert className={styles.alert} color="info">
            <h5>Upload Property Image</h5>
            <hr />

            This functionality is current disabled.
          </Alert>
        </Modal>
      </div>
    );
  }
}

export default UploadPropertyImage;