import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import styles from "./PropertyTenancy.module.scss";
import GenericButton from "../../../../atoms/GenericButton";
import addIcon from "../../../../../images/icons/addbutton.svg";
import {
  updateViewingTenancy,
  updateTenancyList
} from "../../../../../helpers/propertyHelpers";
import { ITenancyItem } from "../../../../../types/Tenancy";
import {
  getTenantsForPropertyId,
  getTenancy
} from "../../../../../services/TenancyService";
import { IOptions } from "../../../../atoms/SelectDropdown";
import RotatingLoader from "../../../../atoms/RotatingLoader";
import TenancyGrid from "../../../TenancyPage/TenancyGrid";
import { PropertyDefaultTemplate } from "../../../TenancyPage/TenancyGrid/_templates/propertyDefaultTemplate copy";
import { onAddTenancyClick } from "../../../../atoms/SFDropdown/SFPropertyDropdown/_helpers";

interface IProps {
  history: any;
  match: any;
  location: any;
  postcode: string;
  viewing_tenancy: ITenancyItem;
  viewing_property: any;
}

interface IState {
  noResults: boolean;
  showCurrent: boolean;
  currentTenants: Array<IOptions>;
  selectedTenancyId: string;
  loading: boolean;
}

class PropertyTenancy extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      noResults: true,
      showCurrent: false,
      currentTenants: [],
      selectedTenancyId: "",
      loading: false
    };

    this.onRadioChange = this.onRadioChange.bind(this);
    this.getCurrentTenantsDropdown = this.getCurrentTenantsDropdown.bind(this);
  }

  componentDidMount(): void {
    this.getCurrentTenantsDropdown();
  }

  onSelectCurrentTenant(selectedOption: IOptions): void {
    this.setState(
      {
        selectedTenancyId: selectedOption.value
      },
      () => {
        getTenancy(this.state.selectedTenancyId).then(resp => {
          updateViewingTenancy(resp.data);
        });
      }
    );
  }

  componentWillUnmount(): void {
    updateViewingTenancy(null);
    updateTenancyList([]);
  }

  getCurrentTenantsDropdown(): void {
    this.setState({
      loading: true
    });
    getTenantsForPropertyId(this.props.match.params.propertyId)
      .then(resp => {
        if (resp.data) {
          this.setState(
            {
              currentTenants: resp.data,
              selectedTenancyId: resp.data[0].value
            },
            () => {
              if (!this.props.viewing_tenancy) {
                getTenancy(this.state.selectedTenancyId).then(resp => {
                  if (resp && resp.data) {
                    updateViewingTenancy(resp.data);
                  }
                });
              }
            }
          );
        }
        this.setState({
          loading: false
        });
      })
      .catch(() => {
        this.setState({
          loading: false
        });
      });
  }

  onRadioChange(): void {
    this.setState(
      {
        showCurrent: !this.state.showCurrent
      },
      () => {
        if (this.state.showCurrent) {
          this.setState(
            {
              currentTenants: [],
              selectedTenancyId: ""
            },
            () => {
              updateViewingTenancy(null);
              this.getCurrentTenantsDropdown();
            }
          );
        }
      }
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          <RotatingLoader text="Loading Tenancy..." loading={true} />
        </div>
      );
    }

    return (
      <div className={styles.page}>
        {this.props.viewing_tenancy ? (
          <div>
              <TenancyGrid config={{
                template: PropertyDefaultTemplate(this.props),
                addSettings: {
                  propertyId: this.props.match.params.propertyId,
                  propertyName: this.props.viewing_tenancy.propertyName,
                  tenancyId: this.props.viewing_tenancy.tenancyId,
                },
                dataFilterSettings: {
                  propertyId: this.props.match.params.propertyId
                }
              }} />
          </div>
        ) : (
            <div className={styles.noTenancies}>
              <p>No tenancies have been added for this property.</p>
              <p>
                <GenericButton onClick={() => onAddTenancyClick(this.props.match.params.propertyId, this.props.viewing_property.propertyName, this.props.location.search)}>
                  <img src={addIcon} /> <span>Add Tenancy</span>
                </GenericButton>
              </p>
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  viewing_tenancy: state.property.tenancy.viewing_tenancy_detail,
  viewing_property: state.property.property_data.property_data
});

export default withRouter(connect(mapStateToProps)(PropertyTenancy) as any);
