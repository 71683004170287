import React from "react";
import styles from "./TransactionStatusPill.module.scss";

interface IProps {
  isPaid: boolean;
  isOpen: boolean;
  isOverdue: boolean;
  daysOver?: number;
}

const TransactionStatusPill = ({isPaid, isOpen, isOverdue, daysOver}: IProps) => {
  return (
    <div className={styles.paidOpenOverdueStatus}>
      {isPaid && (
        <div className={`${styles.status} ${styles.paid}`}>Paid</div>
      )}
      {isOpen && (
        <div className={`${styles.status} ${styles.open}`}>Unpaid</div>
      )}
      {isOverdue && (
        <div className={`${styles.status} ${styles.overdue}`}>
          {daysOver} Days
        </div>
      )}
    </div>
  );
}

export default TransactionStatusPill;