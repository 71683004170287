import { RCCurrencyInput } from '@rentchief/components'
import { AccordionComponent } from '@syncfusion/ej2-react-navigations'
import { ErrorMessage, Form, Formik } from 'formik'
import moment from 'moment'
import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { withRouter } from 'react-router-dom'
import { getFileTypeLogo } from '../../../helpers/documentHelpers'
import { toggleAddTransactionFormChanged } from '../../../helpers/formStateHelpers'
import { removeURLParameter } from '../../../helpers/locationHelpers'
import {
    getLoggedInUsersContactId,
    getLoggedInUsersForename,
    getLoggedInUsersSurname,
} from '../../../helpers/profileHelpers'
import { toggleTransactionPopup } from '../../../helpers/sidebarHelpers'
import { updateToastMessage } from '../../../helpers/toastHelper'
import {
    addToTransactionList,
    updateTransactionItem,
} from '../../../helpers/transactionHelpers'
import {
    getContactFromPropertyId,
    getLandlordContactFromPropertyId,
} from '../../../services/ContactService'
import { getAssociatedDocuments } from '../../../services/DocumentService'
import {
    getLatestTenantForPropertyId,
    getTenancyFromContactId,
} from '../../../services/TenancyService'
import {
    addTransaction,
    getAccounts,
    getTransaction,
    updateTransaction,
} from '../../../services/TransactionService'
import { doesPropertyHaveUnits } from '../../../services/UnitService'
import { ISearchResult } from '../../../types/SearchService/ISearchService'
import {
    IAddTransactionRequest,
    ITransactionItem,
} from '../../../types/Transaction'
import { AddTransaction } from '../../../YupValidationSchemas'
import { IAddTenantCallback } from '../../molecules/AddContactForm'
import { IDocumentUpload } from '../../molecules/AddDocumentModal'
import { IAddEditTransactionOptions } from '../AddEditTransactionModal'
import { IAssociatedDocumentsResponse } from '../AssociatedDocuments/AssociatedDocuments.types'
import Buttons from '../Buttons'
import CloseButton from '../CloseButton'
import CurrencyInput from '../CurrencyInput'
import FileUploadDropzone from '../FileUploadDropzone'
import RotatingLoader from '../RotatingLoader'
import SearchContactInput from '../SearchContactInput'
import SearchPropertyInput from '../SearchPropertyInput'
import SearchUnitInput from '../SearchUnitInput'
import SelectDropdown, { IOptions } from '../SelectDropdown'
import styles from './AddEditTransaction.module.scss'
const queryString = require('query-string')

export enum ITransactionType {
    income,
    expense,
}

interface IProps {
    history: any
    location: any
    match: any
    type: ITransactionType
    options?: IAddEditTransactionOptions
}

interface IState {
    fieldToChange?: string

    loading: boolean
    success: boolean
    isEditing: boolean
    selectedPropertyHasUnits: boolean

    accountOptions: Array<IOptions>

    accountId: string

    creditContactId: string
    selectedCreditContactName: string
    debitContactId: string
    selectedDebitContactName: string

    propertyId: string
    unitId: string
    tenancyId: string
    selectedUnitName: string
    selectedPropertyName: string
    categoryId: string
    selectedCategoryName: string
    reference: string
    dueDate?: string
    paidDate?: string
    invoiceDate?: string
    amount?: number
    paidAmount?: number
    description: string
    notes: string
    isPaidInFull: boolean

    selectedFiles: Array<IDocumentUpload>
    setDueDate: boolean

    documents: Array<IAssociatedDocumentsResponse>
    deleteDocuments: Array<string>
}

let changesState: any = {
    fieldToChange: '',
    accountId: '',
    selectedDebitContactName: '',
    selectedCreditContactName: '',
    propertyId: '',
    unitId: '',
    tenancyId: '',
    selectedUnitName: '',
    selectedPropertyName: '',
    categoryId: '',
    selectedCategoryName: '',
    reference: '',
    documents: [],
    deleteDocuments: [],
    dueDate: new Date().toDateString(),
    invoiceDate: new Date().toDateString(),
    paidDate: null,
    amount: null,
    paidAmount: null,
    description: '',
    notes: '',
}

let initialState: any = {
    loading: false,
    success: false,
    isEditing: false,
    selectedPropertyHasUnits: false,
    accountOptions: [],
    setDueDate: false,
    isPaidInFull: false,
    selectedFiles: [],
}

export enum IAddTransactionReferer {
    Tenancy,
    Contact,
    Property,
    Unit,
    Transaction,
}

class AddEditTransaction extends React.Component<IProps, IState> {
    public accordion: AccordionComponent | null

    constructor(props: any) {
        super(props)

        this.state = {
            ...initialState,
            ...changesState,
        }

        this.onClose = this.onClose.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.getTransaction = this.getTransaction.bind(this)
        this.getOptions = this.getOptions.bind(this)
        this.addFile = this.addFile.bind(this)
        this.removeDocument = this.removeDocument.bind(this)
        this.addToDeleteDocuments = this.addToDeleteDocuments.bind(this)
        this.generateDescription = this.generateDescription.bind(this)
        this.moreInformationContent = this.moreInformationContent.bind(this)
        this.doesPropertyHaveUnits = this.doesPropertyHaveUnits.bind(this)
        this.checkForChanges = this.checkForChanges.bind(this)
        this.onAddContact = this.onAddContact.bind(this)
        this.addFromTenancy = this.addFromTenancy.bind(this)
        this.addFromProperty = this.addFromProperty.bind(this)
        this.addFromContact = this.addFromContact.bind(this)
        this.addFromUnit = this.addFromUnit.bind(this)
        this.setCreditContactToLandlordAtProperty =
            this.setCreditContactToLandlordAtProperty.bind(this)
    }

    onAddContact(field: string): void {
        this.setState({
            fieldToChange: field,
        })
    }

    checkForChanges(): void {
        let difference = Object.keys(this.state).filter((k) => {
            let type = this.state[k]
            let d = moment(type, 'YYYY-MM-DDThh:mm:ssZ', true)
            if (d.isValid()) {
                let nowDate = moment(type, 'YYYY-MM-DD', true)
                let initialDate = moment(changesState[k], 'YYYY-MM-DD', true)

                return !moment(nowDate).isSame(changesState, 'day')
            }

            return (
                this.state[k] !== changesState[k] &&
                changesState[k] !== undefined
            )
        })

        if (difference.length > 0) {
            toggleAddTransactionFormChanged(true)
            return
        }

        toggleAddTransactionFormChanged(false)
    }

    onClose(): void {}

    getTransaction(transactionId: string): void {
        getTransaction(transactionId).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                let data: ITransactionItem = resp.data
                this.setState({
                    isEditing: true,
                    debitContactId: data.debitContactId,
                    creditContactId: data.creditContactId,
                    selectedCreditContactName: data.creditContactName,
                    selectedDebitContactName: data.debitContactName,
                    propertyId: data.propertyId,
                    selectedPropertyName: data.propertyName,
                    paidAmount: data.paidAmount,
                    amount: data.amount,
                    dueDate: data.dueDate,
                    paidDate: data.paidDate,
                    reference: data.reference,
                    notes: data.notes,
                    description: data.description,
                    accountId: data.accountId,
                    invoiceDate: data.invoiceDate,
                    selectedPropertyHasUnits: data.doesPropertyHaveUnits,
                    unitId: data.unitId,
                    selectedUnitName: data.unitName,
                    tenancyId: data.tenancyId
                })
            }
        })

        // Get documents
        getAssociatedDocuments(
            {
                transactionId: transactionId,
            },
            []
        ).then((resp) => {
            if (resp.status == 200 && resp.data) {
                this.setState({
                    documents: resp.data.documents,
                })
            }
        })
    }

    moreInformationContent(): JSX.Element {
        return (
            <div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <div className={styles.label}>Reference</div>
                        <input
                            value={this.state.reference}
                            onChange={(e: any) =>
                                this.setState({
                                    reference: e.currentTarget.value,
                                })
                            }
                            className={styles.input}
                            placeholder="Enter a reference..."
                        />
                        <span className={styles.errorMessage}>
                            <ErrorMessage
                                className={styles.errorMessage}
                                name="reference"
                            />
                        </span>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <div className={styles.label}>Notes</div>
                        <textarea
                            value={this.state.notes}
                            onChange={(e: any) => {
                                this.setState({
                                    notes: e.currentTarget.value,
                                })
                            }}
                            className={styles.textarea}
                            placeholder="Notes..."
                        />
                    </div>
                </div>
            </div>
        )
    }

    addFile(file: IDocumentUpload) {
        this.setState(
            {
                selectedFiles: this.state.selectedFiles.concat([file]),
            },
            () => this.checkForChanges()
        )
    }

    componentWillReceiveProps(newProps: IProps): void {
        if (newProps.options) {
            if (newProps.options != this.props.options) {
                if (
                    !this.props.options ||
                    newProps.options.transactionId !=
                        this.props.options.transactionId
                ) {
                    this.getTransaction(newProps.options.transactionId)
                }
                this.getOptions(newProps.options)
            }
        }

        let next = queryString.parse(
            newProps.location.search
        ).addContactCallback
        if (next) {
            let data: IAddTenantCallback = JSON.parse(next)
            if (this.state.fieldToChange == 'debit') {
                this.setState(
                    {
                        debitContactId: data.contactId,
                        selectedDebitContactName: data.name,
                    },
                    () => this.checkForChanges()
                )
            }

            if (this.state.fieldToChange == 'credit') {
                this.setState(
                    {
                        creditContactId: data.contactId,
                        selectedCreditContactName: data.name,
                    },
                    () => this.checkForChanges()
                )
            }

            let query = removeURLParameter(
                this.props.location.search,
                'addContactCallback'
            )
            this.props.history.push({
                search: query,
            })
        }
    }

    addFromTenancy(options: IAddEditTransactionOptions): void {
        if (options.propertyId) {
            this.setState({
                propertyId: options.propertyId,
                selectedPropertyName: options.propertyName,
            })
        }

        if (options.unitId) {
            this.setState({
                unitId: options.unitId,
                selectedUnitName: options.unitName,
                selectedPropertyHasUnits: true,
            })
        }

        // Debit Contact = Tenant from Tenancy
        if (options.tenancyId) {
            this.setState({
                tenancyId: options.tenancyId,
                debitContactId: options.contactId,
                selectedDebitContactName: options.contactName,
            })
        }

        // Credit Contact = Landlord Contact ID
        this.setCreditContactToLandlordAtProperty(options.propertyId);
        /* getLatestTenantForPropertyId(options.propertyId).then(
            (resp) => {
                if (resp && resp.data) {
                    this.setState({
                        debitContactId: resp.data.value,
                        selectedDebitContactName: resp.data.label,
                    })
                }
            }
        ) */
        
        this.setState({
            tenancyId: options.tenancyId,
            accountId: '9AB582AB-7558-8FCE-A2DF-75039724924E',
            selectedCategoryName: 'Rent',
        })
    }

    addFromContact(): void {}

    addFromProperty(): void {}

    addFromUnit(): void {}

    setCreditContactToLandlordAtProperty(propertyId: string): void {
        if (!propertyId) {
            return
        }

        getLandlordContactFromPropertyId(propertyId).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                this.setState({
                    creditContactId: resp.data.value,
                    selectedCreditContactName: resp.data.label,
                })
            }
        })
    }

    getOptions(options: IAddEditTransactionOptions): void {
        if (!options && !options.isExpense && !options.isIncome) {
            return
        }

        let type = ''
        if (this.props.options) {
            if (this.props.options.isExpense) {
                type = 'expense'
            }

            if (this.props.options.isIncome) {
                type = 'income'
            }
        }

        getAccounts(type).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                this.setState({
                    accountOptions: resp.data,
                })
            }
        })

        if (options.transactionId) {
            return
        }

        if (options.propertyId) {
            doesPropertyHaveUnits(options.propertyId).then((resp) => {
                if (resp.data && resp.status == 200) {
                    this.setState({
                        selectedPropertyHasUnits: resp.data,
                    })
                }
            })
        }

        if (options.isIncome) {
            // Adding income from Property or Unit
            if (
                options.referer == IAddTransactionReferer.Property ||
                options.referer == IAddTransactionReferer.Unit
            ) {
                this.setState({
                    propertyId: options.propertyId,
                    selectedPropertyName: options.propertyName,
                    unitId: options.unitId,
                    selectedUnitName: options.unitName
                })
                // Credit Contact = Landlord from Property
                this.setCreditContactToLandlordAtProperty(options.propertyId)
                // Debit Contact = Tenant from the most recent current tenancy at the property (if setup)
                if(!options.tenancyId){
                    if(options.referer == IAddTransactionReferer.Property){
                        getLatestTenantForPropertyId(options.propertyId).then(
                            (resp) => {
                                if (resp && resp.data) {
                                    this.setState({
                                        debitContactId: resp.data.value,
                                        selectedDebitContactName: resp.data.label,
                                    })
                                }
                            }
                        )
                    }
                    if(options.referer == IAddTransactionReferer.Unit){
                        // Need to add service call here for unit
                    }
                    return;
                }
            }

            // Adding income from Contact
            if (options.referer == IAddTransactionReferer.Contact) {
                // Credit Contact = Contact ID passed into the request
                this.setState({
                    creditContactId: options.contactId,
                    selectedCreditContactName: options.contactName,
                    debitContactId: '',
                    selectedDebitContactName: '',
                })
                return
            }

            // Adding income from Tenancy
            if (options.referer == IAddTransactionReferer.Tenancy) {
                this.addFromTenancy(options)
                return
            }

            // Adding income from Home
            if (options.referer == IAddTransactionReferer.Transaction) {
                // Credit Contact = CURRENT LOGGED IN USER CONTACT ID
                this.setState({
                    creditContactId: getLoggedInUsersContactId(),
                    selectedCreditContactName: `${getLoggedInUsersForename()} ${getLoggedInUsersSurname()}`,
                    debitContactId: '',
                    selectedDebitContactName: '',
                })
                return
            }
        }

        if (options.propertyId && options.propertyName) {
            this.setState({
                propertyId: options.propertyId,
                selectedPropertyName: options.propertyName,
            })

            this.doesPropertyHaveUnits(options.propertyId)
        }

        if (options.referer != IAddTransactionReferer.Tenancy && options.contactId && options.contactName) {
            this.setState({
                debitContactId: options.contactId,
                selectedDebitContactName: options.contactName,
            })
        }

        /* if ((options.propertyId || options.unitId) && !options.contactId) {
            getContactFromPropertyId(options.propertyId).then((resp) => {
                if (resp && resp.status == 200 && resp.data) {
                    this.setState({
                        debitContactId: resp.data.value,
                        selectedDebitContactName: resp.data.label,
                    })
                }
            })
        } */

        if (options.contactId && !options.propertyId) {
            getTenancyFromContactId(options.contactId).then((resp) => {
                this.setState({
                    selectedPropertyName: resp.data.propertyName,
                    propertyId: resp.data.propertyId,
                })
            })
        }

        if (options.unitId && options.unitName) {
            this.setState({
                unitId: options.unitId,
                selectedUnitName: options.unitName,
            })
        }

        if (options.isExpense && (options.propertyId || options.unitId || options.tenancyId)) {
            getLandlordContactFromPropertyId(options.propertyId).then(
                (resp) => {
                    if (resp && resp.status == 200 && resp.data) {
                        this.setState({
                            debitContactId: '',
                            selectedDebitContactName: '',
                            creditContactId: resp.data.value,
                            selectedCreditContactName: resp.data.label,
                        })
                    }
                }
            )
        }

        if(options.tenancyId){
            this.setState({
                tenancyId: options.tenancyId,
            })
        }

        if (
            options.isExpense &&
            !options.propertyId &&
            !options.unitId &&
            !options.contactId &&
            !options.tenancyId
        ) {
            this.setState({
                debitContactId: '',
                selectedDebitContactName: '',
                creditContactId: getLoggedInUsersContactId(),
                selectedCreditContactName: `${getLoggedInUsersForename()} ${getLoggedInUsersSurname()}`,
            })
        }
    }

    componentDidMount(): void {
        if (this.props.options && this.props.options.transactionId) {
            this.getTransaction(this.props.options.transactionId)
        }

        if (this.props.options) {
            this.getOptions(this.props.options)
        }

        this.setState(initialState)
    }

    onCancel(): void {
        toggleTransactionPopup(false)
    }

    doesPropertyHaveUnits(propertyId: string): void {
        doesPropertyHaveUnits(propertyId).then((resp) => {
            if (resp && resp.status == 200) {
                if (resp.data == true) {
                    this.setState({
                        selectedPropertyHasUnits: true,
                    })
                } else {
                    this.setState({
                        selectedPropertyHasUnits: false,
                    })
                }
            }
        })
    }

    generateDescription(): void {
        let category = this.state.selectedCategoryName
        let unitName = this.state.selectedUnitName
        let propertyName = this.state.selectedPropertyName
        let debitContactName = this.state.selectedDebitContactName
        let creditContactName = this.state.selectedCreditContactName

        let result = ''
        // Category
        if (category) {
            result += `${category} `
        }

        // From NAME
        if (debitContactName) {
            result += `from ${debitContactName} `
        }

        // AT
        if (unitName || propertyName) {
            result += `at `
        }

        // UNIT NAME
        if (unitName) {
            result += `${unitName} `
        }

        // PROPERTY NAME
        if (propertyName) {
            result += `${unitName ? ', ' : ''}${propertyName} `
        }

        // To NAME
        if (creditContactName) {
            result += `to ${creditContactName}`
        }

        this.setState({
            description: result,
        })
    }

    removeDocument(index: number): void {
        var copy = this.state.selectedFiles.filter((d, i) => i != index)
        this.setState(
            {
                selectedFiles: copy,
            },
            () => this.checkForChanges()
        )
    }

    addToDeleteDocuments(documentId: string): void {
        if (
            this.state.deleteDocuments.filter((x) => x == documentId).length > 0
        ) {
            return
        }

        this.setState(
            {
                deleteDocuments: this.state.deleteDocuments.concat([
                    documentId,
                ]),
            },
            () => this.checkForChanges()
        )

        var copy = this.state.documents.filter(
            (d, i) => d.documentId != documentId
        )
        this.setState(
            {
                documents: copy,
            },
            () => this.checkForChanges()
        )
    }

    onSubmit(): void {
        let request: IAddTransactionRequest = {
            unitId: this.state.unitId,
            documents: this.state.selectedFiles,
            deleteDocuments: this.state.deleteDocuments,
            paidDate: this.state.paidDate
                ? this.state.paidDate
                : this.state.isPaidInFull
                ? new Date().toDateString()
                : null,
            invoiceDate: this.state.invoiceDate,
            dueDate: this.state.dueDate,
            reference: this.state.reference,
            description: this.state.description,
            amount: this.state.amount,
            paidAmount: this.state.isPaidInFull
                ? this.state.amount
                : this.state.paidAmount,
            notes: this.state.notes,
            isPaidInFull: this.state.isPaidInFull,
            isIncome: this.props.options ? this.props.options.isIncome : false,
            isExpense: this.props.options
                ? this.props.options.isExpense && !this.props.options.isIncome
                : true,
            creditContactId: this.state.creditContactId,

            debitContactId: this.state.debitContactId,
            creditAccountId:
                this.props.options && this.props.options.isIncome
                    ? this.state.accountId
                    : null,
            debitAccountId:
                this.props.options && this.props.options.isExpense
                    ? this.state.accountId
                    : null,
            propertyId: this.state.propertyId,
            tenancyId: this.state.tenancyId
        }

        if (this.props.options && this.props.options.transactionId) {
            this.setState({
                loading: true,
            })
            updateTransaction(this.props.options.transactionId, request).then(
                (resp) => {
                    if (resp && resp.status == 200 && resp.data) {
                        updateTransactionItem(resp.data)
                        this.setState({
                            loading: false,
                        })

                        updateToastMessage(
                            'Transaction successfully updated',
                            'success'
                        )
                        this.props.history.push({
                            search: '?refreshTransactionGrid=true',
                        })
                        toggleTransactionPopup(false)
                    }
                }
            )
            return
        }

        this.setState({
            loading: true,
        })
        if (this.state.propertyId) {
            request.propertyId = this.state.propertyId
        }

        addTransaction(request).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                addToTransactionList(resp.data)
                this.setState({
                    loading: false,
                })

                updateToastMessage('Transaction successfully added', 'success')
                this.props.history.push({
                    search: '?refreshTransactionGrid=true',
                })
                toggleTransactionPopup(false)
            }
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <div className={styles.loading}>
                    <RotatingLoader loading={true} />
                </div>
            )
        }

        return (
            <div className={styles.modalBody}>
                <Formik
                    initialValues={{
                        creditContactId: this.state.creditContactId,
                        debitContactId: this.state.debitContactId,
                        propertyId: this.state.propertyId,
                        categoryId: this.state.categoryId,
                        unitId: this.state.unitId,
                        reference: this.state.reference,
                        dueDate: this.state.dueDate,
                        amount: this.state.amount,
                        description: this.state.description,
                    }}
                    enableReinitialize={true}
                    validationSchema={AddTransaction}
                    onSubmit={(values: any, { setSubmitting }) => {
                        this.onSubmit()
                    }}
                >
                    {(props) => (
                        <Form>
                            {!this.props.options ||
                                (this.props.options && (
                                    <div className={styles.row}>
                                        <div
                                            className={`${styles.column} ${
                                                this.state.selectedFiles
                                                    .length > 0
                                                    ? styles.documents
                                                    : ''
                                            }`}
                                        >
                                            <FileUploadDropzone
                                                maxSize={5}
                                                addFile={this.addFile}
                                            >
                                                Drag 'n' drop files to upload,
                                                or click to select a file.
                                            </FileUploadDropzone>
                                        </div>
                                        {(this.state.selectedFiles.length > 0 ||
                                            this.state.documents.length >
                                                0) && (
                                            <div
                                                className={`${styles.columnSmall} ${styles.selectedDocuments}`}
                                            >
                                                <div
                                                    className={
                                                        styles.rowNoMargin
                                                    }
                                                >
                                                    {this.state.selectedFiles.map(
                                                        (x, i) => {
                                                            return (
                                                                <div
                                                                    className={
                                                                        styles.item
                                                                    }
                                                                >
                                                                    {getFileTypeLogo(
                                                                        x.fileName
                                                                    )}
                                                                    <div
                                                                        className={
                                                                            styles.fileName
                                                                        }
                                                                    >
                                                                        {
                                                                            x.fileName
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.removeButton
                                                                        }
                                                                    >
                                                                        <CloseButton
                                                                            close={() =>
                                                                                this.removeDocument(
                                                                                    i
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    )}

                                                    {this.state.documents.map(
                                                        (x, i) => {
                                                            return (
                                                                <div
                                                                    className={
                                                                        styles.item
                                                                    }
                                                                >
                                                                    {getFileTypeLogo(
                                                                        x.documentName
                                                                    )}
                                                                    <div
                                                                        className={
                                                                            styles.fileName
                                                                        }
                                                                    >
                                                                        {
                                                                            x.documentName
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.removeButton
                                                                        }
                                                                    >
                                                                        <CloseButton
                                                                            close={() =>
                                                                                this.addToDeleteDocuments(
                                                                                    x.documentId
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}

                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <div className={styles.label}>From</div>
                                    <SearchContactInput
                                        onAdd={() => this.onAddContact('debit')}
                                        allowNewContact
                                        selectedId={this.state.debitContactId}
                                        selectedName={
                                            this.state.selectedDebitContactName
                                        }
                                        onSelect={(selected: ISearchResult) => {
                                            this.setState(
                                                {
                                                    debitContactId: selected.value,
                                                    selectedDebitContactName:
                                                        selected.label,
                                                },
                                                () => this.checkForChanges()
                                            )
                                        }}
                                        onClear={() => {
                                            this.setState(
                                                {
                                                    debitContactId: '',
                                                    selectedDebitContactName:
                                                        '',
                                                },
                                                () => this.checkForChanges()
                                            )
                                        }}
                                    />
                                    <span className={styles.errorMessage}>
                                        <ErrorMessage
                                            className={styles.errorMessage}
                                            name="contactId"
                                        />
                                    </span>
                                </div>
                                <div className={styles.column}>
                                    <div className={styles.label}>To</div>
                                    <SearchContactInput
                                        onAdd={() =>
                                            this.onAddContact('credit')
                                        }
                                        allowNewContact
                                        selectedId={this.state.creditContactId}
                                        selectedName={
                                            this.state.selectedCreditContactName
                                        }
                                        onSelect={(selected: ISearchResult) => {
                                            this.setState(
                                                {
                                                    creditContactId:
                                                        selected.value,
                                                    selectedCreditContactName:
                                                        selected.label,
                                                },
                                                () => this.checkForChanges()
                                            )
                                        }}
                                        onClear={() => {
                                            this.setState(
                                                {
                                                    creditContactId: '',
                                                    selectedCreditContactName:
                                                        '',
                                                },
                                                () => this.checkForChanges()
                                            )
                                        }}
                                    />
                                    <span className={styles.errorMessage}>
                                        <ErrorMessage
                                            className={styles.errorMessage}
                                            name="contactId"
                                        />
                                    </span>
                                </div>
                            </div>

                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <div className={styles.label}>Property</div>
                                    <SearchPropertyInput
                                        selectedPropertyId={
                                            this.state.propertyId
                                        }
                                        selectedPropertyName={
                                            this.state.selectedPropertyName
                                        }
                                        onSelect={(selected: IOptions) => {
                                            this.setState(
                                                {
                                                    propertyId: selected.value,
                                                    selectedPropertyName:
                                                        selected.label,
                                                },
                                                () => {
                                                    this.doesPropertyHaveUnits(
                                                        this.state.propertyId
                                                    )
                                                    this.generateDescription()
                                                    this.checkForChanges()
                                                }
                                            )
                                        }}
                                        onClear={() => {
                                            this.setState(
                                                {
                                                    propertyId: '',
                                                    selectedPropertyName: '',
                                                    selectedPropertyHasUnits:
                                                        false,
                                                },
                                                () => {
                                                    this.generateDescription()
                                                    this.checkForChanges()
                                                }
                                            )
                                        }}
                                    />
                                    <span className={styles.errorMessage}>
                                        <ErrorMessage
                                            className={styles.errorMessage}
                                            name="propertyId"
                                        />
                                    </span>
                                </div>

                                {this.state.selectedPropertyHasUnits && (
                                    <div className={styles.column}>
                                        <div className={styles.label}>Unit</div>
                                        <SearchUnitInput
                                            onClear={() => {
                                                this.setState(
                                                    {
                                                        unitId: '',
                                                        selectedUnitName: '',
                                                    },
                                                    () => this.checkForChanges()
                                                )
                                            }}
                                            propertyId={this.state.propertyId}
                                            selectedUnitId={this.state.unitId}
                                            selectedUnitName={
                                                this.state.selectedUnitName
                                            }
                                            onSelect={(id: IOptions) =>
                                                this.setState(
                                                    {
                                                        selectedUnitName:
                                                            id.label,
                                                        unitId: id.value,
                                                    },
                                                    () => this.checkForChanges()
                                                )
                                            }
                                        />

                                        <span className={styles.errorMessage}>
                                            <ErrorMessage
                                                className={styles.errorMessage}
                                                name="categoryId"
                                            />
                                        </span>
                                    </div>
                                )}
                            </div>

                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <div className={styles.label}>Category</div>
                                    <SelectDropdown
                                        selectedId={this.state.accountId}
                                        data={this.state.accountOptions}
                                        placeholder="Select a category..."
                                        onSelect={(id: IOptions) =>
                                            this.setState(
                                                {
                                                    selectedCategoryName:
                                                        id.label,
                                                    accountId: id.value,
                                                },
                                                () => {
                                                    this.generateDescription()
                                                    this.checkForChanges()
                                                }
                                            )
                                        }
                                        isSearchable={true}
                                    />

                                    <span className={styles.errorMessage}>
                                        <ErrorMessage
                                            className={styles.errorMessage}
                                            name="categoryId"
                                        />
                                    </span>
                                </div>

                                <div className={styles.column}>
                                    <div className={styles.label}>Date</div>
                                    <DatePicker
                                        todayButton="Today"
                                        dateFormat="dd/MM/yyyy"
                                        className={styles.input}
                                        selected={
                                            this.state.invoiceDate
                                                ? moment(this.state.invoiceDate)
                                                      .local()
                                                      .toDate()
                                                : null
                                        }
                                        onChange={(date: Date) => {
                                            this.setState(
                                                {
                                                    invoiceDate: date
                                                        ? date.toDateString()
                                                        : null,
                                                },
                                                () => this.checkForChanges()
                                            )
                                            if (
                                                date &&
                                                !this.state.setDueDate
                                            ) {
                                                this.setState(
                                                    {
                                                        dueDate: date
                                                            ? date.toDateString()
                                                            : null,
                                                    },
                                                    () => this.checkForChanges()
                                                )
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            <div className={styles.label}>
                                                Amount
                                            </div>
                                            <div className={styles.currencyInputWrapper}>
                                                <RCCurrencyInput
                                                    name="amount"
                                                    value={this.state.amount}
                                                    prefix="£"
                                                    handleChange={(e) => this.setState(
                                                        { amount: e },
                                                        () => this.checkForChanges()
                                                    )}
                                                />
                                            </div>
                                            <span
                                                className={styles.errorMessage}
                                            >
                                                <ErrorMessage
                                                    className={
                                                        styles.errorMessage
                                                    }
                                                    name="amount"
                                                />
                                            </span>
                                        </div>
                                        <div className={styles.column}>
                                            <div className={styles.label}>
                                                Due on
                                            </div>
                                            <DatePicker
                                                todayButton="Today"
                                                dateFormat="dd/MM/yyyy"
                                                className={styles.input}
                                                selected={
                                                    this.state.dueDate
                                                        ? moment(
                                                              this.state.dueDate
                                                          )
                                                              .local()
                                                              .toDate()
                                                        : null
                                                }
                                                onChange={(date: Date) =>
                                                    this.setState(
                                                        {
                                                            dueDate: date
                                                                ? date.toDateString()
                                                                : null,
                                                            setDueDate: date
                                                                ? true
                                                                : false,
                                                        },
                                                        () =>
                                                            this.checkForChanges()
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <div className={styles.label}>
                                        Description
                                    </div>
                                    <input
                                        value={this.state.description}
                                        onChange={(e: any) => {
                                            this.setState(
                                                {
                                                    description:
                                                        e.currentTarget.value,
                                                },
                                                () => this.checkForChanges()
                                            )
                                        }}
                                        className={styles.input}
                                        placeholder="Description..."
                                    />
                                </div>
                            </div>

                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <div className={styles.label}>
                                        Paid Amount
                                    </div>
                                    <div className={styles.currencyInputWrapper}>
                                        <RCCurrencyInput
                                            name="amount"
                                            value={this.state.paidAmount}
                                            prefix="£"
                                            handleChange={(e) => this.setState(
                                                { paidAmount: e },
                                                () => this.checkForChanges()
                                            )}
                                        />
                                    </div>
                                    <span className={styles.errorMessage}>
                                        <ErrorMessage
                                            className={styles.errorMessage}
                                            name="paidAmount"
                                        />
                                    </span>
                                </div>
                                <div className={styles.column}>
                                    <div className={styles.label}>Paid on</div>
                                    <DatePicker
                                        minDate={new Date(this.state.dueDate)}
                                        todayButton="Today"
                                        dateFormat="dd/MM/yyyy"
                                        className={styles.input}
                                        selected={
                                            this.state.paidDate
                                                ? moment(this.state.paidDate)
                                                      .local()
                                                      .toDate()
                                                : null
                                        }
                                        onChange={(date: Date) =>
                                            this.setState(
                                                {
                                                    paidDate: date
                                                        ? date.toDateString()
                                                        : null,
                                                },
                                                () => this.checkForChanges()
                                            )
                                        }
                                    />
                                </div>
                            </div>

                            <div className={styles.buttons}>
                                <Buttons
                                    buttons={[
                                        {
                                            displayType: 'cancel',
                                            elementType: 'button',
                                            onClick: () => this.onCancel(),
                                        },
                                        {
                                            visible:
                                                !this.props.options ||
                                                !this.props.options.isPaid,
                                            text: 'Pay in Full',
                                            displayType: 'action',
                                            elementType: 'button',
                                            onClick: () =>
                                                this.setState(
                                                    {
                                                        isPaidInFull: true,
                                                    },
                                                    () => props.submitForm()
                                                ),
                                        },
                                        {
                                            text: 'Save',
                                            displayType: 'submit',
                                            elementType: 'button',
                                            onClick: () =>
                                                this.setState(
                                                    { isPaidInFull: false },
                                                    () => props.submitForm()
                                                ),
                                        },
                                    ]}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}

export default withRouter(AddEditTransaction)
