import React from 'react'
import { getSearchIssues } from '../../../services/SearchService'
import styles from './SearchIssuesInput.module.scss'

interface IProps {
    //selectedIssueId: string;
    //selectedIssueName: string;
    onSearch(search: string): void
}

interface IState {
    results: any[]
    search: string
    cursor: number
}

class SearchIssuesInput extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            results: [],
            search: '',
            cursor: 0,
        }

        this.onSearchIssue = this.onSearchIssue.bind(this)
        this.getIssues = this.getIssues.bind(this)
        this.onIssueChange = this.onIssueChange.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.handleEnterPress = this.handleEnterPress.bind(this)
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const value: string = event.currentTarget.value
        this.setState({
            search: value,
        })
    }

    onIssueChange(option: any): void {
        this.setState(
            {
                results: [],
            }
            //() => this.props.onSelect({
            //  label: option.text,
            //  value: option.id
            //})
        )
    }

    onSearchIssue(search: string): void {
        if (!search) {
            this.setState({
                results: [],
            })
            return
        }

        this.getIssues(search)
    }

    getIssues(search?: string): void {
        getSearchIssues(search).then((resp) => {
            if (resp && resp.data) {
                this.setState({
                    results: resp.data,
                })
            }
        })
    }

    handleEnterPress(e: any): void {
        if (e.key === 'Enter') {
            this.props.onSearch(this.state.search)
        }
    }

    handleKeyDown(e: any): void {
        this.handleEnterPress(e)
        const { cursor, results } = this.state
        if (e.keyCode === 38 && cursor > 0) {
            this.setState((prevState) => ({
                cursor: prevState.cursor - 1,
            }))
        } else if (e.keyCode === 40 && cursor < results.length - 1) {
            this.setState((prevState) => ({
                cursor: prevState.cursor + 1,
            }))
        }
    }

    render() {
        return (
            <div className={styles.inputContainer}>
                <input
                    name="tenantName"
                    onChange={this.handleChange}
                    onKeyDown={this.handleEnterPress}
                    className={styles.input}
                    placeholder="Search for an issue..."
                />
            </div>
        )
    }
}

export default SearchIssuesInput
