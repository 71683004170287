import { AssetsAction, AssetActionType, IAssetsListResponse, IServiceScheduleType, IQuantityAndValueConditionType, IProductStatusType } from "../../types/Assets";

interface IAssetDropdownTypesReducer {
    error: string | null;
    serviceScheduleTypes: IServiceScheduleType[];
    quantityAndValueConditionTypes: IQuantityAndValueConditionType[];
    productStatusTypes: IProductStatusType[];
}

const initialState: IAssetDropdownTypesReducer = {
    error: null,
    serviceScheduleTypes: [],
    quantityAndValueConditionTypes: [],
    productStatusTypes: []
}

const reducer = (
    state: IAssetDropdownTypesReducer = initialState,
    action: AssetsAction
): IAssetDropdownTypesReducer => {
    switch (action.type) {
        case AssetActionType.GET_SERVICE_SCHEDULE_TYPES:
            return {
                ...state
            }
        case AssetActionType.GET_SERVICE_SCHEDULE_TYPES_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case AssetActionType.GET_SERVICE_SCHEDULE_TYPES_SUCCESS:
            return {
                ...state,
                error: null,
                serviceScheduleTypes: action.payload
            }

        case AssetActionType.GET_QUANTITY_AND_VALUE_CONDITION_TYPES:
            return {
                ...state
            }
        case AssetActionType.GET_QUANTITY_AND_VALUE_CONDITION_TYPES_SUCCESS:
            return {
                ...state,
                quantityAndValueConditionTypes: action.payload
            }
        case AssetActionType.GET_QUANTITY_AND_VALUE_CONDITION_TYPES_ERROR:
            return {
                ...state,
                error: action.payload
            }

        case AssetActionType.GET_PRODUCT_STATUS_TYPES:
            return {
                ...state
            }
        case AssetActionType.GET_PRODUCT_STATUS_TYPES_SUCCESS:
            return {
                ...state,
                productStatusTypes: action.payload
            }
        case AssetActionType.GET_PRODUCT_STATUS_TYPES_ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
};

export default reducer;
