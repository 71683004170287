import React from "react";
import logo from "../../../images/rent_chief_logo.svg";
import { connect } from "react-redux";
import styles from "./LoadingOverlay.module.scss";
import RotatingLoader from "../../atoms/RotatingLoader";
import { IClientData } from "../../../types/ClientService";
import ClientLogo from "../../atoms/ClientLogo";

interface IProps {
  client: IClientData;
}

class LoadingOverlay extends React.Component<IProps> {
  render() {
    return (
      <div className={styles.flexContainer}>
          <ClientLogo className={styles.logo} />
        <div className={styles.flexItem}>
          <RotatingLoader
            loading={true}
            text={`Loading the ${(this.props.client && this.props.client.name) ? this.props.client.name : ""} platform...`}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  client: state.client
});

export default connect(
  mapStateToProps,
)(LoadingOverlay) as any;