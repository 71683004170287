import { IClientData } from "../types/ClientService"

const initialState: IClientData = {
    id: '',
    name: '',
    platformName: '',
    whitelabelDomains: [],
    clientAdminEmail: '',
    approvalRequired: null,
    upload_logo: null,
    upload_secondary_logo: null,
    htmlEmailSignature: null,
    registrationSnippet: null,
    description: '',
    logo: '',
    secondaryLogo: '',
    htmlRegistrationScreenFooter: ''
}

export default (state: IClientData = initialState, action: any): IClientData => {
    switch (action.type) {

        case "FETCH_CLIENT_DATA_SUCCESS":
            return {
                ...state = action.data
            }

        case "CLEAR_CLIENT_STATE_DATA":
            return {
                ...state = initialState
            }

        default:
            return state
    }
}