import { apiService } from './ApiService';
import * as domains from '../configuration/domains';
import { createBearerToken } from '../helpers/authHelpers';

export interface IChecklistType {
    label: string;
    value: string;
}

export interface IAddInspectionRequest {
    inspectionType: string;
    propertyId?: string;
    inspectionName: string;
    inspectionDateAndTime?: Date;
    inspectorId: string;
    tenantId: string;
    signatureRequiredInspector: boolean;
    signatureRequiredTenant: boolean;
    description?: string;
}

export const getInspectionImages = (inspectionId: number): Promise<any> => {
    return apiService.get(`${domains.inspectionsService}/images/${inspectionId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}


export const getInspection = (inspectionId: number): Promise<any> => {
    return apiService.get(`${domains.inspectionsService}/${inspectionId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const addInspection = (request: IAddInspectionRequest): Promise<any> => {
    return apiService.post(`${domains.inspectionsService}`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

const toQueryStringArray = (arr: number[]): string => {
    if (!arr || arr.length === 0) {
        return "";
    }

    let query = "";

    arr.map((a) => {
        query += "&inspectionIds=" + a;
    });

    return query;
};

export const deleteInspection = (inspectionIds: number[]): Promise<any> => {
    var inspectionsStringed = toQueryStringArray(inspectionIds);
    return apiService
        .delete(
            `${domains.inspectionsService}/ids/inspectionIds?${inspectionsStringed}`,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    Authorization: createBearerToken(),
                },
            }
        )
        .catch((error) => {
            return error.response.data;
        });
};

export const updateInspection = (inspectionId: number, request: IAddInspectionRequest): Promise<any> => {
    return apiService.put(`${domains.inspectionsService}/${inspectionId}`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getChecklistTypes = (): Promise<any> => {
    return apiService.get(`${domains.inspectionsService}/checklistTypes`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getInspectionsStats = (): Promise<any> => {
    return apiService.get(`${domains.inspectionsService}/inspectionsStats`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}