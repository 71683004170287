import React, { useRef } from "react";
import { toggleIssuePopup } from '../../../../helpers/sidebarHelpers';
import useStaticHtml from '../_hooks/useStaticHtml.hook';
import useStaticHtmlString from "../_hooks/useStaticHtmlString.hook";
import { IEmptyStateConfig } from "../_config/config";
import { issueEmptyStateHtml } from "./IssueEmptyStateHtml";

interface IProps {
    onAddIssue?(): void;
}

const IssueEmptyState = ({onAddIssue}: IProps) => {
    const config = useRef<IEmptyStateConfig>(require("../_config/config.json"));

    const onAddIssueInternal = (): void => {
        if (onAddIssue) {
            onAddIssue()
        }

        toggleIssuePopup(true)
    }

    const emptyStateHtml = useStaticHtml(config.current.issue.htmlName, 
                                        config.current.issue.buttonId, 
                                        config.current.issue.buttonText, 
                                        onAddIssueInternal);

    const emptyStateHtml1 = useStaticHtmlString(issueEmptyStateHtml, 
                                                config.current.issue.buttonId, 
                                                config.current.issue.buttonText, 
                                                onAddIssueInternal);

    return (
        emptyStateHtml
    )

}

export default IssueEmptyState;