import React from "react";
import { ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-grids";
import { minWidthTablet } from "../../../../../global/variables/_variables";
import { Router } from "react-router-dom";
import AssociatedDocuments from "../../../../atoms/AssociatedDocuments";
import history, { goToRoute } from "../../../../../history";
import styles from "../UnitGrid.module.scss";
import Currency from "../../../../atoms/Currency";
import { IUnitNavigationOptions } from "../../../../../types/UnitService";
import TenancyStatus from "../components/TenancyStatus";
import noImage from "../../../../../images/noImage.svg";

function image(props) {
  return (<img onClick={() => goToRoute(`/dashboard/unit/view/${props.unitId}`)} className={styles.image} src={props.imageUrl ? props.imageUrl : noImage} />)
}

function name(props) {
  return (<div className={`${styles.unitName} ${styles.hoverCell}`} onClick={() => goToRoute(`/dashboard/unit/view/${props.unitId}`)}>{props.name}</div>)
}

function typeName(props) {
  return (<div className={`${styles.hoverCell}`} onClick={() => goToRoute(`/dashboard/unit/view/${props.unitId}`)}>{props.typeName}</div>)
}

function availableToRent(props) {
  return (props.availableToRent ? <div>Yes</div> : <div>No</div>)
}

function tenancyStatus(props) {
  return (props.availableToRent ? <TenancyStatus status={props.tenancyStatus} /> : null)
}

function targetRent(props) {
  return (<span className={styles.amount}><Currency prefix="£" value={props.targetRent} /></span>)
}

function documents(props) {
  return (<Router history={history}><AssociatedDocuments
    key={props.unitId}
    showOnlyNoneText
    options={{
      unitId: props.unitId
    }}
    viewAll={false}
  /></Router>)
}

function furnished(props) {
  return (props.furnished ? <span>Yes</span> : <span>No</span>);
}

export var UnitDefaultTemplate = function (props: any, options?: IUnitNavigationOptions) {
  return (
    <ColumnsDirective>
      <ColumnDirective
        headerText="Select"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        type="checkbox"
        width="50"
      />

      <ColumnDirective
        field="action"
        textAlign="Left"
        headerText="Action"
        width="100"
      />

      <ColumnDirective
        field="imageUrl"
        textAlign="Left"
        headerText="Image"
        width="100"
        template={image}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Name"
        field="name"
        width="100"
        template={name}
      />

      <ColumnDirective
        textAlign="Left"
        field="typeName"
        headerText="Unit Type"
        width="120"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={typeName}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Rent?"
        field="availableToRent"
        width="75"
        template={availableToRent}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Tenant"
        field="tenantName"
        width="140"
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Status"
        field="tenancyStatus"
        width="120"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={tenancyStatus}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Property"
        key={'test field'}
        field="propertyId"
        template={(props) => <div>{props.propertyName}</div>}
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Size (Sq Ft)"
        field="size"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Target Rent"
        field="targetRent"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={targetRent}
      />

      <ColumnDirective
        field="unitId"
        textAlign="Left"
        headerText="Docs"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={documents}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Created by"
        field="createdByName"
        width="100"
        visible={false}
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Created date"
        field="createdDate"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        visible={false}
        type="date"
        format={{ type: "date", format: "dd/MM/yy HH:mm:ss" }}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Description"
        field="description"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        type="date"
        format={{ type: "date", format: "dd/MM/yy" }}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Furnished"
        field="furnished"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={furnished}
      />
    </ColumnsDirective>
  );
};