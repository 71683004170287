import { DataManager } from '@syncfusion/ej2-data';
import { IListItemOptions } from '../../../../../types/ListFilters';
import { inspectionServiceOdataFriendly } from '../../../../../configuration/domains';
import { createBearerToken } from '../../../../../helpers/authHelpers';
import { toQueryString } from '../../../../../services/DocumentService';
import { CustomODataV4Adapter } from '../_adapters';
import { getDateWithoutOffset } from '../../../../../helpers/timerHelpers';

export function generateOdataSource(listItemOptions?: IListItemOptions, startDate?: Date, endDate?: Date, activeStatus?: boolean): DataManager {
    let stringed = toQueryString(listItemOptions);
    let filter = ``;

    if (startDate && endDate) {
        let startDate1 = getDateWithoutOffset(startDate).toISOString();
        let endDate1 = getDateWithoutOffset(endDate).toISOString();
        filter += `&$filter=(createdDate gt ${startDate1} and createdDate lt ${endDate1} or createdDate eq ${startDate1} or createdDate eq ${endDate1})`
    }

    if (activeStatus != null) {
        if (filter) {
            filter += ` and (active eq ${activeStatus})`;
        } else {
            filter = `&$filter=(active eq ${activeStatus})`;
        }
    }

    let dataManager: DataManager = new DataManager({
        adaptor: new CustomODataV4Adapter,
        url: `${inspectionServiceOdataFriendly}?${stringed}${filter}`,
        headers: [
            { "Authorization": createBearerToken() }
        ]
    });

    return dataManager;
}