import React from 'react'
import { Row, Col } from 'reactstrap'
import Modal from 'react-responsive-modal'
import styles from './DeleteTenancyModal.module.scss'
import MaterialCheckbox from '../MaterialCheckbox'
import GenericButton from '../GenericButton'
import SuccessTick from '../SuccessTick'
import RotatingLoader from '../RotatingLoader'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
    deleteTenancies,
    deleteTenancy,
} from '../../../services/TenancyService'
import { toggleDeleteTenancyPopup } from '../../../helpers/tenancyHelpers'
import { IDataResponse } from '../../../types/ApiService'
import { processToast } from '../../../helpers/toastHelper'
import {
    addURLParameter,
    removeURLParameter,
} from '../../../helpers/locationHelpers'
import Buttons from '../Buttons'
const queryString = require('query-string')

interface IProps {
    //tenancyId: string;
    //description: string;

    history: any
    location: any
    match: any

    tenancy_delete_popup: boolean
}

interface IState {
    deleteTenancies: boolean
    deleteDocuments: boolean
    loading: boolean
    success: boolean

    // From Params
    tenancyIds: Array<string>
    description: string
}

export interface IDeleteRequest {
    deleteAssociatedTenancy: boolean
    deleteAssociatedDocument: boolean
}

class DeleteTenancyModal extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            deleteDocuments: true,
            deleteTenancies: true,
            loading: false,
            success: false,

            description: '',
            tenancyIds: [],
        }
    }

    componentDidMount(): void {
        let options = queryString.parse(
            this.props.location.search
        ).deleteTenancy

        if (!options) {
            return
        }

        let tenancyIds = JSON.parse(options)
        this.setState({
            tenancyIds,
        })

        let removeQuery = removeURLParameter(
            this.props.location.search,
            'deleteTenancy'
        )
        this.props.history.push({
            search: removeQuery,
        })
    }

    onDelete(): void {
        this.setState({
            loading: true,
        })

        deleteTenancies(this.state.tenancyIds, {
            deleteAssociatedDocument: this.state.deleteDocuments,
            deleteAssociatedTransaction: false,
            deleteAssociatedTenancy: false,
        }).then((resp) => {
            if (resp && resp.status == 200) {
                let response: IDataResponse<any> = resp.data
                processToast(response)
                this.setState({
                    loading: false,
                })

                let newQuery = addURLParameter(
                    this.props.location.search,
                    `refreshTenancyGrid=true`
                )
                this.props.history.push({
                    search: newQuery,
                })

                toggleDeleteTenancyPopup(false)
            }
        })
    }

    render() {
        let deleting =
            this.state.tenancyIds.length > 1
                ? `${this.state.tenancyIds.length} tenancies`
                : `1 tenancy`

        return (
            <Modal
                open={this.props.tenancy_delete_popup}
                onClose={() => toggleDeleteTenancyPopup(false)}
                center
            >
                {this.state.loading ? (
                    <div>
                        <RotatingLoader
                            loading={true}
                            text={`Deleting ${deleting}...`}
                        />
                    </div>
                ) : this.state.success ? (
                    <div>
                        <SuccessTick />
                        <p className={styles.modalBody}>Tenancy deleted</p>
                    </div>
                ) : (
                    <div>
                        <p className={styles.modelHeader}>DELETE TENANCY</p>

                        <p className={styles.modalBody}>
                            You're about to delete{' '}
                            {this.state.tenancyIds.length > 1
                                ? `${this.state.tenancyIds.length} tenancies`
                                : `1 tenancy`}
                        </p>

                        <p className={styles.modalBody}>
                            <span className={styles.address}>
                                {this.state.description}
                            </span>
                        </p>

                        <p className={styles.modalBody}>
                            Deleting a tenancy will delete the tenancy record.
                            In addition, you can delete associated documents if
                            required.
                        </p>

                        <div className={styles.checkboxes}>
                            <p className={styles.modalBody}>
                                <MaterialCheckbox
                                    checked={this.state.deleteDocuments}
                                    onChange={() =>
                                        this.setState({
                                            deleteDocuments:
                                                !this.state.deleteDocuments,
                                        })
                                    }
                                    labelText="Delete all associated document records?"
                                />
                            </p>
                        </div>

                        <p className={styles.modalBody}>
                            Do you wish to go ahead and delete?
                        </p>

                        <Row>
                            <Col className={styles.buttonDiv}>
                                <Buttons
                                    buttons={[
                                        {
                                            displayType: 'cancel',
                                            elementType: 'button',
                                            onClick: () =>
                                                toggleDeleteTenancyPopup(false),
                                        },
                                        {
                                            text: 'Delete',
                                            displayType: 'submit',
                                            elementType: 'button',
                                            onClick: () => this.onDelete(),
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </div>
                )}
            </Modal>
        )
    }
}

const mapStateToProps = (state: any) => ({
    tenancy_delete_popup: state.app.popups.tenancy_delete_popup,
})

export default withRouter(connect(mapStateToProps)(DeleteTenancyModal) as any)
