import React from 'react'
import { Row, Col } from 'reactstrap'
import Modal from 'react-responsive-modal'

import styles from './DeleteIssueModal.module.scss'
import GenericButton from '../GenericButton'
import SuccessTick from '../SuccessTick'
import RotatingLoader from '../RotatingLoader'
import { deleteIssue } from '../../../services/IssueService'
import { removeFromListById } from '../../../helpers/issueHelpers'
import { IDataResponse } from '../../../types/ApiService'
import { processToast } from '../../../helpers/toastHelper'
import Buttons from '../Buttons'

interface IProps {
    open: boolean
    onOpenModal(): void
    onCloseModal(): void
    onDeleteCallBack(): void
    issueTitle: string
    issueId: string
}

interface IState {
    loading: boolean
    success: boolean
}

class DeleteIssueModal extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            loading: false,
            success: false,
        }
    }

    componentWillUnmount(): void {
        this.setState({
            loading: false,
            success: false,
        })
    }

    onDelete(): void {
        this.setState({
            loading: true,
        })

        deleteIssue(this.props.issueId, {
            deleteAssociatedDocument: true,
            deleteAssociatedTenancy: false,
        }).then((resp) => {
            if (resp && resp.status == 200) {
                let response: IDataResponse<any> = resp.data
                processToast(response)

                this.setState({
                    loading: false,
                })

                if (response.isSuccess) {
                    removeFromListById(this.props.issueId)
                    this.props.onDeleteCallBack()
                    this.props.onCloseModal()
                    this.setState({
                        success: false,
                    })

                    this.setState({
                        success: true,
                    })
                }
            }
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <Modal
                    open={this.props.open}
                    onClose={this.props.onCloseModal}
                    center
                >
                    <div>
                        <RotatingLoader loading={true} text={`Deleting...`} />
                    </div>
                </Modal>
            )
        }

        return (
            <Modal
                open={this.props.open}
                onClose={this.props.onCloseModal}
                center
            >
                <div>
                    <p className={styles.modelHeader}>DELETE ISSUE</p>
                    <p className={styles.modalBody}>
                        You are about to delete the issue:
                    </p>

                    <p className={styles.modalBody}>
                        <span className={styles.address}>
                            {this.props.issueTitle}
                        </span>
                    </p>

                    <p className={styles.modalBody}>
                        Deleting an issue will delete all linked information.
                        This will include documents you've uploaded and chat
                        records.
                    </p>

                    <p className={styles.modalBody}>
                        Do you wish to go ahead and delete?
                    </p>
                    <Row>
                        <Col className={styles.buttonDiv}>
                            <Buttons
                                buttons={[
                                    {
                                        displayType: 'cancel',
                                        elementType: 'button',
                                        onClick: () =>
                                            this.props.onCloseModal(),
                                    },
                                    {
                                        text: 'Delete',
                                        displayType: 'submit',
                                        elementType: 'button',
                                        onClick: () => this.onDelete(),
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                </div>
            </Modal>
        )
    }
}

export default DeleteIssueModal
