import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import RotatingLoader from '../../../atoms/RotatingLoader'
import Breadcrumbs from '../../../atoms/Breadcrumbs'
import { ITaskDto } from '../../../../types/Tasks'
import styles from './TaskDetailsPageContainer.module.scss'
import TaskDetailsPage from '../TaskDetailsPage/TaskDetailsPage.component'
import { useFetchTaskQuery } from '../../../../servicesRtk/TasksServiceRtk'
import { canAccessPropertyDetails } from '../../../../helpers/permissionHelpers'
const queryString = require('query-string')

const TaskDetailsPageContainer = () => {
    let history = useHistory();
    const location = useLocation();

    const [currentTask, setCurrentTask] = useState<ITaskDto>(null);

    const [taskId, setTaskId] = useState(null);
    const { data, isLoading, isError } = useFetchTaskQuery(taskId, {skip: taskId && taskId != '' ? false : true});

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        var curTaskId = queryParams.get('taskId');
        if(curTaskId){
            setTaskId(curTaskId);
        }
    }, []);

    const taskNameForBreadcrumb = () => {
        let taskName = null;
        if(currentTask && currentTask.taskName){
            taskName = currentTask.taskName.substring(0, 80);
            if(currentTask.taskName.length > 80){
                taskName += "...";
            }
        }

        return taskName;
    }

    const TaskDetailsPageHeader = () => {
        let curPropertyName = '';
        let curPropertyId = '';
        const queryParams = new URLSearchParams(location.search);
        if(queryParams.has('propertyName')){
            curPropertyName = queryParams.get('propertyName');
            curPropertyId = queryParams.get('propertyId');
        }
        if(!curPropertyName && currentTask && currentTask.propertyName){
            curPropertyName = currentTask.propertyName;
            curPropertyId = currentTask.propertyId;
        }
        return (
            <div className={styles.headerBar}>
            <div className={styles.breadcrumbs}>
                <Breadcrumbs
                    breadcrumbs={[
                        ...(curPropertyName
                            ? [
                                  {
                                      name: curPropertyName,
                                      route: canAccessPropertyDetails() ? `/dashboard/property/${curPropertyId}` : '',
                                      ordinal: 1,
                                  },
                              ]
                            : []),
                        {
                            name: 'TASKS',
                            route: curPropertyId
                                ? canAccessPropertyDetails() ? `/dashboard/property/${curPropertyId}/tasks` : ''
                                : '/dashboard/tasks',
                            ordinal: 2,
                        },
                        {
                            name: currentTask && currentTask.taskName ? taskNameForBreadcrumb() : 'Add Task',
                            ordinal: 3,
                        }
                    ]}
                />
            </div>
        </div>
        )
    }

    if (isLoading) {
        return (
            <div className={styles.loader}>
                <RotatingLoader loading={true} text="Loading task..." />
            </div>
        )
    }

    if(isError || (data && data.isError)){
        if(data && data.httpStatusCode == 401){
            return <div>You do not have permission to access</div> 
        }
        else{
            return <div>{data && data.errorMessage ? data.errorMessage :  "There was an error loading task"}</div> 
        }
    }

    if(data && data.isSuccess && !currentTask){
        setCurrentTask(data.data);
    }

    return (
        <div className={styles.container}>
            <TaskDetailsPageHeader />
            {/* <Navigation /> */}

            <div className={styles.content}>
                <TaskDetailsPage />
            </div>
        </div>
    )
}

export default TaskDetailsPageContainer;