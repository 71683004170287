export function CalculateDeposit(purchasecost: number, mortgageAmount: number) {
  return purchasecost - mortgageAmount;
}

export function Calculatemortgage(purchasecost: number, depositAmount: number) {
  return purchasecost - depositAmount;
}

export function CalculateLTV(purchasecost: number, mortgageAmount: number) {
  return Math.round((mortgageAmount / purchasecost) * 100);
}

export function CalculateUsingLTV(purchasecost: number, ltv: number) {
  return (purchasecost * ltv) / 100;
}


export function CalculateProfitPCM(rentPCM: number, costsPCM: number): number {
  return rentPCM - costsPCM;
}