import React from "react";
import { ColumnDirective } from "@syncfusion/ej2-react-grids";
import SFDropdown from "../../../../atoms/SFDropdown";
import { goToRoute } from "../../../../../history";
import styles from "../TenancyGrid.module.scss";
import { addDocumentClicked, onAddIssueClickGeneric } from "../../../../atoms/SFDropdown/SFPropertyDropdown/_helpers";
import { onDeleteRows } from "../../../TenancyPage/TenancyGrid/_helpers";

const customAttributes: any = { class: styles.actionColumn };

export var ActionColumnTemplate = function (pProps: any) {

  return (
    <ColumnDirective
      customAttributes={customAttributes}
      template={(props: any) => (
        <SFDropdown
          loadedBeforeRender
          customCssClassName={styles.syncfusionDropdown}
          onSelect={(args) => {
            if (args.item.text == "Edit") {
              goToRoute(`/dashboard/tenancy/view/${props.tenancyId}`);
            }
            if (args.item.text == "Add Document") {
              addDocumentClicked({
                tenancyId: props.tenancyId,
                contactId: props.contactId,
                propertyId: props.propertyId,
                unitId: props.unitId,
              }, "uploadDocumentOptions");
            }
            if (args.item.text == "Add Tenancy Contract") {
              addDocumentClicked({
                tenancyId: props.tenancyId,
                contactId: props.contactId,
                propertyId: props.propertyId,
                unitId: props.unitId,
                documentTypeId: "3bdd80c3-91c9-4e37-8588-db6a0db0f4a9",
                dialogueTitle: "Tenancy Contract"
              }, "uploadDocumentOptions");
            }
            if (args.item.text == "Add Issue") {
              onAddIssueClickGeneric({
                tenancyId: props.tenancyId,
                contactId: props.contactId,
                contactName: props.tenantName,
                propertyId: props.propertyId,
                propertyName: props.propertyName,
              }, "issueOptions");
            }
            if (args.item.text == "Linked Property") {
              goToRoute(`/dashboard/property/${props.propertyId}`);
            }
            if (args.item.text == "Linked Contact") {
              goToRoute(`/dashboard/contact/${props.contactId}`);
            }
            if (args.item.text == "Delete") {
              onDeleteRows([props.tenancyId], pProps.location, pProps.history);
            }
          }}
          items={[
            {
              iconCss: styles.subIconEdit,
              text: "Edit"
            },
            {
              iconCss: styles.subIconEdit,
              text: "Clone"
            },
            {
              separator: true,
            },
            {
              iconCss: styles.subIconAddDocument,
              text: "Add Document"
            },
            {
              iconCss: styles.subIconAddTenancyContract,
              text: "Add Tenancy Contract"
            },
            {
              iconCss: styles.subIconAddIssue,
              text: "Add Issue"
            },
            {
              separator: true,
            },
            ...(props.propertyId
              ? [
                {
                  iconCss: styles.subIconProperty,
                  text: "Linked Property"
                },
              ]
              : []),
            ...(props.contactId
              ? [
                {
                  iconCss: styles.subIconContact,
                  text: "Linked Contact"
                },
              ]
              : []),
            {
              iconCss: styles.subIconDelete,
              text: "Delete"
            }
          ]}
        />
      )}
      width="75"
    />
  );
};