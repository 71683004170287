import React from 'react';
import styles from './MultiSelectItem.module.scss';

interface IProps {
    option: string;
    selected: string;
    isReadOnly?: boolean;
    onChange(option: string): void;
}

const MultiSelectItem = ({ option, selected, onChange, isReadOnly }: IProps) => {
    const getLetter = option ? option.substring(0, 1).toUpperCase() : '?';

    const onHandleKeyPress = (e) => {
        if (e.key != 'Enter') {
            return;
        }

        if (isReadOnly) {
            return;
        }

        onChange(option);
    }

    return (
        <div
            tabIndex={0}
            onClick={() => isReadOnly ? null : onChange(option)}
            onKeyPress={onHandleKeyPress}
        >
            <div className={styles.itemContainer}>
                <span className={`${styles.multiselectItem} ${selected == option ? styles.selected : ''} ${isReadOnly ? styles.disabled : ''}`}>
                    {getLetter}
                </span>
            </div>

            <div className={styles.text}>{option}</div>
        </div>
    )
}

export default MultiSelectItem;