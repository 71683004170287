import { toggleIssuePopup, toggleAddDocumentPopup, toggleTransactionPopup } from "../../../../../helpers/sidebarHelpers";
import { appendAddTenancyOptions, IAddTenancyOptions, addURLParameter } from "../../../../../helpers/locationHelpers";
import history from "../../../../../history";
import { IRaiseIssueOptions } from "../../../AddEditIssue";
import { IDocumentUploadOptions } from "../../../../molecules/AddDocumentModal";
import { IAddEditTransactionOptions } from "../../../AddEditTransactionModal";
import { sendInvitation, deleteInvitation } from "../../../../../services/InvitationService";
import { IDataResponse } from "../../../../../types/ApiService";
import { updateInvitationStatusForContactList } from "../../../../../helpers/profileHelpers";
import { updateToastMessage } from "../../../../../helpers/toastHelper";
import { IAddTransactionReferer } from "../../../AddEditTransaction";

export function onAddTenancyClick(contactId: string, contactName: string, search: any): void {
    let options: IAddTenancyOptions = {
        contactId,
        contactName
    };

    let query = appendAddTenancyOptions(search, options);

    history.push(`/dashboard/tenancy/add${query}`);
}

export function onAddIssueClick(contactId: string, contactName: string, search: any): void {
    let options: IRaiseIssueOptions = {
        contactId: contactId,
        contactName: contactName
    }

    let stringed = "issueOptions=" + JSON.stringify(options);

    let query = addURLParameter(search, stringed);
    history.push({
        search: query
    });

    toggleIssuePopup(true);
}

export function addDocumentClicked(contactId: string, search: string): void {
    let options: IDocumentUploadOptions = {
        contactId
    }

    let query = "uploadDocumentOptions=" + JSON.stringify(options);

    let search1 = addURLParameter(search, query);

    history.push({
        search: search1
    });

    toggleAddDocumentPopup(true);
}

export function onAddExpenseClick(contactId: string, contactName: string, search: string, isIncome: boolean, isExpense: boolean,): void {
    let options: IAddEditTransactionOptions = {
        referer: IAddTransactionReferer.Contact,
        contactId: contactId,
        contactName: contactName,
        isIncome,
        isExpense
    }

    let stringed = "addEditTransactionOptions=" + JSON.stringify(options);

    let query = addURLParameter(search, stringed);
    history.push({
        search: query
    });

    toggleTransactionPopup(true);
}

export function onDeleteClick(contactId: string, contactName: string): void {

}

export function onCancelInviteClick(contactId: string): void {
    deleteInvitation(contactId).then(resp => {
        if (resp && resp.status == 200) {
            updateInvitationStatusForContactList(null, contactId);
            updateToastMessage("Invite has been cancelled", "success");
        }
    });
}

export function onSendInviteClick(contactId: string): void {
    sendInvitation({
        contactId: contactId
    }).then(resp => {
        if (resp && resp.status == 200 && resp.data) {
            let response: IDataResponse<any> = resp.data;
            updateInvitationStatusForContactList(response.data, contactId);
            updateToastMessage(response.isSuccess ? response.message : response.errorMessage, response.isSuccess ? "success" : "failure");
        }
    });
}