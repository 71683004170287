// Import React
import React, { Component } from "react";
import { Row, Col } from "reactstrap";

import styles from "./PropertyTenancyPage.module.scss";
import TabbedNavigationContainer from "../../../atoms/TabbedNavigationContainer";
import { Redirect, Route } from "react-router";
import NestedSimpleRouterTabs from "../../../atoms/NestedSimpleRouterTabs";
import { withRouter } from "react-router-dom";
import PropertyTenancyRequirementsPage from "../PropertyDetailPage/PropertyTenancyRequirementsPage";
import PropertyTenancy from "../PropertyDetailPage/PropertyTenancy";
import { connect } from "react-redux";
import { ITenancyItem } from "../../../../types/Tenancy";

interface IProps {
  match: any;
  history: any;
  location: any;
  tenancies: Array<ITenancyItem>;
}

interface IState {

}

type Props = IProps;

class PropertyTenancyPage extends Component<Props, IState> {
  constructor(prop: any) {
    super(prop);

    this.state = {

    };

  }

  render() {
    return (
      <Row className={styles.container}>
        <Col className={styles.routedPage}>
          <TabbedNavigationContainer fullWidth isSubMenu>
            <Col className={styles.tabs}>
              <NestedSimpleRouterTabs
                routes={[
                  {
                    path: "tenancies",
                    text: "Tenancies"
                  },
                  {
                    path: "Defaults",
                    text: "defaults"
                  }
                ]}
              />
            </Col>
          </TabbedNavigationContainer>

          <div className={styles.content}>
            <Route
              path={`${this.props.match.url}`}
              exact
              render={() => <Redirect to={`${this.props.match.url}/tenancies`} />}
            />

            <Route
              path={`${this.props.match.path}/tenancies`}
              component={PropertyTenancy}
            />

            <Route
              path={`${this.props.match.path}/defaults`}
              component={PropertyTenancyRequirementsPage}
            />
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: any) => ({
  tenancies: state.property.tenancy.tenancy_list
});

export default withRouter(connect(
  mapStateToProps,
)(PropertyTenancyPage));