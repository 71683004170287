import { apiService } from './ApiService';
import * as domains from '../configuration/domains';
import { createBearerToken } from '../helpers/authHelpers';
import { IAssignUserRoleRequest, IUpdateRoleRequest } from '../types/RolesService';

export const getContactTypeWithAssignableRoles = (contactTypeId: string): Promise<any> => {
    return apiService.get(`${domains.rolesService}/contactTypeRoles/${contactTypeId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getRoles = (): Promise<any> => {
    return apiService.get(`${domains.rolesService}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getRole = (roleId: string): Promise<any> => {
    return apiService.get(`${domains.rolesService}/${roleId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getAssignedUsers = (roleId: string): Promise<any> => {
    return apiService.get(`${domains.rolesService}/assigned-users/${roleId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getAssignableUsers = (roleId: string): Promise<any> => {
    return apiService.get(`${domains.rolesService}/assignable-users/${roleId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const assignRoleToUsers = (request: IAssignUserRoleRequest): Promise<any> => {
    return apiService.put(`${domains.rolesService}`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const updateRole = (roleId: string, request: IUpdateRoleRequest): Promise<any> => {
    return apiService.put(`${domains.rolesService}/update-role/${roleId}`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}