// Import React
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

interface IProps {
  canView: boolean;
  children?: any;
}

interface IRouterProps {
  history: any;
  location: any;
  match: any;
}

interface IState {

}

type Props = IProps & IRouterProps;

class SubNavigationItem extends Component<Props, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
    };
  }


  render() {
    if (!this.props.canView) {
      return null;
    }

    return (
      this.props.children
    );
  }
}

export default withRouter(SubNavigationItem);
