import { apiService } from '../services/ApiService';
import * as domains from '../configuration/domains';
import { createBearerToken } from '../helpers/authHelpers';
import { useGetAddressIoAddressSearch } from '../configuration/appConfig';
import { IUpdatePropertyAddress } from '../types/PropertyService/IPropertyService';

export const findAddressByPostcode = (postcode: string): Promise<any> => {
    return apiService.get(`${domains.findAddressService}/${postcode}${useGetAddressIoAddressSearch ? '?devResponse=false' : ''}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })    
    .catch(error =>{
       return error; 
    });
}

export const getAddressByPropertyId = (propertyId: any): Promise<any> => {
    return apiService.get(`${domains.addressService}/get-address/${propertyId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getAddressByUserId = (propertyId: any): Promise<any> => {
    return apiService.get(`${domains.addressService}/get-address/${propertyId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const updateAddressByPropertyId = (PropertyUpdateRequest: IUpdatePropertyAddress ): Promise<any> => {
    return apiService.put(`${domains.addressService}/update-property-address/`, PropertyUpdateRequest, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}