import React from "react";
import SFDropdown from "..";
import { withRouter } from "react-router";
import { trustedTeamUser } from "../../../../helpers/permissionHelpers";
import { ITaskDto } from "../../../../types/Tasks";
import { getLoggedInUsersUsertId } from "../../../../helpers/profileHelpers";
import styles from "./SFTaskDropdown.module.scss";

interface IProps {
  task?: ITaskDto;
  taskId: string;
  toggle?: JSX.Element;
  onDelete(): any;
  onEdit(taskId: string): any;
}

class SFTaskDropdown extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      (trustedTeamUser() || this.props.task.createdByUserId == getLoggedInUsersUsertId()) &&
      <SFDropdown
        loadedBeforeRender
        customToggle={this.props.toggle}
        customCssClassName={styles.syncfusionDropdown}
        onSelect={(args) => {
          if (args.item.text == 'Edit') {
            this.props.onEdit(this.props.taskId)
          }
          if (args.item.text == 'Delete') {
            this.props.onDelete();
          }
        }}
        items={[
/*           {
            iconCss: styles.subIconEdit,
            text: "Edit"
          }, */
          {
            iconCss: styles.subIconDelete,
            text: "Delete"
          }
        ]}
      />
    );
  }
}

export default withRouter(SFTaskDropdown);