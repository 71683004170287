import { apiService } from './ApiService';
import * as domains from '../configuration/domains';
import { createBearerToken } from '../helpers/authHelpers';
import { IAddTeamMemberRequest, IChangeTeamNameRequest, ITeamMembersListItemOptions, IToggleTeamMemberAccessRequest } from '../types/Teams';

export const changeTeamNameApi = (request: IChangeTeamNameRequest): Promise<any> => {
    return apiService.post(`${domains.teamsService}/teamName`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const toggleTeamMemberAccess = (request: IToggleTeamMemberAccessRequest): Promise<any> => {
    return apiService.post(`${domains.teamsService}/toggleAccess`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const addTeamMemberWithEmailApi = (
    request: IAddTeamMemberRequest
): Promise<any> => {
    return apiService.post(
        `${domains.teamsService}/teamMember`, request,
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Authorization: createBearerToken()
            }
        }
    );
};

export const getTeamMembersApi = (
    options: ITeamMembersListItemOptions
): Promise<any> => {
    var stringed = toQueryString(options);
    return apiService.get(
        `${domains.teamsService}/teamMembers?${stringed}`,
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Authorization: createBearerToken()
            }
        }
    );
};

const toQueryStringArray = (arr: number[]): string => {
    if (!arr || arr.length === 0) {
        return "";
    }

    let query = "";

    arr.map((a) => {
        query += "&userTeamIds=" + a;
    });

    return query;
};

export const removeTeamMembersApi = (userTeamIds: number[]): Promise<any> => {
    var userTeamIdsStringed = toQueryStringArray(userTeamIds);
    return apiService
        .delete(
            `${domains.teamsService}/ids/userTeamIds?${userTeamIdsStringed}`,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    Authorization: createBearerToken(),
                },
            }
        )
};

// A copy of this sits in the DocumentService, rather move this to a proper shared file
function toQueryString(obj) {
    var parts = [];
    for (var i in obj) {
        if (obj.hasOwnProperty(i)) {
            parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]));
        }
    }
    return parts.join("&");
}

