import React from "react";
import { connect } from "react-redux";
import { IClientData } from "../../../types/ClientService";
import logo from "../../../images/rent_chief_logo.svg";
import styles from "./ClientLogo.module.scss";

interface IProps {
  client: IClientData;
  className?: string;
}

class ClientLogo extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <img
        className={`${styles.platformLogo} ${this.props.className ? this.props.className : ''}`}
        src={(this.props.client && this.props.client.logo) ? this.props.client.logo : logo}
        alt={`${(this.props.client && this.props.client.name) ? `${this.props.client.name} Logo` : "Platform Logo"}`} />
    );
  }
}

const mapStateToProps = (state: any) => ({
  client: state.client
});

export default connect(
  mapStateToProps,
)(ClientLogo) as any;