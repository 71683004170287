import React from 'react'
import Modal from 'react-responsive-modal'
import Buttons from '../Buttons'
import styles from './OutstandingChangesModal.module.scss'

interface IProps {
    open: boolean
    onCloseChangesModal(close: boolean): void
}

const OutstandingChangesModal = (props: IProps) => {
    return (
        <Modal
            classNames={{ modal: styles.modal }}
            open={props.open}
            onClose={() => props.onCloseChangesModal(false)}
            center
        >
            <p className={styles.modalHeader}>Outstanding changes</p>
            <p className={styles.modalBodyOutstandingChanges}>
                Any updated information will be lost, do you wish to continue?
            </p>

            <Buttons
                buttons={[
                    {
                        text: 'No',

                        displayType: 'cancel',
                        elementType: 'button',
                        onClick: () => props.onCloseChangesModal(false),
                    },
                    {
                        text: 'Yes',
                        displayType: 'submit',
                        elementType: 'button',
                        onClick: () => props.onCloseChangesModal(true),
                    },
                ]}
            />
        </Modal>
    )
}

export default OutstandingChangesModal
