import React from 'react'
import Modal from 'react-responsive-modal'
import { withRouter } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { removeURLParameter } from '../../../helpers/locationHelpers'
import { processToast } from '../../../helpers/toastHelper'
import { toggleDeleteUnitModal } from '../../../helpers/unitHelpers'
import { goToRoute } from '../../../history'
import { deleteUnit } from '../../../services/UnitService'
import { IDataResponse } from '../../../types/ApiService'
import Buttons from '../Buttons'
import MaterialCheckbox from '../MaterialCheckbox'
import RotatingLoader from '../RotatingLoader'
import styles from './DeleteUnitModal.module.scss'
const queryString = require('query-string')

interface IProps {
    history: any
    location: any
    match: any
}

interface IState {
    deleteTenancies: boolean
    deleteDocuments: boolean
    deleteTransaction: boolean
    loading: boolean
    success: boolean

    unitIds: Array<string>
    redirectUrl: string
}

export interface IDeleteRequest {
    deleteAssociatedTenancy: boolean
    deleteAssociatedDocument: boolean
    deleteAssociatedTransaction?: boolean
}

class DeleteUnitModal extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            deleteDocuments: true,
            deleteTenancies: true,
            deleteTransaction: true,
            loading: false,
            success: false,

            unitIds: [],
            redirectUrl: '',
        }

        this.onDelete = this.onDelete.bind(this)
    }

    componentDidMount(): void {
        let options1 = queryString.parse(
            this.props.location.search
        ).deleteUnitRedirectUrl

        if (options1) {
            this.setState({
                redirectUrl: options1,
            })

            let removeQuery1 = removeURLParameter(
                this.props.location.search,
                'deleteUnitRedirectUrl'
            )
            this.props.history.push({
                search: removeQuery1,
            })
        }

        let options = queryString.parse(this.props.location.search).deleteUnit

        if (!options) {
            return
        }

        let option = JSON.parse(options)

        this.setState({
            unitIds: option,
        })

        let removeQuery = removeURLParameter(
            this.props.location.search,
            'deleteUnit'
        )
        this.props.history.push({
            search: removeQuery,
        })
    }

    onDelete(): void {
        this.setState({
            loading: true,
        })

        deleteUnit(this.state.unitIds, {
            deleteAssociatedDocument: this.state.deleteDocuments,
            deleteAssociatedTransaction: this.state.deleteTransaction,
            deleteAssociatedTenancy: this.state.deleteTenancies,
        }).then((resp) => {
            if (resp && resp.status == 200) {
                this.setState({
                    loading: false,
                })

                let response: IDataResponse<boolean> = resp.data
                processToast(response)

                if (response.isSuccess) {
                    goToRoute(this.state.redirectUrl + '?refreshUnitGrid=true')
                    toggleDeleteUnitModal(false)
                }
            }
        })
    }

    render() {
        return (
            <Modal
                open={true}
                onClose={() => toggleDeleteUnitModal(false)}
                center
            >
                {this.state.loading ? (
                    <div>
                        <RotatingLoader
                            loading={true}
                            text={`Deleting ${
                                this.state.unitIds.length > 1
                                    ? this.state.unitIds.length + ' units'
                                    : 'unit'
                            }...`}
                        />
                    </div>
                ) : (
                    <div>
                        <p className={styles.modelHeader}>
                            DELETE{' '}
                            {this.state.unitIds.length > 1
                                ? this.state.unitIds.length
                                : ''}{' '}
                            UNIT{this.state.unitIds.length > 1 ? 'S' : ''}
                        </p>

                        <div className={styles.checkboxes}>
                            {/*<p className={styles.modalBody}>
                  <MaterialCheckbox
                    checked={this.state.deleteTenancies}
                    onChange={() =>
                      this.setState({
                        deleteTenancies: !this.state.deleteTenancies
                      })
                    }
                    labelText="Delete all associated tenancy records?"
                  />
                </p>*/}

                            <p className={styles.modalBody}>
                                <MaterialCheckbox
                                    checked={this.state.deleteDocuments}
                                    onChange={() =>
                                        this.setState({
                                            deleteDocuments:
                                                !this.state.deleteDocuments,
                                        })
                                    }
                                    labelText="Delete all associated document records?"
                                />
                            </p>
                        </div>

                        <p className={styles.modalBody}>
                            Do you wish to go ahead and delete?
                        </p>

                        <Row>
                            <Col className={styles.buttonDiv}>
                                <Buttons
                                    buttons={[
                                        {
                                            displayType: 'cancel',
                                            elementType: 'button',
                                            onClick: () =>
                                                toggleDeleteUnitModal(false),
                                        },
                                        {
                                            text: 'Delete',
                                            displayType: 'submit',
                                            elementType: 'button',
                                            onClick: () => this.onDelete(),
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </div>
                )}
            </Modal>
        )
    }
}

export default withRouter(DeleteUnitModal)
