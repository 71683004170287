import { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import isEqual from 'react-fast-compare';
import { useDebouncedCallback } from 'use-debounce';

const FormikPersist = ({ name, add_options }) => {
  const { values, setValues, setFieldValue } = useFormikContext<any>();
  const prefValuesRef = useRef<any>();

  const onSave = (values) => {
    window.sessionStorage.setItem(name, JSON.stringify(values));
  };

  const debouncedOnSave = useDebouncedCallback(onSave, 300);

  useEffect(() => {
    if(add_options){
      setFieldValue('forename', add_options.forename)
      setFieldValue('surname', add_options.surname)
      setFieldValue('email', add_options.email)
      setFieldValue('contactType', add_options.contactType)
    }else{
      const savedForm = window.sessionStorage.getItem(name);

      if (savedForm) {
        const parsedForm = JSON.parse(savedForm);

        prefValuesRef.current = parsedForm;
        setValues(parsedForm);
      }
    }
  }, [name, setValues, add_options]);

  useEffect(() => {
    if (!isEqual(prefValuesRef.current, values)) {
      debouncedOnSave(values);
    }
  });

  useEffect(() => {
    prefValuesRef.current = values;
  });

  return null;
};

export default FormikPersist;

export const resetFormikPersist = (name) => {
    window.sessionStorage.removeItem(name);
}