import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { RootState } from '../../../../../reducers/rootReducer';
import { IAssetAddEdit } from '../../../../../types/Assets';
import PropertySummaryComponent from '../../../../atoms/PropertySummaryComponent'
import SectionHeader from "../SectionHeader/SectionHeader.component"
import CounterPart from "../CounterPart/CounterPart.component"
import * as S from "./ActivePropertySpecSection.styles";
import { Loader } from '@rentchief/components';
import { useFetchTotalStatsData } from '../../../../../servicesHooks/useFetchTotalStatsData.hook';

interface sectionProps {
    id: string;
    header: string;
    hideOnClick(id: string): void;
}

const ActivePropertySpecSection = ({id, header, hideOnClick}: sectionProps) => {
    const history = useHistory();

    const { propertyId, propertyName } = useSelector((state: RootState) => state.property.property_data.property_data);
    const { council, epcRating  } = useSelector((state: RootState) => state.property.property_data.property_data.propertyDetails);
    const { yearBuilt, propertyType } = useSelector((state: RootState) => state.property.property_data.property_data.propertySummary);

    const { dataRetrieved, data } = useFetchTotalStatsData(propertyId);

    const onNavigate = (id?: string): void => {
        var url = '/dashboard/units'
    
        switch(id){
            case "PropertySpec":
                url = `/dashboard/property/${propertyId}/spec`; break;
            case "Units":
                url = propertyId ? `/dashboard/property/${propertyId}/spec/units` : '/dashboard/units'; break;
            case "Assets": 
                url = propertyId ? `/dashboard/property/${propertyId}/spec/assets` : '/dashboard/assets'; break;
        }
    
        history.push(url)
    }

    const onAddAsset = () => {
        let request: IAssetAddEdit = {
            propertyId: propertyId,
            propertyName: propertyName,
            referer: 'property',
        }

        history.push({
            pathname: `/dashboard/asset/add`,
            search: request ? `assetsOptions=${JSON.stringify(request)}` : '',
        })
    }

    const onAddUnit = () => {
        history.push(
            `/dashboard/unit/add?propertyId=${propertyId}&propertyName=${propertyName}&updateRedirectUrl=${location.pathname}`
        )
    }

    return (
        <>
        {
            !dataRetrieved
            ?
            <Loader />
            :
            <S.SectionDiv>
                <SectionHeader id={id} header={header} hideOnClick={hideOnClick} titleOnClick={() => onNavigate("PropertySpec")}/>
                <S.Area1Div>
                    <PropertySummaryComponent
                        data={{
                            propertyId: propertyId,
                            propertyName: propertyName,
                            propertyType: propertyType,
                            yearBuilt: yearBuilt,
                            council: council,
                            epcRating: epcRating,
                        }}
                        showPropertyEPCRating
                        showPropertyType
                        showPropertyYearBuilt
                        showLocalCouncilInformation
                    />
                </S.Area1Div>
                <S.Area2Div><CounterPart id="Units" value={data && data.atAGlance.totalUnits} type="Units" hasAdd={true} addOnClick={() => onAddUnit()} valueOnClick={onNavigate}/></S.Area2Div>
                <S.Area3Div><CounterPart id="Assets" value={data && data.atAGlance.totalAssets} type="Assets" hasAdd={true} addOnClick={() => onAddAsset()} valueOnClick={onNavigate} /></S.Area3Div>
            </S.SectionDiv>
        }
        </>
    )
}

export default ActivePropertySpecSection;