import {
    Route,
    Redirect
} from 'react-router-dom'
import React from 'react';
import history from '../history';

import { isUserAuthenticated } from '../helpers/authHelpers';
import { isLandlord, isPlatformAdmin, isClientAdmin } from './roleHelpers';

export const AuthenticatedRoute = ({ component: Component, ...rest }: any) => {
    let returnUrl = "";
    let pathname = history.location.pathname;
    if ((pathname.indexOf("account") === -1 && pathname != "/dashboard") ||
        (location.search && new URLSearchParams(location.search).has("addProperty"))) {
        returnUrl = `?returnUrl=${pathname}${location.search}`;
    }

    return (
        <Route {...rest} render={(props: any) => (
            isUserAuthenticated()
                ? <Component {...props} />
                :
            <Redirect to={{
                pathname: `/account/login${returnUrl}`,
                //search: rest.location.search
            }} />
        )} />
    )
}

export const GuestRoute = ({ component: Component, ...rest }: any) => (
    <Route {...rest} render={(props) => (
        !isUserAuthenticated()
            ? <Component {...props} />
            : <Redirect to={{
                pathname: `/dashboard`,
                search: rest.location.search
            }} />
    )} />
)

export const LandlordRoute = ({ component: Component, ...rest }: any) => (
    <Route {...rest} render={(props) => (
        isLandlord() || isPlatformAdmin()
            ? <Component {...props} />
            : <Redirect to={{
                pathname: `/dashboard`,
                search: rest.location.search,
                state: rest.loction.state
            }} />
    )} />
)

export const PlatformAdminRoute = ({ component: Component, ...rest }: any) => (
    <Route {...rest} render={(props) => (
        isPlatformAdmin()
            ? <Component {...props} />
            : <Redirect to={{
                pathname: `/dashboard`,
                search: rest.location.search
            }} />
    )} />
)

export const PlatformAdminOrClientAdminRoute = ({ component: Component, ...rest }: any) => (
    <Route {...rest} render={(props) => (
        isPlatformAdmin() || isClientAdmin()
            ? <Component {...props} />
            : <Redirect to={{
                pathname: `/dashboard`,
                search: rest.location.search
            }} />
    )} />
)