import { Dispatch } from 'redux';
import { processToast, updateToastMessage } from '../helpers/toastHelper';
import { TeamsActionType, TeamsAction, ITeamMembersListItemOptions, IAddTeamMemberRequest, IInviteTeamMemberOptions } from '../types/Teams';
import { addTeamMemberWithEmailApi, changeTeamNameApi, getTeamMembersApi, removeTeamMembersApi, toggleTeamMemberAccess } from '../services/TeamsService';
import { deleteInvitation } from '../services/InvitationService';
import { updateInvitationStatusForContactList } from '../helpers/profileHelpers';
import { store } from '../store';

export const changeTeamName = (
    teamName: string) => {
    return async (dispatch: Dispatch<TeamsAction>) => {
        dispatch({ type: TeamsActionType.EDIT_TEAM_NAME });

        try {
            const { data } = await changeTeamNameApi({ teamName });

            if (data) {
                processToast(data);
                if (data.isSuccess) {
                    dispatch({
                        type: TeamsActionType.EDIT_TEAM_NAME_SUCCESS,
                        payload: data.data
                    });
                }
            } else {
                dispatch({
                    type: TeamsActionType.EDIT_TEAM_NAME_ERROR,
                    payload: data.errorMessage
                });
            }

        } catch (err) {
            updateToastMessage('An error occurred whilst toggling the team members access', 'failure');
            dispatch({
                type: TeamsActionType.TOGGLE_TEAM_MEMBER_ACCESS_ERROR,
                payload: err.message
            })
        }
    }
};

export const clearTeamMembers = () => {
    return (dispatch: Dispatch<TeamsAction>) => {
        dispatch({ type: TeamsActionType.CLEAR_TEAM_MEMBERS });
    }
};

export const toggleEditTeamName = (
    open: boolean) => {
    return (dispatch: Dispatch<TeamsAction>) => {
        dispatch({ type: TeamsActionType.TOGGLE_EDIT_TEAM_NAME, payload: open });
    }
};

export function toggleAddTeamMemberHelper(open: boolean,
    options?: IInviteTeamMemberOptions) {
    store.dispatch({ type: TeamsActionType.TOGGLE_ADD_TEAM_MEMBER_SIDE_PANEL, payload: { open, options } });
}

export const toggleAddTeamMember = (
    open: boolean,
    options?: IInviteTeamMemberOptions) => {
    return (dispatch: Dispatch<TeamsAction>) => {
        dispatch({ type: TeamsActionType.TOGGLE_ADD_TEAM_MEMBER_SIDE_PANEL, payload: { open, options } });
    }
};

export const toggleAccess = (
    userId: string,
    teamId: number,
    active: boolean) => {
    return async (dispatch: Dispatch<TeamsAction>) => {
        dispatch({ type: TeamsActionType.TOGGLE_TEAM_MEMBER_ACCESS });

        try {
            const { data } = await toggleTeamMemberAccess({ userId, teamId, active });

            if (data) {
                processToast(data);
                if (data.isSuccess) {
                    dispatch({
                        type: TeamsActionType.TOGGLE_TEAM_MEMBER_ACCESS_SUCCESS,
                        payload: data.data
                    });
                }
            } else {
                dispatch({
                    type: TeamsActionType.TOGGLE_TEAM_MEMBER_ACCESS_ERROR,
                    payload: data.errorMessage
                });
            }

        } catch (err) {
            updateToastMessage('An error occurred whilst toggling the team members access', 'failure');
            dispatch({
                type: TeamsActionType.TOGGLE_TEAM_MEMBER_ACCESS_ERROR,
                payload: err.message
            })
        }
    }
};

export const cancelTeamMemberInvite = (
    contactId: string) => {
    return async (dispatch: Dispatch<TeamsAction>) => {
        dispatch({ type: TeamsActionType.CANCEL_TEAM_MEMBER_INVITE });

        try {
            const { data } = await deleteInvitation(contactId);

            if (data) {
                processToast(data);

                if (data.isSuccess) {
                    return dispatch({
                        type: TeamsActionType.CANCEL_TEAM_MEMBER_INVITE_SUCCESS,
                        payload: contactId
                    });
                }

                dispatch({
                    type: TeamsActionType.CANCEL_TEAM_MEMBER_INVITE_ERROR,
                    payload: data.errorMessage
                })
            }
        } catch (err) {
            updateToastMessage('An error occurred when adding team member', 'failure');
            dispatch({
                type: TeamsActionType.CANCEL_TEAM_MEMBER_INVITE_ERROR,
                payload: err.message
            })
        }
    }
};

export const addTeamMember = (
    request: IAddTeamMemberRequest) => {
    return async (dispatch: Dispatch<TeamsAction>) => {
        dispatch({ type: TeamsActionType.ADD_TEAM_MEMBER });

        try {
            const { data } = await addTeamMemberWithEmailApi(request);

            if (data) {
                if(data.isSuccess){
                    dispatch({
                        type: TeamsActionType.ADD_TEAM_MEMBER_SUCCESS,
                        payload: data.data
                    });

                    dispatch({
                        type: TeamsActionType.TOGGLE_ADD_TEAM_MEMBER_SIDE_PANEL,
                        payload: { open: false }
                    });

                    if(data.data) {
                        updateInvitationStatusForContactList({
                            isInvited: true,
                            isUserAccount: false,
                            isActiveUser: false,
                            invitedOn: '',
                            invitedBy: '',
                            isAccepted: false,
                            acceptedOn: null,
                        }, data.data.contactId);
                    }
                }else{
                    dispatch({
                        type: TeamsActionType.ADD_TEAM_MEMBER_ERROR,
                        payload: data && data.errorMessage
                    })
                }

                processToast(data);
            }
        } catch (err) {
            updateToastMessage('An error occurred when adding team member', 'failure');
            dispatch({
                type: TeamsActionType.ADD_TEAM_MEMBER_ERROR,
                payload: err.message
            })
        }
    }
};

export const getTeamMembers = (
    options: ITeamMembersListItemOptions) => {
    return async (dispatch: Dispatch<TeamsAction>) => {
        dispatch({ type: TeamsActionType.GET_TEAM_MEMBERS });
        try {
            const { data } = await getTeamMembersApi(options);
            dispatch({
                type: TeamsActionType.GET_TEAM_MEMBERS_SUCCESS,
                payload: data
            });
        } catch (err) {
            updateToastMessage('An error occurred when fetching team members', 'failure');
            dispatch({
                type: TeamsActionType.GET_TEAM_MEMBERS_ERROR,
                payload: err.message
            })
        }
    }
};

export const removeTeamMembers = (userTeamIds: number[]) => {
    return async (dispatch: Dispatch<TeamsAction>) => {
        dispatch({ type: TeamsActionType.REMOVE_TEAM_MEMBERS });
        try {
            const { data } = await removeTeamMembersApi(userTeamIds);
            if (data) {
                dispatch({
                    type: TeamsActionType.REMOVE_TEAM_MEMBERS_SUCCESS,
                    payload: data.data.removedIds
                });
                processToast(data);
            }
            else {
                dispatch({
                    type: TeamsActionType.REMOVE_TEAM_MEMBERS_ERROR,
                    payload: 'Failed to remove one or more members from the team'
                });
            }
        }
        catch (err) {
            dispatch({
                type: TeamsActionType.REMOVE_TEAM_MEMBERS_ERROR,
                payload: err.message
            });
        }
    }
};
