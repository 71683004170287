import React from "react";
import history from "../../../../../history";
import { ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-grids";
import { minWidthTablet } from "../../../../../global/variables/_variables";
import { currencyFormat } from "../_config/currencyConfig";
import TransactionAmount from "../components/TransactionAmount";
import TransactionStatusPill from "../../../../atoms/TransactionListItem/TransactionStatusPill";
import { Router } from "react-router-dom";
import AssociatedDocuments from "../../../../atoms/AssociatedDocuments";
import moment from "moment-timezone";
import UserContactInfo, { UserContactInfoType } from "../../../../atoms/UserContactInfo";
import CategoryPill from "../components/CategoryPill/CategoryPill.component";
import * as S from "./columnsTemplate.styles"
import styles from "../TransactionsGrid.module.scss"
import "./columnsTemplate.css"

const ColumnsTemplate = (props: any) => {
  const SummaryTemplate = (p) => {
    return (
      <S.OuterTitleDiv>
          <S.InnerTitleDiv>
              <S.TitleSpan onClick={() => props.openTransactionModal(p)}>{p.description}</S.TitleSpan>
          </S.InnerTitleDiv>
          <S.PropertyDiv onClick={() => props.openTransactionModal(p)}>{`${p.propertyName} ${p.unitName ? '-' : ''} ${p.unitName ? p.unitName : ''}`}</S.PropertyDiv>
          <S.ContactsDiv>{contactTemplate(p)}{categoryTemplate(p)}</S.ContactsDiv>
      </S.OuterTitleDiv>
    )
}
  
const SummaryMobileTemplate = (p) => {
  return (
      <S.OuterTitleDiv>
        <S.DateAndAmountDiv>{amountWithStatusMobile(p)}</S.DateAndAmountDiv>
          <S.InnerTitleDiv>
              <S.TitleSpan onClick={() => props.openTransactionModal(p)}>{p.description}</S.TitleSpan>
          </S.InnerTitleDiv>
          <S.PropertyDiv onClick={() => props.openTransactionModal(p)}>{`${p.propertyName} ${p.unitName ? '-' : ''} ${p.unitName ? p.unitName : ''}`}</S.PropertyDiv>
          <S.ContactsDiv>{contactTemplate(p)}{categoryTemplate(p)}</S.ContactsDiv>
      </S.OuterTitleDiv>
  )
}

const amountWithStatus = (p) =>{
  var a = moment();
  var b = moment(p.dueDate);  
  let daysDiff = a.diff(b, 'days');
  return (<>
    <div className={styles.mobileAmountStatusRow} onClick={() => props.openTransactionModal(p)}><TransactionAmount isPositive={p.isIncome} isNegative={p.isExpense} value={p.amount} /></div>
    {
      p.paidAmount != p.amount &&
      <div className={styles.mobileAmountStatusRow} onClick={() => props.openTransactionModal(p)}>{`(Paid: £${p.paidAmount ? p.paidAmount : 0})`}</div>
    }
    <div className={styles.mobileAmountStatusRow} onClick={() => props.openTransactionModal(p)}><TransactionStatusPill isPaid={p.isPaid} isOpen={p.isOpen} isOverdue={p.isOverdue} daysOver={daysDiff} /></div>
  </>)
}

const amountWithStatusMobile = (p) =>{
  var a = moment();
  var b = moment(p.dueDate);  
  let daysDiff = a.diff(b, 'days');
  return (<>
    <div className={styles.amountWithStatusMobileWrapper}>
      <div className={styles.dateMobile}>{moment(p.invoiceDate).format('DD/MM/YYYY')}</div>
      <div className={styles.amountMobile} onClick={() => props.openTransactionModal(p)}><TransactionAmount isPositive={p.isIncome} isNegative={p.isExpense} value={p.amount} /></div>
      {
        p.paidAmount != p.amount &&
        <div className={styles.paidAmountMobile} onClick={() => props.openTransactionModal(p)}>{`(Paid: £${p.paidAmount ? p.paidAmount : 0})`}</div>
      }
      <div  className={styles.amountStatusMobile} onClick={() => props.openTransactionModal(p)}><TransactionStatusPill isPaid={p.isPaid} isOpen={p.isOpen} isOverdue={p.isOverdue} daysOver={daysDiff} /></div>
    </div>
  </>)
}

const documents = (props) => {
  return (<Router history={history}><AssociatedDocuments
      showOnlyNoneText
      options={{
          transactionId: props.transactionId
      }}
      viewAll={false}
  /></Router>);
}

const categoryTemplate = (props) => {
  return(
      <div className={styles.mobileAmountStatusRow}><CategoryPill value={props.categoryName} /></div>
  );
}

const contactTemplate = (p) => {
  if(p.contactId){
      return(
          <S.ContactWrapper>
              <UserContactInfo
                  id={p.contactId}
                  type={UserContactInfoType.Contact}
              />
          </S.ContactWrapper>
      )
  }
}

const paidAmount = (props) => {
  return (<TransactionAmount isPositive={true} isNegative={false} value={props.paidAmount} />);
}

const template = (props) => {
  return (<>
    <div className={styles.mobileAmountStatusRow}><TransactionAmount isPositive={props.isIncome} isNegative={props.isExpense} value={props.amount} /></div>
    <div className={styles.mobileAmountStatusRow}><TransactionStatusPill isPaid={props.isPaid} isOpen={props.isOpen} isOverdue={props.isOverdue} /></div>
  </>)
}

const MobileAmountStatusColumnTemplate = () => {
  return (
    <ColumnDirective
      field="amount"
      hideAtMedia={`(max-width: ${minWidthTablet})`}
      template={template}
      width="150"
    />
  );
};

  return (
    <ColumnsDirective>
      <ColumnDirective
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        type="checkbox"
        customAttributes={{class: 'customcss'}}
        showInColumnChooser={false}
        showColumnMenu={false}
      />

      <ColumnDirective
        field="action"
        headerText=""
        textAlign="Left"
        customAttributes={{class: 'customcss'}}
        showInColumnChooser={false}
        showColumnMenu={false}
        allowSorting={false}
      />

      {/* {MobileAmountStatusColumnTemplate()} */}

      <ColumnDirective
        visible={false}
        field="invoiceMonthYear"
        headerText="Inv Month"
        type="date"
        format={{ type: "date", format: "MMM yyyy" }}
        textAlign="Left"
      />
      <ColumnDirective
        format={{ type: "date", format: "dd/MM/yy" }}
        type="date"
        textAlign="Left"
        headerText="Inv Date"
        field="invoiceDate"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        customAttributes={{class: 'customcss'}}
        showInColumnChooser={false}
        showColumnMenu={false}
      />
      {
        window.innerWidth >  768
        ?
        <ColumnDirective
          field="summary"
          headerText="Summary"
          textAlign="Left"
          template={SummaryTemplate}
          customAttributes={{class: 'customcss'}}
        />
        :
        <ColumnDirective
          field="summary"
          headerText="Summary"
          textAlign="Left"
          template={SummaryMobileTemplate}
          customAttributes={{class: 'customcss'}}
        />
      }
      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Description"
        field="description"
      />
      <ColumnDirective
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Right"
        headerText="Amount"
        template={amountWithStatus}
        field="amount"
        width="140"
      />
      <ColumnDirective
        visible={false}
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        headerText="Status"
        field="status"
        width="110"
      />
      <ColumnDirective
        visible={false}
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        headerText="Category"
        field="categoryName"
        width="120"
      />
      <ColumnDirective
          visible={props.showPMColumns}
          textAlign="Left"
          headerText="DContact"
          field="debitContactName"
      />
      <ColumnDirective
          visible={props.showPMColumns}
          textAlign="Left"
          headerText="CContact"
          field="creditContactName"
      />
      <ColumnDirective
        visible={false}
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        headerText="HMRC"
        field="hmrcReporting"
        minWidth="100"
        width="120"
      />
      <ColumnDirective
        visible={false}
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        headerText="Property"
        field="propertyName"
      />
      <ColumnDirective
        visible={false}
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        headerText="Unit"
        field="unitName"
        width="120"
      />
      <ColumnDirective
        visible={false}
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        type="date"
        format={{ type: "date", format: "dd/MM/yy" }}
        textAlign="Left"
        headerText="Due Date"
        field="dueDate"
      />
      <ColumnDirective
        visible={false}
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Right"
        headerText="Paid"
        type="string"
        //format={currencyFormat}
        field="paidAmount"
        width="100"
        template={paidAmount}
      />
      <ColumnDirective
        visible={false}
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        type="date"
        format={{ type: "date", format: "dd/MM/yy" }}
        textAlign="Left"
        headerText="Paid Date"
        field="paidDate"
        minWidth="98"
        width="98"
      />
      <ColumnDirective
        visible={false}
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        headerText="Type"
        field="accountType"
        width="100"
      />
      <ColumnDirective
        field="documents"
        headerText="Documents"
        textAlign="Left"
        minWidth="98"
        width="98"
        template={documents}
      />
      <ColumnDirective
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        field="dateAdded"
        headerText="Added"
        type="datetime"
        format="dd/MM/yyyy hh:mm a"
        textAlign="Left"
        maxWidth="115"
        width="115"
      />
      <ColumnDirective
        visible={false}
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        headerText="Reference"
        field="reference"
      />
      <ColumnDirective
        visible={false}
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        headerText="Notes"
        field="notes"
        width="110"
      />
      <ColumnDirective
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        headerText="Contact"
        field="contactName"
        width="160"
        visible={false}
      />
    </ColumnsDirective>
  );
};

export default ColumnsTemplate;