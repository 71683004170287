import { apiService } from './ApiService';
import * as domains from '../configuration/domains';
import { createBearerToken } from '../helpers/authHelpers';

export const getCoreVersion = (): Promise<any> => {
    return apiService.get(`${domains.coreVersionService}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getIdentityVersion = (): Promise<any> => {
    return apiService.get(`${domains.identityVersionService}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const callRollbarTestEndpoint = (): Promise<any> => {
    return apiService.get(`${domains.coreVersionService}/rollbar-test`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}