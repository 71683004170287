import React from 'react';
import { Button } from "@rentchief/components";
import * as S from "./CounterPart.styles";

interface props {
    id: string;
    value: any;
    type: string;
    hasAdd: boolean;
    addOnClick?(): void;
    valueOnClick?(id: string): void;
    numberFontSize?: string;
    nameFontSize?: string;
    color?: string;
}

const CounterPart = ({id, value, type, hasAdd, addOnClick, valueOnClick, numberFontSize, nameFontSize, color}: props) => {
    return (
        <S.CounterDiv>
            <S.NumberLabel fontSize={numberFontSize} color={color} onClick={() => valueOnClick && valueOnClick(id)}>{value}</S.NumberLabel>
            <S.NameLabel fontSize={nameFontSize} color={color}>{type}</S.NameLabel>
            {
                hasAdd
                ?
                <S.AddButtonDiv><Button onClick={() => addOnClick && addOnClick()}>Add</Button></S.AddButtonDiv>
                :
                <div></div>
            }
            
        </S.CounterDiv>
    )
}

export default CounterPart;