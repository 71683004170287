import styled from 'styled-components';

export const SectionDiv = styled.div`
    height: 120px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-y: auto;
`; 

export const ActionDiv = styled.div`
    display: flex;
    width: 150px;
`;

export const ButtonWrapperDiv = styled.div`
    width: 80px;
    margin-right: 5px;
`;