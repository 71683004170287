import { apiService } from './ApiService';
import * as domains from '../configuration/domains';
import { createBearerToken } from '../helpers/authHelpers';
import { IAddress } from '../types/PropertyService/IPropertyService';

export const getPricePaidData = (address: IAddress): Promise<any> => {
    return apiService.post(`${domains.landRegistryService}`, address, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getNearbyPropertySales = (postcode: string, dateFrom: string, dateTo: string, tenure: string, propertyType: string): Promise<any> => {
    let tenureParam = tenure ? `&tenure=${tenure}` : ``;
    let propertyTypeParam = propertyType ? `&propertyType=${propertyType}` : ``;

    return apiService.get(`${domains.landRegistryService}/nearby/${postcode}?dateFrom=${dateFrom}&dateTo=${dateTo}${tenureParam}${propertyTypeParam}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}