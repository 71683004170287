import React from "react";
import { Col, Row } from "reactstrap";
import { withRouter } from "react-router-dom";

import styles from "./RoleListItem.module.scss";
import deleteIcon from "../../../images/icons/property-action/Delete.png";
import editIcon from "../../../images/icons/property-action/Edit.png";
import moreIcon from "../../../images/icons/property-action/Action.png";
import { IRole } from "../../../types/RolesService";
import DateTimeFormat from "../DateTimeFormat";
import EllipsisText from "../EllipsisText";

interface IProps {
  role: IRole;
}

interface IWithRouterProps {
  history: any;
  location: any;
  match: any;
}

type Props = IProps & IWithRouterProps;

class RoleListItem extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.state = {};

    this.onNavigateToRole = this.onNavigateToRole.bind(this);
  }

  onNavigateToRole(): void {
    this.props.history.push(
      "/dashboard/manage/role/" + this.props.role.roleId
    );
  }

  render() {
    const { role } = this.props;
    return (
      <Row className={styles.userItem} onClick={() => this.onNavigateToRole()}>
        <Col>
          <div className={styles.label}>Role Name</div>
          {role.roleName}
        </Col>
        <Col>
          <div className={styles.label}>Description</div>
          <EllipsisText length={40} text={role.roleDescription} />
        </Col>
        <Col>
          <div className={styles.label}>Created date</div>
          <DateTimeFormat showOnlyDateFormat rawDateTime={role.roleAddedAt} />
        </Col>
        <Col className={styles.icons}>
          <img src={editIcon} />
        </Col>
      </Row>
    );
  }
}

export default withRouter(RoleListItem);
