import React from 'react'
import { withRouter } from 'react-router-dom'
import { generateId } from '../../../helpers/randomHelpers'
import { QuickViewType } from '../../../types/QuickView'
import CouncilInformationPopup from '../CouncilInformationPopup'
import EllipsisText from '../EllipsisText'
import GenericPopup from '../GenericPopup'
import styles from './PropertySummaryComponent.module.scss'

interface IPropertySummaryComponentData {
    propertyId: string
    propertyName: string
    propertyType: string
    yearBuilt: number
    council?: string
    epcRating?: string
    propertyStatus?: string
}

interface IClassNames {
    propertyType?: string
}

interface IProps {
    history: any
    location: any
    match: any

    data: IPropertySummaryComponentData
    classNames?: IClassNames

    showPropertyName?: boolean
    showPropertyType?: boolean
    showPropertyFriendlyName?: boolean
    showPropertyAddress?: boolean
    showPropertyPopupIcon?: boolean
    showLocalCouncilInformation?: boolean
    showPropertyYearBuilt?: boolean
    showPropertyEPCRating?: boolean
    showShortPropertyName?: boolean
}

const PropertySummaryComponent = (props: IProps) => {
    const PropertyName = () => {
        return (
            <div className={styles.propertyName}>
                {props.showShortPropertyName ? (
                    <EllipsisText text={props.data.propertyName} length={30} />
                ) : (
                    props.data.propertyName
                )}
            </div>
        )
    }

    const PropertyType = () => {
        return (
            <div
                className={`${styles.propertyType} ${
                    props.classNames && props.classNames.propertyType
                        ? props.classNames.propertyType
                        : ''
                }`}
            >
                {props.data.propertyType} Property
            </div>
        )
    }

    const PropertyYearBuilt = () => {
        return props.data.yearBuilt ? (
            <div className={styles.propertyYearBuilt}>
                built in {props.data.yearBuilt}
            </div>
        ) : null
    }

    const GenerateClassName = (append: string) => {
        switch (append) {
            case 'A':
                return styles.a

            case 'B':
                return styles.b

            case 'C':
                return styles.c

            case 'D':
                return styles.d

            case 'E':
                return styles.e

            case 'F':
                return styles.f

            case 'G':
                return styles.g

            default:
                break
        }
    }

    const Row: React.FunctionComponent<any> = (props) => {
        return <div className={styles.row}>{props.children}</div>
    }

    const Col: React.FunctionComponent<any> = (props) => {
        return (
            <div className={`${props.auto ? styles.autoCol : styles.column}`}>
                {props.children}
            </div>
        )
    }

    const {
        showPropertyName,
        showPropertyType,
        showPropertyPopupIcon,
        showLocalCouncilInformation,
        showPropertyYearBuilt,
        showPropertyEPCRating,
        data,
    } = props

    return (
        <div className={styles.propertySummaryCard}>
            {/* Property Name and Property Popup Icon */}
            {(showPropertyName || showPropertyPopupIcon) && (
                <div className={styles.row}>
                    <div className={styles.title}>{data.propertyStatus}</div>
                    <div className={styles.autoColumn}>
                        {showPropertyName && <PropertyName />}
                    </div>

                    {showPropertyPopupIcon && (
                        <div className={styles.column}>
                            <span className={styles.icon}>
                                <GenericPopup
                                    identifier={generateId(10)}
                                    options={{
                                        type: QuickViewType.Property,
                                        propertyId: data.propertyId,
                                    }}
                                />
                            </span>
                        </div>
                    )}
                </div>
            )}

            {/* Property Type and Property Year Built */}
            {(showPropertyType || showPropertyYearBuilt) && (
                <Row>
                    <Col>
                        <div>
                            {showPropertyType && <PropertyType />}
                            {showPropertyType && showPropertyYearBuilt && ' '}
                            {showPropertyYearBuilt && <PropertyYearBuilt />}
                        </div>
                    </Col>
                </Row>
            )}

            {/* Council */}
            {showLocalCouncilInformation && data.council && (
                <Row>
                    <Col>
                        {showPropertyType && (
                            <div>
                                <span>{data.council}</span>
                                <CouncilInformationPopup
                                    council={data.council}
                                />
                            </div>
                        )}
                    </Col>
                </Row>
            )}

            {/* EPC Rating */}
            {showPropertyEPCRating && data.epcRating && (
                <Row>
                    <Col>
                        {
                            <div>
                                <span>EPC Rating</span>{' '}
                                <span
                                    className={`${
                                        styles.circle
                                    } ${GenerateClassName(data.epcRating)}`}
                                >
                                    {data.epcRating}
                                </span>
                            </div>
                        }
                    </Col>
                </Row>
            )}
        </div>
    )
}

export default withRouter(PropertySummaryComponent)
