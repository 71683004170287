import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

// Import Styles
import AuditHighlight from '../../PlatformManagementPage/AuditHighlight'
import 'react-chat-elements/dist/main.css'
import WebsocketService from '../../../../services/SignalRService'
import {
    getMessages,
    startDiscussion,
    sendMessage,
    IGetMessageResponse,
} from '../../../../services/ChatService'
import moment from 'moment'
import ChatBox from '../../../atoms/ChatBox'
import EditIssue from '../../../atoms/EditIssue'
import styles from './IssueSummary.module.scss'

interface IWithRouterProps {
    history: any
    location: any
    match: any
}

interface IState {
    chatSessionId: string
    messages: Array<any>
    newMessage: string
    chatLoading: boolean
    editBoxHeight: number;
}

class IssueSummary extends Component<IWithRouterProps, IState> {
    editBox

    constructor(props: any) {
        super(props)

        this.editBox = React.createRef()

        this.state = {
            chatSessionId: '',
            messages: [],
            newMessage: '',
            chatLoading: false,
            editBoxHeight: 0
        }

        this.messageAdded = this.messageAdded.bind(this)
        this.onSend = this.onSend.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.getChats = this.getChats.bind(this)
        this.startDiscussion = this.startDiscussion.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
    }

    messageAdded(msg: any) {
        this.setState({
            messages: this.state.messages.concat({
                position: 'right',
                type: 'text',
                text: msg,
                date: new Date(),
            }),
        })
    }

    componentDidMount(): void {
        this.getChats()
        window.visualViewport.addEventListener('resize', () => {
            if(this.editBox && this.editBox.current){
            console.debug(`editboxresize: ${this.editBox.current.clientHeight}`); 
            this.setState({editBoxHeight: this.editBox.current.clientHeight});
        }});
        if(this.editBox && this.editBox.current){
            console.debug(`editboxresize: ${this.editBox.current.clientHeight}`); 
            this.setState({editBoxHeight: this.editBox.current.clientHeight});
        }
    }

    componentWillReceiveProps(newProps: IWithRouterProps): void {
        if (newProps.match.params.issueId == this.props.match.params.issueId) {
            return
        }
        this.getChats()
    }

    getChats(): void {
        this.setState({
            chatLoading: true,
        })
        getMessages({
            issueId: this.props.match.params.issueId,
        })
            .then((resp) => {
                if (resp && resp.status == 200) {
                    let result: IGetMessageResponse = resp.data
                    let arr: Array<any> = []
                    result.messages.map((r) => {
                        arr = arr.concat({
                            position: r.isCurrentUserSender ? 'left' : 'right',
                            type: 'text',
                            text: r.message,
                            date: moment(r.dateAdded).local().toDate(),
                        })
                    })
                    this.setState({
                        chatSessionId: result.chatSessionId,
                        messages: arr,
                    })
                }
                this.setState({
                    chatLoading: false,
                })
            })
            .catch((e) => {
                this.setState({
                    chatLoading: false,
                })
            })
    }

    startDiscussion(): void {
        this.setState({
            chatLoading: true,
        })
        startDiscussion({
            issueId: this.props.match.params.issueId,
        }).then((resp) => {
            if (resp && resp.status == 200) {
                this.getChats()
            }
            this.setState({
                chatLoading: false,
            })
        })
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.onSend()
        }
    }

    onSend(): void {
        if (!this.state.newMessage) {
            return
        }

        sendMessage({
            chatSessionId: this.state.chatSessionId,
            message: this.state.newMessage,
        }).then((resp) => {
            if (resp && resp.status == 200) {
                this.setState(
                    {
                        messages: this.state.messages.concat({
                            position: 'left',
                            type: 'text',
                            text: this.state.newMessage,
                            date: new Date(),
                        }),
                    },
                    () =>
                        this.setState({
                            newMessage: '',
                        })
                )
            }
        })
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const value: string = event.currentTarget.value
        this.setState({ newMessage: value } as Pick<IState, any>)
    }

    render() {
        return (
            <div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <div className={styles.ds} ref={this.editBox}>
                            <EditIssue
                                options={{
                                    issueId: this.props.match.params.issueId,
                                }}
                            />
                        </div>
                        <div className={styles.chat}>
                            <ChatBox
                                isNested
                                fullChatLink
                                editBoxHeight={this.state.editBoxHeight}
                                options={{
                                    issueId: this.props.match.params.issueId,
                                }}
                            />
                        </div>
                    </div>

{/*                     <div className={styles.columnSmall}>
                        <AuditHighlight
                            showDescription
                            issueId={this.props.match.params.issueId}
                        />
                    </div> */}
                </div>
            </div>
        )
    }
}

export default withRouter(IssueSummary)
