import React from "react";
import { goToRoute } from "../../../history";
import styles from "./Breadcrumbs.module.scss";

export interface IBreadcrumbItem {
  name: string;
  route?: string;
  ordinal?: number;
}

interface IProps {
  breadcrumbs: Array<IBreadcrumbItem>;
}

class Breadcrumbs extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div className={styles.breadcrumbs}>
        <ul>
          {
            this.props.breadcrumbs.sort(x => x.ordinal).map(((x, i) => {
              if (i == this.props.breadcrumbs.length - 1) {
                return <li onClick={() => goToRoute(x.route)}>{x.name}</li>
              }
              return <li><span className={styles.link} onClick={() => x.route ? goToRoute(x.route) : null}>{x.name}</span><span className={styles.seperator}>&gt;</span></li>
            }))
          }
          <li></li>
        </ul>
      </div>
    );
  }
}

export default Breadcrumbs;
