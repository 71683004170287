import { AnyARecord } from "dns";
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Tooltip } from "react-tippy";
import styles from "./NestedSimpleRouterTabs.module.scss";

export interface IRouterTab {
  text: string;
  path: string;
  disabled?: boolean;
  icon?: string;
  suffix?: any;
  fullWidth?: boolean;
  maxWidthPercentage?: string;
  tooltip?: string;
  id?: string;
  hide?: boolean;
}

interface IProps {
  routes: IRouterTab[];
  history: any;
  match: any;
  location: any;
}

class NestedSimpleRouterTabs extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  navigateToTabRoute(route: string): void {
    this.props.history.push(route);
  }

  render() {
    const { routes } = this.props;

    return (
      <div className={styles.simpleRouterTabs}>
        <nav>
          <ul>
            {routes.map((route, index) => {
              const isActive =
                this.props.location.pathname ==
                `${this.props.match.url}/${route.path}` ||
                this.props.location.pathname.indexOf(route.path) > -1;
              const isDisabled = route.disabled;
              if (route.hide) {
                return null;
              }
              return (
                <>
                  {route.tooltip && (
                    <Link
                      id={route.id && route.id}
                      style={{
                        maxWidth: route.maxWidthPercentage
                          ? route.maxWidthPercentage
                          : null,
                      }}
                      className={`${
                        isDisabled ? styles.disabledLink : styles.link
                        } ${isActive ? styles.activeA : null}`}
                      to={route.path}
                      key={index}
                    >
                      <Tooltip
                        theme={"light"}
                        html={
                          <div className={styles.tooltipContent}>
                            <p>{route.tooltip}</p>
                          </div>
                        }
                        //position="top"
                        trigger="mouseenter"
                        arrow
                        interactive={true}
                        distance={110}
                      >
                        <li
                          className={`li ${
                            isDisabled
                              ? styles.disabledLi
                              : isActive
                                ? styles.activeLi
                                : null
                            }`}
                        >
                          {route.icon && (
                            <img
                              src={route.icon}
                              className={styles.icon}
                              alt={route.text}
                            />
                          )}
                          {route.text}

                          {route.suffix && route.suffix}
                        </li>
                      </Tooltip>
                    </Link>
                  )}
                  {!route.tooltip && (
                    <Link
                      style={{
                        maxWidth: route.maxWidthPercentage
                          ? route.maxWidthPercentage
                          : null,
                      }}
                      className={`${
                        isDisabled ? styles.disabledLink : styles.link
                        } ${isActive ? styles.activeA : null}`}
                      to={route.path}
                      key={index}
                    >
                      <li
                        className={`li ${
                          isDisabled
                            ? styles.disabledLi
                            : isActive
                              ? styles.activeLi
                              : null
                          }`}
                      >
                        {route.icon && (
                          <img
                            src={route.icon}
                            className={styles.icon}
                            alt={route.text}
                          />
                        )}
                        {route.text}

                        {route.suffix && route.suffix}
                      </li>
                    </Link>
                  )}
                </>
              );
            })}
          </ul>
        </nav>
      </div>
    );
  }
}

export default withRouter(NestedSimpleRouterTabs);
