import { ConductInspectionAction, ConductInspectionActionType } from "../../types/ConductInspection";
import { IConductInspectionDto, IInspectionsResultDto, IInspectionsTemplateDto } from "../../types/Dynamic/types";

interface IConductInspectionState {
    loading: boolean;
    templates: IInspectionsTemplateDto[];
    inspections: IConductInspectionDto[];
    viewing_inspection: IInspectionsResultDto;
    isError: boolean;
    error: string;
}

const initialState: IConductInspectionState = {
    loading: false,
    templates: [],
    inspections: [],
    isError: false,
    error: null,
    viewing_inspection: null
}

const reducer = (
    state: IConductInspectionState = initialState,
    action: ConductInspectionAction
): IConductInspectionState => {
    switch (action.type) {
        case ConductInspectionActionType.SAVE_INSPECTION:
            return {
                ...state,
                error: null,
            }
        case ConductInspectionActionType.SAVE_INSPECTION_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case ConductInspectionActionType.SAVE_INSPECTION_SUCCESS:
            return {
                ...state,
            }
        case ConductInspectionActionType.SET_VIEWING_INSPECTION:
            return {
                ...state,
                loading: false,
                error: null,
                viewing_inspection: action.payload
            }
        case ConductInspectionActionType.GET_INSPECTION:
            return {
                ...state,
                loading: true,
                isError: false,
                error: null,
                viewing_inspection: null
            }
        case ConductInspectionActionType.GET_INSPECTION_SUCCESS:
            return {
                ...state,
                loading: false,
                viewing_inspection: action.payload
            }
        case ConductInspectionActionType.GET_INSPECTION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isError: true
            }
        case ConductInspectionActionType.GET_CONDUCT_INSPECTION_TEMPLATES:
            return {
                ...state,
                loading: true,
                isError: false,
                error: null
            }
        case ConductInspectionActionType.GET_CONDUCT_INSPECTION_TEMPLATES_SUCCESS:
            return {
                ...state,
                loading: false,
                templates: action.payload
            }
        case ConductInspectionActionType.GET_CONDUCT_INSPECTION_TEMPLATES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isError: true
            }
        default:
            return state;
    }
};

export default reducer;
