import React from "react";
import styles from "./UserContactInfo.module.scss";
import ProfileImage from "../ProfileImage";
import {
  IUserContactInfoRequest,
  IUserContactInfoResponse,
  QuickViewType
} from "../../../types/QuickView";
import { getUserContactInfo } from "../../../services/QuickViewService";
import GenericPopup from "../GenericPopup";
import { generateId } from "../../../helpers/randomHelpers";
import ContentLoading from "../ContentLoader";
import userIcon from "../../../images/icons/user_account.svg";
import { withRouter } from "react-router-dom";
import { IReactRouterProps } from "../../../types";
import { addToUserContactInfoPopup, clearUserContactInfoPopup, getUserContactInfoDataFromState } from "../../../helpers/contactHelpers";

export enum UserContactInfoType {
  User,
  Contact
}

export interface IClassNames {
  /* A way to target the elements styling*/
  row?: string;
  name?: string;
  typeOrRole?: string;
  wrapper?: string;
  image?: string;
  textWrapper?: string;
  userIcon?: string;
}

interface IProps {
  type: UserContactInfoType;
  id: string;
  classNames?: IClassNames;
  manualOverride?: IUserContactInfoResponse;
  disablePopup?: boolean;
  onImageClick?(): void;
}

interface IState {
  data: IUserContactInfoResponse;
  loading: boolean;
}

type Props = IProps;

class UserContactInfo extends React.Component<Props, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      data: null,
      loading: false
    };
  }

  componentDidMount(): void {
    if (this.props.manualOverride) {
      this.setState({
        data: this.props.manualOverride
      });
      return;
    }

    if (!this.props.id) {
      return;
    }

    if (this.props.type == UserContactInfoType.Contact) {
      let hasContactData = getUserContactInfoDataFromState(this.props.id);
      if (hasContactData) {
        this.setState({
          data: hasContactData
        });
        return;
      }
    }

    if (this.props.type == UserContactInfoType.User) {
      let hasUserData = getUserContactInfoDataFromState(this.props.id);
      if (hasUserData) {
        this.setState({
          data: hasUserData
        });
        return;
      }
    }

    let request: IUserContactInfoRequest = {};
    if (this.props.type == UserContactInfoType.Contact) {
      request.contactId = this.props.id;
    }

    if (this.props.type == UserContactInfoType.User) {
      request.userId = this.props.id;
    }

    this.setState({
      loading: true
    });
    getUserContactInfo(request).then(resp => {
      if (resp && resp.status == 200 && resp.data) {
        this.setState({
          data: resp.data
        });
        addToUserContactInfoPopup(resp.data);
      }
      this.setState({
        loading: false
      })
    }).catch(e => {

    });
  }

  componentWillUnmount(): void {
    clearUserContactInfoPopup();
  }

  componentWillReceiveProps(newProps: IProps): void {
    if (newProps.manualOverride != this.props.manualOverride) {
      this.setState({
        data: newProps.manualOverride
      });
      return;
    }

    if (newProps.id == this.props.id) {
      return;
    }

    if (newProps.id != this.props.id) {
      let request: IUserContactInfoRequest = {};
      if (newProps.type == UserContactInfoType.Contact) {
        request.contactId = newProps.id;
      }

      if (newProps.type == UserContactInfoType.User) {
        request.userId = newProps.id;
      }

      this.setState({
        loading: true
      });
      getUserContactInfo(request).then(resp => {
        if (resp && resp.status == 200 && resp.data) {
          this.setState({
            data: resp.data
          });
        }
        this.setState({
          loading: false
        });
      }).catch(e => {

      });
    }
  }

  render() {
    if (this.state.loading) {
      return <ContentLoading loading={true} />;
    }

    if (!this.state.data) {
      return null;
    }

    return (
      <div
        className={`${styles.userContactInfo} ${this.props.classNames &&
          this.props.classNames.wrapper}`}
      >
        <div
          className={`${styles.row} ${this.props.classNames &&
            this.props.classNames.row}`}
          id={"profile"}
        >
          <div
            className={`${styles.image} ${this.props.classNames &&
              this.props.classNames.image}`}
            onClick={this.props.onImageClick && this.props.onImageClick}
          >
            {this.props.disablePopup ? (
              <ProfileImage
                forename={this.state.data.forename}
                surname={this.state.data.surname}
                image={this.state.data.profileImage}
                large={false}
              />
            ) : (
              <GenericPopup
                identifier={generateId(10)}
                options={{
                  userId: this.props.id,
                  contactId: this.props.id,
                  type:
                    this.props.type == UserContactInfoType.User
                      ? QuickViewType.User
                      : QuickViewType.Contact,
                  toggleComponent: (
                    <div>
                      <ProfileImage
                        forename={this.state.data.forename}
                        surname={this.state.data.surname}
                        image={this.state.data.profileImage}
                        large={false}
                      />
                    </div>
                  )
                }}
              />
            )}
          </div>

          <div
            className={`${styles.name} ${this.props.classNames &&
              this.props.classNames.textWrapper}`}
          >
            <div className={styles.row}>
              <div
                className={`${styles.column} ${this.props.classNames &&
                  this.props.classNames.name &&
                  this.props.classNames.name}`}
              >
                {this.state.data.forename} {this.state.data.surname}
              </div>
            </div>
            <div className={styles.row}>
              <div
                className={`${styles.column} ${this.props.classNames &&
                  this.props.classNames.typeOrRole &&
                  this.props.classNames.typeOrRole}`}
              >
                <span className={styles.typeOrRole}>
                  {this.state.data.isUser && (
                    <img
                      className={`${styles.icon} ${this.props.classNames &&
                        this.props.classNames.userIcon &&
                        this.props.classNames.userIcon}`}
                      src={userIcon}
                    />
                  )}
                  <span
                    className={`${this.state.data.isUser ? styles.isUser : ""}`}
                  >
                    {this.state.data.typeOrRole}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserContactInfo;