import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { getMessagesList, IMessageListItem } from "../../../services/ChatService";
import { updateChatList, addChatToChatList } from "../../../helpers/chatHelpers";
import SplitListPage from "../SplitListPage";
import { toggleChatListCollapse } from "../../../helpers/sidebarHelpers";
import { IListItemOptions } from "../../../types/ListFilters";
import { changeChatSessionIdUrlPathParameter } from "../../../helpers/locationHelpers";
import ChatList from "../ChatList";
import ChatBox from "../../atoms/ChatBox";
import { isMobileOnly } from "react-device-detect";
import ChatEmptyState from "../EmptyState/ChatEmptyState/ChatEmptyState.component";
import chatIcon from "../../../images/icons/menu_chat.svg";
import RotatingLoader from "../../atoms/RotatingLoader";
import styles from "./ChatListPage.module.scss";

interface IProps {
  history: any;
  match: any;
  location: any;

  chat_list: Array<IMessageListItem>;
  chat_list_collapsed: boolean;

  options?: IListItemOptions;
}

interface IState {
  selectedSessionId: string;
  selectedSessionIndex: number;
  loading: boolean;
  sessions: Array<IMessageListItem>;
  isAddingChat: boolean;

  page: number;
  totalResults: number;
  resultAmount: number;
}

export interface ITenancyOptions { }

class ChatListPage extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedSessionId: '',
      selectedSessionIndex: null,
      loading: false,
      sessions: [],
      isAddingChat: false,
      page: 1,
      totalResults: 0,
      resultAmount: 20
    };

    this.getMessages = this.getMessages.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
    this.addActiveSessionToUrl = this.addActiveSessionToUrl.bind(this);
    this.onAddChatCallback = this.onAddChatCallback.bind(this);
  }

  componentWillUnmount(): void {
    updateChatList([]);
  }

  componentWillReceiveProps(newProps: IProps): void {
    let newParam = newProps.match.params.chatSessionId;

    if (!newParam && newParam != this.state.selectedSessionId) {
      return;
    }

    this.setState({
      selectedSessionId: newParam
    });
  }

  addActiveSessionToUrl(chatSessionId: string): void {
    if (!chatSessionId) {
      return;
    }
    let path = changeChatSessionIdUrlPathParameter(this.props.match, chatSessionId);
    this.props.history.push(path);
  }

  componentDidMount(): void {
    this.getMessages();

    if (this.props.match.params.chatSessionId && isMobileOnly) {
      toggleChatListCollapse(false);
    }
  }

  getMessages(clearList?: boolean): void {
    this.setState({
      loading: true
    });

    let options: IListItemOptions = {
      page: this.state.page,
      size: this.state.resultAmount
    }

    if (this.props.options) {
      if (this.props.options.propertyId) {
        options.propertyId = this.props.options.propertyId;
      }

      if (this.props.options.userId) {
        options.userId = this.props.options.userId;
      }

      if (this.props.options.contactId) {
        options.contactId = this.props.options.contactId;
      }
    }

    getMessagesList(options).then(resp => {
      if (resp && resp.status == 200 && resp.data) {
        if (resp.data) {
          this.setState({
            page: resp.data.nextPage,
            totalResults: resp.data.totalCount
          });
        }
        if (resp.data.data.length === 0) {
          this.setState({
            loading: false
          });
          return;
        }
        //updateChatList(this.state.sessions.concat(resp.data.data));
        let list: Array<any> = resp.data.data;
        let selectedSessionId: string;
        let selectedSessionIndex: number;
        if (this.props.match.params.chatSessionId) {
          selectedSessionId = this.props.match.params.chatSessionId;
          let index = list.findIndex(x => x.chatSessionId == selectedSessionId);
          if (index > -1) {
            selectedSessionIndex = index;
          }
        } else {
          selectedSessionId = list[0].chatSessionId,
            selectedSessionIndex = 0;
        }
        this.setState({
          selectedSessionId: selectedSessionId,
          selectedSessionIndex: selectedSessionIndex,
          sessions: this.state.sessions.concat(resp.data.data)
        }, () => {
          if (!this.props.match.params.chatSessionId) {
            this.addActiveSessionToUrl(this.state.selectedSessionId);
          }
        });
      }
      this.setState({
        loading: false
      });
    })
  }

  onClickSearch(chatSessionId: string, selectedIndex: number): void {
    this.setState({
      selectedSessionIndex: selectedIndex,
    });

    this.addActiveSessionToUrl(chatSessionId);
  }

  onAddChatCallback(item: IMessageListItem): void {
    addChatToChatList(item);
    let newPath = changeChatSessionIdUrlPathParameter(this.props.match, item.chatSessionId);
    this.props.history.push(newPath);
  }

  render() {
    if(this.state.loading){
      return <RotatingLoader loading={true} text="Loading chats..." />
    }
    return (
      <div className={styles.page}>
        {
          (this.props.chat_list && this.props.chat_list.length > 0) || this.state.totalResults > 0
          ?
          <SplitListPage
            listClassName={styles.list}
            detailsClassName={styles.details}
            collapsed={this.props.chat_list_collapsed}
            toggleCollapse={() => toggleChatListCollapse(!this.props.chat_list_collapsed)}
            listComponent={<ChatList options={this.props.options} />}
            detailComponent={
              this.props.match.params.chatSessionId
                ? <ChatBox options={{ chatSessionId: this.props.match.params.chatSessionId }} />
                : <div className={styles.noChatSelected}>
                  <img src={chatIcon} />
                  <p>No chat selected. Please select a chat or create a new chat</p>
                </div>
            } 
          />
          :
          <ChatEmptyState listOptions={this.props.options} />
        }
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  chat_list: state.chat.chat_list,
  chat_list_collapsed: state.app.sidebars.chat_list_collapsed
});

export default connect(mapStateToProps)(withRouter(ChatListPage));