import React, { Component } from "react";
import styles from "./TransactionsGrid.module.scss";
import { withRouter } from "react-router-dom";
import TabbedNavigationContainer from "../../../atoms/TabbedNavigationContainer";
import NestedSimpleRouterTabs from "../../../atoms/NestedSimpleRouterTabs";
import Col from "reactstrap/lib/Col";
import { isMobile } from "react-device-detect";

interface IProps {
  history: any;
  location: any;
  match: any;
}

class TransactionsTabs extends Component<IProps, {}> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div className={styles.tabNav}>
        <TabbedNavigationContainer fullWidth isSubMenu={true}>
          <Col className={styles.tabs}>
            <NestedSimpleRouterTabs
              routes={[
                {
                  id: "financial_tabs_income_tab",
                  path:
                    this.props.match.url.substring(
                      0,
                      this.props.match.url.lastIndexOf("/")
                    ) + "/income",
                  text: "Income",
                  mobileText: "Income",
                  tooltip:
                    isMobile ? null : "Displaying income transaction types for the last 3 months and next 3 months.",
                },
                {
                  id: "financial_tabs_expense_tab",
                  path:
                    this.props.match.url.substring(
                      0,
                      this.props.match.url.lastIndexOf("/")
                    ) + "/expense",
                  text: "Expense",
                  mobileText: "Expense",
                  tooltip:
                    isMobile ? null : "Displaying expense transaction types for the last 3 months and next 3 months.",
                },
                {
                  id: "financial_tabs_pl_tab",
                  path:
                    this.props.match.url.substring(
                      0,
                      this.props.match.url.lastIndexOf("/")
                    ) + "/pl",
                  text: "P&L",
                  mobileText: "P&L",
                  tooltip:
                    isMobile ? null : "Displaying all transaction types for the last 3 months and next 3 months.",
                },
                {
                  id: "financial_tabs_hmrc_tab",
                  path:
                    this.props.match.url.substring(
                      0,
                      this.props.match.url.lastIndexOf("/")
                    ) + "/HMRC",
                  text: "HMRC",
                  mobileText: "HMRC",
                  tooltip:
                    isMobile ? null : "Displaying all transaction types grouped by HMRC Categories and for the last financial year.",
                },
              ]}
            />
          </Col>
        </TabbedNavigationContainer>
      </div>
    );
  }
}

export default withRouter(TransactionsTabs);