import React, { useEffect, useState } from "react";
import Breadcrumbs, { IBreadcrumbItem } from "../../atoms/Breadcrumbs";
import styles from "./AddAsset.module.scss";
import AssetAddEditForm from "../AssetAddEditForm";
import { withRouter } from "react-router-dom";
import { IAssetAddEdit } from "../../../types/Assets";
import { removeURLParameter } from "../../../helpers/locationHelpers";
const queryString = require("query-string");

const generateBreadcrumbs = (options: IAssetAddEdit): Array<IBreadcrumbItem> => {
  if (!options || !options.referer) {
    return [{
      name: 'ASSETS',
      route: '/dashboard/assets'
    },
    {
      name: 'ADD ASSET'
    }];
  }

  let result: Array<IBreadcrumbItem> = [];

  if (options.referer === 'property') {
    result.push({
      name: options.propertyName,
      route: `/dashboard/property/${options.propertyId}`
    })
  }

  if (options.referer === 'unit') {
    result.push({
      name: options.propertyName,
      route: `/dashboard/property/${options.propertyId}`
    }, {
      name: options.unitName,
      route: `/dashboard/unit/view/${options.unitId}`
    })
  }

  result.push({
    name: 'ADD ASSET'
  });

  return result;
}

const AddAsset = (props: any) => {
  const [options, setOptions] = useState<IAssetAddEdit>(null);
  const [render, setRender] = useState<boolean>(false);
  const [images, setImages] = useState<string[]>(null);
  const [uploadImages, setUploadImages] = useState(false);

  useEffect(() => {
    let options = queryString.parse(props.location.search).assetsOptions;
    if (options) {
      let json: IAssetAddEdit = JSON.parse(options);
      if (!json) {
        return;
      }

      setOptions(json);

      let removeQuery = removeURLParameter(props.location.search, "assetsOptions");
      props.history.replace({
        search: removeQuery
      })
    }

    setRender(true);
  }, []);

  const Header = () =>
    <div className={styles.headerBar}>
      <div className={styles.breadcrumbs}>
        <Breadcrumbs
          breadcrumbs={generateBreadcrumbs(options)}
        />
      </div>
    </div>

  if (!render) return null;

  return (
    <div className={styles.addAsset}>
      {/* Header bar */}
      <Header />

      <div className={styles.content}>
        {/* Form */}
        <div className={styles.form}>
          <AssetAddEditForm assetOptions={{ ...options, images }} />
        </div>
      </div>
    </div>
  )
}

export default withRouter(AddAsset);