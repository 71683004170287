import React from 'react';
import { Tooltip } from "react-tippy";
import infoIcon from '../../../../../images/icons/ic_info_24px.png'
import * as S from "./LabelWithIconPart.styles";

interface props {
    id: string;
    labelText: string;
    iconUrl: string;
    onClick(): void;
    tooltipText?: string;
}

const LabelWithIconPart = ({id, labelText, iconUrl, onClick, tooltipText}: props) => {
    return (
        <S.LabelDiv onClick={() => onClick()}>
            <S.IconImage src={iconUrl} />
            <S.LabelText>
                {labelText}
                {" "}
                {
                    tooltipText
                    ?
                    <Tooltip
                        theme="light"
                        html={<p>{tooltipText}</p>}
                        position="right"
                        trigger="mouseenter"
                        arrow
                        interactive={true}
                    >
                        <img src={infoIcon} alt="Info" />
                    </Tooltip>
                    :
                    ""
                }
            </S.LabelText>
        </S.LabelDiv>
    )
}

export default LabelWithIconPart;