import React from 'react'
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import { addChatToChatList } from '../../../helpers/chatHelpers'
import { canInviteUsers } from '../../../helpers/permissionHelpers'
import {
    getLoggedInUsersContactId,
    updateInvitationStatusForContactList,
} from '../../../helpers/profileHelpers'
import { IChatOptions, startDiscussion } from '../../../services/ChatService'
import {
    deleteInvitation,
    getInvitation,
} from '../../../services/InvitationService'
import { IInvitation } from '../../../types/ContactService/ContactService'
import Button from '../Buttons/Button'
import DateTimeFormat from '../DateTimeFormat'
import styles from './InvitationStatus.module.scss'

interface IProps {
    history: any

    invitation?: IInvitation
    hidePermissionMessage?: boolean
    contactId?: string
    disableLoader?: boolean
    hideStatus?: boolean
    userId?: string
    forename?: string
    surname?: string
    email?: string
    contactType?: string
    toggleAddTeamMember?: any
    teamId?: number
}

interface IState {
    status: string
    invitation: IInvitation
    isLoadingInvitation: boolean
    isChat: boolean
    invitePopupOpen: boolean
}

class InvitationStatus extends React.Component<IProps, IState> {
    tippyRef

    constructor(props: any) {
        super(props)

        this.tippyRef = React.createRef()

        this.state = {
            status: '',
            invitation: null,
            isLoadingInvitation: false,
            isChat: false,
            invitePopupOpen: false,
        }

        this.getStatus = this.getStatus.bind(this)
        this.handleSendInviteClick = this.handleSendInviteClick.bind(this)
        this.handleCancelInviteClick = this.handleCancelInviteClick.bind(this)
        this.onOpenModal = this.onOpenModal.bind(this)
        this.onCloseModal = this.onCloseModal.bind(this)
        this.onAddChatCallback = this.onAddChatCallback.bind(this)
    }

    handleSendInviteClick(): void {
        if (this.tippyRef && this.tippyRef.current) {
            this.tippyRef.current.hideTooltip()
        }

        this.props.toggleAddTeamMember(true, {
            forename: this.props.forename,
            surname: this.props.surname,
            email: this.props.email,
            contactType: this.props.contactType,
            contactId: this.props.contactId,
            teamId: this.props.teamId
        })
    }

    handleCancelInviteClick(): void {
        this.setState({
            isLoadingInvitation: true,
        })

        deleteInvitation(this.props.contactId).then((resp) => {
            if (resp && resp.status == 200) {
                updateInvitationStatusForContactList(null, this.props.contactId)
                this.setState({
                    invitation: null,
                    isLoadingInvitation: false,
                })
            }
        })
    }

    getStatus(): string {
        if (this.props.invitation.isInvited) {
            return 'Invited'
        }

        return 'Not Invited'
    }

    componentWillReceiveProps(newProps: IProps): void {
        if (newProps.invitation) {
            this.setState({
                invitation: newProps.invitation,
            })
        } else {
            if (!this.props.disableLoader) {
                this.setState({
                    isLoadingInvitation: true,
                })
            }
            getInvitation(this.props.contactId).then((resp) => {
                if (resp && resp.status && resp.data) {
                    this.setState({
                        invitation: resp.data,
                    })
                } else {
                    this.setState({
                        invitation: null,
                    })
                }
                this.setState({
                    isLoadingInvitation: false,
                })
            })
        }
    }

    componentDidMount(): void {
        if (this.props.invitation) {
            this.setState({
                invitation: this.props.invitation,
            })
        } else {
            if (!this.props.disableLoader) {
                this.setState({
                    isLoadingInvitation: true,
                })
            }
            getInvitation(this.props.contactId).then((resp) => {
                if (resp && resp.status && resp.data) {
                    this.setState({
                        invitation: resp.data,
                    })
                } else {
                    this.setState({
                        invitation: null,
                    })
                }
                this.setState({
                    isLoadingInvitation: false,
                })
            })
        }
    }

    onAddChatCallback(): void {
        let options: IChatOptions = {
            userIds: [this.props.userId],
        }

        startDiscussion(options).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                addChatToChatList(resp.data.item)
                this.props.history.push(
                    `/dashboard/contact/${this.props.contactId}/chats/${resp.data.item.chatSessionId}`
                )
            }
        })
    }

    onOpenModal = () => {
        this.setState({ isChat: true })
    }

    onCloseModal = () => {
        if (this.state.isChat) {
            this.setState({ isChat: false })
        }
    }

    render() {
        if (
            getLoggedInUsersContactId() === this.props.contactId ||
            this.state.isLoadingInvitation
        ) {
            return null
        }

        if (
            !canInviteUsers() &&
            !this.state.invitation &&
            !this.props.hidePermissionMessage
        ) {
            return <div>You do not have permission to invite users</div>
        }

        if (canInviteUsers() && !this.state.invitation && !this.props.userId) {
            return (
                <div className={styles.invitationStatus}>
                    <div
                        className={styles.button}
                        onClick={this.handleSendInviteClick}
                    >
                        <Tooltip
                            ref={this.tippyRef}
                            theme="light"
                            html={
                                <span className={styles.tooltipContent}>
                                    <h1>
                                        INVITE YOUR CONTACT TO JOIN YOUR TEAM
                                    </h1>
                                    <p>
                                        On selection you will be able to control
                                        what level of access your contact will
                                        have.
                                    </p>
                                    <p>
                                        Your contact will receive an invitation
                                        email which on verification will give
                                        them access to the platform.
                                    </p>
                                    <p>
                                        You control the level of data they see
                                        and can revoke that access at any point
                                        in the future from the Teams screen.
                                    </p>
                                    <p>
                                        Go for it and use the power of the Team!
                                    </p>
                                </span>
                            }
                            hideOnClick={true}
                            position="right"
                            trigger="mouseenter"
                            arrow
                            interactive={true}
                        >
                            <Button
                                button={{
                                    text: 'Send Invite',
                                    displayType: 'action',
                                    elementType: 'button',
                                    onClick: () => null,
                                }}
                            />
                        </Tooltip>
                    </div>
                </div>
            )
        }

        if (
            !this.props.hideStatus &&
            !this.props.userId &&
            this.state.invitation &&
            this.state.invitation.isUserAccount &&
            this.state.invitation.isActiveUser
        ) {
            return (
                <div className={styles.invitationStatus}>
                    <div className={styles.text}>
                        <p>Status: Active</p>
                        <div className={styles.dateBy}>
                            Registered on{' '}
                            <DateTimeFormat
                                rawDateTime={this.state.invitation.acceptedOn}
                                showOnlyDateFormat
                                showTimeAgoFormat
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (
            this.props.userId ||
            (this.state.invitation &&
                this.state.invitation.isUserAccount &&
                this.state.invitation.isActiveUser)
        ) {
            return (
                <div className={styles.invitationStatus}>
                    {/* {Adding the following StartChatModal seems to cause a crash when hovering over the contact pop-up} 
           This wasn't happening prior to the teams work.  Not sure what this modal is actually needed for.
           Note this was removed due to the Start Chat option not appearing on the contact pop-up*/}
                    {/*           <StartChatModal
            onAddChatCallback={() => this.setState({ isChat: false })}
            name={
              this.props.forename +
              " " +
              this.props.surname
            }
            userId={
              this.props.userId
            }
            open={this.state.isChat}
            onOpenModal={() => this.setState({ isChat: true })}
            onCloseModal={() => this.setState({ isChat: false })}
          /> */}
                    <div
                        className={styles.button}
                        onClick={() => {
                            this.onAddChatCallback()
                        }}
                    >
                        <Button
                            button={{
                                text: 'Start Chat',
                                displayType: 'action',
                                elementType: 'button',
                                onClick: () => null,
                            }}
                        />
                    </div>
                </div>
            )
        }

        if (
            !this.props.hideStatus &&
            this.state.invitation &&
            this.state.invitation.isUserAccount &&
            !this.state.invitation.isActiveUser
        ) {
            return (
                <div className={styles.invitationStatus}>
                    <div className={styles.text}>
                        <p>Status: Inactive</p>
                    </div>
                </div>
            )
        }

        if (
            !this.props.hideStatus &&
            this.state.invitation &&
            this.state.invitation.acceptedOn &&
            this.state.invitation.isAccepted
        ) {
            return (
                <div className={styles.invitationStatus}>
                    <div className={styles.text}>
                        <p>Status: Accepted</p>
                        <div className={styles.dateBy}>
                            Invitation accepted{' '}
                            <DateTimeFormat
                                rawDateTime={this.state.invitation.acceptedOn}
                                showOnlyDateFormat
                                showTimeAgoFormat
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (
            this.state.invitation &&
            !this.state.invitation.isAccepted &&
            this.state.invitation.isInvited
        ) {
            return (
                <div className={styles.invitationStatus}>
                    <div
                        className={styles.button}
                        onClick={this.handleCancelInviteClick}
                    >
                        <Tooltip
                            theme="light"
                            html={
                                <div className={styles.tooltipContent}>
                                    <p>Status: Invited</p>
                                    <div className={styles.dateBy}>
                                        Invitation sent{' '}
                                        <DateTimeFormat
                                            rawDateTime={
                                                this.state.invitation.invitedOn
                                            }
                                            showOnlyDateFormat
                                            showTimeAgoFormat
                                        />{' '}
                                        {this.state.invitation.invitedBy && (
                                            <>
                                                by{' '}
                                                {
                                                    this.state.invitation
                                                        .invitedBy
                                                }
                                            </>
                                        )}
                                    </div>
                                </div>
                            }
                            position="right"
                            trigger="mouseenter"
                            arrow
                            interactive={true}
                        >
                            <Button
                                button={{
                                    text: 'Cancel Invite',
                                    displayType: 'action',
                                    elementType: 'button',
                                    onClick: () => null,
                                }}
                            />
                        </Tooltip>
                    </div>
                </div>
            )
        }

        return null
    }
}

export default InvitationStatus
