import { store } from '../store';
import { isPlatformAdmin, isClientAdmin } from './roleHelpers';

export function canAddProperties() {

}

export function canEditProperties() {

}

export function canDeleteProperties() {

}

/**
    // Have left an example/starter here of how we might want to check for permissions especially,
    // if the checks are going to increase by a lot.  Instead of adding a seperate method for each
    // permission check, export the below getPermission method which would require a value from the 
    // enum Permissions to be passed in e.g. getPermission(Permissions.CanAdministerTeam)
    export enum Permissions {
        CanAdministerTeam = "CanAdministerTeam",
        CanAccessTeamList = "CanAccessTeamList"
        //...Add rest of permissions
    }
 */

function getPermission(permissionName: string) {
    let state = store.getState();
    if (state.user.permissions.indexOf(permissionName) > -1) {
        return true;
    }
    return false;
}

export function canAdministerTeam() {
    return getPermission("CanAdministerTeam");
}

export function canAccessTeamList() {
    return getPermission('CanAccessTeamList');
}

export function canImpersonateUser() {
    return getPermission('CanImpersonate');
}

export function canSelectClientImplementation() {
    return getPermission('SelectClientImplementation');
}

export function canViewAdmin() {
    return isPlatformAdmin() || isClientAdmin();
}

export function canViewDashboard() {
    return getPermission('CanViewDashboard');
}

export function canEditPropertyAddress() {
    return getPermission('CanEditPropertyAddress');
}

export function canViewPropertyList() {
    return getPermission('CanViewPropertyList');
}

export function canViewDocuments() {
    return getPermission('CanViewDocuments');
}

export function canViewIssues() {
    return getPermission('CanViewIssues');
}

export function canUseChat() {
    return getPermission('CanUseChat');
}

export function canStartChat() {
    return getPermission('CanStartChat');
}

export function canAddUsersToChat() {
    return getPermission('CanAddUsersToChat');
}

export function canInviteUsers() {
    return getPermission('CanInviteUsers');
}

export function canDeleteIssue() {
    return getPermission('CanDeleteIssue');
}

export function canAccessPropertyDetails() {
    return getPermission('CanAccessPropertyDetails');
}

export function canDeleteChat() {
    return getPermission('ChatDelete');
}

export function canAccessContactDetails() {
    return getPermission('CanAccessContactDetails');
}

export function trustedTeamUser() {
    return getPermission('TrustedTeamUser');
}