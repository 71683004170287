import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// Import Styles
import styles from "./ContactDocuments.module.scss";
import { IUserProfileResponse } from "../../../../../types/ProfileService/IProfile";
import DocumesntsPage from "../../../DocumentsPage";
import { updateDocumentList } from "../../../../../helpers/documentHelpers";
import DocumentsPage from "../../../DocumentsPage";
import { toggleAddDocumentPopup } from "../../../../../helpers/sidebarHelpers";
import { addURLParameter } from "../../../../../helpers/locationHelpers";
import { IDocumentUploadOptions } from "../../../../molecules/AddDocumentModal";

interface IWithRouterProps {
  history: any;
  location: any;
  match: any;
  userProfile: IUserProfileResponse;
}

interface IState {
  contactId: string;
}

class ContactDocuments extends Component<IWithRouterProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      contactId: null
    };

    this.onClickAddDocument = this.onClickAddDocument.bind(this);
  }

  componentDidMount(): void {
    let contactId = this.props.match.params.contactId;

    if (!contactId) {
      return null;
    }

    this.setState({
      contactId
    });

    updateDocumentList([]);
  }

  onClickAddDocument(): void {
    let options: IDocumentUploadOptions = {
      contactId: this.state.contactId,
      contactName: "Test Contact"
    }

    let query = "uploadDocumentOptions=" + JSON.stringify(options);

    let search = addURLParameter(this.props.location.search, query);

    this.props.history.push({
      search
    });

    toggleAddDocumentPopup(true);
  }

  render() {
    if (!this.state.contactId) {
      return null;
    }

    return (
      <div className={styles.page}>
        <DocumentsPage onAddDocumentClick={this.onClickAddDocument} contactId={this.state.contactId} />
      </div>
    );
  }
}

export default withRouter(ContactDocuments);