import React from "react";
import styles from "./UserActiveStatus.module.scss";

interface IProps {
  active: boolean;
}

class UserActiveStatus extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { active } = this.props;
    
    return (
      <span className={`${styles.userActiveStatus} ${active ? styles.active : styles.notActive}`}>{active ? 'Active' : 'Not Active'}</span>
    );
  }
}

export default UserActiveStatus;