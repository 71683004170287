import React from "react";
import { IOptions } from "../SelectDropdown";
import { getSearchProperties } from "../../../services/SearchService";
import { QuickViewType } from "../../../types/QuickView";
import SelectDropdownV2 from "../SelectDropdownV2/SelectDropdownV2.component";

interface IProps {
  selectedPropertyId?: string;
  selectedPropertyName?: string;
  onSelect(option: IOptions): void;
  onClear?(): void;
  disableClear?: boolean;
  showSearchButton?: boolean;
  searchButtonOnClick?(searchValue: string): void;
}

interface IState {
  results: any[];
}

class SearchPropertyInput extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      results: []
    };

    this.onSearchProperty = this.onSearchProperty.bind(this);
    this.getProperties = this.getProperties.bind(this);
  }

  onSearchProperty(search: string): void {
    if (!search || search == "") {
      this.setState({
        results: []
      });
      return;
    }

    this.getProperties(search);
  }

  getProperties(search?: string): void {
    getSearchProperties(search ? search : '').then(resp => {
      if (resp && resp.status && resp.data) {
        this.setState({
          results: resp.data
        });
      }
    });
  }

  render() {
    return (
      <SelectDropdownV2 
        propertyId={this.props.selectedPropertyId}
        onClear={this.props.onClear}
        disableClear={false}
        selectedId={this.props.selectedPropertyId}
        selectedName={this.props.selectedPropertyName}
        onSelect={this.props.onSelect}
        onSearch={this.getProperties}
        data={this.state.results}
        inputPlaceHolder={"Search properties..."}
        quickView={QuickViewType.Property}
        isSearchable={true}
        showSearchButton={this.props.showSearchButton}
        searchButtonOnClick={this.props.searchButtonOnClick}
      />
    )
  }
}

export default SearchPropertyInput;
