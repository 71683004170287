import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import SFDropdown from "..";
import { toggleAddTeamMember } from "../../../../actions/teamsActions";
import { toggleUserStatus } from "../../../../helpers/contactHelpers";
import { IInviteTeamMemberOptions } from "../../../../types/Teams";
import ActionButton from "../../ActionButton";
import styles from "./SFContactDropdown.module.scss";
import { addDocumentClicked, onAddExpenseClick, onAddIssueClick, onAddTenancyClick, onCancelInviteClick } from "./_helpers";

interface IProps {
  contactId: string;
  contactName: string;
  invited: boolean;
  userId?: string;
  isLandlord?: boolean;
  isUser: boolean;
  isUserActive: boolean;
  toggle?: JSX.Element;
  forename: string;
  surname: string;
  email: string;
  contactType: string;
  history: any;
  location: any;
  match: any;

  onDelete(contactId: string, contactName: string): void;
  toggleAddTeamMember?(open: boolean, options?: IInviteTeamMemberOptions): void;
}

class SFContactDropdown extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <SFDropdown
        customCssClassName={styles.syncfusionDropdown}
        customToggle={
          this.props.toggle ? this.props.toggle : <ActionButton />
        }
        onSelect={(args) => {
          if (args.item.text === "View Summary") {
            this.props.history.push(`/dashboard/contact/${this.props.contactId}`)
          }

          if (args.item.text === "Add Tenancy") {
            onAddTenancyClick(this.props.contactId, this.props.contactName, this.props.location.search);
          }

          if (args.item.text === "Add Issue") {
            onAddIssueClick(this.props.contactId, this.props.contactName, this.props.location.search);
          }

          if (args.item.text === "Add Document") {
            addDocumentClicked(this.props.contactId, this.props.location.search);
          }

          if (args.item.text === "Add Income") {
            onAddExpenseClick(this.props.contactId, this.props.contactName, this.props.location.search, true, false);
          }

          if (args.item.text === "Add Expense") {
            onAddExpenseClick(this.props.contactId, this.props.contactName, this.props.location.search, false, true);
          }

          if (args.item.text === "Send Invite") {
            this.props.toggleAddTeamMember(true, {
              forename: this.props.forename,
              surname: this.props.surname,
              email: this.props.email,
              contactType: this.props.contactType,
              contactId: this.props.contactId
            });
          }

          if (args.item.text === "Cancel Invite") {
            onCancelInviteClick(this.props.contactId);
          }

          if (args.item.text === "Enable Access") {
            toggleUserStatus(this.props.userId, true);
          }

          if (args.item.text === "Disable Access") {
            toggleUserStatus(this.props.userId, false);
          }

          if (args.item.text === "Delete") {
            this.props.onDelete(this.props.contactId, this.props.contactName);
          }

          if (args.item.text === "Go to Money") {
            this.props.history.push(`/dashboard/contact/${this.props.contactId}/financials`);
          }

          if (args.item.text === "Go to Issues") {
            this.props.history.push(`/dashboard/contact/${this.props.contactId}/issues`);
          }

          if (args.item.text === "Go to Chats") {
            this.props.history.push(`/dashboard/contact/${this.props.contactId}/chats`);
          }
          if (args.item.text === "Go to Properties") {
            this.props.history.push(`/dashboard/contact/${this.props.contactId}/properties`);
          }
        }}
        items={[
          {
            iconCss: styles.iconViewSummary,
            text: "View Summary",
            url: `/dashboard/contact/${this.props.contactId}`
          },
          {
            separator: true
          },
          {
            iconCss: styles.iconAddExpense,
            text: "Add Expense",
          },
          {
            iconCss: styles.iconAddIncome,
            text: "Add Income",
          },
          {
            iconCss: styles.iconAddDocument,
            text: "Add Document",
          },
          {
            iconCss: styles.iconAddIssue,
            text: "Add Issue",
          },
          {
            iconCss: styles.iconAddTenancy,
            text: "Add Tenancy",
          },
          ...(!this.props.isUser
            ? [{
              separator: true
            },
            {
              iconCss: styles.iconSendInvite,
              text: this.props.invited ? "Cancel Invite" : "Send Invite",
            }] : []),
          ...(this.props.isUser
            ? [{
              separator: true
            },
            {
              iconCss: styles.iconSendInvite,
              text: this.props.isUserActive ? "Disable Access" : "Enable Access",
            }] : []),
          {
            separator: true
          },
          ...(this.props.isLandlord
            ? [
              {
                iconCss: styles.iconGoToFinancials,
                text: "Go to Properties",
                url: `/dashboard/contact/${this.props.contactId}/properties`
              }] : []),
          {
            iconCss: styles.iconGoToFinancials,
            text: "Go to Money",
            url: `/dashboard/contact/${this.props.contactId}/financials`
          },
          {
            iconCss: styles.iconGoToIssues,
            text: "Go to Issues",
            url: `/dashboard/contact/${this.props.contactId}/issues`
          },
          {
            iconCss: styles.iconGoToIssues,
            text: "Go to Tenancies",
            url: `/dashboard/contact/${this.props.contactId}/tenancies`
          },
          {
            iconCss: styles.iconGoToChats,
            text: "Go to Chats",
            url: `/dashboard/contact/${this.props.contactId}/chats`
          },
          {
            separator: true
          },
          {
            iconCss: styles.iconDelete,
            text: "Delete",
          },
        ]}
      />
    );
  }
}


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      toggleAddTeamMember
    },
    dispatch
  );

export default withRouter<IProps, any>(connect(
  null,
  mapDispatchToProps)
  (SFContactDropdown));