import styled from 'styled-components';

export const GridWrapperDiv = styled.div`
    max-width: 600px;
    max-height: 300px;
    text-align: left;
    background-color: white;
    overflow-y:scroll;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
`;

