import React, { Component } from 'react'
import Sidebar from 'react-sidebar'
import { withRouter } from 'react-router-dom'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import AddContactForm from '../AddContactForm'

import styles from './AddContactSidePanel.module.scss'
import { sidebarStyling } from '../../../global/variables/_variables'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import { toggleAddContactSidebar } from '../../../helpers/sidebarHelpers'
import Buttons from '../../atoms/Buttons'

interface IProps {
    isOpen?: boolean
    onSetOpen?(open: boolean): void
    add_contact_form_changed?: boolean
}

interface IRouterProps {
    history: any
    location: any
    match: any
}

interface IState {
    sidebarOpen: boolean
    outstandingChangesModalOpen: boolean
}

type Props = IProps & IRouterProps

class AddContactSidePanel extends Component<Props, IState> {
    constructor(props: any) {
        super(props)
        this.state = {
            sidebarOpen: false,
            outstandingChangesModalOpen: false,
        }

        this.onSetOpenInternal = this.onSetOpenInternal.bind(this)
        this.onCloseChangesModal = this.onCloseChangesModal.bind(this)
    }

    onSetOpenInternal(open: boolean): void {
        if (!open) {
            // Check if there is outstanding changes
            if (this.props.add_contact_form_changed) {
                this.setState({
                    outstandingChangesModalOpen: true,
                })

                return
            }
        }

        toggleAddContactSidebar(open)
    }

    onCloseChangesModal(closeSidebar: boolean): void {
        this.setState({
            outstandingChangesModalOpen: false,
        })

        if (closeSidebar) {
            toggleAddContactSidebar(false)
        }
    }

    render() {
        return (
            <div>
                <Modal
                    open={this.state.outstandingChangesModalOpen}
                    onClose={() => this.onCloseChangesModal(false)}
                    center
                >
                    <p className={styles.modalHeader}>Outstanding changes</p>
                    <p className={styles.modalBodyOutstandingChanges}>
                        Any updated information will be lost, do you wish to
                        continue?
                    </p>

                    <Buttons
                        buttons={[
                            {
                                text: 'No',
                                displayType: 'cancel',
                                elementType: 'button',
                                onClick: () => this.onCloseChangesModal(false),
                            },
                            {
                                text: 'Yes',
                                displayType: 'submit',
                                elementType: 'button',
                                onClick: () => this.onCloseChangesModal(true),
                            },
                        ]}
                    />
                </Modal>

                <Sidebar
                    touch={false}
                    sidebarClassName={styles.sidebar}
                    sidebar={
                        this.props.isOpen ? <AddContactForm /> : <div></div>
                    }
                    open={this.props.isOpen}
                    onSetOpen={this.onSetOpenInternal}
                    styles={sidebarStyling}
                >
                    <div />
                </Sidebar>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    add_contact_form_changed: state.app.form_states.add_contact_form_changed,
})

export default withRouter<Props,any>(connect(mapStateToProps)(AddContactSidePanel))
