import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import {
    canAccessTeamList,
    canUseChat,
    canViewAdmin,
    canViewDashboard,
    canViewDocuments,
    canViewIssues,
} from '../../../helpers/permissionHelpers'
import {
    isLandlord,
    isPlatformAdmin,
    isTenant,
    isTradesPerson,
} from '../../../helpers/roleHelpers'
import { getDashboardSideNavigationState } from '../../../helpers/sessionSettingsHelpers'
import {
    toggleAddPropertySidebar,
    toggleDetailedSidebarNavigation,
    toggleMobileSidebarFalse,
} from '../../../helpers/sidebarHelpers'
import menuChat from '../../../images/icons/menuicon_chat.svg'
import menuContact from '../../../images/icons/menuicon_contacts.svg'
import menuHome from '../../../images/icons/menuicon_dashboard.svg'
import menuDocuments from '../../../images/icons/menuicon_documents.svg'
import menuFinancials from '../../../images/icons/menuicon_financials.svg'
import menuInspections from '../../../images/icons/menuicon_inspections (2).svg'
import menuIssues from '../../../images/icons/menuicon_issues.svg'
import menuPlatform from '../../../images/icons/menuicon_platform (1).svg'
import menuProperties from '../../../images/icons/menuicon_properties.svg'
import menuResearch from '../../../images/icons/menuicon_research.svg'
import menuSettings from '../../../images/icons/menuicon_settings.svg'
import menuTenancies from '../../../images/icons/menuicon_tenancies.svg'
import showAll from '../../../images/icons/menu_showall.svg'
import menuTasks from '../../../images/icons/menuicon_tasks.svg'
import { RootState } from '../../../reducers/rootReducer'
import { IReactRouterProps } from '../../../types'
import ClientAreaRestriction from '../../atoms/ClientAreaRestriction'
import PlatformVersionModal from '../../atoms/PlatformVersionModal'
import StickyComponent from '../../atoms/StickyComponent'
import styles from './DashboardNavigationItems.module.scss'
import NavigationItem from './NavigationItem'
import SubNavigationItem from './SubNavigationItem'
import { IInspectionsStats } from  '../../../types/Inspection' 
import { getInspectionsStats } from '../../../services/inspectionService'

interface IProps {
    small?: boolean
    sticky?: boolean
    detailed_side_navigation?
    unreadChatCount?: number
}

interface IState {
    sidebarOpen: boolean
    versionModal: boolean
    inspectionsStats: IInspectionsStats
}

type Props = IProps & IReactRouterProps

class DashboardNavigationItems extends Component<Props, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            sidebarOpen: false,
            versionModal: false,
            inspectionsStats: null
        }
    }

    componentDidMount(): void {
        getDashboardSideNavigationState()
        getInspectionsStats().then((resp) => {
            if (resp && resp.status == 200) {
                this.setState({
                    inspectionsStats: resp.data,
                })
            }
        })
    }

    goToRoute = (route: string): void => {
        this.props.history.push('/' + route)
        toggleMobileSidebarFalse()
    }

    render() {
        return (
            <div className={`${styles.dashboardNavigationPanel} ${this.props.sticky ? styles.sticky : ''}`}
                 id="sideNavigation"
            >
                <PlatformVersionModal
                    open={this.state.versionModal}
                    onOpenModal={() => this.setState({ versionModal: true })}
                    onCloseModal={() => this.setState({ versionModal: false })}
                />
                <ul className={styles.ul}>
                    {/* <NavigationItem
                        options={{
                            small: this.props.small || false,
                            route: 'dashboard/dashboardV1',
                            text: 'DashboardV1',
                            image: menuHome,
                            matches: '/dashboard/dashboardV1',
                            canView: canViewDashboard(),
                        }}
                    /> */}
                    <NavigationItem
                        options={{
                            small: this.props.small || false,
                            route: 'dashboard',
                            text: 'Dashboard',
                            image: menuHome,
                            matches: '/dashboard',
                            canView: true, //canViewDashboard(),
                        }}
                    />

                    <NavigationItem
                        options={{
                            small: this.props.small || false,
                            route: 'dashboard/properties',
                            text: 'Properties',
                            image: menuProperties,
                            matches: '/dashboard/properties',
                            landlordOnly: true,
                            indexOf: [
                                'dashboard/properties',
                                'dashboard/property',
                                'dashboard/assets',
                                'dashboard/asset',
                                'dashboard/units',
                                'dashboard/unit',
                            ],
                            divId: 'navProperties',
                            canView: true,
                        }}
                    >
                        <ul className={`${styles.childList} ${this.props.small && styles.small}`}>
                            <li onClick={() => toggleAddPropertySidebar(true)}>Add Property</li>
                                

                            <a className={styles.anchor} href={`/dashboard/units`} onClick={(e) => e.preventDefault()}>
                                <li onClick={() => this.goToRoute('dashboard/units')}>Units</li>
                            </a>
                            <ClientAreaRestriction restriction="AssetsFunctions">
                                <a className={styles.anchor} href={`/dashboard/assets`} onClick={(e) => e.preventDefault()}>
                                    <li onClick={() => this.goToRoute('dashboard/assets')}>Assets</li>
                                </a>
                            </ClientAreaRestriction>
                        
                    
                            {this.props.detailed_side_navigation && (
                                <a className={styles.anchor} href={`/dashboard/tenancies`} onClick={(e) => e.preventDefault()}>
                                    <li onClick={() => this.goToRoute('dashboard/tenancies')}>Tenancies</li>
                                </a>
                            )}

                            {canViewIssues() && (
                                <a className={styles.anchor} href={`/dashboard/issues`} onClick={(e) => e.preventDefault()}>
                                    <li onClick={() => this.goToRoute('dashboard/issues')}>Issues</li>
                                </a>
                            )}

                            <ClientAreaRestriction restriction="InspectionsFunctions">
                                {this.props.detailed_side_navigation && (isPlatformAdmin() || isLandlord() || isTradesPerson()) && (
                                    <a className={styles.anchor} href={`/dashboard/inspections`} onClick={(e) => e.preventDefault()}>
                                        <li onClick={() => this.goToRoute('dashboard/inspections')}>Inspections</li>
                                    </a>
                                )}
                            </ClientAreaRestriction>
                            
                        </ul>
                    </NavigationItem>

                    <ClientAreaRestriction restriction="TasksFunctions">
                        <NavigationItem
                            options={{
                                small: this.props.small || false,
                                route: 'dashboard/tasks',
                                text: 'Tasks',
                                image: menuTasks,
                                matches: '/dashboard/tasks',
                                //landlordOnly: true,
                                indexOf: ['dashboard/tasks'],
                                divId: 'navTasks',
                                canView: true,
                            }}
                        >
                            <ul className={`${styles.childList} ${this.props.small && styles.small}`}>
                                <li onClick={() => this.goToRoute('dashboard/task/add')}>Add Task</li>
                            </ul>
                        </NavigationItem>
                    </ClientAreaRestriction>
                    
                    {
                        this.state.inspectionsStats && this.state.inspectionsStats.allInpsectionsCount > 0 &&
                        <ClientAreaRestriction restriction="InspectionsFunctions">
                            {this.props.detailed_side_navigation && (
                                <NavigationItem
                                    options={{
                                        small: this.props.small || false,
                                        route: 'dashboard/inspections',
                                        text: 'Inspections',
                                        image: menuInspections,
                                        matches: '/dashboard/inspections',
                                        indexOf: [
                                            'dashboard/inspections',
                                            'dashboard/inspection',
                                        ],
                                        landlordOnly: false,
                                        divId: 'navInspection',
                                        canView: isTradesPerson(),
                                    }}
                                />
                            )}
                        </ClientAreaRestriction>
                    }
                    

                    <NavigationItem
                        options={{
                            small: this.props.small || false,
                            route: 'dashboard/financials/transactions/income',
                            text: 'Money',
                            image: menuFinancials,
                            matches: '/dashboard/financials/transactions/income',
                            landlordOnly: true,
                            indexOf: ['dashboard/financials/transactions/income'],
                            divId: 'navFinance',
                            canView: true,
                        }}
                    />
           
                    <NavigationItem
                        options={{
                            small: this.props.small || false,
                            route: 'dashboard/contacts?contactType=All',
                            text: 'Contacts',
                            image: menuContact,
                            matches: 'dashboard/contacts',
                            landlordOnly: true,
                            indexOf: [
                                'dashboard/contacts',
                                'dashboard/contact',
                            ],
                            divId: 'navContacts',
                            canView: true,
                        }}
                    >
                        <ul className={`${styles.childList} ${this.props.small && styles.small}`}>
                            <a className={styles.anchor} href={`/dashboard/contacts?contactType=Tenant`} onClick={(e) => e.preventDefault()}>
                                <li onClick={() => this.goToRoute('dashboard/contacts?contactType=Tenant')}>Tenants</li>
                            </a>
                            <a className={styles.anchor} href={`/dashboard/contacts?contactType=Landlord`} onClick={(e) => e.preventDefault()}>
                                <li onClick={() => this.goToRoute('dashboard/contacts?contactType=Landlord')}>Landlords</li>
                            </a>
                            <a className={styles.anchor} href={`/dashboard/contacts?contactType=Trade`} onClick={(e) => e.preventDefault()}>
                                <li onClick={() => this.goToRoute('dashboard/contacts?contactType=Trade')}>Trade</li>
                            </a>
                        </ul>
                    </NavigationItem>

                    {this.props.detailed_side_navigation && (
                        <NavigationItem
                            options={{
                                small: this.props.small || false,
                                route: 'dashboard/research',
                                text: 'Research',
                                image: menuResearch,
                                matches: '/dashboard/research',
                                indexOf: ['dashboard/research'],
                                landlordOnly: true,
                                canView: true,
                            }}
                        >
                            <ul className={`${styles.childList} ${this.props.small && styles.small}`}>
                                <li onClick={() => this.goToRoute('dashboard/research/deal-calculator')}>Deal Calculator</li>
                                <li onClick={() => this.goToRoute('dashboard/research')}>Area Research</li>
                            </ul>
                        </NavigationItem>
                    )}

                    {(this.props.detailed_side_navigation || isTenant()) && (
                        <NavigationItem
                            options={{
                                small: this.props.small || false,
                                route: 'dashboard/documents',
                                text: 'Documents',
                                image: menuDocuments,
                                matches: '/dashboard/documents',
                                divId: 'navDocuments',
                                canView: canViewDocuments(),
                            }}
                        />
                    )}

                    <NavigationItem
                        options={{
                            small: this.props.small || false,
                            route: 'dashboard/chats',
                            text: 'Chat',
                            image: menuChat,
                            matches: '/dashboard/chats',
                            indexOf: [
                                'dashboard/chats',
                                'dashboard/chats',
                                'dashboard/chat-list',
                            ],
                            hasNew: true,
                            divId: 'navChat',
                            canView: canUseChat(),
                        }}
                    />

                    {this.props.detailed_side_navigation && (
                        <NavigationItem
                            options={{
                                small: this.props.small || false,
                                route: 'dashboard/settings',
                                text: 'Settings',
                                image: menuSettings,
                                matches: '/dashboard/settings',
                                indexOf: ['dashboard/settings'],
                                landlordOnly: false,
                                divId: 'navInspection',
                                canView: canAccessTeamList(),
                            }}
                        >
                            <ul className={`${styles.childList} ${this.props.small && styles.small}`}>
                                <SubNavigationItem canView={true}>
                                    <a className={styles.anchor} href={`/dashboard/settings/team`} onClick={(e) => e.preventDefault()}>
                                        <li onClick={() => this.goToRoute('dashboard/settings/team')}>Team</li>
                                    </a>
                                </SubNavigationItem>
                            </ul>
                        </NavigationItem>
                    )}

                    {this.props.detailed_side_navigation && (
                        <NavigationItem
                            options={{
                                small: this.props.small || false,
                                route: 'dashboard/manage',
                                text: 'Platform',
                                image: menuPlatform,
                                matches: 'dashboard/manage',
                                indexOf: [
                                    'dashboard/platform',
                                    'dashboard/manage',
                                ],
                                platformAdminOnly: false,
                                canView: canViewAdmin(),
                            }}
                        >
                            <ul
                                className={`${styles.childList} ${ this.props.small && styles.small}`}>
                                <SubNavigationItem canView={canViewAdmin()}>
                                    <li onClick={() => this.goToRoute('dashboard/manage/users')}>Users</li>
                                </SubNavigationItem>

                                <SubNavigationItem canView={isPlatformAdmin()}>
                                    <li onClick={() => this.goToRoute('dashboard/manage/clients') }>Clients</li>
                                    <li onClick={() => this.goToRoute('dashboard/manage/permissions')}>Permissions</li>
                                </SubNavigationItem>

                                <SubNavigationItem canView={isPlatformAdmin()}>
                                    <li onClick={() => this.goToRoute('dashboard/manage/roles') }>Roles</li>
                                </SubNavigationItem>

                                <SubNavigationItem canView={isPlatformAdmin()}>
                                    <li onClick={() => this.goToRoute('dashboard/manage/entities')}>Entities</li>
                                </SubNavigationItem>

                                <SubNavigationItem canView={isPlatformAdmin()}>
                                    <li onClick={() => this.goToRoute('dashboard/manage/timeline')}>Timeline</li>
                                </SubNavigationItem>

                                <SubNavigationItem canView={isPlatformAdmin()}>
                                    <li onClick={() => this.goToRoute('dashboard/manage/audit')}>Research Audit</li>
                                </SubNavigationItem>
                            </ul>
                        </NavigationItem>
                    )}

                    <div>
                        {(isLandlord() || isPlatformAdmin()) && (
                            <li className={styles.showToggle} onClick={() => toggleDetailedSidebarNavigation(!this.props.detailed_side_navigation)}>
                                <img src={showAll} />Show{' '}
                                {this.props.detailed_side_navigation ? 'less': 'all'}
                            </li>
                        )}
                    </div>
                </ul>
{/*                 <StickyComponent>
                    <div
                        className={styles.buildNumber}
                        onClick={() => this.setState({ versionModal: true })}
                    >
                        Version
                    </div>
                </StickyComponent>
 */}            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    detailed_side_navigation: state.app.sidebars.detailed_side_navigation,
    unreadChatCount: state.notifications.unreadChatCount,
})

export default withRouter<Props,any>(connect(mapStateToProps)(DashboardNavigationItems))
