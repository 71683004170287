import React, { useState, useEffect, useRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers/rootReducer';
import { Responsive, WidthProvider } from "react-grid-layout";
import { deduceLayout, defaultLayoutLg, defaultLayoutMd, defaultLayoutXs, defaultLayoutConfig, ILayoutObject } from './_layout/dashboard.layout';
import { getFromLS, saveToLS, getLayoutFromLS } from '../helpers/dashboardHelper';

import ActiveStreetViewSection from '../Sections/ActiveStreetViewSection/ActiveStreetViewSection.component'
import ActiveGoogleMapSection from '../Sections/ActiveGoogleMapSection/ActiveGoogleMapSection.component';
import ActivePropertyYieldSection from '../Sections/ActivePropertyYieldSection/ActivePropertyYieldSection.component';
import ActiveResearchAreaSection from '../Sections/ActiveResearchAreaSection/ActiveResearchAreaSection.component';
import ActiveValuationHistorySection from '../Sections/ActiveValuationHistorySection/ActiveValuationHistorySection.component';
import ActiveKeyStatsSection from '../Sections/ActiveKeyStatsSection/ActiveKeyStatsSection.component';
import { DashboardContext } from '../_context/dashboardContext';
import { IDashboardConfig } from './_config/dashboardConfig';
import * as S from "./PropertyResearchDashboard.styles";

const ResponsiveGridLayout = WidthProvider(Responsive);

const PropertyResearchDashboard = () => {
    // Redux 
    const { propertyId, address, propertyImages } = useSelector((state: RootState) => state.property.property_data.property_data);
    const userId = useSelector((state: RootState) => state.user.user_id);

    const [layoutVisibiltyConfig, setLayoutVisibiltyConfig] = useState(defaultLayoutConfig);
    const [layoutLg, setLayoutLg] = useState<Array<ILayoutObject>>(defaultLayoutLg(false));
    const [layoutMd, setLayoutMd] = useState<Array<ILayoutObject>>(defaultLayoutMd(false));
    const [layoutXs, setLayoutXs] = useState<Array<ILayoutObject>>(defaultLayoutXs(false));
    const [inEditMode, setInEditMode] = useState(false);
    const [breakpointHasChanged, setBreakpointHasChanged] = useState(false);

    const storeId = useRef(null);
    const currentBreakpoint = useRef(null);
    const initialLoadComplete = useRef(false);
    const previousRefWidth = useRef(4);
    const config = useRef<IDashboardConfig>(null);
    const streetViewImageHeight = useRef<number>(0);
    

    // Layout initialisation
    useEffect(() => {
        config.current = require("./_config/dashboardConfig.json");
        storeId.current = config.current.dashboardName+userId;

        let savedLayoutConfig = getFromLS("layoutVisibiltyConfig", storeId.current) || undefined;
        let lLayoutLg = null;
        let lLayoutMd = null;
        let lLayoutXs = null;
        if(savedLayoutConfig != undefined){
            let savedDashboardVersion: number = getFromLS("dashboardVersion", storeId.current);
            if(!savedDashboardVersion || config.current.dashboardVersion > savedDashboardVersion){
                savedLayoutConfig = layoutVisibiltyConfig;
                lLayoutLg = defaultLayoutLg(false);
                lLayoutMd = defaultLayoutMd(false);
                lLayoutXs = defaultLayoutXs(false);
            }else{
                lLayoutLg = deduceLayout(getLayoutFromLS("layoutLg", storeId.current), false, 0);
                lLayoutMd = deduceLayout(getLayoutFromLS("layoutMd", storeId.current), false, 0);
                lLayoutXs = deduceLayout(getLayoutFromLS("layoutXs", storeId.current), false, 0);
            }
        }else{
            savedLayoutConfig = layoutVisibiltyConfig;
            lLayoutLg = defaultLayoutLg(false);
            lLayoutMd = defaultLayoutMd(false);
            lLayoutXs = defaultLayoutXs(false);
        }         

        setLayoutVisibiltyConfig(savedLayoutConfig);
        setLayoutLg(lLayoutLg);
        setLayoutMd(lLayoutMd);
        setLayoutXs(lLayoutXs);

        previousRefWidth.current = 4;
        initialLoadComplete.current = true; // Need to set this as onLayoutChange seems to fire before useEffect
      }, []);

    const removeSection = (id: string) => {
        let updatedLayoutConfig  = {...layoutVisibiltyConfig}
        switch(id){
            case "propertyValue": updatedLayoutConfig.showPropertyValue = false; break;
            case "propertyYield": updatedLayoutConfig.showPropertyValue = false; break;
            case "researchArea": updatedLayoutConfig.showResearchArea = false; break;
            case "streetView": updatedLayoutConfig.showStreetView = false; break;
            case "contacts": updatedLayoutConfig.showContacts = false; break;
            case "tenancy": updatedLayoutConfig.showTenancy = false; break;
        }
        setLayoutVisibiltyConfig(updatedLayoutConfig);
    }

    const onImgChanged = (height: number, width: number) => {
        streetViewImageHeight.current = height/30;
        updateAllLayouts(false, false, layoutLg, layoutMd, layoutXs, layoutVisibiltyConfig);
    };

    const resetSections = () => {
        let updatedDefaultLayoutConfig  = { ...defaultLayoutConfig };
        updateAllLayouts(false, true, defaultLayoutLg(false), defaultLayoutMd(false), defaultLayoutXs(false), updatedDefaultLayoutConfig);
        setLayoutVisibiltyConfig(updatedDefaultLayoutConfig);
    }

    const saveEditSections = (editMode: boolean, save: boolean) => {
        updateAllLayouts(editMode, save, layoutLg, layoutMd, layoutXs, layoutVisibiltyConfig);
    }

    const updateAllLayouts = (editMode: boolean, save: boolean, cLayoutLg, cLayoutMd, cLayoutXS, cLayoutVisibiltyConfig) => {
        setInEditMode(editMode);
        let lLayoutLg = deduceLayout(cLayoutLg, editMode, streetViewImageHeight.current);
        let lLayoutMd = deduceLayout(cLayoutMd, editMode, streetViewImageHeight.current);
        let lLayoutXs = deduceLayout(cLayoutXS, editMode, streetViewImageHeight.current);
        setLayoutLg(lLayoutLg);
        setLayoutMd(lLayoutMd);
        setLayoutXs(lLayoutXs);
        if(save){
            saveToLS(lLayoutLg, lLayoutMd, lLayoutXs, cLayoutVisibiltyConfig, storeId.current, config.current.dashboardVersion);
        }
    }

    const onLayoutChange = (newLayout: Array<ILayoutObject>) => {
        // There is a defect in this layout control event, newLayout does not always have the correct layout when
        // there is a breakpoint change, hence we ignore it during breakpoint changes and use our stored values
        if(initialLoadComplete.current && previousRefWidth.current == newLayout[0].w && !breakpointHasChanged){
            if(currentBreakpoint.current == "lg"){
                setLayoutLg(newLayout);
            }
            else if(currentBreakpoint.current == "md" || currentBreakpoint.current == "sm"){
                setLayoutMd(newLayout);
            }
            else if(currentBreakpoint.current == "xs" || currentBreakpoint.current == "xxs"){
                setLayoutXs(newLayout);
            }
            else{
                //setLayoutLg(deduceLayout(newLayout));
            }
        }
        previousRefWidth.current = newLayout[0].w;
        if(breakpointHasChanged){
            setBreakpointHasChanged(false);
        }
    }

    const onBreakpointChange = (breakpoint) => {
        if(breakpoint != currentBreakpoint.current && currentBreakpoint.current != null){
            setBreakpointHasChanged(true);
        }
        currentBreakpoint.current = breakpoint;
    }

    return (
        <>
        <DashboardContext.Provider value={{propertyDasboardInEditMode: inEditMode}}>
            <S.PageWrapperDiv>
                <ResponsiveGridLayout
                    className="layout"
                    layouts={{
                        lg: layoutLg,
                        md: layoutMd,
                        sm: layoutMd,
                        xs: layoutXs,
                        xxs: layoutXs
                    }}
                    cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12}}
                    rowHeight={30}
                    isDraggable={false}
                    isResizable={false}
                    verticalCompact={true}
                    draggableCancel=".non-draggable"
                    onBreakpointChange={onBreakpointChange}
                    onLayoutChange={onLayoutChange}
                    >
                    {
                        layoutVisibiltyConfig.showKeyStats
                        ?
                        <S.SectionDiv key="keyStats">
                            {
                                <ActiveKeyStatsSection 
                                    id="keyStats"
                                    header={config.current && config.current.keyStatsSection.header}
                                    hideOnClick={removeSection}
                                />
                            }
                        </S.SectionDiv>
                        :
                        <div></div>
                    }
                    {
                        layoutVisibiltyConfig.showPropertyYield
                        ?
                        <S.SectionDiv key="propertyYield">
                            <ActivePropertyYieldSection 
                                id="propertyYield"
                                header={config.current && config.current.propertyYieldSection.header}
                                hideOnClick={removeSection}
                            />
                        </S.SectionDiv>
                        :
                        <div></div>
                    }
                    {
                        layoutVisibiltyConfig.showPriceValuationHistory
                        ?
                        <S.SectionDiv  key="priceValuationHistory">
                            {
                                <ActiveValuationHistorySection 
                                    id="priceValuationHistory"
                                    header={config.current && config.current.priceValuationHistorySection.header}
                                    hideOnClick={removeSection}
                                    config={config.current && config.current.priceValuationHistorySection}
                                    isRental={false}
                                />
                            }   
                        </S.SectionDiv>
                        :
                        <div></div>
                    }
                    {
                        layoutVisibiltyConfig.showRentValuationHistory
                        ?
                        <S.SectionDiv  key="rentValuationHistory">
                            {
                                <ActiveValuationHistorySection 
                                    id="rentValuationHistory"
                                    header={config.current && config.current.rentValuationHistorySection.header}
                                    hideOnClick={removeSection}
                                    config={config.current && config.current.rentValuationHistorySection}
                                    isRental={true}
                                />
                            }   
                        </S.SectionDiv>
                        :
                        <div></div>
                    }
                    {
                        layoutVisibiltyConfig.showResearchArea
                        ?
                        <S.SectionDiv key="researchArea">
                            <ActiveResearchAreaSection 
                                id="researchArea"
                                header={config.current && config.current.researchAreaSection.header}
                                hideOnClick={removeSection}
                            />
                        </S.SectionDiv>
                        :
                        <div></div>
                    }
                    {
                        layoutVisibiltyConfig.showStreetView
                        ?
                        <S.SectionDiv  key="streetView">
                            {
                                <ActiveStreetViewSection 
                                    id="streetView"
                                    header={config.current && config.current.streetViewSection.header}
                                    hideOnClick={removeSection}
                                    propertyId={propertyId}
                                    images={propertyImages}
                                    address={address}
                                    onImageChanged={onImgChanged}
                                />
                            }
                        </S.SectionDiv>
                        :
                        <div></div>
                    }
                    {
                        layoutVisibiltyConfig.showGoogleMap
                        ?
                        <S.SectionDiv  key="googleMap">
                            {
                                <ActiveGoogleMapSection 
                                    id="googleMap"
                                    header={config.current && config.current.googleMapSection.header}
                                    hideOnClick={removeSection}
                                    address={address}
                                />
                            }   
                        </S.SectionDiv>
                        :
                        <div></div>
                    }
                </ResponsiveGridLayout>
                <S.ResetEditWrapperDiv>
                    <S.ResetEditInnerWrapperDiv data-tour="resetEdit">
                        <S.ResetEditDiv onClick={() => resetSections()}><img src="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/reset_dashboard_icon.svg" /><label>Reset Dashboard</label></S.ResetEditDiv>
                        {
                            !inEditMode
                            ?
                            <S.ResetEditDiv onClick={() => saveEditSections(true, false)}><img src="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/edit_dashboard_icon.svg" /><label>Edit Dashboard</label></S.ResetEditDiv>
                            :
                            <S.ResetEditDiv onClick={() => saveEditSections(false, true)}><img src="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/save_dashboard_icon.svg" /><label>Save</label></S.ResetEditDiv>
                        }
                    </S.ResetEditInnerWrapperDiv>
                    
                </S.ResetEditWrapperDiv>
            </S.PageWrapperDiv>
        </DashboardContext.Provider>
        </>
        
    );
}

export default PropertyResearchDashboard;