import {
    Column,
    ColumnMenu,
    CommandColumn,
    ExcelExport,
    Filter,
    GridComponent,
    Group,
    Inject,
    Page,
    PdfExport,
    QueryCellInfoEventArgs,
    Reorder,
    Resize,
    Sort,
} from '@syncfusion/ej2-react-grids'
import {
    ChangedEventArgs,
    TextBoxComponent,
} from '@syncfusion/ej2-react-inputs'
import { ContextMenu } from '@syncfusion/ej2-react-navigations'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Router, withRouter } from 'react-router-dom'
import {
    filterOptions,
    pageSettings,
} from '../../../../configuration/grid_syncfusion_options'
import {
    addURLParameter,
    appendAddTenancyOptions,
    IAddTenancyOptions,
    removeURLParameter,
} from '../../../../helpers/locationHelpers'
import { toggleTransactionPopup } from '../../../../helpers/sidebarHelpers'
import { updateToastMessage } from '../../../../helpers/toastHelper'
import history, { goToRoute } from '../../../../history'
import { ExportTypeEnum } from '../../../../types/Transaction'
import { IAddTransactionReferer } from '../../../atoms/AddEditTransaction'
import { IAddEditTransactionOptions } from '../../../atoms/AddEditTransactionModal'
import IconButton from '../../../atoms/Buttons/IconButton'
import ActionIcon from '../../../atoms/Buttons/Icons/ActionIcon'
import AddAction from '../../../atoms/Buttons/Icons/AddAction'
import SFDropdown from '../../../atoms/SFDropdown'
import { onAddIssueClickGeneric } from '../../../atoms/SFDropdown/SFPropertyDropdown/_helpers'
import { addDocumentClicked } from '../../../atoms/SFDropdown/SFTenancyDropdown/_helpers'
import UserContactInfo, {
    UserContactInfoType,
} from '../../../atoms/UserContactInfo'
import styles from './TenancyGrid.module.scss'
import { editSettings, textWrapSettings } from './_config/columnOptions'
import { generateOdataSource } from './_config/dataConfig'
import { selectionSettings } from './_config/selectionConfig'
import { onDeleteRows } from './_helpers'
import { DefaultTemplate } from './_templates/defaultTemplate'
import { ITenancyGridOptions } from './_types'
const queryString = require('query-string')

interface IState {
    loading?: boolean
    hasBeenGrouped: boolean
    initialRender: boolean
    search?: string
    startDate?: Date
    endDate?: Date
    selectedType?: string
    deletingUserIds: string[]
    deleting: boolean
}

interface IProps {
    config: ITenancyGridOptions
    history: any
    location: any
    match: any
    dateRangeStart?: Date
    dateRangeEnd?: Date
}

class TenancyGrid extends Component<IProps, IState> {
    public grid: any
    public topRowRef: any

    constructor(props: any) {
        super(props)

        this.topRowRef = React.createRef()
        this.grid = React.createRef()

        this.state = {
            loading: false,
            selectedType: '',
            initialRender: false,
            hasBeenGrouped: false,
            search: '',
            deletingUserIds: [],
            deleting: false,
        }

        this.externalSearchHandler = this.externalSearchHandler.bind(this)
        this.externalExportHandler = this.externalExportHandler.bind(this)
        this.setType = this.setType.bind(this)
        this.dataBound = this.dataBound.bind(this)
        this.requestDataSource = this.requestDataSource.bind(this)
        this.onDateRangeChange = this.onDateRangeChange.bind(this)
        this.deleteRow = this.deleteRow.bind(this)
        this.contextMenuClickedDelete = this.contextMenuClickedDelete.bind(this)
        this.customCell = this.customCell.bind(this)
    }

    dataBound(args: any): void {
        if (
            this.grid.groupSettings.columns.length < 1 &&
            !this.state.hasBeenGrouped
        ) {
            this.grid.groupSettings = {
                showGroupedColumn: false,
                columns: this.props.config.groupSettings
                    ? this.props.config.groupSettings
                    : [],
            }

            this.setState({
                hasBeenGrouped: true,
            })
        }

        if (!this.props.config.dataFilterSettings) {
            return
        }
    }

    externalExportHandler(exportType: ExportTypeEnum): void {
        if (!this.grid || !this.grid.current) {
            return
        }

        const { current } = this.grid

        let selectedRecords = current.getSelectedRecords()
        if (!selectedRecords) {
            return
        }

        if (exportType == ExportTypeEnum.PDF) {
            current.pdfExport()
            return
        }

        if (exportType == ExportTypeEnum.Excel) {
            current.excelExport()
            return
        }

        if (exportType == ExportTypeEnum.Csv) {
            current.csvExport()
            return
        }
    }

    externalSearchHandler(searchValue: string): void {
        this.setState(
            {
                search: searchValue,
            },
            () => this.requestDataSource()
        )
    }

    customCell(args: QueryCellInfoEventArgs) {
        if ((args.column as Column).headerText === 'Tenant') {
            let data = args.data as any
            if (data.contactId && data.contactId != null) {
                ReactDOM.render(
                    <Router history={history}>
                        <div
                            onClick={() =>
                                goToRoute(
                                    `/dashboard/tenancy/view/${data.tenancyId}`
                                )
                            }
                        >
                            <UserContactInfo
                                id={data.contactId}
                                type={UserContactInfoType.Contact}
                            />
                        </div>
                    </Router>,
                    args.cell as Element
                )
            } else {
                ReactDOM.render(<div>No tenant</div>, args.cell as Element)
            }
        }

        if ((args.column as Column).field === 'action') {
            let data = args.data as any
            ReactDOM.render(
                <SFDropdown
                    loadedBeforeRender
                    customCssClassName={styles.syncfusionDropdown}
                    onSelect={(args) => {
                        if (args.item.text == 'Edit') {
                            goToRoute(`/dashboard/tenancy/view/${data.tenancyId}`)
                        }
                        if (args.item.text == 'Clone') {
                            this.addTenancyClick(data.tenancyId);
                        }
                        if (args.item.text == 'Add Document') {
                            addDocumentClicked(
                                {
                                    tenancyId: data.tenancyId || '',
                                    contactId: data.contactId || '',
                                    propertyId: data.propertyId || '',
                                    unitId: data.unitId || '',
                                },
                                'uploadDocumentOptions'
                            )
                        }
                        if (args.item.text == 'Add Tenancy Contract') {
                            addDocumentClicked(
                                {
                                    tenancyId: data.tenancyId,
                                    contactId: data.contactId,
                                    propertyId: data.propertyId,
                                    unitId: data.unitId,
                                    documentTypeId:
                                        '3bdd80c3-91c9-4e37-8588-db6a0db0f4a9',
                                    dialogueTitle: 'Tenancy Contract',
                                },
                                'uploadDocumentOptions'
                            )
                        }
                        if (args.item.text == 'Add Issue') {
                            onAddIssueClickGeneric(
                                {
                                    tenancyId: data.tenancyId,
                                    contactId: data.contactId,
                                    contactName: data.tenantName,
                                    propertyId: data.propertyId,
                                    propertyName: data.propertyName,
                                },
                                'issueOptions'
                            )
                        }
                        if (args.item.text == 'Linked Property') {
                            goToRoute(`/dashboard/property/${data.propertyId}`)
                        }
                        if (args.item.text == 'Linked Contact') {
                            goToRoute(`/dashboard/contact/${data.contactId}`)
                        }
                        if (args.item.text == 'Delete') {
                            onDeleteRows(
                                [data.tenancyId],
                                this.props.location,
                                this.props.history
                            )
                        }
                        if (args.item.text == 'Add Expense') {
                            let options: IAddEditTransactionOptions = {
                                referer: IAddTransactionReferer.Tenancy,
                                contactId: data.contactId,
                                contactName: data.tenantName,

                                propertyId: data.propertyId,
                                propertyName: data.propertyName,

                                unitId: data.unitId,
                                unitName: data.unitName,

                                tenancyId: data.tenancyId,
                                isExpense: true,
                            }

                            let stringed =
                                'addEditTransactionOptions=' +
                                JSON.stringify(options)

                            let query = addURLParameter(
                                this.props.location.search,
                                stringed
                            )
                            history.push({
                                search: query,
                            })

                            toggleTransactionPopup(true)
                        }
                        if (args.item.text == 'Add Income') {
                            let options: IAddEditTransactionOptions = {
                                referer: IAddTransactionReferer.Tenancy,
                                contactId: data.contactId,
                                contactName: data.contactName,

                                propertyId: data.propertyId,
                                propertyName: data.propertyName,

                                unitId: data.unitId,
                                unitName: data.unitName,

                                tenancyId: data.tenancyId,
                                isIncome: true,
                            }

                            let stringed =
                                'addEditTransactionOptions=' +
                                JSON.stringify(options)

                            let query = addURLParameter(
                                this.props.location.search,
                                stringed
                            )
                            history.push({
                                search: query,
                            })

                            toggleTransactionPopup(true)
                        }
                    }}
                    items={[
                        {
                            iconCss: styles.subIconEdit,
                            text: 'Edit',
                        },
                        {
                            iconCss: styles.subIconEdit,
                            text: 'Clone',
                        },
                        {
                            separator: true,
                        },
                        {
                            iconCss: styles.subIconAddDocument,
                            text: 'Add Document',
                        },
                        {
                            iconCss: styles.subIconAddTenancyContract,
                            text: 'Add Tenancy Contract',
                        },
                        {
                            iconCss: styles.subIconAddIssue,
                            text: 'Add Issue',
                        },
                        {
                            iconCss: styles.subIconAddExpense,
                            text: 'Add Expense',
                        },
                        {
                            iconCss: styles.subIconAddIncome,
                            text: 'Add Income',
                        },
                        {
                            separator: true,
                        },
                        ...(data.propertyId
                            ? [
                                  {
                                      iconCss: styles.subIconProperty,
                                      text: 'Linked Property',
                                  },
                              ]
                            : []),
                        ...(data.contactId
                            ? [
                                  {
                                      iconCss: styles.subIconContact,
                                      text: 'Linked Contact',
                                  },
                              ]
                            : []),
                        {
                            iconCss: styles.subIconDelete,
                            text: 'Delete',
                        },
                    ]}
                />,
                args.cell as Element
            )
        }
    }

    setType(transactionType: string) {
        if (transactionType != this.state.selectedType) {
            this.setState({ selectedType: transactionType }, () => {
                this.requestDataSource()
            })
        }
    }

    shouldComponentUpdate(nextProps: IProps, nextState: any): boolean {
        // If the refresh param is present then perform a refresh
        let newQuery = queryString.parse(
            nextProps.location.search
        ).refreshTenancyGrid
        let previousQuery = queryString.parse(
            this.props.location.search
        ).refreshTenancyGrid

        if (newQuery == 'true' && previousQuery != 'true') {
            let removeQuery = removeURLParameter(
                nextProps.location.search,
                'refreshTenancyGrid'
            )
            this.props.history.push({
                search: removeQuery,
            })

            return true
        }

        if (this.state.search != nextState.search) {
            return true
        }

        return false
    }

    requestDataSource(): void {
        if (!this.grid) {
            this.grid.dataSource = generateOdataSource(
                {
                    size: 0,
                    page: 0,
                    search: this.state.search,
                    propertyId:
                        this.props.config.dataFilterSettings &&
                        this.props.config.dataFilterSettings.propertyId,
                    contactId:
                        this.props.config.dataFilterSettings &&
                        this.props.config.dataFilterSettings.contactId,
                    unitId:
                        this.props.config.dataFilterSettings &&
                        this.props.config.dataFilterSettings.unitId,
                },
                this.state.startDate,
                this.state.endDate,
                this.props.config.dataFilterSettings &&
                    this.props.config.dataFilterSettings.active
            )
        }
    }

    deleteRow(): void {
        this.requestDataSource()
    }

    onDateRangeChange(startDate: Date, endDate: Date): void {
        this.setState(
            {
                startDate,
                endDate,
            },
            () => {
                this.requestDataSource()
            }
        )
    }

    contextMenuClickedDelete() {
        if (!this.grid) {
            return
        }

        let rows = this.grid.current.getSelectedRecords()

        if (rows.length == 0) {
            updateToastMessage('No rows have been selected.', 'warning')
            return
        }

        let tenancyIds = []
        for (let i = 0; i < rows.length; i++) {
            tenancyIds.push((rows[i] as any).tenancyId)
        }

        onDeleteRows(tenancyIds, this.props.location, this.props.history)
    }

    addTenancyClick = (cloneTenancyId?: string) => {
        if(this.props.config.addSettings){
            let options: IAddTenancyOptions = {
                contactId: this.props.config.addSettings.contactId,
                propertyId: this.props.config.addSettings.propertyId,
                propertyName: this.props.config.addSettings.propertyName,
                unitId: this.props.config.addSettings.unitId,
                unitName: this.props.config.addSettings.unitName,
                cloneTenancyId: cloneTenancyId
            }

            let query = appendAddTenancyOptions(this.props.location.search, options)

            this.props.history.push(`/dashboard/tenancy/add${query}`);
        }
        else{
            if(cloneTenancyId){
                let options: IAddTenancyOptions = {
                    cloneTenancyId: cloneTenancyId
                }
    
                let query = appendAddTenancyOptions(this.props.location.search, options)
    
                this.props.history.push(`/dashboard/tenancy/add${query}`);
            }else{
                this.props.history.push(`/dashboard/tenancy/add`);
            }
        }
    }

    render() {
        return (
            <div className={styles.page}>
                {/*<button onClick={() => this.props.history.push({ search: '?refreshTenancyGrid=true' })}>Refresh Grid</button>*/}
                <div className={styles.content}>
                    <div className={`${styles.row}`}>
                        <div className={styles.column}>
                            <div className={styles.dropdown}>
                                <div className={styles.searchInput}>
                                    <TextBoxComponent
                                        change={(args: ChangedEventArgs) =>
                                            this.externalSearchHandler(
                                                args.value
                                            )
                                        }
                                        placeholder="Search Tenancies"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={styles.iconColumn}>
                            <SFDropdown
                                loadedBeforeRender
                                customCssClassName={styles.syncfusionDropdown}
                                customToggle={
                                    <IconButton
                                        button={{
                                            text: 'Actions',
                                            displayType: 'action',
                                            elementType: 'button',
                                            icon: 'action',
                                        }}
                                    />
                                }
                                items={[
                                    {
                                        iconCss: styles.subIconAdd,
                                        text: 'Add Tenancy',
                                    },
                                    {
                                        iconCss: styles.subIconDelete,
                                        text: 'Delete',
                                    },
                                    {
                                        separator: true,
                                    },
                                    {
                                        iconCss: styles.subIconExportExcel,
                                        text: 'Export to Excel',
                                    },
                                    {
                                        iconCss: styles.subIconExportCsv,
                                        text: 'Export to CSV',
                                    },
                                    {
                                        iconCss: styles.subIconExportPdf,
                                        text: 'Export to PDF',
                                    },
                                ]}
                                onSelect={(args) => {
                                    if (args.item.text === 'Add Tenancy') {
                                        this.addTenancyClick();
                                    }
                                    if (args.item.text === 'Delete') {
                                        this.contextMenuClickedDelete()
                                    }
                                    if (args.item.text === 'Export to PDF') {
                                        this.externalExportHandler(
                                            ExportTypeEnum.PDF
                                        )
                                    }
                                    if (args.item.text === 'Export to Excel') {
                                        this.externalExportHandler(
                                            ExportTypeEnum.Excel
                                        )
                                    }
                                    if (args.item.text === 'Export to CSV') {
                                        this.externalExportHandler(
                                            ExportTypeEnum.Csv
                                        )
                                    }
                                }}
                            />
                        </div>

                        <IconButton
                            button={{
                                text: 'Add Tenancy',
                                displayType: 'submit',
                                elementType: 'button',
                                icon: 'add',
                                onClick: () => {
                                    this.addTenancyClick();
                                },
                            }}
                        />

                        {/* <div className={`${styles.addTenancy}`}>
                            <div
                                className={styles.icon}
                                onClick={() => {
                                    let options: IAddTenancyOptions = {
                                        contactId:
                                            this.props.config.addSettings &&
                                            this.props.config.addSettings
                                                .contactId,
                                        propertyId:
                                            this.props.config.addSettings &&
                                            this.props.config.addSettings
                                                .propertyId,
                                        unitId:
                                            this.props.config.addSettings &&
                                            this.props.config.addSettings
                                                .unitId,
                                    }

                                    if (this.props.config.addSettings) {
                                        let query = appendAddTenancyOptions(
                                            this.props.location.search,
                                            options
                                        )

                                        this.props.history.push({
                                            search: query,
                                        })
                                    }

                                    toggleAddEditTenancyPopup(true)
                                }}
                            >
                                <img src={addIcon} />
                                <span className={styles.text}>Add Tenancy</span>
                            </div>
                        </div> */}
                    </div>

                    <div className={`${styles.row}`}>
                        <div className={`${styles.column} ${styles.grid}`}>
                            <GridComponent
                                queryCellInfo={this.customCell}
                                editSettings={editSettings}
                                showColumnMenu
                                id="tenancyGrid"
                                textWrapSettings={textWrapSettings}
                                allowTextWrap={true}
                                allowExcelExport={true}
                                allowPdfExport={true}
                                allowGrouping={true}
                                dataSource={generateOdataSource(
                                    {
                                        size: 0,
                                        page: 0,
                                        search: this.state.search,
                                        propertyId:
                                            this.props.config
                                                .dataFilterSettings &&
                                            this.props.config.dataFilterSettings
                                                .propertyId,
                                        contactId:
                                            this.props.config
                                                .dataFilterSettings &&
                                            this.props.config.dataFilterSettings
                                                .contactId,
                                        unitId:
                                            this.props.config
                                                .dataFilterSettings &&
                                            this.props.config.dataFilterSettings
                                                .unitId,
                                    },
                                    this.state.startDate,
                                    this.state.endDate,
                                    this.props.config.dataFilterSettings &&
                                        this.props.config.dataFilterSettings
                                            .active
                                )}
                                allowResizing={true}
                                allowReordering={true}
                                selectionSettings={selectionSettings}
                                allowPaging={true}
                                allowSorting={true}
                                sortSettings={{
                                    columns: [
                                        {
                                            field: 'createdDate',
                                            direction: 'Descending',
                                        },
                                    ],
                                }}
                                pageSettings={pageSettings}
                                filterSettings={filterOptions}
                                allowFiltering={true}
                                ref={this.grid}
                            >
                                <Inject
                                    services={[
                                        ColumnMenu,
                                        ContextMenu,
                                        CommandColumn,
                                        Filter,
                                        Page,
                                        Sort,
                                        ExcelExport,
                                        PdfExport,
                                        Group,
                                        Reorder,
                                        Resize,
                                    ]}
                                />

                                {DefaultTemplate({ ...this.props })}
                            </GridComponent>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(TenancyGrid)
