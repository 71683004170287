import React, { Component } from "react";

interface IProps {
  value: string;
  name: string;
  prefix?: string;
  precision?: number;
  suffix?: string;
  className?: string;
  inputType?: string;
  disabled?: boolean;
  maxValue?: number;
  maxLength?: number;
  allowNegative?: boolean;
  handleChange?(event: any, maskedValue: string, floatValue: string): void;
  handleBlur?(): void;
  innerRef?: any;
}

interface IState {
  maskedValue: string;
  floatValue: string;
}

class CurrencyInput extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.mask = this.mask.bind(this);
    this.prepareProps = this.prepareProps.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);

    this.state = this.prepareProps(this.props);
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();

    var ref = this.mask(event.target.value);

    var maskedValue = ref.maskedValue;
    var value = ref.value;

    if (value > this.props.maxValue) {
      return;
    }

    event.persist();

    this.setState({ maskedValue: maskedValue, floatValue: value });
    if (this.props.handleChange) {
      this.props.handleChange(event, maskedValue, value);
    }
  }

  handleBlur(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
  }

  mask(value: any): any {
    var precision: any;
    if (this.props.precision) {
      precision = this.props.precision;
    }
    if (this.props.precision && this.props.precision < 0) {
      precision = 0;
    }

    value = String(value);

    if (value.length == 0) {
      return {
        value: 0,
        maskedValue: ""
      };
    }

    var digits = value.match(/\d/g) || ["0"];
    var numberIsNegative = false;
    if (this.props.allowNegative) {
      var negativeSignCount = (value.match(/-/g) || []).length;
      numberIsNegative = negativeSignCount % 2 === 1;
      var allDigitsAreZero = true;
      for (var idx = 0; idx < digits.length; idx += 1) {
        if (digits[idx] !== "0") {
          allDigitsAreZero = false;
          break;
        }
      }
      if (allDigitsAreZero) {
        numberIsNegative = false;
      }
    }

    while (digits.length <= precision) {
      digits.unshift("0");
    }
    if (precision > 0) {
      digits.splice(digits.length - precision, 0, ".");
    }

    digits = Number(digits.join(""))
      .toFixed(precision)
      .split("");
    var raw = Number(digits.join(""));

    var decimalpos = digits.length - precision - 1; // -1 needed to position the decimal separator before the digits.
    if (precision > 0) {
      digits[decimalpos] = ".";
    } else {
      decimalpos = digits.length;
    }

    for (var x = decimalpos - 3; x > 0; x = x - 3) {
      digits.splice(x, 0, ",");
    }

    if (this.props.prefix) {
      digits.unshift(this.props.prefix);
    }
    if (this.props.suffix) {
      digits.push(this.props.suffix);
    }

    if (this.props.allowNegative && numberIsNegative) {
      digits.unshift("-");
      raw = -raw;
    }

    return {
      value: raw,
      maskedValue: digits.join("").trim()
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this.prepareProps(nextProps));
  }

  prepareProps(props: any) {
    var initialValue = props.value;

    if (!props.value) {
      return;
    }

    if (props.thousandSeparator === ".") {
      initialValue = initialValue.toString().replace(/\./g, "");
    }

    initialValue = initialValue.toString().replace(/[^0-9-.]/g, "");
    initialValue = Number.parseFloat(initialValue);

    initialValue = Number(initialValue).toLocaleString(undefined, {
      style: "decimal",
      minimumFractionDigits: props.precision,
      maximumFractionDigits: props.precision
    });

    var ref = this.mask(initialValue);
    var maskedValue = ref.maskedValue;
    var value = ref.value;

    return { maskedValue: maskedValue, floatValue: value };
  }

  handleFocus = event => event.target.select();

  render() {
    return (
      <input
        maxLength={this.props.maxLength}
        max={this.props.maxValue}
        className={this.props.className}
        type={this.props.inputType ? this.props.inputType : "text"}
        value={this.state ? this.state.maskedValue : null}
        name={this.props.name}
        onChange={this.handleChange}
        autoComplete={"off"}
        disabled={this.props.disabled ? this.props.disabled : false}
        onFocus={this.handleFocus}
        onBlur={this.props.handleBlur}
        ref={this.props.innerRef ? this.props.innerRef : null}
      />
    );
  }
}
export default CurrencyInput;
