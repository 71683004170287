import React, { useEffect, useRef } from "react";
import { IValuationItem } from "../../../../../types/PropertyDetailService";
import Currency from "../../../../atoms/Currency";
import { QuickViewType } from "../../../../../types/QuickView";
import GenericPopup from "../../../../atoms/GenericPopup";
import { generateId } from "../../../../../helpers/randomHelpers";
import infoIcon from '../../../../../images/icons/ic_info_24px.png'
import deleteIcon from "../../../../../images/icons/property-action/Delete.png";
import moment from "moment";
import * as S from "./ValuationTable.styles";

interface IProps {
  title: string;
  valuations: Array<IValuationItem>;
  deleteData?(id: string): void;
}

const ValuationTable = ({title, valuations, deleteData}: IProps) => {

  const getSourceFriendlyText = (source) => {
    if (source == "Land Registry") {
      return "Sold";
    }

    if (source == "Property Data") {
      return "AVM";
    }

    return source;
  }

  return (
    <>
    {
      valuations && valuations.length > 0 
      ?
      <S.Table>
        {/* <S.TableCaption>Price Valuation History</S.TableCaption>
        <S.TableHeader></S.TableHeader> */}
        <S.TableHeaderCell>Date</S.TableHeaderCell>
        <S.TableHeaderCell>Type</S.TableHeaderCell>
        <S.TableHeaderCell></S.TableHeaderCell>
        <S.TableHeaderCell>£/SqFt</S.TableHeaderCell>
        <S.TableHeaderCell justify="right">Value</S.TableHeaderCell>
        <S.TableHeaderCell></S.TableHeaderCell>
        {
          valuations.map((x: IValuationItem, index: number) => {
            return (
              <S.TableRow>
                <S.TableCell justify="left" width="24%">
                  {moment(x.dateTime).local().format("DD/MM/YYYY")}
                </S.TableCell>
                <S.TableCell justify="left" width="14%">
                  {getSourceFriendlyText(x.source)}
                </S.TableCell>
                <S.TableCell justify="left" width="10%">
                  <GenericPopup
                    identifier={generateId(10)}
                    options={{
                      type: QuickViewType.Valuation,
                      valuationId: x.propertyValuationId,
                      toggleComponent: <img height={"20px"} width={"20px"}src={infoIcon} />
                    }}
                  />
                </S.TableCell>
                <S.TableCell justify="left" width="14%">
                  {
                    x.pricePer > 0 
                    ?
                    <Currency value={x.pricePer} prefix="£" />
                    :
                    "N/A"
                  }
                </S.TableCell>
                <S.TableCell justify="right" width="30%">
                  <Currency value={x.price} prefix="£" />
                </S.TableCell>
                <S.TableCell justify="right" width="8%">
                <S.DeleteImage
                  src={deleteIcon}
                  height={"20px"}
                  width={"20px"}
                  onClick={(e: any) => { deleteData(x.propertyValuationId)}}
                />
                </S.TableCell>
              </S.TableRow>
            );
          })
        }
      </S.Table>
      :
      <div>No valuations added</div>
    }
      
    </>
  );

}

export default ValuationTable;
