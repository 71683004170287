import { apiService } from "../services/ApiService";
import { createBearerToken } from "../helpers/authHelpers";
import * as domains from "../configuration/domains";
import { propertyitemcount } from "../configuration/appConfig";
import { propertyCardStatus } from "../configuration/appConfig";

const buildpropertyStatus = (): string => {
  let statusString = "";
  propertyCardStatus.split(",").forEach(element => {
    statusString += "&statusIds=" + element;
  });
  return statusString;
};
{
}

export const getTotalProperties = (): Promise<any> => {
  return apiService.get(
    `${domains.dashboardService}/totalProperties`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    }
  );
};

export const getDashboardIssues = (): Promise<any> => {
  return apiService
    .get(
      `${domains.dashboardService}/getissues?field=formattedAddress&field=propertyId&field=propertyMetadata`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: createBearerToken()
        }
      }
    )
    .catch(error => {
      return error;
    });
};

export const getTotalStats = (propertyId: string): Promise<any> => {
  return apiService
    .get(
      `${domains.dashboardService}/totalStats?propertyId=${propertyId}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: createBearerToken()
        }
      }
    )
    .catch(error => {
      return error;
    });
};

export const getChart = (chartApiName: string): Promise<any> => {
  return apiService
    .get(
      `${domains.dashboardService}/${chartApiName}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: createBearerToken()
        }
      }
    )
    .catch(error => {
      return error;
    });
};

export const getPortfolioChart = (): Promise<any> => {
  return apiService
    .get(
      `${domains.dashboardService}/portfolioChart`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: createBearerToken()
        }
      }
    )
    .catch(error => {
      return error;
    });
};

export const getPropertyCards = (): Promise<any> => {
  return apiService.get(
    `${domains.dashboardService}/propertyCards?&propertyCardCount=${propertyitemcount}` +
      buildpropertyStatus(),
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    }
  );
};