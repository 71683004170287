// Import React
import React, { Component } from "react";
import { withRouter, Route, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "react-dropzone-uploader/dist/styles.css";
import { getClient } from "../../../services/ClientService";
import { IClientData } from "../../../types/ClientService";
import detailsIcon from "../../../images/icons/user-management/usermanagement_details.svg";
import auditIcon from "../../../images/icons/icon_timeline.svg";
import TabbedNavigationContainer from "../../atoms/TabbedNavigationContainer";
import Col from "reactstrap/lib/Col";
import SimpleRouterTabs from "../../atoms/SimpleRouterTabs";
import styles from "./ClientPage.module.scss";
import ClientDetailPage from "../ClientDetailPage";
import AuditPage from "../PlatformManagementPage/AuditPage";
const queryString = require("query-string");

interface IState {
  client: IClientData;
  isLoading: boolean;
}

interface IRouterProps {
  history: any;
  location: any;
  match: any;
}

type Props = IRouterProps;

class ClientPage extends Component<Props, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      client: null,
      isLoading: false
    };
  }

  notify = (message: string) => {
    toast(message);
  };

  componentDidMount(): void {
    window.scrollTo(0, 0);

    let clientId = this.props.match.params.clientId;
    if (!clientId) {
      return;
    }

    this.setState({
      isLoading: true
    });

    getClient(clientId).then(resp => {
      if (resp && resp.status == 200 && resp.data) {
        this.setState({
          client: resp.data,
          isLoading: false
        });
      }
    });
  }

  render() {
    return (
      <div className={styles.page}>
        <div className={styles.navigation}>
          <TabbedNavigationContainer fullWidth>
            <Col className={styles.tabs}>
              <SimpleRouterTabs
                routes={[
                  {
                    path: this.props.match.url + "/detail",
                    text: "Detail",
                    mobileText: "Detail",
                    icon: detailsIcon
                  },
                  {
                    path: this.props.match.url + "/timeline",
                    text: "Timeline",
                    mobileText: "Timeline",
                    icon: auditIcon
                  }
                ]}
              />
            </Col>
          </TabbedNavigationContainer>

          <Col className={styles.routedPage}>
            <div className={styles.content}>
              <Route
                path={`${this.props.match.url}`}
                exact
                render={() => <Redirect to={`${this.props.match.url}/detail`} />}
              />

              <Route
                path={`${this.props.match.path}/detail`}
                exact
                render={() => (
                  <div className={styles.paddedPage}>
                    <ClientDetailPage />
                  </div>
                )}
              />

              <Route
                path={`${this.props.match.path}/timeline`}
                exact
                render={() => (
                  <div className={styles.paddedPage}>
                    <AuditPage clientId={this.props.match.params.clientId} hideDropdown />
                  </div>
                )}
              />
            </div>
          </Col>
        </div>
      </div>
    );
  }
}

export default withRouter(ClientPage);