import React from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { IDashboardData } from '../../../../../types/DashboardService'
import PropertySummaryCard from '../../../../atoms/PropertySummaryCard'
import { IPropertyCard } from '../../../../../types/PropertyService/IPropertyService'
import { toggleAddPropertySidebar } from '../../../../../actions/appActions';
import SectionHeader from "../SectionHeader/SectionHeader.component"
import * as S from "./RecentPropertiesSection.styles";
import { useFetchPropertyCardsData } from '../../../../../servicesHooks/useFetchPropertyCardsData.hook';
import { Loader } from '@rentchief/components';

interface sectionProps {
    id: string;
    header: string;
    hideOnClick(id: string): void;
    onDelete(): void;
}

const RecentPropertiesSection = ({id, header, hideOnClick, onDelete}: sectionProps) => {
    const dispatch = useDispatch();
    let history = useHistory();

    const { dataRetrieved, data } = useFetchPropertyCardsData();

    const goToPropertyList = (): void => {
        history.push(
            `/dashboard/properties?filterAmount=8&viewType=0&sort=0&isSorting=false&search=`
        )
    }

    const addProperty = () => {
        dispatch(toggleAddPropertySidebar(true));
    }

    return (
        <div>
            <SectionHeader id={id} header={header} hideOnClick={hideOnClick} titleOnClick={() => goToPropertyList()}  addOnClick={addProperty}/>
            {
                !dataRetrieved
                ?
                    <Loader />
                :
                <S.PropertiesListDiv>
                {
                    data && data.atAGlance.propertyCards &&
                    data.atAGlance.propertyCards
                        .map((prop: IPropertyCard, index ) => (
                                <div>
                                    <PropertySummaryCard
                                        onDelete={onDelete}
                                        options={{
                                            propertyId: prop.propertyId,
                                            showTenancy: true,
                                            canAction: true,
                                            tenancyViewOptions:
                                                {
                                                    occupationIndicator: false,
                                                    bedroomIcon: true,
                                                    bathroomIcon: true,
                                                },
                                        }}
                                        canView
                                        displaySmallName
                                        isDashboard
                                        propertyCard={prop}
                                        key={index}
                                    />
                                </div>
                            )
                        )
                }
                </S.PropertiesListDiv>
            }
        </div>
    )
}

export default RecentPropertiesSection;