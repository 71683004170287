// Import React
import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";

import styles from "./IssueActionBody.module.scss";
import {
  toggleIssueActionSidebar,
  toggleIssuePopup,
} from "../../../helpers/sidebarHelpers";
import CloseButton from "../../atoms/CloseButton";
const queryString = require("query-string");

// Icons
import propertyDetail from "../../../images/icons/property-action/property-detail.png";
import deleteIcon from "../../../images/icons/property-action/delete.svg";
import { IContactListItem } from "../../../types/ContactService/ContactService";
import {
  removeURLParameter,
  addURLParameter
} from "../../../helpers/locationHelpers";
import { canDeleteIssue } from "../../../helpers/permissionHelpers";
import { IIssueActionMenuOptions } from "../../../types/IssueService";
import DeleteIssueModal from "../../atoms/DeleteIssueModal";
import { IRaiseIssueOptions } from "../../atoms/EditIssue";

interface IProps {
  contactItem?: IContactListItem;
}

interface IRouterProps {
  history: any;
  location: any;
  match: any;
}

interface IState {
  options: IIssueActionMenuOptions;
  isDeleting: boolean;
}

type Props = IProps & IRouterProps;

class IssueActionBody extends Component<Props, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      options: null,
      isDeleting: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
  }

  componentDidMount(): void {
    let route = this.props.location.search;
    let query = queryString.parse(route).actionIssue;
    if (!query) {
      return;
    }

    let obj: IIssueActionMenuOptions = JSON.parse(query);

    if (!obj) {
      return;
    }

    this.setState({
      options: obj
    }, () => {
      let removeQuery = removeURLParameter(this.props.location.search, "actionIssue");
      this.props.history.push({
        search: removeQuery
      })
    });
  }

  handleChange(event: React.FormEvent<HTMLInputElement>) {
    const field = event.currentTarget.name;
    const value: string = event.currentTarget.value;
    this.setState({ [field]: value } as Pick<IState, any>);
  }

  wipeQueryParameters(): void {
    this.props.history.push({
      search: ""
    });
  }

  componentWillUnmount(): void {
    let query = removeURLParameter(this.props.location.search, "actionIssue");
    this.props.history.push({
      search: query
    });
  }

  onClickEdit(): void {
    let query: IRaiseIssueOptions = {
      issueId: this.state.options.issueId
    }

    let queryParam = addURLParameter(this.props.location.search, "issueOptions=" + JSON.stringify(query));
    this.props.history.push({
      search: queryParam
    });

    toggleIssuePopup(true);
    toggleIssueActionSidebar(false);
  }

  onDelete(): void {
    toggleIssueActionSidebar(false);
  }

  render() {
    if (!this.state.options) {
      return null;
    }

    return (
      <div className={styles.page}>
        <DeleteIssueModal
          open={this.state.isDeleting}
          onOpenModal={() => this.setState({ isDeleting: true })}
          onCloseModal={() => this.setState({ isDeleting: false })}
          onDeleteCallBack={this.onDelete}
          issueTitle={this.state.options.issueTitle}
          issueId={this.state.options.issueId} />
        <Row>
          <Col>
            <div className={styles.closeButton}>
              <CloseButton close={() => toggleIssueActionSidebar(false)} />
            </div>
          </Col>
        </Row>
        <Col className={styles.col}>
          <h4 className={styles.title}>What would you like to do?</h4>
          <ul className="list-group">
            <li
              className={styles.list}
              onClick={() => this.onClickEdit()}
            >
              <img src={propertyDetail} className={styles.icon} />
              <h5 className={styles.listItem}>Edit Issue</h5>
            </li>
            {
              canDeleteIssue() &&
              <li
                className={styles.list}
                onClick={() => {
                  this.setState({ isDeleting: true })
                }}
              >
                <img src={deleteIcon} className={styles.icon} />
                <h5 className={styles.listItem}>Delete Issue</h5>
              </li>
            }
          </ul>
        </Col>
      </div>
    );
  }
}

export default withRouter(IssueActionBody);