import React, { useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IStartChatOptions } from '../../../atoms/StartChatModal';
import { toggleStartChatPopup } from '../../../../helpers/sidebarHelpers';
import { addURLParameter } from '../../../../helpers/locationHelpers';
import { IListItemOptions } from '../../../../types/ListFilters';
import useStaticHtml from '../_hooks/useStaticHtml.hook';
import useStaticHtmlString from "../_hooks/useStaticHtmlString.hook";
import { IEmptyStateConfig } from "../_config/config";
import { chatEmptyStateHtml } from "./ChatEmptyStateHtml";

interface IProps {
    listOptions?: IListItemOptions;
}

const ChatEmptyState = ({listOptions}: IProps) => {
    const history = useHistory();
    const location = useLocation();

    const config = useRef<IEmptyStateConfig>(require("../_config/config.json"));

    const onAddChatClick = (): void => {
        let options: IStartChatOptions = {
            redirectToChat: true,
            redirectToPropertyId: listOptions && listOptions.propertyId,
        }

        let queryParam = addURLParameter(
            location.search,
            'startChatOptions=' + JSON.stringify(options)
        )
        history.push({
            search: queryParam,
        })

        toggleStartChatPopup(true)
    }

    const emptyStateHtml = useStaticHtml(config.current.chat.htmlName, 
                                        config.current.chat.buttonId, 
                                        config.current.chat.buttonText, 
                                        onAddChatClick);

    const emptyStateHtml1 = useStaticHtmlString(chatEmptyStateHtml, 
                                                config.current.chat.buttonId, 
                                                config.current.chat.buttonText, 
                                                onAddChatClick);

    return (
        emptyStateHtml
    )

}

export default ChatEmptyState;