import styled from 'styled-components';

export const SectionDiv = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 32% 32% 32%; 
    grid-template-areas:
        'header header header'
        'new inProgress total';
`; 

export const NewDiv = styled.div`
    grid-area: new;
`;

export const InProgressDiv = styled.div`
    grid-area: inProgress;
`;

export const TotalDiv = styled.div`
    grid-area: total;
`;

export const ActionDiv = styled.div`
    width: 150px;
`;
