import React from "react";
import RollbarService from "../../../helpers/rollbarHelpers";
import { updateToastMessage } from "../../../helpers/toastHelper";
import { Redirect, withRouter } from 'react-router-dom';
import ErrorMessage from "../ErrorMessage";

interface IProps {
  errorText?: string;
  children?: any;

  history: any;
  location: any;
  match: any;
}

interface IState {
  error: any;
}

class ErrorsHoldMyBeer extends React.Component<IProps> {
  state = {
    hasError: false,
    error: { message: '', stack: '' },
    info: { componentStack: '' }
  };

  unlisten = null;

  static getDerivedStateFromError = error => {
    return { hasError: true };
  };

  componentDidMount(): void {
    const { history } = this.props;
    this.unlisten = history.listen(() => {
      if (this.state.hasError) {
        this.setState({
          hasError: false
        })
      }
    })
  }

  componentWillUnmount(): void {
    this.unlisten();
  }

  componentDidCatch = (error, info) => {
    this.setState({ error, info });
    RollbarService.getInstance().error(error);

    // Send Toast
    updateToastMessage("Something has gone wrong. Support have been notified", "failure");
  };

  render() {
    const { hasError, error, info } = this.state;
    const { children } = this.props;

    return hasError ? <div>Something has gone wrong. Support have been notified</div> : children;
  }
}

export default withRouter(ErrorsHoldMyBeer);