import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader } from "@rentchief/components";
import { RootState } from '../../../../../reducers/rootReducer';
import AssociatedDocuments from '../../../../atoms/AssociatedDocuments'
import { onAddDocument } from '../../helpers/dashboardHelper';
import { getAssociatedDocuments, IAssociatedDocumentsRequest } from '../../../../../services/DocumentService'
import SectionHeader from "../SectionHeader/SectionHeader.component"
import { ISection } from '../../PropertyDashboard/_config/dashboardConfig';
import InitialStateSection from '../../Sections/InitialStateSection/InitialStateSection.component';
import * as S from "./ActiveDocumentsSection.styles";

interface sectionProps {
    id: string;
    header: string;
    hideOnClick(id: string): void;
    config: ISection;
}

const ActiveDocumentsSection = ({id, header, hideOnClick, config}: sectionProps) => {
    // Redux 
    const { propertyId } = useSelector((state: RootState) => state.property.property_data.property_data);

    let history = useHistory();

    const [documentsCount, setDocumentsCount] = useState(-1);
    const [documentsRetrieved, setDocumentsRetrieved] = useState(false);

    useEffect(() => {
        fetchDocuments(); 
    }, []);

    useEffect(() => {
        // This is a bit hacky, due to the way doc uploading working need to do this to 
        // trigger refresh
        const queryParams = new URLSearchParams(location.search);
        if(queryParams.has('refreshDocumentsGrid')){
            fetchDocuments();
            queryParams.delete('refreshDocumentsGrid');
            history.replace({ search: queryParams.toString() });
        }    
    });

    const fetchDocuments = async () => {
        setDocumentsRetrieved(false);
        const request: IAssociatedDocumentsRequest = { propertyId: propertyId }
        const { data } = await getAssociatedDocuments(request, null, null)

        if (data) {
            setDocumentsRetrieved(true);
            setDocumentsCount(data.totalDocuments);
        }
    }

    return (
        <>
            {
                !documentsRetrieved
                ?
                <Loader />
                :
                documentsCount > 0
                ?
                <div>
                    <SectionHeader id={id} header={header} hideOnClick={hideOnClick} titleOnClick={() => history.push(`/dashboard/property/${propertyId}/documents`)} addOnClick={() => onAddDocument(propertyId, history)}/>
                    <S.SectionDiv onClick={(e: any) => e.stopPropagation()}>
                        <AssociatedDocuments
                            onClick={() => history.push(`/dashboard/property/${propertyId}/documents`)}
                            viewAll
                            canAdd
                            options={{ propertyId: propertyId }}
                        />
                    </S.SectionDiv>
                </div>
                :
                <InitialStateSection 
                    id="documents"
                    header={config && config.header}
                    iconUrl={config && config.initialState.iconUrl}
                    title={config && config.initialState.title}
                    summary={config && config.initialState.summary}
                    //action={<S.ActionDiv><Button onClick={() => onAddDocument(propertyId, history)}>Add</Button></S.ActionDiv>}
                    hideOnClick={hideOnClick}
                    titleOnClick={() => history.push(`/dashboard/property/${propertyId}/documents`)}
                    addOnClick={() => onAddDocument(propertyId, history)}
                />
            }
        </>
        
    )
}

export default ActiveDocumentsSection;