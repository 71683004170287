import { store } from "../store";
import Elevio from 'elevio/lib/client';

export function setElevioConfiguration(hash: string): void {
  let data = store.getState();

  if (!hash) {
    return;
  }

  Elevio.load('5ee75d4a9864d').then(() => {
    Elevio.setUser(({
      first_name: data.user.forename,
      last_name: data.user.surname,
      email: data.user.email,
      user_hash: hash,
      groups: [data.user.role],
    }));
  });
}

export function openElevio(): void {
  Elevio.open();
}

export function removeElevioConfiguration(): void {
  Elevio.logoutUser();
}

export function goToElevioArticle(articleId: string): void {
  if (window) {
    window.open(`https://rent-chief.elevio.help/en/articles/${articleId}`, '_blank');
  }
}