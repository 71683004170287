import React from "react";
import { Col, Row } from "reactstrap";

import styles from "./ListItem.module.scss";
import {
  IUserListItem,
  IClientListItem
} from "../../../../types/ClientService";

interface IProps {
  user?: IUserListItem;
  showImage?: boolean;
  client?: IClientListItem;
  children: any;
}

class ListItem extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <Col xs="12" sm="6" md="6" lg="12" xl="12" className={styles.listItem}>
        <div className={styles.propertyListItem}>
          <Row className={styles.imageDiv}>
            <Col>
              <div className={styles.paddedContent}>{this.props.children}</div>
            </Col>
          </Row>
        </div>
      </Col>
      //*/
    );
  }
}

export default ListItem;
