import React, { Component } from "react";
import styles from "./ProfileImage.module.scss";
import Avatar from "@material-ui/core/Avatar";

interface IName {
  forename: string;
  surname: string;
  image: string;
  large: boolean;
}

class ProfileImage extends Component<IName> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  render() {
    if (!this.props && this.props.image) {
      return (
        <Avatar
          className={this.props.large ? styles.avatarLarge : styles.avatar}
        >
          <img src={this.props.image} />
        </Avatar>
      );
    }

    if (this.props && this.props.image) {
      return (
        <div className={
          this.props.large ? styles.profileImageLarge : styles.profileImage
        } style={{ backgroundImage: `url(${this.props.image}` }}>
        </div>
      );
    }

    if (
      (this.props && this.props.forename) ||
      (this.props && this.props.surname)
    ) {
      return (
        <Avatar
          className={this.props.large ? styles.avatarLarge : styles.avatar}
        >
          {" "}
          {this.props.forename && this.props.forename.substring(0, 1)}
          {this.props.surname && this.props.surname.substring(0, 1)}{" "}
        </Avatar>
      );
    }

    if (this.props.forename && this.props.forename.length > 0 || this.props.forename && this.props.surname.length > 0) {
      return (
        <Avatar
          className={this.props.large ? styles.avatarLarge : styles.avatar}
        >
          {" "}
          {this.props.forename.substring(0, 1)}
          {this.props.surname.substring(0, 1)}{" "}
        </Avatar>
      );
    }
    return (
      <img
        className={
          this.props.large ? styles.profileImageLarge : styles.profileImage
        }
        src={
          "https://static.wixstatic.com/media/497d73_a5a8e3cc69334c629885e45e7ea27743~mv2.png"
        }
      />
    );
  }
}

export default ProfileImage;
