export const rcToken = process.env.REACT_APP_TOKEN_NAME || "rc-token-generic";
export const termsAndConditionsContentId =
  process.env.REACT_APP_CMS_TERMS_AND_CONDITIONS_ID ||
  "2BBE4C79-9FE8-4F98-9106-F9BB5C5271D4";
export const dataPolicy =
  process.env.REACT_APP_DATA_POLICY_ID ||
  "0C49CAA0-F5A3-49DC-AF2F-6D831DC12251";
export const cookiePolicy =
  process.env.REACT_APP_COOKIE_POLICY_ID ||
  "88DB1F32-06E9-435E-9031-9ACA388F2CD7";
export const rememberLoginDuration_days =
  process.env.REACT_APP_REMEMBER_LOGIN_DURATION_DAYS || "183";
export const rememberLoginDurationForSession_hours =
  process.env.REACT_APP_REMEMBER_LOGIN_DURATION_FOR_SESSION_HOURS || "8";
export const applicationId =
  process.env.REACT_APP_APPLICATION_ID ||
  "887e2736-9364-4b54-873d-c6399bacc637";
export const supportRefreshTokens =
  process.env.REACT_APP_REFRESH_TOKENS || true;
export const refreshTokenHeader =
  process.env.REACT_APP_REFRESH_TOKEN_HEADER || "rc-refreshed-token";
export const useGetAddressIoAddressSearch =
  process.env.REACT_APP_GETADDRESSIO_ADDRESS_SEARCH || true;
export const GmapAPI =
  process.env.REACT_APP_GMAP_API_KEY ||
  "AIzaSyBdMtHW0ceWL35CMbukKvnZXL1W4YVvSzc";
export const buildNumber =
  process.env.REACT_APP_RC_UI_BUILD_VERSION_NUMBER || "0.0.0";
export const propertyitemcount =
  process.env.REACT_APP_RC_UI_Property_Item_Count || "4";
export const propertyCardStatus =
  process.env.REACT_APP_RC_UI_Property_Card_Status ||
  "A2E860AE-D660-4969-23E6-08D6DFA464B9,500F8D5E-1C31-4465-23E7-08D6DFA464B9";
export const propertyCardStatusProspect =
  process.env.REACT_APP_RC_UI_Property_Card_Status_Prospect ||
  "a2e860ae-d660-4969-23e6-08d6dfa464b9";
export const propertyCardStatusPortfolio =
  process.env.REACT_APP_RC_UI_Property_Card_Status_Portfolio ||
  "500f8d5e-1c31-4465-23e7-08d6dfa464b9";
export const taxTypeGroupId =
  process.env.REACT_APP_RC_UI_Tax_Type_Group_Id ||
  "4308477F-8896-44F8-A866-8C916E33DE55";
export const entityTypeGroupId =
  process.env.REACT_APP_RC_UI_Entity_Type_Group_Id ||
  "D7816D55-B18E-4C5E-A76E-E8F6CEA5BE77";
export const ownerTypeGroupId =
  process.env.REACT_APP_RC_UI_OWNER_Type_Group_Id ||
  "67B7A1B1-4D45-4194-A1F2-C8DA2F479054";
export const propertyDefaultStatus =
  process.env.REACT_APP_RC_UI_Property_Default_Status ||
  "a2e860ae-d660-4969-23e6-08d6dfa464b9";
export const PropertyResidentialTypeGroupId =
  process.env.REACT_APP_RC_UI_Residential_Type_Group_Id ||
  "EF7305A7-6D3F-4917-810F-4AD1E8DAD0B6";
export const PropertyCommercialTypeGroupId =
  process.env.REACT_APP_RC_UI_Commercial_Type_Group_Id ||
  "13E51429-FCE3-43BB-9976-5717A642FBE6";
export const PropertySizeTypeGroupId =
  process.env.REACT_APP_RC_UI_Size_Type_Group_Id ||
  "17041329-3FB2-4C64-8B5C-5E8C5014B80D";
export const FlatTypeGroupId =
  process.env.REACT_APP_RC_UI_Flat_Type_Group_Id ||
  "4B3DA86E-4C15-4228-8DC8-B398BF171FFD";
export const PropertyTypeGroupId =
  process.env.REACT_APP_RC_UI_Property_Type_Group_Id ||
  "A7086A99-1A3B-4EA0-88DE-4E341A01BF70";
export const PropertyAgeGroupId =
  process.env.REACT_APP_RC_UI_Propert_Age_Group_Id ||
  "E9E20139-B644-4199-9654-168AC705F7FF";
export const FurnishedTypeGroupId =
  process.env.REACT_APP_RC_UI_Furnished_Group_Id ||
  "1C5D8AB0-081B-4D85-88B3-23A36E467DC3";
export const ResidentialPropertyEntityId =
  process.env.REACT_APP_RC_UI_Residential_Property_Entity_Id ||
  "b6722151-3c50-4307-97a6-c098d2ac231b";
export const CommercialPropertyEntityId =
  process.env.REACT_APP_RC_UI_Commercial_Property_Entity_Id ||
  "EE36409F-FF69-487D-9D5F-15025A1E8A3D";
export const FlatPropertyEntityId =
  process.env.REACT_APP_RC_UI_Flat_Property_Entity_Id ||
  "AAC4B808-05B2-438D-82EF-EC9ADD9D09E9";
export const ParkingArragementsEntityId =
  process.env.REACT_APP_RC_UI_Parking_Arrangements_Id ||
  "6CD8691F-0C9E-4812-8FE0-4B868AB3DA7B";
export const RoofMaterialEntityId =
  process.env.REACT_APP_RC_UI_ROOF_MATERIAL_Id ||
  "34CA2C07-D6F1-4289-9F95-2EE7B92D344F";
export const WallsConstructionId =
  process.env.REACT_APP_RC_UI_WALLS_CONSTRUCTION_Id ||
  "7C756238-2801-4782-AFEB-652886639F90";
export const TenureId =
  process.env.REACT_APP_RC_UI_TENURE_ID ||
  "781957EC-519F-442D-99FA-53719EFAABF3";
export const LeaseholdId =
  process.env.REACT_APP_RC_UI_LEASEHOLD_ID ||
  "E55705DA-E4F4-43B9-A3EE-0BF0D63E3203";
export const runningEnvironnment = process.env.REACT_APP_ENVIRONMENT || 'local';
//export const startRollbar = runningEnvironnment == 'production';
export const startRollbar = true;
export const elevioHelpLinkUrl = "https://rent-chief.elevio.help/en";
export const toastDisplayTimeInMs = 7000;