import React, { useRef } from "react";
import useStaticHtml from '../_hooks/useStaticHtml.hook';
import useStaticHtmlString from "../_hooks/useStaticHtmlString.hook";
import { IEmptyStateConfig } from "../_config/config";
import { taskEmptyStateHtml } from "./TaskEmptyStateHtml";

interface IProps {
    onAddTask?(): void;
}

const TaskEmptyState = ({onAddTask}: IProps) => {
    const config = useRef<IEmptyStateConfig>(require("../_config/config.json"));

    const onAddTaskInternal = (): void => {
        if (onAddTask) {
            onAddTask()
        }
    }

    const emptyStateHtml = useStaticHtml(config.current.task.htmlName, 
                                        config.current.task.buttonId, 
                                        config.current.task.buttonText, 
                                        onAddTaskInternal);

    const emptyStateHtml1 = useStaticHtmlString(taskEmptyStateHtml, 
                                                config.current.task.buttonId, 
                                                config.current.task.buttonText, 
                                                onAddTaskInternal);

    return (
        emptyStateHtml
    )

}

export default TaskEmptyState;