import React, { Component } from "react";

// Import Styles
import styles from "./NotificationPage.module.scss";
import withErrorShield from "../../../atoms/withErrorShield";

interface IState {
  
}

class NotificationPage extends Component<{}, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
    };

   }

  render() {
    return (
      <div className={styles.generalPage}>
        Notification Page
      </div>
    );
  }
}

export default withErrorShield(NotificationPage);