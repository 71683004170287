import styled from 'styled-components';
import { minWidthTablet } from '../../../global/variables/_variables';

/* export const ContainerDiv = styled.div`
    padding-left: 20px;
    padding-right: 20px;
`; */

export const ContainerDiv = styled.div`
    //padding-left: 20px;
    //padding-right: 20px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 49.5% 49.5%; 
    justify-content: center;
    grid-template-areas:
        'summary summary'
        'landlordInsurancePolicyDate propertyInspectionDate'
        'mortgageRateExpiryDate gasSafetyCertificateDate'
        'electricalSafetyCheckDate energyPerformanceCertificateDate'
        'hmoLicenceDate selectiveLicenseDate'
        'regulationChecks regulationChecks'
        'saveCancel saveCancel';

    @media (max-width: ${minWidthTablet}) {            
        display: initial;
    }
`;

export const SectionDiv = styled.div<{ gridArea?: string }>`
    grid-area: ${p => p.gridArea};
    background-color: #EFE4E4;
`;

export const SummaryDiv = styled.div`
    grid-area: summary;
    //padding-top: 20px;
    //padding-bottom: 20px;
    text-align: left;
`;

export const SectionSpacerDiv = styled.div`
    @media (max-width: ${minWidthTablet}) {            
        height: 20px;
    }
`;

export const SaveCancelDiv = styled.div`
    grid-area: saveCancel;
`;
