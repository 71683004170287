interface IToastState {
  toast_text: string;
  toast_type: string;
  toast_duration: number;
}

const initialState = {
  toast_text: "",
  toast_type: "",
  toast_duration: 1000,
};

export default (
  state: IToastState = initialState,
  action: any
): IToastState => {
  switch (action.type) {
    case "UPDATE_TOAST":
      return {
        ...state,
        toast_text: action.toast_text,
        toast_type: action.toast_type,
        toast_duration: action.toast_duration,
      };

    default:
      return state;
  }
};
