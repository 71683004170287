import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import styles from "./PropertyChatsPage.module.scss";
import ChatListPage from "../../../ChatListPage";

interface IProps {
  history: any;
  match: any;
  location: any;
}

interface IState {

}

class PropertyChatsPage extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <div className={styles.page}>
        <ChatListPage options={{ propertyId: this.props.match.params.propertyId }} />
      </div>
    )
  }
}

export default withRouter(PropertyChatsPage) as any;