import styled from 'styled-components';

export const CardDiv = styled.div<{ backgroundColor?: string }>`
    line-height: 1;
    background-color: ${p => p.backgroundColor ? p.backgroundColor : 'green'};
    padding: 2px;
    border-radius: 5px;
    &:hover {
        background-color: grey;
        cursor: pointer;
      }
    height: 95px;
`; 

export const NumberLabel = styled.label<{ fontSize?: string, color?: string }>`
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    font-size: ${p => p.fontSize ? p.fontSize : '30px'};
    color: ${p => p.color ? p.color : 'white'};
    &:hover {
        //background-color: #F5F5F5;
        cursor: pointer;
      }
`; 

export const NumberLabel2 = styled.label<{ fontSize?: string, color?: string }>`
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    font-size: ${p => p.fontSize ? p.fontSize : '14px'};
    color: ${p => p.color ? p.color : 'white'};
    &:hover {
        //background-color: #F5F5F5;
        cursor: pointer;
      }
`; 

export const NameLabel = styled.label<{ fontSize?: string, color?: string }>`
    display: block;
    width: 100%;
    text-align: center;
    font-size: font-size: ${p => p.fontSize ? p.fontSize : '14px'};
    color: ${p => p.color ? p.color : 'white'};
    &:hover {
        //background-color: #F5F5F5;
        cursor: pointer;
      }
`;