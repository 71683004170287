import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers/rootReducer';
import DatePicker from 'react-datepicker'
import Modal from 'react-responsive-modal'
import { Formik, Form, ErrorMessage } from 'formik'
import SearchPropertyInput from '../../../atoms/SearchPropertyInput'
import SelectDropdown, { IOptions } from '../../../atoms/SelectDropdown'
import Buttons from '../../../atoms/Buttons'
import ImageGalleryComponent from '../../../atoms/ImageGallery'
import SFUploaderModal from '../../../atoms/SFUploaderModal'
import SFUploader from '../../../atoms/SFUploader'
import TextareaAutosize from 'react-textarea-autosize'

import styles from './TaskDetailsPage.module.scss'
import { goToRoute } from '../../../../history'
import TaskAddEditSchema from './schema'
import CollapsibleContent from '../../../atoms/CollapsibleContent'
//import { doesPropertyHaveUnits } from '../../../../services/UnitService'
import SearchUnitInput from '../../../atoms/SearchUnitInput'
import RotatingLoader from '../../../atoms/RotatingLoader'
import moment from 'moment-timezone'
import { correctDate } from '../../../../helpers/dateHelpers'
import { mapToDropdown } from '../../../../helpers/dropdownHelpers'
import { processToast, updateToastMessage } from '../../../../helpers/toastHelper'
import { imageService } from '../../../../configuration/domains'
import { ImageTypeEnum, IUploadForm, IUploadRequest } from '../../../../types/ImageService'
import { changePrimaryImage } from '../../../../services/ImageService'
import { FileInfo } from '@syncfusion/ej2-react-inputs'
import AssociatedDocuments from '../../../atoms/AssociatedDocuments'
import StickyComponent from '../../../atoms/StickyComponent'
import { DateStatusPill } from '../../../atoms/StatusPill/StatusPill.component';
import { ITaskType } from '../_types'
import { ITaskDto } from '../../../../types/Tasks'
import { deleteImage, getImages, uploadImagesApi } from '../../../../services/ImageService';
import { useAddTaskMutation, useUpdateTaskMutation, useFetchTaskTypesQuery, useFetchTaskPrioritiesQuery, useFetchTaskStatusesQuery, useFetchTaskQuery } from '../../../../servicesRtk/TasksServiceRtk'
import { useLazyFetchImagesQuery, useFetchImagesQuery, useDeleteImageMutation } from '../../../../servicesRtk/ImageServiceRtk'
import { useLazyDoesPropertyHaveUnitsQuery } from '../../../../servicesRtk/UnitServiceRtk'
import { getAllTypesAsFlatStructure, getToDefaultOtherChild, getParentType } from '../_helpers'
import { current } from '@reduxjs/toolkit'
import SearchContactInput from '../../../atoms/SearchContactInput';
import { trustedTeamUser } from '../../../../helpers/permissionHelpers';
import { IAddTenantCallback } from '../../../molecules/AddContactForm';
import { Tooltip } from 'react-tippy';
import infoIcon from '../../../../images/icons/ic_info_24px.png'

const queryString = require('query-string')

let buttons
let observer: IntersectionObserver

const TaskDetailsPage = () => {
    let history = useHistory();
    const location = useLocation();

    // Redux 
    const { propertyId, propertyName } = useSelector((state: RootState) => state.property.property_data.property_data);

    const [buttonsInView, setButtonsInView] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [hasNameBeenEdited, setHasNameBeenEdited] = useState(false);
    const [uploadingImages, setUploadingImages] = useState(false);
    const [outstandingChangesModalOpen, setOutstandingChangesModalOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [taskTypes, setTaskTypes] = useState<ITaskType[]>(null);
    const [taskSubtypes, setTaskSubtypes] = useState<ITaskType[]>(null);
    const [allTaskTypes, setAllTaskTypes] = useState<ITaskType[]>(null);
    const [currentTask, setCurrentTask] = useState<ITaskDto>(null);
    const [selectedPropertyIdHasUnits, setSelectedPropertyIdHasUnits] = useState<boolean>(false);
    const [fetchImagesRequired, setFetchImagesRequired] = useState<boolean>(false);
    const [selectedContactId, setSelectedContactId] = useState<string>(null);
    const [selectedStatusId, setSelectedStatusId] = useState<string>(null);
    const [selectedPriorityId, setSelectedPriorityId] = useState<string>(null);
    const [selectedTaskTypeLevel1Id, SetSelectedTaskTypeLevel1Id] = useState<string>(null);
    const [selectedTaskTypeLevel2Id, SetSelectedTaskTypeLevel2Id] = useState<string>(null);
    const [selectedTaskName, SetSelectedTaskName] = useState<string>(null);
    const deletedImageId = useRef(null);

    const curPropertyId = useRef(propertyId);
    const curPropertyName = useRef(propertyName);

    const [taskId, setTaskId] = useState(null);
    const [statusComplete, setStatusComplete] = useState(false);

    const statusDivRef = useRef<HTMLDivElement>();

    // RTK Query- Tasks
    const [addTask, {data: addTaskResponseData, isLoading: adding}] = useAddTaskMutation();
    const [updateTask, {data: updateTaskResponseData, isLoading: updating}] = useUpdateTaskMutation();
    const { data: taskTypesData, isError: taskTypesIsError } = useFetchTaskTypesQuery(null);
    const { data: taskPriorities } = useFetchTaskPrioritiesQuery(null);
    const { data: taskStatuses } = useFetchTaskStatusesQuery(null);
    const { data: taskData } = useFetchTaskQuery(taskId, {skip: taskId && taskId != '' ? false : true});
    // RTK Query- Image
    const [fetchTaskImages, { data: imagesData }] = useLazyFetchImagesQuery();
    const [deleteImage, { data: imageDeleteData }] = useDeleteImageMutation();
    // RTK Query- Unit
    const [fetchDoesPropertyHaveUnits] = useLazyDoesPropertyHaveUnitsQuery();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        var curTaskId = queryParams.get('taskId');
        if(curTaskId){
            setTaskId(curTaskId);
        }
        var complete = queryParams.get('complete');
        if(complete){
            setStatusComplete(true);
            if (statusDivRef.current) {
                window.scrollTo({ behavior: 'smooth', top: statusDivRef.current.offsetTop })
            }
        }
        //queryParams.delete('taskId');
        //history.replace({ search: queryParams.toString() });
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        var addContactCallBack = queryParams.get('addContactCallback');
        if(addContactCallBack){
            let data: IAddTenantCallback = JSON.parse(addContactCallBack);
            setSelectedContactId(data.contactId);

            queryParams.delete('addContactCallback');
            history.replace({ search: queryParams.toString() });
        }
    }, [location.search]);

    useEffect(() => {
        buttons = React.createRef()

        const callback = (entries: any[]) => {
            let isIntersecting = entries.filter((x) => x.isIntersecting)
            if (isIntersecting.length) {
                setButtonsInView(true);
            } else {
                setButtonsInView(false);
            }
        }

        observer = new IntersectionObserver(callback)

        // Edit mode
        if(taskData && taskData.data){
            doesPropertyHaveUnitsUI(taskData.data.propertyId);
        }else{ // add mode
            if(!curPropertyId.current){
                const queryParams = new URLSearchParams(location.search);
                curPropertyId.current = queryParams.get('propertyId');
                if(curPropertyId.current){
                    doesPropertyHaveUnitsUI(curPropertyId.current);
                }
                curPropertyName.current = queryParams.get('propertyName');
                queryParams.delete('propertyId');
                queryParams.delete('propertyName');
                history.replace({ search: queryParams.toString() });
            }
        }

        // from componentDidMount
        //observer.observe(buttons.current)
    }, []);

    useEffect(() => {
        if(addTaskResponseData){
            addImagesToTask(addTaskResponseData.data);
            history.goBack();
        }
        else if(updateTaskResponseData && updateTaskResponseData.isSuccess){
            addImagesToTask(currentTask.taskId);
        }
    }, [addTaskResponseData, updateTaskResponseData]);

    useEffect(() => {
        if(!taskTypesIsError && taskTypesData && !taskTypes){
            let allTaskTypes = getAllTypesAsFlatStructure(taskTypesData);
            setTaskTypes(taskTypesData);
            setTaskSubtypes(allTaskTypes);
            setAllTaskTypes(allTaskTypes);
        }
    }, [taskTypesData]);

    useEffect(() => {
        if(taskData && taskData.isSuccess && !currentTask){
            setCurrentTask(taskData.data);
            setSelectedContactId(taskData.data.contactId);
            if (taskData.data.propertyId && taskData.data.unitId) {
                setSelectedPropertyIdHasUnits(true);
            }else if (taskData.data.propertyId) {
                doesPropertyHaveUnitsUI(taskData.data.propertyId);
            }
    
            // We do this when a link was clicked say on a email to auto complete the task,
            // however if the task is already in complete status then navigate to the regulation screen
            if(statusComplete){
                if(taskData.data.taskStatusId == '5'){
                    if(taskData.data.propertyId){
                        let url = `/dashboard/property/${taskData.data.propertyId}/spec/regulation`;
                        let qs = `?highlightedDate=${taskData.data.systemType}`
        
                        history.push(url + qs);
                    }
                }
                else{
                    updateToastMessage("The status as been updated to completed, just click save to confirm this.", "success");
                }
            }
        }
    }, [taskData]);

    const addImagesToTask = async(newTaskId) => {
        let filesRequest: IUploadForm = null
        if (images.length) {
            // If there are images create the form data
            var formData = new FormData()
            images.forEach((image) =>
                formData.append('File', image.rawFile)
            )
            filesRequest = {
                images: formData,
                options: {
                    imageType: ImageTypeEnum.TASK_IMAGE.toString(),
                },
            }
        }
        // If there are files passed, now upload these
        if (filesRequest) {
            const uploadResp = await uploadImagesApi(filesRequest.images, { ...filesRequest.options, parentId: newTaskId });
            if (uploadResp.data) {
                processToast(uploadResp.data);
            }
        }
    }

    const onAddDocument = (documentIds: string | string[], type: string) => {
        let newArr = []

        if (Array.isArray(documentIds)) {
            newArr = documentIds.map((x) => ({ documentId: x, type }))
        } else {
            newArr = [{ documentId: documentIds, type }]
        }

        setDocuments(documents.concat(newArr));
    }

    const onChangePrimaryImage = async(imageId: string) => {
        const { taskId } = currentTask

        if (!taskId) {
            return
        }

        let resp = await changePrimaryImage({
                                        imageId,
                                        parentId: taskId,
                                        imageType: ImageTypeEnum.TASK_IMAGE,
                                    });

        if(resp && resp.status == 200){
            processToast(resp.data)
            //dispatch(getAssetImages(assetId));
            const { data } = await getImages({ parentId: taskId, imageType: [ImageTypeEnum.TASK_IMAGE] })
            if(data){
                setCurrentTask({
                    ...currentTask,
                    images: data
                })
            }
        }
    }

    const deleteTaskImage = async(imageId: string) =>{
        deletedImageId.current = imageId;
        deleteImage(imageId);
    }

    if (imageDeleteData && deletedImageId.current != null) {
        processToast(imageDeleteData);
        setCurrentTask({
            ...currentTask,
            images: currentTask.images.filter(image => image.imageId != deletedImageId.current)
        })
        deletedImageId.current = null;
    }

    if(imagesData && fetchImagesRequired){
        setCurrentTask({
            ...currentTask,
            images: imagesData
        })
        setFetchImagesRequired(false);
    }

    const generateName = (taskType: string, taskName: string) => {
        if (hasNameBeenEdited && taskName.length > 0) {
            return
        }

        if (taskType) {
            return taskType.trim()
        }

        return null
    }

    const getInitialState = () => ({
        taskName: selectedTaskName ? selectedTaskName : null,
        taskTypeLevel1Id: selectedTaskTypeLevel1Id ? selectedTaskTypeLevel1Id : null,
        taskTypeLevel2Id: selectedTaskTypeLevel2Id ? selectedTaskTypeLevel2Id : null,
        dueDate: new Date().toDateString(),
        startDate: new Date().toDateString(),
        taskStatusName: "New",
        taskStatusId: selectedStatusId ? selectedStatusId : "1",
        taskPriorityName: "3-Medium",
        taskPriorityId: selectedPriorityId ? selectedPriorityId : "3",
        propertyId: curPropertyId.current,
        propertyName: curPropertyName.current,
        contactId: selectedContactId 
    })

    const doesPropertyHaveUnitsUI = async(propertyId: string) => {
        if(propertyId){
            let hasUnits = await fetchDoesPropertyHaveUnits(propertyId).unwrap()
            setSelectedPropertyIdHasUnits(hasUnits);
        }
    }

    const checkForChanges = () => {
        setHasChanges(true);
    }

    const populateSubtypes = (selectedId: string) => {
        checkForChanges()
        let index = selectedId ? parseInt(selectedId) : 1;
        setTaskSubtypes(taskTypes[index-1].subtypes);
    }

    const getDropdownDataInCorrectFormat = (taskTypes: ITaskType[]): IOptions[] => {
        return mapToDropdown(taskTypes, 'taskTypeId', 'taskTypeName')
    }

    const onCancel = () => {
        hasChanges ? setOutstandingChangesModalOpen(true) : history.goBack();;
    }

    const onCloseChangesModal = (submitCancel: boolean): void => {
        setOutstandingChangesModalOpen(false);

        if (submitCancel) {
            //goToRoute(getNavigatePath());
            history.goBack();
        } 
    }

    const onSubmit = (task: ITaskDto): void => {
        // need to correct any date fields
        task.dueDate = correctDate(task.dueDate);
        task.reminderDateTime = correctDate(task.reminderDateTime);
        task.startDate = correctDate(task.startDate);

        if (currentTask) {
            updateTask(task);
            return;
        } else {
            task.taskId = 0;

            if (documents.length) {
                task.documents = documents.map((x) => x.documentId)
            }

            addTask(task);
            return;
        }
    }

    if (adding || updating) {
        return (
            <RotatingLoader
                loading={true}
                text={`${currentTask ? 'Updating task...' : 'Adding task...'}`}
            />
        )
    }

    const getParentLinkButton = (systemType: number) => {
        let buttonText: string = "";
        let navPath: string = `/dashboard/property/${currentTask.propertyId}`;
        if(systemType >= 1 && systemType <= 19){
            buttonText = 'Regulation';
            let url = `/dashboard/property/${currentTask.propertyId}/spec/regulation`;
            let qs = `?highlightedDate=${currentTask.systemType}`
            navPath = url + qs;
        }else if(systemType >= 20 && systemType <= 39){
            buttonText = 'Tenancy';
            let url = `/dashboard/tenancy/view/${currentTask.tenancyId}/details`;
            //let qs = `?highlightedDate=${currentTask.systemType}`
            navPath = url;
        }

        return(
            <Buttons
                align='left'
                buttons={[
                    {
                        text: buttonText,
                        displayType: 'cancel',
                        elementType: 'button',
                        onClick: () => history.push(navPath),
                    }
                ]}
            />
        )
    }

    return (
        <div className={styles.form}>
            <Modal
                open={outstandingChangesModalOpen}
                onClose={() => onCloseChangesModal(false)}
                center
            >
                <p className={styles.modalHeader}>Outstanding changes</p>
                <p className={styles.modalBodyOutstandingChanges}>
                    Any updated information will be lost, do you wish to
                    continue?
                </p>

                <Buttons
                    buttons={[
                        {
                            text: 'No',
                            displayType: 'cancel',
                            elementType: 'button',
                            onClick: () => onCloseChangesModal(false),
                        },
                        {
                            text: 'Yes',
                            displayType: 'submit',
                            elementType: 'button',
                            onClick: () => onCloseChangesModal(true),
                        },
                    ]}
                />
            </Modal>

            <div className={styles.content}>
                <Formik
                    initialValues={
                        currentTask
                            ? {
                                createdByUserId: currentTask.createdByUserId,
                                teamId: currentTask.teamId,
                                contactId: selectedContactId,
                                userId: currentTask.userId,
                                unitId: currentTask.unitId,
                                unitName: currentTask.unitName,
                                propertyId: currentTask.propertyId,
                                propertyName: currentTask.propertyName,
                                taskName: currentTask.taskName,
                                taskSubTitle: currentTask.taskSubTitle,
                                taskTypeLevel2Id: currentTask.taskTypeLevel2Id,
                                taskTypeLevel2Name: currentTask.taskTypeLevel2Name,
                                taskTypeLevel1Id: currentTask.taskTypeLevel1Id,
                                taskTypeLevel1Name: currentTask.taskTypeLevel1Name,
                                taskPriorityId: currentTask.taskPriorityId,
                                taskPriorityName: currentTask.taskPriorityName,
                                taskStatusId: statusComplete ? '5' : currentTask.taskStatusId,
                                taskStatusName: currentTask.taskStatusName,
                                description: currentTask.description,
                                startDate: currentTask.startDate,
                                dueDate: currentTask.dueDate,
                                reminderDateTime: currentTask.reminderDateTime,
                                systemGenerated: currentTask.systemGenerated,
                                systemType: currentTask.systemType,
                                }
                            : getInitialState()
                    }
                    enableReinitialize={true}
                    validationSchema={TaskAddEditSchema}
                    onSubmit={(values: any) => {
                        onSubmit({
                                taskId: currentTask ? currentTask.taskId : null,
                                createdByUserId: currentTask ? currentTask.createdByUserId : null,
                                teamId: currentTask ? currentTask.teamId : null,
                                contactId: values.contactId,
                                userId: currentTask ? currentTask.userId : null,
                                unitId: values.unitId,
                                propertyId: values.propertyId,
                                propertyName: values.propertyName,
                                taskName: values.taskName,
                                taskSubTitle: values.taskSubTitle,
                                taskTypeLevel2Id: values.taskTypeLevel2Id,
                                taskTypeLevel2Name: values.taskTypeLevel2Name,
                                taskTypeLevel1Id: values.taskTypeLevel1Id,
                                taskTypeLevel1Name: values.taskTypeLevel1Name,
                                taskPriorityId: values.taskPriorityId,
                                taskPriorityName: values.taskPriorityName,
                                taskStatusId: values.taskStatusId,
                                taskStatusName: values.taskStatusName,
                                description: values.description,
                                startDate: values.startDate != null && values.startDate != "" ? new Date(values.startDate) : null,
                                dueDate: values.dueDate != null && values.dueDate != "" ? new Date(values.dueDate) : null,
                                reminderDateTime: values.reminderDateTime != null && values.reminderDateTime != "" ? new Date(values.reminderDateTime) : null,
                                systemGenerated: currentTask ? currentTask.systemGenerated : false,
                                systemType: currentTask ? currentTask.systemType: 0,
                            }
                        )
                    }}
                >
                    {(props) => (
                        <Form>
                            {/* Row 1 */}
                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <div className={styles.label}>Title</div>
                                    <TextareaAutosize
                                        maxLength={255}
                                        value={props.values && props.values.taskName}
                                        onChange={(e) => {
                                            props.setFieldValue('taskName',e.currentTarget.value)
                                            setHasNameBeenEdited(true);
                                            SetSelectedTaskName(e.currentTarget.value);
                                        }}
                                        autoFocus={true}
                                    />
                                    <div className={styles.errorMessage}>
                                        <ErrorMessage name="taskName" />
                                    </div>
                                </div>
                            </div>
                            {/* Row 2 */}
                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <div className={styles.label}>Due Date</div>
                                        <div className={styles.dateContainer}>
                                        <div className={styles.datePickerWrapper}>
                                            <DatePicker className={styles.input}
                                                todayButton="Today"
                                                dateFormat="dd/MM/yyyy"
                                                selected={props.values && props.values.dueDate &&
                                                    moment(props.values.dueDate).toDate()
                                                }
                                                onChange={(date: Date) => {
                                                    if(!date && (currentTask.systemType >= 20 && currentTask.systemType <= 39))
                                                        return;
                                                    props.setFieldValue('dueDate',date)
                                                    checkForChanges()
                                                }}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                            />
                                            </div>
                                            <div className={styles.dateStatus}>
                                                <DateStatusPill date={props.values && props.values.dueDate && moment(props.values.dueDate).toDate()} />
                                            </div>
                                     </div>
                                </div>
                                
                                
                                <div className={styles.column}>
                                    <div className={styles.label}>Reminder Date</div>
                                    <div className={styles.dateContainer}>
                                        <div className={styles.datePickerWrapper}>
                                            <DatePicker className={styles.input}
                                                todayButton="Today"
                                                dateFormat="dd/MM/yyyy"
                                                selected={props.values && props.values.reminderDateTime &&
                                                    moment(props.values.reminderDateTime).toDate()
                                                }
                                                onChange={(date: Date) => {
                                                    props.setFieldValue('reminderDateTime',date)
                                                    checkForChanges()
                                                }}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                            />
                                        </div>
                                        
                                        <div className={styles.dateStatus}>
                                            <DateStatusPill date={props.values && props.values.reminderDateTime && moment(props.values.reminderDateTime).toDate()} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Row 4 */}
                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <div className={styles.label}>Type</div>
                                    <SelectDropdown
                                        selectedId={
                                            props.values && props.values.taskTypeLevel2Id
                                                ? props.values.taskTypeLevel2Id.toString()
                                                : null
                                        }
                                        data={
                                            taskTypes
                                                ? getDropdownDataInCorrectFormat(taskSubtypes)
                                                : []
                                        }
                                        placeholder="Select..."
                                        onSelect={(id: IOptions) => {
                                            props.setFieldValue('taskTypeLevel2Id',id.value)
                                            props.setFieldValue('taskTypeLevel2Name',id.label)
                                            let parentType = getParentType(id.value, taskTypes, allTaskTypes)
                                            if (parentType) {
                                                props.setFieldValue('taskTypeLevel1Id',parentType.value)
                                                props.setFieldValue('taskTypeLevel1Name',parentType.label)
                                                SetSelectedTaskTypeLevel1Id(parentType.value);
                                            }

                                            if(props.values && !props.values.taskName || (props.values && props.values.taskName && props.values.taskName.length == 0)){
                                                props.setFieldValue('taskName',generateName(id.label, props.values.taskName))
                                                SetSelectedTaskName(generateName(id.label, props.values.taskName));
                                            }
                                            checkForChanges()
                                            SetSelectedTaskTypeLevel2Id(id.value);
                                        }}
                                        isSearchable={true}
                                    />
                                </div>
                                <div className={styles.column}>
                                    <div className={styles.label}>Category</div>
                                    <SelectDropdown
                                        showAllOption
                                        selectedId={
                                            props.values && props.values.taskTypeLevel1Id
                                                ? props.values.taskTypeLevel1Id.toString()
                                                : null
                                        }
                                        data={getDropdownDataInCorrectFormat(taskTypes)}
                                        placeholder="Select..."
                                        onSelect={(id: IOptions) => {
                                            props.setFieldValue('taskTypeLevel1Id',id.value)
                                            props.setFieldValue('taskTypeLevel1Name',id.label)
                                            // Set default to other
                                            let childOther = null
                                            if (id.value != null) {
                                                childOther = getToDefaultOtherChild(id.value, taskTypes)
                                                props.setFieldValue('taskTypeLevel2Id',childOther.value)
                                                props.setFieldValue('taskTypeLevel2Name',childOther.label)
                                            } else {
                                                // When 'All' is selected
                                                props.setFieldValue('taskTypeLevel2Id',null)
                                                props.setFieldValue('taskTypeLevel2Name',null)
                                            }
                                            populateSubtypes(id.value)
                                            SetSelectedTaskTypeLevel1Id(id.value);
                                        }}
                                    />
                                </div>
                            </div>
                            {/* Row 5 */}
                            <div ref={statusDivRef} className={styles.row}>
                                <div className={styles.column}>
                                    <div className={styles.label}>Status</div>
                                    <SelectDropdown
                                        selectedId={
                                            props.values && props.values.taskStatusId
                                                ? props.values.taskStatusId.toString()
                                                : null
                                        }
                                        data={taskStatuses ? mapToDropdown(taskStatuses, 'typeId', 'typeName'):[]}
                                        placeholder="Select..."
                                        onSelect={(id: IOptions) => {
                                            props.setFieldValue('taskStatusId',id.value)
                                            props.setFieldValue('taskStatusName',id.label)
                                            checkForChanges()
                                            setSelectedStatusId(id.value);
                                        }}
                                    />
                                </div>
                                <div className={styles.column}>
                                    <div className={styles.label}>Priority</div>
                                    <SelectDropdown
                                        selectedId={
                                            props.values && props.values.taskPriorityId
                                                ? props.values.taskPriorityId.toString()
                                                : null
                                        }
                                        data={taskPriorities ? mapToDropdown(taskPriorities, 'typeId', 'typeName'):[]}
                                        placeholder="Select..."
                                        onSelect={(id: IOptions) => {
                                            props.setFieldValue('taskPriorityId',id.value)
                                            props.setFieldValue('taskPriorityName',id.label)
                                            setSelectedPriorityId(id.value)
                                        }}
                                    />
                                </div>
                            </div>    
                            {/* Row 6 */}
                            {
                                trustedTeamUser() 
                                ?
                                <div ref={statusDivRef} className={styles.row}>
                                    <div className={styles.column}>
                                        <div className={styles.label}>Assigned Contact
                                            <Tooltip
                                                theme="light"
                                                html={
                                                <div className={styles.tooltipContent}>
                                                    Assign someone to action task. An email is sent to the contact with details of the task. If they’ve been invited as a user they will be able to track allocated tasks and update the task with further details and to mark complete.
                                                </div>
                                                }
                                                position="right"
                                                trigger="mouseenter"
                                                arrow
                                                interactive={true}
                                            >
                                                <img
                                                className={styles.infoIcon}
                                                src={infoIcon}
                                                alt="Info"
                                                />
                                            </Tooltip>
                                        </div>
                                        <SearchContactInput
                                            onAdd={() => null}
                                            allowNewContact
                                            newContactType="Contact"
    /*                                         contactType={[
                                                '921CCFFF-7386-400D-804C-B638121A75D2',
                                            ]} */
                                            onClear={() => {
                                                props.setFieldValue('contactId', null)
                                                /* props.setFieldValue(
                                                    'tenantId',
                                                    null
                                                ) */
                                                checkForChanges()
                                            }}
                                            selectedId={props.values.contactId}
                                            //selectedName={props.values.contactName}
                                            onSelect={(option) => {
                                                //props.setFieldValue('contactName', option.text)
                                                props.setFieldValue('contactId', option.value)
                                                checkForChanges()
                                            }}
                                        />
                                    </div>
                                    <div className={styles.column}></div>
                                </div> 
                                :
                            "" 
                            }      
                            {/* More details */}
                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <CollapsibleContent title="More" defaultStateOpen={false}>
                                        <React.Fragment>
                                        <div className={styles.row}>
                                                <div className={styles.column}>
                                                    <div className={styles.label}>Property</div>
                                                    <SearchPropertyInput
                                                        selectedPropertyId={props.values && props.values.propertyId}
                                                        selectedPropertyName={props.values && props.values.propertyName}
                                                        onSelect={(selected: IOptions) => {
                                                            props.setFieldValue('propertyId',selected.value)
                                                            props.setFieldValue('propertyName',selected.label)
                                                            doesPropertyHaveUnitsUI(selected.value)
                                                        }}
                                                        onClear={() => {
                                                            props.setFieldValue('propertyId',null)
                                                            props.setFieldValue('propertyName',null)
                                                            props.setFieldValue('unitId',null)
                                                            props.setFieldValue('unitName',null)
                                                            setSelectedPropertyIdHasUnits(false);
                                                        }}
                                                        /* disableClear={
                                                            this.props.assetOptions &&
                                                            this.props.assetOptions
                                                                .propertyId
                                                                ? true
                                                                : false
                                                        } */
                                                        disableClear={false}
                                                    />
                                                </div>
                                                <div className={styles.column}>
                                                    <div className={styles.label}>Unit</div>
                                                    {selectedPropertyIdHasUnits ? (
                                                        <SearchUnitInput
                                                            propertyId={props.values && props.values.propertyId}
                                                            onClear={() => {
                                                                props.setFieldValue('unitId',null)
                                                                props.setFieldValue('unitName',null)
                                                            }}
                                                            /* disableClear={
                                                                this.props.assetOptions &&
                                                                this.props.assetOptions
                                                                    .unitId
                                                                    ? true
                                                                    : false
                                                            } */
                                                            disableClear={false}
                                                            selectedUnitId={props.values && props.values.unitId}
                                                            selectedUnitName={props.values && props.values.unitName}
                                                            onSelect={(option: IOptions) => {
                                                                props.setFieldValue('unitId',option.value)
                                                                props.setFieldValue('unitName',option.label)
                                                                checkForChanges()
                                                            }}
                                                        />
                                                    ) : props.values && props.values.propertyId ? (
                                                        'Property has no Units'
                                                    ) : (
                                                        'Select property to get Units'
                                                    )}
                                                </div>
                                            </div>
                                            <div className={styles.row}>
                                                <div className={styles.column}>
                                                    <div className={styles.label}>Start Date</div>
                                                    <DatePicker className={styles.input}
                                                        todayButton="Today"
                                                        dateFormat="dd/MM/yyyy"
                                                        selected={props.values && props.values.startDate &&
                                                            moment(props.values.startDate).toDate()}
                                                        onChange={(date: Date) => {
                                                            props.setFieldValue('startDate',date)
                                                            checkForChanges()
                                                        }}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                    />
                                                </div>
                                                <div className={styles.column}></div>
                                            </div>
                                            <div className={styles.row}>
                                                <div className={styles.column}>
                                                    <div className={styles.label}>Notes</div>
                                                    <TextareaAutosize
                                                        maxLength={512}
                                                        value={props.values && props.values.description}
                                                        onChange={(e) =>
                                                            props.setFieldValue('description',e.currentTarget.value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    </CollapsibleContent>
                                </div>
                            </div>
                            {/* buttons */}
                            <div ref={buttons} className={styles.buttons}>
                                <StickyComponent>
                                    <div className={styles.stickyComponentContentWrapper}>
                                    {
                                        currentTask && currentTask.systemType && currentTask.systemType != 0 && trustedTeamUser()
                                        ?
                                            getParentLinkButton(currentTask.systemType)
                                        : 
                                        ""
                                    }
                                    <Buttons
                                        buttons={[
                                            {
                                                text: 'Cancel',
                                                displayType: 'cancel',
                                                elementType: 'button',
                                                onClick: () => onCancel(),
                                            },
                                            {
                                                text: 'Save',
                                                displayType: 'submit',
                                                elementType: 'button',
                                                onClick: () => props.submitForm(),
                                            },
                                        ]}
                                    />
                                    </div>
                                </StickyComponent>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>

            <div className={styles.image}>
                <div className={styles.row}>
                    <div className={styles.column}>
                        {currentTask && currentTask.images && currentTask.images.length > 0 ? (
                            <ImageGalleryComponent
                                onClickFullscreen
                                dropdownActions={{
                                        onClickUpload: () => setUploadingImages(true),
                                        onClickDelete: () => null,
                                        onClickOverride: deleteTaskImage,
                                        onChangePrimaryImage: onChangePrimaryImage,
                                    }}
                                    items={currentTask ? currentTask.images : []
                                }
                            />
                        ) : (
                            <SFUploader
                                hideClose
                                options={{ imageType: ImageTypeEnum.TASK_IMAGE.toString() }}
                                onAdd={(e) => {setImages(images.concat([e]))}}
                                onRemove={(e) => setImages(images.filter((x) => x != e))}
                                callbackOnly={true}
                            />
                        )}
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.column}>

                            <div className={styles.document}>
                                <div className={styles.label}>Add Documents</div>
                                {currentTask &&
                                    currentTask.taskId ? (
                                    <AssociatedDocuments
                                        onAddDocument={(id) =>
                                            onAddDocument(id,'4C624222-5A3F-4503-A115-B8815D2663C6')
                                        }
                                        showUpload
                                        returnDocumentId
                                        showOnlyNoneText
                                        options={{
                                            taskId: currentTask.taskId,
                                            propertyId: currentTask.propertyId,
                                            propertyName: currentTask.propertyName,
                                            unitId: currentTask.unitId,
                                            unitName: currentTask.unitName,
                                            documentType: '4C624222-5A3F-4503-A115-B8815D2663C6',
                                        }}
                                    />
                                ) : (
                                    <AssociatedDocuments
                                        onAddDocument={(id) =>
                                            onAddDocument(id,'4C624222-5A3F-4503-A115-B8815D2663C6')
                                        }
                                        showUpload
                                        showOnlyNoneText
                                        returnDocumentId
                                        options={{
                                            documentsOverride:
                                                documents.filter(
                                                    (d) => d.type == '4C624222-5A3F-4503-A115-B8815D2663C6'
                                                ),
                                            isCreating: true,
                                            documentType: '4C624222-5A3F-4503-A115-B8815D2663C6',
                                        }}
                                    />
                                )}

                        </div>
                    </div>
                </div>
            </div>

            {currentTask && currentTask.taskId && (
                <SFUploaderModal
                    open={uploadingImages}
                    onToggle={(open: boolean) => setUploadingImages(open)
                    }
                    urls={{
                        saveUrl: `${imageService}/Save`,
                        removeUrl: `${imageService}/Remove`,
                    }}
                    onFileUpload={(imageIds?: string[]) =>
                        updateToastMessage('Task image has been uploaded successfully','success')
                    }
                    successCallback={() => {
                        setUploadingImages(false)
                        setFetchImagesRequired(true);
                        fetchTaskImages({ parentId: currentTask.taskId, imageType: [ImageTypeEnum.TASK_IMAGE] });
                    }}
                    options={{
                        imageType: ImageTypeEnum.TASK_IMAGE,
                        parentId: currentTask.taskId,
                    }}
                />
            )}
        </div>
    )
}

export default TaskDetailsPage;