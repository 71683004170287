import {
  markTransactionAsPaid,
  markTransactionsAsPaid,
} from "../../../../../services/TransactionService";
import {
  updateTransactionItem,
  updateTransactionItems,
} from "../../../../../helpers/transactionHelpers";
import { IAddEditTransactionOptions } from "../../../../atoms/AddEditTransactionModal";
import { ITransactionItem } from "../../../../../types/Transaction";
import { addURLParameter } from "../../../../../helpers/locationHelpers";
import {
  toggleTransactionPopup,
  toggleDeleteTransactionPopup,
  toggleDeleteUserPopup,
  toggleDeleteInspectionPopup,
  toggleDeleteDocumentPopup,
} from "../../../../../helpers/sidebarHelpers";
import { updateToastMessage } from "../../../../../helpers/toastHelper";
import { createPasswordResetToken } from "../../../../../services/CredentialsService";
import { toggleUserActiveStatus } from "../../../../../services/UserService";
import { IAddTransactionReferer } from "../../../../atoms/AddEditTransaction";

export function onClickPaidMultiple(transactionIds: string[]): void {
  markTransactionsAsPaid(transactionIds).then((resp) => {
    if (resp && resp.status == 200 && resp.data) {
      updateTransactionItems(resp.data);
      markAsPaidToast(transactionIds.length);
    }
  });
}

export function onClickPaid(transactionId: string): void {
  markTransactionAsPaid(transactionId).then((resp) => {
    if (resp && resp.status == 200 && resp.data) {
      updateTransactionItem(resp.data);
      markAsPaidToast(1);
    }
  });
}

function markAsPaidToast(length: number) {
  let count = `${length} ${length == 1 ? "transaction has" : "transactions have"
    }`;

  updateToastMessage(
    `${count} been marked as paid successfully.`,
    "success",
    4000
  );
}

export function onDeleteRow(
  transaction: ITransactionItem,
  location: any,
  history: any
): void {
  let options = getOnEditOptions(transaction);

  let query = addURLParameter(
    location.search,
    "deleteTransaction=" + JSON.stringify(options.transactionId)
  );

  history.push({
    search: query,
  });

  toggleDeleteTransactionPopup(true);
}

export function toggleUserStatus(userId: string, active: boolean): void {
  toggleUserActiveStatus(userId, active).then(resp => {
    if (resp.status == 200) {
      updateToastMessage(`User has been succesfully ${active ? 'activated' : 'deactivated'}`, "success");
    }
  });
}

export function resetPassword(email: string): void {
  createPasswordResetToken(email).then(resp => {
    if (resp && resp.status == 200 && resp.data != "00000000-0000-0000-0000-000000000000") {
      updateToastMessage("Password reset has been submitted", "success");
      return;
    }

    updateToastMessage("Password reset was not successful", "failure");
  });
}

export function onDeleteRows(
  documentIds: string[],
  location: any,
  history: any
): void {
  let query = addURLParameter(
    location.search,
    "deleteDocument=" + JSON.stringify(documentIds)
  );

  history.push({
    search: query,
  });

  toggleDeleteDocumentPopup(true);
}

export function onEditRow(
  transaction: ITransactionItem,
  location: any,
  history: any
): void {
  let options = getOnEditOptions(transaction);

  let query = addURLParameter(
    location.search,
    "addEditTransactionOptions=" + JSON.stringify(options)
  );

  history.push({
    search: query,
  });

  toggleTransactionPopup(true);
}

export function getOnEditOptions(
  transaction: ITransactionItem
): IAddEditTransactionOptions {
  return {
    referer: IAddTransactionReferer.Transaction,
    transactionId: transaction.transactionId,
    isExpense: transaction.isExpense,
    isIncome: transaction.isIncome,
    isPaid: transaction.isPaid,
  };
}
