import { store } from "../store";
import { ITransactionItem } from "../types/Transaction";

export function updateTransactionsList(data: Array<ITransactionItem>) {
  store.dispatch({
    type: "UPDATE_TRANSACTION_LIST",
    data,
  });
}

export function filterByDateTransactionItem(startDate: Date, endDate: Date, currentList?: Array<ITransactionItem>) {
  let current = currentList
    ? currentList
    : store.getState().transaction.transactions;
  let copy = current.map((object: ITransactionItem) => ({ ...object }));

  if (startDate) {
    copy = copy.filter((x: ITransactionItem) => new Date(x.invoiceDate) >= startDate);
  }

  if (endDate) {
    copy = copy.filter((x: ITransactionItem) => new Date(x.invoiceDate) <= endDate);
  }

  updateTransactionsList(copy);
}

export function deleteTransactionItem(transactionId: string) {
  let current = store.getState().transaction.transactions;
  let copy = current.map((object) => ({ ...object }));
  copy = copy.filter((x) => x.transactionId != transactionId);
  updateTransactionsList(copy);
}

export function updateTransactionItems(data: ITransactionItem[]) {
  data.forEach((item) => {
    updateTransactionItem(item);
  });
}

export function updateTransactionItem(data: ITransactionItem) {
  let current = store.getState().transaction.transactions;
  if (current.length === 0) {
    addToTransactionList(data);
  }

  let index = current.findIndex((t) => t.transactionId == data.transactionId);
  if (index > -1) {
    const copy = current.map((object) => ({ ...object }));
    copy[index] = data;
    updateTransactionsList(copy);
    return;
  }

  addToTransactionList(data);
}

export function addToTransactionList(data: ITransactionItem) {
  let current = store.getState().transaction.transactions;
  let arr = [data].concat(current);

  store.dispatch({
    type: "UPDATE_TRANSACTION_LIST",
    data: arr,
  });
}
