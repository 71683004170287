import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';
import { compose } from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import { tasksApi } from '../servicesRtk/TasksServiceRtk';
import { imageApi } from '../servicesRtk/ImageServiceRtk';
import { unitApi } from "../servicesRtk/UnitServiceRtk";
import { calculatorApi } from '../servicesRtk/CalculatorServiceRtk';
const composeEnhancers = (window as any).window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false})
      .concat(tasksApi.middleware)
      .concat(imageApi.middleware)
      .concat(unitApi.middleware)
      .concat(calculatorApi.middleware),
  } 
  )

/* export const store1 = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
); */

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>