import {
  duplicateTransaction,
  markTransactionAsPaid,
  markTransactionsAsPaid,
} from "../../../../../services/TransactionService";
import {
  updateTransactionItem,
  updateTransactionItems,
} from "../../../../../helpers/transactionHelpers";
import { IAddEditTransactionOptions } from "../../../../atoms/AddEditTransactionModal";
import { ITransactionItem } from "../../../../../types/Transaction";
import { addURLParameter } from "../../../../../helpers/locationHelpers";
import {
  toggleTransactionPopup,
  toggleDeleteTransactionPopup,
} from "../../../../../helpers/sidebarHelpers";
import { processToast, updateToastMessage } from "../../../../../helpers/toastHelper";
import { IDataResponse } from "../../../../../types/ApiService";
import history from "../../../../../history";
import { IAddTransactionReferer } from "../../../../atoms/AddEditTransaction";

export function onClickDuplicate(transactionId: string): void {
  duplicateTransaction(transactionId).then(resp => {
    if (resp && resp.status == 200 && resp.data) {
      let response: IDataResponse<any> = resp.data;
      processToast(response);
    }
  });
}

export function onClickPaidMultiple(transactionIds: string[]): void {
  markTransactionsAsPaid(transactionIds).then((resp) => {
    if (resp && resp.status == 200 && resp.data) {
      updateTransactionItems(resp.data);
      markAsPaidToast(transactionIds.length);

      history.push({
        search: "?refreshTransactionGrid=true"
      });
    }
  });
}

export function markAsPaidToast(length: number) {
  let count = `${length} ${length == 1 ? "transaction has" : "transactions have"
    }`;

  updateToastMessage(
    `${count} been marked as paid successfully.`,
    "success",
    4000
  );
}

export function onDeleteRow(
  transaction: ITransactionItem,
  location: any,
  history: any
): void {
  let options = getOnEditOptions(transaction);

  let query = addURLParameter(
    location.search,
    "deleteTransaction=" + JSON.stringify(options.transactionId)
  );

  history.push({
    search: query,
  });

  toggleDeleteTransactionPopup(true);
}

export function onDeleteRows(
  transactionIds: string[],
  location: any,
  history: any
): void {
  let query = addURLParameter(
    location.search,
    "deleteTransaction=" + JSON.stringify(transactionIds)
  );

  history.push({
    search: query,
  });

  toggleDeleteTransactionPopup(true);
}

export function onEditRow(
  transaction: ITransactionItem,
  location: any,
  history: any
): void {
  let options = getOnEditOptions(transaction);

  let query = addURLParameter(
    location.search,
    "addEditTransactionOptions=" + JSON.stringify(options)
  );

  history.push({
    search: query,
  });

  toggleTransactionPopup(true);
}

export function getOnEditOptions(
  transaction: ITransactionItem
): IAddEditTransactionOptions {
  return {
    referer: IAddTransactionReferer.Transaction,
    transactionId: transaction.transactionId,
    isExpense: transaction.isExpense,
    isIncome: transaction.isIncome,
    isPaid: transaction.isPaid,
  };
}
