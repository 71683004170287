import styled from 'styled-components';

export const Section = styled.table`
    width: 100%;
    border-collapse: collapse;
    color: ${({ theme }) => theme.colors.primary};

    a {
        width: 100%;
        display: contents;
        text-decoration: none;
        color: ${({ theme }) => theme.colors.primary};
    }

    tr {
        border-bottom: solid 0.5px;
        line-height: 30px;
        border-color: ${({ theme }) => theme.colors.primary};

        &:hover {
            background-color: #eee;
        }
    }

    td {
        cursor: pointer;

        &:first-child {
            width: 85%;
            word-break: break-all;
            text-align: left;
        }

        &:last-child {
            font-weight: bold;
            vertical-align: top;
        }
    }
`;

export const Chevron = styled.span`
    font-size: 1rem;
    font-weight: bolder;
    vertical-align: top;
    padding-right: 1rem;
    left: 0;
    transform: rotate(45deg);

    &:before {
        border-style: solid;
        border-width: 0.1em 0.1em 0 0;
        content: '';
        display: inline-block;
        height: 0.45em;
        left: 0.15em;
        position: relative;
        transform: rotate(45deg);
        width: 0.45em;
    }

    &:hover {
        cursor: pointer;
    }
`;

export const Header = styled.label`
    width: 100%;
    display: block;
    text-align: left;
    color: #121212;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
`;
