import { apiService } from './ApiService';
import * as domains from '../configuration/domains';
import { createBearerToken } from '../helpers/authHelpers';

export const impersonateUser = (userId: string): Promise<any> => {
    return apiService.get(`${domains.impersonationService}/${userId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}