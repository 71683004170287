// Import React
import React, { Component } from "react";
import { withRouter, Route, Redirect } from "react-router-dom";
import TradeEmptyState from "../../../organisms/EmptyState/TradeEmptyState/TradeEmptyState.component";
import InspectionsGrid from "../../../organisms/InspectionsPage/InspectionsGrid";
import TradeDashboardV2 from "../TradeDashboardV2/TradeDashboardV2.component";

import styles from "./TradeDashboard.module.scss";

interface IState {
}


interface IProps {
  history: any;
  location: any;
  match: any;
}

class TradeDashboard extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className={styles.tenantDashboard}>
        <div className={styles.content}>
          <Route
            path={`${this.props.match.url}`}
            exact
            render={() => <Redirect to={`${this.props.match.url}`} />}
          />

          <Route
              path={`${this.props.match.path}/inspections`}
              render={() => <div className={styles.testPage}><InspectionsGrid
                config={{
                  addSettings: {
                    returnUrl: `/dashboard/inspections`
                  },
                  groupSettings: []
                }} /></div>}
            />
        </div>
        <TradeDashboardV2 />
      </div>
    );
  }
}

export default withRouter(TradeDashboard);