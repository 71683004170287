import React, { Component } from 'react'
import { Redirect, Route, withRouter } from 'react-router-dom'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'
import contactDetails from '../../../../images/icons/contact_details.svg'
import contactDocuments from '../../../../images/icons/contact_documents.svg'
import contactFinancials from '../../../../images/icons/contact_financials.svg'
import contactProperties from '../../../../images/icons/contact_properties.svg'
import contactTenancy from '../../../../images/icons/contact_tenancy.svg'
import contactAudit from '../../../../images/icons/icon_timeline.svg'
import menuIssues from '../../../../images/icons/Issues.svg'
import menuChat from '../../../../images/icons/menuicon_chat.svg'
import { getProfileFromProfileId } from '../../../../services/ProfileUpdateService'
import { IUserProfileResponse } from '../../../../types/ProfileService/IProfile'
import IconButton from '../../../atoms/Buttons/IconButton'
import DeleteContactModal from '../../../atoms/DeleteContactModal'
import SFContactDropdown from '../../../atoms/SFDropdown/SFContactDropdown'
import SimpleRouterTabs from '../../../atoms/SimpleRouterTabs'
import TabbedNavigationContainer from '../../../atoms/TabbedNavigationContainer'
import UserCardNav from '../../../atoms/UserCardNav'
import TransactionsGridContainer from '../../FinancialsPage/TransactionsGridContainer/TransactionsGridContainer.component'
import PropertiesListPage from '../../PropertiesListPage'
import ContactChatsPage from './ContactChatsPage'
import styles from './ContactDetail.module.scss'
import ContactDocuments from './ContactDocuments'
import ContactHistory from './ContactHistory'
import ContactIssues from './ContactIssues'
import ContactSummary from './ContactSummary'
import ContactTenancy from './ContactTenancy'

const queryString = require('query-string')

interface IWithRouterProps {
    history: any
    location: any
    match: any
}

interface IState {
    userProfile: IUserProfileResponse
    contactDeleteModal: boolean
}

class ContactPage extends Component<IWithRouterProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            userProfile: null,
            contactDeleteModal: false,
        }

        this.onOpenModal = this.onOpenModal.bind(this)
        this.onCloseModal = this.onCloseModal.bind(this)
    }

    componentWillMount() {
        if (this.props.match.params.contactId) {
            getProfileFromProfileId(this.props.match.params.contactId).then(
                (resp) => {
                    if (resp && resp.status == 200 && resp.data) {
                        this.setState({
                            userProfile: resp.data,
                        })
                    }
                }
            )
        }
    }

    onOpenModal(): void {
        this.setState({
            contactDeleteModal: true,
        })
    }

    onCloseModal(): void {
        this.setState({
            contactDeleteModal: false,
        })
    }

    render() {
        if (!this.state.userProfile) {
            return null
        }

        const routes = [
            {
                path: this.props.match.url + '/summary',
                text: 'Summary',
                icon: contactDetails,
                mobileText: 'Home',
            },
            ...(this.state.userProfile.additionalInformation.contactTypeName ===
                'Property Manager' ||
            this.state.userProfile.additionalInformation.contactTypeName ===
                'Landlord'
                ? [
                      {
                          path: this.props.match.url + '/properties',
                          icon: contactProperties,
                          text: 'Properties',
                          mobileText: 'Properties',
                      },
                  ]
                : []),
            {
                path: this.props.match.url + '/tenancy',
                text: 'Tenancy',
                icon: contactTenancy,
                mobileText: 'Tenancy',
            },
            {
                path: this.props.match.url + '/issues',
                text: 'Issues',
                icon: menuIssues,
                mobileText: 'Issues',
            },
            {
                path: this.props.match.url + '/documents',
                text: 'Docs',
                icon: contactDocuments,
                mobileText: 'Docs',
            },
            {
                path: this.props.match.url + '/financials',
                text: 'Money',
                icon: contactFinancials,
                mobileText: 'Money',
            },
            {
                path: this.props.match.url + '/timeline',
                text: 'Timeline',
                icon: contactAudit,
                mobileText: 'Timeline',
            },
        ]

        if (this.state.userProfile.user) {
            routes.push({
                path: 'chats',
                text: 'Chats',
                icon: menuChat,
                mobileText: 'Chats',
            })
        }

        return (
            <Row className={styles.container}>
                <DeleteContactModal
                    isTenant={
                        this.state.userProfile.additionalInformation.contactType.toLowerCase() ==
                        'Tenant'
                    }
                    isUser={
                        this.state.userProfile.user &&
                        this.state.userProfile.user.userId != null
                    }
                    contactId={this.props.match.params.contactId}
                    contactName={
                        this.state.userProfile.profile.forename +
                        ' ' +
                        this.state.userProfile.profile.surname
                    }
                    open={this.state.contactDeleteModal}
                    onOpenModal={this.onOpenModal}
                    onCloseModal={this.onCloseModal}
                    onDeleteCallBack={() =>
                        this.props.history.push('/dashboard/contacts')
                    }
                />

                <UserCardNav
                    button={
                        <SFContactDropdown
                            isLandlord={
                                this.state.userProfile.additionalInformation
                                    .contactTypeName == 'Landlord'
                            }
                            userId={
                                this.state.userProfile.user &&
                                this.state.userProfile.user.userId
                            }
                            isUser={
                                this.state.userProfile.user &&
                                this.state.userProfile.user.userId != null
                            }
                            isUserActive={
                                this.state.userProfile.user &&
                                this.state.userProfile.user.active
                            }
                            invited={false}
                            onDelete={() => this.onOpenModal()}
                            forename={this.state.userProfile.profile.forename}
                            surname={this.state.userProfile.profile.surname}
                            email={this.state.userProfile.profile.email}
                            contactType={
                                this.state.userProfile.additionalInformation
                                    .contactType
                            }
                            toggle={
                                <IconButton
                                    button={{
                                        displayType: 'action',
                                        elementType: 'button',
                                        icon: 'action',
                                    }}
                                />
                            }
                            contactId={this.props.match.params.contactId}
                            contactName={
                                this.state.userProfile.profile.forename +
                                ' ' +
                                this.state.userProfile.profile.surname
                            }
                        />
                    }
                    subText="Contact"
                    forename={this.state.userProfile.profile.forename}
                    surname={this.state.userProfile.profile.surname}
                    image={this.state.userProfile.profile.profileImageUri}
                />

                <div className={styles.navigation}>
                    <TabbedNavigationContainer fullWidth>
                        <Col className={styles.tabs}>
                            <SimpleRouterTabs routes={routes} />
                        </Col>
                    </TabbedNavigationContainer>
                </div>

                <div className={styles.content}>
                    <Route
                        path={`${this.props.match.url}`}
                        exact
                        render={() => (
                            <Redirect
                                to={`${this.props.match.url}/summary${this.props.location.search}`}
                            />
                        )}
                    />

                    <Route
                        path={`${this.props.match.path}/summary`}
                        render={() => (
                            <div className={styles.routedPage}>
                                <ContactSummary
                                    userProfile={this.state.userProfile}
                                />
                            </div>
                        )}
                    />

                    <Route
                        path={`${this.props.match.path}/properties`}
                        render={() => (
                            <div className={styles.routedPage}>
                                <PropertiesListPage
                                    contactId={
                                        this.props.match.params.contactId
                                    }
                                    contactName={`${this.state.userProfile.profile.forename} ${this.state.userProfile.profile.surname}`}
                                    isLandlord={
                                        this.state.userProfile
                                            .additionalInformation
                                            .contactTypeName === 'Landlord'
                                    }
                                    isPropertyManager={
                                        this.state.userProfile
                                            .additionalInformation
                                            .contactTypeName ===
                                        'Property Manager'
                                    }
                                />
                            </div>
                        )}
                    />

                    <Route
                        path={`${this.props.match.path}/timeline`}
                        render={() => (
                            <div className={styles.routedPage}>
                                <ContactHistory />
                            </div>
                        )}
                    />

                    <Route
                        path={`${this.props.match.path}/tenancy`}
                        render={() => (
                            <div className={styles.routedPageNoMargin}>
                                <ContactTenancy
                                    userProfile={this.state.userProfile}
                                />
                            </div>
                        )}
                    />

                    <Route
                        path={`${this.props.match.path}/issues/:issueId?`}
                        render={() => (
                            <ContactIssues
                                contactName={
                                    this.state.userProfile.profile.forename +
                                    ' ' +
                                    this.state.userProfile.profile.surname
                                }
                                contactId={this.props.match.params.contactId}
                            />
                        )}
                    />

                    <Route
                        path={`${this.props.match.path}/documents`}
                        render={() => (
                            <div className={styles.routedPage}>
                                <ContactDocuments />
                            </div>
                        )}
                    />

                    <Route
                        path={`${this.props.match.path}/financials`}
                        render={() => (
                            <div className={styles.routedPage}>
                                <TransactionsGridContainer context='contact' />
                            </div>
                        )}
                    />
                    
                    {this.state.userProfile.user && (
                        <Route
                            path={`${this.props.match.path}/chats/:chatSessionId?`}
                            component={ContactChatsPage}
                        />
                    )}
                </div>
            </Row>
        )
    }
}

export default withRouter(ContactPage)
