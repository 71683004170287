import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import styles from "./CollapsibleContent.module.scss";

interface IProps {
  title: string;
  defaultStateOpen?: boolean;
  scrollIntoView?: boolean;
  children: JSX.Element;
}

const CollapsibleContent = forwardRef(({ title, defaultStateOpen, scrollIntoView, children }: IProps, ref) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (defaultStateOpen === false) { // Unfortunate variable naming, should be defaultStateClosed
      setOpen(true);
    }
    else {
      setOpen(false);
    }
  }, [])

  useImperativeHandle(ref, () => {
    return {
      toggle: setOpen
    }
  })

  const handleClick = (isOpen: boolean) => {
    setOpen(isOpen);

    if (scrollIntoView) {
      if (divRef.current) {
        window.scrollTo({ behavior: 'smooth', top: divRef.current.offsetTop })
      }
    }
  }

  return <div ref={divRef} className={styles.collapsibleContent}>
    <div className={styles.title} onClick={() => handleClick(!isOpen)}><span className={`${styles.chevron} ${isOpen ? styles.bottom : styles.right}`} />{title}</div>

    <div className={styles.content} aria-expanded={isOpen}>
      {children}
    </div>

  </div>
})

export default CollapsibleContent;