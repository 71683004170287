import * as React from 'react';
import { searchForAssociationContact, searchForAssociationProperty, searchForAssociationTenancy } from '../../../../services/DocumentService';
import { ISearchResult } from '../../../../types/SearchService/ISearchService';
import SearchContactInput from '../../../atoms/SearchContactInput';
import SearchInput from '../../../atoms/SearchInput';
import SearchIssueInput from '../../../atoms/SearchIssueInput';
import SearchPropertyInput from '../../../atoms/SearchPropertyInput';
import SearchTransactionInput from '../../../atoms/SearchTransactionInput';
import SearchUnitInput from '../../../atoms/SearchUnitInput';
import { IOptions } from '../../../atoms/SelectDropdown';
import clearIcon from "../../../../images/icons/clear.svg";
import styles from './DocumentLinks.module.scss';

export enum AssociationType {
    Tenancy,
    Unit,
    Contact,
    Property,
    Issue,
    Transaction
}

interface IProps {
    // Flags
    selectedPropertyIdHasUnits: boolean;

    // Selected IDs
    selectedPropertyId?: IOptions;
    selectedUnitId?: IOptions;
    selectedTenancyId?: any;
    selectedContactId?: ISearchResult;
    selectedIssueId?: IOptions;
    selectedTransactionId?: IOptions;

    // Callbacks
    onSelect(selected: any, type: AssociationType)
}

const DocumentLinks = ({ selectedPropertyId, selectedUnitId, selectedContactId, selectedTenancyId, selectedIssueId, selectedTransactionId, selectedPropertyIdHasUnits, onSelect }: IProps) => {
    const [tenancyAssociationResults, setTenancyAssociationResults] = React.useState(null);
    const [contactAssociationResults, setContactAssociationResults] = React.useState(null);
    const [propertyAssociationResults, setPropertyAssociationResults] = React.useState(null);

    const onSearch = (query: string, type: AssociationType) => {
        if (type == AssociationType.Transaction) {
            searchForAssociationContact(query).then(resp => {
                setContactAssociationResults(resp.data);
            });
        }

        if (type == AssociationType.Contact) {
            searchForAssociationContact(query).then(resp => {
                setContactAssociationResults(resp.data);
            });
        }

        if (type == AssociationType.Property) {
            searchForAssociationProperty(query).then(resp => {
                setPropertyAssociationResults(resp.data);
            });
        }

        if (type == AssociationType.Tenancy) {
            searchForAssociationTenancy(query).then(resp => {
                setTenancyAssociationResults(resp.data);
            });
        }
    }

    return <div className={styles.documentLinks}>
        <div className={styles.inputs}>
            <div className={styles.row}>
                <div className={styles.column}>
                    <div className={styles.search}>
                        <div>
                            <SearchPropertyInput
                                onClear={() => {
                                    onSelect(null, AssociationType.Property);
                                    onSelect(null, AssociationType.Unit);
                                }
                                }
                                onSelect={(option: IOptions) =>
                                    onSelect(
                                        option,
                                        AssociationType.Property
                                    )
                                }
                                selectedPropertyId={
                                    selectedPropertyId &&
                                    selectedPropertyId.value
                                }
                                selectedPropertyName={
                                    (selectedPropertyId &&
                                        selectedPropertyId.label) ||
                                    "No property name or address set"
                                }
                            />
                        </div>
                    </div>
                </div>

                {
                    selectedPropertyId && selectedPropertyIdHasUnits &&
                    <div className={styles.column}>
                        <div className={styles.search}>
                            <div>
                                <SearchUnitInput
                                    propertyId={selectedPropertyId.value}
                                    onClear={() =>
                                        onSelect(null, AssociationType.Unit)
                                    }
                                    selectedUnitId={
                                        selectedUnitId &&
                                        selectedUnitId.value
                                    }
                                    selectedUnitName={
                                        selectedUnitId &&
                                        selectedUnitId.label
                                    }
                                    onSelect={(option: IOptions) =>
                                        onSelect(
                                            option,
                                            AssociationType.Unit
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                }

                <div className={styles.column}>
                    <div className={styles.search}>
                        <div>
                            <SearchContactInput
                                allowNewContact
                                onClear={() =>
                                    onSelect(null, AssociationType.Contact)
                                }
                                onSelect={(option: ISearchResult) =>
                                    onSelect(
                                        option,
                                        AssociationType.Contact
                                    )
                                }
                                selectedId={
                                    selectedContactId &&
                                    selectedContactId.value
                                }
                                selectedName={
                                    selectedContactId &&
                                    selectedContactId.label
                                }
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.column}>
                    <div className={styles.search}>
                        {selectedTenancyId ? (
                            <div>
                                <input
                                    className={styles.input}
                                    disabled
                                    value={
                                        "Tenancy: " + selectedTenancyId.name
                                    }
                                />
                                <img
                                    src={clearIcon}
                                    className={styles.clearIcon}
                                    onClick={() =>
                                        onSelect(null, AssociationType.Tenancy)
                                    }
                                />
                            </div>
                        ) : (
                                <div>
                                    <SearchInput
                                        onSearch={(query: string) =>
                                            onSearch(query, AssociationType.Tenancy)
                                        }
                                        text="Search for a Tenancy..."
                                    />
                                    {tenancyAssociationResults &&
                                        tenancyAssociationResults.length > 0 && (
                                            <ul className={styles.list}>
                                                <li className={styles.heading}>
                                                    <span className={styles.label}>
                                                        Tenancies (
                                    {
                                                            tenancyAssociationResults
                                                                .length
                                                        }
                                    )
                                  </span>
                                                </li>
                                                {tenancyAssociationResults.map(
                                                    (ar, index) => {
                                                        //return <li onClick={() => this.onSelectAssociationItem(ar, AssociationType.Tenancy)} key={index}>{ar.name}</li>
                                                    }
                                                )}
                                            </ul>
                                        )}
                                </div>
                            )}
                    </div>
                </div>

                <div className={styles.column}>
                    <div className={styles.search}>
                        <div>
                            <SearchIssueInput
                                onClear={() =>
                                    onSelect(null, AssociationType.Issue)
                                }
                                selectedIssueId={
                                    selectedIssueId &&
                                    selectedIssueId.value
                                }
                                selectedIssueName={
                                    selectedIssueId &&
                                    selectedIssueId.label
                                }
                                onSelect={(option: IOptions) =>
                                    onSelect(option, AssociationType.Issue)
                                }
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.column}>
                    <div className={styles.search}>
                        <div>
                            <SearchTransactionInput
                                onClear={() => {
                                    onSelect(null, AssociationType.Transaction);
                                }}
                                onSelect={(option: IOptions) => {
                                    onSelect(option, AssociationType.Transaction);
                                }}
                                selectedTransactionId={
                                    selectedTransactionId &&
                                    selectedTransactionId.value
                                }
                                selectedTransactionName={
                                    selectedTransactionId &&
                                    selectedTransactionId.label
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default DocumentLinks;