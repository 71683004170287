import Picker from 'emoji-picker-react'
import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import 'react-chat-elements/dist/main.css'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import { getFileTypeLogo } from '../../../../helpers/documentHelpers'
import chatAttach from '../../../../images/icons/chat_attach.svg'
import chatEmoji from '../../../../images/icons/chat_emoji.svg'
import menuChat from '../../../../images/icons/send_icon.svg'
import AddDocumentModal from '../../../molecules/AddDocumentModal'
import GenericButton from '../../GenericButton'
import styles from './ChatInput.module.scss'
const isImage = require('is-image')

interface IProps extends RouteComponentProps {
    location: any
    history: any
    match: any
    
    handleChange?(e: any): void
    handleDocumentAdded?(document: File): void
    onSubmit?(value: string, file: File): void
    onUploadFileSelected?(uploadFileSelected: boolean): void 

    placeholder?: string
    sending?: boolean
}

interface IState {
    msg: string
    file: File
    uploadingFile: boolean
    isFocused: boolean
    showDocumentModal: boolean
}

class ChatInput extends React.Component<IProps, IState> {
    chatInput

    constructor(props: any) {
        super(props)

        this.chatInput = React.createRef()

        this.state = {
            msg: '',
            file: null,
            uploadingFile: false,
            isFocused: false,
            showDocumentModal: false
        }
    }

    onAddDocumentClick = (): void => {
        this.setState({showDocumentModal: true})
    }

    onCloseDocModal = (images: any[]) => {
        if(images && images.length > 0){
            this.setState({
                file: images[0],
            })

            if (this.props.handleDocumentAdded) {
                this.props.handleDocumentAdded(
                    images[0]
                )
            }

            this.props.onUploadFileSelected && this.props.onUploadFileSelected(true);
        }

        this.setState({
            showDocumentModal: false
        })
    }

    // Added to solve ios keyboard pushing whole screen upwards
/*     componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        console.debug("componentDidUpdate start");
        this.chatInput.current.onfocus = () => {
            //window.scrollTo(0, 0);
            //document.body.scrollTop = 0;
            this.props.inputOnFocus && this.props.inputOnFocus(true);
          };
    } */

    handleChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
        const value: string = event.currentTarget.value
        if (value !== '\n') { // we are using the enter key to also send so ignore
            this.setState({ msg: value } as Pick<IState, any>)

            if (this.props.handleChange) {
                this.props.handleChange(event)
            }
        }
    }

    handleEnterPress = (e: any): void => {
        if (e.key === 'Enter') {
            this.onSubmit()
        }
    }

    onSubmit = (): void => {
        if (this.props.onSubmit) {
            this.props.onSubmit(this.state.msg, this.state.file)
            if(this.state.file){
                this.props.onUploadFileSelected && this.props.onUploadFileSelected(false);
            }
            this.setState({
                msg: '',
                file: null,
            })
        }
    }

    onEmojiClick = (_: any, emojiObject: any) => {
        this.setState({
            msg: this.state.msg.concat(emojiObject.emoji),
        })
    }

    displayFile = (file: File): JSX.Element => {
        if (isImage(file.name)) {
            return <img src={URL.createObjectURL(file)} />
        }

        return getFileTypeLogo(file.name)
    }

    render() {
        return (
            
            <div>
                {
                this.state.showDocumentModal && 
                    <AddDocumentModal 
                        openManual={true} 
                        onCloseModalWithDocs={this.onCloseDocModal} 
                    />
                }
                {this.state.file && (
                    <div className={styles.files}>
                        <p className={styles.label}>Upload File</p>
                        {this.displayFile(this.state.file)}
                    </div>
                )}

                <div className={styles.container}>
                    <div className={styles.innerContainer}>
                        <div className={styles.actions}>
                            <div className={styles.emoji}>
                                <Tooltip
                                    theme="light"
                                    html={
                                        <Picker onEmojiClick={this.onEmojiClick} />
                                    }
                                    position="bottom"
                                    trigger="mouseenter"
                                    arrow
                                    interactive={true}
                                >
                                    <img src={chatEmoji} />
                                </Tooltip>
                            </div>

                            <div
                                className={styles.attach}
                                onClick={() => this.onAddDocumentClick()}
                            >
                                <img src={chatAttach} />
                            </div>
                        </div>

                        <TextareaAutosize
                            ref={this.chatInput}
                            onKeyDown={this.handleEnterPress}
                            value={this.state.msg}
                            onChange={this.handleChange}
                            autoFocus={true}
                        />
                        <GenericButton onClick={() => this.onSubmit()}>
                            <img src={menuChat}></img>
                        </GenericButton>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ChatInput)
