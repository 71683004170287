import React from 'react'
import SuccessTick from '../../../atoms/SuccessTick'
import styles from './ChangePasswordFormSuccess.module.scss'

const ChangePasswordFormSuccess = () => {
    return (
        <div className={styles.success}>
            <h1>Password Change Successful</h1>
            <SuccessTick />
            <p>Your password has been changed.</p>
        </div>
    )
}

export default ChangePasswordFormSuccess
