import SelectDropdown, { IOptions } from '../../../atoms/SelectDropdown'
import { ITaskDto } from '../../../../types/Tasks' 
import { ITaskType } from '../_types'

export const reformatDates = (data: Array<object>): Array<ITaskDto> => {
    let result: Array<ITaskDto> = [];

    if(data)
    {
        data.forEach((x: ITaskDto) => {
            let normalisedTask : ITaskDto = {
                ...x,
                dueDate: x.dueDate != null ? new Date(x.dueDate) : null,
                startDate: x.startDate != null ? new Date(x.startDate) : null,
                reminderDateTime: x.reminderDateTime != null ? new Date(x.reminderDateTime) : null,

            }
    
            result.push(normalisedTask);
        });
    }

    return result;
}

export const getAllTypesAsFlatStructure = (typesInHierarchy) => {
    let alltypes = [];
    if(typesInHierarchy != null)
    {
        typesInHierarchy.forEach(function (item) {
            item.subtypes.forEach(function (a) {
                if (a.taskTypeName != 'Other' || (a.taskTypeName == 'Other' && a.parentTaskTypeId == 7)) {
                    alltypes.push(a)
                }
            })
        });
    }

    return alltypes;
}

export const getParentType = (childId: string, parentTypes: ITaskType[], allTypes: ITaskType[]): IOptions => {
    let childType: ITaskType = allTypes.find(
        (a: ITaskType) => a != null && a.taskTypeId == parseInt(childId)
    )
    if (childType) {
        let parent: ITaskType =
        parentTypes.find(
                (a: ITaskType) => a.taskTypeId == childType.parentTaskTypeId
            )
            if(parent){
                return {
                    value: parent.taskTypeId.toString(),
                    label: parent.taskTypeName,
                }
            }
    }
    return null;
}

export const getToDefaultOtherChild = (parentId: string, parentTypes: ITaskType[]): IOptions => {
    if (parentId) {
        let parent: ITaskType =
        parentTypes.find(
                (a) => a.taskTypeId == parseInt(parentId)
            )
        let childOther: ITaskType = parent.subtypes.find(
            (a) => a.taskTypeName == 'Other'
        )
        return {
            value: childOther.taskTypeId.toString(),
            label: childOther.taskTypeName,
        }
    }
    return null;
}