import React, { useState, useEffect, useRef, createContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { RootState } from '../../../../reducers/rootReducer';
import { Responsive, WidthProvider } from "react-grid-layout";
import { getLocalTourInformation, setLocalTourInformation } from '../../../../helpers/joyrideHelpers'
import Tour from 'reactour'
import { dashboardHomeSteps } from '../../../../configuration/joyride'
import { getTotalProperties } from '../../../../services/DashboardService';
import { deduceLayout, defaultLayoutLg, defaultLayoutMd, defaultLayoutXs, defaultLayoutConfig, ILayoutObject } from './_layout/landlordDashbordV2.layout';
import { getFromLS, saveToLS, getLayoutFromLS } from '../helpers/dashboardHelper';

import InitialStateSection from '../Sections/InitialStateSection/InitialStateSection.component';
import PostcodeSearchPart from '../Sections/PostcodeSearchPart/PostcodeSeachPart.component';
import CalculatorPart from '../Sections/CalculatorPart/CalculatorPart.component';
import ActiveMaintenanceIssuesSection from '../Sections/ActiveMaintenanceIssuesSection/ActiveMaintenanceIssuesSection.component';
import ActivePropertiesSection from '../Sections/ActivePropertiesSection/ActivePropertiesSection.component'
import ActiveChartSection from '../Sections/ActiveChartSection/ActiveChartSection.component'
import GettingStartedSection from '../Sections/GettingStartedSection/GettingStartedSection.component'
import RecentPropertiesSection from '../Sections/RecentPropertiesSection/RecentPropertiesSection.component'
import ActiveTasksSection from '../Sections/ActiveTasksSection/ActiveTasksSection.component';
import HelpCentre from '../Sections/HelpCentre/HelpCentre.component';
import { DashboardContext } from '../_context/dashboardContext';
import { IDashboardConfig } from './_config/dashboardConfig';
import * as S from "./LandlordDashboardV2.styles";
import ActiveIncomeSection from '../Sections/ActiveIncomeSection/ActiveIncomeSection.component';
import ActiveMoneyChart1Section from '../Sections/ActiveMoneyChart1Section/ActiveMoneyChart1Section.component';

const ResponsiveGridLayout = WidthProvider(Responsive);

const LandlordDashboardV2 = () => {
    const [layoutVisibiltyConfig, setLayoutVisibiltyConfig] = useState(defaultLayoutConfig);
    const [layoutLg, setLayoutLg] = useState<Array<ILayoutObject>>(defaultLayoutLg(false));
    const [layoutMd, setLayoutMd] = useState<Array<ILayoutObject>>(defaultLayoutMd(false));
    const [layoutXs, setLayoutXs] = useState<Array<ILayoutObject>>(defaultLayoutXs(false));
    const [inEditMode, setInEditMode] = useState(false);
    const [breakpointHasChanged, setBreakpointHasChanged] = useState(false);
    const [dashboardHomeTour, setDashboardHomeTour] = useState(false);

    const storeId = useRef(null);
    const currentBreakpoint = useRef(null);
    const initialLoadComplete = useRef(false);
    const hasProperties = useRef(true);
    const previousRefWidth = useRef(4);
    const config = useRef<IDashboardConfig>(null);
    
    let history = useHistory();

    const userId = useSelector((state: RootState) => state.user.user_id);

    // Layout initialisation
    useEffect(() => {
        config.current = require("./_config/dashboardConfig.json");
        storeId.current = config.current.dashboardName+userId;

        let savedLayoutConfig = getFromLS("layoutVisibiltyConfig", storeId.current) || undefined;
        let lLayoutLg = null;
        let lLayoutMd = null;
        let lLayoutXs = null;

        if(savedLayoutConfig != undefined){
            let savedDashboardVersion: number = getFromLS("dashboardVersion", storeId.current);
            if(!savedDashboardVersion || config.current.dashboardVersion > savedDashboardVersion){
                savedLayoutConfig = layoutVisibiltyConfig;
                lLayoutLg = layoutLg;
                lLayoutMd = layoutMd;
                lLayoutXs = layoutXs;
            }else{
                setLayoutVisibiltyConfig(savedLayoutConfig);
                lLayoutLg = deduceLayout("layoutLg", getLayoutFromLS("layoutLg", storeId.current), false, false);
                lLayoutMd = deduceLayout("layoutMd", getLayoutFromLS("layoutMd", storeId.current), false, false);
                lLayoutXs = deduceLayout("layoutXs", getLayoutFromLS("layoutXs", storeId.current), false, false);
                setLayoutLg(lLayoutLg);
                setLayoutMd(lLayoutMd);
                setLayoutXs(lLayoutXs);
            }
        }else{
            savedLayoutConfig = layoutVisibiltyConfig;
            lLayoutLg = layoutLg;
            lLayoutMd = layoutMd;
            lLayoutXs = layoutXs;
        }         
        previousRefWidth.current = 4;
        initialLoadComplete.current = true; // Need to set this as onLayoutChange seems to fire before useEffect
        fetchDashboardData(savedLayoutConfig, lLayoutLg, lLayoutMd, lLayoutXs);
      }, []);

    // Tour initialisation
    useEffect(() => {
        let tourInformation = getLocalTourInformation(userId)
        let urlParams = new URLSearchParams(location.search);
        let addProperty = urlParams.has('addProperty') || localStorage.getItem('rc-add-property');

        if (tourInformation && !tourInformation.dashboardTour && !addProperty) {
            setDashboardHomeTour(true);

            tourInformation.dashboardTour = true
            setLocalTourInformation(tourInformation, userId)
        }else if (addProperty) {
            tourInformation.dashboardTour = true
            setLocalTourInformation(tourInformation, userId)
        }
    }, []);

    const fetchDashboardData = async(visibilityConfig, lLayoutLg, lLayoutMd, lLayoutXs) =>{
        let resp = await getTotalProperties();
        if (resp.status == 200 && resp.data){
            hasProperties.current = resp.data.atAGlance.totalProperties > 0;

            updateSectionsVisibility(visibilityConfig, lLayoutLg, lLayoutMd, lLayoutXs);
        }
    }

    const removeSection = (id: string) => {
        let updatedLayoutConfig  = {...layoutVisibiltyConfig}
        switch(id){
            case "getStarted": updatedLayoutConfig.showGetStarted = false; break;
            case "helpCentre": updatedLayoutConfig.showHelpCentre = false; break;
            case "properties": updatedLayoutConfig.showProperties = false; break;
            case "maintenance": updatedLayoutConfig.showMaintenance = false; break;
            case "tasksSummary": updatedLayoutConfig.showTasksSummary = false; break;
            case "researchArea": updatedLayoutConfig.showResearchArea = false; break;
            case "dealCalculator": updatedLayoutConfig.showDealCalculator = false; break;
            case "portfolioChart": updatedLayoutConfig.showPortfolioChart = false; break;
            case "recentProperties": updatedLayoutConfig.showRecentProperties = false; break;
            case "income": updatedLayoutConfig.showIncome = false; break;
            case "moneyChart1": updatedLayoutConfig.showMoneyChart1 = false; break;
        }
        setLayoutVisibiltyConfig(updatedLayoutConfig);
    }

    const updateSectionsVisibility = (visibilityConfig, lLayoutLg, lLayoutMd, lLayoutXs) => {
        updateAllLayouts(false, false, lLayoutLg, lLayoutMd, lLayoutXs, visibilityConfig);
        setLayoutVisibiltyConfig(visibilityConfig);
    }

    const resetSections = () => {
        updateAllLayouts(false, true, defaultLayoutLg(false), defaultLayoutMd(false), defaultLayoutXs(false), defaultLayoutConfig);
        setLayoutVisibiltyConfig(defaultLayoutConfig);
    }
    
    const saveEditSections = (editMode: boolean, save: boolean) => {
        updateAllLayouts(editMode, save, layoutLg, layoutMd, layoutXs, layoutVisibiltyConfig);
    }

    const updateAllLayouts = (editMode: boolean, save: boolean, cLayoutLg, cLayoutMd, cLayoutXS, cLayoutVisibiltyConfig) => {
        setInEditMode(editMode);
        let lLayoutLg = deduceLayout("layoutLg", cLayoutLg, editMode, hasProperties.current);
        let lLayoutMd = deduceLayout("layoutMd", cLayoutMd, editMode, hasProperties.current);
        let lLayoutXs = deduceLayout("layoutXs", cLayoutXS, editMode, hasProperties.current);
        setLayoutLg(lLayoutLg);
        setLayoutMd(lLayoutMd);
        setLayoutXs(lLayoutXs);
        if(save){
            saveToLS(lLayoutLg, lLayoutMd, lLayoutXs, cLayoutVisibiltyConfig, storeId.current, config.current.dashboardVersion);
        }
    }

    const onLayoutChange = (newLayout: Array<ILayoutObject>) => {
        // There is a defect in this layout control event, newLayout does not always have the correct layout when
        // there is a breakpoint change, hence we ignore it during breakpoint changes and use our stored values
        if(initialLoadComplete.current && previousRefWidth.current == newLayout[0].w && !breakpointHasChanged){
            if(currentBreakpoint.current == "lg"){
                setLayoutLg(newLayout);
            }
            else if(currentBreakpoint.current == "md" || currentBreakpoint.current == "sm"){
                setLayoutMd(newLayout);
            }
            else if(currentBreakpoint.current == "xs" || currentBreakpoint.current == "xxs"){
                setLayoutXs(newLayout);
            }
            else{
                //setLayoutLg(deduceLayout(newLayout));
            }
        }
        previousRefWidth.current = newLayout[0].w;
        if(breakpointHasChanged){
            setBreakpointHasChanged(false);
        }
    }

    const onBreakpointChange = (breakpoint) => {
        if(breakpoint != currentBreakpoint.current && currentBreakpoint.current != null){
            setBreakpointHasChanged(true);
        }
        currentBreakpoint.current = breakpoint;
    }

    const closeTour = () => {
        let tourInformation = getLocalTourInformation(userId)
        tourInformation.dashboardTour = true
        setLocalTourInformation(tourInformation, userId)
        setDashboardHomeTour(false);
    }

    const restartTour = () => {
        let tourInformation = getLocalTourInformation(userId)
        tourInformation.dashboardTour = false
        setLocalTourInformation(tourInformation, userId)
        setDashboardHomeTour(true);
    }

    const onPropertyDelete = () => {
        fetchDashboardData(layoutVisibiltyConfig, layoutLg, layoutMd, layoutXs);
    }

    return (
        <>
        <Tour
            steps={dashboardHomeSteps}
            isOpen={dashboardHomeTour}
            onRequestClose={() => closeTour()}
        />
        <DashboardContext.Provider value={{landlordDasboardInEditMode: inEditMode}}>
            <S.PageWrapperDiv>
                <ResponsiveGridLayout
                    className="layout"
                    layouts={{
                        lg: layoutLg,
                        md: layoutMd,
                        sm: layoutMd,
                        xs: layoutXs,
                        xxs: layoutXs
                    }}
                    cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12}}
                    rowHeight={30}
                    isDraggable={false}
                    isResizable={false}
                    verticalCompact={true}
                    draggableCancel=".non-draggable"
                    onBreakpointChange={onBreakpointChange}
                    onLayoutChange={onLayoutChange}
                    >
                    {
                        layoutVisibiltyConfig.showGetStarted
                        ?
                        <S.SectionDiv key="getStarted" data-tour="getStarted">
                            <GettingStartedSection 
                                id="getStarted"
                                header="Get Started"
                                hideOnClick={removeSection}
                                startTour={restartTour}
                            />
                        </S.SectionDiv>
                        :
                        <div></div>
                    }
                    {
                        layoutVisibiltyConfig.showHelpCentre
                        ?
                        <S.SectionDiv key="helpCentre">
                            <HelpCentre 
                                id="helpCentre"
                                header="Help Centre"
                                hideOnClick={removeSection}
                            />
                        </S.SectionDiv>
                        :
                        <div></div>
                    }
                    {
                        layoutVisibiltyConfig.showTasksSummary 
                        ?
                        <S.SectionDiv key="tasksSummary" className={"tasksSummary"}>
                            <ActiveTasksSection 
                                id="tasksSummary"
                                header={config.current && config.current.tasksSection.header}
                                hideOnClick={removeSection}
                                config={config.current && config.current.tasksSection}
                            />
                        </S.SectionDiv>
                        :
                        <div></div> 
                    }
                    {
                        layoutVisibiltyConfig.showProperties 
                        ?
                        <S.SectionDiv key="properties" className={"propertyNumber"}>
                            <ActivePropertiesSection 
                                id="properties"
                                header={config.current && config.current.propertiesSection.header}
                                hideOnClick={removeSection}
                                config={config.current && config.current.propertiesSection}
                            />
                        </S.SectionDiv>
                        :
                        <div></div>
                    }
                    {
                        layoutVisibiltyConfig.showIncome
                        ?
                        <S.SectionDiv  key="income">
                            <ActiveIncomeSection 
                                id="income"
                                header={config.current && config.current.incomeSection.header}
                                hideOnClick={removeSection}
                            />  
                        </S.SectionDiv>
                        :
                        <div></div>
                    }
                    {
                        layoutVisibiltyConfig.showMaintenance 
                        ?
                        <S.SectionDiv key="maintenance" data-tour="threeTabsDashboard">
                            <ActiveMaintenanceIssuesSection 
                                id="maintenance"
                                header={config.current && config.current.maintenanceSection.header}
                                hideOnClick={removeSection}
                                config={config.current && config.current.maintenanceSection}
                            />
                        </S.SectionDiv>
                        :
                        <div></div> 
                    }
                    {
                        layoutVisibiltyConfig.showResearchArea 
                        ?
                        <S.SectionDiv key="research" data-tour="research">
                            <InitialStateSection 
                                id="researchArea" 
                                header={config.current && config.current.researchSection.header} 
                                iconUrl={config.current && config.current.researchSection.initialState.iconUrl}
                                title={config.current && config.current.researchSection.initialState.title}
                                summary={config.current && config.current.researchSection.initialState.summary}
                                action={<PostcodeSearchPart />}
                                hideOnClick={removeSection}
                                titleOnClick={() => history.push(`/dashboard/research`)}
                                //imageSize="96%"
                            />
                        </S.SectionDiv>
                        :
                        <div></div> 
                    }
                    {
                        layoutVisibiltyConfig.showRecentProperties && hasProperties.current
                        ?
                        <S.SectionDiv  key="recentProperties">
                            <RecentPropertiesSection 
                                id="recentProperties"
                                header="Recent Properties"
                                hideOnClick={removeSection}
                                onDelete={onPropertyDelete}
                            /> 
                        </S.SectionDiv>
                        :
                        <div></div>
                    }
                    {
                        layoutVisibiltyConfig.showDealCalculator 
                        ?
                        <S.SectionDiv key="dealCalculator">
                            <InitialStateSection 
                                id="dealCalculator"
                                header={config.current && config.current.calculatorSection.header} 
                                iconUrl={config.current && config.current.calculatorSection.initialState.iconUrl}
                                title={config.current && config.current.calculatorSection.initialState.title}
                                summary={config.current && config.current.calculatorSection.initialState.summary}
                                action={<CalculatorPart />}
                                hideOnClick={removeSection}
                                titleOnClick={() => history.push(`/dashboard/research/deal-calculator?purchasePrice=${200000}`)}
                                //imageSize="96%"
                            />
                        </S.SectionDiv>
                        :
                        <div></div>
                    }
                    {
                        layoutVisibiltyConfig.showMoneyChart1
                        ?
                        <S.SectionDiv  key="moneyChart1">
                            <ActiveMoneyChart1Section 
                                id="moneyChart1"
                                header={config.current && config.current.moneyChart1Section.header}
                                hideOnClick={removeSection}
                            />  
                        </S.SectionDiv>
                        :
                        <div></div>
                    }
                    {
                        layoutVisibiltyConfig.showPortfolioChart
                        ?
                        <S.SectionDiv  key="portfolioChart">
                            <ActiveChartSection 
                                id="portfolioChart"
                                header={config.current && config.current.portfolioChartSection.header}
                                hideOnClick={removeSection}
                                config={config.current && config.current.portfolioChartSection}
                            />
                        </S.SectionDiv>
                        :
                        <div></div>
                    }
                </ResponsiveGridLayout>
                <S.ResetEditWrapperDiv>
                    <S.ResetEditInnerWrapperDiv data-tour="resetEdit">
                        <S.ResetEditDiv onClick={() => resetSections()}><img src="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/reset_dashboard_icon.svg" /><label>Reset Dashboard</label></S.ResetEditDiv>
                        {
                            !inEditMode
                            ?
                            <S.ResetEditDiv onClick={() => saveEditSections(true, false)}><img src="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/edit_dashboard_icon.svg" /><label>Edit Dashboard</label></S.ResetEditDiv>
                            :
                            <S.ResetEditDiv onClick={() => saveEditSections(false, true)}><img src="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/save_dashboard_icon.svg" /><label>Save</label></S.ResetEditDiv>
                        }
                    </S.ResetEditInnerWrapperDiv>
                    
                </S.ResetEditWrapperDiv>
            </S.PageWrapperDiv>
        </DashboardContext.Provider>
        </>
        
    );
}

export default LandlordDashboardV2;