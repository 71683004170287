import React from "react";
import SFDropdown from "..";
import ActionButton from "../../ActionButton";
import { withRouter } from "react-router";
import styles from "./SFPropertyDropdown.module.scss";
import { onAddTenancyClick, onAddIssueClick, addDocumentClicked, onAddExpenseClick, onPropertyDetailsClick, onEditProperty } from "./_helpers";
import { goToRoute } from "../../../../history";
import { IPropertyConfigurationRequest } from "../../../../types/UnitService";
import { IAddEditInspection } from "../../AddEditInspection";
import { addURLParameter } from "../../../../helpers/locationHelpers";
import { hasClientArea } from "../../../../helpers/clientHelpers";

interface IProps {
  propertyId: string;
  propertyName: string;
  propertyType: string;
  residentialType: string;
  commercialType: string;
  isProspect: string;
  toggle?: JSX.Element;
  history: any;
  location: any;
  match: any;

  onDelete?(): void;
  markAsPropertyPurchased?(): void;
  betaOnClick?(): void
}

class SFPropertyDropdown extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <SFDropdown
        loadedBeforeRender
        customCssClassName={styles.syncfusionDropdown}
        customToggle={
          this.props.toggle ? this.props.toggle : <ActionButton />
        }
        onSelect={(args) => {
          if (args.item.text === "View Summary") {
            this.props.history.push(`/dashboard/property/${this.props.propertyId}`)
          }

          if (args.item.text === "Add Tenancy") {
            //this.props.history.push(`/dashboard/tenancy/add`);
            onAddTenancyClick(this.props.propertyId, this.props.propertyName, this.props.location.search);
          }

          if (args.item.text === "Add Task") {
            this.props.history.push(`/dashboard/task/add?propertyId=${this.props.propertyId}&propertyName=${this.props.propertyName}`);
          }

          if (args.item.text === "Add Issue") {
            onAddIssueClick(this.props.propertyId, this.props.propertyName, this.props.location.search);
          }

          if (args.item.text === "Add Document") {
            addDocumentClicked({
              propertyId: this.props.propertyId
            }, this.props.location.search);
          }

          if (args.item.text === "Add Income") {
            onAddExpenseClick(this.props.propertyId, this.props.propertyName, this.props.location.search, true, false);
          }

          if (args.item.text === "Add Expense") {
            onAddExpenseClick(this.props.propertyId, this.props.propertyName, this.props.location.search, false, true);
          }

          if (args.item.text === "Property Details") {
            onPropertyDetailsClick(this.props.propertyId);
          }

          if (args.item.text === "Address/Valuation") {
            onEditProperty(this.props.propertyId, this.props.location);
          }

          if (args.item.text === "Delete") {
            if (this.props.onDelete) {
              this.props.onDelete();
            }
          }

          if (args.item.text === "Go to Money") {
            this.props.history.push(`/dashboard/property/${this.props.propertyId}/financials`)
          }

          if (args.item.text === "Go to Issues") {
            this.props.history.push(`/dashboard/property/${this.props.propertyId}/issues`)
          }

          if (args.item.text === "Property purchased") {
            this.props.markAsPropertyPurchased();
          }

          if (args.item.text === "Add Multi Units") {
            let query: IPropertyConfigurationRequest = {
              propertyId: this.props.propertyId,
              propertyName: this.props.propertyName,
              propertyType: this.props.propertyType,
              residentialType: this.props.residentialType,
              commercialType: this.props.commercialType,
              returnUrl: `/dashboard/property/${this.props.propertyId}/spec/units`
            };

            let toQuery = JSON.stringify(query);
            goToRoute(`/dashboard/units/multi-add?propertyConfiguration=${toQuery}`);
          }

          if (args.item.text === "Add Inspection") {
            let request: IAddEditInspection = {
              propertyId: this.props.propertyId,
              propertyName: this.props.propertyName,
              returnUrl: `/dashboard/property/${this.props.propertyId}/spec/inspections`
            }

            let string = `inspectionOptions=${JSON.stringify(request)}`;
            let query = addURLParameter(this.props.location.search, string);

            this.props.history.push({
              pathname: '/dashboard/inspection/add',
              search: query
            })
          }

          if (args.item.text === "Beta") {
            this.props.betaOnClick();
          }
        }}
        items={[
          ...(hasClientArea('BetaFunctions')
              ? [
                    {
                      iconCss: styles.iconViewSummary,
                      text: "Beta",
                    },
                ]
              : []),
          {
            iconCss: styles.iconViewSummary,
            text: "View Summary",
            url: `/dashboard/property/${this.props.propertyId}`
          },
          ...((this.props.isProspect && this.props.markAsPropertyPurchased) ?
            [{
              separator: true
            }, {
              iconCss: styles.iconAddExpense,
              text: "Property purchased",
            }] : []),
          {
            separator: true
          },
          ...((!this.props.isProspect) ?
            [{
              iconCss: styles.iconAddDocument,
              text: "Add Task",
            }] : []),
          {
            iconCss: styles.iconAddDocument,
            text: "Add Document",
          },
          ...(!this.props.isProspect ?
            [
              {
                iconCss: styles.iconAddExpense,
                text: "Add Expense",
              }, {
                iconCss: styles.iconAddIncome,
                text: "Add Income",
              }, {
                iconCss: styles.iconAddIssue,
                text: "Add Issue",
              }, {
                iconCss: styles.iconAddTenancy,
                text: "Add Tenancy",
              }] : []),
          {
            separator: true
          },
          {
            iconCss: styles.iconEdit,
            text: "Address/Valuation",
          },
          {
            iconCss: styles.iconEdit,
            text: "Property Details",
            url: `/dashboard/property/${this.props.propertyId}/spec/composition`
          },
          ...(!this.props.isProspect ?
            [{
              separator: true
            },
            {
              iconCss: styles.iconGoToFinancials,
              text: "Go to Money",
              url: `/dashboard/property/${this.props.propertyId}/financials`
            },
            {
              iconCss: styles.iconGoToIssues,
              text: "Go to Issues",
              url: `/dashboard/property/${this.props.propertyId}/issues`
            },] : []), 
          {
            separator: true
          },
          {
            iconCss: styles.iconDelete,
            text: "Delete",
          }
        ]}
      />
    );
  }
}

export default withRouter(SFPropertyDropdown);