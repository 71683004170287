import React from 'react';
import GoogleMap from '../../../../atoms/GoogleMap'
import { IAddress } from '../../../../../types/PropertyService/IPropertyService'
import SectionHeader from "../SectionHeader/SectionHeader.component"
import * as S from "./ActiveGoogleMapSection.styles";

interface sectionProps {
    id: string;
    header: string;
    hideOnClick(id: string): void;
    address: IAddress;
}

const ActiveGoogleMapSection = ({id, header, hideOnClick, address}: sectionProps) => {
    return (
        <div>
            <SectionHeader id={id} header={header} hideOnClick={hideOnClick}/>
            <S.SectionDiv>
                <GoogleMap
                    propertyName="The best house"
                    address={address}
                />
            </S.SectionDiv>
        </div>
    )
}

export default ActiveGoogleMapSection;