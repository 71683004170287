import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import GenericButton from '../GenericButton'
import styles from './FileUploadDropzone.module.scss'
import { toMegabytes } from '../../../helpers/formattingHelpers'
import { getFileExtention } from '../../../helpers/documentHelpers'
import Buttons from '../Buttons'

function FileUploadDropzone(props: any) {
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file: any) => {
            let reader = new FileReader()
            let fileName = ''
            let fileSize = ''

            reader.onabort = () => console.log('Error')
            reader.onerror = () => console.log('Error')
            reader.onload = (event: any) => {
                // Do whatever you want with the file contents
                let base64 = event.target.result

                let isTooBig = toMegabytes(parseInt(fileSize)) > props.maxSize

                props.addFile({
                    fileName: fileName,
                    base64: base64,
                    extension: getFileExtention(fileName),
                    isUploading: false,
                    isUploaded: false,
                    hasError: false,
                    size: fileSize,
                    isTooBig: isTooBig,
                    documentTypeId: null,
                })
            }

            fileName = file.name
            fileSize = file.size
            reader.readAsDataURL(file)
        })
    }, [])
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: true,
        accept: 'application/pdf, image/*, audio/*, video/*, .docx',
    })

    return (
        <div className={styles.dropzone} {...getRootProps()}>
            <input {...getInputProps()} />

            {props.children && props.children}

            <div className={styles.uploadButton}>
                <Buttons
                    align={'center'}
                    buttons={[
                        {
                            text: props.text ? props.text : 'Upload Files',
                            displayType: 'submit',
                            elementType: 'button',
                            onClick: () => null,
                        },
                    ]}
                />
            </div>
        </div>
    )
}

export default FileUploadDropzone
