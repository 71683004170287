import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'
import {
    addType,
    getEntities,
    getGroupsForDropdown,
    updateDefaults,
} from '../../../../services/EntityService'
import { IClientListItem, IUserListItem } from '../../../../types/ClientService'
import {
    ICreateEntityTypeRequest,
    IEntityListItem,
} from '../../../../types/EntityService'
import AddButton from '../../../atoms/AddButton'
import IconButton from '../../../atoms/Buttons/IconButton'
import EntityListItem from '../../../atoms/EntityListItem'
import ListItem from '../../../atoms/ListComponents/ListItem'
import SearchBar from '../../../atoms/SearchBar'
import SelectDropdown, { IOptions } from '../../../atoms/SelectDropdown'
import withErrorShield from '../../../atoms/withErrorShield'
import styles from './EntityPage.module.scss'
const queryString = require('query-string')

interface IState {
    entites: Array<IEntityListItem>
    newEntityGroupId: string
    newEntityTypeName: string
    newEntityTypeValue: string
    newEntityTypeDescription: string
    isCreating: boolean
    groups: Array<IOptions>

    entityGroup: Array<string>
}

interface IProps {
    history: any
    match: any
    location: any
    user: IUserListItem
    client: IClientListItem
}

class EntityPage extends Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            entites: [],
            newEntityGroupId: '',
            newEntityTypeName: '',
            newEntityTypeValue: '',
            newEntityTypeDescription: '',
            isCreating: false,
            groups: [],
            entityGroup: [],
        }

        this.handleChange = this.handleChange.bind(this)
        this.getGroupDropdowns = this.getGroupDropdowns.bind(this)
        this.addType = this.addType.bind(this)
        this.getQueryParams = this.getQueryParams.bind(this)
        this.buildQueryParams = this.buildQueryParams.bind(this)
        this.onSelectEntityGroup = this.onSelectEntityGroup.bind(this)
        this.onMarkAsDefault = this.onMarkAsDefault.bind(this)
    }

    onMarkAsDefault(entityGroupId: string, selectedId: string): void {
        updateDefaults(entityGroupId, selectedId).then((resp) => {
            if (resp && resp.status == 200) {
                let selected = this.state.entites.filter(
                    (c) => c.entityGroupId == entityGroupId
                )

                if (!selected && selected.length === 0) {
                    return
                }

                selected.forEach((s) => {
                    s.isDefault = false
                })

                selected.filter(
                    (s) => s.entityTypeId == selectedId
                )[0].isDefault = true

                this.setState({
                    entites: selected,
                })
            }
        })
    }

    getGroupDropdowns(): void {
        getGroupsForDropdown().then((resp) => {
            if (resp && resp.data) {
                this.setState({
                    groups: resp.data,
                })
            }
        })
    }

    addType(): void {
        let request: ICreateEntityTypeRequest = {
            entityGroupId: this.state.newEntityGroupId,
            entityTypeName: this.state.newEntityTypeName,
            entityTypeDescription: this.state.newEntityTypeDescription,
            entityTypeValue: this.state.newEntityTypeValue,
        }

        addType(request).then(() => {
            getEntities(this.state.entityGroup).then((resp) => {
                if (resp && resp.data) {
                    this.setState({
                        entites: resp.data,
                    })
                }
            })
        })
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const field = event.currentTarget.name
        const value: string = event.currentTarget.value

        this.setState({ [field]: value } as Pick<IState, any>)
    }

    componentDidMount(): void {
        try {
            getEntities(this.state.entityGroup).then((resp) => {
                if (resp && resp.data) {
                    this.setState({
                        entites: resp.data,
                    })
                }
            })

            this.getGroupDropdowns()
        } catch (error) {
            throw 'Error'
        }
    }

    getQueryParams(): void {
        const { entityGroup } = queryString.parse(this.props.location.search)

        if (entityGroup) {
            this.setState({
                entityGroup,
            })
        }
    }

    buildQueryParams(): string {
        let entityGroup = ''

        if (this.state.entityGroup instanceof Array) {
            this.state.entityGroup.map((eg) => {
                entityGroup += 'entityGroup=' + eg
            })
        } else {
            entityGroup = 'entityGroup=' + this.state.entityGroup
        }

        return `?${entityGroup}`
    }

    onSelectEntityGroup(id: Array<IOptions>): void {
        this.setState({
            entites: [],
            entityGroup: [],
        })

        id.map((i) => {
            this.state.entityGroup.push(i.value)
        })

        let params = this.buildQueryParams()

        this.props.history.push({
            search: params,
        })

        getEntities(this.state.entityGroup).then((resp) => {
            if (resp && resp.data) {
                this.setState({
                    entites: resp.data,
                })
            }
        })
    }

    render() {
        return (
            <div className={styles.generalPage}>
                <SearchBar
                    text="Search for an entity..."
                    onSearch={() => null}
                />

                <Row className={styles.addButtons}>
                    <Col className={styles.filterBar} xs="4">
                        <SelectDropdown
                            multiSelect
                            placeholder="Filter by an entity group..."
                            data={this.state.groups}
                            onSelect={(id: Array<IOptions>) =>
                                this.onSelectEntityGroup(id)
                            }
                        />
                    </Col>

                    <Col>
                        <IconButton
                            button={{
                                text: ' Add Entity Group',
                                displayType: 'submit',
                                elementType: 'button',
                                icon: 'add',
                            }}
                        />
                    </Col>
                    <Col>
                        <IconButton
                            button={{
                                text: ' Add Entity Type',
                                displayType: 'submit',
                                elementType: 'button',
                                icon: 'add',
                                onClick: () =>
                                    this.setState({
                                        isCreating: !this.state.isCreating,
                                    }),
                            }}
                        />
                    </Col>
                </Row>

                {this.state.entites.length < 1 && (
                    <div>You haven't added any custom entities.</div>
                )}

                {this.state.isCreating && (
                    <ListItem client={this.props.client} user={this.props.user}>
                        <div className={styles.newItem}>
                            <div className={styles.label}>Add Entity Type</div>
                            <Row>
                                <Col>
                                    <input
                                        placeholder="Entity Type name..."
                                        name="newEntityTypeName"
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                <Col>
                                    <input
                                        placeholder="Entity Type description"
                                        name="newEntityTypeDescription"
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                <Col>
                                    <input
                                        placeholder="Entity Type value"
                                        name="newEntityTypeValue"
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                <Col>
                                    <SelectDropdown
                                        placeholder="Select an entity group..."
                                        data={this.state.groups}
                                        onSelect={(id: IOptions) =>
                                            this.setState({
                                                newEntityGroupId: id.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <AddButton
                                        text="Add"
                                        onAdd={() => this.addType()}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </ListItem>
                )}

                {this.state.entites &&
                    this.state.entites.map((entity, index) => {
                        return (
                            <ListItem
                                client={this.props.client}
                                user={this.props.user}
                                key={index}
                            >
                                <EntityListItem
                                    onDefaultChange={(
                                        entityGroupId: string,
                                        selectedId: string
                                    ) =>
                                        this.onMarkAsDefault(
                                            entityGroupId,
                                            selectedId
                                        )
                                    }
                                    entity={entity}
                                />
                            </ListItem>
                        )
                    })}
            </div>
        )
    }
}

export default withErrorShield(withRouter(EntityPage)) as any
