import React from "react";
import { Row } from "reactstrap";

import styles from "./TabbedNavigationContainer.module.scss";

interface IProps {
  noPadding?: boolean;
  fullWidth?: boolean;
  isSubMenu?: boolean;
  children?: any;
}

class TabbedNavigationContainer extends React.Component<IProps, {}> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div
        id="tabbedNavigation" className={`${this.props.isSubMenu ? styles.subNavigation : this.props.fullWidth ? styles.fullWidthNavigation : styles.navigation} ${this.props.noPadding ? styles.noPadding : null}`}
        style={{ paddingBottom: this.props.noPadding ? ".5rem" : "0rem" }}
      >
        <div className={styles.container}>
          <Row>{this.props.children}</Row>
        </div>
      </div>
    );
  }
}

export default TabbedNavigationContainer;