import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Loader, formatPercentage } from "@rentchief/components";
import { RootState } from '../../../../../reducers/rootReducer';
import { useGetCalculationsByPropertyIdQuery } from '../../../../../servicesRtk/CalculatorServiceRtk';
import CounterPart from "../CounterPart/CounterPart.component"
import SectionHeader from "../SectionHeader/SectionHeader.component"
import * as S from "./ActivePropertyYieldSection.styles";

interface sectionProps {
    id?: string;
    header?: string;
    hideOnClick?(id: string): void;
    suppliedPropertyId?: string;
}

const ActivePropertyYieldSection = ({id, header, hideOnClick, suppliedPropertyId}: sectionProps) => {
  // Redux 
  const { propertyId: reduxPropertyId } = useSelector((state: RootState) => state.property.property_data.property_data);

  const [propertyId, setPropertyId] = useState(suppliedPropertyId ? suppliedPropertyId : reduxPropertyId);

  const { data: calculatorData, isLoading, isFetching, isError } = useGetCalculationsByPropertyIdQuery(propertyId);

  let history = useHistory();

  const onNavigate = () => {
    history.push(`/dashboard/property/${propertyId}/research/deal-calculator`)
  }
      
  return (
      <div>
        {
        isLoading
        ?
        <Loader />
        :
        <S.SectionDiv>
            {
              header &&
              <SectionHeader id={id} header={header} hideOnClick={hideOnClick} titleOnClick={onNavigate} /> 
            }
            
            <S.NewDiv>
              <CounterPart 
                id="grossYield" 
                value={
                  calculatorData
                  ? formatPercentage(calculatorData.calculations.percentageCalculations.grossYield)
                  : "N/A"} 
                type="Gross Yield" hasAdd={false}
                numberFontSize={'1.4rem'}
                nameFontSize={'0.8rem'}
                valueOnClick={onNavigate} />
            </S.NewDiv>
            <S.InProgressDiv>
              <CounterPart 
                id="roi" 
                value={
                  calculatorData
                  ? formatPercentage(calculatorData.calculations.percentageCalculations.roi)
                  : "N/A"}
                type="ROI" hasAdd={false}
                numberFontSize={'1.4rem'}
                nameFontSize={'0.8rem'}
                valueOnClick={onNavigate} />
            </S.InProgressDiv>
            <S.TotalDiv>
              <CounterPart 
                id="annualRoi" 
                value={
                  calculatorData
                  ? formatPercentage(calculatorData.calculations.percentageCalculations.annualRoi)
                  : "N/A"} 
                type="Annual ROI" 
                hasAdd={false}
                numberFontSize={'1.4rem'}
                nameFontSize={'0.8rem'}
                valueOnClick={onNavigate} />
            </S.TotalDiv>
        </S.SectionDiv>
        }
      </div>
  )
}

export default ActivePropertyYieldSection;