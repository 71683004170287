import { IIdentityRequest } from '../types/LoginService/ILogin';
import * as domains from '../configuration/domains';
import axios from 'axios';

const instance = axios.create();

export const loginUser = (request: IIdentityRequest): Promise<any> => {
    return instance.post(`${domains.identityService}`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
    });
}

export const refreshToken = (request: IIdentityRequest): Promise<any> => {
    return instance.post(`${domains.identityService}`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
    });
}

export const doesEmailAlreadyExist = (email: string): Promise<any> => {
    return instance.get(`${domains.emailCheckService}?email=${email}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
    });
}