// Import React
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { toggleMobileSidebarFalse } from "../../../../helpers/sidebarHelpers";
import { SlideDown } from "react-slidedown";
import Expand from "../../../../images/icons/ic_chevron_right_24px.svg";
import Collapse from "../../../../images/icons/ic_expand_more_24px.svg";
import { isPlatformAdmin, isLandlord } from "../../../../helpers/roleHelpers";
import styles from "./NavigationItem.module.scss";
import "react-slidedown/lib/slidedown.css";
import UnreadChatCount from "../../../atoms/UnreadChatCount";
import UnreadChatCountRedux from "../../../atoms/UnreadChatCountRedux";

export interface INavigationItem {
  text: string;
  image: string;
  route: string;
  matches: string;
  canView?: boolean;
  landlordOnly?: boolean;
  platformAdminOnly?: boolean;
  indexOf?: Array<string>;
  mobileLink?: string;
  hasNew?: boolean;
  hasNewCount?: number;
  divId?: string;
  small?: boolean;
}

interface IProps {
  options: INavigationItem;
  children?: any;
}

interface IRouterProps {
  history: any;
  location: any;
  match: any;
}

interface IState {
  sidebarOpen: boolean;
  versionModal: boolean;
  showChidlList: boolean;
  internalToggle: boolean;
}

type Props = IProps & IRouterProps;

class NavigationItem extends Component<Props, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      sidebarOpen: false,
      versionModal: false,
      showChidlList: false,
      internalToggle: false
    };

    this.goToRoute = this.goToRoute.bind(this);
    this.isActive = this.isActive.bind(this);
    this.toggleChildList = this.toggleChildList.bind(this);
    this.isChildListActive = this.isChildListActive.bind(this);
  }

  goToRoute(route: string): void {
    this.props.history.push("/" + route);
    toggleMobileSidebarFalse();
  }

  toggleChildList(): void {
    this.setState({
      showChidlList: !this.state.showChidlList,
      internalToggle: true
    });
  }

  componentWillReceiveProps(newProps: Props): void {
    // If the child list is open, the pathname is different and it's not active, then close the list
    if (
      (this.state.showChidlList &&
        this.props.location.pathname != newProps.location.pathname) ||
      !this.isActive()
    ) {
      this.setState({
        showChidlList: false,
        internalToggle: false
      });
    }
  }

  isActive(): boolean {
    const { pathname } = this.props.location;
    if (this.props.options.matches == pathname) {
      return true;
    }

    if (
      !this.props.options.indexOf ||
      this.props.options.indexOf.length === 0
    ) {
      return false;
    }

    let isMatch = false;
    this.props.options.indexOf.forEach(i => {
      if (pathname.indexOf(i) > -1) {
        isMatch = true;
      }
    });

    return isMatch;
  }

  isChildListActive(): boolean {
    return (this.isActive() && !this.state.internalToggle) ||
      (this.state.showChidlList && this.props.children)
      ? true
      : false;
  }

  render() {
    const { options } = this.props;

    if (!options.canView) {
      return null;
    }

    if (options.platformAdminOnly) {
      if (!isPlatformAdmin()) {
        return null;
      }
    }

    if (options.landlordOnly) {
      if (!isPlatformAdmin() && !isLandlord()) {
        return null;
      }
    }

    return (
      <div id={`${options.divId || null}`}>
        <a className={styles.anchor} href={`/${options.route}`} onClick={(e) => e.preventDefault()}>
          <li
            onClick={() => this.goToRoute(options.route)}
            className={`${styles.li} ${this.props.options.small ? styles.isSmall : ""
              } ${this.isActive() ? styles.active : ""}`}
          >

            <div className={styles.inline}>
              <div className={styles.row}>
                <div className={styles.autoCol}>
                  <img src={options.image} />{" "}
                  <span className={styles.smallSpan}>{options.text} </span>
                </div>

                {
                  !this.props.options.small && this.props.options.hasNew &&
                  <div className={styles.col}>
                    <UnreadChatCountRedux />
                  </div>
                }
              </div>
            </div>

            <div
              className={`${styles.expand} ${this.props.options.small ? styles.isHidden : ""
                }`}
              onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                this.toggleChildList();
              }}
            >
              {this.props.children &&
                (this.isChildListActive() ? (
                  <img src={Collapse} />
                ) : (
                    <img src={Expand} />
                  ))}
            </div>
          </li>
        </a>

        <li
          className={`${styles.mobileLi} ${this.isActive() ? styles.active : ""
            }`}
        >
          <div
            className={styles.inline}
            onClick={() => this.goToRoute(options.mobileLink || options.route)}
          >
            <img src={options.image} />{" "}
            <span className={styles.smallSpan}>{options.text}</span>
          </div>

          {
            <div
              className={styles.expand}
              onClick={() => this.toggleChildList()}
            >
              {this.props.children &&
                (this.isChildListActive() ? (
                  <img src={Collapse} />
                ) : (
                    <img src={Expand} />
                  ))}
            </div>
          }
        </li>

        {this.props.children && this.isChildListActive() && (
          <SlideDown className={"my-dropdown-slidedown"}>
            <div>{this.props.children}</div>
          </SlideDown>
        )}
      </div>
    );
  }
}

export default withRouter(NavigationItem);