import styled from 'styled-components';

export const SectionDiv = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 56% 20% 20%; 
    grid-template-areas:
        'header header header'
        'area1 area2 area3';
`; 

export const Area1Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: area1;
`;

export const Area2Div = styled.div`
    grid-area: area2;
`;

export const Area3Div = styled.div`
    grid-area: area3;
`;

