import React from 'react'
import { Row, Col } from 'reactstrap'
import Modal from 'react-responsive-modal'

import styles from './AddUsersToChatModal.module.scss'
import GenericButton from '../GenericButton'
import RotatingLoader from '../RotatingLoader'
import SearchUsersInput from '../SearchUsersInput'
import { IOptions } from '../SelectDropdown'
import { addUserToChatSession, IChatUsers } from '../../../services/ChatService'
import { ISearchResult } from '../../../types/SearchService/ISearchService'
import Buttons from '../Buttons'

interface IProps {
    open: boolean
    chatSessionId: string
    onOpenModal(): void
    onCloseModal(): void
    onCallback(): void
    filteredOutUserList?: Array<IChatUsers>;
}

interface IState {
    loading: boolean
    success: boolean
    selectedUserId: string
    selectedUserName: string
}

class AddUsersToChatModal extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            loading: false,
            success: false,
            selectedUserId: '',
            selectedUserName: '',
        }

        this.onSubmit = this.onSubmit.bind(this)
    }

    onSubmit(): void {
        addUserToChatSession({
            chatSessionId: this.props.chatSessionId,
            userId: this.state.selectedUserId,
        }).then((resp) => {
            if (resp && resp.status == 200) {
                this.props.onCallback()
            }
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <Modal
                    open={this.props.open}
                    onClose={this.props.onCloseModal}
                    center
                >
                    <div>
                        <RotatingLoader loading={true} text={`Deleting...`} />
                    </div>
                </Modal>
            )
        }

        return (
            <Modal
                classNames={{ modal: styles.modal }}
                open={this.props.open}
                onClose={this.props.onCloseModal}
                center
            >
                <div>
                    <p className={styles.modelHeader}>Add Users to Chat</p>
                    <div>
                        <SearchUsersInput
                            onSelect={(selected: ISearchResult) =>
                                this.setState({
                                    selectedUserId: selected.value,
                                    selectedUserName: selected.label,
                                }, () => this.onSubmit())
                            }
                            selectedId={this.state.selectedUserId}
                            selectedName={this.state.selectedUserName}
                            onClear={() =>
                                this.setState(
                                    {
                                        selectedUserId: null,
                                        selectedUserName: null
                                    }
                                )
                            }
                            filteredOutUserList={this.props.filteredOutUserList}
                        />
                    </div>

{/*                     <Row>
                        <Col className={styles.buttonDiv}>
                            <Buttons
                                buttons={[
                                    {
                                        displayType: 'cancel',
                                        elementType: 'button',
                                        onClick: () =>
                                            this.props.onCloseModal(),
                                    },
                                    {
                                        displayType: 'submit',
                                        elementType: 'button',
                                        onClick: () => this.onSubmit(),
                                    },
                                ]}
                            />
                        </Col>
                    </Row> */}
                </div>
            </Modal>
        )
    }
}

export default AddUsersToChatModal
