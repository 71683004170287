import { dateDiffInDays } from '../../../../helpers/dateHelpers';

export interface IStatusPillValues{
    displayText: string;
    backgroundColor: string;
}

export const getStatusPillValues = (expiryDate: any) => {
    let retValues: IStatusPillValues = {
        displayText: '',
        backgroundColor: '#EFE4E4' 
    }

    if(expiryDate){
        let diffInDays = dateDiffInDays(new Date(), expiryDate);
        if(diffInDays < 0){
            retValues.displayText = `${-diffInDays} Days Over`
            retValues.backgroundColor = 'red';
        }else if(diffInDays == 0){
            retValues.displayText = 'Due Today'
            retValues.backgroundColor = 'red';
        }else if(diffInDays > 0 && diffInDays <= 365){
            retValues.displayText = `${diffInDays} Days Left`
            retValues.backgroundColor = (diffInDays < 31) ? 'orange' : 'green';
        }else if(diffInDays > 365){
            let dueDateFormatted = expiryDate.getDate() + "/" + (expiryDate.getMonth() + 1) + "/" + expiryDate.getFullYear().toString().substring(2);;
            retValues.displayText = `Due ${dueDateFormatted}`
            retValues.backgroundColor = 'green';
        }
    }
    else{
        retValues.displayText = `Not Set`
        retValues.backgroundColor = 'red';
    }

    return retValues;
}

export const getStatusPillValuesForCheckItem = (checkCompleted: any) => {
    let retValues: IStatusPillValues = {
        displayText: '',
        backgroundColor: '#EFE4E4' 
    }

    if(checkCompleted){
        retValues.displayText = `Done`
        retValues.backgroundColor = 'green';
    }
    else{
        retValues.displayText = `Not Done`
        retValues.backgroundColor = 'red';
    }

    return retValues;
}