import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { toggleAddPropertySidebar } from '../../../../../actions/appActions';
import { toggleAddTeamMember } from '../../../../../actions/teamsActions'
import SectionHeader from "../SectionHeader/SectionHeader.component"
import LabelWithIconPart from "../LabelWithIconPart/LabelWithIconPart.component"
import * as S from "./GettingStartedSection.styles";

interface sectionProps {
    id: string;
    header: string;
    hideOnClick(id: string): void;
    startTour?(): void;
}

const GettingStartedSection = ({id, header, hideOnClick, startTour}: sectionProps) => {
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <S.SectionDiv>
            <SectionHeader id={id} header={header} hideOnClick={hideOnClick} startTour={startTour} />
            <S.bodyDiv>
                <S.bodyContentDiv>
                    <LabelWithIconPart 
                        id="updateProfile" 
                        labelText="1. Update profile"  
                        iconUrl="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/update_profile_icon.svg"
                        onClick={() => history.push('/dashboard/profile')}
                    />
                    <LabelWithIconPart 
                        id="createTenancy" 
                        labelText="3. Create tenancy"  
                        iconUrl="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/create_tenancy_icon.svg"
                        onClick={() => history.push(`/dashboard/tenancy/add`)}
                    />
                    <LabelWithIconPart 
                        id="addProperty" 
                        labelText="2. Add property"  
                        iconUrl="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/add_property_icon.svg"
                        onClick={() => dispatch(toggleAddPropertySidebar(true))}
                    />
                    <LabelWithIconPart 
                        id="inviteTeamMember" 
                        labelText="4. Invite team"  
                        iconUrl="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/invite_team_member_icon.svg"
                        onClick={() => dispatch(toggleAddTeamMember(true))}
                    />
                </S.bodyContentDiv>
            </S.bodyDiv>
        </S.SectionDiv>
    )
}

export default GettingStartedSection;