import React, {  useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers/rootReducer';
import DatePicker from 'react-datepicker';
import { correctDate } from '../../../../helpers/dateHelpers'
import { Tooltip } from "react-tippy";
import { ToggleSlider }  from "react-toggle-slider";
import regIcon from "../../../../images/icons/icon_reg.svg";
import AssociatedDocuments from '../../../atoms/AssociatedDocuments';
import { Button } from '@rentchief/components';
import * as S from './KeyDatesSection.styles';
import { DateStatusPill } from '../../../atoms/StatusPill/StatusPill.component';

interface sectionProps {
    title?: string;
    description?: string;
    expiryDate?: Date;
    typeCode?: string;
    tooltip?: any;
    createTask?: boolean;
    createTaskUserChoice?: boolean;
    createTaskType?: string;
    displayDocs?: string;
    docType?: string;
    isActive?: boolean;
    onDateChanged?(newDate: Date): void;
    onActiveChanged?(active: boolean): void;
    onDetailsClick?(): void;
    highlighted?: boolean;
}

const KeyDatesSection = ({title, description, expiryDate, tooltip, docType, isActive, highlighted, onDateChanged, onActiveChanged, onDetailsClick}: sectionProps) => {
    // Redux 
    const { propertyId  } = useSelector((state: RootState) => state.property.property_data.property_data);

    const [date, setDate] = useState<Date>(expiryDate);
    const [rememberDate, setRememberDate] = useState<Date>(null);
    const [originalDate, setOriginalDate] = useState<Date>(null);
    const [active, setActive] = useState<boolean>(isActive);

    const navDivRef = useRef<HTMLDivElement>();

    useEffect(() => {
        setDate(expiryDate);
      }, [expiryDate]);

    useEffect(() => {
        setRememberDate(expiryDate);
        setOriginalDate(expiryDate);
        setActive(isActive);

        if(highlighted){
            if (navDivRef.current) {
                window.scrollTo({ behavior: 'smooth', top: navDivRef.current.offsetTop })
            }
        }
        }, []);

    return (
        <>
            <S.ContainerDiv ref={navDivRef} highlighted={highlighted}>
                <S.InnerContainerDiv>
                    <S.MainAreaDiv>
                        <S.HeaderDiv>
                            <S.TitleDiv>{title}</S.TitleDiv>
                            {
                                tooltip 
                                ?
                                <S.TooltipDiv>
                                    <Tooltip
                                        theme="light"
                                        html={tooltip}
                                        position="right"
                                        trigger="mouseenter"
                                        arrow
                                        interactive={true}
                                    >
                                        <img src={regIcon} alt="Info" />
                                    </Tooltip>
                                </S.TooltipDiv>
                                :
                                ""
                            }
                            <S.StatusDiv visibility={active ? 'visible' : 'hidden'}>
                                <DateStatusPill date={expiryDate} />
                            </S.StatusDiv>
                            {/* Add toggle here */}
                            <div>
                                <ToggleSlider
                                    active={active}
                                    onToggle={(isToggled) => {
                                        setActive(isToggled);
                                        isToggled ? setDate(rememberDate) : setDate(null);
                                        onActiveChanged && onActiveChanged(isToggled);
                                        isToggled ? onDateChanged && onDateChanged(rememberDate) : onDateChanged && onDateChanged(null);
                                    }}
                                />
                            </div>

                        </S.HeaderDiv>
                        <S.DateDescriptionDiv dangerouslySetInnerHTML={{__html: description}}></S.DateDescriptionDiv>
                    </S.MainAreaDiv>
                    <S.BottomAreaDiv visibility={active ? 'visible' : 'hidden'}>
                        <S.DateAreaDiv visibility={active ? 'visible' : 'hidden'}>
                            <S.DateDiv highlighted={highlighted}>
                                <DatePicker
                                    todayButton="Today"
                                    dateFormat="dd/MM/yyyy"
                                    selected={date ? date : null}
                                    onChange={(date: Date) => {
                                        let correctedDate = correctDate(date);
                                        setDate(correctedDate);
                                        setRememberDate(correctedDate);
                                        onDateChanged && onDateChanged(correctedDate);
                                        //checkForChanges()
                                    }}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    autofocus={highlighted}
                                    //popperPlacement="bottom-end"
                                />
                            </S.DateDiv>
                            <S.DocDiv>
                                {
                                    docType
                                    ?
                                    <AssociatedDocuments
                                        showUpload
                                        options={{
                                            propertyId: propertyId,
                                            documentType: docType
                                        }}
                                        hideNoDocumentText
                                    />
                                    :
                                    ""
                                }
                                
                            </S.DocDiv>
                        </S.DateAreaDiv>
                        {
                            originalDate && 
                            <S.DetailsLinkDiv><Button onClick={onDetailsClick}>Task</Button></S.DetailsLinkDiv>
                        }
                        
                    </S.BottomAreaDiv>

                </S.InnerContainerDiv>  
            </S.ContainerDiv>
        </>
    )
}

export default KeyDatesSection;