import React, { useEffect, useRef, useState } from 'react';
import { QuestionComponentKeyMap } from '../../../../types/Dynamic/enums';
import { IQuestion, IQuestionComponent } from "../../../../types/Dynamic/types";
import cameraIcon from '../../../../images/icons/ic_camera_alt_24px.svg';
import videoIcon from '../../../../images/icons/ic_videocam_24px.svg';
import styles from './ConductQuestion.module.scss';
import SFUploaderModal from '../../../atoms/SFUploaderModal';
import { imageService } from '../../../../configuration/domains';
import { updateToastMessage } from '../../../../helpers/toastHelper';
import { IReactRouterProps } from '../../../../types';
import { withRouter } from 'react-router';
import { IImageDto, ImageTypeEnum } from '../../../../types/ImageService';
import { getImagesFromImageIds, deleteImage } from '../../../../services/ImageService';
import Modal from "react-responsive-modal";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import 'react-multi-carousel/lib/styles.css';

interface IProps {
    onHandleChange(question): void;
    question: IQuestion;
    objectRoute: string;
}

enum Direction {
    Backwards,
    Forwards
}

type Props = IProps & IReactRouterProps;

const ConductQuestion = ({ question, objectRoute, match, onHandleChange }: Props) => {
    const [uploadingImage, setUploadingImage] = useState(false);
    const [images, setImages] = useState<IImageDto[]>([]);
    const [furthestIndex, setFurthestIndex] = useState(0);
    const [initialLoad, setInitialLoad] = useState(false);
    const [visibleImageCount] = useState(4);
    const [isViewingImages, setIsViewingImages] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [selectedImagesCount, setSelectedImagesCount] = useState(0);
    const [uploadedImagesCount, setUploadedImagesCount] = useState(0);

    useEffect((): void => {
        if (!question.imageIds) {
            return;
        }

        const getImages = (async () => {
            const { data } = await getImagesFromImageIds(question.imageIds);
            if (data) {
                setImages(data);

                if (initialLoad && data.length > visibleImageCount) {
                    return;
                }

                if (data.length > visibleImageCount) {
                    setFurthestIndex(visibleImageCount);
                } else {
                    setFurthestIndex(data.length);
                }

                setInitialLoad(true);
            }
        });

        getImages();

    }, [question.imageIds]);

    const changeImages = (direction: Direction) => {
        let length = question.imageIds.length;

        if (direction === Direction.Forwards) {
            setFurthestIndex(furthestIndex + visibleImageCount);
        }

        if (direction === Direction.Backwards) {
            setFurthestIndex(furthestIndex - visibleImageCount);
        }
    }

    const onChange = (resultValue: any, index: number) => {
        const newQuestionComponentsArr = question.questionComponents.slice();
        newQuestionComponentsArr[index].resultValue = resultValue;

        onHandleChange({
            ...question,
            questionComponents: newQuestionComponentsArr
        });
    }

    const deleteSelectedImage = async (imageId: string) => {
        const { status } = await deleteImage(imageId);
        if (status === 200) {
            let images = question.imageIds.slice();
            images = images.filter(x => x != imageId);
            onHandleChange({
                ...question,
                imageIds: images
            });
            updateToastMessage("Image has been deleted successfully", "success");
        }
    }

    const renderQuestionComponent = (component: IQuestionComponent, index: number) => {
        if (typeof QuestionComponentKeyMap[component.type] === "undefined") {
            return null;
        }

        const route = `${objectRoute}.questionComponents[${index}]`;

        return <div className={styles.column}>
            {React.createElement(
                QuestionComponentKeyMap[component.type],
                {
                    objectRoute: route,
                    validations: component.validation,
                    properties: component.properties || {},
                    content: component.content,
                    resultValue: component.resultValue,
                    isNumeric: component.isNumeric,
                    isReadOnly: component.isReadOnly,
                    onChange: (option: any) => onChange(option, index)
                },
            )}
        </div>
    }

    return (
        <div className={styles.conductQuestion}>
            <Modal showCloseIcon={false} classNames={{ modal: styles.modal }} open={isViewingImages} onClose={() => setIsViewingImages(false)} center>
                <Carousel swipeable={true} useKeyboardArrows={true} selectedItem={selectedImageIndex}>
                    {
                        images.map(x => <div>
                            <img src={x.original} />
                        </div>) as any
                    }
                </Carousel>
            </Modal>

            <SFUploaderModal
                open={uploadingImage}
                onToggle={(open: boolean) => setUploadingImage(open)}
                urls={{
                    saveUrl: `${imageService}/Save`,
                    removeUrl: `${imageService}/Remove`
                }}
                onFileUpload={(imageId) => {
                    setUploadedImagesCount(uploadedImagesCount+1);
                    onHandleChange({ ...question, imageIds: question.imageIds ? question.imageIds.concat([imageId]) : [imageId] })
                    if(uploadedImagesCount+1 == selectedImagesCount){
                        updateToastMessage(selectedImagesCount + " Image(s) have been uploaded successfully", "success")
                        setUploadedImagesCount(0);
                        setUploadingImage(false);
                    }
                }}
                successCallback={() => {
                    //setUploadingImage(false);
                }}
                options={{
                    inspectionId: match.params.inspectionId,
                    imageType: ImageTypeEnum.INSPECTION_RESULT_IMAGE
                }}
                onFilesSelectComplete={(selectedImagesCount) => {setSelectedImagesCount(selectedImagesCount)}}
            />

            <div className={styles.row}>

                {/* Question Text */}
                <div className={styles.questionText}>
                    {question.questionText}
                </div>

                {/* Question Components */}
                {question.questionComponents.map(renderQuestionComponent)}

                {/* Photo and Video */}
                {
                    (question.allowPhotoUpload || question.allowVideoUpload) &&
                    <div className={styles.photoVideoColumn}>
                        <div className={styles.photoRow}>
                            <div className={`${styles.column} ${styles.center}`}>
                                {question.allowPhotoUpload && <span><img tabIndex={0} onClick={() => setUploadingImage(true)} src={cameraIcon} alt='Upload Photo' /></span>}
                                {question.allowVideoUpload && <span><img tabIndex={0} src={videoIcon} alt='Upload Video' /></span>}
                            </div>
                            <div className={styles.imagesColumn}>
                                <div className={styles.images}>
                                    {
                                        images && furthestIndex > visibleImageCount &&
                                        <span className={`${styles.chevron} ${styles.left}`} onClick={() => changeImages(Direction.Backwards)} />
                                    }
                                    {
                                        images && images.length > 0 && images.map((x, i) => {
                                            if (i + 1 > furthestIndex || (i + 1) <= furthestIndex - visibleImageCount) {
                                                return;
                                            }

                                            return <div className={styles.imageWrap}>
                                                <img src={x.original} onClick={() => {
                                                    setSelectedImageIndex(i);
                                                    setIsViewingImages(true)
                                                }} />
                                                <div onClick={() => deleteSelectedImage(x.imageId)} className={`${styles.icon} ${styles.highlighted}`}>x</div>
                                            </div>
                                        })
                                    }
                                    {
                                        images && images && furthestIndex < images.length &&
                                        <span className={`${styles.chevron} ${styles.right}`} onClick={() => changeImages(Direction.Forwards)} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default withRouter(ConductQuestion);