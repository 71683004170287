import { updateToastMessage } from "../helpers/toastHelper";
import { addChatToChatList, checkForUnreadMessages, isActiveHandlerForChatSession, updateChatListItemWithLatestChat } from "../helpers/chatHelpers";
import { INewMessageResponse, markChatAsRead } from "./ChatService";
import { store } from "../store";
import { INewChatStarted } from "../types/ChatService";

const ChatMessageProcessor = {
    processNewChat: function (message: INewChatStarted): void {
        checkForUnreadMessages();
        addChatToChatList(message.listItem);
        updateToastMessage(message.message, "success");
    },

    process: function (message: any): void { 
        // If it's a string, show the message
        if (typeof message === 'string' || message instanceof String) {
            updateToastMessage(`${message}`, "success");
            return;
        }

        const { listItem } = message;

        if (!listItem) {
            return;
        }

        const { chatSessionId } = message.listItem;

        let isActiveChat = store.getState().chat.active_chat_session_id == chatSessionId;

        if (isActiveChat) {
            if (chatSessionId) {
                markChatAsRead(chatSessionId);
                return;
            }
        }

        if (!isActiveChat) {
            checkForUnreadMessages();

            if (listItem) {
                updateChatListItemWithLatestChat(listItem, listItem.chatSessionId);
            }

            updateToastMessage(`${message.chatMessage.sender.senderForename} has sent you a new message`, "success");
        }

        if (!message.chatMessage) {
            updateToastMessage(`${message}`, "success");
            return;
        }

    }
}

export default ChatMessageProcessor;