import React from "react";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';
import styles from "./PageMetadataController.module.scss";

interface IProps {
  unread_chat_count: number;
  client_name: string;
}

class PageMetadataController extends React.Component<IProps, {}> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <Helmet>
        <title>
          {`${this.props.unread_chat_count > 0 ? `(${this.props.unread_chat_count}) ` : ''} ${this.props.client_name} Platform`}
        </title>
      </Helmet>
    );
  }
}

const mapStateToProps = (state: any) => ({
  unread_chat_count: state.notifications.unreadChatCount,
  client_name: state.client.name
});


export default connect(mapStateToProps)(PageMetadataController);