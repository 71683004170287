import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import lodashObject from 'lodash/fp'
import React, { Component } from 'react'
import 'react-dropzone-uploader/dist/styles.css'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Col, FormGroup, Label, Row } from 'reactstrap'
import { entityTypeGroupId } from '../../../../configuration/appConfig'
import { profileUpdateService } from '../../../../configuration/domains'
import { ImageExtensions } from '../../../../configuration/uploader/allowed-extentions.config'
import { getProfileId, getUserId } from '../../../../helpers/authHelpers'
import { canSelectClientImplementation } from '../../../../helpers/permissionHelpers'
import {
    updateLoggedInUserProfileInReduxStore,
    updateProfileImage,
} from '../../../../helpers/profileHelpers'
import { getRole } from '../../../../helpers/roleHelpers'
import {
    processToast,
    updateToastMessage,
} from '../../../../helpers/toastHelper'
import cameraIcon from '../../../../images/icons/ic_photo_camera_24px.png'
import { getClientsDropdown } from '../../../../services/ClientService'
import { getTypesForDropdown } from '../../../../services/EntityService'
import { updateProfile } from '../../../../services/ProfileUpdateService'
import { updateUser } from '../../../../services/UserService'
import { IReactRouterProps } from '../../../../types'
import {
    IUpdateProfile,
    ProfileType,
} from '../../../../types/ProfileService/IProfile'
import { IAddress } from '../../../../types/PropertyService/IPropertyService'
import { IUpdateUserRequest } from '../../../../types/UserService/IUser'
import { UpdateProfile } from '../../../../YupValidationSchemas'
import AddressFinder from '../../../atoms/AddressFinder'
import Buttons from '../../../atoms/Buttons'
import DateTimeFormat from '../../../atoms/DateTimeFormat'
import OutstandingChangesModal from '../../../atoms/OutstandingChangesModal'
import ProfileImage from '../../../atoms/ProfileImage'
import RotatingLoader from '../../../atoms/RotatingLoader'
import SelectDropdown, { IOptions } from '../../../atoms/SelectDropdown'
import SFUploaderModal from '../../../atoms/SFUploaderModal'
import StickyComponent from '../../../atoms/StickyComponent'
import styles from './UserProfilePage.module.scss'
import { IManualProps, IState } from './UserProfilePage.types'
const queryString = require('query-string')

type Props = IReactRouterProps & IManualProps

class UserProfilePage extends Component<Props, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            userProfile: this.props.userProfile,
            isLoading: false,
            isUpdating: false,
            isChangingProfilePicture: false,
            addressResult: null,
            availableContactTypes: [],
            selectedContactType: null,
            availableClients: [],
            outstandingChangesModal: false,
            initialValues: {},
        }
    }

    handleProfileImageChangeSuccess = async (url: string) => {
        if (this.props.isLoggedInUser) {
            updateProfileImage(url)
        } else {
            if (this.props.match.params.contactId) {
                this.setState((prevState) => ({
                    userProfile: {
                        ...prevState.userProfile,
                        profile: {
                            ...prevState.userProfile.profile,
                            profileImageUri: url,
                        },
                    },
                }))
            }
        }
    }

    notify = (message: string) => {
        toast(message)
    }

    handleProfileChange = (event: React.FormEvent<HTMLInputElement>) => {
        const field = event.currentTarget.name
        const value: string = event.currentTarget.value

        this.setState((prevState) => ({
            userProfile: {
                ...prevState.userProfile,
                profile: {
                    ...prevState.userProfile.profile,
                    [field]: value,
                },
            },
        }))

        this.setState({ [field]: value.toLocaleUpperCase() } as Pick<
            IState,
            any
        >)
    }

    cancelForm = (current) => {
        if (lodashObject.isEqual(this.state.initialValues, current)) {
            this.onCancel()
            return
        } else {
            this.setState({
                outstandingChangesModal: true,
            })
        }
    }

    onCancel = (): void => {
        let returnUrl = queryString.parse(this.props.location.search).returnUrl
        if (returnUrl) {
            this.props.history.push(returnUrl)
            return
        }

        if (this.props.redirectUrl) {
            this.props.history.push(this.props.redirectUrl)
            return
        }

        this.props.history.goBack()
    }

    onSave = (): void => {
        let request: IUpdateUserRequest = {
            userId: this.props.userId && this.props.userId,
            forename: this.state.userProfile.profile.forename,
            surname: this.state.userProfile.profile.surname,
            contactNumber: this.state.userProfile.profile.contactNumber,
            emergencyContactName:
                this.state.userProfile.profile.emergencyContactName,
            emergencyContactEmail:
                this.state.userProfile.profile.emergencyContactEmail,
            emergencyContactPhone:
                this.state.userProfile.profile.emergencyContactPhone,
            address: this.state.userProfile.address,
            contactPreferences: {
                email: this.state.userProfile.profile.contactEmail,
                sms: this.state.userProfile.profile.contactSms,
                post: this.state.userProfile.profile.contactPost,
                phone: this.state.userProfile.profile.contactPhone,
            },
            title: this.state.userProfile.profile.title,
            citizen: this.state.userProfile.profile.citizen,
            gender: this.state.userProfile.profile.gender,
            clientId:
                this.state.userProfile.user &&
                this.state.userProfile.user.clientId,
        }

        updateUser(request).then((resp) => {
            if (resp) {
                updateLoggedInUserProfileInReduxStore(request)
                this.props.history.goBack()
            }
        })
    }

    getContactType = (): void => {
        getTypesForDropdown(entityTypeGroupId).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                let data: Array<IOptions> = resp.data
                let selected = data.filter(
                    (x) =>
                        x.value ==
                        this.props.userProfile.additionalInformation.contactType
                )[0]
                this.setState({
                    selectedContactType: selected ? selected : null,
                    availableContactTypes: resp.data,
                })
            }
        })
    }

    isContactProfile = (): boolean => {
        return this.props.profileType == ProfileType.Contact
    }

    isUserProfile = (): boolean => {
        return this.props.profileType == ProfileType.User
    }

    toggleContactPreferencesState = (preference: string) => {
        let newPreference = !this.state.userProfile.profile[preference]
        this.setState((prevState) => ({
            userProfile: {
                ...prevState.userProfile,
                profile: {
                    ...prevState.userProfile.profile,
                    [preference]: newPreference,
                },
            },
        }))
    }

    changeDropdown = (selected: IOptions, type: string) => {
        this.setState((prevState) => ({
            userProfile: {
                ...prevState.userProfile,
                profile: {
                    ...prevState.userProfile.profile,
                    [type]: selected.value,
                },
            },
        }))
    }

    changeContactDropdown = (selected: IOptions, type: string) => {
        this.setState((prevState) => ({
            userProfile: {
                ...prevState.userProfile,
                additionalInformation: {
                    ...prevState.userProfile.additionalInformation,
                    contactType: selected.value,
                    contactTypeName: selected.label
                }
            },
        }))
    }

    resetAddress = () => {
        this.setState({
            addressResult: null,
        })
    }

    componentDidMount(): void {
        window.scrollTo(0, 0)

        getClientsDropdown().then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                this.setState({
                    availableClients: resp.data,
                })
            }
        })

        if (this.props.userProfile) {
            this.setState({
                initialValues: {
                    forename: this.state.userProfile.profile.forename,
                    surname: this.state.userProfile.profile.surname,
                    email: this.state.userProfile.profile.email,
                    contactNumber: this.state.userProfile.profile.contactNumber,
                    address: this.state.userProfile.address,
                    emergencyContactEmail:
                        this.state.userProfile.profile.emergencyContactEmail,
                    emergencyContactName:
                        this.state.userProfile.profile.emergencyContactName,
                    emergencyContactPhone:
                        this.state.userProfile.profile.emergencyContactPhone,
                },
            })
        }

        if (this.props.profileType == ProfileType.Contact) {
            this.getContactType()
        }
    }

    handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        const field = event.currentTarget.name
        const value: string = event.currentTarget.value
        this.setState({ [field]: value } as Pick<IState, any>)
    }

    selectAddressCallback = (address: IAddress) => {
        this.setState({
            userProfile: {
                ...this.state.userProfile,
                address: address,
            },
        })
    }

    componentWillReceiveProps = (newProps: Props): void => {
        if (newProps.userProfile.profile != this.state.userProfile.profile) {
            this.setState({
                userProfile: newProps.userProfile,
            })
        }
    }

    onUpdateSubmitted = () => {
        const {
            forename,
            surname,
            contactNumber,
            profileId,
            citizen,
            gender,
            title,
            email,
        } = this.state.userProfile.profile
        const { address } = this.state.userProfile

        this.setState({
            isUpdating: true,
        })

        let request: IUpdateProfile = {
            isContact: this.props.profileType == ProfileType.Contact,
            profile: {
                email: email,
                profileId: profileId,
                saluation: 'Mr',
                forename: forename ? forename.trim() : null,
                surname: surname ? surname.trim() : null,
                contactNumber: contactNumber ? contactNumber.trim() : null,
                citizen: citizen,
                title: title,
                gender: gender,
                emergencyContactEmail:
                    this.state.userProfile.profile.emergencyContactEmail,
                emergencyContactName:
                    this.state.userProfile.profile.emergencyContactName,
                emergencyContactPhone:
                    this.state.userProfile.profile.emergencyContactPhone,
            },
            address: address,
            contactPreferences: {
                email: this.state.userProfile.profile.contactEmail,
                sms: this.state.userProfile.profile.contactSms,
                post: this.state.userProfile.profile.contactPost,
                phone: this.state.userProfile.profile.contactPhone,
            },
            clientId:
                this.state.userProfile.user &&
                this.state.userProfile.user.clientId,
            contactType: 
                    this.state.userProfile.additionalInformation && this.state.userProfile.additionalInformation.contactType &&
                    this.state.userProfile.additionalInformation.contactType
        }

        updateProfile(request, profileId).then((resp) => {
            if (resp.status == '200') {
                let response = resp.data
                processToast(response)

                let data: IUpdateUserRequest = {
                    userId: this.props.userId && this.props.userId,
                    emergencyContactName:
                        this.state.userProfile.profile.emergencyContactName,
                    emergencyContactEmail:
                        this.state.userProfile.profile.emergencyContactEmail,
                    emergencyContactPhone:
                        this.state.userProfile.profile.emergencyContactPhone,
                    forename: this.state.userProfile.profile.forename,
                    surname: this.state.userProfile.profile.surname,
                    contactNumber: this.state.userProfile.profile.contactNumber,
                    address: this.state.userProfile.address,
                    contactPreferences: {
                        email: this.state.userProfile.profile.contactEmail,
                        sms: this.state.userProfile.profile.contactSms,
                        post: this.state.userProfile.profile.contactPost,
                        phone: this.state.userProfile.profile.contactPhone,
                    },
                    citizen: this.state.userProfile.profile.citizen,
                    title: this.state.userProfile.profile.title,
                    gender: this.state.userProfile.profile.gender,
                    clientId:
                        this.state.userProfile.user &&
                        this.state.userProfile.user.clientId,
                }

                if (
                    this.props.isLoggedInUser ||
                    this.props.userId === getUserId() ||
                    this.state.userProfile.profile.profileId == getProfileId()
                ) {
                    updateLoggedInUserProfileInReduxStore(data)
                }

                if (this.props.redirectUrl) {
                    this.props.history.push(this.props.redirectUrl)
                }

                this.setState({
                    isUpdating: false,
                })
            }
        })
    }

    onClose = (uri?: string): void => {
        this.setState({
            isChangingProfilePicture: false,
        })

        if (!this.props.isLoggedInUser && this.props.match.params.contactId) {
            this.setState((prevState) => ({
                userProfile: {
                    ...prevState.userProfile,
                    profile: {
                        ...prevState.userProfile.profile,
                        profileImageUri: uri,
                    },
                },
            }))
        }
    }

    onCloseChangesModal = (submitCancel: boolean) => {
        this.setState({
            outstandingChangesModal: false,
        })

        if (submitCancel) {
            this.onCancel()
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className={styles.loader}>
                    <RotatingLoader loading={true} text="Loading profile..." />
                </div>
            )
        }

        if (this.state.isUpdating) {
            return (
                <div className={styles.loader}>
                    <RotatingLoader loading={true} text="Editing contact..." />
                </div>
            )
        }

        if (!this.state.userProfile) {
            return <div>No user could be loaded for {this.props.contactId}</div>
        }

        return (
            <div className={styles.page}>
                <OutstandingChangesModal
                    open={this.state.outstandingChangesModal}
                    onCloseChangesModal={this.onCloseChangesModal}
                />

                <Formik
                    initialValues={{
                        forename: this.state.userProfile.profile.forename,
                        surname: this.state.userProfile.profile.surname,
                        email: this.state.userProfile.profile.email,
                        contactNumber:
                            this.state.userProfile.profile.contactNumber,
                        address: this.state.userProfile.address,
                        emergencyContactEmail:
                            this.state.userProfile.profile
                                .emergencyContactEmail,
                        emergencyContactName:
                            this.state.userProfile.profile.emergencyContactName,
                        emergencyContactPhone:
                            this.state.userProfile.profile
                                .emergencyContactPhone,
                    }}
                    enableReinitialize={true}
                    validationSchema={UpdateProfile}
                    onSubmit={(values: any) => {
                        this.setState(
                            (prevState) => ({
                                userProfile: {
                                    ...prevState.userProfile,
                                    profile: {
                                        ...prevState.userProfile.profile,
                                        email: values.email,
                                        forename: values.forename,
                                        surname: values.surname,
                                        contactNumber: values.contactNumber,
                                        emergencyContactEmail:
                                            values.emergencyContactEmail,
                                        emergencyContactName:
                                            values.emergencyContactName,
                                        emergencyContactPhone:
                                            values.emergencyContactPhone,
                                    },
                                    address: values.address,
                                },
                            }),
                            () => {
                                this.onUpdateSubmitted()
                            }
                        )
                    }}
                >
                    {(props) => (
                        <Form>
                            <SFUploaderModal
                                customOptions={
                                    this.props.match.params.contactId
                                        ? {
                                              contactId:
                                                  this.props.match.params
                                                      .contactId,
                                          }
                                        : null
                                }
                                allowedExtensions={ImageExtensions}
                                uploadFileLimit={1}
                                open={this.state.isChangingProfilePicture}
                                onToggle={(open: boolean) =>
                                    this.setState({
                                        isChangingProfilePicture: open,
                                    })
                                }
                                urls={{
                                    saveUrl: `${profileUpdateService}/ProfileImage`,
                                }}
                                onFileUpload={(imageUrl?: string) => {
                                    this.handleProfileImageChangeSuccess(
                                        imageUrl
                                    )
                                    updateToastMessage(
                                        'Profile image has been uploaded successfully',
                                        'success'
                                    )
                                    this.setState({
                                        isChangingProfilePicture: false,
                                    })
                                }}
                            />
                            <Row>
                                <Col className={styles.summary}>
                                    <div className={styles.wrapper}>
                                        <div className={styles.heading}>
                                            <div
                                                className={styles.profileImage}
                                                onClick={() =>
                                                    this.setState({
                                                        isChangingProfilePicture:
                                                            true,
                                                    })
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.cameraIcon
                                                    }
                                                >
                                                    <img
                                                        onClick={() =>
                                                            this.setState({
                                                                isChangingProfilePicture:
                                                                    !this.state
                                                                        .isChangingProfilePicture,
                                                            })
                                                        }
                                                        src={cameraIcon}
                                                        alt="Change Profile Picture"
                                                        title="Change Profile Picture"
                                                    />
                                                </div>
                                                <ProfileImage
                                                    forename={
                                                        this.state.userProfile
                                                            .profile.forename
                                                    }
                                                    surname={
                                                        this.state.userProfile
                                                            .profile.surname
                                                    }
                                                    image={
                                                        this.state.userProfile
                                                            .profile
                                                            .profileImageUri
                                                    }
                                                    large={true}
                                                />
                                            </div>

                                            <p className={styles.name}>
                                                {
                                                    this.state.userProfile
                                                        .profile.forename
                                                }{' '}
                                                {
                                                    this.state.userProfile
                                                        .profile.surname
                                                }
                                            </p>
                                            {this.props.isLoggedInUser && (
                                                <div className={styles.role}>
                                                    {getRole()}
                                                </div>
                                            )}
                                            {!this.isContactProfile() &&
                                                this.props.userProfile.user && (
                                                    <p className={styles.role}>
                                                        You've been registered
                                                        since{' '}
                                                        <DateTimeFormat
                                                            showOnlyDateFormat
                                                            rawDateTime={
                                                                this.props
                                                                    .userProfile
                                                                    .user
                                                                    .accountCreatedAt
                                                            }
                                                        />
                                                    </p>
                                                )}
                                            {this.isContactProfile() && (
                                                <p className={styles.role}>
                                                    {this.state.userProfile
                                                        .additionalInformation
                                                        .status &&
                                                        this.state.userProfile
                                                            .additionalInformation
                                                            .status}
                                                </p>
                                            )}
                                        </div>
                                    </div>

                                    {canSelectClientImplementation() &&
                                        !this.isContactProfile() && (
                                            <div>
                                                <Row
                                                    className={
                                                        styles.contactType
                                                    }
                                                >
                                                    <Col>
                                                        <FormGroup
                                                            className={
                                                                styles.formGroup
                                                            }
                                                        >
                                                            <Label
                                                                className={
                                                                    styles.label
                                                                }
                                                                for="profile.type"
                                                            >
                                                                Client
                                                            </Label>
                                                            <SelectDropdown
                                                                data={
                                                                    this.state
                                                                        .availableClients
                                                                }
                                                                selectedId={
                                                                    this.state
                                                                        .userProfile
                                                                        .user
                                                                        .clientId
                                                                }
                                                                placeholder="Select a Client..."
                                                                onSelect={(
                                                                    id: IOptions
                                                                ) =>
                                                                    this.setState(
                                                                        (
                                                                            prevState
                                                                        ) => ({
                                                                            userProfile:
                                                                                {
                                                                                    ...prevState.userProfile,
                                                                                    user: {
                                                                                        ...prevState
                                                                                            .userProfile
                                                                                            .user,
                                                                                        clientId:
                                                                                            id.value,
                                                                                    },
                                                                                },
                                                                        })
                                                                    )
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}

                                    {this.isContactProfile() && (
                                        <div>
                                            <Row className={styles.contactType}>
                                                <Col>
                                                    <FormGroup
                                                        className={
                                                            styles.formGroup
                                                        }
                                                    >
                                                        <Label
                                                            className={
                                                                styles.label
                                                            }
                                                            for="profile.type"
                                                        >
                                                            Contact Type
                                                        </Label>
                                                        <SelectDropdown
                                                            //disabled={true}
                                                            data={
                                                                this.state
                                                                    .availableContactTypes
                                                            }
                                                            selectedId={
                                                                this.state.userProfile.additionalInformation.contactType
                                                            }
                                                            placeholder="Contact type"
                                                            onSelect={(
                                                                id: IOptions
                                                            ) => this.changeContactDropdown(
                                                                id,
                                                                'contactType'
                                                            )}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}

                                    <div
                                        className={
                                            styles.contactPreferencesContainer
                                        }
                                    >
                                        <Row>
                                            <Col>
                                                <h3 className={styles.title}>
                                                    Contact Preferences
                                                </h3>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p
                                                    className={
                                                        styles.contactPreferences
                                                    }
                                                >
                                                    Please indicate contact preferences.
                                                </p>

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                className={
                                                    styles.contactCheckboxContainer
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.contactdiv
                                                    }
                                                >
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={
                                                                        this
                                                                            .state
                                                                            .userProfile
                                                                            .profile
                                                                            .contactEmail
                                                                    }
                                                                    color="primary"
                                                                    onChange={() =>
                                                                        this.toggleContactPreferencesState(
                                                                            'contactEmail'
                                                                        )
                                                                    }
                                                                    value="Email"
                                                                    className={
                                                                        this
                                                                            .state
                                                                            .userProfile
                                                                            .profile
                                                                            .contactEmail
                                                                            ? styles.materialCheckbox
                                                                            : null
                                                                    }
                                                                />
                                                            }
                                                            label="Email"
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div
                                                    className={
                                                        styles.contactdiv
                                                    }
                                                >
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={
                                                                        this
                                                                            .state
                                                                            .userProfile
                                                                            .profile
                                                                            .contactPhone
                                                                    }
                                                                    color="primary"
                                                                    onChange={() =>
                                                                        this.toggleContactPreferencesState(
                                                                            'contactPhone'
                                                                        )
                                                                    }
                                                                    value="Phone"
                                                                    className={
                                                                        this
                                                                            .state
                                                                            .userProfile
                                                                            .profile
                                                                            .contactPhone
                                                                            ? styles.materialCheckbox
                                                                            : null
                                                                    }
                                                                />
                                                            }
                                                            label="Phone"
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div
                                                    className={
                                                        styles.contactdiv
                                                    }
                                                >
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={
                                                                        this
                                                                            .state
                                                                            .userProfile
                                                                            .profile
                                                                            .contactSms
                                                                    }
                                                                    color="primary"
                                                                    onChange={() =>
                                                                        this.toggleContactPreferencesState(
                                                                            'contactSms'
                                                                        )
                                                                    }
                                                                    value="SMS"
                                                                    className={
                                                                        this
                                                                            .state
                                                                            .userProfile
                                                                            .profile
                                                                            .contactSms
                                                                            ? styles.materialCheckbox
                                                                            : null
                                                                    }
                                                                />
                                                            }
                                                            label="SMS"
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div
                                                    className={
                                                        styles.contactdiv
                                                    }
                                                >
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={
                                                                        this
                                                                            .state
                                                                            .userProfile
                                                                            .profile
                                                                            .contactPost
                                                                    }
                                                                    color="primary"
                                                                    onChange={() =>
                                                                        this.toggleContactPreferencesState(
                                                                            'contactPost'
                                                                        )
                                                                    }
                                                                    value="Post"
                                                                    className={
                                                                        this
                                                                            .state
                                                                            .userProfile
                                                                            .profile
                                                                            .contactPost
                                                                            ? styles.materialCheckbox
                                                                            : null
                                                                    }
                                                                />
                                                            }
                                                            label="Post"
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col className={styles.details}>
                                    <h2 className={styles.sectionHeading}>
                                        Personal Details
                                    </h2>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="profile.title"
                                        >
                                            Title
                                        </Label>
                                        <span className={styles.dropdown}>
                                            <SelectDropdown
                                                selectedId={
                                                    this.state.userProfile
                                                        .profile.title
                                                }
                                                data={[
                                                    {
                                                        label: 'Mr',
                                                        value: 'Mr',
                                                    },
                                                    {
                                                        label: 'Mrs',
                                                        value: 'Mrs',
                                                    },
                                                    {
                                                        label: 'Miss',
                                                        value: 'Miss',
                                                    },
                                                    {
                                                        label: 'Mx',
                                                        value: 'Mx',
                                                    },
                                                    {
                                                        label: 'Dr',
                                                        value: 'Dr',
                                                    },
                                                    {
                                                        label: 'Prof',
                                                        value: 'Prof',
                                                    },
                                                    {
                                                        label: 'Rev',
                                                        value: 'Rev',
                                                    },
                                                ]}
                                                placeholder={
                                                    this.props.profileType ==
                                                    ProfileType.Contact
                                                        ? 'Contact Title'
                                                        : 'Title'
                                                }
                                                onSelect={(id: IOptions) =>
                                                    this.changeDropdown(
                                                        id,
                                                        'title'
                                                    )
                                                }
                                            />
                                        </span>
                                    </FormGroup>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="profile.firstname"
                                        >
                                            First name
                                        </Label>
                                        <Field
                                            className={styles.input}
                                            type="text"
                                            name="forename"
                                            placeholder="First name"
                                            id={styles.smallInput}
                                            onChange={this.handleProfileChange}
                                        />
                                    </FormGroup>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="profile.lastname"
                                        >
                                            Surname
                                        </Label>
                                        <Field
                                            className={styles.input}
                                            type="text"
                                            name="surname"
                                            placeholder="Enter surname"
                                            id={styles.smallInput}
                                            onChange={this.handleProfileChange}
                                        />
                                    </FormGroup>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="profile.email"
                                        >
                                            Email
                                        </Label>
                                        <Field
                                            disabled={this.isUserProfile()}
                                            className={styles.input}
                                            type="email"
                                            name="email"
                                            placeholder="Enter email"
                                            id={styles.smallInput}
                                        />
                                    </FormGroup>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="profile.contact"
                                        >
                                            Contact Number
                                        </Label>
                                        <Field
                                            className={styles.input}
                                            type="tel"
                                            name="contactNumber"
                                            placeholder="Enter contact number"
                                            id={styles.smallInput}
                                        />
                                    </FormGroup>
                                    <div className={styles.errorMessage}>
                                        <ErrorMessage
                                            className={styles.errorMessage}
                                            name="contactNumber"
                                        />
                                    </div>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="profile.contact"
                                        >
                                            Gender
                                        </Label>
                                        <SelectDropdown
                                            selectedId={
                                                this.state.userProfile.profile
                                                    .gender
                                            }
                                            data={[
                                                {
                                                    label: 'Female',
                                                    value: 'Female',
                                                },
                                                {
                                                    label: 'Male',
                                                    value: 'Male',
                                                },
                                                {
                                                    label: 'Non-binary/Third Gender',
                                                    value: 'Non-binary/Third Gender',
                                                },
                                                {
                                                    label: 'Prefer not to say',
                                                    value: 'Prefer not to say',
                                                },
                                            ]}
                                            placeholder="Other"
                                            onSelect={(id: IOptions) =>
                                                this.changeDropdown(
                                                    id,
                                                    'gender'
                                                )
                                            }
                                        />
                                    </FormGroup>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="profile.contact"
                                        >
                                            Citizen
                                        </Label>
                                        <SelectDropdown
                                            selectedId={
                                                this.state.userProfile.profile
                                                    .citizen
                                            }
                                            data={[
                                                {
                                                    label: 'UK Citizen',
                                                    value: 'UK Citizen',
                                                },
                                                {
                                                    label: 'EEA Citizen',
                                                    value: 'EEA Citizen',
                                                },
                                                {
                                                    label: 'Other',
                                                    value: 'Other',
                                                },
                                            ]}
                                            placeholder="Other"
                                            onSelect={(id: IOptions) =>
                                                this.changeDropdown(
                                                    id,
                                                    'citizen'
                                                )
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                {/* Address Details */}
                                <Col className={styles.address}>
                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="profile.addressline1"
                                        ></Label>
                                        <AddressFinder
                                            label="Address"
                                            selectAddressCallback={
                                                this.selectAddressCallback
                                            }
                                            resetAddress={this.resetAddress}
                                        />
                                    </FormGroup>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="profile.addressline1"
                                        >
                                            Address Line 1
                                        </Label>
                                        <Field
                                            className={styles.input}
                                            type="text"
                                            name="address.line1"
                                            placeholder="Enter first line of address"
                                        />
                                    </FormGroup>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="profile.addressline2"
                                        >
                                            Address Line 2
                                        </Label>
                                        <Field
                                            className={styles.input}
                                            type="text"
                                            name="address.line2"
                                            placeholder="Enter second line of address"
                                        />
                                    </FormGroup>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="profile.city"
                                        >
                                            Town/City
                                        </Label>
                                        <Field
                                            className={styles.input}
                                            type="text"
                                            name="address.town"
                                            placeholder="Enter city/town"
                                        />
                                    </FormGroup>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="profile.country"
                                        >
                                            County
                                        </Label>
                                        <Field
                                            className={styles.input}
                                            type="text"
                                            name="address.county"
                                            placeholder="County"
                                        />
                                    </FormGroup>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="profile.postcode"
                                        >
                                            Post Code
                                        </Label>
                                        <Field
                                            className={styles.input}
                                            type="text"
                                            name="address.postcode"
                                            placeholder="Postcode"
                                        />
                                    </FormGroup>

                                    <FormGroup className={styles.formGroup}>
                                        <Label
                                            className={styles.label}
                                            for="profile.country"
                                        >
                                            Country
                                        </Label>
                                        <Field
                                            className={styles.input}
                                            type="text"
                                            name="address.country"
                                            placeholder="Country"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className={styles.emergencyTitle}>
                                        Emergency Contact Details
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            <FormGroup>
                                                <div className={styles.label}>
                                                    Emergency Contact name
                                                </div>
                                                <Field
                                                    className={styles.input}
                                                    type="text"
                                                    name="emergencyContactName"
                                                    placeholder="Enter name..."
                                                    id={styles.smallInput}
                                                />
                                            </FormGroup>
                                            <div
                                                className={styles.errorMessage}
                                            >
                                                <ErrorMessage
                                                    className={
                                                        styles.errorMessage
                                                    }
                                                    name="emergencyName"
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.column}>
                                            <FormGroup>
                                                <div className={styles.label}>
                                                    Emergency Contact phone
                                                </div>
                                                <Field
                                                    className={styles.input}
                                                    type="tel"
                                                    name="emergencyContactPhone"
                                                    placeholder="Enter number..."
                                                    id={styles.smallInput}
                                                />
                                                <div
                                                    className={
                                                        styles.errorMessage
                                                    }
                                                >
                                                    <ErrorMessage
                                                        className={
                                                            styles.errorMessage
                                                        }
                                                        name="emergencyName"
                                                    />
                                                </div>
                                            </FormGroup>
                                        </div>

                                        <div className={styles.column}>
                                            <FormGroup>
                                                <div className={styles.label}>
                                                    Emergency Contact email
                                                </div>
                                                <Field
                                                    className={styles.input}
                                                    type="tel"
                                                    name="emergencyContactEmail"
                                                    placeholder="Enter email..."
                                                    id={styles.smallInput}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className={styles.buttonBar}>
                                <StickyComponent>
                                    <Buttons
                                        buttons={[
                                            {
                                                displayType: 'cancel',
                                                elementType: 'button',
                                                onClick: () =>
                                                    this.cancelForm(
                                                        props.values
                                                    ),
                                            },
                                            {
                                                displayType: 'submit',
                                                elementType: 'button',
                                                onClick: () =>
                                                    props.submitForm(),
                                            },
                                        ]}
                                    />
                                </StickyComponent>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}

export default withRouter(UserProfilePage)
