import React from "react";
import SFDropdown from "..";
import ActionButton from "../../ActionButton";
import { withRouter } from "react-router";
import styles from "./SFChatDropdown.module.scss";

interface IProps {
  chatSessionId: string;
  toggle?: JSX.Element;

  history: any;
  location: any;
  match: any;
 
  onDelete?(): void;
}

class SFChatDropdown extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <SFDropdown
        customCssClassName={styles.syncfusionDropdown}
        customToggle={
          this.props.toggle ? this.props.toggle : <ActionButton />
        }
        onSelect={(args) => {
          if (args.item.text === "Delete Chat") {
            this.props.onDelete()
          }
        }}
        items={[
          {
            iconCss: styles.iconDelete,
            text: "Delete Chat",
          }
        ]}
      />
    );
  }
}

export default withRouter(SFChatDropdown);