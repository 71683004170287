import { IAddress } from "../types/PropertyService/IPropertyService";

export function ellipsis(text: string, length: number): string {
    let short = text.substring(0, length);
    return text.length > length ? `${short}...` : text;
}

export function toUKPostcode(input: string) {
    var parts = input.match(/^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/);

    if (parts) {
        return parts.join(' ');
    }

    return input;
}

export function formatPostcode(input: string): string {
    if (input.length < 6) {
        return input;
    }

    var parts = input.match(/^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/);
    parts.shift();
    return parts.join(' ');
}

export function toMegabytes(input: number) {
    let result = input / Math.pow(1024, 2);
    return result.toFixed(2);
}

export function toBytes(input: number) {
    let result = input * Math.pow(1024, 2);
    return result.toFixed(2);
}

export function capitaliseFirstLetter(input: string): string {
    if (input.length > 0) {
        return input.charAt(0).toUpperCase() + input.slice(1);
    }

    return input;
}

export function capitaliseFirstLetterOfEachWord(sentence: string): string {
    if (sentence.length > 0) {
        let arr = sentence.split(" ");
        let result = "";
        arr.map(x => {
            x = capitaliseFirstLetter(x);
            result += (x + " ");
        });

        return result;
    }

    return sentence;
}

export function formatAddress(address: IAddress): string {
    return `${address.line1 && address.line1 + ","}${address.line2 && address.line2 + ","}${(address.street_Name && !address.line1.includes(address.street_Name) && !address.line2.includes(address.street_Name)) && address.street_Name + ","}${address.town && address.town + ","}${address.county != null ? address.county + "," : ""}${address.postcode && address.postcode}`
}

export function getOrdinalNum(n: number): string {
    return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
}