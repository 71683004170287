import React, { Component } from "react";
import { withRouter, Route, Redirect } from "react-router-dom";

// Import Styles
import styles from "./IssueDetail.module.scss";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import TabbedNavigationContainer from "../../atoms/TabbedNavigationContainer";
import SimpleRouterTabs from "../../atoms/SimpleRouterTabs";
import contactDocuments from "../../../images/icons/contact_documents.svg";
import contactDetails from "../../../images/icons/contact_details.svg";
import contactAudit from "../../../images/icons/icon_timeline.svg";
import AuditPage from "../PlatformManagementPage/AuditPage";
import IssueSummary from "./IssueSummary";
import DocumentsPage from "../DocumentsPage";
import NestedSimpleRouterTabs from "../../atoms/NestedSimpleRouterTabs";
import { connect } from 'react-redux';
import { IIssueItem } from "../../../types/IssueService";
import { IDocumentUploadOptions } from "../../molecules/AddDocumentModal";
import { addURLParameter } from "../../../helpers/locationHelpers";
import RotatingLoader from "../../atoms/RotatingLoader";
import { clearIssueEdit, getIssueEdit } from "../../../actions/issueActions";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../../reducers/rootReducer";
import { updateAlertMessage } from "../../../helpers/alertHelpers";
import ChatBox from "../../atoms/ChatBox";

interface IWithRouterProps {
  history: any;
  location: any;
  match: any;
  isNested?: boolean;

  loading: boolean;
  error: string;
  issues: Array<IIssueItem>;
  viewing_issue_edit: IIssueItem;

  getIssueEdit(issueId: string): void;
  clearIssueEdit(): void;
}

class IssueDetail extends Component<IWithRouterProps> {
  constructor(props: any) {
    super(props);

    this.onClickAddDocument = this.onClickAddDocument.bind(this);
  }

  componentDidMount(): void {
    if (!this.props.viewing_issue_edit || this.props.viewing_issue_edit.issueId != this.props.match.params.issueId) {
      this.props.getIssueEdit(this.props.match.params.issueId);
    }
  }

  componentWillUnmount(): void {
    this.props.clearIssueEdit();
  }

  componentWillReceiveProps(newProps: IWithRouterProps): void {
    if (newProps.match.params.issueId != this.props.match.params.issueId) {
      this.props.getIssueEdit(newProps.match.params.issueId);
    }
  }

  onClickAddDocument(): void {
    let index = this.props.issues.findIndex(x => x.issueId == this.props.match.params.issueId);
    if (index > -1) {
      let issue = this.props.issues[index];

      let options: IDocumentUploadOptions = {
        issueId: issue.issueId,
        issueName: issue.title,
        propertyId: issue.propertyId != "00000000-0000-0000-0000-000000000000" ? issue.propertyId : '',
        propertyName: issue.propertyName,
        documentTypeId: "7EB10CE6-7DF4-4C4F-BB33-C0565D80B4D4"
      }

      let query = "uploadDocumentOptions=" + JSON.stringify(options);

      let search = addURLParameter(this.props.location.search, query);

      this.props.history.push({
        search
      });
    }
  }

  render() {
    if (this.props.error) {
      updateAlertMessage(this.props.error, true, 0, false);
    }

    if (!this.props.error) {
      updateAlertMessage("", false);
    }

    if (this.props.loading) {
      return <RotatingLoader loading text='Loading issue...' />
    }

    if (!this.props.viewing_issue_edit) {
      return null;
    }

    return (
      <Row className={styles.container}>
        <div className={styles.navigation}>
          {
            this.props.isNested ?
              <TabbedNavigationContainer fullWidth isSubMenu={this.props.isNested}>
                <Col className={styles.tabs}>
                  <NestedSimpleRouterTabs
                    routes={[
                      {
                        path: this.props.match.url + "/issue-summary",
                        text: "Summary",
                        mobileText: "Summary"
                      },
                      {
                        path: this.props.match.url + "/issue-chat",
                        text: "Chat",
                        mobileText: "Chat"
                      },
                      {
                        path: this.props.match.url + "/documents",
                        text: "Docs",
                        mobileText: "Docs"
                      },
                      {
                        path: this.props.match.url + "/timeline",
                        text: "Timeline",
                        mobileText: "Timeline"
                      }
                    ]}
                  />
                </Col>
              </TabbedNavigationContainer> :
              <TabbedNavigationContainer fullWidth>
                <Col className={styles.tabs}>
                  <SimpleRouterTabs
                    routes={[
                      {
                        path: this.props.match.url + "/issue-summary",
                        text: "Summary",
                        icon: contactDetails,
                        mobileText: "Summary"
                      },
                      {
                        path: this.props.match.url + "/issue-chat",
                        text: "Chat",
                        icon: contactDetails,
                        mobileText: "Chat"
                      },
                      {
                        path: this.props.match.url + "/documents",
                        text: "Docs",
                        icon: contactDocuments,
                        mobileText: "Docs"
                      },
                      {
                        path: this.props.match.url + "/timeline",
                        text: "Timeline",
                        icon: contactAudit,
                        mobileText: "Timeline"
                      }
                    ]}
                  />
                </Col>
              </TabbedNavigationContainer>
          }
        </div>

        <Col className={styles.routedPage}>
          <div className={styles.content}>
            <Route
              path={`${this.props.match.url}`}
              exact
              render={() => <Redirect to={`${this.props.match.url}/issue-summary`} />}
            />

            <Route
              path={`${this.props.match.path}/issue-summary`}
              render={() => (
                <IssueSummary />
              )}
            />

            <Route
              path={`${this.props.match.path}/issue-chat`}
              render={() => (
                <ChatBox
                    //isNested
                    //fullChatLink
                    options={{
                        issueId: this.props.match.params.issueId,
                    }}
                />
              )}
            />

            <Route
              path={`${this.props.match.path}/documents`}
              render={() => (
                <div className={styles.documentsPage}>
                  <DocumentsPage
                    onAddDocumentClick={this.onClickAddDocument}
                    issueId={this.props.match.params.issueId} /></div>
              )}
            />

            <Route
              path={`${this.props.match.path}/timeline`}
              render={() => (
                <div className={styles.ds}><AuditPage issueId={this.props.match.params.issueId} hideDropdown /></div>
              )}
            />
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  issues: state.issue.issue_list,
  viewing_issue_edit: state.issue.viewing_issue_edit,
  loading: state.issue.loading,
  error: state.issue.error
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      clearIssueEdit,
      getIssueEdit
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IssueDetail));