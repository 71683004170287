import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { IInspectionsResultDto, ISection } from '../../../types/Dynamic/types'
import styles from './ConductInspectionForm.module.scss'
import ConductSection from './ConductSection'
import lodashObject from 'lodash/fp'
import cloneDeep from 'lodash/cloneDeep'
import OutstandingChangesModal from '../../atoms/OutstandingChangesModal'
import { IReactRouterProps } from '../../../types'
import { withRouter } from 'react-router'
import { IConductInspectionOptions } from '../../../types/ConductInspection'
import { IInspectionDto } from '../../../types/Inspection'
import {
    insertInspection,
    updateInspection,
} from '../../../actions/conductInspectionActions'
import { useDispatch } from 'react-redux'
import ConductInspectionContext from '../../../types/Dynamic/conductInspectionContext'
import StickyComponent from '../../atoms/StickyComponent'
import Buttons from '../../atoms/Buttons'

let buttonsRef

interface IProps {
    options: IConductInspectionOptions
    inspectionDto: IInspectionDto
    inspectionResult: IInspectionsResultDto
}

type Props = IProps & IReactRouterProps

const ConductInspectionForm = ({
    history,
    options,
    inspectionDto,
    inspectionResult,
}: Props) => {
    buttonsRef = React.createRef()
    const [inView, setInView] = useState(false)
    const [initialInspection, setInitialInspection] = useState(null)
    const [changesModalOpen, setChangesModalOpen] = useState(false)
    const [isCompleted, setIsCompleted] = useState(
        inspectionResult ? inspectionResult.isCompleted : false
    )
    const dispatch = useDispatch()

    const { validations, validateAll } = useContext(ConductInspectionContext)

    useEffect(() => {
        setInitialInspection(cloneDeep(inspectionResult.objectResult))
    }, [])

    const onSubmit = (section: ISection) => {
        if (inspectionResult.inspectionResultId) {
            dispatch(
                updateInspection({
                    inspectionResultId: inspectionResult.inspectionResultId,
                    jsonResult: JSON.stringify(section),
                    templateId: inspectionResult.templateId,
                    inspectionId: inspectionDto.inspectionId,
                    isCompleted,
                })
            )
        } else {
            dispatch(
                insertInspection({
                    jsonResult: JSON.stringify(section),
                    templateId: inspectionResult.templateId,
                    inspectionId: inspectionDto.inspectionId,
                    isCompleted,
                })
            )
        }

        if (isCompleted) {
            history.push(
                (options && options.redirectUrl) ||
                    history.push(`/dashboard/inspections`)
            )
        }

        return
    }

    const onCloseChangesModal = (close: boolean) => {
        setChangesModalOpen(false)

        if (close) {
            history.push('/dashboard/inspections')
        }
    }

    const onCancel = () => {
        if (hasChanges()) {
            setChangesModalOpen(true)
            return
        }

        if (options && options.redirectUrl) {
            history.push(options.redirectUrl)
            return
        }

        history.push('/dashboard/inspections')
    }

    const hasChanges = () => {
        if (
            lodashObject.isEqual(
                inspectionResult.objectResult,
                initialInspection
            )
        ) {
            return false
        }

        return true
    }

    return (
        <div className={styles.conductInspectionForm}>
            <OutstandingChangesModal
                open={changesModalOpen}
                onCloseChangesModal={onCloseChangesModal}
            />

            <Formik
                initialValues={inspectionResult.objectResult}
                enableReinitialize={true}
                onSubmit={async (values: any) => {
                    const { isValid } = await validateAll()
                    if (isValid) {
                        onSubmit(values)
                    }
                }}
            >
                {(props) => (
                    <Form>
                        <ConductSection
                            sectionIndex={0}
                            section={props.values}
                            setFieldValue={props.setFieldValue}
                        />

                        <StickyComponent>
                            <div className={styles.buttonContainer}>
                                <div className={styles.buttons}>
                                    <Buttons
                                        buttons={[
                                            {
                                                displayType: 'cancel',
                                                elementType: 'button',
                                                onClick: () => onCancel(),
                                            },
                                            {
                                                displayType: 'submit',
                                                elementType: 'button',
                                                disabled:
                                                    validations.length > 0,
                                                onClick: () =>
                                                    props.submitForm(),
                                            },
                                        ]}
                                    />
                                </div>
                                <div className={styles.checkbox}>
                                    <Checkbox
                                        checked={isCompleted}
                                        color="primary"
                                        onChange={() =>
                                            setIsCompleted(!isCompleted)
                                        }
                                    />
                                    Completed
                                </div>
                            </div>
                        </StickyComponent>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default withRouter(ConductInspectionForm)
