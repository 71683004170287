import React from 'react';
import { Redirect, Route, withRouter } from 'react-router';
import { IReactRouterProps } from '../../../types';
import TeamsPage from '../TeamsPage';

const SettingsPage = ({ match }: IReactRouterProps) => {
    return <div>
        <Route
            path={`${match.url}`}
            exact
            render={() => (
                <Redirect to={`${match.url}/team`} />
            )}
        />

        <Route
            path={`${match.path}/team`}
            render={(props) => <TeamsPage />}
        />
    </div>
}

export default withRouter(SettingsPage);