import React from "react";
import Currency from "../../../../../atoms/Currency";
import styles from "./TransactionAmount.module.scss";

interface IProps {
  value?: number;
  isPositive?: boolean;
  isNegative?: boolean;
}

const TransactionAmount = ({ isPositive, value }: IProps) => {
  return (
    <span className={`${styles.amount} ${isPositive ? styles.income : styles.expense}`}><Currency decimalCount={2} prefix="£" value={value ? value : 0} /></span>
  );
}

export default TransactionAmount;
