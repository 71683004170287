import React from 'react'
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import { canAccessPropertyDetails } from '../../../helpers/permissionHelpers';
import { ITenancySummary } from '../../../types/Tenancy'
import infoIcon from '../../../images/icons/ic_info_24px.png'
import styles from "./TenancyRemaining.module.scss";

interface props {
    tenancy: ITenancySummary
}

export const TenancyRemaining = ({tenancy} : props) => {
    const history = useHistory();

    const getTimeRemainingTenancy = (): string => {
        if(tenancy.endDate != null){
            return tenancy.remainingDurationOfTenancy;
        }
        else{
            return "No Tenancy";
        }
    }

    return (
        <div>
            <div className={styles.mainTextRow}
                onClick={
                    canAccessPropertyDetails() && getTimeRemainingTenancy() != "No Tenancy"
                    ? () => history.push(`/dashboard/tenancy/view/${tenancy.tenancyId}`)
                    : null
                }
            >
                <div className={styles.value}>
                    {getTimeRemainingTenancy()}    
                </div>
            </div>
            <span>
                {
                    (getTimeRemainingTenancy() != "Rolling" && getTimeRemainingTenancy() != "Expired" && getTimeRemainingTenancy() != "Future" && getTimeRemainingTenancy() != "No Tenancy")
                    &&
                    <span className={styles.leftText}>left</span>
                }
                {
                    tenancy.tenancyDetail
                    &&
                    <span className={styles.tooltip}>
                        <Tooltip
                            theme="light"
                            html={<p dangerouslySetInnerHTML={{__html: tenancy.tenancyDetail}} />}
                            position="right"
                            trigger="mouseenter"
                            arrow
                            interactive={true}
                        >
                            <img src={infoIcon} alt="Info" />
                        </Tooltip>
                    </span>
                } 
            </span>
        </div>
    )
}