
import { useEffect, useState } from "react";
import { getPropertyCards } from "../services/DashboardService";

export const useFetchPropertyCardsData = () => {
    const [dataRetrieved, setDataRetrieved] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async() => {
        setDataRetrieved(false);

        let resp = await getPropertyCards();
        if (resp && resp.status == 200 && resp.data) {
            setData(resp.data);
            setDataRetrieved(true);
        }
    }

    return { dataRetrieved, data };
}