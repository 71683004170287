import React from "react";
import SFDropdown from "..";
import { withRouter } from "react-router";
import styles from "./SFInspectionDropdown.module.scss";
import { addDocumentClicked, onAddIssueClickGeneric } from "./_helpers";
import { goToRoute } from "../../../../history";
import { addURLParameter } from "../../../../helpers/locationHelpers";
import { toggleDeleteInspectionPopup } from "../../../../helpers/sidebarHelpers";
import { IConductInspectionOptions } from "../../../../types/ConductInspection";
import { IDocumentUploadOptions } from "../../../molecules/AddDocumentModal";

interface IProps {
  propertyId: string;
  propertyName: string;

  inspectionId: number;

  toggle?: JSX.Element;
  history: any;
  location: any;
  match: any;

  updateRedirectUrl?: string;
}

function onDeleteRows(
  unitIds: number[],
  location: any,
  history: any,
  deleteInspectionRedirectUrl?: string
): void {
  let test = JSON.stringify(unitIds);

  let q = "deleteInspection=" + test;

  if (deleteInspectionRedirectUrl) {
    q += "&deleteInspectionRedirectUrl=" + deleteInspectionRedirectUrl;
  }

  if (location != null && location != undefined) {
    let query = addURLParameter(
      location.search,
      q
    );

    history.push({
      search: query
    });
  }

  toggleDeleteInspectionPopup(true);
}

class SFInspectionDropdown extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <SFDropdown
        loadedBeforeRender
        customToggle={this.props.toggle}
        customCssClassName={styles.syncfusionDropdown}
        onSelect={(args) => {
          if (args.item.text == "Edit") {
            let q = ''
            if (this.props.match.params.propertyId) {
              q = `?referer=property`;
            }

            goToRoute(`/dashboard/inspection/view/${this.props.inspectionId}${q}`);
          }
          if (args.item.text == "Conduct inspection") {
            let obj: IConductInspectionOptions = {
              redirectUrl: this.props.location.pathname
            }
            let params = JSON.stringify(obj);
            goToRoute(`/dashboard/inspection/view/${this.props.inspectionId}/conduct${params ? `?conductInspectionOptions=${params}` : ''}`);
          }
          if (args.item.text == "Add Document") {
            let obj: IDocumentUploadOptions = {
              inspectionId: this.props.inspectionId,
              documentTypeId: "85ba3702-2186-4acc-b489-6a80e692894f"
            }

            if (this.props.propertyId) {
              obj.propertyId = this.props.propertyId;
            }
            addDocumentClicked(obj, "uploadDocumentOptions");
          }
          if (args.item.text == "Add Issue") {
            onAddIssueClickGeneric({
              propertyId: this.props.propertyId,
              propertyName: this.props.propertyName,
              inspectionId: this.props.inspectionId
            }, "issueOptions");
          }
          if (args.item.text == "Go to Documents") {
            goToRoute(`/dashboard/inspection/view/${this.props.inspectionId}/documents`);
          }
          if (args.item.text == "Go to Issues") {
            goToRoute(`/dashboard/inspection/view/${this.props.inspectionId}/issues`);
          }
          if (args.item.text == "Delete") {
            onDeleteRows([this.props.inspectionId], this.props.location, this.props.history, this.props.updateRedirectUrl);
          }
        }}
        items={[
          {
            iconCss: styles.subIconEdit,
            text: "Edit"
          },
          {
            iconCss: styles.subIconEdit,
            text: "Conduct inspection"
          },
          {
            separator: true,
          },
          {
            iconCss: styles.subIconAddDocument,
            text: "Add Document"
          },
          {
            iconCss: styles.subIconAddIssue,
            text: "Add Issue"
          },
          {
            separator: true,
          },
          {
            iconCss: styles.subIconGoToDocuments,
            text: "Go to Documents"
          },
          {
            iconCss: styles.iconGoToIssues,
            text: "Go to Issues"
          },
          {
            iconCss: styles.subIconDelete,
            text: "Delete"
          }
        ]}
      />
    );
  }
}

export default withRouter(SFInspectionDropdown);