import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import IssueDetail from "../IssueDetail";
import IssueList from "./IssueList";
import SplitListPage from "../SplitListPage";
import { toggleIssueListCollapse } from "../../../helpers/sidebarHelpers";
import { connect } from "react-redux";
import styles from "./IssueListPage.module.scss";
import { IIssueItem } from "../../../types/IssueService";
import ElevioArticleEmbedWrapper from "../../atoms/ElevioArticleEmbedWrapper";
import { elevioIssueArticleId } from "../../../configuration/elevioConfig";
import IssueEmptyState from "../EmptyState/IssueEmptyState/IssueEmptyState.component";

interface IProps {
  history: any;
  match: any;
  location: any;
  
  contactId?: string;
  tenancyId?: string;
  propertyId?: string;
  unitId?: string;
  propertyName?: string;
  contactName?: string;
  inspectionId?: number;
  assetId?: number;

  onAddIssue?(): void;
  isNested?: boolean;

  issue_list_collapsed?: boolean;
  issues?: Array<IIssueItem>;
}

export interface ITenancyOptions { }

class IssueListPage extends Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  componentWillReceiveProps(newProps: IProps): void {
    //if (!newProps.match.params.issueId && this.props.issue_list_collapsed) {
      //toggleIssueListCollapse(false);
    //}
  }

  render() {
    return (
      <SplitListPage
        toggleCollapse={() => toggleIssueListCollapse(!this.props.issue_list_collapsed)}
        collapsed={this.props.issue_list_collapsed}
        listComponent={<IssueList
          onAddIssue={this.props.onAddIssue}
          contactId={this.props.contactId}
          propertyId={this.props.propertyId}
          inspectionId={this.props.inspectionId}
          unitId={this.props.unitId}
          assetId={this.props.assetId} />}
          detailComponent={<div className={styles.content}>
          {
            (this.props.issues.length == 0 && !this.props.match.params.issueId) ?
            <IssueEmptyState onAddIssue={this.props.onAddIssue} />
            :
            this.props.match.params.issueId ? <IssueDetail isNested={this.props.isNested} /> : <div>Please select an issue</div>
          }
        </div>
        }
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  issues: state.issue.issue_list,
  issue_list_collapsed: state.app.sidebars.issue_list_collapsed
});

export default withRouter<IProps,any>(connect(
  mapStateToProps,
)(IssueListPage));