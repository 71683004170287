import React, { useEffect, useRef, useState } from 'react' 
import lodashObject from 'lodash/fp'
import { IOptions } from "../SelectDropdown";
import { QuickViewType } from "../../../types/QuickView";
import Select, { components } from 'react-select'
import searchIcon from '../../../images/icons/ic_search_24px.png'
import styles from "./SelectDropdownV2.module.scss";
import * as S from "./SelectDropdownV2.styles";
import GenericPopup from '../GenericPopup';
import { generateId } from '../../../helpers/randomHelpers';

interface IProps {
    selectedId: string;
    selectedName: string;
    onSelect(option: IOptions): void;
    onClear?(): void;
    onSearch(search?: string): void;
    disableClear?: boolean;
    propertyId?: string;
    data: any[];
    inputPlaceHolder: string;
    quickView?: QuickViewType;
    isSearchable?: boolean;
    addNewItemControl?: any;
    menuPlacement?: string;
    tabSelectsValue?: boolean;
    showSearchButton?: boolean;
    searchButtonOnClick?(searchValue: string): void;
    focussed?: boolean;
    addNewItemButtonControl?: any;
}

const SelectDropdownV2 = (props: IProps) => {

  const [data, setData] = useState<any[]>([]);
  const [selectedValue, setSelectedValue] = useState<IOptions>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const selectRef = useRef<any>(null);

  useEffect(() =>{
    if(!lodashObject.isEqual(data, props.data)){
      setData(props.data);
    }

    if(props.focussed){
      selectRef.current.focus();
    }
      
  }, [props.data])

  useEffect(() =>{
      if(props.selectedName){
        setSelectedValue({value: props.selectedId, label: props.selectedName})
        setSearchValue('');
      }
    }, [props.selectedName])

  const handleChange = (selection, action) => {
    if(action.action == 'clear'){
      setSelectedValue(null);
      props.onSelect({value: null, label: null});
      props.onClear && props.onClear();
    }else{
      setSelectedValue({value: selection && selection.value, label: selection && selection.label});
      props.onSelect({value: selection && selection.value, label: selection && selection.label});
      setSearchValue('');
    }
  };
    
  const formatOptionLabel = ({ value, label }) => (
    <div style={{ display: "flex" }}>
      <div>{label}</div>
      <div style={{ marginLeft: "10px" }}>
      {
            props.quickView != null && value 
              //&& (props.selectedId || props.propertyId) 
              //&& (props.selectedId == value || props.propertyId == value) 
              ?
              <div className={styles.inputContent}>
                <GenericPopup
                  identifier={generateId(10)}
                  options={{
                    contactId: value,
                    propertyId: value,
                    type: props.quickView
                  }}
                />
              </div>
              :
              ""
          }
      </div>
    </div>
  );

  const SelectMenuButton = (selectProps) => {
    return (
      <components.MenuList  {...selectProps}>
          {selectProps.children}
          {props.addNewItemControl}
      </components.MenuList >
      );
  }

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <img height={20} src={searchIcon}/>
      </components.DropdownIndicator>
    );
  };

  const customFilter = (option, searchText) => {
    // Have to add this to force the search not use internal behaviour which only searches the content of the items
    // We need to show the results returned from the server, i.e. searches all fields, e.g. all address fields
    return true;
  };

  const desktopWidth = 768;
  const selectStyles = {
    menuList: styles => {
      return {
        ...styles,
        maxHeight: ((window.innerWidth < desktopWidth) || (props.isSearchable != null && props.isSearchable == true)) ? 168 : 420,
      };
    }
  };

  return (
    <S.SearchBarDiv>
        <S.SelectDiv searchbuttonVisible={props.showSearchButton} addbuttonVisible={props.addNewItemButtonControl}>
          <Select
              ref={selectRef}
              id="dropdown"
              classNamePrefix="rc"
              className={`${styles.dropdown}`}
              onFocus={() =>props.onSearch(searchValue)}
              options={data.slice(0, 10)} 
              value={selectedValue ? {value: selectedValue.value, label: selectedValue.label} : null}
              inputValue={searchValue}
              components={{ MenuList: SelectMenuButton, DropdownIndicator: DropdownIndicator }} 
              formatOptionLabel={formatOptionLabel}
              isClearable={true}
              onChange={handleChange}
              onInputChange={(input, event) => {
                if(event.action == "input-change"){
                  setSearchValue(input);
                  props.onSearch(input)
                }
              }}
              placeholder={props.inputPlaceHolder}
              isSearchable={((window.innerWidth >= desktopWidth) || (props.isSearchable != null && props.isSearchable == true)) ? true : false}
              //onMenuOpen={() => window.scrollTo(0, document.body.scrollHeight)}
              menuPlacement={props.menuPlacement ? props.menuPlacement : "bottom"}
              styles={selectStyles}
              tabSelectsValue={props.tabSelectsValue ? props.tabSelectsValue : true}
              filterOption={(customFilter)}
          />
        </S.SelectDiv>
        
        <S.SearchButtonDiv buttonVisible={props.showSearchButton}>
          <S.SearchButton onClick={() => props.searchButtonOnClick(searchValue)}>Search</S.SearchButton>
        </S.SearchButtonDiv>

        <S.AddButtonDiv buttonVisible={props.addNewItemButtonControl}>
            {
              props.addNewItemButtonControl
            }
        </S.AddButtonDiv>
      </S.SearchBarDiv>
    );
}

export default SelectDropdownV2;