import React from "react";
import { useHistory } from "react-router-dom";
import { Button, RCCurrencyInput } from "@rentchief/components";
import { Formik, FormikProps } from "formik";
import * as S from "./CalculatorPart.styles";

const CalculatorPart = () => {
    let history = useHistory();

    const onSubmit = ({ purchasePrice, monthlyRentalAmount }) => {
        let path = (purchasePrice != "" && purchasePrice != null) 
        ? `/dashboard/research/deal-calculator?purchasePrice=${purchasePrice}&monthlyRentalAmount=${monthlyRentalAmount}` 
        : `/dashboard/research/deal-calculator?purchasePrice=${200000}`
      history.push(path);
    };
  
    const onPropertyPriceChange = (propertyPrice: number) => {
      if (propertyPrice) {
        return propertyPrice * 0.004;
      } else {
        return 0;
      }
    };

  return (
    <Formik
      initialValues={{ purchasePrice: null, monthlyRentalAmount: null }}
      onSubmit={(v) => onSubmit(v)}
    >
      {(props: FormikProps<{
          purchasePrice: number;
          monthlyRentalAmount: number;
        }>) => (
        <S.CalculatorDiv>
            <S.InputPurchasePriceDiv>
                <S.Label>Purchase Price</S.Label>
                <RCCurrencyInput
                    prefix={"£"}
                    id="purchasePrice"
                    value={props.values.purchasePrice}
                    handleChange={(e) => {
                        let monthlyRental = onPropertyPriceChange(e);
                        props.setFieldValue("purchasePrice", e);
                        props.setFieldValue("monthlyRentalAmount", monthlyRental);
                    }}
                />
            </S.InputPurchasePriceDiv>
            <S.InputMonthlyRentalDiv>
                <S.Label>Monthly Rental</S.Label>
                <RCCurrencyInput
                    prefix={"£"}
                    id="monthlyRental"
                    value={props.values.monthlyRentalAmount}
                    handleChange={(e) =>
                        props.setFieldValue("monthlyRentalAmount", e)
                    }
                />
            </S.InputMonthlyRentalDiv>
            <S.ButtonDiv>
                <S.Label></S.Label>
                <Button type="submit" onClick={() => props.submitForm()}>
                    Go
                </Button>
            </S.ButtonDiv>
        </S.CalculatorDiv>
      )}
    </Formik>
  );
};

export default CalculatorPart;