import { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { RootState } from "../../../reducers/rootReducer";
import { IReactRouterProps } from "../../../types";
import {
  IAddPropertyFromQueryParams,
  ICalculatorAddPropertyRequest,
} from "../../../types/PropertyService/IPropertyService";
import { addProperty } from "../../../services/PropertyService";
import { propertyCardStatusProspect } from "../../../configuration/appConfig";
import { processToast, updateToastMessage } from "../../../helpers/toastHelper";
import { toggleAddPropertySidebar } from "../../../helpers/sidebarHelpers";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { updateAddPropertyRequest } from "../../../actions/propertyActions";

interface Redux {
  email: string;
  contactId: string;
  add_property_request: any;
  updateAddPropertyRequest(data): void;
}

const AddPropertyFromQueryParamsPixel = ({
  history,
  location,
  email,
  contactId,
  add_property_request,
  updateAddPropertyRequest,
}: IReactRouterProps & Redux) => {
  useEffect(() => {
    let request: IAddPropertyFromQueryParams = null;

    if (add_property_request) {
      toggleAddPropertySidebar(true);
      return;
    }

    // Get add property query params here
    if (location.search && new URLSearchParams(location.search).has("addProperty")) {
      let urlParams = new URLSearchParams(location.search)
      request = JSON.parse(urlParams.get("addProperty"));
    }
    else if (localStorage.getItem("rc-add-property")) {
      request = JSON.parse(localStorage.getItem("rc-add-property"));
      localStorage.removeItem("rc-add-property");
    }

    // Remove the query parameters from the history stack using replace
    history.replace(location.pathname);

    if (!request || !email) return null;

    let obj: ICalculatorAddPropertyRequest = {
      address: request.address,
      email,
      propertyName: request.propertyName,
      purchasePrice: request.purchasePrice,
      monthlyRentalAmount: request.monthlyRentalAmount,
      investmentAmount: request.investmentAmount,
      deposit: request.deposit,
      mortgageAmount: request.mortgageAmount,
      yearsOfInvestment: request.yearsOfInvestment,
      dateOfPurchase: request.dateOfPurchase,
      propertyStatus: propertyCardStatusProspect,
      landlordContactId: contactId,
      propertyManagerContactId: contactId,
      ltv: request.ltv,
      interestRate: request.interestRate,
      valuationFee: request.valuationFee,
      mortgageFee: request.mortgageFee,
      refurbishment: request.refurbishment,
      stampDuty: request.stampDuty,
      lettingAgent: request.lettingAgent,
      maintenance: request.maintenance,
      voidPeriod: request.voidPeriod,
      insurance: request.insurance,
      capitalGrowthRate: request.capitalGrowthRate,
      rentalGrowthRate: request.rentalGrowthRate,
      serviceChargesPCM: request.serviceChargesPCM,
      groundRentPCM: request.groundRentPCM,
      solicitorFeeAndSearches: request.solicitorFeeAndSearches,

      investmentAmountPercent: request.investmentAmountPercent,
      mortgagePCM: request.mortgagePCM,
      mortgageType: request.mortgageType,
      mortgageTerm: request.mortgageTerm,
      lettingAgentPercent: request.lettingAgentPercent,
      maintenancePercent: request.maintenancePercent,
      voidPeriodPercent: request.voidPeriodPercent,
      refurbishmentPercent: request.refurbishmentPercent,
      otherPurchaseCosts: request.otherPurchaseCosts,
      otherOperatingCosts: request.otherOperatingCosts,
      country: request.country,
      stampDutyLocale: request.stampDutyLocale,
      isInvestmentProperty: request.isInvestmentProperty,
      insurancePCM: request.insurancePCM,
      renovationPeriodMonths: request.renovationPeriodMonths,
      propertyValueAfterRenovation: request.propertyValueAfterRenovation,
      investorStrategy: request.investorStrategy,
      clientName: request.clientName
    };

    updateAddPropertyRequest(obj);

    //history.push({
    //  state: obj,
    //});
    toggleAddPropertySidebar(true);
  }, []);

  return null;
};

const mapStateToProps = (state: RootState) => ({
  email: state.user.email,
  contactId: state.user.contact_id,
  add_property_request: state.property.add_property_request,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      updateAddPropertyRequest,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddPropertyFromQueryParamsPixel)
);
