import React from "react";
import { goToRoute } from "../../../../history";
import KeyDatesSummary from "../KeyDatesSummary/KeyDatesSummary.component";
import styles from "./RegulationSummary.module.scss";

interface IProps {
  propertyId?: string;
}

const RegulationSummary = ({propertyId}: IProps) =>{
  const openEditRegulation = () => {
    goToRoute(`/dashboard/property/${propertyId}/spec/regulation`)
  }

    return (
      <div className={styles.row}>
        <div
          className={styles.tiles}
          onClick={() => openEditRegulation()}
        >
            <KeyDatesSummary suppliedPropertyId={propertyId} />
        </div>

        <div className={styles.edit} onClick={() => openEditRegulation()}>
          <span>view regulation &gt;</span>
        </div>
      </div>
    );
  }

export default RegulationSummary;