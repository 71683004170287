import React, { useState, useEffect } from "react";
import * as S from "./RCTabs.styles";

interface IProps {
    items: {
        name: string,
        tab?: string,
    }[];
    initialTab?: string;
    width?: string;
    reset?: boolean;
    onClick(selectedTab: string): void;
}

const RCTabs = ({ items, initialTab, width, reset, onClick }: IProps) => {
  const [selectedTab, setSelectedTab] = useState(null);

  useEffect(() => {
    setSelectedTab(initialTab);
  }, [initialTab]);

  useEffect(() =>{
    if(reset){
      setSelectedTab(initialTab);
    }
  }, [reset])

  const getClassName = (i) => {
    let className = "";
    if(reset){
      className = initialTab == i.tab ? "active" : ""
    }else{
      className = selectedTab == i.tab ? "active" : ""
    }
    
    return className
  }

  return (
    <S.TabBar>
      <S.TabList width={width}>
        {items.map((i) => (
          <li
            onClick={() => { setSelectedTab(i.tab); onClick(i.tab);}}
            className={getClassName(i)}
          >
            {i.name}
          </li>
        ))}
      </S.TabList>
    </S.TabBar>
  );
};

export default RCTabs ;
