import React, { Component } from "react";
import * as ReactDOM from 'react-dom';
import { getOwnersList } from "../../../../services/OwnershipService";
import styles from "./PropertyOwnershipPage.module.scss";
import { IOwnershipItem, IOwnership } from "../../../../types/PropertyOwnerService/IPropertyOwnerService";
import ErrorsHoldMyBeer from "../../../atoms/ErrorsHoldMyBeer";
import RotatingLoader from "../../../atoms/RotatingLoader";
import { toolbarOptions, pageSettings, commands, contextMenuItems, editOptions } from "../../../../configuration/grid_syncfusion_options";
import { ColumnDirective, ColumnsDirective, Grid, GridComponent, Sort, Toolbar, ExcelExport, Group, PdfExport, Edit, Filter, CommandColumn, ContextMenu, ColumnMenu, QueryCellInfoEventArgs, Column, PdfExportProperties, ExcelExportProperties, Reorder, Page, Inject, AggregatesDirective, AggregateDirective, AggregateColumnsDirective, AggregateColumnDirective, Aggregate } from '@syncfusion/ej2-react-grids';
import { minWidthTablet } from "../../../../global/variables/_variables";
import { ClickEventArgs } from '@syncfusion/ej2-navigations';

import "@syncfusion/ej2-react-grids/styles/material.css";
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-calendars/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';
import "@syncfusion/ej2-react-grids/styles/material.css";
import UserContactInfo, { UserContactInfoType } from "../../../atoms/UserContactInfo";
import { withRouter, Router } from "react-router-dom";
import { IReactRouterProps } from "../../../../types";

interface IProps {
  match: any;
  history: any;
  location: any;
}

interface IState {
  loading: boolean;
  ownershipList: IOwnershipItem[];
  AddOwner: boolean;
}

type Props = IProps & IReactRouterProps;

class PropertyOwnershipPage extends Component<Props, IState> {
  // Ref to the Grid Instance
  public grid: Grid | null;


  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      ownershipList: [],
      AddOwner: false
    };

    this.toggleAdd = this.toggleAdd.bind(this);
    this.onAddCancel = this.onAddCancel.bind(this);
    this.getOwerships = this.getOwerships.bind(this);
    this.saveOwnership = this.saveOwnership.bind(this);
    this.toolbarClick = this.toolbarClick.bind(this);
    this.contactComponent = this.contactComponent.bind(this);
    this.footerSum = this.footerSum.bind(this);
  }

  public footerSum(props: any): any {
    return (<span>Percentage: {props.Sum}</span>)
  }

  public contactComponent(args: QueryCellInfoEventArgs) {
    if ((args.column as Column).field === "ownership.contactId") {
      const val = (args.data as IOwnershipItem).ownership.contactId;
      ReactDOM.render(
        <Router history={this.props.history}>
          <UserContactInfo
            disablePopup
            id={val}
            type={UserContactInfoType.Contact}
          />
        </Router>
        , args.cell as Element)
    }
  }

  // Managing toolbar click events
  toolbarClick = (args: ClickEventArgs) => {
    const selectedRecords = this.grid.getSelectedRecords();

    if (this.grid && args.item.id === 'propertyOwnershipGrid_pdfexport') {
      const exportProperties: PdfExportProperties = {
        dataSource: selectedRecords
      };
      if (selectedRecords.length > 0) {
        this.grid.pdfExport(exportProperties, true);
        return;
      }

      this.grid.pdfExport();
      return;
    }

    if (this.grid && args.item.id === 'propertyOwnershipGrid_csvexport') {
      if (selectedRecords.length > 0) {
        const exportProperties: ExcelExportProperties = {
          dataSource: selectedRecords
        };
        this.grid.csvExport(exportProperties);
        return;
      }
      this.grid.csvExport();
      return;
    }
  }

  componentDidMount() {
    this.getOwerships();
  }

  getOwerships() {
    this.setState({
      loading: true
    });
    let propertyId = this.props.match.params.propertyId;
    getOwnersList(propertyId).then(resp => {
      if (resp.status == "200" && resp.data) {
        this.setState({
          ownershipList: resp.data
        });
      }
      this.setState({
        loading: false
      });
    });
  }

  saveOwnership() {
    this.setState({
      AddOwner: !this.state.AddOwner
    });
    this.getOwerships();
  }

  toggleAdd(addItem: boolean) {
    this.setState({
      AddOwner: !this.state.AddOwner
    });
  }

  onAddCancel() {
    this.setState({
      AddOwner: false
    });
  }
  render() {
    if (this.state.loading) {
      return (
        <div>
          <RotatingLoader loading={true} text="Loading ownership..." />
        </div>
      );
    }

    return (
      <ErrorsHoldMyBeer>
        <div className={styles.page}>
          <GridComponent
            queryCellInfo={this.contactComponent}
            allowReordering={true}
            contextMenuItems={contextMenuItems}
            //actionBegin={this.actionBegin}
            id="propertyOwnershipGrid"
            toolbarClick={this.toolbarClick}
            allowTextWrap={true}
            allowExcelExport={true}
            allowPdfExport={true}
            allowGrouping={false}
            editSettings={editOptions}
            toolbar={toolbarOptions}
            dataSource={this.state.ownershipList}
            //searchSettings={searchOptions}
            //selectionSettings={selectionSettings}
            //editSettings={editOptions}
            //groupSettings={groupOptions}
            allowPaging={true}
            allowSorting={false}
            pageSettings={pageSettings}
            //filterSettings={filterOptions}
            allowFiltering={false}
            ref={g => this.grid = g}
          >
            <Inject services={[Aggregate, Reorder, ColumnMenu, ContextMenu, Edit, CommandColumn, Filter, Page, Sort, Toolbar, ExcelExport, PdfExport, Group]} />
            <ColumnsDirective>
              <ColumnDirective textAlign="Left" type='checkbox' minWidth='25' width='25' />
              <ColumnDirective textAlign="Left" headerText="Owner" allowEditing={false} field="ownership.contactId" width="100" />
              <ColumnDirective textAlign="Left" headerText="% Share" field="ownership.percentage" width='100' />
              {/* ADD IN VALUATION COL */}
              <ColumnDirective textAlign="Left" allowEditing={false} headerText="Owner Type" field="contact.type.entityName" width='100' />
              <ColumnDirective textAlign="Left" allowEditing={false} headerText="Tax Status" field="contact.taxStatus.entityName" width='100' />
              <ColumnDirective hideAtMedia={`(min-width: ${minWidthTablet})`} width='25' commands={commands}></ColumnDirective>
            </ColumnsDirective>
            <AggregatesDirective>
              
                <AggregateColumnsDirective>
                  <AggregateColumnDirective field='ownership.percentage' type='Sum' footerTemplate={this.footerSum} />
                </AggregateColumnsDirective>
             
            </AggregatesDirective>
          </GridComponent>
        </div>
      </ErrorsHoldMyBeer>
    );
  }
}

export default withRouter(PropertyOwnershipPage);