// Import React
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";

// Import Routing
import { Route, Redirect } from "react-router-dom";
import { Col } from "reactstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./PropertyPage.module.scss";
import PropertyDetailPage from "./PropertyDetailPage";
import SimpleRouterTabs from "../../atoms/SimpleRouterTabs";
import PropertyFinancialsPage from "./PropertyFinancialsPage";
import TabbedNavigationContainer from "../../atoms/TabbedNavigationContainer";
import { getPrimaryPropertyDetails } from "../../../services/PropertyService";
import withErrorShield from '../../atoms/withErrorShield';
import { hasClientArea } from '../../../helpers/clientHelpers'

import {
  dispatchUpdateCalculateReturnsProperty,
  dispatchResetCalculateReturnsProperty
} from "../../../actions/propertyActions";

import { setBetaSettings, IBetaSettings } from "../../../slices/betaConfigSlice"

// Icons
import propertyDetails from "../../../images/icons/property_details.png";
import menuReturns from "../../../images/icons/returns.png";
import menuIssues from "../../../images/icons/Issues.svg";
import menuChat from "../../../images/icons/icon_chat.svg";
import menuResearch from "../../../images/icons/research (1).svg";
import {
  ICalculateReturnsData,
  IMoreInformation,
  IPropertyDetail
} from "../../../reducers/propertyReducer";
import MoreInformationModal from "../../atoms/MoreInformationModal";
import PropertyCardNav from "../../atoms/PropertyCardNav";
import { IAddress } from "../../../types/PropertyService/IPropertyService";
import PropertyDocumentPage from "./PropertyDocumentsPage/PropertyDocumentPage";
import RotatingLoader from "../../atoms/RotatingLoader";
import PropertyAuditPage from "./PropertyAuditPage";
import PropertyIssuesPage from "./PropertyDetailPage/PropertyIssuesPage";
import PropertyTenancyPage from "./PropertyTenancyPage";
import IssueDetail from "../IssueDetail";
import PropertyChatsPage from "./PropertyDetailPage/PropertyChatsPage";
import { propertyCardStatusProspect } from "../../../configuration/appConfig";
import ResearchPage from "../ResearchPage";
import { addURLParameter, removeURLParameter } from "../../../helpers/locationHelpers";
import StreetView from "../../atoms/StreetView";
import { saveStreetViewStaticImage } from "../../../services/GoogleService";
import { updatePropertyImages, updateViewingPropertyImage } from "../../../helpers/propertyHelpers";
import PropertyDashboard from "../../molecules/DashboardHome/PropertyDashboard/PropertyDashboard.component";
import PropertyProspectDashboard from "../../molecules/DashboardHome/PropertyProspectDashboard/PropertyProspectDashboard.component";
import { IImageDto, ImageTypeEnum } from "../../../types/ImageService";
import { getImages } from "../../../services/ImageService";
import DocumentsPage from "../DocumentsPage";
import { toggleAddDocumentPopup } from "../../../actions/appActions";
import { IDocumentUploadOptions } from "../../molecules/AddDocumentModal";
import { IDataResponse } from "../../../types/ApiService";
import { updateAlertMessage } from "../../../helpers/alertHelpers";
import TasksListPage from "../Tasks/TasksListPage/TasksListPage.component";
const queryString = require("query-string");

interface IProps {
  match: any;
  history: any;
  location: any;
}

interface IReduxProps {
  propertyId: string;
  propertyName: string;
  propertyStatus: string;
  propertyStatusId: string;
  propertyImage: string;
  propertyImages: Array<IImageDto>;
  propertyDetails: IPropertyDetail;
  address: IAddress;
  moreInformation: IMoreInformation;  
  betaSettings: IBetaSettings;
  dispatchUpdateCalculateReturnsProperty(data: any): void;
  dispatchResetCalculateReturnsProperty(): void;
  setBetaSettings(betaSettings: IBetaSettings): void;
}

interface IState {
  justAdded: boolean;
  active: boolean;
  inactive: boolean;
  moreInformation: boolean;
  loading: boolean;
  showInitialPopup: boolean;
}

type Props = IProps & IReduxProps;

class PropertyPage extends Component<Props, IState> {
  constructor(props) {
    super(props);
    this.state = {
      justAdded: true,
      active: false,
      inactive: true,
      moreInformation: false,
      loading: false,
      showInitialPopup: false
    };
    this.toggleHidden = this.toggleHidden.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onUpdateStreetViewImage = this.onUpdateStreetViewImage.bind(this);
    this.onClickAddDocument = this.onClickAddDocument.bind(this);
  }

  toggleHidden = () =>
    this.setState(prevState => ({ active: prevState.inactive }));

  toggleShow = () =>
    this.setState(prevState => ({ active: !prevState.inactive }));

  componentWillReceiveProps(newProps: IProps): void {
    // Not using the intial pop anymore commented out below, have not completey, removed
    // all logic just incase we want to put it back in
    if (!this.state.showInitialPopup) {
      let showInitialPopup = queryString.parse(newProps.location.search)
        .showInitialPopup;

      if (showInitialPopup && !this.state.showInitialPopup) {
        this.setState({
          showInitialPopup: true
        });
      }

      //let removeQuery = removeURLParameter(newProps.location.search, "showInitialPopup");
      //this.props.history.push({
      //  search: removeQuery
      //});
    }

    let refreshProperty = queryString.parse(this.props.location.search).refreshProperty;

    if (
      (this.props.match.params.propertyId != newProps.match.params.propertyId) || refreshProperty
    ) {
      let query = removeURLParameter(this.props.location.search, "refreshProperty");
      this.props.history.push({
        search: query
      });

      this.setState({
        loading: true
      });

      getPrimaryPropertyDetails(newProps.match.params.propertyId).then(
        result => {
          let response: IDataResponse<any> = result.data;
          if (response.data) {
            let reduxData: ICalculateReturnsData = {
              propertyId: newProps.match.params.propertyId,
              propertyName: response.data.propertyName,
              propertyStatus: response.data.propertyStatus,
              propertyStatusId: response.data.propertyStatusId,
              propertyImage: response.data.propertyImage,
              address: response.data.address,
              propertySummary: response.data.propertySummary,
              propertyValuation: response.data.propertyValuation,
              moreInformation: response.data.moreInformation,
              chartData: response.data.chartData,
              propertyDetails: response.data.propertyDetail,
              propertyContacts: response.data.propertyContacts,
              propertyImages: response.data.images
            };

            this.setState({
              moreInformation: response.data.moreInformation.hasViewed
            });

            this.props.dispatchUpdateCalculateReturnsProperty(reduxData);

            this.setState({
              loading: false
            });
          } else {
            this.setState({
              loading: false
            });
            updateAlertMessage(response.errorMessage, true, 3000, true);
          }
        }
      );
    }
  }

  componentDidMount(): void {
    this.setState({
      loading: true
    });
    let propertyId = this.props.match.params.propertyId;
    getPrimaryPropertyDetails(propertyId).then(result => {
      let response: IDataResponse<any> = result.data;
      if (response.data) {
        let reduxData: ICalculateReturnsData = {
          propertyId: propertyId,
          propertyName: response.data.propertyName,
          propertyStatus: response.data.propertyStatus,
          propertyStatusId: response.data.propertyStatusId,
          propertyImage: response.data.propertyImage,
          address: response.data.address,
          propertySummary: response.data.propertySummary,
          propertyValuation: response.data.propertyValuation,
          moreInformation: response.data.moreInformation,
          chartData: response.data.chartData,
          propertyDetails: response.data.propertyDetail,
          propertyContacts: response.data.propertyContacts,
          propertyImages: response.data.images
        };

        this.setState({
          moreInformation: response.data.moreInformation.hasViewed
        });

        this.props.dispatchUpdateCalculateReturnsProperty(reduxData);

        this.setState({
          loading: false
        });
      } else {
        this.setState({
          loading: false
        });
        updateAlertMessage(response.errorMessage, true, 3000, true);
      }
    }).catch((error) => {
      throw error;
    });

    let isJustAddedProperty = queryString.parse(this.props.location.search)
      .justAdded;

    if (isJustAddedProperty) {
      this.notify("Your new property has been added.");
    }

    let showInitialPopup = queryString.parse(this.props.location.search)
      .showInitialPopup;

    if (showInitialPopup) {
      this.setState({
        showInitialPopup: true
      });
    }

    let removeQuery = removeURLParameter(this.props.location.search, "showInitialPopup");
    this.props.history.push({
      search: removeQuery
    });
  }

  notify = (message: string) => {
    toast(message);
  };

  componentWillUnmount() {
    this.props.dispatchResetCalculateReturnsProperty();
  }

  onOpen(): void {
    this.setState({
      moreInformation: false
    });
  }

  onClose(): void {
    this.setState({
      moreInformation: true
    });
  }

  onUpdateStreetViewImage(lat: number, lng: number, heading: number): void {
    saveStreetViewStaticImage({
      lat: lat,
      lng: lng,
      heading: heading,
      propertyId: this.props.propertyId
    }).then(resp => {
      if (resp && resp.status == 200 && resp.data) {
        updateViewingPropertyImage(resp.data);

        getImages({ propertyId: this.props.match.params.propertyId, imageType: [ImageTypeEnum.PROPERTY_IMAGE] })
          .then(resp => {
            if (resp && resp.status == 200) {
              updatePropertyImages(resp.data);
            }
          })
      }
    });
  }

  onClickAddDocument(): void {
    let options: IDocumentUploadOptions = {
      propertyId: this.props.propertyId,
      propertyName: this.props.propertyName || 'No property name'
    }

    let query = "uploadDocumentOptions=" + JSON.stringify(options);

    let search = addURLParameter(this.props.location.search, query);

    this.props.history.push({
      search
    });

    toggleAddDocumentPopup(true);
  }

  render() {
    if (this.state.loading) {
      return (
        <div className={styles.loading}>
          <RotatingLoader
            loading={true}
            text="Loading your property details..."
          />
        </div>
      );
    }

    if (!this.props.propertyDetails) {
      return null;
    }

    const showStreetView = this.props.address.line1;
    let isProspect = this.props.propertyStatusId == propertyCardStatusProspect;

    return (
      <div className={styles.container}>

        {this.props.moreInformation && (
          <MoreInformationModal
            propertyType={this.props.moreInformation.propertyType}
            currentValuation={this.props.moreInformation.currentValuation}
            address={this.props.address}
            salesHistory={this.props.moreInformation.propertyValuations}
            open={!this.state.moreInformation && !this.state.showInitialPopup}
            onOpenModal={this.onOpen}
            onCloseModal={this.onClose}
          />
        )}

        {
          this.props.propertyImages && this.props.propertyImages.length === 0 &&
          <div className={styles.hiddenStreetView}>
            <StreetView
              canMove={false}
              firstLoadCallback={this.onUpdateStreetViewImage}
              isFirstLoad={true}
              address={this.props.address}
            />
          </div>
        }

        <div className={styles.propertyCard}>
          <PropertyCardNav
            canAction
            canEdit
            propertyCard={{
              propertyId: this.props.propertyId,
              propertyName: this.props.propertyName,
              propertyStatus: this.props.propertyStatus,
              propertyStatusId: this.props.propertyStatusId,
              address: this.props.address,
              propertyImage: this.props.propertyImages && this.props.propertyImages.length > 0 ? this.props.propertyImages[0].original : this.props.propertyImage,
              propertyType: this.props.propertyDetails && this.props.propertyDetails.propertyType,
              residentialType: this.props.propertyDetails && this.props.propertyDetails.residentialType,
              commercialType: this.props.propertyDetails && this.props.propertyDetails.commercialType,
            }}
            betaOnClick={() => this.props.setBetaSettings({ 
                ...this.props.betaSettings,
                isPropertyDashboardBeta: !this.props.betaSettings.isPropertyDashboardBeta,
                isKeyDatesBeta: !this.props.betaSettings.isKeyDatesBeta
              })}
          />
        </div>

        <div className={styles.tabbedNav}>
          <TabbedNavigationContainer>
            <Col>
              <SimpleRouterTabs
                routes={[
                  {
                    path: this.props.match.url + "/summary",
                    text: "Summary",
                    mobileText: 'Home',
                    icon: propertyDetails
                  },
                  {
                    path: this.props.match.url + "/research/summary",
                    text: "Returns",
                    mobileText: 'Returns',
                    icon: menuResearch
                  },
                  {
                    path: this.props.match.url + "/spec",
                    text: "Spec",
                    mobileText: 'Spec',
                    icon: propertyDetails
                  },
                  {
                    path: this.props.match.url + "/financials",
                    text: "Money",
                    mobileText: 'Money',
                    icon: menuReturns,
                    hide: this.props.propertyStatusId == propertyCardStatusProspect
                  },
                  ...(hasClientArea('TasksFunctions')
                      ? [
                            {
                              path: this.props.match.url + "/tasks",
                              text: "Tasks",
                              mobileText: 'Tasks',
                              icon: menuReturns
                            },
                        ]
                      : []),
                  {
                    path: this.props.match.url + "/issues",
                    text: "Issues",
                    mobileText: 'Issues',
                    icon: menuIssues,
                    hide: this.props.propertyStatusId == propertyCardStatusProspect
                  },
                  {
                    path: this.props.match.url + "/chats",
                    text: "Chats",
                    mobileText: 'Chats',
                    icon: menuChat
                  }
                ]}
              />
            </Col>
          </TabbedNavigationContainer>
        </div>
        {
          (this.props.propertyImage && this.props.address.line1 == '' && this.props.address.postcode == '') &&
          <div className={styles.propertyImage}>
            <img src={this.props.propertyImage} alt='' />
          </div>
        }

        <div>
          <div>
            <Route
              path={`${this.props.match.url}`}
              exact
              render={() => (
                <Redirect to={`${this.props.match.url}/summary`} />
              )}
            />

            {
              !isProspect
              ?
              <Route
                path={`${this.props.match.path}/summary`}
                render={() => <div className={styles.plainNested}><PropertyDashboard /></div>}
              />
              :
              <Route
                path={`${this.props.match.path}/summary`}
                render={() => <div className={styles.plainNested}><PropertyProspectDashboard /></div>}
              />
            }
            
            <Route
              path={`${this.props.match.path}/spec`}
              render={(props) => <PropertyDetailPage {...props} isMapAvailable={showStreetView} />
              }
            />

            <Route
              path={`${this.props.match.path}/financials`}
              component={PropertyFinancialsPage}
            />

            <Route
              path={`${this.props.match.path}/research`}
              render={() => <div className={styles.nested}><ResearchPage /></div>}
            />

            <Route
              path={`${this.props.match.path}/documents`}
              exact
              render={() => <DocumentsPage onAddDocumentClick={this.onClickAddDocument} propertyId={this.props.propertyId} />}
            />

            <Route
              path={`${this.props.match.path}/tasks`}
              exact
              render={() => <div className={styles.plainNested}><TasksListPage /></div>}
            />

            <Route
              path={`${this.props.match.path}/tenancy`}
              render={() => <PropertyTenancyPage />}
            />

            <Route
              path={`${this.props.match.path}/issues/:issueId?`}
              render={(props) => isProspect ? <Redirect to={`${this.props.match.url}`} /> : <PropertyIssuesPage propertyId={this.props.propertyId} propertyName={this.props.propertyName} />}
            />

            <Route
              path={`${this.props.match.path}/issue/:issueId?`}
              render={(props) => isProspect ? <Redirect to={`${this.props.match.url}`} /> : <IssueDetail />}
            />

            <Route
              path={`${this.props.match.path}/documents/view/:documentId`}
              component={PropertyDocumentPage}
            />

            <Route
              path={`${this.props.match.path}/timeline`}
              exact
              render={(props) => isProspect ? <Redirect to={`${this.props.match.url}`} /> : <PropertyAuditPage />}
            />

            <Route
              path={`${this.props.match.path}/chats/:chatSessionId?`}
              exact
              render={() => <PropertyChatsPage />}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchUpdateCalculateReturnsProperty: (data: any) => {
    dispatch(dispatchUpdateCalculateReturnsProperty(data));
  },
  dispatchResetCalculateReturnsProperty: () => {
    dispatch(dispatchResetCalculateReturnsProperty());
  },
  setBetaSettings: (betaSettings: IBetaSettings) => {
    dispatch(setBetaSettings(betaSettings));
  }
});

const mapStateToProps = (state: any) => ({
  propertyId: state.property.property_data.property_data.propertyId,
  propertyName: state.property.property_data.property_data.propertyName,
  propertyStatus: state.property.property_data.property_data.propertyStatus,
  propertyStatusId: state.property.property_data.property_data.propertyStatusId,
  propertyImage: state.property.property_data.property_data.propertyImage,
  address: state.property.property_data.property_data.address,
  moreInformation: state.property.property_data.property_data.moreInformation,
  propertyImages: state.property.property_data.property_data.propertyImages,
  propertyDetails: state.property.property_data.property_data.propertyDetails,
  betaSettings: state.betaConfigReducer
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorShield(PropertyPage)) as any);