import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// Import Styles
import styles from "./ContactTenancy.module.scss";
import { IUserProfileResponse } from "../../../../../types/ProfileService/IProfile";
import {
  IAddTenancyOptions,
  appendAddTenancyOptions
} from "../../../../../helpers/locationHelpers";
import { toggleAddEditTenancyPopup } from "../../../../../helpers/sidebarHelpers";
import { getTenancyFromContactId } from "../../../../../services/TenancyService";
import { ITenancyItem } from "../../../../../types/Tenancy";
import {
  updateTenancyList,
  updateViewingTenancy
} from "../../../../../helpers/propertyHelpers";
import { connect } from "react-redux";
import TenancyGrid from "../../../TenancyPage/TenancyGrid";
import { ContactDefaultTemplate } from "../../../TenancyPage/TenancyGrid/_templates/contactDefaultTemplate";

interface IWithRouterProps {
  history: any;
  location: any;
  match: any;
  
  userProfile?: IUserProfileResponse;
  tenancy?: ITenancyItem;
}

interface IState {
  contactId: string;
  showCurrent: boolean;
  noResults: boolean;
  tenancy: ITenancyItem;
  isLoading: boolean;
  radioValue: string;
}

const viewLabels = ["Current", "Historic"];

class ContactTenancy extends Component<IWithRouterProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      contactId: null,
      showCurrent: true,
      noResults: false,
      tenancy: null,
      isLoading: false,
      radioValue: viewLabels[0]
    };

    this.noResultsCallback = this.noResultsCallback.bind(this);
    this.onAddTenancy = this.onAddTenancy.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  noResultsCallback(hasResults: boolean): void {
    this.setState({
      noResults: hasResults
    });
  }

  componentWillUnmount(): void {
    updateTenancyList([]);
    updateViewingTenancy(null);
  }

  componentDidMount(): void {
    let contactId = this.props.match.params.contactId;

    if (!contactId) {
      return null;
    }

    this.setState({
      isLoading: true
    });

    getTenancyFromContactId(contactId)
      .then(resp => {
        this.setState({
          noResults: false,
          isLoading: false
        });
        updateViewingTenancy(resp.data);
      })
      .catch(e => {
        this.setState({
          noResults: true,
          isLoading: false
        });
      });
  }

  onAddTenancy(): void {
    let options: IAddTenancyOptions = {
      contactId: this.props.match.params.contactId,
      requireUpdate: true
    };

    let query = appendAddTenancyOptions(this.props.location.search, options);
    this.props.history.push({
      search: query
    });

    toggleAddEditTenancyPopup(true);
  }

  handleChange(radioValue) {
    this.setState({ radioValue });
  }

  render() {
    return (
      <div className={styles.page}>
        <TenancyGrid config={{
          template: ContactDefaultTemplate(this.props),
          addSettings: {
            contactId: this.props.match.params.contactId,
            contactName: this.props.userProfile.profile.forename + " " + this.props.userProfile.profile.surname
          },
          dataFilterSettings: {
            contactId: this.props.match.params.contactId,
          }
        }} />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  tenancy: state.property.tenancy.viewing_tenancy_detail
});

export default withRouter<IWithRouterProps,any>(connect(mapStateToProps)(ContactTenancy));
