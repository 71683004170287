import styled from 'styled-components';

export const ContainerDiv = styled.div<{ highlighted?: boolean }>`
    padding: 15px 5px 15px 5px;
    //border: solid lightgray;
    border: ${p => p.highlighted ? 'solid red' : 'none'};
    background-color: #EFE4E4;
`;

export const InnerContainerDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    //column-gap: 10px;
    //align-items: flex-end;
    align-content: space-between;
`;

export const MainAreaDiv = styled.div`
    order: 1;
    //flex-basis: 70%;
    //max-width: 1900px;
`;

export const BottomAreaDiv = styled.div<{ visibility?: string }>`
    order: 2;
    display: inline-flex;
    width: 100%;
    //padding: 5px 10px 5px 10px;
    //text-align: right;
    overflow: hidden;
    white-space: nowrap;
    visibility: ${p => p.visibility ? p.visibility : 'visible'};
`;

export const DateAreaDiv = styled.div<{ visibility?: string }>`
    
    //flex-basis: 30%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    //column-gap: 10px;
    //align-items: flex-start;
    align-content: flex-start;
    width: 80%;
    visibility: ${p => p.visibility ? p.visibility : 'visible'};
    height: ${p => p.visibility && p.visibility == 'visible' ? '50px' : '0px'};
`;

export const HeaderDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    justify-content: flex-start;
    column-gap: 4px;
`;

export const TitleDiv = styled.div`
    order: 1;
    padding: 5px 0px 5px 10px;
    //white-space: nowrap;
    font-weight: bold;
`;

export const StatusDiv = styled.div<{ visibility?: string }>`
    order: 2;
    visibility: ${p => p.visibility ? p.visibility : 'visible'};
`;

export const TooltipDiv = styled.div`
    order: 2;
`;

export const ToggleDiv = styled.div`
    order: 4;
`;

export const DateDiv = styled.div<{ highlighted?: boolean }>`
    white-space: nowrap;
    padding: 5px 10px 5px 10px;
    input{
        width: 140px;
        height: 38px;
        text-align: center;
        border-radius: 5px;
        //border: 1px solid #f0f3ff;
        border: ${p => p.highlighted ? '1px solid red' : '1px solid #f0f3ff'};
        color: #808080;
        background-color: #f9faff;
    }
`;

export const DateDescriptionDiv = styled.div`
    text-align: left;
    padding: 5px 10px 5px 10px;
    color: gray;
    //white-space: pre-wrap;
`;

export const DocDiv = styled.div`
    margin: auto 0;
    white-space: nowrap;
    padding: 0px 10px 0px 10px;
`;

export const DetailsLinkDiv = styled.div`
    margin: auto 0;
    padding: 0px 10px 0px 10px;
    text-align: right;
    width: 20%;
    &:hover{
        cursor: pointer;
    }
`;