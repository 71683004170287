import React from 'react'
import styles from './ChatMessage.module.scss'
import { withRouter } from 'react-router-dom'
import 'react-chat-elements/dist/main.css'
import { IChatItem } from '../../../../services/ChatService'
import ProfileImage from '../../ProfileImage'
import DateTimeFormat from '../../DateTimeFormat'
import GenericPopup from '../../GenericPopup'
import { generateId } from '../../../../helpers/randomHelpers'
import { QuickViewType } from '../../../../types/QuickView'
import SuccessTick from '../../SuccessTick'
import { getFileTypeLogo, docIsImage } from '../../../../helpers/documentHelpers'
import Lightbox from 'react-image-lightbox'
import "react-image-lightbox/style.css";
import Linkify from 'react-linkify'
const isImage = require('is-image')

interface IProps {
    message: IChatItem
    images: Array<string>;
}

interface IState {
    isViewingFile: boolean
    photoIndex: number
}

class ChatMessage extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            isViewingFile: false,
            photoIndex: 0
        }

        this.displayFile = this.displayFile.bind(this)
    }

    componentDidMount(): void {
        // Set the inital image index to the current message index
        if(this.props.images){
            for(var i = 0; i <  this.props.images.length; i++){
                if(this.props.images[i] == this.props.message.imageUri){
                    this.setState({photoIndex: i})
                    break;
                }
            }
        }
    }

    displayFile(file: string): JSX.Element {
        if (isImage(file)) {
            return <img src={file} />
        }

        return (
            <a target="_blank" href={file}>
                {getFileTypeLogo(file)}
            </a>
        )
    }

    isImage(file: string): boolean {
        return isImage(file)
    }

    render() {
        const { message } = this.props
        return (
            <div className={styles.container}>
                {this.state.isViewingFile && (
                    <Lightbox
                        mainSrc={this.props.images[this.state.photoIndex]}
                        nextSrc={this.props.images[(this.state.photoIndex + 1) % this.props.images.length]}
                        prevSrc={this.props.images[(this.state.photoIndex + this.props.images.length - 1) % this.props.images.length]}
                        onCloseRequest={() => this.setState({ isViewingFile: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                            photoIndex: (this.state.photoIndex + this.props.images.length - 1) % this.props.images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                            photoIndex: (this.state.photoIndex + 1) % this.props.images.length
                            })
                        }
                    />
                )}
                <div
                    className={`${
                        message.isCurrentUserSender
                            ? styles.rightItem
                            : styles.leftItem
                    }`}
                >
                    <div className={styles.avatar}>
                        <GenericPopup
                            identifier={generateId(10)}
                            options={{
                                userId: message.sender.senderUserId,
                                type: QuickViewType.User,
                                toggleComponent: (
                                    <ProfileImage
                                        image={
                                            message.sender.senderProfileImageUri
                                        }
                                        large={false}
                                        forename={message.sender.senderForename}
                                        surname={message.sender.senderSurname}
                                    />
                                ),
                            }}
                        />
                    </div>

                    <div className={styles.message}>
                        <div className={styles.heading}>
                            <span className={styles.name}>
                                {message.isCurrentUserSender
                                    ? 'You '
                                    : message.sender.senderForename +
                                      ' ' +
                                      message.sender.senderSurname +
                                      ' '}
                            </span>

                            <span className={styles.timestamp}>
                                <DateTimeFormat
                                    rawDateTime={message.dateAdded}
                                    showShortDateAndTime={true}
                                    showTimeAgoFormat={true}
                                />
                            </span>
                        </div>
                        <div className={styles.messageContent}>
                            <Linkify>{message.message}</Linkify>
                            {message.isImage && (
                                <div
                                    onClick={() => 
                                        { docIsImage(message.imageUri) && this.setState({ isViewingFile: true })}
                                    }
                                >
                                    {this.displayFile(message.imageUri)}
                                </div>
                            )}
                        </div>

                        {this.props.message.isSuccess && (
                            <div className={styles.status}>
                                <SuccessTick />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ChatMessage)
