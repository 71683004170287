import React from 'react';
import { useDispatch } from 'react-redux';
import { Loader } from "@rentchief/components";
import { toggleAddPropertySidebar } from '../../../../../actions/appActions';
import Chart from 'react-google-charts'
import SectionHeader from "../SectionHeader/SectionHeader.component"
import { ISection } from '../../PropertyDashboard/_config/dashboardConfig';
import InitialStateSection from '../../Sections/InitialStateSection/InitialStateSection.component';
import { useFetchChartData } from '../../../../../servicesHooks/useFetchChartData.hook';

interface sectionProps {
    id: string;
    header: string;
    hideOnClick(id: string): void;
    config: ISection;
}

const ActiveChartSection = ({id, header, hideOnClick, config}: sectionProps) => {
    const dispatch = useDispatch();

    const { dataRetrieved, data } = useFetchChartData(id);

    const onAddProperty = (): void => {
        dispatch(toggleAddPropertySidebar(true));
    }

    return (
        <>
            <SectionHeader id={id} header={header} hideOnClick={hideOnClick}/>
            {
                !dataRetrieved
                ?
                <Loader />
                :
                <div>
                    {
                        data 
                        ?
                        <Chart
                            width={'100%'}
                            height={'316px'}
                            chartType="PieChart"
                            //loader={<div>Loading Chart</div>}
                            data={data}
                            options={{
                                backgroundColor: 'none',
                                pieSliceText: 'value',
                                pieHole: 0.4,
                                legend: {
                                    position: 'top',
                                },
                            }}
                        />
                        :
                        <InitialStateSection 
                            id="portfolioChart"
                            header={config && config.header} 
                            iconUrl={config && config.initialState.iconUrl}
                            title={config && config.initialState.title}
                            summary={config && config.initialState.summary}
                            //action={<S.ActionDiv><Button onClick={onAddProperty}>Add</Button></S.ActionDiv>}
                            hideOnClick={hideOnClick}
                            addOnClick={onAddProperty}
                        />
                    }
                </div>
            }
        </>
        
    )
}

export default ActiveChartSection;