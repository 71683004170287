import moment from 'moment'
import {
    IChatItem,
    IMessageListItem,
    IGetMessageResponse,
} from '../services/ChatService'
import { IStickyChatItem } from '../types/ChatService'

interface IChatState {
    active_chat_session_id: string
    viewing_chat: IGetMessageResponse
    chat_list: Array<IMessageListItem>
    sticky_chat: Array<IStickyChatItem>
    is_sticky_chat_visible: boolean
}

const initialState: IChatState = {
    active_chat_session_id: null,
    viewing_chat: null,
    chat_list: [],
    sticky_chat: [],
    is_sticky_chat_visible: true
}

export default (state: IChatState = initialState, action: any): IChatState => {
    switch (action.type) {
        case 'UPDATE_ACTIVE_CHAT_SESSION_ID':
            return {
                ...state,
                active_chat_session_id: action.data,
            }

        case 'UPDATE_VIEWING_CHAT_MESSAGES':
            let current = [...state.viewing_chat.messages];
            let chatIndex = state.viewing_chat.messages.findIndex(c => c.locChatMessageId == action.data.locChatMessageId);
            current[chatIndex] = action.data;
            return {
                ...state,
                viewing_chat: {
                    ...state.viewing_chat,
                    messages: current,
                },
            }

        case 'ADD_VIEWING_CHAT_MESSAGES':
            return {
                ...state,
                viewing_chat: {
                    ...state.viewing_chat,
                    messages: state.viewing_chat.messages.concat(action.data),
                },
            }

        case 'UPDATE_VIEWING_CHAT':
            return {
                ...state,
                viewing_chat: action.data,
            }

        case 'UPDATE_CHAT_LIST':
            return {
                ...state,
                chat_list: action.data,
            }

        case 'UPDATE_CHAT_LIST_ITEM_WITH_LATEST_CHAT':
            return {
                ...state,
                chat_list: state.chat_list.map(
                    item => item.chatSessionId === action.data.curSessionId ? 
                        {
                            ...item, 
                            lastMessage: action.data.newMsg.lastMessage,
                            hasOpened: action.data.newMsg.hasOpened,
                            lastMessageDateTime: action.data.newMsg.lastMessageDateTime,
                            unreadMessageCount: !action.data.isLoggedInUser && state.active_chat_session_id != action.data.curSessionId 
                                ? action.data.newMsg.unreadMessageCount : item.unreadMessageCount
                        } 
                        : item
                ).sort(function (a: any, b: any) {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return +moment(b.lastMessageDateTime) - +moment(a.lastMessageDateTime)
                }),
            }
            
        case 'REMOVE_ITEM_FROM_CHAT_LIST':
            return {
                ...state,
                chat_list: state.chat_list.filter((c) => c.chatSessionId != action.data),
                viewing_chat: null
            }

        case 'ADD_ITEM_FROM_CHAT_LIST':
            return {
                ...state,
                chat_list: state.chat_list.concat(action.data).sort(function (a: any, b: any) {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return +moment(b.lastMessageDateTime) - +moment(a.lastMessageDateTime)
                }),
            }

        case 'UPDATE_STICKY_CHAT':
            return {
                ...state,
                sticky_chat: action.data,
            }

        case 'TOGGLE_STICKY_CHAT':
            return {
                ...state,
                is_sticky_chat_visible: action.data,
            }

        default:
            return state
    }
}
