// Import React
import React, { Component } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { connect } from 'react-redux'
import { Route, withRouter } from 'react-router-dom'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import { StickyContainer } from 'react-sticky'
import 'react-toastify/dist/ReactToastify.css'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'
import { Dispatch } from 'redux'
import {
    dispatchOverlay,
    toggleAddClientSidebar,
    toggleAddContactSidebar,
    toggleAddDocumentPopup,
    toggleAddPropertySidebar,
    toggleAddUserSidebar,
    toggleAssignRoleSidebar,
    toggleEditPropertySidebar,
    toggleProfileSidebar,
    toggleSidebar,
    toggleTenancyActionSidebar,
} from '../../../actions/appActions'
import { toggleAddTeamMember } from '../../../actions/teamsActions'
// Import Other
import { getUserInformation } from '../../../actions/userActions'
import {
    addToStickyChat,
    removeStickyChatItem,
    toggleShowHideStickyChatItem,
    toggleStickyChat,
} from '../../../helpers/chatHelpers'
import {
    getLocalTourInformation,
    setLocalTourInformation,
} from '../../../helpers/joyrideHelpers'
import { updateUnreadMessageCount } from '../../../helpers/notificationHelpers'
import { canAccessTeamList } from '../../../helpers/permissionHelpers'
import {
    LandlordRoute,
    PlatformAdminOrClientAdminRoute,
} from '../../../helpers/routeAuthentication'
import {
    isSidebarOpen,
    toggleIssueActionSidebar,
    toggleMobileSidebarFalse,
    toggleStartChatPopup,
    toggleWelcomePopup,
} from '../../../helpers/sidebarHelpers'
import { delayAction } from '../../../helpers/timerHelpers'
import chatIcon from '../../../images/icons/chat_main.svg'
import { ISidebarState } from '../../../reducers/appReducer'
import {
    getUnreadMessages,
    IMessageListItem,
} from '../../../services/ChatService'
import { IStickyChatItem } from '../../../types/ChatService'
import { IInviteTeamMemberOptions } from '../../../types/Teams'
import AddEditInspection from '../../atoms/AddEditInspection'
import AddEditIssueModal from '../../atoms/AddEditIssueModal'
import AddEditTransactionModal from '../../atoms/AddEditTransactionModal'
import Alert from '../../atoms/Alert'
import ChatBox from '../../atoms/ChatBox'
import ClientAreaRestriction from '../../atoms/ClientAreaRestriction'
import CloseButton from '../../atoms/CloseButton'
import DeleteAssetModal from '../../atoms/DeleteAssetModal'
import DeleteDocumentModal from '../../atoms/DeleteDocumentModal'
import DeleteInspectionModal from '../../atoms/DeleteInspectionModal'
import DeleteTenancyModal from '../../atoms/DeleteTenancyModal'
import DeleteTransactionModal from '../../atoms/DeleteTransactionModal'
import DeleteUnitModal from '../../atoms/DeleteUnitModal'
import DeleteUserModal from '../../atoms/DeleteUserModal'
import EditDocumentContainer from '../../atoms/EditDocumentContainer'
import ErrorsHoldMyBeer from '../../atoms/ErrorsHoldMyBeer'
import Footer from '../../atoms/Footer'
import PageMetadataController from '../../atoms/PageMetadataController'
import ResearchPage from '../../atoms/ResearchPage'
import StartChatModal from '../../atoms/StartChatModal'
import WelcomeToRentChiefModal from '../../atoms/WelcomeToRentChiefModal'
import AddClientUserPanel from '../../molecules/AddClientUserPanel'
import AddContactSidePanel from '../../molecules/AddContactSidePanel'
import AddDocumentModal from '../../molecules/AddDocumentModal'
import AddPropertySidePanel from '../../molecules/AddPropertySidePanel'
import AddTeamMemberSidePanel from '../../molecules/AddTeamMemberSidePanel'
import AddUserSidePanel from '../../molecules/AddUserSidePanel'
import AssignPermissionToRolePanel from '../../molecules/AssignPermissionToRolePanel'
import AssignRoleUserPanel from '../../molecules/AssignRoleUserPanel'
import ChangeProfileImageSidePanel from '../../molecules/ChangeProfileImageSidePanel'
import DashboardHome from '../../molecules/DashboardHome'
import DashboardNavigationBar from '../../molecules/DashboardNavigationBar'
import DashboardNavigationItems from '../../molecules/DashboardNavigationItems'

import FilterSidePanel from '../../molecules/FilterSidePanel'
import IssueActionPanel from '../../molecules/IssueActionPanel'
import LoadingOverlay from '../../molecules/LoadingOverlay'
import ProfileSidePanel from '../../molecules/ProfileSidePanel'
import PropertiesListPage from '../../organisms/PropertiesListPage'
import AddAsset from '../AddAsset'
import AddEditUnit from '../AddEditUnit'
import AssetDetails from '../AssetDetails'
import AssetsGrid from '../AssetsPage/AssetsGrid'
import ChatList from '../ChatList'
import ChatListPage from '../ChatListPage'
import ContactPage from '../ContactPage'
import ContactDetail from '../ContactPage/ContactDetail'
import DocumentsPage from '../DocumentsPage'
import EditProfilePage from '../EditProfilePage'
import InspectionDetailPage from '../InspectionDetailPage'
import InspectionsGrid from '../InspectionsPage/InspectionsGrid'
import IssueDetail from '../IssueDetail'
import IssueListPage from '../IssueListPage'
import IssueList from '../IssueListPage/IssueList'
import PlatformManagementPage from '../PlatformManagementPage'
import PropertyImportPage from '../PropertyImportPage'
import PropertyPage from '../PropertyPage'
import PropertyConfigurationForm from '../PropertyPage/PropertyConfigurationForm'
import SettingsPage from '../SettingsPage'
import TenancyContainerPage from '../TenancyPage/TenancyContainerPage/TenancyContainerPage.component'
import TenancyGrid from '../TenancyPage/TenancyGrid'
import UnitDetailPage from '../UnitPage/UnitDetailPage'
import UnitGrid from '../UnitPage/UnitGrid'
// Import Styles
import styles from './DashboardPage.module.scss'
import AddPropertyFromQueryParamsPixel from '../../atoms/AddPropertyFromQueryParamsPixel'
import TasksListPage from '../Tasks/TasksListPage/TasksListPage.component'
import TaskDetailsPageContainer from '../Tasks/TaskDetailsPageContainer/TaskDetailsPageContainer.component'
import TransactionsGridContainer from '../FinancialsPage/TransactionsGridContainer/TransactionsGridContainer.component'

interface IReduxProps {
    getUserInformation(): void
    dispatchOverlay(show: boolean): void
    toggleSidebar(show: boolean): void
    toggleAddPropertySidebar(show: boolean): void
    toggleProfileSidebar(show: boolean): void
    toggleAddUserSidebar(show: boolean): void
    toggleFilterSidebar(show: boolean): void
    toggleEditPropertySidebar(show: boolean): void
    toggleAddDocumentPopup(show: boolean): void
    toggleAddClientSidebar(show: boolean): void
    toggleAddContactSidebar(show: boolean): void
    toggleAssignRoleSidebar(show: boolean): void
    toggleTenancyActionSidebar(show: boolean): void
    toggleAddTeamMember(open: boolean, options?: IInviteTeamMemberOptions): void
    history: any
    location: any
    match: any
    is_impersonating: boolean
    app_loading: boolean
    overlay_show: boolean
    sidebar_show: boolean
    mobile_sidebar_show: boolean
    add_property_show: boolean
    profile_show: boolean
    upload_property_image_show: boolean
    add_user_show: boolean
    filter_show: boolean
    edit_property_id: string
    edit_property_show: boolean
    edit_regulation_popup: boolean
    add_document_popup: boolean
    add_client_show: boolean
    property_action_show: boolean
    add_contact_show: boolean
    assign_role_show: boolean
    user_id: string
    welcome_popup: boolean
    tenancy_popup: boolean
    issue_popup: boolean
    transaction_popup: boolean
    transaction_delete_popup: boolean
    unit_delete_popup: boolean
    tenancy_delete_popup: boolean
    user_delete_popup: boolean
    inspection_delete_popup: boolean
    document_delete_popup: boolean
    start_chat_popup: boolean
    sidebars: ISidebarState
    sticky_chat: Array<IStickyChatItem>
    is_sticky_chat_visible: boolean
    adding_team_member: boolean
    show_footer: boolean
}

interface IState {
    mobile_menu_toggle: boolean
    desktop_menu_toggle: boolean
}

class DashboardPage extends Component<IReduxProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            mobile_menu_toggle: false,
            desktop_menu_toggle: false,
        }

        this.onCloseModal = this.onCloseModal.bind(this)
        this.register = this.register.bind(this)
        this.unregister = this.unregister.bind(this)
    }

    componentWillReceiveProps(newProps: IReduxProps): void {
        if (!newProps.sidebars || newProps.sidebars == this.props.sidebars) {
            return
        }

        isSidebarOpen(
            newProps.sidebars,
            `${styles.sidebarOpen} ${
                this.props.mobile_sidebar_show ? styles.unsetOverflow : ''
            }`,
            styles.sidebarClosed
        )

        delayAction(200, () =>
            isSidebarOpen(
                newProps.sidebars,
                `${styles.sidebarOpen} ${
                    this.props.mobile_sidebar_show ? styles.unsetOverflow : ''
                }`,
                styles.sidebarClosed
            )
        )
    }

    register(): void {}

    unregister(): void {
        // WS Handlers - Unregister All
    }

    componentWillMount(): void {
        if (this.props.user_id == '') {
            this.props.getUserInformation()
        }
    }

    componentDidCatch(e: any): void {
        throw new Error(e)
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.checkSidebar.bind(this))
        this.unregister()
    }

    componentDidMount(): void {
        // Flag that keeps track of a user having ever logged in
        let hasLoggedInBefore = localStorage.getItem('rc-previous-login');
        if(!hasLoggedInBefore) localStorage.setItem('rc-previous-login', 'yes');

        this.register()

        let tourInformation = getLocalTourInformation(this.props.user_id)

        if (tourInformation && !tourInformation.dashboardTour) {
            tourInformation.dashboardTour = false
            setLocalTourInformation(tourInformation, this.props.user_id)
        }

        window.addEventListener('resize', this.checkSidebar.bind(this))

        getUnreadMessages().then((resp) => {
            if (resp && resp.status == 200) {
                let data: Array<any> = resp.data
                updateUnreadMessageCount(data.length)
            }
        })
    }

    onCloseModal(): void {
        toggleWelcomePopup(false)
    }

    checkSidebar() {
        // Check if any sidebars are open, if so hide the scrollbar
        isSidebarOpen(
            this.props.sidebars,
            `${styles.sidebarOpen} ${
                !this.props.mobile_sidebar_show ? styles.unsetOverflow : ''
            }`,
            styles.sidebarClosed
        )
    }

    render() {
        if (this.props.app_loading) {
            return <LoadingOverlay />
        }

        return (
            <div className={styles.page}>
                <ErrorsHoldMyBeer>
                    <PageMetadataController />
                    <EditDocumentContainer />

                    <ErrorsHoldMyBeer>
                        <div>
                            <AddTeamMemberSidePanel
                                isOpen={this.props.adding_team_member}
                                onSetOpen={(open) =>
                                    this.props.toggleAddTeamMember(open)
                                }
                            />

                            <AddPropertyFromQueryParamsPixel />

                            {this.props.add_document_popup && (
                                <AddDocumentModal />
                            )}

                            {this.props.issue_popup && <AddEditIssueModal />}

                            {this.props.transaction_popup && (
                                <AddEditTransactionModal />
                            )}

                            {this.props.transaction_delete_popup && (
                                <DeleteTransactionModal />
                            )}

                            {this.props.inspection_delete_popup && (
                                <DeleteInspectionModal />
                            )}

                            {this.props.unit_delete_popup && (
                                <DeleteUnitModal />
                            )}

                            {this.props.tenancy_delete_popup && (
                                <DeleteTenancyModal />
                            )}

                            {this.props.user_delete_popup && (
                                <DeleteUserModal />
                            )}

                            {this.props.document_delete_popup && (
                                <DeleteDocumentModal />
                            )}

                            <DeleteAssetModal />

                            {this.props.start_chat_popup && (
                                <StartChatModal
                                    open={true}
                                    onCloseModal={() =>
                                        toggleStartChatPopup(false)
                                    }
                                    onOpenModal={() =>
                                        toggleStartChatPopup(true)
                                    }
                                />
                            )}

                            <WelcomeToRentChiefModal
                                open={false}
                                onClose={() => this.onCloseModal()}
                                onSuccess={() => null}
                            />
                        </div>
                    </ErrorsHoldMyBeer>

                    <StickyContainer>
                        <AddPropertySidePanel
                            isOpen={this.props.add_property_show}
                            onSetOpen={this.props.toggleAddPropertySidebar}
                        />
                        <ProfileSidePanel
                            isOpen={this.props.profile_show}
                            onSetOpen={this.props.toggleProfileSidebar}
                        />
                        <AddUserSidePanel
                            isOpen={this.props.add_user_show}
                            onSetOpen={this.props.toggleAddUserSidebar}
                        />
                        <FilterSidePanel
                            isOpen={this.props.filter_show}
                            onSetOpen={this.props.toggleFilterSidebar}
                        />

                        <AddPropertySidePanel
                            //calculateReturn={() => null}
                            isOpen={this.props.edit_property_show}
                            onSetOpen={this.props.toggleAddPropertySidebar}
                        />

                        <AddClientUserPanel
                            isOpen={this.props.add_client_show}
                            onSetOpen={this.props.toggleAddClientSidebar}
                        />

                        <ChangeProfileImageSidePanel
                            isOpen={false}
                            onSetOpen={() => null}
                        />

                        <IssueActionPanel
                            isOpen={this.props.sidebars.issue_action}
                            onSetOpen={toggleIssueActionSidebar}
                        />

                        <AddContactSidePanel
                            isOpen={this.props.add_contact_show}
                            onSetOpen={this.props.toggleAddContactSidebar}
                        />

                        <AssignRoleUserPanel
                            isOpen={this.props.assign_role_show}
                            onSetOpen={this.props.toggleAssignRoleSidebar}
                        />

                        <AssignPermissionToRolePanel
                            isOpen={false}
                            onSetOpen={() => null}
                        />

                        <DashboardNavigationBar />

                        <div className={styles.container}>
                            <Row>
                                {/* Desktop Navigation Wrapper*/}
                                <Col
                                    className={`${
                                        styles.dashboardNavigationDesktop
                                    } ${
                                        this.props.sidebar_show && styles.sidebarOpen
                                    }`}
                                >
                                    <DashboardNavigationItems
                                        sticky
                                        small={this.props.sidebar_show}
                                    />
                                </Col>

                                {/* Desktop Navigation Toggler */}

                                {/* Desktop Navigation Toggler */}

                                {/* Mobile Navigation Wrapper */}
                                {this.props.mobile_sidebar_show && (
                                    <div
                                        className={
                                            styles.dashboardNavigationMobile
                                        }
                                    >
                                        <div className={styles.navContainer}>
                                            <OutsideClickHandler
                                                onOutsideClick={() => {
                                                    toggleMobileSidebarFalse()
                                                }}
                                            >
                                                <DashboardNavigationItems />
                                            </OutsideClickHandler>
                                        </div>
                                    </div>
                                )}

                                <Col
                                    className={`${styles.pageContainer} ${
                                        this.props.sidebar_show && styles.sidebarOpen
                                    }`}
                                >
                                    <Alert />

                                    {false &&
                                        this.props.sticky_chat &&
                                        this.props.sticky_chat.length > 0 && (
                                            <div className={styles.stickyChats}>
                                                <ul>
                                                    {this.props.sticky_chat.map(
                                                        (sc) => {
                                                            return (
                                                                <li>
                                                                    <p
                                                                        onClick={() => {
                                                                            toggleShowHideStickyChatItem(
                                                                                sc.chatSessionId,
                                                                                !sc.open
                                                                            )
                                                                        }}
                                                                    >
                                                                        {
                                                                            sc.title
                                                                        }{' '}
                                                                        <div
                                                                            className={
                                                                                styles.close
                                                                            }
                                                                        >
                                                                            <CloseButton
                                                                                close={() =>
                                                                                    removeStickyChatItem(
                                                                                        sc.chatSessionId
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </p>
                                                                    {sc.open && (
                                                                        <ChatBox
                                                                            hideUsers
                                                                            options={{
                                                                                chatSessionId:
                                                                                    sc.chatSessionId,
                                                                            }}
                                                                        />
                                                                    )}
                                                                </li>
                                                            )
                                                        }
                                                    )}
                                                </ul>
                                            </div>
                                        )}

                                    <ErrorsHoldMyBeer>
                                        <Route
                                            path={`${this.props.match.path}`}
                                            exact
                                            component={DashboardHome}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/profile`}
                                            component={EditProfilePage}
                                        />
                                        {
                                            <Route
                                                path={`${this.props.match.path}/properties`}
                                                exact
                                                component={PropertiesListPage}
                                            />
                                        }
                                        <Route
                                            path={`${this.props.match.path}/research`}
                                            component={ResearchPage}
                                        />
                                        <PlatformAdminOrClientAdminRoute
                                            path={`${this.props.match.path}/manage`}
                                            component={PlatformManagementPage}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/property/:propertyId`}
                                            component={PropertyPage}
                                        />
                                        <LandlordRoute
                                            path={`${this.props.match.path}/contacts`}
                                            component={ContactPage}
                                        />
                                        <LandlordRoute
                                            path={`${this.props.match.path}/contact/:contactId`}
                                            component={ContactDetail}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/tenancies`}
                                            render={() => (
                                                <TenancyGrid config={{}} />
                                            )}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/tenancy/view/:tenancyId`}
                                            component={TenancyContainerPage}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/tenancy/add`}
                                            exact
                                            render={() => (

                                                    <TenancyContainerPage />
                                            )}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/documents`}
                                            render={() => (
                                                <DocumentsPage
                                                    groupBy={['propertyName']}
                                                />
                                            )}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/properties/data-import`}
                                            component={PropertyImportPage}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/issues/:issueId?`}
                                            component={IssueListPage}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/issue/:issueId`}
                                            component={IssueDetail}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/issue-list`}
                                            render={() => (
                                                <IssueList
                                                    goToIssueDirect={true}
                                                />
                                            )}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/chats/:chatSessionId?`}
                                            component={ChatListPage}
                                        />

                                        <Route
                                            path={`${this.props.match.path}/assets`}
                                            render={() => (
                                                <div>
                                                    <AssetsGrid config={{}} />
                                                </div>
                                            )}
                                        />

                                        <Route
                                            path={`${this.props.match.path}/asset/add`}
                                            component={AddAsset}
                                        />

                                        <Route
                                            path={`${this.props.match.path}/asset/view/:assetId`}
                                            render={() => <AssetDetails />}
                                        />

                                        <Route
                                            path={`${this.props.match.path}/tasks`}
                                            render={() => (
                                                <div className={styles.assetPage}>
                                                    <TasksListPage />
                                                </div>
                                            )}
                                        />

                                        <Route
                                            path={`${this.props.match.path}/task/add`}
                                            exact
                                            render={() => (
                                                <div className={styles.assetPage}>
                                                    <TaskDetailsPageContainer />
                                                </div>
                                            )}
                                        />

                                        <Route
                                            path={`${this.props.match.path}/task`}
                                            exact
                                            render={() => (
                                                <div className={styles.assetPage}>
                                                    <TaskDetailsPageContainer />
                                                </div>
                                            )}
                                        />

                                        <ClientAreaRestriction restriction="InspectionsFunctions">
                                            <React.Fragment>
                                                <Route
                                                    path={`${this.props.match.path}/inspections`}
                                                    render={() => (
                                                        <InspectionsGrid
                                                            config={{
                                                                addSettings:
                                                                    {
                                                                        returnUrl: `/dashboard/inspections`,
                                                                    },
                                                                groupSettings:
                                                                    [],
                                                            }}
                                                        />
                                                    )}
                                                />

                                                <Route
                                                    path={`${this.props.match.path}/inspection/add`}
                                                    render={() => (
                                                        <AddEditInspection />
                                                    )}
                                                />

                                                <Route
                                                    path={`${this.props.match.path}/inspection/view/:inspectionId`}
                                                    render={() => (
                                                        <InspectionDetailPage />
                                                    )}
                                                />
                                            </React.Fragment>
                                        </ClientAreaRestriction>
                                        <Route
                                            path={`${this.props.match.path}/financials/transactions`}
                                            render={() => (
                                                <TransactionsGridContainer context='none' />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path={`${this.props.match.path}/units`}
                                            render={() => (
                                                <UnitGrid
                                                    defaultAvailableToRent={
                                                        false
                                                    }
                                                    config={{
                                                        groupSettings: [
                                                            'propertyId',
                                                        ],
                                                    }}
                                                    onAdd={() =>
                                                        this.props.history.push(
                                                            '/dashboard/unit/add'
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/units/multi-add`}
                                            render={() => (
                                                <PropertyConfigurationForm />
                                            )}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/unit/view/:unitId`}
                                            render={() => (
                                                <UnitDetailPage
                                                    unitId={
                                                        this.props.match.params
                                                            .unitId
                                                    }
                                                />
                                            )}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/unit/add`}
                                            render={() => (
                                                <AddEditUnit
                                                    onUpdateCallback={() =>
                                                        this.props.history.push(
                                                            '/dashboard/units'
                                                        )
                                                    }
                                                    onSuccessCallback={() =>
                                                        this.props.history.push(
                                                            '/dashboard/units'
                                                        )
                                                    }
                                                />
                                            )}
                                        />

                                        {canAccessTeamList() && (
                                            <Route
                                                path={`${this.props.match.path}/settings`}
                                                render={() => (
                                                    <div
                                                        className={
                                                            styles.assetPage
                                                        }
                                                    >
                                                        <SettingsPage />
                                                    </div>
                                                )}
                                            />
                                        )}
                                    </ErrorsHoldMyBeer>
                                </Col>

                                {false && (
                                    <div>
                                        {this.props.is_sticky_chat_visible ? (
                                            <Col className={styles.stickyChat}>
                                                <div
                                                    className={
                                                        styles.stickyChatFixed
                                                    }
                                                >
                                                    <ChatList
                                                        onClickChatItem={(
                                                            item: IMessageListItem
                                                        ) =>
                                                            addToStickyChat({
                                                                title: item.title,
                                                                chatSessionId:
                                                                    item.chatSessionId,
                                                                open: true,
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </Col>
                                        ) : (
                                            <div
                                                className={
                                                    styles.stickyChatToggle
                                                }
                                                onClick={() =>
                                                    toggleStickyChat(true)
                                                }
                                            >
                                                <img src={chatIcon} />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Row>

                            {this.props.show_footer ? <Footer /> : ""}
                        </div>
                    </StickyContainer>
                </ErrorsHoldMyBeer>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    is_impersonating: state.app.is_impersonating,
    sidebars: state.app.sidebars,
    app_loading: state.app.app_loading,
    overlay_show: state.app.overlay_show,
    sidebar_show: state.app.sidebars.side_nav,
    mobile_sidebar_show: state.app.sidebars.mobile_side_nav,
    add_property_show: state.app.sidebars.add_property,
    add_contact_show: state.app.sidebars.add_contact,
    profile_show: state.app.sidebars.profile,
    upload_property_image_show: state.app.sidebars.upload_property_image,
    add_user_show: state.app.sidebars.add_user,
    filter_show: state.app.sidebars.filter,
    edit_property_id: state.app.sidebars.edit_property.propertyId,
    edit_property_show: state.app.sidebars.edit_property.show,
    add_document_popup: state.app.popups.add_document_popup,
    edit_regulation_popup: state.app.popups.edit_regulation_popup,
    add_client_show: state.app.sidebars.add_client,
    assign_role_show: state.app.sidebars.assign_role,
    user_id: state.user.user_id,
    welcome_popup: state.app.popups.welcome_popup,
    tenancy_popup: state.app.popups.tenancy_popup,
    issue_popup: state.app.popups.issue_popup,
    transaction_popup: state.app.popups.transaction_popup,
    transaction_delete_popup: state.app.popups.transaction_delete_popup,
    unit_delete_popup: state.app.popups.unit_delete_popup,
    tenancy_delete_popup: state.app.popups.tenancy_delete_popup,
    user_delete_popup: state.app.popups.user_delete_popup,
    start_chat_popup: state.app.popups.start_chat_popup,
    document_delete_popup: state.app.popups.document_delete_popup,
    inspection_delete_popup: state.app.popups.inspection_delete_popup,
    sticky_chat: state.chat.sticky_chat,
    is_sticky_chat_visible: state.chat.is_sticky_chat_visible,
    adding_team_member: state.teams.adding,
    show_footer: state.app.show_footer
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getUserInformation: () => {
            dispatch<any>(getUserInformation())
        },
        dispatchOverlay: (show: boolean) => {
            dispatch(dispatchOverlay(show))
        },
        toggleSidebar: (show: boolean) => {
            dispatch(toggleSidebar(show))
        },
        toggleAddPropertySidebar: (show: boolean) => {
            dispatch(toggleAddPropertySidebar(show))
        },
        toggleProfileSidebar: (show: boolean) => {
            dispatch(toggleProfileSidebar(show))
        },
        toggleAddUserSidebar: (show: boolean) => {
            dispatch(toggleAddUserSidebar(show))
        },
        toggleEditPropertySidebar: (show: boolean) => {
            dispatch(toggleEditPropertySidebar(show))
        },
        toggleAddDocumentPopup: (show: boolean) => {
            dispatch(toggleAddDocumentPopup(show))
        },
        toggleAddClientSidebar: (show: boolean) => {
            dispatch(toggleAddClientSidebar(show))
        },
        toggleTenancyActionSidebar: (show: boolean) => {
            dispatch(toggleTenancyActionSidebar(show))
        },
        toggleAddContactSidebar: (show: boolean) => {
            dispatch(toggleAddContactSidebar(show))
        },
        toggleAssignRoleSidebar: (show: boolean) => {
            dispatch(toggleAssignRoleSidebar(show))
        },
        toggleAddTeamMember: (
            show: boolean,
            options?: IInviteTeamMemberOptions
        ) => {
            dispatch(toggleAddTeamMember(show, options) as any)
        },
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DashboardPage) as any
)
