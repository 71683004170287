import { Dispatch } from "redux";
import axios from "axios";
import { propertyService } from "../configuration/domains";
import { createBearerToken } from "../helpers/authHelpers";
import { toggleAddPropertySidebar } from "../actions/appActions";
import { getCalculateReturnsData } from "../services/PropertyService";
import { ICalculateReturnsData } from "../reducers/propertyReducer";
import { IAddress, IUpdateProperty } from "../types/PropertyService/IPropertyService";

export const updateAddPropertyRequest = (request: any) => {
  return (dispatch: Dispatch) => {
    dispatch(dispatchUpdateAddPropertyRequest(request));
  };
};

export const addProperty = (request: any) => {
  return (dispatch: Dispatch) => {
    dispatch(dispatchAppLoadingTrue());

    return axios
      .post(`${propertyService}/add`, request, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: createBearerToken(),
        },
      })
      .then((response) => {
        if (response.data != null) {
          dispatch(dispatchAddPropertySuccess(response.data));
          dispatch(dispatchAppLoadingFalse());
          dispatch(toggleAddPropertySidebar(false));
        }
      });
  };
};

export const getPropertyReturnsData = (propertyId: string) => {
  return async (dispatch: Dispatch) => {
    let response = await getCalculateReturnsData(propertyId);

    if (
      response.data != undefined &&
      response.data.property != null &&
      response.data.address != null
    ) {
      return dispatch(dispatchUpdateCalculateReturnsProperty(response.data));
    }
  };
};

export const dispatchEditProperty = (propertyId: string) => {
  return {
    type: "EDIT_PROPERTY",
    data: propertyId,
  };
};

export const dispatchEditPropertyClear = () => {
  return {
    type: "EDIT_PROPERTY_CLEAR",
  };
};

export const dispatchAddPropertySuccess = (data: any) => {
  return {
    type: "ADD_PROPERTY_SUCCESS",
    data,
  };
};

export const dispatchUpdateAddPropertyRequest = (data) => {
  return {
    type: "UPDATE_ADD_PROPERTY_REQUEST",
    data,
  };
};

export const dispatchAppLoadingTrue = () => {
  return {
    type: "APP_LOADING_TRUE",
  };
};

export const dispatchAppLoadingFalse = () => {
  return {
    type: "APP_LOADING_FALSE",
  };
};

export const dispatchUpdateCalculateReturnsProperty = (
  data: ICalculateReturnsData
) => {
  return {
    type: "UPDATE_CALCULATE_RETURNS_PROPERTY",
    data,
  };
};

export const dispatchUpdateCalculateReturnsFromEdit = (
  data: IUpdateProperty
) => {
  return {
    type: "UPDATE_CALCULATE_RETURNS_PROPERTY_FROM_EDIT",
    data,
  };
};

export const dispatchUpdateCalculateReturnsPropertyAddress = (
  data: IAddress
) => {
  return {
    type: "UPDATE_CALCULATE_RETURNS_PROPERTY_ADDRESS",
    data,
  };
};

export const dispatchResetCalculateReturnsProperty = () => {
  return {
    type: "RESET_CALCUATE_RETURNS_PROPERTY",
  };
};

export const dispatchUpdatePropertyList = (data: any) => {
  return {
    type: "UPDATE_PROPERTY_LIST",
    data,
  };
};

export const dispatchResetPropertyList = () => {
  return {
    type: "RESET_PROPERTY_LIST",
  };
};
