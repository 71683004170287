import styled from 'styled-components';

export const GetStartedDiv = styled.div`
    background-color: lightgrey;
    border-radius: 20px;
`;

export const SectionDiv = styled.div`
    button {
        &:hover {
            background-color: ${({ theme }) => theme.buttons.primaryHover};
          }
    }
`;

export const ActionDiv = styled.div`
    width: 150px;
`;

export const PageWrapperDiv = styled.div`
    width: 100%;
    margin: auto;
`;

export const ResetEditDiv = styled.div`
    width: 150px;
    &:hover {
        cursor: pointer;
    }

    label {
        color: #0085CA;
        text-decoration: underline;
        padding-left: 5px;
        &:hover {
            cursor: pointer;
        }
    }

    img {

    }
`;

export const ResetEditWrapperDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ResetEditInnerWrapperDiv = styled.div`
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

