import React, { Component } from "react";
import { Timeline, TimelineEvent } from "react-event-timeline";
import { getAudits } from "../../../../services/AuditService";

// Import Styles
import styles from "./AuditPage.module.scss";
import SearchBar from "../../../atoms/SearchBar";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import {
  IAuditListResponse,
  IAuditResponse
} from "../../../../types/AuditService";
import InfiniteScroll from "react-infinite-scroll-component";
import { IAtAGlance } from "../../../../types/AuditService";
import RotatingLoader from "../../../atoms/RotatingLoader";
import { withRouter } from "react-router-dom";
import { IListItemOptions } from "../../../../types/ListFilters";
import DateTimeFormat from "../../../atoms/DateTimeFormat";
import GenericPopup from "../../../atoms/GenericPopup";
import { generateId } from "../../../../helpers/randomHelpers";
import { QuickViewType } from "../../../../types/QuickView";

// SignalR
import ProfileImage from "../../../atoms/ProfileImage";
import SelectDropdown, { IOptions } from "../../../atoms/SelectDropdown";
import { getClientsDropdown } from "../../../../services/ClientService";

interface IState {
  audits: Array<IAuditResponse>;
  dashboardData: IAtAGlance;
  nextPage: number;
  totalAudits: number;
  filterAmount: number;
  page: number;
  size: number;
  loading: boolean;
  newEventCount: number;
  clients: Array<IOptions>;
  selectedClientId?: string;
}

type Props = {
  userId?: string;
  tenancyId?: string;
  clientId?: string;
  propertyId?: string;
  contactId?: string;
  issueId?: string;
  unitId?: string;
  inspectionId?: number;
  assetId?: number;
  hideDropdown?: boolean;
};

interface IProps {
  history: any;
  match: any;
  location: any;
}

type Prop = IProps & Props;

class AuditPage extends Component<Prop, IState> {
  constructor(props: Prop) {
    super(props);

    this.state = {
      dashboardData: {
        exceptionsInPast24Hours: 0,
        loginsInPast24Hours: 0,
        eventsInPast24Hours: 0
      },
      audits: [],
      nextPage: 0,
      totalAudits: 0,
      filterAmount: 8,
      page: 1,
      size: 8,
      loading: false,
      newEventCount: 0,
      clients: [],
      selectedClientId: this.props.clientId
    };

    this.fetchMore = this.fetchMore.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.buildListItemRequest = this.buildListItemRequest.bind(this);
    this.messageAdded = this.messageAdded.bind(this);
    this.getClients = this.getClients.bind(this);
  }

  getClients(): void {
    getClientsDropdown().then(resp => {
      this.setState({
        clients: resp.data
      });
    });
  }

  toggleLoading(loading: boolean): void {
    this.setState({
      loading
    });
  }

  fetchMore(): void {
    let request = this.buildListItemRequest(
      this.props.contactId,
      this.props.tenancyId,
      this.props.userId,
      this.props.propertyId,
      this.props.issueId,
      this.state.selectedClientId,
      this.props.unitId,
      this.props.inspectionId,
    );
    request.page = this.state.page;
    request.size = this.state.size;

    if (this.props.assetId) {
      request.assetId = this.props.assetId;
    }

    this.toggleLoading(true);
    getAudits(request).then(resp => {
      if (resp && resp.data) {
        let response: IAuditListResponse = resp.data;
        let newAudits = this.state.audits.concat(resp.data.audits);
        this.setState({
          audits: newAudits,
          page: resp.data.nextPage,
          totalAudits: response.totalAudits
        });
      }
      this.toggleLoading(false);
    });
  }

  buildListItemRequest(
    contactId?: string,
    tenancyId?: string,
    userId?: string,
    propertyId?: string,
    issueId?: string,
    clientId?: string,
    unitId?: string,
    inspectionId?: number,
    assetId?: number
  ): IListItemOptions {
    let request: IListItemOptions = {
      page: this.state.page,
      size: this.state.size
    };

    if (issueId) {
      request.issueId = issueId;
    }

    if (contactId) {
      request.contactId = contactId;
    }

    if (tenancyId) {
      request.tenancyId = tenancyId;
    }

    if (userId) {
      request.userId = userId;
    }

    if (propertyId) {
      request.propertyId = propertyId;
    }

    if (clientId) {
      request.clientId = clientId;
    }

    if (unitId) {
      request.unitId = unitId;
    }

    if (inspectionId) {
      request.inspectionId = inspectionId;
    }

    if (assetId) {
      request.assetId = assetId;
    }

    return request;
  }

  messageAdded(msg: any) {
    this.setState({
      newEventCount: this.state.newEventCount + 1
    });
  }

  componentDidMount(): void {
    this.getClients();

    let request = this.buildListItemRequest(
      this.props.contactId,
      this.props.tenancyId,
      this.props.userId,
      this.props.propertyId,
      this.props.issueId,
      this.state.selectedClientId,
      this.props.unitId,
      this.props.inspectionId
    );

    if (this.props.assetId) {
      request.assetId = this.props.assetId;
    }

    try {
      this.toggleLoading(true);
      getAudits(request).then(resp => {
        let response: IAuditListResponse = resp.data;
        if (
          resp &&
          resp.data &&
          response.audits &&
          response.audits.length > 0
        ) {
          this.setState({
            audits: resp.data.audits,
            page: resp.data.nextPage,
            totalAudits: response.totalAudits,
            dashboardData: {
              eventsInPast24Hours: response.eventsInPast24Hours,
              exceptionsInPast24Hours: response.exceptionsInPast24Hours,
              loginsInPast24Hours: response.loginsInPast24Hours
            }
          });
        }
        this.toggleLoading(false);
      });
    } catch (error) {
      throw "Error";
    }
  }

  render() {
    return (
      <div className={styles.generalPage}>
        <Row>
          <Col className={styles.page}>
            <SearchBar
              text="Search for timeline event (User, Description)..."
              onSearch={() => null}
            />

            <Row className={styles.loaded}>
              {
                !this.props.hideDropdown &&
                <Col className={styles.dropdown}>
                  <SelectDropdown
                    placeholder="Select a client..."
                    showAllOption
                    showDefaultOption
                    selectedId={
                      this.state.selectedClientId
                    }
                    onSelect={(selected: IOptions) => {
                      this.setState({
                        selectedClientId: selected.value,
                        audits: [],
                        page: 1
                      }, () => this.fetchMore())
                    }}
                    data={this.state.clients}
                  />
                </Col>
              }

              <Col className={styles.loadedIn}>
                {" "}
                <div>
                  Loaded {this.state.audits.length}/{this.state.totalAudits}{" "}
                  audits
                </div>
              </Col>
            </Row>

            {!this.state.loading && this.state.audits.length == 0 && (
              <div>No timeline records found</div>
            )}


            <div className={styles.list}>
              <InfiniteScroll
                className={styles.infiniteScroll}
                dataLength={this.state.totalAudits}
                next={this.fetchMore}
                hasMore={this.state.audits.length < this.state.totalAudits}
              >
                {this.state.audits && this.state.audits.length > 0 && (
                  <Timeline lineColor={"$colour-blue-1"}>
                    {this.state.audits.map((audit, index) => {
                      return (
                        <TimelineEvent
                          iconStyle={{ width: "50px", height: "50px" }}
                          bubbleStyle={{
                            overflow: "hidden",
                            border: "1px solid $colour-blue-1"
                          }}
                          className={styles.timelineEvent}
                          title={
                            <div>
                              {audit.title} by {audit.forename} {audit.surname}
                              <div className={styles.popup}>
                                <GenericPopup
                                  identifier={generateId(10)}
                                  options={{
                                    userId: audit.userId,
                                    type: QuickViewType.User
                                  }}
                                />
                              </div>
                              {audit.propertyId && (
                                <div className={styles.popup}>
                                  <span className={styles.label}>Property</span>
                                  <GenericPopup
                                    identifier={generateId(10)}
                                    options={{
                                      propertyId: audit.propertyId,
                                      type: QuickViewType.Property
                                    }}
                                  />
                                </div>
                              )}
                              {false && audit.tenancyId && (
                                <div className={styles.popup}>
                                  <span className={styles.label}>Tenancy</span>
                                  <GenericPopup
                                    identifier={generateId(10)}
                                    options={{
                                      tenancyId: audit.tenancyId,
                                      type: QuickViewType.Tenancy
                                    }}
                                  />
                                </div>
                              )}
                              {audit.contactId && (
                                <div className={styles.popup}>
                                  <span className={styles.label}>Contact</span>
                                  <GenericPopup
                                    identifier={generateId(10)}
                                    options={{
                                      contactId: audit.contactId,
                                      type: QuickViewType.Contact
                                    }}
                                  />
                                </div>
                              )}
                              {audit.documentId && (
                                <div className={styles.popup}>
                                  <span className={styles.label}>Document</span>
                                  <GenericPopup
                                    identifier={generateId(10)}
                                    options={{
                                      documentId: audit.documentId,
                                      type: QuickViewType.Document
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          }
                          createdAt={
                            <div>
                              <DateTimeFormat
                                showTimeAgoFormat
                                rawDateTime={audit.occured}
                                showOnlyDateFormat={false}
                              />
                              <GenericPopup
                                identifier={generateId(10)}
                                options={{
                                  eventId: audit.auditId,
                                  type: QuickViewType.Event
                                }}
                              />
                            </div>
                          }
                          icon={
                            <div>
                              <GenericPopup
                                identifier={generateId(10)}
                                options={{
                                  userId: audit.userId,
                                  type: QuickViewType.User,
                                  toggleComponent: audit.profileImage ? (
                                    <div className={styles.profileImage}>
                                      <ProfileImage
                                        image={audit.profileImage}
                                        forename={audit.forename}
                                        surname={audit.surname}
                                        large={false}
                                      />
                                    </div>
                                  ) : (
                                      <ProfileImage
                                        image={null}
                                        forename={audit.forename}
                                        surname={audit.surname}
                                        large={false}
                                      />
                                    )
                                }}
                              />
                            </div>
                          }
                          key={index}
                        >
                          {audit.body}
                        </TimelineEvent>
                      );
                    })}
                  </Timeline>
                )}
              </InfiniteScroll>

              <RotatingLoader
                text="Loading timeline..."
                loading={this.state.loading}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(AuditPage);
