import React from 'react';
import * as S from './ResearchCouncilPart.styles';

interface IProps {
    mainLink: string;
    taxLink: string;
    hmoLink: string;
}

const ResearchCouncilPart = (props: IProps) => (
    <S.Section>
        <tbody>
            <tr>
                <a href={props.mainLink} target="_blank">
                    <td>Council home page</td>
                    <td>
                        <S.Chevron />
                    </td>
                </a>
            </tr>
            <tr>
                <a href={props.taxLink} target="_blank">
                    <td>Council tax information</td>
                    <td>
                        <S.Chevron />
                    </td>
                </a>
            </tr>
            <tr>
                <a href={props.hmoLink} target="_blank">
                    <td onClick={() => window.open(props.hmoLink, '_blank')}>HMO information</td>
                    <td>
                        <S.Chevron />
                    </td>
                </a>
            </tr>
        </tbody>
    </S.Section>
);

export { ResearchCouncilPart };