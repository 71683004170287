export const defaultState = {
    hasLoaded: false,
    propertyName: '',
    findAddressResult: null,
    enterAddressManually: false,
    purchasePrice: 200000,
    investmentAmount: 50000,
    email: '',
    yearsOfInvestment: 7,
    monthlyRentalAmount: 800,
    deposit: 50000,
    postcode: '',
    address: {
        building_Name: '',
        building_Number: '',
        sub_Building_Name: '',
        sub_Building_Number: '',
        line1: '',
        line2: '',
        street_Name:'',
        town: '',
        county: '',
        locality: '',
        district: '',
        country: '',
        postcode: '',
    },
    mortgageAmount: 0,
    emailIsAlreadyAdded: false,
    showAssumptionInputs1: false,
    submitting: false,
    loading: false,
    propertyStatus: '',
    propertyStatusName: '',
    ActivePropertyStatus: [],
    isLandRegistryMatch: false,
    isLandRegistryPopupOpen: false,
    moreInformation: null,
    propertyMortgageStatus: 1,
    tenantInResidenceStatus: 2,
    dateOfPurchase: new Date(),
    editPropertyId: '',
    isEditProperty: false,
    addPropertyTour: false,
    depositOnStartChange: 0,
    isDepositChanged: false,
    priceValuation: false,
    rentalPriceValuation: false,

    // Fields that we get from Property Data API Valuation
    propertyType: null,
    finishQuality: null,
    outdoorSpace: null,
    parking: null,
    size: null,
    sizeType: null,
    numberOfParkingSpaces: null,
    bedrooms: null,
    bathrooms: null,
    yearBuilt: null,
    propertyData: null,

    hasUpdatedAddress: false,
    initialPostcode: null,
    propertyContacts: null
}
