import { IAddress } from "../PropertyService/IPropertyService";

export enum QuickViewType {
    Contact,
    Property,
    Tenancy,
    Document,
    Profile,
    User,
    Event,
    Issue,
    Valuation,
    Transaction
}

export interface IQuickViewIssue {
    issueId: string;
    issueStatus: string;
    issueCategory: string;
    createdOn: string;
    propertyName?: string;
    issueTitle: string;
}

export interface IQuickViewEvent {
    browserName: string;
    browserPlatform: string;
    ipAddress: string;
}

export interface IQuickViewProfile {
    contactId: string;
    userId?: string;
    forename: string;
    surname: string;
    email: string;
    profileImageUri: string;
    contactType: string;
    contactTypeId: string;
    phoneNumber: string;
    address: IAddress;
    isUser: boolean;
    role: string;
}

export interface IQuickViewDocument {
    documentId: string;
    propertyId: string;
    contactId: string;
    issueId: string;
    tenancyId: string;
    unitId: string;
    transactionId: string;
    documentName: string;
    documentDescription: string;
    uploadedOn: string;
    size: string;
    uri: string;
    documentType: string;
    fileType: string;
}

export interface IQuickViewValuation {
    source: string;
    amount: number;
    margin: number;
    isRental: boolean;
    userId?: string;
    dateTime: string;
}

export interface IQuickViewProperty {
    propertyId: string;
    propertyName: string;
    address: IAddress;
    propertyImageUri: string;
}

export interface IUserContactInfoRequest {
    userId?: string;
    contactId?: string;
}

export interface IUserContactInfoResponse {
    userId?: string;
    contactId?: string;
    forename: string;
    surname: string;
    profileImage: string;
    typeOrRole: string;
    isUser: boolean;
}