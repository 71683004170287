import React from 'react';
import Modal from 'react-responsive-modal'
import DatePicker from 'react-datepicker'
import { Formik, FormikProps } from 'formik';
import { RCCurrencyInput } from '@rentchief/components';
import Buttons from '../../../../atoms/Buttons'
import * as S from "./ValuationEntryModal.styles";
import styles from './ValuationEntryModal.module.scss'


interface sectionProps {
    header: string;
    onClose(): void;
    open: boolean;
    currentValuation: number;
    onUpdateValuation(value: number, date: Date): void
    valueInputHeader?: string;
}

const ValuationEntryModal = ({header, onClose, open, currentValuation, onUpdateValuation, valueInputHeader}: sectionProps) => {
    const valueInput = React.createRef<any>();

    return (
        <Modal
            open={open}
            onClose={() => onClose()}
            center
            classNames={{
                //overlay: 'customOverlay',
                modal: `${styles.customModal}`,
                }}
            focusTrapped={true}
            initialFocusRef={valueInput}
        >
            <S.ModalHeaderDiv>{header}</S.ModalHeaderDiv>
            <Formik
                initialValues={{ nValue: currentValuation, nDate: new Date() }}
                onSubmit={(v) => onUpdateValuation(v.nValue, v.nDate) }
            >
                {(props: FormikProps<{
                    nValue: number;
                    nDate: Date;
                }>) => (
                <>
                    <S.InputWrapperDiv>
                        <S.LabelInputWrapperDiv>
                            <S.LabelDiv>Valuation Date</S.LabelDiv>
                            <DatePicker className={styles.currentDateInputField}
                                todayButton="Today"
                                dateFormat="dd/MM/yyyy"
                                selected={props.values.nDate}
                                onChange={(date: Date) => props.setFieldValue("nDate", date)}
                                popperPlacement="bottom-end"
                            />
                        </S.LabelInputWrapperDiv>
                        <S.LabelInputWrapperDiv>
                            <S.LabelDiv>{valueInputHeader ? valueInputHeader : "Value"}</S.LabelDiv>
                            <div className={styles.rcCurrencyInput}>
                                <RCCurrencyInput
                                    prefix={"£"}
                                    id="purchasePrice"
                                    value={props.values.nValue}
                                    handleChange={(e) => props.setFieldValue("nValue", e)}
                                    innerRef={valueInput}
                                />
                            </div>
                        </S.LabelInputWrapperDiv>
                    </S.InputWrapperDiv>
                    
                    <Buttons
                        buttons={[
                            {
                                text: 'Cancel',
                                displayType: 'cancel',
                                elementType: 'button',
                                onClick: () => onClose(),
                            },
                            {
                                text: 'Save',
                                displayType: 'submit',
                                elementType: 'button',
                                onClick: () => props.submitForm(),
                            },
                        ]}
                        align='center'
                    />
                </>

            )}
            </Formik>

        </Modal>
    )
}

export default ValuationEntryModal;