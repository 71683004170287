import React, { useContext, useEffect, useState } from 'react';
import ConductInspectionContext from '../../../../types/Dynamic/conductInspectionContext';
import { QuestionComponentProps } from '../../../../types/Dynamic/types';
import Error from '../Error';
import { Required } from '../Validations';
import styles from './MultiSelect.module.scss';
import MultiSelectItem from './MultiSelectItem';

const MultiSelect = ({ validations, objectRoute, content, onChange, resultValue, isReadOnly }: QuestionComponentProps) => {
    const { updateValidations, removeValidation, addValidationFn } = useContext(ConductInspectionContext);
    const [initialLoad, setInitialLoad] = useState(false);

    const validate = (): { isError } => {
        if (validations) {
            const { required } = validations;
            if (required) {
                if (Required(resultValue)) {
                    updateValidations(objectRoute, validations.errorMessage || 'This input is not valid');
                    return { isError: true }
                } else {
                    removeValidation(objectRoute)
                    return { isError: false }
                }
            }
            return { isError: false }
        }
 
        return { isError: false }
    }

    useEffect(() => {
        if (initialLoad) {
            validate();
        }

        addValidationFn(objectRoute, validate);
        setInitialLoad(true);
    }, [resultValue])

    return (
        <div>
            <div className={styles.multiselect}>
                {content.map(option => <div className={styles.item}>
                    <MultiSelectItem isReadOnly={isReadOnly} selected={resultValue} onChange={onChange} option={option} />
                </div>)}
            </div>
            <Error name={objectRoute} />
        </div>
    )
}

export default MultiSelect;