import styled from 'styled-components';

export const SectionDiv = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 100%; 
    grid-template-areas:
        'header'
        'body'
`; 

export const bodyDiv = styled.div`
    background-color: #F5F5F5;
    border-radius: 20px;
    padding-top: 10px;
`;

export const bodyContentDiv = styled.div`
    margin: auto;
    width: 94%;
    grid-area: body;
    display: grid;
    grid-template-columns: 50% 50%; 
    grid-template-rows: 50px 50px;
    grid-template-areas:
        'updateProfile createTenancy'
        'addProperty inviteMember';
    align-items: center;
    justify-items: start;
`;

export const updateProfileDiv = styled.div`
    grid-area: updateProfile;
`;

export const createTenancyDiv = styled.div`
    grid-area: createTenancy;
`;

export const addPropertyDiv = styled.div`
    grid-area: addProperty;
`;

export const inviteMemberDiv = styled.div`
    grid-area: inviteMember;
`;

