import React from "react";

import styles from "./Viewing.module.scss";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import ProfileImage from "../ProfileImage";
import { IUserListItem } from "../../../types/ClientService";

interface IProps {
  forename: string;
  surname: string;
  user: IUserListItem;
}

class Viewing extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <Col xs="2" className={styles.viewingAtom}>
        <Row>
          <Col xs="2">
            <ProfileImage
              forename={this.props.user && this.props.user.forename}
              surname={this.props.user && this.props.user.surname}
              image={this.props.user && this.props.user.profileImageUri}
              large={false}
            />
          </Col>

          <Col>
            <div className={styles.viewing}>
              <div className={styles.label}>Viewing:</div>
              <p>
                {this.props.user && this.props.forename} {this.props.user && this.props.surname}
              </p>
            </div>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default Viewing;
