// Import React
import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import styles from "./UserChatsPage.module.scss";
import ChatListPage from "../../ChatListPage";

interface IState {
  
}

interface IRouterProps {
  history: any;
  location: any;
  match: any;
}

type Props = IRouterProps;

class UserChatsPage extends Component<Props, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      
    };
  }

  render() {
    return (
      <div className={styles.page}>
        <ChatListPage options={{ userId: this.props.match.params.userId }} />
      </div>
    );
  }
}

export default withRouter(UserChatsPage);