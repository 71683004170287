import { addURLParameter } from "../../../../../helpers/locationHelpers";
import { toggleDeleteTenancyPopup } from "../../../../../helpers/tenancyHelpers";

export function onDeleteRows(
  tenancyIds: Array<string>,
  location: any,
  history: any
): void {
  let query = addURLParameter(
    location.search,
    "deleteTenancy=" + JSON.stringify(tenancyIds)
  );

  history.push({
    search: query
  });

  toggleDeleteTenancyPopup(true);
}