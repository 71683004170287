import React from 'react';
import moment from 'moment';
import TimeAgo from 'timeago-react';
import ReactTooltip from "react-tooltip";

interface IProps {
    rawDateTime: string;
    showOnlyDateFormat?: boolean;
    showTimeAgoFormat?: boolean;
    showOnlyShortDate?: boolean;
    showYesterdayText?: boolean;
    showShortDateAndTime?: boolean;
    isChat?: boolean;
}

var REFERENCE = moment();
var TODAY = REFERENCE.clone().startOf('day');
var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
var A_WEEK_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');

class DateTimeFormat extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    result = (content: any) => {
        return <span data-tip={moment(this.props.rawDateTime).format('MMMM Do YYYY, h:mm:ss')}><ReactTooltip />
            {content}
        </span>
    }

    differenceInDays(): number {
        return moment().diff(moment(this.props.rawDateTime), 'days');
    }

    isToday() {
        return moment(this.props.rawDateTime).isSame(TODAY, 'd');
    }

    isYesterday() {
        return moment(this.props.rawDateTime).isSame(YESTERDAY, 'd');
    }

    isWithin12Hours(): boolean {
        return moment(this.props.rawDateTime).isAfter(moment().subtract(12, 'hours'));
    }

    render() {
        if (this.isYesterday() && this.props.showYesterdayText) {
            return this.result('Yesterday')
        }

        if (this.props.showTimeAgoFormat && this.isWithin12Hours()) {
            return <span data-tip={moment(this.props.rawDateTime).format('MMMM Do YYYY, h:mm:ss')}><ReactTooltip />
                <TimeAgo
                    datetime={this.props.rawDateTime}
                    locale='en_GB' /></span>
        }

        if (this.props.isChat && this.isToday()) {
            return moment(this.props.rawDateTime).format('hh:mm A')
        }

        if (this.props.isChat && !this.isToday() && !this.isYesterday() && this.differenceInDays() < 3) {
            let difference = this.differenceInDays();
            return this.result(`${difference} day${difference > 1 ? 's' : ''} ago`)
        }

        if (this.props.showOnlyShortDate) {
            return (
                this.result(moment(this.props.rawDateTime).format('DD/MM/YYYY'))
            );
        }

        if (this.props.showOnlyDateFormat) {
            return (
                this.result(moment(this.props.rawDateTime).format('MMMM Do YYYY'))
            );
        }

        if (this.props.showShortDateAndTime) {
            return (
                this.result(moment(this.props.rawDateTime).format('D MMM YY H:mm'))
            );
        }

        return (
            this.result(moment(this.props.rawDateTime).format('MMMM Do YYYY, h:mm:ss a'))
        );
    }
}

export default DateTimeFormat;