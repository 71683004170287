import React from "react";
import { withRouter } from "react-router";
import styles from "./UnreadChatCount.module.scss";

interface IProps {
  history: any;
  match: any;
  location: any;

  unreadChatCount: number;
}

class UnreadChatCount extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    if (this.props.unreadChatCount == 0) {
      return null;
    }

    return (
      <span className={styles.circle}>{this.props.unreadChatCount}</span>
    );
  }
}

export default withRouter(UnreadChatCount);