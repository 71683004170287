import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader, formatPercentage, formatCurrency } from "@rentchief/components";
import { RootState } from '../../../../../reducers/rootReducer';
import { getMortgagePanel } from '../../../../../services/PropertyService';
import { IMortgagePanel } from '../../../../../types/PropertyService/IPropertyService';
import { onEditProperty } from '../../../../atoms/SFDropdown/SFPropertyDropdown/_helpers';
import CounterPart from "../CounterPart/CounterPart.component"
import SectionHeader from "../SectionHeader/SectionHeader.component"
import * as S from "./ActiveKeyStatsSection.styles";

interface sectionProps {
    id?: string;
    header?: string;
    hideOnClick?(id: string): void;
    suppliedPropertyId?: string;
}

const ActiveKeyStatsSection = ({id, header, hideOnClick, suppliedPropertyId}: sectionProps) => {
  const history = useHistory();
  const location = useLocation();

  // Redux 
  const { propertyId: reduxPropertyId } = useSelector((state: RootState) => state.property.property_data.property_data);

  const [calculatorData, setCalculatorData] = useState<IMortgagePanel>(null);
  const [calculatorDataRetrieved, setCalculatorDataRetrieved] = useState(false);

  const [propertyId, setPropertyId] = useState(null);

  useEffect(() => {
    if(suppliedPropertyId){
      setPropertyId(suppliedPropertyId);
    }else if(reduxPropertyId){
      setPropertyId(reduxPropertyId);
    }
    fetchCalculatorData(suppliedPropertyId ? suppliedPropertyId : reduxPropertyId);
  }, []);

  const fetchCalculatorData = async (currentPropertyId: string) => {
    setCalculatorDataRetrieved(false);
    let calculationsData: any = null;

    if (currentPropertyId) {
        let resp = await getMortgagePanel(currentPropertyId);
        if(resp){
            setCalculatorDataRetrieved(true);
            if(resp.status == 200 && resp.data){
                calculationsData = resp.data;
            }
        }
    } 
    setCalculatorData(calculationsData);
  };

  const onNavigate = () => {
    history.push(`/dashboard/property/${propertyId}/research`)
  }

  return (
      <div>
        {
        !calculatorDataRetrieved
        ?
        <Loader />
        :
        <S.SectionDiv>
            {
              header &&
              <SectionHeader id={id} header={header} hideOnClick={hideOnClick} titleOnClick={onNavigate} />
            }
            <S.Col1>
              <CounterPart 
                id="value" 
                value={
                  calculatorData
                  ? formatCurrency(calculatorData.latestValue, false)
                  : "N/A"} 
                type="Value" hasAdd={false}
                numberFontSize={'1.2rem'}
                nameFontSize={'0.8rem'}
                valueOnClick={() => onEditProperty(propertyId, location.pathname)} />
            </S.Col1>
            <S.Col2>
              <CounterPart 
                id="equity" 
                value={
                  calculatorData
                  ? formatCurrency(calculatorData.totalEquity, false)
                  : "N/A"}
                type="Equity" hasAdd={false}
                numberFontSize={'1.2rem'}
                nameFontSize={'0.8rem'}
                valueOnClick={() => onEditProperty(propertyId, location.pathname)} />
            </S.Col2>
            <S.Col3>
              <CounterPart 
                id="mortgage" 
                value={
                  calculatorData
                  ? formatCurrency(calculatorData.mortgage, false)
                  : "N/A"} 
                type="Mortgage" 
                hasAdd={false}
                numberFontSize={'1.2rem'}
                nameFontSize={'0.8rem'}
                valueOnClick={() => onEditProperty(propertyId, location.pathname)} />
            </S.Col3>
            <S.Col4>
              <CounterPart 
                id="ltv" 
                value={
                  calculatorData
                  ? formatPercentage(calculatorData.ltv)
                  : "N/A"} 
                type="LTV" 
                hasAdd={false}
                numberFontSize={'1.2rem'}
                nameFontSize={'0.8rem'}
                valueOnClick={() => onEditProperty(propertyId, location.pathname)} />
            </S.Col4>
        </S.SectionDiv>
        }
      </div>
  )
}

export default ActiveKeyStatsSection;