import React from 'react'
import { withRouter } from 'react-router-dom'
import { getFileTypeLogo } from '../../../helpers/documentHelpers'
import {
    addURLParameter,
    removeURLParameter,
} from '../../../helpers/locationHelpers'
import { generateId } from '../../../helpers/randomHelpers'
import { toggleAddDocumentPopup } from '../../../helpers/sidebarHelpers'
import addIcon from '../../../images/icons/addbutton.svg'
import {
    getAssociatedDocuments,
    IAssociatedDocumentsRequest,
} from '../../../services/DocumentService'
import { IReactRouterProps } from '../../../types'
import { QuickViewType } from '../../../types/QuickView'
import { IDocumentUploadOptions } from '../../molecules/AddDocumentModal'
import IconButton from '../Buttons/IconButton'
import GenericPopup from '../GenericPopup'
import styles from './AssociatedDocuments.module.scss'
import {
    AssociatedDocumentsProps,
    IAssociatedDocumentsResponse,
} from './AssociatedDocuments.types'
const queryString = require('query-string')

interface IState {
    isLoading: boolean
    documents: Array<IAssociatedDocumentsResponse>
    propertyId: string
    tenancyId: string
    contactId: string
    transactionId: string
    unitId: string
    inspectionId: number
    assetId?: number
    taskId?: number;
    documentType: string
    fileTypes: Array<string>
    totalDocuments: number
    returnId: string
    returnNewDocumentId: boolean
}

type Props = AssociatedDocumentsProps & IReactRouterProps

class AssociatedDocuments extends React.Component<Props, IState> {
    constructor(props: any) {
        super(props)
        this.state = {
            isLoading: false,
            documents: [],
            propertyId: '',
            tenancyId: '',
            contactId: '',
            transactionId: '',
            unitId: '',
            inspectionId: null,
            documentType: '',
            assetId: null,
            taskId: null,
            fileTypes: [],
            totalDocuments: 0,
            returnNewDocumentId: false,
            returnId: generateId(4),
        }
    }

    getDocumentOverrides = (documents: Array<any>) => {
        if (documents && documents.length > 0) {
            let arr: Array<IAssociatedDocumentsResponse> = []
            documents.map(
                (d) => {
                    arr.push({
                        documentId: d.documentId,
                        fileType: d.fileType,
                        documentName: '',
                        uri: d.uri,
                        contentType: d.contentType
                    })
                },
                () => {}
            )

            this.setState({
                documents: arr,
            })

            return
        }
    }

    componentDidMount(): void {
        if (this.props.options.isCreating) {
            this.getDocumentOverrides(this.props.options.documentsOverride)
            return
        }

        this.setState(
            {
                propertyId: this.props.options.propertyId,
                tenancyId: this.props.options.tenancyId,
                contactId: this.props.options.contactId,
                assetId: this.props.options.assetId,
                taskId: this.props.options.taskId,
                documentType: this.props.options.documentType,
                transactionId: this.props.options.transactionId,
                unitId: this.props.options.unitId,
                inspectionId: this.props.options.inspectionId,
                fileTypes: this.props.options.fileTypes,
            },
            () => this.getData()
        )
    }

    componentWillReceiveProps(nextProps: any) {
        let uploadedDocumentId = queryString.parse(
            nextProps.location.search
        ).uploadedDocumentId
        let returnId = queryString.parse(nextProps.location.search).returnId
        if (
            uploadedDocumentId &&
            this.props.returnDocumentId &&
            returnId == this.state.returnId
        ) {
            // Get Associated Document by Document ID
            let removeQuery = removeURLParameter(
                nextProps.location.search,
                'uploadedDocumentId'
            )
            let removeQuery1 = removeURLParameter(
                nextProps.location.search,
                'returnId'
            )
            if (this.props.onAddDocument) {
                this.props.onAddDocument(uploadedDocumentId)
            }
            this.props.history.push({
                search: removeQuery,
            })
            this.props.history.push({
                search: removeQuery1,
            })
        }

        if (
            nextProps.options.documentsOverride &&
            nextProps.options.isCreating
        ) {
            // If there was previously document overrides and they are the same length, do nothing.
            if (
                this.props.options.documentsOverride &&
                this.props.options.documentsOverride.length ==
                    nextProps.options.documentsOverride.length
            ) {
                return
            }

            this.getData(nextProps.options.documentsOverride)
            return
        }

        if (this.props.options.contactId != nextProps.options.contactId) {
            this.setState(
                {
                    contactId: nextProps.options.contactId,
                },
                () => this.getData()
            )
        }

        if (this.props.options.tenancyId != nextProps.options.tenancyId) {
            this.setState(
                {
                    tenancyId: nextProps.options.tenancyId,
                    documentType: nextProps.options.documentType
                },
                () => this.getData()
            )
        }

        if (this.props.options.propertyId != nextProps.options.propertyId) {
            this.setState(
                {
                    propertyId: nextProps.options.propertyId,
                },
                () => this.getData()
            )
        }

        if (this.props.options.assetId != nextProps.options.assetId) {
            this.setState(
                {
                    assetId: nextProps.options.assetId,
                },
                () => this.getData()
            )
        }

        if (this.props.options.taskId != nextProps.options.taskId) {
            this.setState(
                {
                    taskId: nextProps.options.taskId,
                },
                () => this.getData()
            )
        }

        let isUploaded = queryString.parse(
            nextProps.location.search
        ).uploadedDocument
        if (isUploaded && !this.props.options.isCreating) {
            this.getData()
            let removeQuery = removeURLParameter(
                nextProps.location.search,
                'uploadedDocument'
            )
            nextProps.history.push({
                search: removeQuery,
            })
        }
    }

    getData = async (documentIds?: string[]) => {
        const request: IAssociatedDocumentsRequest = {
            propertyId: this.state.propertyId || '',
            contactId: this.state.contactId || '',
            documentType: this.state.documentType || '',
            tenancyId: this.state.tenancyId || '',
            transactionId: this.state.transactionId || '',
            unitId: this.state.unitId || '',
        }

        if (this.state.tenancyId) {
            request.tenancyId = this.state.tenancyId
        }

        if (this.state.inspectionId) {
            request.inspectionId = this.state.inspectionId
        }

        if (this.state.assetId) {
            request.assetId = this.state.assetId
        }

        if (this.state.taskId) {
            request.taskId = this.state.taskId
        }

        const { data } = await getAssociatedDocuments(
            request,
            this.state.fileTypes || null,
            documentIds || null
        )

        if (!data) {
            return
        }

        this.setState({
            totalDocuments: data.totalDocuments,
            documents: data.documents,
        })
    }

    onAddDocument(e?: any): void {
        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }

        let options: IDocumentUploadOptions = {}

        if (this.props.options) {
            if (this.props.options.propertyId) {
                options.propertyId = this.props.options.propertyId
            }

            if (this.props.options.propertyName) {
                options.propertyName = this.props.options.propertyName
            }

            if (this.props.options.contactId) {
                options.contactId = this.props.options.contactId
            }

            if (this.props.options.tenancyId) {
                options.tenancyId = this.props.options.tenancyId
            }

            if (this.props.options.unitId) {
                options.unitId = this.props.options.unitId
            }

            if (this.props.options.unitName) {
                options.unitName = this.props.options.unitName
            }

            if (this.props.options.documentType) {
                options.documentTypeId = this.props.options.documentType
            }

            if (this.props.options.assetId) {
                options.assetId = this.props.options.assetId
            }

            if (this.props.options.taskId) {
                options.taskId = this.props.options.taskId
            }

            options.returnNewDocumentId = this.props.returnDocumentId
            options.returnId = this.state.returnId
        }

        let query = 'uploadDocumentOptions=' + JSON.stringify(options)

        let search = addURLParameter(this.props.location.search, query)

        this.props.history.push({
            search,
        })

        toggleAddDocumentPopup(true)
    }

    viewAll() {
        if (this.state.contactId && !this.state.propertyId) {
            this.props.history.push(
                `/dashboard/contact/${this.state.contactId}/documents`
            )
        } else {
            this.props.history.push(
                `/dashboard/property/${this.state.propertyId}/documents`
            )
        }
    }

    render() {
        if (
            !this.state.documents ||
            (this.state.documents.length === 0 && !this.props.showUpload)
        ) {
            if (this.props.hideNoDocumentText) {
                return <></>
            }

            if (this.props.showOnlyNoneText) {
                return (
                    <div
                        className={`${styles.container} ${styles.noDocuments}`}
                    >
                        None
                    </div>
                )
            }

            return (
                <div className={`${styles.container} ${styles.noDocuments}`}>
                    {this.props.canAdd && (
                        <div className={styles.add}>
                            <img
                                onClick={() => this.onAddDocument()}
                                src={addIcon}
                            />
                        </div>
                    )}
                    <h3>Upload a document to the property</h3>
                    <p>
                        Store all documents relating to the property in a single
                        location
                    </p>
                </div>
            )
        }

        return (
            <div
                className={`${styles.container} ${styles.hover}`}
                /* onClick={(e: any) => {
                    e.stopPropagation()
                    if (this.props.onClick) {
                        this.props.onClick()
                    }
                }} */
            >
                <div className={styles.row}>
                    {this.state.documents.length > 0 ? (
                        this.state.documents
                            .filter(
                                (x) =>
                                    x.documentId && x.documentName && x.fileType
                            )
                            .map((d, index) => {
                                return (
                                    <div className={styles.column} key={index}>
                                        <GenericPopup
                                            identifier={generateId(10)}
                                            options={{
                                                toggleComponent: (
                                                    <span>
                                                        <a href={d.uri} target="_blank">
                                                            {getFileTypeLogo(
                                                                d.fileType
                                                            )}
                                                        </a>
                                                        
                                                    </span>
                                                ),
                                                type: QuickViewType.Document,
                                                documentId: d.documentId,
                                            }}
                                        />
                                    </div>
                                )
                            })
                    ) : this.props.hideNoDocumentText ? (
                        <></>
                    ) : (
                        <div
                            className={styles.col}
                            onClick={this.onAddDocument}
                        >
                            None
                        </div>
                    )}

                    {this.props.viewAll && (
                        <button
                            onClick={() => this.viewAll()}
                            className={styles.addButton}
                        >
                            View all{' '}
                            {this.state.totalDocuments > 1 && (
                                <span>({this.state.totalDocuments})</span>
                            )}
                        </button>
                    )}

                    {this.props.showUpload && (
                        <div className={`${styles.icon}`}>
                            <IconButton
                                iconOnly
                                button={{
                                    displayType: 'submit',
                                    elementType: 'button',
                                    icon: 'upload',
                                    onClick: () => this.onAddDocument(),
                                }}
                            />
                        </div>
                    )}

                    {this.props.canAdd && (
                        <div
                            className={`${styles.icon}`}
                            onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                this.onAddDocument()
                            }}
                        >
                            <IconButton
                                align={'center'}
                                iconOnly
                                button={{
                                    displayType: 'submit',
                                    elementType: 'button',
                                    icon: 'upload',
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default withRouter(AssociatedDocuments)
