import React from 'react'
import { withRouter } from 'react-router-dom'
import Buttons from '../Buttons'
import styles from './UnitSummary.module.scss'

interface IProps {
    total: number
    rentable: number
    occupied: number
    vacant: number

    history: any
    location: any
    match: any

    showBlankState?: boolean
    totalRoomsIndicator?: boolean
    rentableIndicator?: boolean
    occupiedIndicator?: boolean
    vacantIndicator?: boolean
    showInPortrait?: boolean
    showInLandscape?: boolean

    onConfigure?(): void

    propertyId: string
}

const UnitSummary = (props: IProps) => {
    function GoToUnits(availableToRent?: boolean) {
        if (availableToRent) {
            props.history.push(
                `/dashboard/property/${props.propertyId}/spec/units?availableToRent=true`
            )
            return
        }

        props.history.push(`/dashboard/property/${props.propertyId}/spec/units`)
    }

    const WithUnits = () => {
        return (
            <div className={`${styles.row} ${styles.withUnits}`}>
                {props.totalRoomsIndicator && (
                    <div className={styles.col}>
                        <div
                            className={styles.totalUnits}
                            onClick={() =>
                                props.history.push(
                                    `/dashboard/property/${props.propertyId}/spec/units`
                                )
                            }
                        >
                            <div>
                                <span className={styles.number}>
                                    {props.total}
                                </span>
                                <span className={styles.text}>units</span>
                            </div>
                        </div>
                    </div>
                )}

                {props.vacant || props.occupied || props.rentable ? (
                    <div className={styles.col}>
                        <div
                            className={`${
                                props.showInLandscape
                                    ? styles.row
                                    : props.showInPortrait
                                    ? styles.portrait
                                    : styles.row
                            }`}
                        >
                            {props.rentableIndicator && (
                                <div
                                    className={`${styles.col} ${styles.unitItem}`}
                                    onClick={() => GoToUnits(true)}
                                >
                                    <span
                                        className={`${styles.circle} ${styles.rentable}`}
                                    >
                                        {props.rentable}
                                    </span>{' '}
                                    rentable
                                </div>
                            )}
                            {props.occupiedIndicator && (
                                <div
                                    className={`${styles.col} ${styles.unitItem}`}
                                    onClick={() => GoToUnits()}
                                >
                                    <span
                                        className={`${styles.circle} ${styles.occupied}`}
                                    >
                                        {props.occupied}
                                    </span>{' '}
                                    occupied
                                </div>
                            )}
                            {props.vacantIndicator && (
                                <div
                                    className={`${styles.col} ${styles.unitItem}`}
                                    onClick={() => GoToUnits()}
                                >
                                    <span
                                        className={`${styles.circle} ${styles.vacant}`}
                                    >
                                        {props.vacant}
                                    </span>{' '}
                                    vacant
                                </div>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
        )
    }

    const NoUnits = () => {
        if (!props.showBlankState) {
            return <></>
        }

        return (
            <div className={styles.noUnits}>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <p>Set the layout of the property</p>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <Buttons
                            align={'center'}
                            buttons={[
                                {
                                    text: 'Configure',
                                    displayType: 'action',
                                    elementType: 'button',
                                    onClick: () =>
                                        props.onConfigure &&
                                        props.onConfigure(),
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const { rentable, occupied, vacant, total } = props

    return (
        <div className={styles.unitSummary}>
            {!rentable && !occupied && !vacant && !total ? (
                <NoUnits />
            ) : (
                <WithUnits />
            )}
        </div>
    )
}

export default withRouter(UnitSummary)
