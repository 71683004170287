import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    dispatchUpdatePropertyList,
    dispatchResetPropertyList,
} from '../../../actions/propertyActions'
import { isPlatformAdmin } from '../../../helpers/roleHelpers'
import { getPropertyList } from '../../../services/PropertyListService'
import InfiniteScroll from 'react-infinite-scroll-component'
import 'react-table/react-table.css'
import PropertyListItem, { IData } from '../../atoms/PropertyListItem'
import styles from './PropertyListPage.module.scss'
import { ToastContainer } from 'react-toastify'
import { toggleAddPropertySidebar } from '../../../actions/appActions'
import { PropertySortEnums } from '../../../types/PropertyList'
import ReactTooltip from 'react-tooltip'
import RCFilter from '../../atoms/ListComponents/Filter'
import { IFilterElement } from '../../../types/ListComponents'
import FilterMobile from '../../atoms/ListComponents/FilterMobile'
import { isMobile, isTablet } from 'react-device-detect'
import RotatingLoader from '../../atoms/RotatingLoader'
import { getSearchProperties } from '../../../services/SearchService'
import { exportAllUserProperties } from '../../../services/CSVExportService'
import moment from 'moment'
import ErrorsHoldMyBeer from '../../atoms/ErrorsHoldMyBeer'
import ShowOnlyMy from '../../atoms/ShowOnlyMy'
import SelectDropdown, { IOptions } from '../../atoms/SelectDropdown'
import { getClientsDropdown } from '../../../services/ClientService'
import { IListItemOptions } from '../../../types/ListFilters'
import { canSelectClientImplementation } from '../../../helpers/permissionHelpers'
import { generateId } from '../../../helpers/randomHelpers'
import ElevioArticleEmbedWrapper from '../../atoms/ElevioArticleEmbedWrapper'
import { elevioPropertyArticleId } from '../../../configuration/elevioConfig'
import SearchContactInput from '../../atoms/SearchContactInput'
import { ISearchResult } from '../../../types/SearchService/ISearchService'
import { toQueryString } from '../../../services/DocumentService'
import { updateAlertMessage } from '../../../helpers/alertHelpers'
import { IDataResponse } from '../../../types/ApiService'
import IconButton from '../../atoms/Buttons/IconButton'
import SearchPropertyInput from '../../atoms/SearchPropertyInput'
const queryString = require('query-string')
const FileDownload = require('js-file-download')

interface IProps {
    history: any
    location: any
    match: any

    toggleSidebar?(show: boolean): void
    dispatchUpdatePropertyList?(data: any): void
    dispatchResetPropertyList?(): void
    property_list_data?: IData[]
    userId?: string
    
    contactId?: string
    contactName?: string
    isPropertyManager?: boolean
    isLandlord?: boolean
}

enum ViewType {
    list,
    map,
}

interface IState {
    properties: Array<any>
    pages: any
    page: any
    loading: boolean
    showElevio: boolean
    status: string
    totalProperties: number
    hasMore: boolean
    filterAmount: number
    nextPage: number
    initialLoad: boolean
    showBackToTop: boolean
    sort: PropertySortEnums
    isFilter: boolean
    viewType: ViewType
    deletePropertyModalOpen: boolean
    isLoadingImage: boolean
    statusOptions: Array<IFilterElement>
    searchTerm: string
    loadingOverlay: boolean
    searchResults: any[]
    isSearching: boolean
    showOnlyMyProperties: boolean
    clientFilterOptions: Array<IOptions>
    selectedClient: string
    viewsFilterOptions: Array<IOptions>
    selectedView: string
    error: string

    // New queries
    clients: Array<string>
    createdBy: Array<string>
    propertyOwners: Array<string>
    propertyType: Array<string>
    gridSize: number
    contactName?: string
    contactId?: string
}

interface IAddPropertyParams {
    contactId?: string
    contactName?: string
    isPropertyManager?: boolean
    isLandlord?: boolean
}

class PropertiesListPage extends Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        var viewingFilterOptions: IOptions[] = [
            {
                value: 'Returns',
                label: 'Forecast Investment Returns',
                isDefault: false,
            },
            {
                value: 'Summary',
                label: 'Summary',
                isDefault: true,
            },
            {
                value: 'Regulation',
                label: 'Regulation',
                isDefault: false,
            },
        ]

        this.state = {
            properties: [],
            pages: null,
            page: 1,
            loading: false,
            showElevio: false,
            status: 'all',
            totalProperties: 0,
            hasMore: true,
            filterAmount: 8,
            nextPage: 1,
            initialLoad: true,
            showBackToTop: false,
            sort: PropertySortEnums.Newest,
            isFilter: false,
            viewType: ViewType.list,
            deletePropertyModalOpen: false,
            isLoadingImage: false,
            statusOptions: [],
            searchTerm: '',
            loadingOverlay: false,
            searchResults: [],
            isSearching: false,
            showOnlyMyProperties: false,
            clientFilterOptions: [],
            viewsFilterOptions: viewingFilterOptions,
            selectedClient: '',
            selectedView: 'Summary',

            // New queries
            clients: [],
            createdBy: [],
            propertyOwners: [],
            propertyType: [],
            gridSize: 3,
            contactName: null,
            contactId: this.props.contactId,
            error: null,
        }

        this.fetchProperties = this.fetchProperties.bind(this)
        this.toggleStatus = this.toggleStatus.bind(this)
        this.toggleLoading = this.toggleLoading.bind(this)
        this.toggleHasMore = this.toggleHasMore.bind(this)
        this.getProperties = this.getProperties.bind(this)
        this.toggleStatusDropdownMenu = this.toggleStatusDropdownMenu.bind(this)
        this.toggleViewType = this.toggleViewType.bind(this)
        this.onDeleteCallback = this.onDeleteCallback.bind(this)
        this.getQueryParams = this.getQueryParams.bind(this)
        this.buildQueryParam = this.buildQueryParam.bind(this)
        this.onSearch = this.onSearch.bind(this)
        this.onExportProperties = this.onExportProperties.bind(this)
        this.toggleShowMyPropertiesOnly =
            this.toggleShowMyPropertiesOnly.bind(this)
        this.onSearchButtonClick = this.onSearchButtonClick.bind(this)
        this.onSetContact = this.onSetContact.bind(this)
        this.onAdd = this.onAdd.bind(this)
    }

    onAdd(): void {
        let request: IAddPropertyParams = {}

        if (
            this.props.contactId &&
            this.props.contactName &&
            (this.props.isLandlord || this.props.isPropertyManager)
        ) {
            request.contactId = this.props.contactId
            request.contactName = this.props.contactName
            ;(request.isLandlord = this.props.isLandlord),
                (request.isPropertyManager = this.props.isPropertyManager)
        }

        let query = toQueryString(request)
        this.props.history.push({
            search: `?${query}`,
        })

        this.props.toggleSidebar(true)
    }

    onSetContact(o: ISearchResult): void {
        this.setState(
            {
                contactId: o.value,
                contactName: o.label,
            },
            () => this.getProperties(true, 1)
        )
    }

    onSearchButtonClick(search: string): void {
        this.setState(
            {
                searchTerm: search,
            },
            () => {
                this.getProperties(true, 1)
            }
        )
    }
    onExportProperties(): void {
        exportAllUserProperties().then((r) => {
            if (r.status == 200) {
                FileDownload(
                    r.data,
                    'Property_Export_' +
                        moment(new Date()).local().format('Do-MMMM-YYYY') +
                        this.props.userId +
                        '.csv'
                )
            }
        })
    }

    fetchProperties() {
        this.toggleLoading(true)

        var options: IListItemOptions = {
            page: this.state.nextPage,
            size: this.state.filterAmount,
            onlyShowOwnRecords: this.state.showOnlyMyProperties,
        }

        if (this.state.searchTerm) {
            options.search = this.state.searchTerm
        }

        if (this.state.selectedClient) {
            options.clientId = this.state.selectedClient
        }

        if (this.state.status) {
            if (this.state.status.toLowerCase() != 'all') {
                options.status = this.state.status
            }
        }

        getPropertyList(options, this.state.sort).then((resp) => {
            if (resp) {
                let response: IDataResponse<any> = resp.data
                if (response.isSuccess && response.data) {
                    let newProperties = this.props.property_list_data.concat(
                        response.data.properties
                    )

                    this.setState({
                        properties: newProperties,
                        pages: response.data.totalPages,
                        loading: false,
                        page: this.state.page + 1,
                        totalProperties: response.data.totalProperties,
                        nextPage: response.data.nextPage,
                        statusOptions: response.data.statusOptions,
                    })

                    this.props.dispatchUpdatePropertyList(newProperties)
                }
            }
            this.toggleLoading(false)
        })
    }

    onDeleteCallback(propertyId: string) {
        let filteredArray = this.props.property_list_data.filter(
            (item) => item.property.propertyId !== propertyId
        )
        this.props.dispatchUpdatePropertyList(filteredArray)

        if (this.state.properties.length == 0) {
            this.setState({
                showElevio: true,
            })
        }
    }

    toggleStatus(status: string) {
        this.setState(
            {
                page: 1,
                nextPage: 1,
                status: status,
                loadingOverlay: true,
            },
            () => {
                this.setQueryParamValues()
                this.getProperties(true)
            }
        )
    }

    toggleLoading(loading: boolean) {
        this.setState({
            loadingOverlay: loading,
        })
    }

    toggleHasMore(hasMore: boolean) {
        this.setState({
            hasMore,
        })
    }

    componentWillUnmount() {
        this.props.dispatchResetPropertyList()
    }

    async onSearch(searchTerm: string, contactId?: string): Promise<void> {
        return getSearchProperties(searchTerm, contactId)
    }

    getProperties(
        showLoading: boolean = true,
        pageNumber: number = this.state.nextPage
    ) {
        if (this.state.properties) {
            this.setState({
                properties: [],
                loading: showLoading,
            })
        }

        this.setState({
            showElevio: false,
            loading: showLoading,
        })

        this.props.dispatchResetPropertyList()

        var options: IListItemOptions = {
            page: pageNumber,
            size: this.state.filterAmount,
            onlyShowOwnRecords: this.state.showOnlyMyProperties,
        }

        if (this.state.contactId) {
            options.contactId = this.state.contactId
        }

        if (this.state.searchTerm) {
            options.search = this.state.searchTerm
        }

        if (this.state.selectedClient) {
            options.clientId = this.state.selectedClient
        }

        if (this.state.status) {
            if (this.state.status.toLowerCase() != 'all') {
                options.status = this.state.status
            }
        }

        getPropertyList(options, this.state.sort)
            .then((resp) => {
                let response: IDataResponse<any> = resp.data
                if (response.isSuccess && response.data) {
                    this.setState(
                        {
                            properties: response.data.properties,
                            pages: response.data.totalPages,
                            loading: false,
                            page: pageNumber,
                            totalProperties: response.data.totalProperties,
                            nextPage: response.data.nextPage,
                            initialLoad: false,
                            statusOptions: response.data.statusOptions,
                        },
                        () => this.setQueryParamValues()
                    )

                    if (response.data.properties) {
                        this.props.dispatchUpdatePropertyList(
                            response.data.properties
                        )

                        if (
                            this.props.property_list_data &&
                            this.props.property_list_data.length == 0
                        ) {
                            this.setState({
                                showElevio: true,
                            })
                        }

                        this.setState({
                            loadingOverlay: false,
                        })
                    }
                } else {
                    updateAlertMessage(response.errorMessage, true, 0, false)
                    this.setState({
                        loading: false,
                        initialLoad: false,
                        error: response.errorMessage,
                    })
                }
            })
            .catch((e) => {
                this.setState({
                    loading: false,
                    initialLoad: false,
                })
            })
    }

    toggleViewType(ViewType) {
        this.setState({ viewType: ViewType }, () => this.setQueryParamValues())
    }

    toggleStatusDropdownMenu() {
        this.setState({ isFilter: !this.state.isFilter }, () =>
            this.setQueryParamValues()
        )
    }

    toggleShowMyPropertiesOnly(): void {
        this.setState(
            {
                showOnlyMyProperties: !this.state.showOnlyMyProperties,
            },
            () => {
                this.setQueryParamValues()
                this.getProperties(true, 1)
            }
        )
    }

    getQueryParams() {
        const {
            filterAmount,
            viewType,
            propertyStatus,
            // New queries
            client,
            propertyOwner,
            propertyType,
        } = queryString.parse(this.props.location.search)

        // Set filter amount value
        if (filterAmount) {
            this.setState({
                filterAmount,
            })
        }

        // Set view type value
        if (viewType) {
            if (viewType == ViewType.list || viewType == ViewType.map) {
                this.setState({
                    viewType,
                })
            }
        }
    }

    buildQueryParam() {
        let filterAmount = 'filterAmount=' + this.state.filterAmount
        let viewType = '&viewType=' + this.state.viewType
        let propertyStatus = '&propertyStatus=' + this.state.status
        let searchTerm = this.state.searchTerm
            ? '&search=' + this.state.searchTerm
            : ''
        let clients = ''
        let propertyTypes = ''
        let propertyOwners = ''
        let showOnlyMyProperties = ''

        // New queries
        if (this.state.clients instanceof Array) {
            this.state.clients.map((client) => {
                clients += '&client=' + client
            })
        } else {
            clients = '&client=' + this.state.clients
        }

        if (this.state.propertyOwners instanceof Array) {
            this.state.propertyOwners.map((owner) => {
                propertyOwners += '&propertyOwner=' + owner
            })
        } else {
            propertyOwners = '&propertyOwner=' + this.state.propertyOwners
        }

        if (this.state.propertyType instanceof Array) {
            this.state.propertyType.map((type) => {
                propertyTypes += '&propertyType=' + type
            })
        } else {
            propertyTypes = '&propertyType=' + this.state.propertyType
        }

        if (this.state.showOnlyMyProperties) {
            showOnlyMyProperties = '&showOnlyMyProperties=true'
        }

        return `?${filterAmount}${viewType}${propertyStatus}${searchTerm}${clients}${propertyOwners}${propertyTypes}${showOnlyMyProperties}`
    }

    setQueryParamValues() {
        this.props.history.replace({
            ...location,
            search: this.buildQueryParam(),
        })
    }

    componentWillMount() {
        this.getQueryParams()

        if (document && document.activeElement) {
            ;(document.activeElement as HTMLElement).blur()
        }
    }

    componentDidMount() {
        this.setState({
            initialLoad: true,
        })
        this.getProperties(true)

        this.setQueryParamValues()

        if (isMobile) {
            this.setState({
                gridSize: 10,
            })
            if (isTablet) {
                this.setState({
                    gridSize: 3,
                })
            }
        }

        getClientsDropdown().then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                let data: Array<IOptions> = resp.data
                this.setState({
                    clientFilterOptions: data,
                })
            }
        })

        window.scrollTo(0, 0)
    }

    componentDidUpdate() {
        if (document && document.activeElement) {
            ;(document.activeElement as HTMLElement).blur()
        }
    }

    render() {
        if (this.state.initialLoad) {
            return (
                <div>
                    <RotatingLoader
                        topMargin="4rem"
                        loading={true}
                        text="Loading your properties..."
                    />
                </div>
            )
        }

        if (this.state.error) {
            return null
        }

        return (
            <ErrorsHoldMyBeer>
                <div className={styles.listPage}>
                    <ToastContainer />
                    <ReactTooltip />

                    <Row>
                        <Col className={styles.listContent}>
                            <div className={styles.propSearchBar}>
                                <Row>
                                    <Col className={styles.searchBarDiv}>
                                        <SearchPropertyInput
                                            onSelect={(option: IOptions) =>{
                                                option.value && this.props.history.push('/dashboard/property/' + option.value)}
                                            }
                                            showSearchButton={true}
                                            searchButtonOnClick={(searchValue: string) => this.onSearchButtonClick(searchValue)}
                                        />
                                        
                                    </Col>
                                    <div className={styles.actions}>
                                        <IconButton
                                            button={{
                                                text: 'Add Property',
                                                displayType: 'submit',
                                                elementType: 'button',
                                                icon: 'add',
                                                onClick: () => this.onAdd(),
                                            }}
                                        />
                                    </div>
                                </Row>

                                <div>
                                    <div>
                                        <div className={styles.filter}>
                                            <RCFilter
                                                showFilterIcon
                                                allElement={{
                                                    id: 'all',
                                                    status: 'All',
                                                }}
                                                activeElementId={this.state.status}
                                                filterElements={this.state.statusOptions}
                                                onChangeFilter={this.toggleStatus}
                                                toggleStatusDropdownMenu={this.toggleStatusDropdownMenu}
                                            />
                                        </div>
                                        <div className={styles.nestedRow}>
                                            {!this.props.contactId && (
                                                <div className={styles.nestedColumn}>
                                                    <SearchContactInput
                                                        newContactType="Landlord"
                                                        contactType={[
                                                            'E08D17B2-3FA0-43BB-8458-18378F92FDED',
                                                            '897D7652-F770-42A2-AB3D-9D3E6CDEDF51',
                                                        ]}
                                                        onClear={() =>
                                                            this.setState(
                                                                {
                                                                    contactId: null,
                                                                    contactName: null,
                                                                    page: 1,
                                                                },
                                                                () => this.getProperties(true,1)
                                                            )
                                                        }
                                                        selectedId={this.state.contactId}
                                                        selectedName={this.state.contactName}
                                                        onSelect={this.onSetContact}
                                                    />
                                                </div>
                                            )}
                                            <div className={styles.nestedColumn}>
                                                <SelectDropdown
                                                    selectedId={this.state.selectedView}
                                                    showAllOption={false}
                                                    data={this.state.viewsFilterOptions}
                                                    placeholder="View Mode"
                                                    onSelect={(id: IOptions) =>
                                                        this.setState({
                                                            selectedView: id.value,
                                                        })
                                                    }
                                                    smallerFont={true}
                                                />
                                            </div>
                                            {canSelectClientImplementation() && (
                                                <div className={styles.nestedColumn}>
                                                    <SelectDropdown
                                                        showAllOption
                                                        data={this.state.clientFilterOptions}
                                                        selectedId={this.state.selectedClient}
                                                        placeholder="Filter by client..."
                                                        onSelect={(id: IOptions) =>
                                                            this.setState(
                                                                {
                                                                    page: 1,
                                                                    nextPage: 1,
                                                                    selectedClient: id.value,
                                                                    properties: [],
                                                                },
                                                                () => this.getProperties()
                                                            )
                                                        }
                                                        smallerFont={true}
                                                    />
                                                </div>
                                            )}

                                            {isPlatformAdmin() && (
                                                <ShowOnlyMy
                                                    showOnlyMy="properties"
                                                    onChange={this.toggleShowMyPropertiesOnly}
                                                    checked={this.state.showOnlyMyProperties}
                                                />
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.isFilter && (
                                <FilterMobile
                                    showFilterIcon
                                    allElement={{
                                        id: 'all',
                                        status: 'All',
                                    }}
                                    activeElementId={this.state.status}
                                    filterElements={this.state.statusOptions}
                                    onChangeFilter={this.toggleStatus}
                                    toggleStatusDropdownMenu={this.toggleStatusDropdownMenu}
                                />
                            )}

                            {this.state.loadingOverlay && (
                                <div className={styles.loadingOverlay}>
                                    <div className={styles.container}>
                                        <RotatingLoader
                                            loading={true}
                                            text="Fetching properties..."
                                        />
                                    </div>
                                </div>
                            )}

                            {this.state.properties.length > 0 &&
                                this.state.viewType == ViewType.list && (
                                    <div
                                        className={styles.propertyList}
                                        id="propertyList"
                                    >
                                        <InfiniteScroll
                                            className={styles.infiniteScroll}
                                            dataLength={this.state.totalProperties}
                                            next={this.fetchProperties}
                                            hasMore={this.state.properties.length < this.state.totalProperties}
                                        >
                                            <Row className={styles.gridDiv}>
                                                {this.props.property_list_data 
                                                && this.props.property_list_data.map((prop: any, key: number) => {
                                                            return (
                                                                <PropertyListItem
                                                                    identifier={generateId(10)}
                                                                    key={key}
                                                                    onDeleteCallback={this.onDeleteCallback}
                                                                    data={prop}
                                                                    selectedView={this.state.selectedView}
                                                                />
                                                            )
                                                        }
                                                    )}
                                            </Row>
                                        </InfiniteScroll>

                                        {this.state.loading && (
                                            <div className={styles.loader}>
                                                <RotatingLoader
                                                    loading={true}
                                                    text="Loading your properties..."
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}

                            {this.props.property_list_data != null &&
                                this.props.property_list_data.length == 0 && (
                                    <div>
                                        No properties have been added
                                        <ElevioArticleEmbedWrapper>
                                            <elevio-element
                                                data-type="article"
                                                data-id={`${elevioPropertyArticleId}`}
                                            ></elevio-element>
                                        </ElevioArticleEmbedWrapper>
                                    </div>
                                )}
                        </Col>
                    </Row>
                </div>
            </ErrorsHoldMyBeer>
        )
    }
}

const mapStateToProps = (state: any) => ({
    property_list_data: state.property.property_data.property_list_data,
    userId: state.user.user_id,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toggleSidebar: (show: boolean) => dispatch(toggleAddPropertySidebar(show)),
    dispatchUpdatePropertyList: (data: any) =>
        dispatch(dispatchUpdatePropertyList(data)),
    dispatchResetPropertyList: () => dispatch(dispatchResetPropertyList()),
})

export default withRouter<IProps,any>(
    connect(mapStateToProps, mapDispatchToProps)(PropertiesListPage)
)
