import React, { useEffect, useState, useContext} from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../reducers/rootReducer';
import { ApiContext } from "@rentchief/components";
import { INearbyPropertySalesStats, LandRegistryService } from "@rentchief/components";
import { getJwt } from "../../../../../helpers/authHelpers";
import ResearchTheAreaPart from '../ResearchTheAreaPart/ResearchTheAreaPart.component';
import { ResearchCouncilPart } from '../ResearchCouncilPart/ResearchCouncilPart.component';
import SectionHeader from "../SectionHeader/SectionHeader.component"
import * as S from "./ActiveResearchAreaSection.styles";

interface sectionProps {
    id: string;
    header: string;
    hideOnClick(id: string): void;
}

const ActiveResearchAreaSection = ({id, header, hideOnClick}: sectionProps) => {
  // Redux 
  const { address } = useSelector((state: RootState) => state.property.property_data.property_data);

  const { land_registry_service } = useContext(ApiContext);
    const landRegistryService = new LandRegistryService(land_registry_service, {
      bearer_token: getJwt(),
    });

  const [landRegistryData, setLandRegistryData] = useState<INearbyPropertySalesStats>(null);

  useEffect(() => {
    fetchLandRegistryData();
  }, []);

  const fetchLandRegistryData = async (
    page?: number,
    size?: number,
    skip?: number,
    sortQuery?: string
  ) => {
    page = page != undefined ? page : 1;
    size = size != undefined ? size : 20;
    skip = skip != undefined ? skip : 0;
    sortQuery = sortQuery != undefined ? sortQuery : "";

    //let service = new LandRegistryService(land_registry_service);
    const response = await landRegistryService.getNearbyPropertySalesStats(
        "rentchief",
      address.postcode,
      "",
      "",
      "",
      new Date().toDateString(),
      new Date().toDateString(),
      "",
      "",
      page,
      size,
      skip,
      sortQuery
    );

    if (response.data)
    setLandRegistryData(response.data);
  };

  return (
      <div>
          <SectionHeader id={id} header={header} hideOnClick={hideOnClick} />
          <S.CardDiv onClick={(e: any) => e.stopPropagation()}>
            <S.Panel>
              <ResearchTheAreaPart postcode={address.postcode} />
              <ResearchCouncilPart
                mainLink={landRegistryData && landRegistryData.localCouncil && landRegistryData.localCouncil.webMain}
                taxLink={landRegistryData && landRegistryData.localCouncil && landRegistryData.localCouncil.webCouncilTax}
                hmoLink={landRegistryData && landRegistryData.localCouncil && landRegistryData.localCouncil.webHMO}
              />
            </S.Panel>
          </S.CardDiv>
      </div>
  )
}

export default ActiveResearchAreaSection;