import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import * as domains from '../configuration/domains';
import { toQueryString, queryHeaders } from '../helpers/serviceHelper'

export const unitApi = createApi({
    reducerPath: 'unitApi',
    baseQuery: fetchBaseQuery({
      baseUrl: domains.unitService,
      prepareHeaders: queryHeaders,
    }),
    tagTypes: ['Unit'],
    endpoints: (builder) => ({
        doesPropertyHaveUnits: builder.query({
            query: (propertyId) => `/doesPropertyHaveUnits/${propertyId}`,
            providesTags: ['Unit']
        })
    })
});
  
export const { 
    useDoesPropertyHaveUnitsQuery,
    useLazyDoesPropertyHaveUnitsQuery
    } = unitApi