export const chatEmptyStateHtml = 
`
<!DOCTYPE html>
<html>
    <head>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <style id="applicationStylesheet" type="text/css">
            #outer {
                tex-align: centre;
                width:80%;
                margin: auto;
            }
            #buttonSubstitute {

            }
            #buttonSubstituteDiv {
                width: 200px;
                text-align: center;
                margin: auto;
                padding-top: 20px;
            }
            #chatImage{
                height: 100px;
                width: 100px;
                text-align: center;
            }
            #chatImageDiv{
                text-align: center;
            }
            #title{
                text-align: center;
            }
        </style>
    </head>
    <body>
        <div id="outer">
            <div id="chatImageDiv">
                <img id="chatImage" src="https://rentchiefstorageuk.blob.core.windows.net/config/client/shared/html/ChatEmptyState/chat-icon-1.svg"/>
            </div>
            <div id="title">
                <span>You have no chats started, click button below to select someone to chat with.</span>
            </div>
            <div id="buttonSubstituteDiv">
                <div id="buttonSubstitute" />
            </div>
        </div>
    </body>
</html>
`