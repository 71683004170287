import React, { useContext } from 'react';
import { Button } from "@rentchief/components";
import { DashboardContext } from '../../_context/dashboardContext';
import * as S from "./SectionHeader.styles";

interface props {
    id: string;
    header: string;
    hideOnClick(id: string): void;
    startTour?(): void;
    titleOnClick?(): void;
    addOnClick?(): void;
    priceValuation?(): void;
    color?: string;
}

const SectionHeader = ({id, header, hideOnClick, startTour, titleOnClick, addOnClick, priceValuation, color}: props) => {
    const dashboardContext = useContext(DashboardContext);
    const titleLinkEnabled = titleOnClick && !dashboardContext.landlordDasboardInEditMode && !dashboardContext.propertyDasboardInEditMode;

    return (
        <S.HeaderDiv color={color}>
            <S.HeaderLabelWrapper buttonsCount={priceValuation && !dashboardContext.propertyDasboardInEditMode ? 2 : 1}>
                <S.HeaderLabel hasLink={titleLinkEnabled} color={color} onClick={() => titleLinkEnabled ? titleOnClick() : null}>{header}</S.HeaderLabel>
            </S.HeaderLabelWrapper>
            {
                dashboardContext.landlordDasboardInEditMode || dashboardContext.propertyDasboardInEditMode
                ?
                <span>
                    <S.MoveIconWrapper><img src="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/move_widget_icon.svg" /></S.MoveIconWrapper>
                    <S.HideIconWrapper className="non-draggable" onClick={() => hideOnClick(id)}><img src="https://rentchiefstorageuk.blob.core.windows.net/images/dashboard-images/close_widget_icon.svg" /></S.HideIconWrapper>
                </span>
                :
                !startTour && !addOnClick && !priceValuation
                ?
                <S.EmptyWrapper/>
                :
                startTour 
                ?
                <S.ButtonsWrapper buttonsCount={priceValuation && !dashboardContext.propertyDasboardInEditMode ? 2 : 1}>
                    <S.ButtonLink><Button onClick={() => startTour()}>Tour</Button></S.ButtonLink>
                </S.ButtonsWrapper>
                :
                priceValuation
                ?
                <S.ButtonsWrapper buttonsCount={priceValuation && !dashboardContext.propertyDasboardInEditMode ? 2 : 1}>
                    <S.ButtonLink><Button onClick={() => priceValuation()}>Valuation</Button></S.ButtonLink>
                    <S.ButtonLink><Button onClick={() => addOnClick()}>Add</Button></S.ButtonLink>
                </S.ButtonsWrapper>                 
                :
                <S.ButtonsWrapper buttonsCount={priceValuation && !dashboardContext.propertyDasboardInEditMode ? 2 : 1}>
                    <S.ButtonLink><Button onClick={() => addOnClick()}>Add</Button></S.ButtonLink>
                </S.ButtonsWrapper>
            }
            
        </S.HeaderDiv>
    )
}

export default SectionHeader;