import { store } from '../store';

export function getClientIdFromQueryparams(location: any) {
    return location.clientId;
}

export function getClientId() {
    const state = store.getState();

    if (state.client.id) {
        return state.client.id;
    }

    return '';
}

export function getLogo() {
    const state = store.getState();

    return state.client.logo ? state.client.logo : null;
}

export function getSecondaryLogo() {
    const state = store.getState();

    return state.client.secondaryLogo ? state.client.secondaryLogo : null;
}

export function hasClientArea(area: string): boolean {
    let state = store.getState().client.clientSystemAreas;
    if (!state) {
        return false;
    }

    return state.findIndex(x => x == area) > -1;
}