import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import styles from "./ContactListItem.module.scss";
import {
  IContactListItem,
  IInvitation
} from "../../../types/ContactService/ContactService";
import editIcon from "../../../images/icons/property-action/Edit.png";
import moreIcon from "../../../images/icons/property-action/Action.png";
import deleteIcon from "../../../images/icons/property-action/Delete.png";
import ListItem from "../ListComponents/ListItem";
import { IUserListItem, IClientListItem } from "../../../types/ClientService";
import DateTimeFormat from "../DateTimeFormat";
import InvitationStatus from "../InvitationStatus";
import AssociatedDocuments from "../AssociatedDocuments";
import RotatingLoader from "../RotatingLoader";
import DeleteContactModal from "../DeleteContactModal";
import UserContactInfo, { UserContactInfoType } from "../UserContactInfo";
import { withRouter } from "react-router-dom";
import { canInvite } from "../../../helpers/invitationHelpers";
import SFContactDropdown from "../SFDropdown/SFContactDropdown";
import { connect, Provider } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { toggleAddTeamMember } from '../../../actions/teamsActions';

interface IProps {
  contactItem?: IContactListItem;
  onAddCancel?(): void;
  user: IUserListItem;
  client: IClientListItem;
  history: any;
  location: any;
  match: any;
  toggleAddTeamMember?: any;
}

interface IState {
  invitation: IInvitation;
  editItem: boolean;
  contactDeleteModal: boolean;
  isLoadingInvitation: boolean;
  error: string;
}

class ContactListItem extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      invitation: this.props.contactItem && this.props.contactItem.invitation,
      editItem: false,
      contactDeleteModal: false,
      isLoadingInvitation: false,
      error: ""
    };
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onDeleteContact = this.onDeleteContact.bind(this);
  }

  onOpenModal = () => {
    this.setState({ contactDeleteModal: true });
  };

  onCloseModal = () => {
    this.setState({ contactDeleteModal: false });
  };

  onDeleteContact(): void { }

  goToAddEmail() {
    this.props.history.push(
      "/dashboard/contact/" +
      this.props.contactItem.contact.contactId +
      "?addEmail=true"
    );
  }

  render() {
    if (!this.props.contactItem.profile) {
      return null;
    }

    return (
      <ListItem client={this.props.client} user={this.props.user}>
        <DeleteContactModal
          isTenant={this.props.contactItem.type.entityName == "Tenant"}
          isUser={this.props.contactItem.isUser}
          contactId={this.props.contactItem.contact.contactId}
          contactName={
            this.props.contactItem.profile.forename +
            " " +
            this.props.contactItem.profile.surname
          }
          open={this.state.contactDeleteModal}
          onOpenModal={this.onOpenModal}
          onCloseModal={this.onCloseModal}
          onDeleteCallBack={this.onDeleteContact}
        />
        <div
          onClick={() =>
            this.props.history.push(
              "/dashboard/contact/" + this.props.contactItem.contact.contactId
            )
          }
        >
          <Row>
            <Col className={styles.userContactInfo}>
              <UserContactInfo
                manualOverride={{
                  forename: this.props.contactItem.profile.forename,
                  surname: this.props.contactItem.profile.surname,
                  contactId: this.props.contactItem.contact.contactId,
                  profileImage: this.props.contactItem.profile.profileImageUri,
                  typeOrRole: this.props.contactItem.type.entityName,
                  isUser: this.props.contactItem.isUser
                }}
                type={UserContactInfoType.Contact}
                id={this.props.contactItem.contact.contactId}
                classNames={{
                  name: styles.name,
                  typeOrRole: styles.typeOrRole,
                  userIcon: styles.userIcon
                }}
              />
            </Col>
            <Col className={styles.centerAligned}>
              <div className={styles.item}>
                <div className={styles.label} id={styles.ownerType}>
                  Documents
                </div>
                <AssociatedDocuments
                  showOnlyNoneText
                  options={{
                    contactId: this.props.contactItem.contact.contactId
                  }}
                  viewAll={true}
                />
              </div>
            </Col>
            <Col
              className={styles.invitationStatus}
              onClick={(e: any) => e.stopPropagation()}
            >
              {this.props.contactItem &&
                canInvite(this.props.contactItem.type.entityName) ? (
                this.state.isLoadingInvitation ? (
                  <div>
                    <RotatingLoader text="Loading..." loading={true} />
                  </div>
                ) : (
                  <InvitationStatus
                    toggleAddTeamMember={this.props.toggleAddTeamMember}
                    history={this.props.history}
                    userId={this.props.contactItem.userId}
                    disableLoader
                    contactId={this.props.contactItem.contact.contactId}
                    invitation={this.props.contactItem.invitation}
                    forename={this.props.contactItem && this.props.contactItem.profile && this.props.contactItem.profile.forename}
                    surname={this.props.contactItem && this.props.contactItem.profile && this.props.contactItem.profile.surname}
                    email={this.props.contactItem && this.props.contactItem.profile && this.props.contactItem.profile.email}
                    contactType={this.props.contactItem && this.props.contactItem.contact.type}
                    teamId={this.props.contactItem.contact.teamId}
                    hideStatus={true}
                  />
                )
              ) : null}
            </Col>

            <Row className={styles.buttons}>
              <Col className={styles.itemButtons} onClick={(e: any) => {
                e.stopPropagation();
              }}>
                <SFContactDropdown
                  isLandlord={this.props.contactItem.type.entityName == "Landlord"}
                  invited={this.props.contactItem && this.props.contactItem.invitation && this.props.contactItem.invitation.isInvited}
                  userId={this.props.contactItem.userId}
                  isUser={this.props.contactItem.isUser}
                  isUserActive={this.props.contactItem.isUserActive}
                  onDelete={() => this.onOpenModal()}
                  toggle={<img
                    src={moreIcon}
                    alt="View more"
                  />}
                  forename={this.props.contactItem && this.props.contactItem.profile.forename}
                  surname={this.props.contactItem && this.props.contactItem.profile.surname}
                  email={this.props.contactItem && this.props.contactItem.profile.email}
                  contactType={this.props.contactItem && this.props.contactItem.contact.type}
                  contactId={this.props.contactItem && this.props.contactItem.contact.contactId}
                  contactName={this.props.contactItem && this.props.contactItem.profile.forename + " " + this.props.contactItem.profile.surname} />
              </Col>
              <Col className={styles.itemButtons}>
                <img
                  src={editIcon}
                  alt="Edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    this.props.history.push(`/dashboard/contact/${this.props.contactItem.contact.contactId}?returnUrl=${this.props.match.path}`)
                  }}
                />
              </Col>
              <Col className={styles.itemButtons}>
                <img
                  src={deleteIcon}
                  alt="Delete"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    this.onOpenModal();
                  }}
                />
              </Col>
            </Row>
          </Row>
        </div>
        <div className={styles.bottomRight}>
          <span>Added:</span>{" "}
          <DateTimeFormat
            showTimeAgoFormat
            rawDateTime={
              this.props.contactItem && this.props.contactItem.contact.dateAdded
            }
            showOnlyDateFormat={false}
          />
          {this.props.contactItem &&
            this.props.contactItem.addedBy &&
            " by " + this.props.contactItem.addedBy}
        </div>
      </ListItem>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      toggleAddTeamMember
    },
    dispatch
  );

export default withRouter<IProps,any>(connect(
  null,
  mapDispatchToProps)
  (ContactListItem));