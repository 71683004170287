import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import { toggleAddClientSidebar } from '../../../helpers/sidebarHelpers'
// Services
import { getClients } from '../../../services/ClientService'
import { IClientListItem, IUserListItem } from '../../../types/ClientService'
import IconButton from '../../atoms/Buttons/IconButton'
import ClientListItem from '../../atoms/ClientListItem'
import ListItem from '../../atoms/ListComponents/ListItem'
import SearchBar from '../../atoms/SearchBar'
import withErrorShield from '../../atoms/withErrorShield'
// Import Styles
import styles from './ClientsManagementPage.module.scss'

interface IState {
    clients: Array<IClientListItem>
}

interface IProps {
    user: IUserListItem
}

class ClientsManagementPage extends Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            clients: [],
        }
    }

    componentDidMount() {
        this.getClientList()
    }

    getClientList() {
        getClients()
            .then((resp) => {
                if (resp && resp.data) {
                    this.setState({
                        clients: resp.data,
                    })
                }
            })
            .catch((error) => {
                throw 'Error'
            })
    }

    render() {
        return (
            <div className={styles.clientsManagement}>
                <SearchBar text="" onSearch={() => null} />

                <Row>
                    <Col className={styles.addButton}>
                        <IconButton
                            button={{
                                text: 'Add Client',
                                displayType: 'submit',
                                elementType: 'button',
                                icon: 'add',
                                onClick: () => toggleAddClientSidebar(true),
                            }}
                        />
                    </Col>
                </Row>

                {this.state.clients &&
                    this.state.clients.map((client, index) => {
                        return (
                            <ListItem
                                client={client}
                                user={this.props.user}
                                key={index}
                            >
                                <ClientListItem client={client} />
                            </ListItem>
                        )
                    })}
            </div>
        )
    }
}

export default withErrorShield(ClientsManagementPage)
