import {
    Aggregate,
    Column,
    ColumnMenu,
    CommandColumn,
    ContextMenu,
    ExcelExport,
    Filter,
    GridComponent,
    Group,
    Inject,
    Page,
    PdfExport,
    QueryCellInfoEventArgs,
    Reorder,
    Resize,
    Sort,
} from '@syncfusion/ej2-react-grids'
import {
    ChangedEventArgs,
    TextBoxComponent,
} from '@syncfusion/ej2-react-inputs'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Router, withRouter } from 'react-router-dom'
import {
    filterOptions,
    pageSettings,
} from '../../../../configuration/grid_syncfusion_options'
import { hasClientArea } from '../../../../helpers/clientHelpers'
import {
    addURLParameter,
    removeURLParameter,
} from '../../../../helpers/locationHelpers'
import { toggleAddDocumentPopup } from '../../../../helpers/sidebarHelpers'
import { updateToastMessage } from '../../../../helpers/toastHelper'
import history from '../../../../history'
import { ExportTypeEnum } from '../../../../types/Transaction'
import { IAddEditInspection } from '../../../atoms/AddEditInspection'
import IconButton from '../../../atoms/Buttons/IconButton'
import SFDropdown from '../../../atoms/SFDropdown'
import SFInspectionDropdown from '../../../atoms/SFDropdown/SFInspectionDropdown'
import UserContactInfo, {
    UserContactInfoType,
} from '../../../atoms/UserContactInfo'
import { IDocumentUploadOptions } from '../../../molecules/AddDocumentModal'
import styles from './InspectionsGrid.module.scss'
import { editSettings, textWrapSettings } from './_config/columnOptions'
import { generateOdataSource } from './_config/dataConfig'
import { selectionSettings } from './_config/selectionConfig'
import { onDeleteRows } from './_helpers'
import { DefaultTemplate } from './_templates/defaultTemplate'
import { IInspectionsGridOptions } from './_types'
const queryString = require('query-string')

interface IState {
    loading?: boolean
    hasBeenGrouped: boolean
    search?: string
    startDate?: Date
    endDate?: Date
    selectedType?: string
    deletingUserIds: string[]
    deleting: boolean
}

interface IProps {
    config: IInspectionsGridOptions
    history: any
    location: any
    match: any
    dateRangeStart?: Date
    dateRangeEnd?: Date
}

class InspectionsGrid extends Component<IProps, IState> {
    public grid: any
    public topRowRef: any

    constructor(props: any) {
        super(props)

        this.topRowRef = React.createRef()
        this.grid = React.createRef()

        this.state = {
            loading: false,
            selectedType: '',
            hasBeenGrouped: false,
            search: '',
            deletingUserIds: [],
            deleting: false,
        }

        this.externalSearchHandler = this.externalSearchHandler.bind(this)
        this.externalExportHandler = this.externalExportHandler.bind(this)
        this.requestDataSource = this.requestDataSource.bind(this)
        this.onDateRangeChange = this.onDateRangeChange.bind(this)
        this.deleteRow = this.deleteRow.bind(this)
        this.onResetPassword = this.onResetPassword.bind(this)
        this.customCell = this.customCell.bind(this)
        this.onAddInspection = this.onAddInspection.bind(this)
        this.contextMenuClickedDelete = this.contextMenuClickedDelete.bind(this)
        this.addDocumentClicked = this.addDocumentClicked.bind(this)
    }

    contextMenuClickedDelete() {
        if (!this.grid || !this.grid.current) {
            return
        }

        const { current } = this.grid

        let rows = current.getSelectedRecords()

        if (rows.length == 0) {
            updateToastMessage('No rows have been selected.', 'warning')
            return
        }

        let inspectionIds = []
        for (let i = 0; i < rows.length; i++) {
            inspectionIds.push((rows[i] as any).inspectionId)
        }

        onDeleteRows(inspectionIds, this.props.location, this.props.history)
    }

    shouldComponentUpdate(nextProps: any, nextState: any): boolean {
        // If the refresh param is present then perform a refresh
        let newQuery = queryString.parse(
            nextProps.location.search
        ).refreshInspectionsGrid
        let previousQuery = queryString.parse(
            this.props.location.search
        ).refreshInspectionsGrid

        if (newQuery == 'true' && previousQuery != 'true') {
            let removeQuery = removeURLParameter(
                nextProps.location.search,
                'refreshInspectionsGrid'
            )
            this.props.history.push({
                search: removeQuery,
            })

            return true
        }

        if (this.state.search != nextState.search) {
            return true
        }

        return false
    }

    externalExportHandler(exportType: ExportTypeEnum): void {
        if (!this.grid) {
            return
        }

        let selectedRecords = this.grid.getSelectedRecords()
        if (!selectedRecords) {
            return
        }

        if (exportType == ExportTypeEnum.PDF) {
            this.grid.pdfExport()
            return
        }

        if (exportType == ExportTypeEnum.Excel) {
            this.grid.excelExport()
            return
        }

        if (exportType == ExportTypeEnum.Csv) {
            this.grid.csvExport()
            return
        }
    }

    externalSearchHandler(searchValue: string): void {
        this.setState(
            {
                search: searchValue,
            },
            () => this.requestDataSource()
        )
    }

    customCell(args: QueryCellInfoEventArgs) {
        if ((args.column as Column).headerText === 'Created By') {
            let data = args.data as any
            if (data.createdBy && data.createdBy != null) {
                ReactDOM.render(
                    <Router history={history}>
                        <UserContactInfo
                            id={data.createdBy}
                            type={UserContactInfoType.User}
                        />
                    </Router>,
                    args.cell as Element
                )
            } else {
                ReactDOM.render(<div> </div>, args.cell as Element)
            }
        }

        if ((args.column as Column).headerText === 'Inspector') {
            let data = args.data as any
            if (data.inspectorId && data.inspectorId != null) {
                ReactDOM.render(
                    <Router history={history}>
                        <UserContactInfo
                            id={data.inspectorId}
                            type={UserContactInfoType.Contact}
                        />
                    </Router>,
                    args.cell as Element
                )
            } else {
                ReactDOM.render(<div> </div>, args.cell as Element)
            }
        }

        if ((args.column as Column).headerText === 'User') {
            let data = args.data as any
            if (data.userId && data.userId != null) {
                ReactDOM.render(
                    <Router history={history}>
                        <UserContactInfo
                            id={data.userId}
                            type={UserContactInfoType.User}
                        />
                    </Router>,
                    args.cell as Element
                )
            } else {
                ReactDOM.render(<div> </div>, args.cell as Element)
            }
        }

        if ((args.column as Column).field === 'Action') {
            let data = args.data as any
            ReactDOM.render(
                <div>
                    <Router history={history}>
                        <SFInspectionDropdown
                            propertyId={data.propertyId}
                            propertyName={data.propertyName}
                            inspectionId={data.inspectionId}
                            updateRedirectUrl={
                                data.propertyId
                                    ? `/dashboard/property/${data.propertyId}/spec/inspections`
                                    : null
                            }
                        />
                    </Router>
                </div>,
                args.cell as Element
            )
        }
    }

    componentDidMount(): void {
        this.requestDataSource()
    }

    requestDataSource(): void {
        if (!this.grid) {
            this.grid.dataSource = generateOdataSource(
                {
                    size: 0,
                    page: 0,
                    search: this.state.search,
                },
                this.state.startDate,
                this.state.endDate,
                this.props.config.dataFilterSettings &&
                    this.props.config.dataFilterSettings.active
            )
        }
    }

    deleteRow(): void {
        this.requestDataSource()
    }

    onDateRangeChange(startDate: Date, endDate: Date): void {
        this.setState(
            {
                startDate,
                endDate,
            },
            () => {
                this.requestDataSource()
            }
        )
    }

    onResetPassword(): void {}

    onAddInspection(): void {
        let request: IAddEditInspection = {
            propertyId:
                this.props.config.addSettings &&
                this.props.config.addSettings.propertyId,
            propertyName:
                this.props.config.addSettings &&
                this.props.config.addSettings.propertyName,
            returnUrl:
                this.props.config.addSettings &&
                this.props.config.addSettings.returnUrl,
        }

        let string = `inspectionOptions=${JSON.stringify(request)}`
        let query = addURLParameter(this.props.location.search, string)

        this.props.history.push({
            pathname: '/dashboard/inspection/add',
            search: query,
        })
    }

    addDocumentClicked(propertyId: string, inspectionId: number): void {
        let options: IDocumentUploadOptions = {
            propertyId,
            inspectionId,
            documentTypeId: '85BA3702-2186-4ACC-B489-6A80E692894F',
        }

        let query = 'uploadDocumentOptions=' + JSON.stringify(options)

        let search1 = addURLParameter(this.props.location.search, query)

        history.push({
            search: search1,
        })

        toggleAddDocumentPopup(true)
    }

    render() {
        return (
            <div className={styles.page}>
                <div className={styles.content}>
                    <div className={`${styles.row}`}>
                        <div className={styles.column}>
                            <div className={styles.left}>
                                <div className={styles.dropdown}>
                                    <div className={styles.searchInput}>
                                        <TextBoxComponent
                                            change={(args: ChangedEventArgs) =>
                                                this.externalSearchHandler(
                                                    args.value
                                                )
                                            }
                                            placeholder="Search Inspections"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.iconColumn}>
                            <SFDropdown
                                loadedBeforeRender
                                customCssClassName={styles.syncfusionDropdown}
                                customToggle={
                                    <IconButton
                                        button={{
                                            text: 'Actions',
                                            displayType: 'action',
                                            elementType: 'button',
                                            icon: 'action',
                                        }}
                                    />
                                }
                                items={[
                                    ...(hasClientArea('InspectionsFunctions')
                                        ? [
                                              {
                                                  iconCss: styles.subIconAdd,
                                                  text: 'Add Inspection',
                                              },
                                          ]
                                        : []),
                                    {
                                        iconCss: styles.subIconDelete,
                                        text: 'Delete',
                                    },
                                    {
                                        separator: true,
                                    },
                                    {
                                        iconCss: styles.subIconExportExcel,
                                        text: 'Export to Excel',
                                    },
                                    {
                                        iconCss: styles.subIconExportCsv,
                                        text: 'Export to CSV',
                                    },
                                    {
                                        iconCss: styles.subIconExportPdf,
                                        text: 'Export to PDF',
                                    },
                                ]}
                                onSelect={(args) => {
                                    if (args.item.text === 'Add Inspection') {
                                        this.onAddInspection()
                                    }
                                    if (args.item.text === 'Delete') {
                                        this.contextMenuClickedDelete()
                                    }
                                    if (args.item.text === 'Export to PDF') {
                                        this.grid.current.pdfExport()
                                    }
                                    if (args.item.text === 'Export to Excel') {
                                        this.grid.current.excelExport()
                                    }
                                    if (args.item.text === 'Export to CSV') {
                                        this.grid.current.csvExport()
                                    }
                                }}
                            />
                        </div>

                        <div
                            className={`${styles.iconColumn} ${styles.addIcons}`}
                        >
                            <IconButton
                                button={{
                                    text: 'Add Inspection',
                                    displayType: 'submit',
                                    elementType: 'button',
                                    icon: 'add',
                                    onClick: () => this.onAddInspection(),
                                }}
                            />
                        </div>
                    </div>

                    <div className={`${styles.row}`}>
                        <div className={`${styles.column} ${styles.grid}`}>
                            <GridComponent
                                ref={this.grid}
                                queryCellInfo={this.customCell}
                                editSettings={editSettings}
                                showColumnMenu
                                id="inspectionsGrid"
                                textWrapSettings={textWrapSettings}
                                allowTextWrap={true}
                                allowExcelExport={true}
                                allowPdfExport={true}
                                allowGrouping={true}
                                dataSource={generateOdataSource(
                                    {
                                        size: 0,
                                        page: 0,
                                        search: this.state.search,
                                        propertyId:
                                            this.props.config
                                                .dataFilterSettings &&
                                            this.props.config.dataFilterSettings
                                                .propertyId,
                                        contactId:
                                            this.props.config
                                                .dataFilterSettings &&
                                            this.props.config.dataFilterSettings
                                                .contactId,
                                    },
                                    this.state.startDate,
                                    this.state.endDate,
                                    this.props.config.dataFilterSettings &&
                                        this.props.config.dataFilterSettings
                                            .active
                                )}
                                allowResizing={true}
                                allowReordering={true}
                                selectionSettings={selectionSettings}
                                allowPaging={true}
                                allowSorting={true}
                                sortSettings={{
                                    columns: [
                                        {
                                            field: 'createdDate',
                                            direction: 'Descending',
                                        },
                                    ],
                                }}
                                pageSettings={pageSettings}
                                filterSettings={filterOptions}
                                allowFiltering={true}
                            >
                                <Inject
                                    services={[
                                        Aggregate,
                                        ColumnMenu,
                                        ContextMenu,
                                        CommandColumn,
                                        Filter,
                                        Page,
                                        Sort,
                                        ExcelExport,
                                        PdfExport,
                                        Group,
                                        Reorder,
                                        Resize,
                                    ]}
                                />

                                {this.props.config.template
                                    ? this.props.config.template
                                    : DefaultTemplate(this.props)}
                            </GridComponent>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(InspectionsGrid)
