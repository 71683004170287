import React, { useEffect } from 'react';
import { DataResult } from '@syncfusion/ej2-data';
import {
    Aggregate,
    ColumnMenu,
    CommandColumn,
    ContextMenu,
    DataStateChangeEventArgs,
    Filter,
    Grid as GridType,
    GridComponent,
    Inject,
    Page,
    PageEventArgs,
    RecordClickEventArgs,
    Reorder,
    Resize,
    Sort,
    Sorts,
    Group,
    CommandClickEventArgs,
    ExcelExport
} from '@syncfusion/ej2-react-grids';
import { filterOptions } from '../../../../configuration/grid_syncfusion_options'
import * as S from './RCGrid.styles'
import {
    Button,
    Popup
  } from "@rentchief/components";
import styles from './AuditPageV2.module.scss'

interface IProps {
    loading: boolean;
    datasource: any;
    fetchData: any;
    columnsDirective: any;
    id?: string;
    onGridButtonClicked?: any;
    noResultsMessage?: string;
    gridAnchorLink?: any;
    rowClick?: any;
    commandClick?: any;
    allowResizing?: boolean;
    allowPaging?: boolean;
    allowSorting?: boolean;
    allowTextWrap?: boolean;
    allowGrouping?: boolean;
    allowFiltering?: boolean;
    showColumnMenu?: boolean;
    title?: string;
    height?: string;
    showPageSizes?: boolean;
    pageSize?: number;
}

const RCGrid = (props: IProps) => {
    let gridRef: GridType | null = null;

    useEffect(() => {

        updateEmptyRecordMessage(props.noResultsMessage);
    }, [props.noResultsMessage]);

    useEffect(() => {
        if (gridRef) {
            if (props.loading) {
                gridRef.showSpinner();
            } else {
                gridRef.hideSpinner();
            }
        }
    }, [props.loading]);

    const updateEmptyRecordMessage = (message: string) => {

        if (gridRef) {
            const el = document.getElementsByClassName('e-emptyrow');
            if (el && el[0]) {
                el[0].firstChild.textContent = message;
            }
        }
    };

    const dataStateChange = (state: DataStateChangeEventArgs | undefined) => {
        let sortQuery: string = '';
        if (state != undefined && state.action != undefined) {
            if (state.sorted != undefined) {
                if ((state.sorted || []).length) {
                    sortQuery = state.sorted
                        .map((obj: Sorts) => {
                            return obj.direction === 'descending' ? `${obj.name} desc` : `${obj.name} asc`;
                        })
                        .reverse()
                        .join(',');
                }
            }

            const args: PageEventArgs = state.action;

            props.fetchData(
                args.currentPage != undefined ? parseInt(args.currentPage) : 1,
                state.take,
                state.skip,
                sortQuery,
            );
        }
    };

    const DataResult = (): DataResult => {
        if (props.datasource != undefined && props.datasource.data != undefined) {
            return {
                result: props.datasource.data,
                count: props.datasource.count,
            };
        } else {
            return { result: [], count: 0 };
        }
    };

    const gridDataBound = () => {
        if (!props.noResultsMessage) {
            return;
        }

        updateEmptyRecordMessage(props.noResultsMessage);
    };

    const onRowClick = (e: RecordClickEventArgs) => {
        if (!props.rowClick) return;
        // This filters out clicks on any of the other items for e.g. the checkbox and action menu
        if (e.target && e.target.classList.contains('e-rowcell')) {
            props.rowClick(e.rowData as any);
        }
    };

    const commandClick = (args: CommandClickEventArgs): void => {
        if (gridRef) {
            props.commandClick(args.commandColumn.title, args.rowData);
        }
    }

    return (
        <div>
            <div className={styles.wrapperDiv}>
                <div>
                    <Button className={styles.excelButtonDiv} onClick={() => gridRef.excelExport()}>
                        Export To Excel
                    </Button>
                </div>
            </div>
            <S.GlobalGridStyle />
            <GridComponent
                recordClick={onRowClick}
                height={props.height != null ? props.height : "500px"}
                id={props.id != null ? props.id : "dataGrid"}
                ref={(g) => (gridRef = g)}
                dataBound={gridDataBound}
                dataSource={DataResult()}
                dataStateChange={dataStateChange}
                title={props.title != null ? props.title : ""}
                pageSettings={{ pageSize: props.pageSize != null ? props.pageSize : 20, 
                    pageSizes: props.showPageSizes != null ? props.showPageSizes : true }}
                allowResizing={props.allowResizing != null ? props.allowResizing : true}
                allowPaging={props.allowPaging != null ? props.allowPaging : true}
                allowSorting={props.allowSorting != null ? props.allowSorting : true}
                allowTextWrap={props.allowTextWrap != null ? props.allowTextWrap : true}
                commandClick={commandClick}
                allowGrouping={props.allowGrouping != null ? props.allowGrouping : true}
                allowFiltering={props.allowFiltering != null ? props.allowFiltering : true}
                showColumnMenu={props.showColumnMenu != null ? props.showColumnMenu : true}
                filterSettings={filterOptions}
                allowExcelExport={true}
            >
                <Inject
                    services={[Aggregate, ColumnMenu, ContextMenu, CommandColumn, Filter, Page, Sort, Reorder, Resize, Group, Filter, ExcelExport]}
                />
               {props.columnsDirective()}
            </GridComponent>
        </div>
    );
};

export default RCGrid;