import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styles from './PropertyTenancyRequirements.module.scss'

import { toggleAddEditTenancyPopup } from '../../../../../helpers/sidebarHelpers'
import {
    appendAddTenancyOptions,
    IAddTenancyOptions,
} from '../../../../../helpers/locationHelpers'
import { updateViewingTenancy } from '../../../../../helpers/propertyHelpers'
import { Formik, Form } from 'formik'
import { FormGroup } from 'reactstrap'
import MaterialCheckbox from '../../../../atoms/MaterialCheckbox'
import DatePicker from 'react-datepicker'
import {
    getTenancyRequirement,
    updateTenancyRequirement,
} from '../../../../../services/TenancyService'
import SelectDropdown, { IOptions } from '../../../../atoms/SelectDropdown'
import { ITenancyRequirement } from '../../../../../types/Tenancy'
import { getTypesForDropdown } from '../../../../../services/EntityService'
import RotatingLoader from '../../../../atoms/RotatingLoader'
import CurrencyInput from '../../../../atoms/CurrencyInput'
import moment from 'moment'
import Buttons from '../../../../atoms/Buttons'

interface IProps {
    history: any
    match: any
    location: any
    postcode: string
}

interface IState {
    noResults: boolean
    tenancyRequirement: ITenancyRequirement
    loading: boolean
    tenancyTypes: Array<IOptions>
    contractTypes: Array<IOptions>
}

class PropertyTenancyRequirements extends Component<IProps, IState> {
    constructor(props: any) {
        super(props)
        this.state = {
            noResults: false,
            tenancyRequirement: {
                availableFrom: new Date(),
            } as ITenancyRequirement,
            loading: false,
            tenancyTypes: [],
            contractTypes: [],
        }
    }

    async componentDidMount(): Promise<void> {
        this.getTenancyRequirements()
        await this.getEntityTypes(
            '144e8562-6f5b-4eab-b6fa-d86efb18a41b',
            'tenancyTypes'
        )
        await this.getEntityTypes(
            '367db491-f955-4061-881b-e4aa4d48c414',
            'contractTypes'
        )
    }

    async getEntityTypes(
        type: string,
        field: string,
        callback?: any
    ): Promise<void> {
        getTypesForDropdown(type).then((resp) => {
            if (resp && resp.data && resp.data.length > 0) {
                this.setState(
                    { [field]: resp.data } as Pick<IState, any>,
                    () => {
                        if (callback) {
                            callback()
                        }
                    }
                )
            }
        })
    }

    componentWillUnmount(): void {
        updateViewingTenancy(null)
    }

    noResultsCallback = (): void => {
        this.setState({
            noResults: true,
        })
    }

    onAddTenancy = (): void => {
        let options: IAddTenancyOptions = {
            propertyId: this.props.match.params.propertyId,
        }

        let query = appendAddTenancyOptions(this.props.location.search, options)
        this.props.history.push({
            search: query,
        })

        toggleAddEditTenancyPopup(true)
    }

    getTenancyRequirements = (): void => {
        this.setState({
            loading: true,
        })

        getTenancyRequirement(this.props.match.params.propertyId).then(
            (resp) => {
                if (resp && resp.data) {
                    this.setState({
                        tenancyRequirement: resp.data,
                    })
                }

                this.setState({
                    loading: false,
                })
            }
        )
    }

    updateTenancyRequirements(): void {
        this.setState({
            loading: true,
        })

        updateTenancyRequirement(
            this.props.match.params.propertyId,
            this.state.tenancyRequirement
        ).then((resp) => {
            this.setState({
                loading: false,
            })
        })
    }

    handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        const field = event.currentTarget.name
        const value: string = event.currentTarget.value

        this.setState((prevState) => ({
            tenancyRequirement: {
                ...prevState.tenancyRequirement,
                [field]: value,
            },
        }))
    }

    handleCheckboxChange = (field: string): void => {
        this.setState((prevState) => ({
            tenancyRequirement: {
                ...prevState.tenancyRequirement,
                [field]: !this.state.tenancyRequirement[field],
            },
        }))
    }

    handleChangeCurrencyInput = (event, maskedvalue, floatvalue) => {
        const field = event.target['name']
        this.setState(
            (prevState) =>
                ({
                    tenancyRequirement: {
                        ...prevState.tenancyRequirement,
                        [field]: floatvalue,
                    },
                } as Pick<IState, any>)
        )
    }

    render() {
        if (this.state.loading) {
            return (
                <div>
                    <RotatingLoader loading={true} text="Loading Defaults..." />
                </div>
            )
        }

        return (
            <div className={styles.page}>
                <Formik
                    initialValues={{
                        tenantId: null,
                        propertyId: null,
                    }}
                    enableReinitialize={true}
                    onSubmit={(values: any, { setSubmitting }) => {
                        this.updateTenancyRequirements()
                    }}
                >
                    {(props) => (
                        <Form>
                            <div id={styles.title} className={styles.title}>
                                Tenancy Terms
                            </div>
                            <div className={styles.rowJustifyLeft}>
                                <div className={styles.column}>
                                    <FormGroup className={styles.flexColumn}>
                                        <div className={styles.label}>
                                            Tenancy Type
                                        </div>
                                        <SelectDropdown
                                            selectedId={
                                                (this.state
                                                    .tenancyRequirement &&
                                                    this.state
                                                        .tenancyRequirement
                                                        .tenancyType) ||
                                                ''
                                            }
                                            showDefaultOption
                                            data={this.state.tenancyTypes}
                                            placeholder="Tenancy Type..."
                                            onSelect={(id: IOptions) =>
                                                this.setState((prevState) => ({
                                                    tenancyRequirement: {
                                                        ...prevState.tenancyRequirement,
                                                        tenancyType: id.value,
                                                    },
                                                }))
                                            }
                                        />
                                    </FormGroup>
                                </div>

                                <div className={styles.column}>
                                    <FormGroup className={styles.flexColumn}>
                                        <div className={styles.label}>
                                            Contract Type
                                        </div>
                                        <SelectDropdown
                                            selectedId={
                                                (this.state
                                                    .tenancyRequirement &&
                                                    this.state
                                                        .tenancyRequirement
                                                        .contractType) ||
                                                ''
                                            }
                                            showDefaultOption
                                            data={this.state.contractTypes}
                                            placeholder="Contract Type..."
                                            onSelect={(id: IOptions) =>
                                                this.setState((prevState) => ({
                                                    tenancyRequirement: {
                                                        ...prevState.tenancyRequirement,
                                                        contractType: id.value,
                                                    },
                                                }))
                                            }
                                        />
                                    </FormGroup>
                                </div>

                                <div className={styles.column}>
                                    <FormGroup className={styles.flexColumn}>
                                        <div className={styles.label}>
                                            Duration
                                        </div>

                                        <div
                                            className={`${styles.row} ${styles.durationRow}`}
                                        >
                                            <div
                                                className={`${styles.columnSmall} ${styles.durationValueContainer}`}
                                            >
                                                <input
                                                    onChange={this.handleChange}
                                                    name="durationValue"
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    className={`${styles.input} ${styles.durationValue}`}
                                                    placeholder="6"
                                                    value={
                                                        (this.state
                                                            .tenancyRequirement &&
                                                            this.state
                                                                .tenancyRequirement
                                                                .durationValue) ||
                                                        6
                                                    }
                                                />
                                            </div>

                                            <div
                                                className={`${styles.column} ${styles.durationRange}`}
                                            >
                                                <SelectDropdown
                                                    data={[
                                                        {
                                                            label: 'Days',
                                                            value: 'Days',
                                                        },
                                                        {
                                                            label: 'Weeks',
                                                            value: 'Weeks',
                                                        },
                                                        {
                                                            label: 'Months',
                                                            value: 'Months',
                                                        },
                                                        {
                                                            label: 'Years',
                                                            value: 'Years',
                                                        },
                                                    ]}
                                                    placeholder="Duration..."
                                                    onSelect={(id: IOptions) =>
                                                        this.setState(
                                                            (prevState) => ({
                                                                tenancyRequirement:
                                                                    {
                                                                        ...prevState.tenancyRequirement,
                                                                        durationRange:
                                                                            id.value,
                                                                    },
                                                            })
                                                        )
                                                    }
                                                    selectedId={
                                                        (this.state
                                                            .tenancyRequirement &&
                                                            this.state
                                                                .tenancyRequirement
                                                                .durationRange) ||
                                                        'Months'
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className={styles.column}>
                                    <FormGroup className={styles.flexColumn}>
                                        <div className={styles.label}>
                                            Available from
                                        </div>
                                        <div className={styles.datePicker}>
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                className={styles.input}
                                                selected={
                                                    this.state
                                                        .tenancyRequirement &&
                                                    this.state
                                                        .tenancyRequirement
                                                        .availableFrom &&
                                                    moment(
                                                        this.state
                                                            .tenancyRequirement
                                                            .availableFrom
                                                    )
                                                        .local()
                                                        .toDate()
                                                }
                                                onChange={(date: Date) =>
                                                    this.setState(
                                                        (prevState) => ({
                                                            tenancyRequirement:
                                                                {
                                                                    ...prevState.tenancyRequirement,
                                                                    availableFrom:
                                                                        date,
                                                                },
                                                        })
                                                    )
                                                }
                                            />
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>

                            <div className={styles.rowJustifyLeft}>
                                <div className={styles.column}>
                                    <FormGroup className={styles.flexColumn}>
                                        <div className={styles.label}>
                                            Target Rent
                                        </div>
                                        <CurrencyInput
                                            name="marketRent"
                                            inputType={'tel'}
                                            handleChange={
                                                this.handleChangeCurrencyInput
                                            }
                                            className={styles.input}
                                            value={
                                                this.state.tenancyRequirement &&
                                                this.state.tenancyRequirement
                                                    .marketRent
                                                    ? this.state.tenancyRequirement.marketRent.toString()
                                                    : '0'
                                            }
                                            prefix="£"
                                        />
                                    </FormGroup>
                                </div>

                                <div className={styles.column}>
                                    <FormGroup className={styles.flexColumn}>
                                        <div className={styles.label}>
                                            Frequency
                                        </div>
                                        <SelectDropdown
                                            data={[
                                                {
                                                    label: 'Daily',
                                                    value: 'Daily',
                                                },
                                                {
                                                    label: 'Weekly',
                                                    value: 'Weekly',
                                                },
                                                {
                                                    label: 'BI Weekly',
                                                    value: 'BiWeekly',
                                                },
                                                {
                                                    label: 'Monthly',
                                                    value: 'Monthly',
                                                    isDefault: true,
                                                },
                                                {
                                                    label: 'Quarterly',
                                                    value: 'Quarterly',
                                                },
                                                {
                                                    label: 'Half Yearly',
                                                    value: 'HalfYearly',
                                                },
                                                {
                                                    label: 'Yearly',
                                                    value: 'Yearly',
                                                },
                                                {
                                                    label: 'One Off',
                                                    value: 'OneOff',
                                                },
                                            ]}
                                            showDefaultOption
                                            placeholder="Frequency..."
                                            onSelect={(id: IOptions) =>
                                                this.setState((prevState) => ({
                                                    tenancyRequirement: {
                                                        ...prevState.tenancyRequirement,
                                                        frequency: id.value,
                                                    },
                                                }))
                                            }
                                            selectedId={
                                                this.state.tenancyRequirement &&
                                                this.state.tenancyRequirement
                                                    .frequency
                                            }
                                        />
                                    </FormGroup>
                                </div>
                            </div>

                            <div className={styles.checkboxRow}>
                                <div className={styles.columnSmall}>
                                    <FormGroup className={styles.flexColumn}>
                                        <div>
                                            <MaterialCheckbox
                                                checked={
                                                    this.state
                                                        .tenancyRequirement &&
                                                    this.state
                                                        .tenancyRequirement
                                                        .petsAllowed
                                                }
                                                onChange={() =>
                                                    this.handleCheckboxChange(
                                                        'petsAllowed'
                                                    )
                                                }
                                                labelText="Pets Allowed"
                                            />
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className={styles.columnSmall}>
                                    <FormGroup className={styles.flexColumn}>
                                        <div>
                                            <MaterialCheckbox
                                                checked={
                                                    this.state
                                                        .tenancyRequirement &&
                                                    this.state
                                                        .tenancyRequirement
                                                        .smokingAllowed
                                                }
                                                onChange={() =>
                                                    this.handleCheckboxChange(
                                                        'smokingAllowed'
                                                    )
                                                }
                                                labelText="Smoking Allowed"
                                            />
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>

                            <div className={styles.checkboxRowWrapper}>
                                <div className={styles.checkboxRowLeft}>
                                    <div className={styles.title}>
                                        Bills Included
                                    </div>
                                    <div className={styles.checkboxList}>
                                        <div
                                            className={`${styles.columnSmall} ${styles.checkbox}`}
                                        >
                                            <FormGroup
                                                className={styles.flexColumn}
                                            >
                                                <div>
                                                    <MaterialCheckbox
                                                        checked={
                                                            this.state
                                                                .tenancyRequirement &&
                                                            this.state
                                                                .tenancyRequirement
                                                                .electricityIncluded
                                                        }
                                                        onChange={() =>
                                                            this.handleCheckboxChange(
                                                                'electricityIncluded'
                                                            )
                                                        }
                                                        labelText="Electricity"
                                                    />
                                                </div>
                                            </FormGroup>
                                        </div>

                                        <div
                                            className={`${styles.columnSmall} ${styles.checkbox}`}
                                        >
                                            <FormGroup
                                                className={styles.flexColumn}
                                            >
                                                <div>
                                                    <MaterialCheckbox
                                                        checked={
                                                            this.state
                                                                .tenancyRequirement &&
                                                            this.state
                                                                .tenancyRequirement
                                                                .gasIncluded
                                                        }
                                                        onChange={() =>
                                                            this.handleCheckboxChange(
                                                                'gasIncluded'
                                                            )
                                                        }
                                                        labelText="Gas"
                                                    />
                                                </div>
                                            </FormGroup>
                                        </div>
                                        <div
                                            className={`${styles.columnSmall} ${styles.checkbox}`}
                                        >
                                            <FormGroup
                                                className={styles.flexColumn}
                                            >
                                                <div>
                                                    <MaterialCheckbox
                                                        checked={
                                                            this.state
                                                                .tenancyRequirement &&
                                                            this.state
                                                                .tenancyRequirement
                                                                .waterIncluded
                                                        }
                                                        onChange={() =>
                                                            this.handleCheckboxChange(
                                                                'waterIncluded'
                                                            )
                                                        }
                                                        labelText="Water"
                                                    />
                                                </div>
                                            </FormGroup>
                                        </div>
                                        <div
                                            className={`${styles.columnSmall} ${styles.checkbox}`}
                                        >
                                            <FormGroup
                                                className={styles.flexColumn}
                                            >
                                                <div>
                                                    <MaterialCheckbox
                                                        checked={
                                                            this.state
                                                                .tenancyRequirement &&
                                                            this.state
                                                                .tenancyRequirement
                                                                .tvLicenseIncluded
                                                        }
                                                        onChange={() =>
                                                            this.handleCheckboxChange(
                                                                'tvLicenseIncluded'
                                                            )
                                                        }
                                                        labelText="TV License"
                                                    />
                                                </div>
                                            </FormGroup>
                                        </div>
                                        <div
                                            className={`${styles.columnSmall} ${styles.checkbox}`}
                                        >
                                            <FormGroup
                                                className={styles.flexColumn}
                                            >
                                                <div>
                                                    <MaterialCheckbox
                                                        checked={
                                                            this.state
                                                                .tenancyRequirement &&
                                                            this.state
                                                                .tenancyRequirement
                                                                .skyCableIncluded
                                                        }
                                                        onChange={() =>
                                                            this.handleCheckboxChange(
                                                                'skyCableIncluded'
                                                            )
                                                        }
                                                        labelText="Sky/Cable"
                                                    />
                                                </div>
                                            </FormGroup>
                                        </div>
                                        <div
                                            className={`${styles.columnSmall} ${styles.checkbox}`}
                                        >
                                            <FormGroup
                                                className={styles.flexColumn}
                                            >
                                                <div>
                                                    <MaterialCheckbox
                                                        checked={
                                                            this.state
                                                                .tenancyRequirement &&
                                                            this.state
                                                                .tenancyRequirement
                                                                .internetIncluded
                                                        }
                                                        onChange={() =>
                                                            this.handleCheckboxChange(
                                                                'internetIncluded'
                                                            )
                                                        }
                                                        labelText="Internet"
                                                    />
                                                </div>
                                            </FormGroup>
                                        </div>
                                        <div
                                            className={`${styles.columnSmall} ${styles.checkbox}`}
                                        >
                                            <FormGroup
                                                className={styles.flexColumn}
                                            >
                                                <div>
                                                    <MaterialCheckbox
                                                        checked={
                                                            this.state
                                                                .tenancyRequirement &&
                                                            this.state
                                                                .tenancyRequirement
                                                                .phoneIncluded
                                                        }
                                                        onChange={() =>
                                                            this.handleCheckboxChange(
                                                                'phoneIncluded'
                                                            )
                                                        }
                                                        labelText="Phone"
                                                    />
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.checkboxRowRight}>
                                    <div className={styles.title}>
                                        Eligibility
                                    </div>
                                    <div className={styles.checkboxList}>
                                        <div
                                            className={`${styles.columnSmall} ${styles.checkbox}`}
                                        >
                                            <FormGroup
                                                className={styles.flexColumn}
                                            >
                                                <div>
                                                    <MaterialCheckbox
                                                        checked={
                                                            this.state
                                                                .tenancyRequirement &&
                                                            this.state
                                                                .tenancyRequirement
                                                                .dss
                                                        }
                                                        onChange={() =>
                                                            this.handleCheckboxChange(
                                                                'dss'
                                                            )
                                                        }
                                                        labelText="DSS"
                                                    />
                                                </div>
                                            </FormGroup>
                                        </div>
                                        <div
                                            className={`${styles.columnSmall} ${styles.checkbox}`}
                                        >
                                            <FormGroup
                                                className={styles.flexColumn}
                                            >
                                                <div>
                                                    <MaterialCheckbox
                                                        checked={
                                                            this.state
                                                                .tenancyRequirement &&
                                                            this.state
                                                                .tenancyRequirement
                                                                .professionals
                                                        }
                                                        onChange={() =>
                                                            this.handleCheckboxChange(
                                                                'professionals'
                                                            )
                                                        }
                                                        labelText="Professionals"
                                                    />
                                                </div>
                                            </FormGroup>
                                        </div>
                                        <div
                                            className={`${styles.columnSmall} ${styles.checkbox}`}
                                        >
                                            <FormGroup
                                                className={styles.flexColumn}
                                            >
                                                <div>
                                                    <MaterialCheckbox
                                                        checked={
                                                            this.state
                                                                .tenancyRequirement &&
                                                            this.state
                                                                .tenancyRequirement
                                                                .postgraduates
                                                        }
                                                        onChange={() =>
                                                            this.handleCheckboxChange(
                                                                'postgraduates'
                                                            )
                                                        }
                                                        labelText="Postgraduates"
                                                    />
                                                </div>
                                            </FormGroup>
                                        </div>
                                        <div
                                            className={`${styles.columnSmall} ${styles.checkbox}`}
                                        >
                                            <FormGroup
                                                className={styles.flexColumn}
                                            >
                                                <div>
                                                    <MaterialCheckbox
                                                        checked={
                                                            this.state
                                                                .tenancyRequirement &&
                                                            this.state
                                                                .tenancyRequirement
                                                                .trainees
                                                        }
                                                        onChange={() =>
                                                            this.handleCheckboxChange(
                                                                'trainees'
                                                            )
                                                        }
                                                        labelText="Trainees"
                                                    />
                                                </div>
                                            </FormGroup>
                                        </div>
                                        <div
                                            className={`${styles.columnSmall} ${styles.checkbox}`}
                                        >
                                            <FormGroup
                                                className={styles.flexColumn}
                                            >
                                                <div>
                                                    <MaterialCheckbox
                                                        checked={
                                                            this.state
                                                                .tenancyRequirement &&
                                                            this.state
                                                                .tenancyRequirement
                                                                .student
                                                        }
                                                        onChange={() =>
                                                            this.handleCheckboxChange(
                                                                'student'
                                                            )
                                                        }
                                                        labelText="Student"
                                                    />
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.rowJustifyRight}>
                                <Buttons
                                    buttons={[
                                        {
                                            displayType: 'cancel',
                                            elementType: 'button',
                                            onClick: () =>
                                                this.props.history.push(
                                                    `/dashboard/property/${this.state.tenancyRequirement.propertyId}`
                                                ),
                                        },
                                        {
                                            displayType: 'submit',
                                            elementType: 'button',
                                            onClick: () => props.submitForm(),
                                        },
                                    ]}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}

export default withRouter(PropertyTenancyRequirements)
