import React, { useState, useEffect, useRef } from 'react'
import { Row, Col } from 'reactstrap'
import Modal from 'react-responsive-modal'
import RotatingLoader from '../../../atoms/RotatingLoader'
import Buttons from '../../../atoms/Buttons'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { processToast } from '../../../../helpers/toastHelper'
import { useDeleteTaskMutation } from '../../../../servicesRtk/TasksServiceRtk'
import styles from './DeleteTaskModal.module.scss'

interface IProps {
    ids?: Array<number>,
    open?: boolean,
    onClose(): void;
}

const DeleteTaskModal = ({ids, open, onClose}: IProps) => {
    const [deleteTask, {data, isLoading: deleting}] = useDeleteTaskMutation();

    const [deleteDocuments, setDeleteDocuments] = useState(false)

    const deleteButtonRef = useRef<any>(null);

    if(data){
        processToast(data);
        onClose();
    }

    if (!ids) return null

    return (
        <Modal
            classNames={{ modal: styles.modal }}
            open={open}
            onClose={() => onClose()}
            center
            focusTrapped={true}
            initialFocusRef={deleteButtonRef}
        >
            <div className={styles.body}>
                {deleting ? (
                    <div>
                        <RotatingLoader
                            loading={true}
                            text={`Deleting task...`}
                        />
                    </div>
                ) : (
                    <div>
                        <p className={styles.modelHeader}>DELETE TASK</p>

                        <p className={styles.modalBody}>
                            <div>You are about to delete:</div>
                            <div className={styles.records}>
                                {ids.length} Task
                                {ids.length == 1 ? '' : 's'}
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={deleteDocuments}
                                            color="primary"
                                            onChange={() => setDeleteDocuments(!deleteDocuments)}
                                        />
                                    }
                                    label="Delete associated documents?"
                                />
                            </div>

                            <div>Do you wish to go ahead and delete?</div>
                        </p>

                        <Row>
                            <Col className={styles.buttonDiv}>
                                <Buttons
                                    buttons={[
                                        {
                                            displayType: 'cancel',
                                            elementType: 'button',
                                            onClick: () => onClose(),
                                        },
                                        {
                                            text: 'Delete',
                                            displayType: 'submit',
                                            elementType: 'button',
                                            onClick: () => deleteTask({taskIds: ids, otherOptions: {deleteAssociatedDocument: deleteDocuments}}),
                                            ref: deleteButtonRef
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default DeleteTaskModal;
