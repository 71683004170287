import { useEffect, useState } from "react";
import { getTransactionsChartData } from "../services/TransactionService";
import { IListItemOptions, ITransactionListOptions } from "../types/ListFilters";

export const useFetchTransactionsChartData = (options: IListItemOptions, transactionOptions: ITransactionListOptions) => {
    const [dataRetrieved, setDataRetrieved] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        fetchData();
    }, [options, transactionOptions]);

    const fetchData = async() => {
        setDataRetrieved(false);

        let resp = await getTransactionsChartData(options, transactionOptions);
        if (resp && resp.status == 200 && resp.data) {
            setData(resp.data);
            setDataRetrieved(true);
        }
    }

    return { dataRetrieved, data };
}