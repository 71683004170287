import React, { Component } from 'react'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'
import { getRoles } from '../../../../services/RolesService'
import { IClientListItem, IUserListItem } from '../../../../types/ClientService'
import { IRole } from '../../../../types/RolesService'
import IconButton from '../../../atoms/Buttons/IconButton'
import ListItem from '../../../atoms/ListComponents/ListItem'
import RoleListItem from '../../../atoms/RoleListItem'
import SearchBar from '../../../atoms/SearchBar'
// Import Styles
import styles from './RolesPage.module.scss'

interface IState {
    roles: Array<IRole>
}

interface IProps {
    user: IUserListItem
    client: IClientListItem
}

class RolesPage extends Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            roles: [],
        }
    }

    componentDidMount(): void {
        getRoles().then((resp) => {
            this.setState({
                roles: resp.data,
            })
        })
    }

    render() {
        return (
            <div className={styles.generalPage}>
                <SearchBar text="Search for roles..." onSearch={() => null} />

                <Row>
                    <Col>
                        <IconButton
                            button={{
                                text: 'Add Role',
                                displayType: 'submit',
                                elementType: 'button',
                                icon: 'add',
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col className={styles.list}>
                        {this.state.roles.map((role, index) => {
                            return (
                                <ListItem
                                    client={this.props.client}
                                    user={this.props.user}
                                    key={index}
                                >
                                    <RoleListItem role={role} />
                                </ListItem>
                            )
                        })}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default RolesPage
