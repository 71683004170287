import { apiService } from './ApiService';
import * as domains from '../configuration/domains';
import { createBearerToken } from '../helpers/authHelpers';

export interface IInvitationRequest {
    contactId: string;
}

export interface IAcceptInvitationRequest {
    invitationId: string;
    contactId: string;
    accessCode: string;
    password: string;
}

export const getInvitation = (contactId: string): Promise<any> => {
    return apiService.get(`${domains.invitationService}/${contactId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
        .catch(error => {
            return error;
        });
}

export const acceptInvitation = (request: IAcceptInvitationRequest): Promise<any> => {
    return apiService.post(`${domains.invitationService}/accept`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
        .catch(error => {
            return error;
        });
}

export const sendInvitation = (request: IInvitationRequest): Promise<any> => {
    return apiService.post(`${domains.invitationService}`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
        .catch(error => {
            return error;
        });
}

export const deleteInvitation = (contactId: string): Promise<any> => {
    return apiService.delete(`${domains.invitationService}/${contactId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
        .catch(error => {
            return error;
        });
}