import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import TabbedNavigationContainer from "../../../../../atoms/TabbedNavigationContainer";
import NestedSimpleRouterTabs from "../../../../../atoms/NestedSimpleRouterTabs";
import Col from "reactstrap/lib/Col";
import { isMobile } from "react-device-detect";
import styles from "./UserGridTabs.module.scss";

interface IProps {
  history: any;
  location: any;
  match: any;
}

class UserGridTabs extends Component<IProps, {}> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div className={styles.tabNav}>
        <TabbedNavigationContainer fullWidth isSubMenu={true}>
          <Col className={styles.tabs}>
            <NestedSimpleRouterTabs
              routes={[
                {
                  path:
                    this.props.match.url.substring(
                      0,
                      this.props.match.url.lastIndexOf("/")
                    ) + "/users/all",
                  text: "All",
                  mobileText: "All",
                  tooltip:
                    isMobile ? null : "",
                },
                {
                  path:
                    this.props.match.url.substring(
                      0,
                      this.props.match.url.lastIndexOf("/")
                    ) + "/users/not-active",
                  text: "Not Active",
                  mobileText: "Not Active",
                  tooltip:
                    isMobile ? null : "",
                }
              ]}
            />
          </Col>
        </TabbedNavigationContainer>
      </div>
    );
  }
}

export default withRouter(UserGridTabs);