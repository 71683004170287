import styled from 'styled-components';

export const SearchBarDiv = styled.div`

`;

export const SelectDiv = styled.div<{searchbuttonVisible?: boolean; addbuttonVisible?: boolean}>`
    display: inline-block;
    width: ${p => p.searchbuttonVisible ? '85%' : p.addbuttonVisible ? '90%'  : '100%'};
    float: left;
    @media (max-width: 500px) {
        width: ${p => p.searchbuttonVisible ? '75%' : p.addbuttonVisible ? '90%' : '100%'};
    }
`;

export const SearchButtonDiv = styled.div<{buttonVisible?: boolean}>`
    display: ${p => p.buttonVisible ? 'inline-block' : 'none'};
    width: 15%;
    float: right;
    @media (max-width: 500px) {
        width: 25%;
    }
`;

export const AddButtonDiv = styled.div<{buttonVisible?: boolean}>`
    display: ${p => p.buttonVisible ? 'inline-block' : 'none'};
    width: 10%;
    float: right;
    @media (max-width: 500px) {
        width: 10%;
    }
`;

export const SearchButton = styled.button`
    width: 90%;
    height: 38px;
    padding-left: 5px;
    color: white;
    background-color: ${({ theme }) => theme.buttons.primary};
    border: 0px;
    &:hover{
        background-color: ${({ theme }) => theme.buttons.primaryHover};
    }
`;