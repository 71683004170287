import React, { Component } from 'react'
import styles from './IssueList.module.scss'
import GenericButton from '../../../atoms/GenericButton'
import tuneIcon from '../../../../images/icons/icon_filter.svg'
import { toggleIssueListCollapse, toggleIssuePopup } from '../../../../helpers/sidebarHelpers'
import IssueListItem from '../../../atoms/IssueListItem'
import { getIssues } from '../../../../services/IssueService'
import { IIssueItem } from '../../../../types/IssueService'
import RotatingLoader from '../../../atoms/RotatingLoader'
import { updateIssueList } from '../../../../helpers/issueHelpers'
import { connect } from 'react-redux'
import { getSearchIssues } from '../../../../services/SearchService'
import {
    IListItemOptions,
    IIssueListItemOptions,
} from '../../../../types/ListFilters'
import { withRouter } from 'react-router-dom'
import SelectDropdown, { IOptions } from '../../../atoms/SelectDropdown'
import { getTypesForDropdown } from '../../../../services/EntityService'
import {
    removeURLParameter,
    changeUrlPathParameter,
} from '../../../../helpers/locationHelpers'
import SearchIssuesInput from '../../../atoms/SearchIssuesInput'
import HorizontalEntitySelector from '../../../atoms/HorizontalEntitySelector'
import { IListResponse, IIssueListResponse } from '../../../../types/List'
import InfiniteScroll from 'react-infinite-scroll-component'
import { isLandlord, isPlatformAdmin } from '../../../../helpers/roleHelpers'
import addIcon from '../../../../images/icons/addbutton.svg'
import IconButton from '../../../atoms/Buttons/IconButton'
import * as S from './IssueList.styles'
const queryString = require('query-string')

interface IProps {
    issues: Array<IIssueItem>
    contactId?: string
    tenancyId?: string
    propertyId?: string
    unitId?: string
    inspectionId?: number
    assetId?: number

    history: any
    match: any
    location: any

    onAddIssue?(): void
    goToIssueDirect?: boolean
    chatAreaHeight: number;
}

interface IState {
    loading: boolean
    page: number
    size: number
    results: Array<IIssueItem>
    totalResults: number

    priorities: Array<IOptions>
    status: Array<IOptions>

    propertyId: string
    inspectionId: number
    selectedStatus: string
    selectedPriority: string

    isFiltering: boolean
    search: string
}

export interface ITenancyOptions {}

class IssueList extends Component<IProps, IState> {
    constructor(props: any) {
        super(props)
        this.state = {
            loading: false,
            page: 1,
            size: 8,
            results: [],
            priorities: [],
            status: [],
            propertyId: '',
            inspectionId: null,
            selectedStatus: '',
            selectedPriority: '',
            totalResults: 0,
            isFiltering: false,
            search: '',
        }

        this.searchIssues = this.searchIssues.bind(this)
        this.onAddIssueInternal = this.onAddIssueInternal.bind(this)
        this.getIssuesList = this.getIssuesList.bind(this)
        this.removeQuery = this.removeQuery.bind(this)
    }

    componentWillMount(): void {
        updateIssueList([])
    }

    removeQuery(): void {
        let newQuery = removeURLParameter(this.props.location.search, 'status')
        this.props.history.push({
            search: newQuery,
        })
    }

    getIssuesList(clearList?: boolean, selectFirstResult?: boolean): void {
        if (clearList) {
            updateIssueList([])
        }

        let request: IListItemOptions = {
            page: this.state.page,
            size: this.state.size,
        }

        let issueRequest: IIssueListItemOptions = {}

        if (this.state.search) {
            request.search = this.state.search
        }

        if (this.state.selectedPriority) {
            issueRequest.priority = this.state.selectedPriority
        }

        if (this.props.inspectionId) {
            request.inspectionId = this.props.inspectionId
        }

        if (this.props.contactId) {
            request.contactId = this.props.contactId
        }

        if (this.props.tenancyId) {
            request.tenancyId = this.props.tenancyId
        }

        if (this.state.selectedStatus) {
            request.status = this.state.selectedStatus
        }

        if (this.props.propertyId) {
            request.propertyId = this.props.propertyId
        }

        if (this.props.unitId) {
            request.unitId = this.props.unitId
        }

        if (this.props.assetId) {
            request.assetId = this.props.assetId
        }

        const { status } = queryString.parse(this.props.location.search)
        const { propertyId } = queryString.parse(this.props.location.search)
        if (status) {
            let statusIdNew = '77DD4304-B259-4D9C-8FD2-97D1489203D0'
            let statusIdInProgress = '8BD47439-A82A-4CD8-93C6-0468F229DBD9'

            if (status == 'New' || status == statusIdNew) {
                this.setState({
                    selectedStatus: statusIdNew,
                })
                request.status = statusIdNew
            }

            if (status == 'In Progress' || status == statusIdInProgress) {
                request.status = statusIdInProgress
                this.setState({
                    selectedStatus: statusIdInProgress,
                })
            }

            this.removeQuery()
        }

        if (propertyId) {
            request.propertyId = propertyId
        }

        this.setState({
            loading: true,
        })

        request.page = this.state.page
        request.size = this.state.size

        getIssues(request, issueRequest)
            .then((resp) => {
                if (resp && resp.status == 200 && resp.data) {
                    if(resp.data.totalCount == 0){
                        toggleIssueListCollapse(true);
                        this.setState({
                            loading: false,
                        })
                        return;
                    }

                    let results: IListResponse = resp.data
                    let data: IIssueListResponse = results.data
                    this.setState({
                        results: data.issues,
                        page: results.nextPage,
                        totalResults: results.totalCount,
                        status: data.statuses,
                    })
                    if (
                        (!this.props.match.params.issueId &&
                            data.issues.length > 0) ||
                        (selectFirstResult && data.issues.length > 0)
                    ) {
                        updateIssueList([])
                        let newPath = changeUrlPathParameter(
                            this.props.match,
                            data.issues[0].issueId
                        )
                        this.props.history.push(newPath)
                    }
                    if (clearList) {
                        updateIssueList(data.issues)
                    } else {
                        let newArr = this.props.issues.concat(data.issues)
                        updateIssueList(newArr)
                    }
                }
                this.setState({
                    loading: false,
                })
            })
            .catch((e) => {
                this.setState({
                    loading: false,
                })
            })
    }

    componentDidMount(): void {
        getTypesForDropdown('23CD92B3-D51A-4EF9-A6E5-FC76ECBA326F').then(
            (resp) => {
                if (resp && resp.status == 200 && resp.data) {
                    this.setState({
                        priorities: resp.data,
                    })
                }
            }
        )

        this.getIssuesList(true)
    }

    onAddIssueInternal(): void {
        if (this.props.onAddIssue) {
            this.props.onAddIssue()
        }

        toggleIssuePopup(true)
    }

    componentWillUnmount(): void {
        updateIssueList([])
    }

    async searchIssues(searchTerm: string): Promise<any> {
        return getSearchIssues(searchTerm)
    }

    render() {
        return (
            <S.IssueListDiv height={`${this.props.chatAreaHeight+330}px`} id="scroller">
                <div className={styles.heading}>
                    <div className={styles.filters1}>
                        <div className={styles.row}>
                            <div className={styles.search}>
                                <SearchIssuesInput
                                    onSearch={(search) => {
                                        this.setState(
                                            {
                                                search,
                                            },
                                            () => this.getIssuesList(true)
                                        )
                                    }}
                                />
                            </div>
                            <div className={styles.addButton}>
                                <IconButton
                                    iconOnly
                                    button={{
                                        displayType: 'submit',
                                        elementType: 'button',
                                        icon: 'add',
                                        onClick: () =>
                                            this.onAddIssueInternal(),
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {this.state.isFiltering && (
                        <div className={styles.row}>
                            <p>
                                <span className={styles.label}>Filter</span>
                            </p>
                            <div className={styles.filters}>
                                <div className={styles.dropdown}>
                                    <SelectDropdown
                                        showAllOptionText="All Statuses"
                                        selectedId={this.state.selectedStatus}
                                        showAllOption
                                        data={this.state.status}
                                        placeholder="Status..."
                                        onSelect={(id: IOptions) => {
                                            this.setState(
                                                {
                                                    selectedStatus: id.value,
                                                },
                                                () => this.getIssuesList(true)
                                            )
                                        }}
                                    />
                                </div>

                                {(isLandlord() || isPlatformAdmin()) && (
                                    <div className={styles.dropdown}>
                                        <SelectDropdown
                                            showAllOptionText="All Priorities"
                                            selectedId={
                                                this.state.selectedPriority
                                            }
                                            showAllOption
                                            data={this.state.priorities}
                                            placeholder="Priority..."
                                            onSelect={(id: IOptions) =>
                                                this.setState(
                                                    {
                                                        selectedPriority:
                                                            id.value,
                                                    },
                                                    () =>
                                                        this.getIssuesList(true)
                                                )
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    <div className={styles.row}>
                        <HorizontalEntitySelector
                            showCount
                            onSelect={(selected: IOptions) => {
                                this.setState(
                                    { page: 1, selectedStatus: selected.value },
                                    () => this.getIssuesList(true, true)
                                )
                            }}
                            selectedOption={this.state.selectedStatus}
                            showAllOption
                            options={this.state.status}
                        />
                    </div>
                </div>

                {this.props.issues.length > 0 && (
                    <div className={styles.issueItems}>
                        <InfiniteScroll
                            scrollableTarget={document.getElementById(
                                'scroller'
                            )}
                            className={styles.infiniteScroll}
                            dataLength={this.state.totalResults}
                            next={this.getIssuesList}
                            hasMore={
                                this.props.issues.length <=
                                this.state.totalResults
                            }
                            endMessage={<p>All issues have been loaded in.</p>}
                        >
                            <div>
                                {this.props.issues.map((i, index) => {
                                    return (
                                        <div key={index}>
                                            <IssueListItem
                                                goToIssueDirect={
                                                    this.props.goToIssueDirect
                                                }
                                                selected={
                                                    this.props.match.params
                                                        .issueId == i.issueId
                                                }
                                                issue={i}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </InfiniteScroll>
                    </div>
                )}

                {this.state.loading && (
                    <div>
                        <RotatingLoader
                            loading={true}
                            text="Loading Issues..."
                        />
                    </div>
                )}
            </S.IssueListDiv>
        )
    }
}

const mapStateToProps = (state: any) => ({
    issues: state.issue.issue_list,
    chatAreaHeight: state.app.chat_area_height
})

export default connect(mapStateToProps)(withRouter(IssueList))
