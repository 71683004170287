import { store } from "../store";

export function toggleAddPropertyFormChanged(changed: boolean): void {
  store.dispatch({
    type: "TOGGLE_ADD_PROPERTY_FORM_CHANGED",
    data: changed
  });
}

export function toggleAddTenancyFormChanged(changed: boolean): void {
  store.dispatch({
    type: "TOGGLE_ADD_TENANCY_FORM_CHANGED",
    data: changed
  });
}

export function toggleAddTransactionFormChanged(changed: boolean): void {
  store.dispatch({
    type: "TOGGLE_ADD_TRANSACTION_FORM_CHANGED",
    data: changed
  });
}

export function toggleAddContactFormChanged(changed: boolean): void {
  store.dispatch({
    type: "TOGGLE_ADD_CONTACT_FORM_CHANGED",
    data: changed
  });
}

export function toggleAddDocumentFormChanged(changed: boolean): void {
  store.dispatch({
    type: "TOGGLE_ADD_DOCUMENT_FORM_CHANGED",
    data: changed
  });
}

export function toggleAddIssueFormChanged(changed: boolean): void {
  store.dispatch({
    type: "TOGGLE_ADD_ISSUE_FORM_CHANGED",
    data: changed
  });
}