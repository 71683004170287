import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { goToRoute, goToPreviousRoute } from "../history";
import { ITaskDto, ITasksListItemOptions, ITasksDeleteOptions } from '../types/Tasks'
import * as domains from '../configuration/domains';
import { toQueryString, toQueryStringArray, queryHeaders } from '../helpers/serviceHelper'
import { processToast } from '../helpers/toastHelper';
import { canAccessPropertyDetails } from '../helpers/permissionHelpers';

export const tasksApi = createApi({
    reducerPath: 'tasksApi',
    baseQuery: fetchBaseQuery({
      baseUrl: domains.tasksService,
      prepareHeaders: queryHeaders,
    }),
    tagTypes: ['Tasks', 'TasksStats'],
    endpoints: (builder) => ({
        fetchTasks: builder.query({
            query: (options) => `/tasks?${toQueryString(options)}`,
            providesTags: ['Tasks']
        }),
        fetchTask: builder.query({
            query: (taskId) => `/${taskId}`,
            //transformResponse: (response : {data}) => { return response.data},
            providesTags: ['Tasks']
        }),
        addTask: builder.mutation({
            query: (task: ITaskDto) => (
                { 
                    url: ``, 
                    method: "POST",
                    body: task 
                }),
            onQueryStarted: (body, {dispatch, queryFulfilled}) => {
                queryFulfilled.then(result =>{
                        processToast(result.data);
                    }).catch(error => {
                    processToast(error.data);
                })
            },
            invalidatesTags: ["Tasks"],
          }),
          updateTask: builder.mutation({
            query: (task: ITaskDto) => (
                { 
                    url: ``, 
                    method: "PUT",
                    body: task 
                }),
            onQueryStarted: (body, {dispatch, queryFulfilled}) => {
                queryFulfilled.then(result => {
                    processToast(result.data);
                    if(body.systemType != null && (body.systemType >= 1 && body.systemType <= 19) && body.taskStatusId == '5'){
                        if(body.propertyId && canAccessPropertyDetails()){
                            let url = `/dashboard/property/${body.propertyId}/spec/regulation`;
                            let qs = `?highlightedDate=${body.systemType}`

                            goToRoute(url + qs);
                        }else{
                            goToRoute(`/dashboard/tasks`);
                        }
                    }else{
                        if(body.propertyId){
                            if(canAccessPropertyDetails()){
                                goToRoute(`/dashboard/property/${body.propertyId}/tasks`);
                            }else{
                                goToRoute(`/dashboard/tasks`);
                            }
                        }else{
                            goToRoute(`/dashboard/tasks`);
                        }
                    }
                }).catch(error => {
                    processToast(error);
                })
            },
            invalidatesTags: ["Tasks"],
          }),
        deleteTask: builder.mutation({
            query: (options: ITasksDeleteOptions) => (
                { 
                    url: `/ids/taskIds?${toQueryStringArray(options.taskIds)}&${toQueryString(options.otherOptions)}`, 
                    method: "DELETE" 
                }),
            invalidatesTags: ["Tasks"],
          }),
        fetchTaskTypes: builder.query({
            query: () => `/taskTypes`
        }),
        fetchTaskPriorities: builder.query({
            query: () => `/taskPriorities`
        }),
        fetchTaskStatuses: builder.query({
            query: () => `/taskStatuses`
        }),
        fetchTasksStats: builder.query({
            query: (propertyId?: string) => `/tasksStats?propertyId=${propertyId}`,
            providesTags: ['TasksStats']
        }),
    })
});
  
export const { 
    useFetchTasksQuery, 
    useFetchTaskQuery,
    useAddTaskMutation, 
    useUpdateTaskMutation, 
    useDeleteTaskMutation,
    useLazyFetchTasksQuery, 
    useFetchTaskTypesQuery,
    useFetchTaskPrioritiesQuery,
    useFetchTaskStatusesQuery,
    useFetchTasksStatsQuery, 
    } = tasksApi