import React, { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    addChatToChatList,
    checkForUnreadMessages,
    updateChatList,
} from '../../../helpers/chatHelpers'
import {
    changeChatSessionIdUrlPathParameter,
} from '../../../helpers/locationHelpers'
import { canStartChat } from '../../../helpers/permissionHelpers'
import {
    toggleChatListCollapse,
} from '../../../helpers/sidebarHelpers'
import {
    getMessagesList,
    IMessageListItem,
} from '../../../services/ChatService'
import { IListItemOptions } from '../../../types/ListFilters'
import IconButton from '../../atoms/Buttons/IconButton'
import RotatingLoader from '../../atoms/RotatingLoader'
import { IStartChatOptions } from '../../atoms/StartChatModal'
import ChatListItem from '../ChatListPage/ChatListItem'
import SearchUsersInput from '../../atoms/SearchUsersInput'
import {
    startDiscussion,
    IChatOptions,
} from '../../../services/ChatService'
import { ISearchResult } from '../../../types/SearchService/ISearchService'
import { updateToastMessage } from '../../../helpers/toastHelper'
import { isMobileOnly } from 'react-device-detect'
import styles from './ChatList.module.scss'
import * as S from './ChatList.styles'

interface IProps {
    history: any
    location: any
    match: any

    chat_list: Array<IMessageListItem>
    chat_list_collapsed: boolean

    options?: IListItemOptions
    onClickChatItem?(item: IMessageListItem): void

    classNames?: IClassNames
    chatAreaHeight: number;
}

interface IClassNames {
    input?: string
}

interface IState {
    selectedSessionId: string
    selectedSessionIndex: number
    loading: boolean
    sessions: Array<IMessageListItem>
    isAddingChat: boolean

    page: number
    totalResults: number
    resultAmount: number

    selectedUserId: string
    selectedUserName: string

    options: IStartChatOptions
}

class ChatList extends Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            selectedSessionId: '',
            selectedSessionIndex: null,
            loading: false,
            sessions: [],
            isAddingChat: false,
            page: 1,
            totalResults: 0,
            resultAmount: 20,
            selectedUserId: '',
            selectedUserName: '',
            options: null,
        }

        this.getMessages = this.getMessages.bind(this)
        this.onClickSearch = this.onClickSearch.bind(this)
        this.addActiveSessionToUrl = this.addActiveSessionToUrl.bind(this)
    }

    componentDidMount(): void {
        this.getMessages(true)
    }

    addActiveSessionToUrl(chatSessionId: string): void {
        if (!chatSessionId) {
            return
        }
        let path = changeChatSessionIdUrlPathParameter(
            this.props.match,
            chatSessionId
        )
        this.props.history.push(path)
    }

    getMessages(clearList?: boolean): void {
        this.setState({
            loading: true,
        })

        let options: IListItemOptions = {
            page: this.state.page,
            size: this.state.resultAmount,
        }

        if (this.props.options) {
            if (this.props.options.propertyId) {
                options.propertyId = this.props.options.propertyId
            }

            if (this.props.options.userId) {
                options.userId = this.props.options.userId
            }

            if (this.props.options.contactId) {
                options.contactId = this.props.options.contactId
            }
        }

        getMessagesList(options).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                if (resp.data) {
                    this.setState({
                        page: resp.data.nextPage,
                        totalResults: resp.data.totalCount,
                    })
                }
                if (resp.data.data.length === 0) {
                    this.setState({
                        loading: false,
                    })
                    return
                }
                updateChatList(this.state.sessions.concat(resp.data.data))
                let list: Array<any> = resp.data.data
                let selectedSessionId: string
                let selectedSessionIndex: number
                if (this.props.match.params.chatSessionId) {
                    selectedSessionId = this.props.match.params.chatSessionId
                    let index = list.findIndex(
                        (x) => x.chatSessionId == selectedSessionId
                    )
                    if (index > -1) {
                        selectedSessionIndex = index
                    }
                } else {
                    ;(selectedSessionId = list[0].chatSessionId),
                        (selectedSessionIndex = 0)
                }
                this.setState(
                    {
                        selectedSessionId: selectedSessionId,
                        selectedSessionIndex: selectedSessionIndex,
                        sessions: this.state.sessions.concat(resp.data.data),
                    },
                    () => {
                        if (!this.props.match.params.chatSessionId) {
                            this.addActiveSessionToUrl(
                                this.state.selectedSessionId
                            )
                        }
                    }
                )
            }
            this.setState({
                loading: false,
            })

            checkForUnreadMessages()
        })
    }

    onClickSearch(chatSessionId: string, selectedIndex: number): void {
        if (this.props.onClickChatItem) {
            this.props.onClickChatItem(this.props.chat_list[selectedIndex])
            return
        }

        this.setState({
            selectedSessionIndex: selectedIndex,
        })

        this.addActiveSessionToUrl(chatSessionId)
    }

    startChat(): void {
        this.setState({
            loading: true,
            selectedUserId: null,
            selectedUserName: null
        })
        let request: IChatOptions = {}
        if (this.state.selectedUserId) {
            request.userIds = [this.state.selectedUserId]
        }
        if (this.props.options) {
            request.propertyId = this.props.options.propertyId
        }
        startDiscussion(request).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                let newItem: IMessageListItem = resp.data.item

                let newChatMessage = `Chat started ${
                    resp.data.chatStartedWithName &&
                    'with ' + resp.data.chatStartedWithName
                }`

                updateToastMessage(newChatMessage, 'success')

                let newPath = changeChatSessionIdUrlPathParameter(this.props.match, newItem.chatSessionId)
                this.props.history.push(newPath)
                addChatToChatList(newItem)
                if (isMobileOnly) {
                    toggleChatListCollapse(true)
                }
            }
            this.setState({
                loading: false,
            })
        }).catch(e =>{
            let err = e;
        }
        )
    }

    render() {
        return (
            <div className={styles.page}>
                <S.ChatListDiv height={`${this.props.chatAreaHeight+100}px`} id="chatList">
                    <div className={styles.row}>
                        {canStartChat() && (
                            <div className={styles.searchInput} >
                                <SearchUsersInput
                                    onSelect={(selected: ISearchResult) =>
                                        this.setState({
                                            selectedUserId: selected.value,
                                            selectedUserName: selected.label,
                                        })
                                    }
                                    selectedId={this.state.selectedUserId}
                                    selectedName={this.state.selectedUserName}
                                    onClear={() =>
                                        this.setState(
                                            {
                                                selectedUserId: null,
                                                selectedUserName: null
                                            }
                                        )
                                    }
                                />
                            </div>
                        )}
                        {canStartChat() && (
                            <div className={styles.startChat}>
                                <IconButton
                                    iconOnly
                                    button={{
                                        //text: 'Start Chat',
                                        displayType: 'submit',
                                        elementType: 'button',
                                        icon: 'add',
                                        onClick: () => this.startChat(),
                                    }}
                                />
                            </div>
                        )}
                    </div>

                    {this.props.chat_list.length > 0 && (
                        <InfiniteScroll
                            scrollableTarget={document.getElementById(
                                'chatList'
                            )}
                            onScroll={() => {
                                console.log(document.getElementById('chatList'))
                            }}
                            className={styles.infiniteScroll}
                            dataLength={this.props.chat_list.length}
                            next={this.getMessages}
                            hasMore={
                                this.state.sessions.length <
                                this.state.totalResults
                            }
                        >
                            <ul className={styles.chatUl}>
                                {this.props.chat_list.map((s, index) => {
                                    return (
                                        <div key={index}>
                                            <div className={styles.mobile}>
                                                <ChatListItem
                                                    isActive={
                                                        this.props.match.params
                                                            .chatSessionId ==
                                                        s.chatSessionId
                                                    }
                                                    activeIndex={
                                                        this.state
                                                            .selectedSessionIndex
                                                    }
                                                    onClick={() => {
                                                        toggleChatListCollapse(
                                                            true
                                                        )
                                                        this.onClickSearch(
                                                            s.chatSessionId,
                                                            index
                                                        )
                                                    }}
                                                    item={s}
                                                />
                                            </div>

                                            <div className={styles.desktop}>
                                                <ChatListItem
                                                    onClick={() =>
                                                        this.onClickSearch(
                                                            s.chatSessionId,
                                                            index
                                                        )
                                                    }
                                                    isActive={
                                                        this.props.match.params
                                                            .chatSessionId ==
                                                        s.chatSessionId
                                                    }
                                                    activeIndex={
                                                        this.state
                                                            .selectedSessionIndex
                                                    }
                                                    item={s}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </ul>
                        </InfiniteScroll>
                    )}

                    {!this.state.loading &&
                        this.props.chat_list.length === 0 && (
                            <div className={styles.noChats}>
                                You have not started any chats
                            </div>
                        )}

                    {this.state.loading && (
                        <RotatingLoader
                            loading={true}
                            text="Loading chats..."
                        />
                    )}
                </S.ChatListDiv>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    chat_list: state.chat.chat_list,
    chat_list_collapsed: state.app.sidebars.chat_list_collapsed,
    chatAreaHeight: state.app.chat_area_height
})

export default connect(mapStateToProps)(withRouter(ChatList))
