import React, { Component } from "react";
import ErrorMessage from "../ErrorMessage";

const withErrorShield = (WrappedComponent) => {
  return class EH extends Component {
    state = {
      error: null
    }; 

    static getDerivedStateFromError(error) {
      return { error: true };
    }

    componentDidCatch(error, info) {
      // Display fallback UI
      this.setState({ error: true });
    }

    render() {
      let renderSection = this.state.error ? (
        <div><ErrorMessage text='A problem occured when trying to load your Entities. Please try again.'/></div>
      ) : (
        <WrappedComponent {...this.props} />
      );
      return renderSection;
    }
  };
};

export default withErrorShield;