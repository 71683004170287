import React from "react";
import CurrenyFormat from "react-currency-format";

interface IProps {
  value: number;
  prefix?: string;
  suffix?: string;
  format?: boolean;
  textOrInput?: string;
  decimalCount?: number;
}

class Currency extends React.Component<IProps, {}> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { decimalCount, format, prefix, suffix, textOrInput, value } = this.props;
    return (
      <span>
      <CurrenyFormat decimalScale={decimalCount && decimalCount > 0 ? decimalCount : 0} prefix={prefix ? prefix : null} value={value} thousandSeparator={format ? format : true} displayType={textOrInput ? textOrInput : 'text'}/>
      { suffix ? suffix : null }
      </span>
    );
  }
}

export default Currency;
