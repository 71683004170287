import { Grid } from '@syncfusion/ej2-react-grids'
import {
    ChangedEventArgs,
    TextBoxComponent,
} from '@syncfusion/ej2-react-inputs'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { isPlatformAdmin } from '../../../../../helpers/roleHelpers'
import {
    processToast,
    updateToastMessage,
} from '../../../../../helpers/toastHelper'
import { resendEmailConfirmation } from '../../../../../services/AccountService'
import IconButton from '../../../../atoms/Buttons/IconButton'
import DateRangePicker from '../../../../atoms/DateRangePicker'
import SFDropdown from '../../../../atoms/SFDropdown'
import { onDeleteRows } from '../_helpers'
import styles from './UserGridTopRow.module.scss'

interface IProps {
    history: any
    location: any
    match: any
    grid: Grid | null

    getTransactionsList(bool: boolean): void
    externalSearchHandler(string: string): void
    setType(string: string): void
    onDelete(): void
    onDeleteCallback?(): void
    onDateRangeChange?(startDate: Date, endDate: Date): void

    selectedRowsCount: number

    dateRangeStart?: Date
    dateRangeEnd?: Date
}

interface IState {
    selectedType: string
    startDate?: Date
    endDate?: Date
}

class UserGridTopRow extends Component<IProps, IState> {
    public datePickerRef

    constructor(props: any) {
        super(props)

        this.datePickerRef = React.createRef()

        this.state = {
            selectedType: '',
        }

        this.onAdd = this.onAdd.bind(this)
        this.contextMenuClickedPaid = this.contextMenuClickedPaid.bind(this)
        this.contextMenuClickedDelete = this.contextMenuClickedDelete.bind(this)
        this.dateRangeChange = this.dateRangeChange.bind(this)
        this.dateRangeCleared = this.dateRangeCleared.bind(this)
        this.focusDateRange = this.focusDateRange.bind(this)
        this.applyDateRange = this.applyDateRange.bind(this)
        this.exportSuccess = this.exportSuccess.bind(this)
        this.resendConfirmationEmail = this.resendConfirmationEmail.bind(this)
    }

    onAdd(isExpense: boolean, isIncome: boolean): void {
        //let options: IAddEditTransactionOptions = {};
        //if (this.props.addEditOptions) {
        //  options = this.props.addEditOptions;
        //}
        //options.isExpense = isExpense;
        //options.isIncome = isIncome;
        //let query = addURLParameter(
        //  this.props.location.search,
        //  "addEditTransactionOptions=" + JSON.stringify(options)
        //);
        //this.props.history.push({
        //  search: query,
        //});
        //toggleTransactionPopup(true);
    }

    contextMenuClickedPaid() {
        if (!this.props.grid) {
            return
        }

        let rows = this.props.grid.getSelectedRecords()

        if (rows.length == 0) {
            //updateToastMessage("No rows have been selected.", "warning");
            //return;
        }

        let transactionIds: string[] = []
        for (let i = 0; i < rows.length; i++) {
            transactionIds.push((rows[i] as any).transactionId)
        }
        //onClickPaidMultiple(transactionIds);
    }

    resendConfirmationEmail(): void {
        if (!this.props.grid) {
            return
        }

        let rows = this.props.grid.getSelectedRecords()

        if (rows.length == 0) {
            updateToastMessage('No rows have been selected.', 'warning')
            return
        }

        let userIds = []
        for (let i = 0; i < rows.length; i++) {
            userIds.push((rows[i] as any).userId)
        }

        resendEmailConfirmation(userIds).then((resp) => {
            if (resp && resp.status == 200) {
                processToast<string>(resp.data)
            }
        })
    }

    contextMenuClickedDelete() {
        if (!this.props.grid) {
            return
        }

        let rows = this.props.grid.getSelectedRecords()

        if (rows.length == 0) {
            updateToastMessage('No rows have been selected.', 'warning')
            return
        }

        let userIds = []
        for (let i = 0; i < rows.length; i++) {
            userIds.push((rows[i] as any).userId)
        }

        onDeleteRows(userIds, this.props.location, this.props.history)

        if (this.props.onDeleteCallback) {
            this.props.onDeleteCallback()
        }
    }

    dateRangeChange(startDate, endDate) {
        if (this.props.onDateRangeChange) {
            this.props.onDateRangeChange(startDate, endDate)
        }
    }

    applyDateRange(startDate: Date, endDate: Date) {
        //filterByDateTransactionItem(startDate, endDate);
        //this.setState({ startDate, endDate });
        this.changeDateRange(startDate, endDate)
    }

    dateRangeCleared() {
        //this.props.getTransactionsList(true);
        this.changeDateRange(null, null)
    }

    focusDateRange() {
        ;() => this.datePickerRef.current.focusDateRange
    }

    exportSuccess() {
        //updateToastMessage(
        //  `Transactions have been exported successfully.`,
        //  "success"
        //);
    }

    changeDateRange(start: Date, end: Date) {
        this.setState({ startDate: start, endDate: end })
        //let filters: IFilter = store.getState().filters.filters;
        //filters.financials.dateRangeStart = start;
        //filters.financials.dateRangeEnd = end;
        //updateFilters(filters);
    }

    render() {
        if (this.props.grid == null) {
            return <div></div>
        }

        return (
            <div className={`${styles.row} ${styles.marginTop}`}>
                <div className={styles.column}>
                    <div className={styles.dropdown}>
                        <div className={styles.searchInput}>
                            <TextBoxComponent
                                change={(args: ChangedEventArgs) =>
                                    this.props.externalSearchHandler(args.value)
                                }
                                placeholder="Search Users"
                            />
                        </div>
                        <div className={styles.dateRangePicker}>
                            <DateRangePicker
                                change={this.dateRangeChange.bind(this)}
                                cleared={this.dateRangeCleared.bind(this)}
                                focus={this.focusDateRange.bind(this)}
                                startDate={this.props.dateRangeStart}
                                endDate={this.props.dateRangeEnd}
                                ref={this.datePickerRef}
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.iconColumn}>
                    <SFDropdown
                        loadedBeforeRender
                        customCssClassName={styles.syncfusionDropdown}
                        customToggle={
                            <IconButton
                                button={{
                                    text: 'Actions',
                                    displayType: 'action',
                                    elementType: 'button',
                                    icon: 'action',
                                }}
                            />
                        }
                        items={[
                            {
                                iconCss: styles.subIconAdd,
                                text: 'Add User',
                            },
                            {
                                iconCss: styles.subIconActivate,
                                text: 'Resend Email Confirmation',
                            },
                            ...(isPlatformAdmin()
                                ? [
                                      {
                                          iconCss: styles.subIconActivate,
                                          text: 'Activate',
                                      },
                                  ]
                                : []),
                            {
                                iconCss: styles.subIconDelete,
                                text: 'Delete',
                            },
                            {
                                separator: true,
                            },
                            {
                                iconCss: styles.subIconExportExcel,
                                text: 'Export to Excel',
                            },
                            {
                                iconCss: styles.subIconExportCsv,
                                text: 'Export to CSV',
                            },
                            {
                                iconCss: styles.subIconExportPdf,
                                text: 'Export to PDF',
                            },
                        ]}
                        onSelect={(args) => {
                            if (args.item.text === 'Delete') {
                                this.contextMenuClickedDelete()
                            }
                            if (args.item.text === 'Export to PDF') {
                                this.props.grid.pdfExport()
                                this.exportSuccess()
                            }
                            if (args.item.text === 'Export to Excel') {
                                this.props.grid.excelExport()
                                this.exportSuccess()
                            }
                            if (args.item.text === 'Export to CSV') {
                                this.props.grid.csvExport()
                                this.exportSuccess()
                            }
                            if (args.item.text == 'Resend Email Confirmation') {
                                this.resendConfirmationEmail()
                            }
                        }}
                    />
                </div>
            </div>
        )
    }
}

export default withRouter(UserGridTopRow)
