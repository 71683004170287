import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from "react-router-dom";
import { Formik, FormikProps } from 'formik';
import { Loader } from '@rentchief/components';
import { IRegulation } from '../../../../reducers/propertyReducer';
import { getPropertyDetails } from '../../../../services/PropertyDetail'
import { IPropertyDetail } from '../../../../types/PropertyDetailService'
import { dateWithoutTime } from '../../../../helpers/dateHelpers';
import KeyDatesSummarySection from '../KeyDatesSummarySection/KeyDatesSummarySection.component';
import { IKeyDatesConfig } from '../_config/config';
import { hmoTooltip, epcTooltip, eicrTooltip, gasSafetyTooltip, selectiveLicenseTooltip, smokeDetectorsTooltip, carbonMonoxideTooltip, legionellaAssessmentTooltip } from '../_config/tooltipConfig';
import * as S from './KeyDatesSummary.styles'

interface props {
    suppliedPropertyId?: string;
}

const KeyDatesSummary = ({suppliedPropertyId}: props) => {
    // Redux 
    //const { propertyId } = useSelector((state: RootState) => state.property.property_data.property_data);

    const history = useHistory();

    const config = useRef<IKeyDatesConfig>(require("../_config/config.json"));
    const curPropertyId = useRef<string>(null);

    const [keyDatesData, setKeyDatesData] = useState<IRegulation>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        getDetails();
      }, [suppliedPropertyId]);

    const getDetails = async () => {
        curPropertyId.current = suppliedPropertyId;

        if (!curPropertyId) {
            return
        }

        setLoading(true);
        let resp = await getPropertyDetails(curPropertyId.current);
        if (resp && resp.data) {
            let data: IPropertyDetail = resp.data
            let response = {
                regulation: {
                    gasAppliances: data.gasAppliances,
                    epcCertificateRequired: data.epcCertificateRequired,
                    epcExpiryDate: data.epcExpiryDate && dateWithoutTime(data.epcExpiryDate),
                    gasExpiryDate: data.gasExpiryDate && dateWithoutTime(data.gasExpiryDate),
                    smokeDetectors: data.smokeDetectors,
                    smartMeters: data.smartMeters,
                    carbonMonoxide: data.carbonMonoxide,
                    hmoLicence: data.hmoLicence,
                    hmoLicenceExpires: data.hmoLicenceExpires && dateWithoutTime(data.hmoLicenceExpires),
                    legionellaAssessment: data.legionellaAssessment,
                    electricalInspectionRequired: data.electricalInspectionRequired,
                    electricalCheckInspection: data.electricalCheckInspection && dateWithoutTime(data.electricalCheckInspection),
                    landlordInsuranceExpiry: data.landlordInsuranceExpiry && dateWithoutTime(data.landlordInsuranceExpiry),
                    mortgageRateExpiry: data.mortgageRateExpiry && dateWithoutTime(data.mortgageRateExpiry),
                    selectiveLicenseExpiry: data.selectiveLicenseExpiry && dateWithoutTime(data.selectiveLicenseExpiry),
                    nextPropertyInspection: data.nextPropertyInspection && dateWithoutTime(data.nextPropertyInspection),
                    landlordInsuranceRequired: data.landlordInsuranceRequired,
                    propertyInspectioneRequired: data.propertyInspectioneRequired,
                    mortgageRateUpdateRequired: data.mortgageRateUpdateRequired,
                    selectiveLicenseRequired: data.selectiveLicenseRequired,
                    gasCertificateRequired: data.gasAppliances,
                    hmoLicenceRequired: data.hmoLicenceRequired,
                },
            }

            setKeyDatesData(response.regulation);

            setLoading(false);
        }
    }

    const saveChanges = async (changes) => {
        // Dummy save...no save functionality
    }

    const getInitialState = () => {
        let reData = null;
        if(keyDatesData != undefined || keyDatesData != null){
            reData = {
                gasSafetyCertificateDate: keyDatesData.gasExpiryDate,
                electricalSafetyCheckDate: keyDatesData.electricalCheckInspection,
                energyPerformanceCertificateDate: keyDatesData.epcExpiryDate,
                hmoLicenceDate: keyDatesData.hmoLicenceExpires,
                landlordInsuranceExpiry: keyDatesData.landlordInsuranceExpiry,
                mortgageRateExpiry: keyDatesData.mortgageRateExpiry,
                selectiveLicenseExpiry: keyDatesData.selectiveLicenseExpiry,
                nextPropertyInspection: keyDatesData.nextPropertyInspection,
                landlordInsuranceRequired: keyDatesData.landlordInsuranceRequired != null ? keyDatesData.landlordInsuranceRequired : config.current.landlordInsurancePolicyDate.defaultActive,
                propertyInspectioneRequired: keyDatesData.propertyInspectioneRequired != null ? keyDatesData.propertyInspectioneRequired : config.current.propertyInspectionDate.defaultActive,
                mortgageRateUpdateRequired: keyDatesData.mortgageRateUpdateRequired != null ? keyDatesData.mortgageRateUpdateRequired: config.current.mortgageRateExpiryDate.defaultActive,
                selectiveLicenseRequired: keyDatesData.selectiveLicenseRequired != null ? keyDatesData.selectiveLicenseRequired: config.current.selectiveLicenseDate.defaultActive,
                gasCertificateRequired: keyDatesData.gasCertificateRequired != null ? keyDatesData.gasCertificateRequired : config.current.gasSafetyCertificateDate.defaultActive,
                electricalInspectionRequired: keyDatesData.electricalInspectionRequired != null ? keyDatesData.electricalInspectionRequired : config.current.electricalSafetyCheckDate.defaultActive,
                epcCertificateRequired: keyDatesData.epcCertificateRequired != null ? keyDatesData.epcCertificateRequired : config.current.energyPerformanceCertificateDate.defaultActive,
                hmoLicenceRequired: keyDatesData.hmoLicenceRequired != null ? keyDatesData.hmoLicenceRequired : config.current.hmoLicenceDate.defaultActive,
                smokeDetectors: keyDatesData.smokeDetectors,
                carbonMonoxide: keyDatesData.carbonMonoxide,
                legionellaAssessment: keyDatesData.legionellaAssessment
            }
        }else{
            reData = {
                gasSafetyCertificateDate: null,
                electricalSafetyCheckDate: null,
                energyPerformanceCertificateDate: null,
                hmoLicenceDate: null,
                landlordInsuranceExpiry: null,
                mortgageRateExpiry: null,
                selectiveLicenseExpiry: null,
                nextPropertyInspection: null,
                landlordInsuranceRequired: config.current.landlordInsurancePolicyDate.defaultActive,
                propertyInspectioneRequired: config.current.propertyInspectionDate.defaultActive,
                mortgageRateUpdateRequired: config.current.mortgageRateExpiryDate.defaultActive,
                selectiveLicenseRequired: config.current.selectiveLicenseDate.defaultActive,
                gasCertificateRequired: config.current.gasSafetyCertificateDate.defaultActive,
                electricalInspectionRequired: config.current.electricalSafetyCheckDate.defaultActive,
                epcCertificateRequired: config.current.energyPerformanceCertificateDate.defaultActive,
                hmoLicenceRequired: config.current.hmoLicenceDate.defaultActive,
                smokeDetectors: false,
                carbonMonoxide: false,
                legionellaAssessment: false
            }
        } 
        return reData;
    }

    const onClick = (id?: string) =>{
        let url = `/dashboard/property/${curPropertyId.current}/spec/regulation`;
        if(id){
            url += `?highlightedDate=${id}`;
        }

        history.push(url);
    }

    if(loading){
        return <Loader />
    }

    return (
        <>
            <S.ContainerDiv>
                <Formik
                    initialValues={getInitialState()}
                    onSubmit={(v) => saveChanges(v) }
                    enableReinitialize={true}
                >
                    {(props: FormikProps<{
                        gasSafetyCertificateDate: Date;
                        electricalSafetyCheckDate: Date;
                        energyPerformanceCertificateDate: Date;
                        hmoLicenceDate: Date;
                        smokeDetectors: boolean;
                        carbonMonoxide: boolean;
                        legionellaAssessment: boolean;
                        landlordInsuranceExpiry: Date
                        mortgageRateExpiry: Date
                        selectiveLicenseExpiry: Date
                        nextPropertyInspection: Date
                        landlordInsuranceRequired?: boolean
                        propertyInspectioneRequired?: boolean
                        mortgageRateUpdateRequired?: boolean
                        selectiveLicenseRequired?: boolean
                        gasCertificateRequired?: boolean;
                        electricalInspectionRequired?: boolean;
                        epcCertificateRequired?: boolean;
                        hmoLicenceRequired?: boolean;
                    }>) => (
                        <>
                            {
                                props.values.gasCertificateRequired &&
                                <S.SectionDiv>
                                    <KeyDatesSummarySection 
                                        title={config.current.gasSafetyCertificateDate.shortTitle}
                                        expiryDate={props.values.gasSafetyCertificateDate}
                                        tooltip={gasSafetyTooltip}
                                        onClick={() => onClick(config.current.gasSafetyCertificateDate.id)}
                                    />
                                </S.SectionDiv>
                            }
                            {
                                props.values.electricalInspectionRequired && 
                                <S.SectionDiv>
                                    <KeyDatesSummarySection
                                        title={config.current.electricalSafetyCheckDate.shortTitle}
                                        expiryDate={props.values.electricalSafetyCheckDate}
                                        tooltip={eicrTooltip}
                                        onClick={() => onClick(config.current.electricalSafetyCheckDate.id)}
                                    />
                                </S.SectionDiv>
                            }
                            {
                                props.values.epcCertificateRequired &&
                                <S.SectionDiv>
                                    <KeyDatesSummarySection
                                        title={config.current.energyPerformanceCertificateDate.shortTitle}
                                        expiryDate={props.values.energyPerformanceCertificateDate}
                                        tooltip={epcTooltip}
                                        onClick={() => onClick(config.current.energyPerformanceCertificateDate.id)}
                                    />
                                </S.SectionDiv>
                            }   
                            {
                                props.values.hmoLicenceRequired && 
                                <S.SectionDiv>
                                    <KeyDatesSummarySection
                                        title={config.current.hmoLicenceDate.shortTitle}
                                        expiryDate={props.values.hmoLicenceDate}
                                        tooltip={hmoTooltip}
                                        onClick={() => onClick(config.current.hmoLicenceDate.id)}
                                    />
                                </S.SectionDiv>
                            }
                            {
                                props.values.selectiveLicenseRequired &&
                                <S.SectionDiv>
                                    <KeyDatesSummarySection
                                        title={config.current.selectiveLicenseDate.shortTitle}
                                        expiryDate={props.values.selectiveLicenseExpiry}
                                        tooltip={selectiveLicenseTooltip}
                                        onClick={() => onClick(config.current.selectiveLicenseDate.id)}
                                    />
                                </S.SectionDiv>
                            }
                            {
                                <S.SectionDiv>
                                    <KeyDatesSummarySection
                                        title={config.current.smokeDetectorsCheck.shortTitle}
                                        checkItem={true}
                                        checkCompleted={props.values.smokeDetectors}
                                        tooltip={smokeDetectorsTooltip}
                                        onClick={() => onClick()}
                                    />
                                </S.SectionDiv>
                            }
                            {
                                <S.SectionDiv>
                                    <KeyDatesSummarySection
                                        title={config.current.carbonMonoxideCheck.shortTitle}
                                        checkItem={true}
                                        checkCompleted={props.values.carbonMonoxide}
                                        tooltip={carbonMonoxideTooltip}
                                        onClick={() => onClick()}
                                    />
                                </S.SectionDiv>
                            }
                            {
                                <S.SectionDiv>
                                    <KeyDatesSummarySection
                                        title={config.current.legionellaAssessmentCheck.shortTitle}
                                        checkItem={true}
                                        checkCompleted={props.values.legionellaAssessment}
                                        tooltip={legionellaAssessmentTooltip}
                                        onClick={() => onClick()}
                                    />
                                </S.SectionDiv>
                            }
                        </>
                    )}
                </Formik>
            </S.ContainerDiv>
        </>
    )
}

export default KeyDatesSummary;