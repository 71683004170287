import React from "react";
import SFDropdown from "..";
import { withRouter } from "react-router";
import styles from "./SFTeamDropdown.module.scss";
import { goToRoute } from "../../../../history";
import { getUserId } from "../../../../helpers/authHelpers";
import { startDiscussion } from "../../../../services/ChatService";

interface IProps {
  cancelTeamMemberInvite(contactId: string): void;
  toggleTeamMemberAccess(userId: string, teamId: number, active: boolean): void;
  removeTeamMember(userIds: number[]): void;
  isInvited: boolean;
  isActive: boolean;
  IsInactive: boolean;

  userId?: string;
  userTeamId?: number;
  contactId?: string;
  teamId: number;

  toggle?: JSX.Element;
  history: any;
  location: any;
  match: any;

  updateRedirectUrl?: string;
}

class SFTeamDropdown extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  startChat = () => {
    startDiscussion({ contactId: this.props.contactId }).then(resp => {
      if (resp && resp.status == 200 && resp.data) {
        this.props.history.push(
          `/dashboard/contact/${this.props.contactId}/chats/${resp.data.item.chatSessionId}`
        );
      }
    });
  }

  render() {
    return (
      <SFDropdown
        loadedBeforeRender
        customToggle={this.props.toggle}
        customCssClassName={styles.syncfusionDropdown}
        onSelect={(args) => {
          if (args.item.text == "Edit") {
            if (this.props.contactId) {
              goToRoute(`/dashboard/contact/${this.props.contactId}?redirectUrl=${this.props.location.pathname}`);
              return;
            }
          }
          if (args.item.text == "Remove") {
            this.props.removeTeamMember([this.props.userTeamId])
          }
          if (args.item.text == "Cancel Invite") {
            this.props.cancelTeamMemberInvite(this.props.contactId)
          }
          if (args.item.text == "Disable Access") {
            this.props.toggleTeamMemberAccess(this.props.userId, this.props.teamId, false);
          }
          if (args.item.text == "Enable Access") {
            this.props.toggleTeamMemberAccess(this.props.userId, this.props.teamId, true);
          }
          if(args.item.text == "Start Chat"){
            this.startChat();
          }
        }}
        items={[
          ...(this.props.isInvited || this.props.isActive
            ? [
              {
                iconCss: styles.subIconProperty,
                text: "Edit",
              },
            ]
            : []),
          ...(this.props.isInvited
            ? [
              {
                iconCss: styles.subIconProperty,
                text: "Cancel Invite",
              },
            ]
            : []),
          ...(this.props.isActive && this.props.userId != getUserId()
            ? [
              {
                iconCss: styles.subIconProperty,
                text: "Disable Access",
              },
            ]
            : []),
          ...(this.props.IsInactive && this.props.userId != getUserId()
            ? [
              {
                iconCss: styles.subIconProperty,
                text: "Enable Access",
              },
            ]
            : []),
          ...(this.props.isActive && this.props.userId != getUserId()
            ? [
              {
                iconCss: styles.subIconProperty,
                text: "Start Chat"
              },
            ]
            : []),
          ...(this.props.userId != getUserId() && !this.props.isInvited
            ? [
              {
                iconCss: styles.subIconDelete,
                text: "Remove"
              },
            ]
            : []),
        ]}
      />
    );
  }
}

export default withRouter(SFTeamDropdown);