import React, { Component } from 'react'
import { Button, Row } from 'reactstrap'
import { Dispatch } from 'redux'
import { getUserInformation } from '../../../actions/userActions'

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
const queryString = require('query-string')

import styles from './AcceptInvitationForm.module.scss'

import { Formik, Field, Form, ErrorMessage } from 'formik'
import { acceptInvitation } from '../../../services/InvitationService'
import {
    AddTokenToLocalStorage,
    UpdateTokenInReduxStore,
} from '../../../helpers/tokenHelpers'
import { IClientData } from '../../../types/ClientService'
import RotatingLoader from '../../atoms/RotatingLoader'
import { AcceptInvitationSchema } from '../../../YupValidationSchemas'
import ClientRegistrationFooter from '../../atoms/ClientRegistrationFooter'

interface IProps {
    getUserInformation(): void
}

interface IState {
    invitationId: string
    contactId: string
    accessCode: string
    password: string
    error: string
    loading: boolean
}

interface IRouterProps {
    history: any
    location: any
    match: any
}

interface IRedux {
    client: IClientData
}

interface IAcceptInvitationResponse {
    isSuccess: boolean
    access_Token: string
    message: string
}

type Props = IRouterProps & IProps & IRedux

class AcceptInvitationForm extends Component<Props, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            invitationId: '',
            contactId: '',
            accessCode: '',
            password: '',
            error: '',
            loading: false,
        }

        this.onSubmit = this.onSubmit.bind(this)
    }

    componentWillMount(): void {
        let invitationId = queryString.parse(
            this.props.location.search
        ).invitationId
        let accessCode = queryString.parse(
            this.props.location.search
        ).accessCode
        let contactId = queryString.parse(this.props.location.search).contactId

        if (!invitationId || !accessCode || !contactId) {
            return null
        }

        this.setState({
            invitationId,
            accessCode,
            contactId,
        })
    }

    onSubmit(): void {
        this.setState({
            loading: true,
        })
        acceptInvitation({
            contactId: this.state.contactId,
            accessCode: this.state.accessCode,
            invitationId: this.state.invitationId,
            password: this.state.password,
        }).then((resp) => {
            if (resp && resp.status && resp.data) {
                let response: IAcceptInvitationResponse = resp.data

                if (response.isSuccess && response.access_Token) {
                    UpdateTokenInReduxStore(response.access_Token)
                    AddTokenToLocalStorage(response.access_Token, false)
                    this.props.getUserInformation()
                    this.setState({
                        loading: false,
                    })
                    return
                }

                this.setState({
                    error: response.message,
                    loading: false,
                })
            }
        })
    }

    render() {
        return (
            <div className={styles.page}>
                <div className={styles.form}>
                    <h1 className={styles.formHeading}>Accept your invite</h1>
                    <h2 className={styles.formSubHeading}>
                        Create your password and you are good to go
                    </h2>

                    {!this.state.contactId ||
                    !this.state.invitationId ||
                    !this.state.accessCode ? (
                        <p>Invalid invitation request.</p>
                    ) : this.state.error ? (
                        <div>
                            <p className={styles.errorMessage}>
                                {this.state.error}
                            </p>
                        </div>
                    ) : this.state.loading ? (
                        <RotatingLoader loading text="Accepting invite..." />
                    ) : (
                        <Formik
                            initialValues={{
                                password: '',
                            }}
                            validationSchema={AcceptInvitationSchema}
                            onSubmit={(values: any) => {
                                this.setState(
                                    {
                                        password: values.password,
                                    },
                                    () => this.onSubmit()
                                )
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <div className={styles.group}>
                                        <label className={styles.label}>
                                            Create Password
                                        </label>
                                        <input
                                            className={styles.input}
                                            value={props.values.email}
                                            placeholder="Create Password"
                                            type="password"
                                            onChange={(e) =>
                                                props.setFieldValue(
                                                    'password',
                                                    e.currentTarget.value
                                                )
                                            }
                                        />

                                        <div className={styles.errorMessage}>
                                            <ErrorMessage
                                                className={styles.errorMessage}
                                                name="password"
                                            />
                                        </div>
                                    </div>

                                    <p className={styles.tandcs}>
                                        By accepting this invite you agree to
                                        the terms &amp; conditions and privacy
                                        policy.
                                    </p>

                                    <p>
                                        <ClientRegistrationFooter />
                                    </p>

                                    <div className={styles.buttonContainer}>
                                        <Button
                                            type="submit"
                                            className={styles.button}
                                            disabled={false}
                                        >
                                            Accept Invite
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}

                    <Row
                        className={styles.text}
                        onClick={() =>
                            this.props.history.push('/account/login')
                        }
                    >
                        <p className={styles.alreadyHaveAnAccount}>
                            Already have an account?{' '}
                            <span className={styles.link}>Sign in</span>
                        </p>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    client: state.client,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getUserInformation: () => {
            dispatch<any>(getUserInformation())
        },
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AcceptInvitationForm)
)
