import { apiService } from './ApiService';
import * as domains from '../configuration/domains';
import { createBearerToken } from '../helpers/authHelpers';
import { IUserContactInfoRequest } from '../types/QuickView';
import { toQueryString } from './DocumentService';

export const getQuickViewValuation = (valuationId: string): Promise<any> => {
    return apiService.get(`${domains.quickViewService}/valuation/${valuationId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getQuickViewIssue = (issueId: string): Promise<any> => {
    return apiService.get(`${domains.quickViewService}/issue/${issueId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getQuickViewEvent = (eventId: string): Promise<any> => {
    return apiService.get(`${domains.quickViewService}/event/${eventId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getQuickViewProperty = (propertyId: string): Promise<any> => {
    return apiService.get(`${domains.quickViewService}/property/${propertyId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getQuickViewUser = (userId: string): Promise<any> => {
    return apiService.get(`${domains.quickViewService}/user/${userId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        }
    });
}

export const getQuickViewContact = (contactId: string): Promise<any> => {
    return apiService.get(`${domains.quickViewService}/contact/${contactId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getQuickViewProfile = (profileId: string): Promise<any> => {
    return apiService.get(`${domains.quickViewService}/profile/${profileId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getQuickViewDocument = (documentId: string): Promise<any> => {
    return apiService.get(`${domains.quickViewService}/document/${documentId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}

export const getUserContactInfo = (request: IUserContactInfoRequest): Promise<any> => {
    var stringed = toQueryString(request);

    return apiService.get(`${domains.quickViewService}/user-contact?${stringed}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    });
}