import React from "react";
import actionIcon from "../../../images/icons/action_icon.svg";
import styles from "./ActionButton.module.scss";

interface IProps {
  onClick?(): void;
}

class ActionButton extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div onClick={() => (this.props.onClick ? this.props.onClick() : {})}>
        <img src={actionIcon} className={styles.actionIcon} />
      </div>
    );
  }
}

export default ActionButton;
