import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from "@rentchief/components";
import { RootState } from '../../../../../reducers/rootReducer';
import { Button } from "@rentchief/components";
import ValuationTable from '../ValuationTable/ValuationTable.component';
import { getPriceValuationHistory, getRentValuationHistory, deleteValuationApi } from '../../../../../services/PropertyService'
import { updatePropertyCurrentValuation } from '../../../../../services/PropertyDetail'
import EstimatedValuationModal from '../../../../atoms/EstimatedValuationModal';
import { IGetPriceValuationResponse, IGetPriceValuationRequest } from '../../../../../types/PropertyService/IPropertyService'
import { IUpdatePropertyCurrentValuationRequest } from '../../../../../types/PropertyDetailService'
import { updateCurrentRentalValuation, updateCurrentValuation } from '../../../../../helpers/propertyHelpers'
import { useGetCalculationsByPropertyIdQuery } from '../../../../../servicesRtk/CalculatorServiceRtk';
import { updateToastMessage } from '../../../../../helpers/toastHelper'
import InitialStateSection from '../../Sections/InitialStateSection/InitialStateSection.component';
import ValuationEntryModal from '../ValuationEntryModal/ValuationEntryModal.component';
import SectionHeader from "../SectionHeader/SectionHeader.component"
import { ISection } from '../../PropertyDashboard/_config/dashboardConfig';
import * as S from "./ActiveValuationHistorySection.styles";

interface sectionProps {
    id: string;
    header: string;
    hideOnClick(id: string): void;
    config: ISection;
    isRental: boolean;
    estimatedValue?: IGetPriceValuationResponse;
}

const ActiveValuationHistorySection = ({id, header, hideOnClick, config, isRental, estimatedValue}: sectionProps) => {
    // Redux 
    const { propertyId, propertyName, address } = useSelector((state: RootState) => state.property.property_data.property_data);
    const { propertyType, currentValuation, currentRentalValuation } = useSelector((state: RootState) => state.property.property_data.property_data.propertySummary);
    const { data: calculatorData, isLoading, isFetching, isError } = useGetCalculationsByPropertyIdQuery(propertyId);

    const [valuationHistory, setValuationHistory] = useState([]);
    const [fecthingData, setFetchingData] = useState(false);
    const [showEstimatedValuationModal, setShowEstimatedValuationModal] = useState(false);
    const [showManualValuationModal, setShowManualValuationModal] = useState(false);

    useEffect(() => {
        fetchValuationHistory();
      }, []);

      useEffect(() => {
        // Use purchase cost if no valuation history exists
        if(valuationHistory.length == 0){
            if(calculatorData){
                updateCurrentValuation(calculatorData.propertyInformation.purchaseCost);
            }
        }
      }, [calculatorData]);

    useEffect(() => {
        // We might get an update from an external call  
        if(estimatedValue)
        {
           isRental ? onSuccess(null, estimatedValue) : onSuccess(estimatedValue, null)
        }
    }, [estimatedValue]);

    const fetchValuationHistory = async () => {
        setFetchingData(true);
        var resp = null;
        if(isRental){
            resp = await getRentValuationHistory({propertyId: propertyId, size: 5, page: 1});
        }else{
            resp = await getPriceValuationHistory({propertyId: propertyId, size: 5, page: 1});
        }
    
        if (resp) {
            setFetchingData(false);
            if(resp.status == 200 && resp.data){
                if(!isRental){
                    updateCurrentValuation(resp.data[0].price);
                }
                setValuationHistory(resp.data);
            }else{
                setValuationHistory([]);
                // Use purchase cost if no valuation history exists
                if(calculatorData){
                    updateCurrentValuation(calculatorData.propertyInformation.purchaseCost);
                }
                
            }
        }
    }

    const deleteValuation = async (valuationId: string) => {
        const resp = await deleteValuationApi(valuationId);
        if(resp){
            fetchValuationHistory();
            updateToastMessage(
                `Price valuation for ${propertyName} has been deleted`,
                'success'
            )
        }
    }

    const onUpdateValuation = async (value: number, date: Date) => {
        if(!value){
            return;
        }
        let request: IUpdatePropertyCurrentValuationRequest = {
            propertyId: propertyId,
            valuation: value,
            isRental: isRental,
            date: date
        }

        const resp = await updatePropertyCurrentValuation(request);
        if (resp.status == '200') {
            let type = isRental ? 'Rental' : 'Price';
            updateToastMessage(
                `${type} valuation for ${propertyName} has been updated`,
                'success'
            )
            fetchValuationHistory();
        }

        setShowManualValuationModal(false);
    }

    const onSuccess = (priceItemResp: IGetPriceValuationResponse, rentItemResp: IGetPriceValuationResponse) => {
        if(isRental){
            setValuationHistory([rentItemResp.valuationItem,].concat(valuationHistory));
        }else{
            setValuationHistory([priceItemResp.valuationItem,].concat(valuationHistory));
        }
        
        setShowEstimatedValuationModal(false);
        fetchValuationHistory();
        let type = isRental ? 'Rental' : 'Price';
        updateToastMessage(
            `${type} valuation for ${propertyName} has been added`,
            'success'
        )
    }

    return (
        <>
            {showManualValuationModal && (
                <ValuationEntryModal 
                    header={'Add Valuation'}
                    onClose={() => setShowManualValuationModal(false)}
                    open={showManualValuationModal}
                    currentValuation={isRental ? currentRentalValuation : currentValuation}
                    onUpdateValuation={onUpdateValuation}
                    valueInputHeader={isRental ? "Monthly Rent" : "Value"}
                />
            )}
            {showEstimatedValuationModal && (
                <EstimatedValuationModal
                    isResidential={propertyType == null || propertyType.toLocaleLowerCase() == 'residential'}
                    isRental={isRental}
                    onSuccess={(
                        priceItemResp: IGetPriceValuationResponse,
                        rentItemResp: IGetPriceValuationResponse,
                        request: IGetPriceValuationRequest
                    ) => {
                        onSuccess(priceItemResp, rentItemResp);
                    }}
                    postcode={address.postcode}
                    onClose={() => setShowEstimatedValuationModal(false)}
                    open={showEstimatedValuationModal}
                    propertyId={propertyId}
                    header="Get Valuation"
                />
            )}
            {
                fecthingData || isLoading || isFetching
                ?
                <Loader />
                :
                !valuationHistory || (valuationHistory && valuationHistory.length > 0)
                ?
                <div>
                    <SectionHeader id={id} header={header} hideOnClick={hideOnClick} priceValuation={() => setShowEstimatedValuationModal(true)} addOnClick={() => setShowManualValuationModal(true)} />
                    <S.SectionDiv onClick={(e: any) => e.stopPropagation()}>
                        <ValuationTable
                            title={'Price History'}
                            valuations={valuationHistory}
                            deleteData={deleteValuation}
                        />
                    </S.SectionDiv>
                </div>
                :
                <InitialStateSection 
                    id={id}
                    header={config && config.header}
                    iconUrl={config && config.initialState.iconUrl}
                    title={config && config.initialState.title}
                    summary={config && config.initialState.summary}
                    action={
                        <S.ActionDiv>
                            <S.ButtonWrapperDiv>
                                <Button onClick={() => setShowEstimatedValuationModal(true)}>Automatic</Button>
                            </S.ButtonWrapperDiv>
                            <S.ButtonWrapperDiv>
                                <Button onClick={() => setShowManualValuationModal(true)}>Manual</Button>
                            </S.ButtonWrapperDiv>
                        </S.ActionDiv>
                        }    
                    hideOnClick={hideOnClick}
                />
            }
        </>
    )
}

export default ActiveValuationHistorySection;