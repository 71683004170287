import { apiService } from './ApiService';
import * as domains from '../configuration/domains';
import { createBearerToken } from '../helpers/authHelpers';
import { IDocumentUploadRequest, IDocumentUpdateRequest, IUpdateDocumentsRequest } from '../types/DocumentService';
import { IListItemOptions, IDocumentListItemOptions } from '../types/ListFilters';
import { IAssociationRequest } from '../components/molecules/AddDocumentModal';

export function toQueryString(obj) {
    var parts = [];
    for (var i in obj) {
        if (obj.hasOwnProperty(i)) {
            parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]));
        }
    }
    return parts.join("&");
}

export const updateDocuments = (request: IUpdateDocumentsRequest): Promise<any> => {
    return apiService.post(`${domains.documentService}/Update`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
        .catch(error => {
            return error;
        });
}

export const getDocuments = (propertyId: string): Promise<any> => {
    return apiService.get(`${domains.documentService}/property/${propertyId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
        .catch(error => {
            return error;
        });
}

export const updateDocument = (documentId: string, request: IDocumentUpdateRequest): Promise<any> => {
    return apiService.put(`${domains.documentService}/${documentId}`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
        .catch(error => {
            return error;
        });
}

export const getEditInformation = (documentId: string): Promise<any> => {
    return apiService.get(`${domains.documentService}/edit/${documentId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
        .catch(error => {
            return error;
        });
}

export const searchForAssociationProperty = (query: string): Promise<any> => {
    return apiService.get(`${domains.documentService}/association/property?search=${query}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
        .catch(error => {
            return error;
        });
}

export const searchForAssociationContact = (query: string): Promise<any> => {
    return apiService.get(`${domains.documentService}/association/contact?search=${query}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
        .catch(error => {
            return error;
        });
}

export const searchForAssociationTenancy = (query: string): Promise<any> => {
    return apiService.get(`${domains.documentService}/association/tenancy?search=${query}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
        .catch(error => {
            return error;
        });
}

const toQueryStringArray = (arr: string[]): string => {
    if (!arr || arr.length === 0) {
        return "";
    }

    let query = "";

    arr.map(a => {
        query += "&fileTypes=" + a
    });

    return query;
}

const toDocumentIdQueryStringArray = (arr: string[]): string => {
    if (!arr || arr.length === 0) {
        return "";
    }

    let query = "?";

    arr.map((a, index) => {
        if (index == arr.length - 1) {
            query += "documentIds=" + a
        } else {
            query += "documentIds=" + a + "&"
        }
    });

    return query;
}

export interface IAssociatedDocumentsRequest {
    propertyId?: string;
    contactId?: string;
    tenancyId?: string;
    transactionId?: string;
    documentType?: string;
    unitId?: string;
    inspectionId?: number;
    assetId?: number;
    taskId?: number;
}

export const getAssociatedDocuments = (request: IAssociatedDocumentsRequest, fileTypes: string[], overrides?: any[]): Promise<any> => {
    var stringed = toQueryString(request);
    var fileTypesStringed = toQueryStringArray(fileTypes);

    var ids = '';

    if (overrides) {
        ids = `&documentIds=${overrides.map(d => d.documentId).join(',')}`
    }

    return apiService.get(`${domains.documentService}/association?${stringed}${fileTypesStringed}${ids}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
        .catch(error => {
            return error;
        });
}

export const getAssociationItem = (request: IAssociationRequest): Promise<any> => {
    var stringed = toQueryString(request);

    return apiService.get(`${domains.documentService}/association-item?${stringed}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
        .catch(error => {
            return error;
        });
}

export const getDocumentList = (options: IListItemOptions, documentOptions: IDocumentListItemOptions): Promise<any> => {
    var stringed = toQueryString(options);
    var documentStringed = toQueryString(documentOptions);

    return apiService.get(`${domains.documentService}/list?${stringed}&${documentStringed}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
        .catch(error => {
            return error;
        });
}

export const getDocument = (documentId: string): Promise<any> => {
    return apiService.get(`${domains.documentService}/document/${documentId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
        .catch(error => {
            return error;
        });
}

export const uploadDocument = (request: IDocumentUploadRequest): Promise<any> => {
    return apiService.post(`${domains.documentService}/`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
        .catch(error => {
            return error;
        });
}

export const deleteDocuments = (documentIds: string[]): Promise<any> => {
    var documentsStringed = toDocumentIdQueryStringArray(documentIds);
    return apiService
        .delete(
            `${domains.documentService}/ids${documentsStringed}`,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    Authorization: createBearerToken(),
                },
            }
        )
        .catch((error) => {
            return error.response.data;
        });
};

export const deleteDocument = (documentId: string): Promise<any> => {
    return apiService.delete(`${domains.documentService}/${documentId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
        .catch(error => {
            return error;
        })
}