import styled from 'styled-components';

export const CounterDiv = styled.div`
    line-height: 1;
`; 

export const NumberLabel = styled.label<{ fontSize?: string, color?: string }>`
    display: block;
    width: 100%;
    text-align: center;
    font-size: ${p => p.fontSize ? p.fontSize : '40px'};
    color: ${p => p.color ? p.color : '#104351'};
    &:hover {
        background-color: #F5F5F5;
        cursor: pointer;
      }
`; 

export const NameLabel = styled.label<{ fontSize?: string, color?: string }>`
    display: block;
    width: 100%;
    text-align: center;
    font-size: font-size: ${p => p.fontSize ? p.fontSize : '14px'};
    color: ${p => p.color ? p.color : '#104351'};
`;

export const AddButtonDiv = styled.div`
    display: block; 
    width: 50%; 
    margin: auto;
    text-align: center;
    button {
        height: 30px;
    }
`;