import styled from 'styled-components';

export const ChatListDiv = styled.div<{height?: string}>`
    padding-top: 0;
    flex: 0 40%;
    border-right: 1px solid #f0f2f6;
    //height: calc(100vh - 77.83px);
    height: ${p => p.height};
    overflow: auto;

    @include tablet {
        padding: 0;
    }

    .selectedItem {
        background-color: blue;
    }
`