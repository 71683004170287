import { store } from '../store';

export function updateAlertMessage(alert_text: string, is_error: boolean, hide_after_milliseconds?: number, auto_hide?: boolean) {
    store.dispatch({
        type: 'UPDATE_ALERT',
        alert_text,
        is_error,
        auto_hide,
        hide_after_milliseconds
    });
}