// Import React
import React, { Component } from "react";
import withErrorShield from "../../atoms/withErrorShield";
import { Redirect, withRouter } from "react-router-dom";

import styles from "./DashboardHome.module.scss";
import { isLandlord, isPlatformAdmin, isTenant, isTradesPerson, isClientAdmin } from "../../../helpers/roleHelpers";
import LandlordDashboardV2 from './LandlordDashboardV2/LandlordDashboardV2.component'
import TenantDashboardV2 from "./TenantDashboardV2";
import TradeDashboard from "./TradeDashboard";

interface IState {
}

interface IRouterProps {
  history: any;
  location: any;
  match: any;
}

class DashboardHome extends Component<IRouterProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
    };
  }

  render() {
    if (isLandlord() || isPlatformAdmin() || isClientAdmin()) {
      return <LandlordDashboardV2 />
    }

    if (isTenant()) {
      return <TenantDashboardV2 />
    }

    if (isTradesPerson()) {
      return <TradeDashboard />
    }

    return <div>Dashboard is not available. You do not have the required permissions to access this resource or your session may have expired, please re-login</div>
  }
}

export default withErrorShield(withRouter(DashboardHome)) as any;