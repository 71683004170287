import React, { Component } from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'
import { hasClientArea } from '../../../../helpers/clientHelpers'
import { addURLParameter } from '../../../../helpers/locationHelpers'
import { toggleAddDocumentPopup } from '../../../../helpers/sidebarHelpers'
import { updateViewingUnit } from '../../../../helpers/unitHelpers'
import assetIcon from '../../../../images/icons/asset_icon.svg'
import documents from '../../../../images/icons/documents.png'
import auditIcon from '../../../../images/icons/icon_timeline.svg'
import menuIssues from '../../../../images/icons/menuicon_issues.svg'
import actionIcon from '../../../../images/icons/property-action/Action.png'
import menuReturns from '../../../../images/icons/returns.png'
import menuTenancy from '../../../../images/icons/Tenancy1.svg'
import detailsIcon from '../../../../images/icons/user-management/usermanagement_details.svg'
import { getUnitByUnitId } from '../../../../services/UnitService'
import { IUnitItem } from '../../../../types/UnitService'
import { IRaiseIssueOptions } from '../../../atoms/AddEditIssue'
import Breadcrumbs from '../../../atoms/Breadcrumbs'
import IconButton from '../../../atoms/Buttons/IconButton'
import ClientAreaRestriction from '../../../atoms/ClientAreaRestriction'
import GenericButton from '../../../atoms/GenericButton'
import RotatingLoader from '../../../atoms/RotatingLoader'
import SFUnitDropdown from '../../../atoms/SFDropdown/SFUnitDropdown'
import SimpleRouterTabs from '../../../atoms/SimpleRouterTabs'
import TabbedNavigationContainer from '../../../atoms/TabbedNavigationContainer'
import { IDocumentUploadOptions } from '../../../molecules/AddDocumentModal'
import AddEditUnit from '../../AddEditUnit'
import AssetsGrid from '../../AssetsPage/AssetsGrid'
import DocumentsPage from '../../DocumentsPage'
import TransactionsGridContainer from '../../FinancialsPage/TransactionsGridContainer/TransactionsGridContainer.component'
import IssueDetail from '../../IssueDetail'
import IssueListPage from '../../IssueListPage'
import AuditPage from '../../PlatformManagementPage/AuditPage'
import TenancyGrid from '../../TenancyPage/TenancyGrid'
import styles from './UnitDetailPage.module.scss'

interface IProps {
    history: any
    match: any
    location: any

    onUpdateReturnUrl?: string
}

interface IState {
    unit: IUnitItem
    loading: boolean
}

class UnitDetailPage extends Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            unit: null,
            loading: false,
        }

        this.getUnit = this.getUnit.bind(this)
        this.onUpdate = this.onUpdate.bind(this)
        this.onAddDocumentClick = this.onAddDocumentClick.bind(this)
        this.onAddButtonClick = this.onAddButtonClick.bind(this)
    }

    getUnit(): void {
        this.setState({
            loading: true,
        })

        getUnitByUnitId(this.props.match.params.unitId).then((resp) => {
            if (resp && resp.status == 200) {
                this.setState({
                    unit: resp.data,
                })

                updateViewingUnit(resp.data)
            }
            this.setState({
                loading: false,
            })
        })
    }

    componentDidMount(): void {
        this.getUnit()
    }

    componentWillUnmount(): void {
        updateViewingUnit(null)
    }

    onUpdate(unit: IUnitItem): void {
        this.setState({
            unit,
        })

        this.props.history.push(`/dashboard/units`)
    }

    onAddDocumentClick(): void {
        let options: IDocumentUploadOptions = {
            unitId: this.props.match.params.unitId,
            unitName: this.state.unit.unitName,
            propertyId: this.state.unit.propertyId,
        }

        let query = 'uploadDocumentOptions=' + JSON.stringify(options)

        let search = addURLParameter(this.props.location.search, query)

        this.props.history.push({
            search,
        })

        toggleAddDocumentPopup(true)
    }

    onAddButtonClick(): void {
        let options: IRaiseIssueOptions = {
            propertyId: this.state.unit.propertyId,
            propertyName: this.state.unit.propertyName,
            unitId: this.props.match.params.unitId,
            unitName: this.state.unit.unitName,
            redirectUrl: `/dashboard/unit/view/${this.props.match.params.unitId}/issues`,
        }

        let query = 'issueOptions=' + JSON.stringify(options)
        let queryParam = addURLParameter(this.props.location.search, query)

        this.props.history.push({
            search: queryParam,
        })
    }

    render() {
        if (this.state.loading || !this.state.unit) {
            return <RotatingLoader loading={true} text="Loading Unit..." />
        }

        return (
            <Row className={styles.container}>
                <div className={styles.headerBar}>
                    <div className={styles.breadcrumbs}>
                        <Breadcrumbs
                            breadcrumbs={[
                                {
                                    name: this.state.unit.propertyName,
                                    route: `/dashboard/property/${this.state.unit.propertyId}`,
                                    ordinal: 0,
                                },
                                {
                                    name: 'Units',
                                    route: `/dashboard/property/${this.state.unit.propertyId}/spec/units`,
                                    ordinal: 1,
                                },
                                {
                                    name: this.state.unit.unitName,
                                    route: `/dashboard/unit/view/${this.state.unit.unitId}`,
                                    ordinal: 2,
                                },
                            ]}
                        />
                    </div>
                    <div className={styles.actionMenu}>
                        <SFUnitDropdown
                            toggle={
                                <IconButton
                                    button={{
                                        displayType: 'action',
                                        elementType: 'button',
                                        icon: 'action',
                                    }}
                                />
                            }
                            unitId={this.props.match.params.unitId}
                            unitName={this.state.unit.unitName}
                            propertyId={this.state.unit.propertyId}
                            propertyName={this.state.unit.propertyName}
                            updateRedirectUrl={`/dashboard/property/${this.state.unit.propertyId}/spec/units`}
                        />
                    </div>
                </div>

                <div className={styles.navigation}>
                    <TabbedNavigationContainer fullWidth>
                        <Col className={styles.tabs}>
                            <SimpleRouterTabs
                                routes={[
                                    {
                                        path: this.props.match.url + '/summary',
                                        text: 'Unit',
                                        mobileText: 'Unit',
                                        icon: detailsIcon,
                                    },
                                    {
                                        path:
                                            this.props.match.url +
                                            '/financials',
                                        text: 'Money',
                                        mobileText: 'Money',
                                        icon: menuReturns,
                                    },
                                    {
                                        path: this.props.match.url + '/issues',
                                        text: 'Issues',
                                        mobileText: 'Issues',
                                        icon: menuIssues,
                                    },
                                    ...(hasClientArea('AssetsFunctions')
                                        ? [
                                              {
                                                  path:
                                                      this.props.match.url +
                                                      '/assets',
                                                  text: 'Assets',
                                                  mobileText: 'Assets',
                                                  icon: assetIcon,
                                              },
                                          ]
                                        : []),
                                    {
                                        path: this.props.match.url + '/tenancy',
                                        text: 'Tenancy',
                                        mobileText: 'Tenancy',
                                        icon: menuTenancy,
                                    },
                                    {
                                        path:
                                            this.props.match.url + '/documents',
                                        text: 'Docs',
                                        mobileText: 'Docs',
                                        icon: documents,
                                    },
                                    {
                                        path:
                                            this.props.match.url + '/timeline',
                                        text: 'Timeline',
                                        mobileText: 'Timeline',
                                        icon: auditIcon,
                                    },
                                ]}
                            />
                        </Col>
                    </TabbedNavigationContainer>
                </div>

                <Col className={styles.routedPage}>
                    <div className={styles.content}>
                        <Switch>
                            <Route
                                path={`${this.props.match.url}`}
                                exact
                                component={({ location }) => (
                                    <Redirect
                                        to={{
                                            ...location,
                                            pathname: `${this.props.match.url}/summary`,
                                        }}
                                    />
                                )}
                            />

                            <Route
                                path={`${this.props.match.path}/summary`}
                                exact
                                render={() => (
                                    <AddEditUnit
                                        onUpdateCallback={this.onUpdate}
                                    />
                                )}
                            />

                            <Route
                                path={`${this.props.match.path}/documents`}
                                exact
                                render={() => (
                                    <div className={styles.whiteBg}>
                                        <DocumentsPage
                                            onAddDocumentClick={
                                                this.onAddDocumentClick
                                            }
                                            unitId={
                                                this.props.match.params.unitId
                                            }
                                        />
                                    </div>
                                )}
                            />

                            <Route
                                path={`${this.props.match.path}/tenancy`}
                                exact
                                render={() => (
                                    <div className={styles.whiteBg}>
                                        <TenancyGrid
                                            config={{
                                                addSettings: {
                                                    propertyId:
                                                        this.state.unit &&
                                                        this.state.unit
                                                            .propertyId,
                                                    propertyName:
                                                        this.state.unit &&
                                                        this.state.unit
                                                            .propertyName,
                                                    unitId: this.props.match
                                                        .params.unitId,
                                                    unitName:
                                                        this.state.unit &&
                                                        this.state.unit
                                                            .unitName,
                                                },
                                                dataFilterSettings: {
                                                    unitId: this.props.match
                                                        .params.unitId,
                                                },
                                            }}
                                        />
                                    </div>
                                )}
                            />

                            <Route
                                path={`${this.props.match.path}/financials`}
                                render={() => (
                                    <TransactionsGridContainer context='unit' />
                                )}
                            />

                            <Route
                                path={`${this.props.match.path}/issue/:issueId?`}
                                render={(props) => (
                                    <div className={styles.whiteBg}>
                                        <IssueDetail />
                                    </div>
                                )}
                            />

                            <Route
                                path={`${this.props.match.path}/issues/:issueId?`}
                                render={() => (
                                    <div className={styles.whiteBg}>
                                        <IssueListPage
                                            onAddIssue={this.onAddButtonClick}
                                            unitId={
                                                this.props.match.params.unitId
                                            }
                                            isNested={true}
                                        />
                                    </div>
                                )}
                            />

                            <Route
                                path={`${this.props.match.path}/timeline`}
                                exact
                                render={() => (
                                    <div className={styles.whiteBg}>
                                        <AuditPage
                                            unitId={
                                                this.props.match.params.unitId
                                            }
                                            hideDropdown
                                        />
                                    </div>
                                )}
                            />

                            <ClientAreaRestriction restriction="AssetsFunctions">
                                <Route
                                    path={`${this.props.match.path}/assets`}
                                    exact
                                    render={() => (
                                        <div
                                            className={`${styles.whiteBg} ${styles.page}`}
                                        >
                                            <AssetsGrid
                                                config={{
                                                    filterSettings: {
                                                        unitId: this.props.match
                                                            .params.unitId,
                                                    },
                                                    addSettings: {
                                                        propertyId:
                                                            this.state.unit
                                                                .propertyId,
                                                        propertyName:
                                                            this.state.unit
                                                                .propertyName,
                                                        unitId: this.props.match
                                                            .params.unitId,
                                                        unitName:
                                                            this.state.unit
                                                                .unitName,
                                                        referer: 'unit',
                                                    },
                                                }}
                                            />
                                        </div>
                                    )}
                                />
                            </ClientAreaRestriction>
                        </Switch>
                    </div>
                </Col>
            </Row>
        )
    }
}

export default withRouter(UnitDetailPage)
