import * as domains from '../configuration/domains'
import { createBearerToken } from '../helpers/authHelpers'
import { apiService } from '../services/ApiService'
import {
    IChangePrimaryImageRequest,
    IGetImagesRequest,
    IUploadRequest,
} from '../types/ImageService'
import { toQueryString } from './DocumentService'

const toImageIdQueryStringArray = (arr: string[]): string => {
    if (!arr || arr.length === 0) {
        return ''
    }

    let query = '?'

    arr.map((a, index) => {
        if (index == arr.length - 1) {
            query += 'imageId=' + a
        } else {
            query += 'imageId=' + a + '&'
        }
    })

    return query
}

export const uploadImagesApi = (
    request: FormData,
    query?: IUploadRequest
): Promise<any> => { 
    var q = toQueryString(query)

    return apiService
        .post(`${domains.imageService}/files?${q}`, request, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'multipart/form-data',
                Authorization: createBearerToken(),
            },
        })
        .catch((error) => {
            return error.response.data
        })
}

export const changePrimaryImage = (
    request: IChangePrimaryImageRequest
): Promise<any> => {
    var query = toQueryString(request)

    return apiService
        .post(`${domains.imageService}/Update/Primary`, request, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: createBearerToken(),
            },
        })
        .catch((error) => {
            return error.response.data
        })
}

export const getImages = (request: IGetImagesRequest): Promise<any> => {
    var query = toQueryString(request)

    return apiService
        .get(`${domains.imageService}?${query}`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: createBearerToken(),
            },
        })
        .catch((error) => {
            return error.response.data
        })
}

export const getImagesFromImageIds = (request: string[]): Promise<any> => {
    var query = toImageIdQueryStringArray(request)

    return apiService
        .get(`${domains.imageService}/ids/${query}`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: createBearerToken(),
            },
        })
        .catch((error) => {
            return error.response.data
        })
}

export const getPrimaryPropertyImage = (propertyId: string): Promise<any> => {
    return apiService
        .get(`${domains.assetService}/images/primary/${propertyId}`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: createBearerToken(),
            },
        })
        .catch((error) => {
            return error.response.data
        })
}

export const setPrimaryPropertyImage = (
    propertyId: string,
    request: any
): Promise<any> => {
    return apiService
        .post(
            `${domains.assetService}/images/primary/upload/${propertyId}`,
            request,
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: createBearerToken(),
                },
            }
        )
        .catch((error) => {
            return error.response.data
        })
}

export const deleteImage = (imageId: string): Promise<any> => {
    return apiService
        .delete(`${domains.imageService}/delete/${imageId}`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: createBearerToken(),
            },
        })
        .catch((error) => {
            return error.response.data
        })
}
