import React from "react";
import { ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-grids";
import { minWidthTablet } from "../../../../../global/variables/_variables";
import AssociatedDocuments from "../../../../atoms/AssociatedDocuments";
import { Router } from "react-router-dom";
import history, { goToRoute } from "../../../../../history";
import GenericPopup from "../../../../atoms/GenericPopup";
import { generateId } from "../../../../../helpers/randomHelpers";
import { QuickViewType } from "../../../../../types/QuickView";
import noImage from "../../../../../images/noImage.svg";
import styles from '../InspectionsGrid.module.scss';

function image(props) {
  return (<img onClick={() => goToRoute(`/dashboard/inspection/view/${props.inspectionId}`)} className={styles.image} src={props.imageUrl ? props.imageUrl : noImage} />)
}

function type(props) {
  return (<div onClick={() => goToRoute(`/dashboard/inspection/view/${props.inspectionId}`)}>{props.inspectionType}</div>)
}

function name(props) {
  return (<div onClick={() => goToRoute(`/dashboard/inspection/view/${props.inspectionId}`)}>{props.inspectionName}</div>)
}

function status(props) {
  return (<div onClick={() => goToRoute(`/dashboard/inspection/view/${props.inspectionId}`)}>{props.status}</div>)
}

function documents(props) {
  return (<Router history={history}><AssociatedDocuments
    key={props.unitId}
    showOnlyNoneText
    options={{
      inspectionId: props.inspectionId
    }}
    viewAll={false}
  /></Router>)
}

function property(props) {
  return (<GenericPopup
    identifier={generateId(10)}
    options={{
      propertyId: props.propertyId,
      type: QuickViewType.Property,
      toggleComponent: <div>{props.propertyName}</div>
    }}
  />)
}

export var DefaultTemplate = function (props: any) {
  return (
    <ColumnsDirective>
      <ColumnDirective
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        type="checkbox"
        width="50"
      />

      <ColumnDirective
        headerText=""
        field="Action"
        textAlign="Left"
        width="120"
      />

      <ColumnDirective
        field="imageUrl"
        textAlign="Left"
        headerText="Image"
        width="100"
        template={image}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Inspection Type"
        field="inspectionType"
        width="120"
        template={type}
      />

      <ColumnDirective
        textAlign="Left"
        field="propertyName"
        headerText="Property"
        width="120"
        template={property}
      />

      <ColumnDirective
        textAlign="Left"
        field="inspectionName"
        headerText="Inspection Name"
        width="120"
        template={name}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Inspector"
        width="120"
      />

      <ColumnDirective
        textAlign="Left"
        field="status"
        headerText="Status"
        width="120"
        template={status}
      />

      <ColumnDirective
        textAlign="Left"
        field="docs"
        headerText="Docs"
        width="120"
        template={documents}
      />

      <ColumnDirective
        textAlign="Left"
        field="inspectionDateAndTime"
        headerText="Inspection Date and Time"
        type="date"
        format={{ type: "date", format: "dd/MM/yyyy HH:mm" }}
        width="120"
      />

      <ColumnDirective
        textAlign="Left"
        field="createdDate"
        headerText="Created Date"
        type="date"
        format={{ type: "date", format: "MMM yyyy" }}
        width="120"
        visible={false}
      />

      <ColumnDirective
        textAlign="Left"
        field="createdBy"
        headerText="Created By"
        width="150"
        visible={false}
      />
    </ColumnsDirective>
  );
};