import { IOptions } from "../components/atoms/SelectDropdown"

export const mapToDropdown = (data: any[], value: string, label: string) => {
    let result: IOptions[] = [];
    if(data != null){
        data.forEach(d => {
            if (d !== undefined && d[value] && d[label]) {
                result.push({
                    label: d[label],
                    value: d[value].toString()
                })
            }
        })
    }

    return result;
}