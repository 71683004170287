import styled from 'styled-components';

export const SectionDiv = styled.div`
    display: flex;
`; 

export const CardDiv = styled.div`
    padding: 10px;
`; 

export const Panel = styled.div`
  text-align: center;
  
  .label {
    text-align: left;
    font-weight: bold;
    color: #121212;
    margin-bottom: 1rem;
  }

  .content {
    border-radius: 25px;
    padding: 1rem;
    background-color: ${({ theme }) => theme.colors.containers};
  }

  .grid {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
  
    label {
      color: $primary-branding-0;
      font-size: 0.8rem;
  
    }
  
    .value {
      line-height: normal !important;
      font-size: 1.5rem;
      color: #121212;;
  
      font-size: 1.2rem;

/*       @media (min-width: 1600px) {            
        font-size: 2.5rem;
      }
      @media screen and (max-width: 1300px) {   
        font-size: 1.5rem;
      }
      @media screen and (max-width: 500px) {   
        font-size: 1.2rem;
      } */
    }
  }
`;