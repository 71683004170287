import React, { useEffect, useState } from 'react'
import styles from './StickyComponent.module.scss'

type Props = {
    children: JSX.Element
    options?: {
        stickyClassName: string
    }
}

let observer: IntersectionObserver
let stickyElementRef

const StickyComponent = ({ children, options }: Props) => {
    const [inView, setInView] = useState(false)
    stickyElementRef = React.createRef()

    useEffect(() => {
        observer = new IntersectionObserver(callback)
        observer.observe(stickyElementRef.current)
    }, [])

    const callback = (entries: any[]) => {
        let isIntersecting = entries.filter((x) => x.isIntersecting)
        if (isIntersecting.length) {
            setInView(true)
        } else {
            setInView(false)
        }
    }

    return (
        <div>
            <div className={styles.inView} ref={stickyElementRef}>
                {children}
            </div>

            {!inView && (
                <div
                    className={`${styles.stickyMobile} ${
                        options ? options.stickyClassName : ''
                    }`}
                >
                    <div>{children}</div>
                </div>
            )}
        </div>
    )
}

export default StickyComponent
