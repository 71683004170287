import React, { Component } from 'react'
import { Redirect, Route } from 'react-router'
import { withRouter } from 'react-router-dom'
import ClientPage from '../ClientPage'
import ClientsManagementPage from '../ClientsManagementPage'
import UserPage from '../UserPage'
import UsersPage from '../UserPage/UsersPage'
import AuditPage from './AuditPage'
import AuditPageV2 from './AuditPageV2'
import Detail from './AuditPage/Detail'
import EntityPage from './EntityPage'
import NotificationPage from './NotificationPage'
import PermissionsPage from './PermissionsPage'
import styles from './PlatformManagementPage.module.scss'
import RolesPage from './RolesPage'
import RolePage from './RolesPage/RolePage'

interface IProps {
    history: any
    location: any
    match: any
}

class PlatformManagementPage extends Component<IProps> {
    constructor(props: any) {
        super(props)

        this.state = {}
    }

    render() {
        return (
            <div className={styles.platformManagement}>
                <div className={styles.page}>
                    <Route
                        path={`${this.props.match.url}`}
                        exact
                        render={() => (
                            <Redirect to={`${this.props.match.url}/users`} />
                        )}
                    />

                    <Route
                        path={`${this.props.match.path}/permissions`}
                        exact
                        component={PermissionsPage}
                    />

                    <Route
                        path={`${this.props.match.path}/roles`}
                        exact
                        component={RolesPage}
                    />

                    <Route
                        path={`${this.props.match.path}/role/:roleId`}
                        component={RolePage}
                    />

                    <Route
                        path={`${this.props.match.path}/entities`}
                        exact
                        component={EntityPage}
                    />

                    <Route
                        path={`${this.props.match.path}/timeline`}
                        exact
                        component={AuditPage}
                    />

                    <Route
                        path={`${this.props.match.path}/audit`}
                        exact
                        component={AuditPageV2}
                    />

                    <Route
                        path={`${this.props.match.path}/timeline/:auditId`}
                        exact
                        component={Detail}
                    />

                    <Route
                        path={`${this.props.match.path}/notifications`}
                        exact
                        component={NotificationPage}
                    />

                    <Route
                        path={`${this.props.match.path}/user/:userId?`}
                        component={UserPage}
                    />
                    <Route
                        path={`${this.props.match.path}/users`}
                        render={(props) => <UsersPage />}
                    />

                    <Route
                        path={`${this.props.match.path}/clients`}
                        exact
                        component={ClientsManagementPage}
                    />

                    <Route
                        path={`${this.props.match.path}/client/:clientId`}
                        component={ClientPage}
                    />
                </div>
            </div>
        )
    }
}

export default withRouter(PlatformManagementPage)
