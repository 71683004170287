import styled from 'styled-components';

export const HeaderDiv = styled.div<{ color?: string }>`
    color: red;
    grid-area: header;
    display: block;
    text-align: left;
    width: 100%;
    border-bottom: 2px solid;
    border-color: ${p => p.color ? p.color : 'black'};
`; 

export const HeaderLabelWrapper = styled.label<{ buttonsCount?: number }>`
    width: ${p => p.buttonsCount == 1 ? '75%' : '50%'};
    text-align: left;
    margin-bottom: 5px;
`; 

export const HeaderLabel = styled.label<{ hasLink?: boolean , color?: string}>`
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    color: ${p => p.color ? p.color : '#104351'};
    margin-bottom: 0px;
    &:hover {
        background-color: ${p => p.hasLink ? '#F5F5F5' : ''};
        cursor: ${p => p.hasLink ? 'pointer' : ''};
      }
`; 

export const HideIconWrapper = styled.label`
    width: 5%;
    text-align: right;
    margin-bottom: 0px;
    &:hover {
        cursor: pointer;
      }
`;

export const MoveIconWrapper = styled.label`
    width: 20%;
    text-align: right;
    margin-bottom: 0px;
`;

export const EmptyWrapper = styled.label`
    width: 15%;
    text-align: right;
    margin-bottom: 0px;
`;

export const ButtonLink = styled.label`
    text-align: right;
    margin-left: 5px;
    margin-bottom: 0px;
    button {
        height: 22px;
        width: 80px;
    }
`;

export const ButtonsWrapper = styled.label<{ buttonsCount?: number }>`
    width: ${p => p.buttonsCount == 1 ? '25%' : '50%'};
    text-align: right;
    margin-bottom: 0px;
`;