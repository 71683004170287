import React from 'react'
import { Row, Col } from 'reactstrap'
import Modal from 'react-responsive-modal'

import styles from './DeleteContactModal.module.scss'
import GenericButton from '../GenericButton'
import SuccessTick from '../SuccessTick'
import RotatingLoader from '../RotatingLoader'
import { deleteContact } from '../../../services/ContactService'
import MaterialCheckbox from '../MaterialCheckbox'
import { removeContactById } from '../../../helpers/contactHelpers'
import { IDataResponse } from '../../../types/ApiService'
import { processToast } from '../../../helpers/toastHelper'
import Buttons from '../Buttons'

interface IProps {
    open: boolean
    onOpenModal(): void
    onCloseModal(): void
    onDeleteCallBack(): void
    contactId: string
    contactName: string
    isUser: boolean
    isTenant: boolean
}

interface IState {
    deleteTenancies: boolean
    deleteDocuments: boolean
    deleteTransactions: boolean
    loading: boolean
    success: boolean
}

export interface IDeleteRequest {
    deleteAssociatedTenancy: boolean
    deleteAssociatedDocument: boolean
}

class DeleteContactModal extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            deleteDocuments: true,
            deleteTenancies: true,
            deleteTransactions: true,
            loading: false,
            success: false,
        }
    }

    onDelete(): void {
        this.setState({
            loading: true,
        })

        deleteContact(this.props.contactId, {
            deleteAssociatedDocument: this.state.deleteDocuments,
            deleteAssociatedTenancy: this.state.deleteTenancies,
            deleteAssociatedTransaction: this.state.deleteTransactions,
        }).then((resp) => {
            if (resp.status == 200) {
                this.setState({
                    loading: false,
                })
                let response: IDataResponse<any> = resp.data
                processToast(response)
                this.props.onCloseModal()

                if (response.isSuccess && response.message) {
                    removeContactById(this.props.contactId)
                    this.props.onDeleteCallBack()
                }
            }
        })
    }

    render() {
        if (this.state.success) {
            return (
                <Modal
                    open={this.props.open}
                    onClose={this.props.onCloseModal}
                    center
                >
                    <div>
                        <SuccessTick />
                        <p className={styles.modalBody}>Contact deleted</p>
                    </div>
                </Modal>
            )
        }

        if (this.state.loading) {
            return (
                <Modal
                    open={this.props.open}
                    onClose={this.props.onCloseModal}
                    center
                >
                    <div>
                        <RotatingLoader
                            loading={true}
                            text={`Deleting ${this.props.contactName}...`}
                        />
                    </div>
                </Modal>
            )
        }

        return (
            <Modal
                open={this.props.open}
                onClose={this.props.onCloseModal}
                center
            >
                {this.props.isUser && !this.state.loading ? (
                    <div>
                        <p className={styles.modelHeader}>DELETE CONTACT</p>
                        <p className={styles.modalBody}>
                            You cannot delete this contact as it has a user
                            account linked. Please contact{' '}
                            <a
                                href="mailto:rcsupport@rentchief.co.uk"
                                target="_blank"
                            >
                                support
                            </a>{' '}
                            to remove this user.{' '}
                        </p>
                        <Row>
                            <Col className={styles.buttonDiv}>
                                <GenericButton
                                    onClick={() => this.props.onCloseModal()}
                                    border
                                >
                                    Cancel
                                </GenericButton>
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <div>
                        <p className={styles.modelHeader}>DELETE CONTACT</p>
                        <p className={styles.modalBody}>
                            You are about to delete the contact:
                        </p>

                        <p className={styles.modalBody}>
                            <span className={styles.address}>
                                {this.props.contactName}
                            </span>
                        </p>

                        <p className={styles.modalBody}>
                            Deleting a contact will delete all contact detail
                            information. In addition, you can delete associated
                            tenancy records and documents if required.
                        </p>

                        <div className={styles.checkboxes}>
                            {this.props.isTenant && (
                                <p className={styles.modalBody}>
                                    <MaterialCheckbox
                                        checked={this.state.deleteTenancies}
                                        onChange={() =>
                                            this.setState({
                                                deleteTenancies:
                                                    !this.state.deleteTenancies,
                                            })
                                        }
                                        labelText="Delete all associated tenancy records?"
                                    />
                                </p>
                            )}

                            <p className={styles.modalBody}>
                                <MaterialCheckbox
                                    checked={this.state.deleteDocuments}
                                    onChange={() =>
                                        this.setState({
                                            deleteDocuments:
                                                !this.state.deleteDocuments,
                                        })
                                    }
                                    labelText="Delete all associated document records?"
                                />
                            </p>

                            <p className={styles.modalBody}>
                                <MaterialCheckbox
                                    checked={this.state.deleteTransactions}
                                    onChange={() =>
                                        this.setState({
                                            deleteTransactions:
                                                !this.state.deleteTransactions,
                                        })
                                    }
                                    labelText="Delete all associated transaction records?"
                                />
                            </p>
                        </div>

                        <p className={styles.modalBody}>
                            Do you wish to go ahead and delete?
                        </p>
                        <Row>
                            <Col className={styles.buttonDiv}>
                                <Buttons
                                    buttons={[
                                        {
                                            displayType: 'cancel',
                                            elementType: 'button',
                                            onClick: () =>
                                                this.props.onCloseModal(),
                                        },
                                        {
                                            text: 'Delete',
                                            displayType: 'submit',
                                            elementType: 'button',
                                            onClick: () => this.onDelete(),
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </div>
                )}
            </Modal>
        )
    }
}

export default DeleteContactModal
