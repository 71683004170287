import { IContactPreference } from '../ContactPreferencesService'
import { IAddress } from '../PropertyService/IPropertyService'
import { IUser } from '../UserService/IUser'

export interface IUpdateProfile {
    isContact?: boolean
    profile: IProfile
    address: IAddress
    contactPreferences: IContactPreference
    clientId: string
    contactType?: string
}

export interface IProfile {
    profileId?: string
    email?: string
    saluation: string
    forename: string
    surname: string
    contactNumber: string
    profileImageUri?: string
    contactEmail?: boolean
    contactPost?: boolean
    contactSms?: boolean
    contactPhone?: boolean
    title?: string
    gender?: string
    citizen?: string
    emergencyContactPhone: string
    emergencyContactEmail: string
    emergencyContactName: string
}

export interface IUploadProfileImage {
    base64EncodedImage: string
    contactId?: string
}

export interface IUserProfileResponse {
    user: IUser
    profile: IProfile
    address: IAddress
    additionalInformation: IAdditionalInformation
}

export interface IAdditionalInformation {
    role: string
    contactType: string
    contactTypeName: string
    status: string
}

export enum ProfileType {
    User,
    Contact,
}
