import axios, { AxiosResponse } from "axios";
import {
  AddTokenToLocalStorage,
  UpdateTokenInReduxStore
} from "../helpers/tokenHelpers";
import { IIdentityRequest } from "../types/LoginService/ILogin";
import { getJwt, createBearerToken, getFullToken } from "../helpers/authHelpers";
import { IPersistedToken } from '../types/Token';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { identityService } from "../configuration/domains";
import { store } from "../store";
import { updateToastMessage } from "../helpers/toastHelper";

const instance = axios.create();

instance.interceptors.request.use(function (config) {
  config.headers['Authorization'] = createBearerToken();
  config.headers['Accept-Language'] = "en-GB,en;q=0.9";
  return config;
}, function (error) {
  // Do something with request error
  return errorHandler(error)
});

const validateResponse = (response: any) => {
  if (response.status == "200") {
    return true;
  }

  return false;
}

const successHandler = (response: any) => {
  let validResponse = validateResponse(response);
  return response;
}

const errorHandler = (error: any) => {
  //if (error.response.status != 404 && error.response.status != 401) {
  //  updateToastMessage("Something has gone wrong. Support have been notified", "failure");
  //}

  return Promise.reject({ ...error })
}

instance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest: any) => {
  let fullToken : IPersistedToken = getFullToken();
  console.log("refreshAuthLogic - Full token: " + JSON.stringify(fullToken));
  let request: IIdentityRequest = {
    grant_type: 'refresh',
    token: fullToken && fullToken.access_token,
    email: '',
    password: '',
    client_id: ''
  }

  return axios.post(identityService, request).then(tokenRefreshResponse => {
    console.log("refreshAuthLogic - Reponse received");
    let rememberLogin = true;
    if(fullToken){
      rememberLogin = fullToken.storage_type == "local" ? true : false
    }

    if (!store.getState().app.is_impersonating) {
      AddTokenToLocalStorage(tokenRefreshResponse.data.newToken, rememberLogin, fullToken && fullToken.expiry_date);
    }

    UpdateTokenInReduxStore(tokenRefreshResponse.data.newToken);
    failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.newToken;

    const { config } = failedRequest;
    const originalRequest = config;

    originalRequest.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.newToken;
    return Promise.resolve();
  }).catch(error => {
    console.log("refreshAuthLogic - Error: " + error);
  });
};

let service = createAuthRefreshInterceptor(instance, refreshAuthLogic);

export { service as apiService };