import {
    Aggregate,
    Column,
    ColumnMenu,
    CommandColumn,
    ContextMenu,
    ExcelExport,
    Filter,
    Grid,
    GridComponent,
    Group,
    Inject,
    Page,
    PdfExport,
    QueryCellInfoEventArgs,
    Reorder,
    Resize,
    Sort,
} from '@syncfusion/ej2-react-grids'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Router, withRouter } from 'react-router-dom'
import {
    filterOptions,
    pageSettings,
} from '../../../../configuration/grid_syncfusion_options'
import { processImpersonation } from '../../../../helpers/impersonatorHelpers'
import { canImpersonateUser } from '../../../../helpers/permissionHelpers'
import { isClientAdmin, isPlatformAdmin } from '../../../../helpers/roleHelpers'
import { processToast } from '../../../../helpers/toastHelper'
import history from '../../../../history'
import { resendEmailConfirmation } from '../../../../services/AccountService'
import { impersonateUser } from '../../../../services/ImpersonateService'
import { IDataResponse } from '../../../../types/ApiService'
import { ExportTypeEnum, ITransactionItem } from '../../../../types/Transaction'
import SFDropdown from '../../../atoms/SFDropdown'
import UserContactInfo, {
    UserContactInfoType,
} from '../../../atoms/UserContactInfo'
import styles from './UserGrid.module.scss'
import UserGridTopRow from './UserGridTopRow'
import { editSettings, textWrapSettings } from './_config/columnOptions'
import { generateOdataSource } from './_config/dataConfig'
import { selectionSettings } from './_config/selectionConfig'
import { onDeleteRows, resetPassword, toggleUserStatus } from './_helpers'
import { IUserGridOptions } from './_types'

interface IState {
    loading?: boolean
    hasBeenGrouped: boolean
    search?: string
    startDate?: Date
    endDate?: Date
    selectedType?: string
    deletingUserIds: string[]
    deleting: boolean
}

interface IProps {
    config: IUserGridOptions
    history: any
    location: any
    match: any
    dateRangeStart?: Date
    dateRangeEnd?: Date
}

class UserGrid extends Component<IProps, IState> {
    public grid: Grid | null
    public topRowRef: any

    constructor(props: any) {
        super(props)

        this.topRowRef = React.createRef()

        this.state = {
            loading: false,
            selectedType: '',
            hasBeenGrouped: false,
            search: '',
            deletingUserIds: [],
            deleting: false,
        }

        this.onDelete = this.onDelete.bind(this)
        this.onDeleteCallback = this.onDeleteCallback.bind(this)
        this.externalSearchHandler = this.externalSearchHandler.bind(this)
        this.externalExportHandler = this.externalExportHandler.bind(this)
        this.setType = this.setType.bind(this)
        this.dataBound = this.dataBound.bind(this)
        this.requestDataSource = this.requestDataSource.bind(this)
        this.onDateRangeChange = this.onDateRangeChange.bind(this)
        this.deleteRow = this.deleteRow.bind(this)
        this.onResetPassword = this.onResetPassword.bind(this)
        this.customCell = this.customCell.bind(this)
    }

    dataBound(args: any): void {
        if (
            this.grid.groupSettings.columns.length < 1 &&
            !this.state.hasBeenGrouped
        ) {
            this.grid.groupSettings = {
                showGroupedColumn: false,
                columns: this.props.config.groupSettings
                    ? this.props.config.groupSettings
                    : [],
            }

            this.setState({
                hasBeenGrouped: true,
            })
        }

        if (!this.props.config.dataFilterSettings) {
            return
        }
    }

    externalExportHandler(exportType: ExportTypeEnum): void {
        if (!this.grid) {
            return
        }

        let selectedRecords = this.grid.getSelectedRecords()
        if (!selectedRecords) {
            return
        }

        if (exportType == ExportTypeEnum.PDF) {
            this.grid.pdfExport()
            return
        }

        if (exportType == ExportTypeEnum.Excel) {
            this.grid.excelExport()
            return
        }

        if (exportType == ExportTypeEnum.Csv) {
            this.grid.csvExport()
            return
        }
    }

    externalSearchHandler(searchValue: string): void {
        this.setState(
            {
                search: searchValue,
            },
            () => this.requestDataSource()
        )
    }

    onDelete(transaction: ITransactionItem): void {
        this.setState({
            //deletingTransactions: transaction,
            //isDeleting: true
        })
    }

    onDeleteCallback(transactionId: string): void {
        //deleteTransactionItem(transactionId);
        this.setState({
            //isDeleting: false,
            //deletingTransactions: null
        })
    }

    customCell(args: QueryCellInfoEventArgs) {
        if ((args.column as Column).headerText === 'User') {
            let data = args.data as any
            if (data.userId && data.userId != null) {
                ReactDOM.render(
                    <Router history={history}>
                        <UserContactInfo
                            //disablePopup
                            id={data.userId}
                            type={UserContactInfoType.User}
                        />
                    </Router>,
                    args.cell as Element
                )
            } else {
                ReactDOM.render(<div> </div>, args.cell as Element)
            }
        }

        if ((args.column as Column).headerText === 'Action') {
            let data = args.data as any
            ReactDOM.render(
                <div>
                    <SFDropdown
                        loadedBeforeRender
                        customCssClassName={styles.syncfusionDropdown}
                        onSelect={(args) => {
                            if (args.item.text == 'Edit') {
                                this.props.history.push(
                                    `/dashboard/manage/user/${data.userId}`
                                )
                            }
                            if (args.item.text == 'Delete') {
                                onDeleteRows(
                                    [data.userId],
                                    this.props.location,
                                    this.props.history
                                )
                            }
                            if (args.item.text == 'Reset Password') {
                                resetPassword(data.email)
                            }
                            if (args.item.text == 'Mark as Active') {
                                toggleUserStatus(data.userId, true)
                            }
                            if (args.item.text == 'Deactivate') {
                                toggleUserStatus(data.userId, false)
                            }
                            if (args.item.text == 'Resend Email Confirmation') {
                                resendEmailConfirmation([data.userId]).then(
                                    (resp) => {
                                        if (resp && resp.status == 200) {
                                            processToast<string>(resp.data)
                                        }
                                    }
                                )
                            }
                            if (args.item.text == 'Impersonate User') {
                                impersonateUser(data.userId).then((resp) => {
                                    if (resp && resp.status == 200) {
                                        processToast<string>(resp.data)

                                        let response: IDataResponse<string> =
                                            resp.data

                                        if (response.isSuccess) {
                                            processImpersonation(resp.data.data)
                                        }
                                    }
                                })
                            }
                        }}
                        items={[
                            {
                                iconCss: styles.subIconEdit,
                                text: 'Edit',
                                url: `/dashboard/manage/user/${data.userId}`,
                            },
                            {
                                iconCss: styles.subIconChangePassword,
                                text: 'Reset Password',
                            },
                            ...(canImpersonateUser()
                                ? [
                                      {
                                          iconCss: styles.subIconActivate,
                                          text: 'Impersonate User',
                                      },
                                  ]
                                : []),
                            ...(!data.hasApprovedEmail
                                ? [
                                      {
                                          iconCss: styles.subIconActivate,
                                          text: 'Resend Email Confirmation',
                                      },
                                  ]
                                : []),
                            ...(isPlatformAdmin() || isClientAdmin()
                                ? [
                                      {
                                          iconCss: styles.subIconActivate,
                                          text: data.active
                                              ? 'Deactivate'
                                              : 'Mark as Active',
                                      },
                                  ]
                                : []),
                            {
                                iconCss: styles.subIconDelete,
                                text: 'Delete',
                            },
                        ]}
                    />
                </div>,
                args.cell as Element
            )
        }
    }

    setType(transactionType: string) {
        if (transactionType != this.state.selectedType) {
            this.setState({ selectedType: transactionType }, () => {
                this.requestDataSource()
            })
        }
    }

    componentDidMount(): void {
        this.requestDataSource()
    }

    requestDataSource(): void {
        if (!this.grid) {
            this.grid.dataSource = generateOdataSource(
                {
                    size: 0,
                    page: 0,
                    search: this.state.search,
                },
                this.state.startDate,
                this.state.endDate,
                this.props.config.dataFilterSettings &&
                    this.props.config.dataFilterSettings.active
            )
        }
    }

    deleteRow(): void {
        this.requestDataSource()
    }

    onDateRangeChange(startDate: Date, endDate: Date): void {
        this.setState(
            {
                startDate,
                endDate,
            },
            () => {
                this.requestDataSource()
            }
        )
    }

    onResetPassword(): void {}

    render() {
        return (
            <div className={styles.page}>
                <div className={styles.content}>
                    {(!this.props.config.uiSettings ||
                        !this.props.config.uiSettings.hideTopBar) && (
                        <UserGridTopRow
                            {...this.props}
                            addEditOptions={this.props.config.addSettings}
                            grid={this.grid}
                            getTransactionsList={null}
                            externalSearchHandler={this.externalSearchHandler}
                            setType={this.setType}
                            startDate={this.props.dateRangeStart}
                            endDate={this.props.dateRangeEnd}
                            ref={this.topRowRef}
                            onDelete={this.deleteRow}
                            onDeleteCallback={this.deleteRow}
                            onDateRangeChange={this.onDateRangeChange}
                        />
                    )}

                    <div className={`${styles.row}`}>
                        <div className={`${styles.column} ${styles.grid}`}>
                            <GridComponent
                                dataBound={this.dataBound}
                                queryCellInfo={this.customCell}
                                editSettings={editSettings}
                                showColumnMenu
                                id="userGrid"
                                textWrapSettings={textWrapSettings}
                                allowTextWrap={true}
                                allowExcelExport={true}
                                allowPdfExport={true}
                                allowGrouping={true}
                                dataSource={generateOdataSource(
                                    {
                                        size: 0,
                                        page: 0,
                                        search: this.state.search,
                                        propertyId:
                                            this.props.config
                                                .dataFilterSettings &&
                                            this.props.config.dataFilterSettings
                                                .propertyId,
                                        contactId:
                                            this.props.config
                                                .dataFilterSettings &&
                                            this.props.config.dataFilterSettings
                                                .contactId,
                                    },
                                    this.state.startDate,
                                    this.state.endDate,
                                    this.props.config.dataFilterSettings &&
                                        this.props.config.dataFilterSettings
                                            .active
                                )}
                                allowResizing={true}
                                allowReordering={true}
                                selectionSettings={selectionSettings}
                                allowPaging={true}
                                allowSorting={true}
                                sortSettings={{
                                    columns: [
                                        {
                                            field: 'createdDate',
                                            direction: 'Descending',
                                        },
                                    ],
                                }}
                                pageSettings={pageSettings}
                                filterSettings={filterOptions}
                                allowFiltering={true}
                                ref={(g) => (this.grid = g)}
                            >
                                <Inject
                                    services={[
                                        Aggregate,
                                        ColumnMenu,
                                        ContextMenu,
                                        CommandColumn,
                                        Filter,
                                        Page,
                                        Sort,
                                        ExcelExport,
                                        PdfExport,
                                        Group,
                                        Reorder,
                                        Resize,
                                    ]}
                                />

                                {this.props.config.template &&
                                    this.props.config.template}
                            </GridComponent>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(UserGrid)
