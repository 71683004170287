import { store } from '../store';
import { rcToken } from '../configuration/appConfig';
import { IPersistedToken } from '../types/Token';
import { removeElevioConfiguration } from './elevioHelpers';
import { IWSHandler } from '../reducers/appReducer';
import WebsocketService from '../services/SignalRService';

export function getJwt() {
    const state = store.getState();
    var token = state.user.access_token;

    if (token != '') {
        return token;
    }

    let tkn = localStorage.getItem(rcToken);
    if (!tkn) {
        tkn = sessionStorage.getItem(rcToken);
    }

    if (!tkn) {
        return null;
    }

    let obj: IPersistedToken = JSON.parse(tkn);
    return obj.access_token;
}

export function getFullToken() {
    let tkn = localStorage.getItem(rcToken);
    if (!tkn) {
        tkn = sessionStorage.getItem(rcToken);
    }

    if (!tkn) {
        return null;
    }

    let obj: IPersistedToken = JSON.parse(tkn);
    return obj;
}

export function hasTokenInReduxStore() {
    const state = store.getState();

    if (state.user.is_logged_in && state.user.access_token != '') {
        return true;
    }

    return false;
}

export function isUserAuthenticated() {
    const state = store.getState();
    if (state.app.is_preview) {
        return false;
    }

    if (state.user.is_logged_in) {
        return true;
    }

    if (getJwt() != '' && getJwt() != null) {
        return true;
    }

    return false;
}

export function getUserId() {
    const state = store.getState();
    return state.user.user_id;
}

export function getProfileId() {
    const state = store.getState();
    return state.user.profile_id;;
}

export function createBearerToken() {
    var token = getJwt();
    return 'Bearer ' + token;
}

export function logoutUser(): void {
    store.dispatch({
        type: 'LOGOUT_USER'
    });

    store.dispatch({
        type: 'APP_TOGGLE_SIDEBAR',
        show: false
    });

    removeElevioConfiguration();

    // WS Handlers - Unregister All
    WebsocketService.unregisterAll();
    WebsocketService.disconnectFromHub();

    var token = (localStorage.getItem(rcToken));
    if(token){
        localStorage.removeItem(rcToken);
    }else{
        token = (sessionStorage.getItem(rcToken));
        if(token){
            sessionStorage.removeItem(rcToken);
        }
    }
}

export function loginSuccess(data: any): void {
    store.dispatch({
        type: "USER_LOGIN_SUCCESS",
        data
    });
}

export function userIsLoggedInTrue(): void {
    store.dispatch({
        type: "USER_IS_LOGGED_IN_TRUE"
    });
}

export function resetIsCreatingUser() {
    store.dispatch({
        type: 'RESET_IS_CREATING_USER'
    });
}

export function isPreviewTrue() {
    store.dispatch({
        type: 'APP_IS_PREVIEW_TRUE'
    });
}

export function isImpersonatingTrue() {
    store.dispatch({
        type: 'APP_IS_IMPERSONATING_TRUE'
    });
}

export function isImpersonatingFalse() {
    store.dispatch({
        type: 'APP_IS_IMPERSONATING_FALSE'
    });
}

export function removeWebsocketHandler(handlerName: string) {
    let current = store.getState().app.websocketHandlers;
    updateWebsocketHandlers(current.filter(x => x.handlerName != handlerName));
}

export function addWebsocketHandler(handler: IWSHandler) {
    let current = store.getState().app.websocketHandlers;
    let newArr = [handler].concat(current);

    updateWebsocketHandlers(newArr);
}

export function updateWebsocketHandlers(handlers: Array<IWSHandler>) {
    store.dispatch({
        type: 'Websockets/Update Websocket Handlers',
        data: handlers
    });
}