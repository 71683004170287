import React from "react";
import { Alert } from "reactstrap";
import Modal from "react-responsive-modal";
import styles from "./WelcomeToRentChiefModal.module.scss";
import "react-dropzone-uploader/dist/styles.css";
import GenericButton from "../GenericButton";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { toggleAddPropertySidebar } from "../../../helpers/sidebarHelpers";

interface IProps {
  open: boolean;
  onClose(): void;
  onSuccess(uri: string): void;
}

interface IState {
  uri: string;
  loading: boolean;
  success: boolean;
}

class WelcomeToRentChiefModal extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      uri: "",
      loading: false,
      success: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.onLoading = this.onLoading.bind(this);
    this.setImage = this.setImage.bind(this);
  }

  setImage(uri: string): void {
    this.props.onSuccess(uri);
  }

  handleChange(event: React.FormEvent<HTMLInputElement>) {
    const field = event.currentTarget.name;
    const value: string = event.currentTarget.value;

    this.setState({ [field]: value } as Pick<IState, any>);
  }

  onLoading(loading: boolean): void {
    this.setState({
      loading
    });
  }

  addProperty(): void {
    toggleAddPropertySidebar(true);
    this.props.onClose();
  }

  render() {

    return (
      <div>
        <Modal
          modalId={styles.modal}
          open={this.props.open}
          onClose={this.props.onClose}
          center
        >
          <Alert className={styles.alert} color="info">
            <h1 className={styles.h1}>Welcome</h1>
            <h3 className={styles.h3}>Your property investment journey with Rent Chief starts here</h3>

            <ul className={styles.tasks}>
              <li><img src='https://png.pngtree.com/svg/20170602/person_1058425.png' />Complete your profile setup</li>
              <li onClick={() => toggleAddPropertySidebar(true)}><img src='https://img.icons8.com/cotton/2x/home--v2.png' />Add your first property</li>
            </ul>

            <Row className={styles.buttons}>
              <Col><GenericButton border text='Start Exploring'/></Col>
              <Col><GenericButton border text="I'll find my own way around" /></Col>
            </Row>

          </Alert>
        </Modal>
      </div>
    );
  }
}

export default WelcomeToRentChiefModal;