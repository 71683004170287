import ActionIcon from '../Icons/ActionIcon'
import AddAction from '../Icons/AddAction'
import ChevronIcon from '../Icons/ChevronIcon'
import UploadIcon from '../Icons/UploadIcon'

const defaultText = {
    submit: 'Add',
    action: 'Actions',
}

const iconMap = {
    add: AddAction(),
    action: ActionIcon(),
    chevron: ChevronIcon(),
    upload: UploadIcon(),
}

export { defaultText, iconMap }
