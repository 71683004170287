import { store } from "../store";
import { IContactListItem } from "../types/ContactService/ContactService";
import { IUserContactInfoResponse } from "../types/QuickView";
import { toggleUserActiveStatus } from "../services/UserService";
import { processToast } from "./toastHelper";
import { IDataResponse } from "../types/ApiService";
import { getLoggedInUsersContactId } from "./profileHelpers";

export function getTenantDocumentLinks(): { propertyId: string, contactId: string, tenancyId: string } {
  const { tenancy_id, tenancy_property_id } = store.getState().user;

  return {
    tenancyId: tenancy_id,
    propertyId: tenancy_property_id,
    contactId: getLoggedInUsersContactId()
  }
}

export function updateUserActiveStatusState(userId: string, status: boolean) {
  let current = store.getState().contact.contact_list;
  const copy = current.map((object) => ({ ...object }));

  let filtered = copy.findIndex(x => x.userId == userId);
  if (filtered === null) {
    return;
  }

  copy[filtered].isUserActive = status;
  updateContactsList(copy);
}

export function toggleUserStatus(userId: string, status: boolean) {
  toggleUserActiveStatus(userId, status).then(resp => {
    if (resp && resp.status == 200) {
      let data: IDataResponse<boolean> = resp.data;
      processToast<boolean>(data);

      if (data.isSuccess) {
        updateUserActiveStatusState(userId, status);
      }
    }
  });
}

export function updateContactsList(data: Array<IContactListItem>) {
  store.dispatch({
    type: "UPDATE_CONTACTS_LIST",
    data
  });
}

export function addToContactsList(data: IContactListItem) {
  let state = store.getState();

  let copy = state.contact.contact_list.map((object: any) => ({ ...object }));
  copy.unshift(data);

  store.dispatch({
    type: "UPDATE_CONTACTS_LIST",
    data: copy
  });
}

export function removeContactById(contactId: string) {
  let state = store.getState();
  let currentDocuments = state.contact.contact_list;
  currentDocuments = currentDocuments.filter(doc => doc.contact.contactId !== contactId)
  updateContactsList(currentDocuments);
}

export function getUserContactInfoDataFromState(contactId?: string, userId?: string): IUserContactInfoResponse {
  let state = store.getState();

  if (contactId) {
    let filtered = state.contact.user_contact_info_popup.filter(x => x.contactId == contactId);
    if (filtered) {
      return filtered[0];
    }
  }

  if (userId) {
    let filtered = state.contact.user_contact_info_popup.filter(x => x.userId == userId);
    if (filtered) {
      return filtered[0];
    }
  }
}

export function addToUserContactInfoPopup(data: IUserContactInfoResponse) {
  let state = store.getState();

  let newContacts = [data].concat(state.contact.user_contact_info_popup);

  store.dispatch({
    type: "UPDATE_USER_CONTACT_INFO_POPUP_LIST",
    data: newContacts
  });
}

export function clearUserContactInfoPopup() {
  let state = store.getState();

  store.dispatch({
    type: "UPDATE_USER_CONTACT_INFO_POPUP_LIST",
    data: []
  });
}