import { toggleIssuePopup, toggleAddDocumentPopup, toggleTransactionPopup, toggleEditPropertySidebar } from "../../../../../helpers/sidebarHelpers";
import { addURLParameter } from "../../../../../helpers/locationHelpers";
import history from "../../../../../history";
import { IRaiseIssueOptions } from "../../../AddEditIssue";
import { IDocumentUploadOptions } from "../../../../molecules/AddDocumentModal";
import { IAddEditTransactionOptions } from "../../../AddEditTransactionModal";
import { IAddTransactionReferer } from "../../../AddEditTransaction";

export function onAddIssueClickGeneric(options: IRaiseIssueOptions, search: any): void {
    let stringed = "issueOptions=" + JSON.stringify(options);

    let query = addURLParameter(search, stringed);
    history.push({
        search: query
    });

    toggleIssuePopup(true);
}

export function onAddIssueClick(propertyId: string, propertyName: string, search: any): void {
    let options: IRaiseIssueOptions = {
        propertyName: propertyName,
        propertyId: propertyId,
    }

    let stringed = "issueOptions=" + JSON.stringify(options);

    let query = addURLParameter(search, stringed);
    history.push({
        search: query
    });

    toggleIssuePopup(true);
}

export function addDocumentClicked(options: IDocumentUploadOptions, search: string): void {
    let query = "uploadDocumentOptions=" + JSON.stringify(options);

    let search1 = addURLParameter(search, query);

    history.push({
        search: search1
    });

    toggleAddDocumentPopup(true);
}

export function onAddExpenseClick(propertyId: string, propertyName: string, search: string, isIncome: boolean, isExpense: boolean,): void {
    let options: IAddEditTransactionOptions = {
        referer: IAddTransactionReferer.Unit,
        propertyId: propertyId,
        propertyName: propertyName,
        isIncome,
        isExpense
    }

    let stringed = "addEditTransactionOptions=" + JSON.stringify(options);

    let query = addURLParameter(search, stringed);
    history.push({
        search: query
    });

    toggleTransactionPopup(true);
}

export function onPropertyDetailsClick(propertyId: string): void {
    history.push(`/dashboard/property/${propertyId}/spec/composition`)
}

export function onEditProperty(propertyId: string, location: string): void {
    const { search } = location;
    const queryFormat = search ? "&" : "?";
    history.push({
        search:
            search +
            queryFormat +
            "editProperty=" +
            propertyId
    });

    // Dispatch open Edit (Which will get the property ID from the query param)
    toggleEditPropertySidebar(true);
}