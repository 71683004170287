import { store } from "../store";
import { ISidebarState } from "../reducers/appReducer";
import { setDashboardSideNavigationState } from "../helpers/sessionSettingsHelpers";

export function toggleSidebar(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_SIDEBAR",
    show,
  });
}

export function isMobileSidebarVisible(): boolean {
  let state = store.getState();
  return state.app.sidebars.mobile_side_nav;
}

export function toggleMobileSidebarFalse() {
  store.dispatch({
    type: "APP_TOGGLE_MOBILE_SIDEBAR",
    show: false,
  });
}

export function toggleMobileSidebar(status?: string) {
  let state = store.getState();
  store.dispatch({
    type: "APP_TOGGLE_MOBILE_SIDEBAR",
    show: !state.app.sidebars.mobile_side_nav,
  });
}

export function toggleAddUserSidebar(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_ADD_USER",
    show,
  });
}

export function toggleFilterSidebar(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_FILTER_SIDEBAR",
    show,
  });
}

export function toggleAddPropertySidebar(show: boolean) {
  if(show){
    toggleMobileSidebarFalse();
  }
  store.dispatch({
    type: "APP_TOGGLE_ADD_PROPERTY",
    show,
  });
}

export function toggleEditPropertySidebar(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_EDIT_PROPERTY",
    show,
  });
}

export function saveChangesEditProperty(refreshRequired: boolean) {
  store.dispatch({
    type: "APP_SAVE_CHANGES_EDIT_PROPERTY",
    refreshRequired,
  });
}

export function toggleAddDocumentSidebar(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_ADD_DOCUMENT",
    show,
  });
}

export function toggleAddClientSidebar(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_ADD_CLIENT",
    show,
  });
}

export function toggleTenancyActionSidebar(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_TENANCY_ACTION_SIDEBAR",
    show,
  });
}

export function toggleIssueActionSidebar(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_ISSUE_ACTION_SIDEBAR",
    show,
  });
}

export function toggleAddContactSidebar(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_ADD_CONTACT",
    show,
  });
}

export function toggleAssignRoleSidebar(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_ASSIGN_ROLE",
    show,
  });
}

export function toggleWelcomePopup(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_WELCOME_POPUP",
    data: show,
  });
}

export function toggleEditDocumentPopup(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_EDIT_DOCUMENT_POPUP",
    data: show,
  });
}

export function toggleAddDocumentPopup(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_ADD_DOCUMENT_POPUP",
    data: show,
  });
}

export function toggleEditRegulationPopup(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_EDIT_REGULATION_POPUP",
    data: show,
  });
}

export function toggleAddEditTenancyPopup(show: boolean) {
  if(show){
    toggleMobileSidebarFalse();
  }
  store.dispatch({
    type: "APP_TOGGLE_TENANCY_POPUP",
    data: show,
  });
}

export function toggleIssuePopup(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_ISSUE_POPUP",
    data: show,
  });
}

export function toggleTransactionPopup(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_TRANSACTION_POPUP",
    data: show,
  });
}

export function toggleDeleteInspectionPopup(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_INSPECTION_DELETE_POPUP",
    data: show,
  });
}

export function toggleDeleteDocumentPopup(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_DOCUMENT_DELETE_POPUP",
    data: show,
  });
}

export function toggleDeleteTransactionPopup(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_TRANSACTION_DELETE_POPUP",
    data: show,
  });
}

export function toggleDeleteUserPopup(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_USER_DELETE_POPUP",
    data: show,
  });
}

export function toggleIssueListCollapse(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_ISSUE_LIST_COLLAPSED",
    data: show,
  });
}

export function toggleUserListCollapse(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_USER_LIST_COLLAPSED",
    data: show,
  });
}

export function toggleChatListCollapse(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_CHAT_LIST_COLLAPSED",
    data: show,
  });
}

export function toggleStartChatPopup(show: boolean) {
  store.dispatch({
    type: "APP_TOGGLE_START_CHAT_POPUP",
    data: show,
  });
}

export function toggleDetailedSidebarNavigation(show: boolean) {
  setDashboardSideNavigationState(show);
  store.dispatch({
    type: "APP_TOGGLE_DETAILED_SIDE_NAVIGATION",
    data: show,
  });
}

export function isSidebarOpen(
  sidebars: ISidebarState,
  openClass: string,
  closedClass: string
): void {
  // Uncomment to find which sidebars are blocking scrolling
  var found = false;
  found = sidebars.add_property || found;
  found = sidebars.side_nav || found;
  found = sidebars.mobile_side_nav || found;
  found = sidebars.profile || found;
  found = sidebars.filter || found;
  found = sidebars.upload_property_image || found;
  found = sidebars.add_user || found;
  found = sidebars.edit_property.show || found;
  found = sidebars.add_document || found;
  found = sidebars.add_client || found;
  found = sidebars.tenancy_action || found;
  found = sidebars.add_contact || found;
  found = sidebars.assign_role || found;
  found = sidebars.issue_action || found;

  let hasSidebarOpen = found;
  var el = document.getElementById("html");

  if (!el) {
    return;
  }

  if (!hasSidebarOpen) {
    el.className = closedClass;
    return;
  }

  el.className = openClass;
  return;
}
