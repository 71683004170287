import { CommercialPropertyEntityId, ResidentialPropertyEntityId } from "../configuration/appConfig";
import { store } from "../store";
import { IUnitItem } from "../types/UnitService";

export function generateUnitLabel(propertyType: string, plural: boolean): string {
  if (propertyType == ResidentialPropertyEntityId) {
    return `room${plural ? 's' : ''}`;
  }

  if (propertyType == CommercialPropertyEntityId) {
    return `unit${plural ? 's' : ''}`;
  }
}

export function toggleDeleteUnitModal(show: boolean): void {
  store.dispatch({
    type: "APP_TOGGLE_UNIT_DELETE_POPUP",
    data: show
  });
}

export function updateViewingUnit(unit: IUnitItem): void {
  store.dispatch({
    type: "UPDATE_VIEWING_UNIT",
    data: unit
  });
}