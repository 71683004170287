import React from "react";
import { RotateLoader } from "react-spinners";
import logo from "../../../images/rent-chief-logo-block.png";

interface IProps {
  loading: boolean;
  text?: string;
  topMargin?: string;
  showLogo?: boolean;
}

import styles from "./RotatingLoader.module.scss";
import { rotateLoaderStyling } from "../../../global/variables/_variables";

class RotatingLoader extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    if (!this.props.loading) {
      return null;
    }

    return (
      <div
        style={{ marginTop: this.props.topMargin ? this.props.topMargin : 0 }}
      >
        {this.props.showLogo && <img className={styles.logo} src={logo} />}
        <div className={styles.loadingContainer}>
          <span className={styles.loading} />
        </div>
        {
          this.props.text &&
          <p className={styles.text}>{this.props.text}</p>
        }
      </div>
    );

    return (
      <div
        style={{ marginTop: this.props.topMargin ? this.props.topMargin : 0 }}
      >
        {this.props.showLogo && <img className={styles.logo} src={logo} />}
        <p>
          {" "}
          <RotateLoader {...rotateLoaderStyling} />
        </p>
        <p className={styles.text}>{this.props.text}</p>
      </div>
    );
  }
}

export default RotatingLoader;
