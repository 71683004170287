import {
  ToolbarItems,
  SearchSettingsModel,
  GroupSettingsModel,
  FilterSettingsModel,
  ContextMenuItem,
  SelectionSettingsModel,
  PageSettingsModel,
  EditSettingsModel,
} from "@syncfusion/ej2-react-grids";

// Toolbar Options
export const toolbarOptions: ToolbarItems[] = [
  "Edit",
  "Update",
  "Cancel",
  "PdfExport",
  "ExcelExport",
  "Search",
];

// Search Options
// TODO: Make this generic
export const searchOptions: SearchSettingsModel = {
  //fields: [
  //  "permissionTitle",
  //  "PermissionGroup",
  //  "Property Name",
  //  "propertyName",
  //],
  ignoreCase: true,
  operator: "contains",
};

// Grouping Options
export const groupOptions: GroupSettingsModel = {
  columns: ["permissionGroup"],
  showDropArea: false,
  showGroupedColumn: true,
};

// Filter Options
export const filterOptions: FilterSettingsModel = {
  type: "Excel",
};

// Context Menu options
export const contextMenuItems: ContextMenuItem[] = [
  "Edit",
  "Save",
  "Cancel",
  "PdfExport",
  "CsvExport",
];

// Select Options
export const selectionSettings: SelectionSettingsModel = { type: "Multiple" };

// Page Settings (paging etc)
export const pageSettings: PageSettingsModel = { pageSize: 20, pageSizes: true };

// Edit Options
export const editOptions: EditSettingsModel = {
  allowEditing: true,
  allowEditOnDblClick: true,
};

// Command Options
export const commands: any = [
  {
    type: "Edit",
    buttonOption: { iconCss: " e-icons e-edit", cssClass: "e-flat" },
  },
  {
    type: "Save",
    buttonOption: { iconCss: "e-icons e-update", cssClass: "e-flat" },
  },
  {
    type: "Cancel",
    buttonOption: { iconCss: "e-icons e-cancel-icon", cssClass: "e-flat" },
  },
];