import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import MoneySummary from '../../../../organisms/FinancialsPage/Components/PaymentSummaryComponent/MoneySummary/MoneySummary.component';
import SectionHeader from "../SectionHeader/SectionHeader.component"
import * as S from "./ActiveIncomeSection.styles";

interface sectionProps {
    id: string;
    header: string;
    hideOnClick(id: string): void;
    propertyId?: string;
}

const ActiveIncomeSection = ({id, header, hideOnClick, propertyId}: sectionProps) => {
    const history = useHistory();
    const location = useLocation();

    const [dataChangeCount, setDataChangeCount] = useState<number>();

    // The AddEditTransaction at the moment adds 'refreshTransactionGrid' to the query params after an update 
    // we simply listen to this to trigger a refresh for the MoneySummarySection (same as TransactionsGrid screen)
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if(queryParams.has('refreshTransactionGrid')){
            setDataChangeCount(dataChangeCount + 1);

            queryParams.delete('refreshTransactionGrid');
            history.replace({ search: queryParams.toString() });
        }
    }, [location.search]);

    const titleOnClick = () =>{
        let url = propertyId ? `/dashboard/property/${propertyId}/financials/income` : `/dashboard/financials/transactions/income`;
        history.push(url);
    }

    return (
        <div>
            <SectionHeader id={id} header={header} hideOnClick={hideOnClick} titleOnClick={titleOnClick} />
            <S.SectionDiv onClick={(e: any) => e.stopPropagation()}>
                <MoneySummary 
                    propertyId={propertyId} 
                    width={"100%"}
                    forceUpdateCounter={dataChangeCount}
                    transactionType='income'
                />
            </S.SectionDiv>
        </div>
    )
}

export default ActiveIncomeSection;