import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { withRouter } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppFeaturesItems } from "../../../configuration/account/account.config";
import tickIcon from "../../../images/account/ic_check_24px.svg";
import logo from "../../../images/rent_chief_logo.svg";
import { RootState } from "../../../reducers/rootReducer";
import { IReactRouterProps } from "../../../types";
import { IClientData } from "../../../types/ClientService";
import AcceptInvitationForm from "../../molecules/AcceptInvitationForm";
import ChangePasswordForm from "../../molecules/ChangePasswordForm";
import ForgottenPasswordForm from "../../molecules/ForgottenPasswordForm";
import LoginForm from "../../molecules/LoginForm";
import RegisterForm from "../../molecules/RegisterForm";
import WhitelabelRegisterForm from "../WhitelabelRegisterForm";
import { updateAddPropertyRequest } from "../../../actions/propertyActions";
import styles from "./AccountPage.module.scss";
import { removeURLParameter } from "../../../helpers/locationHelpers";
const queryString = require("query-string");

interface IProps {
  client: IClientData;
  add_property_request: any;
  updateAddPropertyRequest(data: any): void;
}

type Props = IProps & IReactRouterProps;

const AccountPage = (props: Props) => {
  useEffect(() => {
    if (!props.location.search) return;

    if (queryString.parse(props.location.search).addProperty != null) {
      let remove = removeURLParameter(props.location.search, "addProperty");
      props.history.push({
        search: remove,
      });
      props.updateAddPropertyRequest(
        JSON.parse(queryString.parse(props.location.search).addProperty)
      );

      let hasLoggedInBefore = localStorage.getItem("rc-previous-login");
      if (hasLoggedInBefore) {
        props.history.push("/account/login");
      } else {
        props.history.push("/account/register");
      }
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.mainRow}>
        <div className={styles.eLeft}>
          <div className={styles.infoBox}>
            <ul>
              {AppFeaturesItems &&
                AppFeaturesItems.map((e) => (
                  <li>
                    <img src={tickIcon} alt={e} />
                    {e}
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className={styles.left}></div>

        <div className={styles.right}>
          <div className={styles.row}>
            <div className={styles.column}>
              <img
                className={styles.logo}
                src={
                  props.client && props.client.logo ? props.client.logo : logo
                }
              />
            </div>
          </div>
          <div>
            <Switch>
              <Route
                {...props.location}
                path={`${props.match.path}/login/:userId?/:activationCode?`}
                component={LoginForm}
              />
              <Route
                {...props.location}
                path={`${props.match.path}/register`}
                component={RegisterForm}
              />
              <Route
                path={`${props.match.path}/registerwl`}
                component={WhitelabelRegisterForm}
              />
              <Route
                path={`${props.match.path}/forgotten-password`}
                component={ForgottenPasswordForm}
              />
              <Route
                path={`${props.match.path}/invitation`}
                component={AcceptInvitationForm}
              />
              <Route
                path={`${props.match.path}/password/:userId?/:token?`}
                component={ChangePasswordForm}
              />
              <Route
                {...props.location}
                path={`${props.match.path}`}
                component={LoginForm}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  client: state.client,
  add_property_request: state.property.add_property_request,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      updateAddPropertyRequest,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountPage)
);
