import React, { Component } from 'react'
import styles from './GoogleMap.module.scss';
import Geocode from 'react-geocode';
import { IAddress } from '../../../types/PropertyService/IPropertyService';
import { GmapAPI } from '../../../configuration/appConfig';

import semiDetache from '../../../images/icons/property-detail/Semi_Detached.png';

declare var google: any;

interface IProps {
  propertyName?: string;
  address?: IAddress;
  fullHeight?: boolean;

  latitude?: number;
  longitude?: number;
}

interface Cordinates {
  lat: string;
  lang: string;

}
interface IState {
  coordinates: Cordinates
}

class GoogleMap extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      coordinates: {
        lat: '',
        lang: ''
      }
    }

    this.onMapCreated = this.onMapCreated.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);
    this.onClick = this.onClick.bind(this);
    this.getCordinates = this.getCordinates.bind(this);
    this.initializeMap = this.initializeMap.bind(this);
  }

  getCordinates() {
    Geocode.setApiKey(GmapAPI);
    Geocode.enableDebug();
    Geocode.fromAddress(this.props.address.line1 + "+" + this.props.address.line1 + "+" + this.props.address.town + "+" + this.props.address.postcode).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        this.initializeMap(lat, lng);
      },
      error => {
        console.error(error);
      }
    );
  }

  onMapCreated(map) {
    map.setOptions({
      disableDefaultUI: true
    });
  }

  onDragEnd(e) {
  }

  onCloseClick() {
  }

  onClick(e) {
  }

  componentDidMount() {
    if (this.props.longitude && this.props.latitude) {
      this.initializeMap(this.props.latitude, this.props.longitude);
      return;
    }

    this.getCordinates()
  }

  componentWillReceiveProps(newProps: IProps): void {
    if (newProps.address != this.props.address) {
      this.getCordinates();
    }
  }


  initializeMap = (lat: number, lng: number) => {
    var latLng = new google.maps.LatLng(lat, lng);

    var mapOptions = {
      center: latLng,
      zoom: 14,
      fullScreenControl: false,
      streetViewControl: false
    }

    if (!this.refs.map) {
      return;
    }

    var map = new google.maps.Map(this.refs.map, mapOptions);

    var locations = [
      ['DESCRIPTION', lat, lng, 3],
    ];

    var bounds = new google.maps.LatLngBounds();

    for (var i = 0; i < locations.length; i++) {
      var marker = new google.maps.Marker({
        position: new google.maps.LatLng(locations[i][1], locations[i][2]),
        map: map,
        // icon: semiDetache
      });

      //extend the bounds to include each marker's position
      bounds.extend(marker.position);

      //google.maps.event.addListener(marker, 'click', (function (marker, i) {
      //  return function () {
      //    infowindow.setContent(locations[i][0]);
      //    infowindow.open(map, marker);
      //  }
      //})(marker, i));

      map.fitBounds(bounds);

      //(optional) restore the zoom level after the map is done scaling
      var listener = google.maps.event.addListener(map, "idle", function () {
        map.setZoom(14);
        google.maps.event.removeListener(listener);
      });
    }
  }

  render() {
    return (
      <div ref="map" id="map" className={this.props.fullHeight ? styles.gmapFullHeight : styles.gmap}></div>
    )
  }
}
export default GoogleMap;