import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { Col } from 'reactstrap'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import {
    clearAsset,
    deleteAssetImage,
    getAsset,
    getAssetImages,
    toggleDeleteAssets,
} from '../../../actions/assetsActions'
import { updateAlertMessage } from '../../../helpers/alertHelpers'
import {
    addURLParameter,
    removeURLParameter,
} from '../../../helpers/locationHelpers'
import { toggleAddDocumentPopup } from '../../../helpers/sidebarHelpers'
import { processToast } from '../../../helpers/toastHelper'
import documents from '../../../images/icons/documents.png'
import auditIcon from '../../../images/icons/icon_timeline.svg'
import menuIssues from '../../../images/icons/menuicon_issues.svg'
import actionIcon from '../../../images/icons/property-action/Action.png'
import detailsIcon from '../../../images/icons/user-management/usermanagement_details.svg'
import { RootState } from '../../../reducers/rootReducer'
import { changePrimaryImage } from '../../../services/ImageService'
import { IDataResponse } from '../../../types/ApiService'
import { IAssetAddEdit, IAssetDto } from '../../../types/Assets'
import { ImageTypeEnum } from '../../../types/ImageService'
import { IRaiseIssueOptions } from '../../atoms/AddEditIssue'
import Breadcrumbs from '../../atoms/Breadcrumbs'
import IconButton from '../../atoms/Buttons/IconButton'
import GenericButton from '../../atoms/GenericButton'
import RotatingLoader from '../../atoms/RotatingLoader'
import SFAssetDropdown from '../../atoms/SFDropdown/SFAssetDropdown'
import SimpleRouterTabs from '../../atoms/SimpleRouterTabs'
import TabbedNavigationContainer from '../../atoms/TabbedNavigationContainer'
import { IDocumentUploadOptions } from '../../molecules/AddDocumentModal'
import AssetAddEditForm from '../AssetAddEditForm'
import DocumentsPage from '../DocumentsPage'
import IssueDetail from '../IssueDetail'
import IssueListPage from '../IssueListPage'
import AuditPage from '../PlatformManagementPage/AuditPage'
import styles from './AssetDetails.module.scss'
const queryString = require('query-string')

interface IProps {
    history: any
    match: any
    location: any

    onUpdateReturnUrl?: string
    viewing_asset?: IAssetDto
    loading?: boolean
    error?: string

    getAsset?: any
    clearAsset(): void
    getAssetImages(assetId: number): void
    deleteAssetImage(imageId: string): void
    updateAsset?: any
    toggleDeleteAssets?: any
}

const AssetDetails = (props: IProps) => {
    const [options, setOptions] = useState<IAssetAddEdit>(null)
    const [uploadImages, setUploadImages] = useState<boolean>(false)
    const [returnUrl, setReturnUrl] = useState<string>(null)

    useEffect(() => {
        let options = queryString.parse(props.location.search).assetsOptions
        if (options) {
            let json: IAssetAddEdit = JSON.parse(options)
            if (!json) {
                return
            }

            setOptions(json)

            let removeQuery = removeURLParameter(
                props.location.search,
                'assetsOptions'
            )
            props.history.replace({
                search: removeQuery,
            })

            if (json.referer == 'property' && json.propertyId) {
                setReturnUrl(
                    `/dashboard/property/${json.propertyId}/spec/assets`
                )
            }
        }

        if (!props.viewing_asset && props.match.params.assetId) {
            props.getAsset(props.match.params.assetId)
        }

        return () => props.clearAsset()
    }, [])

    const onAddIssueClick = () => {
        const { assetId, propertyId, propertyName, unitId, unitName } =
            props.viewing_asset

        let options: IRaiseIssueOptions = {
            assetId,
            propertyId,
            propertyName,
            unitId,
            unitName,
        }

        let query = 'issueOptions=' + JSON.stringify(options)
        let queryParam = addURLParameter(props.location.search, query)

        props.history.replace({
            search: queryParam,
        })
    }

    const onChangePrimaryImage = (imageId: string) => {
        const { assetId } = props.viewing_asset

        changePrimaryImage({
            imageId,
            parentId: assetId,
            imageType: ImageTypeEnum.ASSET_IMAGE,
        }).then((resp) => {
            if (!resp || resp.status != 200) {
                return
            }

            let response: IDataResponse<string> = resp.data
            processToast(response)
            props.getAssetImages(assetId)
        })
    }

    const onAddDocument = () => {
        let options: IDocumentUploadOptions = {
            assetId: props.viewing_asset.assetId,
        }

        if (props.viewing_asset.propertyId) {
            options.propertyId = props.viewing_asset.propertyId
            options.propertyName = props.viewing_asset.propertyName
        }

        if (props.viewing_asset.unitId) {
            options.unitId = props.viewing_asset.unitId
            options.unitName = props.viewing_asset.unitName
        }

        let query = 'uploadDocumentOptions=' + JSON.stringify(options)

        let search = addURLParameter(props.location.search, query)

        props.history.push({
            search,
        })

        toggleAddDocumentPopup(true)
    }

    const Header = () => (
        <div className={styles.headerBar}>
            <div className={styles.breadcrumbs}>
                <Breadcrumbs
                    breadcrumbs={[
                        {
                            name: props.viewing_asset.propertyName
                                ? props.viewing_asset.propertyName
                                : 'Property',
                            route: props.viewing_asset.propertyId
                                ? `/dashboard/property/${props.viewing_asset.propertyId}`
                                : '/dashboard/properties',
                            ordinal: 1,
                        },
                        {
                            name: 'ASSETS',
                            route: props.viewing_asset.propertyId
                                ? `/dashboard/property/${props.viewing_asset.propertyId}/spec/assets`
                                : '/dashboard/assets',
                            ordinal: 2,
                        },
                        ...(props.viewing_asset.assetTypeLevel1Name
                            ? [
                                  {
                                      name: `${props.viewing_asset.assetTypeLevel1Name} - ${props.viewing_asset.assetTypeLevel2Name}`,
                                      ordinal: 3,
                                  },
                              ]
                            : []),
                    ]}
                />
            </div>
            <div className={styles.actionMenu}>
                <SFAssetDropdown
                    toggle={
                        <IconButton
                            button={{
                                displayType: 'action',
                                elementType: 'button',
                                icon: 'action',
                            }}
                        />
                    }
                    asset={props.viewing_asset}
                    assetId={props.viewing_asset.assetId}
                    updateRedirectUrl={`/dashboard/asset/view/1234`}
                    onDelete={() =>
                        props.toggleDeleteAssets(
                            [props.viewing_asset.assetId],
                            true,
                            returnUrl || `/dashboard/assets`
                        )
                    }
                />
            </div>
        </div>
    )

    const Navigation = () => (
        <div className={styles.navigation}>
            <TabbedNavigationContainer fullWidth>
                <Col className={styles.tabs}>
                    <SimpleRouterTabs
                        routes={[
                            {
                                path: props.match.url + '/asset',
                                text: 'Asset',
                                mobileText: 'Asset',
                                icon: detailsIcon,
                            },
                            {
                                path: props.match.url + '/documents',
                                text: 'Docs',
                                mobileText: 'Docs',
                                icon: documents,
                            },
                            {
                                path: props.match.url + '/issues',
                                text: 'Issues',
                                mobileText: 'Issues',
                                icon: menuIssues,
                            },
                            {
                                path: props.match.url + '/timeline',
                                text: 'Timeline',
                                mobileText: 'Timeline',
                                icon: auditIcon,
                            },
                        ]}
                    />
                </Col>
            </TabbedNavigationContainer>
        </div>
    )

    if (props.error) {
        updateAlertMessage(props.error, true, 3000, true)
        return <div />
    }
    if (props.loading || !props.viewing_asset) {
        return (
            <div className={styles.loader}>
                <RotatingLoader loading={true} text="Loading asset..." />
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <Header />
            <Navigation />

            <div className={styles.content}>
                <Switch>
                    <Route
                        path={`${props.match.url}`}
                        exact
                        component={({ location }) => (
                            <Redirect
                                to={{
                                    ...location,
                                    pathname: `${props.match.url}/asset`,
                                }}
                            />
                        )}
                    />

                    <Route
                        path={`${props.match.path}/asset`}
                        exact
                        render={() => (
                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <AssetAddEditForm
                                        asset={props.viewing_asset}
                                        assetOptions={options}
                                    />
                                </div>
                            </div>
                        )}
                    />

                    <Route
                        path={`${props.match.path}/documents`}
                        exact
                        render={() => (
                            <div className={styles.whiteBg}>
                                <DocumentsPage
                                    assetId={props.viewing_asset.assetId}
                                    onAddDocumentClick={onAddDocument}
                                />
                            </div>
                        )}
                    />

                    <Route
                        path={`${props.match.path}/issue/:issueId?`}
                        render={() => (
                            <div className={styles.whiteBg}>
                                <IssueDetail />
                            </div>
                        )}
                    />

                    <Route
                        path={`${props.match.path}/issues/:issueId?`}
                        render={() => (
                            <div className={styles.whiteBg}>
                                <IssueListPage
                                    onAddIssue={onAddIssueClick}
                                    assetId={props.viewing_asset.assetId}
                                    isNested={true}
                                />
                            </div>
                        )}
                    />

                    <Route
                        path={`${props.match.path}/timeline`}
                        exact
                        render={() => (
                            <div className={styles.whiteBg}>
                                <AuditPage
                                    assetId={props.viewing_asset.assetId}
                                    hideDropdown
                                />
                            </div>
                        )}
                    />
                </Switch>
            </div>
        </div>
    )
}

const mapStateToProps = (state: RootState) => ({
    viewing_asset: state.assets.viewing_asset,
    loading: state.assets.loading,
    error: state.assets.error,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getAsset,
            getAssetImages,
            clearAsset,
            toggleDeleteAssets,
            deleteAssetImage,
        },
        dispatch
    )

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AssetDetails)
)
