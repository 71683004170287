import React from 'react';
import SectionHeader from "../SectionHeader/SectionHeader.component"
import * as S from "./InitialStateSection.styles";

interface sectionProps {
    id?: string;
    header?: string;
    iconUrl: string;
    title: string;
    summary: string;
    action?: JSX.Element | JSX.Element[];
    hideOnClick?(id: string): void;
    titleOnClick?(): void;
    addOnClick?(): void;
    imageSize?: string;
    showHeader?: boolean;
}

const InitialStateSection = ({id, header, iconUrl, title, summary, action, hideOnClick, titleOnClick, addOnClick, imageSize, showHeader=true}: sectionProps) => {

    const innerBody =
        <>
            <S.IconDiv>
                <S.IconImage imageSize={imageSize} src={iconUrl}></S.IconImage>
            </S.IconDiv>
            <S.BodyRightDiv>
                <S.TitleDiv>{title}</S.TitleDiv>
                <S.SummaryDiv>{summary}</S.SummaryDiv>
                <S.ActionDiv>{action ? action : ""}</S.ActionDiv>
            </S.BodyRightDiv>
        </>

    return (
        showHeader
        ?
        <S.SectionDiv>
            <SectionHeader id={id} header={header} hideOnClick={hideOnClick} titleOnClick={titleOnClick} addOnClick={addOnClick} />
            {innerBody}
        </S.SectionDiv>
        :
        <S.SectionNoHeaderDiv>
            {innerBody}
        </S.SectionNoHeaderDiv>
    )
}

export default InitialStateSection;