import React from 'react'
import { withRouter } from 'react-router-dom'
import { Formik, Form, ErrorMessage } from 'formik'
import SearchContactInput from '../SearchContactInput'
import SearchPropertyInput from '../SearchPropertyInput'
import SelectDropdown, { IOptions } from '../SelectDropdown'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styles from './AddEditInspection.module.scss'
import {
    getLoggedInUsersContactId,
    getLoggedInUsersForename,
    getLoggedInUsersSurname,
    getLoggedInUsersUsertId
} from '../../../helpers/profileHelpers'
import { removeURLParameter } from '../../../helpers/locationHelpers'
import Breadcrumbs from '../Breadcrumbs'
import {
    addInspection,
    getChecklistTypes,
    getInspection,
    IAddInspectionRequest,
    updateInspection,
} from '../../../services/inspectionService'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { AddInspection } from '../../../YupValidationSchemas'
import RotatingLoader from '../RotatingLoader'
import { processToast } from '../../../helpers/toastHelper'
import { IInspectionDto } from '../../../types/Inspection'
import { IAddTenantCallback } from '../../molecules/AddContactForm'
import ClientAreaRestriction from '../ClientAreaRestriction'
import Modal from 'react-responsive-modal'
import Buttons from '../Buttons'
import { IConductInspectionOptions } from '../../../types/ConductInspection'
import { updateViewingInspection } from '../../../helpers/inspectionHelpers'
import lodashObject from 'lodash/fp'
import { hasClientArea } from '../../../helpers/clientHelpers'
import { canAccessPropertyDetails } from '../../../helpers/permissionHelpers'
const queryString = require('query-string')

interface IProps {
    history: any
    location: any
    match: any

    inspectionId?: number
    inspection?: IInspectionDto
    hideBreadcrumbs?: boolean
}

interface IState {
    selectedInspectionType: string
    selectedPropertyId: string
    selectedPropertyName: string
    inspectionName: string
    inspectionDateAndTime: Date
    selectedInspectorId: string
    selectedInspectorName: string
    selectedTenantId: string
    selectedTenantName: string
    description: string
    signatureRequiredInspector: boolean
    signatureRequiredTenant: boolean
    checklistTypes: Array<IOptions>
    loading: boolean

    hasChanges: boolean
    returnUrl?: string
    outstandingChangesModalOpen: boolean
    conducting?: boolean
    hasInspectionResultOpen: boolean
    hasInspectionResult: boolean

    isReadOnly: boolean
}

export interface IAddEditInspection {
    propertyId?: string
    propertyName?: string
    returnUrl?: string
}

let initialState: any = {
    selectedInspectionType: null,
    selectedPropertyId: null,
    selectedPropertyName: null,
    inspectionName: null,
    inspectionDateAndTime: new Date(),
    selectedInspectorId: getLoggedInUsersContactId(),
    selectedInspectorName: `${getLoggedInUsersForename()} ${getLoggedInUsersSurname()}`,
    selectedTenantId: null,
    selectedTenantName: null,
    description: null,
    signatureRequiredInspector: false,
    signatureRequiredTenant: false,
    isReadOnly: false
}

class AddEditInspection extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            ...initialState,
            checklistTypes: [],
            loading: false,
            hasChanges: false,
            outstandingChangesModalOpen: false,
            returnUrl: null,
            conducting: false,
            hasInspectionResultOpen: false,
            hasInspectionResult: false,
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.checkForChanges = this.checkForChanges.bind(this)
        this.onCloseChangesModal = this.onCloseChangesModal.bind(this)
        this.onCancel = this.onCancel.bind(this)
    }

    onClickConduct = () => {
        let obj: IConductInspectionOptions = {
            redirectUrl: this.props.location.pathname,
        }
        let params = JSON.stringify(obj)

        if (this.props.inspectionId) {
            this.props.history.push(
                `/dashboard/inspection/view/${this.props.inspectionId}/conduct${
                    params ? `?conductInspectionOptions=${params}` : ''
                }`
            )
            return
        }
    }

    onCancel(): void {
        if (this.state.hasChanges) {
            this.setState({
                outstandingChangesModalOpen: true,
            })
            return
        }

        if (this.state.returnUrl) {
            this.props.history.push(this.state.returnUrl)
            return
        }

        if (this.state.selectedPropertyId && canAccessPropertyDetails()) {
            this.props.history.push(
                `/dashboard/property/${this.state.selectedPropertyId}/spec/inspections`
            )
            return
        } else {
            this.props.history.push('/dashboard/inspections')
        }
    }

    onCloseInspectionResultModal(submitCancel: boolean, submitFn?: any): void {
        if (submitCancel) {
            this.setState(
                {
                    hasInspectionResultOpen: false,
                },
                () => {
                    if (submitFn) {
                        var el = document.getElementById('html')

                        if (!el) {
                            return
                        }

                        el.style.overflow = 'unset'
                        el.style.position = 'unset'
                        el.style.width = 'unset'
                        submitFn()
                    }
                }
            )
        } else {
            this.setState({
                hasInspectionResultOpen: false,
            })
        }
    }

    onCloseChangesModal(submitCancel: boolean): void {
        this.setState({
            outstandingChangesModalOpen: false,
        })

        if (submitCancel) {
            this.setState(
                {
                    hasChanges: false,
                },
                () => this.onCancel()
            )
        }
    }

    checkForChanges(): void {
        if (this.state != initialState) {
            this.setState({
                hasChanges: true,
            })
        }
    }

    componentWillReceiveProps(newProps: IProps): void {
        let next = queryString.parse(
            newProps.location.search
        ).addContactCallback

        if (next) {
            let data: IAddTenantCallback = JSON.parse(next)

            if (
                !this.state.selectedTenantId ||
                data.contactId != this.state.selectedTenantId
            ) {
                this.setState({
                    selectedTenantId: data.contactId,
                    selectedTenantName: data.name,
                })

                let query = removeURLParameter(
                    this.props.location.search,
                    'addContactCallback'
                )
                this.props.history.push({
                    search: query,
                })
            }
        }
    }

    componentDidMount(): void {
        this.setState({
            loading: true,
        })

        getChecklistTypes().then((resp) => {
            if (resp && resp.status == 200) {
                this.setState({
                    checklistTypes: resp.data,
                })
            }
        })

        if (this.props.inspection) {
            const { inspection } = this.props

            this.setState({
                inspectionName: inspection.inspectionName,
                selectedInspectionType: inspection.inspectionTypeId,
                description: inspection.description,
                selectedInspectorId: inspection.inspectorId,
                selectedInspectorName: inspection.inspectorName,
                inspectionDateAndTime: inspection.inspectionDateAndTime,
                selectedPropertyId: inspection.propertyId,
                selectedPropertyName: inspection.propertyName,
                selectedTenantId: inspection.propertyOccupierId,
                selectedTenantName: inspection.propertyOccupierName,
                signatureRequiredInspector:
                    inspection.signatureRequiredInspector,
                signatureRequiredTenant: inspection.signatureRequiredTenant,
                hasInspectionResult: inspection.hasInspectionResult,
                isReadOnly: getLoggedInUsersUsertId() != inspection.createdBy
            })

            this.setState({
                loading: false,
            })

            return
        }

        if (this.props.match.params.inspectionId) {
            getInspection(this.props.match.params.inspectionId).then((resp) => {
                if (resp.data && resp.status == 200) {
                    let data: IInspectionDto = resp.data.data
                    this.setState({
                        inspectionName: data.inspectionName,
                        selectedInspectionType: data.inspectionTypeId,
                        description: data.description,
                        selectedInspectorId: data.inspectorId,
                        selectedInspectorName: data.inspectorName,
                        inspectionDateAndTime: data.inspectionDateAndTime,
                        selectedPropertyId: data.propertyId,
                        selectedPropertyName: data.propertyName,
                        selectedTenantId: data.propertyOccupierId,
                        selectedTenantName: data.propertyOccupierName,
                        signatureRequiredInspector:
                            data.signatureRequiredInspector,
                        signatureRequiredTenant: data.signatureRequiredTenant,
                    })
                }
            })

            this.setState({
                loading: false,
            })
        }

        let options = queryString.parse(
            this.props.location.search
        ).inspectionOptions
        if (options) {
            let json: IAddEditInspection = JSON.parse(options)
            if (!json) {
                return
            }

            if (json.propertyId && json.propertyName) {
                this.setState({
                    selectedPropertyId: json.propertyId,
                    selectedPropertyName: json.propertyName,
                })
            }

            if (json.returnUrl) {
                this.setState({
                    returnUrl: json.returnUrl,
                })
            }

            let removeQuery = removeURLParameter(
                this.props.location.search,
                'inspectionOptions'
            )
            this.props.history.push({
                search: removeQuery,
            })
        }

        this.setState({
            loading: false,
        })
    }

    generateInspectionName(type: string, propertyName: string): string {
        if (type && propertyName) {
            return `${type} for ${propertyName}`
        }

        if (type) {
            return `${type}`
        }

        if (propertyName) {
            return `Inspection for ${propertyName}`
        }
    }

    onSubmit(request: IAddInspectionRequest): void {
        this.setState({
            loading: true,
        })

        if (this.props.match.params.inspectionId) {
            updateInspection(
                this.props.match.params.inspectionId,
                request
            ).then((resp) => {
                if (resp.data && resp.status == 200) {
                    processToast(resp.data)
                    let data: IInspectionDto = resp.data.data
                    updateViewingInspection(data)
                }

                this.setState({
                    loading: true,
                })
                if (this.state.conducting) {
                    this.props.history.push(
                        `/dashboard/inspection/view/${this.props.match.params.inspectionId}/conduct`
                    )
                    return
                }
                if (request.propertyId && canAccessPropertyDetails()) {
                    this.props.history.push(
                        `/dashboard/property/${request.propertyId}/spec/inspections`
                    )
                    return
                }
                this.props.history.push('/dashboard/inspections')
                return
            })
        } else {
            addInspection(request).then((resp) => {
                if (resp.data && resp.status == 200) {
                    processToast(resp.data)
                    this.setState({
                        loading: true,
                    })
                    if (this.state.conducting) {
                        this.props.history.push(
                            `/dashboard/inspection/view/${resp.data.data.inspectionId}/conduct`
                        )
                        return
                    }

                    if (this.state.returnUrl) {
                        this.props.history.push(this.state.returnUrl)
                        return
                    }

                    if (request.propertyId) {
                        this.props.history.push(
                            `/dashboard/property/${request.propertyId}/spec/inspections`
                        )
                        return
                    }
                    this.props.history.push('/dashboard/inspections')
                }
            })
        }
    }

    render() {
        return (
            <div className={styles.form}>
                <Modal
                    open={this.state.outstandingChangesModalOpen}
                    onClose={() => this.onCloseChangesModal(false)}
                    center
                >
                    <p className={styles.modalHeader}>Outstanding changes</p>
                    <p className={styles.modalBodyOutstandingChanges}>
                        Any updated information will be lost, do you wish to
                        continue?
                    </p>

                    <Buttons
                        buttons={[
                            {
                                text: 'No',
                                displayType: 'cancel',
                                elementType: 'button',
                                onClick: () => this.onCloseChangesModal(false),
                            },
                            {
                                text: 'Yes',
                                displayType: 'submit',
                                elementType: 'button',
                                onClick: () => this.onCloseChangesModal(true),
                            },
                        ]}
                    />
                </Modal>

                {!this.props.hideBreadcrumbs && (
                    <div className={styles.breadcrumbs}>
                        <Breadcrumbs
                            breadcrumbs={[
                                {
                                    name: `Properties`,
                                    route: `/dashboard/properties`,
                                    ordinal: 0,
                                },
                                ...(this.state.selectedPropertyId
                                    ? [
                                          {
                                              name: this.state
                                                  .selectedPropertyName
                                                  ? this.state
                                                        .selectedPropertyName
                                                  : 'No property name',
                                              route: `/dashboard/property/${this.state.selectedPropertyId}`,
                                              ordinal: 1,
                                          },
                                      ]
                                    : []),
                                {
                                    name: `Inspection`,
                                    ordinal: 2,
                                },
                            ]}
                        />
                    </div>
                )}

                <div className={styles.content}>
                    {this.state.loading ? (
                        <RotatingLoader loading />
                    ) : (
                        <Formik
                            initialValues={{
                                inspectionTypeId:
                                    this.state.selectedInspectionType,
                                inspectionTypeName: null,
                                propertyId: this.state.selectedPropertyId,
                                propertyName: this.state.selectedPropertyName,
                                inspectionName: this.state.inspectionName,
                                inspectionDateAndTime: new Date(
                                    this.state.inspectionDateAndTime
                                ),
                                inspectorId: this.state.selectedInspectorId,
                                signatureRequiredInspector:
                                    this.state.signatureRequiredInspector,
                                signatureRequiredTenant:
                                    this.state.signatureRequiredTenant,
                                description: this.state.description,
                                inspectorName: this.state.selectedInspectorName,
                                tenantId: this.state.selectedTenantId,
                                tenantName: this.state.selectedTenantName,
                            }}
                            enableReinitialize={true}
                            validationSchema={AddInspection}
                            onSubmit={(values: any) => {
                                this.onSubmit({
                                    inspectionName: values.inspectionName,
                                    inspectionType: values.inspectionTypeId,
                                    propertyId: values.propertyId,
                                    inspectionDateAndTime:
                                        values.inspectionDateAndTime,
                                    inspectorId: values.inspectorId,
                                    tenantId: values.tenantId,
                                    signatureRequiredInspector:
                                        values.signatureRequiredInspector,
                                    signatureRequiredTenant:
                                        values.signatureRequiredTenant,
                                    description: values.description,
                                })
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <Modal
                                        open={
                                            this.state.hasInspectionResultOpen
                                        }
                                        onClose={() =>
                                            this.onCloseInspectionResultModal(
                                                false
                                            )
                                        }
                                        center
                                    >
                                        <p className={styles.modalHeader}>
                                            Inspection Result
                                        </p>
                                        <p
                                            className={
                                                styles.modalBodyOutstandingChanges
                                            }
                                        >
                                            You have changed the Inspection Type
                                            but have previously conducted an
                                            Inspection. If you change the type,
                                            your previously conducted inspection
                                            will be removed.
                                        </p>

                                        <Buttons
                                            buttons={[
                                                {
                                                    text: 'No',
                                                    displayType: 'cancel',
                                                    elementType: 'button',
                                                    onClick: () =>
                                                        this.onCloseInspectionResultModal(
                                                            false
                                                        ),
                                                },
                                                {
                                                    text: 'Yes',
                                                    displayType: 'submit',
                                                    elementType: 'button',
                                                    onClick: () =>
                                                        this.onCloseInspectionResultModal(
                                                            true,
                                                            props.submitForm
                                                        ),
                                                },
                                            ]}
                                        />
                                    </Modal>

                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            <div className={styles.label}>
                                                Inspection Type
                                            </div>
                                            <SelectDropdown
                                                selectedId={
                                                    props.values
                                                        .inspectionTypeId
                                                }
                                                data={this.state.checklistTypes}
                                                placeholder="Select an inspection type..."
                                                onSelect={(id: IOptions) => {
                                                    props.setFieldValue(
                                                        'inspectionTypeId',
                                                        id.value
                                                    )
                                                    props.setFieldValue(
                                                        'inspectionTypeName',
                                                        id.label
                                                    )
                                                    props.setFieldValue(
                                                        'description',
                                                        this.generateInspectionName(
                                                            id.label,
                                                            props.values
                                                                .propertyName
                                                        )
                                                    )
                                                    props.setFieldValue(
                                                        'inspectionName',
                                                        this.generateInspectionName(
                                                            id.label,
                                                            props.values
                                                                .propertyName
                                                        )
                                                    )
                                                    this.checkForChanges()
                                                }}
                                                disabled={this.state.isReadOnly}
                                            />
                                            <span
                                                className={styles.errorMessage}
                                            >
                                                <ErrorMessage
                                                    className={
                                                        styles.errorMessage
                                                    }
                                                    name="inspectionTypeId"
                                                />
                                            </span>
                                        </div>
                                        <div className={styles.column}>
                                            <div className={styles.label}>
                                                Property
                                            </div>
                                            <SearchPropertyInput
                                                selectedPropertyId={
                                                    props.values.propertyId
                                                }
                                                selectedPropertyName={
                                                    props.values.propertyName
                                                }
                                                onSelect={(
                                                    selected: IOptions
                                                ) => {
                                                    props.setFieldValue(
                                                        'propertyId',
                                                        selected.value
                                                    )
                                                    props.setFieldValue(
                                                        'propertyName',
                                                        selected.label
                                                    )
                                                    props.setFieldValue(
                                                        'description',
                                                        this.generateInspectionName(
                                                            props.values
                                                                .inspectionTypeName,
                                                            selected.label
                                                        )
                                                    )
                                                    props.setFieldValue(
                                                        'inspectionName',
                                                        this.generateInspectionName(
                                                            props.values
                                                                .inspectionTypeName,
                                                            selected.label
                                                        )
                                                    )
                                                    this.checkForChanges()
                                                }}
                                                onClear={() => {
                                                    props.setFieldValue(
                                                        'description',
                                                        this.generateInspectionName(
                                                            props.values
                                                                .inspectionTypeName,
                                                            null
                                                        )
                                                    )
                                                    props.setFieldValue(
                                                        'inspectionName',
                                                        this.generateInspectionName(
                                                            props.values
                                                                .inspectionTypeName,
                                                            null
                                                        )
                                                    )
                                                    props.setFieldValue(
                                                        'propertyId',
                                                        null
                                                    )
                                                    this.checkForChanges()
                                                }}
                                                disableClear={this.state.isReadOnly}
                                            />
                                            <span
                                                className={styles.errorMessage}
                                            >
                                                <ErrorMessage
                                                    className={
                                                        styles.errorMessage
                                                    }
                                                    name="propertyId"
                                                />
                                            </span>
                                        </div>
                                    </div>

                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            <div className={styles.label}>
                                                Inspection Name
                                            </div>
                                            <input
                                                value={
                                                    props.values.inspectionName
                                                }
                                                className={styles.input}
                                                onChange={(e) =>
                                                    props.setFieldValue(
                                                        'inspectionName',
                                                        e.currentTarget.value
                                                    )
                                                }
                                                placeholder="Inspection name..."
                                                disabled={this.state.isReadOnly}
                                            />
                                            <span
                                                className={styles.errorMessage}
                                            >
                                                <ErrorMessage
                                                    className={
                                                        styles.errorMessage
                                                    }
                                                    name="inspectionName"
                                                />
                                            </span>
                                        </div>

                                        <div className={styles.column}>
                                            <div className={styles.label}>
                                                Inspection Date and Time
                                            </div>
                                            <DatePicker
                                                showTimeSelect
                                                todayButton="Today"
                                                dateFormat="dd/MM/yyyy HH:mm"
                                                className={styles.input}
                                                selected={
                                                    props.values
                                                        .inspectionDateAndTime ||
                                                    null
                                                }
                                                onChange={(date: Date) => {
                                                    props.setFieldValue(
                                                        'inspectionDateAndTime',
                                                        date
                                                    )
                                                    this.checkForChanges()
                                                }}
                                                readOnly={this.state.isReadOnly}
                                            />
                                            <span
                                                className={styles.errorMessage}
                                            >
                                                <ErrorMessage
                                                    className={
                                                        styles.errorMessage
                                                    }
                                                    name="inspectionDateAndTime"
                                                />
                                            </span>
                                        </div>
                                    </div>

                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            <div className={styles.row}>
                                                <div className={styles.column}>
                                                    <div
                                                        className={styles.label}
                                                    >
                                                        Inspector
                                                    </div>
                                                    <SearchContactInput
                                                        onAdd={() => null}
                                                        allowNewContact
                                                        newContactType="Contact"
                                                        onClear={() => {
                                                            props.setFieldValue(
                                                                'inspectorName',
                                                                null
                                                            )
                                                            props.setFieldValue(
                                                                'inspectorId',
                                                                null
                                                            )
                                                            this.checkForChanges()
                                                        }}
                                                        selectedId={
                                                            props.values
                                                                .inspectorId
                                                        }
                                                        selectedName={
                                                            props.values
                                                                .inspectorName
                                                        }
                                                        onSelect={(option) => {
                                                            props.setFieldValue(
                                                                'inspectorName',
                                                                option.label
                                                            )
                                                            props.setFieldValue(
                                                                'inspectorId',
                                                                option.value
                                                            )
                                                            this.checkForChanges()
                                                        }}
                                                        disableClear={this.state.isReadOnly}
                                                    />
                                                    <span
                                                        className={
                                                            styles.errorMessage
                                                        }
                                                    >
                                                        <ErrorMessage
                                                            className={
                                                                styles.errorMessage
                                                            }
                                                            name="inspectorId"
                                                        />
                                                    </span>
                                                    <div>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={
                                                                        props
                                                                            .values
                                                                            .signatureRequiredInspector
                                                                    }
                                                                    color="primary"
                                                                    onChange={() =>
                                                                        props.setFieldValue(
                                                                            'signatureRequiredInspector',
                                                                            !props
                                                                                .values
                                                                                .signatureRequiredInspector
                                                                        )
                                                                    }
                                                                    disabled={this.state.isReadOnly}
                                                                />
                                                            }
                                                            label="Signature required to complete inspection"
                                                        />
                                                    </div>
                                                </div>
                                                <div className={styles.column}>
                                                    <div
                                                        className={styles.label}
                                                    >
                                                        Tenant/Occupier
                                                    </div>
                                                    <SearchContactInput
                                                        onAdd={() => null}
                                                        allowNewContact
                                                        newContactType="Contact"
                                                        contactType={[
                                                            '921CCFFF-7386-400D-804C-B638121A75D2',
                                                        ]}
                                                        onClear={() => {
                                                            props.setFieldValue(
                                                                'tenantName',
                                                                null
                                                            )
                                                            props.setFieldValue(
                                                                'tenantId',
                                                                null
                                                            )
                                                            this.checkForChanges()
                                                        }}
                                                        selectedId={
                                                            props.values
                                                                .tenantId
                                                        }
                                                        selectedName={
                                                            props.values
                                                                .tenantName
                                                        }
                                                        onSelect={(option) => {
                                                            props.setFieldValue(
                                                                'tenantName',
                                                                option.label
                                                            )
                                                            props.setFieldValue(
                                                                'tenantId',
                                                                option.value
                                                            )
                                                            this.checkForChanges()
                                                        }}
                                                        disableClear={this.state.isReadOnly}
                                                    />
                                                    <div>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={
                                                                        props
                                                                            .values
                                                                            .signatureRequiredTenant
                                                                    }
                                                                    color="primary"
                                                                    onChange={() =>
                                                                        props.setFieldValue(
                                                                            'signatureRequiredTenant',
                                                                            !props
                                                                                .values
                                                                                .signatureRequiredTenant
                                                                        )
                                                                    }
                                                                    disabled={this.state.isReadOnly}
                                                                />
                                                            }
                                                            label="Signature required to complete inspection"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            <div className={styles.label}>
                                                Description
                                            </div>
                                            <textarea
                                                rows={4}
                                                value={props.values.description}
                                                onChange={(e: any) => {
                                                    props.setFieldValue(
                                                        'description',
                                                        e.currentTarget.value
                                                    )
                                                    this.checkForChanges()
                                                }}
                                                className={styles.textarea}
                                                placeholder="Description..."
                                                disabled={this.state.isReadOnly}
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.buttons}>
                                        <Buttons
                                            buttons={[
                                                {
                                                    displayType: 'cancel',
                                                    elementType: 'button',
                                                    onClick: () =>
                                                        this.onCancel(),
                                                },
                                                {
                                                    hidden: !hasClientArea(
                                                        'ConductInspections'
                                                    ),
                                                    text: 'Conduct',
                                                    displayType: 'action',
                                                    elementType: 'button',
                                                    onClick: () => {
                                                        if (
                                                            lodashObject.isEqual(
                                                                props.initialValues,
                                                                props.values
                                                            )
                                                        ) {
                                                            this.onClickConduct()
                                                            return
                                                        }

                                                        this.setState(
                                                            {
                                                                conducting:
                                                                    true,
                                                            },
                                                            () => {
                                                                if (
                                                                    props.values
                                                                        .inspectionTypeId !=
                                                                        this
                                                                            .state
                                                                            .selectedInspectionType &&
                                                                    this.state
                                                                        .hasInspectionResult
                                                                ) {
                                                                    this.setState(
                                                                        {
                                                                            hasInspectionResultOpen:
                                                                                true,
                                                                        }
                                                                    )
                                                                } else {
                                                                    props.submitForm()
                                                                }
                                                            }
                                                        )
                                                    },
                                                },
                                                {
                                                    displayType: 'submit',
                                                    onClick: () => {
                                                        if (
                                                            props.values
                                                                .inspectionTypeId !=
                                                                this.state
                                                                    .selectedInspectionType &&
                                                            this.state
                                                                .hasInspectionResult
                                                        ) {
                                                            this.setState({
                                                                hasInspectionResultOpen:
                                                                    true,
                                                            })
                                                        } else {
                                                            props.submitForm()
                                                        }
                                                    },
                                                    elementType: 'button',
                                                    hidden: this.state.isReadOnly
                                                },
                                            ]}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                </div>
            </div>
        )
    }
}

export default withRouter(AddEditInspection)
