import {
    Aggregate,
    Column,
    ColumnMenu,
    CommandColumn,
    ContextMenu,
    ExcelExport,
    Filter,
    GridComponent,
    Group,
    GroupSettingsModel,
    Inject,
    Page,
    PdfExport,
    QueryCellInfoEventArgs,
    RecordClickEventArgs,
    Reorder,
    Resize,
    Sort,
} from '@syncfusion/ej2-react-grids'
import {
    ChangedEventArgs,
    TextBoxComponent,
} from '@syncfusion/ej2-react-inputs'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Router, withRouter } from 'react-router-dom'
import {
    filterOptions,
    pageSettings,
} from '../../../../configuration/grid_syncfusion_options'
import {
    addURLParameter,
    openInNewTab,
    removeURLParameter,
} from '../../../../helpers/locationHelpers'
import { isLandlord, isPlatformAdmin } from '../../../../helpers/roleHelpers'
import {
    toggleAddDocumentPopup,
    toggleEditDocumentPopup,
} from '../../../../helpers/sidebarHelpers'
import { updateToastMessage } from '../../../../helpers/toastHelper'
import history, { goToRoute } from '../../../../history'
import { getTypesForDropdown } from '../../../../services/EntityService'
import { IDocument } from '../../../../types/DocumentService'
import { ExportTypeEnum } from '../../../../types/Transaction'
import IconButton from '../../../atoms/Buttons/IconButton'
import SelectDropdown, { IOptions } from '../../../atoms/SelectDropdown'
import SFDropdown from '../../../atoms/SFDropdown'
import ShowOnlyMy from '../../../atoms/ShowOnlyMy'
import UserContactInfo, {
    UserContactInfoType,
} from '../../../atoms/UserContactInfo'
import { IDocumentUploadOptions } from '../../../molecules/AddDocumentModal'
import styles from './DocumentsGrid.module.scss'
import { editSettings, textWrapSettings } from './_config/columnOptions'
import { generateOdataSource } from './_config/dataConfig'
import { selectionSettings } from './_config/selectionConfig'
import { onDeleteRows } from './_helpers'
import { DefaultTemplate } from './_templates/defaultTemplate'
import { IDocumentsGridOptions } from './_types'
const queryString = require('query-string')

interface IState {
    loading?: boolean
    hasBeenGrouped: boolean
    search?: string
    startDate?: Date
    endDate?: Date
    selectedType?: string
    deletingUserIds: string[]
    deleting: boolean
    selectedDocumentType: any
    filterOptions: any[]
    onlyShowOwnRecords: boolean
}

interface IProps {
    config: IDocumentsGridOptions
    history: any
    location: any
    match: any
    dateRangeStart?: Date
    dateRangeEnd?: Date
}

class DocumentsGrid extends Component<IProps, IState> {
    public grid: any
    public topRowRef: any

    constructor(props: any) {
        super(props)

        this.topRowRef = React.createRef()
        this.grid = React.createRef()

        this.state = {
            loading: false,
            selectedType: '',
            hasBeenGrouped: false,
            search: '',
            deletingUserIds: [],
            deleting: false,
            selectedDocumentType: null,
            filterOptions: [],
            onlyShowOwnRecords: false,
        }

        this.externalSearchHandler = this.externalSearchHandler.bind(this)
        this.externalExportHandler = this.externalExportHandler.bind(this)
        this.requestDataSource = this.requestDataSource.bind(this)
        this.onDateRangeChange = this.onDateRangeChange.bind(this)
        this.deleteRow = this.deleteRow.bind(this)
        this.onResetPassword = this.onResetPassword.bind(this)
        this.customCell = this.customCell.bind(this)
        this.onAddDocument = this.onAddDocument.bind(this)
        this.contextMenuClickedDelete = this.contextMenuClickedDelete.bind(this)
        this.addDocumentClicked = this.addDocumentClicked.bind(this)
        this.contextMenuEditDocument = this.contextMenuEditDocument.bind(this)
        this.onDocumentTypeChange = this.onDocumentTypeChange.bind(this)
        this.groupSettings = this.groupSettings.bind(this)
    }

    contextMenuEditDocument(props: IDocument): void {
        let query = {
            propertyId: props.propertyId,
            contactId: props.contactId,
            issueId: props.issueId,
            documentId: props.documentId,
            tenancyId: props.attachedTo.tenancy,
            unitId: props.unitId,
            transactionId: props.transactionId,
            assetId: props.assetId,
        }

        let newParam = addURLParameter(
            this.props.location.search,
            'editDocument=' + JSON.stringify(query)
        )
        this.props.history.push({
            search: newParam,
        })

        toggleEditDocumentPopup(true)
    }

    contextMenuClickedDelete() {
        if (!this.grid || !this.grid.current) {
            return
        }

        const { current } = this.grid

        let rows = current.getSelectedRecords()

        if (rows.length == 0) {
            updateToastMessage('No rows have been selected.', 'warning')
            return
        }

        let documentIds = []
        for (let i = 0; i < rows.length; i++) {
            documentIds.push((rows[i] as any).documentId)
        }

        onDeleteRows(documentIds, this.props.location, this.props.history)
    }

    shouldComponentUpdate(nextProps: any, nextState: any): boolean {
        // If the refresh param is present then perform a refresh
        let newQuery = queryString.parse(
            nextProps.location.search
        ).refreshDocumentsGrid
        let previousQuery = queryString.parse(
            this.props.location.search
        ).refreshDocumentsGrid

        if (newQuery == 'true' && previousQuery != 'true') {
            let removeQuery = removeURLParameter(
                nextProps.location.search,
                'refreshDocumentsGrid'
            )
            this.props.history.push({
                search: removeQuery,
            })

            return true
        }

        if (this.state.search != nextState.search) {
            return true
        }

        if (this.state.onlyShowOwnRecords != nextState.onlyShowOwnRecords) {
            return true
        }

        if (this.state.filterOptions != nextState.filterOptions) {
            return true
        }

        if (this.state.selectedDocumentType != nextState.selectedDocumentType) {
            return true
        }

        return false
    }

    externalExportHandler(exportType: ExportTypeEnum): void {
        if (!this.grid) {
            return
        }

        let selectedRecords = this.grid.getSelectedRecords()
        if (!selectedRecords) {
            return
        }

        if (exportType == ExportTypeEnum.PDF) {
            this.grid.pdfExport()
            return
        }

        if (exportType == ExportTypeEnum.Excel) {
            this.grid.excelExport()
            return
        }

        if (exportType == ExportTypeEnum.Csv) {
            this.grid.csvExport()
            return
        }
    }

    externalSearchHandler(searchValue: string): void {
        this.setState({
            search: searchValue,
        })
    }

    customCell(args: QueryCellInfoEventArgs) {
        if ((args.column as Column).headerText === 'Created By') {
            let data = args.data as any
            if (data.createdBy && data.createdBy != null) {
                ReactDOM.render(
                    <Router history={history}>
                        <UserContactInfo
                            id={data.createdBy}
                            type={UserContactInfoType.User}
                        />
                    </Router>,
                    args.cell as Element
                )
            } else {
                ReactDOM.render(<div> </div>, args.cell as Element)
            }
        }

        if ((args.column as Column).headerText === 'Inspector') {
            let data = args.data as any
            if (data.inspectorId && data.inspectorId != null) {
                ReactDOM.render(
                    <Router history={history}>
                        <UserContactInfo
                            id={data.inspectorId}
                            type={UserContactInfoType.Contact}
                        />
                    </Router>,
                    args.cell as Element
                )
            } else {
                ReactDOM.render(<div> </div>, args.cell as Element)
            }
        }

        if ((args.column as Column).headerText === 'User') {
            let data = args.data as any
            if (data.userId && data.userId != null) {
                ReactDOM.render(
                    <Router history={history}>
                        <UserContactInfo
                            id={data.userId}
                            type={UserContactInfoType.User}
                        />
                    </Router>,
                    args.cell as Element
                )
            } else {
                ReactDOM.render(<div> </div>, args.cell as Element)
            }
        }

        if ((args.column as Column).field === 'Action') {
            let data = args.data as any
            ReactDOM.render(
                <div>
                    <SFDropdown
                        loadedBeforeRender
                        customCssClassName={styles.syncfusionDropdown}
                        onSelect={(args) => {
                            if (args.item.text == 'Edit') {
                                this.contextMenuEditDocument(data)
                            }
                            if (args.item.text == 'View') {
                                openInNewTab(data.uri)
                            }
                            if (args.item.text == 'Linked property') {
                                goToRoute(
                                    `/dashboard/property/${data.propertyId}`
                                )
                            }
                            if (args.item.text == 'Linked contact') {
                                goToRoute(
                                    `/dashboard/contact/${data.contactId}`
                                )
                            }
                            if (args.item.text == 'Delete') {
                                onDeleteRows(
                                    [data.documentId],
                                    this.props.location,
                                    this.props.history
                                )
                            }
                        }}
                        items={[
                            {
                                iconCss: styles.subIconEdit,
                                text: 'Edit',
                            },
                            {
                                iconCss: styles.subIconEdit,
                                text: 'View',
                            },
                            {
                                separator: true,
                            },
                            ...(data.propertyId &&
                            data.propertyId !=
                                '00000000-0000-0000-0000-000000000000'
                                ? [
                                      {
                                          iconCss: styles.subIconGoToDocuments,
                                          text: 'Linked property',
                                          url: `/dashboard/property/${data.propertyId}`,
                                      },
                                  ]
                                : []),
                            ...(data.contactId &&
                            data.contactId !=
                                '00000000-0000-0000-0000-000000000000'
                                ? [
                                      {
                                          iconCss: styles.subIconGoToDocuments,
                                          text: 'Linked contact',
                                          url: `/dashboard/contact/${data.contactId}`,
                                      },
                                  ]
                                : []),
                            ...(isLandlord() || isPlatformAdmin()
                                ? [
                                      {
                                          iconCss: styles.subIconDelete,
                                          text: 'Delete',
                                      },
                                  ]
                                : []),
                        ]}
                    />
                </div>,
                args.cell as Element
            )
        }
    }

    groupSettings(): GroupSettingsModel {
        return {
            showGroupedColumn: false,
            columns:
                this.props.config && this.props.config.groupSettings
                    ? this.props.config.groupSettings
                    : [],
        }
    }

    componentDidMount(): void {
        getTypesForDropdown('8A324853-7F7D-4B45-BC8E-891514DE3713').then(
            (resp) => {
                if (resp.status == 200) {
                    let options = [
                        {
                            label: 'All',
                            value: '',
                        },
                    ]
                    this.setState({
                        filterOptions: options.concat(resp.data),
                    })
                }
            }
        )

        //this.requestDataSource();
    }

    requestDataSource(): void {
        //if (!this.grid) {
        //  this.grid.dataSource = generateOdataSource({
        //    size: 0,
        //    page: 0,
        //   search: this.state.search,
        //  });
        //}
    }

    deleteRow(): void {
        this.requestDataSource()
    }

    onDateRangeChange(startDate: Date, endDate: Date): void {
        this.setState(
            {
                startDate,
                endDate,
            },
            () => {
                this.requestDataSource()
            }
        )
    }

    onResetPassword(): void {}

    onAddDocument(): void {
        if (this.props.config && this.props.config.onAddDocument) {
            this.props.config.onAddDocument()
        }

        toggleAddDocumentPopup(true)
    }

    addDocumentClicked(propertyId: string, inspectionId: number): void {
        let options: IDocumentUploadOptions = {
            propertyId,
            inspectionId,
        }

        let query = 'uploadDocumentOptions=' + JSON.stringify(options)

        let search1 = addURLParameter(this.props.location.search, query)

        history.push({
            search: search1,
        })

        toggleAddDocumentPopup(true)
    }

    onDocumentTypeChange(options: IOptions): void {
        this.setState({
            selectedDocumentType: options.value,
        })
    }

    onRowClick = (e: RecordClickEventArgs) => {
        // This filters out clicks on any of the other items for e.g. the checkbox and action menu
        if (e.target && e.target.classList.contains('e-rowcell')) {
            window.open((e.rowData as IDocument).uri)
        }
    }

    render() {
        return (
            <div className={styles.page}>
                <div className={styles.content}>
                    <div className={`${styles.row}`}>
                        <div className={styles.column}>
                            <div className={styles.left}>
                                <div className={styles.dropdown}>
                                    <div className={styles.searchInput}>
                                        <TextBoxComponent
                                            change={(args: ChangedEventArgs) =>
                                                this.externalSearchHandler(
                                                    args.value
                                                )
                                            }
                                            placeholder="Search Documents"
                                        />
                                    </div>
                                </div>

                                <div className={styles.documentType}>
                                    <SelectDropdown
                                        selectedId={
                                            this.state.selectedDocumentType
                                        }
                                        data={this.state.filterOptions}
                                        placeholder="Document type..."
                                        onSelect={this.onDocumentTypeChange}
                                    />
                                </div>

                                {isPlatformAdmin() && (
                                    <div className={styles.showOnlyMyDocuments}>
                                        <ShowOnlyMy
                                            checked={
                                                this.state.onlyShowOwnRecords
                                            }
                                            showOnlyMy="documents"
                                            onChange={() =>
                                                this.setState({
                                                    onlyShowOwnRecords:
                                                        !this.state
                                                            .onlyShowOwnRecords,
                                                })
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={styles.iconColumn}>
                            <SFDropdown
                                loadedBeforeRender
                                customCssClassName={styles.syncfusionDropdown}
                                customToggle={
                                    <IconButton
                                        button={{
                                            text: 'Actions',
                                            displayType: 'action',
                                            elementType: 'button',
                                            icon: 'action',
                                        }}
                                    />
                                }
                                items={[
                                    {
                                        iconCss: styles.subIconAdd,
                                        text: 'Add Document',
                                    },
                                    ...(isLandlord() || isPlatformAdmin()
                                        ? [
                                              {
                                                  iconCss: styles.subIconDelete,
                                                  text: 'Delete document',
                                              },
                                          ]
                                        : []),
                                    {
                                        iconCss: styles.subIconExportExcel,
                                        text: 'Export to Excel',
                                    },
                                    {
                                        iconCss: styles.subIconExportCsv,
                                        text: 'Export to CSV',
                                    },
                                    {
                                        iconCss: styles.subIconExportPdf,
                                        text: 'Export to PDF',
                                    },
                                ]}
                                onSelect={(args) => {
                                    if (args.item.text === 'Add Document') {
                                        this.onAddDocument()
                                    }
                                    if (args.item.text === 'Delete document') {
                                        this.contextMenuClickedDelete()
                                    }
                                    if (args.item.text === 'Export to PDF') {
                                        this.grid.current.pdfExport()
                                    }
                                    if (args.item.text === 'Export to Excel') {
                                        this.grid.current.excelExport()
                                    }
                                    if (args.item.text === 'Export to CSV') {
                                        this.grid.current.csvExport()
                                    }
                                }}
                            />
                        </div>

                        <div
                            className={`${styles.iconColumn} ${styles.addIcons}`}
                        >
                            <IconButton
                                button={{
                                    text: 'Add Document',
                                    displayType: 'submit',
                                    elementType: 'button',
                                    icon: 'add',
                                    onClick: () => this.onAddDocument(),
                                }}
                            />
                        </div>
                    </div>

                    <div className={`${styles.row}`}>
                        <div className={`${styles.column} ${styles.grid}`}>
                            <GridComponent
                                ref={this.grid}
                                recordClick={this.onRowClick}
                                groupSettings={this.groupSettings()}
                                queryCellInfo={this.customCell}
                                editSettings={editSettings}
                                showColumnMenu
                                id="documentsGrid"
                                textWrapSettings={textWrapSettings}
                                allowTextWrap={true}
                                allowExcelExport={true}
                                allowPdfExport={true}
                                allowGrouping={true}
                                dataSource={generateOdataSource(
                                    {
                                        onlyShowOwnRecords:
                                            this.state.onlyShowOwnRecords,
                                        size: 0,
                                        page: 0,
                                        search: this.state.search,
                                        propertyId:
                                            this.props.config
                                                .dataFilterSettings &&
                                            this.props.config.dataFilterSettings
                                                .propertyId,
                                        contactId:
                                            this.props.config
                                                .dataFilterSettings &&
                                            this.props.config.dataFilterSettings
                                                .contactId,
                                        issueId:
                                            this.props.config
                                                .dataFilterSettings &&
                                            this.props.config.dataFilterSettings
                                                .issueId,
                                        unitId:
                                            this.props.config
                                                .dataFilterSettings &&
                                            this.props.config.dataFilterSettings
                                                .unitId,
                                        assetId:
                                            this.props.config
                                                .dataFilterSettings &&
                                            this.props.config.dataFilterSettings
                                                .assetId,
                                        inspectionId:
                                            this.props.config
                                                .dataFilterSettings &&
                                            this.props.config.dataFilterSettings
                                                .inspectionId,
                                        tenancyId:
                                            this.props.config
                                                .dataFilterSettings &&
                                            this.props.config.dataFilterSettings
                                                .tenancyId,
                                        transactionId:
                                            this.props.config
                                                .dataFilterSettings &&
                                            this.props.config.dataFilterSettings
                                                .transactionId,        
                                    },
                                    {
                                        documentType:
                                            this.state.selectedDocumentType,
                                    }
                                )}
                                allowResizing={true}
                                allowReordering={true}
                                selectionSettings={selectionSettings}
                                allowPaging={true}
                                allowSorting={true}
                                sortSettings={{
                                    columns: [
                                        {
                                            field: 'uploadedOn',
                                            direction: 'Descending',
                                        },
                                    ],
                                }}
                                pageSettings={pageSettings}
                                filterSettings={filterOptions}
                                allowFiltering={true}
                            >
                                <Inject
                                    services={[
                                        Aggregate,
                                        ColumnMenu,
                                        ContextMenu,
                                        CommandColumn,
                                        Filter,
                                        Page,
                                        Sort,
                                        ExcelExport,
                                        PdfExport,
                                        Group,
                                        Reorder,
                                        Resize,
                                    ]}
                                />

                                {this.props.config.template
                                    ? this.props.config.template(this.props)
                                    : DefaultTemplate(this.props)}
                            </GridComponent>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(DocumentsGrid)
