import moment from 'moment'

export function convertDateToUTC(date: Date) {
    let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000)
    return newDate;
}

export function dayDiff(d1, d2): number {
    return +((d2.getTime() - d1.getTime()) / 31536000000).toFixed(0);
}

export function monthDiff(dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() +
        (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}

// a and b are javascript Date objects
export const dateDiffInDays = (a, b) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export const dateWithoutTime = (date?: Date) => {
    return new Date(moment(date.toString()).local().format('YYYY/MM/DD'));
}

export const correctDate = (date: Date) => {
    // There is a bug in react-datepicker whereby a selection with no datetime serialises 
    // incorrectly when sending to server and saves the date as date -1 day, so we need to do this.
    
    return date != null && date != undefined ? new Date(date.getTime() - date.getTimezoneOffset() * 60000) : null;
}