import styled from 'styled-components';

export const ContainerDiv = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 0px;
    align-content: flex-start;
    justify-content: flex-start;
`;

export const SectionDiv = styled.div`
    flex: 0 0 100%;
    text-align: left;
`;


