import React from "react";
import { FormGroup } from "reactstrap";

import styles from "./ShowOnlyMy.module.scss";
import MaterialCheckbox from "../MaterialCheckbox";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

interface IProps {
  showOnlyMy: string;
  checked: boolean;
  onChange(): void;
}

interface IState {
  checked: boolean;
}

class ShowOnlyMy extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      checked: this.props.checked
    };

    this.changeChecked = this.changeChecked.bind(this);
  }

  changeChecked() {
    this.setState({ checked: !this.state.checked }, () =>
      this.props.onChange()
    );
  }

  render() {
    return (
      <div className={styles.showOwnPropertiesToggle}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.checked}
                color="primary"
                onChange={() => this.changeChecked()}
                value={"My " + this.props.showOnlyMy}
                className={this.props.checked ? styles.materialCheckbox : null}
              />
            }
            label={"My " + this.props.showOnlyMy}
          />

      </div>
    );
  }
}

export default ShowOnlyMy;
