import { apiService } from "../services/ApiService";
import { createBearerToken, getUserId } from "../helpers/authHelpers";
import * as domains from "../configuration/domains";
import { PropertySortEnums } from "../types/PropertyList";
import { IListItemOptions } from "../types/ListFilters";
import { toQueryString } from "./DocumentService";
import { string } from "prop-types";

export const getPropertyList = (
  options: IListItemOptions,
  sort: PropertySortEnums
): Promise<any> => {
  var stringed = toQueryString(options);
  return apiService.get(
    `${domains.propertyListService}?${stringed}&sort=${sort}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: createBearerToken()
      }
    }
  );
};
