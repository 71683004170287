import { RCCurrencyInput } from '@rentchief/components'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import Modal from 'react-responsive-modal'
import { withRouter } from 'react-router-dom'
import { FormGroup } from 'reactstrap'
import {
    ParkingArragementsEntityId,
    PropertyResidentialTypeGroupId,
    PropertySizeTypeGroupId,
} from '../../../configuration/appConfig'
import { canSelectNumberOfSpaces } from '../../../helpers/propertyHelpers'
import { getTypesForDropdown } from '../../../services/EntityService'
import { getPropertyDetails } from '../../../services/PropertyDetail'
import { getEstimatedSaleValue } from '../../../services/PropertyService'
import { IPropertyDetail } from '../../../types/PropertyDetailService'
import {
    IGetPriceValuationRequest,
    IGetPriceValuationResponse,
} from '../../../types/PropertyService/IPropertyService'
import { EstimateValuation } from '../../../YupValidationSchemas'
import AddressFinder from '../AddressFinder'
import Buttons from '../Buttons'
import Currency from '../Currency'
import RotatingLoader from '../RotatingLoader'
import SelectDropdown, { IOptions } from '../SelectDropdown'
import styles from './EstimatedValuationModal.module.scss'
import { IProps, IState } from './EstimatedValuationModal.types'

class EstimatedValuationModal extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = {
            propertyDetail: {} as IPropertyDetail,
            propertyTypes: [],
            parkingTypes: [],
            sizeTypes: [],
            finishQualityTypes: [],
            outdoorSpaceTypes: [],
            result: null,
            selectedFinishQualityType: '',
            selectedOutdoorSpaceType: '',
            selectedParkingType: '',
            selectedPropertyType: '',
            selectedSizeType: null,
            loading: false,
            error: '',
            numberOfParkingSpaces: 0,
            address: null,
        }
    }

    isResidential = (): boolean => {
        let propertyType = this.state.propertyTypes.findIndex(
            (x) => x.value == this.state.selectedPropertyType
        )
        if (propertyType < 0) {
            return false
        }

        let selectedType =
            this.state.propertyTypes[propertyType].label.toLocaleLowerCase()
        return selectedType == 'Residential'
    }

    handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        const field = event.currentTarget.name
        const value: string = event.currentTarget.value
        this.setState((prevState) => ({
            propertyDetail: {
                ...prevState.propertyDetail,
                [field]: value,
            },
        }))
    }

    getSizeTypeFriendlyText() {
        for (let i = 0; i < this.state.sizeTypes.length; i++) {
            let sizeType = this.state.sizeTypes[i].label

            if (sizeType == 'Sq Foot') {
                sizeType = 'SQFT'
            }

            if (sizeType == 'Sq Metre') {
                sizeType = 'SQM'
            }

            this.state.sizeTypes[i].label = sizeType
        }
    }

    componentDidMount(): void {
        this.setState({
            loading: true,
        })

        if (this.props.propertyDetail) {
            this.setState({
                selectedSizeType: this.props.propertyDetail.sizeType,
            })
        }

        getTypesForDropdown(PropertyResidentialTypeGroupId).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                this.setState({
                    propertyTypes: resp.data,
                })
            }
        })

        getTypesForDropdown(ParkingArragementsEntityId).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                this.setState({
                    parkingTypes: resp.data,
                })
            }
        })

        getTypesForDropdown(PropertySizeTypeGroupId).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                this.setState(
                    {
                        sizeTypes: resp.data,
                    },
                    () => {
                        this.getSizeTypeFriendlyText()
                    }
                )

                let def = this.state.sizeTypes.findIndex((x) => x.isDefault)
                if (def && !this.state.propertyDetail.sizeType) {
                    this.setState((prevState) => ({
                        propertyDetail: {
                            ...prevState.propertyDetail,
                            sizeType: this.state.sizeTypes[def].value,
                        },
                    }))
                }
            }
        })

        getTypesForDropdown('2DBB47A7-A2D4-4B5A-B6E2-12DCDE270BE4').then(
            (resp) => {
                if (resp && resp.status == 200 && resp.data) {
                    this.setState({
                        finishQualityTypes: resp.data,
                    })
                }
            }
        )

        getTypesForDropdown('C22896A7-8470-48D7-9D01-F9991F5CC4DC').then(
            (resp) => {
                if (resp && resp.status == 200 && resp.data) {
                    this.setState({
                        outdoorSpaceTypes: resp.data,
                    })
                }
            }
        )

        this.setState({
            loading: false,
        })

        if (this.props.manualOverride) {
            this.setState((prevState) => ({
                selectedPropertyType:
                    this.props.manualOverride.propertyType || '',
                selectedFinishQualityType:
                    this.props.manualOverride.finishQuality || '',
                selectedOutdoorSpaceType:
                    this.props.manualOverride.outdoorSpace || '',
                selectedParkingType: this.props.manualOverride.parking || '',
                numberOfParkingSpaces:
                    this.props.manualOverride.numberOfParkingSpaces,
                propertyDetail: {
                    ...prevState.propertyDetail,
                    beds: this.props.manualOverride.bedrooms,
                    baths: this.props.manualOverride.bathrooms,
                    size: this.props.manualOverride.size,
                    sizeType: this.props.manualOverride.sizeType,
                    yearBuild: this.props.manualOverride.yearBuilt,
                },
            }))
        }

        if (this.props.propertyDetail) {
            this.setState({
                propertyDetail: this.props.propertyDetail,
                selectedFinishQualityType: this.props.propertyDetail
                    .finishQualityType
                    ? this.props.propertyDetail.finishQualityType.entityTypeId
                    : null,
                selectedOutdoorSpaceType: this.props.propertyDetail
                    .outdoorSpaceType
                    ? this.props.propertyDetail.outdoorSpaceType.entityTypeId
                    : null,
                selectedPropertyType: this.props.propertyDetail.residentialType
                    ? this.props.propertyDetail.residentialType.entityTypeId
                    : null,
                selectedParkingType: this.props.propertyDetail.parking
                    ? this.props.propertyDetail.parking.entityTypeId
                    : null,
                numberOfParkingSpaces: this.props.propertyDetail
                    .numberOfParkingSpaces
                    ? this.props.propertyDetail.numberOfParkingSpaces
                    : 0,
            })
            return
        }

        if (!this.props.propertyId) {
            return
        }

        getPropertyDetails(this.props.propertyId).then((resp) => {
            if (resp && resp.status == 200 && resp.data) {
                this.setState({
                    propertyDetail: 
                    {
                        ...resp.data,
                        sizeType: resp.data.sizeType ? resp.data.sizeType : '71a09ed7-e80f-4441-8df3-8218e6eee856' // SQFT
                    },
                    selectedFinishQualityType: resp.data.finishQualityType
                        ? resp.data.finishQualityType.entityTypeId
                        : '',
                    selectedOutdoorSpaceType: resp.data.outdoorSpaceType
                        ? resp.data.outdoorSpaceType.entityTypeId
                        : '',
                    selectedPropertyType: resp.data.residentialType
                        ? resp.data.residentialType.entityTypeId
                        : '',
                    selectedParkingType: resp.data.parking
                        ? resp.data.parking.entityTypeId
                        : '',
                    numberOfParkingSpaces: resp.data.numberOfParkingSpaces,
                })
            }
        })
    }

    delay = ms => new Promise(res => setTimeout(res, ms));

    onSubmit = async() => {
        let isRental = this.props.isRental || false;
        let request: IGetPriceValuationRequest = {
            isRental: isRental,
            postcode: this.props.postcode,
            propertyType: this.state.selectedPropertyType,
            constructionDate: this.state.propertyDetail.yearBuild,
            internalArea: this.state.propertyDetail.size,
            internalAreaType: this.state.propertyDetail.sizeType,
            bedrooms: this.state.propertyDetail.beds,
            bathrooms: this.state.propertyDetail.baths,
            finishQuality: this.state.selectedFinishQualityType,
            outdoorSpace: this.state.selectedOutdoorSpaceType,
            parkingType: this.state.selectedParkingType,
            offStreetParking: this.state.numberOfParkingSpaces.toString(),
        }

        // We can either be requested to supply both rent and price valuation or just one of them
        let priceResp: IGetPriceValuationResponse = null;
        let rentResp: IGetPriceValuationResponse = null;

        let res1 = await this.saveEstimations(request, isRental);
        isRental ? rentResp = res1 : priceResp = res1;

        if(this.props.createValuationForPriceAndRent){
            await this.delay(3000); // Need to add a delay because the second call seems to fail due to race conditions
            request.isRental = !isRental;
            let res2 = await this.saveEstimations(request, !isRental);
            !isRental ? rentResp = res2 : priceResp = res2;
        }

        if(this.props.onSuccess){
            this.props.onSuccess(priceResp, rentResp, request);
        }
    }

    saveEstimations = async(request: IGetPriceValuationRequest, isRental: boolean): Promise<IGetPriceValuationResponse> =>{
        try{
            this.setState({
                error: '',
                loading: true,
            })

            if (this.props.propertyId) {
                request.propertyId = this.props.propertyId
            }

            let resp = await getEstimatedSaleValue(request);
            if (resp && resp.status == 200 && resp.data) {
                if (resp.data.status != 'success') {
                    this.setState({
                        loading: false,
                        error: resp.data.message,
                    })
                    return
                }else{
                    return resp.data;
                }
            }
            
        }catch(e){
            this.setState({
                loading: false,
                error: e.response.data,
            })
        }
    } 

    render() {
        return (
            <Modal
                classNames={{ modal: styles.modal }}
                open={this.props.open}
                onClose={() => this.props.onClose()}
                center
            >
                <div className={styles.heading}>
                    {
                        //this.props.header ? this.props.header : "Estimated " + (this.props.isRental ? 'Rental Value' : 'Sale Value')
                    }
                    
                </div>

                <p className={styles.p}>
                    <strong>Please note: </strong>
                    This is only an estimated value and it may not be fully
                    accurate. The best way to receive a market value estimation
                    is by using an agent.
                </p>

                {!this.props.postcode && this.props.onSelectAddress && (
                    <p className={styles.p}>
                        To obtain an estimated valuation please first select
                        your address.
                        <div className={styles.row}>
                            <div className={styles.column}>
                                <AddressFinder
                                    label="Enter a postcode..."
                                    resetAddress={() =>
                                        this.setState({ address: null })
                                    }
                                    selectAddressCallback={
                                        this.props.onSelectAddress
                                    }
                                />
                            </div>
                        </div>
                    </p>
                )}

                {!this.props.postcode && !this.props.onSelectAddress && (
                    <p className={styles.p}>
                        To obtain an estimated valuation please enter an address
                        against the property.
                    </p>
                )}

                {this.state.error && (
                    <p className={styles.error}>{this.state.error}</p>
                )}

                {this.props.postcode && (
                    <div>
                        {!this.props.isResidential ? (
                            <div className={styles.residentialOnlyMessage}>
                                This service is only available for residential
                                property types. If the Land Registry has not
                                matched the Property type then it can be set on
                                the details tab.
                            </div>
                        ) : this.props.isResidential && this.state.loading ? (
                            <RotatingLoader
                                loading={true}
                                text="Loading your valuation..."
                            />
                        ) : this.state.result ? (
                            <div>
                                Property valued at{' '}
                                <Currency
                                    value={this.state.result.result.estimate}
                                    prefix="£"
                                />{' '}
                                with a margin of{' '}
                                <Currency
                                    value={this.state.result.result.margin}
                                    prefix="£"
                                />
                            </div>
                        ) : (
                            <div>
                                <Formik
                                    initialValues={{
                                        bedrooms: this.state.propertyDetail.beds,
                                        bathrooms: this.state.propertyDetail.baths,
                                        propertyType: this.state.selectedPropertyType,
                                        yearBuilt: this.state.propertyDetail.yearBuild,
                                        finishQuality: this.state.selectedFinishQualityType,
                                        outdoorSpace: this.state.selectedOutdoorSpaceType,
                                        sizeType: this.state.propertyDetail.sizeType,
                                        size: this.state.propertyDetail.size,
                                    }}
                                    enableReinitialize={true}
                                    validationSchema={EstimateValuation}
                                    onSubmit={(
                                        values: any,
                                        { setSubmitting }
                                    ) => {
                                        this.onSubmit()
                                    }}
                                >
                                    {(props) => (
                                        <Form>
                                            <div className={styles.row}>
                                                <FormGroup className={styles.column}>
                                                    <div className={styles.label}>Property Type</div>
                                                    <SelectDropdown
                                                        showDefaultOption
                                                        selectedId={this.state.selectedPropertyType}
                                                        onSelect={(
                                                            selected: IOptions
                                                        ) => {
                                                            this.setState((prevState) => ({
                                                                    selectedPropertyType: selected.value,
                                                                })
                                                            )
                                                        }}
                                                        data={this.state.propertyTypes}
                                                    />
                                                    <span className={styles.errorMessage}>
                                                        <ErrorMessage className={styles.errorMessage} name="propertyType" />
                                                    </span>
                                                </FormGroup>
                                            </div>

                                            <div></div>

                                            <div className={styles.row}>
                                                <FormGroup className={styles.column}>
                                                    <div className={styles.row}>
                                                        <div className={styles.columnThirtyPercent}>
                                                            <div className={styles.label}>Year Built</div>
                                                            <input
                                                                //placeholder="When was it built?"
                                                                name="yearBuild"
                                                                type="number"
                                                                maxLength={4}
                                                                className={styles.input}
                                                                onChange={this.handleChange}
                                                                value={this.state.propertyDetail.yearBuild}
                                                            />
                                                            <span className={styles.errorMessage}>
                                                                <ErrorMessage className={styles.errorMessage} name="yearBuilt" />
                                                            </span>
                                                        </div>
                                                        <div className={styles.column}>
                                                            <div className={styles.label}>Size</div>
                                                            <input
                                                                placeholder="Enter size"
                                                                name="size"
                                                                type="number"
                                                                onChange={this.handleChange}
                                                                className={styles.input}
                                                                value={this.state.propertyDetail.size}
                                                            />
                                                            <span className={styles.errorMessage}>
                                                                <ErrorMessage className={styles.errorMessage}name="size"/>
                                                            </span>
                                                        </div>
                                                        <div
                                                            className={`${styles.column} ${styles.dropdownSpacing}`}
                                                        >
                                                            <SelectDropdown
                                                                showDefaultOption
                                                                selectedId={this.state.propertyDetail && this.state.propertyDetail.sizeType}
                                                                onSelect={(selected: IOptions) => {
                                                                    this.setState((prevState) => ({
                                                                            propertyDetail:
                                                                                {
                                                                                    ...prevState.propertyDetail,
                                                                                    sizeType: selected.value,
                                                                                },
                                                                            selectedSizeType: selected.value,
                                                                        })
                                                                    )
                                                                }}
                                                                data={this.state.sizeTypes}
                                                            />
                                                            <span className={styles.errorMessage}>
                                                                <ErrorMessage className={styles.errorMessage} name="sizeType" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </div>

                                            <div className={styles.row}>
                                                <FormGroup className={styles.column}>
                                                    <div className={styles.row}>
                                                        <div className={styles.column}>
                                                            <div className={styles.label}>Bedrooms</div>
                                                            <input
                                                                name="beds"
                                                                type="number"
                                                                maxLength={4}
                                                                onChange={this.handleChange}
                                                                className={styles.inputSmall}
                                                                value={this.state.propertyDetail.beds}
                                                            />
                                                            <span className={styles.errorMessage}>
                                                                <ErrorMessage className={styles.errorMessage} name="bedrooms" />
                                                            </span>
                                                        </div>
                                                        <div className={styles.column}>
                                                            <div className={styles.label}>Bathrooms</div>
                                                            <input
                                                                name="baths"
                                                                type="number"
                                                                maxLength={4}
                                                                onChange={this.handleChange}
                                                                className={styles.inputSmall}
                                                                value={this.state.propertyDetail.baths}
                                                            />
                                                            <span className={styles.errorMessage}>
                                                                <ErrorMessage className={styles.errorMessage} name="bathrooms" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </div>

                                            <div className={styles.row}>
                                                <FormGroup className={styles.column}>
                                                    <div className={styles.label}>Finish Quality</div>
                                                    <SelectDropdown
                                                        showDefaultOption
                                                        selectedId={this.state.selectedFinishQualityType}
                                                        onSelect={(selected: IOptions) => {
                                                            this.setState((prevState) => ({
                                                                    selectedFinishQualityType: selected.value,
                                                                })
                                                            )
                                                        }}
                                                        data={this.state.finishQualityTypes}
                                                    />
                                                    <span className={styles.errorMessage}>
                                                        <ErrorMessage className={styles.errorMessage} name="finishQuality" />
                                                    </span>
                                                </FormGroup>
                                            </div>

                                            <div className={styles.row}>
                                                <FormGroup className={styles.column}>
                                                    <div className={styles.label}>Outdoor Space</div>
                                                    <SelectDropdown
                                                        showDefaultOption
                                                        selectedId={this.state.selectedOutdoorSpaceType}
                                                        onSelect={(selected: IOptions) => {
                                                            this.setState((prevState) => ({
                                                                    selectedOutdoorSpaceType: selected.value,
                                                                })
                                                            )
                                                        }}
                                                        data={this.state.outdoorSpaceTypes}
                                                    />
                                                    <span className={styles.errorMessage}>
                                                        <ErrorMessage className={styles.errorMessage} name="outdoorSpace" />
                                                    </span>
                                                </FormGroup>
                                            </div>

                                            <div className={styles.row}>
                                                <FormGroup className={styles.column}>
                                                    <div className={styles.row}>
                                                        <div className={styles.column}>
                                                            <div className={styles.label}>Parking</div>
                                                            <SelectDropdown
                                                                showDefaultOption
                                                                selectedId={this.state.selectedParkingType}
                                                                onSelect={(selected: IOptions) => {
                                                                    if (
                                                                        !canSelectNumberOfSpaces(
                                                                            selected.label,
                                                                            this.state.propertyTypes
                                                                        )
                                                                    ) {
                                                                        this.setState(
                                                                            {
                                                                                numberOfParkingSpaces: 0,
                                                                            }
                                                                        )
                                                                    }
                                                                    this.setState(
                                                                        {
                                                                            selectedParkingType: selected.value,
                                                                        }
                                                                    )
                                                                }}
                                                                data={this.state.parkingTypes}
                                                            />
                                                        </div>
                                                        {canSelectNumberOfSpaces(
                                                            this.state.selectedParkingType,
                                                            this.state.parkingTypes
                                                        ) && (
                                                            <div className={styles.column}>
                                                                <div className={styles.label}>
                                                                    Number of
                                                                    spaces
                                                                </div>
                                                                {/* <input
                                                                    name="numberOfSpaces"
                                                                    type="number"
                                                                    maxLength={4}
                                                                    onChange={(e) => {
                                                                        this.setState(
                                                                            {
                                                                                numberOfParkingSpaces: Number(e.currentTarget.value),
                                                                            }
                                                                        )
                                                                    }}
                                                                    className={styles.inputSmall}
                                                                    value={this.state.numberOfParkingSpaces}
                                                                /> */}
                                                                <div className={styles.rcCurrencyInput}>
                                                                    <RCCurrencyInput
                                                                        id="numberOfSpaces"
                                                                        value={this.state.numberOfParkingSpaces}
                                                                        handleChange={(e) => this.setState(
                                                                            {
                                                                                numberOfParkingSpaces: e ? Number(e) : null,
                                                                            }
                                                                        )}
                                                                    />
                                                                </div>
                                                                <span className={styles.errorMessage}>
                                                                    <ErrorMessage
                                                                        className={styles.errorMessage}
                                                                        name="numberOfSpaces"
                                                                    />
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </FormGroup>
                                            </div>

                                            <div>
                                                <Buttons
                                                    buttons={[
                                                        {
                                                            displayType: 'cancel',
                                                            elementType: 'button',
                                                            onClick: () => this.props.onClose(),
                                                        },
                                                        {
                                                            displayType: 'submit',
                                                            elementType: 'button',
                                                            onClick: () => props.submitForm(),
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        )}
                    </div>
                )}
            </Modal>
        )
    }
}

export default withRouter(EstimatedValuationModal)
