import React from "react";
import styles from "./CategoryPill.module.scss";

interface props {
  value: string;
}

const CategoryPill = ({value} : props) => {
  return (
    <div className={styles.categoryPill}>{value}</div>
  );

}

export default CategoryPill;