import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Loader } from "@rentchief/components";
import { toggleIssuePopup } from '../../../../../helpers/sidebarHelpers'
import { isTenant } from '../../../../../helpers/roleHelpers'
import { addURLParameter } from '../../../../../helpers/locationHelpers'
import { IRaiseIssueOptions } from '../../../../atoms/AddEditIssue'
import { IListItemOptions, IIssueListItemOptions } from "../../../../../types/ListFilters";
import { getIssues } from "../../../../../services/IssueService";
import SectionHeader from "../SectionHeader/SectionHeader.component"
import CounterPart from "../CounterPart/CounterPart.component"
import { ISection } from '../../PropertyDashboard/_config/dashboardConfig';
import InitialStateSection from '../../Sections/InitialStateSection/InitialStateSection.component';
import * as S from "./ActiveMaintenanceIssuesSection.styles";

interface sectionProps {
    id?: string;
    header?: string;
    hideOnClick?(id: string): void;
    propertyId?: string;
    propertyName?: string;
    config?: ISection;
    hideInitialState?: boolean;
    color?: string;
    hideAddButton?: boolean;
}

const ActiveMaintenanceIssuesSection = ({id, header, hideOnClick, propertyId, propertyName, config, hideInitialState, color, hideAddButton}: sectionProps) => {
    let history = useHistory();
    let location = useLocation();

    const [issuesDataRetrieved, setIssuesDataRetrieved] = useState(false);
    const [issuesResult, setIssuesResult] = useState(null);

    useEffect(() => {
        fetchIssues();
      }, []);

    const fetchIssues = async() => {
        setIssuesDataRetrieved(false);
        let request: IListItemOptions = {
            page: 1,
            size: 0,
            propertyId: propertyId
          };
      
        let issuesRequest: IIssueListItemOptions = {}

        let resp = await getIssues(request, issuesRequest);
        if (resp && resp.status == 200 && resp.data) {
            if(resp.data.data.issues.length > 0){
                setIssuesResult(resp.data.data.issues);
            }

            setIssuesDataRetrieved(true);
        }
    }

    const getIssueTypeCount = (statusId: string[]) => {
        let count = 0;

        if(issuesResult != null){
            for (let i = 0; i < issuesResult.length; i++) {
                for (let j = 0; j < statusId.length; j++) {
                    if (issuesResult[i].status == statusId[j]) {
                        count++;
                    }
                }
            }
        }
    
        return count;
    }

    const newCount = () => {
        return getIssueTypeCount([
            "77DD4304-B259-4D9C-8FD2-97D1489203D0".toLowerCase(),
            "New"
          ]);
    }

    const inProgressCount = () => {
        return getIssueTypeCount([
            "8BD47439-A82A-4CD8-93C6-0468F229DBD9".toLowerCase(),
            "In Progress"
          ]);
    }

    const totalCount = () => {
        return issuesResult != null ? issuesResult.length : 0;
    }

    const onNavigate = (status?: string): void => {
        var url = '/dashboard/issues'
    
        if (status != "total") {
            url = '/dashboard/issues?status=' + status
        }
    
        if (propertyId) {
            if (isTenant()) {
                url = '/dashboard/issues?propertyId=' + propertyId
            } else {
                url = `/dashboard/property/${propertyId}/issues`
    
                if (status) {
                    url += '?status=' + status
                }
            }
        }
    
        history.push(url)
    }
    
    const addIssue = () => {
        let options: IRaiseIssueOptions = {
            propertyName: propertyName,
            propertyId: propertyId,
        }

        if (propertyId) {
            options.isOnPropertyPage = true
        }

        let stringed = 'issueOptions=' + JSON.stringify(options)

        let query = addURLParameter(location.search, stringed)
        history.push({search: query});

        toggleIssuePopup(true)
    }

    return (
        <>
        {
            !issuesDataRetrieved
            ?
            <Loader />
            :
            issuesResult || hideInitialState
            ?
            <S.SectionDiv>
                {
                    header &&
                    <SectionHeader id={id} header={header} hideOnClick={hideOnClick} titleOnClick={() => onNavigate("total")} addOnClick={!hideAddButton && addIssue} color={color}/>
                }
                <S.NewDiv><CounterPart id="New" value={newCount()} type="New" hasAdd={false} valueOnClick={onNavigate} color={color}/></S.NewDiv>
                <S.InProgressDiv><CounterPart id="In Progress" value={inProgressCount()} type="In Progress" hasAdd={false} valueOnClick={onNavigate} color={color}/></S.InProgressDiv>
                <S.TotalDiv><CounterPart id="total" value={totalCount()} type="Total" hasAdd={false} valueOnClick={onNavigate} color={color}/></S.TotalDiv>
            </S.SectionDiv>
            :
            <InitialStateSection 
                id="maintenance"
                header={config && config.header} 
                iconUrl={config && config.initialState.iconUrl}
                title={config && config.initialState.title}
                summary={config && config.initialState.summary}
                //action={<S.ActionDiv><Button onClick={addIssue}>Add</Button></S.ActionDiv>}
                hideOnClick={hideOnClick}
                titleOnClick={() => onNavigate("total")}
                addOnClick={addIssue}
            />
        }
        </>
        
    )
}

export default ActiveMaintenanceIssuesSection;