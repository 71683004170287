import { apiService } from '../services/ApiService';
import { IUpdateProfile, IUploadProfileImage } from '../types/ProfileService/IProfile';
import * as domains from '../configuration/domains';
import { createBearerToken } from '../helpers/authHelpers';

export const updateProfile = (ProfileRequest: IUpdateProfile, userId: string): Promise<any> => {
    return apiService.put(`${domains.profileUpdateService}/update/${userId}`, ProfileRequest, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
}

export const uploadProfileImage = (request: IUploadProfileImage): Promise<any> => {
    return apiService.put(`${domains.profileUpdateService}/profile-image`, request, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
}

export const getProfile = (userId: string): Promise<any> => {
    return apiService.get(`${domains.profileUpdateService}/user-profile/${userId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
}

export const getProfileFromProfileId = (profileId: string): Promise<any> => {
    return apiService.get(`${domains.profileUpdateService}/profile/${profileId}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': createBearerToken()
        },
    })
}