import React from "react";
import { QuickViewType } from "../../../types/QuickView";
import { IOptions } from "../SelectDropdown";
import { getSearchIssues } from "../../../services/SearchService";
import SelectDropdownV2 from "../SelectDropdownV2/SelectDropdownV2.component";

interface IProps {
  selectedIssueId: string;
  selectedIssueName: string;
  onSelect(option: IOptions): void;
  onClear?(): void;
}

interface IState {
  results: any[];
  search: string;
  cursor: number;
}

class SearchIssueInput extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      results: [],
      search: "",
      cursor: 0
    };

    this.getIssues = this.getIssues.bind(this);
    this.onIssueChange = this.onIssueChange.bind(this);
  }

  onIssueChange(option: any): void {
    this.setState(
      {
        results: []
      },
      () => this.props.onSelect({
        label: option.text,
        value: option.id
      })
    );
  }

  getIssues(search?: string): void {
    getSearchIssues(search ? search : '').then(resp => {
      if (resp && resp.data) {
        this.setState({
          results: resp.data
        });
      }
    });
  }

  render() {
    return (
      <SelectDropdownV2 
          onClear={this.props.onClear}
          disableClear={false}
          selectedId={this.props.selectedIssueId}
          selectedName={this.props.selectedIssueName}
          onSelect={this.props.onSelect}
          onSearch={this.getIssues}
          data={this.state.results}
          inputPlaceHolder={"Search for a Issue..."}
          quickView={QuickViewType.Issue}
          isSearchable={true}
        />
    )
  }
}

export default SearchIssueInput;