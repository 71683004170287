import React from "react";
import { ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-grids";
import { minWidthTablet } from "../../../../../global/variables/_variables";
import { ActionColumnTemplate } from "./actionColumnTemplate";
import { Router } from "react-router-dom";
import AssociatedDocuments from "../../../../atoms/AssociatedDocuments";
import history from "../../../../../history";
import TenancyStatus from "../components/TenancyStatus";
import styles from "../TenancyGrid.module.scss";
import Currency from "../../../../atoms/Currency";

export var PropertyDefaultTemplate = function (props: any) {
  return (
    <ColumnsDirective>
      <ColumnDirective
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        textAlign="Left"
        type="checkbox"
        width="50"
      />

      {ActionColumnTemplate(props)}

      <ColumnDirective
        textAlign="Left"
        headerText="Tenant"
        field="tenantName"
        width="180"
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Unit"
        field="unitName"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        textAlign="Left"
        field="reference"
        headerText="Reference"
        width="120"
        visible={false}
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Amount"
        field="rentAmount"
        width="100"
        type="number"
        //format={currencyFormat}
        template={(prop: any) => <div className={styles.amount}><Currency decimalCount={2} prefix="£" value={prop.rentAmount} /></div>}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Duration"
        field="duration"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Start"
        field="startDate"
        type="date"
        format={{ type: "date", format: "dd/MM/yy" }}
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="End"
        field="endDate"
        type="date"
        format={{ type: "date", format: "dd/MM/yy" }}
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Docs"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={(prop: any) => <Router history={history}><AssociatedDocuments
          showOnlyNoneText
          options={{
            tenancyId: prop.tenancyId
          }}
          viewAll={false}
        /></Router>}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Status"
        field="status"
        width="150"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={(prop: any) => <TenancyStatus status={prop.status} />}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Created by"
        field="createdByName"
        width="100"
        visible={false}
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        textAlign="Left"
        headerText="Created date"
        field="createdDate"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        visible={false}
        type="date"
        format={{ type: "date", format: "dd/MM/yy" }}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Tenancy Type"
        field="tenancyType"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        type="date"
        format={{ type: "date", format: "dd/MM/yy" }}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Contract Type"
        field="contractType"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Rolling?"
        field="rollingContract"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={(prop: any) => prop.rollingContract ? <span>Yes</span> : <span>No</span>}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Move in Date"
        field="moveInDate"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        type="date"
        format={{ type: "date", format: "dd/MM/yy" }}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Move out Date"
        field="moveOutDate"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        type="date"
        format={{ type: "date", format: "dd/MM/yy" }}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Rent Review Date"
        field="rentReviewDate"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        type="date"
        format={{ type: "date", format: "dd/MM/yy" }}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Tenant Notice"
        field="tenantNoticePeriod"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Landlord Notice"
        field="landlordNoticePeriod"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Rent Frequency"
        field="rentFrequency"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Day of Month Paid"
        field="dayOfMonthPaid"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Rent Pay Method"
        field="rentPayMethod"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Deposit"
        field="deposit"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        type="number"
      //format={currencyFormat}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Deposit Scheme"
        field="depositScheme"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Deposit Reference"
        field="depositReference"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Electricity"
        field="electricity"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={(prop: any) => prop.electricity ? <span>Yes</span> : <span>No</span>}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Gas"
        field="gas"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={(prop: any) => prop.gas ? <span>Yes</span> : <span>No</span>}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Water"
        field="water"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={(prop: any) => prop.water ? <span>Yes</span> : <span>No</span>}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="TV License"
        field="tvLicense"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={(prop: any) => prop.tvLicense ? <span>Yes</span> : <span>No</span>}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Sky/Cable"
        field="skyOrCable"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={(prop: any) => prop.skyOrCable ? <span>Yes</span> : <span>No</span>}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Internet"
        field="internet"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={(prop: any) => prop.internet ? <span>Yes</span> : <span>No</span>}
      />

      <ColumnDirective
        visible={false}
        textAlign="Left"
        headerText="Phone"
        field="phone"
        width="100"
        hideAtMedia={`(min-width: ${minWidthTablet})`}
        template={(prop: any) => prop.phone ? <span>Yes</span> : <span>No</span>}
      />
    </ColumnsDirective>
  );
};