import { Button, Input } from "@rentchief/components";
import { Formik, FormikProps } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import * as S from "./PostcodeSearchPart.styles";

const PostcodeSearchPart = () => {
  let history = useHistory();

  const onSubmit = (postcode: string) => {
    let path = (postcode != "" && postcode != null) 
      ? `/dashboard/research?postcode=${postcode}` 
      : `/dashboard/research`
    history.push(path);
  };

  return (
    <Formik
      initialValues={{ postcode: null }}
      onSubmit={(v) => onSubmit(v.postcode)}
    >
      {(props: FormikProps<{ postcode: string }>) => (
        <S.ResearchAreaDiv>
            <S.InputDiv>
                <S.Label>Postcode</S.Label>
                <Input
                  value={props.values.postcode}
                  onChange={(e) => props.setFieldValue("postcode", e.currentTarget.value)}
                />
            </S.InputDiv>
            <S.ButtonDiv>
                <S.Label></S.Label>
                <Button type="submit" onClick={() => props.submitForm()}>
                    Go
                </Button>
            </S.ButtonDiv>
        </S.ResearchAreaDiv>
      )}
    </Formik>
  );
};

export default PostcodeSearchPart;